import { newPageInfo } from "@hex-insights/router";
import { classYearHubPageInfo } from "../pageinfo";

export const classYearHubIndexPageInfo = newPageInfo({
	name: "Class Years Index",
	title: "",
	description: "Index of Class Years.",
	exact: true,
	to: classYearHubPageInfo.to,
	path: classYearHubPageInfo.path,
	parent: classYearHubPageInfo,
});
