import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	NoteAttachmentFilterFormController,
	NoteAttachmentFilterFormState,
	NoteAttachmentFilterFormUtils,
	NoteAttachmentFormat,
	NoteAttachmentIndexQuery,
	NoteAttachmentOrderFormController,
	NoteAttachmentOrderFormState,
	NoteAttachmentPaginationForm,
	NoteAttachmentSearchForm,
	useNoteAttachmentIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { noteAttachmentHubCreatePageInfo } from "../CreatePage/pageinfo";
import { noteAttachmentHubDetailPageInfo } from "../DetailPage/pageinfo";
import { noteAttachmentHubIndexPageInfo } from "./pageinfo";

const columns: (keyof NoteAttachmentIndexQuery["noteAttachmentConnection"]["edges"][0]["node"])[] = [
	"id",
	"order",
	"isFile",
	"linkURL",
	"url",
];

function orderFieldToColumn(_field: null) {
	return "id";
}

function columnToOrderField(_column: string) {
	return null;
}

export function NoteAttachmentHubIndexPage() {
	useActivePageRegistration(noteAttachmentHubIndexPageInfo);

	const filterFormState = NoteAttachmentFilterFormState.useFormState();
	const orderFormState = NoteAttachmentOrderFormState.useFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(
		filterFormState.formValues,
		NoteAttachmentFilterFormUtils.toFilterInputs,
	);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useNoteAttachmentIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		NoteAttachmentFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = NoteAttachmentFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.noteAttachmentConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading level={1} noMargin>
					Note Attachments
				</Heading>

				<InternalLink to={noteAttachmentHubCreatePageInfo.to}>New Note Attachment</InternalLink>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<NoteAttachmentSearchForm formState={filterFormState} />
				<NoteAttachmentFilterFormController formState={filterFormState} numActiveFilters={numActiveFilters} />
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<NoteAttachmentOrderFormController formState={orderFormState} />
			</Row>

			<Table.Container className="hub__index-page__table__container">
				<Table columns={columns} minColumnWidth="10rem" {...tableSortingProps} className="hub__index-page__table">
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="id">ID</Table.Heading>
							<Table.Heading column="order" noSort>
								Order
							</Table.Heading>
							<Table.Heading column="isFile" noSort>
								Is File
							</Table.Heading>
							<Table.Heading column="linkURL" noSort>
								Link URL
							</Table.Heading>
							<Table.Heading column="url" noSort>
								URL
							</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.noteAttachmentConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="id" oneLine numeric>
											<InternalLink to={noteAttachmentHubDetailPageInfo.to(e.node.id)}>{e.node.id}</InternalLink>
										</Table.Cell>
										<Table.Cell column="order" oneLine numeric>
											{NoteAttachmentFormat.Fields.order(e.node.order)}
										</Table.Cell>
										<Table.Cell column="isFile" oneLine>
											{NoteAttachmentFormat.Fields.isFile(e.node.isFile)}
										</Table.Cell>
										<Table.Cell column="linkURL" oneLine>
											{NoteAttachmentFormat.Fields.linkURL(e.node.linkURL)}
										</Table.Cell>
										<Table.Cell column="url" oneLine>
											{NoteAttachmentFormat.Fields.url(e.node.url)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<NoteAttachmentPaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.noteAttachmentConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
