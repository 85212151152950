import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const homeRoomHubPageInfo = newPageInfo({
	title: "Home Rooms",
	description: "Pages related to the Home Room model.",
	exact: false,
	to: hubsPageInfo.to + "/home-rooms",
	path: hubsPageInfo.path + "/home-rooms",
	parent: hubsPageInfo,
});
