import { newPageInfo } from "@hex-insights/router";
import { Course } from "@hex-insights/verita.shared";
import { courseHubPageInfo } from "../pageinfo";

export type CourseHubDetailPageRouteParams = {
	id: string;
};

type CourseForRouteTitle = Pick<Course, "name">;

export const courseHubDetailPageInfo = newPageInfo({
	name: "Course Detail",
	title: (e: CourseForRouteTitle) => e.name,
	description: "Detail page for an course.",
	exact: false,
	isLeaf: true,
	to: (id: Course["id"]) => courseHubPageInfo.to + `/${id}`,
	path: courseHubPageInfo.path + "/:id(\\d+)",
	parent: courseHubPageInfo,
});
