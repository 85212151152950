import { IndexForms } from "@hex-insights/app-modules";
import { MFAFactorFilterFormValues } from "../../../../Utilities";

export type MFAFactorSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the MFAFactor model.
 */
export function MFAFactorSearchForm(props: MFAFactorSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type MFAFactorFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, MFAFactorFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the MFAFactor model.
 */
export function MFAFactorFilterFormController(props: MFAFactorFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={MFAFactorFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `MFAFactorFilterForm`
