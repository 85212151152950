import { newPageInfo } from "@hex-insights/router";
import { courseHubPageInfo } from "../pageinfo";

export const courseHubCreatePageInfo = newPageInfo({
	name: "New Course",
	title: "New",
	description: "Create a new Course record.",
	exact: true,
	to: courseHubPageInfo.to + "/new",
	path: courseHubPageInfo.path + "/new",
	parent: courseHubPageInfo,
});
