import React from "react";
import { useApolloClient } from "@apollo/client";
import { asString, nullableStringTypeFilter, TypeFilterObject, useOpenToggle, useTimeout } from "@hex-insights/core";
import { useQueryStateSync, useQueryStateValuesOnMount } from "@hex-insights/router";
import {
	EnrollmentApplication,
	EnrollmentApplicationFilterInput,
	EnrollmentApplicationNamesDocument,
} from "@hex-insights/verita.shared";

export type DetailQuery = {
	id: string | null;
	studentFormIdentifier: string | null;
};

const defaultDetailQuery: DetailQuery = {
	id: null,
	studentFormIdentifier: null,
};

const detailQueryTypeFilterObject: TypeFilterObject<DetailQuery> = {
	id: (v: any) => (v ? asString(v) : null),
	studentFormIdentifier: nullableStringTypeFilter(),
};

export function useEnrollmentApplicationDetailDrawer() {
	const initialQueryState = useQueryStateValuesOnMount(detailQueryTypeFilterObject, defaultDetailQuery);
	const [queryState, setQueryState] = React.useState(initialQueryState);
	useQueryStateSync(queryState, detailQueryTypeFilterObject, setQueryState);

	const { isOpen: isDrawerOpen, open: baseOpenDrawer, close: baseCloseDrawer } = useOpenToggle(queryState.id !== null);
	const { setTimeout, clearTimeout } = useTimeout();
	const openDrawer = React.useCallback(
		(enrollmentApplicationID: EnrollmentApplication["id"]) => {
			clearTimeout();
			setQueryState({ id: enrollmentApplicationID, studentFormIdentifier: null });
			baseOpenDrawer();
		},
		[clearTimeout, baseOpenDrawer],
	);
	const closeDrawer = React.useCallback(() => {
		baseCloseDrawer();
		setTimeout(() => setQueryState({ id: null, studentFormIdentifier: null }), 400); // allow time for drawer animation to finish
	}, [setTimeout, baseCloseDrawer]);

	const { id: openEnrollmentApplicationID, studentFormIdentifier } = queryState;
	const client = useApolloClient();
	React.useEffect(() => {
		if (!studentFormIdentifier) {
			return;
		}
		(async () => {
			const filters: EnrollmentApplicationFilterInput = {
				enrollmentApplicationStudent: [{ formIdentifierEQ: studentFormIdentifier }],
			};
			const { data } = await client.query({
				query: EnrollmentApplicationNamesDocument,
				variables: { filters },
			});
			if (data && data.enrollmentApplicationConnection.edges.length > 0) {
				openDrawer(data.enrollmentApplicationConnection.edges[0].node.id);
			}
		})();
	}, [studentFormIdentifier, client, openDrawer]);

	return { openEnrollmentApplicationID, isDrawerOpen, openDrawer, closeDrawer };
}
