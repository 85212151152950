import { stringToLocalDate } from "@hex-insights/core";
import { SchoolStudentEnrollment } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the createdDate field of the SchoolStudentEnrollment model.
 */
export function createdDate(value: SchoolStudentEnrollment["createdDate"] | null): string {
	if (value === null) {
		return toString(value);
	}
	const dateValue = stringToLocalDate(value, "day");
	return dateValue.toLocaleString(undefined, {
		year: dateValue.getFullYear() === new Date().getFullYear() ? undefined : "numeric",
		month: "short",
		day: "numeric",
	});
}

/**
 * Formats the paymentDueDate field of the SchoolStudentEnrollment model.
 */
export function paymentDueDate(value: SchoolStudentEnrollment["paymentDueDate"] | null): string {
	if (value === null) {
		return toString(value);
	}
	const dateValue = stringToLocalDate(value, "day");
	return dateValue.toLocaleString(undefined, {
		year: dateValue.getFullYear() === new Date().getFullYear() ? undefined : "numeric",
		month: "short",
		day: "numeric",
	});
}

/**
 * Formats the hasPaidFee field of the SchoolStudentEnrollment model.
 */
export function hasPaidFee(value: SchoolStudentEnrollment["hasPaidFee"]): string {
	return value ? "Yes" : "No";
}

/**
 * Formats the isVoided field of the SchoolStudentEnrollment model.
 */
export function isVoided(value: SchoolStudentEnrollment["isVoided"]): string {
	return value ? "Yes" : "No";
}
