import { Conditional, Else, Heading, If } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import { Main, MealPlanFormat, StudentLink, useMealPlanIndexQuery } from "@hex-insights/verita.shared";
import { mealPlanListPageInfo } from "./pageinfo";

const columns = ["student", "notes"];

export function MealPlanListPage() {
	useActivePageRegistration(mealPlanListPageInfo);

	const { loading, data, error } = useMealPlanIndexQuery();

	return (
		<Main>
			<Heading level={1}>Meal Plans</Heading>

			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={!!error}>Something went wrong.</If>
				<Else>
					<Table columns={columns} className="hub__index-page__table__container">
						<Table.Header className="hub__index-page__table__header">
							<Table.Row>
								<Table.Heading column="student" noSort>
									Student
								</Table.Heading>
								<Table.Heading column="notes" noSort>
									Notes
								</Table.Heading>
							</Table.Row>
						</Table.Header>

						<Table.Body className="hub__index-page__table__body">
							{data?.mealPlanConnection.edges.map((e) => (
								<Table.Row key={e.node.id} className="hub__index-page__table__row">
									<Table.Cell column="student" oneLine>
										<StudentLink instance={e.node.student} />
									</Table.Cell>
									<Table.Cell column="notes" oneLine>
										{MealPlanFormat.Fields.notes(e.node.notes)}
									</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table>
				</Else>
			</Conditional>
		</Main>
	);
}
