import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	ExpenseBudget,
	ExpenseBudgetCreateInput,
	ExpenseBudgetCreateMutation,
	ExpenseBudgetDeleteMutation,
	ExpenseBudgetDetailDocument,
	ExpenseBudgetDetailQuery,
	ExpenseBudgetDetailQueryVariables,
	ExpenseBudgetUpdateInput,
	ExpenseBudgetUpdateMutation,
	ExpenseCategory,
	graphTypeNames,
	useExpenseBudgetCreateMutation,
	useExpenseBudgetDeleteMutation,
	useExpenseBudgetUpdateMutation,
} from "../../GraphQL";
import { ExpenseBudgetFormConversion } from "../ModelFormConversion";
import { ExpenseBudgetFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the ExpenseBudget model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the ExpenseBudget model.
 */
export function useCreate() {
	const [createExpenseBudget] = useExpenseBudgetCreateMutation();

	return React.useCallback(
		async (formValues: ExpenseBudgetFormValues.Create) => {
			const input = ExpenseBudgetFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createExpenseBudget({ variables: { input }, update: updateCache });

			return { data: data?.createExpenseBudget ?? null, errors: errors ?? null };
		},
		[createExpenseBudget],
	);
}

/**
 * Returns an `update` function for the ExpenseBudget model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the ExpenseBudget model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: ExpenseBudget["id"]) {
	const [updateExpenseBudget] = useExpenseBudgetUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<ExpenseBudgetFormValues.Detail>,
			initialFormValues: Partial<ExpenseBudgetFormValues.Detail>,
		) => {
			const input = ExpenseBudgetFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateExpenseBudget({ variables: { id, input }, update: updateCache });

			return { data: data?.updateExpenseBudget ?? null, errors: errors ?? null };
		},
		[updateExpenseBudget, id],
	);
}

/**
 * Returns a `del` function for the ExpenseBudget model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: ExpenseBudget["id"]) {
	const [deleteExpenseBudget] = useExpenseBudgetDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteExpenseBudget({ variables: { id }, update: updateCache });

		return { data: data?.deleteExpenseBudget ?? false, errors: errors ?? null };
	}, [deleteExpenseBudget, id]);
}

export function getUpdateCacheForCreate(input: ExpenseBudgetCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ExpenseBudgetCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createExpenseBudget;

		cache.writeQuery<ExpenseBudgetDetailQuery, ExpenseBudgetDetailQueryVariables>({
			query: ExpenseBudgetDetailDocument,
			data: { expenseBudget: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.expenseCategoryID) {
			addToExpenseBudgetsOfExpenseCategoryCache(cache, input.expenseCategoryID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "expenseBudgetConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: ExpenseBudgetUpdateInput,
	initialFormValues: Partial<ExpenseBudgetFormValues.Detail>,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ExpenseBudgetUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateExpenseBudget;

		if (initialFormValues.expenseCategoryID && input.expenseCategoryID) {
			removeFromExpenseBudgetsOfExpenseCategoryCache(cache, initialFormValues.expenseCategoryID, updatedObject);
		}
		if (input.expenseCategoryID) {
			addToExpenseBudgetsOfExpenseCategoryCache(cache, input.expenseCategoryID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: ExpenseBudget["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ExpenseBudgetDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteExpenseBudget) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.ExpenseBudget }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "expenseBudgetConnection" });
		cache.gc();
	};
}

function addToExpenseBudgetsOfExpenseCategoryCache(
	cache: ApolloCache<any>,
	targetID: ExpenseCategory["id"],
	object: Pick<ExpenseBudget, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ExpenseCategory }),
		fields: {
			expenseBudgets: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromExpenseBudgetsOfExpenseCategoryCache(
	cache: ApolloCache<any>,
	targetID: ExpenseCategory["id"],
	object: Pick<ExpenseBudget, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ExpenseCategory }),
		fields: {
			expenseBudgets: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<ExpenseBudget, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment ExpenseBudgetRef on ExpenseBudget {
				id
			}
		`,
		data: object,
	});
}
