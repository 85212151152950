import React from "react";
import { addTimeToDate, Column, dateTrunc, Heading, Icon, If, makeClassName, Row, Section } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	DocumentResourceFolder,
	DocumentResourceOrderField,
	HorizontalScrollingContainer,
	OrderDirection,
	useDocumentResourceFolderDetailQuery,
	useDocumentResourceIndexQuery,
} from "@hex-insights/verita.shared";
import {
	DocumentResourceDisplay,
	DocumentResourceDisplayProps,
	DocumentResourceFolderCreator,
	DocumentResourceFolderDisplay,
	DocumentResourceFolderDisplayProps,
	DocumentResourceFolderEditor,
	FoldersGrid,
	ResourcesGrid,
	ResourcesRowSection,
	SectionDivider,
	useDropTarget,
} from "../../Components";
import { resourcesFoldersIndexPageInfo } from "../IndexPage/pageinfo";
import { resourcesFoldersDetailPageInfo, ResourcesFoldersDetailPageRouteParams } from "./pageinfo";
import styles from "./styles.module.css";

export function ResourcesFoldersDetailPage() {
	const { documentResourceFolderID } = useRouteParams<ResourcesFoldersDetailPageRouteParams>();
	const { data } = useDocumentResourceFolderDetailQuery({ variables: { id: documentResourceFolderID } });
	const folder = data?.documentResourceFolder;

	useActivePageRegistration(
		resourcesFoldersDetailPageInfo,
		data ? resourcesFoldersDetailPageInfo.title(data.documentResourceFolder) : "",
	);

	if (!folder) {
		return <div>Loading...</div>;
	}

	return (
		<Section style={{ width: "100%", overflowX: "hidden" }}>
			<Section.Header>
				<Column>
					<div>
						<BackLink parentDocumentResourceFolder={folder.parentDocumentResourceFolder} />
					</div>

					<Heading level={1} noMargin>
						<Row justify="spaced-start" align="center">
							<span>{folder.name}</span>
							<DocumentResourceFolderEditor id={documentResourceFolderID} />
						</Row>
					</Heading>
				</Column>
			</Section.Header>
			<Section.Body>
				<Column justify="spaced-start">
					<RecentlyCreated documentResourceFolderID={documentResourceFolderID} />
					<Folders documentResourceFolders={folder.childDocumentResourceFolders} />
					<If condition={folder.documentResources.length > 0}>
						<Documents documentResources={folder.documentResources} />
					</If>
				</Column>
			</Section.Body>
		</Section>
	);
}

type BackLinkProps = {
	parentDocumentResourceFolder: Pick<DocumentResourceFolder, "id" | "name"> | null;
};

function BackLink({ parentDocumentResourceFolder }: BackLinkProps) {
	const { isDraggingOver, onDragOver, onDragLeave, onDrop } = useDropTarget(parentDocumentResourceFolder, true);

	let link = <InternalLink to={resourcesFoldersIndexPageInfo.to}>&lt; Home</InternalLink>;

	if (parentDocumentResourceFolder) {
		link = (
			<InternalLink to={resourcesFoldersDetailPageInfo.to(parentDocumentResourceFolder.id)}>
				&lt; {parentDocumentResourceFolder.name}
			</InternalLink>
		);
	}

	return (
		<div
			onDragOver={onDragOver}
			onDragLeave={onDragLeave}
			onDrop={onDrop}
			className={makeClassName(styles["back-link"], isDraggingOver ? styles["back-link--dragging-over"] : "")}
		>
			{link}
		</div>
	);
}

type RecentlyCreatedProps = {
	documentResourceFolderID: DocumentResourceFolder["id"];
};

function RecentlyCreated({ documentResourceFolderID }: RecentlyCreatedProps) {
	const cutoffTime = dateTrunc(addTimeToDate(new Date(), [-48, "hours"]), "hour");
	const { data } = useDocumentResourceIndexQuery({
		variables: {
			filters: {
				createdAtGT: cutoffTime.toISOString(),
				documentResourceFolders: [{ idEQ: documentResourceFolderID }],
			},
			order: { field: DocumentResourceOrderField.CreatedAt, direction: OrderDirection.Desc },
		},
		fetchPolicy: "cache-and-network",
	});

	if (!data || data.documentResourceConnection.edges.length === 0) {
		return null;
	}

	return (
		<React.Fragment>
			<ResourcesRowSection icon={<Icon.Star />} label="New" style={{ width: "100%" }}>
				<HorizontalScrollingContainer>
					<Row justify="spaced-start" style={{ width: "100%", minWidth: 0 }}>
						{data?.documentResourceConnection.edges.map(({ node }) => (
							<DocumentResourceDisplay key={node.id} documentResource={node} />
						))}
					</Row>
				</HorizontalScrollingContainer>
			</ResourcesRowSection>

			<SectionDivider />
		</React.Fragment>
	);
}

type FoldersProps = {
	documentResourceFolders: DocumentResourceFolderDisplayProps["documentResourceFolder"][];
};

function Folders({ documentResourceFolders }: FoldersProps) {
	return (
		<Section>
			<Section.Body style={{ paddingLeft: 0, paddingRight: 0 }}>
				<FoldersGrid>
					{documentResourceFolders.map((e) => (
						<DocumentResourceFolderDisplay
							key={e.id}
							documentResourceFolder={e}
							draggable
							dropTarget
							style={{ width: "100%" }}
						/>
					))}
					<DocumentResourceFolderCreator style={{ width: "100%" }} />
				</FoldersGrid>
			</Section.Body>
		</Section>
	);
}

type DocumentsProps = {
	documentResources: DocumentResourceDisplayProps["documentResource"][];
};

function Documents({ documentResources }: DocumentsProps) {
	return (
		<Section>
			<Section.Body style={{ paddingLeft: 0, paddingRight: 0 }}>
				<ResourcesGrid>
					{documentResources.map((e) => (
						<DocumentResourceDisplay key={e.id} documentResource={e} draggable style={{ width: "100%" }} />
					))}
				</ResourcesGrid>
			</Section.Body>
		</Section>
	);
}
