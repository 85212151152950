import React from "react";
import { Heading } from "@hex-insights/core";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { AcademicYearForm } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { academicYearHubDetailPageInfo } from "../DetailPage/pageinfo";
import { academicYearHubCreatePageInfo } from "./pageinfo";

export function AcademicYearHubCreatePage() {
	useActivePageRegistration(academicYearHubCreatePageInfo);

	const { push: pushHistory } = useHistory();
	const onSuccess = React.useCallback<AcademicYearForm.ControlledCreateProps["onSuccess"]>(
		({ id }) => {
			pushHistory(academicYearHubDetailPageInfo.to(id));
		},
		[pushHistory],
	);

	return (
		<Main>
			<Heading.H1>New Academic Year</Heading.H1>

			<AcademicYearForm.ControlledCreate onSuccess={onSuccess} />
		</Main>
	);
}
