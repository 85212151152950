import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, If, Row } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
	SubmitButton,
} from "@hex-insights/forms";
import { UserDetailQuery, UserFormConversion, UserFormState, UserFormValues } from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<UserFormValues.Create>;
	onSuccess: () => void;
};

/**
 * Renders the create form of the User model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = UserFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<UserFormValues.Create>;
};

/**
 * Renders the create form of the User model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<UserFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="user.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Field.Email formState={formState} formType={FormType.Create} />
				<Field.Person formState={formState} formType={FormType.Create} />
				<Field.PermissionGroups formState={formState} formType={FormType.Create} />
				<Field.UserGroups formState={formState} formType={FormType.Create} />

				<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
					Submit
				</SubmitButton>
			</Column>
		</Form>
	);
}

export type ControlledDetailProps = {
	user: UserDetailQuery["user"];
	applyUpdate: ApplyUpdateFunction<UserFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the User model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(() => UserFormConversion.toFormValues(props.user), [props.user]);
	const formState = UserFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<UserFormValues.Detail>;
};

/**
 * Renders the detail form of the User model using the given form state.
 */
export function Detail({ formState, user, applyUpdate, onSuccess }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<UserFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form name={`user.detail.${user.id}`} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.Email formState={formState} formType={FormType.Update} id={user.id} />
				<Field.Status formState={formState} formType={FormType.Update} id={user.id} />
				<Field.PasswordUpdatedAt formState={formState} formType={FormType.Update} id={user.id} />
				<Field.LoginRecords
					formState={formState}
					formType={FormType.Update}
					id={user.id}
					currentLoginRecords={user.loginRecords}
				/>
				<Field.MFAFactor
					formState={formState}
					formType={FormType.Update}
					id={user.id}
					currentMFAFactor={user.mfaFactor}
				/>
				<Field.MFAInvalidOTPAttempts
					formState={formState}
					formType={FormType.Update}
					id={user.id}
					currentMFAInvalidOTPAttempts={user.mfaInvalidOTPAttempts}
				/>
				<Field.PermissionGroups
					formState={formState}
					formType={FormType.Update}
					id={user.id}
					currentPermissionGroups={user.permissionGroups}
				/>
				<Field.Person formState={formState} formType={FormType.Update} id={user.id} currentPerson={user.person} />
				<Field.Sessions formState={formState} formType={FormType.Update} id={user.id} currentSessions={user.sessions} />
				<Field.TrustedDevices
					formState={formState}
					formType={FormType.Update}
					id={user.id}
					currentTrustedDevices={user.trustedDevices}
				/>
				<Field.UserGroups
					formState={formState}
					formType={FormType.Update}
					id={user.id}
					currentUserGroups={user.userGroups}
				/>
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	user: UserDetailQuery["user"];
};

/**
 * Renders a read-only detail form of the User model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(() => UserFormConversion.toFormValues(props.user), [props.user]);
	const formState = UserFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<UserFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the User model using the given form state.
 */
export function ReadOnly({ formState, user }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.Email formState={formState} formType={FormType.Update} id={user.id} />
			<Field.Status formState={formState} formType={FormType.Update} id={user.id} />
			<Field.PasswordUpdatedAt formState={formState} formType={FormType.Update} id={user.id} />
			<Field.LoginRecords
				formState={formState}
				formType={FormType.Update}
				id={user.id}
				currentLoginRecords={user.loginRecords}
			/>
			<Field.MFAFactor
				formState={formState}
				formType={FormType.Update}
				id={user.id}
				currentMFAFactor={user.mfaFactor}
			/>
			<Field.MFAInvalidOTPAttempts
				formState={formState}
				formType={FormType.Update}
				id={user.id}
				currentMFAInvalidOTPAttempts={user.mfaInvalidOTPAttempts}
			/>
			<Field.PermissionGroups
				formState={formState}
				formType={FormType.Update}
				id={user.id}
				currentPermissionGroups={user.permissionGroups}
			/>
			<Field.Person formState={formState} formType={FormType.Update} id={user.id} currentPerson={user.person} />
			<Field.Sessions formState={formState} formType={FormType.Update} id={user.id} currentSessions={user.sessions} />
			<Field.TrustedDevices
				formState={formState}
				formType={FormType.Update}
				id={user.id}
				currentTrustedDevices={user.trustedDevices}
			/>
			<Field.UserGroups
				formState={formState}
				formType={FormType.Update}
				id={user.id}
				currentUserGroups={user.userGroups}
			/>
		</Column>
	);
}
