import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import {
	NoteAttachmentFilterInput,
	NoteAttachmentOrder,
	useNoteAttachmentPaginationQuery,
} from "../../../../Utilities";

export type NoteAttachmentPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: NoteAttachmentFilterInput | NoteAttachmentFilterInput[] | null;
	order: NoteAttachmentOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the NoteAttachment model.
 */
export function NoteAttachmentPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: NoteAttachmentPaginationFormProps) {
	const { loading, data } = useNoteAttachmentPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.noteAttachmentConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
