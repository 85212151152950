import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const documentResourceFolderHubPageInfo = newPageInfo({
	title: "Document Resource Folders",
	description: "Pages related to the Document Resource Folder model.",
	exact: false,
	to: hubsPageInfo.to + "/document-resource-folders",
	path: hubsPageInfo.path + "/document-resource-folders",
	parent: hubsPageInfo,
});
