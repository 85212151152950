import { ExpenseBudget } from "../../../GraphQL";
import { formatPrice } from "../../money";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the budgetMonth field of the ExpenseBudget model.
 */
export function budgetMonth(value: ExpenseBudget["budgetMonth"] | null): string {
	return toString(value);
}

/**
 * Formats the amount field of the ExpenseBudget model.
 */
export function amount(value: ExpenseBudget["amount"] | null): string {
	return formatPrice(value);
}
