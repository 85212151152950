import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { PaymentForm, PaymentFormValues, PaymentMutation, usePaymentDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { paymentHubDetailPageInfo } from "./pageinfo";

function PaymentHubDetailPageWrapper() {
	// Manages identity of the PaymentHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <PaymentHubDetailPage key={id} />;
}

export { PaymentHubDetailPageWrapper as PaymentHubDetailPage };

function PaymentHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = usePaymentDetailQuery({ variables: { id } });
	const payment = data?.payment;
	useActivePageRegistration(paymentHubDetailPageInfo, !!payment ? paymentHubDetailPageInfo.title(payment) : "");

	const update = PaymentMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<PaymentFormValues.Detail>, initialFormValues: PaymentFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading payment</If>
				<Else>
					{!!payment && (
						<React.Fragment>
							<Heading level={1}>{payment.id}</Heading>

							<PaymentForm.ControlledDetail
								key={syncID}
								payment={payment}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
