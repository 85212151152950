import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	EnrollmentApplication,
	EnrollmentApplicationNote,
	EnrollmentApplicationNoteCreateInput,
	EnrollmentApplicationNoteCreateMutation,
	EnrollmentApplicationNoteDeleteMutation,
	EnrollmentApplicationNoteDetailDocument,
	EnrollmentApplicationNoteDetailQuery,
	EnrollmentApplicationNoteDetailQueryVariables,
	EnrollmentApplicationNoteUpdateInput,
	EnrollmentApplicationNoteUpdateMutation,
	graphTypeNames,
	useEnrollmentApplicationNoteCreateMutation,
	useEnrollmentApplicationNoteDeleteMutation,
	useEnrollmentApplicationNoteUpdateMutation,
} from "../../GraphQL";
import { EnrollmentApplicationNoteFormConversion } from "../ModelFormConversion";
import { EnrollmentApplicationNoteFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the EnrollmentApplicationNote model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the EnrollmentApplicationNote model.
 */
export function useCreate() {
	const [createEnrollmentApplicationNote] = useEnrollmentApplicationNoteCreateMutation();

	return React.useCallback(
		async (formValues: EnrollmentApplicationNoteFormValues.Create) => {
			const input = EnrollmentApplicationNoteFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createEnrollmentApplicationNote({
				variables: { input },
				update: updateCache,
			});

			return { data: data?.createEnrollmentApplicationNote ?? null, errors: errors ?? null };
		},
		[createEnrollmentApplicationNote],
	);
}

/**
 * Returns an `update` function for the EnrollmentApplicationNote model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the EnrollmentApplicationNote model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: EnrollmentApplicationNote["id"]) {
	const [updateEnrollmentApplicationNote] = useEnrollmentApplicationNoteUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<EnrollmentApplicationNoteFormValues.Detail>,
			initialFormValues: EnrollmentApplicationNoteFormValues.Detail,
		) => {
			const input = EnrollmentApplicationNoteFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateEnrollmentApplicationNote({
				variables: { id, input },
				update: updateCache,
			});

			return { data: data?.updateEnrollmentApplicationNote ?? null, errors: errors ?? null };
		},
		[updateEnrollmentApplicationNote, id],
	);
}

/**
 * Returns a `del` function for the EnrollmentApplicationNote model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: EnrollmentApplicationNote["id"]) {
	const [deleteEnrollmentApplicationNote] = useEnrollmentApplicationNoteDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteEnrollmentApplicationNote({ variables: { id }, update: updateCache });

		return { data: data?.deleteEnrollmentApplicationNote ?? false, errors: errors ?? null };
	}, [deleteEnrollmentApplicationNote, id]);
}

function getUpdateCacheForCreate(input: EnrollmentApplicationNoteCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<EnrollmentApplicationNoteCreateMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createEnrollmentApplicationNote;

		cache.writeQuery<EnrollmentApplicationNoteDetailQuery, EnrollmentApplicationNoteDetailQueryVariables>({
			query: EnrollmentApplicationNoteDetailDocument,
			data: { enrollmentApplicationNote: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.enrollmentApplicationID) {
			addToEnrollmentApplicationNotesOfEnrollmentApplicationCache(cache, input.enrollmentApplicationID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "enrollmentApplicationNoteConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: EnrollmentApplicationNoteUpdateInput,
	initialFormValues: EnrollmentApplicationNoteFormValues.Detail,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<EnrollmentApplicationNoteUpdateMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateEnrollmentApplicationNote;

		if (initialFormValues.enrollmentApplicationID && input.enrollmentApplicationID) {
			removeFromEnrollmentApplicationNotesOfEnrollmentApplicationCache(
				cache,
				initialFormValues.enrollmentApplicationID,
				updatedObject,
			);
		}
		if (input.enrollmentApplicationID) {
			addToEnrollmentApplicationNotesOfEnrollmentApplicationCache(cache, input.enrollmentApplicationID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: EnrollmentApplicationNote["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<EnrollmentApplicationNoteDeleteMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (!result.data?.deleteEnrollmentApplicationNote) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.EnrollmentApplicationNote }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "enrollmentApplicationNoteConnection" });
		cache.gc();
	};
}

function addToEnrollmentApplicationNotesOfEnrollmentApplicationCache(
	cache: ApolloCache<any>,
	targetID: EnrollmentApplication["id"],
	object: Pick<EnrollmentApplicationNote, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.EnrollmentApplication }),
		fields: {
			enrollmentApplicationNotes: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromEnrollmentApplicationNotesOfEnrollmentApplicationCache(
	cache: ApolloCache<any>,
	targetID: EnrollmentApplication["id"],
	object: Pick<EnrollmentApplicationNote, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.EnrollmentApplication }),
		fields: {
			enrollmentApplicationNotes: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<EnrollmentApplicationNote, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment EnrollmentApplicationNoteRef on EnrollmentApplicationNote {
				id
			}
		`,
		data: object,
	});
}
