import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { MFAFactorHubDetailPage } from "./DetailPage";
import { mfaFactorHubDetailPageInfo } from "./DetailPage/pageinfo";
import { MFAFactorHubIndexPage } from "./IndexPage";
import { mfaFactorHubIndexPageInfo } from "./IndexPage/pageinfo";
import { mfaFactorHubPageInfo } from "./pageinfo";

export function MFAFactorHub() {
	useActivePageRegistration(mfaFactorHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...mfaFactorHubIndexPageInfo.routeProps}>
				<MFAFactorHubIndexPage />
			</Route>
			<Route {...mfaFactorHubDetailPageInfo.routeProps}>
				<MFAFactorHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
