import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { EmailAddressHubCreatePage } from "./CreatePage";
import { emailAddressHubCreatePageInfo } from "./CreatePage/pageinfo";
import { EmailAddressHubDetailPage } from "./DetailPage";
import { emailAddressHubDetailPageInfo } from "./DetailPage/pageinfo";
import { EmailAddressHubIndexPage } from "./IndexPage";
import { emailAddressHubIndexPageInfo } from "./IndexPage/pageinfo";
import { emailAddressHubPageInfo } from "./pageinfo";

export function EmailAddressHub() {
	useActivePageRegistration(emailAddressHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...emailAddressHubIndexPageInfo.routeProps}>
				<EmailAddressHubIndexPage />
			</Route>
			<Route {...emailAddressHubCreatePageInfo.routeProps}>
				<EmailAddressHubCreatePage />
			</Route>
			<Route {...emailAddressHubDetailPageInfo.routeProps}>
				<EmailAddressHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
