import React from "react";
import {
	BooleanField,
	DateTimeField,
	FieldDisplayArgs,
	FormType,
	NumberField,
	SelectField,
	TextField,
} from "@hex-insights/forms";
import { MFAFactorFormValues, UserSelect, useUserSelectLazyQuery } from "../../../../Utilities";
import { UserLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the MFAFactor model.
 */
type FieldProps<K extends keyof MFAFactorFormValues.Base = keyof MFAFactorFormValues.Base> = BaseFieldProps<
	Pick<MFAFactorFormValues.Base, K>
>;

/**
 * Generic props for fields of the MFAFactor model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof MFAFactorFormValues.Detail = keyof MFAFactorFormValues.Detail> = BaseFieldProps<
	Pick<MFAFactorFormValues.Detail, K>
>;

/**
 * Renders a field component for the `createdAt` field of the MFAFactor model.
 */
export function CreatedAt({ formState, formType = FormType.Update }: FieldProps<"createdAt">) {
	return (
		<DateTimeField formState={formState} name="createdAt" optional={FormType.isCreate(formType)} precision="minute" />
	);
}

/**
 * Renders a field component for the `count` field of the MFAFactor model.
 */
export function Count({ formState, formType = FormType.Update }: FieldProps<"count">) {
	return <NumberField formState={formState} name="count" optional={FormType.isCreate(formType)} validationUnit={1} />;
}

/**
 * Renders a field component for the `isApp` field of the MFAFactor model.
 */
export function IsApp({ formState }: FieldProps<"isApp">) {
	return <BooleanField formState={formState} name="isApp" />;
}

/**
 * Renders a field component for the `email` field of the MFAFactor model.
 */
export function Email({ formState }: FieldProps<"email">) {
	return <TextField formState={formState} name="email" optional />;
}

/**
 * Renders a field component for the `sms` field of the MFAFactor model.
 */
export function Sms({ formState }: FieldProps<"sms">) {
	return <TextField formState={formState} name="sms" optional />;
}

/**
 * Renders a field component for the `isVerified` field of the MFAFactor model.
 */
export function IsVerified({ formState }: FieldProps<"isVerified">) {
	return <BooleanField formState={formState} name="isVerified" />;
}

export type UserProps = DetailFieldProps<"userID"> & {
	currentUser?: UserSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `user` edge of the MFAFactor model.
 */
export function User({ formState, currentUser }: UserProps) {
	const [loadOptions, { loading, data }] = useUserSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.userID) {
			loadOptions();
		}
	}, [formState.formEditing.userID, loadOptions]);
	const options = React.useMemo(
		() => UserSelect.toOptions(data?.userConnection.edges, currentUser),
		[data, currentUser],
	);

	return (
		<SelectField
			formState={formState}
			name="userID"
			isLoading={loading}
			options={options}
			display={displayUser}
			blankValue={null}
		/>
	);
}

function displayUser({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserLink instance={{ id }}>{formattedValue}</UserLink>;
}
