import { newPageInfo } from "@hex-insights/router";
import { Service } from "@hex-insights/verita.shared";
import { serviceHubPageInfo } from "../pageinfo";

type ServiceForRouteTitle = Pick<Service, "name">;

export const serviceHubDetailPageInfo = newPageInfo({
	name: "Service Detail",
	title: (e: ServiceForRouteTitle) => e.name,
	description: "Detail page for an service.",
	exact: false,
	isLeaf: true,
	to: (id: Service["id"]) => serviceHubPageInfo.to + `/${id}`,
	path: serviceHubPageInfo.path + "/:id(\\d+)",
	parent: serviceHubPageInfo,
});
