import { NullPartial, omitKeys } from "@hex-insights/core";
import {
	EnrollmentApplicationNoteCreateInput,
	EnrollmentApplicationNoteDetailQuery,
	EnrollmentApplicationNoteUpdateInput,
} from "../../GraphQL";
import { EnrollmentApplicationNoteFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the EnrollmentApplicationNote model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(
	formValues: EnrollmentApplicationNoteFormValues.Create,
): EnrollmentApplicationNoteCreateInput {
	const input: NullPartial<EnrollmentApplicationNoteCreateInput> = { ...formValues };
	return input as EnrollmentApplicationNoteCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the EnrollmentApplicationNote model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<EnrollmentApplicationNoteFormValues.Detail>,
	_initialFormValues: EnrollmentApplicationNoteFormValues.Detail,
): EnrollmentApplicationNoteUpdateInput {
	const inputFormValues = omitKeys(formValues, ["createdAt", "authorID"]);
	const input: EnrollmentApplicationNoteUpdateInput = { ...inputFormValues };
	return input;
}

/**
 * Converts a EnrollmentApplicationNote instance to its corresponding detail form values.
 *
 * @param enrollmentApplicationNote The instance to convert.
 */
export function toFormValues(
	enrollmentApplicationNote: EnrollmentApplicationNoteDetailQuery["enrollmentApplicationNote"],
): EnrollmentApplicationNoteFormValues.Detail {
	return {
		createdAt: enrollmentApplicationNote.createdAt,
		body: enrollmentApplicationNote.body,
		authorID: enrollmentApplicationNote.author?.id ?? null,
		enrollmentApplicationID: enrollmentApplicationNote.enrollmentApplication?.id ?? null,
	};
}
