import { newPageInfo } from "@hex-insights/router";
import { academicYearTuitionHubPageInfo } from "../pageinfo";

export const academicYearTuitionHubCreatePageInfo = newPageInfo({
	name: "New Academic Year Tuition",
	title: "New",
	description: "Create a new Academic Year Tuition record.",
	exact: true,
	to: academicYearTuitionHubPageInfo.to + "/new",
	path: academicYearTuitionHubPageInfo.path + "/new",
	parent: academicYearTuitionHubPageInfo,
});
