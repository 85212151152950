import React from "react";
import {
	Button,
	compareObjects,
	compareStringsAsc,
	Conditional,
	Else,
	getObjectEntries,
	Heading,
	If,
	Row,
} from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	Bus,
	BusLink,
	BusPlanDropoffBusListQuery,
	BusPlanFormat,
	HomeRoomSection,
	HomeRoomSectionLink,
	Main,
	StudentLink,
	useBusPlanDropoffBusListQuery,
} from "@hex-insights/verita.shared";
import { busListDropoffPageInfo } from "./pageinfo";

export function BusListDropoffPage() {
	useActivePageRegistration(busListDropoffPageInfo);

	const { loading, data, error } = useBusPlanDropoffBusListQuery();
	const [groupBy, setGroupBy] = React.useState<"bus" | "homeRoomSection">("bus");
	const groupedData = React.useMemo(() => {
		if (!data) {
			return [];
		}
		if (groupBy === "bus") {
			return groupDataByBus(data);
		}
		if (groupBy === "homeRoomSection") {
			return groupDataByHomeRoomSection(data);
		}
		return [];
	}, [data, groupBy]);

	return (
		<Main>
			<Heading level={1}>Drop Off Bus List</Heading>

			<Row justify="spaced-start" style={{ marginBottom: "1rem" }}>
				<Button variant={groupBy === "bus" ? "primary" : "secondary"} onClick={() => setGroupBy("bus")}>
					Bus
				</Button>
				<Button
					variant={groupBy === "homeRoomSection" ? "primary" : "secondary"}
					onClick={() => setGroupBy("homeRoomSection")}
				>
					Home Room
				</Button>
			</Row>

			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={!!error}>Something went wrong.</If>
				<Else>
					<Conditional>
						<If condition={groupBy === "bus"}>
							<DropoffByBusTable groupedData={groupedData as any} />
						</If>
						<If condition={groupBy === "homeRoomSection"}>
							<DropoffByHomeRoomSectionTable groupedData={groupedData as any} />
						</If>
					</Conditional>
				</Else>
			</Conditional>
		</Main>
	);
}

const dropoffByBusColumns = ["student", "homeRoom", "dropoffTimeMinutes", "dropoffLocation"];

type DropoffByBusTableProps = {
	groupedData: ReturnType<typeof groupDataByBus>;
};

function DropoffByBusTable({ groupedData }: DropoffByBusTableProps) {
	return (
		<Table columns={dropoffByBusColumns} className="hub__index-page__table__container">
			<Table.Header className="hub__index-page__table__header">
				<Table.Row>
					<Table.Heading column="student" noSort>
						Student
					</Table.Heading>
					<Table.Heading column="homeRoom" noSort>
						Home Room
					</Table.Heading>
					<Table.Heading column="dropoffTimeMinutes" noSort>
						Dropoff Time
					</Table.Heading>
					<Table.Heading column="dropoffLocation" noSort>
						Dropoff Location
					</Table.Heading>
				</Table.Row>
			</Table.Header>
			<Table.Body className="hub__index-page__table__body">
				{groupedData.map(({ bus, busPlans }) => (
					<React.Fragment key={bus.id}>
						<Table.Row style={{ backgroundColor: "#f4f4f4" }}>
							<Table.Cell
								column="student"
								oneLine
								colspan={dropoffByBusColumns.length}
								style={{
									fontSize: "1.5rem",
									fontWeight: "bold",
								}}
							>
								{bus.name}
							</Table.Cell>
						</Table.Row>
						{busPlans.map((node) => (
							<Table.Row key={node.id}>
								<Table.Cell column="student" oneLine>
									<StudentLink instance={node.student} />
								</Table.Cell>
								<Table.Cell column="homeRoom" oneLine>
									{node.student.homeRoomSectionStudentEnrollments.length > 0 ? (
										<HomeRoomSectionLink instance={node.student.homeRoomSectionStudentEnrollments[0].homeRoomSection} />
									) : (
										""
									)}
								</Table.Cell>
								<Table.Cell column="dropoffTimeMinutes" oneLine style={{ textAlign: "center" }}>
									{BusPlanFormat.Fields.dropoffTimeMinutes(node.dropoffTimeMinutes)}
								</Table.Cell>
								<Table.Cell column="dropoffLocation" oneLine>
									{node.dropoffLocation}
								</Table.Cell>
							</Table.Row>
						))}
					</React.Fragment>
				))}
			</Table.Body>
		</Table>
	);
}

const dropoffByHomeRoomSectionColumns = ["student", "bus", "dropoffTimeMinutes", "dropoffLocation"];

type DropoffByHomeRoomSectionTableProps = {
	groupedData: ReturnType<typeof groupDataByHomeRoomSection>;
};

function DropoffByHomeRoomSectionTable({ groupedData }: DropoffByHomeRoomSectionTableProps) {
	return (
		<Table columns={dropoffByHomeRoomSectionColumns} className="hub__index-page__table__container">
			<Table.Header className="hub__index-page__table__header">
				<Table.Row>
					<Table.Heading column="student" noSort>
						Student
					</Table.Heading>
					<Table.Heading column="bus" noSort>
						Bus
					</Table.Heading>
					<Table.Heading column="dropoffTimeMinutes" noSort>
						Dropoff Time
					</Table.Heading>
					<Table.Heading column="dropoffLocation" noSort>
						Dropoff Location
					</Table.Heading>
				</Table.Row>
			</Table.Header>
			<Table.Body className="hub__index-page__table__body">
				{groupedData.map(({ homeRoomSection, busPlans }) => (
					<React.Fragment key={homeRoomSection.id}>
						<Table.Row style={{ backgroundColor: "#f4f4f4" }}>
							<Table.Cell
								column="student"
								oneLine
								colspan={dropoffByHomeRoomSectionColumns.length}
								style={{
									fontSize: "1.5rem",
									fontWeight: "bold",
								}}
							>
								{homeRoomSection.name}
							</Table.Cell>
						</Table.Row>
						{busPlans.map((node) => (
							<Table.Row key={node.id}>
								<Table.Cell column="student" oneLine>
									<StudentLink instance={node.student} />
								</Table.Cell>
								<Table.Cell column="bus" oneLine>
									{node.dropoffBus && <BusLink instance={node.dropoffBus} />}
								</Table.Cell>
								<Table.Cell column="dropoffTimeMinutes" oneLine style={{ textAlign: "center" }}>
									{BusPlanFormat.Fields.dropoffTimeMinutes(node.dropoffTimeMinutes)}
								</Table.Cell>
								<Table.Cell column="dropoffLocation" oneLine>
									{node.dropoffLocation}
								</Table.Cell>
							</Table.Row>
						))}
					</React.Fragment>
				))}
			</Table.Body>
		</Table>
	);
}

function groupDataByBus(data: BusPlanDropoffBusListQuery) {
	const groupedData: Record<Bus["id"], BusPlanDropoffBusListQuery["busPlanConnection"]["edges"][0]["node"][]> = {};
	const buses = {};

	for (let i = 0; i < data.busPlanConnection.edges.length; i++) {
		const node = data.busPlanConnection.edges[i].node;
		if (!node.dropoffBus) {
			continue;
		}
		if (!groupedData[node.dropoffBus.id]) {
			groupedData[node.dropoffBus.id] = [];
			buses[node.dropoffBus.id] = node.dropoffBus;
		}
		groupedData[node.dropoffBus.id].push(node);
	}

	return getObjectEntries(groupedData)
		.map(([busID, busPlans]) => ({ bus: buses[busID], busPlans }))
		.sort(compareObjects((e) => e.bus.name, compareStringsAsc()));
}

function groupDataByHomeRoomSection(data: BusPlanDropoffBusListQuery) {
	const groupedData: Record<
		HomeRoomSection["id"],
		BusPlanDropoffBusListQuery["busPlanConnection"]["edges"][0]["node"][]
	> = {};
	const homeRoomSections = {};

	for (let i = 0; i < data.busPlanConnection.edges.length; i++) {
		const node = data.busPlanConnection.edges[i].node;
		const homeRoomSection =
			node.student.homeRoomSectionStudentEnrollments.length > 0
				? node.student.homeRoomSectionStudentEnrollments[0].homeRoomSection
				: { id: "0", name: "Unknown" };
		if (!groupedData[homeRoomSection.id]) {
			groupedData[homeRoomSection.id] = [];
			homeRoomSections[homeRoomSection.id] = homeRoomSection;
		}
		groupedData[homeRoomSection.id].push(node);
	}

	return getObjectEntries(groupedData)
		.map(([homeRoomSectionID, busPlans]) => ({
			homeRoomSection: homeRoomSections[homeRoomSectionID],
			busPlans: busPlans.sort(compareObjects((e) => e.dropoffBus?.name ?? "", compareStringsAsc())),
		}))
		.sort(compareObjects((e) => (e.homeRoomSection.id !== "0" ? e.homeRoomSection.name : "Z"), compareStringsAsc()));
}
