import { newPageInfo } from "@hex-insights/router";
import { Relationship } from "@hex-insights/verita.shared";
import { relationshipHubPageInfo } from "../pageinfo";

type RelationshipForRouteTitle = Pick<Relationship, "id">;

export const relationshipHubDetailPageInfo = newPageInfo({
	name: "Relationship Detail",
	title: (e: RelationshipForRouteTitle) => e.id,
	description: "Detail page for an relationship.",
	exact: false,
	isLeaf: true,
	to: (id: Relationship["id"]) => relationshipHubPageInfo.to + `/${id}`,
	path: relationshipHubPageInfo.path + "/:id(\\d+)",
	parent: relationshipHubPageInfo,
});
