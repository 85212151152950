import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	graphTypeNames,
	Note,
	NoteComment,
	NoteCommentCreateInput,
	NoteCommentCreateMutation,
	NoteCommentDeleteMutation,
	NoteCommentDetailDocument,
	NoteCommentDetailQuery,
	NoteCommentDetailQueryVariables,
	NoteCommentUpdateInput,
	NoteCommentUpdateMutation,
	useNoteCommentCreateMutation,
	useNoteCommentDeleteMutation,
	useNoteCommentUpdateMutation,
} from "../../GraphQL";
import { NoteCommentFormConversion } from "../ModelFormConversion";
import { NoteCommentFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the NoteComment model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the NoteComment model.
 */
export function useCreate() {
	const [createNoteComment] = useNoteCommentCreateMutation();

	return React.useCallback(
		async (formValues: NoteCommentFormValues.Create) => {
			const input = NoteCommentFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createNoteComment({ variables: { input }, update: updateCache });

			return { data: data?.createNoteComment ?? null, errors: errors ?? null };
		},
		[createNoteComment],
	);
}

/**
 * Returns an `update` function for the NoteComment model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the NoteComment model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: NoteComment["id"]) {
	const [updateNoteComment] = useNoteCommentUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<NoteCommentFormValues.Detail>,
			initialFormValues: NoteCommentFormValues.Detail,
		) => {
			const input = NoteCommentFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateNoteComment({ variables: { id, input }, update: updateCache });

			return { data: data?.updateNoteComment ?? null, errors: errors ?? null };
		},
		[updateNoteComment, id],
	);
}

/**
 * Returns a `del` function for the NoteComment model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: NoteComment["id"]) {
	const [deleteNoteComment] = useNoteCommentDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteNoteComment({ variables: { id }, update: updateCache });

		return { data: data?.deleteNoteComment ?? false, errors: errors ?? null };
	}, [deleteNoteComment, id]);
}

function getUpdateCacheForCreate(input: NoteCommentCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<NoteCommentCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createNoteComment;

		cache.writeQuery<NoteCommentDetailQuery, NoteCommentDetailQueryVariables>({
			query: NoteCommentDetailDocument,
			data: { noteComment: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.noteID) {
			addToCommentsOfNoteCache(cache, input.noteID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "noteCommentConnection" });
	};
}

function getUpdateCacheForUpdate(_input: NoteCommentUpdateInput, _initialFormValues: NoteCommentFormValues.Detail) {
	return (
		_cache: ApolloCache<any>,
		result: Omit<FetchResult<NoteCommentUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}
	};
}

function getUpdateCacheForDelete(id: NoteComment["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<NoteCommentDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteNoteComment) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.NoteComment }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "noteCommentConnection" });
		cache.gc();
	};
}

function addToCommentsOfNoteCache(cache: ApolloCache<any>, targetID: Note["id"], object: Pick<NoteComment, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Note }),
		fields: {
			comments: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<NoteComment, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment NoteCommentRef on NoteComment {
				id
			}
		`,
		data: object,
	});
}
