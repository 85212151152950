import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const homeRoomSectionHubPageInfo = newPageInfo({
	title: "Home Room Sections",
	description: "Pages related to the Home Room Section model.",
	exact: false,
	to: hubsPageInfo.to + "/home-room-sections",
	path: hubsPageInfo.path + "/home-room-sections",
	parent: hubsPageInfo,
});
