import { newPageInfo } from "@hex-insights/router";
import { NoteComment } from "@hex-insights/verita.shared";
import { noteCommentHubPageInfo } from "../pageinfo";

type NoteCommentForRouteTitle = Pick<NoteComment, "id">;

export const noteCommentHubDetailPageInfo = newPageInfo({
	name: "Note Comment Detail",
	title: (e: NoteCommentForRouteTitle) => e.id,
	description: "Detail page for an noteComment.",
	exact: false,
	isLeaf: true,
	to: (id: NoteComment["id"]) => noteCommentHubPageInfo.to + `/${id}`,
	path: noteCommentHubPageInfo.path + "/:id(\\d+)",
	parent: noteCommentHubPageInfo,
});
