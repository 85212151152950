import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import {
	AcademicYearTuitionFilterInput,
	AcademicYearTuitionOrder,
	useAcademicYearTuitionPaginationQuery,
} from "../../../../Utilities";

export type AcademicYearTuitionPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: AcademicYearTuitionFilterInput | AcademicYearTuitionFilterInput[] | null;
	order: AcademicYearTuitionOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the AcademicYearTuition model.
 */
export function AcademicYearTuitionPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: AcademicYearTuitionPaginationFormProps) {
	const { loading, data } = useAcademicYearTuitionPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.academicYearTuitionConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
