import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, Heading, HeadingLevel, If, Row } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
	SubmitButton,
	useInternalField,
} from "@hex-insights/forms";
import {
	EmployeeDetailQuery,
	EmployeeFormConversion,
	EmployeeFormState,
	EmployeeFormValues,
	PersonFormValues,
} from "../../../../Utilities";
import { PersonForm } from "../Person";
import * as Field from "./Field";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<EmployeeFormValues.Create>;
	onSuccess: () => void;
};

/**
 * Renders the create form of the Employee model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = EmployeeFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<EmployeeFormValues.Create>;
};

/**
 * Renders the create form of the Employee model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<EmployeeFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="employee.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Field.Email formState={formState} formType={FormType.Create} />
				<Field.JobTitle formState={formState} formType={FormType.Create} />
				<Field.Role formState={formState} formType={FormType.Create} />
				<Field.StartDate formState={formState} formType={FormType.Create} />
				<Field.EndDate formState={formState} formType={FormType.Create} />
				<Field.Campuses formState={formState} formType={FormType.Create} />
				<Field.Person formState={formState} formType={FormType.Create} />

				<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
					Submit
				</SubmitButton>
			</Column>
		</Form>
	);
}

export type ControlledCreateWithPersonProps = {
	applyCreate: ApplyCreateFunction<EmployeeFormValues.CreateWithPerson>;
	onSuccess: () => void;
	headingLevel?: HeadingLevel;
};

/**
 * Renders the create form of the Employee model using an internally managed form state.
 */
export function ControlledCreateWithPerson(props: ControlledCreateWithPersonProps) {
	const formState = EmployeeFormState.useCreateWithPersonFormState();

	return <CreateWithPerson formState={formState} {...props} />;
}

export type CreateWithPersonProps = ControlledCreateWithPersonProps & {
	formState: FormState<EmployeeFormValues.CreateWithPerson>;
};

export function CreateWithPerson({ formState, applyCreate, onSuccess, headingLevel = 2 }: CreateWithPersonProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<EmployeeFormValues.CreateWithPerson>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="employee.create.withPerson" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<PersonForm.GeneralCreateFields formState={formState} headingLevel={headingLevel} />
				<PersonForm.DemographicCreateFields formState={formState} headingLevel={headingLevel} />
				<PersonForm.ContactCreateFields formState={formState} headingLevel={headingLevel} />
				<PersonForm.LinkedRecordsCreateFields formState={formState} headingLevel={headingLevel} />

				<Heading level={headingLevel} noMargin>
					Employment Details
				</Heading>
				<Field.Email formState={formState} formType={FormType.Create} />
				<Field.JobTitle formState={formState} formType={FormType.Create} />
				<Field.Role formState={formState} formType={FormType.Create} />
				<Field.StartDate formState={formState} formType={FormType.Create} />
				<Field.EndDate formState={formState} formType={FormType.Create} />
				<Field.Campuses formState={formState} formType={FormType.Create} />

				<Row justify="flex-end">
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

export type ControlledDetailProps = {
	employee: EmployeeDetailQuery["employee"];
	applyUpdate: ApplyUpdateFunction<EmployeeFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the Employee model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(() => EmployeeFormConversion.toFormValues(props.employee), [props.employee]);
	const formState = EmployeeFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<EmployeeFormValues.Detail>;
};

/**
 * Renders the detail form of the Employee model using the given form state.
 */
export function Detail({ formState, employee, applyUpdate, onSuccess }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<EmployeeFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form name={`employee.detail.${employee.id}`} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.Email formState={formState} formType={FormType.Update} id={employee.id} />
				<Field.JobTitle formState={formState} formType={FormType.Update} id={employee.id} />
				<Field.Role formState={formState} formType={FormType.Update} id={employee.id} />
				<Field.StartDate formState={formState} formType={FormType.Update} id={employee.id} />
				<Field.EndDate formState={formState} formType={FormType.Update} id={employee.id} />
				<Field.Campuses
					formState={formState}
					formType={FormType.Update}
					id={employee.id}
					currentCampuses={employee.campuses}
				/>
				<Field.Person
					formState={formState}
					formType={FormType.Update}
					id={employee.id}
					currentPerson={employee.person}
				/>
			</Column>
		</Form>
	);
}

export type ControlledDetailWithPersonProps = {
	employee: EmployeeDetailQuery["employee"];
	applyUpdate: ApplyUpdateFunction<EmployeeFormValues.DetailWithPerson>;
	onSuccess: () => void;
	headingLevel?: HeadingLevel;
};

/**
 * Renders the detail form of the Employee model using an internally managed form state.
 */
export function ControlledDetailWithPerson(props: ControlledDetailWithPersonProps) {
	const initialFormValues = React.useMemo(
		() => EmployeeFormConversion.toFormValuesWithPerson(props.employee),
		[props.employee],
	);
	const formState = EmployeeFormState.useDetailWithPersonFormState({ initialFormValues });

	return <DetailWithPerson formState={formState} {...props} />;
}

const hiddenPersonDetailFields: (keyof PersonFormValues.DetailInOther)[] = ["userID"];

export type DetailWithPersonProps = ControlledDetailWithPersonProps & {
	formState: FormState<EmployeeFormValues.DetailWithPerson>;
};

/**
 * Renders the detail form of the Employee model using the given form state.
 */
export function DetailWithPerson({
	formState,
	employee,
	applyUpdate,
	onSuccess,
	headingLevel = 2,
}: DetailWithPersonProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<EmployeeFormValues.DetailWithPerson>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	useInternalField(formState, hiddenPersonDetailFields);

	return (
		<Form name={`employee.detail.${employee.id}`} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<PersonForm.GeneralDetailFields formState={formState} person={employee.person} headingLevel={headingLevel} />
				<PersonForm.DemographicDetailFields
					formState={formState}
					person={employee.person}
					headingLevel={headingLevel}
				/>
				<PersonForm.ContactDetailFields formState={formState} person={employee.person} headingLevel={headingLevel} />

				<Heading level={headingLevel} noMargin>
					Employment Details
				</Heading>
				<Field.Email formState={formState} formType={FormType.Update} id={employee.id} />
				<Field.JobTitle formState={formState} formType={FormType.Update} id={employee.id} />
				<Field.Role formState={formState} formType={FormType.Update} id={employee.id} />
				<Field.StartDate formState={formState} formType={FormType.Update} id={employee.id} />
				<Field.EndDate formState={formState} formType={FormType.Update} id={employee.id} />
				<Field.Campuses
					formState={formState}
					formType={FormType.Update}
					id={employee.id}
					currentCampuses={employee.campuses}
				/>
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	employee: EmployeeDetailQuery["employee"];
};

/**
 * Renders a read-only detail form of the Employee model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(() => EmployeeFormConversion.toFormValues(props.employee), [props.employee]);
	const formState = EmployeeFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<EmployeeFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the Employee model using the given form state.
 */
export function ReadOnly({ formState, employee }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.Email formState={formState} formType={FormType.Update} id={employee.id} />
			<Field.JobTitle formState={formState} formType={FormType.Update} id={employee.id} />
			<Field.Role formState={formState} formType={FormType.Update} id={employee.id} />
			<Field.StartDate formState={formState} formType={FormType.Update} id={employee.id} />
			<Field.EndDate formState={formState} formType={FormType.Update} id={employee.id} />
			<Field.Campuses
				formState={formState}
				formType={FormType.Update}
				id={employee.id}
				currentCampuses={employee.campuses}
			/>
			<Field.Person formState={formState} formType={FormType.Update} id={employee.id} currentPerson={employee.person} />
		</Column>
	);
}
