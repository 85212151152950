import { newPageInfo } from "@hex-insights/router";
import { schoolStudentEnrollmentHubPageInfo } from "../pageinfo";

export const schoolStudentEnrollmentHubIndexPageInfo = newPageInfo({
	name: "School Student Enrollments Index",
	title: "",
	description: "School Student Enrollment index page.",
	exact: true,
	to: schoolStudentEnrollmentHubPageInfo.to,
	path: schoolStudentEnrollmentHubPageInfo.path,
	parent: schoolStudentEnrollmentHubPageInfo,
});
