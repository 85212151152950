import { newPageInfo } from "@hex-insights/router";
import { academicYearHubPageInfo } from "../pageinfo";

export const academicYearHubIndexPageInfo = newPageInfo({
	name: "Academic Years Index",
	title: "",
	description: "Academic Year index page.",
	exact: true,
	to: academicYearHubPageInfo.to,
	path: academicYearHubPageInfo.path,
	parent: academicYearHubPageInfo,
});
