import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { NoteOrderFormValues } from "../../../../Utilities";

export type NoteOrderFormControllerProps = {
	formState: FormState<NoteOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the Note model.
 */
export function NoteOrderFormController({ formState }: NoteOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={NoteOrderFormValues.fieldOptions}
			fieldTypeMapping={NoteOrderFormValues.fieldTypeMapping}
		/>
	);
}
