import { BillingSetupSubmission } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the numPayments field of the BillingSetupSubmission model.
 */
export function numPayments(value: BillingSetupSubmission["numPayments"] | null): string {
	return toString(value);
}

/**
 * Formats the isInterestedInBusPlan field of the BillingSetupSubmission model.
 */
export function isInterestedInBusPlan(value: BillingSetupSubmission["isInterestedInBusPlan"]): string {
	return toString(value);
}

/**
 * Formats the isInterestedInMealPlan field of the BillingSetupSubmission model.
 */
export function isInterestedInMealPlan(value: BillingSetupSubmission["isInterestedInMealPlan"]): string {
	return toString(value);
}

/**
 * Formats the authorName field of the BillingSetupSubmission model.
 */
export function authorName(value: BillingSetupSubmission["authorName"]): string {
	return toString(value);
}

/**
 * Formats the authorEmail field of the BillingSetupSubmission model.
 */
export function authorEmail(value: BillingSetupSubmission["authorEmail"]): string {
	return toString(value);
}

/**
 * Formats the authorPhone field of the BillingSetupSubmission model.
 */
export function authorPhone(value: BillingSetupSubmission["authorPhone"]): string {
	return toString(value);
}
