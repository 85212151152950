import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { RoomFilterInput, RoomOrder, useRoomPaginationQuery } from "../../../../Utilities";

export type RoomPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: RoomFilterInput | RoomFilterInput[] | null;
	order: RoomOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the Room model.
 */
export function RoomPaginationForm({ formState, filters, order, setPaginationInfo }: RoomPaginationFormProps) {
	const { loading, data } = useRoomPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.roomConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
