import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { EnrollmentApplicationNoteOrderFormValues } from "../../../../Utilities";

export type EnrollmentApplicationNoteOrderFormControllerProps = {
	formState: FormState<EnrollmentApplicationNoteOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the EnrollmentApplicationNote model.
 */
export function EnrollmentApplicationNoteOrderFormController({
	formState,
}: EnrollmentApplicationNoteOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={EnrollmentApplicationNoteOrderFormValues.fieldOptions}
			fieldTypeMapping={EnrollmentApplicationNoteOrderFormValues.fieldTypeMapping}
		/>
	);
}
