import { Quotation } from "../../GraphQL";

export type Base = {
	body: Quotation["body"];
	attribution: Quotation["attribution"];
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	body: "",
	attribution: "",
};
