import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	StudentDocumentForm,
	StudentDocumentFormValues,
	StudentDocumentMutation,
	useStudentDocumentDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { studentDocumentHubDetailPageInfo } from "./pageinfo";

function StudentDocumentHubDetailPageWrapper() {
	// Manages identity of the StudentDocumentHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <StudentDocumentHubDetailPage key={id} />;
}

export { StudentDocumentHubDetailPageWrapper as StudentDocumentHubDetailPage };

function StudentDocumentHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useStudentDocumentDetailQuery({ variables: { id } });
	const studentDocument = data?.studentDocument;
	useActivePageRegistration(
		studentDocumentHubDetailPageInfo,
		!!studentDocument ? studentDocumentHubDetailPageInfo.title(studentDocument) : "",
	);

	const update = StudentDocumentMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<StudentDocumentFormValues.Detail>,
			initialFormValues: StudentDocumentFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading studentDocument</If>
				<Else>
					{!!studentDocument && (
						<React.Fragment>
							<Heading level={1}>{studentDocument.name}</Heading>

							<StudentDocumentForm.ControlledDetail
								key={syncID}
								studentDocument={studentDocument}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
