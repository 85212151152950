import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import {
	LearningObjectiveMarkFilterInput,
	LearningObjectiveMarkOrder,
	useLearningObjectiveMarkPaginationQuery,
} from "../../../../Utilities";

export type LearningObjectiveMarkPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: LearningObjectiveMarkFilterInput | LearningObjectiveMarkFilterInput[] | null;
	order: LearningObjectiveMarkOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the LearningObjectiveMark model.
 */
export function LearningObjectiveMarkPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: LearningObjectiveMarkPaginationFormProps) {
	const { loading, data } = useLearningObjectiveMarkPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.learningObjectiveMarkConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
