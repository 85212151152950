import React from "react";
import { Icon, makeClassName, Row } from "@hex-insights/core";
import {
	BooleanButtonInput,
	BooleanField,
	BooleanFieldInputProps,
	FieldDisplayArgs,
	invalid,
	MultiSelectField,
	RadioButtonsInput,
	RadioField,
	RadioFieldProps,
	SelectField,
	TextField,
	useFieldSetState,
	valid,
	ValidationStatus,
} from "@hex-insights/forms";
import {
	EnrollmentApplicationParentFormValues,
	EnrollmentApplicationSubmissionSelect,
	useEnrollmentApplicationSubmissionSelectLazyQuery,
	validateEmail,
} from "../../../../Utilities";
import { EnrollmentApplicationSubmissionLink } from "../../../Links";
import { PhoneInput } from "../../../PhoneInput";
import { countryOptions } from "../options";
import { FutureBaseFieldProps } from "../Shared";
import styles from "./styles.module.css";

/**
 * Generic props for fields of the EnrollmentApplicationParent model.
 */
type FieldProps<
	K extends keyof EnrollmentApplicationParentFormValues.Base = keyof EnrollmentApplicationParentFormValues.Base,
> = FutureBaseFieldProps<Pick<EnrollmentApplicationParentFormValues.Base, K>>;

/**
 * Generic props for fields of the EnrollmentApplicationParent model that only appear in the detail form.
 */
type DetailFieldProps<
	K extends keyof EnrollmentApplicationParentFormValues.Detail = keyof EnrollmentApplicationParentFormValues.Detail,
> = FutureBaseFieldProps<Pick<EnrollmentApplicationParentFormValues.Detail, K>>;

/**
 * Renders a field component for the `formIdentifier` field of the EnrollmentApplicationParent model.
 */
export function FormIdentifier({ formState, ...props }: FieldProps<"formIdentifier">) {
	return <TextField formState={formState} name="formIdentifier" {...props} />;
}

/**
 * Renders a field component for the `name` field of the EnrollmentApplicationParent model.
 */
export function Name({ formState, ...props }: DetailFieldProps<"name">) {
	return <TextField formState={formState} name="name" {...props} />;
}

/**
 * Renders a field component for the `firstName` field of the EnrollmentApplicationParent model.
 */
export function FirstName({ formState, ...props }: FieldProps<"firstName">) {
	return <TextField formState={formState} name="firstName" {...props} />;
}

/**
 * Renders a field component for the `lastName` field of the EnrollmentApplicationParent model.
 */
export function LastName({ formState, ...props }: FieldProps<"lastName">) {
	return <TextField formState={formState} name="lastName" {...props} />;
}

/**
 * Renders a field component for the `nationality` field of the EnrollmentApplicationParent model.
 */
export function Nationality({ formState, ...props }: FieldProps<"nationality">) {
	return <TextField formState={formState} name="nationality" optional {...props} />;
}

/**
 * Renders a field component for the `nationalityList` field of the EnrollmentApplicationStudent model.
 */
export function NationalityList({
	formState,
	label = "Nationality",
	...props
}: FutureBaseFieldProps<{ nationalityList: string[] }>) {
	return (
		<MultiSelectField formState={formState} name="nationalityList" label={label} options={countryOptions} {...props} />
	);
}

/**
 * Renders a field component for the `occupation` field of the EnrollmentApplicationParent model.
 */
export function Occupation({ formState, ...props }: FieldProps<"occupation">) {
	return <TextField formState={formState} name="occupation" optional {...props} />;
}

/**
 * Renders a field component for the `relationshipType` field of the EnrollmentApplicationParent model.
 */
export function RelationshipType({ formState, ...props }: FieldProps<"relationshipType">) {
	return (
		<RadioField
			formState={formState}
			name="relationshipType"
			options={EnrollmentApplicationParentFormValues.relationshipTypeOptions}
			blankValue={null}
			Input={RadioButtonsInput}
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `relationshipTypeCustom` field of the EnrollmentApplicationParent model.
 */
export function RelationshipTypeCustom({
	formState,
	label = "Relationship Type (Other)",
	...props
}: FieldProps<"relationshipTypeCustom">) {
	return <TextField formState={formState} name="relationshipTypeCustom" label={label} optional {...props} />;
}

/**
 * Renders a field component for the `isPrimaryContact` field of the EnrollmentApplicationParent model.
 */
export function IsPrimaryContact({
	formState,
	label = "Primary Contact?",
	className,
	...props
}: FieldProps<"isPrimaryContact">) {
	return (
		<BooleanField
			formState={formState}
			name="isPrimaryContact"
			label={label}
			Input={IsPrimaryContactInput}
			noHeader
			display={IsPrimaryContactDisplay}
			className={makeClassName(styles["boolean-button"], className)}
			{...props}
		/>
	);
}

function IsPrimaryContactInput(props: BooleanFieldInputProps) {
	return (
		<BooleanButtonInput {...props}>
			<IsPrimaryContactDisplay />
		</BooleanButtonInput>
	);
}

function IsPrimaryContactDisplay() {
	return (
		<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
			<Icon.Star size="1.1rem" /> Primary Contact
		</Row>
	);
}

/**
 * Renders a field component for the `livesWithStudent` field of the EnrollmentApplicationParent model.
 */
export function LivesWithStudent({
	formState,
	label = "Lives with Student?",
	className,
	...props
}: FieldProps<"livesWithStudent">) {
	return (
		<BooleanField
			formState={formState}
			name="livesWithStudent"
			label={label}
			Input={LivesWithStudentInput}
			noHeader
			display={LivesWithStudentDisplay}
			className={makeClassName(styles["boolean-button"], className)}
			{...props}
		/>
	);
}

function LivesWithStudentInput(props: BooleanFieldInputProps) {
	return (
		<BooleanButtonInput {...props}>
			<LivesWithStudentDisplay />
		</BooleanButtonInput>
	);
}

function LivesWithStudentDisplay() {
	return (
		<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
			<Icon.Home size="1.1rem" /> Lives with Student
		</Row>
	);
}

/**
 * Renders a field component for the `isAuthorizedForPickup` field of the EnrollmentApplicationParent model.
 */
export function IsAuthorizedForPickup({
	formState,
	label = "Authorized for Pickup?",
	className,
	...props
}: FieldProps<"isAuthorizedForPickup">) {
	return (
		<BooleanField
			formState={formState}
			name="isAuthorizedForPickup"
			label={label}
			Input={IsAuthorizedForPickupInput}
			noHeader
			display={IsAuthorizedForPickupDisplay}
			className={makeClassName(styles["boolean-button"], className)}
			{...props}
		/>
	);
}

function IsAuthorizedForPickupInput(props: BooleanFieldInputProps) {
	return (
		<BooleanButtonInput {...props}>
			<IsAuthorizedForPickupDisplay />
		</BooleanButtonInput>
	);
}

function IsAuthorizedForPickupDisplay() {
	return (
		<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
			<Icon.Truck size="1.1rem" /> Authorized for Pickup
		</Row>
	);
}

/**
 * Renders a field component for the `isEmergencyContact` field of the EnrollmentApplicationParent model.
 */
export function IsEmergencyContact({
	formState,
	label = "Emergency Contact?",
	className,
	...props
}: FieldProps<"isEmergencyContact">) {
	return (
		<BooleanField
			formState={formState}
			name="isEmergencyContact"
			label={label}
			Input={IsEmergencyContactInput}
			noHeader
			display={IsEmergencyContactDisplay}
			className={makeClassName(styles["boolean-button"], className)}
			{...props}
		/>
	);
}

function IsEmergencyContactInput(props: BooleanFieldInputProps) {
	return (
		<BooleanButtonInput {...props}>
			<IsEmergencyContactDisplay />
		</BooleanButtonInput>
	);
}

function IsEmergencyContactDisplay() {
	return (
		<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
			<Icon.AlertTriangle size="1.1rem" /> Emergency Contact
		</Row>
	);
}

/**
 * Renders a field component for the `isFinancialContact` field of the EnrollmentApplicationParent model.
 */
export function IsFinancialContact({
	formState,
	label = "Financial Contact?",
	className,
	...props
}: FieldProps<"isFinancialContact">) {
	return (
		<BooleanField
			formState={formState}
			name="isFinancialContact"
			label={label}
			Input={IsFinancialContactInput}
			noHeader
			display={IsFinancialContactDisplay}
			className={makeClassName(styles["boolean-button"], className)}
			{...props}
		/>
	);
}

function IsFinancialContactInput(props: BooleanFieldInputProps) {
	return (
		<BooleanButtonInput {...props}>
			<IsFinancialContactDisplay />
		</BooleanButtonInput>
	);
}

function IsFinancialContactDisplay() {
	return (
		<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
			<Icon.DollarSign size="1.1rem" /> Financial Contact
		</Row>
	);
}

/**
 * Renders a field component for the `addressSameAsFormIdentifier` field of the EnrollmentApplicationParent model.
 */
export function AddressSameAsFormIdentifier({
	formState,
	label = "Autofill",
	...props
}: FieldProps<"addressSameAsFormIdentifier"> &
	Pick<
		RadioFieldProps<
			EnrollmentApplicationParentFormValues.Base,
			EnrollmentApplicationParentFormValues.Base["addressSameAsFormIdentifier"]
		>,
		"options"
	>) {
	return <RadioField formState={formState} name="addressSameAsFormIdentifier" label={label} optional {...props} />;
}

/**
 * Renders a field component for the `addressLine1` field of the EnrollmentApplicationParent model.
 */
export function AddressLine1({ formState, ...props }: FieldProps<"addressLine1">) {
	return <TextField formState={formState} name="addressLine1" {...props} />;
}

/**
 * Renders a field component for the `addressLine2` field of the EnrollmentApplicationParent model.
 */
export function AddressLine2({ formState, ...props }: FieldProps<"addressLine2">) {
	return <TextField formState={formState} name="addressLine2" optional {...props} />;
}

/**
 * Renders a field component for the `city` field of the EnrollmentApplicationParent model.
 */
export function City({ formState, ...props }: FieldProps<"city">) {
	return <TextField formState={formState} name="city" {...props} />;
}

/**
 * Renders a field component for the `stateOrProvince` field of the EnrollmentApplicationParent model.
 */
export function StateOrProvince({ formState, ...props }: FieldProps<"stateOrProvince">) {
	return <TextField formState={formState} name="stateOrProvince" optional {...props} />;
}

/**
 * Renders a field component for the `postalCode` field of the EnrollmentApplicationParent model.
 */
export function PostalCode({ formState, ...props }: FieldProps<"postalCode">) {
	return <TextField formState={formState} name="postalCode" {...props} />;
}

/**
 * Renders a field component for the `country` field of the EnrollmentApplicationParent model.
 */
export function Country({ formState, ...props }: FieldProps<"country">) {
	return <TextField formState={formState} name="country" {...props} />;
}

/**
 * Renders a field component for the `homePhone` field of the EnrollmentApplicationParent model.
 */
export function HomePhone({ formState, ...props }: FieldProps<"homePhone">) {
	return <TextField formState={formState} name="homePhone" optional Input={PhoneInput} {...props} />;
}

/**
 * Renders a field component for the `workPhone` field of the EnrollmentApplicationParent model.
 */
export function WorkPhone({ formState, ...props }: FieldProps<"workPhone">) {
	return <TextField formState={formState} name="workPhone" optional Input={PhoneInput} {...props} />;
}

/**
 * Renders a field component for the `mobilePhone` field of the EnrollmentApplicationParent model.
 */
export function MobilePhone({ formState, ...props }: FieldProps<"mobilePhone">) {
	return <TextField formState={formState} name="mobilePhone" optional Input={PhoneInput} {...props} />;
}

const phoneFields: ("homePhone" | "workPhone" | "mobilePhone")[] = ["homePhone", "workPhone", "mobilePhone"];

export function PhoneValidation({ formState }: FieldProps<"homePhone" | "workPhone" | "mobilePhone">) {
	const { validationStatus, validationErrors } = useFieldSetState({
		formState,
		names: phoneFields,
		validationOptions: { immediateValidation: validatePhoneFields },
	});

	if (ValidationStatus.isFailure(validationStatus)) {
		return (
			<Row
				justify="spaced-center"
				align="center"
				horizontalSpacing="0.5rem"
				className={styles["phone-validation--error"]}
			>
				<FieldHintIconFailure /> <span>{validationErrors.join(" ")}</span>
			</Row>
		);
	}

	return null;
}

function FieldHintIconFailure() {
	// TODO hex-core-update remove when exported from forms
	return (
		<div className={styles["field-hint"] + " " + styles["field-hint--failure"]}>
			<Icon.X strokeWidth="3" className={styles["field-hint__icon"]} />
		</div>
	);
}

function validatePhoneFields({
	homePhone,
	workPhone,
	mobilePhone,
}: Pick<EnrollmentApplicationParentFormValues.Base, "homePhone" | "workPhone" | "mobilePhone">) {
	if (homePhone === "" && workPhone === "" && mobilePhone === "") {
		return invalid("Please provide at least one phone number.");
	}
	return valid();
}

/**
 * Renders a field component for the `email` field of the EnrollmentApplicationParent model.
 */
export function Email({ formState, ...props }: FieldProps<"email">) {
	return <TextField formState={formState} name="email" {...props} immediateValidation={validateEmail} />;
}

export type EnrollmentApplicationSubmissionProps = FieldProps<"enrollmentApplicationSubmissionID"> & {
	currentEnrollmentApplicationSubmission?: EnrollmentApplicationSubmissionSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `enrollmentApplicationSubmission` edge of the EnrollmentApplicationParent model.
 */
export function EnrollmentApplicationSubmission({
	formState,
	currentEnrollmentApplicationSubmission,
	...props
}: EnrollmentApplicationSubmissionProps) {
	const [loadOptions, { loading, data }] = useEnrollmentApplicationSubmissionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.enrollmentApplicationSubmissionID) {
			loadOptions();
		}
	}, [formState.formEditing.enrollmentApplicationSubmissionID, loadOptions]);
	const options = React.useMemo(
		() =>
			EnrollmentApplicationSubmissionSelect.toOptions(
				data?.enrollmentApplicationSubmissionConnection.edges,
				currentEnrollmentApplicationSubmission,
			),
		[data, currentEnrollmentApplicationSubmission],
	);

	return (
		<SelectField
			formState={formState}
			name="enrollmentApplicationSubmissionID"
			isLoading={loading}
			options={options}
			display={displayEnrollmentApplicationSubmission}
			blankValue={null}
			{...props}
		/>
	);
}

function displayEnrollmentApplicationSubmission({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <EnrollmentApplicationSubmissionLink instance={{ id }}>{formattedValue}</EnrollmentApplicationSubmissionLink>;
}
