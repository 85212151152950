import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	graphTypeNames,
	Note,
	NoteReaction,
	NoteReactionCreateInput,
	NoteReactionCreateMutation,
	NoteReactionDeleteMutation,
	NoteReactionDetailDocument,
	NoteReactionDetailQuery,
	NoteReactionDetailQueryVariables,
	NoteReactionUpdateInput,
	NoteReactionUpdateMutation,
	useNoteReactionCreateMutation,
	useNoteReactionDeleteMutation,
	useNoteReactionUpdateMutation,
} from "../../GraphQL";
import { NoteReactionFormConversion } from "../ModelFormConversion";
import { NoteReactionFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the NoteReaction model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the NoteReaction model.
 */
export function useCreate() {
	const [createNoteReaction] = useNoteReactionCreateMutation();

	return React.useCallback(
		async (formValues: NoteReactionFormValues.Create) => {
			const input = NoteReactionFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createNoteReaction({ variables: { input }, update: updateCache });

			return { data: data?.createNoteReaction ?? null, errors: errors ?? null };
		},
		[createNoteReaction],
	);
}

/**
 * Returns an `update` function for the NoteReaction model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the NoteReaction model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: NoteReaction["id"]) {
	const [updateNoteReaction] = useNoteReactionUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<NoteReactionFormValues.Detail>,
			initialFormValues: NoteReactionFormValues.Detail,
		) => {
			const input = NoteReactionFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateNoteReaction({ variables: { id, input }, update: updateCache });

			return { data: data?.updateNoteReaction ?? null, errors: errors ?? null };
		},
		[updateNoteReaction, id],
	);
}

/**
 * Returns a `del` function for the NoteReaction model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: NoteReaction["id"]) {
	const [deleteNoteReaction] = useNoteReactionDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteNoteReaction({ variables: { id }, update: updateCache });

		return { data: data?.deleteNoteReaction ?? false, errors: errors ?? null };
	}, [deleteNoteReaction, id]);
}

function getUpdateCacheForCreate(input: NoteReactionCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<NoteReactionCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createNoteReaction;

		cache.writeQuery<NoteReactionDetailQuery, NoteReactionDetailQueryVariables>({
			query: NoteReactionDetailDocument,
			data: { noteReaction: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.noteID) {
			addToReactionsOfNoteCache(cache, input.noteID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "noteReactionConnection" });
	};
}

function getUpdateCacheForUpdate(_input: NoteReactionUpdateInput, _initialFormValues: NoteReactionFormValues.Detail) {
	return (
		_cache: ApolloCache<any>,
		result: Omit<FetchResult<NoteReactionUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}
	};
}

function getUpdateCacheForDelete(id: NoteReaction["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<NoteReactionDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteNoteReaction) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.NoteReaction }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "noteReactionConnection" });
		cache.gc();
	};
}

function addToReactionsOfNoteCache(cache: ApolloCache<any>, targetID: Note["id"], object: Pick<NoteReaction, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Note }),
		fields: {
			reactions: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<NoteReaction, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment NoteReactionRef on NoteReaction {
				id
			}
		`,
		data: object,
	});
}
