import { IndexForms } from "@hex-insights/app-modules";
import { UserGroupFilterFormValues } from "../../../../Utilities";

export type UserGroupSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the UserGroup model.
 */
export function UserGroupSearchForm(props: UserGroupSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type UserGroupFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, UserGroupFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the UserGroup model.
 */
export function UserGroupFilterFormController(props: UserGroupFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={UserGroupFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `UserGroupFilterForm`
