import { newPageInfo } from "@hex-insights/router";
import { contractHubPageInfo } from "../pageinfo";

export const contractHubCreatePageInfo = newPageInfo({
	name: "New Contract",
	title: "New",
	description: "Create a new Contract record.",
	exact: true,
	to: contractHubPageInfo.to + "/new",
	path: contractHubPageInfo.path + "/new",
	parent: contractHubPageInfo,
});
