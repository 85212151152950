import { LearningObjectiveMark, ReportCard, Student, Term } from "../../GraphQL";

export type Base = {
	issueDate: ReportCard["issueDate"] | null;
	learningObjectiveMarkIDs: LearningObjectiveMark["id"][];
	studentID: Student["id"] | null;
	termID: Term["id"] | null;
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	issueDate: null,
	learningObjectiveMarkIDs: [],
	studentID: null,
	termID: null,
};
