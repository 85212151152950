import { dateDiff, toGrammaticalNumber, toLocalDateString } from "@hex-insights/core";
import {
	EnrollmentApplicationStudent,
	EnrollmentApplicationStudentGender,
	EnrollmentApplicationStudentNameFragment,
} from "../../../GraphQL";
import * as Fields from "./fields";

export { Fields };

/**
 * Formats the full name of the given instance.
 */
export function name(instance: EnrollmentApplicationStudentNameFragment) {
	return instance.name;
}

/**
 * Formats the age of the given instance.
 */
export function age(instance: { dateOfBirth: EnrollmentApplicationStudent["dateOfBirth"] | null }) {
	if (instance.dateOfBirth === null) {
		return "";
	}
	return toGrammaticalNumber("year", dateDiff(toLocalDateString(new Date()), instance.dateOfBirth, "years"), true);
}

/**
 * Formats the gender (including custom gender) of the given instance.
 */
export function gender(instance: Pick<EnrollmentApplicationStudent, "gender" | "genderCustom">) {
	if (instance.gender === null) {
		return "";
	}
	if (instance.gender === EnrollmentApplicationStudentGender.Other) {
		return Fields.genderCustom(instance.genderCustom);
	}
	return Fields.gender(instance.gender);
}
