import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const userGroupHubPageInfo = newPageInfo({
	title: "User Groups",
	description: "Pages related to the User Group model.",
	exact: false,
	to: hubsPageInfo.to + "/user-groups",
	path: hubsPageInfo.path + "/user-groups",
	parent: hubsPageInfo,
});
