import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import {
	EnrollmentApplicationParentForm,
	useEnrollmentApplicationParentDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { enrollmentApplicationParentHubDetailPageInfo } from "./pageinfo";

export function EnrollmentApplicationParentHubDetailPage() {
	const { enrollmentApplicationParentID } = enrollmentApplicationParentHubDetailPageInfo.useParams();

	const { loading, data, error } = useEnrollmentApplicationParentDetailQuery({
		variables: { id: enrollmentApplicationParentID },
	});
	const enrollmentApplicationParent = data?.enrollmentApplicationParent;
	useActivePageRegistration(
		enrollmentApplicationParentHubDetailPageInfo,
		!!enrollmentApplicationParent
			? enrollmentApplicationParentHubDetailPageInfo.title(enrollmentApplicationParent)
			: "",
	);

	const { syncID, synchronize } = useSync();

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading enrollment application parent</If>
				<Else>
					{!!enrollmentApplicationParent && (
						<React.Fragment>
							<Heading.H1>{enrollmentApplicationParent.name}</Heading.H1>

							<EnrollmentApplicationParentForm.ControlledDetail
								key={syncID}
								enrollmentApplicationParent={enrollmentApplicationParent}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
