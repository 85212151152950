import React from "react";
import { onCreateSubmit } from "@hex-insights/app-modules";
import {
	Button,
	ClassNameProps,
	Column,
	Conditional,
	Drawer,
	DrawerProps,
	Else,
	Heading,
	Icon,
	If,
	makeClassName,
	Modal,
	ModalProps,
	RequiredKeys,
	Row,
	StyleProps,
	useToggle,
	useUpdateEffect,
} from "@hex-insights/core";
import { PermissionRequired } from "@hex-insights/permissioning";
import { ExternalLink } from "@hex-insights/router";
import {
	DocumentResource,
	DocumentResourceDeleteButton,
	DocumentResourceForm,
	DocumentResourceFormValues,
	DocumentResourceMutation,
	DocumentResourceUserViewRecordFormValues,
	DocumentResourceUserViewRecordMutation,
	permissions,
	useBookmarkDocumentResourceMutation,
	useDocumentResourceBookmarkCheckQuery,
	useDocumentResourceDetailQuery,
	useUnbookmarkDocumentResourceMutation,
} from "@hex-insights/verita.shared";
import { AuthenticationContext } from "../../../../Contexts";
import { getDocumentResourceOnDragStart } from "../../Utilities";
import styles from "./styles.module.css";

export type DocumentResourceDisplayProps = {
	documentResource: Pick<
		DocumentResource,
		"id" | "name" | "description" | "imageURL" | "isFile" | "fileURL" | "fileContentType" | "resourceURL"
	>;
	size?: "small" | "medium";
	draggable?: boolean;
} & Partial<ClassNameProps & StyleProps>;

export function DocumentResourceDisplay({
	documentResource,
	size = "medium",
	draggable = false,
}: DocumentResourceDisplayProps) {
	const { id, isFile, fileURL, fileContentType, resourceURL } = documentResource;

	const { isOn: isPreviewOpen, toggle: toggleIsPreviewOpen } = useToggle(false);

	const create = DocumentResourceUserViewRecordMutation.useCreate();
	const applyCreate = React.useCallback(
		async (formValues: DocumentResourceUserViewRecordFormValues.Create) => {
			const { errors } = await create(formValues);
			return errors;
		},
		[create],
	);
	React.useEffect(() => {
		if (isPreviewOpen) {
			onCreateSubmit({ documentResourceID: id }, applyCreate);
		}
	}, [isPreviewOpen, applyCreate, id]);

	const resourceType = getResourceType(documentResource);

	const onDragStart = React.useMemo(() => getDocumentResourceOnDragStart(documentResource), [documentResource]);

	return (
		<React.Fragment>
			<div
				draggable={draggable}
				onDragStart={draggable ? onDragStart : undefined}
				className={makeClassName(
					styles["document-resource-display"],
					styles[`document-resource-display--${size}`],
					styles[`document-resource-display--${resourceType}`],
				)}
			>
				<Button onClick={toggleIsPreviewOpen} className={styles["document-resource-display__button"]}>
					<Conditional>
						<If condition={size === "medium"}>
							<DocumentResourceDisplayMediumContent documentResource={documentResource} />
						</If>
						<If condition={size === "small"}>
							<DocumentResourceDisplaySmallContent documentResource={documentResource} />
						</If>
					</Conditional>
				</Button>
			</div>

			<Modal.If condition={isPreviewOpen}>
				<ResourcePreview
					id={id}
					url={isFile ? fileURL : resourceURL}
					resourceType={resourceType}
					fileContentType={fileContentType}
					onClose={toggleIsPreviewOpen}
				/>
			</Modal.If>
		</React.Fragment>
	);
}

type DocumentResourceDisplayMediumContentProps = {
	documentResource: Pick<
		DocumentResource,
		"name" | "description" | "imageURL" | "isFile" | "fileURL" | "fileContentType"
	>;
};

function DocumentResourceDisplayMediumContent({ documentResource }: DocumentResourceDisplayMediumContentProps) {
	const { name, description } = documentResource;
	const resourceType = getResourceType(documentResource);

	return (
		<Column
			justify="spaced-start"
			verticalSpacing="0.25rem"
			align="center"
			className={styles["document-resource-display__column"]}
		>
			<ImageContainer src={getImageURL(documentResource)} resourceType={resourceType} />
			<span
				title={name + (description !== "" ? "\n\n" + description : "")}
				className={styles["document-resource-display__name"]}
			>
				{name}
			</span>
		</Column>
	);
}

type DocumentResourceDisplaySmallContentProps = {
	documentResource: Pick<DocumentResource, "name" | "isFile" | "fileURL" | "imageURL" | "fileContentType">;
};

function DocumentResourceDisplaySmallContent({ documentResource }: DocumentResourceDisplaySmallContentProps) {
	const { name } = documentResource;
	const resourceType = getResourceType(documentResource);

	const imageURL = getImageURL(documentResource);

	return (
		<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center" style={{ height: "100%" }}>
			<Conditional>
				<If condition={imageURL !== ""}>
					<div style={{ width: "2rem", height: "2rem" }}>
						<img src={imageURL} alt="" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
					</div>
				</If>
				<Else>
					<DocumentResourceIcon resourceType={resourceType} size="1.25rem" style={{ flexShrink: 0 }} />
				</Else>
			</Conditional>
			<span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{name}</span>
		</Row>
	);
}

export type DocumentResourceDisplayLoadingProps = {
	size?: "small" | "medium";
} & Partial<ClassNameProps & StyleProps>;

DocumentResourceDisplay.Loading = function ({
	size = "medium",
	className,
	style,
}: DocumentResourceDisplayLoadingProps) {
	return (
		<div
			className={makeClassName(
				styles["document-resource-display"],
				styles[`document-resource-display--${size}`],
				styles["document-resource-display--loading"],
				className,
			)}
			style={style}
		>
			<div className={styles["document-resource-display__button"]}>
				<Conditional>
					<If condition={size === "medium"}>
						<DocumentResourceDisplayLoadingMediumContent />
					</If>
					<If condition={size === "small"}>
						<DocumentResourceDisplayLoadingSmallContent />
					</If>
				</Conditional>
			</div>
		</div>
	);
};

function DocumentResourceDisplayLoadingMediumContent() {
	return (
		<Column justify="spaced-start" verticalSpacing="0.25rem">
			<div className={styles["document-resource-display--loading__image"]}></div>
			<div className={styles["document-resource-display--loading__name"]}>Loading...</div>
		</Column>
	);
}

function DocumentResourceDisplayLoadingSmallContent() {
	return (
		<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
			<DocumentResourceIcon
				resourceType="file"
				size="1.25rem"
				className={styles["document-resource-display--loading__icon"]}
				style={{ flexShrink: 0 }}
			/>
			<span
				className={styles["document-resource-display--loading__name"]}
				style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
			>
				Loading...
			</span>
		</Row>
	);
}

function getImageURL(documentResource: Pick<DocumentResource, "imageURL" | "fileURL" | "fileContentType">) {
	if (documentResource.imageURL !== "") {
		return documentResource.imageURL;
	}
	if (documentResource.fileContentType.startsWith("image")) {
		return documentResource.fileURL;
	}
	return "";
}

type ResourceType = "resource" | "image" | "file";

function getResourceType(documentResource: Pick<DocumentResource, "isFile" | "fileContentType">): ResourceType {
	if (!documentResource.isFile) {
		return "resource";
	}
	if (documentResource.fileContentType.startsWith("image")) {
		return "image";
	}
	return "file";
}

type ResourcePreviewProps = {
	id: DocumentResource["id"];
	url: string;
	resourceType: ResourceType;
	fileContentType: string;
} & Pick<ModalProps, "ifRef" | "onClose">;

function ResourcePreview({ id, url, resourceType, fileContentType, ifRef, onClose }: ResourcePreviewProps) {
	const urlWithProtocol = /^((https?:)?\/\/|tel:|mailto:|blob:)/.test(url) ? url : "//" + url;

	const { user } = React.useContext(AuthenticationContext);
	const userID = user?.id ?? "";

	const { loading, data } = useDocumentResourceBookmarkCheckQuery({
		variables: { userID, documentResourceID: id },
		fetchPolicy: "cache-and-network",
	});
	const [isBookmarked, setIsBookmarked] = React.useState(false);
	React.useEffect(() => {
		setIsBookmarked((data?.documentResourceConnection.totalCount ?? 0) > 0);
	}, [data]);

	// TODO cache updating
	const [mutateBookmark, { loading: loadingBookmarkAction }] = useBookmarkDocumentResourceMutation();
	const [mutateUnbookmark, { loading: loadingUnbookmarkAction }] = useUnbookmarkDocumentResourceMutation();

	const onBookmarkClick = React.useCallback(() => {
		if (!userID) {
			return;
		}
		if (isBookmarked) {
			mutateUnbookmark({ variables: { documentResourceID: id, userID } });
			setIsBookmarked(false);
		} else {
			mutateBookmark({ variables: { documentResourceID: id, userID } });
			setIsBookmarked(true);
		}
	}, [id, userID, isBookmarked, mutateBookmark, mutateUnbookmark]);

	const { isOn: isEditModalOpen, toggle: toggleIsEditModalOpen } = useToggle(false);

	const random = React.useMemo(() => Math.random(), []);

	return (
		<Modal ifRef={ifRef} onClose={onClose} className={styles["resource-preview"]}>
			<Modal.Body style={{ height: "100%", paddingBottom: "0.25rem", overflow: "hidden" }}>
				<Conditional>
					<If condition={resourceType === "resource"}>
						<iframe src={urlWithProtocol} frameBorder="0" className={styles["resource-preview__display"]}></iframe>
					</If>
					<If condition={resourceType === "image"}>
						<ExternalLink href={urlWithProtocol}>
							{/* TODO image component */}
							<div style={{ width: "100%", height: "100%", margin: "0 auto" }}>
								<img
									src={urlWithProtocol}
									className={styles["resource-preview__display"]}
									style={{ width: "100%", height: "100%", objectFit: "contain" }}
								/>
							</div>
						</ExternalLink>
					</If>
					<If condition={resourceType === "file"}>
						<embed
							src={urlWithProtocol + "?" + random} // query string for Safari bug where is content missing on remount
							type={fileContentType}
							className={styles["resource-preview__display"]}
						/>
					</If>
				</Conditional>
			</Modal.Body>
			<Modal.Footer>
				<Row justify="space-between">
					<Button
						variant="tertiary"
						size="small"
						onClick={onBookmarkClick}
						disabled={loading || loadingBookmarkAction || loadingUnbookmarkAction}
					>
						<Row justify="spaced-start" horizontalSpacing="0.5rem" align="center">
							<Icon.Bookmark size="1rem" fill={isBookmarked ? "currentColor" : "transparent"} />{" "}
							{isBookmarked ? "Remove Bookmark" : "Bookmark"}
						</Row>
					</Button>

					{/* TODO allow author to edit */}
					<PermissionRequired requiredPermission={permissions.DocumentResource.Update.Admin}>
						<Button variant="link" size="small" onClick={toggleIsEditModalOpen}>
							<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
								<Icon.Settings size="1rem" /> Settings
							</Row>
						</Button>

						<Drawer.If condition={isEditModalOpen}>
							<EditDrawer documentResourceID={id} onClose={toggleIsEditModalOpen} />
						</Drawer.If>
					</PermissionRequired>

					<ExternalLink href={url}>
						<Row justify="spaced-start" horizontalSpacing="0.1rem" align="center">
							<span>View Externally</span> <Icon.ArrowUpRight size="1rem" />
						</Row>
					</ExternalLink>
				</Row>
			</Modal.Footer>
		</Modal>
	);
}

type EditDrawerProps = {
	documentResourceID: DocumentResource["id"];
} & RequiredKeys<Pick<DrawerProps, "ifRef" | "onClose">, "onClose">;

function EditDrawer({ documentResourceID, ifRef, onClose }: EditDrawerProps) {
	const { loading, data } = useDocumentResourceDetailQuery({ variables: { id: documentResourceID } });
	const documentResource = data?.documentResource;

	const update = DocumentResourceMutation.useUpdate(documentResourceID);
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<DocumentResourceFormValues.Detail>,
			initialFormValues: DocumentResourceFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Drawer
			ifRef={ifRef}
			onClose={onClose}
			style={{ width: "fit-content", minWidth: "calc(var(--general__field---width) + 3rem)" }}
		>
			<Drawer.Header>
				<Heading level={2} noMargin>
					{documentResource?.name ?? "Loading..."}
				</Heading>
			</Drawer.Header>
			<Drawer.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						{documentResource && (
							<DocumentResourceForm.ControlledDetail
								documentResource={documentResource}
								applyUpdate={applyUpdate}
								onSuccess={onClose}
							/>
						)}
					</Else>
				</Conditional>
			</Drawer.Body>

			<PermissionRequired requiredPermission={permissions.DocumentResource.Delete.Admin}>
				<Drawer.Footer>
					<Row justify="center" align="center">
						<DocumentResourceDeleteButton instanceID={documentResourceID} onSuccess={onClose} size="small" />
					</Row>
				</Drawer.Footer>
			</PermissionRequired>
		</Drawer>
	);
}

// TODO make single image container component (<Image>)
//  this one is the full fixed size one (as opposed to height-scaled or width-scaled)

type ImageContainerProps = {
	src: string;
	resourceType: ResourceType;
};

function ImageContainer({ src, resourceType }: ImageContainerProps) {
	const ref = React.useRef<HTMLImageElement | null>(null);

	const [hasImageError, setHasImageError] = React.useState(false);
	useUpdateEffect(() => {
		setHasImageError(false);
	}, [src]);

	const hasImage = src !== "" && !hasImageError;

	return (
		<div className={styles["image-container"]}>
			<Conditional>
				<If condition={hasImage}>
					<img
						ref={ref}
						src={src}
						onError={() => setHasImageError(true)}
						style={{ borderRadius: "0.3rem", pointerEvents: "none" }}
					/>
				</If>
				<Else>
					<Column justify="center" align="center" className={styles["image-placeholder"]}>
						<DocumentResourceIcon resourceType={resourceType} size="3rem" />
					</Column>
				</Else>
			</Conditional>
		</div>
	);
}

type DocumentResourceIconProps = {
	resourceType: ResourceType;
} & Icon.IconProps;

function DocumentResourceIcon({ resourceType, ...props }: DocumentResourceIconProps) {
	if (resourceType === "resource") {
		return <Icon.Link {...props} />;
	}
	if (resourceType === "image") {
		return <Icon.Image {...props} />;
	}
	if (resourceType === "file") {
		return <Icon.FileText {...props} />;
	}
	return null;
}
