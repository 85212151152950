// Code generated by @hex-insights/frontend-generator; DO NOT EDIT.
import { academicYearHubDetailPageInfo } from "./Pages/Hubs/AcademicYear/DetailPage/pageinfo";
import { academicYearTuitionHubDetailPageInfo } from "./Pages/Hubs/AcademicYearTuition/DetailPage/pageinfo";
import { addressHubDetailPageInfo } from "./Pages/Hubs/Address/DetailPage/pageinfo";
import { billingSetupSubmissionHubDetailPageInfo } from "./Pages/Hubs/BillingSetupSubmission/DetailPage/pageinfo";
import { busHubDetailPageInfo } from "./Pages/Hubs/Bus/DetailPage/pageinfo";
import { busPlanHubDetailPageInfo } from "./Pages/Hubs/BusPlan/DetailPage/pageinfo";
import { calendarEventHubDetailPageInfo } from "./Pages/Hubs/CalendarEvent/DetailPage/pageinfo";
import { campusHubDetailPageInfo } from "./Pages/Hubs/Campus/DetailPage/pageinfo";
import { classYearHubDetailPageInfo } from "./Pages/Hubs/ClassYear/DetailPage/pageinfo";
import { contractHubDetailPageInfo } from "./Pages/Hubs/Contract/DetailPage/pageinfo";
import { contractInstallmentHubDetailPageInfo } from "./Pages/Hubs/ContractInstallment/DetailPage/pageinfo";
import { courseHubDetailPageInfo } from "./Pages/Hubs/Course/DetailPage/pageinfo";
import { courseAttendanceRecordHubDetailPageInfo } from "./Pages/Hubs/CourseAttendanceRecord/DetailPage/pageinfo";
import { courseSectionHubDetailPageInfo } from "./Pages/Hubs/CourseSection/DetailPage/pageinfo";
import { courseSectionPeriodHubDetailPageInfo } from "./Pages/Hubs/CourseSectionPeriod/DetailPage/pageinfo";
import { discountHubDetailPageInfo } from "./Pages/Hubs/Discount/DetailPage/pageinfo";
import { documentResourceHubDetailPageInfo } from "./Pages/Hubs/DocumentResource/DetailPage/pageinfo";
import { documentResourceFolderHubDetailPageInfo } from "./Pages/Hubs/DocumentResourceFolder/DetailPage/pageinfo";
import { documentResourceUserViewRecordHubDetailPageInfo } from "./Pages/Hubs/DocumentResourceUserViewRecord/DetailPage/pageinfo";
import { emailAddressHubDetailPageInfo } from "./Pages/Hubs/EmailAddress/DetailPage/pageinfo";
import { employeeHubDetailPageInfo } from "./Pages/Hubs/Employee/DetailPage/pageinfo";
import { enrollmentApplicationHubDetailPageInfo } from "./Pages/Hubs/EnrollmentApplication/DetailPage/pageinfo";
import { enrollmentApplicationNoteHubDetailPageInfo } from "./Pages/Hubs/EnrollmentApplicationNote/DetailPage/pageinfo";
import { enrollmentApplicationParentHubDetailPageInfo } from "./Pages/Hubs/EnrollmentApplicationParent/DetailPage/pageinfo";
import { enrollmentApplicationStudentHubDetailPageInfo } from "./Pages/Hubs/EnrollmentApplicationStudent/DetailPage/pageinfo";
import { enrollmentApplicationSubmissionHubDetailPageInfo } from "./Pages/Hubs/EnrollmentApplicationSubmission/DetailPage/pageinfo";
import { enrollmentApplicationUserViewRecordHubDetailPageInfo } from "./Pages/Hubs/EnrollmentApplicationUserViewRecord/DetailPage/pageinfo";
import { enrollmentInvitationHubDetailPageInfo } from "./Pages/Hubs/EnrollmentInvitation/DetailPage/pageinfo";
import { expenseHubDetailPageInfo } from "./Pages/Hubs/Expense/DetailPage/pageinfo";
import { expenseBudgetHubDetailPageInfo } from "./Pages/Hubs/ExpenseBudget/DetailPage/pageinfo";
import { expenseCategoryHubDetailPageInfo } from "./Pages/Hubs/ExpenseCategory/DetailPage/pageinfo";
import { homeRoomHubDetailPageInfo } from "./Pages/Hubs/HomeRoom/DetailPage/pageinfo";
import { homeRoomSectionHubDetailPageInfo } from "./Pages/Hubs/HomeRoomSection/DetailPage/pageinfo";
import { invoiceHubDetailPageInfo } from "./Pages/Hubs/Invoice/DetailPage/pageinfo";
import { invoiceLineItemHubDetailPageInfo } from "./Pages/Hubs/InvoiceLineItem/DetailPage/pageinfo";
import { learningObjectiveHubDetailPageInfo } from "./Pages/Hubs/LearningObjective/DetailPage/pageinfo";
import { learningObjectiveMarkHubDetailPageInfo } from "./Pages/Hubs/LearningObjectiveMark/DetailPage/pageinfo";
import { lessonPlanHubDetailPageInfo } from "./Pages/Hubs/LessonPlan/DetailPage/pageinfo";
import { loginRecordHubDetailPageInfo } from "./Pages/Hubs/LoginRecord/DetailPage/pageinfo";
import { mealMenuHubDetailPageInfo } from "./Pages/Hubs/MealMenu/DetailPage/pageinfo";
import { mealPlanHubDetailPageInfo } from "./Pages/Hubs/MealPlan/DetailPage/pageinfo";
import { mfaFactorHubDetailPageInfo } from "./Pages/Hubs/MFAFactor/DetailPage/pageinfo";
import { mfaInvalidOTPAttemptHubDetailPageInfo } from "./Pages/Hubs/MFAInvalidOTPAttempt/DetailPage/pageinfo";
import { noteHubDetailPageInfo } from "./Pages/Hubs/Note/DetailPage/pageinfo";
import { noteAttachmentHubDetailPageInfo } from "./Pages/Hubs/NoteAttachment/DetailPage/pageinfo";
import { noteCommentHubDetailPageInfo } from "./Pages/Hubs/NoteComment/DetailPage/pageinfo";
import { noteReactionHubDetailPageInfo } from "./Pages/Hubs/NoteReaction/DetailPage/pageinfo";
import { parentHubDetailPageInfo } from "./Pages/Hubs/Parent/DetailPage/pageinfo";
import { paymentHubDetailPageInfo } from "./Pages/Hubs/Payment/DetailPage/pageinfo";
import { paymentLineItemHubDetailPageInfo } from "./Pages/Hubs/PaymentLineItem/DetailPage/pageinfo";
import { permissionHubDetailPageInfo } from "./Pages/Hubs/Permission/DetailPage/pageinfo";
import { permissionGroupHubDetailPageInfo } from "./Pages/Hubs/PermissionGroup/DetailPage/pageinfo";
import { personHubDetailPageInfo } from "./Pages/Hubs/Person/DetailPage/pageinfo";
import { phoneNumberHubDetailPageInfo } from "./Pages/Hubs/PhoneNumber/DetailPage/pageinfo";
import { quotationHubDetailPageInfo } from "./Pages/Hubs/Quotation/DetailPage/pageinfo";
import { relationshipHubDetailPageInfo } from "./Pages/Hubs/Relationship/DetailPage/pageinfo";
import { reportCardHubDetailPageInfo } from "./Pages/Hubs/ReportCard/DetailPage/pageinfo";
import { roomHubDetailPageInfo } from "./Pages/Hubs/Room/DetailPage/pageinfo";
import { schoolAttendanceRecordHubDetailPageInfo } from "./Pages/Hubs/SchoolAttendanceRecord/DetailPage/pageinfo";
import { schoolStudentEnrollmentHubDetailPageInfo } from "./Pages/Hubs/SchoolStudentEnrollment/DetailPage/pageinfo";
import { serviceHubDetailPageInfo } from "./Pages/Hubs/Service/DetailPage/pageinfo";
import { serviceSubscriptionHubDetailPageInfo } from "./Pages/Hubs/ServiceSubscription/DetailPage/pageinfo";
import { sessionHubDetailPageInfo } from "./Pages/Hubs/Session/DetailPage/pageinfo";
import { studentHubDetailPageInfo } from "./Pages/Hubs/Student/DetailPage/pageinfo";
import { studentDocumentHubDetailPageInfo } from "./Pages/Hubs/StudentDocument/DetailPage/pageinfo";
import { termHubDetailPageInfo } from "./Pages/Hubs/Term/DetailPage/pageinfo";
import { trustedDeviceHubDetailPageInfo } from "./Pages/Hubs/TrustedDevice/DetailPage/pageinfo";
import { tuitionHubDetailPageInfo } from "./Pages/Hubs/Tuition/DetailPage/pageinfo";
import { userHubDetailPageInfo } from "./Pages/Hubs/User/DetailPage/pageinfo";
import { userGroupHubDetailPageInfo } from "./Pages/Hubs/UserGroup/DetailPage/pageinfo";

export const hubDetailPages = {
	AcademicYear: academicYearHubDetailPageInfo,
	AcademicYearTuition: academicYearTuitionHubDetailPageInfo,
	Address: addressHubDetailPageInfo,
	BillingSetupSubmission: billingSetupSubmissionHubDetailPageInfo,
	Bus: busHubDetailPageInfo,
	BusPlan: busPlanHubDetailPageInfo,
	CalendarEvent: calendarEventHubDetailPageInfo,
	Campus: campusHubDetailPageInfo,
	ClassYear: classYearHubDetailPageInfo,
	Contract: contractHubDetailPageInfo,
	ContractInstallment: contractInstallmentHubDetailPageInfo,
	Course: courseHubDetailPageInfo,
	CourseAttendanceRecord: courseAttendanceRecordHubDetailPageInfo,
	CourseSection: courseSectionHubDetailPageInfo,
	CourseSectionPeriod: courseSectionPeriodHubDetailPageInfo,
	Discount: discountHubDetailPageInfo,
	DocumentResource: documentResourceHubDetailPageInfo,
	DocumentResourceFolder: documentResourceFolderHubDetailPageInfo,
	DocumentResourceUserViewRecord: documentResourceUserViewRecordHubDetailPageInfo,
	EmailAddress: emailAddressHubDetailPageInfo,
	Employee: employeeHubDetailPageInfo,
	EnrollmentApplication: enrollmentApplicationHubDetailPageInfo,
	EnrollmentApplicationNote: enrollmentApplicationNoteHubDetailPageInfo,
	EnrollmentApplicationParent: enrollmentApplicationParentHubDetailPageInfo,
	EnrollmentApplicationStudent: enrollmentApplicationStudentHubDetailPageInfo,
	EnrollmentApplicationSubmission: enrollmentApplicationSubmissionHubDetailPageInfo,
	EnrollmentApplicationUserViewRecord: enrollmentApplicationUserViewRecordHubDetailPageInfo,
	EnrollmentInvitation: enrollmentInvitationHubDetailPageInfo,
	Expense: expenseHubDetailPageInfo,
	ExpenseBudget: expenseBudgetHubDetailPageInfo,
	ExpenseCategory: expenseCategoryHubDetailPageInfo,
	HomeRoom: homeRoomHubDetailPageInfo,
	HomeRoomSection: homeRoomSectionHubDetailPageInfo,
	Invoice: invoiceHubDetailPageInfo,
	InvoiceLineItem: invoiceLineItemHubDetailPageInfo,
	LearningObjective: learningObjectiveHubDetailPageInfo,
	LearningObjectiveMark: learningObjectiveMarkHubDetailPageInfo,
	LessonPlan: lessonPlanHubDetailPageInfo,
	LoginRecord: loginRecordHubDetailPageInfo,
	MFAFactor: mfaFactorHubDetailPageInfo,
	MFAInvalidOTPAttempt: mfaInvalidOTPAttemptHubDetailPageInfo,
	MealMenu: mealMenuHubDetailPageInfo,
	MealPlan: mealPlanHubDetailPageInfo,
	Note: noteHubDetailPageInfo,
	NoteAttachment: noteAttachmentHubDetailPageInfo,
	NoteComment: noteCommentHubDetailPageInfo,
	NoteReaction: noteReactionHubDetailPageInfo,
	Parent: parentHubDetailPageInfo,
	Payment: paymentHubDetailPageInfo,
	PaymentLineItem: paymentLineItemHubDetailPageInfo,
	Permission: permissionHubDetailPageInfo,
	PermissionGroup: permissionGroupHubDetailPageInfo,
	Person: personHubDetailPageInfo,
	PhoneNumber: phoneNumberHubDetailPageInfo,
	Quotation: quotationHubDetailPageInfo,
	Relationship: relationshipHubDetailPageInfo,
	ReportCard: reportCardHubDetailPageInfo,
	Room: roomHubDetailPageInfo,
	SchoolAttendanceRecord: schoolAttendanceRecordHubDetailPageInfo,
	SchoolStudentEnrollment: schoolStudentEnrollmentHubDetailPageInfo,
	Service: serviceHubDetailPageInfo,
	ServiceSubscription: serviceSubscriptionHubDetailPageInfo,
	Session: sessionHubDetailPageInfo,
	Student: studentHubDetailPageInfo,
	StudentDocument: studentDocumentHubDetailPageInfo,
	Term: termHubDetailPageInfo,
	TrustedDevice: trustedDeviceHubDetailPageInfo,
	Tuition: tuitionHubDetailPageInfo,
	User: userHubDetailPageInfo,
	UserGroup: userGroupHubDetailPageInfo,
};
