import { newPageInfo } from "@hex-insights/router";
import { invoiceHubPageInfo } from "../pageinfo";

export const invoiceHubIndexPageInfo = newPageInfo({
	name: "Invoices Index",
	title: "",
	description: "Index of Invoices.",
	exact: true,
	to: invoiceHubPageInfo.to,
	path: invoiceHubPageInfo.path,
	parent: invoiceHubPageInfo,
});
