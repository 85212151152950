import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const personHubPageInfo = newPageInfo({
	title: "People",
	description: "Pages related to the Person model.",
	exact: false,
	to: hubsPageInfo.to + "/people",
	path: hubsPageInfo.path + "/people",
	parent: hubsPageInfo,
});
