import { newPageInfo } from "@hex-insights/router";
import { contractHubPageInfo } from "../pageinfo";

export const contractHubIndexPageInfo = newPageInfo({
	name: "Contracts Index",
	title: "",
	description: "Index of Contracts.",
	exact: true,
	to: contractHubPageInfo.to,
	path: contractHubPageInfo.path,
	parent: contractHubPageInfo,
});
