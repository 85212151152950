import { newPageInfo } from "@hex-insights/router";
import { noteReactionHubPageInfo } from "../pageinfo";

export const noteReactionHubIndexPageInfo = newPageInfo({
	name: "Note Reactions Index",
	title: "",
	description: "Index of Note Reactions.",
	exact: true,
	to: noteReactionHubPageInfo.to,
	path: noteReactionHubPageInfo.path,
	parent: noteReactionHubPageInfo,
});
