import React from "react";
import { Column } from "@hex-insights/core";
import { FormState, FormType } from "@hex-insights/forms";
import {
	TrustedDeviceDetailQuery,
	TrustedDeviceFormConversion,
	TrustedDeviceFormState,
	TrustedDeviceFormValues,
} from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledReadOnlyProps = {
	trustedDevice: TrustedDeviceDetailQuery["trustedDevice"];
};

/**
 * Renders a read-only detail form of the TrustedDevice model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => TrustedDeviceFormConversion.toFormValues(props.trustedDevice),
		[props.trustedDevice],
	);
	const formState = TrustedDeviceFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<TrustedDeviceFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the TrustedDevice model using the given form state.
 */
export function ReadOnly({ formState, trustedDevice }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.CreatedAt formState={formState} formType={FormType.Update} id={trustedDevice.id} />
			<Field.Name formState={formState} formType={FormType.Update} id={trustedDevice.id} />
			<Field.IsCurrent formState={formState} formType={FormType.Update} id={trustedDevice.id} />
			<Field.Sessions
				formState={formState}
				formType={FormType.Update}
				id={trustedDevice.id}
				currentSessions={trustedDevice.sessions}
			/>
			<Field.User
				formState={formState}
				formType={FormType.Update}
				id={trustedDevice.id}
				currentUser={trustedDevice.user}
			/>
		</Column>
	);
}
