import { IndexForms } from "@hex-insights/app-modules";
import { NoteFilterFormValues } from "../../../../Utilities";

export type NoteSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the Note model.
 */
export function NoteSearchForm(props: NoteSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type NoteFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, NoteFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Note model.
 */
export function NoteFilterFormController(props: NoteFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={NoteFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `NoteFilterForm`
