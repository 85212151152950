import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import {
	EnrollmentApplicationParentFilterInput,
	EnrollmentApplicationParentOrder,
	useEnrollmentApplicationParentPaginationQuery,
} from "../../../../Utilities";

export type EnrollmentApplicationParentPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: EnrollmentApplicationParentFilterInput | EnrollmentApplicationParentFilterInput[] | null;
	order: EnrollmentApplicationParentOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the EnrollmentApplicationParent model.
 */
export function EnrollmentApplicationParentPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: EnrollmentApplicationParentPaginationFormProps) {
	const { loading, data } = useEnrollmentApplicationParentPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.enrollmentApplicationParentConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
