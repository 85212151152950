import React from "react";
import { EnrollmentApplication, useRecordEnrollmentApplicationListViewMutation } from "../../GraphQL";

export function useViewRecorder(edges: { node: { id: EnrollmentApplication["id"] } }[] | undefined) {
	const [recordListView] = useRecordEnrollmentApplicationListViewMutation();
	React.useEffect(() => {
		if (!edges) {
			return;
		}
		const ids = edges.map((e) => e.node.id);
		recordListView({ variables: { ids } });
	}, [edges, recordListView]);
}
