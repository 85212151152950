import { Quotation } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the body field of the Quotation model.
 */
export function body(value: Quotation["body"]): string {
	return toString(value);
}

/**
 * Formats the attribution field of the Quotation model.
 */
export function attribution(value: Quotation["attribution"]): string {
	return toString(value);
}
