import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import {
	CourseSectionPeriodFilterInput,
	CourseSectionPeriodOrder,
	useCourseSectionPeriodPaginationQuery,
} from "../../../../Utilities";

export type CourseSectionPeriodPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: CourseSectionPeriodFilterInput | CourseSectionPeriodFilterInput[] | null;
	order: CourseSectionPeriodOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the CourseSectionPeriod model.
 */
export function CourseSectionPeriodPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: CourseSectionPeriodPaginationFormProps) {
	const { loading, data } = useCourseSectionPeriodPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.courseSectionPeriodConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
