import { CourseSection, CourseSectionPeriod, Room } from "../../GraphQL";

export type Base = {
	dayOfWeek: CourseSectionPeriod["dayOfWeek"] | null;
	startTimeMinutes: CourseSectionPeriod["startTimeMinutes"] | null;
	endTimeMinutes: CourseSectionPeriod["endTimeMinutes"] | null;
	courseSectionID: CourseSection["id"] | null;
	roomID: Room["id"] | null;
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	dayOfWeek: null,
	startTimeMinutes: null,
	endTimeMinutes: null,
	courseSectionID: null,
	roomID: null,
};

export const dayOfWeekOptions = [
	{ value: 1, label: "Monday" },
	{ value: 2, label: "Tuesday" },
	{ value: 3, label: "Wednesday" },
	{ value: 4, label: "Thursday" },
	{ value: 5, label: "Friday" },
];
