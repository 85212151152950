import { newPageInfo } from "@hex-insights/router";
import { resourcesPageInfo, ResourcesPageRouteParams } from "../pageinfo";

export type ResourcesRecentsPageRouteParams = ResourcesPageRouteParams;

export const resourcesRecentsPageInfo = newPageInfo({
	name: "Recently Viewed Resources",
	title: "Recents",
	description: "Recently viewed resources.",
	exact: true,
	to: resourcesPageInfo.to + "/recents",
	path: resourcesPageInfo.path + "/recents",
	parent: resourcesPageInfo,
});
