import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { ServiceFilterInput } from "../../GraphQL";
import { ServiceFilterFormValues } from "../ModelFilterFormValues";

/**
 * Counts the number of active filters for the Service model.
 *
 * @param formValues The form values of the filter form.
 */
export function countActiveFilters(formValues: ServiceFilterFormValues.FormValues) {
	// TODO count quick filters
	const numAdvancedFilters = IndexForms.countActiveAdvancedFilters(
		formValues,
		ServiceFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	);
	return numAdvancedFilters;
}

/**
 * Clears the values of the filter form for the Service model.
 *
 * @param formState The form state of the filter form.
 */
export function clearFilters(formState: FormState<ServiceFilterFormValues.FormValues>) {
	// TODO clear quick filters
	formState.formClearFunctions.filterSets();
}

/**
 * Resets the values of the filter form for the Service model.
 *
 * @param formState The form state of the filter form.
 */
export function resetFilters(formState: FormState<ServiceFilterFormValues.FormValues>) {
	// TODO reset quick filters
	formState.formResetFunctions.filterSets();
}

/**
 * Converts the form values of the filter form for the Service model to the correct GraphQL
 * filter input type.
 *
 * @param formValues The form values of the filter form.
 */
export function toFilterInputs(
	formValues: ServiceFilterFormValues.FormValues,
): ServiceFilterInput | ServiceFilterInput[] {
	return IndexForms.toFilterInput(
		formValues,
		ServiceFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	) as ServiceFilterInput;
}
