import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	Expense,
	ExpenseBudget,
	ExpenseCategory,
	ExpenseCategoryCreateInput,
	ExpenseCategoryCreateMutation,
	ExpenseCategoryDeleteMutation,
	ExpenseCategoryDetailDocument,
	ExpenseCategoryDetailQuery,
	ExpenseCategoryDetailQueryVariables,
	ExpenseCategoryUpdateInput,
	ExpenseCategoryUpdateMutation,
	graphTypeNames,
	useExpenseCategoryCreateMutation,
	useExpenseCategoryDeleteMutation,
	useExpenseCategoryUpdateMutation,
} from "../../GraphQL";
import { ExpenseCategoryFormConversion } from "../ModelFormConversion";
import { ExpenseCategoryFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the ExpenseCategory model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the ExpenseCategory model.
 */
export function useCreate() {
	const [createExpenseCategory] = useExpenseCategoryCreateMutation();

	return React.useCallback(
		async (formValues: ExpenseCategoryFormValues.Create) => {
			const input = ExpenseCategoryFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createExpenseCategory({ variables: { input }, update: updateCache });

			return { data: data?.createExpenseCategory ?? null, errors: errors ?? null };
		},
		[createExpenseCategory],
	);
}

/**
 * Returns an `update` function for the ExpenseCategory model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the ExpenseCategory model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: ExpenseCategory["id"]) {
	const [updateExpenseCategory] = useExpenseCategoryUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<ExpenseCategoryFormValues.Detail>,
			initialFormValues: ExpenseCategoryFormValues.Detail,
		) => {
			const input = ExpenseCategoryFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateExpenseCategory({ variables: { id, input }, update: updateCache });

			return { data: data?.updateExpenseCategory ?? null, errors: errors ?? null };
		},
		[updateExpenseCategory, id],
	);
}

/**
 * Returns a `del` function for the ExpenseCategory model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: ExpenseCategory["id"]) {
	const [deleteExpenseCategory] = useExpenseCategoryDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteExpenseCategory({ variables: { id }, update: updateCache });

		return { data: data?.deleteExpenseCategory ?? false, errors: errors ?? null };
	}, [deleteExpenseCategory, id]);
}

function getUpdateCacheForCreate(input: ExpenseCategoryCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ExpenseCategoryCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createExpenseCategory;

		cache.writeQuery<ExpenseCategoryDetailQuery, ExpenseCategoryDetailQueryVariables>({
			query: ExpenseCategoryDetailDocument,
			data: { expenseCategory: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.expenseBudgetIDs) {
			for (let i = 0; i < input.expenseBudgetIDs.length; i++) {
				addToExpenseCategoryOfExpenseBudgetCache(cache, input.expenseBudgetIDs[i], createdObject);
			}
		}

		if (input.expenseIDs) {
			for (let i = 0; i < input.expenseIDs.length; i++) {
				addToExpenseCategoryOfExpenseCache(cache, input.expenseIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "expenseCategoryConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: ExpenseCategoryUpdateInput,
	_initialFormValues: ExpenseCategoryFormValues.Detail,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ExpenseCategoryUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateExpenseCategory;

		if (input.addExpenseBudgetIDs) {
			for (let i = 0; i < input.addExpenseBudgetIDs.length; i++) {
				addToExpenseCategoryOfExpenseBudgetCache(cache, input.addExpenseBudgetIDs[i], updatedObject);
			}
		}
		if (input.removeExpenseBudgetIDs) {
			for (let i = 0; i < input.removeExpenseBudgetIDs.length; i++) {
				removeFromExpenseCategoryOfExpenseBudgetCache(cache, input.removeExpenseBudgetIDs[i], updatedObject);
			}
		}

		if (input.addExpenseIDs) {
			for (let i = 0; i < input.addExpenseIDs.length; i++) {
				addToExpenseCategoryOfExpenseCache(cache, input.addExpenseIDs[i], updatedObject);
			}
		}
		if (input.removeExpenseIDs) {
			for (let i = 0; i < input.removeExpenseIDs.length; i++) {
				removeFromExpenseCategoryOfExpenseCache(cache, input.removeExpenseIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: ExpenseCategory["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ExpenseCategoryDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteExpenseCategory) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.ExpenseCategory }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "expenseCategoryConnection" });
		cache.gc();
	};
}

function addToExpenseCategoryOfExpenseBudgetCache(
	cache: ApolloCache<any>,
	targetID: ExpenseBudget["id"],
	object: Pick<ExpenseCategory, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ExpenseBudget }),
		fields: {
			expenseCategory: () => objectRef,
			expenseCategoryID: () => object.id,
		},
	});
}

function removeFromExpenseCategoryOfExpenseBudgetCache(
	cache: ApolloCache<any>,
	targetID: ExpenseBudget["id"],
	_object: Pick<ExpenseCategory, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ExpenseBudget }),
		fields: {
			expenseCategory: () => null,
			expenseCategoryID: () => null,
		},
	});
}

function addToExpenseCategoryOfExpenseCache(
	cache: ApolloCache<any>,
	targetID: Expense["id"],
	object: Pick<ExpenseCategory, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Expense }),
		fields: {
			expenseCategory: () => objectRef,
			expenseCategoryID: () => object.id,
		},
	});
}

function removeFromExpenseCategoryOfExpenseCache(
	cache: ApolloCache<any>,
	targetID: Expense["id"],
	_object: Pick<ExpenseCategory, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Expense }),
		fields: {
			expenseCategory: () => null,
			expenseCategoryID: () => null,
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<ExpenseCategory, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment ExpenseCategoryRef on ExpenseCategory {
				id
			}
		`,
		data: object,
	});
}
