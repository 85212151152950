import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	ExpenseCategoryForm,
	ExpenseCategoryFormValues,
	ExpenseCategoryMutation,
	useExpenseCategoryDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { expenseCategoryHubDetailPageInfo } from "./pageinfo";

function ExpenseCategoryHubDetailPageWrapper() {
	// Manages identity of the ExpenseCategoryHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <ExpenseCategoryHubDetailPage key={id} />;
}

export { ExpenseCategoryHubDetailPageWrapper as ExpenseCategoryHubDetailPage };

function ExpenseCategoryHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useExpenseCategoryDetailQuery({ variables: { id } });
	const expenseCategory = data?.expenseCategory;
	useActivePageRegistration(
		expenseCategoryHubDetailPageInfo,
		!!expenseCategory ? expenseCategoryHubDetailPageInfo.title(expenseCategory) : "",
	);

	const update = ExpenseCategoryMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<ExpenseCategoryFormValues.Detail>,
			initialFormValues: ExpenseCategoryFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading expenseCategory</If>
				<Else>
					{!!expenseCategory && (
						<React.Fragment>
							<Heading level={1}>{expenseCategory.name}</Heading>

							<ExpenseCategoryForm.ControlledDetail
								key={syncID}
								expenseCategory={expenseCategory}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
