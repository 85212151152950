import { newPageInfo } from "@hex-insights/router";
import { addressHubPageInfo } from "../pageinfo";

export const addressHubIndexPageInfo = newPageInfo({
	name: "Addresses Index",
	title: "",
	description: "Index of Addresses.",
	exact: true,
	to: addressHubPageInfo.to,
	path: addressHubPageInfo.path,
	parent: addressHubPageInfo,
});
