import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { MealMenuHubBulkCreatePage } from "./BulkCreatePage";
import { mealMenuHubBulkCreatePageInfo } from "./BulkCreatePage/pageinfo";
import { MealMenuHubCreatePage } from "./CreatePage";
import { mealMenuHubCreatePageInfo } from "./CreatePage/pageinfo";
import { MealMenuHubDetailPage } from "./DetailPage";
import { mealMenuHubDetailPageInfo } from "./DetailPage/pageinfo";
import { MealMenuHubIndexPage } from "./IndexPage";
import { mealMenuHubIndexPageInfo } from "./IndexPage/pageinfo";
import { mealMenuHubPageInfo } from "./pageinfo";

export function MealMenuHub() {
	useActivePageRegistration(mealMenuHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...mealMenuHubIndexPageInfo.routeProps}>
				<MealMenuHubIndexPage />
			</Route>
			<Route {...mealMenuHubCreatePageInfo.routeProps}>
				<MealMenuHubCreatePage />
			</Route>
			<Route {...mealMenuHubBulkCreatePageInfo.routeProps}>
				<MealMenuHubBulkCreatePage />
			</Route>
			<Route {...mealMenuHubDetailPageInfo.routeProps}>
				<MealMenuHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
