import { DocumentResource, DocumentResourceFolder, User } from "../../GraphQL";

export type Base = {
	name: DocumentResource["name"];
	description: DocumentResource["description"];
	hasImage: DocumentResource["hasImage"];
	image: File | null;
	isFile: DocumentResource["isFile"];
	file: File | null;
	resourceURL: DocumentResource["resourceURL"];
	documentResourceFolderIDs: DocumentResourceFolder["id"][];
};

export type Create = Base;

export type Detail = Base & {
	createdAt: DocumentResource["createdAt"] | null;
	authorID: User["id"] | null;
	documentResourceFolderIDs: DocumentResourceFolder["id"][];
};

export const initialCreate: Create = {
	name: "",
	description: "",
	hasImage: false,
	image: null,
	isFile: true,
	file: null,
	resourceURL: "",
	documentResourceFolderIDs: [],
};
