import { Permission } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the name field of the Permission model.
 */
export function name(value: Permission["name"]): string {
	return toString(value);
}

/**
 * Formats the family field of the Permission model.
 */
export function family(value: Permission["family"]): string {
	return toString(value);
}

/**
 * Formats the action field of the Permission model.
 */
export function action(value: Permission["action"]): string {
	return toString(value);
}

/**
 * Formats the level field of the Permission model.
 */
export function level(value: Permission["level"]): string {
	return toString(value);
}

/**
 * Formats the description field of the Permission model.
 */
export function description(value: Permission["description"]): string {
	return toString(value);
}
