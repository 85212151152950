import { newPageInfo } from "@hex-insights/router";
import { serviceHubPageInfo } from "../pageinfo";

export const serviceHubCreatePageInfo = newPageInfo({
	name: "New Service",
	title: "New",
	description: "Create a new Service record.",
	exact: true,
	to: serviceHubPageInfo.to + "/new",
	path: serviceHubPageInfo.path + "/new",
	parent: serviceHubPageInfo,
});
