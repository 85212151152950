import { newPageInfo } from "@hex-insights/router";
import { noteAttachmentHubPageInfo } from "../pageinfo";

export const noteAttachmentHubIndexPageInfo = newPageInfo({
	name: "Note Attachments Index",
	title: "",
	description: "Index of Note Attachments.",
	exact: true,
	to: noteAttachmentHubPageInfo.to,
	path: noteAttachmentHubPageInfo.path,
	parent: noteAttachmentHubPageInfo,
});
