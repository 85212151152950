import { newPageInfo } from "@hex-insights/router";
import { documentResourceHubPageInfo } from "../pageinfo";

export const documentResourceHubIndexPageInfo = newPageInfo({
	name: "Document Resources Index",
	title: "",
	description: "Index of Document Resources.",
	exact: true,
	to: documentResourceHubPageInfo.to,
	path: documentResourceHubPageInfo.path,
	parent: documentResourceHubPageInfo,
});
