import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { SessionOrderFormValues } from "../../../../Utilities";

export type SessionOrderFormControllerProps = {
	formState: FormState<SessionOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the Session model.
 */
export function SessionOrderFormController({ formState }: SessionOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={SessionOrderFormValues.fieldOptions}
			fieldTypeMapping={SessionOrderFormValues.fieldTypeMapping}
		/>
	);
}
