import React from "react";
import { FieldDisplayArgs, MultiSelectField, TextField } from "@hex-insights/forms";
import { PermissionFormValues, PermissionGroupSelect, usePermissionGroupSelectLazyQuery } from "../../../../Utilities";
import { PermissionGroupLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Permission model.
 */
type FieldProps<K extends keyof PermissionFormValues.Base = keyof PermissionFormValues.Base> = BaseFieldProps<
	Pick<PermissionFormValues.Base, K>
>;

/**
 * Generic props for fields of the Permission model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof PermissionFormValues.Detail = keyof PermissionFormValues.Detail> = BaseFieldProps<
	Pick<PermissionFormValues.Detail, K>
>;

/**
 * Renders a field component for the `name` field of the Permission model.
 */
export function Name({ formState }: DetailFieldProps<"name">) {
	return <TextField formState={formState} name="name" />;
}

/**
 * Renders a field component for the `family` field of the Permission model.
 */
export function Family({ formState }: DetailFieldProps<"family">) {
	return <TextField formState={formState} name="family" />;
}

/**
 * Renders a field component for the `action` field of the Permission model.
 */
export function Action({ formState }: DetailFieldProps<"action">) {
	return <TextField formState={formState} name="action" />;
}

/**
 * Renders a field component for the `level` field of the Permission model.
 */
export function Level({ formState }: DetailFieldProps<"level">) {
	return <TextField formState={formState} name="level" />;
}

/**
 * Renders a field component for the `description` field of the Permission model.
 */
export function Description({ formState }: DetailFieldProps<"description">) {
	return <TextField formState={formState} name="description" />;
}

export type PermissionGroupsProps = FieldProps<"permissionGroupIDs"> & {
	currentPermissionGroups?: PermissionGroupSelect.ModelForOption[];
};

/**
 * Renders a field component for the `permissionGroups` edge of the Permission model.
 */
export function PermissionGroups({ formState, currentPermissionGroups }: PermissionGroupsProps) {
	const [loadOptions, { loading, data }] = usePermissionGroupSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.permissionGroupIDs) {
			loadOptions();
		}
	}, [formState.formEditing.permissionGroupIDs, loadOptions]);
	const options = React.useMemo(
		() => PermissionGroupSelect.toMultiOptions(data?.permissionGroupConnection.edges, currentPermissionGroups),
		[data, currentPermissionGroups],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="permissionGroupIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayPermissionGroupInstance}
		/>
	);
}

function displayPermissionGroupInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <PermissionGroupLink instance={{ id }}>{formattedValue}</PermissionGroupLink>;
}
