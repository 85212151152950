// Code generated by @hex-insights/frontend-generator; DO NOT EDIT.
import { admissionsApplicationsPageInfo } from "./Pages/Admissions/Applications/pageinfo";
import { admissionsEnrollmentInvitationsPageInfo } from "./Pages/Admissions/EnrollmentInvitations/pageinfo";
import { admissionsEnrollmentsPageInfo } from "./Pages/Admissions/Enrollments/pageinfo";
import { admissionsMeetingsPageInfo } from "./Pages/Admissions/Meetings/pageinfo";
import { admissionsPageInfo } from "./Pages/Admissions/pageinfo";
import { admissionsReportsPageInfo } from "./Pages/Admissions/Reports/pageinfo";
import { loginPageInfo } from "./Pages/Authentication/Login/pageinfo";
import { logoutPageInfo } from "./Pages/Authentication/Logout/pageinfo";
import { mfaLoginPageInfo } from "./Pages/Authentication/MFALogin/pageinfo";
import { recoverPageInfo } from "./Pages/Authentication/Recover/pageinfo";
import { setPasswordPageInfo } from "./Pages/Authentication/SetPassword/pageinfo";
import { budgetMonthPageInfo } from "./Pages/Budget/Month/pageinfo";
import { budgetPageInfo } from "./Pages/Budget/pageinfo";
import { busListDropoffPageInfo } from "./Pages/BusList/Dropoff/pageinfo";
import { busListIndexPageInfo } from "./Pages/BusList/IndexPage/pageinfo";
import { busListPageInfo } from "./Pages/BusList/pageinfo";
import { busListPickupPageInfo } from "./Pages/BusList/Pickup/pageinfo";
import { dashboardsAcademicPageInfo } from "./Pages/Dashboards/Academic/pageinfo";
import { dashboardsDirectorPageInfo } from "./Pages/Dashboards/Director/pageinfo";
import { dashboardsFinancialsPageInfo } from "./Pages/Dashboards/Financials/pageinfo";
import { dashboardsFrontDeskPageInfo } from "./Pages/Dashboards/FrontDesk/pageinfo";
import { dashboardsGeneralPageInfo } from "./Pages/Dashboards/General/pageinfo";
import { dashboardsPageInfo } from "./Pages/Dashboards/pageinfo";
import { homePageInfo } from "./Pages/Home/pageinfo";
import { academicYearHubCreatePageInfo } from "./Pages/Hubs/AcademicYear/CreatePage/pageinfo";
import { academicYearHubDetailPageInfo } from "./Pages/Hubs/AcademicYear/DetailPage/pageinfo";
import { academicYearHubIndexPageInfo } from "./Pages/Hubs/AcademicYear/IndexPage/pageinfo";
import { academicYearHubPageInfo } from "./Pages/Hubs/AcademicYear/pageinfo";
import { academicYearTuitionHubCreatePageInfo } from "./Pages/Hubs/AcademicYearTuition/CreatePage/pageinfo";
import { academicYearTuitionHubDetailPageInfo } from "./Pages/Hubs/AcademicYearTuition/DetailPage/pageinfo";
import { academicYearTuitionHubIndexPageInfo } from "./Pages/Hubs/AcademicYearTuition/IndexPage/pageinfo";
import { academicYearTuitionHubPageInfo } from "./Pages/Hubs/AcademicYearTuition/pageinfo";
import { addressHubCreatePageInfo } from "./Pages/Hubs/Address/CreatePage/pageinfo";
import { addressHubDetailPageInfo } from "./Pages/Hubs/Address/DetailPage/pageinfo";
import { addressHubIndexPageInfo } from "./Pages/Hubs/Address/IndexPage/pageinfo";
import { addressHubPageInfo } from "./Pages/Hubs/Address/pageinfo";
import { billingSetupSubmissionHubCreatePageInfo } from "./Pages/Hubs/BillingSetupSubmission/CreatePage/pageinfo";
import { billingSetupSubmissionHubDetailPageInfo } from "./Pages/Hubs/BillingSetupSubmission/DetailPage/pageinfo";
import { billingSetupSubmissionHubIndexPageInfo } from "./Pages/Hubs/BillingSetupSubmission/IndexPage/pageinfo";
import { billingSetupSubmissionHubPageInfo } from "./Pages/Hubs/BillingSetupSubmission/pageinfo";
import { busHubCreatePageInfo } from "./Pages/Hubs/Bus/CreatePage/pageinfo";
import { busHubDetailPageInfo } from "./Pages/Hubs/Bus/DetailPage/pageinfo";
import { busHubIndexPageInfo } from "./Pages/Hubs/Bus/IndexPage/pageinfo";
import { busHubPageInfo } from "./Pages/Hubs/Bus/pageinfo";
import { busPlanHubCreatePageInfo } from "./Pages/Hubs/BusPlan/CreatePage/pageinfo";
import { busPlanHubDetailPageInfo } from "./Pages/Hubs/BusPlan/DetailPage/pageinfo";
import { busPlanHubIndexPageInfo } from "./Pages/Hubs/BusPlan/IndexPage/pageinfo";
import { busPlanHubPageInfo } from "./Pages/Hubs/BusPlan/pageinfo";
import { calendarEventHubCreatePageInfo } from "./Pages/Hubs/CalendarEvent/CreatePage/pageinfo";
import { calendarEventHubDetailPageInfo } from "./Pages/Hubs/CalendarEvent/DetailPage/pageinfo";
import { calendarEventHubIndexPageInfo } from "./Pages/Hubs/CalendarEvent/IndexPage/pageinfo";
import { calendarEventHubPageInfo } from "./Pages/Hubs/CalendarEvent/pageinfo";
import { campusHubCreatePageInfo } from "./Pages/Hubs/Campus/CreatePage/pageinfo";
import { campusHubDetailPageInfo } from "./Pages/Hubs/Campus/DetailPage/pageinfo";
import { campusHubIndexPageInfo } from "./Pages/Hubs/Campus/IndexPage/pageinfo";
import { campusHubPageInfo } from "./Pages/Hubs/Campus/pageinfo";
import { classYearHubCreatePageInfo } from "./Pages/Hubs/ClassYear/CreatePage/pageinfo";
import { classYearHubDetailPageInfo } from "./Pages/Hubs/ClassYear/DetailPage/pageinfo";
import { classYearHubIndexPageInfo } from "./Pages/Hubs/ClassYear/IndexPage/pageinfo";
import { classYearHubPageInfo } from "./Pages/Hubs/ClassYear/pageinfo";
import { contractHubCreatePageInfo } from "./Pages/Hubs/Contract/CreatePage/pageinfo";
import { contractHubDetailPageInfo } from "./Pages/Hubs/Contract/DetailPage/pageinfo";
import { contractHubIndexPageInfo } from "./Pages/Hubs/Contract/IndexPage/pageinfo";
import { contractHubPageInfo } from "./Pages/Hubs/Contract/pageinfo";
import { contractInstallmentHubCreatePageInfo } from "./Pages/Hubs/ContractInstallment/CreatePage/pageinfo";
import { contractInstallmentHubDetailPageInfo } from "./Pages/Hubs/ContractInstallment/DetailPage/pageinfo";
import { contractInstallmentHubIndexPageInfo } from "./Pages/Hubs/ContractInstallment/IndexPage/pageinfo";
import { contractInstallmentHubPageInfo } from "./Pages/Hubs/ContractInstallment/pageinfo";
import { courseHubCreatePageInfo } from "./Pages/Hubs/Course/CreatePage/pageinfo";
import { courseHubDetailPageInfo } from "./Pages/Hubs/Course/DetailPage/pageinfo";
import { courseHubIndexPageInfo } from "./Pages/Hubs/Course/IndexPage/pageinfo";
import { courseHubPageInfo } from "./Pages/Hubs/Course/pageinfo";
import { courseAttendanceRecordHubCreatePageInfo } from "./Pages/Hubs/CourseAttendanceRecord/CreatePage/pageinfo";
import { courseAttendanceRecordHubDetailPageInfo } from "./Pages/Hubs/CourseAttendanceRecord/DetailPage/pageinfo";
import { courseAttendanceRecordHubIndexPageInfo } from "./Pages/Hubs/CourseAttendanceRecord/IndexPage/pageinfo";
import { courseAttendanceRecordHubPageInfo } from "./Pages/Hubs/CourseAttendanceRecord/pageinfo";
import { courseSectionHubCreatePageInfo } from "./Pages/Hubs/CourseSection/CreatePage/pageinfo";
import { courseSectionHubDetailPageInfo } from "./Pages/Hubs/CourseSection/DetailPage/pageinfo";
import { courseSectionHubIndexPageInfo } from "./Pages/Hubs/CourseSection/IndexPage/pageinfo";
import { courseSectionHubPageInfo } from "./Pages/Hubs/CourseSection/pageinfo";
import { courseSectionPeriodHubCreatePageInfo } from "./Pages/Hubs/CourseSectionPeriod/CreatePage/pageinfo";
import { courseSectionPeriodHubDetailPageInfo } from "./Pages/Hubs/CourseSectionPeriod/DetailPage/pageinfo";
import { courseSectionPeriodHubIndexPageInfo } from "./Pages/Hubs/CourseSectionPeriod/IndexPage/pageinfo";
import { courseSectionPeriodHubPageInfo } from "./Pages/Hubs/CourseSectionPeriod/pageinfo";
import { hubsDetailRedirectPageInfo } from "./Pages/Hubs/DetailRedirectPage/pageinfo";
import { discountHubCreatePageInfo } from "./Pages/Hubs/Discount/CreatePage/pageinfo";
import { discountHubDetailPageInfo } from "./Pages/Hubs/Discount/DetailPage/pageinfo";
import { discountHubIndexPageInfo } from "./Pages/Hubs/Discount/IndexPage/pageinfo";
import { discountHubPageInfo } from "./Pages/Hubs/Discount/pageinfo";
import { documentResourceHubCreatePageInfo } from "./Pages/Hubs/DocumentResource/CreatePage/pageinfo";
import { documentResourceHubDetailPageInfo } from "./Pages/Hubs/DocumentResource/DetailPage/pageinfo";
import { documentResourceHubIndexPageInfo } from "./Pages/Hubs/DocumentResource/IndexPage/pageinfo";
import { documentResourceHubPageInfo } from "./Pages/Hubs/DocumentResource/pageinfo";
import { documentResourceFolderHubCreatePageInfo } from "./Pages/Hubs/DocumentResourceFolder/CreatePage/pageinfo";
import { documentResourceFolderHubDetailPageInfo } from "./Pages/Hubs/DocumentResourceFolder/DetailPage/pageinfo";
import { documentResourceFolderHubIndexPageInfo } from "./Pages/Hubs/DocumentResourceFolder/IndexPage/pageinfo";
import { documentResourceFolderHubPageInfo } from "./Pages/Hubs/DocumentResourceFolder/pageinfo";
import { documentResourceUserViewRecordHubCreatePageInfo } from "./Pages/Hubs/DocumentResourceUserViewRecord/CreatePage/pageinfo";
import { documentResourceUserViewRecordHubDetailPageInfo } from "./Pages/Hubs/DocumentResourceUserViewRecord/DetailPage/pageinfo";
import { documentResourceUserViewRecordHubIndexPageInfo } from "./Pages/Hubs/DocumentResourceUserViewRecord/IndexPage/pageinfo";
import { documentResourceUserViewRecordHubPageInfo } from "./Pages/Hubs/DocumentResourceUserViewRecord/pageinfo";
import { emailAddressHubCreatePageInfo } from "./Pages/Hubs/EmailAddress/CreatePage/pageinfo";
import { emailAddressHubDetailPageInfo } from "./Pages/Hubs/EmailAddress/DetailPage/pageinfo";
import { emailAddressHubIndexPageInfo } from "./Pages/Hubs/EmailAddress/IndexPage/pageinfo";
import { emailAddressHubPageInfo } from "./Pages/Hubs/EmailAddress/pageinfo";
import { employeeHubCreatePageInfo } from "./Pages/Hubs/Employee/CreatePage/pageinfo";
import { employeeHubDetailPageInfo } from "./Pages/Hubs/Employee/DetailPage/pageinfo";
import { employeeHubIndexPageInfo } from "./Pages/Hubs/Employee/IndexPage/pageinfo";
import { employeeHubPageInfo } from "./Pages/Hubs/Employee/pageinfo";
import { enrollmentApplicationHubDetailPageInfo } from "./Pages/Hubs/EnrollmentApplication/DetailPage/pageinfo";
import { enrollmentApplicationHubIndexPageInfo } from "./Pages/Hubs/EnrollmentApplication/IndexPage/pageinfo";
import { enrollmentApplicationHubPageInfo } from "./Pages/Hubs/EnrollmentApplication/pageinfo";
import { enrollmentApplicationNoteHubCreatePageInfo } from "./Pages/Hubs/EnrollmentApplicationNote/CreatePage/pageinfo";
import { enrollmentApplicationNoteHubDetailPageInfo } from "./Pages/Hubs/EnrollmentApplicationNote/DetailPage/pageinfo";
import { enrollmentApplicationNoteHubIndexPageInfo } from "./Pages/Hubs/EnrollmentApplicationNote/IndexPage/pageinfo";
import { enrollmentApplicationNoteHubPageInfo } from "./Pages/Hubs/EnrollmentApplicationNote/pageinfo";
import { enrollmentApplicationParentHubCreatePageInfo } from "./Pages/Hubs/EnrollmentApplicationParent/CreatePage/pageinfo";
import { enrollmentApplicationParentHubDetailPageInfo } from "./Pages/Hubs/EnrollmentApplicationParent/DetailPage/pageinfo";
import { enrollmentApplicationParentHubIndexPageInfo } from "./Pages/Hubs/EnrollmentApplicationParent/IndexPage/pageinfo";
import { enrollmentApplicationParentHubPageInfo } from "./Pages/Hubs/EnrollmentApplicationParent/pageinfo";
import { enrollmentApplicationStudentHubCreatePageInfo } from "./Pages/Hubs/EnrollmentApplicationStudent/CreatePage/pageinfo";
import { enrollmentApplicationStudentHubDetailPageInfo } from "./Pages/Hubs/EnrollmentApplicationStudent/DetailPage/pageinfo";
import { enrollmentApplicationStudentHubIndexPageInfo } from "./Pages/Hubs/EnrollmentApplicationStudent/IndexPage/pageinfo";
import { enrollmentApplicationStudentHubPageInfo } from "./Pages/Hubs/EnrollmentApplicationStudent/pageinfo";
import { enrollmentApplicationSubmissionHubDetailPageInfo } from "./Pages/Hubs/EnrollmentApplicationSubmission/DetailPage/pageinfo";
import { enrollmentApplicationSubmissionHubIndexPageInfo } from "./Pages/Hubs/EnrollmentApplicationSubmission/IndexPage/pageinfo";
import { enrollmentApplicationSubmissionHubPageInfo } from "./Pages/Hubs/EnrollmentApplicationSubmission/pageinfo";
import { enrollmentApplicationUserViewRecordHubDetailPageInfo } from "./Pages/Hubs/EnrollmentApplicationUserViewRecord/DetailPage/pageinfo";
import { enrollmentApplicationUserViewRecordHubIndexPageInfo } from "./Pages/Hubs/EnrollmentApplicationUserViewRecord/IndexPage/pageinfo";
import { enrollmentApplicationUserViewRecordHubPageInfo } from "./Pages/Hubs/EnrollmentApplicationUserViewRecord/pageinfo";
import { enrollmentInvitationHubCreatePageInfo } from "./Pages/Hubs/EnrollmentInvitation/CreatePage/pageinfo";
import { enrollmentInvitationHubDetailPageInfo } from "./Pages/Hubs/EnrollmentInvitation/DetailPage/pageinfo";
import { enrollmentInvitationHubIndexPageInfo } from "./Pages/Hubs/EnrollmentInvitation/IndexPage/pageinfo";
import { enrollmentInvitationHubPageInfo } from "./Pages/Hubs/EnrollmentInvitation/pageinfo";
import { expenseHubCreatePageInfo } from "./Pages/Hubs/Expense/CreatePage/pageinfo";
import { expenseHubDetailPageInfo } from "./Pages/Hubs/Expense/DetailPage/pageinfo";
import { expenseHubIndexPageInfo } from "./Pages/Hubs/Expense/IndexPage/pageinfo";
import { expenseHubPageInfo } from "./Pages/Hubs/Expense/pageinfo";
import { expenseBudgetHubCreatePageInfo } from "./Pages/Hubs/ExpenseBudget/CreatePage/pageinfo";
import { expenseBudgetHubDetailPageInfo } from "./Pages/Hubs/ExpenseBudget/DetailPage/pageinfo";
import { expenseBudgetHubIndexPageInfo } from "./Pages/Hubs/ExpenseBudget/IndexPage/pageinfo";
import { expenseBudgetHubPageInfo } from "./Pages/Hubs/ExpenseBudget/pageinfo";
import { expenseCategoryHubCreatePageInfo } from "./Pages/Hubs/ExpenseCategory/CreatePage/pageinfo";
import { expenseCategoryHubDetailPageInfo } from "./Pages/Hubs/ExpenseCategory/DetailPage/pageinfo";
import { expenseCategoryHubIndexPageInfo } from "./Pages/Hubs/ExpenseCategory/IndexPage/pageinfo";
import { expenseCategoryHubPageInfo } from "./Pages/Hubs/ExpenseCategory/pageinfo";
import { homeRoomHubCreatePageInfo } from "./Pages/Hubs/HomeRoom/CreatePage/pageinfo";
import { homeRoomHubDetailPageInfo } from "./Pages/Hubs/HomeRoom/DetailPage/pageinfo";
import { homeRoomHubIndexPageInfo } from "./Pages/Hubs/HomeRoom/IndexPage/pageinfo";
import { homeRoomHubPageInfo } from "./Pages/Hubs/HomeRoom/pageinfo";
import { homeRoomSectionHubCreatePageInfo } from "./Pages/Hubs/HomeRoomSection/CreatePage/pageinfo";
import { homeRoomSectionHubDetailPageInfo } from "./Pages/Hubs/HomeRoomSection/DetailPage/pageinfo";
import { homeRoomSectionHubIndexPageInfo } from "./Pages/Hubs/HomeRoomSection/IndexPage/pageinfo";
import { homeRoomSectionHubPageInfo } from "./Pages/Hubs/HomeRoomSection/pageinfo";
import { hubsIndexPageInfo } from "./Pages/Hubs/IndexPage/pageinfo";
import { invoiceHubCreatePageInfo } from "./Pages/Hubs/Invoice/CreatePage/pageinfo";
import { invoiceHubDetailPageInfo } from "./Pages/Hubs/Invoice/DetailPage/pageinfo";
import { invoiceHubIndexPageInfo } from "./Pages/Hubs/Invoice/IndexPage/pageinfo";
import { invoiceHubPageInfo } from "./Pages/Hubs/Invoice/pageinfo";
import { invoiceLineItemHubCreatePageInfo } from "./Pages/Hubs/InvoiceLineItem/CreatePage/pageinfo";
import { invoiceLineItemHubDetailPageInfo } from "./Pages/Hubs/InvoiceLineItem/DetailPage/pageinfo";
import { invoiceLineItemHubIndexPageInfo } from "./Pages/Hubs/InvoiceLineItem/IndexPage/pageinfo";
import { invoiceLineItemHubPageInfo } from "./Pages/Hubs/InvoiceLineItem/pageinfo";
import { learningObjectiveHubCreatePageInfo } from "./Pages/Hubs/LearningObjective/CreatePage/pageinfo";
import { learningObjectiveHubDetailPageInfo } from "./Pages/Hubs/LearningObjective/DetailPage/pageinfo";
import { learningObjectiveHubIndexPageInfo } from "./Pages/Hubs/LearningObjective/IndexPage/pageinfo";
import { learningObjectiveHubPageInfo } from "./Pages/Hubs/LearningObjective/pageinfo";
import { learningObjectiveMarkHubCreatePageInfo } from "./Pages/Hubs/LearningObjectiveMark/CreatePage/pageinfo";
import { learningObjectiveMarkHubDetailPageInfo } from "./Pages/Hubs/LearningObjectiveMark/DetailPage/pageinfo";
import { learningObjectiveMarkHubIndexPageInfo } from "./Pages/Hubs/LearningObjectiveMark/IndexPage/pageinfo";
import { learningObjectiveMarkHubPageInfo } from "./Pages/Hubs/LearningObjectiveMark/pageinfo";
import { lessonPlanHubCreatePageInfo } from "./Pages/Hubs/LessonPlan/CreatePage/pageinfo";
import { lessonPlanHubDetailPageInfo } from "./Pages/Hubs/LessonPlan/DetailPage/pageinfo";
import { lessonPlanHubIndexPageInfo } from "./Pages/Hubs/LessonPlan/IndexPage/pageinfo";
import { lessonPlanHubPageInfo } from "./Pages/Hubs/LessonPlan/pageinfo";
import { loginRecordHubDetailPageInfo } from "./Pages/Hubs/LoginRecord/DetailPage/pageinfo";
import { loginRecordHubIndexPageInfo } from "./Pages/Hubs/LoginRecord/IndexPage/pageinfo";
import { loginRecordHubPageInfo } from "./Pages/Hubs/LoginRecord/pageinfo";
import { mealMenuHubBulkCreatePageInfo } from "./Pages/Hubs/MealMenu/BulkCreatePage/pageinfo";
import { mealMenuHubCreatePageInfo } from "./Pages/Hubs/MealMenu/CreatePage/pageinfo";
import { mealMenuHubDetailPageInfo } from "./Pages/Hubs/MealMenu/DetailPage/pageinfo";
import { mealMenuHubIndexPageInfo } from "./Pages/Hubs/MealMenu/IndexPage/pageinfo";
import { mealMenuHubPageInfo } from "./Pages/Hubs/MealMenu/pageinfo";
import { mealPlanHubCreatePageInfo } from "./Pages/Hubs/MealPlan/CreatePage/pageinfo";
import { mealPlanHubDetailPageInfo } from "./Pages/Hubs/MealPlan/DetailPage/pageinfo";
import { mealPlanHubIndexPageInfo } from "./Pages/Hubs/MealPlan/IndexPage/pageinfo";
import { mealPlanHubPageInfo } from "./Pages/Hubs/MealPlan/pageinfo";
import { mfaFactorHubDetailPageInfo } from "./Pages/Hubs/MFAFactor/DetailPage/pageinfo";
import { mfaFactorHubIndexPageInfo } from "./Pages/Hubs/MFAFactor/IndexPage/pageinfo";
import { mfaFactorHubPageInfo } from "./Pages/Hubs/MFAFactor/pageinfo";
import { mfaInvalidOTPAttemptHubDetailPageInfo } from "./Pages/Hubs/MFAInvalidOTPAttempt/DetailPage/pageinfo";
import { mfaInvalidOTPAttemptHubIndexPageInfo } from "./Pages/Hubs/MFAInvalidOTPAttempt/IndexPage/pageinfo";
import { mfaInvalidOTPAttemptHubPageInfo } from "./Pages/Hubs/MFAInvalidOTPAttempt/pageinfo";
import { noteHubCreatePageInfo } from "./Pages/Hubs/Note/CreatePage/pageinfo";
import { noteHubDetailPageInfo } from "./Pages/Hubs/Note/DetailPage/pageinfo";
import { noteHubIndexPageInfo } from "./Pages/Hubs/Note/IndexPage/pageinfo";
import { noteHubPageInfo } from "./Pages/Hubs/Note/pageinfo";
import { noteAttachmentHubCreatePageInfo } from "./Pages/Hubs/NoteAttachment/CreatePage/pageinfo";
import { noteAttachmentHubDetailPageInfo } from "./Pages/Hubs/NoteAttachment/DetailPage/pageinfo";
import { noteAttachmentHubIndexPageInfo } from "./Pages/Hubs/NoteAttachment/IndexPage/pageinfo";
import { noteAttachmentHubPageInfo } from "./Pages/Hubs/NoteAttachment/pageinfo";
import { noteCommentHubCreatePageInfo } from "./Pages/Hubs/NoteComment/CreatePage/pageinfo";
import { noteCommentHubDetailPageInfo } from "./Pages/Hubs/NoteComment/DetailPage/pageinfo";
import { noteCommentHubIndexPageInfo } from "./Pages/Hubs/NoteComment/IndexPage/pageinfo";
import { noteCommentHubPageInfo } from "./Pages/Hubs/NoteComment/pageinfo";
import { noteReactionHubCreatePageInfo } from "./Pages/Hubs/NoteReaction/CreatePage/pageinfo";
import { noteReactionHubDetailPageInfo } from "./Pages/Hubs/NoteReaction/DetailPage/pageinfo";
import { noteReactionHubIndexPageInfo } from "./Pages/Hubs/NoteReaction/IndexPage/pageinfo";
import { noteReactionHubPageInfo } from "./Pages/Hubs/NoteReaction/pageinfo";
import { hubsPageInfo } from "./Pages/Hubs/pageinfo";
import { parentHubCreatePageInfo } from "./Pages/Hubs/Parent/CreatePage/pageinfo";
import { parentHubDetailPageInfo } from "./Pages/Hubs/Parent/DetailPage/pageinfo";
import { parentHubIndexPageInfo } from "./Pages/Hubs/Parent/IndexPage/pageinfo";
import { parentHubPageInfo } from "./Pages/Hubs/Parent/pageinfo";
import { paymentHubCreatePageInfo } from "./Pages/Hubs/Payment/CreatePage/pageinfo";
import { paymentHubDetailPageInfo } from "./Pages/Hubs/Payment/DetailPage/pageinfo";
import { paymentHubIndexPageInfo } from "./Pages/Hubs/Payment/IndexPage/pageinfo";
import { paymentHubPageInfo } from "./Pages/Hubs/Payment/pageinfo";
import { paymentLineItemHubCreatePageInfo } from "./Pages/Hubs/PaymentLineItem/CreatePage/pageinfo";
import { paymentLineItemHubDetailPageInfo } from "./Pages/Hubs/PaymentLineItem/DetailPage/pageinfo";
import { paymentLineItemHubIndexPageInfo } from "./Pages/Hubs/PaymentLineItem/IndexPage/pageinfo";
import { paymentLineItemHubPageInfo } from "./Pages/Hubs/PaymentLineItem/pageinfo";
import { permissionHubDetailPageInfo } from "./Pages/Hubs/Permission/DetailPage/pageinfo";
import { permissionHubIndexPageInfo } from "./Pages/Hubs/Permission/IndexPage/pageinfo";
import { permissionHubPageInfo } from "./Pages/Hubs/Permission/pageinfo";
import { permissionGroupHubCreatePageInfo } from "./Pages/Hubs/PermissionGroup/CreatePage/pageinfo";
import { permissionGroupHubDetailPageInfo } from "./Pages/Hubs/PermissionGroup/DetailPage/pageinfo";
import { permissionGroupHubIndexPageInfo } from "./Pages/Hubs/PermissionGroup/IndexPage/pageinfo";
import { permissionGroupHubPageInfo } from "./Pages/Hubs/PermissionGroup/pageinfo";
import { personHubCreatePageInfo } from "./Pages/Hubs/Person/CreatePage/pageinfo";
import { personHubDetailPageInfo } from "./Pages/Hubs/Person/DetailPage/pageinfo";
import { personHubIndexPageInfo } from "./Pages/Hubs/Person/IndexPage/pageinfo";
import { personHubPageInfo } from "./Pages/Hubs/Person/pageinfo";
import { phoneNumberHubCreatePageInfo } from "./Pages/Hubs/PhoneNumber/CreatePage/pageinfo";
import { phoneNumberHubDetailPageInfo } from "./Pages/Hubs/PhoneNumber/DetailPage/pageinfo";
import { phoneNumberHubIndexPageInfo } from "./Pages/Hubs/PhoneNumber/IndexPage/pageinfo";
import { phoneNumberHubPageInfo } from "./Pages/Hubs/PhoneNumber/pageinfo";
import { quotationHubCreatePageInfo } from "./Pages/Hubs/Quotation/CreatePage/pageinfo";
import { quotationHubDetailPageInfo } from "./Pages/Hubs/Quotation/DetailPage/pageinfo";
import { quotationHubIndexPageInfo } from "./Pages/Hubs/Quotation/IndexPage/pageinfo";
import { quotationHubPageInfo } from "./Pages/Hubs/Quotation/pageinfo";
import { relationshipHubCreatePageInfo } from "./Pages/Hubs/Relationship/CreatePage/pageinfo";
import { relationshipHubDetailPageInfo } from "./Pages/Hubs/Relationship/DetailPage/pageinfo";
import { relationshipHubIndexPageInfo } from "./Pages/Hubs/Relationship/IndexPage/pageinfo";
import { relationshipHubPageInfo } from "./Pages/Hubs/Relationship/pageinfo";
import { reportCardHubCreatePageInfo } from "./Pages/Hubs/ReportCard/CreatePage/pageinfo";
import { reportCardHubDetailPageInfo } from "./Pages/Hubs/ReportCard/DetailPage/pageinfo";
import { reportCardHubIndexPageInfo } from "./Pages/Hubs/ReportCard/IndexPage/pageinfo";
import { reportCardHubPageInfo } from "./Pages/Hubs/ReportCard/pageinfo";
import { roomHubCreatePageInfo } from "./Pages/Hubs/Room/CreatePage/pageinfo";
import { roomHubDetailPageInfo } from "./Pages/Hubs/Room/DetailPage/pageinfo";
import { roomHubIndexPageInfo } from "./Pages/Hubs/Room/IndexPage/pageinfo";
import { roomHubPageInfo } from "./Pages/Hubs/Room/pageinfo";
import { schoolAttendanceRecordHubCreatePageInfo } from "./Pages/Hubs/SchoolAttendanceRecord/CreatePage/pageinfo";
import { schoolAttendanceRecordHubDetailPageInfo } from "./Pages/Hubs/SchoolAttendanceRecord/DetailPage/pageinfo";
import { schoolAttendanceRecordHubIndexPageInfo } from "./Pages/Hubs/SchoolAttendanceRecord/IndexPage/pageinfo";
import { schoolAttendanceRecordHubPageInfo } from "./Pages/Hubs/SchoolAttendanceRecord/pageinfo";
import { schoolStudentEnrollmentHubCreatePageInfo } from "./Pages/Hubs/SchoolStudentEnrollment/CreatePage/pageinfo";
import { schoolStudentEnrollmentHubDetailPageInfo } from "./Pages/Hubs/SchoolStudentEnrollment/DetailPage/pageinfo";
import { schoolStudentEnrollmentHubIndexPageInfo } from "./Pages/Hubs/SchoolStudentEnrollment/IndexPage/pageinfo";
import { schoolStudentEnrollmentHubPageInfo } from "./Pages/Hubs/SchoolStudentEnrollment/pageinfo";
import { serviceHubCreatePageInfo } from "./Pages/Hubs/Service/CreatePage/pageinfo";
import { serviceHubDetailPageInfo } from "./Pages/Hubs/Service/DetailPage/pageinfo";
import { serviceHubIndexPageInfo } from "./Pages/Hubs/Service/IndexPage/pageinfo";
import { serviceHubPageInfo } from "./Pages/Hubs/Service/pageinfo";
import { serviceSubscriptionHubCreatePageInfo } from "./Pages/Hubs/ServiceSubscription/CreatePage/pageinfo";
import { serviceSubscriptionHubDetailPageInfo } from "./Pages/Hubs/ServiceSubscription/DetailPage/pageinfo";
import { serviceSubscriptionHubIndexPageInfo } from "./Pages/Hubs/ServiceSubscription/IndexPage/pageinfo";
import { serviceSubscriptionHubPageInfo } from "./Pages/Hubs/ServiceSubscription/pageinfo";
import { sessionHubDetailPageInfo } from "./Pages/Hubs/Session/DetailPage/pageinfo";
import { sessionHubIndexPageInfo } from "./Pages/Hubs/Session/IndexPage/pageinfo";
import { sessionHubPageInfo } from "./Pages/Hubs/Session/pageinfo";
import { studentHubCreatePageInfo } from "./Pages/Hubs/Student/CreatePage/pageinfo";
import { studentHubDetailAcademicsPageInfo } from "./Pages/Hubs/Student/DetailPage/AcademicsPage/pageinfo";
import { studentHubDetailFamilyAndHealthPageInfo } from "./Pages/Hubs/Student/DetailPage/FamilyAndHealth/pageinfo";
import { studentHubDetailFinancialsPageInfo } from "./Pages/Hubs/Student/DetailPage/FinancialsPage/pageinfo";
import { studentHubDetailOverviewPageInfo } from "./Pages/Hubs/Student/DetailPage/OverviewPage/pageinfo";
import { studentHubDetailPageInfo } from "./Pages/Hubs/Student/DetailPage/pageinfo";
import { studentHubIndexPageInfo } from "./Pages/Hubs/Student/IndexPage/pageinfo";
import { studentHubPageInfo } from "./Pages/Hubs/Student/pageinfo";
import { studentDocumentHubCreatePageInfo } from "./Pages/Hubs/StudentDocument/CreatePage/pageinfo";
import { studentDocumentHubDetailPageInfo } from "./Pages/Hubs/StudentDocument/DetailPage/pageinfo";
import { studentDocumentHubIndexPageInfo } from "./Pages/Hubs/StudentDocument/IndexPage/pageinfo";
import { studentDocumentHubPageInfo } from "./Pages/Hubs/StudentDocument/pageinfo";
import { termHubCreatePageInfo } from "./Pages/Hubs/Term/CreatePage/pageinfo";
import { termHubDetailPageInfo } from "./Pages/Hubs/Term/DetailPage/pageinfo";
import { termHubIndexPageInfo } from "./Pages/Hubs/Term/IndexPage/pageinfo";
import { termHubPageInfo } from "./Pages/Hubs/Term/pageinfo";
import { trustedDeviceHubDetailPageInfo } from "./Pages/Hubs/TrustedDevice/DetailPage/pageinfo";
import { trustedDeviceHubIndexPageInfo } from "./Pages/Hubs/TrustedDevice/IndexPage/pageinfo";
import { trustedDeviceHubPageInfo } from "./Pages/Hubs/TrustedDevice/pageinfo";
import { tuitionHubCreatePageInfo } from "./Pages/Hubs/Tuition/CreatePage/pageinfo";
import { tuitionHubDetailPageInfo } from "./Pages/Hubs/Tuition/DetailPage/pageinfo";
import { tuitionHubIndexPageInfo } from "./Pages/Hubs/Tuition/IndexPage/pageinfo";
import { tuitionHubPageInfo } from "./Pages/Hubs/Tuition/pageinfo";
import { userHubCreatePageInfo } from "./Pages/Hubs/User/CreatePage/pageinfo";
import { userHubDetailPageInfo } from "./Pages/Hubs/User/DetailPage/pageinfo";
import { userHubIndexPageInfo } from "./Pages/Hubs/User/IndexPage/pageinfo";
import { userHubPageInfo } from "./Pages/Hubs/User/pageinfo";
import { userGroupHubCreatePageInfo } from "./Pages/Hubs/UserGroup/CreatePage/pageinfo";
import { userGroupHubDetailPageInfo } from "./Pages/Hubs/UserGroup/DetailPage/pageinfo";
import { userGroupHubIndexPageInfo } from "./Pages/Hubs/UserGroup/IndexPage/pageinfo";
import { userGroupHubPageInfo } from "./Pages/Hubs/UserGroup/pageinfo";
import { mealPlanListPageInfo } from "./Pages/MealPlanList/pageinfo";
import { resourcesFoldersDetailPageInfo } from "./Pages/Resources/Folders/DetailPage/pageinfo";
import { resourcesFoldersIndexPageInfo } from "./Pages/Resources/Folders/IndexPage/pageinfo";
import { resourcesFoldersPageInfo } from "./Pages/Resources/Folders/pageinfo";
import { resourcesIndexPageInfo } from "./Pages/Resources/IndexPage/pageinfo";
import { resourcesPageInfo } from "./Pages/Resources/pageinfo";
import { resourcesRecentsPageInfo } from "./Pages/Resources/Recents/pageinfo";
import { resourcesSearchPageInfo } from "./Pages/Resources/Search/pageinfo";
import { resourcesSharedPageInfo } from "./Pages/Resources/Shared/pageinfo";
import { termSetupCreatePageInfo } from "./Pages/TermSetup/CreatePage/pageinfo";
import { termSetupDetailHomeRoomSectionsCreatePageInfo } from "./Pages/TermSetup/DetailPage/HomeRoomSections/CreatePage/pageinfo";
import { termSetupDetailHomeRoomSectionsDetailPageInfo } from "./Pages/TermSetup/DetailPage/HomeRoomSections/DetailPage/pageinfo";
import { termSetupDetailHomeRoomSectionsPageInfo } from "./Pages/TermSetup/DetailPage/HomeRoomSections/pageinfo";
import { termSetupDetailOverviewPageInfo } from "./Pages/TermSetup/DetailPage/OverviewPage/pageinfo";
import { termSetupDetailPageInfo } from "./Pages/TermSetup/DetailPage/pageinfo";
import { termSetupPageInfo } from "./Pages/TermSetup/pageinfo";
import { rootPageInfo } from "./root.pageinfo";

export const allPages = [
	admissionsApplicationsPageInfo,
	admissionsEnrollmentInvitationsPageInfo,
	admissionsEnrollmentsPageInfo,
	admissionsMeetingsPageInfo,
	admissionsReportsPageInfo,
	admissionsPageInfo,
	loginPageInfo,
	logoutPageInfo,
	mfaLoginPageInfo,
	recoverPageInfo,
	setPasswordPageInfo,
	budgetMonthPageInfo,
	budgetPageInfo,
	busListDropoffPageInfo,
	busListIndexPageInfo,
	busListPickupPageInfo,
	busListPageInfo,
	dashboardsAcademicPageInfo,
	dashboardsDirectorPageInfo,
	dashboardsFinancialsPageInfo,
	dashboardsFrontDeskPageInfo,
	dashboardsGeneralPageInfo,
	dashboardsPageInfo,
	homePageInfo,
	academicYearHubCreatePageInfo,
	academicYearHubDetailPageInfo,
	academicYearHubIndexPageInfo,
	academicYearHubPageInfo,
	academicYearTuitionHubCreatePageInfo,
	academicYearTuitionHubDetailPageInfo,
	academicYearTuitionHubIndexPageInfo,
	academicYearTuitionHubPageInfo,
	addressHubCreatePageInfo,
	addressHubDetailPageInfo,
	addressHubIndexPageInfo,
	addressHubPageInfo,
	billingSetupSubmissionHubCreatePageInfo,
	billingSetupSubmissionHubDetailPageInfo,
	billingSetupSubmissionHubIndexPageInfo,
	billingSetupSubmissionHubPageInfo,
	busHubCreatePageInfo,
	busHubDetailPageInfo,
	busHubIndexPageInfo,
	busHubPageInfo,
	busPlanHubCreatePageInfo,
	busPlanHubDetailPageInfo,
	busPlanHubIndexPageInfo,
	busPlanHubPageInfo,
	calendarEventHubCreatePageInfo,
	calendarEventHubDetailPageInfo,
	calendarEventHubIndexPageInfo,
	calendarEventHubPageInfo,
	campusHubCreatePageInfo,
	campusHubDetailPageInfo,
	campusHubIndexPageInfo,
	campusHubPageInfo,
	classYearHubCreatePageInfo,
	classYearHubDetailPageInfo,
	classYearHubIndexPageInfo,
	classYearHubPageInfo,
	contractHubCreatePageInfo,
	contractHubDetailPageInfo,
	contractHubIndexPageInfo,
	contractHubPageInfo,
	contractInstallmentHubCreatePageInfo,
	contractInstallmentHubDetailPageInfo,
	contractInstallmentHubIndexPageInfo,
	contractInstallmentHubPageInfo,
	courseHubCreatePageInfo,
	courseHubDetailPageInfo,
	courseHubIndexPageInfo,
	courseHubPageInfo,
	courseAttendanceRecordHubCreatePageInfo,
	courseAttendanceRecordHubDetailPageInfo,
	courseAttendanceRecordHubIndexPageInfo,
	courseAttendanceRecordHubPageInfo,
	courseSectionHubCreatePageInfo,
	courseSectionHubDetailPageInfo,
	courseSectionHubIndexPageInfo,
	courseSectionHubPageInfo,
	courseSectionPeriodHubCreatePageInfo,
	courseSectionPeriodHubDetailPageInfo,
	courseSectionPeriodHubIndexPageInfo,
	courseSectionPeriodHubPageInfo,
	hubsDetailRedirectPageInfo,
	discountHubCreatePageInfo,
	discountHubDetailPageInfo,
	discountHubIndexPageInfo,
	discountHubPageInfo,
	documentResourceHubCreatePageInfo,
	documentResourceHubDetailPageInfo,
	documentResourceHubIndexPageInfo,
	documentResourceHubPageInfo,
	documentResourceFolderHubCreatePageInfo,
	documentResourceFolderHubDetailPageInfo,
	documentResourceFolderHubIndexPageInfo,
	documentResourceFolderHubPageInfo,
	documentResourceUserViewRecordHubCreatePageInfo,
	documentResourceUserViewRecordHubDetailPageInfo,
	documentResourceUserViewRecordHubIndexPageInfo,
	documentResourceUserViewRecordHubPageInfo,
	emailAddressHubCreatePageInfo,
	emailAddressHubDetailPageInfo,
	emailAddressHubIndexPageInfo,
	emailAddressHubPageInfo,
	employeeHubCreatePageInfo,
	employeeHubDetailPageInfo,
	employeeHubIndexPageInfo,
	employeeHubPageInfo,
	enrollmentApplicationHubDetailPageInfo,
	enrollmentApplicationHubIndexPageInfo,
	enrollmentApplicationHubPageInfo,
	enrollmentApplicationNoteHubCreatePageInfo,
	enrollmentApplicationNoteHubDetailPageInfo,
	enrollmentApplicationNoteHubIndexPageInfo,
	enrollmentApplicationNoteHubPageInfo,
	enrollmentApplicationParentHubCreatePageInfo,
	enrollmentApplicationParentHubDetailPageInfo,
	enrollmentApplicationParentHubIndexPageInfo,
	enrollmentApplicationParentHubPageInfo,
	enrollmentApplicationStudentHubCreatePageInfo,
	enrollmentApplicationStudentHubDetailPageInfo,
	enrollmentApplicationStudentHubIndexPageInfo,
	enrollmentApplicationStudentHubPageInfo,
	enrollmentApplicationSubmissionHubDetailPageInfo,
	enrollmentApplicationSubmissionHubIndexPageInfo,
	enrollmentApplicationSubmissionHubPageInfo,
	enrollmentApplicationUserViewRecordHubDetailPageInfo,
	enrollmentApplicationUserViewRecordHubIndexPageInfo,
	enrollmentApplicationUserViewRecordHubPageInfo,
	enrollmentInvitationHubCreatePageInfo,
	enrollmentInvitationHubDetailPageInfo,
	enrollmentInvitationHubIndexPageInfo,
	enrollmentInvitationHubPageInfo,
	expenseHubCreatePageInfo,
	expenseHubDetailPageInfo,
	expenseHubIndexPageInfo,
	expenseHubPageInfo,
	expenseBudgetHubCreatePageInfo,
	expenseBudgetHubDetailPageInfo,
	expenseBudgetHubIndexPageInfo,
	expenseBudgetHubPageInfo,
	expenseCategoryHubCreatePageInfo,
	expenseCategoryHubDetailPageInfo,
	expenseCategoryHubIndexPageInfo,
	expenseCategoryHubPageInfo,
	homeRoomHubCreatePageInfo,
	homeRoomHubDetailPageInfo,
	homeRoomHubIndexPageInfo,
	homeRoomHubPageInfo,
	homeRoomSectionHubCreatePageInfo,
	homeRoomSectionHubDetailPageInfo,
	homeRoomSectionHubIndexPageInfo,
	homeRoomSectionHubPageInfo,
	hubsIndexPageInfo,
	invoiceHubCreatePageInfo,
	invoiceHubDetailPageInfo,
	invoiceHubIndexPageInfo,
	invoiceHubPageInfo,
	invoiceLineItemHubCreatePageInfo,
	invoiceLineItemHubDetailPageInfo,
	invoiceLineItemHubIndexPageInfo,
	invoiceLineItemHubPageInfo,
	learningObjectiveHubCreatePageInfo,
	learningObjectiveHubDetailPageInfo,
	learningObjectiveHubIndexPageInfo,
	learningObjectiveHubPageInfo,
	learningObjectiveMarkHubCreatePageInfo,
	learningObjectiveMarkHubDetailPageInfo,
	learningObjectiveMarkHubIndexPageInfo,
	learningObjectiveMarkHubPageInfo,
	lessonPlanHubCreatePageInfo,
	lessonPlanHubDetailPageInfo,
	lessonPlanHubIndexPageInfo,
	lessonPlanHubPageInfo,
	loginRecordHubDetailPageInfo,
	loginRecordHubIndexPageInfo,
	loginRecordHubPageInfo,
	mfaFactorHubDetailPageInfo,
	mfaFactorHubIndexPageInfo,
	mfaFactorHubPageInfo,
	mfaInvalidOTPAttemptHubDetailPageInfo,
	mfaInvalidOTPAttemptHubIndexPageInfo,
	mfaInvalidOTPAttemptHubPageInfo,
	mealMenuHubBulkCreatePageInfo,
	mealMenuHubCreatePageInfo,
	mealMenuHubDetailPageInfo,
	mealMenuHubIndexPageInfo,
	mealMenuHubPageInfo,
	mealPlanHubCreatePageInfo,
	mealPlanHubDetailPageInfo,
	mealPlanHubIndexPageInfo,
	mealPlanHubPageInfo,
	noteHubCreatePageInfo,
	noteHubDetailPageInfo,
	noteHubIndexPageInfo,
	noteHubPageInfo,
	noteAttachmentHubCreatePageInfo,
	noteAttachmentHubDetailPageInfo,
	noteAttachmentHubIndexPageInfo,
	noteAttachmentHubPageInfo,
	noteCommentHubCreatePageInfo,
	noteCommentHubDetailPageInfo,
	noteCommentHubIndexPageInfo,
	noteCommentHubPageInfo,
	noteReactionHubCreatePageInfo,
	noteReactionHubDetailPageInfo,
	noteReactionHubIndexPageInfo,
	noteReactionHubPageInfo,
	parentHubCreatePageInfo,
	parentHubDetailPageInfo,
	parentHubIndexPageInfo,
	parentHubPageInfo,
	paymentHubCreatePageInfo,
	paymentHubDetailPageInfo,
	paymentHubIndexPageInfo,
	paymentHubPageInfo,
	paymentLineItemHubCreatePageInfo,
	paymentLineItemHubDetailPageInfo,
	paymentLineItemHubIndexPageInfo,
	paymentLineItemHubPageInfo,
	permissionHubDetailPageInfo,
	permissionHubIndexPageInfo,
	permissionHubPageInfo,
	permissionGroupHubCreatePageInfo,
	permissionGroupHubDetailPageInfo,
	permissionGroupHubIndexPageInfo,
	permissionGroupHubPageInfo,
	personHubCreatePageInfo,
	personHubDetailPageInfo,
	personHubIndexPageInfo,
	personHubPageInfo,
	phoneNumberHubCreatePageInfo,
	phoneNumberHubDetailPageInfo,
	phoneNumberHubIndexPageInfo,
	phoneNumberHubPageInfo,
	quotationHubCreatePageInfo,
	quotationHubDetailPageInfo,
	quotationHubIndexPageInfo,
	quotationHubPageInfo,
	relationshipHubCreatePageInfo,
	relationshipHubDetailPageInfo,
	relationshipHubIndexPageInfo,
	relationshipHubPageInfo,
	reportCardHubCreatePageInfo,
	reportCardHubDetailPageInfo,
	reportCardHubIndexPageInfo,
	reportCardHubPageInfo,
	roomHubCreatePageInfo,
	roomHubDetailPageInfo,
	roomHubIndexPageInfo,
	roomHubPageInfo,
	schoolAttendanceRecordHubCreatePageInfo,
	schoolAttendanceRecordHubDetailPageInfo,
	schoolAttendanceRecordHubIndexPageInfo,
	schoolAttendanceRecordHubPageInfo,
	schoolStudentEnrollmentHubCreatePageInfo,
	schoolStudentEnrollmentHubDetailPageInfo,
	schoolStudentEnrollmentHubIndexPageInfo,
	schoolStudentEnrollmentHubPageInfo,
	serviceHubCreatePageInfo,
	serviceHubDetailPageInfo,
	serviceHubIndexPageInfo,
	serviceHubPageInfo,
	serviceSubscriptionHubCreatePageInfo,
	serviceSubscriptionHubDetailPageInfo,
	serviceSubscriptionHubIndexPageInfo,
	serviceSubscriptionHubPageInfo,
	sessionHubDetailPageInfo,
	sessionHubIndexPageInfo,
	sessionHubPageInfo,
	studentHubCreatePageInfo,
	studentHubDetailAcademicsPageInfo,
	studentHubDetailFamilyAndHealthPageInfo,
	studentHubDetailFinancialsPageInfo,
	studentHubDetailOverviewPageInfo,
	studentHubDetailPageInfo,
	studentHubIndexPageInfo,
	studentHubPageInfo,
	studentDocumentHubCreatePageInfo,
	studentDocumentHubDetailPageInfo,
	studentDocumentHubIndexPageInfo,
	studentDocumentHubPageInfo,
	termHubCreatePageInfo,
	termHubDetailPageInfo,
	termHubIndexPageInfo,
	termHubPageInfo,
	trustedDeviceHubDetailPageInfo,
	trustedDeviceHubIndexPageInfo,
	trustedDeviceHubPageInfo,
	tuitionHubCreatePageInfo,
	tuitionHubDetailPageInfo,
	tuitionHubIndexPageInfo,
	tuitionHubPageInfo,
	userHubCreatePageInfo,
	userHubDetailPageInfo,
	userHubIndexPageInfo,
	userHubPageInfo,
	userGroupHubCreatePageInfo,
	userGroupHubDetailPageInfo,
	userGroupHubIndexPageInfo,
	userGroupHubPageInfo,
	hubsPageInfo,
	mealPlanListPageInfo,
	resourcesFoldersDetailPageInfo,
	resourcesFoldersIndexPageInfo,
	resourcesFoldersPageInfo,
	resourcesIndexPageInfo,
	resourcesRecentsPageInfo,
	resourcesSearchPageInfo,
	resourcesSharedPageInfo,
	resourcesPageInfo,
	termSetupCreatePageInfo,
	termSetupDetailHomeRoomSectionsCreatePageInfo,
	termSetupDetailHomeRoomSectionsDetailPageInfo,
	termSetupDetailHomeRoomSectionsPageInfo,
	termSetupDetailOverviewPageInfo,
	termSetupDetailPageInfo,
	termSetupPageInfo,
	rootPageInfo,
];
