import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { ClassYearFilterInput, ClassYearOrder, useClassYearPaginationQuery } from "../../../../Utilities";

export type ClassYearPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: ClassYearFilterInput | ClassYearFilterInput[] | null;
	order: ClassYearOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the ClassYear model.
 */
export function ClassYearPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: ClassYearPaginationFormProps) {
	const { loading, data } = useClassYearPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.classYearConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
