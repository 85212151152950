import { newPageInfo } from "@hex-insights/router";
import { learningObjectiveHubPageInfo } from "../pageinfo";

export const learningObjectiveHubIndexPageInfo = newPageInfo({
	name: "Learning Objectives Index",
	title: "",
	description: "Index of Learning Objectives.",
	exact: true,
	to: learningObjectiveHubPageInfo.to,
	path: learningObjectiveHubPageInfo.path,
	parent: learningObjectiveHubPageInfo,
});
