import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { ReportCardCreateInput, ReportCardDetailQuery, ReportCardUpdateInput } from "../../GraphQL";
import { ReportCardFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the ReportCard model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: ReportCardFormValues.Create): ReportCardCreateInput {
	const input: NullPartial<ReportCardCreateInput> = { ...formValues };
	return input as ReportCardCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the ReportCard model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<ReportCardFormValues.Detail>,
	initialFormValues: ReportCardFormValues.Detail,
): ReportCardUpdateInput {
	const inputFormValues = omitKeys(formValues, ["learningObjectiveMarkIDs"]);
	const input: ReportCardUpdateInput = { ...inputFormValues };

	if (formValues.learningObjectiveMarkIDs !== undefined) {
		const { added, removed } = getArrayDiff(
			initialFormValues.learningObjectiveMarkIDs,
			formValues.learningObjectiveMarkIDs,
		);
		input.addLearningObjectiveMarkIDs = added;
		input.removeLearningObjectiveMarkIDs = removed;
	}

	return input;
}

/**
 * Converts a ReportCard instance to its corresponding detail form values.
 *
 * @param reportCard The instance to convert.
 */
export function toFormValues(reportCard: ReportCardDetailQuery["reportCard"]): ReportCardFormValues.Detail {
	return {
		issueDate: reportCard.issueDate,
		learningObjectiveMarkIDs: reportCard.learningObjectiveMarks.map((e) => e.id),
		studentID: reportCard.student?.id ?? null,
		termID: reportCard.term?.id ?? null,
	};
}
