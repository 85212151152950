import { newPageInfo } from "@hex-insights/router";
import { Term } from "@hex-insights/verita.shared";
import { termSetupPageInfo } from "../pageinfo";

type TermForRouteTitle = Pick<Term, "name">;

export const termSetupDetailPageInfo = newPageInfo({
	name: "Term Overview",
	title: (e: TermForRouteTitle) => e.name,
	description: "Overview of term setup.",
	exact: false,
	noSearch: true,
	to: (id: Term["id"]) => termSetupPageInfo.to + `/${id}`,
	path: termSetupPageInfo.path + "/:termID(\\d+)",
	parent: termSetupPageInfo,
});
