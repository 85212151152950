import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const relationshipHubPageInfo = newPageInfo({
	title: "Relationships",
	description: "Pages related to the Relationship model.",
	exact: false,
	to: hubsPageInfo.to + "/relationships",
	path: hubsPageInfo.path + "/relationships",
	parent: hubsPageInfo,
});
