import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { BillingSetupSubmissionFilterInput } from "../../GraphQL";
import { BillingSetupSubmissionFilterFormValues } from "../ModelFilterFormValues";

/**
 * Counts the number of active filters for the BillingSetupSubmission model.
 *
 * @param formValues The form values of the filter form.
 */
export function countActiveFilters(formValues: BillingSetupSubmissionFilterFormValues.FormValues) {
	// TODO count standard filters
	const numAdvancedFilters = IndexForms.countActiveAdvancedFilters(
		formValues,
		BillingSetupSubmissionFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	);
	return numAdvancedFilters;
}

/**
 * Clears the values of the filter form for the BillingSetupSubmission model.
 *
 * @param formState The form state of the filter form.
 */
export function clearFilters(formState: FormState<BillingSetupSubmissionFilterFormValues.FormValues>) {
	// TODO clear standard filters
	if (formState.formClearFunctions.filterSets) {
		formState.formClearFunctions.filterSets();
	}
}

/**
 * Resets the values of the filter form for the BillingSetupSubmission model.
 *
 * @param formState The form state of the filter form.
 */
export function resetFilters(formState: FormState<BillingSetupSubmissionFilterFormValues.FormValues>) {
	// TODO reset standard filters
	if (formState.formResetFunctions.filterSets) {
		formState.formResetFunctions.filterSets();
	}
}

/**
 * Converts the form values of the filter form for the BillingSetupSubmission model to the correct GraphQL
 * filter input type.
 *
 * @param formValues The form values of the filter form.
 */
export function toFilterInputs(
	formValues: BillingSetupSubmissionFilterFormValues.FormValues,
): BillingSetupSubmissionFilterInput | BillingSetupSubmissionFilterInput[] {
	return IndexForms.toFilterInput(
		formValues,
		BillingSetupSubmissionFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	) as BillingSetupSubmissionFilterInput;
}
