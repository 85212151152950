import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { CalendarEventHubCreatePage } from "./CreatePage";
import { calendarEventHubCreatePageInfo } from "./CreatePage/pageinfo";
import { CalendarEventHubDetailPage } from "./DetailPage";
import { calendarEventHubDetailPageInfo } from "./DetailPage/pageinfo";
import { CalendarEventHubIndexPage } from "./IndexPage";
import { calendarEventHubIndexPageInfo } from "./IndexPage/pageinfo";
import { calendarEventHubPageInfo } from "./pageinfo";

export function CalendarEventHub() {
	useActivePageRegistration(calendarEventHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...calendarEventHubIndexPageInfo.routeProps}>
				<CalendarEventHubIndexPage />
			</Route>
			<Route {...calendarEventHubCreatePageInfo.routeProps}>
				<CalendarEventHubCreatePage />
			</Route>
			<Route {...calendarEventHubDetailPageInfo.routeProps}>
				<CalendarEventHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
