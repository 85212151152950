import { Redirect, Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../Components";
import { DashboardsAcademicPage } from "./Academic";
import { dashboardsAcademicPageInfo } from "./Academic/pageinfo";
import { DashboardsDirectorPage } from "./Director";
import { dashboardsDirectorPageInfo } from "./Director/pageinfo";
import { DashboardsFinancialsPage } from "./Financials";
import { dashboardsFinancialsPageInfo } from "./Financials/pageinfo";
import { DashboardsFrontDeskPage } from "./FrontDesk";
import { dashboardsFrontDeskPageInfo } from "./FrontDesk/pageinfo";
import { DashboardsGeneralPage } from "./General";
import { dashboardsGeneralPageInfo } from "./General/pageinfo";
import { dashboardsPageInfo } from "./pageinfo";

export function DashboardsPage() {
	useActivePageRegistration(dashboardsPageInfo);

	return (
		<PageRouteSwitch>
			<Route exact path={dashboardsPageInfo.path}>
				<Redirect to={dashboardsGeneralPageInfo.to} />
			</Route>
			<Route {...dashboardsDirectorPageInfo.routeProps}>
				<DashboardsDirectorPage />
			</Route>
			<Route {...dashboardsAcademicPageInfo.routeProps}>
				<DashboardsAcademicPage />
			</Route>
			<Route {...dashboardsFinancialsPageInfo.routeProps}>
				<DashboardsFinancialsPage />
			</Route>
			<Route {...dashboardsFrontDeskPageInfo.routeProps}>
				<DashboardsFrontDeskPage />
			</Route>
			<Route {...dashboardsGeneralPageInfo.routeProps}>
				<DashboardsGeneralPage />
			</Route>
		</PageRouteSwitch>
	);
}
