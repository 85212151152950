import React from "react";
import { Button, Column } from "@hex-insights/core";
import { SubFormRenderProps, useInternalField } from "@hex-insights/forms";
import { NoteAttachmentFormValues } from "../../../../Utilities";
import { NoteAttachmentForm } from "../NoteAttachment";

export function NoteAttachmentItemForm({
	formState,
	onRemoveClick,
	index,
}: SubFormRenderProps<NoteAttachmentFormValues.CreateInNote>) {
	const { order: setOrder } = formState.formSetFunctions;
	React.useEffect(() => {
		setOrder(index);
	}, [setOrder, index]);
	useInternalField(formState, "order");

	return (
		<Column justify="spaced-start">
			<Button variant="tertiary" size="small" onClick={onRemoveClick} disabled={formState.disabled}>
				Remove Attachment
			</Button>
			<NoteAttachmentForm.CreateInNote formState={formState} />
		</Column>
	);
}
