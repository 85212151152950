import React from "react";
import { Grid, Icon, Modal, Row, StyleProps, toGrammaticalNumber, toLocalDateString } from "@hex-insights/core";
import {
	Campus,
	ControlledStudentListModal,
	EmployeeFilterInput,
	EmployeeWrapperContext,
	HomeRoomSectionFilterInput,
	StudentFilterInput,
	Tile,
	useCampusObjectCountsQuery,
	useEmployeeIndexQuery,
	useHomeRoomSectionIndexQuery,
} from "@hex-insights/verita.shared";
import {
	EmployeeListModal,
	EmployeeListModalProps,
	HomeRoomSectionsListModal,
	HomeRoomSectionsListModalProps,
} from "../../../CampusOverviews";
import styles from "./styles.module.css";

export function DataLists() {
	const { employee } = React.useContext(EmployeeWrapperContext);
	const campusIDs = employee?.campuses.map((e) => e.id) ?? [];

	const { loading, data } = useCampusObjectCountsQuery({
		variables: {
			studentFilters: getStudentFilters(campusIDs),
			homeRoomSectionFilters: getHomeRoomSectionFilters(campusIDs),
			employeeFilters: getEmployeeFilters(campusIDs),
		},
	});

	return (
		<Grid columns={3} gap="var(--general--spaced---horizontal-spacing)">
			<StudentList isLoading={loading} numItems={data?.studentConnection.totalCount ?? 0} style={{ width: "100%" }} />
			<HomeRoomList
				isLoading={loading}
				numItems={data?.homeRoomSectionConnection.totalCount ?? 0}
				style={{ width: "100%" }}
			/>
			<EmployeeList isLoading={loading} numItems={data?.employeeConnection.totalCount ?? 0} style={{ width: "100%" }} />
		</Grid>
	);
}

type DataListProps = {
	isLoading: boolean;
	numItems: number;
} & Partial<StyleProps>;

function StudentList({ isLoading, numItems, style }: DataListProps) {
	const { employee } = React.useContext(EmployeeWrapperContext);
	const campusIDs = employee?.campuses.map((e) => e.id);
	const initialFilterFormValues = React.useMemo(() => ({ campusIDs: campusIDs ?? [] }), [campusIDs]);

	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<div style={style} onClick={toggleIsModalOpen}>
				<Tile className={styles["data-list-tile"]}>
					<Tile.Body className={styles["data-list-tile__body"]}>
						<Row justify="spaced-start" align="center">
							<span className={styles["data-list-tile__number"]}>{isLoading ? "..." : numItems}</span>

							<span className={styles["data-list-tile__label"]}>{toGrammaticalNumber("Student", numItems)}</span>

							<Icon.ChevronRight
								size="2.5rem"
								className={styles["data-list-tile__expand-icon"]}
								style={{ display: "block", marginLeft: "auto" }}
							/>
						</Row>
					</Tile.Body>
				</Tile>
			</div>

			<Modal.If condition={isModalOpen}>
				<ControlledStudentListModal
					title="Students"
					initialFilterFormValues={initialFilterFormValues}
					onClose={toggleIsModalOpen}
				/>
			</Modal.If>
		</React.Fragment>
	);
}

function getStudentFilters(campusIDs: Campus["id"][]): StudentFilterInput | StudentFilterInput[] {
	const today = toLocalDateString(new Date());

	return [
		{
			homeRoomSectionStudentEnrollments: [
				{
					homeRoomSection: [
						{
							room: [{ campusIDIn: campusIDs }],
							term: [{ startDateLTE: today, endDateGTE: today }],
						},
					],
				},
			],
		},
		{
			courseSectionStudentEnrollments: [
				{
					courseSection: [
						{
							courseSectionPeriods: [{ room: [{ campusIDIn: campusIDs }] }],
							term: [{ startDateLTE: today, endDateGTE: today }],
						},
					],
				},
			],
		},
	];
}

function HomeRoomList({ isLoading, numItems, style }: DataListProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<div style={style} onClick={toggleIsModalOpen}>
				<Tile className={styles["data-list-tile"]}>
					<Tile.Body className={styles["data-list-tile__body"]}>
						<Row justify="spaced-start" align="center">
							<span className={styles["data-list-tile__number"]}>{isLoading ? "..." : numItems}</span>

							<span className={styles["data-list-tile__label"]}>{toGrammaticalNumber("Home Room", numItems)}</span>

							<Icon.ChevronRight
								size="2.5rem"
								className={styles["data-list-tile__expand-icon"]}
								style={{ display: "block", marginLeft: "auto" }}
							/>
						</Row>
					</Tile.Body>
				</Tile>
			</div>

			<Modal.If condition={isModalOpen}>
				<ControlledHomeRoomSectionsListModal title="Home Room Sections" onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type ControlledHomeRoomSectionsListModalProps = {
	title: React.ReactNode;
} & Pick<HomeRoomSectionsListModalProps, "ifRef" | "onClose">;

function ControlledHomeRoomSectionsListModal({ title, ifRef, onClose }: ControlledHomeRoomSectionsListModalProps) {
	const { employee } = React.useContext(EmployeeWrapperContext);
	const campusIDs = employee?.campuses.map((e) => e.id) ?? [];

	const filters = getHomeRoomSectionFilters(campusIDs);
	const { loading, data } = useHomeRoomSectionIndexQuery({ variables: { filters } });

	return (
		<HomeRoomSectionsListModal
			title={title}
			isLoading={loading}
			edges={data?.homeRoomSectionConnection.edges}
			filters={filters}
			ifRef={ifRef}
			onClose={onClose}
		/>
	);
}

function getHomeRoomSectionFilters(
	campusIDs: Campus["id"][],
): HomeRoomSectionFilterInput | HomeRoomSectionFilterInput[] {
	const today = toLocalDateString(new Date());
	return {
		room: [{ campusIDIn: campusIDs }],
		term: [{ startDateLTE: today, endDateGTE: today }],
	};
}

function EmployeeList({ isLoading, numItems, style }: DataListProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<div style={style} onClick={toggleIsModalOpen}>
				<Tile className={styles["data-list-tile"]}>
					<Tile.Body className={styles["data-list-tile__body"]}>
						<Row justify="spaced-start" align="center">
							<span className={styles["data-list-tile__number"]}>{isLoading ? "..." : numItems}</span>

							<span className={styles["data-list-tile__label"]}>Faculty &amp; Staff</span>

							<Icon.ChevronRight
								size="2.5rem"
								className={styles["data-list-tile__expand-icon"]}
								style={{ display: "block", marginLeft: "auto" }}
							/>
						</Row>
					</Tile.Body>
				</Tile>
			</div>

			<Modal.If condition={isModalOpen}>
				<ControlledEmployeeListModal title="Faculty & Staff" onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type ControlledEmployeeListModalProps = {
	title: React.ReactNode;
} & Pick<EmployeeListModalProps, "ifRef" | "onClose">;

function ControlledEmployeeListModal({ title, ifRef, onClose }: ControlledEmployeeListModalProps) {
	const { employee } = React.useContext(EmployeeWrapperContext);
	const campusIDs = employee?.campuses.map((e) => e.id) ?? [];

	const { loading, data } = useEmployeeIndexQuery({
		variables: { filters: getEmployeeFilters(campusIDs) },
	});

	return (
		<EmployeeListModal
			title={title}
			isLoading={loading}
			edges={data?.employeeConnection.edges}
			ifRef={ifRef}
			onClose={onClose}
		/>
	);
}

function getEmployeeFilters(campusIDs: Campus["id"][]): EmployeeFilterInput | EmployeeFilterInput[] {
	return {
		endDateIsNull: true,
		campuses: [{ idIn: campusIDs }],
	};
}
