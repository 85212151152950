import React from "react";
import { BooleanField, DateTimeField, FieldDisplayArgs, FormType, SelectField, TextField } from "@hex-insights/forms";
import {
	LoginRecordFormValues,
	SessionSelect,
	UserSelect,
	useSessionSelectLazyQuery,
	useUserSelectLazyQuery,
} from "../../../../Utilities";
import { SessionLink, UserLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the LoginRecord model.
 */
type FieldProps<K extends keyof LoginRecordFormValues.Base = keyof LoginRecordFormValues.Base> = BaseFieldProps<
	Pick<LoginRecordFormValues.Base, K>
>;

/**
 * Generic props for fields of the LoginRecord model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof LoginRecordFormValues.Detail = keyof LoginRecordFormValues.Detail> =
	BaseFieldProps<Pick<LoginRecordFormValues.Detail, K>>;

/**
 * Renders a field component for the `createdAt` field of the LoginRecord model.
 */
export function CreatedAt({ formState, formType = FormType.Update }: FieldProps<"createdAt">) {
	return (
		<DateTimeField formState={formState} name="createdAt" optional={FormType.isCreate(formType)} precision="minute" />
	);
}

/**
 * Renders a field component for the `userEmail` field of the LoginRecord model.
 */
export function UserEmail({ formState }: FieldProps<"userEmail">) {
	return <TextField formState={formState} name="userEmail" />;
}

/**
 * Renders a field component for the `wasBlocked` field of the LoginRecord model.
 */
export function WasBlocked({ formState }: FieldProps<"wasBlocked">) {
	return <BooleanField formState={formState} name="wasBlocked" />;
}

/**
 * Renders a field component for the `wasSuccessful` field of the LoginRecord model.
 */
export function WasSuccessful({ formState }: FieldProps<"wasSuccessful">) {
	return <BooleanField formState={formState} name="wasSuccessful" />;
}

/**
 * Renders a field component for the `isTrusted` field of the LoginRecord model.
 */
export function IsTrusted({ formState }: FieldProps<"isTrusted">) {
	return <BooleanField formState={formState} name="isTrusted" />;
}

export type SessionProps = DetailFieldProps<"sessionID"> & {
	currentSession?: SessionSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `session` edge of the LoginRecord model.
 */
export function Session({ formState, currentSession }: SessionProps) {
	const [loadOptions, { loading, data }] = useSessionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.sessionID) {
			loadOptions();
		}
	}, [formState.formEditing.sessionID, loadOptions]);
	const options = React.useMemo(
		() => SessionSelect.toOptions(data?.sessionConnection.edges, currentSession),
		[data, currentSession],
	);

	return (
		<SelectField
			formState={formState}
			name="sessionID"
			isLoading={loading}
			options={options}
			optional
			display={displaySession}
			blankValue={null}
		/>
	);
}

function displaySession({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <SessionLink instance={{ id }}>{formattedValue}</SessionLink>;
}

export type UserProps = DetailFieldProps<"userID"> & {
	currentUser?: UserSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `user` edge of the LoginRecord model.
 */
export function User({ formState, currentUser }: UserProps) {
	const [loadOptions, { loading, data }] = useUserSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.userID) {
			loadOptions();
		}
	}, [formState.formEditing.userID, loadOptions]);
	const options = React.useMemo(
		() => UserSelect.toOptions(data?.userConnection.edges, currentUser),
		[data, currentUser],
	);

	return (
		<SelectField
			formState={formState}
			name="userID"
			isLoading={loading}
			options={options}
			optional
			display={displayUser}
			blankValue={null}
		/>
	);
}

function displayUser({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserLink instance={{ id }}>{formattedValue}</UserLink>;
}
