import { Column, Grid, Heading, Row, Section } from "@hex-insights/core";
import { Label } from "@hex-insights/forms";
import { EventCalendarTile, ResourcesTile, Tile } from "@hex-insights/verita.shared";

export function FrontDeskDashboard() {
	return (
		<Row
			justify="spaced-start"
			style={{
				height: "calc(100vh - var(--header---height) - 2 * var(--main---padding-top))",
			}}
		>
			<Column justify="spaced-start" style={{ flexGrow: 1, minWidth: 0 }}>
				<Tile>
					<Tile.Body>Announcements</Tile.Body>
				</Tile>
				<Tile style={{ flexGrow: 1 }}>
					<Tile.Body>
						<Column justify="spaced-start">
							<Row justify="spaced-start">
								<input type="text" placeholder="Search Students" />

								<Column align="center">
									<Label>Student Attendance</Label>
									<span style={{ fontSize: "1.5rem" }}>154/160</span>
								</Column>
							</Row>

							<Section>
								<Section.Header>
									<Heading level={3} noMargin>
										Classes In Progress
									</Heading>
								</Section.Header>
								<Section.Body>
									<Grid columns={2}>
										<div>Maths (Room 102)</div>
										<div>Circle Time (Room 203)</div>
										<div>P.E. (Field)</div>
										<div>Class 1 (Room XXX)</div>
										<div>Class 2 (Room XXX)</div>
										<div>Class 3 (Room XXX)</div>
										<div>Class 4 (Room XXX)</div>
									</Grid>
								</Section.Body>
							</Section>
						</Column>
					</Tile.Body>
				</Tile>
				<FrontDeskResourcesTile />
			</Column>
			<Column justify="spaced-start" style={{ width: "33%", height: "100%" }}>
				<Tile>
					<Tile.Body>QUICK ACTIONS</Tile.Body>
				</Tile>
				<EventCalendarTile style={{ flexGrow: 1, height: "100%", overflow: "hidden" }} />
				<Tile>
					<Tile.Body>
						NOTES FEED <div style={{ height: "7rem" }}></div>
					</Tile.Body>
				</Tile>
			</Column>
		</Row>
	);
}

export function FrontDeskResourcesTile() {
	return <ResourcesTile />;
}
