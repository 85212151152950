import { newPageInfo } from "@hex-insights/router";
import { ServiceSubscription } from "@hex-insights/verita.shared";
import { serviceSubscriptionHubPageInfo } from "../pageinfo";

type ServiceSubscriptionForRouteTitle = Pick<ServiceSubscription, "id">;

export const serviceSubscriptionHubDetailPageInfo = newPageInfo({
	name: "Service Subscription Detail",
	title: (e: ServiceSubscriptionForRouteTitle) => e.id,
	description: "Detail page for an serviceSubscription.",
	exact: false,
	isLeaf: true,
	to: (id: ServiceSubscription["id"]) => serviceSubscriptionHubPageInfo.to + `/${id}`,
	path: serviceSubscriptionHubPageInfo.path + "/:id(\\d+)",
	parent: serviceSubscriptionHubPageInfo,
});
