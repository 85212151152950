import { newPageInfo } from "@hex-insights/router";
import { Invoice } from "@hex-insights/verita.shared";
import { invoiceHubPageInfo } from "../pageinfo";

type InvoiceForRouteTitle = Pick<Invoice, "id">;

export const invoiceHubDetailPageInfo = newPageInfo({
	name: "Invoice Detail",
	title: (e: InvoiceForRouteTitle) => e.id,
	description: "Detail page for an invoice.",
	exact: false,
	isLeaf: true,
	to: (id: Invoice["id"]) => invoiceHubPageInfo.to + `/${id}`,
	path: invoiceHubPageInfo.path + "/:id(\\d+)",
	parent: invoiceHubPageInfo,
});
