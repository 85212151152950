import { Column, Conditional, formatPercentage, If, Row } from "@hex-insights/core";
import { Label } from "@hex-insights/forms";
import { Tile } from "@hex-insights/verita.shared";

export function ReportingTile() {
	const invoicesPercentageChange = 0.25;
	const paymentsPercentageChange = -0.17;
	const expensesPercentageChange = 0.11;

	return (
		<Tile>
			<Tile.Body>
				<Row justify="space-between">
					<Statistic label="Invoices" value={invoicesPercentageChange} />
					<Statistic label="Payments" value={paymentsPercentageChange} />
					<Statistic label="Expenses" value={expensesPercentageChange} downIsGood />
				</Row>
			</Tile.Body>
		</Tile>
	);
}

type StatisticProps = {
	label: string;
	value: number;
	downIsGood?: boolean;
};

function Statistic({ label, value, downIsGood = false }: StatisticProps) {
	return (
		<Column align="center">
			<Label>{label}</Label>
			<span style={{ color: getStatisticColor(value, downIsGood), fontSize: "1.5rem" }}>
				{formatPercentage(value)}
				<Conditional>
					<If condition={value > 0}>&#8599;</If>
					<If condition={value < 0}>&#8600;</If>
				</Conditional>
			</span>
		</Column>
	);
}

function getStatisticColor(value: number, downIsGood = false) {
	if (value > 0) {
		return downIsGood ? "var(--danger-color)" : "var(--success-color)";
	}
	if (value < 0) {
		return downIsGood ? "var(--success-color)" : "var(--danger-color)";
	}
	return undefined;
}
