import { Row } from "@hex-insights/core";
import { NavLink, Redirect, Route, RouteSwitch, useActivePageRegistration } from "@hex-insights/router";
import { Main } from "@hex-insights/verita.shared";
import { AdmissionsApplicationsPage } from "./Applications";
import { admissionsApplicationsPageInfo } from "./Applications/pageinfo";
import { AdmissionsStats } from "./Components";
import { AdmissionsEnrollmentInvitationsPage } from "./EnrollmentInvitations";
import { admissionsEnrollmentInvitationsPageInfo } from "./EnrollmentInvitations/pageinfo";
import { AdmissionsEnrollmentsPage } from "./Enrollments";
import { admissionsEnrollmentsPageInfo } from "./Enrollments/pageinfo";
import { AdmissionsMeetingsPage } from "./Meetings";
import { admissionsMeetingsPageInfo } from "./Meetings/pageinfo";
import { admissionsPageInfo } from "./pageinfo";
import { AdmissionsReportsPage } from "./Reports";
import { admissionsReportsPageInfo } from "./Reports/pageinfo";
import styles from "./styles.module.css";

export function AdmissionsPage() {
	useActivePageRegistration(admissionsPageInfo);

	return (
		<Main>
			<AdmissionsStats className={styles["admissions-stats"]} />

			<nav className={styles["nav"]}>
				<Row justify="spaced-start">
					<NavLink
						to={admissionsApplicationsPageInfo.to}
						className={styles["nav-link"]}
						activeClassName={styles["nav-link--location-active"]}
					>
						Applications
					</NavLink>
					<NavLink
						to={admissionsMeetingsPageInfo.to}
						className={styles["nav-link"]}
						activeClassName={styles["nav-link--location-active"]}
					>
						Meetings
					</NavLink>
					<NavLink
						to={admissionsEnrollmentInvitationsPageInfo.to}
						className={styles["nav-link"]}
						activeClassName={styles["nav-link--location-active"]}
					>
						Enrollment Invitations
					</NavLink>
					<NavLink
						to={admissionsEnrollmentsPageInfo.to}
						className={styles["nav-link"]}
						activeClassName={styles["nav-link--location-active"]}
					>
						Enrollments
					</NavLink>
					<NavLink
						to={admissionsReportsPageInfo.to}
						className={styles["nav-link"] + " " + styles["nav-link--reports"]}
						activeClassName={styles["nav-link--location-active"]}
					>
						Reports
					</NavLink>
				</Row>
			</nav>

			<RouteSwitch>
				<Route exact path={admissionsPageInfo.path}>
					<Redirect to={admissionsApplicationsPageInfo.to} />
				</Route>
				<Route {...admissionsApplicationsPageInfo.routeProps}>
					<AdmissionsApplicationsPage />
				</Route>
				<Route {...admissionsMeetingsPageInfo.routeProps}>
					<AdmissionsMeetingsPage />
				</Route>
				<Route {...admissionsEnrollmentInvitationsPageInfo.routeProps}>
					<AdmissionsEnrollmentInvitationsPage />
				</Route>
				<Route {...admissionsEnrollmentsPageInfo.routeProps}>
					<AdmissionsEnrollmentsPage />
				</Route>
				<Route {...admissionsReportsPageInfo.routeProps}>
					<AdmissionsReportsPage />
				</Route>
			</RouteSwitch>
		</Main>
	);
}
