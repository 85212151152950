import { LoginRecord } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the createdAt field of the LoginRecord model.
 */
export function createdAt(value: LoginRecord["createdAt"] | null): string {
	return toString(value);
}

/**
 * Formats the userEmail field of the LoginRecord model.
 */
export function userEmail(value: LoginRecord["userEmail"]): string {
	return toString(value);
}

/**
 * Formats the wasBlocked field of the LoginRecord model.
 */
export function wasBlocked(value: LoginRecord["wasBlocked"]): string {
	return toString(value);
}

/**
 * Formats the wasSuccessful field of the LoginRecord model.
 */
export function wasSuccessful(value: LoginRecord["wasSuccessful"]): string {
	return toString(value);
}

/**
 * Formats the isTrusted field of the LoginRecord model.
 */
export function isTrusted(value: LoginRecord["isTrusted"]): string {
	return toString(value);
}
