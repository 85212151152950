import { IndexForms } from "@hex-insights/app-modules";
import { AddressFilterFormValues } from "../../../../Utilities";

export type AddressSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the Address model.
 */
export function AddressSearchForm(props: AddressSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type AddressFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, AddressFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Address model.
 */
export function AddressFilterFormController(props: AddressFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={AddressFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `AddressFilterForm`
