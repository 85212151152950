import { newPageInfo } from "@hex-insights/router";
import { documentResourceUserViewRecordHubPageInfo } from "../pageinfo";

export const documentResourceUserViewRecordHubCreatePageInfo = newPageInfo({
	name: "New Document Resource User View Record",
	title: "New",
	description: "Create a new Document Resource User View Record record.",
	exact: true,
	to: documentResourceUserViewRecordHubPageInfo.to + "/new",
	path: documentResourceUserViewRecordHubPageInfo.path + "/new",
	parent: documentResourceUserViewRecordHubPageInfo,
});
