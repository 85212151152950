import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { CampusFilterInput, CampusOrder, useCampusPaginationQuery } from "../../../../Utilities";

export type CampusPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: CampusFilterInput | CampusFilterInput[] | null;
	order: CampusOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the Campus model.
 */
export function CampusPaginationForm({ formState, filters, order, setPaginationInfo }: CampusPaginationFormProps) {
	const { loading, data } = useCampusPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.campusConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
