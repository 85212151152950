import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { EnrollmentApplicationStudentOrderFormValues } from "../../../../Utilities";

export type EnrollmentApplicationStudentOrderFormControllerProps = {
	formState: FormState<EnrollmentApplicationStudentOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the EnrollmentApplicationStudent model.
 */
export function EnrollmentApplicationStudentOrderFormController({
	formState,
}: EnrollmentApplicationStudentOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={EnrollmentApplicationStudentOrderFormValues.fieldOptions}
			fieldTypeMapping={EnrollmentApplicationStudentOrderFormValues.fieldTypeMapping}
		/>
	);
}
