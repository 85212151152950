import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { EnrollmentApplicationOrderFormValues } from "../../../../Utilities";

export type EnrollmentApplicationOrderFormControllerProps = {
	formState: FormState<EnrollmentApplicationOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the EnrollmentApplication model.
 */
export function EnrollmentApplicationOrderFormController({ formState }: EnrollmentApplicationOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={EnrollmentApplicationOrderFormValues.fieldOptions}
			fieldTypeMapping={EnrollmentApplicationOrderFormValues.fieldTypeMapping}
		/>
	);
}
