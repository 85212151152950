import { newPageInfo } from "@hex-insights/router";
import { Parent } from "@hex-insights/verita.shared";
import { parentHubPageInfo } from "../pageinfo";

export type ParentHubDetailPageRouteParams = {
	id: string;
};

type ParentForRouteTitle = { person: Pick<Parent["person"], "name"> };

export const parentHubDetailPageInfo = newPageInfo({
	name: "Parent Detail",
	title: (e: ParentForRouteTitle) => e.person.name,
	description: "Detail page for an parent.",
	exact: false,
	isLeaf: true,
	to: (id: Parent["id"]) => parentHubPageInfo.to + `/${id}`,
	path: parentHubPageInfo.path + "/:id(\\d+)",
	parent: parentHubPageInfo,
});
