import { Route, RouteSwitch, useActivePageRegistration, useRouteMatch } from "@hex-insights/router";
import { ResourcesFoldersDetailPage } from "./DetailPage";
import { resourcesFoldersDetailPageInfo, ResourcesFoldersDetailPageRouteParams } from "./DetailPage/pageinfo";
import { ResourcesFoldersIndexPage } from "./IndexPage";
import { resourcesFoldersIndexPageInfo } from "./IndexPage/pageinfo";
import { resourcesFoldersPageInfo } from "./pageinfo";

export function ResourcesFoldersPage() {
	useActivePageRegistration(resourcesFoldersPageInfo);

	return (
		<RouteSwitch>
			<Route {...resourcesFoldersIndexPageInfo.routeProps}>
				<ResourcesFoldersIndexPage />
			</Route>
			<Route {...resourcesFoldersDetailPageInfo.routeProps}>
				<ResourcesFoldersDetailPage
					key={
						useRouteMatch<ResourcesFoldersDetailPageRouteParams>(resourcesFoldersDetailPageInfo.path as string)?.params
							.documentResourceFolderID
					}
				/>
			</Route>
		</RouteSwitch>
	);
}
