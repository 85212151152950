import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	QuotationForm,
	QuotationFormValues,
	QuotationMutation,
	useQuotationDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { quotationHubDetailPageInfo } from "./pageinfo";

function QuotationHubDetailPageWrapper() {
	// Manages identity of the QuotationHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <QuotationHubDetailPage key={id} />;
}

export { QuotationHubDetailPageWrapper as QuotationHubDetailPage };

function QuotationHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useQuotationDetailQuery({ variables: { id } });
	const quotation = data?.quotation;
	useActivePageRegistration(quotationHubDetailPageInfo, !!quotation ? quotationHubDetailPageInfo.title(quotation) : "");

	const update = QuotationMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<QuotationFormValues.Detail>, initialFormValues: QuotationFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading quotation</If>
				<Else>
					{!!quotation && (
						<React.Fragment>
							<Heading level={1}>{quotation.id}</Heading>

							<QuotationForm.ControlledDetail
								key={syncID}
								quotation={quotation}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
