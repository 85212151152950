import { newPageInfo } from "@hex-insights/router";
import { PhoneNumber } from "@hex-insights/verita.shared";
import { phoneNumberHubPageInfo } from "../pageinfo";

type PhoneNumberForRouteTitle = Pick<PhoneNumber, "phoneNumber">;

export const phoneNumberHubDetailPageInfo = newPageInfo({
	name: "Phone Number Detail",
	title: (e: PhoneNumberForRouteTitle) => e.phoneNumber,
	description: "Detail page for an phoneNumber.",
	exact: false,
	isLeaf: true,
	to: (id: PhoneNumber["id"]) => phoneNumberHubPageInfo.to + `/${id}`,
	path: phoneNumberHubPageInfo.path + "/:id(\\d+)",
	parent: phoneNumberHubPageInfo,
});
