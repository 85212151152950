import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { LessonPlanFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the LessonPlan model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => LessonPlanFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(
		initialFormValues,
		LessonPlanFilterFormValues.typeFilterObject,
	);
}
