import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { RoomHubCreatePage } from "./CreatePage";
import { roomHubCreatePageInfo } from "./CreatePage/pageinfo";
import { RoomHubDetailPage } from "./DetailPage";
import { roomHubDetailPageInfo } from "./DetailPage/pageinfo";
import { RoomHubIndexPage } from "./IndexPage";
import { roomHubIndexPageInfo } from "./IndexPage/pageinfo";
import { roomHubPageInfo } from "./pageinfo";

export function RoomHub() {
	useActivePageRegistration(roomHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...roomHubIndexPageInfo.routeProps}>
				<RoomHubIndexPage />
			</Route>
			<Route {...roomHubCreatePageInfo.routeProps}>
				<RoomHubCreatePage />
			</Route>
			<Route {...roomHubDetailPageInfo.routeProps}>
				<RoomHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
