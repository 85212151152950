import React from "react";
import { useApolloClient } from "@apollo/client";
import { AsyncValidationFunction, invalid, valid } from "@hex-insights/forms";
import {
	UserGroup,
	UserGroupCheckUniqueNameDocument,
	UserGroupCheckUniqueNameQuery,
	UserGroupCheckUniqueNameQueryVariables,
} from "../../GraphQL";
import { UserGroupFormValues } from "../ModelFormValues";

/**
 * Returns an asynchronous validation function to check that the given name is unique for the
 * UserGroup model.
 *
 * @param ignoreID The ID of the instance to ignore (generally the current instance in an update form).
 */
export function useUniqueNameValidator(
	ignoreID?: UserGroup["id"],
): AsyncValidationFunction<UserGroupFormValues.Base["name"]> {
	const client = useApolloClient();
	return React.useCallback(
		async (value: UserGroupFormValues.Base["name"]) => {
			if (value === "") {
				return valid();
			}

			const { data } = await client.query<UserGroupCheckUniqueNameQuery, UserGroupCheckUniqueNameQueryVariables>({
				query: UserGroupCheckUniqueNameDocument,
				variables: { name: value, ignoreID },
			});

			if (data.userGroupConnection.totalCount === 0) {
				return valid();
			}

			return invalid("This name is taken.");
		},
		[client, ignoreID],
	);
}
