import React from "react";
import RawPhoneInput, { PhoneInputProps as RawPhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/plain.css";
import { TextFieldInputProps } from "@hex-insights/forms";
import "./styles.css";
import styles from "./styles.module.css";

type PhoneInputProps = TextFieldInputProps;

export function PhoneInput({
	id,
	name,
	value,
	setValue,
	placeholder,
	disabled,
	onFocus,
	onBlur,
	className,
}: PhoneInputProps) {
	const onChange = React.useCallback<Exclude<RawPhoneInputProps["onChange"], undefined>>(
		(value: string) => {
			setValue(value);
		},
		[setValue],
	);

	return (
		<RawPhoneInput
			value={value}
			onChange={onChange}
			disabled={disabled}
			onFocus={onFocus}
			onBlur={onBlur}
			placeholder={placeholder}
			inputProps={{ id: id ?? name }}
			inputClass={className}
			dropdownClass={styles["dropdown"]}
			buttonClass={styles["button"]}
			country="gr"
		/>
	);
}
