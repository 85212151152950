import { newPageInfo } from "@hex-insights/router";
import { mealPlanHubPageInfo } from "../pageinfo";

export const mealPlanHubIndexPageInfo = newPageInfo({
	name: "Meal Plans Index",
	title: "",
	description: "Index of Meal Plans.",
	exact: true,
	to: mealPlanHubPageInfo.to,
	path: mealPlanHubPageInfo.path,
	parent: mealPlanHubPageInfo,
});
