import { newPageInfo } from "@hex-insights/router";
import { expenseHubPageInfo } from "../pageinfo";

export const expenseHubIndexPageInfo = newPageInfo({
	name: "Expenses Index",
	title: "",
	description: "Index of Expenses.",
	exact: true,
	to: expenseHubPageInfo.to,
	path: expenseHubPageInfo.path,
	parent: expenseHubPageInfo,
});
