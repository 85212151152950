import { newPageInfo } from "@hex-insights/router";
import { busPlanHubPageInfo } from "../pageinfo";

export const busPlanHubCreatePageInfo = newPageInfo({
	name: "New Bus Plan",
	title: "New",
	description: "Create a new Bus Plan record.",
	exact: true,
	to: busPlanHubPageInfo.to + "/new",
	path: busPlanHubPageInfo.path + "/new",
	parent: busPlanHubPageInfo,
});
