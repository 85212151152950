import React from "react";
import { DocumentResource, DocumentResourceFolder } from "@hex-insights/verita.shared";

export type DragData = {
	id: string;
	name: string;
	model: "DocumentResource" | "DocumentResourceFolder";
};

export function getDocumentResourceOnDragStart(
	documentResource: Pick<DocumentResource, "id" | "name">,
): React.DragEventHandler<HTMLDivElement> {
	return (event) => {
		const data: DragData = { id: documentResource.id, name: documentResource.name, model: "DocumentResource" };
		event.dataTransfer.setData("text/plain", JSON.stringify(data));
	};
}

export function getDocumentResourceFolderOnDragStart(
	documentResourceFolder: Pick<DocumentResourceFolder, "id" | "name">,
): React.DragEventHandler<HTMLDivElement> {
	return (event) => {
		const data: DragData = {
			id: documentResourceFolder.id,
			name: documentResourceFolder.name,
			model: "DocumentResourceFolder",
		};
		event.dataTransfer.setData("text/plain", JSON.stringify(data));
	};
}

export function onDragOver(event: React.DragEvent<HTMLDivElement>) {
	event.preventDefault();
	event.dataTransfer.dropEffect = event.altKey ? "copy" : "move";
}

export function getOnDrop(
	callback: (dragData: DragData, isCopy: boolean) => void,
): React.DragEventHandler<HTMLDivElement> {
	return (event) => {
		event.preventDefault();
		try {
			const data = JSON.parse(event.dataTransfer.getData("text/plain"));
			callback(data, event.altKey);
		} catch (err) {
			console.error("Error in onDrop event: ", err);
		}
	};
}
