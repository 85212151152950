import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { ServiceSubscriptionHubCreatePage } from "./CreatePage";
import { serviceSubscriptionHubCreatePageInfo } from "./CreatePage/pageinfo";
import { ServiceSubscriptionHubDetailPage } from "./DetailPage";
import { serviceSubscriptionHubDetailPageInfo } from "./DetailPage/pageinfo";
import { ServiceSubscriptionHubIndexPage } from "./IndexPage";
import { serviceSubscriptionHubIndexPageInfo } from "./IndexPage/pageinfo";
import { serviceSubscriptionHubPageInfo } from "./pageinfo";

export function ServiceSubscriptionHub() {
	useActivePageRegistration(serviceSubscriptionHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...serviceSubscriptionHubIndexPageInfo.routeProps}>
				<ServiceSubscriptionHubIndexPage />
			</Route>
			<Route {...serviceSubscriptionHubCreatePageInfo.routeProps}>
				<ServiceSubscriptionHubCreatePage />
			</Route>
			<Route {...serviceSubscriptionHubDetailPageInfo.routeProps}>
				<ServiceSubscriptionHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
