import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { ExpenseForm, ExpenseFormValues, ExpenseMutation, useExpenseDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { expenseHubDetailPageInfo } from "./pageinfo";

function ExpenseHubDetailPageWrapper() {
	// Manages identity of the ExpenseHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <ExpenseHubDetailPage key={id} />;
}

export { ExpenseHubDetailPageWrapper as ExpenseHubDetailPage };

function ExpenseHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useExpenseDetailQuery({ variables: { id } });
	const expense = data?.expense;
	useActivePageRegistration(expenseHubDetailPageInfo, !!expense ? expenseHubDetailPageInfo.title(expense) : "");

	const update = ExpenseMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<ExpenseFormValues.Detail>, initialFormValues: ExpenseFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading expense</If>
				<Else>
					{!!expense && (
						<React.Fragment>
							<Heading level={1}>{expense.id}</Heading>

							<ExpenseForm.ControlledDetail
								key={syncID}
								expense={expense}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
