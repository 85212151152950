import { IndexForms } from "@hex-insights/app-modules";
import { CourseSectionFilterFormValues } from "../../../../Utilities";

export type CourseSectionSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the CourseSection model.
 */
export function CourseSectionSearchForm(props: CourseSectionSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type CourseSectionFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, CourseSectionFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the CourseSection model.
 */
export function CourseSectionFilterFormController(props: CourseSectionFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={CourseSectionFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `CourseSectionFilterForm`
