import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { HomeRoomOrderFormValues } from "../../../../Utilities";

export type HomeRoomOrderFormControllerProps = {
	formState: FormState<HomeRoomOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the HomeRoom model.
 */
export function HomeRoomOrderFormController({ formState }: HomeRoomOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={HomeRoomOrderFormValues.fieldOptions}
			fieldTypeMapping={HomeRoomOrderFormValues.fieldTypeMapping}
		/>
	);
}
