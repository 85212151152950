import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { LessonPlanFilterInput, LessonPlanOrder, useLessonPlanPaginationQuery } from "../../../../Utilities";

export type LessonPlanPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: LessonPlanFilterInput | LessonPlanFilterInput[] | null;
	order: LessonPlanOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the LessonPlan model.
 */
export function LessonPlanPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: LessonPlanPaginationFormProps) {
	const { loading, data } = useLessonPlanPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.lessonPlanConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
