import { Session } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the createdAt field of the Session model.
 */
export function createdAt(value: Session["createdAt"] | null): string {
	return toString(value);
}

/**
 * Formats the updatedAt field of the Session model.
 */
export function updatedAt(value: Session["updatedAt"] | null): string {
	return toString(value);
}

/**
 * Formats the expiresAt field of the Session model.
 */
export function expiresAt(value: Session["expiresAt"] | null): string {
	return toString(value);
}

/**
 * Formats the longestInactiveSec field of the Session model.
 */
export function longestInactiveSec(value: Session["longestInactiveSec"] | null): string {
	return toString(value);
}

/**
 * Formats the isVerified field of the Session model.
 */
export function isVerified(value: Session["isVerified"]): string {
	return toString(value);
}

/**
 * Formats the isCurrent field of the Session model.
 */
export function isCurrent(value: Session["isCurrent"]): string {
	return toString(value);
}
