import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	ClassYear,
	Course,
	graphTypeNames,
	HomeRoom,
	HomeRoomCreateInput,
	HomeRoomCreateMutation,
	HomeRoomDeleteMutation,
	HomeRoomDetailDocument,
	HomeRoomDetailQuery,
	HomeRoomDetailQueryVariables,
	HomeRoomSection,
	HomeRoomUpdateInput,
	HomeRoomUpdateMutation,
	useHomeRoomCreateMutation,
	useHomeRoomDeleteMutation,
	useHomeRoomUpdateMutation,
} from "../../GraphQL";
import { HomeRoomFormConversion } from "../ModelFormConversion";
import { HomeRoomFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the HomeRoom model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the HomeRoom model.
 */
export function useCreate() {
	const [createHomeRoom] = useHomeRoomCreateMutation();

	return React.useCallback(
		async (formValues: HomeRoomFormValues.Create) => {
			const input = HomeRoomFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createHomeRoom({ variables: { input }, update: updateCache });

			return { data: data?.createHomeRoom ?? null, errors: errors ?? null };
		},
		[createHomeRoom],
	);
}

/**
 * Returns an `update` function for the HomeRoom model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the HomeRoom model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: HomeRoom["id"]) {
	const [updateHomeRoom] = useHomeRoomUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<HomeRoomFormValues.Detail>, initialFormValues: HomeRoomFormValues.Detail) => {
			const input = HomeRoomFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateHomeRoom({ variables: { id, input }, update: updateCache });

			return { data: data?.updateHomeRoom ?? null, errors: errors ?? null };
		},
		[updateHomeRoom, id],
	);
}

/**
 * Returns a `del` function for the HomeRoom model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: HomeRoom["id"]) {
	const [deleteHomeRoom] = useHomeRoomDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteHomeRoom({ variables: { id }, update: updateCache });

		return { data: data?.deleteHomeRoom ?? false, errors: errors ?? null };
	}, [deleteHomeRoom, id]);
}

function getUpdateCacheForCreate(input: HomeRoomCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<HomeRoomCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createHomeRoom;

		cache.writeQuery<HomeRoomDetailQuery, HomeRoomDetailQueryVariables>({
			query: HomeRoomDetailDocument,
			data: { homeRoom: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.classYearID) {
			addToHomeRoomsOfClassYearCache(cache, input.classYearID, createdObject);
		}

		if (input.courseIDs) {
			for (let i = 0; i < input.courseIDs.length; i++) {
				addToHomeRoomOfCourseCache(cache, input.courseIDs[i], createdObject);
			}
		}

		if (input.homeRoomSectionIDs) {
			for (let i = 0; i < input.homeRoomSectionIDs.length; i++) {
				addToHomeRoomOfHomeRoomSectionCache(cache, input.homeRoomSectionIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "homeRoomConnection" });
	};
}

function getUpdateCacheForUpdate(input: HomeRoomUpdateInput, initialFormValues: HomeRoomFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<HomeRoomUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateHomeRoom;

		if (initialFormValues.classYearID && input.classYearID) {
			removeFromHomeRoomsOfClassYearCache(cache, initialFormValues.classYearID, updatedObject);
		}
		if (input.classYearID) {
			addToHomeRoomsOfClassYearCache(cache, input.classYearID, updatedObject);
		}

		if (input.addCourseIDs) {
			for (let i = 0; i < input.addCourseIDs.length; i++) {
				addToHomeRoomOfCourseCache(cache, input.addCourseIDs[i], updatedObject);
			}
		}
		if (input.removeCourseIDs) {
			for (let i = 0; i < input.removeCourseIDs.length; i++) {
				removeFromHomeRoomOfCourseCache(cache, input.removeCourseIDs[i], updatedObject);
			}
		}

		if (input.addHomeRoomSectionIDs) {
			for (let i = 0; i < input.addHomeRoomSectionIDs.length; i++) {
				addToHomeRoomOfHomeRoomSectionCache(cache, input.addHomeRoomSectionIDs[i], updatedObject);
			}
		}
		if (input.removeHomeRoomSectionIDs) {
			for (let i = 0; i < input.removeHomeRoomSectionIDs.length; i++) {
				removeFromHomeRoomOfHomeRoomSectionCache(cache, input.removeHomeRoomSectionIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: HomeRoom["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<HomeRoomDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteHomeRoom) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.HomeRoom }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "homeRoomConnection" });
		cache.gc();
	};
}

function addToHomeRoomsOfClassYearCache(
	cache: ApolloCache<any>,
	targetID: ClassYear["id"],
	object: Pick<HomeRoom, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ClassYear }),
		fields: {
			homeRooms: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromHomeRoomsOfClassYearCache(
	cache: ApolloCache<any>,
	targetID: ClassYear["id"],
	object: Pick<HomeRoom, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ClassYear }),
		fields: {
			homeRooms: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToHomeRoomOfCourseCache(cache: ApolloCache<any>, targetID: Course["id"], object: Pick<HomeRoom, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Course }),
		fields: {
			homeRoom: () => objectRef,
			homeRoomID: () => object.id,
		},
	});
}

function removeFromHomeRoomOfCourseCache(
	cache: ApolloCache<any>,
	targetID: Course["id"],
	_object: Pick<HomeRoom, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Course }),
		fields: {
			homeRoom: () => null,
			homeRoomID: () => null,
		},
	});
}

function addToHomeRoomOfHomeRoomSectionCache(
	cache: ApolloCache<any>,
	targetID: HomeRoomSection["id"],
	object: Pick<HomeRoom, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.HomeRoomSection }),
		fields: {
			homeRoom: () => objectRef,
			homeRoomID: () => object.id,
		},
	});
}

function removeFromHomeRoomOfHomeRoomSectionCache(
	cache: ApolloCache<any>,
	targetID: HomeRoomSection["id"],
	_object: Pick<HomeRoom, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.HomeRoomSection }),
		fields: {
			homeRoom: () => null,
			homeRoomID: () => null,
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<HomeRoom, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment HomeRoomRef on HomeRoom {
				id
			}
		`,
		data: object,
	});
}
