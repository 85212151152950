import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { ReportCardHubCreatePage } from "./CreatePage";
import { reportCardHubCreatePageInfo } from "./CreatePage/pageinfo";
import { ReportCardHubDetailPage } from "./DetailPage";
import { reportCardHubDetailPageInfo } from "./DetailPage/pageinfo";
import { ReportCardHubIndexPage } from "./IndexPage";
import { reportCardHubIndexPageInfo } from "./IndexPage/pageinfo";
import { reportCardHubPageInfo } from "./pageinfo";

export function ReportCardHub() {
	useActivePageRegistration(reportCardHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...reportCardHubIndexPageInfo.routeProps}>
				<ReportCardHubIndexPage />
			</Route>
			<Route {...reportCardHubCreatePageInfo.routeProps}>
				<ReportCardHubCreatePage />
			</Route>
			<Route {...reportCardHubDetailPageInfo.routeProps}>
				<ReportCardHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
