import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { CourseOrderFormValues } from "../../../../Utilities";

export type CourseOrderFormControllerProps = {
	formState: FormState<CourseOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the Course model.
 */
export function CourseOrderFormController({ formState }: CourseOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={CourseOrderFormValues.fieldOptions}
			fieldTypeMapping={CourseOrderFormValues.fieldTypeMapping}
		/>
	);
}
