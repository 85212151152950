import React from "react";
import {
	addTimeToDate,
	calculatePercentageChange,
	ClassNameProps,
	Column,
	formatInt,
	formatPercentage,
	Icon,
	Row,
	StyleProps,
	toLocalDateString,
	Tooltip,
} from "@hex-insights/core";
import { InternalLink } from "@hex-insights/router";
import {
	useAdmissionsApplicationDailyCountsReportQuery,
	useAdmissionsReenrollmentsByGradeReportQuery,
	useEnrollmentApplicationCountQuery,
	useSchoolStudentEnrollmentCountQuery,
} from "@hex-insights/verita.shared";
import { admissionsMeetingsPageInfo } from "../../Meetings/pageinfo";
import { admissionsReportsPageInfo } from "../../Reports/pageinfo";
import styles from "./styles.module.css";

export type AdmissionsStatsProps = Partial<ClassNameProps & StyleProps>;

export function AdmissionsStats({ className, style }: AdmissionsStatsProps) {
	const applicationStats = useApplicationStats();
	const meetingStats = useMeetingStats();
	const enrollmentStats = useEnrollmentStats();
	const reenrollmentStats = useReenrollmentStats();

	return (
		<Row justify="spaced-start" className={className} style={style}>
			<AdmissionsStat
				label="Applications in the Last Week"
				to={admissionsReportsPageInfo.to + "#applications-by-day"}
				value={formatInt(applicationStats.value)}
				trendValue={applicationStats.trendValue}
			/>
			<AdmissionsStat
				label="Meetings This Week"
				to={admissionsMeetingsPageInfo.to}
				value={formatInt(meetingStats.value)}
				trendValue={meetingStats.trendValue}
			/>
			<AdmissionsStat
				label="Enrollments for Next Year"
				to={admissionsReportsPageInfo.to + "#enrollments-by-grade"}
				value={formatInt(enrollmentStats.value)}
			/>
			<AdmissionsStat
				label="Re-enrollment Rate"
				to={admissionsReportsPageInfo.to + "#reenrollment-rates"}
				value={formatPercentage(reenrollmentStats.value)}
			/>
		</Row>
	);
}

function useApplicationStats() {
	const currentStartDate = toLocalDateString(addTimeToDate(new Date(), [-6, "days"]));
	const currentEndDate = toLocalDateString(new Date());
	const { data: currentData } = useAdmissionsApplicationDailyCountsReportQuery({
		variables: { filter: { startDate: currentStartDate, endDate: currentEndDate } },
		fetchPolicy: "cache-and-network",
	});
	const currentCount = currentData?.admissionsApplicationDailyCountsReport.data.reduce((a, e) => a + e.value, 0);

	const previousStartDate = toLocalDateString(addTimeToDate(new Date(), [-13, "days"]));
	const previousEndDate = toLocalDateString(addTimeToDate(new Date(), [-7, "day"]));
	const { data: previousData } = useAdmissionsApplicationDailyCountsReportQuery({
		variables: { filter: { startDate: previousStartDate, endDate: previousEndDate } },
		fetchPolicy: "cache-and-network",
	});
	const previousCount = previousData?.admissionsApplicationDailyCountsReport.data.reduce((a, e) => a + e.value, 0);

	return {
		value: currentCount,
		trendValue: calculatePercentageChange(previousCount ?? 0, currentCount ?? 0),
	};
}

function useMeetingStats() {
	const currentStartDate = toLocalDateString(new Date());
	const currentEndDate = toLocalDateString(addTimeToDate(new Date(), [6, "days"]));
	const { data: currentData } = useEnrollmentApplicationCountQuery({
		variables: { filters: { interviewMeetingTimeGTE: currentStartDate, interviewMeetingTimeLT: currentEndDate } },
		fetchPolicy: "cache-and-network",
	});
	const currentCount = currentData?.enrollmentApplicationConnection.totalCount;

	const previousStartDate = toLocalDateString(addTimeToDate(new Date(), [-7, "days"]));
	const previousEndDate = toLocalDateString(addTimeToDate(new Date(), [-1, "day"]));
	const { data: previousData } = useEnrollmentApplicationCountQuery({
		variables: { filters: { interviewMeetingTimeGTE: previousStartDate, interviewMeetingTimeLT: previousEndDate } },
		fetchPolicy: "cache-and-network",
	});
	const previousCount = previousData?.enrollmentApplicationConnection.totalCount;

	return {
		value: currentCount,
		trendValue: calculatePercentageChange(previousCount ?? 0, currentCount ?? 0),
	};
}

function useEnrollmentStats() {
	const { data } = useSchoolStudentEnrollmentCountQuery({
		variables: { filters: { academicYear: [{ nameEQ: "2023-2024" }], hasPaidFeeEQ: true } },
		fetchPolicy: "cache-and-network",
	});
	return {
		value: data?.schoolStudentEnrollmentConnection.totalCount,
	};
}

function useReenrollmentStats() {
	const { data } = useAdmissionsReenrollmentsByGradeReportQuery();
	return React.useMemo(() => {
		if (!data) {
			return { value: null };
		}
		let enrolledTotal = 0;
		let reenrolledTotal = 0;
		for (let i = 0; i < data.admissionsReenrollmentsByGradeReport.data.length; i++) {
			const point = data.admissionsReenrollmentsByGradeReport.data[i];
			enrolledTotal += point.enrolledValue;
			reenrolledTotal += point.reenrolledValue;
		}
		return {
			value: reenrolledTotal / enrolledTotal,
		};
	}, [data]);
}

type AdmissionsStatProps = {
	label: string;
	to: string;
	value: string;
	trendValue?: number;
};

function AdmissionsStat({ label, to, value, trendValue }: AdmissionsStatProps) {
	let trendText = "Not enough data to provide trends.";
	let trendIcon = <span></span>;
	let trendDirectionClassName = "";
	if (trendValue !== undefined) {
		trendText = "Trending the same for this period vs last period.";
		trendIcon = <Icon.ArrowRight size="1.25rem" style={{ display: "block" }} />;
		if (trendValue > 0) {
			trendText = "Trending up for this period vs last period.";
			trendIcon = <Icon.TrendingUp size="1.25rem" style={{ display: "block" }} />;
			trendDirectionClassName = styles["admissions-stat__trend--up"];
		}
		if (trendValue < 0) {
			trendText = "Trending down for this period vs last period.";
			trendIcon = <Icon.TrendingDown size="1.25rem" style={{ display: "block" }} />;
			trendDirectionClassName = styles["admissions-stat__trend--down"];
		}
	}

	return (
		<InternalLink to={to} className={styles["admissions-stat"] + " link--no-text-decoration"}>
			<Column justify="space-between">
				<label className={styles["admissions-stat__label"]}>{label}</label>

				<Row justify="space-between" align="flex-end">
					<Tooltip.Container>
						<Tooltip>
							<Tooltip.Body style={{ minWidth: "10rem" }}>
								<span>{trendText}</span>
							</Tooltip.Body>
						</Tooltip>
						<Column align="center" className={trendDirectionClassName}>
							{trendIcon}
							<span className={styles["admissions-stat__trend__value"]}>
								{((trendValue ?? 0) > 0 ? "+" : "") + formatPercentage(trendValue)}
							</span>
						</Column>
					</Tooltip.Container>

					<span className={styles["admissions-stat__value"]}>{value}</span>
				</Row>
			</Column>
		</InternalLink>
	);
}
