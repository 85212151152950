import React from "react";
import { FieldDisplayArgs, MultiSelectField, TextField } from "@hex-insights/forms";
import {
	AcademicYearFormValues,
	BillingSetupSubmissionSelect,
	SchoolStudentEnrollmentSelect,
	TermSelect,
	useBillingSetupSubmissionSelectLazyQuery,
	useSchoolStudentEnrollmentSelectLazyQuery,
	useTermSelectLazyQuery,
} from "../../../../Utilities";
import { BillingSetupSubmissionLink, SchoolStudentEnrollmentLink, TermLink } from "../../../Links";
import { FutureBaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the AcademicYear model.
 */
type FieldProps<K extends keyof AcademicYearFormValues.Base = keyof AcademicYearFormValues.Base> = FutureBaseFieldProps<
	Pick<AcademicYearFormValues.Base, K>
>;

/**
 * Renders a field component for the `name` field of the AcademicYear model.
 */
export function Name({ formState, ...props }: FieldProps<"name">) {
	return <TextField formState={formState} name="name" {...props} />;
}

export type BillingSetupSubmissionsProps = FieldProps<"billingSetupSubmissionIDs"> & {
	currentBillingSetupSubmissions?: BillingSetupSubmissionSelect.ModelForOption[];
};

/**
 * Renders a field component for the `billingSetupSubmissions` edge of the AcademicYear model.
 */
export function BillingSetupSubmissions({
	formState,
	currentBillingSetupSubmissions,
	...props
}: BillingSetupSubmissionsProps) {
	const [loadOptions, { loading, data }] = useBillingSetupSubmissionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.billingSetupSubmissionIDs) {
			loadOptions();
		}
	}, [formState.formEditing.billingSetupSubmissionIDs, loadOptions]);
	const options = React.useMemo(
		() =>
			BillingSetupSubmissionSelect.toMultiOptions(
				data?.billingSetupSubmissionConnection.edges,
				currentBillingSetupSubmissions,
			),
		[data, currentBillingSetupSubmissions],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="billingSetupSubmissionIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayBillingSetupSubmissionInstance}
			{...props}
		/>
	);
}

function displayBillingSetupSubmissionInstance({ value: id, formattedValue }: FieldDisplayArgs<string>) {
	return <BillingSetupSubmissionLink instance={{ id }}>{formattedValue}</BillingSetupSubmissionLink>;
}

export type SchoolStudentEnrollmentsProps = FieldProps<"schoolStudentEnrollmentIDs"> & {
	currentSchoolStudentEnrollments?: SchoolStudentEnrollmentSelect.ModelForOption[];
};

/**
 * Renders a field component for the `schoolStudentEnrollments` edge of the AcademicYear model.
 */
export function SchoolStudentEnrollments({
	formState,
	currentSchoolStudentEnrollments,
	...props
}: SchoolStudentEnrollmentsProps) {
	const [loadOptions, { loading, data }] = useSchoolStudentEnrollmentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.schoolStudentEnrollmentIDs) {
			loadOptions();
		}
	}, [formState.formEditing.schoolStudentEnrollmentIDs, loadOptions]);
	const options = React.useMemo(
		() =>
			SchoolStudentEnrollmentSelect.toMultiOptions(
				data?.schoolStudentEnrollmentConnection.edges,
				currentSchoolStudentEnrollments,
			),
		[data, currentSchoolStudentEnrollments],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="schoolStudentEnrollmentIDs"
			isLoading={loading}
			options={options}
			displayInstance={displaySchoolStudentEnrollmentInstance}
			{...props}
		/>
	);
}

function displaySchoolStudentEnrollmentInstance({ value: id, formattedValue }: FieldDisplayArgs<string>) {
	return <SchoolStudentEnrollmentLink instance={{ id }}>{formattedValue}</SchoolStudentEnrollmentLink>;
}

export type TermsProps = FieldProps<"termIDs"> & {
	currentTerms?: TermSelect.ModelForOption[];
};

/**
 * Renders a field component for the `terms` edge of the AcademicYear model.
 */
export function Terms({ formState, currentTerms, ...props }: TermsProps) {
	const [loadOptions, { loading, data }] = useTermSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.termIDs) {
			loadOptions();
		}
	}, [formState.formEditing.termIDs, loadOptions]);
	const options = React.useMemo(
		() => TermSelect.toMultiOptions(data?.termConnection.edges, currentTerms),
		[data, currentTerms],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="termIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayTermInstance}
			{...props}
		/>
	);
}

function displayTermInstance({ value: id, formattedValue }: FieldDisplayArgs<string>) {
	return <TermLink instance={{ id }}>{formattedValue}</TermLink>;
}
