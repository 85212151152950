import { newPageInfo } from "@hex-insights/router";
import { mealMenuHubPageInfo } from "../pageinfo";

export const mealMenuHubBulkCreatePageInfo = newPageInfo({
	name: "Bulk Create Meal Menus",
	title: "New",
	description: "Create new Meal Menu records in bulk.",
	exact: true,
	to: mealMenuHubPageInfo.to + "/bulk-new",
	path: mealMenuHubPageInfo.path + "/bulk-new",
	parent: mealMenuHubPageInfo,
});
