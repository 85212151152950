import { newPageInfo } from "@hex-insights/router";
import { Expense } from "@hex-insights/verita.shared";
import { expenseHubPageInfo } from "../pageinfo";

type ExpenseForRouteTitle = Pick<Expense, "id">;

export const expenseHubDetailPageInfo = newPageInfo({
	name: "Expense Detail",
	title: (e: ExpenseForRouteTitle) => e.id,
	description: "Detail page for an expense.",
	exact: false,
	isLeaf: true,
	to: (id: Expense["id"]) => expenseHubPageInfo.to + `/${id}`,
	path: expenseHubPageInfo.path + "/:id(\\d+)",
	parent: expenseHubPageInfo,
});
