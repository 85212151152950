import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	ServiceFilterFormController,
	ServiceFilterFormState,
	ServiceFilterFormUtils,
	ServiceFormat,
	ServiceIndexQuery,
	ServiceOrderField,
	ServiceOrderFormController,
	ServiceOrderFormState,
	ServiceOrderFormValues,
	ServicePaginationForm,
	ServiceSearchForm,
	useServiceIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { serviceHubCreatePageInfo } from "../CreatePage/pageinfo";
import { serviceHubDetailPageInfo } from "../DetailPage/pageinfo";
import { serviceHubIndexPageInfo } from "./pageinfo";

const columns: (keyof ServiceIndexQuery["serviceConnection"]["edges"][0]["node"])[] = ["name", "description", "price"];

function orderFieldToColumn(field: ServiceOrderField | null) {
	if (field === null) {
		return "id";
	}
	return ServiceOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return ServiceOrderFormValues.modelFieldToOrderField[column];
}

export function ServiceHubIndexPage() {
	useActivePageRegistration(serviceHubIndexPageInfo);

	const filterFormState = ServiceFilterFormState.useFormState();
	const orderFormState = ServiceOrderFormState.useFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(filterFormState.formValues, ServiceFilterFormUtils.toFilterInputs);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useServiceIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		ServiceFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = ServiceFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.serviceConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading level={1} noMargin>
					Services
				</Heading>

				<InternalLink to={serviceHubCreatePageInfo.to}>New Service</InternalLink>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<ServiceSearchForm formState={filterFormState} />
				<ServiceFilterFormController formState={filterFormState} numActiveFilters={numActiveFilters} />
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<ServiceOrderFormController formState={orderFormState} />
			</Row>

			<Table.Container className="hub__index-page__table__container">
				<Table columns={columns} minColumnWidth="10rem" {...tableSortingProps} className="hub__index-page__table">
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="name">Name</Table.Heading>
							<Table.Heading column="description" noSort>
								Description
							</Table.Heading>
							<Table.Heading column="price">Price</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.serviceConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="name" oneLine>
											<InternalLink to={serviceHubDetailPageInfo.to(e.node.id)}>
												{ServiceFormat.Fields.name(e.node.name)}
											</InternalLink>
										</Table.Cell>
										<Table.Cell column="description" oneLine>
											{ServiceFormat.Fields.description(e.node.description)}
										</Table.Cell>
										<Table.Cell column="price" oneLine numeric>
											{ServiceFormat.Fields.price(e.node.price)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<ServicePaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.serviceConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
