import { IndexForms } from "@hex-insights/app-modules";
import { omitKeys } from "@hex-insights/core";
import { FormState } from "@hex-insights/forms";
import { EnrollmentApplicationFilterInput } from "../../GraphQL";
import { EnrollmentApplicationFilterFormValues } from "../ModelFilterFormValues";

/**
 * Counts the number of active filters for the EnrollmentApplication model.
 *
 * @param formValues The form values of the filter form.
 */
export function countActiveFilters(formValues: EnrollmentApplicationFilterFormValues.FormValues) {
	let numStandardFilters = 0;
	if (formValues.statusIn.length > 0) {
		numStandardFilters++;
	}
	if (formValues.isNew) {
		numStandardFilters++;
	}
	if (formValues.isSeen) {
		numStandardFilters++;
	}
	if (formValues.interviewMeetingInFuture) {
		numStandardFilters++;
	}
	if (formValues.interviewMeetingInPast) {
		numStandardFilters++;
	}
	if (formValues.gradeLevelApplyingForIn.length > 0) {
		numStandardFilters++;
	}

	const numAdvancedFilters = IndexForms.countActiveAdvancedFilters(
		formValues,
		EnrollmentApplicationFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	);
	return numStandardFilters + numAdvancedFilters;
}

/**
 * Clears the values of the filter form for the EnrollmentApplication model.
 *
 * @param formState The form state of the filter form.
 */
export function clearFilters(formState: FormState<EnrollmentApplicationFilterFormValues.FormValues>) {
	formState.formClearFunctions.statusIn();
	formState.formClearFunctions.isNew();
	formState.formClearFunctions.isSeen();
	formState.formClearFunctions.interviewMeetingInFuture();
	formState.formClearFunctions.interviewMeetingInPast();
	formState.formClearFunctions.gradeLevelApplyingForIn();

	formState.formClearFunctions.filterSets();
}

/**
 * Resets the values of the filter form for the EnrollmentApplication model.
 *
 * @param formState The form state of the filter form.
 */
export function resetFilters(formState: FormState<EnrollmentApplicationFilterFormValues.FormValues>) {
	formState.formResetFunctions.statusIn();
	formState.formResetFunctions.isNew();
	formState.formResetFunctions.isSeen();
	formState.formResetFunctions.interviewMeetingInFuture();
	formState.formResetFunctions.interviewMeetingInPast();
	formState.formResetFunctions.gradeLevelApplyingForIn();

	formState.formResetFunctions.filterSets();
}

/**
 * Converts the form values of the filter form for the EnrollmentApplication model to the correct GraphQL
 * filter input type.
 *
 * @param formValues The form values of the filter form.
 */
export function toFilterInputs(
	formValues: EnrollmentApplicationFilterFormValues.FormValues,
): EnrollmentApplicationFilterInput | EnrollmentApplicationFilterInput[] {
	const convertableFormValues = omitKeys(formValues, [
		"isNew", // client-side only
		"isSeen", // client-side only
		"interviewMeetingInFuture",
		"interviewMeetingInPast",
		"gradeLevelApplyingForIn",
	]);
	const baseFilterInput = IndexForms.toFilterInput(
		convertableFormValues,
		EnrollmentApplicationFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	) as EnrollmentApplicationFilterInput;

	const filterInput: EnrollmentApplicationFilterInput = {
		search: baseFilterInput.search,
		and: [{ ...baseFilterInput, search: "" }],
	};

	if (formValues.interviewMeetingInFuture !== formValues.interviewMeetingInPast) {
		if (formValues.interviewMeetingInFuture) {
			filterInput.and!.push({
				interviewMeetingTimeGTE: new Date().toISOString(),
			});
		}
		if (formValues.interviewMeetingInPast) {
			filterInput.and!.push({
				interviewMeetingTimeLTE: new Date().toISOString(),
			});
		}
	}

	if (formValues.gradeLevelApplyingForIn.length > 0) {
		filterInput.and!.push({
			enrollmentApplicationStudent: [{ gradeLevelApplyingForIn: formValues.gradeLevelApplyingForIn }],
		});
	}

	const filterInputs = [filterInput];
	if (formValues.search !== "") {
		filterInputs.push({
			...filterInput,
			search: "",
			enrollmentApplicationStudent: [{ search: formValues.search }],
		});
		filterInputs.push({
			...filterInput,
			search: "",
			enrollmentApplicationStudent: [{ enrollmentApplicationSubmission: [{ search: formValues.search }] }],
		});
	}

	return filterInputs;
}
