import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { CourseHubCreatePage } from "./CreatePage";
import { courseHubCreatePageInfo } from "./CreatePage/pageinfo";
import { CourseHubDetailPage } from "./DetailPage";
import { courseHubDetailPageInfo } from "./DetailPage/pageinfo";
import { CourseHubIndexPage } from "./IndexPage";
import { courseHubIndexPageInfo } from "./IndexPage/pageinfo";
import { courseHubPageInfo } from "./pageinfo";

export function CourseHub() {
	useActivePageRegistration(courseHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...courseHubIndexPageInfo.routeProps}>
				<CourseHubIndexPage />
			</Route>
			<Route {...courseHubCreatePageInfo.routeProps}>
				<CourseHubCreatePage />
			</Route>
			<Route {...courseHubDetailPageInfo.routeProps}>
				<CourseHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
