import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import {
	MFAInvalidOTPAttemptFilterInput,
	MFAInvalidOTPAttemptOrder,
	useMFAInvalidOTPAttemptPaginationQuery,
} from "../../../../Utilities";

export type MFAInvalidOTPAttemptPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: MFAInvalidOTPAttemptFilterInput | MFAInvalidOTPAttemptFilterInput[] | null;
	order: MFAInvalidOTPAttemptOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the MFAInvalidOTPAttempt model.
 */
export function MFAInvalidOTPAttemptPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: MFAInvalidOTPAttemptPaginationFormProps) {
	const { loading, data } = useMFAInvalidOTPAttemptPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.mfaInvalidOTPAttemptConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
