import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const enrollmentApplicationStudentHubPageInfo = newPageInfo({
	title: "Enrollment Application Students",
	description: "Pages related to the Enrollment Application Student model.",
	exact: false,
	to: hubsPageInfo.to + "/enrollment-application-students",
	path: hubsPageInfo.path + "/enrollment-application-students",
	parent: hubsPageInfo,
});
