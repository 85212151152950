import { newPageInfo } from "@hex-insights/router";
import { dashboardsPageInfo } from "../pageinfo";

export const dashboardsGeneralPageInfo = newPageInfo({
	name: "General Dashboard",
	title: "General",
	description: "General dashboard.",
	exact: true,
	to: dashboardsPageInfo.to + "/general",
	path: dashboardsPageInfo.path + "/general",
	parent: dashboardsPageInfo,
});
