import { IndexForms } from "@hex-insights/app-modules";
import { CourseSectionPeriodFilterFormValues } from "../../../../Utilities";

export type CourseSectionPeriodFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, CourseSectionPeriodFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the CourseSectionPeriod model.
 */
export function CourseSectionPeriodFilterFormController(props: CourseSectionPeriodFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={CourseSectionPeriodFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `CourseSectionPeriodFilterForm`
