import React from "react";
import { Button, ChildrenProps, Column, Heading, Modal, Row, Section } from "@hex-insights/core";
import { PermissionCheckMethod, PermissionRequired, usePermissionCheck } from "@hex-insights/permissioning";
import { InternalLink, InternalLinkProps, useActivePageRegistration } from "@hex-insights/router";
import {
	HorizontalScrollingContainer,
	permissions,
	ScaledText,
	StudentDocumentCreateModal,
	StudentNoteFormModal,
} from "@hex-insights/verita.shared";
import { CampusOverviews, LessonPlansOverviewModal, Main } from "../../../Components";
import { academicYearHubIndexPageInfo } from "../AcademicYear/IndexPage/pageinfo";
import { addressHubIndexPageInfo } from "../Address/IndexPage/pageinfo";
import { busHubIndexPageInfo } from "../Bus/IndexPage/pageinfo";
import { busPlanHubIndexPageInfo } from "../BusPlan/IndexPage/pageinfo";
import { calendarEventHubCreatePageInfo } from "../CalendarEvent/CreatePage/pageinfo";
import { calendarEventHubIndexPageInfo } from "../CalendarEvent/IndexPage/pageinfo";
import { campusHubIndexPageInfo } from "../Campus/IndexPage/pageinfo";
import { classYearHubIndexPageInfo } from "../ClassYear/IndexPage/pageinfo";
import { contractHubIndexPageInfo } from "../Contract/IndexPage/pageinfo";
import { contractInstallmentHubIndexPageInfo } from "../ContractInstallment/IndexPage/pageinfo";
import { courseHubIndexPageInfo } from "../Course/IndexPage/pageinfo";
import { courseAttendanceRecordHubIndexPageInfo } from "../CourseAttendanceRecord/IndexPage/pageinfo";
import { courseSectionHubIndexPageInfo } from "../CourseSection/IndexPage/pageinfo";
import { discountHubIndexPageInfo } from "../Discount/IndexPage/pageinfo";
import { emailAddressHubIndexPageInfo } from "../EmailAddress/IndexPage/pageinfo";
import { employeeHubCreatePageInfo } from "../Employee/CreatePage/pageinfo";
import { employeeHubIndexPageInfo } from "../Employee/IndexPage/pageinfo";
import { expenseHubIndexPageInfo } from "../Expense/IndexPage/pageinfo";
import { expenseBudgetHubIndexPageInfo } from "../ExpenseBudget/IndexPage/pageinfo";
import { expenseCategoryHubIndexPageInfo } from "../ExpenseCategory/IndexPage/pageinfo";
import { homeRoomHubIndexPageInfo } from "../HomeRoom/IndexPage/pageinfo";
import { homeRoomSectionHubIndexPageInfo } from "../HomeRoomSection/IndexPage/pageinfo";
import { invoiceHubIndexPageInfo } from "../Invoice/IndexPage/pageinfo";
import { learningObjectiveHubIndexPageInfo } from "../LearningObjective/IndexPage/pageinfo";
import { lessonPlanHubIndexPageInfo } from "../LessonPlan/IndexPage/pageinfo";
import { loginRecordHubIndexPageInfo } from "../LoginRecord/IndexPage/pageinfo";
import { mealMenuHubBulkCreatePageInfo } from "../MealMenu/BulkCreatePage/pageinfo";
import { mealMenuHubIndexPageInfo } from "../MealMenu/IndexPage/pageinfo";
import { mealPlanHubIndexPageInfo } from "../MealPlan/IndexPage/pageinfo";
import { mfaFactorHubIndexPageInfo } from "../MFAFactor/IndexPage/pageinfo";
import { mfaInvalidOTPAttemptHubIndexPageInfo } from "../MFAInvalidOTPAttempt/IndexPage/pageinfo";
import { noteHubIndexPageInfo } from "../Note/IndexPage/pageinfo";
import { parentHubIndexPageInfo } from "../Parent/IndexPage/pageinfo";
import { paymentHubIndexPageInfo } from "../Payment/IndexPage/pageinfo";
import { permissionHubIndexPageInfo } from "../Permission/IndexPage/pageinfo";
import { permissionGroupHubIndexPageInfo } from "../PermissionGroup/IndexPage/pageinfo";
import { personHubIndexPageInfo } from "../Person/IndexPage/pageinfo";
import { phoneNumberHubIndexPageInfo } from "../PhoneNumber/IndexPage/pageinfo";
import { quotationHubIndexPageInfo } from "../Quotation/IndexPage/pageinfo";
import { relationshipHubIndexPageInfo } from "../Relationship/IndexPage/pageinfo";
import { reportCardHubIndexPageInfo } from "../ReportCard/IndexPage/pageinfo";
import { roomHubIndexPageInfo } from "../Room/IndexPage/pageinfo";
import { schoolAttendanceRecordHubIndexPageInfo } from "../SchoolAttendanceRecord/IndexPage/pageinfo";
import { serviceHubIndexPageInfo } from "../Service/IndexPage/pageinfo";
import { serviceSubscriptionHubIndexPageInfo } from "../ServiceSubscription/IndexPage/pageinfo";
import { sessionHubIndexPageInfo } from "../Session/IndexPage/pageinfo";
import { studentHubCreatePageInfo } from "../Student/CreatePage/pageinfo";
import { studentHubIndexPageInfo } from "../Student/IndexPage/pageinfo";
import { studentDocumentHubIndexPageInfo } from "../StudentDocument/IndexPage/pageinfo";
import { termHubIndexPageInfo } from "../Term/IndexPage/pageinfo";
import { trustedDeviceHubIndexPageInfo } from "../TrustedDevice/IndexPage/pageinfo";
import { tuitionHubIndexPageInfo } from "../Tuition/IndexPage/pageinfo";
import { userHubCreatePageInfo } from "../User/CreatePage/pageinfo";
import { userHubIndexPageInfo } from "../User/IndexPage/pageinfo";
import { userGroupHubCreatePageInfo } from "../UserGroup/CreatePage/pageinfo";
import { userGroupHubIndexPageInfo } from "../UserGroup/IndexPage/pageinfo";
import { hubsIndexPageInfo } from "./pageinfo";
import styles from "./styles.module.css";

const schoolHubLinks: [string, string, string[]][] = [
	["Students", studentHubIndexPageInfo.to, [permissions.Student.View.Admin, permissions.Student.View.Basic]],
	["Teachers & Staff", employeeHubIndexPageInfo.to, [permissions.Employee.View.Admin, permissions.Employee.View.Basic]],
	["Campuses", campusHubIndexPageInfo.to, [permissions.Campus.View.Admin, permissions.Campus.View.Basic]],
	["Class Years", classYearHubIndexPageInfo.to, [permissions.ClassYear.View.Admin, permissions.ClassYear.View.Basic]],
	["Home Rooms", homeRoomHubIndexPageInfo.to, [permissions.HomeRoom.View.Admin, permissions.HomeRoom.View.Basic]],
	[
		"Home Room Sections",
		homeRoomSectionHubIndexPageInfo.to,
		[permissions.HomeRoomSection.View.Admin, permissions.HomeRoomSection.View.Basic],
	],
	["Courses", courseHubIndexPageInfo.to, [permissions.Course.View.Admin, permissions.Course.View.Basic]],
	[
		"Course Sections",
		courseSectionHubIndexPageInfo.to,
		[permissions.CourseSection.View.Admin, permissions.CourseSection.View.Basic],
	],
	["Rooms", roomHubIndexPageInfo.to, [permissions.Room.View.Admin, permissions.Room.View.Basic]],
	["Terms", termHubIndexPageInfo.to, [permissions.Term.View.Admin, permissions.Term.View.Basic]],
	[
		"Academic Years",
		academicYearHubIndexPageInfo.to,
		[permissions.AcademicYear.View.Admin, permissions.AcademicYear.View.Basic],
	],
];

const schoolRecordsHubLinks: [string, string, string[]][] = [
	[
		"Calendar Events",
		calendarEventHubIndexPageInfo.to,
		[permissions.CalendarEvent.View.Admin, permissions.CalendarEvent.View.Basic],
	],
	[
		"Lesson Plans",
		lessonPlanHubIndexPageInfo.to,
		[permissions.LessonPlan.View.Admin, permissions.LessonPlan.View.Basic],
	],
	["Meal Menus", mealMenuHubIndexPageInfo.to, [permissions.MealMenu.View.Admin, permissions.MealMenu.View.Basic]],
	[
		"School Attendance Records",
		schoolAttendanceRecordHubIndexPageInfo.to,
		[permissions.SchoolAttendanceRecord.View.Admin, permissions.SchoolAttendanceRecord.View.Basic],
	],
	[
		"Course Attendance Records",
		courseAttendanceRecordHubIndexPageInfo.to,
		[permissions.CourseAttendanceRecord.View.Admin, permissions.CourseAttendanceRecord.View.Basic],
	],
	[
		"Report Cards",
		reportCardHubIndexPageInfo.to,
		[permissions.ReportCard.View.Admin, permissions.ReportCard.View.Basic],
	],
	[
		"Learning Objectives",
		learningObjectiveHubIndexPageInfo.to,
		[permissions.LearningObjective.View.Admin, permissions.LearningObjective.View.Basic],
	],
	["Notes", noteHubIndexPageInfo.to, [permissions.Note.View.Admin, permissions.Note.View.Basic]],
	[
		"Student Documents",
		studentDocumentHubIndexPageInfo.to,
		[permissions.StudentDocument.View.Admin, permissions.StudentDocument.View.Basic],
	],
];

const contactHubLinks: [string, string, string[]][] = [
	["Students", studentHubIndexPageInfo.to, [permissions.Student.View.Admin, permissions.Student.View.Basic]],
	["Parents", parentHubIndexPageInfo.to, [permissions.Parent.View.Admin, permissions.Parent.View.Basic]],
	["Employees", employeeHubIndexPageInfo.to, [permissions.Employee.View.Admin, permissions.Employee.View.Basic]],
	["Groups", userGroupHubIndexPageInfo.to, [permissions.UserGroup.View.Admin, permissions.UserGroup.View.Basic]],
	["Addresses", addressHubIndexPageInfo.to, [permissions.Address.View.Admin, permissions.Address.View.Basic]],
	[
		"Email Addresses",
		emailAddressHubIndexPageInfo.to,
		[permissions.EmailAddress.View.Admin, permissions.EmailAddress.View.Basic],
	],
	[
		"Phone Numbers",
		phoneNumberHubIndexPageInfo.to,
		[permissions.PhoneNumber.View.Admin, permissions.PhoneNumber.View.Basic],
	],
	[
		"Relationships",
		relationshipHubIndexPageInfo.to,
		[permissions.Relationship.View.Admin, permissions.Relationship.View.Basic],
	],
	["People", personHubIndexPageInfo.to, [permissions.Person.View.Admin, permissions.Person.View.Basic]],
];

const financialsHubLinks: [string, string, string[]][] = [
	["Invoices", invoiceHubIndexPageInfo.to, [permissions.Invoice.View.Admin, permissions.Invoice.View.Basic]],
	["Payments", paymentHubIndexPageInfo.to, [permissions.Payment.View.Admin, permissions.Payment.View.Basic]],
	["Discounts", discountHubIndexPageInfo.to, [permissions.Discount.View.Admin, permissions.Discount.View.Basic]],
	["Expenses", expenseHubIndexPageInfo.to, [permissions.Expense.View.Admin, permissions.Expense.View.Basic]],
	[
		"Expense Budgets",
		expenseBudgetHubIndexPageInfo.to,
		[permissions.ExpenseBudget.View.Admin, permissions.ExpenseBudget.View.Basic],
	],
	[
		"Expense Categories",
		expenseCategoryHubIndexPageInfo.to,
		[permissions.ExpenseCategory.View.Admin, permissions.ExpenseCategory.View.Basic],
	],
	["Services", serviceHubIndexPageInfo.to, [permissions.Service.View.Admin, permissions.Service.View.Basic]],
	["Contracts", contractHubIndexPageInfo.to, [permissions.Contract.View.Admin, permissions.Contract.View.Basic]],
	[
		"Service Subscriptions",
		serviceSubscriptionHubIndexPageInfo.to,
		[permissions.ServiceSubscription.View.Admin, permissions.ServiceSubscription.View.Basic],
	],
	[
		"Contract Installments",
		contractInstallmentHubIndexPageInfo.to,
		[permissions.ContractInstallment.View.Admin, permissions.ContractInstallment.View.Basic],
	],
	["Buses", busHubIndexPageInfo.to, [permissions.Bus.View.Admin, permissions.Bus.View.Basic]],
	["Bus Plans", busPlanHubIndexPageInfo.to, [permissions.BusPlan.View.Admin, permissions.BusPlan.View.Basic]],
	["Meal Plans", mealPlanHubIndexPageInfo.to, [permissions.MealPlan.View.Admin, permissions.MealPlan.View.Basic]],
	["Tuitions", tuitionHubIndexPageInfo.to, [permissions.Tuition.View.Admin, permissions.Tuition.View.Basic]],
];

const portalManagementHubLinks: [string, string, string[]][] = [
	["Users", userHubIndexPageInfo.to, [permissions.User.View.Admin, permissions.User.View.Basic]],
	["Groups", userGroupHubIndexPageInfo.to, [permissions.UserGroup.View.Admin, permissions.UserGroup.View.Basic]],
	[
		"Permission Groups",
		permissionGroupHubIndexPageInfo.to,
		[permissions.PermissionGroup.View.Admin, permissions.PermissionGroup.View.Basic],
	],
	["Quotations", quotationHubIndexPageInfo.to, [permissions.Quotation.View.Admin, permissions.Quotation.View.Basic]],
	[
		"Permissions",
		permissionHubIndexPageInfo.to,
		[permissions.Permission.View.Admin, permissions.Permission.View.Basic],
	],
	[
		"Login Records",
		loginRecordHubIndexPageInfo.to,
		[permissions.LoginRecord.View.Admin, permissions.LoginRecord.View.Basic],
	],
	["Sessions", sessionHubIndexPageInfo.to, [permissions.Session.View.Admin, permissions.Session.View.Basic]],
	[
		"Trusted Devices",
		trustedDeviceHubIndexPageInfo.to,
		[permissions.TrustedDevice.View.Admin, permissions.TrustedDevice.View.Basic],
	],
	["MFA Factors", mfaFactorHubIndexPageInfo.to, [permissions.MFAFactor.View.Admin, permissions.MFAFactor.View.Basic]],
	[
		"MFA Invalid OTP Attempts",
		mfaInvalidOTPAttemptHubIndexPageInfo.to,
		[permissions.MFAInvalidOTPAttempt.View.Admin, permissions.MFAInvalidOTPAttempt.View.Basic],
	],
];

export function HubsIndexPage() {
	useActivePageRegistration(hubsIndexPageInfo);

	return (
		<Main>
			<Column justify="spaced-start" verticalSpacing="2rem" style={{ marginTop: "1rem" }}>
				<CampusOverviews />
				<QuickActions />

				<Column justify="spaced-start">
					<HorizontalHubSection name="School" links={schoolHubLinks} />
					<HorizontalHubSection name="School Records" links={schoolRecordsHubLinks} />
					<HorizontalHubSection name="Contact" links={contactHubLinks} />
					<HorizontalHubSection name="Services & Financials" links={financialsHubLinks} />
					<HorizontalHubSection name="App Management" links={portalManagementHubLinks} />
				</Column>
			</Column>
		</Main>
	);
}

function QuickActions() {
	return (
		<Row justify="center">
			<HorizontalScrollingContainer>
				<Row justify="spaced-start" horizontalSpacing="0.5rem">
					<QuickActionLink to={studentHubCreatePageInfo.to}>New Student</QuickActionLink>
					<QuickActionLink to={employeeHubCreatePageInfo.to}>New Faculty/Staff</QuickActionLink>
					<QuickActionLink to={mealMenuHubBulkCreatePageInfo.to}>Set Meal Menus</QuickActionLink>
					<QuickActionLessonPlansButton />
					<QuickActionLink to={calendarEventHubCreatePageInfo.to}>New Event</QuickActionLink>
					<QuickActionNoteButton />
					<QuickActionStudentDocumentButton />
					<QuickActionLink to={userGroupHubCreatePageInfo.to}>New Group</QuickActionLink>
					<QuickActionLink to={userHubCreatePageInfo.to}>New User</QuickActionLink>
				</Row>
			</HorizontalScrollingContainer>
		</Row>
	);
}

type QuickActionLinkProps = Pick<InternalLinkProps, "to"> & Partial<ChildrenProps>;

function QuickActionLink({ to, children }: QuickActionLinkProps) {
	return (
		<InternalLink to={to} className={styles["quick-actions__link"] + " link--no-text-decoration"}>
			{children}
		</InternalLink>
	);
}

function QuickActionLessonPlansButton() {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<Button variant="primary" onClick={toggleIsModalOpen} className={styles["quick-actions__button"]}>
				View Lesson Plans
			</Button>

			<Modal.If condition={isModalOpen}>
				<LessonPlansOverviewModal onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

function QuickActionNoteButton() {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<Button variant="primary" onClick={toggleIsModalOpen} className={styles["quick-actions__button"]}>
				New Note
			</Button>

			<Modal.If condition={isModalOpen}>
				<StudentNoteFormModal onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

function QuickActionStudentDocumentButton() {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<Button variant="primary" onClick={toggleIsModalOpen} className={styles["quick-actions__button"]}>
				New Student Document
			</Button>

			<Modal.If condition={isModalOpen}>
				<StudentDocumentCreateModal onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type HorizontalHubSectionProps = {
	name: string;
	links: [string, string, string[]][];
};

function HorizontalHubSection({ name, links }: HorizontalHubSectionProps) {
	const allPermissions = React.useMemo(() => links.reduce((a, e) => a.concat(...e[2]), [] as string[]), [links]);

	const hasPermission = usePermissionCheck({
		requiredPermissions: allPermissions,
		checkMethod: PermissionCheckMethod.Or,
	});
	if (!hasPermission) {
		return null;
	}

	return (
		<Section>
			<Section.Header>
				<Heading.H3 noMargin>{name}</Heading.H3>
			</Section.Header>
			<Section.Body>
				<HorizontalScrollingContainer>
					<Row justify="spaced-start">
						{links.map(([name, to, permissions]) => (
							<PermissionRequired key={to} requiredPermissions={permissions} checkMethod={PermissionCheckMethod.Or}>
								<InternalLink
									to={to}
									style={{ backgroundImage: getHubLinkBackgroundImage() }}
									className={styles["hub-link"] + " link--no-text-decoration"}
								>
									<ScaledText maxFontSize={32} className={styles["hub-link__text"]}>
										{name}
									</ScaledText>
								</InternalLink>
							</PermissionRequired>
						))}
					</Row>
				</HorizontalScrollingContainer>
			</Section.Body>
		</Section>
	);
}

function getHubLinkBackgroundImage() {
	return `radial-gradient(circle at ${Math.random() * 100}% ${Math.random() * 100}%, #0063ad77 0 ${
		Math.random() * 3 + 2
	}rem, transparent 0 100%),
radial-gradient(circle at ${Math.random() * 100}% ${Math.random() * 100}%, #0063ad77 0 ${
		Math.random() * 5
	}rem, transparent 0 100%),
radial-gradient(circle at ${Math.random() * 100}% ${Math.random() * 100}%, #0063ad77 0 ${
		Math.random() * 2
	}rem, transparent 0 100%),
	linear-gradient(${Math.random() * 360}deg, #0063addd, transparent)`;
}
