import { NoteComment } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the body field of the NoteComment model.
 */
export function body(value: NoteComment["body"]): string {
	return toString(value);
}

/**
 * Formats the createdAt field of the NoteComment model.
 */
export function createdAt(value: NoteComment["createdAt"] | null): string {
	return toString(value);
}
