import { newPageInfo } from "@hex-insights/router";
import { UserGroup } from "@hex-insights/verita.shared";
import { userGroupHubPageInfo } from "../pageinfo";

type UserGroupForRouteTitle = Pick<UserGroup, "name">;

export const userGroupHubDetailPageInfo = newPageInfo({
	name: "User Group Detail",
	title: (e: UserGroupForRouteTitle) => e.name,
	description: "Detail page for an userGroup.",
	exact: false,
	isLeaf: true,
	to: (id: UserGroup["id"]) => userGroupHubPageInfo.to + `/${id}`,
	path: userGroupHubPageInfo.path + "/:id(\\d+)",
	parent: userGroupHubPageInfo,
});
