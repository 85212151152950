import { Redirect, Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../Components";
import { TermSetupCreatePage } from "./CreatePage";
import { termSetupCreatePageInfo } from "./CreatePage/pageinfo";
import { TermSetupDetailPage } from "./DetailPage";
import { termSetupDetailPageInfo } from "./DetailPage/pageinfo";
import { termSetupPageInfo } from "./pageinfo";

export function TermSetup() {
	useActivePageRegistration(termSetupPageInfo);

	return (
		<PageRouteSwitch>
			<Route exact path={termSetupPageInfo.path}>
				<Redirect to={termSetupCreatePageInfo.to} />
			</Route>
			<Route {...termSetupCreatePageInfo.routeProps}>
				<TermSetupCreatePage />
			</Route>
			<Route {...termSetupDetailPageInfo.routeProps}>
				<TermSetupDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
