import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	RelationshipForm,
	RelationshipFormValues,
	RelationshipMutation,
	useRelationshipDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { relationshipHubDetailPageInfo } from "./pageinfo";

function RelationshipHubDetailPageWrapper() {
	// Manages identity of the RelationshipHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <RelationshipHubDetailPage key={id} />;
}

export { RelationshipHubDetailPageWrapper as RelationshipHubDetailPage };

function RelationshipHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useRelationshipDetailQuery({ variables: { id } });
	const relationship = data?.relationship;
	useActivePageRegistration(
		relationshipHubDetailPageInfo,
		!!relationship ? relationshipHubDetailPageInfo.title(relationship) : "",
	);

	const update = RelationshipMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<RelationshipFormValues.Detail>,
			initialFormValues: RelationshipFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading relationship</If>
				<Else>
					{!!relationship && (
						<React.Fragment>
							<Heading level={1}>{relationship.id}</Heading>

							<RelationshipForm.ControlledDetail
								key={syncID}
								relationship={relationship}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
