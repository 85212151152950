import { newPageInfo } from "@hex-insights/router";
import { NoteAttachment } from "@hex-insights/verita.shared";
import { noteAttachmentHubPageInfo } from "../pageinfo";

type NoteAttachmentForRouteTitle = Pick<NoteAttachment, "id">;

export const noteAttachmentHubDetailPageInfo = newPageInfo({
	name: "Note Attachment Detail",
	title: (e: NoteAttachmentForRouteTitle) => e.id,
	description: "Detail page for an noteAttachment.",
	exact: false,
	isLeaf: true,
	to: (id: NoteAttachment["id"]) => noteAttachmentHubPageInfo.to + `/${id}`,
	path: noteAttachmentHubPageInfo.path + "/:id(\\d+)",
	parent: noteAttachmentHubPageInfo,
});
