import { newPageInfo } from "@hex-insights/router";
import { Session } from "@hex-insights/verita.shared";
import { sessionHubPageInfo } from "../pageinfo";

type SessionForRouteTitle = Pick<Session, "id">;

export const sessionHubDetailPageInfo = newPageInfo({
	name: "Session Detail",
	title: (e: SessionForRouteTitle) => e.id,
	description: "Detail page for an session.",
	exact: false,
	isLeaf: true,
	to: (id: Session["id"]) => sessionHubPageInfo.to + `/${id}`,
	path: sessionHubPageInfo.path + "/:id(\\d+)",
	parent: sessionHubPageInfo,
});
