import React from "react";
import {
	Button,
	ClassNameProps,
	Column,
	compareObjects,
	compareWithQueryDesc,
	Conditional,
	Else,
	Grid,
	Heading,
	Icon,
	If,
	matchQuery,
	Modal,
	ModalProps,
	Row,
	StyleProps,
} from "@hex-insights/core";
import { TextField, useFormState, ValidationDisplayPolicy } from "@hex-insights/forms";
import {
	DocumentResourceIndexQuery,
	DocumentResourceIndexQueryVariables,
	DocumentResourceOrderField,
	OrderDirection,
	useDocumentResourceIndexQuery,
} from "../../Utilities";
import { DocumentResourceDisplay } from "../DocumentResourceDisplay";
import { Tile } from "../Tile";

export type ResourcesTileProps = {
	filters?: DocumentResourceIndexQueryVariables["filters"];
} & Partial<ClassNameProps & StyleProps>;

export function ResourcesTile({ filters, className, style }: ResourcesTileProps) {
	const { loading, data } = useDocumentResourceIndexQuery({
		variables: {
			filters: filters,
			order: { field: DocumentResourceOrderField.Name, direction: OrderDirection.Asc },
		},
	});

	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<Tile className={className} style={{ ...style, position: "relative" }}>
			<Tile.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<If condition={(data?.documentResourceConnection.edges.length ?? 0) === 0}>No resources to show</If>
					<Else>
						<div style={{ position: "absolute", top: "0.25rem", right: "0.75rem" }}>
							<Button
								variant="link"
								size="small"
								onClick={toggleIsModalOpen}
								title="Expand"
								style={{ padding: 0, fontSize: "1.5rem" }}
							>
								<Icon.Maximize2 color="var(--verita-blue)" size="0.9rem" />
							</Button>
						</div>
						<Row justify="spaced-start" overflow="scroll">
							{data?.documentResourceConnection.edges.map((e) => (
								<DocumentResourceDisplay key={e.node.id} documentResource={e.node} />
							))}
						</Row>

						<Modal.If condition={isModalOpen}>
							{!!data && <ResourcesModal edges={data.documentResourceConnection.edges} onClose={toggleIsModalOpen} />}
						</Modal.If>
					</Else>
				</Conditional>
			</Tile.Body>
		</Tile>
	);
}

const initialModalFormValues = { search: "" };

type ResourcesModalProps = {
	edges: DocumentResourceIndexQuery["documentResourceConnection"]["edges"];
} & Pick<ModalProps, "ifRef" | "onClose">;

function ResourcesModal({ edges, ifRef, onClose }: ResourcesModalProps) {
	const formState = useFormState({
		initialFormValues: initialModalFormValues,
		validationDisplayPolicy: ValidationDisplayPolicy.none,
	});
	const { search } = formState.formValues;

	const filteredEdges = React.useMemo(() => {
		const getValue = (e: DocumentResourceIndexQuery["documentResourceConnection"]["edges"][0]) =>
			e.node.name + " " + e.node.description;
		const filter = matchQuery(search);
		const compare = compareObjects(getValue, compareWithQueryDesc(search));
		return edges.filter((e) => filter(getValue(e))).sort(compare);
	}, [edges, search]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} style={{ height: "90vh", maxHeight: "none" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					Resources
				</Heading>
			</Modal.Header>
			<Modal.Body style={{ height: "100%", overflow: "hidden" }}>
				<Column justify="spaced-start" verticalSpacing="1.5rem" style={{ height: "100%" }}>
					<div>
						<TextField formState={formState} name="search" autoFocus />
					</div>

					<Grid columns={5} gap="1rem" style={{ maxHeight: "100%", overflow: "scroll" }}>
						{filteredEdges.map((e) => (
							<DocumentResourceDisplay key={e.node.id} documentResource={e.node} />
						))}
					</Grid>
				</Column>
			</Modal.Body>
		</Modal>
	);
}
