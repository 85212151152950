import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { AddressHubCreatePage } from "./CreatePage";
import { addressHubCreatePageInfo } from "./CreatePage/pageinfo";
import { AddressHubDetailPage } from "./DetailPage";
import { addressHubDetailPageInfo } from "./DetailPage/pageinfo";
import { AddressHubIndexPage } from "./IndexPage";
import { addressHubIndexPageInfo } from "./IndexPage/pageinfo";
import { addressHubPageInfo } from "./pageinfo";

export function AddressHub() {
	useActivePageRegistration(addressHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...addressHubIndexPageInfo.routeProps}>
				<AddressHubIndexPage />
			</Route>
			<Route {...addressHubCreatePageInfo.routeProps}>
				<AddressHubCreatePage />
			</Route>
			<Route {...addressHubDetailPageInfo.routeProps}>
				<AddressHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
