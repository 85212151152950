import { IndexForms } from "@hex-insights/app-modules";
import { EnrollmentApplicationParentFilterFormValues } from "../../../../Utilities";

export type EnrollmentApplicationParentSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the EnrollmentApplicationParent model.
 */
export function EnrollmentApplicationParentSearchForm({ formState }: EnrollmentApplicationParentSearchFormProps) {
	return <IndexForms.SearchForm formState={formState} />;
}

export type EnrollmentApplicationParentFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, EnrollmentApplicationParentFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the EnrollmentApplicationParent model.
 */
export function EnrollmentApplicationParentFilterFormController(
	props: EnrollmentApplicationParentFilterFormControllerProps,
) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={EnrollmentApplicationParentFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `EnrollmentApplicationParentFilterForm`
