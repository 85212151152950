import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { EnrollmentApplicationNoteHubCreatePage } from "./CreatePage";
import { enrollmentApplicationNoteHubCreatePageInfo } from "./CreatePage/pageinfo";
import { EnrollmentApplicationNoteHubDetailPage } from "./DetailPage";
import { enrollmentApplicationNoteHubDetailPageInfo } from "./DetailPage/pageinfo";
import { EnrollmentApplicationNoteHubIndexPage } from "./IndexPage";
import { enrollmentApplicationNoteHubIndexPageInfo } from "./IndexPage/pageinfo";
import { enrollmentApplicationNoteHubPageInfo } from "./pageinfo";

export function EnrollmentApplicationNoteHub() {
	useActivePageRegistration(enrollmentApplicationNoteHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...enrollmentApplicationNoteHubIndexPageInfo.routeProps}>
				<EnrollmentApplicationNoteHubIndexPage />
			</Route>
			<Route {...enrollmentApplicationNoteHubCreatePageInfo.routeProps}>
				<EnrollmentApplicationNoteHubCreatePage />
			</Route>
			<Route {...enrollmentApplicationNoteHubDetailPageInfo.routeProps}>
				<EnrollmentApplicationNoteHubDetailPage key={enrollmentApplicationNoteHubDetailPageInfo.useKey()} />
			</Route>
		</PageRouteSwitch>
	);
}
