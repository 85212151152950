import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import {
	EnrollmentApplicationParent,
	EnrollmentApplicationParentFormat,
	EnrollmentApplicationParentNameFragment,
} from "../../../../Utilities";

type ModelForLink = Pick<EnrollmentApplicationParent, "id"> & EnrollmentApplicationParentNameFragment;

export type EnrollmentApplicationParentLinkProps = DistributiveOmit<
	Models.LinkProps<ModelForLink>,
	"pageInfoKey" | "format"
>;

/**
 * Renders a link to the given EnrollmentApplicationParent instance.
 */
export function EnrollmentApplicationParentLink(props: EnrollmentApplicationParentLinkProps) {
	return (
		<Models.Link<ModelForLink>
			pageInfoKey="EnrollmentApplicationParent"
			format={EnrollmentApplicationParentFormat.name}
			{...props}
		/>
	);
}
