import { newPageInfo } from "@hex-insights/router";
import { mealPlanHubPageInfo } from "../pageinfo";

export const mealPlanHubCreatePageInfo = newPageInfo({
	name: "New Meal Plan",
	title: "New",
	description: "Create a new Meal Plan record.",
	exact: true,
	to: mealPlanHubPageInfo.to + "/new",
	path: mealPlanHubPageInfo.path + "/new",
	parent: mealPlanHubPageInfo,
});
