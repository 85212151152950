import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import { TuitionForm, useTuitionDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { tuitionHubDetailPageInfo } from "./pageinfo";

export function TuitionHubDetailPage() {
	const { tuitionID } = tuitionHubDetailPageInfo.useParams();

	const { loading, data, error } = useTuitionDetailQuery({ variables: { id: tuitionID } });
	const tuition = data?.tuition;
	useActivePageRegistration(tuitionHubDetailPageInfo, !!tuition ? tuitionHubDetailPageInfo.title(tuition) : "");

	const { syncID, synchronize } = useSync();

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading tuition</If>
				<Else>
					{!!tuition && (
						<React.Fragment>
							<Heading.H1>{tuition.name}</Heading.H1>

							<TuitionForm.ControlledDetail key={syncID} tuition={tuition} onSuccess={synchronize} />
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
