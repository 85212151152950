import { newPageInfo } from "@hex-insights/router";
import { HomeRoom } from "@hex-insights/verita.shared";
import { homeRoomHubPageInfo } from "../pageinfo";

export type HomeRoomHubDetailPageRouteParams = {
	id: string;
};

type HomeRoomForRouteTitle = Pick<HomeRoom, "name">;

export const homeRoomHubDetailPageInfo = newPageInfo({
	name: "Home Room Detail",
	title: (e: HomeRoomForRouteTitle) => e.name,
	description: "Detail page for an homeRoom.",
	exact: false,
	isLeaf: true,
	to: (id: HomeRoom["id"]) => homeRoomHubPageInfo.to + `/${id}`,
	path: homeRoomHubPageInfo.path + "/:id(\\d+)",
	parent: homeRoomHubPageInfo,
});
