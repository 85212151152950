import React from "react";
import { FieldDisplayArgs, MultiSelectField, TextField } from "@hex-insights/forms";
import {
	PermissionGroupFormValidation,
	PermissionGroupFormValues,
	PermissionSelect,
	usePermissionSelectLazyQuery,
	UserSelect,
	useUserSelectLazyQuery,
} from "../../../../Utilities";
import { PermissionLink, UserLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the PermissionGroup model.
 */
type FieldProps<K extends keyof PermissionGroupFormValues.Base = keyof PermissionGroupFormValues.Base> = BaseFieldProps<
	Pick<PermissionGroupFormValues.Base, K>
>;

/**
 * Renders a field component for the `name` field of the PermissionGroup model.
 */
export function Name({ id, formState }: FieldProps<"name">) {
	const validateUnique = PermissionGroupFormValidation.useUniqueNameValidator(id);

	return <TextField formState={formState} name="name" scheduledValidation={validateUnique} />;
}

/**
 * Renders a field component for the `description` field of the PermissionGroup model.
 */
export function Description({ formState }: FieldProps<"description">) {
	return <TextField formState={formState} name="description" optional />;
}

export type PermissionsProps = FieldProps<"permissionIDs"> & {
	currentPermissions?: PermissionSelect.ModelForOption[];
};

/**
 * Renders a field component for the `permissions` edge of the PermissionGroup model.
 */
export function Permissions({ formState, currentPermissions }: PermissionsProps) {
	const [loadOptions, { loading, data }] = usePermissionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.permissionIDs) {
			loadOptions();
		}
	}, [formState.formEditing.permissionIDs, loadOptions]);
	const options = React.useMemo(
		() => PermissionSelect.toMultiOptions(data?.permissionConnection.edges, currentPermissions),
		[data, currentPermissions],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="permissionIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayPermissionInstance}
		/>
	);
}

function displayPermissionInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <PermissionLink instance={{ id }}>{formattedValue}</PermissionLink>;
}

export type UsersProps = FieldProps<"userIDs"> & {
	currentUsers?: UserSelect.ModelForOption[];
};

/**
 * Renders a field component for the `users` edge of the PermissionGroup model.
 */
export function Users({ formState, currentUsers }: UsersProps) {
	const [loadOptions, { loading, data }] = useUserSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.userIDs) {
			loadOptions();
		}
	}, [formState.formEditing.userIDs, loadOptions]);
	const options = React.useMemo(
		() => UserSelect.toMultiOptions(data?.userConnection.edges, currentUsers),
		[data, currentUsers],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="userIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayUserInstance}
		/>
	);
}

function displayUserInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserLink instance={{ id }}>{formattedValue}</UserLink>;
}
