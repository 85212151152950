import React from "react";
import { Conditional, Else, Heading, If, Row, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	EnrollmentApplicationSubmissionForm,
	EnrollmentApplicationSubmissionFormValues,
	EnrollmentApplicationSubmissionMutation,
	Tile,
	useEnrollmentApplicationSubmissionDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { enrollmentApplicationSubmissionHubDetailPageInfo } from "./pageinfo";

function EnrollmentApplicationSubmissionHubDetailPageWrapper() {
	// Manages identity of the EnrollmentApplicationSubmissionHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <EnrollmentApplicationSubmissionHubDetailPage key={id} />;
}

export { EnrollmentApplicationSubmissionHubDetailPageWrapper as EnrollmentApplicationSubmissionHubDetailPage };

function EnrollmentApplicationSubmissionHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useEnrollmentApplicationSubmissionDetailQuery({ variables: { id } });
	const enrollmentApplicationSubmission = data?.enrollmentApplicationSubmission;
	useActivePageRegistration(
		enrollmentApplicationSubmissionHubDetailPageInfo,
		!!enrollmentApplicationSubmission
			? enrollmentApplicationSubmissionHubDetailPageInfo.title(enrollmentApplicationSubmission)
			: "",
	);

	const update = EnrollmentApplicationSubmissionMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<EnrollmentApplicationSubmissionFormValues.Detail>,
			initialFormValues: EnrollmentApplicationSubmissionFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading enrollmentApplicationSubmission</If>
				<Else>
					{!!enrollmentApplicationSubmission && (
						<React.Fragment>
							<Heading level={1}>{enrollmentApplicationSubmission.id}</Heading>

							<Row justify="spaced-start">
								<Tile>
									<Tile.Body>
										<EnrollmentApplicationSubmissionForm.ControlledDetail
											key={syncID}
											enrollmentApplicationSubmission={enrollmentApplicationSubmission}
											applyUpdate={applyUpdate}
											onSuccess={synchronize}
										/>
									</Tile.Body>
								</Tile>
							</Row>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
