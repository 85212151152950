import React from "react";
import { Button, Column, Conditional, Else, Heading, If, Modal, Row, Section, useSync } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	CoursesScheduleTile,
	DocumentResourceOrderField,
	getHomeRoomSectionWeeklyCourseScheduleFilters,
	HomeRoomSection,
	HomeRoomSectionForm,
	HomeRoomSectionFormValues,
	HomeRoomSectionMutation,
	HorizontalScrollingContainer,
	OrderDirection,
	PersonIcon,
	StudentListModal,
	Tile,
	Timeline,
	TimelineObjectType,
	useDocumentResourceIndexQuery,
	useEmployeeIndexQuery,
	useHomeRoomSectionCurrentPeriod,
	useHomeRoomSectionDetailQuery,
	useHomeRoomSectionScheduleForDateQuery,
	usePaginatedTimelineQuery,
	useStudentListQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { ResourcesTile, ResourcesTileProps } from "../../../../Components/Dashboards/Components";
import { employeeHubDetailPageInfo } from "../../Employee/DetailPage/pageinfo";
import { studentHubDetailPageInfo } from "../../Student/DetailPage/pageinfo";
import { LessonPlansSection } from "./Components";
import { homeRoomSectionHubDetailPageInfo, HomeRoomSectionHubDetailPageRouteParams } from "./pageinfo";

function HomeRoomSectionHubDetailPageWrapper() {
	// Manages identity of the HomeRoomSectionHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <HomeRoomSectionHubDetailPage key={id} />;
}

export { HomeRoomSectionHubDetailPageWrapper as HomeRoomSectionHubDetailPage };

function HomeRoomSectionHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useHomeRoomSectionDetailQuery({ variables: { id } });
	const homeRoomSection = data?.homeRoomSection;
	useActivePageRegistration(
		homeRoomSectionHubDetailPageInfo,
		!!homeRoomSection ? homeRoomSectionHubDetailPageInfo.title(homeRoomSection) : "",
	);

	const update = HomeRoomSectionMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<HomeRoomSectionFormValues.Detail>,
			initialFormValues: HomeRoomSectionFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading homeRoomSection</If>
				<Else>
					{!!homeRoomSection && (
						<Row
							justify="spaced-start"
							style={{
								height: "calc(100vh - var(--header---height) - 2 * var(--main---padding-top))",
							}}
						>
							<Column justify="spaced-start" style={{ height: "100%" }}>
								<Row justify="space-between" align="center">
									<Column>
										<Heading level={1} noMargin>
											{homeRoomSection.name}
										</Heading>

										<span>{homeRoomSection.room.campus.name}</span>
									</Column>

									<EmployeesSection />
								</Row>

								<Tile style={{ height: "100%", minHeight: 0 }}>
									<Tile.Body style={{ overflow: "scroll" }}>
										<HomeRoomSectionForm.ControlledDetail
											key={syncID}
											homeRoomSection={homeRoomSection}
											applyUpdate={applyUpdate}
											onSuccess={synchronize}
										/>
									</Tile.Body>
								</Tile>

								<ScheduleTile homeRoomSectionID={homeRoomSection.id} />
							</Column>

							<Column justify="spaced-start" style={{ width: "100%", minWidth: 0 }}>
								<StudentsSection homeRoomSectionID={homeRoomSection.id} />
								<TimelineTile
									homeRoomSectionID={homeRoomSection.id}
									startDate={homeRoomSection.term.startDate}
									endDate={homeRoomSection.term.endDate}
								/>
								<LessonPlansSection homeRoomSectionID={homeRoomSection.id} />
								<HomeRoomResourcesTile homeRoomSection={homeRoomSection} />
							</Column>
						</Row>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}

function ScheduleTile({ homeRoomSectionID }: { homeRoomSectionID: HomeRoomSection["id"] }) {
	return (
		<CoursesScheduleTile
			id={homeRoomSectionID}
			useCurrentPeriod={useHomeRoomSectionCurrentPeriod}
			useScheduleForDateQuery={useHomeRoomSectionScheduleForDateQuery}
			getWeeklyScheduleFilters={getHomeRoomSectionWeeklyCourseScheduleFilters}
		/>
	);
}

type StudentsSectionProps = {
	homeRoomSectionID: HomeRoomSection["id"];
};

function StudentsSection({ homeRoomSectionID }: StudentsSectionProps) {
	const { loading, data } = useStudentListQuery({
		variables: {
			filters: [
				{
					homeRoomSectionStudentEnrollments: [
						{
							homeRoomSection: [
								{
									idEQ: homeRoomSectionID,
								},
							],
						},
					],
				},
			],
		},
	});

	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<Section>
			<Section.Body style={{ padding: 0 }}>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Column>
							<Row justify="spaced-start" align="center">
								<span>Students ({data?.studentConnection.edges.length ?? 0})</span>

								<Button variant="link" size="small" onClick={toggleIsModalOpen}>
									Show All
								</Button>
							</Row>

							<HorizontalScrollingContainer>
								<Row justify="spaced-start">
									{data?.studentConnection.edges.map((e) => (
										<InternalLink
											key={e.node.id}
											to={studentHubDetailPageInfo.to(e.node.id)}
											className="link--no-text-decoration"
											style={{ display: "inline-block", maxWidth: "5rem" }}
										>
											<PersonIcon person={e.node.person} withName imageSize="3rem" />
										</InternalLink>
									))}
								</Row>
							</HorizontalScrollingContainer>
						</Column>
					</Else>
				</Conditional>

				<Modal.If condition={isModalOpen}>
					<StudentListModal
						title="Students"
						isLoading={loading}
						edges={data?.studentConnection.edges}
						onClose={toggleIsModalOpen}
					/>
				</Modal.If>
			</Section.Body>
		</Section>
	);
}

const timelineObjectTypes = [TimelineObjectType.Note, TimelineObjectType.StudentDocument];

type TimelineTileProps = {
	homeRoomSectionID: HomeRoomSection["id"];
	startDate: string;
	endDate: string;
};

function TimelineTile({ homeRoomSectionID, startDate, endDate }: TimelineTileProps) {
	const { data, networkStatus, refetch, hasMore, onLoadMoreClick } = usePaginatedTimelineQuery({
		filter: {
			homeRoomSectionIDs: [homeRoomSectionID],
			startDate,
			endDate,
			objectTypes: timelineObjectTypes,
		},
	});

	return (
		<Tile style={{ flexGrow: 1, width: "100%", height: "100%", minHeight: 0 }}>
			<Tile.Body
				className="timeline__scrolling-container__container"
				style={{ height: "100%", overflow: "hidden", padding: 0 }}
			>
				<div
					className="timeline__scrolling-container"
					style={{ height: "100%", overflow: "scroll", padding: "var(--section__body---padding)" }}
				>
					<Timeline data={data?.timeline} networkStatus={networkStatus} refetch={refetch} />

					<Timeline.LoadMoreButton hasMore={hasMore} networkStatus={networkStatus} onClick={onLoadMoreClick} />
				</div>
			</Tile.Body>
		</Tile>
	);
}

type HomeRoomResourcesTileProps = Pick<ResourcesTileProps, "className" | "style"> & {
	homeRoomSection: Pick<HomeRoomSection, "id"> & {
		homeRoom: Pick<HomeRoomSection["homeRoom"], "id">;
	};
};

function HomeRoomResourcesTile({ homeRoomSection, className, style }: HomeRoomResourcesTileProps) {
	const { loading, data } = useDocumentResourceIndexQuery({
		variables: {
			filters: [
				{
					documentResourceFolders: [
						{
							homeRooms: [{ idEQ: homeRoomSection.homeRoom.id }],
						},
					],
				},
				{
					documentResourceFolders: [
						{
							homeRoomSections: [{ idEQ: homeRoomSection.id }],
						},
					],
				},
			],
			order: { field: DocumentResourceOrderField.Name, direction: OrderDirection.Asc },
		},
	});

	return (
		<ResourcesTile
			isLoading={loading}
			edges={data?.documentResourceConnection.edges}
			className={className}
			style={style}
		/>
	);
}

function EmployeesSection() {
	const { id } = useRouteParams<HomeRoomSectionHubDetailPageRouteParams>();
	const { loading, data } = useEmployeeIndexQuery({
		variables: {
			filters: {
				homeRoomSectionTeacherEnrollments: [
					{
						homeRoomSectionIDEQ: id,
					},
				],
			},
		},
	});

	return (
		<Conditional>
			<If condition={loading}>Loading...</If>
			<Else>
				<Row justify="spaced-start">
					{data?.employeeConnection.edges.map((e) => (
						<InternalLink
							key={e.node.id}
							to={employeeHubDetailPageInfo.to(e.node.id)}
							className="link--no-text-decoration"
						>
							<PersonIcon person={e.node.person} withTooltipPreview employeeID={e.node.id} />
						</InternalLink>
					))}
				</Row>
			</Else>
		</Conditional>
	);
}
