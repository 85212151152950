import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	BillingSetupSubmissionFilterFormController,
	BillingSetupSubmissionFilterFormState,
	BillingSetupSubmissionFilterFormUtils,
	BillingSetupSubmissionFormat,
	BillingSetupSubmissionIndexQuery,
	BillingSetupSubmissionLink,
	BillingSetupSubmissionOrderField,
	BillingSetupSubmissionOrderForm,
	BillingSetupSubmissionOrderFormState,
	BillingSetupSubmissionOrderFormValues,
	BillingSetupSubmissionPaginationForm,
	BillingSetupSubmissionSearchForm,
	useBillingSetupSubmissionIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { billingSetupSubmissionHubCreatePageInfo } from "../CreatePage/pageinfo";
import { billingSetupSubmissionHubIndexPageInfo } from "./pageinfo";

const columns: (keyof BillingSetupSubmissionIndexQuery["billingSetupSubmissionConnection"]["edges"][0]["node"])[] = [
	"id",
	"numPayments",
	"isInterestedInBusPlan",
	"isInterestedInMealPlan",
	"authorName",
	"authorEmail",
	"authorPhone",
];

function orderFieldToColumn(field: BillingSetupSubmissionOrderField | null) {
	if (field === null) {
		return "id";
	}
	return BillingSetupSubmissionOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return BillingSetupSubmissionOrderFormValues.modelFieldToOrderField[column];
}

export function BillingSetupSubmissionHubIndexPage() {
	useActivePageRegistration(billingSetupSubmissionHubIndexPageInfo);

	const filterFormState = BillingSetupSubmissionFilterFormState.useFormState();
	const orderFormState = BillingSetupSubmissionOrderFormState.useOrderFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(
		filterFormState.formValues,
		BillingSetupSubmissionFilterFormUtils.toFilterInputs,
	);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useBillingSetupSubmissionIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		BillingSetupSubmissionFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = BillingSetupSubmissionFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.billingSetupSubmissionConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading.H1 noMargin>Billing Setup Submissions</Heading.H1>

				<InternalLink to={billingSetupSubmissionHubCreatePageInfo.to}>New Billing Setup Submission</InternalLink>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<BillingSetupSubmissionSearchForm formState={filterFormState} />
				<BillingSetupSubmissionFilterFormController formState={filterFormState} numActiveFilters={numActiveFilters} />
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<BillingSetupSubmissionOrderForm.OrderController formState={orderFormState} />
			</Row>

			<Table.Container className="hub__index-page__table__container">
				<Table
					columns={columns}
					minColumnWidth="10rem"
					scrollSyncID={paginationFormState.formValues.cursor}
					{...tableSortingProps}
					className="hub__index-page__table"
				>
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="id">ID</Table.Heading>
							<Table.Heading column="numPayments">Num Payments</Table.Heading>
							<Table.Heading column="isInterestedInBusPlan">Is Interested In Bus Plan</Table.Heading>
							<Table.Heading column="isInterestedInMealPlan">Is Interested In Meal Plan</Table.Heading>
							<Table.Heading column="authorName">Author Name</Table.Heading>
							<Table.Heading column="authorEmail">Author Email</Table.Heading>
							<Table.Heading column="authorPhone">Author Phone</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.billingSetupSubmissionConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="id" oneLine numeric>
											<BillingSetupSubmissionLink instance={e.node}>{e.node.id}</BillingSetupSubmissionLink>
										</Table.Cell>
										<Table.Cell column="numPayments" oneLine numeric>
											{BillingSetupSubmissionFormat.Fields.numPayments(e.node.numPayments)}
										</Table.Cell>
										<Table.Cell column="isInterestedInBusPlan" oneLine>
											{BillingSetupSubmissionFormat.Fields.isInterestedInBusPlan(e.node.isInterestedInBusPlan)}
										</Table.Cell>
										<Table.Cell column="isInterestedInMealPlan" oneLine>
											{BillingSetupSubmissionFormat.Fields.isInterestedInMealPlan(e.node.isInterestedInMealPlan)}
										</Table.Cell>
										<Table.Cell column="authorName" oneLine>
											{BillingSetupSubmissionFormat.Fields.authorName(e.node.authorName)}
										</Table.Cell>
										<Table.Cell column="authorEmail" oneLine>
											{BillingSetupSubmissionFormat.Fields.authorEmail(e.node.authorEmail)}
										</Table.Cell>
										<Table.Cell column="authorPhone" oneLine>
											{BillingSetupSubmissionFormat.Fields.authorPhone(e.node.authorPhone)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<BillingSetupSubmissionPaginationForm.Pagination
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.billingSetupSubmissionConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
