import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	MFAFactorFilterFormController,
	MFAFactorFilterFormState,
	MFAFactorFilterFormUtils,
	MFAFactorFormat,
	MFAFactorIndexQuery,
	MFAFactorOrderField,
	MFAFactorOrderFormController,
	MFAFactorOrderFormState,
	MFAFactorOrderFormValues,
	MFAFactorPaginationForm,
	MFAFactorSearchForm,
	useMFAFactorIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { mfaFactorHubDetailPageInfo } from "../DetailPage/pageinfo";
import { mfaFactorHubIndexPageInfo } from "./pageinfo";

const columns: (keyof MFAFactorIndexQuery["mfaFactorConnection"]["edges"][0]["node"])[] = [
	"email",
	"createdAt",
	"count",
	"isApp",
	"sms",
	"isVerified",
];

function orderFieldToColumn(field: MFAFactorOrderField | null) {
	if (field === null) {
		return "id";
	}
	return MFAFactorOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return MFAFactorOrderFormValues.modelFieldToOrderField[column];
}

export function MFAFactorHubIndexPage() {
	useActivePageRegistration(mfaFactorHubIndexPageInfo);

	const filterFormState = MFAFactorFilterFormState.useFormState();
	const orderFormState = MFAFactorOrderFormState.useFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(filterFormState.formValues, MFAFactorFilterFormUtils.toFilterInputs);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useMFAFactorIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		MFAFactorFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = MFAFactorFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.mfaFactorConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading level={1} noMargin>
					MFA Factors
				</Heading>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<MFAFactorSearchForm formState={filterFormState} />
				<MFAFactorFilterFormController formState={filterFormState} numActiveFilters={numActiveFilters} />
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<MFAFactorOrderFormController formState={orderFormState} />
			</Row>

			<Table.Container className="hub__index-page__table__container">
				<Table columns={columns} minColumnWidth="10rem" {...tableSortingProps} className="hub__index-page__table">
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="email" noSort>
								Email
							</Table.Heading>
							<Table.Heading column="createdAt">Created At</Table.Heading>
							<Table.Heading column="count" noSort>
								Count
							</Table.Heading>
							<Table.Heading column="isApp" noSort>
								Is App
							</Table.Heading>
							<Table.Heading column="sms" noSort>
								Sms
							</Table.Heading>
							<Table.Heading column="isVerified" noSort>
								Is Verified
							</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.mfaFactorConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="email" oneLine>
											<InternalLink to={mfaFactorHubDetailPageInfo.to(e.node.id)}>
												{MFAFactorFormat.Fields.email(e.node.email)}
											</InternalLink>
										</Table.Cell>
										<Table.Cell column="createdAt" oneLine>
											{MFAFactorFormat.Fields.createdAt(e.node.createdAt)}
										</Table.Cell>
										<Table.Cell column="count" oneLine numeric>
											{MFAFactorFormat.Fields.count(e.node.count)}
										</Table.Cell>
										<Table.Cell column="isApp" oneLine>
											{MFAFactorFormat.Fields.isApp(e.node.isApp)}
										</Table.Cell>
										<Table.Cell column="sms" oneLine>
											{MFAFactorFormat.Fields.sms(e.node.sms)}
										</Table.Cell>
										<Table.Cell column="isVerified" oneLine>
											{MFAFactorFormat.Fields.isVerified(e.node.isVerified)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<MFAFactorPaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.mfaFactorConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
