import { DocumentResourceFolder } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the name field of the DocumentResourceFolder model.
 */
export function name(value: DocumentResourceFolder["name"]): string {
	return toString(value);
}

/**
 * Formats the description field of the DocumentResourceFolder model.
 */
export function description(value: DocumentResourceFolder["description"]): string {
	return toString(value);
}

/**
 * Formats the category field of the DocumentResourceFolder model.
 */
export function category(value: DocumentResourceFolder["category"] | null): string {
	return toString(value);
}

/**
 * Formats the isPublic field of the DocumentResourceFolder model.
 */
export function isPublic(value: DocumentResourceFolder["isPublic"]): string {
	return toString(value);
}

/**
 * Formats the createdAt field of the DocumentResourceFolder model.
 */
export function createdAt(value: DocumentResourceFolder["createdAt"] | null): string {
	return toString(value);
}
