import { newPageInfo } from "@hex-insights/router";
import { serviceSubscriptionHubPageInfo } from "../pageinfo";

export const serviceSubscriptionHubIndexPageInfo = newPageInfo({
	name: "Service Subscriptions Index",
	title: "",
	description: "Index of Service Subscriptions.",
	exact: true,
	to: serviceSubscriptionHubPageInfo.to,
	path: serviceSubscriptionHubPageInfo.path,
	parent: serviceSubscriptionHubPageInfo,
});
