import { newPageInfo } from "@hex-insights/router";
import { campusHubPageInfo } from "../pageinfo";

export const campusHubIndexPageInfo = newPageInfo({
	name: "Campuses Index",
	title: "",
	description: "Index of Campuses.",
	exact: true,
	to: campusHubPageInfo.to,
	path: campusHubPageInfo.path,
	parent: campusHubPageInfo,
});
