import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { TermFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the Term model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => TermFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(initialFormValues, TermFilterFormValues.typeFilterObject);
}
