import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import {
	BillingSetupSubmission,
	BillingSetupSubmissionFormat,
	BillingSetupSubmissionNameFragment,
} from "../../../../Utilities";

type ModelForLink = Pick<BillingSetupSubmission, "id"> & BillingSetupSubmissionNameFragment;

export type BillingSetupSubmissionLinkProps = DistributiveOmit<
	Models.LinkProps<ModelForLink>,
	"pageInfoKey" | "format"
>;

/**
 * Renders a link to the given BillingSetupSubmission instance.
 */
export function BillingSetupSubmissionLink(props: BillingSetupSubmissionLinkProps) {
	return (
		<Models.Link<ModelForLink>
			pageInfoKey="BillingSetupSubmission"
			format={BillingSetupSubmissionFormat.name}
			{...props}
		/>
	);
}
