import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const tuitionHubPageInfo = newPageInfo({
	title: "Tuitions",
	description: "Pages related to the Tuition model.",
	exact: false,
	to: hubsPageInfo.to + "/tuitions",
	path: hubsPageInfo.path + "/tuitions",
	parent: hubsPageInfo,
});
