import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { EnrollmentApplicationHubDetailPage } from "./DetailPage";
import { enrollmentApplicationHubDetailPageInfo } from "./DetailPage/pageinfo";
import { EnrollmentApplicationHubIndexPage } from "./IndexPage";
import { enrollmentApplicationHubIndexPageInfo } from "./IndexPage/pageinfo";
import { enrollmentApplicationHubPageInfo } from "./pageinfo";

export function EnrollmentApplicationHub() {
	useActivePageRegistration(enrollmentApplicationHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...enrollmentApplicationHubIndexPageInfo.routeProps}>
				<EnrollmentApplicationHubIndexPage />
			</Route>
			<Route {...enrollmentApplicationHubDetailPageInfo.routeProps}>
				<EnrollmentApplicationHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
