import { CaseStyle, convertCase, formatDateTime, stringToLocalDate } from "@hex-insights/core";
import { Employee } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the email field of the Employee model.
 */
export function email(value: Employee["email"]): string {
	return toString(value);
}

/**
 * Formats the jobTitle field of the Employee model.
 */
export function jobTitle(value: Employee["jobTitle"]): string {
	return toString(value);
}

/**
 * Formats the role field of the Employee model.
 */
export function role(value: Employee["role"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return convertCase(value, CaseStyle.Title);
}

/**
 * Formats the startDate field of the Employee model.
 */
export function startDate(value: Employee["startDate"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return formatDateTime(stringToLocalDate(value, "day"), "D MMM YYYY");
}

/**
 * Formats the endDate field of the Employee model.
 */
export function endDate(value: Employee["endDate"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return formatDateTime(stringToLocalDate(value, "day"), "D MMM YYYY");
}
