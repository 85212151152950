import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { ContractOrderFormValues } from "../../../../Utilities";

export type ContractOrderFormControllerProps = {
	formState: FormState<ContractOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the Contract model.
 */
export function ContractOrderFormController({ formState }: ContractOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={ContractOrderFormValues.fieldOptions}
			fieldTypeMapping={ContractOrderFormValues.fieldTypeMapping}
		/>
	);
}
