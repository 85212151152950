import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, If, Row } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
	SubmitButton,
} from "@hex-insights/forms";
import {
	ReportCardDetailQuery,
	ReportCardFormConversion,
	ReportCardFormState,
	ReportCardFormValues,
} from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<ReportCardFormValues.Create>;
	onSuccess: () => void;
};

/**
 * Renders the create form of the ReportCard model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = ReportCardFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<ReportCardFormValues.Create>;
};

/**
 * Renders the create form of the ReportCard model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<ReportCardFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="reportCard.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Field.IssueDate formState={formState} formType={FormType.Create} />
				<Field.LearningObjectiveMarks formState={formState} formType={FormType.Create} />
				<Field.Student formState={formState} formType={FormType.Create} />
				<Field.Term formState={formState} formType={FormType.Create} />

				<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
					Submit
				</SubmitButton>
			</Column>
		</Form>
	);
}

export type ControlledDetailProps = {
	reportCard: ReportCardDetailQuery["reportCard"];
	applyUpdate: ApplyUpdateFunction<ReportCardFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the ReportCard model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => ReportCardFormConversion.toFormValues(props.reportCard),
		[props.reportCard],
	);
	const formState = ReportCardFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<ReportCardFormValues.Detail>;
};

/**
 * Renders the detail form of the ReportCard model using the given form state.
 */
export function Detail({ formState, reportCard, applyUpdate, onSuccess }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<ReportCardFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form
			name={`reportCard.detail.${reportCard.id}`}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
		>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.IssueDate formState={formState} formType={FormType.Update} id={reportCard.id} />
				<Field.LearningObjectiveMarks
					formState={formState}
					formType={FormType.Update}
					id={reportCard.id}
					currentLearningObjectiveMarks={reportCard.learningObjectiveMarks}
				/>
				<Field.Student
					formState={formState}
					formType={FormType.Update}
					id={reportCard.id}
					currentStudent={reportCard.student}
				/>
				<Field.Term formState={formState} formType={FormType.Update} id={reportCard.id} currentTerm={reportCard.term} />
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	reportCard: ReportCardDetailQuery["reportCard"];
};

/**
 * Renders a read-only detail form of the ReportCard model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => ReportCardFormConversion.toFormValues(props.reportCard),
		[props.reportCard],
	);
	const formState = ReportCardFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<ReportCardFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the ReportCard model using the given form state.
 */
export function ReadOnly({ formState, reportCard }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.IssueDate formState={formState} formType={FormType.Update} id={reportCard.id} />
			<Field.LearningObjectiveMarks
				formState={formState}
				formType={FormType.Update}
				id={reportCard.id}
				currentLearningObjectiveMarks={reportCard.learningObjectiveMarks}
			/>
			<Field.Student
				formState={formState}
				formType={FormType.Update}
				id={reportCard.id}
				currentStudent={reportCard.student}
			/>
			<Field.Term formState={formState} formType={FormType.Update} id={reportCard.id} currentTerm={reportCard.term} />
		</Column>
	);
}
