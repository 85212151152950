import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { NoteReactionOrderFormValues } from "../../../../Utilities";

export type NoteReactionOrderFormControllerProps = {
	formState: FormState<NoteReactionOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the NoteReaction model.
 */
export function NoteReactionOrderFormController({ formState }: NoteReactionOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={NoteReactionOrderFormValues.fieldOptions}
			fieldTypeMapping={NoteReactionOrderFormValues.fieldTypeMapping}
		/>
	);
}
