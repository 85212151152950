import { newPageInfo } from "@hex-insights/router";
import { ExpenseBudget } from "@hex-insights/verita.shared";
import { expenseBudgetHubPageInfo } from "../pageinfo";

type ExpenseBudgetForRouteTitle = Pick<ExpenseBudget, "id">;

export const expenseBudgetHubDetailPageInfo = newPageInfo({
	name: "Expense Budget Detail",
	title: (e: ExpenseBudgetForRouteTitle) => e.id,
	description: "Detail page for an expenseBudget.",
	exact: false,
	isLeaf: true,
	to: (id: ExpenseBudget["id"]) => expenseBudgetHubPageInfo.to + `/${id}`,
	path: expenseBudgetHubPageInfo.path + "/:id(\\d+)",
	parent: expenseBudgetHubPageInfo,
});
