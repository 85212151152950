import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	SchoolAttendanceRecordForm,
	SchoolAttendanceRecordFormValues,
	SchoolAttendanceRecordMutation,
	useSchoolAttendanceRecordDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { schoolAttendanceRecordHubDetailPageInfo } from "./pageinfo";

function SchoolAttendanceRecordHubDetailPageWrapper() {
	// Manages identity of the SchoolAttendanceRecordHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <SchoolAttendanceRecordHubDetailPage key={id} />;
}

export { SchoolAttendanceRecordHubDetailPageWrapper as SchoolAttendanceRecordHubDetailPage };

function SchoolAttendanceRecordHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useSchoolAttendanceRecordDetailQuery({ variables: { id } });
	const schoolAttendanceRecord = data?.schoolAttendanceRecord;
	useActivePageRegistration(
		schoolAttendanceRecordHubDetailPageInfo,
		!!schoolAttendanceRecord ? schoolAttendanceRecordHubDetailPageInfo.title(schoolAttendanceRecord) : "",
	);

	const update = SchoolAttendanceRecordMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<SchoolAttendanceRecordFormValues.Detail>,
			initialFormValues: SchoolAttendanceRecordFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading schoolAttendanceRecord</If>
				<Else>
					{!!schoolAttendanceRecord && (
						<React.Fragment>
							<Heading level={1}>{schoolAttendanceRecord.id}</Heading>

							<SchoolAttendanceRecordForm.ControlledDetail
								key={syncID}
								schoolAttendanceRecord={schoolAttendanceRecord}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
