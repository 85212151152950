import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { BusForm, BusFormValues, BusMutation, useBusDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { busHubDetailPageInfo } from "./pageinfo";

function BusHubDetailPageWrapper() {
	// Manages identity of the BusHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <BusHubDetailPage key={id} />;
}

export { BusHubDetailPageWrapper as BusHubDetailPage };

function BusHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useBusDetailQuery({ variables: { id } });
	const bus = data?.bus;
	useActivePageRegistration(busHubDetailPageInfo, !!bus ? busHubDetailPageInfo.title(bus) : "");

	const update = BusMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<BusFormValues.Detail>, initialFormValues: BusFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading bus</If>
				<Else>
					{!!bus && (
						<React.Fragment>
							<Heading level={1}>{bus.name}</Heading>

							<BusForm.ControlledDetail key={syncID} bus={bus} applyUpdate={applyUpdate} onSuccess={synchronize} />
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
