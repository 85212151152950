import { newPageInfo } from "@hex-insights/router";
import { Room } from "@hex-insights/verita.shared";
import { roomHubPageInfo } from "../pageinfo";

type RoomForRouteTitle = Pick<Room, "name">;

export const roomHubDetailPageInfo = newPageInfo({
	name: "Room Detail",
	title: (e: RoomForRouteTitle) => e.name,
	description: "Detail page for an room.",
	exact: false,
	isLeaf: true,
	to: (id: Room["id"]) => roomHubPageInfo.to + `/${id}`,
	path: roomHubPageInfo.path + "/:id(\\d+)",
	parent: roomHubPageInfo,
});
