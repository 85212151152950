import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { PaymentLineItem, PaymentLineItemFormat, PaymentLineItemNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<PaymentLineItem, "id"> & PaymentLineItemNameFragment;

export type PaymentLineItemLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given PaymentLineItem instance.
 */
export function PaymentLineItemLink(props: PaymentLineItemLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="PaymentLineItem" format={PaymentLineItemFormat.name} {...props} />;
}
