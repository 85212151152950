import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { CampusCreateInput, CampusDetailQuery, CampusUpdateInput } from "../../GraphQL";
import { CampusFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Campus model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: CampusFormValues.Create): CampusCreateInput {
	const input: NullPartial<CampusCreateInput> = { ...formValues };
	return input as CampusCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Campus model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<CampusFormValues.Detail>,
	initialFormValues: CampusFormValues.Detail,
): CampusUpdateInput {
	const inputFormValues = omitKeys(formValues, ["employeeIDs", "roomIDs"]);
	const input: CampusUpdateInput = { ...inputFormValues };

	if (formValues.employeeIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.employeeIDs, formValues.employeeIDs);
		input.addEmployeeIDs = added;
		input.removeEmployeeIDs = removed;
	}

	if (formValues.roomIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.roomIDs, formValues.roomIDs);
		input.addRoomIDs = added;
		input.removeRoomIDs = removed;
	}

	return input;
}

/**
 * Converts a Campus instance to its corresponding detail form values.
 *
 * @param campus The instance to convert.
 */
export function toFormValues(campus: CampusDetailQuery["campus"]): CampusFormValues.Detail {
	return {
		name: campus.name,
		addressLine1: campus.addressLine1,
		addressLine2: campus.addressLine2,
		postalCode: campus.postalCode,
		city: campus.city,
		stateOrProvince: campus.stateOrProvince,
		country: campus.country,
		employeeIDs: campus.employees.map((e) => e.id),
		roomIDs: campus.rooms.map((e) => e.id),
	};
}
