import React from "react";
import { Heading } from "@hex-insights/core";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { PaymentForm, PaymentFormValues, PaymentMutation, Tile } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { paymentHubDetailPageInfo } from "../DetailPage/pageinfo";
import { paymentHubCreatePageInfo } from "./pageinfo";

export function PaymentHubCreatePage() {
	useActivePageRegistration(paymentHubCreatePageInfo);

	const create = PaymentMutation.useCreate();
	const createdIDRef = React.useRef<string | null>(null);
	const { push: pushHistory } = useHistory();

	const applyCreate = React.useCallback(
		async (formValues: PaymentFormValues.Create) => {
			const { data, errors } = await create(formValues);
			createdIDRef.current = data?.id ?? null;
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		if (createdIDRef.current !== null) {
			pushHistory(paymentHubDetailPageInfo.to(createdIDRef.current));
		}
	}, [pushHistory]);

	return (
		<Main>
			<Tile>
				<Tile.Header>
					<Heading level={1} noMargin>
						New Payment
					</Heading>
				</Tile.Header>
				<Tile.Body>
					<PaymentForm.ControlledCreate applyCreate={applyCreate} onSuccess={onSuccess} />
				</Tile.Body>
			</Tile>
		</Main>
	);
}
