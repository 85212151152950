import { omitKeys } from "@hex-insights/core";
import { EnrollmentApplicationSubmissionDetailQuery, EnrollmentApplicationSubmissionUpdateInput } from "../../GraphQL";
import { EnrollmentApplicationSubmissionFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the EnrollmentApplicationSubmission model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<EnrollmentApplicationSubmissionFormValues.Detail>,
	_initialFormValues: EnrollmentApplicationSubmissionFormValues.Detail,
): EnrollmentApplicationSubmissionUpdateInput {
	const inputFormValues = omitKeys(formValues, ["createdAt"]);
	const input: EnrollmentApplicationSubmissionUpdateInput = { ...inputFormValues };

	return input;
}

/**
 * Converts a EnrollmentApplicationSubmission instance to its corresponding detail form values.
 *
 * @param enrollmentApplicationSubmission The instance to convert.
 */
export function toFormValues(
	enrollmentApplicationSubmission: EnrollmentApplicationSubmissionDetailQuery["enrollmentApplicationSubmission"],
): EnrollmentApplicationSubmissionFormValues.Detail {
	return {
		createdAt: enrollmentApplicationSubmission.createdAt,
		doesConsentToDataUse: enrollmentApplicationSubmission.doesConsentToDataUse,
		signatureName: enrollmentApplicationSubmission.signatureName,
		signatureDate: enrollmentApplicationSubmission.signatureDate,
		source: enrollmentApplicationSubmission.source,
	};
}
