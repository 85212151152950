import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { UserGroupOrderFormValues } from "../../../../Utilities";

export type UserGroupOrderFormControllerProps = {
	formState: FormState<UserGroupOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the UserGroup model.
 */
export function UserGroupOrderFormController({ formState }: UserGroupOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={UserGroupOrderFormValues.fieldOptions}
			fieldTypeMapping={UserGroupOrderFormValues.fieldTypeMapping}
		/>
	);
}
