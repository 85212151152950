import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, If, Row } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
	SubmitButton,
} from "@hex-insights/forms";
import { RoomDetailQuery, RoomFormConversion, RoomFormState, RoomFormValues } from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<RoomFormValues.Create>;
	onSuccess: () => void;
};

/**
 * Renders the create form of the Room model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = RoomFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<RoomFormValues.Create>;
};

/**
 * Renders the create form of the Room model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<RoomFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="room.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Field.Name formState={formState} formType={FormType.Create} />
				<Field.Number formState={formState} formType={FormType.Create} />
				<Field.Campus formState={formState} formType={FormType.Create} />

				<Row justify="flex-end">
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

export type ControlledDetailProps = {
	room: RoomDetailQuery["room"];
	applyUpdate: ApplyUpdateFunction<RoomFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the Room model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(() => RoomFormConversion.toFormValues(props.room), [props.room]);
	const formState = RoomFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<RoomFormValues.Detail>;
};

/**
 * Renders the detail form of the Room model using the given form state.
 */
export function Detail({ formState, room, applyUpdate, onSuccess }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<RoomFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form name={`room.detail.${room.id}`} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.Name formState={formState} formType={FormType.Update} id={room.id} />
				<Field.Number formState={formState} formType={FormType.Update} id={room.id} />
				<Field.Campus formState={formState} formType={FormType.Update} id={room.id} currentCampus={room.campus} />
				<Field.CourseSectionPeriods
					formState={formState}
					formType={FormType.Update}
					id={room.id}
					currentCourseSectionPeriods={room.courseSectionPeriods}
				/>
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	room: RoomDetailQuery["room"];
};

/**
 * Renders a read-only detail form of the Room model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(() => RoomFormConversion.toFormValues(props.room), [props.room]);
	const formState = RoomFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<RoomFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the Room model using the given form state.
 */
export function ReadOnly({ formState, room }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.Name formState={formState} formType={FormType.Update} id={room.id} />
			<Field.Number formState={formState} formType={FormType.Update} id={room.id} />
			<Field.Campus formState={formState} formType={FormType.Update} id={room.id} currentCampus={room.campus} />
			<Field.CourseSectionPeriods
				formState={formState}
				formType={FormType.Update}
				id={room.id}
				currentCourseSectionPeriods={room.courseSectionPeriods}
			/>
		</Column>
	);
}
