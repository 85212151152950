import { IndexForms } from "@hex-insights/app-modules";
import { QuotationFilterFormValues } from "../../../../Utilities";

export type QuotationSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the Quotation model.
 */
export function QuotationSearchForm(props: QuotationSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type QuotationFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, QuotationFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Quotation model.
 */
export function QuotationFilterFormController(props: QuotationFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={QuotationFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `QuotationFilterForm`
