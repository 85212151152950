import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	graphTypeNames,
	Invoice,
	InvoiceLineItem,
	InvoiceLineItemCreateInput,
	InvoiceLineItemCreateMutation,
	InvoiceLineItemDeleteMutation,
	InvoiceLineItemDetailDocument,
	InvoiceLineItemDetailQuery,
	InvoiceLineItemDetailQueryVariables,
	InvoiceLineItemUpdateInput,
	InvoiceLineItemUpdateMutation,
	ServiceSubscription,
	useInvoiceLineItemCreateMutation,
	useInvoiceLineItemDeleteMutation,
	useInvoiceLineItemUpdateMutation,
} from "../../GraphQL";
import { InvoiceLineItemFormConversion } from "../ModelFormConversion";
import { InvoiceLineItemFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the InvoiceLineItem model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the InvoiceLineItem model.
 */
export function useCreate() {
	const [createInvoiceLineItem] = useInvoiceLineItemCreateMutation();

	return React.useCallback(
		async (formValues: InvoiceLineItemFormValues.Create) => {
			const input = InvoiceLineItemFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createInvoiceLineItem({ variables: { input }, update: updateCache });

			return { data: data?.createInvoiceLineItem ?? null, errors: errors ?? null };
		},
		[createInvoiceLineItem],
	);
}

/**
 * Returns an `update` function for the InvoiceLineItem model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the InvoiceLineItem model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: InvoiceLineItem["id"]) {
	const [updateInvoiceLineItem] = useInvoiceLineItemUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<InvoiceLineItemFormValues.Detail>,
			initialFormValues: InvoiceLineItemFormValues.Detail,
		) => {
			const input = InvoiceLineItemFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateInvoiceLineItem({ variables: { id, input }, update: updateCache });

			return { data: data?.updateInvoiceLineItem ?? null, errors: errors ?? null };
		},
		[updateInvoiceLineItem, id],
	);
}

/**
 * Returns a `del` function for the InvoiceLineItem model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: InvoiceLineItem["id"]) {
	const [deleteInvoiceLineItem] = useInvoiceLineItemDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteInvoiceLineItem({ variables: { id }, update: updateCache });

		return { data: data?.deleteInvoiceLineItem ?? false, errors: errors ?? null };
	}, [deleteInvoiceLineItem, id]);
}

function getUpdateCacheForCreate(input: InvoiceLineItemCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<InvoiceLineItemCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createInvoiceLineItem;

		cache.writeQuery<InvoiceLineItemDetailQuery, InvoiceLineItemDetailQueryVariables>({
			query: InvoiceLineItemDetailDocument,
			data: { invoiceLineItem: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.invoiceID) {
			addToInvoiceLineItemsOfInvoiceCache(cache, input.invoiceID, createdObject);
		}

		if (input.serviceSubscriptionID) {
			addToInvoiceLineItemsOfServiceSubscriptionCache(cache, input.serviceSubscriptionID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "invoiceLineItemConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: InvoiceLineItemUpdateInput,
	initialFormValues: InvoiceLineItemFormValues.Detail,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<InvoiceLineItemUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateInvoiceLineItem;

		if (initialFormValues.invoiceID && input.invoiceID) {
			removeFromInvoiceLineItemsOfInvoiceCache(cache, initialFormValues.invoiceID, updatedObject);
		}
		if (input.invoiceID) {
			addToInvoiceLineItemsOfInvoiceCache(cache, input.invoiceID, updatedObject);
		}

		if (initialFormValues.serviceSubscriptionID && (input.serviceSubscriptionID || input.clearServiceSubscription)) {
			removeFromInvoiceLineItemsOfServiceSubscriptionCache(
				cache,
				initialFormValues.serviceSubscriptionID,
				updatedObject,
			);
		}
		if (input.serviceSubscriptionID) {
			addToInvoiceLineItemsOfServiceSubscriptionCache(cache, input.serviceSubscriptionID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: InvoiceLineItem["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<InvoiceLineItemDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteInvoiceLineItem) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.InvoiceLineItem }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "invoiceLineItemConnection" });
		cache.gc();
	};
}

function addToInvoiceLineItemsOfInvoiceCache(
	cache: ApolloCache<any>,
	targetID: Invoice["id"],
	object: Pick<InvoiceLineItem, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Invoice }),
		fields: {
			invoiceLineItems: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromInvoiceLineItemsOfInvoiceCache(
	cache: ApolloCache<any>,
	targetID: Invoice["id"],
	object: Pick<InvoiceLineItem, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Invoice }),
		fields: {
			invoiceLineItems: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToInvoiceLineItemsOfServiceSubscriptionCache(
	cache: ApolloCache<any>,
	targetID: ServiceSubscription["id"],
	object: Pick<InvoiceLineItem, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ServiceSubscription }),
		fields: {
			invoiceLineItems: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromInvoiceLineItemsOfServiceSubscriptionCache(
	cache: ApolloCache<any>,
	targetID: ServiceSubscription["id"],
	object: Pick<InvoiceLineItem, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ServiceSubscription }),
		fields: {
			invoiceLineItems: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<InvoiceLineItem, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment InvoiceLineItemRef on InvoiceLineItem {
				id
			}
		`,
		data: object,
	});
}
