import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { DocumentResourceFolderOrderFormValues } from "../../../../Utilities";

export type DocumentResourceFolderOrderFormControllerProps = {
	formState: FormState<DocumentResourceFolderOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the DocumentResourceFolder model.
 */
export function DocumentResourceFolderOrderFormController({
	formState,
}: DocumentResourceFolderOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={DocumentResourceFolderOrderFormValues.fieldOptions}
			fieldTypeMapping={DocumentResourceFolderOrderFormValues.fieldTypeMapping}
		/>
	);
}
