import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const billingSetupSubmissionHubPageInfo = newPageInfo({
	title: "Billing Setup Submissions",
	description: "Pages related to the BillingSetupSubmission model.",
	exact: false,
	to: hubsPageInfo.to + "/billing-setup-submissions",
	path: hubsPageInfo.path + "/billing-setup-submissions",
	parent: hubsPageInfo,
});
