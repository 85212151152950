import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { SchoolStudentEnrollmentHubCreatePage } from "./CreatePage";
import { schoolStudentEnrollmentHubCreatePageInfo } from "./CreatePage/pageinfo";
import { SchoolStudentEnrollmentHubDetailPage } from "./DetailPage";
import { schoolStudentEnrollmentHubDetailPageInfo } from "./DetailPage/pageinfo";
import { SchoolStudentEnrollmentHubIndexPage } from "./IndexPage";
import { schoolStudentEnrollmentHubIndexPageInfo } from "./IndexPage/pageinfo";
import { schoolStudentEnrollmentHubPageInfo } from "./pageinfo";

export function SchoolStudentEnrollmentHub() {
	useActivePageRegistration(schoolStudentEnrollmentHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...schoolStudentEnrollmentHubIndexPageInfo.routeProps}>
				<SchoolStudentEnrollmentHubIndexPage />
			</Route>
			<Route {...schoolStudentEnrollmentHubCreatePageInfo.routeProps}>
				<SchoolStudentEnrollmentHubCreatePage />
			</Route>
			<Route {...schoolStudentEnrollmentHubDetailPageInfo.routeProps}>
				<SchoolStudentEnrollmentHubDetailPage key={schoolStudentEnrollmentHubDetailPageInfo.useKey()} />
			</Route>
		</PageRouteSwitch>
	);
}
