import React from "react";
import { ApolloCache, FetchResult } from "@apollo/client";
import {
	EnrollmentApplication,
	EnrollmentApplicationUpdateInput,
	EnrollmentApplicationUpdateMutation,
	useEnrollmentApplicationUpdateMutation,
} from "../../GraphQL";
import { EnrollmentApplicationFormConversion } from "../ModelFormConversion";
import { EnrollmentApplicationFormValues } from "../ModelFormValues";

/**
 * Returns an `update` function for the EnrollmentApplication model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the EnrollmentApplication model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: EnrollmentApplication["id"]) {
	const [updateEnrollmentApplication] = useEnrollmentApplicationUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<EnrollmentApplicationFormValues.Detail>,
			initialFormValues: Partial<EnrollmentApplicationFormValues.Detail>,
		) => {
			const input = EnrollmentApplicationFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateEnrollmentApplication({
				variables: { id, input },
				update: updateCache,
			});

			return { data: data?.updateEnrollmentApplication ?? null, errors: errors ?? null };
		},
		[updateEnrollmentApplication, id],
	);
}

function getUpdateCacheForUpdate(
	_input: EnrollmentApplicationUpdateInput,
	_initialFormValues: Partial<EnrollmentApplicationFormValues.Detail>,
) {
	return (
		_cache: ApolloCache<any>,
		result: Omit<FetchResult<EnrollmentApplicationUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}
	};
}

// function toCacheRef(cache: ApolloCache<any>, object: Pick<EnrollmentApplication, "id">) {
//     return cache.writeFragment({
//         fragment: gql`
//             fragment EnrollmentApplicationRef on EnrollmentApplication {
//                 id
//             }
//         `,
//         data: object,
//     });
// }
