import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { PersonFilterInput, PersonOrder, usePersonPaginationQuery } from "../../../../Utilities";

export type PersonPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: PersonFilterInput | PersonFilterInput[] | null;
	order: PersonOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the Person model.
 */
export function PersonPaginationForm({ formState, filters, order, setPaginationInfo }: PersonPaginationFormProps) {
	const { loading, data } = usePersonPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.personConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
