import { newPageInfo } from "@hex-insights/router";
import { enrollmentApplicationParentHubPageInfo } from "../pageinfo";

export const enrollmentApplicationParentHubIndexPageInfo = newPageInfo({
	name: "Enrollment Application Parents Index",
	title: "",
	description: "Index of Enrollment Application Parents.",
	exact: true,
	to: enrollmentApplicationParentHubPageInfo.to,
	path: enrollmentApplicationParentHubPageInfo.path,
	parent: enrollmentApplicationParentHubPageInfo,
});
