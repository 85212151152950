import { newPageInfo } from "@hex-insights/router";
import { loginRecordHubPageInfo } from "../pageinfo";

export const loginRecordHubIndexPageInfo = newPageInfo({
	name: "Login Records Index",
	title: "",
	description: "Index of Login Records.",
	exact: true,
	to: loginRecordHubPageInfo.to,
	path: loginRecordHubPageInfo.path,
	parent: loginRecordHubPageInfo,
});
