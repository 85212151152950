import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { EnrollmentApplicationSubmissionHubDetailPage } from "./DetailPage";
import { enrollmentApplicationSubmissionHubDetailPageInfo } from "./DetailPage/pageinfo";
import { EnrollmentApplicationSubmissionHubIndexPage } from "./IndexPage";
import { enrollmentApplicationSubmissionHubIndexPageInfo } from "./IndexPage/pageinfo";
import { enrollmentApplicationSubmissionHubPageInfo } from "./pageinfo";

export function EnrollmentApplicationSubmissionHub() {
	useActivePageRegistration(enrollmentApplicationSubmissionHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...enrollmentApplicationSubmissionHubIndexPageInfo.routeProps}>
				<EnrollmentApplicationSubmissionHubIndexPage />
			</Route>
			<Route {...enrollmentApplicationSubmissionHubDetailPageInfo.routeProps}>
				<EnrollmentApplicationSubmissionHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
