import { newPageInfo } from "@hex-insights/router";
import { courseHubPageInfo } from "../pageinfo";

export const courseHubIndexPageInfo = newPageInfo({
	name: "Courses Index",
	title: "",
	description: "Index of Courses.",
	exact: true,
	to: courseHubPageInfo.to,
	path: courseHubPageInfo.path,
	parent: courseHubPageInfo,
});
