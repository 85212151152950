import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { LoginRecordOrderFormValues } from "../../../../Utilities";

export type LoginRecordOrderFormControllerProps = {
	formState: FormState<LoginRecordOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the LoginRecord model.
 */
export function LoginRecordOrderFormController({ formState }: LoginRecordOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={LoginRecordOrderFormValues.fieldOptions}
			fieldTypeMapping={LoginRecordOrderFormValues.fieldTypeMapping}
		/>
	);
}
