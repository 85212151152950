import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { InvoiceForm, InvoiceFormValues, InvoiceMutation, useInvoiceDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { invoiceHubDetailPageInfo } from "./pageinfo";

function InvoiceHubDetailPageWrapper() {
	// Manages identity of the InvoiceHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <InvoiceHubDetailPage key={id} />;
}

export { InvoiceHubDetailPageWrapper as InvoiceHubDetailPage };

function InvoiceHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useInvoiceDetailQuery({ variables: { id } });
	const invoice = data?.invoice;
	useActivePageRegistration(invoiceHubDetailPageInfo, !!invoice ? invoiceHubDetailPageInfo.title(invoice) : "");

	const update = InvoiceMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<InvoiceFormValues.Detail>, initialFormValues: InvoiceFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading invoice</If>
				<Else>
					{!!invoice && (
						<React.Fragment>
							<Heading level={1}>Invoice {invoice.invoiceNumber}</Heading>

							<InvoiceForm.ControlledDetail
								key={syncID}
								invoice={invoice}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
