import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { ExpenseHubCreatePage } from "./CreatePage";
import { expenseHubCreatePageInfo } from "./CreatePage/pageinfo";
import { ExpenseHubDetailPage } from "./DetailPage";
import { expenseHubDetailPageInfo } from "./DetailPage/pageinfo";
import { ExpenseHubIndexPage } from "./IndexPage";
import { expenseHubIndexPageInfo } from "./IndexPage/pageinfo";
import { expenseHubPageInfo } from "./pageinfo";

export function ExpenseHub() {
	useActivePageRegistration(expenseHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...expenseHubIndexPageInfo.routeProps}>
				<ExpenseHubIndexPage />
			</Route>
			<Route {...expenseHubCreatePageInfo.routeProps}>
				<ExpenseHubCreatePage />
			</Route>
			<Route {...expenseHubDetailPageInfo.routeProps}>
				<ExpenseHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
