import { newPageInfo } from "@hex-insights/router";
import { roomHubPageInfo } from "../pageinfo";

export const roomHubIndexPageInfo = newPageInfo({
	name: "Rooms Index",
	title: "",
	description: "Index of Rooms.",
	exact: true,
	to: roomHubPageInfo.to,
	path: roomHubPageInfo.path,
	parent: roomHubPageInfo,
});
