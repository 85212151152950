import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const learningObjectiveHubPageInfo = newPageInfo({
	title: "Learning Objectives",
	description: "Pages related to the Learning Objective model.",
	exact: false,
	to: hubsPageInfo.to + "/learning-objectives",
	path: hubsPageInfo.path + "/learning-objectives",
	parent: hubsPageInfo,
});
