import { newPageInfo } from "@hex-insights/router";
import { paymentHubPageInfo } from "../pageinfo";

export const paymentHubCreatePageInfo = newPageInfo({
	name: "New Payment",
	title: "New",
	description: "Create a new Payment record.",
	exact: true,
	to: paymentHubPageInfo.to + "/new",
	path: paymentHubPageInfo.path + "/new",
	parent: paymentHubPageInfo,
});
