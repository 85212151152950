import { newPageInfo } from "@hex-insights/router";
import { tuitionHubPageInfo } from "../pageinfo";

export const tuitionHubIndexPageInfo = newPageInfo({
	name: "Tuitions Index",
	title: "",
	description: "Index of Tuitions.",
	exact: true,
	to: tuitionHubPageInfo.to,
	path: tuitionHubPageInfo.path,
	parent: tuitionHubPageInfo,
});
