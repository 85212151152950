import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import {
	LearningObjectiveMark,
	LearningObjectiveMarkFormat,
	LearningObjectiveMarkNameFragment,
} from "../../../../Utilities";

type ModelForLink = Pick<LearningObjectiveMark, "id"> & LearningObjectiveMarkNameFragment;

export type LearningObjectiveMarkLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given LearningObjectiveMark instance.
 */
export function LearningObjectiveMarkLink(props: LearningObjectiveMarkLinkProps) {
	return (
		<Models.Link<ModelForLink>
			pageInfoKey="LearningObjectiveMark"
			format={LearningObjectiveMarkFormat.name}
			{...props}
		/>
	);
}
