import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { CourseSectionPeriodFilterInput } from "../../GraphQL";
import { CourseSectionPeriodFilterFormValues } from "../ModelFilterFormValues";

/**
 * Counts the number of active filters for the CourseSectionPeriod model.
 *
 * @param formValues The form values of the filter form.
 */
export function countActiveFilters(formValues: CourseSectionPeriodFilterFormValues.FormValues) {
	// TODO count quick filters
	const numAdvancedFilters = IndexForms.countActiveAdvancedFilters(
		formValues,
		CourseSectionPeriodFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	);
	return numAdvancedFilters;
}

/**
 * Clears the values of the filter form for the CourseSectionPeriod model.
 *
 * @param formState The form state of the filter form.
 */
export function clearFilters(formState: FormState<CourseSectionPeriodFilterFormValues.FormValues>) {
	// TODO clear quick filters
	formState.formClearFunctions.filterSets();
}

/**
 * Resets the values of the filter form for the CourseSectionPeriod model.
 *
 * @param formState The form state of the filter form.
 */
export function resetFilters(formState: FormState<CourseSectionPeriodFilterFormValues.FormValues>) {
	// TODO reset quick filters
	formState.formResetFunctions.filterSets();
}

/**
 * Converts the form values of the filter form for the CourseSectionPeriod model to the correct GraphQL
 * filter input type.
 *
 * @param formValues The form values of the filter form.
 */
export function toFilterInputs(
	formValues: CourseSectionPeriodFilterFormValues.FormValues,
): CourseSectionPeriodFilterInput | CourseSectionPeriodFilterInput[] {
	return IndexForms.toFilterInput(
		formValues,
		CourseSectionPeriodFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	) as CourseSectionPeriodFilterInput;
}
