import React from "react";
import { Heading } from "@hex-insights/core";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { EnrollmentApplicationStudentForm } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { enrollmentApplicationStudentHubDetailPageInfo } from "../DetailPage/pageinfo";
import { enrollmentApplicationStudentHubCreatePageInfo } from "./pageinfo";

export function EnrollmentApplicationStudentHubCreatePage() {
	useActivePageRegistration(enrollmentApplicationStudentHubCreatePageInfo);

	const { push: pushHistory } = useHistory();
	const onSuccess = React.useCallback<EnrollmentApplicationStudentForm.ControlledCreateProps["onSuccess"]>(
		({ id }) => {
			pushHistory(enrollmentApplicationStudentHubDetailPageInfo.to(id));
		},
		[pushHistory],
	);

	return (
		<Main>
			<Heading.H1>New Enrollment Application Student</Heading.H1>

			<EnrollmentApplicationStudentForm.ControlledCreate onSuccess={onSuccess} />
		</Main>
	);
}
