import { Models } from "@hex-insights/app-modules";
import { DocumentResource, DocumentResourceMutation } from "../../../../Utilities";

export type DocumentResourceDeleteButtonProps = Omit<Models.DeleteButtonProps, "deleteInstance"> & {
	instanceID: DocumentResource["id"];
};

/**
 * A delete button for the DocumentResource model.
 */
export function DocumentResourceDeleteButton({
	instanceID,
	confirmationContent = "Are you sure you want to delete this resource?",
	children = "Delete Resource",
	...props
}: DocumentResourceDeleteButtonProps) {
	const del = DocumentResourceMutation.useDelete(instanceID);

	return (
		<Models.DeleteButton deleteInstance={del} confirmationContent={confirmationContent} {...props}>
			{children}
		</Models.DeleteButton>
	);
}
