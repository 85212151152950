import React from "react";
import { Tile, Timeline, TimelineObjectType, usePaginatedTimelineQuery } from "@hex-insights/verita.shared";

const timelineObjectTypes = [TimelineObjectType.Note, TimelineObjectType.StudentDocument];

export function GeneralDashboardTimelineTile() {
	const { data, networkStatus, refetch, hasMore, onLoadMoreClick } = usePaginatedTimelineQuery({
		filter: { objectTypes: timelineObjectTypes },
	});

	return (
		<Tile style={{ flexGrow: 1, height: "100%", minHeight: 0 }}>
			<Tile.Body
				className="timeline__scrolling-container__container"
				style={{ height: "100%", overflow: "hidden", "--section__body---padding": 0 } as React.CSSProperties}
			>
				<div
					className="timeline__scrolling-container"
					style={{ height: "100%", overflow: "scroll", padding: "1rem 1.5rem" }}
				>
					<Timeline data={data?.timeline} networkStatus={networkStatus} refetch={refetch} />

					<Timeline.LoadMoreButton hasMore={hasMore} networkStatus={networkStatus} onClick={onLoadMoreClick} />
				</div>
			</Tile.Body>
		</Tile>
	);
}
