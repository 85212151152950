import { IndexForms } from "@hex-insights/app-modules";
import { ExpenseCategoryFilterFormValues } from "../../../../Utilities";

export type ExpenseCategorySearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the ExpenseCategory model.
 */
export function ExpenseCategorySearchForm(props: ExpenseCategorySearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type ExpenseCategoryFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, ExpenseCategoryFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the ExpenseCategory model.
 */
export function ExpenseCategoryFilterFormController(props: ExpenseCategoryFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={ExpenseCategoryFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `ExpenseCategoryFilterForm`
