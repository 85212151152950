import { newPageInfo } from "@hex-insights/router";
import { dashboardsPageInfo } from "../pageinfo";

export const dashboardsDirectorPageInfo = newPageInfo({
	name: "Director's Dashboard",
	title: "Director",
	description: "Director's dashboard.",
	exact: true,
	to: dashboardsPageInfo.to + "/director",
	path: dashboardsPageInfo.path + "/director",
	parent: dashboardsPageInfo,
});
