import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { NoteForm, NoteFormValues, NoteMutation, useNoteDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { noteHubDetailPageInfo } from "./pageinfo";

function NoteHubDetailPageWrapper() {
	// Manages identity of the NoteHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <NoteHubDetailPage key={id} />;
}

export { NoteHubDetailPageWrapper as NoteHubDetailPage };

function NoteHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useNoteDetailQuery({ variables: { id } });
	const note = data?.note;
	useActivePageRegistration(noteHubDetailPageInfo, !!note ? noteHubDetailPageInfo.title(note) : "");

	const update = NoteMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<NoteFormValues.Detail>, initialFormValues: NoteFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading note</If>
				<Else>
					{!!note && (
						<React.Fragment>
							<Heading level={1}>{note.title}</Heading>

							<NoteForm.ControlledDetail key={syncID} note={note} applyUpdate={applyUpdate} onSuccess={synchronize} />
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
