import React from "react";
import { BooleanField, DateTimeField, FieldDisplayArgs, FormType, SelectField } from "@hex-insights/forms";
import {
	EnrollmentApplicationSelect,
	EnrollmentApplicationUserViewRecordFormValues,
	useEnrollmentApplicationSelectLazyQuery,
	UserSelect,
	useUserSelectLazyQuery,
} from "../../../../Utilities";
import { EnrollmentApplicationLink, UserLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the EnrollmentApplicationUserViewRecord model.
 */
type FieldProps<
	K extends keyof EnrollmentApplicationUserViewRecordFormValues.Base = keyof EnrollmentApplicationUserViewRecordFormValues.Base,
> = BaseFieldProps<Pick<EnrollmentApplicationUserViewRecordFormValues.Base, K>>;

/**
 * Generic props for fields of the EnrollmentApplicationUserViewRecord model that only appear in the detail form.
 */
type DetailFieldProps<
	K extends keyof EnrollmentApplicationUserViewRecordFormValues.Detail = keyof EnrollmentApplicationUserViewRecordFormValues.Detail,
> = BaseFieldProps<Pick<EnrollmentApplicationUserViewRecordFormValues.Detail, K>>;

/**
 * Renders a field component for the `createdAt` field of the EnrollmentApplicationUserViewRecord model.
 */
export function CreatedAt({ formState, formType = FormType.Update }: FieldProps<"createdAt">) {
	return (
		<DateTimeField formState={formState} name="createdAt" optional={FormType.isCreate(formType)} precision="minute" />
	);
}

/**
 * Renders a field component for the `isDetail` field of the EnrollmentApplicationUserViewRecord model.
 */
export function IsDetail({ formState }: FieldProps<"isDetail">) {
	return <BooleanField formState={formState} name="isDetail" />;
}

export type EnrollmentApplicationProps = DetailFieldProps<"enrollmentApplicationID"> & {
	currentEnrollmentApplication?: EnrollmentApplicationSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `enrollmentApplication` edge of the EnrollmentApplicationUserViewRecord model.
 */
export function EnrollmentApplication({ formState, currentEnrollmentApplication }: EnrollmentApplicationProps) {
	const [loadOptions, { loading, data }] = useEnrollmentApplicationSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.enrollmentApplicationID) {
			loadOptions();
		}
	}, [formState.formEditing.enrollmentApplicationID, loadOptions]);
	const options = React.useMemo(
		() =>
			EnrollmentApplicationSelect.toOptions(data?.enrollmentApplicationConnection.edges, currentEnrollmentApplication),
		[data, currentEnrollmentApplication],
	);

	return (
		<SelectField
			formState={formState}
			name="enrollmentApplicationID"
			isLoading={loading}
			options={options}
			display={displayEnrollmentApplication}
			blankValue={null}
		/>
	);
}

function displayEnrollmentApplication({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <EnrollmentApplicationLink instance={{ id }}>{formattedValue}</EnrollmentApplicationLink>;
}

export type UserProps = DetailFieldProps<"userID"> & {
	currentUser?: UserSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `user` edge of the EnrollmentApplicationUserViewRecord model.
 */
export function User({ formState, currentUser }: UserProps) {
	const [loadOptions, { loading, data }] = useUserSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.userID) {
			loadOptions();
		}
	}, [formState.formEditing.userID, loadOptions]);
	const options = React.useMemo(
		() => UserSelect.toOptions(data?.userConnection.edges, currentUser),
		[data, currentUser],
	);

	return (
		<SelectField
			formState={formState}
			name="userID"
			isLoading={loading}
			options={options}
			display={displayUser}
			blankValue={null}
		/>
	);
}

function displayUser({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserLink instance={{ id }}>{formattedValue}</UserLink>;
}
