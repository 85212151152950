import { IndexForms } from "@hex-insights/app-modules";
import { PaymentFilterFormValues } from "../../../../Utilities";

export type PaymentSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the Payment model.
 */
export function PaymentSearchForm(props: PaymentSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type PaymentFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, PaymentFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Payment model.
 */
export function PaymentFilterFormController(props: PaymentFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={PaymentFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `PaymentFilterForm`
