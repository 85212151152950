import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { InvoiceHubCreatePage } from "./CreatePage";
import { invoiceHubCreatePageInfo } from "./CreatePage/pageinfo";
import { InvoiceHubDetailPage } from "./DetailPage";
import { invoiceHubDetailPageInfo } from "./DetailPage/pageinfo";
import { InvoiceHubIndexPage } from "./IndexPage";
import { invoiceHubIndexPageInfo } from "./IndexPage/pageinfo";
import { invoiceHubPageInfo } from "./pageinfo";

export function InvoiceHub() {
	useActivePageRegistration(invoiceHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...invoiceHubIndexPageInfo.routeProps}>
				<InvoiceHubIndexPage />
			</Route>
			<Route {...invoiceHubCreatePageInfo.routeProps}>
				<InvoiceHubCreatePage />
			</Route>
			<Route {...invoiceHubDetailPageInfo.routeProps}>
				<InvoiceHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
