import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { SessionFilterInput, SessionOrder, useSessionPaginationQuery } from "../../../../Utilities";

export type SessionPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: SessionFilterInput | SessionFilterInput[] | null;
	order: SessionOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the Session model.
 */
export function SessionPaginationForm({ formState, filters, order, setPaginationInfo }: SessionPaginationFormProps) {
	const { loading, data } = useSessionPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.sessionConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
