import { IndexForms } from "@hex-insights/app-modules";
import { TuitionFilterFormValues } from "../../../../Utilities";

export type TuitionSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the Tuition model.
 */
export function TuitionSearchForm({ formState }: TuitionSearchFormProps) {
	return <IndexForms.SearchForm formState={formState} />;
}

export type TuitionFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, TuitionFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Tuition model.
 */
export function TuitionFilterFormController(props: TuitionFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={TuitionFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define QuickFilters component and add to `TuitionFilterFormController`
