import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { AcademicYearTuition } from "../../../../Utilities";

type ModelForLink = Pick<AcademicYearTuition, "id">;

export type AcademicYearTuitionLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given AcademicYearTuition instance.
 */
export function AcademicYearTuitionLink(props: AcademicYearTuitionLinkProps) {
	return <Models.Link pageInfoKey="AcademicYearTuition" format={format} {...props} />;
}

function format(instance: ModelForLink) {
	return instance.id;
}
