import { newPageInfo } from "@hex-insights/router";
import { busHubPageInfo } from "../pageinfo";

export const busHubCreatePageInfo = newPageInfo({
	name: "New Bus",
	title: "New",
	description: "Create a new Bus record.",
	exact: true,
	to: busHubPageInfo.to + "/new",
	path: busHubPageInfo.path + "/new",
	parent: busHubPageInfo,
});
