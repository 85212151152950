import { newPageInfo } from "@hex-insights/router";
import { learningObjectiveHubPageInfo } from "../pageinfo";

export const learningObjectiveHubCreatePageInfo = newPageInfo({
	name: "New Learning Objective",
	title: "New",
	description: "Create a new Learning Objective record.",
	exact: true,
	to: learningObjectiveHubPageInfo.to + "/new",
	path: learningObjectiveHubPageInfo.path + "/new",
	parent: learningObjectiveHubPageInfo,
});
