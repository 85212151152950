import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { StudentDocumentFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the StudentDocument model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => StudentDocumentFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(
		initialFormValues,
		StudentDocumentFilterFormValues.typeFilterObject,
	);
}
