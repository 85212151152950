import { newPageInfo } from "@hex-insights/router";
import { enrollmentApplicationSubmissionHubPageInfo } from "../pageinfo";

export const enrollmentApplicationSubmissionHubIndexPageInfo = newPageInfo({
	name: "Enrollment Application Submissions Index",
	title: "",
	description: "Index of Enrollment Application Submissions.",
	exact: true,
	to: enrollmentApplicationSubmissionHubPageInfo.to,
	path: enrollmentApplicationSubmissionHubPageInfo.path,
	parent: enrollmentApplicationSubmissionHubPageInfo,
});
