import { newPageInfo, newRouteParamsType } from "@hex-insights/router";
import { EnrollmentApplicationStudent } from "@hex-insights/verita.shared";
import { enrollmentApplicationStudentHubPageInfo } from "../pageinfo";

type EnrollmentApplicationStudentForRouteTitle = Pick<EnrollmentApplicationStudent, "name">;

export const enrollmentApplicationStudentHubDetailPageInfo = newPageInfo({
	name: "Enrollment Application Student Detail",
	title: (e: EnrollmentApplicationStudentForRouteTitle) => e.name,
	description: "Detail page for an enrollmentApplicationStudent.",
	exact: false,
	isLeaf: true,
	parent: enrollmentApplicationStudentHubPageInfo,
	to: (id: EnrollmentApplicationStudent["id"]) => enrollmentApplicationStudentHubPageInfo.to + `/${id}`,
	path: enrollmentApplicationStudentHubPageInfo.path + "/:enrollmentApplicationStudentID(\\d+)",
	paramsType: newRouteParamsType(enrollmentApplicationStudentHubPageInfo.paramsType, "enrollmentApplicationStudentID"),
});
