import React from "react";
import {
	CreateFunction,
	onCreateSubmit,
	onUpdateSubmit,
	UpdateFunction,
	useApplyCreate,
	useApplyUpdate,
} from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import {
	Form,
	FormEditButtonPair,
	FormState,
	FormSubmitFunction,
	SubmitButton,
	useOnSuccess,
} from "@hex-insights/forms";
import {
	AcademicYearTuitionCreateMutation,
	AcademicYearTuitionDetailQuery,
	AcademicYearTuitionFormConversion,
	AcademicYearTuitionFormState,
	AcademicYearTuitionFormValues,
	AcademicYearTuitionMutation,
	AcademicYearTuitionUpdateMutation,
} from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledCreateProps = Pick<
	CreateProps<AcademicYearTuitionCreateMutation["createAcademicYearTuition"]>,
	"onSuccess" | "formNameSuffix"
> & {
	formStateArgs?: Partial<AcademicYearTuitionFormState.UseCreateFormStateArgs>;
};

/**
 * Renders the create form of the AcademicYearTuition model using an internally managed form state.
 */
export function ControlledCreate({ formStateArgs, ...props }: ControlledCreateProps) {
	const formState = AcademicYearTuitionFormState.useCreateFormState(formStateArgs);
	const create = AcademicYearTuitionMutation.useCreate();

	return <Create formState={formState} create={create} {...props} />;
}

export type CreateProps<T> = {
	formState: FormState<AcademicYearTuitionFormValues.Create>;
	create: CreateFunction<AcademicYearTuitionFormValues.Create, T>;
	onSuccess: (data: T) => void;
	formNameSuffix?: string;
};

/**
 * Renders the create form of the AcademicYearTuition model using the given form state.
 */
export function Create<T>({ formState, create, onSuccess, formNameSuffix }: CreateProps<T>) {
	const { applyCreate, resultRef } = useApplyCreate(create);
	const onSubmit = React.useCallback<FormSubmitFunction<AcademicYearTuitionFormValues.Create>>(
		async ({ formValues }) => onCreateSubmit(formValues, applyCreate),
		[applyCreate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	const formName = "academicYearTuition.create" + (formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form name={formName} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Form.Column>
				<CreateFields formState={formState} />

				<Form.ActionBar>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Form.ActionBar>
			</Form.Column>
		</Form>
	);
}

type CreateFieldsProps = {
	formState: FormState<AcademicYearTuitionFormValues.Create>;
};

function CreateFields({ formState }: CreateFieldsProps) {
	return (
		<React.Fragment>
			<Field.Tuition formState={formState} />
			<Field.Year formState={formState} />
			<Field.NurseryPrice formState={formState} />
			<Field.ReceptionPrice formState={formState} />
			<Field.PrimaryPrice formState={formState} />
			<Field.SecondaryPrice formState={formState} />
		</React.Fragment>
	);
}

export type ControlledDetailProps = Pick<
	DetailProps<AcademicYearTuitionUpdateMutation["updateAcademicYearTuition"]>,
	"academicYearTuition" | "onSuccess" | "formNameSuffix"
> & {
	formStateArgs?: Partial<DistributiveOmit<AcademicYearTuitionFormState.UseDetailFormStateArgs, "initialFormValues">>;
};

/**
 * Renders the detail form of the AcademicYearTuition model using an internally managed form state.
 */
export function ControlledDetail({ formStateArgs, ...props }: ControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => AcademicYearTuitionFormConversion.toFormValues(props.academicYearTuition),
		[props.academicYearTuition],
	);
	const formState = AcademicYearTuitionFormState.useDetailFormState({ ...formStateArgs, initialFormValues });
	const update = AcademicYearTuitionMutation.useUpdate(props.academicYearTuition.id);

	return <Detail formState={formState} update={update} {...props} />;
}

export type DetailProps<T> = {
	academicYearTuition: AcademicYearTuitionDetailQuery["academicYearTuition"];
	formState: FormState<AcademicYearTuitionFormValues.Detail>;
	update: UpdateFunction<AcademicYearTuitionFormValues.Detail, T>;
	onSuccess: (data: T) => void;
	formNameSuffix?: string;
};

/**
 * Renders the detail form of the AcademicYearTuition model using the given form state.
 */
export function Detail<T>({ formState, academicYearTuition, update, onSuccess, formNameSuffix }: DetailProps<T>) {
	const { applyUpdate, resultRef } = useApplyUpdate(update);
	const onSubmit = React.useCallback<FormSubmitFunction<AcademicYearTuitionFormValues.Detail>>(
		async (formState) => onUpdateSubmit(formState, applyUpdate),
		[applyUpdate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	const formName =
		`academicYearTuition.detail.${academicYearTuition.id}` + (formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form name={formName} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Form.Column>
				<FormEditButtonPair formState={formState} />

				<DetailFields formState={formState} academicYearTuition={academicYearTuition} />
			</Form.Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = Pick<ReadOnlyProps, "academicYearTuition"> & {
	formStateArgs?: Partial<DistributiveOmit<AcademicYearTuitionFormState.UseReadOnlyFormStateArgs, "initialFormValues">>;
};

/**
 * Renders a read-only detail form of the AcademicYearTuition model using an internally managed form state.
 */
export function ControlledReadOnly({ formStateArgs, ...props }: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => AcademicYearTuitionFormConversion.toFormValues(props.academicYearTuition),
		[props.academicYearTuition],
	);
	const formState = AcademicYearTuitionFormState.useReadOnlyFormState({ ...formStateArgs, initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = {
	academicYearTuition: AcademicYearTuitionDetailQuery["academicYearTuition"];
	formState: FormState<AcademicYearTuitionFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the AcademicYearTuition model using the given form state.
 */
export function ReadOnly({ formState, academicYearTuition }: ReadOnlyProps) {
	return (
		<Form.Column>
			<DetailFields formState={formState} academicYearTuition={academicYearTuition} />
		</Form.Column>
	);
}

type DetailFieldsProps = {
	academicYearTuition: AcademicYearTuitionDetailQuery["academicYearTuition"];
	formState: FormState<AcademicYearTuitionFormValues.Detail>;
};

function DetailFields({ formState, academicYearTuition }: DetailFieldsProps) {
	return (
		<React.Fragment>
			<Field.Tuition formState={formState} currentTuition={academicYearTuition.tuition} />
			<Field.Year formState={formState} />
			<Field.NurseryPrice formState={formState} />
			<Field.ReceptionPrice formState={formState} />
			<Field.PrimaryPrice formState={formState} />
			<Field.SecondaryPrice formState={formState} />
		</React.Fragment>
	);
}
