import { ClassNameProps, makeClassName, StyleProps } from "@hex-insights/core";
import { HR } from "@hex-insights/verita.shared";
import styles from "./styles.module.css";

export type SectionDividerProps = Partial<ClassNameProps & StyleProps>;

export function SectionDivider({ className, style }: SectionDividerProps) {
	return (
		<HR color="#0063ad40" size="2px" className={makeClassName(styles["section-divider"], className)} style={style} />
	);
}
