import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { ExpenseOrderFormValues } from "../../../../Utilities";

export type ExpenseOrderFormControllerProps = {
	formState: FormState<ExpenseOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the Expense model.
 */
export function ExpenseOrderFormController({ formState }: ExpenseOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={ExpenseOrderFormValues.fieldOptions}
			fieldTypeMapping={ExpenseOrderFormValues.fieldTypeMapping}
		/>
	);
}
