import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import { AcademicYearForm, AcademicYearFormat, useAcademicYearDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { academicYearHubDetailPageInfo } from "./pageinfo";

export function AcademicYearHubDetailPage() {
	const { academicYearID } = academicYearHubDetailPageInfo.useParams();

	const { loading, data, error } = useAcademicYearDetailQuery({ variables: { id: academicYearID } });
	const academicYear = data?.academicYear;
	useActivePageRegistration(
		academicYearHubDetailPageInfo,
		!!academicYear ? academicYearHubDetailPageInfo.title(academicYear) : "",
	);

	const { syncID, synchronize } = useSync();

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading academic year</If>
				<Else>
					{!!academicYear && (
						<React.Fragment>
							<Heading.H1>{AcademicYearFormat.name(academicYear)}</Heading.H1>

							<AcademicYearForm.ControlledDetail key={syncID} academicYear={academicYear} onSuccess={synchronize} />
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
