import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Permission, PermissionFormat, PermissionNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Permission, "id"> & PermissionNameFragment;

export type PermissionLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Permission instance.
 */
export function PermissionLink(props: PermissionLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Permission" format={PermissionFormat.name} {...props} />;
}
