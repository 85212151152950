import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { MealPlanFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the MealPlan model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => MealPlanFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(initialFormValues, MealPlanFilterFormValues.typeFilterObject);
}
