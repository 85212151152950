import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { ExpenseCategoryOrderFormValues } from "../../../../Utilities";

export type ExpenseCategoryOrderFormControllerProps = {
	formState: FormState<ExpenseCategoryOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the ExpenseCategory model.
 */
export function ExpenseCategoryOrderFormController({ formState }: ExpenseCategoryOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={ExpenseCategoryOrderFormValues.fieldOptions}
			fieldTypeMapping={ExpenseCategoryOrderFormValues.fieldTypeMapping}
		/>
	);
}
