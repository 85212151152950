import { NullPartial } from "@hex-insights/core";
import { ExpenseBudgetCreateInput, ExpenseBudgetDetailQuery, ExpenseBudgetUpdateInput } from "../../GraphQL";
import { ExpenseBudgetFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the ExpenseBudget model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: ExpenseBudgetFormValues.Create): ExpenseBudgetCreateInput {
	const input: NullPartial<ExpenseBudgetCreateInput> = { ...formValues };
	return input as ExpenseBudgetCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the ExpenseBudget model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<ExpenseBudgetFormValues.Detail>,
	_initialFormValues: Partial<ExpenseBudgetFormValues.Detail>,
): ExpenseBudgetUpdateInput {
	const input: ExpenseBudgetUpdateInput = { ...formValues };
	return input;
}

/**
 * Converts a ExpenseBudget instance to its corresponding detail form values.
 *
 * @param expenseBudget The instance to convert.
 */
export function toFormValues(expenseBudget: ExpenseBudgetDetailQuery["expenseBudget"]): ExpenseBudgetFormValues.Detail {
	return {
		budgetMonth: expenseBudget.budgetMonth,
		amount: expenseBudget.amount,
		expenseCategoryID: expenseBudget.expenseCategory?.id ?? null,
	};
}
