import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { EnrollmentApplicationOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the EnrollmentApplication model.
 */
export function useFormState(initialFormValues?: EnrollmentApplicationOrderFormValues.FormValues) {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		React.useMemo(
			() => ({ ...EnrollmentApplicationOrderFormValues.initial, ...initialFormValues }),
			[initialFormValues],
		),
		EnrollmentApplicationOrderFormValues.fieldValues,
	);
}
