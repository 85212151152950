import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { LearningObjectiveMarkOrderFormValues } from "../../../../Utilities";

export type LearningObjectiveMarkOrderFormControllerProps = {
	formState: FormState<LearningObjectiveMarkOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the LearningObjectiveMark model.
 */
export function LearningObjectiveMarkOrderFormController({ formState }: LearningObjectiveMarkOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={LearningObjectiveMarkOrderFormValues.fieldOptions}
			fieldTypeMapping={LearningObjectiveMarkOrderFormValues.fieldTypeMapping}
		/>
	);
}
