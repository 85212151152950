import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const courseHubPageInfo = newPageInfo({
	title: "Courses",
	description: "Pages related to the Course model.",
	exact: false,
	to: hubsPageInfo.to + "/courses",
	path: hubsPageInfo.path + "/courses",
	parent: hubsPageInfo,
});
