import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { HomeRoomSectionCreateInput, HomeRoomSectionDetailQuery, HomeRoomSectionUpdateInput } from "../../GraphQL";
import { HomeRoomSectionFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the HomeRoomSection model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: HomeRoomSectionFormValues.Create): HomeRoomSectionCreateInput {
	const input: NullPartial<HomeRoomSectionCreateInput> = { ...formValues };
	return input as HomeRoomSectionCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the HomeRoomSection model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<HomeRoomSectionFormValues.Detail>,
	initialFormValues: HomeRoomSectionFormValues.Detail,
): HomeRoomSectionUpdateInput {
	const inputFormValues = omitKeys(formValues, ["courseSectionIDs"]);
	const input: HomeRoomSectionUpdateInput = { ...inputFormValues };

	if (formValues.courseSectionIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.courseSectionIDs, formValues.courseSectionIDs);
		input.addCourseSectionIDs = added;
		input.removeCourseSectionIDs = removed;
	}

	return input;
}

/**
 * Converts a HomeRoomSection instance to its corresponding detail form values.
 *
 * @param homeRoomSection The instance to convert.
 */
export function toFormValues(
	homeRoomSection: HomeRoomSectionDetailQuery["homeRoomSection"],
): HomeRoomSectionFormValues.Detail {
	return {
		name: homeRoomSection.name,
		description: homeRoomSection.description,
		courseSectionIDs: homeRoomSection.courseSections.map((e) => e.id),
		homeRoomID: homeRoomSection.homeRoom?.id ?? null,
		roomID: homeRoomSection.room?.id ?? null,
		termID: homeRoomSection.term?.id ?? null,
	};
}
