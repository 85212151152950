import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const noteAttachmentHubPageInfo = newPageInfo({
	title: "Note Attachments",
	description: "Pages related to the Note Attachment model.",
	exact: false,
	to: hubsPageInfo.to + "/note-attachments",
	path: hubsPageInfo.path + "/note-attachments",
	parent: hubsPageInfo,
});
