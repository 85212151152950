import { IndexForms } from "@hex-insights/app-modules";
import { AcademicYearTuitionOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the AcademicYearTuition model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		AcademicYearTuitionOrderFormValues.initial,
		AcademicYearTuitionOrderFormValues.fieldValues,
	);
}
