import { CourseSection, HomeRoom, HomeRoomSection, Room, Term } from "../../GraphQL";

export type Base = {
	name: HomeRoomSection["name"];
	description: HomeRoomSection["description"];
	homeRoomID: HomeRoom["id"] | null;
	roomID: Room["id"] | null;
	termID: Term["id"] | null;
};

export type Create = Base;

export type Detail = Base & {
	courseSectionIDs: CourseSection["id"][];
};

export const initialCreate: Create = {
	name: "",
	description: "",
	homeRoomID: null,
	roomID: null,
	termID: null,
};
