import { Campus, Employee, Room } from "../../GraphQL";

export type Base = {
	name: Campus["name"];
	addressLine1: Campus["addressLine1"];
	addressLine2: Campus["addressLine2"];
	postalCode: Campus["postalCode"];
	city: Campus["city"];
	stateOrProvince: Campus["stateOrProvince"];
	country: Campus["country"];
};

export type Create = Base;

export type Detail = Base & {
	employeeIDs: Employee["id"][];
	roomIDs: Room["id"][];
};

export const initialCreate: Create = {
	name: "",
	addressLine1: "",
	addressLine2: "",
	postalCode: "",
	city: "",
	stateOrProvince: "",
	country: "",
};
