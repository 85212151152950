import React from "react";
import { FieldDisplayArgs, MultiSelectField, TextField } from "@hex-insights/forms";
import {
	CampusFormValues,
	EmployeeSelect,
	RoomSelect,
	useEmployeeSelectLazyQuery,
	useRoomSelectLazyQuery,
} from "../../../../Utilities";
import { EmployeeLink, RoomLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Campus model.
 */
type FieldProps<K extends keyof CampusFormValues.Base = keyof CampusFormValues.Base> = BaseFieldProps<
	Pick<CampusFormValues.Base, K>
>;

/**
 * Generic props for fields of the Campus model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof CampusFormValues.Detail = keyof CampusFormValues.Detail> = BaseFieldProps<
	Pick<CampusFormValues.Detail, K>
>;

/**
 * Renders a field component for the `name` field of the Campus model.
 */
export function Name({ formState }: FieldProps<"name">) {
	return <TextField formState={formState} name="name" />;
}

/**
 * Renders a field component for the `addressLine1` field of the Campus model.
 */
export function AddressLine1({ formState }: FieldProps<"addressLine1">) {
	return <TextField formState={formState} name="addressLine1" />;
}

/**
 * Renders a field component for the `addressLine2` field of the Campus model.
 */
export function AddressLine2({ formState }: FieldProps<"addressLine2">) {
	return <TextField formState={formState} name="addressLine2" optional />;
}

/**
 * Renders a field component for the `postalCode` field of the Campus model.
 */
export function PostalCode({ formState }: FieldProps<"postalCode">) {
	return <TextField formState={formState} name="postalCode" />;
}

/**
 * Renders a field component for the `city` field of the Campus model.
 */
export function City({ formState }: FieldProps<"city">) {
	return <TextField formState={formState} name="city" />;
}

/**
 * Renders a field component for the `stateOrProvince` field of the Campus model.
 */
export function StateOrProvince({ formState }: FieldProps<"stateOrProvince">) {
	return <TextField formState={formState} name="stateOrProvince" optional />;
}

/**
 * Renders a field component for the `country` field of the Campus model.
 */
export function Country({ formState }: FieldProps<"country">) {
	return <TextField formState={formState} name="country" />;
}

export type EmployeesProps = DetailFieldProps<"employeeIDs"> & {
	currentEmployees?: EmployeeSelect.ModelForOption[];
};

/**
 * Renders a field component for the `employees` edge of the Campus model.
 */
export function Employees({ formState, currentEmployees }: EmployeesProps) {
	const [loadOptions, { loading, data }] = useEmployeeSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.employeeIDs) {
			loadOptions();
		}
	}, [formState.formEditing.employeeIDs, loadOptions]);
	const options = React.useMemo(
		() => EmployeeSelect.toMultiOptions(data?.employeeConnection.edges, currentEmployees),
		[data, currentEmployees],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="employeeIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayEmployeeInstance}
		/>
	);
}

function displayEmployeeInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <EmployeeLink instance={{ id }}>{formattedValue}</EmployeeLink>;
}

export type RoomsProps = DetailFieldProps<"roomIDs"> & {
	currentRooms?: RoomSelect.ModelForOption[];
};

/**
 * Renders a field component for the `rooms` edge of the Campus model.
 */
export function Rooms({ formState, currentRooms }: RoomsProps) {
	const [loadOptions, { loading, data }] = useRoomSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.roomIDs) {
			loadOptions();
		}
	}, [formState.formEditing.roomIDs, loadOptions]);
	const options = React.useMemo(
		() => RoomSelect.toMultiOptions(data?.roomConnection.edges, currentRooms),
		[data, currentRooms],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="roomIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayRoomInstance}
		/>
	);
}

function displayRoomInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <RoomLink instance={{ id }}>{formattedValue}</RoomLink>;
}
