import { newPageInfo } from "@hex-insights/router";
import { courseAttendanceRecordHubPageInfo } from "../pageinfo";

export const courseAttendanceRecordHubCreatePageInfo = newPageInfo({
	name: "New Course Attendance Record",
	title: "New",
	description: "Create a new Course Attendance Record record.",
	exact: true,
	to: courseAttendanceRecordHubPageInfo.to + "/new",
	path: courseAttendanceRecordHubPageInfo.path + "/new",
	parent: courseAttendanceRecordHubPageInfo,
});
