import { Expense, ExpenseBudget, ExpenseCategory } from "../../GraphQL";

export type Base = {
	name: ExpenseCategory["name"];
	description: ExpenseCategory["description"];
};

export type Create = Base;

export type Detail = Base & {
	expenseBudgetIDs: ExpenseBudget["id"][];
	expenseIDs: Expense["id"][];
};

export const initialCreate: Create = {
	name: "",
	description: "",
};
