import { newPageInfo } from "@hex-insights/router";
import { enrollmentInvitationHubPageInfo } from "../pageinfo";

export const enrollmentInvitationHubCreatePageInfo = newPageInfo({
	name: "New Enrollment Invitation",
	title: "New",
	description: "Create a new Enrollment Invitation record.",
	exact: true,
	to: enrollmentInvitationHubPageInfo.to + "/new",
	path: enrollmentInvitationHubPageInfo.path + "/new",
	parent: enrollmentInvitationHubPageInfo,
});
