import {
	EnrollmentApplicationParent,
	EnrollmentApplicationParentNameFragment,
	EnrollmentApplicationParentRelationshipType,
} from "../../../GraphQL";
import * as Fields from "./fields";

export { Fields };

/**
 * Formats the full name of the given instance.
 */
export function name(instance: EnrollmentApplicationParentNameFragment) {
	return instance.name;
}

export function nameComputed({ firstName, lastName }: Pick<EnrollmentApplicationParent, "firstName" | "lastName">) {
	return `${firstName} ${lastName}`;
}

export function relationshipType({
	relationshipType,
	relationshipTypeCustom,
}: Pick<EnrollmentApplicationParent, "relationshipType" | "relationshipTypeCustom">) {
	if (relationshipType === EnrollmentApplicationParentRelationshipType.Other) {
		return relationshipTypeCustom || "Other";
	}
	return Fields.relationshipType(relationshipType);
}

export function address({
	addressLine1,
	addressLine2,
	city,
	stateOrProvince,
	postalCode,
	country,
}: Pick<
	EnrollmentApplicationParent,
	"addressLine1" | "addressLine2" | "city" | "stateOrProvince" | "postalCode" | "country"
>) {
	let address = addressLine1;
	if (addressLine2 !== "") {
		address += " " + addressLine2;
	}
	if (city !== "") {
		address += ", " + city;
	}
	if (stateOrProvince !== "") {
		address += ", " + stateOrProvince;
	}
	if (postalCode !== "") {
		address += " " + postalCode;
	}
	if (country !== "") {
		address += ", " + country;
	}
	return address;
}
