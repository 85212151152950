import React from "react";
import { BooleanField, FieldDisplayArgs, MultiSelectField, SelectField, TextField } from "@hex-insights/forms";
import {
	CourseSelect,
	LearningObjectiveFormValues,
	LearningObjectiveMarkSelect,
	useCourseSelectLazyQuery,
	useLearningObjectiveMarkSelectLazyQuery,
} from "../../../../Utilities";
import { CourseLink, LearningObjectiveMarkLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the LearningObjective model.
 */
type FieldProps<K extends keyof LearningObjectiveFormValues.Base = keyof LearningObjectiveFormValues.Base> =
	BaseFieldProps<Pick<LearningObjectiveFormValues.Base, K>>;

/**
 * Renders a field component for the `category` field of the LearningObjective model.
 */
export function Category({ formState }: FieldProps<"category">) {
	return <TextField formState={formState} name="category" />;
}

/**
 * Renders a field component for the `description` field of the LearningObjective model.
 */
export function Description({ formState }: FieldProps<"description">) {
	return <TextField formState={formState} name="description" />;
}

/**
 * Renders a field component for the `isActive` field of the LearningObjective model.
 */
export function IsActive({ formState }: FieldProps<"isActive">) {
	return <BooleanField formState={formState} name="isActive" />;
}

export type CourseProps = FieldProps<"courseID"> & {
	currentCourse?: CourseSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `course` edge of the LearningObjective model.
 */
export function Course({ formState, currentCourse }: CourseProps) {
	const [loadOptions, { loading, data }] = useCourseSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.courseID) {
			loadOptions();
		}
	}, [formState.formEditing.courseID, loadOptions]);
	const options = React.useMemo(
		() => CourseSelect.toOptions(data?.courseConnection.edges, currentCourse),
		[data, currentCourse],
	);

	return (
		<SelectField
			formState={formState}
			name="courseID"
			isLoading={loading}
			options={options}
			display={displayCourse}
			blankValue={null}
		/>
	);
}

function displayCourse({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <CourseLink instance={{ id }}>{formattedValue}</CourseLink>;
}

export type LearningObjectiveMarksProps = FieldProps<"learningObjectiveMarkIDs"> & {
	currentLearningObjectiveMarks?: LearningObjectiveMarkSelect.ModelForOption[];
};

/**
 * Renders a field component for the `learningObjectiveMarks` edge of the LearningObjective model.
 */
export function LearningObjectiveMarks({ formState, currentLearningObjectiveMarks }: LearningObjectiveMarksProps) {
	const [loadOptions, { loading, data }] = useLearningObjectiveMarkSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.learningObjectiveMarkIDs) {
			loadOptions();
		}
	}, [formState.formEditing.learningObjectiveMarkIDs, loadOptions]);
	const options = React.useMemo(
		() =>
			LearningObjectiveMarkSelect.toMultiOptions(
				data?.learningObjectiveMarkConnection.edges,
				currentLearningObjectiveMarks,
			),
		[data, currentLearningObjectiveMarks],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="learningObjectiveMarkIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayLearningObjectiveMarkInstance}
		/>
	);
}

function displayLearningObjectiveMarkInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <LearningObjectiveMarkLink instance={{ id }}>{formattedValue}</LearningObjectiveMarkLink>;
}
