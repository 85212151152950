import { IndexForms } from "@hex-insights/app-modules";
import { ExpenseBudgetFilterFormValues } from "../../../../Utilities";

export type ExpenseBudgetFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, ExpenseBudgetFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the ExpenseBudget model.
 */
export function ExpenseBudgetFilterFormController(props: ExpenseBudgetFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={ExpenseBudgetFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `ExpenseBudgetFilterForm`
