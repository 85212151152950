import { newPageInfo } from "@hex-insights/router";
import { learningObjectiveMarkHubPageInfo } from "../pageinfo";

export const learningObjectiveMarkHubCreatePageInfo = newPageInfo({
	name: "New Learning Objective Mark",
	title: "New",
	description: "Create a new Learning Objective Mark record.",
	exact: true,
	to: learningObjectiveMarkHubPageInfo.to + "/new",
	path: learningObjectiveMarkHubPageInfo.path + "/new",
	parent: learningObjectiveMarkHubPageInfo,
});
