import React from "react";
import {
	DateTimeField,
	FieldDisplayArgs,
	FormType,
	MultiSelectField,
	RadioButtonsInput,
	RadioField,
	SelectField,
	TextField,
} from "@hex-insights/forms";
import {
	CourseSectionSelect,
	CourseSelect,
	DocumentResourceFolderFormValues,
	DocumentResourceFolderSelect,
	DocumentResourceSelect,
	HomeRoomSectionSelect,
	HomeRoomSelect,
	useCourseSectionSelectLazyQuery,
	useCourseSelectLazyQuery,
	useDocumentResourceFolderSelectLazyQuery,
	useDocumentResourceSelectLazyQuery,
	useHomeRoomSectionSelectLazyQuery,
	useHomeRoomSelectLazyQuery,
	UserGroupSelect,
	UserSelect,
	useUserGroupSelectLazyQuery,
	useUserSelectLazyQuery,
} from "../../../../Utilities";
import {
	CourseLink,
	CourseSectionLink,
	DocumentResourceFolderLink,
	DocumentResourceLink,
	HomeRoomLink,
	HomeRoomSectionLink,
	UserGroupLink,
	UserLink,
} from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the DocumentResourceFolder model.
 */
type FieldProps<K extends keyof DocumentResourceFolderFormValues.Base = keyof DocumentResourceFolderFormValues.Base> =
	BaseFieldProps<Pick<DocumentResourceFolderFormValues.Base, K>>;

/**
 * Generic props for fields of the DocumentResourceFolder model that only appear in the detail form.
 */
type DetailFieldProps<
	K extends keyof DocumentResourceFolderFormValues.Detail = keyof DocumentResourceFolderFormValues.Detail,
> = BaseFieldProps<Pick<DocumentResourceFolderFormValues.Detail, K>>;

/**
 * Renders a field component for the `name` field of the DocumentResourceFolder model.
 */
export function Name({ formState }: FieldProps<"name">) {
	return <TextField formState={formState} name="name" />;
}

/**
 * Renders a field component for the `description` field of the DocumentResourceFolder model.
 */
export function Description({ formState }: FieldProps<"description">) {
	return <TextAreaField formState={formState} name="description" optional />;
}

/**
 * Renders a field component for the `category` field of the DocumentResourceFolder model.
 */
export function Category({ formState, formType = FormType.Update }: FieldProps<"category">) {
	return (
		<RadioField
			formState={formState}
			name="category"
			options={DocumentResourceFolderFormValues.categoryOptions}
			blankValue={null}
			optional={FormType.isCreate(formType)}
		/>
	);
}

/**
 * Renders a field component for the `isPublic` field of the DocumentResourceFolder model.
 */
export function IsPublic({ formState }: FieldProps<"isPublic" | "parentDocumentResourceFolderID">) {
	const hasParentFolder = formState.formValues.parentDocumentResourceFolderID !== null;
	return (
		<RadioField
			formState={formState}
			name="isPublic"
			label="Sharing"
			options={DocumentResourceFolderFormValues.isPublicOptions}
			noClear
			Input={RadioButtonsInput}
			hint={`Public folders can be viewed by anyone${
				hasParentFolder ? " who can view the parent folder" : ""
			}. Private folders can only be viewed by the creator and those who have been explicitly given access.`}
		/>
	);
}

/**
 * Renders a field component for the `createdAt` field of the DocumentResourceFolder model.
 */
export function CreatedAt({ formState, formType = FormType.Update }: DetailFieldProps<"createdAt">) {
	return (
		<DateTimeField formState={formState} name="createdAt" optional={FormType.isCreate(formType)} precision="minute" />
	);
}

export type AuthorProps = DetailFieldProps<"authorID"> & {
	currentAuthor?: UserSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `author` edge of the DocumentResourceFolder model.
 */
export function Author({ formState, currentAuthor }: AuthorProps) {
	const [loadOptions, { loading, data }] = useUserSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.authorID) {
			loadOptions();
		}
	}, [formState.formEditing.authorID, loadOptions]);
	const options = React.useMemo(
		() => UserSelect.toOptions(data?.userConnection.edges, currentAuthor),
		[data, currentAuthor],
	);

	return (
		<SelectField
			formState={formState}
			name="authorID"
			isLoading={loading}
			options={options}
			optional
			display={displayAuthor}
			blankValue={null}
		/>
	);
}

function displayAuthor({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserLink instance={{ id }}>{formattedValue}</UserLink>;
}

export type ChildDocumentResourceFoldersProps = DetailFieldProps<"childDocumentResourceFolderIDs"> & {
	currentChildDocumentResourceFolders?: DocumentResourceFolderSelect.ModelForOption[];
};

/**
 * Renders a field component for the `childDocumentResourceFolders` edge of the DocumentResourceFolder model.
 */
export function ChildDocumentResourceFolders({
	formState,
	currentChildDocumentResourceFolders,
}: ChildDocumentResourceFoldersProps) {
	const [loadOptions, { loading, data }] = useDocumentResourceFolderSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.childDocumentResourceFolderIDs) {
			loadOptions();
		}
	}, [formState.formEditing.childDocumentResourceFolderIDs, loadOptions]);
	const options = React.useMemo(
		() =>
			DocumentResourceFolderSelect.toMultiOptions(
				data?.documentResourceFolderConnection.edges,
				currentChildDocumentResourceFolders,
			),
		[data, currentChildDocumentResourceFolders],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="childDocumentResourceFolderIDs"
			label="Subfolders"
			isLoading={loading}
			options={options}
			displayInstance={displayChildDocumentResourceFolderInstance}
		/>
	);
}

function displayChildDocumentResourceFolderInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <DocumentResourceFolderLink instance={{ id }}>{formattedValue}</DocumentResourceFolderLink>;
}

export type CourseSectionsProps = FieldProps<"courseSectionIDs"> & {
	currentCourseSections?: CourseSectionSelect.ModelForOption[];
};

/**
 * Renders a field component for the `courseSections` edge of the DocumentResourceFolder model.
 */
export function CourseSections({ formState, currentCourseSections }: CourseSectionsProps) {
	const [loadOptions, { loading, data }] = useCourseSectionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.courseSectionIDs) {
			loadOptions();
		}
	}, [formState.formEditing.courseSectionIDs, loadOptions]);
	const options = React.useMemo(
		() => CourseSectionSelect.toMultiOptions(data?.courseSectionConnection.edges, currentCourseSections),
		[data, currentCourseSections],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="courseSectionIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayCourseSectionInstance}
		/>
	);
}

function displayCourseSectionInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <CourseSectionLink instance={{ id }}>{formattedValue}</CourseSectionLink>;
}

export type CoursesProps = FieldProps<"courseIDs"> & {
	currentCourses?: CourseSelect.ModelForOption[];
};

/**
 * Renders a field component for the `courses` edge of the DocumentResourceFolder model.
 */
export function Courses({ formState, currentCourses }: CoursesProps) {
	const [loadOptions, { loading, data }] = useCourseSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.courseIDs) {
			loadOptions();
		}
	}, [formState.formEditing.courseIDs, loadOptions]);
	const options = React.useMemo(
		() => CourseSelect.toMultiOptions(data?.courseConnection.edges, currentCourses),
		[data, currentCourses],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="courseIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayCourseInstance}
		/>
	);
}

function displayCourseInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <CourseLink instance={{ id }}>{formattedValue}</CourseLink>;
}

export type DocumentResourcesProps = DetailFieldProps<"documentResourceIDs"> & {
	currentDocumentResources?: DocumentResourceSelect.ModelForOption[];
};

/**
 * Renders a field component for the `documentResources` edge of the DocumentResourceFolder model.
 */
export function DocumentResources({ formState, currentDocumentResources }: DocumentResourcesProps) {
	const [loadOptions, { loading, data }] = useDocumentResourceSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.documentResourceIDs) {
			loadOptions();
		}
	}, [formState.formEditing.documentResourceIDs, loadOptions]);
	const options = React.useMemo(
		() => DocumentResourceSelect.toMultiOptions(data?.documentResourceConnection.edges, currentDocumentResources),
		[data, currentDocumentResources],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="documentResourceIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayDocumentResourceInstance}
		/>
	);
}

function displayDocumentResourceInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <DocumentResourceLink instance={{ id }}>{formattedValue}</DocumentResourceLink>;
}

export type HomeRoomSectionsProps = FieldProps<"homeRoomSectionIDs"> & {
	currentHomeRoomSections?: HomeRoomSectionSelect.ModelForOption[];
};

/**
 * Renders a field component for the `homeRoomSections` edge of the DocumentResourceFolder model.
 */
export function HomeRoomSections({ formState, currentHomeRoomSections }: HomeRoomSectionsProps) {
	const [loadOptions, { loading, data }] = useHomeRoomSectionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.homeRoomSectionIDs) {
			loadOptions();
		}
	}, [formState.formEditing.homeRoomSectionIDs, loadOptions]);
	const options = React.useMemo(
		() => HomeRoomSectionSelect.toMultiOptions(data?.homeRoomSectionConnection.edges, currentHomeRoomSections),
		[data, currentHomeRoomSections],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="homeRoomSectionIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayHomeRoomSectionInstance}
		/>
	);
}

function displayHomeRoomSectionInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <HomeRoomSectionLink instance={{ id }}>{formattedValue}</HomeRoomSectionLink>;
}

export type HomeRoomsProps = FieldProps<"homeRoomIDs"> & {
	currentHomeRooms?: HomeRoomSelect.ModelForOption[];
};

/**
 * Renders a field component for the `homeRooms` edge of the DocumentResourceFolder model.
 */
export function HomeRooms({ formState, currentHomeRooms }: HomeRoomsProps) {
	const [loadOptions, { loading, data }] = useHomeRoomSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.homeRoomIDs) {
			loadOptions();
		}
	}, [formState.formEditing.homeRoomIDs, loadOptions]);
	const options = React.useMemo(
		() => HomeRoomSelect.toMultiOptions(data?.homeRoomConnection.edges, currentHomeRooms),
		[data, currentHomeRooms],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="homeRoomIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayHomeRoomInstance}
		/>
	);
}

function displayHomeRoomInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <HomeRoomLink instance={{ id }}>{formattedValue}</HomeRoomLink>;
}

export type ParentDocumentResourceFolderProps = FieldProps<"parentDocumentResourceFolderID"> & {
	currentParentDocumentResourceFolder?: DocumentResourceFolderSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `parentDocumentResourceFolder` edge of the DocumentResourceFolder model.
 */
export function ParentDocumentResourceFolder({
	formState,
	currentParentDocumentResourceFolder,
}: ParentDocumentResourceFolderProps) {
	const [loadOptions, { loading, data }] = useDocumentResourceFolderSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.parentDocumentResourceFolderID) {
			loadOptions();
		}
	}, [formState.formEditing.parentDocumentResourceFolderID, loadOptions]);
	const options = React.useMemo(
		() =>
			DocumentResourceFolderSelect.toOptions(
				data?.documentResourceFolderConnection.edges,
				currentParentDocumentResourceFolder,
			),
		[data, currentParentDocumentResourceFolder],
	);

	return (
		<SelectField
			formState={formState}
			name="parentDocumentResourceFolderID"
			label="Parent Folder"
			isLoading={loading}
			options={options}
			optional
			display={displayParentDocumentResourceFolder}
			blankValue={null}
		/>
	);
}

function displayParentDocumentResourceFolder({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <DocumentResourceFolderLink instance={{ id }}>{formattedValue}</DocumentResourceFolderLink>;
}

export type SharingUserGroupsProps = FieldProps<"sharingUserGroupIDs"> & {
	currentSharingUserGroups?: UserGroupSelect.ModelForOption[];
};

/**
 * Renders a field component for the `sharingUserGroups` edge of the DocumentResourceFolder model.
 */
export function SharingUserGroups({ formState, formType, currentSharingUserGroups }: SharingUserGroupsProps) {
	const [loadOptions, { loading, data }] = useUserGroupSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.sharingUserGroupIDs) {
			loadOptions();
		}
	}, [formState.formEditing.sharingUserGroupIDs, loadOptions]);
	const options = React.useMemo(
		() => UserGroupSelect.toMultiOptions(data?.userGroupConnection.edges, currentSharingUserGroups),
		[data, currentSharingUserGroups],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="sharingUserGroupIDs"
			label={formType === FormType.Create ? "Share with Groups" : "Shared with Groups"}
			isLoading={loading}
			options={options}
			displayInstance={displaySharingUserGroupInstance}
		/>
	);
}

function displaySharingUserGroupInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserGroupLink instance={{ id }}>{formattedValue}</UserGroupLink>;
}

export type SharingUsersProps = FieldProps<"sharingUserIDs"> & {
	currentSharingUsers?: UserSelect.ModelForOption[];
};

/**
 * Renders a field component for the `sharingUsers` edge of the DocumentResourceFolder model.
 */
export function SharingUsers({ formState, formType, currentSharingUsers }: SharingUsersProps) {
	const [loadOptions, { loading, data }] = useUserSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.sharingUserIDs) {
			loadOptions();
		}
	}, [formState.formEditing.sharingUserIDs, loadOptions]);
	const options = React.useMemo(
		() => UserSelect.toMultiOptions(data?.userConnection.edges, currentSharingUsers),
		[data, currentSharingUsers],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="sharingUserIDs"
			label={formType === FormType.Create ? "Share with Users" : "Shared with Users"}
			isLoading={loading}
			options={options}
			displayInstance={displaySharingUserInstance}
		/>
	);
}

function displaySharingUserInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserLink instance={{ id }}>{formattedValue}</UserLink>;
}
