import React from "react";
import { Conditional, Else, Heading, If } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { TrustedDeviceForm, useTrustedDeviceDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { trustedDeviceHubDetailPageInfo } from "./pageinfo";

function TrustedDeviceHubDetailPageWrapper() {
	// Manages identity of the TrustedDeviceHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <TrustedDeviceHubDetailPage key={id} />;
}

export { TrustedDeviceHubDetailPageWrapper as TrustedDeviceHubDetailPage };

function TrustedDeviceHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useTrustedDeviceDetailQuery({ variables: { id } });
	const trustedDevice = data?.trustedDevice;
	useActivePageRegistration(
		trustedDeviceHubDetailPageInfo,
		!!trustedDevice ? trustedDeviceHubDetailPageInfo.title(trustedDevice) : "",
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading trustedDevice</If>
				<Else>
					{!!trustedDevice && (
						<React.Fragment>
							<Heading level={1}>{trustedDevice.name}</Heading>

							<TrustedDeviceForm.ControlledReadOnly trustedDevice={trustedDevice} />
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
