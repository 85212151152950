import React from "react";
import { NewPaymentModal } from "packages/portal/src/Components";
import {
	ButtonWithConfirmation,
	Column,
	Conditional,
	Drawer,
	DrawerProps,
	Else,
	Heading,
	If,
	Modal,
	NotificationContainer,
	NotificationRenderProps,
	RequiredKeys,
	Row,
	Section,
	useNotifications,
} from "@hex-insights/core";
import { FormType, useFormState } from "@hex-insights/forms";
import {
	BillingSetupSubmission,
	BillingSetupSubmissionField,
	EnrollmentPricingTable,
	SchoolStudentEnrollment,
	SchoolStudentEnrollmentDetailQuery,
	SchoolStudentEnrollmentForm,
	SchoolStudentEnrollmentFormConversion,
	SchoolStudentEnrollmentMutation,
	StudentFormat,
	useAcademicYearTuitionIndexQuery,
	useBillingSetupSubmissionIndexQuery,
	useSchoolStudentEnrollmentDetailQuery,
} from "@hex-insights/verita.shared";

export type EnrollmentDrawerProps = {
	schoolStudentEnrollmentID: SchoolStudentEnrollment["id"];
} & RequiredKeys<Pick<DrawerProps, "ifRef" | "onClose">, "onClose">;

export function EnrollmentDrawer({ schoolStudentEnrollmentID, ifRef, onClose }: EnrollmentDrawerProps) {
	// useClickOutListener(ifRef?.current, onClose); // TODO

	const { loading, data } = useSchoolStudentEnrollmentDetailQuery({
		variables: { id: schoolStudentEnrollmentID },
	});

	return (
		<Drawer ifRef={ifRef} onClose={onClose} style={{ width: "60vw", minWidth: "30rem" }}>
			<Drawer.Header>
				<Heading.H2 noMargin>
					<Conditional>
						<If condition={loading}>Loading...</If>
						<Else>{data && `Enrollment for ${StudentFormat.name(data.schoolStudentEnrollment.student)}`}</Else>
					</Conditional>
				</Heading.H2>
			</Drawer.Header>
			<Drawer.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						{data && (
							<Column justify="spaced-start">
								<SchoolStudentEnrollmentForm.ControlledReadOnly
									schoolStudentEnrollment={data.schoolStudentEnrollment}
								/>
								<ActionButtons schoolStudentEnrollment={data.schoolStudentEnrollment} />
								<BillingSetupSubmissionController schoolStudentEnrollment={data.schoolStudentEnrollment} />
							</Column>
						)}
					</Else>
				</Conditional>
			</Drawer.Body>
		</Drawer>
	);
}

type ActionButtonsProps = {
	schoolStudentEnrollment: SchoolStudentEnrollmentDetailQuery["schoolStudentEnrollment"];
};

function ActionButtons({ schoolStudentEnrollment }: ActionButtonsProps) {
	const update = SchoolStudentEnrollmentMutation.useUpdate(schoolStudentEnrollment.id);
	const { addNotification } = useNotifications();

	const { isModalOpen, openModal, closeModal } = Modal.useToggle();

	const [isUpdatingPaid, setIsUpdatingPaid] = React.useState(false);
	const markAsPaid = React.useCallback(async () => {
		setIsUpdatingPaid(true);
		const initialFormValues = SchoolStudentEnrollmentFormConversion.toFormValues(schoolStudentEnrollment);
		const newHasPaidFee = !schoolStudentEnrollment.hasPaidFee;
		const { errors } = await update({ hasPaidFee: newHasPaidFee }, initialFormValues);
		if (errors) {
			addNotification(ErrorNotification, 3000);
		} else {
			addNotification(SuccessNotification, 2000);
			if (newHasPaidFee) {
				openModal();
			}
		}
		setIsUpdatingPaid(false);
	}, [openModal, update, schoolStudentEnrollment, addNotification]);

	const [isUpdatingVoid, setIsUpdatingVoid] = React.useState(false);
	const markAsVoid = React.useCallback(async () => {
		setIsUpdatingVoid(true);
		const initialFormValues = SchoolStudentEnrollmentFormConversion.toFormValues(schoolStudentEnrollment);
		const { errors } = await update({ isVoided: !schoolStudentEnrollment.isVoided }, initialFormValues);
		if (errors) {
			addNotification(ErrorNotification, 3000);
		} else {
			addNotification(SuccessNotification, 2000);
		}
		setIsUpdatingVoid(false);
	}, [update, schoolStudentEnrollment, addNotification]);

	return (
		<Row justify="spaced-start">
			<ButtonWithConfirmation
				variant={schoolStudentEnrollment.hasPaidFee ? "secondary" : "success"}
				onClick={markAsPaid}
				isLoading={isUpdatingPaid}
				disabled={isUpdatingPaid || isUpdatingVoid}
				confirmationContent="Please confirm."
			>
				{schoolStudentEnrollment.hasPaidFee ? "Mark as Unpaid" : "Mark as Paid"}
			</ButtonWithConfirmation>
			<Modal.If condition={isModalOpen}>
				<NewPaymentModal studentID={schoolStudentEnrollment.student.id} onSuccess={closeModal} onClose={closeModal} />
			</Modal.If>

			<ButtonWithConfirmation
				variant={schoolStudentEnrollment.isVoided ? "secondary" : "danger"}
				onClick={markAsVoid}
				isLoading={isUpdatingVoid}
				disabled={isUpdatingPaid || isUpdatingVoid}
				confirmationContent="Are you sure?"
			>
				{schoolStudentEnrollment.isVoided ? "Unvoid Enrollment" : "Void Enrollment"}
			</ButtonWithConfirmation>
		</Row>
	);
}

function SuccessNotification(props: NotificationRenderProps) {
	return (
		<NotificationContainer {...props} variant="success">
			Successfully saved.
		</NotificationContainer>
	);
}

function ErrorNotification(props: NotificationRenderProps) {
	return (
		<NotificationContainer {...props} variant="danger">
			Error saving. Please try again later.
		</NotificationContainer>
	);
}

type BillingSetupSubmissionControllerProps = {
	schoolStudentEnrollment: {
		student: Pick<SchoolStudentEnrollment["student"], "id">;
		academicYear: Pick<SchoolStudentEnrollment["academicYear"], "id">;
		classYear: Pick<SchoolStudentEnrollment["classYear"], "name">;
	};
};

function BillingSetupSubmissionController({ schoolStudentEnrollment }: BillingSetupSubmissionControllerProps) {
	const {
		student: { id: studentID },
		academicYear: { id: academicYearID },
		classYear,
	} = schoolStudentEnrollment;

	const { data: billingSetupData } = useBillingSetupSubmissionIndexQuery({
		variables: { filters: { studentIDEQ: studentID, academicYearIDEQ: academicYearID } },
	});

	const { data: tuitionData } = useAcademicYearTuitionIndexQuery({
		variables: {
			filters: {
				tuition: [{ students: [{ idEQ: studentID }] }],
				yearEQ: 2023, // TODO
			},
		},
	});

	if (!billingSetupData || billingSetupData.billingSetupSubmissionConnection.edges.length === 0) {
		return null;
	}

	if (!tuitionData || tuitionData.academicYearTuitionConnection.edges.length === 0) {
		return null;
	}

	const academicYearTuition = tuitionData.academicYearTuitionConnection.edges[0].node;
	let tuitionPrice = 0;
	// TODO
	if (classYear.name === "Nursery") {
		tuitionPrice = academicYearTuition.nurseryPrice;
	} else if (classYear.name === "Reception") {
		tuitionPrice = academicYearTuition.receptionPrice;
	} else if (/Year [1-6]/.test(classYear.name)) {
		tuitionPrice = academicYearTuition.primaryPrice;
	} else if (/Year [7-8]/.test(classYear.name)) {
		tuitionPrice = academicYearTuition.secondaryPrice;
	}

	return (
		<BillingSetupSubmissionDisplay
			billingSetupSubmission={billingSetupData.billingSetupSubmissionConnection.edges[0].node}
			tuitionPrice={tuitionPrice}
		/>
	);
}

type BillingSetupSubmissionDisplayProps = {
	billingSetupSubmission: Pick<
		BillingSetupSubmission,
		"numPayments" | "isInterestedInBusPlan" | "isInterestedInMealPlan" | "authorName" | "authorEmail" | "authorPhone"
	>;
	tuitionPrice: number;
};

function BillingSetupSubmissionDisplay({ billingSetupSubmission, tuitionPrice }: BillingSetupSubmissionDisplayProps) {
	const formState = useFormState({
		initialFormValues: {
			numPayments: billingSetupSubmission.numPayments as number | null,
			isInterestedInBusPlan: billingSetupSubmission.isInterestedInBusPlan,
			isInterestedInMealPlan: billingSetupSubmission.isInterestedInMealPlan,
			authorName: billingSetupSubmission.authorName,
			authorEmail: billingSetupSubmission.authorEmail,
			authorPhone: billingSetupSubmission.authorPhone,
		},
		formType: FormType.View,
	});

	return (
		<Section>
			<Section.Header>
				<Heading.H2 noMargin>Billing Details</Heading.H2>
			</Section.Header>
			<Section.Body style={{ paddingLeft: "0.25rem", paddingRight: "0.25rem" }}>
				<Column justify="spaced-start">
					<BillingSetupSubmissionField.NumPayments formState={formState} />
					<BillingSetupSubmissionField.IsInterestedInBusPlan formState={formState} />
					<BillingSetupSubmissionField.IsInterestedInMealPlan formState={formState} />

					<EnrollmentPricingTable
						numPayments={billingSetupSubmission.numPayments}
						isInterestedInBusPlan={billingSetupSubmission.isInterestedInBusPlan}
						isInterestedInMealPlan={billingSetupSubmission.isInterestedInMealPlan}
						tuitionPrice={tuitionPrice}
					/>

					<BillingSetupSubmissionField.AuthorName label="Submitted by" formState={formState} />
					<BillingSetupSubmissionField.AuthorEmail label="Email" formState={formState} />
					<BillingSetupSubmissionField.AuthorPhone label="Phone" formState={formState} />
				</Column>
			</Section.Body>
		</Section>
	);
}
