import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { ServiceCreateInput, ServiceDetailQuery, ServiceUpdateInput } from "../../GraphQL";
import { ServiceFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Service model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: ServiceFormValues.Create): ServiceCreateInput {
	const input: NullPartial<ServiceCreateInput> = { ...formValues };
	return input as ServiceCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Service model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<ServiceFormValues.Detail>,
	initialFormValues: ServiceFormValues.Detail,
): ServiceUpdateInput {
	const inputFormValues = omitKeys(formValues, ["serviceSubscriptionIDs"]);
	const input: ServiceUpdateInput = { ...inputFormValues };

	if (formValues.serviceSubscriptionIDs !== undefined) {
		const { added, removed } = getArrayDiff(
			initialFormValues.serviceSubscriptionIDs,
			formValues.serviceSubscriptionIDs,
		);
		input.addServiceSubscriptionIDs = added;
		input.removeServiceSubscriptionIDs = removed;
	}

	return input;
}

/**
 * Converts a Service instance to its corresponding detail form values.
 *
 * @param service The instance to convert.
 */
export function toFormValues(service: ServiceDetailQuery["service"]): ServiceFormValues.Detail {
	return {
		name: service.name,
		description: service.description,
		price: service.price,
		serviceSubscriptionIDs: service.serviceSubscriptions.map((e) => e.id),
	};
}
