import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	Course,
	CourseSection,
	CourseSectionCreateInput,
	CourseSectionCreateMutation,
	CourseSectionDeleteMutation,
	CourseSectionDetailDocument,
	CourseSectionDetailQuery,
	CourseSectionDetailQueryVariables,
	CourseSectionPeriod,
	CourseSectionUpdateInput,
	CourseSectionUpdateMutation,
	graphTypeNames,
	HomeRoomSection,
	Term,
	useCourseSectionCreateMutation,
	useCourseSectionDeleteMutation,
	useCourseSectionUpdateMutation,
} from "../../GraphQL";
import { CourseSectionFormConversion } from "../ModelFormConversion";
import { CourseSectionFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the CourseSection model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the CourseSection model.
 */
export function useCreate() {
	const [createCourseSection] = useCourseSectionCreateMutation();

	return React.useCallback(
		async (formValues: CourseSectionFormValues.Create) => {
			const input = CourseSectionFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createCourseSection({ variables: { input }, update: updateCache });

			return { data: data?.createCourseSection ?? null, errors: errors ?? null };
		},
		[createCourseSection],
	);
}

/**
 * Returns an `update` function for the CourseSection model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the CourseSection model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: CourseSection["id"]) {
	const [updateCourseSection] = useCourseSectionUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<CourseSectionFormValues.Detail>,
			initialFormValues: CourseSectionFormValues.Detail,
		) => {
			const input = CourseSectionFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateCourseSection({ variables: { id, input }, update: updateCache });

			return { data: data?.updateCourseSection ?? null, errors: errors ?? null };
		},
		[updateCourseSection, id],
	);
}

/**
 * Returns a `del` function for the CourseSection model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: CourseSection["id"]) {
	const [deleteCourseSection] = useCourseSectionDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteCourseSection({ variables: { id }, update: updateCache });

		return { data: data?.deleteCourseSection ?? false, errors: errors ?? null };
	}, [deleteCourseSection, id]);
}

export function getUpdateCacheForCreate(input: CourseSectionCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<CourseSectionCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createCourseSection;

		cache.writeQuery<CourseSectionDetailQuery, CourseSectionDetailQueryVariables>({
			query: CourseSectionDetailDocument,
			data: { courseSection: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.courseID) {
			addToCourseSectionsOfCourseCache(cache, input.courseID, createdObject);
		}

		if (input.courseSectionPeriodIDs) {
			for (let i = 0; i < input.courseSectionPeriodIDs.length; i++) {
				addToCourseSectionOfCourseSectionPeriodCache(cache, input.courseSectionPeriodIDs[i], createdObject);
			}
		}

		if (input.homeRoomSectionID) {
			addToCourseSectionsOfHomeRoomSectionCache(cache, input.homeRoomSectionID, createdObject);
		}

		if (input.termID) {
			addToCourseSectionsOfTermCache(cache, input.termID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "courseSectionConnection" });
	};
}

function getUpdateCacheForUpdate(input: CourseSectionUpdateInput, initialFormValues: CourseSectionFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<CourseSectionUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateCourseSection;

		if (initialFormValues.courseID && input.courseID) {
			removeFromCourseSectionsOfCourseCache(cache, initialFormValues.courseID, updatedObject);
		}
		if (input.courseID) {
			addToCourseSectionsOfCourseCache(cache, input.courseID, updatedObject);
		}

		if (input.addCourseSectionPeriodIDs) {
			for (let i = 0; i < input.addCourseSectionPeriodIDs.length; i++) {
				addToCourseSectionOfCourseSectionPeriodCache(cache, input.addCourseSectionPeriodIDs[i], updatedObject);
			}
		}
		if (input.removeCourseSectionPeriodIDs) {
			for (let i = 0; i < input.removeCourseSectionPeriodIDs.length; i++) {
				removeFromCourseSectionOfCourseSectionPeriodCache(cache, input.removeCourseSectionPeriodIDs[i], updatedObject);
			}
		}

		if (initialFormValues.homeRoomSectionID && input.homeRoomSectionID) {
			removeFromCourseSectionsOfHomeRoomSectionCache(cache, initialFormValues.homeRoomSectionID, updatedObject);
		}
		if (input.homeRoomSectionID) {
			addToCourseSectionsOfHomeRoomSectionCache(cache, input.homeRoomSectionID, updatedObject);
		}

		if (initialFormValues.termID && input.termID) {
			removeFromCourseSectionsOfTermCache(cache, initialFormValues.termID, updatedObject);
		}
		if (input.termID) {
			addToCourseSectionsOfTermCache(cache, input.termID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: CourseSection["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<CourseSectionDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteCourseSection) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.CourseSection }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "courseSectionConnection" });
		cache.gc();
	};
}

function addToCourseSectionsOfCourseCache(
	cache: ApolloCache<any>,
	targetID: Course["id"],
	object: Pick<CourseSection, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Course }),
		fields: {
			courseSections: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromCourseSectionsOfCourseCache(
	cache: ApolloCache<any>,
	targetID: Course["id"],
	object: Pick<CourseSection, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Course }),
		fields: {
			courseSections: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToCourseSectionOfCourseSectionPeriodCache(
	cache: ApolloCache<any>,
	targetID: CourseSectionPeriod["id"],
	object: Pick<CourseSection, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSectionPeriod }),
		fields: {
			courseSection: () => objectRef,
			courseSectionID: () => object.id,
		},
	});
}

function removeFromCourseSectionOfCourseSectionPeriodCache(
	cache: ApolloCache<any>,
	targetID: CourseSectionPeriod["id"],
	_object: Pick<CourseSection, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSectionPeriod }),
		fields: {
			courseSection: () => null,
			courseSectionID: () => null,
		},
	});
}

function addToCourseSectionsOfHomeRoomSectionCache(
	cache: ApolloCache<any>,
	targetID: HomeRoomSection["id"],
	object: Pick<CourseSection, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.HomeRoomSection }),
		fields: {
			courseSections: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromCourseSectionsOfHomeRoomSectionCache(
	cache: ApolloCache<any>,
	targetID: HomeRoomSection["id"],
	object: Pick<CourseSection, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.HomeRoomSection }),
		fields: {
			courseSections: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToCourseSectionsOfTermCache(
	cache: ApolloCache<any>,
	targetID: Term["id"],
	object: Pick<CourseSection, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Term }),
		fields: {
			courseSections: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromCourseSectionsOfTermCache(
	cache: ApolloCache<any>,
	targetID: Term["id"],
	object: Pick<CourseSection, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Term }),
		fields: {
			courseSections: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<CourseSection, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment CourseSectionRef on CourseSection {
				id
			}
		`,
		data: object,
	});
}
