import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import {
	EnrollmentApplication,
	EnrollmentApplicationFormat,
	EnrollmentApplicationNameFragment,
} from "../../../../Utilities";

type ModelForLink = Pick<EnrollmentApplication, "id"> & EnrollmentApplicationNameFragment;

export type EnrollmentApplicationLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given EnrollmentApplication instance.
 */
export function EnrollmentApplicationLink(props: EnrollmentApplicationLinkProps) {
	return (
		<Models.Link<ModelForLink>
			pageInfoKey="EnrollmentApplication"
			format={EnrollmentApplicationFormat.name}
			{...props}
		/>
	);
}
