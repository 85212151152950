import { newPageInfo } from "@hex-insights/router";
import { LearningObjective } from "@hex-insights/verita.shared";
import { learningObjectiveHubPageInfo } from "../pageinfo";

type LearningObjectiveForRouteTitle = Pick<LearningObjective, "id">;

export const learningObjectiveHubDetailPageInfo = newPageInfo({
	name: "Learning Objective Detail",
	title: (e: LearningObjectiveForRouteTitle) => e.id,
	description: "Detail page for an learningObjective.",
	exact: false,
	isLeaf: true,
	to: (id: LearningObjective["id"]) => learningObjectiveHubPageInfo.to + `/${id}`,
	path: learningObjectiveHubPageInfo.path + "/:id(\\d+)",
	parent: learningObjectiveHubPageInfo,
});
