import { Runners } from "@hex-insights/app-modules";
import { Column, If, Row } from "@hex-insights/core";
import { InternalLink } from "@hex-insights/router";
import { homePageInfo } from "../../Pages/Home/pageinfo";
import { hubsPageInfo } from "../../Pages/Hubs/pageinfo";
import styles from "./styles.module.css";

const localVersion = process.env.REACT_APP_VERSION ?? "";

export function Footer() {
	return <Runners.Footer Bar={FooterBar} className="no-print" />;
}

function FooterBar() {
	return (
		<Row justify="space-between" align="center" className={styles["footer__bar"]}>
			<Row justify="spaced-start" horizontalSpacing="5vw">
				<InternalLink to={homePageInfo.to} className={styles["footer__bar__site-title"]}>
					Portal
				</InternalLink>

				<Column justify="spaced-start" verticalSpacing="0.25rem">
					<InternalLink to={homePageInfo.to}>Home</InternalLink>
					<InternalLink to={hubsPageInfo.to}>Hubs</InternalLink>
				</Column>
			</Row>

			<If condition={localVersion !== ""}>
				<span className={styles["footer__bar__version-field"]}>
					Version: <span className={styles["footer__bar__version-number"]}>{localVersion}</span>
				</span>
			</If>
		</Row>
	);
}
