import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { ServiceSubscriptionFilterInput } from "../../GraphQL";
import { ServiceSubscriptionFilterFormValues } from "../ModelFilterFormValues";

/**
 * Counts the number of active filters for the ServiceSubscription model.
 *
 * @param formValues The form values of the filter form.
 */
export function countActiveFilters(formValues: ServiceSubscriptionFilterFormValues.FormValues) {
	// TODO count quick filters
	const numAdvancedFilters = IndexForms.countActiveAdvancedFilters(
		formValues,
		ServiceSubscriptionFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	);
	return numAdvancedFilters;
}

/**
 * Clears the values of the filter form for the ServiceSubscription model.
 *
 * @param formState The form state of the filter form.
 */
export function clearFilters(formState: FormState<ServiceSubscriptionFilterFormValues.FormValues>) {
	// TODO clear quick filters
	formState.formClearFunctions.filterSets();
}

/**
 * Resets the values of the filter form for the ServiceSubscription model.
 *
 * @param formState The form state of the filter form.
 */
export function resetFilters(formState: FormState<ServiceSubscriptionFilterFormValues.FormValues>) {
	// TODO reset quick filters
	formState.formResetFunctions.filterSets();
}

/**
 * Converts the form values of the filter form for the ServiceSubscription model to the correct GraphQL
 * filter input type.
 *
 * @param formValues The form values of the filter form.
 */
export function toFilterInputs(
	formValues: ServiceSubscriptionFilterFormValues.FormValues,
): ServiceSubscriptionFilterInput | ServiceSubscriptionFilterInput[] {
	return IndexForms.toFilterInput(
		formValues,
		ServiceSubscriptionFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	) as ServiceSubscriptionFilterInput;
}
