import { newPageInfo, newRouteParamsType } from "@hex-insights/router";
import { EnrollmentInvitation } from "@hex-insights/verita.shared";
import { enrollmentInvitationHubPageInfo } from "../pageinfo";

type EnrollmentInvitationForRouteTitle = Pick<EnrollmentInvitation, "id">;

export const enrollmentInvitationHubDetailPageInfo = newPageInfo({
	name: "Enrollment Invitation Detail",
	title: (e: EnrollmentInvitationForRouteTitle) => e.id,
	description: "Detail page for an enrollmentInvitation.",
	exact: false,
	isLeaf: true,
	parent: enrollmentInvitationHubPageInfo,
	to: (id: EnrollmentInvitation["id"]) => enrollmentInvitationHubPageInfo.to + `/${id}`,
	path: enrollmentInvitationHubPageInfo.path + "/:enrollmentInvitationID(\\d+)",
	paramsType: newRouteParamsType(enrollmentInvitationHubPageInfo.paramsType, "enrollmentInvitationID"),
});
