import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { DocumentResourceUserViewRecordOrderFormValues } from "../../../../Utilities";

export type DocumentResourceUserViewRecordOrderFormControllerProps = {
	formState: FormState<DocumentResourceUserViewRecordOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the DocumentResourceUserViewRecord model.
 */
export function DocumentResourceUserViewRecordOrderFormController({
	formState,
}: DocumentResourceUserViewRecordOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={DocumentResourceUserViewRecordOrderFormValues.fieldOptions}
			fieldTypeMapping={DocumentResourceUserViewRecordOrderFormValues.fieldTypeMapping}
		/>
	);
}
