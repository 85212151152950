import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import {
	DocumentResourceFolder,
	DocumentResourceFolderFormat,
	DocumentResourceFolderNameFragment,
} from "../../../../Utilities";

type ModelForLink = Pick<DocumentResourceFolder, "id"> & DocumentResourceFolderNameFragment;

export type DocumentResourceFolderLinkProps = DistributiveOmit<
	Models.LinkProps<ModelForLink>,
	"pageInfoKey" | "format"
>;

/**
 * Renders a link to the given DocumentResourceFolder instance.
 */
export function DocumentResourceFolderLink(props: DocumentResourceFolderLinkProps) {
	return (
		<Models.Link<ModelForLink>
			pageInfoKey="DocumentResourceFolder"
			format={DocumentResourceFolderFormat.name}
			{...props}
		/>
	);
}
