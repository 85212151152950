import { newPageInfo } from "@hex-insights/router";
import { campusHubPageInfo } from "../pageinfo";

export const campusHubCreatePageInfo = newPageInfo({
	name: "New Campus",
	title: "New",
	description: "Create a new Campus record.",
	exact: true,
	to: campusHubPageInfo.to + "/new",
	path: campusHubPageInfo.path + "/new",
	parent: campusHubPageInfo,
});
