import { IndexForms } from "@hex-insights/app-modules";
import { LoginRecordFilterFormValues } from "../../../../Utilities";

export type LoginRecordSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the LoginRecord model.
 */
export function LoginRecordSearchForm(props: LoginRecordSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type LoginRecordFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, LoginRecordFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the LoginRecord model.
 */
export function LoginRecordFilterFormController(props: LoginRecordFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={LoginRecordFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `LoginRecordFilterForm`
