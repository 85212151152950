import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	Campus,
	Employee,
	EmployeeCreateInput,
	EmployeeCreateMutation,
	EmployeeDeleteMutation,
	EmployeeDetailDocument,
	EmployeeDetailQuery,
	EmployeeDetailQueryVariables,
	EmployeeUpdateInput,
	EmployeeUpdateMutation,
	EmployeeUpdateWithPersonMutation,
	graphTypeNames,
	Person,
	useEmployeeCreateMutation,
	useEmployeeDeleteMutation,
	useEmployeeUpdateMutation,
	useEmployeeUpdateWithPersonMutation,
} from "../../GraphQL";
import { EmployeeFormConversion } from "../ModelFormConversion";
import { EmployeeFormValues } from "../ModelFormValues";
import * as PersonMutation from "./person";

/**
 * Returns a `create` function for the Employee model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Employee model.
 */
export function useCreate() {
	const [createEmployee] = useEmployeeCreateMutation();

	return React.useCallback(
		async (formValues: EmployeeFormValues.Create) => {
			const input = EmployeeFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createEmployee({ variables: { input }, update: updateCache });

			return { data: data?.createEmployee ?? null, errors: errors ?? null };
		},
		[createEmployee],
	);
}

/**
 * Returns a `create` function for the Employee model with a nested Person model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Employee model.
 */
export function useCreateWithPerson() {
	const [createEmployee] = useEmployeeCreateMutation();

	return React.useCallback(
		async (formValues: EmployeeFormValues.CreateWithPerson) => {
			const input = EmployeeFormConversion.toGQLCreateWithPersonInput(formValues);
			// TODO update person cache
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createEmployee({ variables: { input }, update: updateCache });

			return { data: data?.createEmployee ?? null, errors: errors ?? null };
		},
		[createEmployee],
	);
}

/**
 * Returns an `update` function for the Employee model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Employee model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Employee["id"]) {
	const [updateEmployee] = useEmployeeUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<EmployeeFormValues.Detail>, initialFormValues: EmployeeFormValues.Detail) => {
			const input = EmployeeFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateEmployee({ variables: { id, input }, update: updateCache });

			return { data: data?.updateEmployee ?? null, errors: errors ?? null };
		},
		[updateEmployee, id],
	);
}

export function useUpdateWithPerson(id: Employee["id"]) {
	const [updateEmployee] = useEmployeeUpdateWithPersonMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<EmployeeFormValues.DetailWithPerson>,
			initialFormValues: Partial<EmployeeFormValues.DetailWithPerson>,
		) => {
			const [input, personInput] = EmployeeFormConversion.toGQLUpdateWithPersonInput(
				changedFormValues,
				initialFormValues,
			);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);
			const updatePersonCache = PersonMutation.getUpdateCacheForUpdate(personInput, initialFormValues);
			const update = (
				cache: ApolloCache<any>,
				result: Omit<
					FetchResult<EmployeeUpdateWithPersonMutation, Record<string, any>, Record<string, any>>,
					"context"
				>,
			) => {
				updateCache(cache, {
					...result,
					data: result.data ? { updateEmployee: result.data.updateEmployeeWithPerson } : undefined,
				});
				updatePersonCache(cache, {
					...result,
					data: result.data ? { updatePerson: result.data.updateEmployeeWithPerson.person } : undefined,
				});
			};

			const { data, errors } = await updateEmployee({ variables: { id, input, personInput }, update });

			return { data: data?.updateEmployeeWithPerson ?? null, errors: errors ?? null };
		},
		[updateEmployee, id],
	);
}

/**
 * Returns a `del` function for the Employee model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Employee["id"]) {
	const [deleteEmployee] = useEmployeeDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteEmployee({ variables: { id }, update: updateCache });

		return { data: data?.deleteEmployee ?? false, errors: errors ?? null };
	}, [deleteEmployee, id]);
}

function getUpdateCacheForCreate(input: EmployeeCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<EmployeeCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createEmployee;

		cache.writeQuery<EmployeeDetailQuery, EmployeeDetailQueryVariables>({
			query: EmployeeDetailDocument,
			data: { employee: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.campusIDs) {
			for (let i = 0; i < input.campusIDs.length; i++) {
				addToEmployeesOfCampusCache(cache, input.campusIDs[i], createdObject);
			}
		}

		if (input.personID) {
			addToEmployeeOfPersonCache(cache, input.personID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "employeeConnection" });
	};
}

function getUpdateCacheForUpdate(input: EmployeeUpdateInput, initialFormValues: Partial<EmployeeFormValues.Detail>) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<EmployeeUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateEmployee;

		if (input.addCampusIDs) {
			for (let i = 0; i < input.addCampusIDs.length; i++) {
				addToEmployeesOfCampusCache(cache, input.addCampusIDs[i], updatedObject);
			}
		}
		if (input.removeCampusIDs) {
			for (let i = 0; i < input.removeCampusIDs.length; i++) {
				removeFromEmployeesOfCampusCache(cache, input.removeCampusIDs[i], updatedObject);
			}
		}

		if (initialFormValues.personID && input.personID) {
			removeFromEmployeeOfPersonCache(cache, initialFormValues.personID, updatedObject);
		}
		if (input.personID) {
			addToEmployeeOfPersonCache(cache, input.personID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: Employee["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<EmployeeDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteEmployee) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Employee }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "employeeConnection" });
		cache.gc();
	};
}

function addToEmployeesOfCampusCache(cache: ApolloCache<any>, targetID: Campus["id"], object: Pick<Employee, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Campus }),
		fields: {
			employees: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromEmployeesOfCampusCache(
	cache: ApolloCache<any>,
	targetID: Campus["id"],
	object: Pick<Employee, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Campus }),
		fields: {
			employees: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToEmployeeOfPersonCache(cache: ApolloCache<any>, targetID: Person["id"], object: Pick<Employee, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Person }),
		fields: {
			employee: () => objectRef,
		},
	});
}

function removeFromEmployeeOfPersonCache(
	cache: ApolloCache<any>,
	targetID: Person["id"],
	_object: Pick<Employee, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Person }),
		fields: {
			employee: () => null,
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Employee, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment EmployeeRef on Employee {
				id
			}
		`,
		data: object,
	});
}
