import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { BusHubCreatePage } from "./CreatePage";
import { busHubCreatePageInfo } from "./CreatePage/pageinfo";
import { BusHubDetailPage } from "./DetailPage";
import { busHubDetailPageInfo } from "./DetailPage/pageinfo";
import { BusHubIndexPage } from "./IndexPage";
import { busHubIndexPageInfo } from "./IndexPage/pageinfo";
import { busHubPageInfo } from "./pageinfo";

export function BusHub() {
	useActivePageRegistration(busHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...busHubIndexPageInfo.routeProps}>
				<BusHubIndexPage />
			</Route>
			<Route {...busHubCreatePageInfo.routeProps}>
				<BusHubCreatePage />
			</Route>
			<Route {...busHubDetailPageInfo.routeProps}>
				<BusHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
