import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	EnrollmentApplication,
	EnrollmentApplicationStudent,
	EnrollmentApplicationStudentCreateInput,
	EnrollmentApplicationStudentCreateMutation,
	EnrollmentApplicationStudentDeleteMutation,
	EnrollmentApplicationStudentDetailDocument,
	EnrollmentApplicationStudentDetailQuery,
	EnrollmentApplicationStudentDetailQueryVariables,
	EnrollmentApplicationStudentUpdateInput,
	EnrollmentApplicationStudentUpdateMutation,
	EnrollmentApplicationSubmission,
	graphTypeNames,
	useEnrollmentApplicationStudentCreateMutation,
	useEnrollmentApplicationStudentDeleteMutation,
	useEnrollmentApplicationStudentUpdateMutation,
} from "../../GraphQL";
import { EnrollmentApplicationStudentFormConversion } from "../ModelFormConversion";
import { EnrollmentApplicationStudentFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the EnrollmentApplicationStudent model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the EnrollmentApplicationStudent model.
 */
export function useCreate() {
	const [createEnrollmentApplicationStudent] = useEnrollmentApplicationStudentCreateMutation();

	return React.useCallback(
		async (formValues: EnrollmentApplicationStudentFormValues.Create) => {
			const input = EnrollmentApplicationStudentFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createEnrollmentApplicationStudent({
				variables: { input },
				update: updateCache,
			});

			return { data: data?.createEnrollmentApplicationStudent ?? null, errors: errors ?? null };
		},
		[createEnrollmentApplicationStudent],
	);
}

/**
 * Returns an `update` function for the EnrollmentApplicationStudent model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the EnrollmentApplicationStudent model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: EnrollmentApplicationStudent["id"]) {
	const [updateEnrollmentApplicationStudent] = useEnrollmentApplicationStudentUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<EnrollmentApplicationStudentFormValues.Detail>,
			initialFormValues: EnrollmentApplicationStudentFormValues.Detail,
		) => {
			const input = EnrollmentApplicationStudentFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateEnrollmentApplicationStudent({
				variables: { id, input },
				update: updateCache,
			});

			return { data: data?.updateEnrollmentApplicationStudent ?? null, errors: errors ?? null };
		},
		[updateEnrollmentApplicationStudent, id],
	);
}

/**
 * Returns a `del` function for the EnrollmentApplicationStudent model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: EnrollmentApplicationStudent["id"]) {
	const [deleteEnrollmentApplicationStudent] = useEnrollmentApplicationStudentDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteEnrollmentApplicationStudent({ variables: { id }, update: updateCache });

		return { data: data?.deleteEnrollmentApplicationStudent ?? false, errors: errors ?? null };
	}, [deleteEnrollmentApplicationStudent, id]);
}

function getUpdateCacheForCreate(input: EnrollmentApplicationStudentCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<EnrollmentApplicationStudentCreateMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createEnrollmentApplicationStudent;

		cache.writeQuery<EnrollmentApplicationStudentDetailQuery, EnrollmentApplicationStudentDetailQueryVariables>({
			query: EnrollmentApplicationStudentDetailDocument,
			data: { enrollmentApplicationStudent: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.enrollmentApplicationID) {
			addToEnrollmentApplicationStudentOfEnrollmentApplicationCache(
				cache,
				input.enrollmentApplicationID,
				createdObject,
			);
		}

		if (input.enrollmentApplicationSubmissionID) {
			addToEnrollmentApplicationStudentsOfEnrollmentApplicationSubmissionCache(
				cache,
				input.enrollmentApplicationSubmissionID,
				createdObject,
			);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "enrollmentApplicationStudentConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: EnrollmentApplicationStudentUpdateInput,
	initialFormValues: EnrollmentApplicationStudentFormValues.Detail,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<EnrollmentApplicationStudentUpdateMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateEnrollmentApplicationStudent;

		if (
			initialFormValues.enrollmentApplicationID &&
			(input.enrollmentApplicationID || input.clearEnrollmentApplication)
		) {
			removeFromEnrollmentApplicationStudentOfEnrollmentApplicationCache(
				cache,
				initialFormValues.enrollmentApplicationID,
				updatedObject,
			);
		}
		if (input.enrollmentApplicationID) {
			addToEnrollmentApplicationStudentOfEnrollmentApplicationCache(
				cache,
				input.enrollmentApplicationID,
				updatedObject,
			);
		}

		if (initialFormValues.enrollmentApplicationSubmissionID && input.enrollmentApplicationSubmissionID) {
			removeFromEnrollmentApplicationStudentsOfEnrollmentApplicationSubmissionCache(
				cache,
				initialFormValues.enrollmentApplicationSubmissionID,
				updatedObject,
			);
		}
		if (input.enrollmentApplicationSubmissionID) {
			addToEnrollmentApplicationStudentsOfEnrollmentApplicationSubmissionCache(
				cache,
				input.enrollmentApplicationSubmissionID,
				updatedObject,
			);
		}
	};
}

function getUpdateCacheForDelete(id: EnrollmentApplicationStudent["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<EnrollmentApplicationStudentDeleteMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (!result.data?.deleteEnrollmentApplicationStudent) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.EnrollmentApplicationStudent }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "enrollmentApplicationStudentConnection" });
		cache.gc();
	};
}

function addToEnrollmentApplicationStudentOfEnrollmentApplicationCache(
	cache: ApolloCache<any>,
	targetID: EnrollmentApplication["id"],
	object: Pick<EnrollmentApplicationStudent, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.EnrollmentApplication }),
		fields: {
			enrollmentApplicationStudent: () => objectRef,
			enrollmentApplicationStudentID: () => object.id,
		},
	});
}

function removeFromEnrollmentApplicationStudentOfEnrollmentApplicationCache(
	cache: ApolloCache<any>,
	targetID: EnrollmentApplication["id"],
	_object: Pick<EnrollmentApplicationStudent, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.EnrollmentApplication }),
		fields: {
			enrollmentApplicationStudent: () => null,
			enrollmentApplicationStudentID: () => null,
		},
	});
}

function addToEnrollmentApplicationStudentsOfEnrollmentApplicationSubmissionCache(
	cache: ApolloCache<any>,
	targetID: EnrollmentApplicationSubmission["id"],
	object: Pick<EnrollmentApplicationStudent, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.EnrollmentApplicationSubmission }),
		fields: {
			enrollmentApplicationStudents: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromEnrollmentApplicationStudentsOfEnrollmentApplicationSubmissionCache(
	cache: ApolloCache<any>,
	targetID: EnrollmentApplicationSubmission["id"],
	object: Pick<EnrollmentApplicationStudent, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.EnrollmentApplicationSubmission }),
		fields: {
			enrollmentApplicationStudents: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<EnrollmentApplicationStudent, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment EnrollmentApplicationStudentRef on EnrollmentApplicationStudent {
				id
			}
		`,
		data: object,
	});
}
