import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	AcademicYear,
	AcademicYearCreateInput,
	AcademicYearCreateMutation,
	AcademicYearDeleteMutation,
	AcademicYearDetailDocument,
	AcademicYearDetailQuery,
	AcademicYearDetailQueryVariables,
	AcademicYearUpdateInput,
	AcademicYearUpdateMutation,
	BillingSetupSubmission,
	graphTypeNames,
	SchoolStudentEnrollment,
	Term,
	useAcademicYearCreateMutation,
	useAcademicYearDeleteMutation,
	useAcademicYearUpdateMutation,
} from "../../GraphQL";
import { AcademicYearFormConversion } from "../ModelFormConversion";
import { AcademicYearFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the AcademicYear model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the AcademicYear model.
 */
export function useCreate() {
	const [createAcademicYear] = useAcademicYearCreateMutation();

	return React.useCallback(
		async (formValues: AcademicYearFormValues.Create) => {
			const input = AcademicYearFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createAcademicYear({ variables: { input }, update: updateCache });

			return { data: data?.createAcademicYear ?? null, errors: errors ?? null };
		},
		[createAcademicYear],
	);
}

/**
 * Returns an `update` function for the AcademicYear model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the AcademicYear model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: AcademicYear["id"]) {
	const [updateAcademicYear] = useAcademicYearUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<AcademicYearFormValues.Detail>,
			initialFormValues: AcademicYearFormValues.Detail,
		) => {
			const input = AcademicYearFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateAcademicYear({ variables: { id, input }, update: updateCache });

			return { data: data?.updateAcademicYear ?? null, errors: errors ?? null };
		},
		[updateAcademicYear, id],
	);
}

/**
 * Returns a `del` function for the AcademicYear model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: AcademicYear["id"]) {
	const [deleteAcademicYear] = useAcademicYearDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteAcademicYear({ variables: { id }, update: updateCache });

		return { data: data?.deleteAcademicYear ?? false, errors: errors ?? null };
	}, [deleteAcademicYear, id]);
}

function getUpdateCacheForCreate(input: AcademicYearCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<AcademicYearCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createAcademicYear;

		cache.writeQuery<AcademicYearDetailQuery, AcademicYearDetailQueryVariables>({
			query: AcademicYearDetailDocument,
			data: { academicYear: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.billingSetupSubmissionIDs) {
			for (let i = 0; i < input.billingSetupSubmissionIDs.length; i++) {
				addToAcademicYearOfBillingSetupSubmissionCache(cache, input.billingSetupSubmissionIDs[i], createdObject);
			}
		}

		if (input.schoolStudentEnrollmentIDs) {
			for (let i = 0; i < input.schoolStudentEnrollmentIDs.length; i++) {
				addToAcademicYearOfSchoolStudentEnrollmentCache(cache, input.schoolStudentEnrollmentIDs[i], createdObject);
			}
		}

		if (input.termIDs) {
			for (let i = 0; i < input.termIDs.length; i++) {
				addToAcademicYearOfTermCache(cache, input.termIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "academicYearConnection" });
	};
}

function getUpdateCacheForUpdate(input: AcademicYearUpdateInput, _initialFormValues: AcademicYearFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<AcademicYearUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateAcademicYear;

		if (input.addBillingSetupSubmissionIDs) {
			for (let i = 0; i < input.addBillingSetupSubmissionIDs.length; i++) {
				addToAcademicYearOfBillingSetupSubmissionCache(cache, input.addBillingSetupSubmissionIDs[i], updatedObject);
			}
		}
		if (input.removeBillingSetupSubmissionIDs) {
			for (let i = 0; i < input.removeBillingSetupSubmissionIDs.length; i++) {
				removeFromAcademicYearOfBillingSetupSubmissionCache(
					cache,
					input.removeBillingSetupSubmissionIDs[i],
					updatedObject,
				);
			}
		}

		if (input.addSchoolStudentEnrollmentIDs) {
			for (let i = 0; i < input.addSchoolStudentEnrollmentIDs.length; i++) {
				addToAcademicYearOfSchoolStudentEnrollmentCache(cache, input.addSchoolStudentEnrollmentIDs[i], updatedObject);
			}
		}
		if (input.removeSchoolStudentEnrollmentIDs) {
			for (let i = 0; i < input.removeSchoolStudentEnrollmentIDs.length; i++) {
				removeFromAcademicYearOfSchoolStudentEnrollmentCache(
					cache,
					input.removeSchoolStudentEnrollmentIDs[i],
					updatedObject,
				);
			}
		}

		if (input.addTermIDs) {
			for (let i = 0; i < input.addTermIDs.length; i++) {
				addToAcademicYearOfTermCache(cache, input.addTermIDs[i], updatedObject);
			}
		}
		if (input.removeTermIDs) {
			for (let i = 0; i < input.removeTermIDs.length; i++) {
				removeFromAcademicYearOfTermCache(cache, input.removeTermIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: AcademicYear["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<AcademicYearDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteAcademicYear) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.AcademicYear }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "academicYearConnection" });
		cache.gc();
	};
}

function addToAcademicYearOfBillingSetupSubmissionCache(
	cache: ApolloCache<any>,
	targetID: BillingSetupSubmission["id"],
	object: Pick<AcademicYear, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.BillingSetupSubmission }),
		fields: {
			academicYear: () => objectRef,
			academicYearID: () => object.id,
		},
	});
}

function removeFromAcademicYearOfBillingSetupSubmissionCache(
	cache: ApolloCache<any>,
	targetID: BillingSetupSubmission["id"],
	_object: Pick<AcademicYear, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.BillingSetupSubmission }),
		fields: {
			academicYear: () => null,
			academicYearID: () => null,
		},
	});
}

function addToAcademicYearOfSchoolStudentEnrollmentCache(
	cache: ApolloCache<any>,
	targetID: SchoolStudentEnrollment["id"],
	object: Pick<AcademicYear, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.SchoolStudentEnrollment }),
		fields: {
			academicYear: () => objectRef,
			academicYearID: () => object.id,
		},
	});
}

function removeFromAcademicYearOfSchoolStudentEnrollmentCache(
	cache: ApolloCache<any>,
	targetID: SchoolStudentEnrollment["id"],
	_object: Pick<AcademicYear, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.SchoolStudentEnrollment }),
		fields: {
			academicYear: () => null,
			academicYearID: () => null,
		},
	});
}

function addToAcademicYearOfTermCache(cache: ApolloCache<any>, targetID: Term["id"], object: Pick<AcademicYear, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Term }),
		fields: {
			academicYear: () => objectRef,
			academicYearID: () => object.id,
		},
	});
}

function removeFromAcademicYearOfTermCache(
	cache: ApolloCache<any>,
	targetID: Term["id"],
	_object: Pick<AcademicYear, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Term }),
		fields: {
			academicYear: () => null,
			academicYearID: () => null,
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<AcademicYear, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment AcademicYearRef on AcademicYear {
				id
			}
		`,
		data: object,
	});
}
