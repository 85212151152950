import React from "react";
import { Column, makeClassName } from "@hex-insights/core";
import { EnrollmentApplicationIndexQuery } from "@hex-insights/verita.shared";
import styles from "./styles.module.css";

export type EnrollmentApplicationViewStatusProps = {
	viewRecords: EnrollmentApplicationIndexQuery["enrollmentApplicationConnection"]["edges"][0]["node"]["enrollmentApplicationUserViewRecords"];
};

export function EnrollmentApplicationViewStatus({ viewRecords }: EnrollmentApplicationViewStatusProps) {
	const [text, statusClassName] = React.useMemo(() => {
		if (viewRecords.some((e) => e.isDetail)) {
			return ["Seen", styles["view-status--seen"]];
		}
		return ["New", styles["view-status--new"]];
	}, [viewRecords]);

	return (
		<Column justify="center" align="center" className={makeClassName(styles["view-status"], statusClassName)}>
			<span>{text}</span>
		</Column>
	);
}
