import { IndexForms } from "@hex-insights/app-modules";
import { EmailAddressFilterFormValues } from "../../../../Utilities";

export type EmailAddressSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the EmailAddress model.
 */
export function EmailAddressSearchForm(props: EmailAddressSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type EmailAddressFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, EmailAddressFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the EmailAddress model.
 */
export function EmailAddressFilterFormController(props: EmailAddressFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={EmailAddressFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `EmailAddressFilterForm`
