import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { TermFilterInput, TermOrder, useTermPaginationQuery } from "../../../../Utilities";

export type TermPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: TermFilterInput | TermFilterInput[] | null;
	order: TermOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the Term model.
 */
export function TermPaginationForm({ formState, filters, order, setPaginationInfo }: TermPaginationFormProps) {
	const { loading, data } = useTermPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.termConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
