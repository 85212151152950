import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	AcademicYear,
	ClassYear,
	graphTypeNames,
	SchoolStudentEnrollment,
	SchoolStudentEnrollmentCreateInput,
	SchoolStudentEnrollmentCreateMutation,
	SchoolStudentEnrollmentDeleteMutation,
	SchoolStudentEnrollmentDetailDocument,
	SchoolStudentEnrollmentDetailQuery,
	SchoolStudentEnrollmentDetailQueryVariables,
	SchoolStudentEnrollmentUpdateInput,
	SchoolStudentEnrollmentUpdateMutation,
	Student,
	useSchoolStudentEnrollmentCreateMutation,
	useSchoolStudentEnrollmentDeleteMutation,
	useSchoolStudentEnrollmentUpdateMutation,
} from "../../GraphQL";
import { SchoolStudentEnrollmentFormConversion } from "../ModelFormConversion";
import { SchoolStudentEnrollmentFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the SchoolStudentEnrollment model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the SchoolStudentEnrollment model.
 */
export function useCreate() {
	const [createSchoolStudentEnrollment] = useSchoolStudentEnrollmentCreateMutation();

	return React.useCallback(
		async (formValues: SchoolStudentEnrollmentFormValues.Create) => {
			const input = SchoolStudentEnrollmentFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createSchoolStudentEnrollment({ variables: { input }, update: updateCache });

			return { data: data?.createSchoolStudentEnrollment ?? null, errors: errors ?? null };
		},
		[createSchoolStudentEnrollment],
	);
}

/**
 * Returns an `update` function for the SchoolStudentEnrollment model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the SchoolStudentEnrollment model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: SchoolStudentEnrollment["id"]) {
	const [updateSchoolStudentEnrollment] = useSchoolStudentEnrollmentUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<SchoolStudentEnrollmentFormValues.Detail>,
			initialFormValues: SchoolStudentEnrollmentFormValues.Detail,
		) => {
			const input = SchoolStudentEnrollmentFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateSchoolStudentEnrollment({
				variables: { id, input },
				update: updateCache,
			});

			return { data: data?.updateSchoolStudentEnrollment ?? null, errors: errors ?? null };
		},
		[updateSchoolStudentEnrollment, id],
	);
}

/**
 * Returns a `del` function for the SchoolStudentEnrollment model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: SchoolStudentEnrollment["id"]) {
	const [deleteSchoolStudentEnrollment] = useSchoolStudentEnrollmentDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteSchoolStudentEnrollment({ variables: { id }, update: updateCache });

		return { data: data?.deleteSchoolStudentEnrollment ?? false, errors: errors ?? null };
	}, [deleteSchoolStudentEnrollment, id]);
}

function getUpdateCacheForCreate(input: SchoolStudentEnrollmentCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<SchoolStudentEnrollmentCreateMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createSchoolStudentEnrollment;

		cache.writeQuery<SchoolStudentEnrollmentDetailQuery, SchoolStudentEnrollmentDetailQueryVariables>({
			query: SchoolStudentEnrollmentDetailDocument,
			data: { schoolStudentEnrollment: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.academicYearID) {
			addToSchoolStudentEnrollmentsOfAcademicYearCache(cache, input.academicYearID, createdObject);
		}

		if (input.classYearID) {
			addToSchoolStudentEnrollmentsOfClassYearCache(cache, input.classYearID, createdObject);
		}

		if (input.studentID) {
			addToSchoolStudentEnrollmentsOfStudentCache(cache, input.studentID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "schoolStudentEnrollmentConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: SchoolStudentEnrollmentUpdateInput,
	initialFormValues: SchoolStudentEnrollmentFormValues.Detail,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<SchoolStudentEnrollmentUpdateMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateSchoolStudentEnrollment;

		if (initialFormValues.academicYearID && input.academicYearID) {
			removeFromSchoolStudentEnrollmentsOfAcademicYearCache(cache, initialFormValues.academicYearID, updatedObject);
		}
		if (input.academicYearID) {
			addToSchoolStudentEnrollmentsOfAcademicYearCache(cache, input.academicYearID, updatedObject);
		}

		if (initialFormValues.classYearID && input.classYearID) {
			removeFromSchoolStudentEnrollmentsOfClassYearCache(cache, initialFormValues.classYearID, updatedObject);
		}
		if (input.classYearID) {
			addToSchoolStudentEnrollmentsOfClassYearCache(cache, input.classYearID, updatedObject);
		}

		if (initialFormValues.studentID && input.studentID) {
			removeFromSchoolStudentEnrollmentsOfStudentCache(cache, initialFormValues.studentID, updatedObject);
		}
		if (input.studentID) {
			addToSchoolStudentEnrollmentsOfStudentCache(cache, input.studentID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: SchoolStudentEnrollment["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<SchoolStudentEnrollmentDeleteMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (!result.data?.deleteSchoolStudentEnrollment) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.SchoolStudentEnrollment }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "schoolStudentEnrollmentConnection" });
		cache.gc();
	};
}

function addToSchoolStudentEnrollmentsOfAcademicYearCache(
	cache: ApolloCache<any>,
	targetID: AcademicYear["id"],
	object: Pick<SchoolStudentEnrollment, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.AcademicYear }),
		fields: {
			schoolStudentEnrollments: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromSchoolStudentEnrollmentsOfAcademicYearCache(
	cache: ApolloCache<any>,
	targetID: AcademicYear["id"],
	object: Pick<SchoolStudentEnrollment, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.AcademicYear }),
		fields: {
			schoolStudentEnrollments: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToSchoolStudentEnrollmentsOfClassYearCache(
	cache: ApolloCache<any>,
	targetID: ClassYear["id"],
	object: Pick<SchoolStudentEnrollment, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ClassYear }),
		fields: {
			schoolStudentEnrollments: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromSchoolStudentEnrollmentsOfClassYearCache(
	cache: ApolloCache<any>,
	targetID: ClassYear["id"],
	object: Pick<SchoolStudentEnrollment, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ClassYear }),
		fields: {
			schoolStudentEnrollments: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToSchoolStudentEnrollmentsOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<SchoolStudentEnrollment, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			schoolStudentEnrollments: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromSchoolStudentEnrollmentsOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<SchoolStudentEnrollment, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			schoolStudentEnrollments: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<SchoolStudentEnrollment, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment SchoolStudentEnrollmentRef on SchoolStudentEnrollment {
				id
			}
		`,
		data: object,
	});
}
