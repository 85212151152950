import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import { ExternalLink, InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	EnrollmentApplicationStudentFilterFormController,
	EnrollmentApplicationStudentFilterFormState,
	EnrollmentApplicationStudentFilterFormUtils,
	EnrollmentApplicationStudentFormat,
	EnrollmentApplicationStudentIndexQuery,
	EnrollmentApplicationStudentLink,
	EnrollmentApplicationStudentOrderField,
	EnrollmentApplicationStudentOrderFormController,
	EnrollmentApplicationStudentOrderFormState,
	EnrollmentApplicationStudentOrderFormValues,
	EnrollmentApplicationStudentPaginationForm,
	EnrollmentApplicationStudentSearchForm,
	useEnrollmentApplicationStudentIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { enrollmentApplicationStudentHubCreatePageInfo } from "../CreatePage/pageinfo";
import { enrollmentApplicationStudentHubIndexPageInfo } from "./pageinfo";

const columns: (keyof EnrollmentApplicationStudentIndexQuery["enrollmentApplicationStudentConnection"]["edges"][0]["node"])[] =
	[
		"name",
		"imageURL",
		"firstName",
		"lastName",
		"nickname",
		"dateOfBirth",
		"gender",
		"genderCustom",
		"nationality",
		"primaryLanguage",
		"englishLanguageFluency",
		"currentGradeLevel",
		"gradeLevelApplyingFor",
		"hasPreviousSchooling",
		"previousSchoolInformation",
		"previousSchoolLocation",
		"hasLearningDifficulties",
		"learningDifficultiesDescription",
		"hasMedicalPhysicalExceptionalities",
		"medicalPhysicalExceptionalitiesDescription",
		"hasBehaviorDisciplineChallenges",
		"behaviorDisciplineChallengesDescription",
		"additionalInformation",
	];

function orderFieldToColumn(field: EnrollmentApplicationStudentOrderField | null) {
	if (field === null) {
		return "id";
	}
	return EnrollmentApplicationStudentOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return EnrollmentApplicationStudentOrderFormValues.modelFieldToOrderField[column];
}

export function EnrollmentApplicationStudentHubIndexPage() {
	useActivePageRegistration(enrollmentApplicationStudentHubIndexPageInfo);

	const filterFormState = EnrollmentApplicationStudentFilterFormState.useFormState();
	const orderFormState = EnrollmentApplicationStudentOrderFormState.useFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(
		filterFormState.formValues,
		EnrollmentApplicationStudentFilterFormUtils.toFilterInputs,
	);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useEnrollmentApplicationStudentIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		EnrollmentApplicationStudentFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = EnrollmentApplicationStudentFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) -
			(data?.enrollmentApplicationStudentConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading.H1 noMargin>Enrollment Application Students</Heading.H1>

				<InternalLink to={enrollmentApplicationStudentHubCreatePageInfo.to}>
					New Enrollment Application Student
				</InternalLink>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<EnrollmentApplicationStudentSearchForm formState={filterFormState} />
				<EnrollmentApplicationStudentFilterFormController
					formState={filterFormState}
					numActiveFilters={numActiveFilters}
				/>
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<EnrollmentApplicationStudentOrderFormController formState={orderFormState} />
			</Row>

			<>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<If condition={!!error}>Something went wrong.</If>
					<If condition={data?.enrollmentApplicationStudentConnection.edges.length === 0}>No results found.</If>
					<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
				</Conditional>
			</>

			<Table.Container className="hub__index-page__table__container">
				<Table
					columns={columns}
					minColumnWidth="10rem"
					scrollSyncID={paginationFormState.formValues.cursor}
					{...tableSortingProps}
					className="hub__index-page__table"
				>
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="name" noSort>
								Name
							</Table.Heading>
							<Table.Heading column="imageURL" noSort>
								Image
							</Table.Heading>
							<Table.Heading column="firstName">First Name</Table.Heading>
							<Table.Heading column="lastName">Last Name</Table.Heading>
							<Table.Heading column="nickname">Nickname</Table.Heading>
							<Table.Heading column="dateOfBirth">Date of Birth</Table.Heading>
							<Table.Heading column="gender">Gender</Table.Heading>
							<Table.Heading column="genderCustom">Gender Custom</Table.Heading>
							<Table.Heading column="nationality">Nationality</Table.Heading>
							<Table.Heading column="primaryLanguage">Primary Language</Table.Heading>
							<Table.Heading column="englishLanguageFluency">English Language Fluency</Table.Heading>
							<Table.Heading column="currentGradeLevel">Current Grade Level</Table.Heading>
							<Table.Heading column="gradeLevelApplyingFor">Grade Level Applying For</Table.Heading>
							<Table.Heading column="hasPreviousSchooling">Has Previous Schooling</Table.Heading>
							<Table.Heading column="previousSchoolInformation" noSort>
								Previous School Information
							</Table.Heading>
							<Table.Heading column="previousSchoolLocation" noSort>
								Previous School Location
							</Table.Heading>
							<Table.Heading column="hasLearningDifficulties">Has Learning Difficulties</Table.Heading>
							<Table.Heading column="learningDifficultiesDescription" noSort>
								Learning Difficulties Description
							</Table.Heading>
							<Table.Heading column="hasMedicalPhysicalExceptionalities">
								Has Medical Physical Exceptionalities
							</Table.Heading>
							<Table.Heading column="medicalPhysicalExceptionalitiesDescription" noSort>
								Medical Physical Exceptionalities Description
							</Table.Heading>
							<Table.Heading column="hasBehaviorDisciplineChallenges">Has Behavior Discipline Challenges</Table.Heading>
							<Table.Heading column="behaviorDisciplineChallengesDescription" noSort>
								Behavior Discipline Challenges Description
							</Table.Heading>
							<Table.Heading column="additionalInformation" noSort>
								Additional Information
							</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.enrollmentApplicationStudentConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="name" oneLine>
											<EnrollmentApplicationStudentLink instance={e.node}>
												{EnrollmentApplicationStudentFormat.Fields.name(e.node.name)}
											</EnrollmentApplicationStudentLink>
										</Table.Cell>
										<Table.Cell column="imageURL" oneLine>
											<ExternalLink href={e.node.imageURL}>Image</ExternalLink>
										</Table.Cell>
										<Table.Cell column="firstName" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.firstName(e.node.firstName)}
										</Table.Cell>
										<Table.Cell column="lastName" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.lastName(e.node.lastName)}
										</Table.Cell>
										<Table.Cell column="nickname" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.nickname(e.node.nickname)}
										</Table.Cell>
										<Table.Cell column="dateOfBirth" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.dateOfBirth(e.node.dateOfBirth)}
										</Table.Cell>
										<Table.Cell column="gender" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.gender(e.node.gender)}
										</Table.Cell>
										<Table.Cell column="genderCustom" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.genderCustom(e.node.genderCustom)}
										</Table.Cell>
										<Table.Cell column="nationality" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.nationality(e.node.nationality)}
										</Table.Cell>
										<Table.Cell column="primaryLanguage" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.primaryLanguage(e.node.primaryLanguage)}
										</Table.Cell>
										<Table.Cell column="englishLanguageFluency" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.englishLanguageFluency(e.node.englishLanguageFluency)}
										</Table.Cell>
										<Table.Cell column="currentGradeLevel" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.currentGradeLevel(e.node.currentGradeLevel)}
										</Table.Cell>
										<Table.Cell column="gradeLevelApplyingFor" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.gradeLevelApplyingFor(e.node.gradeLevelApplyingFor)}
										</Table.Cell>
										<Table.Cell column="hasPreviousSchooling" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.hasPreviousSchooling(e.node.hasPreviousSchooling)}
										</Table.Cell>
										<Table.Cell column="previousSchoolInformation" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.previousSchoolInformation(
												e.node.previousSchoolInformation,
											)}
										</Table.Cell>
										<Table.Cell column="previousSchoolLocation" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.previousSchoolLocation(e.node.previousSchoolLocation)}
										</Table.Cell>
										<Table.Cell column="hasLearningDifficulties" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.hasLearningDifficulties(
												e.node.hasLearningDifficulties,
											)}
										</Table.Cell>
										<Table.Cell column="learningDifficultiesDescription" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.learningDifficultiesDescription(
												e.node.learningDifficultiesDescription,
											)}
										</Table.Cell>
										<Table.Cell column="hasMedicalPhysicalExceptionalities" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.hasMedicalPhysicalExceptionalities(
												e.node.hasMedicalPhysicalExceptionalities,
											)}
										</Table.Cell>
										<Table.Cell column="medicalPhysicalExceptionalitiesDescription" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.medicalPhysicalExceptionalitiesDescription(
												e.node.medicalPhysicalExceptionalitiesDescription,
											)}
										</Table.Cell>
										<Table.Cell column="hasBehaviorDisciplineChallenges" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.hasBehaviorDisciplineChallenges(
												e.node.hasBehaviorDisciplineChallenges,
											)}
										</Table.Cell>
										<Table.Cell column="behaviorDisciplineChallengesDescription" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.behaviorDisciplineChallengesDescription(
												e.node.behaviorDisciplineChallengesDescription,
											)}
										</Table.Cell>
										<Table.Cell column="additionalInformation" oneLine>
											{EnrollmentApplicationStudentFormat.Fields.additionalInformation(e.node.additionalInformation)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<EnrollmentApplicationStudentPaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.enrollmentApplicationStudentConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
