import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { TuitionCreateInput, TuitionDetailQuery, TuitionUpdateInput } from "../../GraphQL";
import { TuitionFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Tuition model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: TuitionFormValues.Create): TuitionCreateInput {
	const input: NullPartial<TuitionCreateInput> = { ...formValues };
	return input as TuitionCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Tuition model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<TuitionFormValues.Detail>,
	initialFormValues: TuitionFormValues.Detail,
): TuitionUpdateInput {
	const inputFormValues = omitKeys(formValues, ["academicYearTuitionIDs", "studentIDs"]);
	const input: TuitionUpdateInput = { ...inputFormValues };

	if (formValues.academicYearTuitionIDs !== undefined) {
		const { added, removed } = getArrayDiff(
			initialFormValues.academicYearTuitionIDs,
			formValues.academicYearTuitionIDs,
		);
		input.addAcademicYearTuitionIDs = added;
		input.removeAcademicYearTuitionIDs = removed;
	}

	if (formValues.studentIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.studentIDs, formValues.studentIDs);
		input.addStudentIDs = added;
		input.removeStudentIDs = removed;
	}

	return input;
}

/**
 * Converts a Tuition instance to its corresponding detail form values.
 *
 * @param tuition The instance to convert.
 */
export function toFormValues(tuition: TuitionDetailQuery["tuition"]): TuitionFormValues.Detail {
	return {
		name: tuition.name,
		initialYear: tuition.initialYear,
		academicYearTuitionIDs: tuition.academicYearTuitions.map((e) => e.id),
		studentIDs: tuition.students.map((e) => e.id),
	};
}
