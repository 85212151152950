import { Redirect, Route, useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../../Components";
import { TermSetupDetailHomeRoomSectionsCreatePage } from "./CreatePage";
import { termSetupDetailHomeRoomSectionsCreatePageInfo } from "./CreatePage/pageinfo";
import { TermSetupDetailHomeRoomSectionsDetailPage } from "./DetailPage";
import { termSetupDetailHomeRoomSectionsDetailPageInfo } from "./DetailPage/pageinfo";
import { termSetupDetailHomeRoomSectionsPageInfo } from "./pageinfo";

export function TermSetupDetailHomeRoomSectionsPage() {
	useActivePageRegistration(termSetupDetailHomeRoomSectionsPageInfo);

	const { termID } = useRouteParams<{ termID: string }>();

	return (
		<PageRouteSwitch>
			<Route exact path={termSetupDetailHomeRoomSectionsPageInfo.path}>
				<Redirect to={termSetupDetailHomeRoomSectionsCreatePageInfo.to(termID)} />
			</Route>
			<Route {...termSetupDetailHomeRoomSectionsCreatePageInfo.routeProps}>
				<TermSetupDetailHomeRoomSectionsCreatePage />
			</Route>
			<Route {...termSetupDetailHomeRoomSectionsDetailPageInfo.routeProps}>
				<TermSetupDetailHomeRoomSectionsDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
