import React from "react";
import { FieldDisplayArgs, NumberField, SelectField } from "@hex-insights/forms";
import {
	InvoiceSelect,
	InvoiceSelectQueryVariables,
	PaymentLineItemFormat,
	PaymentLineItemFormValues,
	PaymentSelect,
	useInvoiceSelectLazyQuery,
	usePaymentSelectLazyQuery,
} from "../../../../Utilities";
import { InvoiceLink, PaymentLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the PaymentLineItem model.
 */
type FieldProps<K extends keyof PaymentLineItemFormValues.Base = keyof PaymentLineItemFormValues.Base> = BaseFieldProps<
	Pick<PaymentLineItemFormValues.Base, K>
>;

/**
 * Renders a field component for the `amount` field of the PaymentLineItem model.
 */
export function Amount({ formState }: FieldProps<"amount">) {
	return (
		<NumberField
			formState={formState}
			name="amount"
			validationUnit={1}
			min={0}
			unit={100}
			format={PaymentLineItemFormat.Fields.amount}
		/>
	);
}

export type InvoiceProps = FieldProps<"invoiceID"> & {
	currentInvoice?: InvoiceSelect.ModelForOption | null;
	queryVariables?: InvoiceSelectQueryVariables;
};

/**
 * Renders a field component for the `invoice` edge of the PaymentLineItem model.
 */
export function Invoice({ formState, currentInvoice, queryVariables }: InvoiceProps) {
	const [loadOptions, { loading, data }] = useInvoiceSelectLazyQuery({ variables: queryVariables });
	React.useEffect(() => {
		if (formState.formEditing.invoiceID) {
			loadOptions();
		}
	}, [formState.formEditing.invoiceID, loadOptions]);
	const options = React.useMemo(
		() => InvoiceSelect.toOptions(data?.invoiceConnection.edges, currentInvoice),
		[data, currentInvoice],
	);

	return (
		<SelectField
			formState={formState}
			name="invoiceID"
			isLoading={loading}
			options={options}
			display={displayInvoice}
			blankValue={null}
		/>
	);
}

function displayInvoice({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <InvoiceLink instance={{ id }}>{formattedValue}</InvoiceLink>;
}

export type PaymentProps = FieldProps<"paymentID"> & {
	currentPayment?: PaymentSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `payment` edge of the PaymentLineItem model.
 */
export function Payment({ formState, currentPayment }: PaymentProps) {
	const [loadOptions, { loading, data }] = usePaymentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.paymentID) {
			loadOptions();
		}
	}, [formState.formEditing.paymentID, loadOptions]);
	const options = React.useMemo(
		() => PaymentSelect.toOptions(data?.paymentConnection.edges, currentPayment),
		[data, currentPayment],
	);

	return (
		<SelectField
			formState={formState}
			name="paymentID"
			isLoading={loading}
			options={options}
			display={displayPayment}
			blankValue={null}
		/>
	);
}

function displayPayment({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <PaymentLink instance={{ id }}>{formattedValue}</PaymentLink>;
}
