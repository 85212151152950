import { newPageInfo } from "@hex-insights/router";
import { quotationHubPageInfo } from "../pageinfo";

export const quotationHubCreatePageInfo = newPageInfo({
	name: "New Quotation",
	title: "New",
	description: "Create a new Quotation record.",
	exact: true,
	to: quotationHubPageInfo.to + "/new",
	path: quotationHubPageInfo.path + "/new",
	parent: quotationHubPageInfo,
});
