import { useActivePageRegistration } from "@hex-insights/router";
import { Main } from "@hex-insights/verita.shared";
import { FinancialsDashboard } from "../../../Components";
import { dashboardsFinancialsPageInfo } from "./pageinfo";

export function DashboardsFinancialsPage() {
	useActivePageRegistration(dashboardsFinancialsPageInfo);

	return (
		<Main>
			<FinancialsDashboard />
		</Main>
	);
}
