import { newPageInfo } from "@hex-insights/router";
import { termSetupPageInfo } from "../pageinfo";

export const termSetupCreatePageInfo = newPageInfo({
	title: "New Term",
	description: "Create a new term.",
	exact: true,
	noSearch: true,
	to: termSetupPageInfo.to + "/new",
	path: termSetupPageInfo.path + "/new",
	parent: termSetupPageInfo,
});
