import React from "react";
import { ApolloCache, FetchResult } from "@apollo/client";
import {
	graphTypeNames,
	Quotation,
	QuotationCreateInput,
	QuotationCreateMutation,
	QuotationDeleteMutation,
	QuotationDetailDocument,
	QuotationDetailQuery,
	QuotationDetailQueryVariables,
	QuotationUpdateInput,
	QuotationUpdateMutation,
	useQuotationCreateMutation,
	useQuotationDeleteMutation,
	useQuotationUpdateMutation,
} from "../../GraphQL";
import { QuotationFormConversion } from "../ModelFormConversion";
import { QuotationFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the Quotation model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Quotation model.
 */
export function useCreate() {
	const [createQuotation] = useQuotationCreateMutation();

	return React.useCallback(
		async (formValues: QuotationFormValues.Create) => {
			const input = QuotationFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createQuotation({ variables: { input }, update: updateCache });

			return { data: data?.createQuotation ?? null, errors: errors ?? null };
		},
		[createQuotation],
	);
}

/**
 * Returns an `update` function for the Quotation model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Quotation model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Quotation["id"]) {
	const [updateQuotation] = useQuotationUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<QuotationFormValues.Detail>, initialFormValues: QuotationFormValues.Detail) => {
			const input = QuotationFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateQuotation({ variables: { id, input }, update: updateCache });

			return { data: data?.updateQuotation ?? null, errors: errors ?? null };
		},
		[updateQuotation, id],
	);
}

/**
 * Returns a `del` function for the Quotation model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Quotation["id"]) {
	const [deleteQuotation] = useQuotationDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteQuotation({ variables: { id }, update: updateCache });

		return { data: data?.deleteQuotation ?? false, errors: errors ?? null };
	}, [deleteQuotation, id]);
}

function getUpdateCacheForCreate(_input: QuotationCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<QuotationCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createQuotation;

		cache.writeQuery<QuotationDetailQuery, QuotationDetailQueryVariables>({
			query: QuotationDetailDocument,
			data: { quotation: createdObject },
			variables: { id: createdObject.id },
		});

		cache.evict({ id: "ROOT_QUERY", fieldName: "quotationConnection" });
	};
}

function getUpdateCacheForUpdate(_input: QuotationUpdateInput, _initialFormValues: QuotationFormValues.Detail) {
	return (
		_cache: ApolloCache<any>,
		result: Omit<FetchResult<QuotationUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}
	};
}

function getUpdateCacheForDelete(id: Quotation["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<QuotationDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteQuotation) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Quotation }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "quotationConnection" });
		cache.gc();
	};
}

// function toCacheRef(cache: ApolloCache<any>, object: Pick<Quotation, "id">) {
//     return cache.writeFragment({
//         fragment: gql`
//             fragment QuotationRef on Quotation {
//                 id
//             }
//         `,
//         data: object,
//     });
// }
