import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	ContractFilterFormController,
	ContractFilterFormState,
	ContractFilterFormUtils,
	ContractFormat,
	ContractIndexQuery,
	ContractOrderField,
	ContractOrderFormController,
	ContractOrderFormState,
	ContractOrderFormValues,
	ContractPaginationForm,
	useContractIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { contractHubCreatePageInfo } from "../CreatePage/pageinfo";
import { contractHubDetailPageInfo } from "../DetailPage/pageinfo";
import { contractHubIndexPageInfo } from "./pageinfo";

const columns: (keyof ContractIndexQuery["contractConnection"]["edges"][0]["node"])[] = [
	"id",
	"student",
	"agreementDate",
	"status",
];

function orderFieldToColumn(field: ContractOrderField | null) {
	if (field === null) {
		return "id";
	}
	return ContractOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return ContractOrderFormValues.modelFieldToOrderField[column];
}

export function ContractHubIndexPage() {
	useActivePageRegistration(contractHubIndexPageInfo);

	const filterFormState = ContractFilterFormState.useFormState();
	const orderFormState = ContractOrderFormState.useFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(filterFormState.formValues, ContractFilterFormUtils.toFilterInputs);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useContractIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		ContractFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = ContractFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.contractConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading level={1} noMargin>
					Contracts
				</Heading>

				<InternalLink to={contractHubCreatePageInfo.to}>New Contract</InternalLink>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<ContractFilterFormController formState={filterFormState} numActiveFilters={numActiveFilters} />
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<ContractOrderFormController formState={orderFormState} />
			</Row>

			<Table.Container className="hub__index-page__table__container">
				<Table columns={columns} minColumnWidth="10rem" {...tableSortingProps} className="hub__index-page__table">
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="id">ID</Table.Heading>
							<Table.Heading column="student">Student</Table.Heading>
							<Table.Heading column="agreementDate">Agreement Date</Table.Heading>
							<Table.Heading column="status">Is Active</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.contractConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="id" oneLine numeric>
											<InternalLink to={contractHubDetailPageInfo.to(e.node.id)}>{e.node.id}</InternalLink>
										</Table.Cell>
										<Table.Cell column="student" oneLine>
											{e.node.student.person.name}
										</Table.Cell>
										<Table.Cell column="agreementDate" oneLine>
											{ContractFormat.Fields.agreementDate(e.node.agreementDate)}
										</Table.Cell>
										<Table.Cell column="status" oneLine>
											{ContractFormat.Fields.status(e.node.status)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<ContractPaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.contractConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
