import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	PermissionForm,
	PermissionFormValues,
	PermissionMutation,
	usePermissionDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { permissionHubDetailPageInfo } from "./pageinfo";

function PermissionHubDetailPageWrapper() {
	// Manages identity of the PermissionHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <PermissionHubDetailPage key={id} />;
}

export { PermissionHubDetailPageWrapper as PermissionHubDetailPage };

function PermissionHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = usePermissionDetailQuery({ variables: { id } });
	const permission = data?.permission;
	useActivePageRegistration(
		permissionHubDetailPageInfo,
		!!permission ? permissionHubDetailPageInfo.title(permission) : "",
	);

	const update = PermissionMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<PermissionFormValues.Detail>, initialFormValues: PermissionFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading permission</If>
				<Else>
					{!!permission && (
						<React.Fragment>
							<Heading level={1}>{permission.name}</Heading>

							<PermissionForm.ControlledDetail
								key={syncID}
								permission={permission}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
