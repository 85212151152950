import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { PhoneNumberOrderFormValues } from "../../../../Utilities";

export type PhoneNumberOrderFormControllerProps = {
	formState: FormState<PhoneNumberOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the PhoneNumber model.
 */
export function PhoneNumberOrderFormController({ formState }: PhoneNumberOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={PhoneNumberOrderFormValues.fieldOptions}
			fieldTypeMapping={PhoneNumberOrderFormValues.fieldTypeMapping}
		/>
	);
}
