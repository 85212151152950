import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import {
	Button,
	ButtonProps,
	CardinalLocation,
	Conditional,
	Else,
	If,
	Tooltip,
	useClickOutListener,
} from "@hex-insights/core";
import { DateTimeField, FormState } from "@hex-insights/forms";
import { SchoolStudentEnrollmentFilterFormValues, SchoolStudentEnrollmentFormat } from "../../../../Utilities";
import styles from "./styles.module.css";

export type SchoolStudentEnrollmentSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the SchoolStudentEnrollment model.
 */
export function SchoolStudentEnrollmentSearchForm(props: SchoolStudentEnrollmentSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type SchoolStudentEnrollmentFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, SchoolStudentEnrollmentFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the SchoolStudentEnrollment model.
 */
export function SchoolStudentEnrollmentFilterFormController(props: SchoolStudentEnrollmentFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={SchoolStudentEnrollmentFilterFormValues.advancedFilterInformation}
		/>
	);
}

export type SchoolStudentEnrollmentPaidStatusFilterButtonsProps = {
	formState: FormState<Pick<SchoolStudentEnrollmentFilterFormValues.FormValues, "hasPaid" | "hasNotPaid">>;
	buttonProps?: Omit<ButtonProps, "variant" | "onClick" | "children">;
};

export function SchoolStudentEnrollmentPaidStatusFilterButtons({
	formState,
	buttonProps,
}: SchoolStudentEnrollmentPaidStatusFilterButtonsProps) {
	return (
		<React.Fragment>
			<Button
				{...buttonProps}
				variant={formState.formValues.hasPaid ? "primary" : "tertiary"}
				onClick={() => formState.formSetFunctions.hasPaid((prev) => !prev)}
			>
				Paid
			</Button>
			<Button
				{...buttonProps}
				variant={formState.formValues.hasNotPaid ? "primary" : "tertiary"}
				onClick={() => formState.formSetFunctions.hasNotPaid((prev) => !prev)}
			>
				Not Paid
			</Button>
		</React.Fragment>
	);
}

export type AcademicYearFilterButtonsProps = {
	formState: FormState<Pick<SchoolStudentEnrollmentFilterFormValues.FormValues, "is20232024">>;
	buttonProps?: Omit<ButtonProps, "variant" | "onClick" | "children">;
};

export function AcademicYearFilterButtons({ formState, buttonProps }: AcademicYearFilterButtonsProps) {
	return (
		<React.Fragment>
			<Button
				{...buttonProps}
				variant={formState.formValues.is20232024 ? "primary" : "tertiary"}
				onClick={() => formState.formSetFunctions.is20232024((prev) => !prev)}
			>
				2023-2024
			</Button>
		</React.Fragment>
	);
}

const quickFilterTooltipSideOrder: CardinalLocation[] = ["bottom", "top", "right", "left"];

export type SchoolStudentEnrollmentCreatedAfterFilterButtonProps = {
	formState: FormState<Pick<SchoolStudentEnrollmentFilterFormValues.FormValues, "createdDateGTE">>;
	buttonProps?: Omit<ButtonProps, "variant" | "onClick" | "children">;
};

export function SchoolStudentEnrollmentCreatedAfterFilterButton({
	formState,
	buttonProps,
}: SchoolStudentEnrollmentCreatedAfterFilterButtonProps) {
	// TODO hex-core update replace when quick filter component added to core

	const {
		formValues: { createdDateGTE },
	} = formState;

	const hasValue = !(createdDateGTE === null || createdDateGTE === "");

	const { isTooltipOpen, toggleIsTooltipOpen, closeTooltip } = Tooltip.useToggle();
	const ref = React.useRef<HTMLDivElement | null>(null);
	useClickOutListener(ref.current, closeTooltip);

	React.useEffect(() => {
		if (isTooltipOpen && ref.current) {
			ref.current.querySelector("input")?.focus();
		}
	}, [isTooltipOpen]);

	return (
		<div ref={ref}>
			<Tooltip.Container isControlled isOpen={isTooltipOpen} sideOrder={quickFilterTooltipSideOrder}>
				<Button {...buttonProps} variant={hasValue ? "primary" : "tertiary"} onClick={toggleIsTooltipOpen}>
					<Conditional>
						<If condition={hasValue}>Since: {SchoolStudentEnrollmentFormat.Fields.createdDate(createdDateGTE)}</If>
						<Else>Created Since</Else>
					</Conditional>
				</Button>

				<Tooltip isInteractable className={styles["quick-filter__tooltip"]}>
					<Tooltip.Body>
						<DateTimeField formState={formState} name="createdDateGTE" noHeader />
					</Tooltip.Body>
				</Tooltip>
			</Tooltip.Container>
		</div>
	);
}

export type SchoolStudentEnrollmentPaymentDueBeforeFilterButtonProps = {
	formState: FormState<Pick<SchoolStudentEnrollmentFilterFormValues.FormValues, "paymentDueDateLTE">>;
	buttonProps?: Omit<ButtonProps, "variant" | "onClick" | "children">;
};

export function SchoolStudentEnrollmentPaymentDueBeforeFilterButton({
	formState,
	buttonProps,
}: SchoolStudentEnrollmentPaymentDueBeforeFilterButtonProps) {
	// TODO hex-core update replace when quick filter component added to core

	const {
		formValues: { paymentDueDateLTE },
	} = formState;

	const hasValue = !(paymentDueDateLTE === null || paymentDueDateLTE === "");

	const { isTooltipOpen, toggleIsTooltipOpen, closeTooltip } = Tooltip.useToggle();
	const ref = React.useRef<HTMLDivElement | null>(null);
	useClickOutListener(ref.current, closeTooltip);

	React.useEffect(() => {
		if (isTooltipOpen && ref.current) {
			ref.current.querySelector("input")?.focus();
		}
	}, [isTooltipOpen]);

	return (
		<div ref={ref}>
			<Tooltip.Container isControlled isOpen={isTooltipOpen} sideOrder={quickFilterTooltipSideOrder}>
				<Button {...buttonProps} variant={hasValue ? "primary" : "tertiary"} onClick={toggleIsTooltipOpen}>
					<Conditional>
						<If condition={hasValue}>
							Due By: {SchoolStudentEnrollmentFormat.Fields.paymentDueDate(paymentDueDateLTE)}
						</If>
						<Else>Payment Due</Else>
					</Conditional>
				</Button>

				<Tooltip isInteractable className={styles["quick-filter__tooltip"]}>
					<Tooltip.Body>
						<DateTimeField formState={formState} name="paymentDueDateLTE" noHeader />
					</Tooltip.Body>
				</Tooltip>
			</Tooltip.Container>
		</div>
	);
}
