import { newPageInfo } from "@hex-insights/router";
import { calendarEventHubPageInfo } from "../pageinfo";

export const calendarEventHubIndexPageInfo = newPageInfo({
	name: "Calendar Events Index",
	title: "",
	description: "Index of Calendar Events.",
	exact: true,
	to: calendarEventHubPageInfo.to,
	path: calendarEventHubPageInfo.path,
	parent: calendarEventHubPageInfo,
});
