import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { QuotationOrderFormValues } from "../../../../Utilities";

export type QuotationOrderFormControllerProps = {
	formState: FormState<QuotationOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the Quotation model.
 */
export function QuotationOrderFormController({ formState }: QuotationOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={QuotationOrderFormValues.fieldOptions}
			fieldTypeMapping={QuotationOrderFormValues.fieldTypeMapping}
		/>
	);
}
