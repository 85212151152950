import { IndexForms } from "@hex-insights/app-modules";
import { MFAFactorOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the MFAFactor model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		MFAFactorOrderFormValues.initial,
		MFAFactorOrderFormValues.fieldValues,
	);
}
