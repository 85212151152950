import { newPageInfo } from "@hex-insights/router";
import { paymentHubPageInfo } from "../pageinfo";

export const paymentHubIndexPageInfo = newPageInfo({
	name: "Payments Index",
	title: "",
	description: "Index of Payments.",
	exact: true,
	to: paymentHubPageInfo.to,
	path: paymentHubPageInfo.path,
	parent: paymentHubPageInfo,
});
