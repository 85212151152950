import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { HomeRoomSectionOrderFormValues } from "../../../../Utilities";

export type HomeRoomSectionOrderFormControllerProps = {
	formState: FormState<HomeRoomSectionOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the HomeRoomSection model.
 */
export function HomeRoomSectionOrderFormController({ formState }: HomeRoomSectionOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={HomeRoomSectionOrderFormValues.fieldOptions}
			fieldTypeMapping={HomeRoomSectionOrderFormValues.fieldTypeMapping}
		/>
	);
}
