import { Column, Heading, Row } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Main } from "@hex-insights/verita.shared";
import { busListDropoffPageInfo } from "../Dropoff/pageinfo";
import { busListPickupPageInfo } from "../Pickup/pageinfo";
import { busListIndexPageInfo } from "./pageinfo";
import styles from "./styles.module.css";

export function BusListIndexPage() {
	useActivePageRegistration(busListIndexPageInfo);

	return (
		<Main>
			<Row
				justify="center"
				align="center"
				style={{
					height: "calc(100vh - var(--header---height) - 2 * var(--main---padding-top))",
				}}
			>
				<Column justify="spaced-start" align="center" style={{ width: "100%", marginBottom: "10%" }}>
					<Heading level={1} noMargin style={{ fontSize: "4rem" }}>
						Bus Lists
					</Heading>

					<Row justify="spaced-center" align="center" style={{ width: "100%" }}>
						<InternalLink to={busListPickupPageInfo.to} className={styles["link"]}>
							<Row justify="center" align="center" style={{ width: "100%", height: "100%" }}>
								<span>Pickup</span>
							</Row>
						</InternalLink>
						<InternalLink to={busListDropoffPageInfo.to} className={styles["link"]}>
							<Row justify="center" align="center" style={{ width: "100%", height: "100%" }}>
								<span>Dropoff</span>
							</Row>
						</InternalLink>
					</Row>
				</Column>
			</Row>
		</Main>
	);
}
