import { NullPartial, omitKeys } from "@hex-insights/core";
import { NoteCommentCreateInput, NoteCommentDetailQuery, NoteCommentUpdateInput } from "../../GraphQL";
import { NoteCommentFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the NoteComment model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: NoteCommentFormValues.Create): NoteCommentCreateInput {
	const input: NullPartial<NoteCommentCreateInput> = { ...formValues };
	return input as NoteCommentCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the NoteComment model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<NoteCommentFormValues.Detail>,
	_initialFormValues: NoteCommentFormValues.Detail,
): NoteCommentUpdateInput {
	const inputFormValues = omitKeys(formValues, ["createdAt", "authorID", "noteID"]);
	const input: NoteCommentUpdateInput = { ...inputFormValues };
	return input;
}

/**
 * Converts a NoteComment instance to its corresponding detail form values.
 *
 * @param noteComment The instance to convert.
 */
export function toFormValues(noteComment: NoteCommentDetailQuery["noteComment"]): NoteCommentFormValues.Detail {
	return {
		body: noteComment.body,
		createdAt: noteComment.createdAt,
		authorID: noteComment.author?.id ?? null,
		noteID: noteComment.note?.id ?? null,
	};
}
