import React from "react";
import { FieldDisplayArgs, MultiSelectField, TextField } from "@hex-insights/forms";
import {
	ExpenseBudgetSelect,
	ExpenseCategoryFormValidation,
	ExpenseCategoryFormValues,
	ExpenseSelect,
	useExpenseBudgetSelectLazyQuery,
	useExpenseSelectLazyQuery,
} from "../../../../Utilities";
import { ExpenseBudgetLink, ExpenseLink } from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the ExpenseCategory model.
 */
type FieldProps<K extends keyof ExpenseCategoryFormValues.Base = keyof ExpenseCategoryFormValues.Base> = BaseFieldProps<
	Pick<ExpenseCategoryFormValues.Base, K>
>;

/**
 * Generic props for fields of the ExpenseCategory model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof ExpenseCategoryFormValues.Detail = keyof ExpenseCategoryFormValues.Detail> =
	BaseFieldProps<Pick<ExpenseCategoryFormValues.Detail, K>>;

/**
 * Renders a field component for the `name` field of the ExpenseCategory model.
 */
export function Name({ id, formState }: FieldProps<"name">) {
	const validateUnique = ExpenseCategoryFormValidation.useUniqueNameValidator(id);

	return <TextField formState={formState} name="name" scheduledValidation={validateUnique} />;
}

/**
 * Renders a field component for the `description` field of the ExpenseCategory model.
 */
export function Description({ formState }: FieldProps<"description">) {
	return <TextAreaField formState={formState} name="description" optional />;
}

export type ExpenseBudgetsProps = DetailFieldProps<"expenseBudgetIDs"> & {
	currentExpenseBudgets?: ExpenseBudgetSelect.ModelForOption[];
};

/**
 * Renders a field component for the `expenseBudgets` edge of the ExpenseCategory model.
 */
export function ExpenseBudgets({ formState, currentExpenseBudgets }: ExpenseBudgetsProps) {
	const [loadOptions, { loading, data }] = useExpenseBudgetSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.expenseBudgetIDs) {
			loadOptions();
		}
	}, [formState.formEditing.expenseBudgetIDs, loadOptions]);
	const options = React.useMemo(
		() => ExpenseBudgetSelect.toMultiOptions(data?.expenseBudgetConnection.edges, currentExpenseBudgets),
		[data, currentExpenseBudgets],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="expenseBudgetIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayExpenseBudgetInstance}
		/>
	);
}

function displayExpenseBudgetInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ExpenseBudgetLink instance={{ id }}>{formattedValue}</ExpenseBudgetLink>;
}

export type ExpensesProps = DetailFieldProps<"expenseIDs"> & {
	currentExpenses?: ExpenseSelect.ModelForOption[];
};

/**
 * Renders a field component for the `expenses` edge of the ExpenseCategory model.
 */
export function Expenses({ formState, currentExpenses }: ExpensesProps) {
	const [loadOptions, { loading, data }] = useExpenseSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.expenseIDs) {
			loadOptions();
		}
	}, [formState.formEditing.expenseIDs, loadOptions]);
	const options = React.useMemo(
		() => ExpenseSelect.toMultiOptions(data?.expenseConnection.edges, currentExpenses),
		[data, currentExpenses],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="expenseIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayExpenseInstance}
		/>
	);
}

function displayExpenseInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ExpenseLink instance={{ id }}>{formattedValue}</ExpenseLink>;
}
