import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { TrustedDevice, TrustedDeviceFormat, TrustedDeviceNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<TrustedDevice, "id"> & TrustedDeviceNameFragment;

export type TrustedDeviceLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given TrustedDevice instance.
 */
export function TrustedDeviceLink(props: TrustedDeviceLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="TrustedDevice" format={TrustedDeviceFormat.name} {...props} />;
}
