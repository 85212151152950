import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, If, Row } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
	SubmitButton,
} from "@hex-insights/forms";
import {
	NoteReactionDetailQuery,
	NoteReactionFormConversion,
	NoteReactionFormState,
	NoteReactionFormValues,
} from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<NoteReactionFormValues.Create>;
	onSuccess: () => void;
};

/**
 * Renders the create form of the NoteReaction model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = NoteReactionFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<NoteReactionFormValues.Create>;
};

/**
 * Renders the create form of the NoteReaction model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<NoteReactionFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="noteReaction.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Field.ReactionType formState={formState} formType={FormType.Create} />
				<Field.Note formState={formState} formType={FormType.Create} />

				<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
					Submit
				</SubmitButton>
			</Column>
		</Form>
	);
}

export type ControlledDetailProps = {
	noteReaction: NoteReactionDetailQuery["noteReaction"];
	applyUpdate: ApplyUpdateFunction<NoteReactionFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the NoteReaction model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => NoteReactionFormConversion.toFormValues(props.noteReaction),
		[props.noteReaction],
	);
	const formState = NoteReactionFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<NoteReactionFormValues.Detail>;
};

/**
 * Renders the detail form of the NoteReaction model using the given form state.
 */
export function Detail({ formState, noteReaction, applyUpdate, onSuccess }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<NoteReactionFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form
			name={`noteReaction.detail.${noteReaction.id}`}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
		>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.ReactionType formState={formState} formType={FormType.Update} id={noteReaction.id} />
				<Field.CreatedAt formState={formState} formType={FormType.Update} id={noteReaction.id} />
				<Field.Author
					formState={formState}
					formType={FormType.Update}
					id={noteReaction.id}
					currentAuthor={noteReaction.author}
				/>
				<Field.Note
					formState={formState}
					formType={FormType.Update}
					id={noteReaction.id}
					currentNote={noteReaction.note}
				/>
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	noteReaction: NoteReactionDetailQuery["noteReaction"];
};

/**
 * Renders a read-only detail form of the NoteReaction model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => NoteReactionFormConversion.toFormValues(props.noteReaction),
		[props.noteReaction],
	);
	const formState = NoteReactionFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<NoteReactionFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the NoteReaction model using the given form state.
 */
export function ReadOnly({ formState, noteReaction }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.ReactionType formState={formState} formType={FormType.Update} id={noteReaction.id} />
			<Field.CreatedAt formState={formState} formType={FormType.Update} id={noteReaction.id} />
			<Field.Author
				formState={formState}
				formType={FormType.Update}
				id={noteReaction.id}
				currentAuthor={noteReaction.author}
			/>
			<Field.Note
				formState={formState}
				formType={FormType.Update}
				id={noteReaction.id}
				currentNote={noteReaction.note}
			/>
		</Column>
	);
}
