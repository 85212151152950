import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { PaymentFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the Payment model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => PaymentFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(initialFormValues, PaymentFilterFormValues.typeFilterObject);
}
