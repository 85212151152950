import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { AddressOrderFormValues } from "../../../../Utilities";

export type AddressOrderFormControllerProps = {
	formState: FormState<AddressOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the Address model.
 */
export function AddressOrderFormController({ formState }: AddressOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={AddressOrderFormValues.fieldOptions}
			fieldTypeMapping={AddressOrderFormValues.fieldTypeMapping}
		/>
	);
}
