import { TextField } from "@hex-insights/forms";
import { QuotationFormValues } from "../../../../Utilities";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Quotation model.
 */
type FieldProps<K extends keyof QuotationFormValues.Base = keyof QuotationFormValues.Base> = BaseFieldProps<
	Pick<QuotationFormValues.Base, K>
>;

/**
 * Renders a field component for the `body` field of the Quotation model.
 */
export function Body({ formState }: FieldProps<"body">) {
	return <TextAreaField formState={formState} name="body" />;
}

/**
 * Renders a field component for the `attribution` field of the Quotation model.
 */
export function Attribution({ formState }: FieldProps<"attribution">) {
	return <TextField formState={formState} name="attribution" />;
}
