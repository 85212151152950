import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	PaymentLineItemForm,
	PaymentLineItemFormValues,
	PaymentLineItemMutation,
	usePaymentLineItemDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { paymentLineItemHubDetailPageInfo } from "./pageinfo";

function PaymentLineItemHubDetailPageWrapper() {
	// Manages identity of the PaymentLineItemHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <PaymentLineItemHubDetailPage key={id} />;
}

export { PaymentLineItemHubDetailPageWrapper as PaymentLineItemHubDetailPage };

function PaymentLineItemHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = usePaymentLineItemDetailQuery({ variables: { id } });
	const paymentLineItem = data?.paymentLineItem;
	useActivePageRegistration(
		paymentLineItemHubDetailPageInfo,
		!!paymentLineItem ? paymentLineItemHubDetailPageInfo.title(paymentLineItem) : "",
	);

	const update = PaymentLineItemMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<PaymentLineItemFormValues.Detail>,
			initialFormValues: PaymentLineItemFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading paymentLineItem</If>
				<Else>
					{!!paymentLineItem && (
						<React.Fragment>
							<Heading level={1}>{paymentLineItem.id}</Heading>

							<PaymentLineItemForm.ControlledDetail
								key={syncID}
								paymentLineItem={paymentLineItem}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
