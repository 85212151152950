import React from "react";
import { ApplyCreateFunction } from "@hex-insights/app-modules";
import { Button, getObjectValues, Heading, Modal, ModalProps, useToggle } from "@hex-insights/core";
import { DateTimeField, FieldDisplayArgs, NumberField, SelectField } from "@hex-insights/forms";
import {
	ExpenseBudgetFormat,
	ExpenseBudgetFormValues,
	ExpenseCategoryCreateMutation,
	ExpenseCategoryFormState,
	ExpenseCategoryFormValues,
	ExpenseCategoryMutation,
	ExpenseCategorySelect,
	useExpenseCategorySelectLazyQuery,
} from "../../../../Utilities";
import { ExpenseCategoryLink } from "../../../Links";
import { ExpenseCategoryForm } from "../ExpenseCategory";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the ExpenseBudget model.
 */
type FieldProps<K extends keyof ExpenseBudgetFormValues.Base = keyof ExpenseBudgetFormValues.Base> = BaseFieldProps<
	Pick<ExpenseBudgetFormValues.Base, K>
>;

/**
 * Renders a field component for the `budgetMonth` field of the ExpenseBudget model.
 */
export function BudgetMonth({ formState }: FieldProps<"budgetMonth">) {
	return <DateTimeField formState={formState} name="budgetMonth" precision="month" />;
}

/**
 * Renders a field component for the `amount` field of the ExpenseBudget model.
 */
export function Amount({ formState }: FieldProps<"amount">) {
	return (
		<NumberField
			formState={formState}
			name="amount"
			validationUnit={1}
			min={0}
			unit={100}
			format={ExpenseBudgetFormat.Fields.amount}
		/>
	);
}

export type ExpenseCategoryProps = FieldProps<"expenseCategoryID"> & {
	currentExpenseCategory?: ExpenseCategorySelect.ModelForOption | null;
	allowCreate?: boolean;
};

/**
 * Renders a field component for the `expenseCategory` edge of the ExpenseBudget model.
 */
export function ExpenseCategory({ formState, currentExpenseCategory, allowCreate = false }: ExpenseCategoryProps) {
	const [loadOptions, { loading, data }] = useExpenseCategorySelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.expenseCategoryID) {
			loadOptions();
		}
	}, [formState.formEditing.expenseCategoryID, loadOptions]);
	const options = React.useMemo(
		() => ExpenseCategorySelect.toOptions(data?.expenseCategoryConnection.edges, currentExpenseCategory),
		[data, currentExpenseCategory],
	);

	const { expenseCategoryID: setExpenseCategoryID } = formState.formSetFunctions;
	const onCreate = React.useCallback(
		(expenseCategory: ExpenseCategoryCreateMutation["createExpenseCategory"]) => {
			setExpenseCategoryID(expenseCategory.id);
		},
		[setExpenseCategoryID],
	);

	return (
		<SelectField
			formState={formState}
			name="expenseCategoryID"
			isLoading={loading}
			options={options}
			display={displayExpenseCategory}
			blankValue={null}
			footerElement={allowCreate ? <CreateExpenseCategory onCreate={onCreate} /> : undefined}
		/>
	);
}

function displayExpenseCategory({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ExpenseCategoryLink instance={{ id }}>{formattedValue}</ExpenseCategoryLink>;
}

export type CreateExpenseCategoryProps = {
	onCreate?: (data: ExpenseCategoryCreateMutation["createExpenseCategory"]) => void;
};

export function CreateExpenseCategory({ onCreate }: CreateExpenseCategoryProps) {
	const { isOn: isOpen, toggle: toggleIsOpen, setIsOn: setIsOpen } = useToggle(false);

	const create = ExpenseCategoryMutation.useCreate();

	const addToCache = ExpenseCategorySelect.useAddToCache();
	const applyCreate = React.useCallback(
		async (formValues: ExpenseCategoryFormValues.Create) => {
			const { data, errors } = await create(formValues);
			if (data !== null) {
				addToCache(data);
				if (onCreate) {
					onCreate(data);
				}
			}
			return errors;
		},
		[create, onCreate, addToCache],
	);

	const onSuccess = React.useCallback(() => setIsOpen(false), [setIsOpen]);

	return (
		<React.Fragment>
			<Button variant="link" size="small" onClick={toggleIsOpen}>
				Create New Expense Category
			</Button>

			<Modal.If condition={isOpen}>
				<ExpenseCategoryCreateModal onClose={toggleIsOpen} applyCreate={applyCreate} onSuccess={onSuccess} />
			</Modal.If>
		</React.Fragment>
	);
}

type ExpenseCategoryCreateModalProps = {
	applyCreate: ApplyCreateFunction<ExpenseCategoryFormValues.Create>;
	onSuccess: () => void;
} & Pick<ModalProps, "ifRef" | "onClose">;

function ExpenseCategoryCreateModal({ applyCreate, onSuccess, ifRef, onClose }: ExpenseCategoryCreateModalProps) {
	const formState = ExpenseCategoryFormState.useCreateFormState();

	const anyFieldsChanged = React.useMemo(() => {
		return getObjectValues(formState.formChanged).some((e) => e);
	}, [formState.formChanged]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose={anyFieldsChanged}>
			<Modal.Header>
				<Heading level={2} noMargin>
					New Expense Category
				</Heading>
			</Modal.Header>

			<Modal.Body>
				<ExpenseCategoryForm.Create formState={formState} applyCreate={applyCreate} onSuccess={onSuccess} />
			</Modal.Body>
		</Modal>
	);
}
