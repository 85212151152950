import React from "react";
import {
	CreateFunction,
	onCreateSubmit,
	onUpdateSubmit,
	UpdateFunction,
	useApplyCreate,
	useApplyUpdate,
} from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import {
	Form,
	FormEditButtonPair,
	FormState,
	FormSubmitFunction,
	SubmitButton,
	useOnSuccess,
} from "@hex-insights/forms";
import {
	TuitionCreateMutation,
	TuitionDetailQuery,
	TuitionFormConversion,
	TuitionFormState,
	TuitionFormValues,
	TuitionMutation,
	TuitionUpdateMutation,
} from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledCreateProps = Pick<
	CreateProps<TuitionCreateMutation["createTuition"]>,
	"onSuccess" | "formNameSuffix"
> & {
	formStateArgs?: Partial<TuitionFormState.UseCreateFormStateArgs>;
};

/**
 * Renders the create form of the Tuition model using an internally managed form state.
 */
export function ControlledCreate({ formStateArgs, ...props }: ControlledCreateProps) {
	const formState = TuitionFormState.useCreateFormState(formStateArgs);
	const create = TuitionMutation.useCreate();

	return <Create formState={formState} create={create} {...props} />;
}

export type CreateProps<T> = {
	formState: FormState<TuitionFormValues.Create>;
	create: CreateFunction<TuitionFormValues.Create, T>;
	onSuccess: (data: T) => void;
	formNameSuffix?: string;
};

/**
 * Renders the create form of the Tuition model using the given form state.
 */
export function Create<T>({ formState, create, onSuccess, formNameSuffix }: CreateProps<T>) {
	const { applyCreate, resultRef } = useApplyCreate(create);
	const onSubmit = React.useCallback<FormSubmitFunction<TuitionFormValues.Create>>(
		async ({ formValues }) => onCreateSubmit(formValues, applyCreate),
		[applyCreate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	const formName = "tuition.create" + (formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form name={formName} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Form.Column>
				<CreateFields formState={formState} />

				<Form.ActionBar>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Form.ActionBar>
			</Form.Column>
		</Form>
	);
}

type CreateFieldsProps = {
	formState: FormState<TuitionFormValues.Create>;
};

function CreateFields({ formState }: CreateFieldsProps) {
	return (
		<React.Fragment>
			<Field.Name formState={formState} />
			<Field.InitialYear formState={formState} />
			<Field.AcademicYearTuitions formState={formState} />
			<Field.Students formState={formState} />
		</React.Fragment>
	);
}

export type ControlledDetailProps = Pick<
	DetailProps<TuitionUpdateMutation["updateTuition"]>,
	"tuition" | "onSuccess" | "formNameSuffix"
> & {
	formStateArgs?: Partial<DistributiveOmit<TuitionFormState.UseDetailFormStateArgs, "initialFormValues">>;
};

/**
 * Renders the detail form of the Tuition model using an internally managed form state.
 */
export function ControlledDetail({ formStateArgs, ...props }: ControlledDetailProps) {
	const initialFormValues = React.useMemo(() => TuitionFormConversion.toFormValues(props.tuition), [props.tuition]);
	const formState = TuitionFormState.useDetailFormState({ ...formStateArgs, initialFormValues });
	const update = TuitionMutation.useUpdate(props.tuition.id);

	return <Detail formState={formState} update={update} {...props} />;
}

export type DetailProps<T> = {
	tuition: TuitionDetailQuery["tuition"];
	formState: FormState<TuitionFormValues.Detail>;
	update: UpdateFunction<TuitionFormValues.Detail, T>;
	onSuccess: (data: T) => void;
	formNameSuffix?: string;
};

/**
 * Renders the detail form of the Tuition model using the given form state.
 */
export function Detail<T>({ formState, tuition, update, onSuccess, formNameSuffix }: DetailProps<T>) {
	const { applyUpdate, resultRef } = useApplyUpdate(update);
	const onSubmit = React.useCallback<FormSubmitFunction<TuitionFormValues.Detail>>(
		async (formState) => onUpdateSubmit(formState, applyUpdate),
		[applyUpdate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	const formName = `tuition.detail.${tuition.id}` + (formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form name={formName} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Form.Column>
				<FormEditButtonPair formState={formState} />

				<DetailFields formState={formState} tuition={tuition} />
			</Form.Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = Pick<ReadOnlyProps, "tuition"> & {
	formStateArgs?: Partial<DistributiveOmit<TuitionFormState.UseReadOnlyFormStateArgs, "initialFormValues">>;
};

/**
 * Renders a read-only detail form of the Tuition model using an internally managed form state.
 */
export function ControlledReadOnly({ formStateArgs, ...props }: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(() => TuitionFormConversion.toFormValues(props.tuition), [props.tuition]);
	const formState = TuitionFormState.useReadOnlyFormState({ ...formStateArgs, initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = {
	tuition: TuitionDetailQuery["tuition"];
	formState: FormState<TuitionFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the Tuition model using the given form state.
 */
export function ReadOnly({ formState, tuition }: ReadOnlyProps) {
	return (
		<Form.Column>
			<DetailFields formState={formState} tuition={tuition} />
		</Form.Column>
	);
}

type DetailFieldsProps = {
	tuition: TuitionDetailQuery["tuition"];
	formState: FormState<TuitionFormValues.Detail>;
};

function DetailFields({ formState, tuition }: DetailFieldsProps) {
	return (
		<React.Fragment>
			<Field.Name formState={formState} />
			<Field.InitialYear formState={formState} />
			<Field.AcademicYearTuitions formState={formState} currentAcademicYearTuitions={tuition.academicYearTuitions} />
			<Field.Students formState={formState} currentStudents={tuition.students} />
		</React.Fragment>
	);
}
