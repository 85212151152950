import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { DocumentResourceHubCreatePage } from "./CreatePage";
import { documentResourceHubCreatePageInfo } from "./CreatePage/pageinfo";
import { DocumentResourceHubDetailPage } from "./DetailPage";
import { documentResourceHubDetailPageInfo } from "./DetailPage/pageinfo";
import { DocumentResourceHubIndexPage } from "./IndexPage";
import { documentResourceHubIndexPageInfo } from "./IndexPage/pageinfo";
import { documentResourceHubPageInfo } from "./pageinfo";

export function DocumentResourceHub() {
	useActivePageRegistration(documentResourceHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...documentResourceHubIndexPageInfo.routeProps}>
				<DocumentResourceHubIndexPage />
			</Route>
			<Route {...documentResourceHubCreatePageInfo.routeProps}>
				<DocumentResourceHubCreatePage />
			</Route>
			<Route {...documentResourceHubDetailPageInfo.routeProps}>
				<DocumentResourceHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
