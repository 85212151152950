import { MealMenu } from "../../GraphQL";

export type Base = {
	menuDate: MealMenu["menuDate"] | null;
	breakfast: MealMenu["breakfast"];
	lunch: MealMenu["lunch"];
	snack: MealMenu["snack"];
	vegetarianBreakfast: MealMenu["vegetarianBreakfast"];
	vegetarianLunch: MealMenu["vegetarianLunch"];
	vegetarianSnack: MealMenu["vegetarianSnack"];
};

export type Create = Base;

export type BulkCreate = {
	mealMenus: Create[];
};

export type Detail = Base;

export const initialCreate: Create = {
	menuDate: null,
	breakfast: "",
	lunch: "",
	snack: "",
	vegetarianBreakfast: "",
	vegetarianLunch: "",
	vegetarianSnack: "",
};

export const initialBulkCreate: BulkCreate = {
	mealMenus: [initialCreate],
};
