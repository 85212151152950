import React from "react";
import { Conditional, Else, Heading, If } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	EnrollmentApplicationUserViewRecordForm,
	useEnrollmentApplicationUserViewRecordDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { enrollmentApplicationUserViewRecordHubDetailPageInfo } from "./pageinfo";

function EnrollmentApplicationUserViewRecordHubDetailPageWrapper() {
	// Manages identity of the EnrollmentApplicationUserViewRecordHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <EnrollmentApplicationUserViewRecordHubDetailPage key={id} />;
}

export { EnrollmentApplicationUserViewRecordHubDetailPageWrapper as EnrollmentApplicationUserViewRecordHubDetailPage };

function EnrollmentApplicationUserViewRecordHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useEnrollmentApplicationUserViewRecordDetailQuery({ variables: { id } });
	const enrollmentApplicationUserViewRecord = data?.enrollmentApplicationUserViewRecord;
	useActivePageRegistration(
		enrollmentApplicationUserViewRecordHubDetailPageInfo,
		!!enrollmentApplicationUserViewRecord
			? enrollmentApplicationUserViewRecordHubDetailPageInfo.title(enrollmentApplicationUserViewRecord)
			: "",
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading enrollmentApplicationUserViewRecord</If>
				<Else>
					{!!enrollmentApplicationUserViewRecord && (
						<React.Fragment>
							<Heading level={1}>{enrollmentApplicationUserViewRecord.id}</Heading>

							<EnrollmentApplicationUserViewRecordForm.ControlledReadOnly
								enrollmentApplicationUserViewRecord={enrollmentApplicationUserViewRecord}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
