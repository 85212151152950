import { useActivePageRegistration } from "@hex-insights/router";
import { Main } from "@hex-insights/verita.shared";
import { DirectorDashboard } from "../../../Components";
import { dashboardsDirectorPageInfo } from "./pageinfo";

export function DashboardsDirectorPage() {
	useActivePageRegistration(dashboardsDirectorPageInfo);

	return (
		<Main>
			<DirectorDashboard />
		</Main>
	);
}
