import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { SchoolAttendanceRecordHubCreatePage } from "./CreatePage";
import { schoolAttendanceRecordHubCreatePageInfo } from "./CreatePage/pageinfo";
import { SchoolAttendanceRecordHubDetailPage } from "./DetailPage";
import { schoolAttendanceRecordHubDetailPageInfo } from "./DetailPage/pageinfo";
import { SchoolAttendanceRecordHubIndexPage } from "./IndexPage";
import { schoolAttendanceRecordHubIndexPageInfo } from "./IndexPage/pageinfo";
import { schoolAttendanceRecordHubPageInfo } from "./pageinfo";

export function SchoolAttendanceRecordHub() {
	useActivePageRegistration(schoolAttendanceRecordHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...schoolAttendanceRecordHubIndexPageInfo.routeProps}>
				<SchoolAttendanceRecordHubIndexPage />
			</Route>
			<Route {...schoolAttendanceRecordHubCreatePageInfo.routeProps}>
				<SchoolAttendanceRecordHubCreatePage />
			</Route>
			<Route {...schoolAttendanceRecordHubDetailPageInfo.routeProps}>
				<SchoolAttendanceRecordHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
