import React from "react";
import { FieldDisplayArgs, MultiSelectField, SelectField, TextField } from "@hex-insights/forms";
import {
	CampusSelect,
	CourseSectionPeriodSelect,
	RoomFormValues,
	useCampusSelectLazyQuery,
	useCourseSectionPeriodSelectLazyQuery,
} from "../../../../Utilities";
import { CampusLink, CourseSectionPeriodLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Room model.
 */
type FieldProps<K extends keyof RoomFormValues.Base = keyof RoomFormValues.Base> = BaseFieldProps<
	Pick<RoomFormValues.Base, K>
>;

/**
 * Generic props for fields of the Room model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof RoomFormValues.Detail = keyof RoomFormValues.Detail> = BaseFieldProps<
	Pick<RoomFormValues.Detail, K>
>;

/**
 * Renders a field component for the `name` field of the Room model.
 */
export function Name({ formState }: FieldProps<"name">) {
	return <TextField formState={formState} name="name" />;
}

/**
 * Renders a field component for the `number` field of the Room model.
 */
export function Number({ formState }: FieldProps<"number">) {
	return <TextField formState={formState} name="number" />;
}

export type CampusProps = FieldProps<"campusID"> & {
	currentCampus?: CampusSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `campus` edge of the Room model.
 */
export function Campus({ formState, currentCampus }: CampusProps) {
	const [loadOptions, { loading, data }] = useCampusSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.campusID) {
			loadOptions();
		}
	}, [formState.formEditing.campusID, loadOptions]);
	const options = React.useMemo(
		() => CampusSelect.toOptions(data?.campusConnection.edges, currentCampus),
		[data, currentCampus],
	);

	return (
		<SelectField
			formState={formState}
			name="campusID"
			isLoading={loading}
			options={options}
			display={displayCampus}
			blankValue={null}
		/>
	);
}

function displayCampus({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <CampusLink instance={{ id }}>{formattedValue}</CampusLink>;
}

export type CourseSectionPeriodsProps = DetailFieldProps<"courseSectionPeriodIDs"> & {
	currentCourseSectionPeriods?: CourseSectionPeriodSelect.ModelForOption[];
};

/**
 * Renders a field component for the `courseSectionPeriods` edge of the Room model.
 */
export function CourseSectionPeriods({ formState, currentCourseSectionPeriods }: CourseSectionPeriodsProps) {
	const [loadOptions, { loading, data }] = useCourseSectionPeriodSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.courseSectionPeriodIDs) {
			loadOptions();
		}
	}, [formState.formEditing.courseSectionPeriodIDs, loadOptions]);
	const options = React.useMemo(
		() =>
			CourseSectionPeriodSelect.toMultiOptions(data?.courseSectionPeriodConnection.edges, currentCourseSectionPeriods),
		[data, currentCourseSectionPeriods],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="courseSectionPeriodIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayCourseSectionPeriodInstance}
		/>
	);
}

function displayCourseSectionPeriodInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <CourseSectionPeriodLink instance={{ id }}>{formattedValue}</CourseSectionPeriodLink>;
}
