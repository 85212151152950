import { IndexForms } from "@hex-insights/app-modules";
import { BusPlanOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the BusPlan model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		BusPlanOrderFormValues.initial,
		BusPlanOrderFormValues.fieldValues,
	);
}
