import { IndexForms } from "@hex-insights/app-modules";
import { StudentDocumentFilterFormValues } from "../../../../Utilities";

export type StudentDocumentSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the StudentDocument model.
 */
export function StudentDocumentSearchForm(props: StudentDocumentSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type StudentDocumentFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, StudentDocumentFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the StudentDocument model.
 */
export function StudentDocumentFilterFormController(props: StudentDocumentFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={StudentDocumentFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `StudentDocumentFilterForm`
