import { useActivePageRegistration } from "@hex-insights/router";
import { Main } from "@hex-insights/verita.shared";
import { FrontDeskDashboard } from "../../../Components";
import { dashboardsFrontDeskPageInfo } from "./pageinfo";

export function DashboardsFrontDeskPage() {
	useActivePageRegistration(dashboardsFrontDeskPageInfo);

	return (
		<Main>
			<FrontDeskDashboard />
		</Main>
	);
}
