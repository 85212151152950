import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { TrustedDeviceHubDetailPage } from "./DetailPage";
import { trustedDeviceHubDetailPageInfo } from "./DetailPage/pageinfo";
import { TrustedDeviceHubIndexPage } from "./IndexPage";
import { trustedDeviceHubIndexPageInfo } from "./IndexPage/pageinfo";
import { trustedDeviceHubPageInfo } from "./pageinfo";

export function TrustedDeviceHub() {
	useActivePageRegistration(trustedDeviceHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...trustedDeviceHubIndexPageInfo.routeProps}>
				<TrustedDeviceHubIndexPage />
			</Route>
			<Route {...trustedDeviceHubDetailPageInfo.routeProps}>
				<TrustedDeviceHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
