import { IndexForms } from "@hex-insights/app-modules";
import { PermissionGroupFilterFormValues } from "../../../../Utilities";

export type PermissionGroupSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the PermissionGroup model.
 */
export function PermissionGroupSearchForm(props: PermissionGroupSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type PermissionGroupFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, PermissionGroupFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the PermissionGroup model.
 */
export function PermissionGroupFilterFormController(props: PermissionGroupFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={PermissionGroupFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `PermissionGroupFilterForm`
