import { IndexForms } from "@hex-insights/app-modules";
import { ServiceOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the Service model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		ServiceOrderFormValues.initial,
		ServiceOrderFormValues.fieldValues,
	);
}
