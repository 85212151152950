import React from "react";
import { FieldDisplayArgs, NumberField, SelectField } from "@hex-insights/forms";
import {
	AcademicYearTuitionFormat,
	AcademicYearTuitionFormValues,
	TuitionSelect,
	useTuitionSelectLazyQuery,
} from "../../../../Utilities";
import { TuitionLink } from "../../../Links";
import { FutureBaseFieldProps as BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the AcademicYearTuition model.
 */
type FieldProps<K extends keyof AcademicYearTuitionFormValues.Base = keyof AcademicYearTuitionFormValues.Base> =
	BaseFieldProps<Pick<AcademicYearTuitionFormValues.Base, K>>;

/**
 * Renders a field component for the `year` field of the AcademicYearTuition model.
 */
export function Year({ formState, ...props }: FieldProps<"year">) {
	return <NumberField formState={formState} name="year" validationUnit={1} min={0} {...props} />;
}

/**
 * Renders a field component for the `nurseryPrice` field of the AcademicYearTuition model.
 */
export function NurseryPrice({ formState, ...props }: FieldProps<"nurseryPrice">) {
	return (
		<NumberField
			formState={formState}
			name="nurseryPrice"
			validationUnit={1}
			min={0}
			unit={100}
			format={AcademicYearTuitionFormat.Fields.nurseryPrice}
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `receptionPrice` field of the AcademicYearTuition model.
 */
export function ReceptionPrice({ formState, ...props }: FieldProps<"receptionPrice">) {
	return (
		<NumberField
			formState={formState}
			name="receptionPrice"
			validationUnit={1}
			min={0}
			unit={100}
			format={AcademicYearTuitionFormat.Fields.receptionPrice}
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `primaryPrice` field of the AcademicYearTuition model.
 */
export function PrimaryPrice({ formState, ...props }: FieldProps<"primaryPrice">) {
	return (
		<NumberField
			formState={formState}
			name="primaryPrice"
			validationUnit={1}
			min={0}
			unit={100}
			format={AcademicYearTuitionFormat.Fields.primaryPrice}
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `secondaryPrice` field of the AcademicYearTuition model.
 */
export function SecondaryPrice({ formState, ...props }: FieldProps<"secondaryPrice">) {
	return (
		<NumberField
			formState={formState}
			name="secondaryPrice"
			validationUnit={1}
			min={0}
			unit={100}
			format={AcademicYearTuitionFormat.Fields.secondaryPrice}
			{...props}
		/>
	);
}

export type TuitionProps = FieldProps<"tuitionID"> & {
	currentTuition?: TuitionSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `tuition` edge of the AcademicYearTuition model.
 */
export function Tuition({ formState, currentTuition, ...props }: TuitionProps) {
	const [loadOptions, { loading, data }] = useTuitionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.tuitionID) {
			loadOptions();
		}
	}, [formState.formEditing.tuitionID, loadOptions]);
	const options = React.useMemo(
		() => TuitionSelect.toOptions(data?.tuitionConnection.edges, currentTuition),
		[data, currentTuition],
	);

	return (
		<SelectField
			formState={formState}
			name="tuitionID"
			isLoading={loading}
			options={options}
			display={displayTuition}
			blankValue={null}
			{...props}
		/>
	);
}

function displayTuition({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <TuitionLink instance={{ id }}>{formattedValue}</TuitionLink>;
}
