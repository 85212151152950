import { newPageInfo } from "@hex-insights/router";
import { Person } from "@hex-insights/verita.shared";
import { personHubPageInfo } from "../pageinfo";

type PersonForRouteTitle = Pick<Person, "name">;

export const personHubDetailPageInfo = newPageInfo({
	name: "Person Detail",
	title: (e: PersonForRouteTitle) => e.name,
	description: "Detail page for an person.",
	exact: false,
	isLeaf: true,
	to: (id: Person["id"]) => personHubPageInfo.to + `/${id}`,
	path: personHubPageInfo.path + "/:id(\\d+)",
	parent: personHubPageInfo,
});
