import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { ServiceSubscriptionFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the ServiceSubscription model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => ServiceSubscriptionFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(
		initialFormValues,
		ServiceSubscriptionFilterFormValues.typeFilterObject,
	);
}
