import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { EnrollmentApplicationParentHubCreatePage } from "./CreatePage";
import { enrollmentApplicationParentHubCreatePageInfo } from "./CreatePage/pageinfo";
import { EnrollmentApplicationParentHubDetailPage } from "./DetailPage";
import { enrollmentApplicationParentHubDetailPageInfo } from "./DetailPage/pageinfo";
import { EnrollmentApplicationParentHubIndexPage } from "./IndexPage";
import { enrollmentApplicationParentHubIndexPageInfo } from "./IndexPage/pageinfo";
import { enrollmentApplicationParentHubPageInfo } from "./pageinfo";

export function EnrollmentApplicationParentHub() {
	useActivePageRegistration(enrollmentApplicationParentHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...enrollmentApplicationParentHubIndexPageInfo.routeProps}>
				<EnrollmentApplicationParentHubIndexPage />
			</Route>
			<Route {...enrollmentApplicationParentHubCreatePageInfo.routeProps}>
				<EnrollmentApplicationParentHubCreatePage />
			</Route>
			<Route {...enrollmentApplicationParentHubDetailPageInfo.routeProps}>
				<EnrollmentApplicationParentHubDetailPage key={enrollmentApplicationParentHubDetailPageInfo.useKey()} />
			</Route>
		</PageRouteSwitch>
	);
}
