import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { BusFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the Bus model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => BusFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(initialFormValues, BusFilterFormValues.typeFilterObject);
}
