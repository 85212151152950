import { IndexForms } from "@hex-insights/app-modules";
import { RelationshipFilterFormValues } from "../../../../Utilities";

export type RelationshipFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, RelationshipFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Relationship model.
 */
export function RelationshipFilterFormController(props: RelationshipFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={RelationshipFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `RelationshipFilterForm`
