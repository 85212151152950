import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const enrollmentApplicationHubPageInfo = newPageInfo({
	title: "Enrollment Applications",
	description: "Pages related to the Enrollment Application model.",
	exact: false,
	to: hubsPageInfo.to + "/enrollment-applications",
	path: hubsPageInfo.path + "/enrollment-applications",
	parent: hubsPageInfo,
});
