import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { HomeRoomCreateInput, HomeRoomDetailQuery, HomeRoomUpdateInput } from "../../GraphQL";
import { HomeRoomFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the HomeRoom model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: HomeRoomFormValues.Create): HomeRoomCreateInput {
	const input: NullPartial<HomeRoomCreateInput> = { ...formValues };
	return input as HomeRoomCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the HomeRoom model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<HomeRoomFormValues.Detail>,
	initialFormValues: HomeRoomFormValues.Detail,
): HomeRoomUpdateInput {
	const inputFormValues = omitKeys(formValues, ["courseIDs", "homeRoomSectionIDs"]);
	const input: HomeRoomUpdateInput = { ...inputFormValues };

	if (formValues.studentCapacity === null) {
		input.clearStudentCapacity = true;
	}

	if (formValues.courseIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.courseIDs, formValues.courseIDs);
		input.addCourseIDs = added;
		input.removeCourseIDs = removed;
	}

	if (formValues.homeRoomSectionIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.homeRoomSectionIDs, formValues.homeRoomSectionIDs);
		input.addHomeRoomSectionIDs = added;
		input.removeHomeRoomSectionIDs = removed;
	}

	return input;
}

/**
 * Converts a HomeRoom instance to its corresponding detail form values.
 *
 * @param homeRoom The instance to convert.
 */
export function toFormValues(homeRoom: HomeRoomDetailQuery["homeRoom"]): HomeRoomFormValues.Detail {
	return {
		name: homeRoom.name,
		description: homeRoom.description,
		studentCapacity: homeRoom.studentCapacity,
		classYearID: homeRoom.classYear?.id ?? null,
		courseIDs: homeRoom.courses.map((e) => e.id),
		homeRoomSectionIDs: homeRoom.homeRoomSections.map((e) => e.id),
	};
}
