import { DocumentResourceUserViewRecord } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the viewedAt field of the DocumentResourceUserViewRecord model.
 */
export function viewedAt(value: DocumentResourceUserViewRecord["viewedAt"] | null): string {
	return toString(value);
}
