import { newPageInfo } from "@hex-insights/router";
import { resourcesFoldersPageInfo, ResourcesFoldersPageRouteParams } from "../pageinfo";

export type ResourcesFoldersIndexPageRouteParams = ResourcesFoldersPageRouteParams;

export const resourcesFoldersIndexPageInfo = newPageInfo({
	name: "Resources Index",
	title: "",
	description: "Index page for resources.",
	exact: true,
	to: resourcesFoldersPageInfo.to,
	path: resourcesFoldersPageInfo.path,
	parent: resourcesFoldersPageInfo,
});
