import { newPageInfo } from "@hex-insights/router";
import { mfaInvalidOTPAttemptHubPageInfo } from "../pageinfo";

export const mfaInvalidOTPAttemptHubIndexPageInfo = newPageInfo({
	name: "MFA Invalid OTP Attempts Index",
	title: "",
	description: "Index of MFA Invalid OTP Attempts.",
	exact: true,
	to: mfaInvalidOTPAttemptHubPageInfo.to,
	path: mfaInvalidOTPAttemptHubPageInfo.path,
	parent: mfaInvalidOTPAttemptHubPageInfo,
});
