import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { AcademicYearTuitionOrderFormValues } from "../../../../Utilities";

export type AcademicYearTuitionOrderFormControllerProps = {
	formState: FormState<AcademicYearTuitionOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the AcademicYearTuition model.
 */
export function AcademicYearTuitionOrderFormController({ formState }: AcademicYearTuitionOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={AcademicYearTuitionOrderFormValues.fieldOptions}
			fieldTypeMapping={AcademicYearTuitionOrderFormValues.fieldTypeMapping}
		/>
	);
}
