import { newPageInfo } from "@hex-insights/router";
import { EnrollmentApplication } from "@hex-insights/verita.shared";
import { enrollmentApplicationHubPageInfo } from "../pageinfo";

type EnrollmentApplicationForRouteTitle = Pick<EnrollmentApplication, "id">;

export const enrollmentApplicationHubDetailPageInfo = newPageInfo({
	name: "Enrollment Application Detail",
	title: (e: EnrollmentApplicationForRouteTitle) => e.id,
	description: "Detail page for an enrollmentApplication.",
	exact: false,
	isLeaf: true,
	to: (id: EnrollmentApplication["id"]) => enrollmentApplicationHubPageInfo.to + `/${id}`,
	path: enrollmentApplicationHubPageInfo.path + "/:id(\\d+)",
	parent: enrollmentApplicationHubPageInfo,
});
