import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import {
	LearningObjectiveFilterInput,
	LearningObjectiveOrder,
	useLearningObjectivePaginationQuery,
} from "../../../../Utilities";

export type LearningObjectivePaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: LearningObjectiveFilterInput | LearningObjectiveFilterInput[] | null;
	order: LearningObjectiveOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the LearningObjective model.
 */
export function LearningObjectivePaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: LearningObjectivePaginationFormProps) {
	const { loading, data } = useLearningObjectivePaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.learningObjectiveConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
