import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import {
	DocumentResourceFolderFilterInput,
	DocumentResourceFolderOrder,
	useDocumentResourceFolderPaginationQuery,
} from "../../../../Utilities";

export type DocumentResourceFolderPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: DocumentResourceFolderFilterInput | DocumentResourceFolderFilterInput[] | null;
	order: DocumentResourceFolderOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the DocumentResourceFolder model.
 */
export function DocumentResourceFolderPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: DocumentResourceFolderPaginationFormProps) {
	const { loading, data } = useDocumentResourceFolderPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.documentResourceFolderConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
