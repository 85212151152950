import { newPageInfo } from "@hex-insights/router";
import { expenseBudgetHubPageInfo } from "../pageinfo";

export const expenseBudgetHubIndexPageInfo = newPageInfo({
	name: "Expense Budgets Index",
	title: "",
	description: "Index of Expense Budgets.",
	exact: true,
	to: expenseBudgetHubPageInfo.to,
	path: expenseBudgetHubPageInfo.path,
	parent: expenseBudgetHubPageInfo,
});
