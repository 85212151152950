import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	PhoneNumberForm,
	PhoneNumberFormValues,
	PhoneNumberMutation,
	usePhoneNumberDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { phoneNumberHubDetailPageInfo } from "./pageinfo";

function PhoneNumberHubDetailPageWrapper() {
	// Manages identity of the PhoneNumberHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <PhoneNumberHubDetailPage key={id} />;
}

export { PhoneNumberHubDetailPageWrapper as PhoneNumberHubDetailPage };

function PhoneNumberHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = usePhoneNumberDetailQuery({ variables: { id } });
	const phoneNumber = data?.phoneNumber;
	useActivePageRegistration(
		phoneNumberHubDetailPageInfo,
		!!phoneNumber ? phoneNumberHubDetailPageInfo.title(phoneNumber) : "",
	);

	const update = PhoneNumberMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<PhoneNumberFormValues.Detail>,
			initialFormValues: PhoneNumberFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading phoneNumber</If>
				<Else>
					{!!phoneNumber && (
						<React.Fragment>
							<Heading level={1}>{phoneNumber.phoneNumber}</Heading>

							<PhoneNumberForm.ControlledDetail
								key={syncID}
								phoneNumber={phoneNumber}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
