import { newPageInfo } from "@hex-insights/router";
import { courseSectionHubPageInfo } from "../pageinfo";

export const courseSectionHubCreatePageInfo = newPageInfo({
	name: "New Course Section",
	title: "New",
	description: "Create a new Course Section record.",
	exact: true,
	to: courseSectionHubPageInfo.to + "/new",
	path: courseSectionHubPageInfo.path + "/new",
	parent: courseSectionHubPageInfo,
});
