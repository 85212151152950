import { AcademicYear, BillingSetupSubmission, SchoolStudentEnrollment, Term } from "../../GraphQL";

export type Base = {
	name: AcademicYear["name"];
	billingSetupSubmissionIDs: BillingSetupSubmission["id"][];
	schoolStudentEnrollmentIDs: SchoolStudentEnrollment["id"][];
	termIDs: Term["id"][];
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	name: "",
	billingSetupSubmissionIDs: [],
	schoolStudentEnrollmentIDs: [],
	termIDs: [],
};
