import { IndexForms } from "@hex-insights/app-modules";
import { EmployeeFilterFormValues } from "../../../../Utilities";

export type EmployeeSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the Employee model.
 */
export function EmployeeSearchForm(props: EmployeeSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type EmployeeFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, EmployeeFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Employee model.
 */
export function EmployeeFilterFormController(props: EmployeeFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={EmployeeFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `EmployeeFilterForm`
