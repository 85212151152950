import { newPageInfo } from "@hex-insights/router";
import { userGroupHubPageInfo } from "../pageinfo";

export const userGroupHubCreatePageInfo = newPageInfo({
	name: "New User Group",
	title: "New",
	description: "Create a new User Group record.",
	exact: true,
	to: userGroupHubPageInfo.to + "/new",
	path: userGroupHubPageInfo.path + "/new",
	parent: userGroupHubPageInfo,
});
