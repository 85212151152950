import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	EnrollmentApplicationNoteFilterFormController,
	EnrollmentApplicationNoteFilterFormState,
	EnrollmentApplicationNoteFilterFormUtils,
	EnrollmentApplicationNoteFormat,
	EnrollmentApplicationNoteIndexQuery,
	EnrollmentApplicationNoteLink,
	EnrollmentApplicationNoteOrderField,
	EnrollmentApplicationNoteOrderFormController,
	EnrollmentApplicationNoteOrderFormState,
	EnrollmentApplicationNoteOrderFormValues,
	EnrollmentApplicationNotePaginationForm,
	EnrollmentApplicationNoteSearchForm,
	useEnrollmentApplicationNoteIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { enrollmentApplicationNoteHubCreatePageInfo } from "../CreatePage/pageinfo";
import { enrollmentApplicationNoteHubIndexPageInfo } from "./pageinfo";

const columns: (keyof EnrollmentApplicationNoteIndexQuery["enrollmentApplicationNoteConnection"]["edges"][0]["node"])[] =
	["id", "createdAt", "body"];

function orderFieldToColumn(field: EnrollmentApplicationNoteOrderField | null) {
	if (field === null) {
		return "id";
	}
	return EnrollmentApplicationNoteOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return EnrollmentApplicationNoteOrderFormValues.modelFieldToOrderField[column];
}

export function EnrollmentApplicationNoteHubIndexPage() {
	useActivePageRegistration(enrollmentApplicationNoteHubIndexPageInfo);

	const filterFormState = EnrollmentApplicationNoteFilterFormState.useFormState();
	const orderFormState = EnrollmentApplicationNoteOrderFormState.useFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(
		filterFormState.formValues,
		EnrollmentApplicationNoteFilterFormUtils.toFilterInputs,
	);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useEnrollmentApplicationNoteIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		EnrollmentApplicationNoteFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = EnrollmentApplicationNoteFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) -
			(data?.enrollmentApplicationNoteConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading.H1 noMargin>Enrollment Application Notes</Heading.H1>

				<InternalLink to={enrollmentApplicationNoteHubCreatePageInfo.to}>New Enrollment Application Note</InternalLink>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<EnrollmentApplicationNoteSearchForm formState={filterFormState} />
				<EnrollmentApplicationNoteFilterFormController
					formState={filterFormState}
					numActiveFilters={numActiveFilters}
				/>
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<EnrollmentApplicationNoteOrderFormController formState={orderFormState} />
			</Row>

			<Table.Container className="hub__index-page__table__container">
				<Table
					columns={columns}
					minColumnWidth="10rem"
					scrollSyncID={paginationFormState.formValues.cursor}
					{...tableSortingProps}
					className="hub__index-page__table"
				>
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="id">ID</Table.Heading>
							<Table.Heading column="createdAt">Created At</Table.Heading>
							<Table.Heading column="body" noSort>
								Body
							</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.enrollmentApplicationNoteConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="id" oneLine numeric>
											<EnrollmentApplicationNoteLink instance={e.node}>{e.node.id}</EnrollmentApplicationNoteLink>
										</Table.Cell>
										<Table.Cell column="createdAt" oneLine>
											{EnrollmentApplicationNoteFormat.Fields.createdAt(e.node.createdAt)}
										</Table.Cell>
										<Table.Cell column="body" oneLine>
											{EnrollmentApplicationNoteFormat.Fields.body(e.node.body)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<EnrollmentApplicationNotePaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.enrollmentApplicationNoteConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
