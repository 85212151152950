import { newPageInfo } from "@hex-insights/router";
import { InvoiceLineItem } from "@hex-insights/verita.shared";
import { invoiceLineItemHubPageInfo } from "../pageinfo";

type InvoiceLineItemForRouteTitle = Pick<InvoiceLineItem, "id">;

export const invoiceLineItemHubDetailPageInfo = newPageInfo({
	name: "Invoice Line Item Detail",
	title: (e: InvoiceLineItemForRouteTitle) => e.id,
	description: "Detail page for an invoiceLineItem.",
	exact: false,
	isLeaf: true,
	to: (id: InvoiceLineItem["id"]) => invoiceLineItemHubPageInfo.to + `/${id}`,
	path: invoiceLineItemHubPageInfo.path + "/:id(\\d+)",
	parent: invoiceLineItemHubPageInfo,
});
