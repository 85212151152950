import React from "react";
import { FieldDisplayArgs, NumberField, SelectField, TextField } from "@hex-insights/forms";
import {
	InvoiceLineItemFormat,
	InvoiceLineItemFormValues,
	InvoiceSelect,
	useInvoiceSelectLazyQuery,
} from "../../../../Utilities";
import { InvoiceLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the InvoiceLineItem model.
 */
type FieldProps<K extends keyof InvoiceLineItemFormValues.Base = keyof InvoiceLineItemFormValues.Base> = BaseFieldProps<
	Pick<InvoiceLineItemFormValues.Base, K>
>;

/**
 * Renders a field component for the `lineItemNumber` field of the InvoiceLineItem model.
 */
export function LineItemNumber({ formState }: FieldProps<"lineItemNumber">) {
	return <NumberField formState={formState} name="lineItemNumber" validationUnit={1} />;
}

/**
 * Renders a field component for the `amount` field of the InvoiceLineItem model.
 */
export function Amount({ formState, disabled }: FieldProps<"amount"> & { disabled?: boolean }) {
	return (
		<NumberField
			formState={formState}
			name="amount"
			validationUnit={1}
			unit={100}
			format={InvoiceLineItemFormat.Fields.amount}
			disabled={disabled}
		/>
	);
}

/**
 * Renders a field component for the `description` field of the InvoiceLineItem model.
 */
export function Description({ formState }: FieldProps<"description">) {
	return <TextField formState={formState} name="description" />;
}

export type InvoiceProps = FieldProps<"invoiceID"> & {
	currentInvoice?: InvoiceSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `invoice` edge of the InvoiceLineItem model.
 */
export function Invoice({ formState, currentInvoice }: InvoiceProps) {
	const [loadOptions, { loading, data }] = useInvoiceSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.invoiceID) {
			loadOptions();
		}
	}, [formState.formEditing.invoiceID, loadOptions]);
	const options = React.useMemo(
		() => InvoiceSelect.toOptions(data?.invoiceConnection.edges, currentInvoice),
		[data, currentInvoice],
	);

	return (
		<SelectField
			formState={formState}
			name="invoiceID"
			isLoading={loading}
			options={options}
			display={displayInvoice}
			blankValue={null}
		/>
	);
}

function displayInvoice({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <InvoiceLink instance={{ id }}>{formattedValue}</InvoiceLink>;
}
