import { newPageInfo } from "@hex-insights/router";
import { BusPlan } from "@hex-insights/verita.shared";
import { busPlanHubPageInfo } from "../pageinfo";

type BusPlanForRouteTitle = Pick<BusPlan, "id">;

export const busPlanHubDetailPageInfo = newPageInfo({
	name: "Bus Plan Detail",
	title: (e: BusPlanForRouteTitle) => e.id,
	description: "Detail page for an busPlan.",
	exact: false,
	isLeaf: true,
	to: (id: BusPlan["id"]) => busPlanHubPageInfo.to + `/${id}`,
	path: busPlanHubPageInfo.path + "/:id(\\d+)",
	parent: busPlanHubPageInfo,
});
