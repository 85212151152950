import { MealPlan } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the notes field of the MealPlan model.
 */
export function notes(value: MealPlan["notes"]): string {
	return toString(value);
}
