import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { ClassYearOrderFormValues } from "../../../../Utilities";

export type ClassYearOrderFormControllerProps = {
	formState: FormState<ClassYearOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the ClassYear model.
 */
export function ClassYearOrderFormController({ formState }: ClassYearOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={ClassYearOrderFormValues.fieldOptions}
			fieldTypeMapping={ClassYearOrderFormValues.fieldTypeMapping}
		/>
	);
}
