import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	EmailAddressForm,
	EmailAddressFormValues,
	EmailAddressMutation,
	useEmailAddressDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { emailAddressHubDetailPageInfo } from "./pageinfo";

function EmailAddressHubDetailPageWrapper() {
	// Manages identity of the EmailAddressHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <EmailAddressHubDetailPage key={id} />;
}

export { EmailAddressHubDetailPageWrapper as EmailAddressHubDetailPage };

function EmailAddressHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useEmailAddressDetailQuery({ variables: { id } });
	const emailAddress = data?.emailAddress;
	useActivePageRegistration(
		emailAddressHubDetailPageInfo,
		!!emailAddress ? emailAddressHubDetailPageInfo.title(emailAddress) : "",
	);

	const update = EmailAddressMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<EmailAddressFormValues.Detail>,
			initialFormValues: EmailAddressFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading emailAddress</If>
				<Else>
					{!!emailAddress && (
						<React.Fragment>
							<Heading level={1}>{emailAddress.email}</Heading>

							<EmailAddressForm.ControlledDetail
								key={syncID}
								emailAddress={emailAddress}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
