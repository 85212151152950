import React from "react";
import {
	addTimeToDate,
	Button,
	Column,
	Conditional,
	Else,
	formatDateTime,
	Icon,
	If,
	makeClassName,
	Modal,
	Row,
	Section,
	stringToLocalDate,
	toLocalDateString,
} from "@hex-insights/core";
import {
	CourseSectionFormat,
	CourseSectionHomeRoomSectionLessonPlanOverviewListQuery,
	getDateLessonPlanIDMap,
	getWeekDateRangeForCourseSection,
	HomeRoomSection,
	HorizontalScrollingContainer,
	LessonPlansWeekCourseSectionPreviewModal,
	useCourseSectionHomeRoomSectionLessonPlanOverviewListQuery,
	useWeekBounds,
} from "@hex-insights/verita.shared";
import styles from "./styles.module.css";

export type LessonPlansSectionProps = {
	homeRoomSectionID: HomeRoomSection["id"];
};

export function LessonPlansSection({ homeRoomSectionID }: LessonPlansSectionProps) {
	const { startDate, endDate, isCurrentWeek, onPrevWeekClick, onCurrentWeekClick, onNextWeekClick } = useWeekBounds();

	const { loading, data } = useCourseSectionHomeRoomSectionLessonPlanOverviewListQuery({
		variables: {
			homeRoomSectionID: homeRoomSectionID,
			startDate: toLocalDateString(startDate),
			endDate: toLocalDateString(endDate),
		},
	});

	return (
		<Section>
			<Section.Body style={{ padding: "0.25rem 0.5rem" }}>
				<Row justify="spaced-start" align="center">
					<Column justify="spaced-start" verticalSpacing="0.1rem">
						<span style={{ whiteSpace: "nowrap" }}>
							Lesson Plans:{" "}
							<span style={{ fontSize: "0.8rem", paddingLeft: "0.5rem", whiteSpace: "nowrap" }}>
								Week of {formatDateTime(addTimeToDate(startDate, [1, "day"]), "D MMM")}
							</span>
						</span>
						<Row justify="spaced-center" horizontalSpacing="0.25rem">
							<Button variant="link" size="small" onClick={onPrevWeekClick}>
								<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
									<Icon.ChevronLeft size="0.8rem" style={{ display: "block" }} /> Prev
								</Row>
							</Button>
							<If condition={!isCurrentWeek}>
								<Button variant="link" size="small" onClick={onCurrentWeekClick}>
									Current
								</Button>
							</If>
							<Button variant="link" size="small" onClick={onNextWeekClick}>
								<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
									Next <Icon.ChevronRight size="0.8rem" style={{ display: "block" }} />
								</Row>
							</Button>
						</Row>
					</Column>

					<Conditional>
						<If condition={loading}>Loading...</If>
						<Else>
							<HorizontalScrollingContainer>
								<Row justify="spaced-start">
									{data?.courseSectionConnection.edges.map((e) => (
										<CourseSectionLessonPlans key={e.node.id} courseSection={e.node} date={startDate} />
									))}
								</Row>
							</HorizontalScrollingContainer>
						</Else>
					</Conditional>
				</Row>
			</Section.Body>
		</Section>
	);
}

type CourseSectionLessonPlans = {
	courseSection: CourseSectionHomeRoomSectionLessonPlanOverviewListQuery["courseSectionConnection"]["edges"][0]["node"];
	date: Date;
};

function CourseSectionLessonPlans({ courseSection, date }: CourseSectionLessonPlans) {
	const courseSectionName = CourseSectionFormat.name(courseSection);

	const dateLessonPlanIDMap = React.useMemo(
		() => getDateLessonPlanIDMap(courseSection.lessonPlans),
		[courseSection.lessonPlans],
	);

	const { dateRange } = getWeekDateRangeForCourseSection(courseSection.courseSectionPeriods, date);

	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<Button onClick={toggleIsModalOpen} style={{ padding: 0 }}>
				<Column
					justify="spaced-start"
					verticalSpacing="0.2rem"
					align="center"
					className={styles["course-section-lesson-plans"]}
				>
					<span className={styles["course-section-lesson-plans__name"]}>{courseSectionName}</span>
					<Row justify="spaced-start" horizontalSpacing="0.3rem">
						{dateRange.map((e) => (
							<CourseSectionLessonPlanDayIcon key={e} date={e} hasLessonPlan={!!dateLessonPlanIDMap[e]} />
						))}
					</Row>
				</Column>
			</Button>

			<Modal.If condition={isModalOpen}>
				<LessonPlansWeekCourseSectionPreviewModal
					courseSectionName={courseSectionName}
					date={date}
					dates={dateRange}
					dateLessonPlanIDMap={dateLessonPlanIDMap}
					onClose={toggleIsModalOpen}
				/>
			</Modal.If>
		</React.Fragment>
	);
}

type CourseSectionLessonPlanDayIconProps = {
	date: string;
	hasLessonPlan: boolean;
};

function CourseSectionLessonPlanDayIcon({ date, hasLessonPlan }: CourseSectionLessonPlanDayIconProps) {
	return (
		<div
			className={makeClassName(
				styles["lesson-plan-day-icon"],
				hasLessonPlan ? styles["lesson-plan-day-icon--has-lesson-plan"] : "",
			)}
		>
			<div className={styles["lesson-plan-day-icon__text"]}>
				{formatDateTime(stringToLocalDate(date, "day"), "dd").charAt(0)}
			</div>
		</div>
	);
}
