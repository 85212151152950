import { Campus, CourseSectionPeriod, Room } from "../../GraphQL";

export type Base = {
	name: Room["name"];
	number: Room["number"];
	campusID: Campus["id"] | null;
};

export type Create = Base;

export type Detail = Base & {
	courseSectionPeriodIDs: CourseSectionPeriod["id"][];
};

export const initialCreate: Create = {
	name: "",
	number: "",
	campusID: null,
};
