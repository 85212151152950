import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { AcademicYearFilterInput } from "../../GraphQL";
import { AcademicYearFilterFormValues } from "../ModelFilterFormValues";

/**
 * Counts the number of active filters for the AcademicYear model.
 *
 * @param formValues The form values of the filter form.
 */
export function countActiveFilters(formValues: AcademicYearFilterFormValues.FormValues) {
	// TODO count standard filters
	const numAdvancedFilters = IndexForms.countActiveAdvancedFilters(
		formValues,
		AcademicYearFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	);
	return numAdvancedFilters;
}

/**
 * Clears the values of the filter form for the AcademicYear model.
 *
 * @param formState The form state of the filter form.
 */
export function clearFilters(formState: FormState<AcademicYearFilterFormValues.FormValues>) {
	// TODO clear standard filters
	if (formState.formClearFunctions.filterSets) {
		formState.formClearFunctions.filterSets();
	}
}

/**
 * Resets the values of the filter form for the AcademicYear model.
 *
 * @param formState The form state of the filter form.
 */
export function resetFilters(formState: FormState<AcademicYearFilterFormValues.FormValues>) {
	// TODO reset standard filters
	if (formState.formResetFunctions.filterSets) {
		formState.formResetFunctions.filterSets();
	}
}

/**
 * Converts the form values of the filter form for the AcademicYear model to the correct GraphQL
 * filter input type.
 *
 * @param formValues The form values of the filter form.
 */
export function toFilterInputs(
	formValues: AcademicYearFilterFormValues.FormValues,
): AcademicYearFilterInput | AcademicYearFilterInput[] {
	return IndexForms.toFilterInput(
		formValues,
		AcademicYearFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	) as AcademicYearFilterInput;
}
