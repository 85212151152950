import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export const mealPlanListPageInfo = newPageInfo({
	title: "Meal Plan List",
	description: "List of students with meal plans.",
	exact: false,
	to: "/meal-plan-list",
	path: "/meal-plan-list",
	parent: rootPageInfo,
});
