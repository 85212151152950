import { IndexForms } from "@hex-insights/app-modules";
import { DocumentResourceUserViewRecordFilterFormValues } from "../../../../Utilities";

export type DocumentResourceUserViewRecordFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, DocumentResourceUserViewRecordFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the DocumentResourceUserViewRecord model.
 */
export function DocumentResourceUserViewRecordFilterFormController(
	props: DocumentResourceUserViewRecordFilterFormControllerProps,
) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={DocumentResourceUserViewRecordFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `DocumentResourceUserViewRecordFilterForm`
