import { newPageInfo } from "@hex-insights/router";
import { MFAInvalidOTPAttempt } from "@hex-insights/verita.shared";
import { mfaInvalidOTPAttemptHubPageInfo } from "../pageinfo";

type MFAInvalidOTPAttemptForRouteTitle = Pick<MFAInvalidOTPAttempt, "id">;

export const mfaInvalidOTPAttemptHubDetailPageInfo = newPageInfo({
	name: "MFA Invalid OTP Attempt Detail",
	title: (e: MFAInvalidOTPAttemptForRouteTitle) => e.id,
	description: "Detail page for an mfaInvalidOTPAttempt.",
	exact: false,
	isLeaf: true,
	to: (id: MFAInvalidOTPAttempt["id"]) => mfaInvalidOTPAttemptHubPageInfo.to + `/${id}`,
	path: mfaInvalidOTPAttemptHubPageInfo.path + "/:id(\\d+)",
	parent: mfaInvalidOTPAttemptHubPageInfo,
});
