import { IndexForms } from "@hex-insights/app-modules";
import { omitKeys } from "@hex-insights/core";
import { FormState } from "@hex-insights/forms";
import { EnrollmentInvitationFilterInput } from "../../GraphQL";
import { EnrollmentInvitationFilterFormValues } from "../ModelFilterFormValues";

/**
 * Counts the number of active filters for the EnrollmentInvitation model.
 *
 * @param formValues The form values of the filter form.
 */
export function countActiveFilters(formValues: EnrollmentInvitationFilterFormValues.FormValues) {
	let numQuickFilters = 0;

	if (formValues.responseIn.length > 0) {
		numQuickFilters++;
	}

	const numAdvancedFilters = IndexForms.countActiveAdvancedFilters(
		formValues,
		EnrollmentInvitationFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	);
	return numQuickFilters + numAdvancedFilters;
}

/**
 * Clears the values of the filter form for the EnrollmentInvitation model.
 *
 * @param formState The form state of the filter form.
 */
export function clearFilters(formState: FormState<EnrollmentInvitationFilterFormValues.FormValues>) {
	formState.formClearFunctions.responseIn();

	formState.formClearFunctions.filterSets();
}

/**
 * Resets the values of the filter form for the EnrollmentInvitation model.
 *
 * @param formState The form state of the filter form.
 */
export function resetFilters(formState: FormState<EnrollmentInvitationFilterFormValues.FormValues>) {
	formState.formResetFunctions.responseIn();

	formState.formResetFunctions.filterSets();
}

/**
 * Converts the form values of the filter form for the EnrollmentInvitation model to the correct GraphQL
 * filter input type.
 *
 * @param formValues The form values of the filter form.
 */
export function toFilterInputs(
	formValues: EnrollmentInvitationFilterFormValues.FormValues,
): EnrollmentInvitationFilterInput | EnrollmentInvitationFilterInput[] {
	const preparedFormValues = omitKeys(formValues, ["search"]);
	const filterInput = IndexForms.toFilterInput(
		preparedFormValues,
		EnrollmentInvitationFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	) as EnrollmentInvitationFilterInput;

	if (formValues.search !== "") {
		filterInput.student = [{ person: [{ search: formValues.search }] }];
	}

	return filterInput;
}
