import { newPageInfo } from "@hex-insights/router";
import { Permission } from "@hex-insights/verita.shared";
import { permissionHubPageInfo } from "../pageinfo";

type PermissionForRouteTitle = Pick<Permission, "name">;

export const permissionHubDetailPageInfo = newPageInfo({
	name: "Permission Detail",
	title: (e: PermissionForRouteTitle) => e.name,
	description: "Detail page for an permission.",
	exact: false,
	isLeaf: true,
	to: (id: Permission["id"]) => permissionHubPageInfo.to + `/${id}`,
	path: permissionHubPageInfo.path + "/:id(\\d+)",
	parent: permissionHubPageInfo,
});
