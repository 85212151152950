import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import {
	SchoolAttendanceRecordFilterInput,
	SchoolAttendanceRecordOrder,
	useSchoolAttendanceRecordPaginationQuery,
} from "../../../../Utilities";

export type SchoolAttendanceRecordPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: SchoolAttendanceRecordFilterInput | SchoolAttendanceRecordFilterInput[] | null;
	order: SchoolAttendanceRecordOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the SchoolAttendanceRecord model.
 */
export function SchoolAttendanceRecordPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: SchoolAttendanceRecordPaginationFormProps) {
	const { loading, data } = useSchoolAttendanceRecordPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.schoolAttendanceRecordConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
