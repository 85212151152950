import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { ExpenseFilterInput, ExpenseOrder, useExpensePaginationQuery } from "../../../../Utilities";

export type ExpensePaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: ExpenseFilterInput | ExpenseFilterInput[] | null;
	order: ExpenseOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the Expense model.
 */
export function ExpensePaginationForm({ formState, filters, order, setPaginationInfo }: ExpensePaginationFormProps) {
	const { loading, data } = useExpensePaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.expenseConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
