import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	LoginRecordFilterFormController,
	LoginRecordFilterFormState,
	LoginRecordFilterFormUtils,
	LoginRecordFormat,
	LoginRecordIndexQuery,
	LoginRecordOrderField,
	LoginRecordOrderFormController,
	LoginRecordOrderFormState,
	LoginRecordOrderFormValues,
	LoginRecordPaginationForm,
	LoginRecordSearchForm,
	useLoginRecordIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { loginRecordHubDetailPageInfo } from "../DetailPage/pageinfo";
import { loginRecordHubIndexPageInfo } from "./pageinfo";

const columns: (keyof LoginRecordIndexQuery["loginRecordConnection"]["edges"][0]["node"])[] = [
	"id",
	"createdAt",
	"userEmail",
	"wasBlocked",
	"wasSuccessful",
	"isTrusted",
];

function orderFieldToColumn(field: LoginRecordOrderField | null) {
	if (field === null) {
		return "id";
	}
	return LoginRecordOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return LoginRecordOrderFormValues.modelFieldToOrderField[column];
}

export function LoginRecordHubIndexPage() {
	useActivePageRegistration(loginRecordHubIndexPageInfo);

	const filterFormState = LoginRecordFilterFormState.useFormState();
	const orderFormState = LoginRecordOrderFormState.useFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(filterFormState.formValues, LoginRecordFilterFormUtils.toFilterInputs);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useLoginRecordIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		LoginRecordFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = LoginRecordFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.loginRecordConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading level={1} noMargin>
					Login Records
				</Heading>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<LoginRecordSearchForm formState={filterFormState} />
				<LoginRecordFilterFormController formState={filterFormState} numActiveFilters={numActiveFilters} />
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<LoginRecordOrderFormController formState={orderFormState} />
			</Row>

			<Table.Container className="hub__index-page__table__container">
				<Table columns={columns} minColumnWidth="10rem" {...tableSortingProps} className="hub__index-page__table">
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="id">ID</Table.Heading>
							<Table.Heading column="createdAt">Created At</Table.Heading>
							<Table.Heading column="userEmail">User Email</Table.Heading>
							<Table.Heading column="wasBlocked" noSort>
								Was Blocked
							</Table.Heading>
							<Table.Heading column="wasSuccessful" noSort>
								Was Successful
							</Table.Heading>
							<Table.Heading column="isTrusted" noSort>
								Is Trusted
							</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.loginRecordConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="id" oneLine numeric>
											<InternalLink to={loginRecordHubDetailPageInfo.to(e.node.id)}>{e.node.id}</InternalLink>
										</Table.Cell>
										<Table.Cell column="createdAt" oneLine>
											{LoginRecordFormat.Fields.createdAt(e.node.createdAt)}
										</Table.Cell>
										<Table.Cell column="userEmail" oneLine>
											{LoginRecordFormat.Fields.userEmail(e.node.userEmail)}
										</Table.Cell>
										<Table.Cell column="wasBlocked" oneLine>
											{LoginRecordFormat.Fields.wasBlocked(e.node.wasBlocked)}
										</Table.Cell>
										<Table.Cell column="wasSuccessful" oneLine>
											{LoginRecordFormat.Fields.wasSuccessful(e.node.wasSuccessful)}
										</Table.Cell>
										<Table.Cell column="isTrusted" oneLine>
											{LoginRecordFormat.Fields.isTrusted(e.node.isTrusted)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<LoginRecordPaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.loginRecordConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
