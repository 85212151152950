import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { categoricalColorSchemes, defaultPieProps } from "@hex-insights/charts";
import { ChildrenProps, isNumber, StyleProps } from "@hex-insights/core";
import { ExpenseBudgetFormat, ExpenseBudgetIndexQuery } from "@hex-insights/verita.shared";

export type BudgetPieProps = {
	data: ExpenseBudgetIndexQuery["expenseBudgetConnection"]["edges"];
};

export function BudgetPie({ data }: BudgetPieProps) {
	const pieData = data.map((e) => ({ ...e.node, expenseCategoryName: e.node.expenseCategory.name }));

	const tooltipFormatter = React.useCallback<(v: ValueType, n: NameType) => [ValueType | string, NameType]>(
		(value, name) => {
			if (!isNumber(value)) {
				return [value, name];
			}
			return [ExpenseBudgetFormat.Fields.amount(value), name];
		},
		[],
	);

	return (
		<ResponsiveContainer width="100%" height="100%">
			<PieChart>
				<Tooltip isAnimationActive={false} formatter={tooltipFormatter} />

				<Pie {...defaultPieProps} data={pieData} nameKey="expenseCategoryName" dataKey="amount" label={getPieLabel()}>
					{pieData.map((e, i) => (
						<Cell key={e.id} fill={getColor(categoricalColorSchemes.tableau10, i)} />
					))}
				</Pie>
			</PieChart>
		</ResponsiveContainer>
	);
}

// TODO move to Hex Charts

export type ChartContainerProps = Partial<StyleProps & ChildrenProps>;

export function ChartContainer({ style, children }: ChartContainerProps) {
	return <div style={{ width: "100%", height: "60vh", ...style }}>{children}</div>;
}

export function getPieLabel(formatName: (name: string) => string = defaultFormatName) {
	return (entry: any) => {
		return formatName(entry.name);
	};
}

export function defaultFormatName(name: string) {
	return name;
}

export function getColor(colorSet: string[], index: number) {
	return colorSet[index % colorSet.length];
}
