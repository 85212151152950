import { newPageInfo } from "@hex-insights/router";
import { enrollmentApplicationNoteHubPageInfo } from "../pageinfo";

export const enrollmentApplicationNoteHubCreatePageInfo = newPageInfo({
	name: "New Enrollment Application Note",
	title: "New",
	description: "Create a new Enrollment Application Note record.",
	exact: true,
	to: enrollmentApplicationNoteHubPageInfo.to + "/new",
	path: enrollmentApplicationNoteHubPageInfo.path + "/new",
	parent: enrollmentApplicationNoteHubPageInfo,
});
