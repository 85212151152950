import { getArrayDiff, omitKeys } from "@hex-insights/core";
import { PermissionDetailQuery, PermissionUpdateInput } from "../../GraphQL";
import { PermissionFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Permission model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<PermissionFormValues.Detail>,
	initialFormValues: PermissionFormValues.Detail,
): PermissionUpdateInput {
	const inputFormValues = omitKeys(formValues, [
		"name",
		"family",
		"action",
		"level",
		"description",
		"permissionGroupIDs",
	]);
	const input: PermissionUpdateInput = { ...inputFormValues };

	if (formValues.permissionGroupIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.permissionGroupIDs, formValues.permissionGroupIDs);
		input.addPermissionGroupIDs = added;
		input.removePermissionGroupIDs = removed;
	}

	return input;
}

/**
 * Converts a Permission instance to its corresponding detail form values.
 *
 * @param permission The instance to convert.
 */
export function toFormValues(permission: PermissionDetailQuery["permission"]): PermissionFormValues.Detail {
	return {
		name: permission.name,
		family: permission.family,
		action: permission.action,
		level: permission.level,
		description: permission.description,
		permissionGroupIDs: permission.permissionGroups.map((e) => e.id),
	};
}
