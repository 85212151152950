import { IndexForms } from "@hex-insights/app-modules";
import { LearningObjectiveFilterFormValues } from "../../../../Utilities";

export type LearningObjectiveSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the LearningObjective model.
 */
export function LearningObjectiveSearchForm(props: LearningObjectiveSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type LearningObjectiveFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, LearningObjectiveFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the LearningObjective model.
 */
export function LearningObjectiveFilterFormController(props: LearningObjectiveFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={LearningObjectiveFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `LearningObjectiveFilterForm`
