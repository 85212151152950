import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import {
	EnrollmentApplicationFilterFormState,
	EnrollmentApplicationFilterFormUtils,
	EnrollmentApplicationFilterFormValues,
	EnrollmentApplicationOrderField,
	EnrollmentApplicationOrderFormState,
	EnrollmentApplicationOrderFormValues,
	EnrollmentApplicationUserViewRecordUtils,
	useEnrollmentApplicationIndexQuery,
} from "@hex-insights/verita.shared";
import { AuthenticationContext } from "../../Contexts";

function orderFieldToColumn(field: EnrollmentApplicationOrderField | null) {
	if (field === null) {
		return "id";
	}
	return EnrollmentApplicationOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return EnrollmentApplicationOrderFormValues.modelFieldToOrderField[column];
}

type UseEnrollmentApplicationsIndexArgs = {
	initialFilterFormValues?: EnrollmentApplicationFilterFormValues.FormValues;
	initialOrderFormValues?: EnrollmentApplicationOrderFormValues.FormValues;
};

export function useEnrollmentApplicationsIndex({
	initialFilterFormValues,
	initialOrderFormValues,
}: UseEnrollmentApplicationsIndexArgs = {}) {
	const filterFormState = EnrollmentApplicationFilterFormState.useFormState(initialFilterFormValues);
	const orderFormState = EnrollmentApplicationOrderFormState.useFormState(initialOrderFormValues);
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(
		filterFormState.formValues,
		EnrollmentApplicationFilterFormUtils.toFilterInputs,
	);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { user } = React.useContext(AuthenticationContext);
	const { data: rawData, error } = useEnrollmentApplicationIndexQuery({
		variables: { ...queryVariables, userID: user?.id ?? "0" },
		fetchPolicy: "cache-and-network",
	});
	const { isNew: filterIsNew, isSeen: filterIsSeen } = filterFormState.formValues;
	const data = React.useMemo(() => {
		// TODO replace with API filtering when supported
		if (!rawData) {
			return undefined;
		}
		if (filterIsNew === filterIsSeen) {
			return rawData;
		}
		return {
			enrollmentApplicationConnection: {
				edges: rawData.enrollmentApplicationConnection.edges.filter((e) => {
					if (filterIsNew) {
						const isNew =
							e.node.enrollmentApplicationUserViewRecords.length === 0 ||
							e.node.enrollmentApplicationUserViewRecords.every((e) => !e.isDetail);
						return isNew;
					}
					if (filterIsSeen) {
						const isSeen = e.node.enrollmentApplicationUserViewRecords.some((e) => e.isDetail);
						return isSeen;
					}
					return true;
				}),
			},
		};
	}, [rawData, filterIsNew, filterIsSeen]);
	EnrollmentApplicationUserViewRecordUtils.useViewRecorder(data?.enrollmentApplicationConnection.edges);

	const clearFilters = React.useCallback(() => {
		EnrollmentApplicationFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = EnrollmentApplicationFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.enrollmentApplicationConnection.edges.length ?? 0),
		0,
	);

	return {
		filterFormState,
		orderFormState,
		paginationFormState,
		filterInputs,
		orderInput,
		numActiveFilters,
		data,
		error,
		clearFilters,
		paginationInfo,
		setPaginationInfo,
		tableSortingProps,
		numFillerRows,
	};
}
