import React from "react";
import { GlobalSearch } from "@hex-insights/app-modules";
import {
	Button,
	ClassNameProps,
	compareObjects,
	compareWithOrder,
	makeClassName,
	Modal,
	Row,
	sort,
} from "@hex-insights/core";
import { FormState } from "@hex-insights/forms";
import { SearchResultFilterInput } from "@hex-insights/verita.shared";
import {
	checkAllModels,
	convertModelNameFilterFormValuesToFilterInput,
	initialModelNameFiltersFormValues,
	ModelNameFilterInputs,
	ModelNameFiltersFormValues,
	uncheckAllModels,
} from "./models";
import { defaultBarQueryArgs, SearchQueryArgs, useSearch } from "./use-search";
import styles from "./styles.module.css";

type GlobalSearchBarProps = Pick<
	GlobalSearch.Basic.SearchBarProps,
	"onSelectResult" | "expands" | "withKeyboardShortcut"
> &
	Partial<ClassNameProps>;

export function GlobalSearchBar({ onSelectResult, expands, withKeyboardShortcut, className }: GlobalSearchBarProps) {
	const formState = GlobalSearch.useBasicFormState();
	const { isLoading, results } = useSearch(formState.formValues.search, formState.formValues, defaultBarQueryArgs);
	const sortedResults = React.useMemo(() => {
		return sort(results, compareObjects("group", compareWithOrder(["Student", "Employee", "Parent"])));
	}, [results]);

	return (
		<GlobalSearch.Basic.SearchBar
			isLoading={isLoading}
			results={sortedResults}
			formState={formState}
			loadingElement={<GlobalSearch.Basic.SearchResultLoading />}
			noResultsElement={<GlobalSearch.Basic.NoSearchResults search={formState.formValues.search} />}
			displayResult={GlobalSearch.Basic.SearchResultDisplay}
			AdvancedSearch={AdvancedGlobalSearch}
			Footer={GlobalSearch.Basic.DropDownFooter}
			onSelectResult={onSelectResult}
			expands={expands}
			withKeyboardShortcut={withKeyboardShortcut}
			className={makeClassName(styles["global-search__bar"], className)}
		/>
	);
}

type AdvancedGlobalSearchFormValues = GlobalSearch.AdvancedFormValues & ModelNameFiltersFormValues;

const initialFormValues: AdvancedGlobalSearchFormValues = {
	...GlobalSearch.initialAdvancedFormValues,
	...initialModelNameFiltersFormValues,
};

export type AdvancedGlobalSearchProps = {
	isOpen: boolean;
	onClose: () => void;
	search: string;
	includeRecords: boolean;
	includePages: boolean;
};

export function AdvancedGlobalSearch({
	isOpen,
	onClose,
	search,
	includeRecords,
	includePages,
}: AdvancedGlobalSearchProps) {
	const formState = GlobalSearch.useAdvancedFormState(initialFormValues);
	const { isInitialized } = GlobalSearch.useAdvancedSetup({
		formState,
		isOpen,
		search,
		includeRecords,
		includePages,
	});

	return (
		<Modal.If condition={isOpen && isInitialized}>
			<GlobalSearch.Advanced.SearchModal onClose={onClose}>
				<AdvancedGlobalSearchBody formState={formState} onClose={onClose} />
			</GlobalSearch.Advanced.SearchModal>
		</Modal.If>
	);
}

type AdvancedGlobalSearchBodyProps = {
	formState: FormState<AdvancedGlobalSearchFormValues>;
	onClose: () => void;
};

function AdvancedGlobalSearchBody({ formState, onClose }: AdvancedGlobalSearchBodyProps) {
	const filterState = GlobalSearch.useAdvancedFilterState(formState, convertFormValuesToQueryArgs);
	const { isLoading, results } = useSearch(
		formState.formValues.search,
		filterState.appliedCategories,
		filterState.appliedQueryArgs,
	);

	const searchBarElement = <GlobalSearch.Advanced.SearchBar formState={formState} />;

	const searchResultsElement = (
		<GlobalSearch.Advanced.SearchResults
			isLoading={isLoading}
			formState={formState}
			results={results}
			onClose={onClose}
			loadingElement="Loading..."
			blankSearchElement="Search above."
			noResultsElement="No results found."
			Result={GlobalSearch.Advanced.SearchResultLink}
			className={styles["global-search__advanced__search-results"]}
		/>
	);

	const searchFiltersElement = (
		<GlobalSearch.Advanced.SearchFilters
			formState={formState}
			anyFiltersChanged={filterState.anyFiltersChanged}
			anyFiltersChangedSinceApplied={filterState.anyFiltersChangedSinceApplied}
			onApplyFilters={filterState.onApplyFilters}
			resetFilters={filterState.resetFilters}
			modelFilterInputsElement={<ModelNameFilterInputsWithButtons formState={formState} />}
		/>
	);

	return (
		<GlobalSearch.Advanced.SearchBodyLayout
			searchBarElement={searchBarElement}
			searchResultsElement={searchResultsElement}
			searchFiltersElement={searchFiltersElement}
		/>
	);
}

type ModelNameFilterInputsWithButtonsProps = {
	formState: FormState<ModelNameFiltersFormValues>;
};

function ModelNameFilterInputsWithButtons({ formState }: ModelNameFilterInputsWithButtonsProps) {
	return (
		<React.Fragment>
			<Row justify="spaced-start" horizontalSpacing="0.5rem">
				<Button variant="tertiary" size="small" onClick={() => checkAllModels(formState)}>
					Check All
				</Button>
				<Button variant="tertiary" size="small" onClick={() => uncheckAllModels(formState)}>
					Uncheck All
				</Button>
			</Row>

			<ModelNameFilterInputs formState={formState} />
		</React.Fragment>
	);
}

function convertFormValuesToQueryArgs(formValues: AdvancedGlobalSearchFormValues): SearchQueryArgs {
	const filters: SearchResultFilterInput = {};

	const modelNameIn = convertModelNameFilterFormValuesToFilterInput(formValues);
	if (modelNameIn !== null) {
		filters.modelNameIn = modelNameIn;
	}

	return { filters };
}
