import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { SchoolAttendanceRecordOrderFormValues } from "../../../../Utilities";

export type SchoolAttendanceRecordOrderFormControllerProps = {
	formState: FormState<SchoolAttendanceRecordOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the SchoolAttendanceRecord model.
 */
export function SchoolAttendanceRecordOrderFormController({
	formState,
}: SchoolAttendanceRecordOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={SchoolAttendanceRecordOrderFormValues.fieldOptions}
			fieldTypeMapping={SchoolAttendanceRecordOrderFormValues.fieldTypeMapping}
		/>
	);
}
