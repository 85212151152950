import { newPageInfo } from "@hex-insights/router";
import { NoteReaction } from "@hex-insights/verita.shared";
import { noteReactionHubPageInfo } from "../pageinfo";

type NoteReactionForRouteTitle = Pick<NoteReaction, "id">;

export const noteReactionHubDetailPageInfo = newPageInfo({
	name: "Note Reaction Detail",
	title: (e: NoteReactionForRouteTitle) => e.id,
	description: "Detail page for an noteReaction.",
	exact: false,
	isLeaf: true,
	to: (id: NoteReaction["id"]) => noteReactionHubPageInfo.to + `/${id}`,
	path: noteReactionHubPageInfo.path + "/:id(\\d+)",
	parent: noteReactionHubPageInfo,
});
