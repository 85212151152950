import { newPageInfo } from "@hex-insights/router";
import { CalendarEvent } from "@hex-insights/verita.shared";
import { calendarEventHubPageInfo } from "../pageinfo";

type CalendarEventForRouteTitle = Pick<CalendarEvent, "name">;

export const calendarEventHubDetailPageInfo = newPageInfo({
	name: "Calendar Event Detail",
	title: (e: CalendarEventForRouteTitle) => e.name,
	description: "Detail page for an calendarEvent.",
	exact: false,
	isLeaf: true,
	to: (id: CalendarEvent["id"]) => calendarEventHubPageInfo.to + `/${id}`,
	path: calendarEventHubPageInfo.path + "/:id(\\d+)",
	parent: calendarEventHubPageInfo,
});
