import { Tuition } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the name field of the Tuition model.
 */
export function name(value: Tuition["name"]): string {
	return toString(value);
}

/**
 * Formats the initialYear field of the Tuition model.
 */
export function initialYear(value: Tuition["initialYear"] | null): string {
	return toString(value);
}
