import { IndexForms } from "@hex-insights/app-modules";
import { ParentOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the Parent model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		ParentOrderFormValues.initial,
		ParentOrderFormValues.fieldValues,
	);
}
