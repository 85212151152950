import { newPageInfo } from "@hex-insights/router";
import { MealPlan } from "@hex-insights/verita.shared";
import { mealPlanHubPageInfo } from "../pageinfo";

type MealPlanForRouteTitle = Pick<MealPlan, "id">;

export const mealPlanHubDetailPageInfo = newPageInfo({
	name: "Meal Plan Detail",
	title: (e: MealPlanForRouteTitle) => e.id,
	description: "Detail page for an mealPlan.",
	exact: false,
	isLeaf: true,
	to: (id: MealPlan["id"]) => mealPlanHubPageInfo.to + `/${id}`,
	path: mealPlanHubPageInfo.path + "/:id(\\d+)",
	parent: mealPlanHubPageInfo,
});
