import { AcademicYear, BillingSetupSubmission, Student } from "../../GraphQL";

export type Base = {
	numPayments: BillingSetupSubmission["numPayments"] | null;
	isInterestedInBusPlan: BillingSetupSubmission["isInterestedInBusPlan"];
	isInterestedInMealPlan: BillingSetupSubmission["isInterestedInMealPlan"];
	authorName: BillingSetupSubmission["authorName"];
	authorEmail: BillingSetupSubmission["authorEmail"];
	authorPhone: BillingSetupSubmission["authorPhone"];
	academicYearID: AcademicYear["id"] | null;
	studentID: Student["id"] | null;
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	numPayments: null,
	isInterestedInBusPlan: false,
	isInterestedInMealPlan: false,
	authorName: "",
	authorEmail: "",
	authorPhone: "",
	academicYearID: null,
	studentID: null,
};

export const numPaymentsOptions = [
	{ value: 1, label: "Single Payment" },
	{ value: 2, label: "Two Payments" },
	{ value: 4, label: "Four Payments" },
];
