import { Grid, GridProps } from "@hex-insights/core";

const foldersGridResponseColumns = {
	0: 1,
	550: 2,
	800: 3,
	1000: 4,
	1200: 5,
};

export type FoldersGridProps = GridProps;

export function FoldersGrid({
	responsiveColumns = foldersGridResponseColumns,
	gap = "1rem",
	...props
}: FoldersGridProps) {
	return <Grid responsiveColumns={responsiveColumns} gap={gap} {...props} />;
}

const resourcesGridResponseColumns = {
	0: 1,
	200: 2,
	400: 3,
	600: 4,
	800: 5,
	900: 6,
	1000: 7,
	1100: 8,
};

export type ResourcesGridProps = GridProps;

export function ResourcesGrid({
	responsiveColumns = resourcesGridResponseColumns,
	gap = "1rem",
	...props
}: ResourcesGridProps) {
	return <Grid responsiveColumns={responsiveColumns} gap={gap} {...props} />;
}
