import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { AcademicYearOrderFormValues } from "../../../../Utilities";

export type OrderControllerProps = {
	formState: FormState<AcademicYearOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the AcademicYear model.
 */
export function OrderController({ formState }: OrderControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={AcademicYearOrderFormValues.fieldOptions}
			fieldTypeMapping={AcademicYearOrderFormValues.fieldTypeMapping}
		/>
	);
}
