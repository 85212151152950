import { IndexForms } from "@hex-insights/app-modules";
import { EnrollmentApplicationNoteFilterFormValues } from "../../../../Utilities";

export type EnrollmentApplicationNoteSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the EnrollmentApplicationNote model.
 */
export function EnrollmentApplicationNoteSearchForm({ formState }: EnrollmentApplicationNoteSearchFormProps) {
	return <IndexForms.SearchForm formState={formState} />;
}

export type EnrollmentApplicationNoteFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, EnrollmentApplicationNoteFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the EnrollmentApplicationNote model.
 */
export function EnrollmentApplicationNoteFilterFormController(
	props: EnrollmentApplicationNoteFilterFormControllerProps,
) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={EnrollmentApplicationNoteFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `EnrollmentApplicationNoteFilterForm`
