import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { ExpenseCategoryCreateInput, ExpenseCategoryDetailQuery, ExpenseCategoryUpdateInput } from "../../GraphQL";
import { ExpenseCategoryFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the ExpenseCategory model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: ExpenseCategoryFormValues.Create): ExpenseCategoryCreateInput {
	const input: NullPartial<ExpenseCategoryCreateInput> = { ...formValues };
	return input as ExpenseCategoryCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the ExpenseCategory model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<ExpenseCategoryFormValues.Detail>,
	initialFormValues: ExpenseCategoryFormValues.Detail,
): ExpenseCategoryUpdateInput {
	const inputFormValues = omitKeys(formValues, ["expenseBudgetIDs", "expenseIDs"]);
	const input: ExpenseCategoryUpdateInput = { ...inputFormValues };

	if (formValues.expenseBudgetIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.expenseBudgetIDs, formValues.expenseBudgetIDs);
		input.addExpenseBudgetIDs = added;
		input.removeExpenseBudgetIDs = removed;
	}

	if (formValues.expenseIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.expenseIDs, formValues.expenseIDs);
		input.addExpenseIDs = added;
		input.removeExpenseIDs = removed;
	}

	return input;
}

/**
 * Converts a ExpenseCategory instance to its corresponding detail form values.
 *
 * @param expenseCategory The instance to convert.
 */
export function toFormValues(
	expenseCategory: ExpenseCategoryDetailQuery["expenseCategory"],
): ExpenseCategoryFormValues.Detail {
	return {
		name: expenseCategory.name,
		description: expenseCategory.description,
		expenseBudgetIDs: expenseCategory.expenseBudgets.map((e) => e.id),
		expenseIDs: expenseCategory.expenses.map((e) => e.id),
	};
}
