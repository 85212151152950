import { IndexForms } from "@hex-insights/app-modules";
import { NoteCommentFilterFormValues } from "../../../../Utilities";

export type NoteCommentSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the NoteComment model.
 */
export function NoteCommentSearchForm(props: NoteCommentSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type NoteCommentFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, NoteCommentFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the NoteComment model.
 */
export function NoteCommentFilterFormController(props: NoteCommentFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={NoteCommentFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `NoteCommentFilterForm`
