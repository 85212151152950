import { EditMode, FormType, useFormState } from "@hex-insights/forms";
import { ExpenseFormValues } from "../ModelFormValues";

/**
 * Returns a `formState` object for use in the create form for the Expense model.
 */
export function useCreateFormState() {
	return useFormState({
		initialFormValues: ExpenseFormValues.initialCreate,
		formType: FormType.Create,
	});
}

/**
 * Returns a `formState` object for use in the detail form of the Expense model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link ExpenseFormConversion.toFormValues}.
 */
export function useDetailFormState({ initialFormValues }: { initialFormValues: ExpenseFormValues.Detail }) {
	return useFormState({
		initialFormValues: initialFormValues,
		formType: FormType.Update,
	});
}

/**
 * Returns a read-only `formState` object for use in the detail form of the Expense model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link ExpenseFormConversion.toFormValues}.
 */
export function useReadOnlyFormState({ initialFormValues }: { initialFormValues: ExpenseFormValues.Detail }) {
	return useFormState({
		initialFormValues: initialFormValues,
		editMode: EditMode.ReadOnly,
	});
}
