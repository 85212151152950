import React from "react";
import { Column, Conditional, Else, Heading, If, mappedArray, Section } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import { useDocumentResourceUserViewRecordRecentsQuery } from "@hex-insights/verita.shared";
import { AuthenticationContext } from "../../../Contexts";
import { DocumentResourceDisplay, ResourcesGrid } from "../Components";
import { resourcesRecentsPageInfo } from "./pageinfo";

export function ResourcesRecentsPage() {
	useActivePageRegistration(resourcesRecentsPageInfo);

	return (
		<Column justify="spaced-start" style={{ width: "100%", overflowX: "hidden" }}>
			<Heading level={1} noMargin>
				Recents
			</Heading>

			<Folders />
		</Column>
	);
}

function Folders() {
	const { user } = React.useContext(AuthenticationContext);
	const { loading, data } = useDocumentResourceUserViewRecordRecentsQuery({
		variables: {
			userID: user?.id ?? "0",
		},
		fetchPolicy: "cache-and-network",
	});

	return (
		<Section>
			<Section.Body>
				<ResourcesGrid>
					<Conditional>
						<If condition={loading && !data}>
							{mappedArray(24, (i) => (
								<DocumentResourceDisplay.Loading key={i} />
							))}
						</If>
						<Else>
							{data?.documentResourceUserViewRecordConnectionRecents.edges.map(({ node }) => (
								<DocumentResourceDisplay key={node.id} documentResource={node.documentResource} />
							))}
						</Else>
					</Conditional>
				</ResourcesGrid>
			</Section.Body>
		</Section>
	);
}
