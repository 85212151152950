import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { PermissionGroupOrderFormValues } from "../../../../Utilities";

export type PermissionGroupOrderFormControllerProps = {
	formState: FormState<PermissionGroupOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the PermissionGroup model.
 */
export function PermissionGroupOrderFormController({ formState }: PermissionGroupOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={PermissionGroupOrderFormValues.fieldOptions}
			fieldTypeMapping={PermissionGroupOrderFormValues.fieldTypeMapping}
		/>
	);
}
