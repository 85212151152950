import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	AcademicYearTuition,
	AcademicYearTuitionCreateInput,
	AcademicYearTuitionCreateMutation,
	AcademicYearTuitionDeleteMutation,
	AcademicYearTuitionDetailDocument,
	AcademicYearTuitionDetailQuery,
	AcademicYearTuitionDetailQueryVariables,
	AcademicYearTuitionUpdateInput,
	AcademicYearTuitionUpdateMutation,
	graphTypeNames,
	Tuition,
	useAcademicYearTuitionCreateMutation,
	useAcademicYearTuitionDeleteMutation,
	useAcademicYearTuitionUpdateMutation,
} from "../../GraphQL";
import { AcademicYearTuitionFormConversion } from "../ModelFormConversion";
import { AcademicYearTuitionFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the AcademicYearTuition model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the AcademicYearTuition model.
 */
export function useCreate() {
	const [createAcademicYearTuition] = useAcademicYearTuitionCreateMutation();

	return React.useCallback(
		async (formValues: AcademicYearTuitionFormValues.Create) => {
			const input = AcademicYearTuitionFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createAcademicYearTuition({ variables: { input }, update: updateCache });

			return { data: data?.createAcademicYearTuition ?? null, errors: errors ?? null };
		},
		[createAcademicYearTuition],
	);
}

/**
 * Returns an `update` function for the AcademicYearTuition model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the AcademicYearTuition model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: AcademicYearTuition["id"]) {
	const [updateAcademicYearTuition] = useAcademicYearTuitionUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<AcademicYearTuitionFormValues.Detail>,
			initialFormValues: AcademicYearTuitionFormValues.Detail,
		) => {
			const input = AcademicYearTuitionFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateAcademicYearTuition({ variables: { id, input }, update: updateCache });

			return { data: data?.updateAcademicYearTuition ?? null, errors: errors ?? null };
		},
		[updateAcademicYearTuition, id],
	);
}

/**
 * Returns a `del` function for the AcademicYearTuition model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: AcademicYearTuition["id"]) {
	const [deleteAcademicYearTuition] = useAcademicYearTuitionDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteAcademicYearTuition({ variables: { id }, update: updateCache });

		return { data: data?.deleteAcademicYearTuition ?? false, errors: errors ?? null };
	}, [deleteAcademicYearTuition, id]);
}

function getUpdateCacheForCreate(input: AcademicYearTuitionCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<AcademicYearTuitionCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createAcademicYearTuition;

		cache.writeQuery<AcademicYearTuitionDetailQuery, AcademicYearTuitionDetailQueryVariables>({
			query: AcademicYearTuitionDetailDocument,
			data: { academicYearTuition: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.tuitionID) {
			addToAcademicYearTuitionsOfTuitionCache(cache, input.tuitionID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "academicYearTuitionConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: AcademicYearTuitionUpdateInput,
	initialFormValues: AcademicYearTuitionFormValues.Detail,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<AcademicYearTuitionUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateAcademicYearTuition;

		if (initialFormValues.tuitionID && input.tuitionID) {
			removeFromAcademicYearTuitionsOfTuitionCache(cache, initialFormValues.tuitionID, updatedObject);
		}
		if (input.tuitionID) {
			addToAcademicYearTuitionsOfTuitionCache(cache, input.tuitionID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: AcademicYearTuition["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<AcademicYearTuitionDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteAcademicYearTuition) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.AcademicYearTuition }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "academicYearTuitionConnection" });
		cache.gc();
	};
}

function addToAcademicYearTuitionsOfTuitionCache(
	cache: ApolloCache<any>,
	targetID: Tuition["id"],
	object: Pick<AcademicYearTuition, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Tuition }),
		fields: {
			academicYearTuitions: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromAcademicYearTuitionsOfTuitionCache(
	cache: ApolloCache<any>,
	targetID: Tuition["id"],
	object: Pick<AcademicYearTuition, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Tuition }),
		fields: {
			academicYearTuitions: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<AcademicYearTuition, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment AcademicYearTuitionRef on AcademicYearTuition {
				id
			}
		`,
		data: object,
	});
}
