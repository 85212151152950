import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const serviceHubPageInfo = newPageInfo({
	title: "Services",
	description: "Pages related to the Service model.",
	exact: false,
	to: hubsPageInfo.to + "/services",
	path: hubsPageInfo.path + "/services",
	parent: hubsPageInfo,
});
