import { newPageInfo } from "@hex-insights/router";
import { Term } from "@hex-insights/verita.shared";
import { termHubPageInfo } from "../pageinfo";

type TermForRouteTitle = Pick<Term, "name">;

export const termHubDetailPageInfo = newPageInfo({
	name: "Term Detail",
	title: (e: TermForRouteTitle) => e.name,
	description: "Detail page for an term.",
	exact: false,
	isLeaf: true,
	to: (id: Term["id"]) => termHubPageInfo.to + `/${id}`,
	path: termHubPageInfo.path + "/:id(\\d+)",
	parent: termHubPageInfo,
});
