import { newPageInfo } from "@hex-insights/router";
import { admissionsPageInfo } from "../pageinfo";

export const admissionsReportsPageInfo = newPageInfo({
	title: "Reports",
	description: "Admissions reporting.",
	exact: true,
	to: admissionsPageInfo.to + "/reports",
	path: admissionsPageInfo.path + "/reports",
	parent: admissionsPageInfo,
});
