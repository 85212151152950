import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const emailAddressHubPageInfo = newPageInfo({
	title: "Email Addresses",
	description: "Pages related to the Email Address model.",
	exact: false,
	to: hubsPageInfo.to + "/email-addresses",
	path: hubsPageInfo.path + "/email-addresses",
	parent: hubsPageInfo,
});
