import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Column, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import { FormState } from "@hex-insights/forms";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	HR,
	PersonFormat,
	PersonIcon,
	RelationshipFormat,
	StudentCampusFilterButtons,
	StudentFilterFormController,
	StudentFilterFormState,
	StudentFilterFormUtils,
	StudentFilterFormValues,
	StudentFormat,
	StudentHomeRoomFilterButtons,
	StudentOrderField,
	StudentOrderFormController,
	StudentOrderFormState,
	StudentOrderFormValues,
	StudentPaginationForm,
	StudentSearchForm,
	useStudentIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { studentHubCreatePageInfo } from "../CreatePage/pageinfo";
import { studentHubDetailPageInfo } from "../DetailPage/pageinfo";
import { studentHubIndexPageInfo } from "./pageinfo";

const columns = [
	"image",
	"name",
	"dateOfBirth",
	"homeRoom",
	"homeRoomSection",
	"graduatingYear",
	"busPlan",
	"mealPlan",
	"parent1Name",
	"parent1Relationship",
	"parent1PhoneNumbers",
	"parent1EmailAddresses",
	"parent2Name",
	"parent2Relationship",
	"parent2PhoneNumbers",
	"parent2EmailAddresses",
	"hasPreviousSchooling",
	"previousSchoolInformation",
	"previousSchoolLocation",
	"allergies",
	"asthma",
	"medication",
	"medicationSchedule",
	"dietaryRestrictions",
	"healthNotes",
	"doctorName",
	"doctorPhoneNumber",
	"emergencyContactInformation",
];

const columnWidths = {
	image: "5rem",
	parent1PhoneNumbers: "20rem",
	parent1EmailAddresses: "20rem",
	parent2PhoneNumbers: "20rem",
	parent2EmailAddresses: "20rem",
	emergencyContactInformation: "20rem",
};

function orderFieldToColumn(field: StudentOrderField | null) {
	if (field === null) {
		return "id";
	}
	return StudentOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return StudentOrderFormValues.modelFieldToOrderField[column];
}

export function StudentHubIndexPage() {
	useActivePageRegistration(studentHubIndexPageInfo);

	const filterFormState = StudentFilterFormState.useFormState();
	const orderFormState = StudentOrderFormState.useFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(filterFormState.formValues, StudentFilterFormUtils.toFilterInputs);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useStudentIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		StudentFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = StudentFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.studentConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading level={1} noMargin>
					Students
				</Heading>

				<InternalLink to={studentHubCreatePageInfo.to}>New Student</InternalLink>
			</Row>

			<Column justify="spaced-start" style={{ marginBottom: "0.75rem" }}>
				<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem" overflow="wrap">
					<StudentSearchForm formState={filterFormState} />
					<StudentFilterFormController formState={filterFormState} numActiveFilters={numActiveFilters} />
					<If condition={numActiveFilters > 0}>
						<Button variant="link" size="small" onClick={clearFilters}>
							Clear
						</Button>
					</If>
					<StudentOrderFormController formState={orderFormState} />
				</Row>

				<QuickFilters filterFormState={filterFormState} />
			</Column>

			<Table.Container className="hub__index-page__table__container">
				<Table
					columns={columns}
					columnWidths={columnWidths}
					minColumnWidth="10rem"
					{...tableSortingProps}
					className="hub__index-page__table"
				>
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="image" noSort></Table.Heading>
							<Table.Heading column="name" noSort>
								Name
							</Table.Heading>
							<Table.Heading column="dateOfBirth" noSort>
								Date of Birth
							</Table.Heading>
							<Table.Heading column="homeRoom" noSort>
								Home Room
							</Table.Heading>
							<Table.Heading column="homeRoomSection" noSort>
								Home Room Section
							</Table.Heading>
							<Table.Heading column="graduatingYear">Graduating Year</Table.Heading>
							<Table.Heading column="busPlan" noSort>
								Bus Plan
							</Table.Heading>
							<Table.Heading column="mealPlan" noSort>
								Meal Plan
							</Table.Heading>
							<Table.Heading column="parent1Name" noSort>
								Parent 1 Name
							</Table.Heading>
							<Table.Heading column="parent1Relationship" noSort>
								Parent 1 Relationship
							</Table.Heading>
							<Table.Heading column="parent1PhoneNumbers" noSort>
								Parent 1 Phone Numbers
							</Table.Heading>
							<Table.Heading column="parent1EmailAddresses" noSort>
								Parent 1 Email Addresses
							</Table.Heading>
							<Table.Heading column="parent2Name" noSort>
								Parent 2 Name
							</Table.Heading>
							<Table.Heading column="parent2Relationship" noSort>
								Parent 2 Relationship
							</Table.Heading>
							<Table.Heading column="parent2PhoneNumbers" noSort>
								Parent 2 Phone Numbers
							</Table.Heading>
							<Table.Heading column="parent2EmailAddresses" noSort>
								Parent 2 Email Addresses
							</Table.Heading>
							<Table.Heading column="hasPreviousSchooling">Has Previous Schooling</Table.Heading>
							<Table.Heading column="previousSchoolInformation" noSort>
								Previous School Information
							</Table.Heading>
							<Table.Heading column="previousSchoolLocation" noSort>
								Previous School Location
							</Table.Heading>
							<Table.Heading column="allergies" noSort>
								Allergies
							</Table.Heading>
							<Table.Heading column="asthma" noSort>
								Asthma
							</Table.Heading>
							<Table.Heading column="medication" noSort>
								Medication
							</Table.Heading>
							<Table.Heading column="medicationSchedule" noSort>
								Medication Schedule
							</Table.Heading>
							<Table.Heading column="dietaryRestrictions" noSort>
								Dietary Restrictions
							</Table.Heading>
							<Table.Heading column="healthNotes" noSort>
								Health Notes
							</Table.Heading>
							<Table.Heading column="doctorName" noSort>
								Doctor Name
							</Table.Heading>
							<Table.Heading column="doctorPhoneNumber" noSort>
								Doctor Phone Number
							</Table.Heading>
							<Table.Heading column="emergencyContactInformation" noSort>
								Emergency Contact Information
							</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.studentConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="image" oneLine style={{ paddingTop: "0.1rem", paddingBottom: "0.1rem" }}>
											<Row justify="center" align="center" style={{ height: "100%" }}>
												<PersonIcon person={e.node.person} imageSize="3rem" />
											</Row>
										</Table.Cell>
										<Table.Cell column="name" oneLine>
											<InternalLink to={studentHubDetailPageInfo.to(e.node.id)}>{e.node.person.name}</InternalLink>
										</Table.Cell>
										<Table.Cell column="dateOfBirth" oneLine>
											{PersonFormat.Fields.dateOfBirth(e.node.person.dateOfBirth)}
										</Table.Cell>
										<Table.Cell column="homeRoom" oneLine>
											{e.node.homeRoomSectionStudentEnrollments[0]?.homeRoomSection.homeRoom.name}
										</Table.Cell>
										<Table.Cell column="homeRoomSection" oneLine>
											{e.node.homeRoomSectionStudentEnrollments[0]?.homeRoomSection.name}
										</Table.Cell>
										<Table.Cell column="graduatingYear" oneLine numeric>
											{StudentFormat.Fields.graduatingYear(e.node.graduatingYear)}
										</Table.Cell>
										<Table.Cell column="busPlan" oneLine>
											{e.node.busPlans.length > 0 ? "Yes" : "No"}
										</Table.Cell>
										<Table.Cell column="mealPlan" oneLine>
											{e.node.mealPlans.length > 0 ? "Yes" : "No"}
										</Table.Cell>
										<Table.Cell column="parent1Name" oneLine>
											{e.node.relationships[0]?.parent.person.name}
										</Table.Cell>
										<Table.Cell column="parent1Relationship" oneLine>
											{RelationshipFormat.Fields.relationshipType(e.node.relationships[0]?.relationshipType ?? null)}
										</Table.Cell>
										<Table.Cell column="parent1PhoneNumbers" oneLine>
											{e.node.relationships[0]?.parent.person.phoneNumbers.map((n) => n.phoneNumber).join(", ")}
										</Table.Cell>
										<Table.Cell column="parent1EmailAddresses" oneLine>
											{e.node.relationships[0]?.parent.person.emailAddresses.map((n) => n.email).join(", ")}
										</Table.Cell>
										<Table.Cell column="parent2Name" oneLine>
											{e.node.relationships[1]?.parent.person.name}
										</Table.Cell>
										<Table.Cell column="parent2Relationship" oneLine>
											{RelationshipFormat.Fields.relationshipType(e.node.relationships[1]?.relationshipType ?? null)}
										</Table.Cell>
										<Table.Cell column="parent2PhoneNumbers" oneLine>
											{e.node.relationships[1]?.parent.person.phoneNumbers.map((n) => n.phoneNumber).join(", ")}
										</Table.Cell>
										<Table.Cell column="parent2EmailAddresses" oneLine>
											{e.node.relationships[1]?.parent.person.emailAddresses.map((n) => n.email).join(", ")}
										</Table.Cell>
										<Table.Cell column="hasPreviousSchooling" oneLine>
											{StudentFormat.Fields.hasPreviousSchooling(e.node.hasPreviousSchooling)}
										</Table.Cell>
										<Table.Cell column="previousSchoolInformation" oneLine>
											{StudentFormat.Fields.previousSchoolInformation(e.node.previousSchoolInformation)}
										</Table.Cell>
										<Table.Cell column="previousSchoolLocation" oneLine>
											{StudentFormat.Fields.previousSchoolLocation(e.node.previousSchoolLocation)}
										</Table.Cell>
										<Table.Cell column="allergies" oneLine>
											{StudentFormat.Fields.allergies(e.node.allergies)}
										</Table.Cell>
										<Table.Cell column="asthma" oneLine>
											{StudentFormat.Fields.asthma(e.node.asthma)}
										</Table.Cell>
										<Table.Cell column="medication" oneLine>
											{StudentFormat.Fields.medication(e.node.medication)}
										</Table.Cell>
										<Table.Cell column="medicationSchedule" oneLine>
											{StudentFormat.Fields.medicationSchedule(e.node.medicationSchedule)}
										</Table.Cell>
										<Table.Cell column="dietaryRestrictions" oneLine>
											{StudentFormat.Fields.dietaryRestrictions(e.node.dietaryRestrictions)}
										</Table.Cell>
										<Table.Cell column="healthNotes" oneLine>
											{StudentFormat.Fields.healthNotes(e.node.healthNotes)}
										</Table.Cell>
										<Table.Cell column="doctorName" oneLine>
											{StudentFormat.Fields.doctorName(e.node.doctorName)}
										</Table.Cell>
										<Table.Cell column="doctorPhoneNumber" oneLine>
											{StudentFormat.Fields.doctorPhoneNumber(e.node.doctorPhoneNumber)}
										</Table.Cell>
										<Table.Cell column="emergencyContactInformation" oneLine>
											{StudentFormat.Fields.emergencyContactInformation(e.node.emergencyContactInformation)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<StudentPaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.studentConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}

type QuickFiltersProps = {
	filterFormState: FormState<StudentFilterFormValues.FormValues>;
};

function QuickFilters({ filterFormState }: QuickFiltersProps) {
	return (
		<Row justify="spaced-start" align="spaced-start" overflow="wrap">
			<StudentCampusFilterButtons formState={filterFormState} />
			<HR color="#eee" style={{ height: "45px" }} />
			<StudentHomeRoomFilterButtons formState={filterFormState} />
		</Row>
	);
}
