import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { EmployeeFilterInput, EmployeeOrder, useEmployeePaginationQuery } from "../../../../Utilities";

export type EmployeePaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: EmployeeFilterInput | EmployeeFilterInput[] | null;
	order: EmployeeOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the Employee model.
 */
export function EmployeePaginationForm({ formState, filters, order, setPaginationInfo }: EmployeePaginationFormProps) {
	const { loading, data } = useEmployeePaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.employeeConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
