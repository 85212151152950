import { newPageInfo } from "@hex-insights/router";
import { TrustedDevice } from "@hex-insights/verita.shared";
import { trustedDeviceHubPageInfo } from "../pageinfo";

type TrustedDeviceForRouteTitle = Pick<TrustedDevice, "name">;

export const trustedDeviceHubDetailPageInfo = newPageInfo({
	name: "Trusted Device Detail",
	title: (e: TrustedDeviceForRouteTitle) => e.name,
	description: "Detail page for an trustedDevice.",
	exact: false,
	isLeaf: true,
	to: (id: TrustedDevice["id"]) => trustedDeviceHubPageInfo.to + `/${id}`,
	path: trustedDeviceHubPageInfo.path + "/:id(\\d+)",
	parent: trustedDeviceHubPageInfo,
});
