import { newPageInfo, newRouteParamsType } from "@hex-insights/router";
import {
	SchoolStudentEnrollment,
	SchoolStudentEnrollmentFormat,
	SchoolStudentEnrollmentNameFragment,
} from "@hex-insights/verita.shared";
import { schoolStudentEnrollmentHubPageInfo } from "../pageinfo";

type SchoolStudentEnrollmentForRouteTitle = SchoolStudentEnrollmentNameFragment;

export const schoolStudentEnrollmentHubDetailPageInfo = newPageInfo({
	name: "School Student Enrollment Detail",
	title: (instance: SchoolStudentEnrollmentForRouteTitle) => SchoolStudentEnrollmentFormat.name(instance),
	description: "School Student Enrollment detail page.",
	exact: false,
	isLeaf: true,
	parent: schoolStudentEnrollmentHubPageInfo,
	to: (id: SchoolStudentEnrollment["id"]) => schoolStudentEnrollmentHubPageInfo.to + `/${id}`,
	path: schoolStudentEnrollmentHubPageInfo.path + "/:schoolStudentEnrollmentID(\\d+)",
	paramsType: newRouteParamsType(schoolStudentEnrollmentHubPageInfo.paramsType, "schoolStudentEnrollmentID"),
});
