import { IndexForms } from "@hex-insights/app-modules";
import { MealMenuFilterFormValues } from "../../../../Utilities";

export type MealMenuSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the MealMenu model.
 */
export function MealMenuSearchForm(props: MealMenuSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type MealMenuFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, MealMenuFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the MealMenu model.
 */
export function MealMenuFilterFormController(props: MealMenuFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={MealMenuFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `MealMenuFilterForm`
