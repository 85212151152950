import { Column, Row } from "@hex-insights/core";
import { BigDateDisplay, DailyReport, EventCalendarTile, StudentsQuickActions } from "@hex-insights/verita.shared";
import { useSearch } from "../../GlobalSearch/use-search";
import { DataLists, GeneralDashboardResourcesTile, GeneralDashboardTimelineTile, QuickLists } from "../Components";

export function GeneralDashboard() {
	return (
		<Row
			justify="spaced-start"
			style={{
				height: "calc(100vh - var(--header---height) - 2 * var(--main---padding-top))",
			}}
		>
			<Column
				justify="spaced-start"
				style={{
					flexGrow: 1,
					minWidth: 0,
					paddingTop: "0.5rem",
				}}
			>
				<Row justify="spaced-start" align="center">
					<BigDateDisplay />
					<DailyReport style={{ flexGrow: 1 }} />
				</Row>
				<DataLists />
				<GeneralDashboardTimelineTile />
				<GeneralDashboardResourcesTile />
			</Column>

			<Column
				justify="spaced-start"
				style={{
					width: "30%",
					maxWidth: "25rem",
					flexShrink: 0,
					backgroundColor: "#f2f9ff",
					borderRadius: "var(--general__tile---border-radius)",
					padding: "1rem",
				}}
			>
				<StudentsQuickActions useSearch={useSearch} />
				<EventCalendarTile style={{ flexGrow: 1, height: "100%", overflow: "hidden", boxShadow: "none" }} />
				<Column justify="spaced-start" verticalSpacing="0.5rem">
					<QuickLists.AttendanceTile tileStyle={{ boxShadow: "none" }} style={{ width: "100%" }} />
					<QuickLists.BusPlanListTile style={{ boxShadow: "none" }} />
					<QuickLists.MealPlanListTile style={{ boxShadow: "none" }} />
				</Column>
			</Column>
		</Row>
	);
}
