import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const quotationHubPageInfo = newPageInfo({
	title: "Quotations",
	description: "Pages related to the Quotation model.",
	exact: false,
	to: hubsPageInfo.to + "/quotations",
	path: hubsPageInfo.path + "/quotations",
	parent: hubsPageInfo,
});
