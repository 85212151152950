import { formatDateTime, stringToLocalDate } from "@hex-insights/core";
import { newPageInfo } from "@hex-insights/router";
import { budgetPageInfo, BudgetPageRouteParams } from "../pageinfo";

export type BudgetMonthPageRouteParams = BudgetPageRouteParams & {
	month: string;
};

export const budgetMonthPageInfo = newPageInfo({
	name: "Month Budget",
	title: (month: string) => formatDateTime(stringToLocalDate(month, "day"), "MMMM YYYY"),
	description: "View and manage the budget for a month.",
	exact: false,
	to: (month: string) => budgetPageInfo.to + `/${formatDateTime(stringToLocalDate(month, "day"), "YYYY-MM-DD")}`,
	path: budgetPageInfo.path + "/:month(\\d{4}-\\d{2}-01)",
	parent: budgetPageInfo,
});
