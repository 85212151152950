import { newPageInfo } from "@hex-insights/router";
import { courseSectionHubPageInfo } from "../pageinfo";

export const courseSectionHubIndexPageInfo = newPageInfo({
	name: "Course Sections Index",
	title: "",
	description: "Index of Course Sections.",
	exact: true,
	to: courseSectionHubPageInfo.to,
	path: courseSectionHubPageInfo.path,
	parent: courseSectionHubPageInfo,
});
