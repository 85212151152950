import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { CourseSectionFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the CourseSection model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => CourseSectionFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(
		initialFormValues,
		CourseSectionFilterFormValues.typeFilterObject,
	);
}
