import React from "react";
import { DateTimeField, FieldDisplayArgs, NumberField, SelectField } from "@hex-insights/forms";
import {
	ExpenseCategorySelect,
	ExpenseFormat,
	ExpenseFormValues,
	useExpenseCategorySelectLazyQuery,
} from "../../../../Utilities";
import { ExpenseCategoryLink } from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Expense model.
 */
type FieldProps<K extends keyof ExpenseFormValues.Base = keyof ExpenseFormValues.Base> = BaseFieldProps<
	Pick<ExpenseFormValues.Base, K>
>;

/**
 * Renders a field component for the `createdDate` field of the Expense model.
 */
export function CreatedDate({ formState }: FieldProps<"createdDate">) {
	return <DateTimeField formState={formState} name="createdDate" precision="day" />;
}

/**
 * Renders a field component for the `amount` field of the Expense model.
 */
export function Amount({ formState }: FieldProps<"amount">) {
	return (
		<NumberField
			formState={formState}
			name="amount"
			validationUnit={1}
			min={0}
			unit={100}
			format={ExpenseFormat.Fields.amount}
		/>
	);
}

/**
 * Renders a field component for the `notes` field of the Expense model.
 */
export function Notes({ formState }: FieldProps<"notes">) {
	return <TextAreaField formState={formState} name="notes" optional />;
}

export type ExpenseCategoryProps = FieldProps<"expenseCategoryID"> & {
	currentExpenseCategory?: ExpenseCategorySelect.ModelForOption | null;
};

/**
 * Renders a field component for the `expenseCategory` edge of the Expense model.
 */
export function ExpenseCategory({ formState, currentExpenseCategory }: ExpenseCategoryProps) {
	const [loadOptions, { loading, data }] = useExpenseCategorySelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.expenseCategoryID) {
			loadOptions();
		}
	}, [formState.formEditing.expenseCategoryID, loadOptions]);
	const options = React.useMemo(
		() => ExpenseCategorySelect.toOptions(data?.expenseCategoryConnection.edges, currentExpenseCategory),
		[data, currentExpenseCategory],
	);

	return (
		<SelectField
			formState={formState}
			name="expenseCategoryID"
			isLoading={loading}
			options={options}
			display={displayExpenseCategory}
			blankValue={null}
		/>
	);
}

function displayExpenseCategory({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ExpenseCategoryLink instance={{ id }}>{formattedValue}</ExpenseCategoryLink>;
}
