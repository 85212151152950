import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const schoolStudentEnrollmentHubPageInfo = newPageInfo({
	title: "School Student Enrollments",
	description: "Pages related to the SchoolStudentEnrollment model.",
	exact: false,
	to: hubsPageInfo.to + "/school-student-enrollments",
	path: hubsPageInfo.path + "/school-student-enrollments",
	parent: hubsPageInfo,
});
