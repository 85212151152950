import { CourseSectionPeriodNameFragment } from "../../../GraphQL";
import * as Fields from "./fields";

export { Fields };

/**
 * Formats the full name of the given instance.
 */
export function name(instance: CourseSectionPeriodNameFragment) {
	return `${Fields.dayOfWeek(instance.dayOfWeek)} ${Fields.startTimeMinutes(
		instance.startTimeMinutes,
	)} to ${Fields.endTimeMinutes(instance.endTimeMinutes)}`;
}
