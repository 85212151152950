import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { TermOrderFormValues } from "../../../../Utilities";

export type TermOrderFormControllerProps = {
	formState: FormState<TermOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the Term model.
 */
export function TermOrderFormController({ formState }: TermOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={TermOrderFormValues.fieldOptions}
			fieldTypeMapping={TermOrderFormValues.fieldTypeMapping}
		/>
	);
}
