import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Course, CourseFormat, CourseNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Course, "id"> & CourseNameFragment;

export type CourseLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Course instance.
 */
export function CourseLink(props: CourseLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Course" format={CourseFormat.name} {...props} />;
}
