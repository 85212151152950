import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { CampusHubCreatePage } from "./CreatePage";
import { campusHubCreatePageInfo } from "./CreatePage/pageinfo";
import { CampusHubDetailPage } from "./DetailPage";
import { campusHubDetailPageInfo } from "./DetailPage/pageinfo";
import { CampusHubIndexPage } from "./IndexPage";
import { campusHubIndexPageInfo } from "./IndexPage/pageinfo";
import { campusHubPageInfo } from "./pageinfo";

export function CampusHub() {
	useActivePageRegistration(campusHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...campusHubIndexPageInfo.routeProps}>
				<CampusHubIndexPage />
			</Route>
			<Route {...campusHubCreatePageInfo.routeProps}>
				<CampusHubCreatePage />
			</Route>
			<Route {...campusHubDetailPageInfo.routeProps}>
				<CampusHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
