import { newPageInfo } from "@hex-insights/router";
import { Discount } from "@hex-insights/verita.shared";
import { discountHubPageInfo } from "../pageinfo";

type DiscountForRouteTitle = Pick<Discount, "id">;

export const discountHubDetailPageInfo = newPageInfo({
	name: "Discount Detail",
	title: (e: DiscountForRouteTitle) => e.id,
	description: "Detail page for an discount.",
	exact: false,
	isLeaf: true,
	to: (id: Discount["id"]) => discountHubPageInfo.to + `/${id}`,
	path: discountHubPageInfo.path + "/:id(\\d+)",
	parent: discountHubPageInfo,
});
