import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { CourseSectionPeriodHubCreatePage } from "./CreatePage";
import { courseSectionPeriodHubCreatePageInfo } from "./CreatePage/pageinfo";
import { CourseSectionPeriodHubDetailPage } from "./DetailPage";
import { courseSectionPeriodHubDetailPageInfo } from "./DetailPage/pageinfo";
import { CourseSectionPeriodHubIndexPage } from "./IndexPage";
import { courseSectionPeriodHubIndexPageInfo } from "./IndexPage/pageinfo";
import { courseSectionPeriodHubPageInfo } from "./pageinfo";

export function CourseSectionPeriodHub() {
	useActivePageRegistration(courseSectionPeriodHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...courseSectionPeriodHubIndexPageInfo.routeProps}>
				<CourseSectionPeriodHubIndexPage />
			</Route>
			<Route {...courseSectionPeriodHubCreatePageInfo.routeProps}>
				<CourseSectionPeriodHubCreatePage />
			</Route>
			<Route {...courseSectionPeriodHubDetailPageInfo.routeProps}>
				<CourseSectionPeriodHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
