import { Permission, PermissionGroup, User } from "../../GraphQL";

export type Base = {
	name: PermissionGroup["name"];
	description: PermissionGroup["description"];
	permissionIDs: Permission["id"][];
	userIDs: User["id"][];
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	name: "",
	description: "",
	permissionIDs: [],
	userIDs: [],
};
