import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import {
	EnrollmentInvitationFilterInput,
	EnrollmentInvitationOrder,
	useEnrollmentInvitationPaginationQuery,
} from "../../../../Utilities";

export type EnrollmentInvitationPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: EnrollmentInvitationFilterInput | EnrollmentInvitationFilterInput[] | null;
	order: EnrollmentInvitationOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the EnrollmentInvitation model.
 */
export function EnrollmentInvitationPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: EnrollmentInvitationPaginationFormProps) {
	const { loading, data } = useEnrollmentInvitationPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.enrollmentInvitationConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
