import { LessonPlan } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the courseDate field of the LessonPlan model.
 */
export function courseDate(value: LessonPlan["courseDate"] | null): string {
	return toString(value);
}

/**
 * Formats the lessonDescription field of the LessonPlan model.
 */
export function lessonDescription(value: LessonPlan["lessonDescription"]): string {
	return toString(value);
}

/**
 * Formats the requiredResourcesAndMaterials field of the LessonPlan model.
 */
export function requiredResourcesAndMaterials(value: LessonPlan["requiredResourcesAndMaterials"]): string {
	return toString(value);
}

/**
 * Formats the stationOneDescription field of the LessonPlan model.
 */
export function stationOneDescription(value: LessonPlan["stationOneDescription"]): string {
	return toString(value);
}

/**
 * Formats the stationTwoDescription field of the LessonPlan model.
 */
export function stationTwoDescription(value: LessonPlan["stationTwoDescription"]): string {
	return toString(value);
}

/**
 * Formats the stationThreeDescription field of the LessonPlan model.
 */
export function stationThreeDescription(value: LessonPlan["stationThreeDescription"]): string {
	return toString(value);
}

/**
 * Formats the differentiationDescription field of the LessonPlan model.
 */
export function differentiationDescription(value: LessonPlan["differentiationDescription"]): string {
	return toString(value);
}

/**
 * Formats the assessmentDescription field of the LessonPlan model.
 */
export function assessmentDescription(value: LessonPlan["assessmentDescription"]): string {
	return toString(value);
}

/**
 * Formats the notes field of the LessonPlan model.
 */
export function notes(value: LessonPlan["notes"]): string {
	return toString(value);
}
