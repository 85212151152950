import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import {
	BillingSetupSubmissionFilterInput,
	BillingSetupSubmissionOrder,
	useBillingSetupSubmissionPaginationQuery,
} from "../../../../Utilities";

export type PaginationProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: BillingSetupSubmissionFilterInput | BillingSetupSubmissionFilterInput[] | null;
	order: BillingSetupSubmissionOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the BillingSetupSubmission model.
 */
export function Pagination({ formState, filters, order, setPaginationInfo }: PaginationProps) {
	const { loading, data } = useBillingSetupSubmissionPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.billingSetupSubmissionConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
