import { newPageInfo } from "@hex-insights/router";
import { lessonPlanHubPageInfo } from "../pageinfo";

export const lessonPlanHubIndexPageInfo = newPageInfo({
	name: "Lesson Plans Index",
	title: "",
	description: "Index of Lesson Plans.",
	exact: true,
	to: lessonPlanHubPageInfo.to,
	path: lessonPlanHubPageInfo.path,
	parent: lessonPlanHubPageInfo,
});
