import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	ClassYear,
	Course,
	CourseCreateInput,
	CourseCreateMutation,
	CourseDeleteMutation,
	CourseDetailDocument,
	CourseDetailQuery,
	CourseDetailQueryVariables,
	CourseSection,
	CourseUpdateInput,
	CourseUpdateMutation,
	graphTypeNames,
	HomeRoom,
	LearningObjective,
	useCourseCreateMutation,
	useCourseDeleteMutation,
	useCourseUpdateMutation,
} from "../../GraphQL";
import { CourseFormConversion } from "../ModelFormConversion";
import { CourseFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the Course model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Course model.
 */
export function useCreate() {
	const [createCourse] = useCourseCreateMutation();

	return React.useCallback(
		async (formValues: CourseFormValues.Create) => {
			const input = CourseFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createCourse({ variables: { input }, update: updateCache });

			return { data: data?.createCourse ?? null, errors: errors ?? null };
		},
		[createCourse],
	);
}

/**
 * Returns an `update` function for the Course model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Course model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Course["id"]) {
	const [updateCourse] = useCourseUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<CourseFormValues.Detail>, initialFormValues: CourseFormValues.Detail) => {
			const input = CourseFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateCourse({ variables: { id, input }, update: updateCache });

			return { data: data?.updateCourse ?? null, errors: errors ?? null };
		},
		[updateCourse, id],
	);
}

/**
 * Returns a `del` function for the Course model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Course["id"]) {
	const [deleteCourse] = useCourseDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteCourse({ variables: { id }, update: updateCache });

		return { data: data?.deleteCourse ?? false, errors: errors ?? null };
	}, [deleteCourse, id]);
}

function getUpdateCacheForCreate(input: CourseCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<CourseCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createCourse;

		cache.writeQuery<CourseDetailQuery, CourseDetailQueryVariables>({
			query: CourseDetailDocument,
			data: { course: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.classYearIDs) {
			for (let i = 0; i < input.classYearIDs.length; i++) {
				addToCoursesOfClassYearCache(cache, input.classYearIDs[i], createdObject);
			}
		}

		if (input.courseSectionIDs) {
			for (let i = 0; i < input.courseSectionIDs.length; i++) {
				addToCourseOfCourseSectionCache(cache, input.courseSectionIDs[i], createdObject);
			}
		}

		if (input.homeRoomID) {
			addToCoursesOfHomeRoomCache(cache, input.homeRoomID, createdObject);
		}

		if (input.learningObjectiveIDs) {
			for (let i = 0; i < input.learningObjectiveIDs.length; i++) {
				addToCourseOfLearningObjectiveCache(cache, input.learningObjectiveIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "courseConnection" });
	};
}

function getUpdateCacheForUpdate(input: CourseUpdateInput, initialFormValues: CourseFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<CourseUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateCourse;

		if (input.addClassYearIDs) {
			for (let i = 0; i < input.addClassYearIDs.length; i++) {
				addToCoursesOfClassYearCache(cache, input.addClassYearIDs[i], updatedObject);
			}
		}
		if (input.removeClassYearIDs) {
			for (let i = 0; i < input.removeClassYearIDs.length; i++) {
				removeFromCoursesOfClassYearCache(cache, input.removeClassYearIDs[i], updatedObject);
			}
		}

		if (input.addCourseSectionIDs) {
			for (let i = 0; i < input.addCourseSectionIDs.length; i++) {
				addToCourseOfCourseSectionCache(cache, input.addCourseSectionIDs[i], updatedObject);
			}
		}
		if (input.removeCourseSectionIDs) {
			for (let i = 0; i < input.removeCourseSectionIDs.length; i++) {
				removeFromCourseOfCourseSectionCache(cache, input.removeCourseSectionIDs[i], updatedObject);
			}
		}

		if (initialFormValues.homeRoomID && input.homeRoomID) {
			removeFromCoursesOfHomeRoomCache(cache, initialFormValues.homeRoomID, updatedObject);
		}
		if (input.homeRoomID) {
			addToCoursesOfHomeRoomCache(cache, input.homeRoomID, updatedObject);
		}

		if (input.addLearningObjectiveIDs) {
			for (let i = 0; i < input.addLearningObjectiveIDs.length; i++) {
				addToCourseOfLearningObjectiveCache(cache, input.addLearningObjectiveIDs[i], updatedObject);
			}
		}
		if (input.removeLearningObjectiveIDs) {
			for (let i = 0; i < input.removeLearningObjectiveIDs.length; i++) {
				removeFromCourseOfLearningObjectiveCache(cache, input.removeLearningObjectiveIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: Course["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<CourseDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteCourse) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Course }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "courseConnection" });
		cache.gc();
	};
}

function addToCoursesOfClassYearCache(cache: ApolloCache<any>, targetID: ClassYear["id"], object: Pick<Course, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ClassYear }),
		fields: {
			courses: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromCoursesOfClassYearCache(
	cache: ApolloCache<any>,
	targetID: ClassYear["id"],
	object: Pick<Course, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ClassYear }),
		fields: {
			courses: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToCourseOfCourseSectionCache(
	cache: ApolloCache<any>,
	targetID: CourseSection["id"],
	object: Pick<Course, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSection }),
		fields: {
			course: () => objectRef,
			courseID: () => object.id,
		},
	});
}

function removeFromCourseOfCourseSectionCache(
	cache: ApolloCache<any>,
	targetID: CourseSection["id"],
	_object: Pick<Course, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSection }),
		fields: {
			course: () => null,
			courseID: () => null,
		},
	});
}

function addToCoursesOfHomeRoomCache(cache: ApolloCache<any>, targetID: HomeRoom["id"], object: Pick<Course, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.HomeRoom }),
		fields: {
			courses: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromCoursesOfHomeRoomCache(
	cache: ApolloCache<any>,
	targetID: HomeRoom["id"],
	object: Pick<Course, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.HomeRoom }),
		fields: {
			courses: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToCourseOfLearningObjectiveCache(
	cache: ApolloCache<any>,
	targetID: LearningObjective["id"],
	object: Pick<Course, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.LearningObjective }),
		fields: {
			course: () => objectRef,
			courseID: () => object.id,
		},
	});
}

function removeFromCourseOfLearningObjectiveCache(
	cache: ApolloCache<any>,
	targetID: LearningObjective["id"],
	_object: Pick<Course, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.LearningObjective }),
		fields: {
			course: () => null,
			courseID: () => null,
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Course, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment CourseRef on Course {
				id
			}
		`,
		data: object,
	});
}
