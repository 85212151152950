import React from "react";
import { Heading } from "@hex-insights/core";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import {
	SchoolAttendanceRecordForm,
	SchoolAttendanceRecordFormValues,
	SchoolAttendanceRecordMutation,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { schoolAttendanceRecordHubDetailPageInfo } from "../DetailPage/pageinfo";
import { schoolAttendanceRecordHubCreatePageInfo } from "./pageinfo";

export function SchoolAttendanceRecordHubCreatePage() {
	useActivePageRegistration(schoolAttendanceRecordHubCreatePageInfo);

	const create = SchoolAttendanceRecordMutation.useCreate();
	const createdIDRef = React.useRef<string | null>(null);
	const { push: pushHistory } = useHistory();

	const applyCreate = React.useCallback(
		async (formValues: SchoolAttendanceRecordFormValues.Create) => {
			const { data, errors } = await create(formValues);
			createdIDRef.current = data?.id ?? null;
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		if (createdIDRef.current !== null) {
			pushHistory(schoolAttendanceRecordHubDetailPageInfo.to(createdIDRef.current));
		}
	}, [pushHistory]);

	return (
		<Main>
			<Heading level={1}>New School Attendance Record</Heading>

			<SchoolAttendanceRecordForm.ControlledCreate applyCreate={applyCreate} onSuccess={onSuccess} />
		</Main>
	);
}
