import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { DocumentResourceCreateInput, DocumentResourceDetailQuery, DocumentResourceUpdateInput } from "../../GraphQL";
import { DocumentResourceFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the DocumentResource model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: DocumentResourceFormValues.Create): DocumentResourceCreateInput {
	const inputFormValues = omitKeys(formValues, ["hasImage", "isFile"]);
	const input: NullPartial<DocumentResourceCreateInput> = { ...inputFormValues };
	return input as DocumentResourceCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the DocumentResource model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<DocumentResourceFormValues.Detail>,
	initialFormValues: Partial<DocumentResourceFormValues.Detail>,
): DocumentResourceUpdateInput {
	const inputFormValues = omitKeys(formValues, [
		"hasImage",
		"isFile",
		"createdAt",
		"authorID",
		"documentResourceFolderIDs",
	]);
	const input: DocumentResourceUpdateInput = { ...inputFormValues };

	if (formValues.image === null) {
		input.clearImage = true;
	}

	if (formValues.file === null) {
		input.clearFile = true;
	}

	if (formValues.documentResourceFolderIDs !== undefined) {
		const { added, removed } = getArrayDiff(
			initialFormValues.documentResourceFolderIDs ?? [],
			formValues.documentResourceFolderIDs,
		);
		input.addDocumentResourceFolderIDs = added;
		input.removeDocumentResourceFolderIDs = removed;
	}

	return input;
}

/**
 * Converts a DocumentResource instance to its corresponding detail form values.
 *
 * @param documentResource The instance to convert.
 */
export function toFormValues(
	documentResource: DocumentResourceDetailQuery["documentResource"],
): DocumentResourceFormValues.Detail {
	return {
		name: documentResource.name,
		description: documentResource.description,
		hasImage: documentResource.hasImage,
		image: documentResource.imageURL !== "" ? new File([], "image") : null,
		isFile: documentResource.isFile,
		file: documentResource.fileURL !== "" ? new File([], "file") : null,
		resourceURL: documentResource.resourceURL,
		createdAt: documentResource.createdAt,
		authorID: documentResource.author?.id ?? null,
		documentResourceFolderIDs: documentResource.documentResourceFolders.map((e) => e.id),
	};
}
