import { IndexForms } from "@hex-insights/app-modules";
import { SchoolStudentEnrollmentOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the SchoolStudentEnrollment model.
 */
export function useOrderFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		SchoolStudentEnrollmentOrderFormValues.initial,
		SchoolStudentEnrollmentOrderFormValues.fieldValues,
	);
}
