import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { CourseSectionHubCreatePage } from "./CreatePage";
import { courseSectionHubCreatePageInfo } from "./CreatePage/pageinfo";
import { CourseSectionHubDetailPage } from "./DetailPage";
import { courseSectionHubDetailPageInfo } from "./DetailPage/pageinfo";
import { CourseSectionHubIndexPage } from "./IndexPage";
import { courseSectionHubIndexPageInfo } from "./IndexPage/pageinfo";
import { courseSectionHubPageInfo } from "./pageinfo";

export function CourseSectionHub() {
	useActivePageRegistration(courseSectionHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...courseSectionHubIndexPageInfo.routeProps}>
				<CourseSectionHubIndexPage />
			</Route>
			<Route {...courseSectionHubCreatePageInfo.routeProps}>
				<CourseSectionHubCreatePage />
			</Route>
			<Route {...courseSectionHubDetailPageInfo.routeProps}>
				<CourseSectionHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
