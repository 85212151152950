import { newPageInfo } from "@hex-insights/router";
import { DocumentResourceUserViewRecord } from "@hex-insights/verita.shared";
import { documentResourceUserViewRecordHubPageInfo } from "../pageinfo";

type DocumentResourceUserViewRecordForRouteTitle = Pick<DocumentResourceUserViewRecord, "id">;

export const documentResourceUserViewRecordHubDetailPageInfo = newPageInfo({
	name: "Document Resource User View Record Detail",
	title: (e: DocumentResourceUserViewRecordForRouteTitle) => e.id,
	description: "Detail page for an documentResourceUserViewRecord.",
	exact: false,
	isLeaf: true,
	to: (id: DocumentResourceUserViewRecord["id"]) => documentResourceUserViewRecordHubPageInfo.to + `/${id}`,
	path: documentResourceUserViewRecordHubPageInfo.path + "/:id(\\d+)",
	parent: documentResourceUserViewRecordHubPageInfo,
});
