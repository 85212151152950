import { IndexForms } from "@hex-insights/app-modules";
import { InvoiceLineItemOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the InvoiceLineItem model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		InvoiceLineItemOrderFormValues.initial,
		InvoiceLineItemOrderFormValues.fieldValues,
	);
}
