import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	graphTypeNames,
	User,
	UserGroup,
	UserGroupCreateInput,
	UserGroupCreateMutation,
	UserGroupDeleteMutation,
	UserGroupDetailDocument,
	UserGroupDetailQuery,
	UserGroupDetailQueryVariables,
	UserGroupUpdateInput,
	UserGroupUpdateMutation,
	useUserGroupCreateMutation,
	useUserGroupDeleteMutation,
	useUserGroupUpdateMutation,
} from "../../GraphQL";
import { UserGroupFormConversion } from "../ModelFormConversion";
import { UserGroupFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the UserGroup model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the UserGroup model.
 */
export function useCreate() {
	const [createUserGroup] = useUserGroupCreateMutation();

	return React.useCallback(
		async (formValues: UserGroupFormValues.Create) => {
			const input = UserGroupFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createUserGroup({ variables: { input }, update: updateCache });

			return { data: data?.createUserGroup ?? null, errors: errors ?? null };
		},
		[createUserGroup],
	);
}

/**
 * Returns an `update` function for the UserGroup model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the UserGroup model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: UserGroup["id"]) {
	const [updateUserGroup] = useUserGroupUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<UserGroupFormValues.Detail>, initialFormValues: UserGroupFormValues.Detail) => {
			const input = UserGroupFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateUserGroup({ variables: { id, input }, update: updateCache });

			return { data: data?.updateUserGroup ?? null, errors: errors ?? null };
		},
		[updateUserGroup, id],
	);
}

/**
 * Returns a `del` function for the UserGroup model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: UserGroup["id"]) {
	const [deleteUserGroup] = useUserGroupDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteUserGroup({ variables: { id }, update: updateCache });

		return { data: data?.deleteUserGroup ?? false, errors: errors ?? null };
	}, [deleteUserGroup, id]);
}

function getUpdateCacheForCreate(input: UserGroupCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<UserGroupCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createUserGroup;

		cache.writeQuery<UserGroupDetailQuery, UserGroupDetailQueryVariables>({
			query: UserGroupDetailDocument,
			data: { userGroup: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.userIDs) {
			for (let i = 0; i < input.userIDs.length; i++) {
				addToUserGroupsOfUserCache(cache, input.userIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "userGroupConnection" });
	};
}

function getUpdateCacheForUpdate(input: UserGroupUpdateInput, _initialFormValues: UserGroupFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<UserGroupUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateUserGroup;

		if (input.addUserIDs) {
			for (let i = 0; i < input.addUserIDs.length; i++) {
				addToUserGroupsOfUserCache(cache, input.addUserIDs[i], updatedObject);
			}
		}
		if (input.removeUserIDs) {
			for (let i = 0; i < input.removeUserIDs.length; i++) {
				removeFromUserGroupsOfUserCache(cache, input.removeUserIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: UserGroup["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<UserGroupDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteUserGroup) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.UserGroup }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "userGroupConnection" });
		cache.gc();
	};
}

function addToUserGroupsOfUserCache(cache: ApolloCache<any>, targetID: User["id"], object: Pick<UserGroup, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.User }),
		fields: {
			userGroups: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromUserGroupsOfUserCache(cache: ApolloCache<any>, targetID: User["id"], object: Pick<UserGroup, "id">) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.User }),
		fields: {
			userGroups: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<UserGroup, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment UserGroupRef on UserGroup {
				id
			}
		`,
		data: object,
	});
}
