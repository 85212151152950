import { newPageInfo } from "@hex-insights/router";
import { personHubPageInfo } from "../pageinfo";

export const personHubIndexPageInfo = newPageInfo({
	name: "People Index",
	title: "",
	description: "Index of People.",
	exact: true,
	to: personHubPageInfo.to,
	path: personHubPageInfo.path,
	parent: personHubPageInfo,
});
