import { IndexForms } from "@hex-insights/app-modules";
import { TermFilterFormValues } from "../../../../Utilities";

export type TermSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the Term model.
 */
export function TermSearchForm(props: TermSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type TermFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, TermFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Term model.
 */
export function TermFilterFormController(props: TermFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={TermFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `TermFilterForm`
