import { newPageInfo } from "@hex-insights/router";
import { parentHubPageInfo } from "../pageinfo";

export const parentHubCreatePageInfo = newPageInfo({
	name: "New Parent",
	title: "New",
	description: "Create a new Parent record.",
	exact: true,
	to: parentHubPageInfo.to + "/new",
	path: parentHubPageInfo.path + "/new",
	parent: parentHubPageInfo,
});
