import { CalendarEvent } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the name field of the CalendarEvent model.
 */
export function name(value: CalendarEvent["name"]): string {
	return toString(value);
}

/**
 * Formats the description field of the CalendarEvent model.
 */
export function description(value: CalendarEvent["description"]): string {
	return toString(value);
}

/**
 * Formats the isAllDay field of the CalendarEvent model.
 */
export function isAllDay(value: CalendarEvent["isAllDay"]): string {
	return toString(value);
}

/**
 * Formats the startTime field of the CalendarEvent model.
 */
export function startTime(value: CalendarEvent["startTime"] | null): string {
	return toString(value);
}

/**
 * Formats the endTime field of the CalendarEvent model.
 */
export function endTime(value: CalendarEvent["endTime"] | null): string {
	return toString(value);
}

/**
 * Formats the isPublic field of the CalendarEvent model.
 */
export function isPublic(value: CalendarEvent["isPublic"]): string {
	return toString(value);
}

/**
 * Formats the createdAt field of the CalendarEvent model.
 */
export function createdAt(value: CalendarEvent["createdAt"] | null): string {
	return toString(value);
}
