import { newPageInfo, newRouteParamsType } from "@hex-insights/router";
import { EnrollmentApplicationNote } from "@hex-insights/verita.shared";
import { enrollmentApplicationNoteHubPageInfo } from "../pageinfo";

type EnrollmentApplicationNoteForRouteTitle = Pick<EnrollmentApplicationNote, "id">;

export const enrollmentApplicationNoteHubDetailPageInfo = newPageInfo({
	name: "Enrollment Application Note Detail",
	title: (e: EnrollmentApplicationNoteForRouteTitle) => e.id,
	description: "Detail page for an enrollmentApplicationNote.",
	exact: false,
	isLeaf: true,
	parent: enrollmentApplicationNoteHubPageInfo,
	to: (id: EnrollmentApplicationNote["id"]) => enrollmentApplicationNoteHubPageInfo.to + `/${id}`,
	path: enrollmentApplicationNoteHubPageInfo.path + "/:enrollmentApplicationNoteID(\\d+)",
	paramsType: newRouteParamsType(enrollmentApplicationNoteHubPageInfo.paramsType, "enrollmentApplicationNoteID"),
});
