import { newPageInfo } from "@hex-insights/router";
import { enrollmentApplicationNoteHubPageInfo } from "../pageinfo";

export const enrollmentApplicationNoteHubIndexPageInfo = newPageInfo({
	name: "Enrollment Application Notes Index",
	title: "",
	description: "Index of Enrollment Application Notes.",
	exact: true,
	to: enrollmentApplicationNoteHubPageInfo.to,
	path: enrollmentApplicationNoteHubPageInfo.path,
	parent: enrollmentApplicationNoteHubPageInfo,
});
