import { newPageInfo } from "@hex-insights/router";
import { EnrollmentApplicationSubmission } from "@hex-insights/verita.shared";
import { enrollmentApplicationSubmissionHubPageInfo } from "../pageinfo";

type EnrollmentApplicationSubmissionForRouteTitle = Pick<EnrollmentApplicationSubmission, "id">;

export const enrollmentApplicationSubmissionHubDetailPageInfo = newPageInfo({
	name: "Enrollment Application Submission Detail",
	title: (e: EnrollmentApplicationSubmissionForRouteTitle) => e.id,
	description: "Detail page for an enrollmentApplicationSubmission.",
	exact: false,
	isLeaf: true,
	to: (id: EnrollmentApplicationSubmission["id"]) => enrollmentApplicationSubmissionHubPageInfo.to + `/${id}`,
	path: enrollmentApplicationSubmissionHubPageInfo.path + "/:id(\\d+)",
	parent: enrollmentApplicationSubmissionHubPageInfo,
});
