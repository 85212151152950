import { AcademicYear, ClassYear, SchoolStudentEnrollment, Student } from "../../GraphQL";

export type Base = {
	createdDate: SchoolStudentEnrollment["createdDate"] | null;
	paymentDueDate: SchoolStudentEnrollment["paymentDueDate"] | null;
	hasPaidFee: SchoolStudentEnrollment["hasPaidFee"];
	isVoided: SchoolStudentEnrollment["isVoided"];
	academicYearID: AcademicYear["id"] | null;
	classYearID: ClassYear["id"] | null;
	studentID: Student["id"] | null;
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	createdDate: null,
	paymentDueDate: null,
	hasPaidFee: false,
	isVoided: false,
	academicYearID: null,
	classYearID: null,
	studentID: null,
};
