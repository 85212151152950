import { newPageInfo } from "@hex-insights/router";
import { contractInstallmentHubPageInfo } from "../pageinfo";

export const contractInstallmentHubCreatePageInfo = newPageInfo({
	name: "New Contract Installment",
	title: "New",
	description: "Create a new Contract Installment record.",
	exact: true,
	to: contractInstallmentHubPageInfo.to + "/new",
	path: contractInstallmentHubPageInfo.path + "/new",
	parent: contractInstallmentHubPageInfo,
});
