import { newPageInfo } from "@hex-insights/router";
import { employeeHubPageInfo } from "../pageinfo";

export const employeeHubCreatePageInfo = newPageInfo({
	name: "New Employee",
	title: "New",
	description: "Create a new Employee record.",
	exact: true,
	to: employeeHubPageInfo.to + "/new",
	path: employeeHubPageInfo.path + "/new",
	parent: employeeHubPageInfo,
});
