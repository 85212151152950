import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	CourseSection,
	graphTypeNames,
	HomeRoom,
	HomeRoomSection,
	HomeRoomSectionCreateInput,
	HomeRoomSectionCreateMutation,
	HomeRoomSectionDeleteMutation,
	HomeRoomSectionDetailDocument,
	HomeRoomSectionDetailQuery,
	HomeRoomSectionDetailQueryVariables,
	HomeRoomSectionUpdateInput,
	HomeRoomSectionUpdateMutation,
	Room,
	Term,
	useHomeRoomSectionCreateMutation,
	useHomeRoomSectionDeleteMutation,
	useHomeRoomSectionUpdateMutation,
} from "../../GraphQL";
import { HomeRoomSectionFormConversion } from "../ModelFormConversion";
import { HomeRoomSectionFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the HomeRoomSection model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the HomeRoomSection model.
 */
export function useCreate() {
	const [createHomeRoomSection] = useHomeRoomSectionCreateMutation();

	return React.useCallback(
		async (formValues: HomeRoomSectionFormValues.Create) => {
			const input = HomeRoomSectionFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createHomeRoomSection({ variables: { input }, update: updateCache });

			return { data: data?.createHomeRoomSection ?? null, errors: errors ?? null };
		},
		[createHomeRoomSection],
	);
}

/**
 * Returns an `update` function for the HomeRoomSection model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the HomeRoomSection model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: HomeRoomSection["id"]) {
	const [updateHomeRoomSection] = useHomeRoomSectionUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<HomeRoomSectionFormValues.Detail>,
			initialFormValues: HomeRoomSectionFormValues.Detail,
		) => {
			const input = HomeRoomSectionFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateHomeRoomSection({ variables: { id, input }, update: updateCache });

			return { data: data?.updateHomeRoomSection ?? null, errors: errors ?? null };
		},
		[updateHomeRoomSection, id],
	);
}

/**
 * Returns a `del` function for the HomeRoomSection model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: HomeRoomSection["id"]) {
	const [deleteHomeRoomSection] = useHomeRoomSectionDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteHomeRoomSection({ variables: { id }, update: updateCache });

		return { data: data?.deleteHomeRoomSection ?? false, errors: errors ?? null };
	}, [deleteHomeRoomSection, id]);
}

export function getUpdateCacheForCreate(input: HomeRoomSectionCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<HomeRoomSectionCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createHomeRoomSection;

		cache.writeQuery<HomeRoomSectionDetailQuery, HomeRoomSectionDetailQueryVariables>({
			query: HomeRoomSectionDetailDocument,
			data: { homeRoomSection: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.courseSectionIDs) {
			for (let i = 0; i < input.courseSectionIDs.length; i++) {
				addToHomeRoomSectionOfCourseSectionCache(cache, input.courseSectionIDs[i], createdObject);
			}
		}

		if (input.homeRoomID) {
			addToHomeRoomSectionsOfHomeRoomCache(cache, input.homeRoomID, createdObject);
		}

		if (input.roomID) {
			addToHomeRoomSectionsOfRoomCache(cache, input.roomID, createdObject);
		}

		if (input.termID) {
			addToHomeRoomSectionsOfTermCache(cache, input.termID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "homeRoomSectionConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: HomeRoomSectionUpdateInput,
	initialFormValues: HomeRoomSectionFormValues.Detail,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<HomeRoomSectionUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateHomeRoomSection;

		if (input.addCourseSectionIDs) {
			for (let i = 0; i < input.addCourseSectionIDs.length; i++) {
				addToHomeRoomSectionOfCourseSectionCache(cache, input.addCourseSectionIDs[i], updatedObject);
			}
		}
		if (input.removeCourseSectionIDs) {
			for (let i = 0; i < input.removeCourseSectionIDs.length; i++) {
				removeFromHomeRoomSectionOfCourseSectionCache(cache, input.removeCourseSectionIDs[i], updatedObject);
			}
		}

		if (initialFormValues.homeRoomID && input.homeRoomID) {
			removeFromHomeRoomSectionsOfHomeRoomCache(cache, initialFormValues.homeRoomID, updatedObject);
		}
		if (input.homeRoomID) {
			addToHomeRoomSectionsOfHomeRoomCache(cache, input.homeRoomID, updatedObject);
		}

		if (initialFormValues.roomID && input.roomID) {
			removeFromHomeRoomSectionsOfRoomCache(cache, initialFormValues.roomID, updatedObject);
		}
		if (input.roomID) {
			addToHomeRoomSectionsOfRoomCache(cache, input.roomID, updatedObject);
		}

		if (initialFormValues.termID && input.termID) {
			removeFromHomeRoomSectionsOfTermCache(cache, initialFormValues.termID, updatedObject);
		}
		if (input.termID) {
			addToHomeRoomSectionsOfTermCache(cache, input.termID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: HomeRoomSection["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<HomeRoomSectionDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteHomeRoomSection) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.HomeRoomSection }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "homeRoomSectionConnection" });
		cache.gc();
	};
}

function addToHomeRoomSectionOfCourseSectionCache(
	cache: ApolloCache<any>,
	targetID: CourseSection["id"],
	object: Pick<HomeRoomSection, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSection }),
		fields: {
			homeRoomSection: () => objectRef,
			homeRoomSectionID: () => object.id,
		},
	});
}

function removeFromHomeRoomSectionOfCourseSectionCache(
	cache: ApolloCache<any>,
	targetID: CourseSection["id"],
	_object: Pick<HomeRoomSection, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSection }),
		fields: {
			homeRoomSection: () => null,
			homeRoomSectionID: () => null,
		},
	});
}

function addToHomeRoomSectionsOfHomeRoomCache(
	cache: ApolloCache<any>,
	targetID: HomeRoom["id"],
	object: Pick<HomeRoomSection, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.HomeRoom }),
		fields: {
			homeRoomSections: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromHomeRoomSectionsOfHomeRoomCache(
	cache: ApolloCache<any>,
	targetID: HomeRoom["id"],
	object: Pick<HomeRoomSection, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.HomeRoom }),
		fields: {
			homeRoomSections: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToHomeRoomSectionsOfRoomCache(
	cache: ApolloCache<any>,
	targetID: Room["id"],
	object: Pick<HomeRoomSection, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Room }),
		fields: {
			homeRoomSections: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromHomeRoomSectionsOfRoomCache(
	cache: ApolloCache<any>,
	targetID: Room["id"],
	object: Pick<HomeRoomSection, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Room }),
		fields: {
			homeRoomSections: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToHomeRoomSectionsOfTermCache(
	cache: ApolloCache<any>,
	targetID: Term["id"],
	object: Pick<HomeRoomSection, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Term }),
		fields: {
			homeRoomSections: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromHomeRoomSectionsOfTermCache(
	cache: ApolloCache<any>,
	targetID: Term["id"],
	object: Pick<HomeRoomSection, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Term }),
		fields: {
			homeRoomSections: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<HomeRoomSection, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment HomeRoomSectionRef on HomeRoomSection {
				id
			}
		`,
		data: object,
	});
}
