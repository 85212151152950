import { newPageInfo } from "@hex-insights/router";
import { dashboardsPageInfo } from "../pageinfo";

export const dashboardsFinancialsPageInfo = newPageInfo({
	name: "Financials Dashboard",
	title: "Financials",
	description: "Financials dashboard.",
	exact: true,
	to: dashboardsPageInfo.to + "/financials",
	path: dashboardsPageInfo.path + "/financials",
	parent: dashboardsPageInfo,
});
