import React from "react";
import {
	CreateFunction,
	onCreateSubmit,
	onUpdateSubmit,
	UpdateFunction,
	useApplyCreate,
	useApplyUpdate,
} from "@hex-insights/app-modules";
import { DistributiveOmit, Row } from "@hex-insights/core";
import {
	Form,
	FormEditButtonPair,
	FormState,
	FormSubmitFunction,
	SubmitButton,
	useInternalField,
	useOnSuccess,
	ValidationDisplayPolicy,
} from "@hex-insights/forms";
import {
	EnrollmentApplication,
	EnrollmentApplicationNoteCreateMutation,
	EnrollmentApplicationNoteDetailQuery,
	EnrollmentApplicationNoteFormConversion,
	EnrollmentApplicationNoteFormState,
	EnrollmentApplicationNoteFormValues,
	EnrollmentApplicationNoteMutation,
	EnrollmentApplicationNoteUpdateMutation,
} from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledCreateProps = Pick<
	CreateProps<EnrollmentApplicationNoteCreateMutation["createEnrollmentApplicationNote"]>,
	"onSuccess" | "formNameSuffix"
> & {
	formStateArgs?: Partial<EnrollmentApplicationNoteFormState.UseCreateFormStateArgs>;
};

/**
 * Renders the create form of the EnrollmentApplicationNote model using an internally managed form state.
 */
export function ControlledCreate({ formStateArgs, ...props }: ControlledCreateProps) {
	const formState = EnrollmentApplicationNoteFormState.useCreateFormState(formStateArgs);
	const create = EnrollmentApplicationNoteMutation.useCreate();

	return <Create formState={formState} create={create} {...props} />;
}

export type CreateProps<T> = {
	formState: FormState<EnrollmentApplicationNoteFormValues.Create>;
	create: CreateFunction<EnrollmentApplicationNoteFormValues.Create, T>;
	onSuccess: (data: T) => void;
	formNameSuffix?: string;
};

/**
 * Renders the create form of the EnrollmentApplicationNote model using the given form state.
 */
export function Create<T>({ formState, create, onSuccess, formNameSuffix }: CreateProps<T>) {
	const { applyCreate, resultRef } = useApplyCreate(create);
	const onSubmit = React.useCallback<FormSubmitFunction<EnrollmentApplicationNoteFormValues.Create>>(
		async ({ formValues }) => onCreateSubmit(formValues, applyCreate),
		[applyCreate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	const formName = "enrollmentApplicationNote.create" + (formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form name={formName} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Form.Column>
				<CreateFields formState={formState} />

				<Form.ActionBar>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Form.ActionBar>
			</Form.Column>
		</Form>
	);
}

type CreateFieldsProps = {
	formState: FormState<EnrollmentApplicationNoteFormValues.Create>;
};

function CreateFields({ formState }: CreateFieldsProps) {
	return (
		<React.Fragment>
			<Field.Body formState={formState} />
			<Field.EnrollmentApplication formState={formState} />
		</React.Fragment>
	);
}

export type ControlledCreateOnEnrollmentApplicationProps = Omit<
	CreateOnEnrollmentApplicationProps<EnrollmentApplicationNoteCreateMutation["createEnrollmentApplicationNote"]>,
	"formState" | "create"
> & {
	formStateArgs?: Partial<EnrollmentApplicationNoteFormState.UseCreateFormStateArgs>;
};

export function ControlledCreateOnEnrollmentApplication({
	formStateArgs,
	...props
}: ControlledCreateOnEnrollmentApplicationProps) {
	const formState = EnrollmentApplicationNoteFormState.useCreateFormState({
		validationDisplayPolicy: ValidationDisplayPolicy.none,
		...formStateArgs,
	});
	const create = EnrollmentApplicationNoteMutation.useCreate();

	return <CreateOnEnrollmentApplication formState={formState} create={create} {...props} />;
}

export type CreateOnEnrollmentApplicationProps<T> = CreateProps<T> & {
	enrollmentApplicationID: EnrollmentApplication["id"];
};

export function CreateOnEnrollmentApplication<T>({
	formState,
	create,
	onSuccess,
	formNameSuffix,
	enrollmentApplicationID,
}: CreateOnEnrollmentApplicationProps<T>) {
	const { applyCreate, resultRef } = useApplyCreate(create);
	const onSubmit = React.useCallback<FormSubmitFunction<EnrollmentApplicationNoteFormValues.Create>>(
		async ({ formValues }) => onCreateSubmit(formValues, applyCreate),
		[applyCreate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	useInternalField(formState, "enrollmentApplicationID");
	const { enrollmentApplicationID: setEnrollmentApplicationID } = formState.formSetFunctions;
	React.useEffect(() => {
		setEnrollmentApplicationID(enrollmentApplicationID);
	}, [setEnrollmentApplicationID, enrollmentApplicationID]);

	const formName =
		`enrollmentApplicationNote.create.ea-${enrollmentApplicationID}` + (formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form
			name={formName}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
			mergeUnsavedChangesAutomatically
			noCloseConfirmation
		>
			<Row justify="spaced-start" align="center">
				<Field.Body formState={formState} placeholder="Write a note" noHeader />

				<SubmitButton
					submissionStatus={formState.submissionStatus}
					disabled={formState.formValues.body === ""}
					onClick={formState.onSubmitWrapper(onSubmit)}
				>
					Submit
				</SubmitButton>
			</Row>
		</Form>
	);
}

export type ControlledDetailProps = Pick<
	DetailProps<EnrollmentApplicationNoteUpdateMutation["updateEnrollmentApplicationNote"]>,
	"enrollmentApplicationNote" | "onSuccess" | "formNameSuffix"
> & {
	formStateArgs?: Partial<
		DistributiveOmit<EnrollmentApplicationNoteFormState.UseDetailFormStateArgs, "initialFormValues">
	>;
};

/**
 * Renders the detail form of the EnrollmentApplicationNote model using an internally managed form state.
 */
export function ControlledDetail({ formStateArgs, ...props }: ControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => EnrollmentApplicationNoteFormConversion.toFormValues(props.enrollmentApplicationNote),
		[props.enrollmentApplicationNote],
	);
	const formState = EnrollmentApplicationNoteFormState.useDetailFormState({ ...formStateArgs, initialFormValues });
	const update = EnrollmentApplicationNoteMutation.useUpdate(props.enrollmentApplicationNote.id);

	return <Detail formState={formState} update={update} {...props} />;
}

export type DetailProps<T> = {
	enrollmentApplicationNote: EnrollmentApplicationNoteDetailQuery["enrollmentApplicationNote"];
	formState: FormState<EnrollmentApplicationNoteFormValues.Detail>;
	update: UpdateFunction<EnrollmentApplicationNoteFormValues.Detail, T>;
	onSuccess: (data: T) => void;
	formNameSuffix?: string;
};

/**
 * Renders the detail form of the EnrollmentApplicationNote model using the given form state.
 */
export function Detail<T>({ formState, enrollmentApplicationNote, update, onSuccess, formNameSuffix }: DetailProps<T>) {
	const { applyUpdate, resultRef } = useApplyUpdate(update);
	const onSubmit = React.useCallback<FormSubmitFunction<EnrollmentApplicationNoteFormValues.Detail>>(
		async (formState) => onUpdateSubmit(formState, applyUpdate),
		[applyUpdate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	const formName =
		`enrollmentApplicationNote.detail.${enrollmentApplicationNote.id}` + (formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form name={formName} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Form.Column>
				<FormEditButtonPair formState={formState} />

				<DetailFields formState={formState} enrollmentApplicationNote={enrollmentApplicationNote} />
			</Form.Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = Pick<ReadOnlyProps, "enrollmentApplicationNote"> & {
	formStateArgs?: Partial<
		DistributiveOmit<EnrollmentApplicationNoteFormState.UseReadOnlyFormStateArgs, "initialFormValues">
	>;
};

/**
 * Renders a read-only detail form of the EnrollmentApplicationNote model using an internally managed form state.
 */
export function ControlledReadOnly({ formStateArgs, ...props }: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => EnrollmentApplicationNoteFormConversion.toFormValues(props.enrollmentApplicationNote),
		[props.enrollmentApplicationNote],
	);
	const formState = EnrollmentApplicationNoteFormState.useReadOnlyFormState({ ...formStateArgs, initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = {
	enrollmentApplicationNote: EnrollmentApplicationNoteDetailQuery["enrollmentApplicationNote"];
	formState: FormState<EnrollmentApplicationNoteFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the EnrollmentApplicationNote model using the given form state.
 */
export function ReadOnly({ formState, enrollmentApplicationNote }: ReadOnlyProps) {
	return (
		<Form.Column>
			<DetailFields formState={formState} enrollmentApplicationNote={enrollmentApplicationNote} />
		</Form.Column>
	);
}

type DetailFieldsProps = {
	enrollmentApplicationNote: EnrollmentApplicationNoteDetailQuery["enrollmentApplicationNote"];
	formState: FormState<EnrollmentApplicationNoteFormValues.Detail>;
};

function DetailFields({ formState, enrollmentApplicationNote }: DetailFieldsProps) {
	return (
		<React.Fragment>
			<Field.CreatedAt formState={formState} />
			<Field.Body formState={formState} />
			<Field.Author formState={formState} currentAuthor={enrollmentApplicationNote.author} />
			<Field.EnrollmentApplication
				formState={formState}
				currentEnrollmentApplication={enrollmentApplicationNote.enrollmentApplication}
			/>
		</React.Fragment>
	);
}
