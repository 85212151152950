import { PermissionGroup } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the name field of the PermissionGroup model.
 */
export function name(value: PermissionGroup["name"]): string {
	return toString(value);
}

/**
 * Formats the description field of the PermissionGroup model.
 */
export function description(value: PermissionGroup["description"]): string {
	return toString(value);
}
