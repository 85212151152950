import React from "react";
import { Heading } from "@hex-insights/core";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { TuitionForm } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { tuitionHubDetailPageInfo } from "../DetailPage/pageinfo";
import { tuitionHubCreatePageInfo } from "./pageinfo";

export function TuitionHubCreatePage() {
	useActivePageRegistration(tuitionHubCreatePageInfo);

	const { push: pushHistory } = useHistory();
	const onSuccess = React.useCallback<TuitionForm.ControlledCreateProps["onSuccess"]>(
		({ id }) => {
			pushHistory(tuitionHubDetailPageInfo.to(id));
		},
		[pushHistory],
	);

	return (
		<Main>
			<Heading.H1>New Tuition</Heading.H1>

			<TuitionForm.ControlledCreate onSuccess={onSuccess} />
		</Main>
	);
}
