import { omitKeys } from "@hex-insights/core";
import { EnrollmentApplicationDetailQuery, EnrollmentApplicationUpdateInput } from "../../GraphQL";
import { EnrollmentApplicationFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the EnrollmentApplication model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<EnrollmentApplicationFormValues.Detail>,
	_initialFormValues: Partial<EnrollmentApplicationFormValues.Detail>,
): EnrollmentApplicationUpdateInput {
	const inputFormValues = omitKeys(formValues, ["createdAt"]);
	const input: EnrollmentApplicationUpdateInput = { ...inputFormValues };
	return input;
}

/**
 * Converts a EnrollmentApplication instance to its corresponding detail form values.
 *
 * @param enrollmentApplication The instance to convert.
 */
export function toFormValues(
	enrollmentApplication: EnrollmentApplicationDetailQuery["enrollmentApplication"],
): EnrollmentApplicationFormValues.Detail {
	return {
		createdAt: enrollmentApplication.createdAt,
		status: enrollmentApplication.status,
		externalLink: enrollmentApplication.externalLink,
	};
}
