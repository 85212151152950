import { newPageInfo } from "@hex-insights/router";
import { enrollmentApplicationUserViewRecordHubPageInfo } from "../pageinfo";

export const enrollmentApplicationUserViewRecordHubIndexPageInfo = newPageInfo({
	name: "Enrollment Application User View Records Index",
	title: "",
	description: "Index of Enrollment Application User View Records.",
	exact: true,
	to: enrollmentApplicationUserViewRecordHubPageInfo.to,
	path: enrollmentApplicationUserViewRecordHubPageInfo.path,
	parent: enrollmentApplicationUserViewRecordHubPageInfo,
});
