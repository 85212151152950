import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const calendarEventHubPageInfo = newPageInfo({
	title: "Calendar Events",
	description: "Pages related to the Calendar Event model.",
	exact: false,
	to: hubsPageInfo.to + "/calendar-events",
	path: hubsPageInfo.path + "/calendar-events",
	parent: hubsPageInfo,
});
