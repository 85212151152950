import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { ServiceForm, ServiceFormValues, ServiceMutation, useServiceDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { serviceHubDetailPageInfo } from "./pageinfo";

function ServiceHubDetailPageWrapper() {
	// Manages identity of the ServiceHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <ServiceHubDetailPage key={id} />;
}

export { ServiceHubDetailPageWrapper as ServiceHubDetailPage };

function ServiceHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useServiceDetailQuery({ variables: { id } });
	const service = data?.service;
	useActivePageRegistration(serviceHubDetailPageInfo, !!service ? serviceHubDetailPageInfo.title(service) : "");

	const update = ServiceMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<ServiceFormValues.Detail>, initialFormValues: ServiceFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading service</If>
				<Else>
					{!!service && (
						<React.Fragment>
							<Heading level={1}>{service.name}</Heading>

							<ServiceForm.ControlledDetail
								key={syncID}
								service={service}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
