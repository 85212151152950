import { newPageInfo } from "@hex-insights/router";
import { admissionsPageInfo } from "../pageinfo";

export const admissionsMeetingsPageInfo = newPageInfo({
	title: "Meetings",
	description: "Overview of enrollment applications for meetings.",
	exact: true,
	to:
		admissionsPageInfo.to +
		"/meetings" +
		"?filter.interviewMeetingInFuture=true&order.field=INTERVIEW_MEETING_TIME&order.direction=ASC",
	path: admissionsPageInfo.path + "/meetings",
	parent: admissionsPageInfo,
});
