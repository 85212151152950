import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import {
	addTimeToDate,
	Button,
	Conditional,
	Drawer,
	Else,
	If,
	makeClassName,
	mappedArray,
	Row,
	Section,
	TimeUnit,
	toLocalDateString,
} from "@hex-insights/core";
import { FormState } from "@hex-insights/forms";
import { useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	EnrollmentApplication,
	EnrollmentApplicationFilterFormController,
	EnrollmentApplicationFilterFormValues,
	EnrollmentApplicationFormat,
	EnrollmentApplicationInterviewMeetingTimeFilterButtons,
	EnrollmentApplicationOrderField,
	EnrollmentApplicationOrderFormController,
	EnrollmentApplicationOrderFormValues,
	EnrollmentApplicationPaginationForm,
	EnrollmentApplicationSearchForm,
	EnrollmentApplicationStatusFilterButtons,
	EnrollmentApplicationStatusFilterButtonsProps,
	EnrollmentApplicationStudentFormat,
	EnrollmentApplicationStudentGradeLevelApplyingFor,
	HR,
	OrderDirection,
	PersonIcon,
	SmallEventCalendar,
	SmallEventCalendarItem,
	useEnrollmentApplicationIndexQuery,
	useSmallEventCalendarDates,
} from "@hex-insights/verita.shared";
import { AuthenticationContext } from "../../../Contexts";
import {
	EnrollmentApplicationDrawer,
	EnrollmentApplicationStatusBadge,
	useEnrollmentApplicationDetailDrawer,
} from "../Components";
import { useEnrollmentApplicationsIndex } from "../use-enrollment-applications-index";
import { admissionsMeetingsPageInfo } from "./pageinfo";
import generalStyles from "../enrollment-applications.module.css";

const columns = ["studentImageURL", "studentName", "studentGradeLevelApplyingFor", "status", "interviewMeetingTime"];

const columnWidths = {
	studentImageURL: "4rem",
	status: "7rem",
	interviewMeetingTime: "10rem",
};

const initialOrderFormValues: EnrollmentApplicationOrderFormValues.FormValues = {
	field: EnrollmentApplicationOrderField.InterviewMeetingTime,
	direction: OrderDirection.Desc,
};

export function AdmissionsMeetingsPage() {
	useActivePageRegistration(admissionsMeetingsPageInfo);

	const {
		filterFormState,
		orderFormState,
		paginationFormState,
		filterInputs,
		orderInput,
		numActiveFilters,
		data,
		error,
		clearFilters,
		paginationInfo,
		setPaginationInfo,
		tableSortingProps,
		numFillerRows,
	} = useEnrollmentApplicationsIndex({ initialOrderFormValues });

	const { openEnrollmentApplicationID, isDrawerOpen, openDrawer, closeDrawer } = useEnrollmentApplicationDetailDrawer();

	return (
		<div className="admissions__applications">
			<Row justify="spaced-start" align="center" overflow="wrap" style={{ marginBottom: "0.75rem" }}>
				<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
					<EnrollmentApplicationSearchForm formState={filterFormState} />
					<EnrollmentApplicationFilterFormController formState={filterFormState} numActiveFilters={numActiveFilters} />
					<If condition={numActiveFilters > 0}>
						<Button variant="link" size="small" onClick={clearFilters}>
							Clear
						</Button>
					</If>
					<EnrollmentApplicationOrderFormController formState={orderFormState} />
				</Row>
				<HR color="var(--verita-blue)" style={{ height: "1.5rem" }} />
				<QuickFilters filterFormState={filterFormState} />
			</Row>

			<Drawer.If condition={isDrawerOpen}>
				<EnrollmentApplicationDrawer
					key={openEnrollmentApplicationID}
					enrollmentApplicationID={openEnrollmentApplicationID ?? ""}
					onClose={closeDrawer}
				/>
			</Drawer.If>

			<Row justify="space-between">
				<Table.Container className="hub__index-page__table__container" style={{ flexGrow: 1 }}>
					<Table
						columns={columns}
						columnWidths={columnWidths}
						minColumnWidth="10rem"
						{...tableSortingProps}
						className="hub__index-page__table"
					>
						<Table.Header className="hub__index-page__table__header">
							<Table.Row>
								<Table.Heading column="studentImageURL"></Table.Heading>
								<Table.Heading column="studentName" noSort>
									Student
								</Table.Heading>
								<Table.Heading column="studentGradeLevelApplyingFor" noSort>
									Grade Level
								</Table.Heading>
								<Table.Heading column="status">Status</Table.Heading>
								<Table.Heading column="interviewMeetingTime">Interview</Table.Heading>
							</Table.Row>
						</Table.Header>

						<Table.Body className="hub__index-page__table__body">
							<Conditional>
								<If condition={!data}>
									<Table.Row className="hub__index-page__table__row">
										<Table.Cell column="studentImageURL" colspan={columns.length}>
											Loading...
										</Table.Cell>
									</Table.Row>
									{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
										<Table.FillerRow
											key={i}
											className="hub__index-page__table__row hub__index-page__table__row--filler"
										/>
									))}
								</If>

								<Else>
									{data?.enrollmentApplicationConnection.edges.map((e) => {
										const student = e.node.enrollmentApplicationStudent;
										const isNew = e.node.enrollmentApplicationUserViewRecords.length === 0;
										const isSeen = e.node.enrollmentApplicationUserViewRecords.some((e) => e.isDetail);

										return (
											<Table.Row
												key={e.node.id}
												onClick={() => openDrawer(e.node.id)}
												className={makeClassName(
													"hub__index-page__table__row",
													isNew ? generalStyles["table-row--new"] : "",
													!isSeen ? generalStyles["table-row--unseen"] : "",
												)}
											>
												<Table.Cell
													column="studentImageURL"
													oneLine
													style={{ paddingTop: "0.1rem", paddingBottom: "0.1rem" }}
												>
													<Row justify="center" align="center" style={{ height: "100%" }}>
														<PersonIcon person={e.node.enrollmentApplicationStudent} imageSize="3rem" />
													</Row>
												</Table.Cell>
												<Table.Cell column="studentName" oneLine>
													{EnrollmentApplicationStudentFormat.name(student)}
												</Table.Cell>
												<Table.Cell column="studentGradeLevelApplyingFor" oneLine>
													{EnrollmentApplicationStudentFormat.Fields.gradeLevelApplyingFor(
														student.gradeLevelApplyingFor,
													)}
												</Table.Cell>
												<Table.Cell column="status" oneLine>
													<EnrollmentApplicationStatusBadge status={e.node.status} hasConfirmed={!!student.studentID} />
												</Table.Cell>
												<Table.Cell column="interviewMeetingTime" oneLine>
													{EnrollmentApplicationFormat.Fields.interviewMeetingTime(e.node.interviewMeetingTime)}
												</Table.Cell>
											</Table.Row>
										);
									})}
									{mappedArray(numFillerRows, (i) => (
										<Table.FillerRow
											key={i}
											className="hub__index-page__table__row hub__index-page__table__row--filler"
										/>
									))}
								</Else>
							</Conditional>
						</Table.Body>
					</Table>

					<Table.Toolbar>
						<Row justify="space-between" align="center">
							<EnrollmentApplicationPaginationForm
								formState={paginationFormState}
								filters={filterInputs}
								order={orderInput}
								setPaginationInfo={setPaginationInfo}
							/>

							<span>
								<Conditional>
									<If condition={!!error}>Something went wrong.</If>
									<If condition={!data}>Loading...</If>
									<If condition={data?.enrollmentApplicationConnection.edges.length === 0}>No results found.</If>
									<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
								</Conditional>
							</span>
						</Row>
					</Table.Toolbar>
				</Table.Container>

				<MeetingsEventCalendar onClickEnrollmentApplication={openDrawer} />
			</Row>
		</div>
	);
}

const quickFilterButtonProps: EnrollmentApplicationStatusFilterButtonsProps["buttonProps"] = {
	size: "small",
	style: {
		paddingTop: "0.5rem",
		paddingBottom: "0.5rem",
		borderRadius: "1rem",
	},
};

type QuickFiltersProps = {
	filterFormState: FormState<EnrollmentApplicationFilterFormValues.FormValues>;
};

function QuickFilters({ filterFormState }: QuickFiltersProps) {
	return (
		<Row justify="spaced-start" align="center">
			<Row justify="spaced-start" horizontalSpacing="0.25rem">
				<EnrollmentApplicationInterviewMeetingTimeFilterButtons
					formState={filterFormState}
					buttonProps={quickFilterButtonProps}
				/>
			</Row>
			<div style={{ height: "1.5rem", borderLeft: "1px solid #eee" }}></div>
			<Row justify="spaced-start" horizontalSpacing="0.25rem">
				<EnrollmentApplicationStatusFilterButtons formState={filterFormState} buttonProps={quickFilterButtonProps} />
			</Row>
		</Row>
	);
}

const meetingDuration: [number, TimeUnit] = [60, "minutes"];

type MeetingsEventCalendarProps = {
	onClickEnrollmentApplication: (id: EnrollmentApplication["id"]) => void;
};

function MeetingsEventCalendar({ onClickEnrollmentApplication }: MeetingsEventCalendarProps) {
	const { value, onChange, onActiveStartDateChange, onClickMonth, firstOfMonth, lastOfMonth } =
		useSmallEventCalendarDates();

	const { user } = React.useContext(AuthenticationContext);
	const { data } = useEnrollmentApplicationIndexQuery({
		variables: {
			filters: {
				interviewMeetingTimeIsNull: false,
				interviewMeetingTimeGTE: toLocalDateString(firstOfMonth),
				interviewMeetingTimeLT: toLocalDateString(addTimeToDate(lastOfMonth, [1, "day"])),
			},
			order: { field: EnrollmentApplicationOrderField.InterviewMeetingTime, direction: OrderDirection.Asc },
			userID: user?.id ?? "0",
		},
		fetchPolicy: "cache-and-network",
	});

	const events = React.useMemo<SmallEventCalendarItem[]>(() => {
		if (!data) {
			return [];
		}

		return data.enrollmentApplicationConnection.edges.map(({ node }) => {
			const student = node.enrollmentApplicationStudent;

			return {
				id: node.id,
				name: EnrollmentApplicationStudentFormat.name(student),
				description:
					`Interview meeting with ${EnrollmentApplicationStudentFormat.name(student)}` +
					(student.gradeLevelApplyingFor
						? ` for ${EnrollmentApplicationStudentFormat.Fields.gradeLevelApplyingFor(student.gradeLevelApplyingFor)}`
						: "") +
					".",
				startTime: node.interviewMeetingTime!,
				endTime: addTimeToDate(node.interviewMeetingTime!, meetingDuration).toISOString(),
				isAllDay: false,
				isPublic: false,
				groups: [
					student.gradeLevelApplyingFor === EnrollmentApplicationStudentGradeLevelApplyingFor.Nursery ||
					student.gradeLevelApplyingFor === EnrollmentApplicationStudentGradeLevelApplyingFor.Reception
						? { name: "Early Years Interview Meetings", color: "var(--verita-colors-green)" }
						: { name: "Primary School Interview Meetings", color: "var(--verita-blue)" },
				],
			};
		});
	}, [data]);

	return (
		<div style={{ maxWidth: "24rem" }}>
			<div style={{ position: "sticky", top: "var(--header---height)" }}>
				<Section>
					<Section.Body>
						<SmallEventCalendar
							value={value}
							onChange={onChange}
							onActiveStartDateChange={onActiveStartDateChange}
							onClickMonth={onClickMonth}
							events={events}
							onClickEvent={({ id }) => onClickEnrollmentApplication(id)}
						/>
					</Section.Body>
				</Section>
			</div>
		</div>
	);
}
