import { NullPartial } from "@hex-insights/core";
import { PaymentLineItemCreateInput, PaymentLineItemDetailQuery, PaymentLineItemUpdateInput } from "../../GraphQL";
import { PaymentLineItemFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the PaymentLineItem model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: PaymentLineItemFormValues.Create): PaymentLineItemCreateInput {
	const input: NullPartial<PaymentLineItemCreateInput> = { ...formValues };
	return input as PaymentLineItemCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the PaymentLineItem model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<PaymentLineItemFormValues.Detail>,
	_initialFormValues: PaymentLineItemFormValues.Detail,
): PaymentLineItemUpdateInput {
	const input: PaymentLineItemUpdateInput = { ...formValues };
	return input;
}

/**
 * Converts a PaymentLineItem instance to its corresponding detail form values.
 *
 * @param paymentLineItem The instance to convert.
 */
export function toFormValues(
	paymentLineItem: PaymentLineItemDetailQuery["paymentLineItem"],
): PaymentLineItemFormValues.Detail {
	return {
		amount: paymentLineItem.amount,
		invoiceID: paymentLineItem.invoice?.id ?? null,
		paymentID: paymentLineItem.payment?.id ?? null,
	};
}
