import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { PermissionOrderFormValues } from "../../../../Utilities";

export type PermissionOrderFormControllerProps = {
	formState: FormState<PermissionOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the Permission model.
 */
export function PermissionOrderFormController({ formState }: PermissionOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={PermissionOrderFormValues.fieldOptions}
			fieldTypeMapping={PermissionOrderFormValues.fieldTypeMapping}
		/>
	);
}
