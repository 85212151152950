import { IndexForms } from "@hex-insights/app-modules";
import { PaymentOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the Payment model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		PaymentOrderFormValues.initial,
		PaymentOrderFormValues.fieldValues,
	);
}
