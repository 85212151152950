import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const reportCardHubPageInfo = newPageInfo({
	title: "Report Cards",
	description: "Pages related to the Report Card model.",
	exact: false,
	to: hubsPageInfo.to + "/report-cards",
	path: hubsPageInfo.path + "/report-cards",
	parent: hubsPageInfo,
});
