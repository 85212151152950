import { IndexForms } from "@hex-insights/app-modules";
import { EnrollmentApplicationStudentFilterFormValues } from "../../../../Utilities";

export type EnrollmentApplicationStudentSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the EnrollmentApplicationStudent model.
 */
export function EnrollmentApplicationStudentSearchForm({ formState }: EnrollmentApplicationStudentSearchFormProps) {
	return <IndexForms.SearchForm formState={formState} />;
}

export type EnrollmentApplicationStudentFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, EnrollmentApplicationStudentFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the EnrollmentApplicationStudent model.
 */
export function EnrollmentApplicationStudentFilterFormController(
	props: EnrollmentApplicationStudentFilterFormControllerProps,
) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={EnrollmentApplicationStudentFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `EnrollmentApplicationStudentFilterForm`
