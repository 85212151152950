import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { ReportCardOrderFormValues } from "../../../../Utilities";

export type ReportCardOrderFormControllerProps = {
	formState: FormState<ReportCardOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the ReportCard model.
 */
export function ReportCardOrderFormController({ formState }: ReportCardOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={ReportCardOrderFormValues.fieldOptions}
			fieldTypeMapping={ReportCardOrderFormValues.fieldTypeMapping}
		/>
	);
}
