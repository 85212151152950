import { IndexForms } from "@hex-insights/app-modules";
import { NoteAttachmentFilterFormValues } from "../../../../Utilities";

export type NoteAttachmentSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the NoteAttachment model.
 */
export function NoteAttachmentSearchForm(props: NoteAttachmentSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type NoteAttachmentFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, NoteAttachmentFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the NoteAttachment model.
 */
export function NoteAttachmentFilterFormController(props: NoteAttachmentFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={NoteAttachmentFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `NoteAttachmentFilterForm`
