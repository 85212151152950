import { newPageInfo } from "@hex-insights/router";
import { User } from "@hex-insights/verita.shared";
import { userHubPageInfo } from "../pageinfo";

type UserForRouteTitle = Pick<User, "email">;

export const userHubDetailPageInfo = newPageInfo({
	name: "User Detail",
	title: (e: UserForRouteTitle) => e.email,
	description: "Detail page for an user.",
	exact: false,
	isLeaf: true,
	to: (id: User["id"]) => userHubPageInfo.to + `/${id}`,
	path: userHubPageInfo.path + "/:id(\\d+)",
	parent: userHubPageInfo,
});
