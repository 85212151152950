import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	AcademicYear,
	CourseSection,
	graphTypeNames,
	HomeRoomSection,
	ReportCard,
	Term,
	TermCreateInput,
	TermCreateMutation,
	TermDeleteMutation,
	TermDetailDocument,
	TermDetailQuery,
	TermDetailQueryVariables,
	TermUpdateInput,
	TermUpdateMutation,
	useTermCreateMutation,
	useTermDeleteMutation,
	useTermUpdateMutation,
} from "../../GraphQL";
import { TermFormConversion } from "../ModelFormConversion";
import { TermFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the Term model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Term model.
 */
export function useCreate() {
	const [createTerm] = useTermCreateMutation();

	return React.useCallback(
		async (formValues: TermFormValues.Create) => {
			const input = TermFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createTerm({ variables: { input }, update: updateCache });

			return { data: data?.createTerm ?? null, errors: errors ?? null };
		},
		[createTerm],
	);
}

/**
 * Returns an `update` function for the Term model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Term model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Term["id"]) {
	const [updateTerm] = useTermUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<TermFormValues.Detail>, initialFormValues: TermFormValues.Detail) => {
			const input = TermFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateTerm({ variables: { id, input }, update: updateCache });

			return { data: data?.updateTerm ?? null, errors: errors ?? null };
		},
		[updateTerm, id],
	);
}

/**
 * Returns a `del` function for the Term model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Term["id"]) {
	const [deleteTerm] = useTermDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteTerm({ variables: { id }, update: updateCache });

		return { data: data?.deleteTerm ?? false, errors: errors ?? null };
	}, [deleteTerm, id]);
}

function getUpdateCacheForCreate(input: TermCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<TermCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createTerm;

		cache.writeQuery<TermDetailQuery, TermDetailQueryVariables>({
			query: TermDetailDocument,
			data: { term: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.academicYearID) {
			addToTermsOfAcademicYearCache(cache, input.academicYearID, createdObject);
		}

		if (input.courseSectionIDs) {
			for (let i = 0; i < input.courseSectionIDs.length; i++) {
				addToTermOfCourseSectionCache(cache, input.courseSectionIDs[i], createdObject);
			}
		}

		if (input.homeRoomSectionIDs) {
			for (let i = 0; i < input.homeRoomSectionIDs.length; i++) {
				addToTermOfHomeRoomSectionCache(cache, input.homeRoomSectionIDs[i], createdObject);
			}
		}

		if (input.reportCardIDs) {
			for (let i = 0; i < input.reportCardIDs.length; i++) {
				addToTermOfReportCardCache(cache, input.reportCardIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "termConnection" });
	};
}

function getUpdateCacheForUpdate(input: TermUpdateInput, initialFormValues: TermFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<TermUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateTerm;

		if (initialFormValues.academicYearID && input.academicYearID) {
			removeFromTermsOfAcademicYearCache(cache, initialFormValues.academicYearID, updatedObject);
		}
		if (input.academicYearID) {
			addToTermsOfAcademicYearCache(cache, input.academicYearID, updatedObject);
		}

		if (input.addCourseSectionIDs) {
			for (let i = 0; i < input.addCourseSectionIDs.length; i++) {
				addToTermOfCourseSectionCache(cache, input.addCourseSectionIDs[i], updatedObject);
			}
		}
		if (input.removeCourseSectionIDs) {
			for (let i = 0; i < input.removeCourseSectionIDs.length; i++) {
				removeFromTermOfCourseSectionCache(cache, input.removeCourseSectionIDs[i], updatedObject);
			}
		}

		if (input.addHomeRoomSectionIDs) {
			for (let i = 0; i < input.addHomeRoomSectionIDs.length; i++) {
				addToTermOfHomeRoomSectionCache(cache, input.addHomeRoomSectionIDs[i], updatedObject);
			}
		}
		if (input.removeHomeRoomSectionIDs) {
			for (let i = 0; i < input.removeHomeRoomSectionIDs.length; i++) {
				removeFromTermOfHomeRoomSectionCache(cache, input.removeHomeRoomSectionIDs[i], updatedObject);
			}
		}

		if (input.addReportCardIDs) {
			for (let i = 0; i < input.addReportCardIDs.length; i++) {
				addToTermOfReportCardCache(cache, input.addReportCardIDs[i], updatedObject);
			}
		}
		if (input.removeReportCardIDs) {
			for (let i = 0; i < input.removeReportCardIDs.length; i++) {
				removeFromTermOfReportCardCache(cache, input.removeReportCardIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: Term["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<TermDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteTerm) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Term }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "termConnection" });
		cache.gc();
	};
}

function addToTermsOfAcademicYearCache(
	cache: ApolloCache<any>,
	targetID: AcademicYear["id"],
	object: Pick<Term, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.AcademicYear }),
		fields: {
			terms: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromTermsOfAcademicYearCache(
	cache: ApolloCache<any>,
	targetID: AcademicYear["id"],
	object: Pick<Term, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.AcademicYear }),
		fields: {
			terms: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToTermOfCourseSectionCache(
	cache: ApolloCache<any>,
	targetID: CourseSection["id"],
	object: Pick<Term, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSection }),
		fields: {
			term: () => objectRef,
			termID: () => object.id,
		},
	});
}

function removeFromTermOfCourseSectionCache(
	cache: ApolloCache<any>,
	targetID: CourseSection["id"],
	_object: Pick<Term, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSection }),
		fields: {
			term: () => null,
			termID: () => null,
		},
	});
}

function addToTermOfHomeRoomSectionCache(
	cache: ApolloCache<any>,
	targetID: HomeRoomSection["id"],
	object: Pick<Term, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.HomeRoomSection }),
		fields: {
			term: () => objectRef,
			termID: () => object.id,
		},
	});
}

function removeFromTermOfHomeRoomSectionCache(
	cache: ApolloCache<any>,
	targetID: HomeRoomSection["id"],
	_object: Pick<Term, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.HomeRoomSection }),
		fields: {
			term: () => null,
			termID: () => null,
		},
	});
}

function addToTermOfReportCardCache(cache: ApolloCache<any>, targetID: ReportCard["id"], object: Pick<Term, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ReportCard }),
		fields: {
			term: () => objectRef,
			termID: () => object.id,
		},
	});
}

function removeFromTermOfReportCardCache(
	cache: ApolloCache<any>,
	targetID: ReportCard["id"],
	_object: Pick<Term, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ReportCard }),
		fields: {
			term: () => null,
			termID: () => null,
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Term, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment TermRef on Term {
				id
			}
		`,
		data: object,
	});
}
