import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { ServiceFilterInput, ServiceOrder, useServicePaginationQuery } from "../../../../Utilities";

export type ServicePaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: ServiceFilterInput | ServiceFilterInput[] | null;
	order: ServiceOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the Service model.
 */
export function ServicePaginationForm({ formState, filters, order, setPaginationInfo }: ServicePaginationFormProps) {
	const { loading, data } = useServicePaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.serviceConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
