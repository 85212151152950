import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { LessonPlanHubCreatePage } from "./CreatePage";
import { lessonPlanHubCreatePageInfo } from "./CreatePage/pageinfo";
import { LessonPlanHubDetailPage } from "./DetailPage";
import { lessonPlanHubDetailPageInfo } from "./DetailPage/pageinfo";
import { LessonPlanHubIndexPage } from "./IndexPage";
import { lessonPlanHubIndexPageInfo } from "./IndexPage/pageinfo";
import { lessonPlanHubPageInfo } from "./pageinfo";

export function LessonPlanHub() {
	useActivePageRegistration(lessonPlanHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...lessonPlanHubIndexPageInfo.routeProps}>
				<LessonPlanHubIndexPage />
			</Route>
			<Route {...lessonPlanHubCreatePageInfo.routeProps}>
				<LessonPlanHubCreatePage />
			</Route>
			<Route {...lessonPlanHubDetailPageInfo.routeProps}>
				<LessonPlanHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
