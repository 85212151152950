import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { NoteAttachmentHubCreatePage } from "./CreatePage";
import { noteAttachmentHubCreatePageInfo } from "./CreatePage/pageinfo";
import { NoteAttachmentHubDetailPage } from "./DetailPage";
import { noteAttachmentHubDetailPageInfo } from "./DetailPage/pageinfo";
import { NoteAttachmentHubIndexPage } from "./IndexPage";
import { noteAttachmentHubIndexPageInfo } from "./IndexPage/pageinfo";
import { noteAttachmentHubPageInfo } from "./pageinfo";

export function NoteAttachmentHub() {
	useActivePageRegistration(noteAttachmentHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...noteAttachmentHubIndexPageInfo.routeProps}>
				<NoteAttachmentHubIndexPage />
			</Route>
			<Route {...noteAttachmentHubCreatePageInfo.routeProps}>
				<NoteAttachmentHubCreatePage />
			</Route>
			<Route {...noteAttachmentHubDetailPageInfo.routeProps}>
				<NoteAttachmentHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
