import React from "react";
import { FieldDisplayArgs, MultiSelectField, TextField } from "@hex-insights/forms";
import { BusFormValues, BusPlanSelect, useBusPlanSelectLazyQuery } from "../../../../Utilities";
import { BusPlanLink } from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Bus model.
 */
type FieldProps<K extends keyof BusFormValues.Base = keyof BusFormValues.Base> = BaseFieldProps<
	Pick<BusFormValues.Base, K>
>;

/**
 * Generic props for fields of the Bus model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof BusFormValues.Detail = keyof BusFormValues.Detail> = BaseFieldProps<
	Pick<BusFormValues.Detail, K>
>;

/**
 * Renders a field component for the `name` field of the Bus model.
 */
export function Name({ formState }: FieldProps<"name">) {
	return <TextField formState={formState} name="name" />;
}

/**
 * Renders a field component for the `description` field of the Bus model.
 */
export function Description({ formState }: FieldProps<"description">) {
	return <TextAreaField formState={formState} name="description" optional />;
}

export type DropoffBusPlansProps = DetailFieldProps<"dropoffBusPlanIDs"> & {
	currentDropoffBusPlans?: BusPlanSelect.ModelForOption[];
};

/**
 * Renders a field component for the `dropoffBusPlans` edge of the Bus model.
 */
export function DropoffBusPlans({ formState, currentDropoffBusPlans }: DropoffBusPlansProps) {
	const [loadOptions, { loading, data }] = useBusPlanSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.dropoffBusPlanIDs) {
			loadOptions();
		}
	}, [formState.formEditing.dropoffBusPlanIDs, loadOptions]);
	const options = React.useMemo(
		() => BusPlanSelect.toMultiOptions(data?.busPlanConnection.edges, currentDropoffBusPlans),
		[data, currentDropoffBusPlans],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="dropoffBusPlanIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayDropoffBusPlanInstance}
		/>
	);
}

function displayDropoffBusPlanInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <BusPlanLink instance={{ id }}>{formattedValue}</BusPlanLink>;
}

export type PickupBusPlansProps = DetailFieldProps<"pickupBusPlanIDs"> & {
	currentPickupBusPlans?: BusPlanSelect.ModelForOption[];
};

/**
 * Renders a field component for the `pickupBusPlans` edge of the Bus model.
 */
export function PickupBusPlans({ formState, currentPickupBusPlans }: PickupBusPlansProps) {
	const [loadOptions, { loading, data }] = useBusPlanSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.pickupBusPlanIDs) {
			loadOptions();
		}
	}, [formState.formEditing.pickupBusPlanIDs, loadOptions]);
	const options = React.useMemo(
		() => BusPlanSelect.toMultiOptions(data?.busPlanConnection.edges, currentPickupBusPlans),
		[data, currentPickupBusPlans],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="pickupBusPlanIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayPickupBusPlanInstance}
		/>
	);
}

function displayPickupBusPlanInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <BusPlanLink instance={{ id }}>{formattedValue}</BusPlanLink>;
}
