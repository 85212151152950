import { IndexForms } from "@hex-insights/app-modules";
import { MFAInvalidOTPAttemptOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the MFAInvalidOTPAttempt model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		MFAInvalidOTPAttemptOrderFormValues.initial,
		MFAInvalidOTPAttemptOrderFormValues.fieldValues,
	);
}
