import React from "react";
import { onCreateSubmit } from "@hex-insights/app-modules";
import { Column, Heading, Paragraph, Row, Section } from "@hex-insights/core";
import { Form, FormSubmitFunction, SubmissionStatus, SubmitButton } from "@hex-insights/forms";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { TermField, TermFormState, TermFormValues, TermMutation, Tile } from "@hex-insights/verita.shared";
import { Main } from "../../../Components";
import { termSetupDetailPageInfo } from "../DetailPage/pageinfo";
import { termSetupCreatePageInfo } from "./pageinfo";

export function TermSetupCreatePage() {
	useActivePageRegistration(termSetupCreatePageInfo);

	const create = TermMutation.useCreate();
	const createdIDRef = React.useRef<string | null>(null);
	const { push: pushHistory } = useHistory();

	const applyCreate = React.useCallback(
		async (formValues: TermFormValues.Create) => {
			const { data, errors } = await create(formValues);
			createdIDRef.current = data?.id ?? null;
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		if (createdIDRef.current !== null) {
			pushHistory(termSetupDetailPageInfo.to(createdIDRef.current));
		}
	}, [pushHistory]);

	const formState = TermFormState.useCreateFormState();

	const onSubmit = React.useCallback<FormSubmitFunction<TermFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Main>
			<Row justify="center" align="center" style={{ paddingTop: "10%" }}>
				<Tile style={{ width: "50%", height: "fit-content" }}>
					<Tile.Header isPrimary>
						<Heading level={1} noMargin>
							Term Setup
						</Heading>
					</Tile.Header>
					<Tile.Body>
						<Paragraph style={{ fontSize: "1.5rem" }}>Let's get the next term set up!</Paragraph>

						<Section>
							<Section.Body style={{ paddingLeft: 0, paddingRight: 0 }}>
								<Form name="term.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
									<Column justify="spaced-start">
										<TermField.Name formState={formState} />
										<Row justify="space-between">
											<TermField.StartDate formState={formState} />
											<TermField.EndDate formState={formState} />
										</Row>
									</Column>
								</Form>
							</Section.Body>
						</Section>
					</Tile.Body>

					<Tile.Footer>
						<Row justify="flex-end">
							<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
								Continue
							</SubmitButton>
						</Row>
					</Tile.Footer>
				</Tile>
			</Row>
		</Main>
	);
}
