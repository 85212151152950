import { ClassNameProps, makeClassName, Row, StyleProps } from "@hex-insights/core";
import {
	EnrollmentApplication,
	EnrollmentApplicationFormat,
	EnrollmentApplicationStatus,
} from "@hex-insights/verita.shared";
import styles from "./styles.module.css";

export type EnrollmentApplicationStatusBadgeProps = {
	status: EnrollmentApplication["status"];
	hasConfirmed?: boolean;
} & Partial<ClassNameProps & StyleProps>;

export function EnrollmentApplicationStatusBadge({
	status,
	hasConfirmed = false,
	className,
	style,
}: EnrollmentApplicationStatusBadgeProps) {
	return (
		<Row
			justify="center"
			align="center"
			className={makeClassName(styles["status"], styles[`status--${status}`], className)}
			style={style}
		>
			<span
				className={styles["text"]}
				title={`${EnrollmentApplicationFormat.Fields.status(status)}${
					status === EnrollmentApplicationStatus.Approved && hasConfirmed ? " & Confirmed" : ""
				}`}
			>
				{EnrollmentApplicationFormat.Fields.status(status)}
				{status === EnrollmentApplicationStatus.Approved && hasConfirmed ? " (C)" : ""}
			</span>
		</Row>
	);
}
