import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	AddressFilterFormController,
	AddressFilterFormState,
	AddressFilterFormUtils,
	AddressFormat,
	AddressIndexQuery,
	AddressOrderField,
	AddressOrderFormController,
	AddressOrderFormState,
	AddressOrderFormValues,
	AddressPaginationForm,
	AddressSearchForm,
	useAddressIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { addressHubCreatePageInfo } from "../CreatePage/pageinfo";
import { addressHubDetailPageInfo } from "../DetailPage/pageinfo";
import { addressHubIndexPageInfo } from "./pageinfo";

const columns: (keyof AddressIndexQuery["addressConnection"]["edges"][0]["node"])[] = [
	"shortAddress",
	"addressLine1",
	"addressLine2",
	"city",
	"postalCode",
	"stateOrProvince",
	"country",
];

const columnWidths = {
	shortAddress: "minmax(20rem, 1fr)",
	addressLine1: "minmax(15rem, 1fr)",
};

function orderFieldToColumn(field: AddressOrderField | null) {
	if (field === null) {
		return "id";
	}
	return AddressOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return AddressOrderFormValues.modelFieldToOrderField[column];
}

export function AddressHubIndexPage() {
	useActivePageRegistration(addressHubIndexPageInfo);

	const filterFormState = AddressFilterFormState.useFormState();
	const orderFormState = AddressOrderFormState.useFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(filterFormState.formValues, AddressFilterFormUtils.toFilterInputs);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useAddressIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		AddressFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = AddressFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.addressConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading level={1} noMargin>
					Addresses
				</Heading>

				<InternalLink to={addressHubCreatePageInfo.to}>New Address</InternalLink>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<AddressSearchForm formState={filterFormState} />
				<AddressFilterFormController formState={filterFormState} numActiveFilters={numActiveFilters} />
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<AddressOrderFormController formState={orderFormState} />
			</Row>

			<Table.Container className="hub__index-page__table__container">
				<Table
					columns={columns}
					columnWidths={columnWidths}
					minColumnWidth="10rem"
					{...tableSortingProps}
					className="hub__index-page__table"
				>
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="shortAddress" noSort>
								Address
							</Table.Heading>
							<Table.Heading column="addressLine1">Address Line 1</Table.Heading>
							<Table.Heading column="addressLine2">Address Line 2</Table.Heading>
							<Table.Heading column="city">City</Table.Heading>
							<Table.Heading column="postalCode">Postal Code</Table.Heading>
							<Table.Heading column="stateOrProvince">State or Province</Table.Heading>
							<Table.Heading column="country">Country</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.addressConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="shortAddress" oneLine>
											<InternalLink to={addressHubDetailPageInfo.to(e.node.id)}>
												{AddressFormat.Fields.shortAddress(e.node.shortAddress)}
											</InternalLink>
										</Table.Cell>
										<Table.Cell column="addressLine1" oneLine>
											{AddressFormat.Fields.addressLine1(e.node.addressLine1)}
										</Table.Cell>
										<Table.Cell column="addressLine2" oneLine>
											{AddressFormat.Fields.addressLine2(e.node.addressLine2)}
										</Table.Cell>
										<Table.Cell column="city" oneLine>
											{AddressFormat.Fields.city(e.node.city)}
										</Table.Cell>
										<Table.Cell column="postalCode" oneLine>
											{AddressFormat.Fields.postalCode(e.node.postalCode)}
										</Table.Cell>
										<Table.Cell column="stateOrProvince" oneLine>
											{AddressFormat.Fields.stateOrProvince(e.node.stateOrProvince)}
										</Table.Cell>
										<Table.Cell column="country" oneLine>
											{AddressFormat.Fields.country(e.node.country)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<AddressPaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.addressConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
