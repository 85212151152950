import React from "react";
import {
	CreateFunction,
	onCreateSubmit,
	onUpdateSubmit,
	UpdateFunction,
	useApplyCreate,
	useApplyUpdate,
} from "@hex-insights/app-modules";
import { ClassNameProps, DistributiveOmit, StyleProps } from "@hex-insights/core";
import {
	Form,
	FormEditButtonPair,
	FormState,
	FormSubmitFunction,
	SubmitButton,
	useOnSuccess,
} from "@hex-insights/forms";
import {
	BillingSetupSubmissionCreateMutation,
	BillingSetupSubmissionDetailQuery,
	BillingSetupSubmissionFormConversion,
	BillingSetupSubmissionFormState,
	BillingSetupSubmissionFormValues,
	BillingSetupSubmissionMutation,
	BillingSetupSubmissionUpdateMutation,
} from "../../../../Utilities";
import { BaseFormProps } from "../Shared";
import * as Field from "./Field";

export type ControlledCreateProps = Omit<
	CreateProps<BillingSetupSubmissionCreateMutation["createBillingSetupSubmission"]>,
	"formState" | "create"
> & {
	formStateArgs?: Partial<BillingSetupSubmissionFormState.UseCreateFormStateArgs>;
};

/**
 * Renders the create form of the BillingSetupSubmission model using an internally managed form state.
 */
export function ControlledCreate({ formStateArgs, ...props }: ControlledCreateProps) {
	const formState = BillingSetupSubmissionFormState.useCreateFormState(formStateArgs);
	const create = BillingSetupSubmissionMutation.useCreate();

	return <Create formState={formState} create={create} {...props} />;
}

export type CreateProps<T> = {
	formState: FormState<BillingSetupSubmissionFormValues.Create>;
	create: CreateFunction<BillingSetupSubmissionFormValues.Create, T>;
	onSuccess: (data: T) => void;
	formNameSuffix?: string;
} & BaseFormProps<BillingSetupSubmissionFormValues.Create> &
	Partial<ClassNameProps & StyleProps>;

/**
 * Renders the create form of the BillingSetupSubmission model using the given form state.
 */
export function Create<T>({ formState, create, onSuccess, formNameSuffix, ...props }: CreateProps<T>) {
	const { applyCreate, resultRef } = useApplyCreate(create);
	const onSubmit = React.useCallback<FormSubmitFunction<BillingSetupSubmissionFormValues.Create>>(
		async ({ formValues }) => onCreateSubmit(formValues, applyCreate),
		[applyCreate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	const formName = "billingSetupSubmission.create" + (formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form name={formName} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} {...props}>
			<Form.Column>
				<CreateFields formState={formState} />

				<Form.ActionBar>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Form.ActionBar>
			</Form.Column>
		</Form>
	);
}

type CreateFieldsProps = {
	formState: FormState<BillingSetupSubmissionFormValues.Create>;
};

function CreateFields({ formState }: CreateFieldsProps) {
	return (
		<React.Fragment>
			<Field.NumPayments formState={formState} />
			<Field.IsInterestedInBusPlan formState={formState} />
			<Field.IsInterestedInMealPlan formState={formState} />
			<Field.AuthorName formState={formState} />
			<Field.AuthorEmail formState={formState} />
			<Field.AuthorPhone formState={formState} />
			<Field.AcademicYear formState={formState} />
			<Field.Student formState={formState} />
		</React.Fragment>
	);
}

export type ControlledDetailProps = Omit<
	DetailProps<BillingSetupSubmissionUpdateMutation["updateBillingSetupSubmission"]>,
	"formState" | "update"
> & {
	formStateArgs?: Partial<
		DistributiveOmit<BillingSetupSubmissionFormState.UseDetailFormStateArgs, "initialFormValues">
	>;
};

/**
 * Renders the detail form of the BillingSetupSubmission model using an internally managed form state.
 */
export function ControlledDetail({ formStateArgs, ...props }: ControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => BillingSetupSubmissionFormConversion.toFormValues(props.billingSetupSubmission),
		[props.billingSetupSubmission],
	);
	const formState = BillingSetupSubmissionFormState.useDetailFormState({ ...formStateArgs, initialFormValues });
	const update = BillingSetupSubmissionMutation.useUpdate(props.billingSetupSubmission.id);

	return <Detail formState={formState} update={update} {...props} />;
}

export type DetailProps<T> = {
	billingSetupSubmission: BillingSetupSubmissionDetailQuery["billingSetupSubmission"];
	formState: FormState<BillingSetupSubmissionFormValues.Detail>;
	update: UpdateFunction<BillingSetupSubmissionFormValues.Detail, T>;
	onSuccess: (data: T) => void;
	formNameSuffix?: string;
} & BaseFormProps<BillingSetupSubmissionFormValues.Detail> &
	Partial<ClassNameProps & StyleProps>;

/**
 * Renders the detail form of the BillingSetupSubmission model using the given form state.
 */
export function Detail<T>({
	formState,
	billingSetupSubmission,
	update,
	onSuccess,
	formNameSuffix,
	...props
}: DetailProps<T>) {
	const { applyUpdate, resultRef } = useApplyUpdate(update);
	const onSubmit = React.useCallback<FormSubmitFunction<BillingSetupSubmissionFormValues.Detail>>(
		async (formState) => onUpdateSubmit(formState, applyUpdate),
		[applyUpdate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	const formName =
		`billingSetupSubmission.detail.${billingSetupSubmission.id}` + (formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form name={formName} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} {...props}>
			<Form.Column>
				<FormEditButtonPair formState={formState} />

				<DetailFields formState={formState} billingSetupSubmission={billingSetupSubmission} />
			</Form.Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = Omit<ReadOnlyProps, "formState"> & {
	formStateArgs?: Partial<
		DistributiveOmit<BillingSetupSubmissionFormState.UseReadOnlyFormStateArgs, "initialFormValues">
	>;
};

/**
 * Renders a read-only detail form of the BillingSetupSubmission model using an internally managed form state.
 */
export function ControlledReadOnly({ formStateArgs, ...props }: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => BillingSetupSubmissionFormConversion.toFormValues(props.billingSetupSubmission),
		[props.billingSetupSubmission],
	);
	const formState = BillingSetupSubmissionFormState.useReadOnlyFormState({ ...formStateArgs, initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = {
	billingSetupSubmission: BillingSetupSubmissionDetailQuery["billingSetupSubmission"];
	formState: FormState<BillingSetupSubmissionFormValues.Detail>;
} & Partial<ClassNameProps & StyleProps>;

/**
 * Renders a read-only detail form of the BillingSetupSubmission model using the given form state.
 */
export function ReadOnly({ formState, billingSetupSubmission, className, style }: ReadOnlyProps) {
	return (
		<div className={className} style={style}>
			<Form.Column>
				<DetailFields formState={formState} billingSetupSubmission={billingSetupSubmission} />
			</Form.Column>
		</div>
	);
}

type DetailFieldsProps = {
	billingSetupSubmission: BillingSetupSubmissionDetailQuery["billingSetupSubmission"];
	formState: FormState<BillingSetupSubmissionFormValues.Detail>;
};

function DetailFields({ formState, billingSetupSubmission }: DetailFieldsProps) {
	return (
		<React.Fragment>
			<Field.NumPayments formState={formState} />
			<Field.IsInterestedInBusPlan formState={formState} />
			<Field.IsInterestedInMealPlan formState={formState} />
			<Field.AuthorName formState={formState} />
			<Field.AuthorEmail formState={formState} />
			<Field.AuthorPhone formState={formState} />
			<Field.AcademicYear formState={formState} currentAcademicYear={billingSetupSubmission.academicYear} />
			<Field.Student formState={formState} currentStudent={billingSetupSubmission.student} />
		</React.Fragment>
	);
}
