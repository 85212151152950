import { newPageInfo } from "@hex-insights/router";
import { SchoolAttendanceRecord } from "@hex-insights/verita.shared";
import { schoolAttendanceRecordHubPageInfo } from "../pageinfo";

type SchoolAttendanceRecordForRouteTitle = Pick<SchoolAttendanceRecord, "id">;

export const schoolAttendanceRecordHubDetailPageInfo = newPageInfo({
	name: "School Attendance Record Detail",
	title: (e: SchoolAttendanceRecordForRouteTitle) => e.id,
	description: "Detail page for an schoolAttendanceRecord.",
	exact: false,
	isLeaf: true,
	to: (id: SchoolAttendanceRecord["id"]) => schoolAttendanceRecordHubPageInfo.to + `/${id}`,
	path: schoolAttendanceRecordHubPageInfo.path + "/:id(\\d+)",
	parent: schoolAttendanceRecordHubPageInfo,
});
