import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const expenseCategoryHubPageInfo = newPageInfo({
	title: "Expense Categories",
	description: "Pages related to the Expense Category model.",
	exact: false,
	to: hubsPageInfo.to + "/expense-categories",
	path: hubsPageInfo.path + "/expense-categories",
	parent: hubsPageInfo,
});
