import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { TermCreateInput, TermDetailQuery, TermUpdateInput } from "../../GraphQL";
import { TermFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Term model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: TermFormValues.Create): TermCreateInput {
	const input: NullPartial<TermCreateInput> = { ...formValues };
	return input as TermCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Term model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<TermFormValues.Detail>,
	initialFormValues: TermFormValues.Detail,
): TermUpdateInput {
	const inputFormValues = omitKeys(formValues, ["courseSectionIDs", "homeRoomSectionIDs"]);
	const input: TermUpdateInput = { ...inputFormValues };

	if (formValues.courseSectionIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.courseSectionIDs, formValues.courseSectionIDs);
		input.addCourseSectionIDs = added;
		input.removeCourseSectionIDs = removed;
	}

	if (formValues.homeRoomSectionIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.homeRoomSectionIDs, formValues.homeRoomSectionIDs);
		input.addHomeRoomSectionIDs = added;
		input.removeHomeRoomSectionIDs = removed;
	}

	return input;
}

/**
 * Converts a Term instance to its corresponding detail form values.
 *
 * @param term The instance to convert.
 */
export function toFormValues(term: TermDetailQuery["term"]): TermFormValues.Detail {
	return {
		name: term.name,
		startDate: term.startDate,
		endDate: term.endDate,
		courseSectionIDs: term.courseSections.map((e) => e.id),
		homeRoomSectionIDs: term.homeRoomSections.map((e) => e.id),
		academicYearID: term.academicYear.id,
	};
}
