import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { InvoiceLineItem, InvoiceLineItemFormat, InvoiceLineItemNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<InvoiceLineItem, "id"> & InvoiceLineItemNameFragment;

export type InvoiceLineItemLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given InvoiceLineItem instance.
 */
export function InvoiceLineItemLink(props: InvoiceLineItemLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="InvoiceLineItem" format={InvoiceLineItemFormat.name} {...props} />;
}
