import {
	addTimeToDate,
	CaseStyle,
	convertCase,
	formatDateTime,
	stringToLocalDate,
	toLocalDateString,
} from "@hex-insights/core";
import { EnrollmentApplication } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the createdAt field of the EnrollmentApplication model.
 */
export function createdAt(value: EnrollmentApplication["createdAt"] | null): string {
	if (value === null) {
		return toString(value);
	}

	const dateValue = new Date(value);
	const today = new Date();
	const isoValue = toLocalDateString(dateValue);
	if (isoValue === toLocalDateString(today)) {
		return formatDateTime(value, "[Today,] HH:mm");
	}
	if (isoValue === toLocalDateString(addTimeToDate(today, [-1, "day"]))) {
		return formatDateTime(value, "[Yesterday,] HH:mm");
	}
	if (dateValue.getFullYear() === today.getFullYear()) {
		return formatDateTime(value, "D/M");
	}

	return formatDateTime(value, "D/M/YYYY");
}

/**
 * Formats the status field of the EnrollmentApplication model.
 */
export function status(value: EnrollmentApplication["status"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return convertCase(value, CaseStyle.Title);
}

/**
 * Formats the statusUpdatedAt field of the EnrollmentApplication model.
 */
export function statusUpdatedAt(value: EnrollmentApplication["statusUpdatedAt"] | null): string {
	if (value === null) {
		return toString(value);
	}
	const dateValue = stringToLocalDate(value, "minute");
	return dateValue.toLocaleString(undefined, {
		year: dateValue.getFullYear() === new Date().getFullYear() ? undefined : "2-digit",
		month: "numeric",
		day: "numeric",
		hour: "numeric",
		minute: "2-digit",
	});
}

/**
 * Formats the approvedEmailSentAt field of the EnrollmentApplication model.
 */
export function approvedEmailSentAt(value: EnrollmentApplication["approvedEmailSentAt"] | null): string {
	if (value === null) {
		return toString(value);
	}
	const dateValue = stringToLocalDate(value, "minute");
	return dateValue.toLocaleString(undefined, {
		year: dateValue.getFullYear() === new Date().getFullYear() ? undefined : "2-digit",
		month: "numeric",
		day: "numeric",
		hour: "numeric",
		minute: "2-digit",
	});
}

/**
 * Formats the interviewMeetingTime field of the EnrollmentApplication model.
 */
export function interviewMeetingTime(value: EnrollmentApplication["interviewMeetingTime"] | null): string {
	if (value === null) {
		return "Not Scheduled";
	}

	const dateValue = new Date(value);
	const today = new Date();
	const isoValue = toLocalDateString(dateValue);
	if (isoValue === toLocalDateString(today)) {
		return formatDateTime(value, "[Today,] HH:mm");
	}
	if (isoValue === toLocalDateString(addTimeToDate(today, [-1, "day"]))) {
		return formatDateTime(value, "[Yesterday,] HH:mm");
	}
	if (isoValue === toLocalDateString(addTimeToDate(today, [1, "day"]))) {
		return formatDateTime(value, "[Tomorrow,] HH:mm");
	}
	if (dateValue.getFullYear() === today.getFullYear()) {
		return formatDateTime(value, "D/M[,] HH:mm");
	}

	return formatDateTime(value, "D/M/YYYY[,] HH:mm");
}

export function externalLink(value: EnrollmentApplication["externalLink"]): string {
	return toString(value);
}
