import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	DocumentResourceForm,
	DocumentResourceFormValues,
	DocumentResourceMutation,
	useDocumentResourceDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { documentResourceHubDetailPageInfo } from "./pageinfo";

function DocumentResourceHubDetailPageWrapper() {
	// Manages identity of the DocumentResourceHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <DocumentResourceHubDetailPage key={id} />;
}

export { DocumentResourceHubDetailPageWrapper as DocumentResourceHubDetailPage };

function DocumentResourceHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useDocumentResourceDetailQuery({ variables: { id } });
	const documentResource = data?.documentResource;
	useActivePageRegistration(
		documentResourceHubDetailPageInfo,
		!!documentResource ? documentResourceHubDetailPageInfo.title(documentResource) : "",
	);

	const update = DocumentResourceMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<DocumentResourceFormValues.Detail>,
			initialFormValues: DocumentResourceFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading documentResource</If>
				<Else>
					{!!documentResource && (
						<React.Fragment>
							<Heading level={1}>{documentResource.name}</Heading>

							<DocumentResourceForm.ControlledDetail
								key={syncID}
								documentResource={documentResource}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
