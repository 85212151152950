import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { UserGroupCreateInput, UserGroupDetailQuery, UserGroupUpdateInput } from "../../GraphQL";
import { UserGroupFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the UserGroup model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: UserGroupFormValues.Create): UserGroupCreateInput {
	const input: NullPartial<UserGroupCreateInput> = { ...formValues };
	return input as UserGroupCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the UserGroup model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<UserGroupFormValues.Detail>,
	initialFormValues: UserGroupFormValues.Detail,
): UserGroupUpdateInput {
	const inputFormValues = omitKeys(formValues, ["userIDs"]);
	const input: UserGroupUpdateInput = { ...inputFormValues };

	if (formValues.image === null) {
		input.clearImage = true;
	}

	if (formValues.userIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.userIDs, formValues.userIDs);
		input.addUserIDs = added;
		input.removeUserIDs = removed;
	}

	return input;
}

/**
 * Converts a UserGroup instance to its corresponding detail form values.
 *
 * @param userGroup The instance to convert.
 */
export function toFormValues(userGroup: UserGroupDetailQuery["userGroup"]): UserGroupFormValues.Detail {
	return {
		name: userGroup.name,
		description: userGroup.description,
		image: userGroup.imageURL !== "" ? new File([], "image") : null,
		color: userGroup.color,
		userIDs: userGroup.users.map((e) => e.id),
	};
}
