import { newPageInfo } from "@hex-insights/router";
import { billingSetupSubmissionHubPageInfo } from "../pageinfo";

export const billingSetupSubmissionHubCreatePageInfo = newPageInfo({
	name: "New Billing Setup Submission",
	title: "New",
	description: "Billing Setup Submission create page.",
	exact: true,
	to: billingSetupSubmissionHubPageInfo.to + "/new",
	path: billingSetupSubmissionHubPageInfo.path + "/new",
	parent: billingSetupSubmissionHubPageInfo,
});
