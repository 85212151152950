import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const academicYearTuitionHubPageInfo = newPageInfo({
	title: "Academic Year Tuitions",
	description: "Pages related to the Academic Year Tuition model.",
	exact: false,
	to: hubsPageInfo.to + "/academic-year-tuitions",
	path: hubsPageInfo.path + "/academic-year-tuitions",
	parent: hubsPageInfo,
});
