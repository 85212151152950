import React from "react";
import { Column, Heading } from "@hex-insights/core";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { TermForm, TermFormValues, TermMutation } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { termHubDetailPageInfo } from "../DetailPage/pageinfo";
import { termHubCreatePageInfo } from "./pageinfo";

export function TermHubCreatePage() {
	useActivePageRegistration(termHubCreatePageInfo);

	const create = TermMutation.useCreate();
	const createdIDRef = React.useRef<string | null>(null);
	const { push: pushHistory } = useHistory();

	const applyCreate = React.useCallback(
		async (formValues: TermFormValues.Create) => {
			const { data, errors } = await create(formValues);
			createdIDRef.current = data?.id ?? null;
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		if (createdIDRef.current !== null) {
			pushHistory(termHubDetailPageInfo.to(createdIDRef.current));
		}
	}, [pushHistory]);

	return (
		<Main>
			<Heading level={1}>New Term</Heading>

			<Column style={{ width: "fit-content" }}>
				<TermForm.ControlledCreate applyCreate={applyCreate} onSuccess={onSuccess} />
			</Column>
		</Main>
	);
}
