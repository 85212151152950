import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { EmailAddressOrderFormValues } from "../../../../Utilities";

export type EmailAddressOrderFormControllerProps = {
	formState: FormState<EmailAddressOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the EmailAddress model.
 */
export function EmailAddressOrderFormController({ formState }: EmailAddressOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={EmailAddressOrderFormValues.fieldOptions}
			fieldTypeMapping={EmailAddressOrderFormValues.fieldTypeMapping}
		/>
	);
}
