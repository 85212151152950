import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { ContractHubCreatePage } from "./CreatePage";
import { contractHubCreatePageInfo } from "./CreatePage/pageinfo";
import { ContractHubDetailPage } from "./DetailPage";
import { contractHubDetailPageInfo } from "./DetailPage/pageinfo";
import { ContractHubIndexPage } from "./IndexPage";
import { contractHubIndexPageInfo } from "./IndexPage/pageinfo";
import { contractHubPageInfo } from "./pageinfo";

export function ContractHub() {
	useActivePageRegistration(contractHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...contractHubIndexPageInfo.routeProps}>
				<ContractHubIndexPage />
			</Route>
			<Route {...contractHubCreatePageInfo.routeProps}>
				<ContractHubCreatePage />
			</Route>
			<Route {...contractHubDetailPageInfo.routeProps}>
				<ContractHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
