import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	ServiceSubscriptionForm,
	ServiceSubscriptionFormValues,
	ServiceSubscriptionMutation,
	useServiceSubscriptionDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { serviceSubscriptionHubDetailPageInfo } from "./pageinfo";

function ServiceSubscriptionHubDetailPageWrapper() {
	// Manages identity of the ServiceSubscriptionHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <ServiceSubscriptionHubDetailPage key={id} />;
}

export { ServiceSubscriptionHubDetailPageWrapper as ServiceSubscriptionHubDetailPage };

function ServiceSubscriptionHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useServiceSubscriptionDetailQuery({ variables: { id } });
	const serviceSubscription = data?.serviceSubscription;
	useActivePageRegistration(
		serviceSubscriptionHubDetailPageInfo,
		!!serviceSubscription ? serviceSubscriptionHubDetailPageInfo.title(serviceSubscription) : "",
	);

	const update = ServiceSubscriptionMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<ServiceSubscriptionFormValues.Detail>,
			initialFormValues: ServiceSubscriptionFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading serviceSubscription</If>
				<Else>
					{!!serviceSubscription && (
						<React.Fragment>
							<Heading level={1}>{serviceSubscription.id}</Heading>

							<ServiceSubscriptionForm.ControlledDetail
								key={syncID}
								serviceSubscription={serviceSubscription}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
