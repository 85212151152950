import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	graphTypeNames,
	Parent,
	Payment,
	PaymentCreateInput,
	PaymentCreateMutation,
	PaymentDeleteMutation,
	PaymentDetailDocument,
	PaymentDetailQuery,
	PaymentDetailQueryVariables,
	PaymentLineItem,
	PaymentUpdateInput,
	PaymentUpdateMutation,
	usePaymentCreateMutation,
	usePaymentDeleteMutation,
	usePaymentUpdateMutation,
} from "../../GraphQL";
import { PaymentFormConversion } from "../ModelFormConversion";
import { PaymentFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the Payment model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Payment model.
 */
export function useCreate() {
	const [createPayment] = usePaymentCreateMutation();

	return React.useCallback(
		async (formValues: PaymentFormValues.Create) => {
			const input = PaymentFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createPayment({ variables: { input }, update: updateCache });

			return { data: data?.createPayment ?? null, errors: errors ?? null };
		},
		[createPayment],
	);
}

/**
 * Returns an `update` function for the Payment model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Payment model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Payment["id"]) {
	const [updatePayment] = usePaymentUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<PaymentFormValues.Detail>, initialFormValues: PaymentFormValues.Detail) => {
			const input = PaymentFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updatePayment({ variables: { id, input }, update: updateCache });

			return { data: data?.updatePayment ?? null, errors: errors ?? null };
		},
		[updatePayment, id],
	);
}

/**
 * Returns a `del` function for the Payment model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Payment["id"]) {
	const [deletePayment] = usePaymentDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deletePayment({ variables: { id }, update: updateCache });

		return { data: data?.deletePayment ?? false, errors: errors ?? null };
	}, [deletePayment, id]);
}

function getUpdateCacheForCreate(input: PaymentCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<PaymentCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createPayment;

		cache.writeQuery<PaymentDetailQuery, PaymentDetailQueryVariables>({
			query: PaymentDetailDocument,
			data: { payment: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.parentID) {
			addToPaymentsOfParentCache(cache, input.parentID, createdObject);
		}

		if (input.paymentLineItemIDs) {
			for (let i = 0; i < input.paymentLineItemIDs.length; i++) {
				addToPaymentOfPaymentLineItemCache(cache, input.paymentLineItemIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "paymentConnection" });
	};
}

function getUpdateCacheForUpdate(input: PaymentUpdateInput, initialFormValues: PaymentFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<PaymentUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updatePayment;

		if (initialFormValues.parentID && input.parentID) {
			removeFromPaymentsOfParentCache(cache, initialFormValues.parentID, updatedObject);
		}
		if (input.parentID) {
			addToPaymentsOfParentCache(cache, input.parentID, updatedObject);
		}

		if (input.addPaymentLineItemIDs) {
			for (let i = 0; i < input.addPaymentLineItemIDs.length; i++) {
				addToPaymentOfPaymentLineItemCache(cache, input.addPaymentLineItemIDs[i], updatedObject);
			}
		}
		if (input.removePaymentLineItemIDs) {
			for (let i = 0; i < input.removePaymentLineItemIDs.length; i++) {
				removeFromPaymentOfPaymentLineItemCache(cache, input.removePaymentLineItemIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: Payment["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<PaymentDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deletePayment) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Payment }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "paymentConnection" });
		cache.gc();
	};
}

function addToPaymentsOfParentCache(cache: ApolloCache<any>, targetID: Parent["id"], object: Pick<Payment, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Parent }),
		fields: {
			payments: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromPaymentsOfParentCache(cache: ApolloCache<any>, targetID: Parent["id"], object: Pick<Payment, "id">) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Parent }),
		fields: {
			payments: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToPaymentOfPaymentLineItemCache(
	cache: ApolloCache<any>,
	targetID: PaymentLineItem["id"],
	object: Pick<Payment, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.PaymentLineItem }),
		fields: {
			payment: () => objectRef,
			paymentID: () => object.id,
		},
	});
}

function removeFromPaymentOfPaymentLineItemCache(
	cache: ApolloCache<any>,
	targetID: PaymentLineItem["id"],
	_object: Pick<Payment, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.PaymentLineItem }),
		fields: {
			payment: () => null,
			paymentID: () => null,
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Payment, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment PaymentRef on Payment {
				id
			}
		`,
		data: object,
	});
}
