import { newPageInfo } from "@hex-insights/router";
import { classYearHubPageInfo } from "../pageinfo";

export const classYearHubCreatePageInfo = newPageInfo({
	name: "New Class Year",
	title: "New",
	description: "Create a new Class Year record.",
	exact: true,
	to: classYearHubPageInfo.to + "/new",
	path: classYearHubPageInfo.path + "/new",
	parent: classYearHubPageInfo,
});
