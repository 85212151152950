import { newPageInfo } from "@hex-insights/router";
import { trustedDeviceHubPageInfo } from "../pageinfo";

export const trustedDeviceHubIndexPageInfo = newPageInfo({
	name: "Trusted Devices Index",
	title: "",
	description: "Index of Trusted Devices.",
	exact: true,
	to: trustedDeviceHubPageInfo.to,
	path: trustedDeviceHubPageInfo.path,
	parent: trustedDeviceHubPageInfo,
});
