import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { PhoneNumberHubCreatePage } from "./CreatePage";
import { phoneNumberHubCreatePageInfo } from "./CreatePage/pageinfo";
import { PhoneNumberHubDetailPage } from "./DetailPage";
import { phoneNumberHubDetailPageInfo } from "./DetailPage/pageinfo";
import { PhoneNumberHubIndexPage } from "./IndexPage";
import { phoneNumberHubIndexPageInfo } from "./IndexPage/pageinfo";
import { phoneNumberHubPageInfo } from "./pageinfo";

export function PhoneNumberHub() {
	useActivePageRegistration(phoneNumberHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...phoneNumberHubIndexPageInfo.routeProps}>
				<PhoneNumberHubIndexPage />
			</Route>
			<Route {...phoneNumberHubCreatePageInfo.routeProps}>
				<PhoneNumberHubCreatePage />
			</Route>
			<Route {...phoneNumberHubDetailPageInfo.routeProps}>
				<PhoneNumberHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
