// Code generated by @hex-insights/frontend-generator; DO NOT EDIT.
import React from "react";
import { BooleanField, FormState } from "@hex-insights/forms";
import { SearchResultFilterInput } from "@hex-insights/verita.shared";

export type ModelNameFiltersFormValues = {
	includeAcademicYear: boolean;
	includeAddress: boolean;
	includeBus: boolean;
	includeCalendarEvent: boolean;
	includeCampus: boolean;
	includeClassYear: boolean;
	includeCourse: boolean;
	includeCourseSection: boolean;
	includeDocumentResource: boolean;
	includeDocumentResourceFolder: boolean;
	includeEmailAddress: boolean;
	includeEmployee: boolean;
	includeExpenseCategory: boolean;
	includeHomeRoom: boolean;
	includeHomeRoomSection: boolean;
	includeInvoice: boolean;
	includeLearningObjective: boolean;
	includeParent: boolean;
	includePermissionGroup: boolean;
	includePhoneNumber: boolean;
	includeReportCard: boolean;
	includeRoom: boolean;
	includeService: boolean;
	includeStudent: boolean;
	includeTerm: boolean;
	includeTuition: boolean;
	includeUser: boolean;
	includeUserGroup: boolean;
};

export const initialModelNameFiltersFormValues: ModelNameFiltersFormValues = {
	includeAcademicYear: true,
	includeAddress: true,
	includeBus: true,
	includeCalendarEvent: true,
	includeCampus: true,
	includeClassYear: true,
	includeCourse: true,
	includeCourseSection: true,
	includeDocumentResource: true,
	includeDocumentResourceFolder: true,
	includeEmailAddress: true,
	includeEmployee: true,
	includeExpenseCategory: true,
	includeHomeRoom: true,
	includeHomeRoomSection: true,
	includeInvoice: true,
	includeLearningObjective: true,
	includeParent: true,
	includePermissionGroup: true,
	includePhoneNumber: true,
	includeReportCard: true,
	includeRoom: true,
	includeService: true,
	includeStudent: true,
	includeTerm: true,
	includeTuition: true,
	includeUser: true,
	includeUserGroup: true,
};

export function checkAllModels(formState: FormState<ModelNameFiltersFormValues>) {
	formState.formSetFunctions.includeAcademicYear(true);
	formState.formSetFunctions.includeAddress(true);
	formState.formSetFunctions.includeBus(true);
	formState.formSetFunctions.includeCalendarEvent(true);
	formState.formSetFunctions.includeCampus(true);
	formState.formSetFunctions.includeClassYear(true);
	formState.formSetFunctions.includeCourse(true);
	formState.formSetFunctions.includeCourseSection(true);
	formState.formSetFunctions.includeDocumentResource(true);
	formState.formSetFunctions.includeDocumentResourceFolder(true);
	formState.formSetFunctions.includeEmailAddress(true);
	formState.formSetFunctions.includeEmployee(true);
	formState.formSetFunctions.includeExpenseCategory(true);
	formState.formSetFunctions.includeHomeRoom(true);
	formState.formSetFunctions.includeHomeRoomSection(true);
	formState.formSetFunctions.includeInvoice(true);
	formState.formSetFunctions.includeLearningObjective(true);
	formState.formSetFunctions.includeParent(true);
	formState.formSetFunctions.includePermissionGroup(true);
	formState.formSetFunctions.includePhoneNumber(true);
	formState.formSetFunctions.includeReportCard(true);
	formState.formSetFunctions.includeRoom(true);
	formState.formSetFunctions.includeService(true);
	formState.formSetFunctions.includeStudent(true);
	formState.formSetFunctions.includeTerm(true);
	formState.formSetFunctions.includeTuition(true);
	formState.formSetFunctions.includeUser(true);
	formState.formSetFunctions.includeUserGroup(true);
}

export function uncheckAllModels(formState: FormState<ModelNameFiltersFormValues>) {
	formState.formSetFunctions.includeAcademicYear(false);
	formState.formSetFunctions.includeAddress(false);
	formState.formSetFunctions.includeBus(false);
	formState.formSetFunctions.includeCalendarEvent(false);
	formState.formSetFunctions.includeCampus(false);
	formState.formSetFunctions.includeClassYear(false);
	formState.formSetFunctions.includeCourse(false);
	formState.formSetFunctions.includeCourseSection(false);
	formState.formSetFunctions.includeDocumentResource(false);
	formState.formSetFunctions.includeDocumentResourceFolder(false);
	formState.formSetFunctions.includeEmailAddress(false);
	formState.formSetFunctions.includeEmployee(false);
	formState.formSetFunctions.includeExpenseCategory(false);
	formState.formSetFunctions.includeHomeRoom(false);
	formState.formSetFunctions.includeHomeRoomSection(false);
	formState.formSetFunctions.includeInvoice(false);
	formState.formSetFunctions.includeLearningObjective(false);
	formState.formSetFunctions.includeParent(false);
	formState.formSetFunctions.includePermissionGroup(false);
	formState.formSetFunctions.includePhoneNumber(false);
	formState.formSetFunctions.includeReportCard(false);
	formState.formSetFunctions.includeRoom(false);
	formState.formSetFunctions.includeService(false);
	formState.formSetFunctions.includeStudent(false);
	formState.formSetFunctions.includeTerm(false);
	formState.formSetFunctions.includeTuition(false);
	formState.formSetFunctions.includeUser(false);
	formState.formSetFunctions.includeUserGroup(false);
}

type ModelNameFilterInputsProps = {
	formState: FormState<ModelNameFiltersFormValues>;
};

export function ModelNameFilterInputs({ formState }: ModelNameFilterInputsProps) {
	return (
		<React.Fragment>
			<BooleanField formState={formState} name="includeAcademicYear" label="Academic Years" noToolbar />
			<BooleanField formState={formState} name="includeAddress" label="Addresses" noToolbar />
			<BooleanField formState={formState} name="includeBus" label="Buses" noToolbar />
			<BooleanField formState={formState} name="includeCalendarEvent" label="Calendar Events" noToolbar />
			<BooleanField formState={formState} name="includeCampus" label="Campuses" noToolbar />
			<BooleanField formState={formState} name="includeClassYear" label="Class Years" noToolbar />
			<BooleanField formState={formState} name="includeCourse" label="Courses" noToolbar />
			<BooleanField formState={formState} name="includeCourseSection" label="Course Sections" noToolbar />
			<BooleanField formState={formState} name="includeDocumentResource" label="Document Resources" noToolbar />
			<BooleanField
				formState={formState}
				name="includeDocumentResourceFolder"
				label="Document Resource Folders"
				noToolbar
			/>
			<BooleanField formState={formState} name="includeEmailAddress" label="Email Addresses" noToolbar />
			<BooleanField formState={formState} name="includeEmployee" label="Employees" noToolbar />
			<BooleanField formState={formState} name="includeExpenseCategory" label="Expense Categories" noToolbar />
			<BooleanField formState={formState} name="includeHomeRoom" label="Home Rooms" noToolbar />
			<BooleanField formState={formState} name="includeHomeRoomSection" label="Home Room Sections" noToolbar />
			<BooleanField formState={formState} name="includeInvoice" label="Invoices" noToolbar />
			<BooleanField formState={formState} name="includeLearningObjective" label="Learning Objectives" noToolbar />
			<BooleanField formState={formState} name="includeParent" label="Parents" noToolbar />
			<BooleanField formState={formState} name="includePermissionGroup" label="Permission Groups" noToolbar />
			<BooleanField formState={formState} name="includePhoneNumber" label="Phone Numbers" noToolbar />
			<BooleanField formState={formState} name="includeReportCard" label="Report Cards" noToolbar />
			<BooleanField formState={formState} name="includeRoom" label="Rooms" noToolbar />
			<BooleanField formState={formState} name="includeService" label="Services" noToolbar />
			<BooleanField formState={formState} name="includeStudent" label="Students" noToolbar />
			<BooleanField formState={formState} name="includeTerm" label="Terms" noToolbar />
			<BooleanField formState={formState} name="includeTuition" label="Tuitions" noToolbar />
			<BooleanField formState={formState} name="includeUser" label="Users" noToolbar />
			<BooleanField formState={formState} name="includeUserGroup" label="User Groups" noToolbar />
		</React.Fragment>
	);
}

export const allModelNamesFilterInput = {
	modelNameIn: [
		"AcademicYear",
		"Address",
		"Bus",
		"CalendarEvent",
		"Campus",
		"ClassYear",
		"Course",
		"CourseSection",
		"DocumentResource",
		"DocumentResourceFolder",
		"EmailAddress",
		"Employee",
		"ExpenseCategory",
		"HomeRoom",
		"HomeRoomSection",
		"Invoice",
		"LearningObjective",
		"Parent",
		"PermissionGroup",
		"PhoneNumber",
		"ReportCard",
		"Room",
		"Service",
		"Student",
		"Term",
		"Tuition",
		"User",
		"UserGroup",
	],
};

export function convertModelNameFilterFormValuesToFilterInput(formValues: ModelNameFiltersFormValues) {
	const modelNames: SearchResultFilterInput["modelNameIn"] = [];

	if (formValues.includeAcademicYear) {
		modelNames.push("AcademicYear");
	}
	if (formValues.includeAddress) {
		modelNames.push("Address");
	}
	if (formValues.includeBus) {
		modelNames.push("Bus");
	}
	if (formValues.includeCalendarEvent) {
		modelNames.push("CalendarEvent");
	}
	if (formValues.includeCampus) {
		modelNames.push("Campus");
	}
	if (formValues.includeClassYear) {
		modelNames.push("ClassYear");
	}
	if (formValues.includeCourse) {
		modelNames.push("Course");
	}
	if (formValues.includeCourseSection) {
		modelNames.push("CourseSection");
	}
	if (formValues.includeDocumentResource) {
		modelNames.push("DocumentResource");
	}
	if (formValues.includeDocumentResourceFolder) {
		modelNames.push("DocumentResourceFolder");
	}
	if (formValues.includeEmailAddress) {
		modelNames.push("EmailAddress");
	}
	if (formValues.includeEmployee) {
		modelNames.push("Employee");
	}
	if (formValues.includeExpenseCategory) {
		modelNames.push("ExpenseCategory");
	}
	if (formValues.includeHomeRoom) {
		modelNames.push("HomeRoom");
	}
	if (formValues.includeHomeRoomSection) {
		modelNames.push("HomeRoomSection");
	}
	if (formValues.includeInvoice) {
		modelNames.push("Invoice");
	}
	if (formValues.includeLearningObjective) {
		modelNames.push("LearningObjective");
	}
	if (formValues.includeParent) {
		modelNames.push("Parent");
	}
	if (formValues.includePermissionGroup) {
		modelNames.push("PermissionGroup");
	}
	if (formValues.includePhoneNumber) {
		modelNames.push("PhoneNumber");
	}
	if (formValues.includeReportCard) {
		modelNames.push("ReportCard");
	}
	if (formValues.includeRoom) {
		modelNames.push("Room");
	}
	if (formValues.includeService) {
		modelNames.push("Service");
	}
	if (formValues.includeStudent) {
		modelNames.push("Student");
	}
	if (formValues.includeTerm) {
		modelNames.push("Term");
	}
	if (formValues.includeTuition) {
		modelNames.push("Tuition");
	}
	if (formValues.includeUser) {
		modelNames.push("User");
	}
	if (formValues.includeUserGroup) {
		modelNames.push("UserGroup");
	}

	if (modelNames.length === 0) {
		return allModelNamesFilterInput.modelNameIn;
	}

	return modelNames;
}
