import { DocumentResource, DocumentResourceUserViewRecord, User } from "../../GraphQL";

export type Base = {
	documentResourceID: DocumentResource["id"] | null;
};

export type Create = Base;

export type Detail = Base & {
	viewedAt: DocumentResourceUserViewRecord["viewedAt"] | null;
	userID: User["id"] | null;
};

export const initialCreate: Create = {
	documentResourceID: null,
};
