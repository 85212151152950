import { newPageInfo } from "@hex-insights/router";
import { mfaFactorHubPageInfo } from "../pageinfo";

export const mfaFactorHubIndexPageInfo = newPageInfo({
	name: "MFA Factors Index",
	title: "",
	description: "Index of MFA Factors.",
	exact: true,
	to: mfaFactorHubPageInfo.to,
	path: mfaFactorHubPageInfo.path,
	parent: mfaFactorHubPageInfo,
});
