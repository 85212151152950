import { newPageInfo } from "@hex-insights/router";
import { Payment } from "@hex-insights/verita.shared";
import { paymentHubPageInfo } from "../pageinfo";

type PaymentForRouteTitle = Pick<Payment, "id">;

export const paymentHubDetailPageInfo = newPageInfo({
	name: "Payment Detail",
	title: (e: PaymentForRouteTitle) => e.id,
	description: "Detail page for an payment.",
	exact: false,
	isLeaf: true,
	to: (id: Payment["id"]) => paymentHubPageInfo.to + `/${id}`,
	path: paymentHubPageInfo.path + "/:id(\\d+)",
	parent: paymentHubPageInfo,
});
