import { newPageInfo } from "@hex-insights/router";
import { documentResourceHubPageInfo } from "../pageinfo";

export const documentResourceHubCreatePageInfo = newPageInfo({
	name: "New Document Resource",
	title: "New",
	description: "Create a new Document Resource record.",
	exact: true,
	to: documentResourceHubPageInfo.to + "/new",
	path: documentResourceHubPageInfo.path + "/new",
	parent: documentResourceHubPageInfo,
});
