import { NullPartial } from "@hex-insights/core";
import {
	SchoolStudentEnrollmentCreateInput,
	SchoolStudentEnrollmentDetailQuery,
	SchoolStudentEnrollmentUpdateInput,
} from "../../GraphQL";
import { SchoolStudentEnrollmentFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the SchoolStudentEnrollment model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(
	formValues: SchoolStudentEnrollmentFormValues.Create,
): SchoolStudentEnrollmentCreateInput {
	const input: NullPartial<SchoolStudentEnrollmentCreateInput> = { ...formValues };
	return input as SchoolStudentEnrollmentCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the SchoolStudentEnrollment model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<SchoolStudentEnrollmentFormValues.Detail>,
	_initialFormValues: SchoolStudentEnrollmentFormValues.Detail,
): SchoolStudentEnrollmentUpdateInput {
	const input: SchoolStudentEnrollmentUpdateInput = { ...formValues };
	return input;
}

/**
 * Converts a SchoolStudentEnrollment instance to its corresponding detail form values.
 *
 * @param schoolStudentEnrollment The instance to convert.
 */
export function toFormValues(
	schoolStudentEnrollment: SchoolStudentEnrollmentDetailQuery["schoolStudentEnrollment"],
): SchoolStudentEnrollmentFormValues.Detail {
	return {
		createdDate: schoolStudentEnrollment.createdDate,
		paymentDueDate: schoolStudentEnrollment.paymentDueDate,
		hasPaidFee: schoolStudentEnrollment.hasPaidFee,
		isVoided: schoolStudentEnrollment.isVoided,
		academicYearID: schoolStudentEnrollment.academicYear?.id ?? null,
		classYearID: schoolStudentEnrollment.classYear?.id ?? null,
		studentID: schoolStudentEnrollment.student?.id ?? null,
	};
}
