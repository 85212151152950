import { IndexForms } from "@hex-insights/app-modules";
import { ServiceSubscriptionFilterFormValues } from "../../../../Utilities";

export type ServiceSubscriptionFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, ServiceSubscriptionFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the ServiceSubscription model.
 */
export function ServiceSubscriptionFilterFormController(props: ServiceSubscriptionFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={ServiceSubscriptionFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `ServiceSubscriptionFilterForm`
