import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	CourseSectionPeriodForm,
	CourseSectionPeriodFormat,
	CourseSectionPeriodFormValues,
	CourseSectionPeriodMutation,
	useCourseSectionPeriodDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { courseSectionPeriodHubDetailPageInfo } from "./pageinfo";

function CourseSectionPeriodHubDetailPageWrapper() {
	// Manages identity of the CourseSectionPeriodHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <CourseSectionPeriodHubDetailPage key={id} />;
}

export { CourseSectionPeriodHubDetailPageWrapper as CourseSectionPeriodHubDetailPage };

function CourseSectionPeriodHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useCourseSectionPeriodDetailQuery({ variables: { id } });
	const courseSectionPeriod = data?.courseSectionPeriod;
	useActivePageRegistration(
		courseSectionPeriodHubDetailPageInfo,
		!!courseSectionPeriod ? courseSectionPeriodHubDetailPageInfo.title(courseSectionPeriod) : "",
	);

	const update = CourseSectionPeriodMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<CourseSectionPeriodFormValues.Detail>,
			initialFormValues: CourseSectionPeriodFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading courseSectionPeriod</If>
				<Else>
					{!!courseSectionPeriod && (
						<React.Fragment>
							<Heading level={1}>
								{CourseSectionPeriodFormat.Fields.dayOfWeek(courseSectionPeriod.dayOfWeek)}{" "}
								{CourseSectionPeriodFormat.Fields.startTimeMinutes(courseSectionPeriod.startTimeMinutes)} to{" "}
								{CourseSectionPeriodFormat.Fields.endTimeMinutes(courseSectionPeriod.endTimeMinutes)}
							</Heading>

							<CourseSectionPeriodForm.ControlledDetail
								key={syncID}
								courseSectionPeriod={courseSectionPeriod}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
