import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { ExpenseBudgetHubCreatePage } from "./CreatePage";
import { expenseBudgetHubCreatePageInfo } from "./CreatePage/pageinfo";
import { ExpenseBudgetHubDetailPage } from "./DetailPage";
import { expenseBudgetHubDetailPageInfo } from "./DetailPage/pageinfo";
import { ExpenseBudgetHubIndexPage } from "./IndexPage";
import { expenseBudgetHubIndexPageInfo } from "./IndexPage/pageinfo";
import { expenseBudgetHubPageInfo } from "./pageinfo";

export function ExpenseBudgetHub() {
	useActivePageRegistration(expenseBudgetHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...expenseBudgetHubIndexPageInfo.routeProps}>
				<ExpenseBudgetHubIndexPage />
			</Route>
			<Route {...expenseBudgetHubCreatePageInfo.routeProps}>
				<ExpenseBudgetHubCreatePage />
			</Route>
			<Route {...expenseBudgetHubDetailPageInfo.routeProps}>
				<ExpenseBudgetHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
