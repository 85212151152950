import React from "react";
import { Button, Column, Heading, If, Modal, Row, Section, useToggle } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	ContractsTile,
	NextPaymentTile,
	NoteNoteType,
	NoteOrderField,
	OrderDirection,
	PaymentProgressTile,
	StackedScrollRow,
	Student,
	StudentHub,
	Tile,
	Timeline,
	TimelineObjectType,
	useContractListCountQuery,
	usePaginatedStudentTimelineQuery,
	useStudentNotesListQuery,
} from "@hex-insights/verita.shared";
import { NewPaymentModal } from "../../../../../Components";
import { studentHubDetailFinancialsPageInfo, StudentHubDetailFinancialsPageRouteParams } from "./pageinfo";

const financialsTimelineObjectTypes = [
	TimelineObjectType.Contract,
	TimelineObjectType.Invoice,
	TimelineObjectType.Payment,
];

export function FinancialsMain() {
	useActivePageRegistration(studentHubDetailFinancialsPageInfo);

	const { id } = useRouteParams<StudentHubDetailFinancialsPageRouteParams>();

	const { data, networkStatus, refetch, hasMore, onLoadMoreClick } = usePaginatedStudentTimelineQuery({
		studentID: id,
		filter: { objectTypes: financialsTimelineObjectTypes },
	});

	return (
		<React.Fragment>
			<FinancialNotesSection />

			<Tile style={{ flexGrow: 1, height: "100%", overflow: "hidden" }}>
				<Tile.Header>
					<Row justify="space-between">
						<Heading level={2} noMargin>
							Financials
						</Heading>

						<PaymentCreator studentID={id} />
					</Row>
				</Tile.Header>
				<Tile.Body
					className="timeline__scrolling-container__container"
					style={{ height: "100%", overflow: "hidden", padding: 0 }}
				>
					<div
						className="timeline__scrolling-container"
						style={{ height: "100%", overflow: "scroll", padding: "var(--section__body---padding)" }}
					>
						<Timeline data={data?.studentTimeline} networkStatus={networkStatus} refetch={refetch} />

						<Timeline.LoadMoreButton hasMore={hasMore} networkStatus={networkStatus} onClick={onLoadMoreClick} />
					</div>
				</Tile.Body>
			</Tile>
		</React.Fragment>
	);
}

function FinancialNotesSection() {
	const { id } = useRouteParams<StudentHubDetailFinancialsPageRouteParams>();
	const { data } = useStudentNotesListQuery({
		variables: {
			filters: { noteTypeEQ: NoteNoteType.Financial, students: [{ idEQ: id }] },
			order: { field: NoteOrderField.CreatedAt, direction: OrderDirection.Desc },
		},
	});

	return (
		<Section style={{ flexShrink: 0, width: "100%", overflow: "hidden" }}>
			<Section.Body style={{ padding: 0, width: "100%" }}>
				<Row justify="space-between" align="stretch">
					<Row justify="spaced-start" style={{ marginRight: "0.75rem", overflow: "hidden" }}>
						<StackedScrollRow justify="spaced-start">
							{data?.noteConnection.edges.map((e) => (
								<StudentHub.Detail.SimpleNoteDisplay key={e.node.id} note={e.node} />
							))}
						</StackedScrollRow>
						<StudentHub.Detail.NewSimpleNoteController studentID={id} noteType={NoteNoteType.Financial} />
					</Row>
				</Row>
			</Section.Body>
		</Section>
	);
}

type PaymentCreatorProps = {
	studentID: Student["id"];
};

function PaymentCreator({ studentID }: PaymentCreatorProps) {
	const { isOn: isOpen, toggle: toggleIsOpen, setIsOn: setIsOpen } = useToggle(false);

	const onSuccess = React.useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	return (
		<React.Fragment>
			<Button variant="primary" size="small" onClick={toggleIsOpen}>
				+ Record Payment
			</Button>

			<Modal.If condition={isOpen}>
				<NewPaymentModal studentID={studentID} onClose={toggleIsOpen} onSuccess={onSuccess} />
			</Modal.If>
		</React.Fragment>
	);
}

export function FinancialsLeftColumn() {
	const { id } = useRouteParams<StudentHubDetailFinancialsPageRouteParams>();
	const { loading, data } = useContractListCountQuery({ variables: { filters: { studentIDEQ: id } } });

	if (loading) {
		return null;
	}

	return (
		<Column justify="spaced-start" style={{ flexGrow: 1 }}>
			<If condition={(data?.contractConnection.totalCount ?? 0) > 0}>
				<NextPaymentTile studentID={id} />
				<PaymentProgressTile studentID={id} />
			</If>
			<ContractsTile studentID={id} />
		</Column>
	);
}
