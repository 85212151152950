import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { MealMenuOrderFormValues } from "../../../../Utilities";

export type MealMenuOrderFormControllerProps = {
	formState: FormState<MealMenuOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the MealMenu model.
 */
export function MealMenuOrderFormController({ formState }: MealMenuOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={MealMenuOrderFormValues.fieldOptions}
			fieldTypeMapping={MealMenuOrderFormValues.fieldTypeMapping}
		/>
	);
}
