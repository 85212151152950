import { newPageInfo } from "@hex-insights/router";
import { serviceSubscriptionHubPageInfo } from "../pageinfo";

export const serviceSubscriptionHubCreatePageInfo = newPageInfo({
	name: "New Service Subscription",
	title: "New",
	description: "Create a new Service Subscription record.",
	exact: true,
	to: serviceSubscriptionHubPageInfo.to + "/new",
	path: serviceSubscriptionHubPageInfo.path + "/new",
	parent: serviceSubscriptionHubPageInfo,
});
