import React from "react";
import { Column, Heading, Paragraph } from "@hex-insights/core";
import { StaticURLs } from "@hex-insights/verita.shared";
import { AuthenticationContext } from "../../Contexts";
import styles from "./styles.module.css";

export function MobileViewingInDevelopment() {
	const { user } = React.useContext(AuthenticationContext);

	if (!user || !(user.email.endsWith("@verita.ro") || user.email.endsWith("@veritaschool.ro"))) {
		return null;
	}

	return (
		<Column justify="center" align="center" className={styles["mobile-viewing-in-development"]}>
			<Column align="center">
				<div style={{ width: "10rem", height: "fit-content" }}>
					<img
						src={StaticURLs.Images.veritaWingsLogoGradient}
						alt="Verita School"
						style={{ width: "100%", height: "auto" }}
					/>
				</div>
				<Heading.H1>Mobile Viewing in Development</Heading.H1>
				<Paragraph>Please increase screen size to use this application.</Paragraph>
			</Column>
		</Column>
	);
}
