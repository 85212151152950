import { newPageInfo } from "@hex-insights/router";
import { busPlanHubPageInfo } from "../pageinfo";

export const busPlanHubIndexPageInfo = newPageInfo({
	name: "Bus Plans Index",
	title: "",
	description: "Index of Bus Plans.",
	exact: true,
	to: busPlanHubPageInfo.to,
	path: busPlanHubPageInfo.path,
	parent: busPlanHubPageInfo,
});
