import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { CampusFilterInput } from "../../GraphQL";
import { CampusFilterFormValues } from "../ModelFilterFormValues";

/**
 * Counts the number of active filters for the Campus model.
 *
 * @param formValues The form values of the filter form.
 */
export function countActiveFilters(formValues: CampusFilterFormValues.FormValues) {
	// TODO count quick filters
	const numAdvancedFilters = IndexForms.countActiveAdvancedFilters(
		formValues,
		CampusFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	);
	return numAdvancedFilters;
}

/**
 * Clears the values of the filter form for the Campus model.
 *
 * @param formState The form state of the filter form.
 */
export function clearFilters(formState: FormState<CampusFilterFormValues.FormValues>) {
	// TODO clear quick filters
	formState.formClearFunctions.filterSets();
}

/**
 * Resets the values of the filter form for the Campus model.
 *
 * @param formState The form state of the filter form.
 */
export function resetFilters(formState: FormState<CampusFilterFormValues.FormValues>) {
	// TODO reset quick filters
	formState.formResetFunctions.filterSets();
}

/**
 * Converts the form values of the filter form for the Campus model to the correct GraphQL
 * filter input type.
 *
 * @param formValues The form values of the filter form.
 */
export function toFilterInputs(formValues: CampusFilterFormValues.FormValues): CampusFilterInput | CampusFilterInput[] {
	return IndexForms.toFilterInput(
		formValues,
		CampusFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	) as CampusFilterInput;
}
