import { newPageInfo } from "@hex-insights/router";
import { enrollmentApplicationStudentHubPageInfo } from "../pageinfo";

export const enrollmentApplicationStudentHubIndexPageInfo = newPageInfo({
	name: "Enrollment Application Students Index",
	title: "",
	description: "Index of Enrollment Application Students.",
	exact: true,
	to: enrollmentApplicationStudentHubPageInfo.to,
	path: enrollmentApplicationStudentHubPageInfo.path,
	parent: enrollmentApplicationStudentHubPageInfo,
});
