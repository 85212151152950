import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { CourseSectionPeriodOrderFormValues } from "../../../../Utilities";

export type CourseSectionPeriodOrderFormControllerProps = {
	formState: FormState<CourseSectionPeriodOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the CourseSectionPeriod model.
 */
export function CourseSectionPeriodOrderFormController({ formState }: CourseSectionPeriodOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={CourseSectionPeriodOrderFormValues.fieldOptions}
			fieldTypeMapping={CourseSectionPeriodOrderFormValues.fieldTypeMapping}
		/>
	);
}
