import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { LearningObjectiveMarkHubCreatePage } from "./CreatePage";
import { learningObjectiveMarkHubCreatePageInfo } from "./CreatePage/pageinfo";
import { LearningObjectiveMarkHubDetailPage } from "./DetailPage";
import { learningObjectiveMarkHubDetailPageInfo } from "./DetailPage/pageinfo";
import { LearningObjectiveMarkHubIndexPage } from "./IndexPage";
import { learningObjectiveMarkHubIndexPageInfo } from "./IndexPage/pageinfo";
import { learningObjectiveMarkHubPageInfo } from "./pageinfo";

export function LearningObjectiveMarkHub() {
	useActivePageRegistration(learningObjectiveMarkHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...learningObjectiveMarkHubIndexPageInfo.routeProps}>
				<LearningObjectiveMarkHubIndexPage />
			</Route>
			<Route {...learningObjectiveMarkHubCreatePageInfo.routeProps}>
				<LearningObjectiveMarkHubCreatePage />
			</Route>
			<Route {...learningObjectiveMarkHubDetailPageInfo.routeProps}>
				<LearningObjectiveMarkHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
