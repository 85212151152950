import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	LessonPlanForm,
	LessonPlanFormValues,
	LessonPlanMutation,
	useLessonPlanDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { lessonPlanHubDetailPageInfo } from "./pageinfo";

function LessonPlanHubDetailPageWrapper() {
	// Manages identity of the LessonPlanHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <LessonPlanHubDetailPage key={id} />;
}

export { LessonPlanHubDetailPageWrapper as LessonPlanHubDetailPage };

function LessonPlanHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useLessonPlanDetailQuery({ variables: { id } });
	const lessonPlan = data?.lessonPlan;
	useActivePageRegistration(
		lessonPlanHubDetailPageInfo,
		!!lessonPlan ? lessonPlanHubDetailPageInfo.title(lessonPlan) : "",
	);

	const update = LessonPlanMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<LessonPlanFormValues.Detail>, initialFormValues: LessonPlanFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading lessonPlan</If>
				<Else>
					{!!lessonPlan && (
						<React.Fragment>
							<Heading level={1}>{lessonPlan.id}</Heading>

							<LessonPlanForm.ControlledDetail
								key={syncID}
								lessonPlan={lessonPlan}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
