import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { CourseSectionOrderFormValues } from "../../../../Utilities";

export type CourseSectionOrderFormControllerProps = {
	formState: FormState<CourseSectionOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the CourseSection model.
 */
export function CourseSectionOrderFormController({ formState }: CourseSectionOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={CourseSectionOrderFormValues.fieldOptions}
			fieldTypeMapping={CourseSectionOrderFormValues.fieldTypeMapping}
		/>
	);
}
