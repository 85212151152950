import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const mealMenuHubPageInfo = newPageInfo({
	title: "Meal Menus",
	description: "Pages related to the Meal Menu model.",
	exact: false,
	to: hubsPageInfo.to + "/meal-menus",
	path: hubsPageInfo.path + "/meal-menus",
	parent: hubsPageInfo,
});
