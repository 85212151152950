import React from "react";
import { ApplyUpdateFunction, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, If, Row } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	Form,
	FormState,
	FormSubmitFunction,
	SubmissionStatus,
} from "@hex-insights/forms";
import {
	EnrollmentApplicationDetailQuery,
	EnrollmentApplicationFormConversion,
	EnrollmentApplicationFormState,
	EnrollmentApplicationFormValues,
} from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledDetailProps = {
	enrollmentApplication: EnrollmentApplicationDetailQuery["enrollmentApplication"];
	applyUpdate: ApplyUpdateFunction<EnrollmentApplicationFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the EnrollmentApplication model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => EnrollmentApplicationFormConversion.toFormValues(props.enrollmentApplication),
		[props.enrollmentApplication],
	);
	const formState = EnrollmentApplicationFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<EnrollmentApplicationFormValues.Detail>;
};

/**
 * Renders the detail form of the EnrollmentApplication model using the given form state.
 */
export function Detail({ formState, enrollmentApplication, applyUpdate, onSuccess }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<EnrollmentApplicationFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form
			name={`enrollmentApplication.detail.${enrollmentApplication.id}`}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
		>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.CreatedAt formState={formState} />
				<Field.Status formState={formState} />
				<Field.ExternalLink formState={formState} />
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	enrollmentApplication: EnrollmentApplicationDetailQuery["enrollmentApplication"];
};

/**
 * Renders a read-only detail form of the EnrollmentApplication model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => EnrollmentApplicationFormConversion.toFormValues(props.enrollmentApplication),
		[props.enrollmentApplication],
	);
	const formState = EnrollmentApplicationFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<EnrollmentApplicationFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the EnrollmentApplication model using the given form state.
 */
export function ReadOnly({ formState }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.CreatedAt formState={formState} />
			<Field.Status formState={formState} />
		</Column>
	);
}
