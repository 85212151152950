import React from "react";
import { FieldDisplayArgs, MultiSelectField, NumberField, TextField } from "@hex-insights/forms";
import {
	ServiceFormat,
	ServiceFormValues,
	ServiceSubscriptionSelect,
	useServiceSubscriptionSelectLazyQuery,
} from "../../../../Utilities";
import { ServiceSubscriptionLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Service model.
 */
type FieldProps<K extends keyof ServiceFormValues.Base = keyof ServiceFormValues.Base> = BaseFieldProps<
	Pick<ServiceFormValues.Base, K>
>;

/**
 * Generic props for fields of the Service model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof ServiceFormValues.Detail = keyof ServiceFormValues.Detail> = BaseFieldProps<
	Pick<ServiceFormValues.Detail, K>
>;

/**
 * Renders a field component for the `name` field of the Service model.
 */
export function Name({ formState }: FieldProps<"name">) {
	return <TextField formState={formState} name="name" />;
}

/**
 * Renders a field component for the `description` field of the Service model.
 */
export function Description({ formState }: FieldProps<"description">) {
	return <TextField formState={formState} name="description" optional />;
}

/**
 * Renders a field component for the `price` field of the Service model.
 */
export function Price({ formState }: FieldProps<"price">) {
	return (
		<NumberField
			formState={formState}
			name="price"
			validationUnit={1}
			min={0}
			unit={100}
			format={ServiceFormat.Fields.price}
		/>
	);
}

export type ServiceSubscriptionsProps = DetailFieldProps<"serviceSubscriptionIDs"> & {
	currentServiceSubscriptions?: ServiceSubscriptionSelect.ModelForOption[];
};

/**
 * Renders a field component for the `serviceSubscriptions` edge of the Service model.
 */
export function ServiceSubscriptions({ formState, currentServiceSubscriptions }: ServiceSubscriptionsProps) {
	const [loadOptions, { loading, data }] = useServiceSubscriptionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.serviceSubscriptionIDs) {
			loadOptions();
		}
	}, [formState.formEditing.serviceSubscriptionIDs, loadOptions]);
	const options = React.useMemo(
		() =>
			ServiceSubscriptionSelect.toMultiOptions(data?.serviceSubscriptionConnection.edges, currentServiceSubscriptions),
		[data, currentServiceSubscriptions],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="serviceSubscriptionIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayServiceSubscriptionInstance}
		/>
	);
}

function displayServiceSubscriptionInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ServiceSubscriptionLink instance={{ id }}>{formattedValue}</ServiceSubscriptionLink>;
}
