import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	Course,
	CourseSection,
	DocumentResource,
	DocumentResourceFolder,
	DocumentResourceFolderCreateInput,
	DocumentResourceFolderCreateMutation,
	DocumentResourceFolderDeleteMutation,
	DocumentResourceFolderDetailDocument,
	DocumentResourceFolderDetailQuery,
	DocumentResourceFolderDetailQueryVariables,
	DocumentResourceFolderUpdateInput,
	DocumentResourceFolderUpdateMutation,
	DocumentResourceFolderUpdateMutationHookResult,
	graphTypeNames,
	HomeRoom,
	HomeRoomSection,
	useDocumentResourceFolderCreateMutation,
	useDocumentResourceFolderDeleteMutation,
	useDocumentResourceFolderUpdateMutation,
	User,
	UserGroup,
} from "../../GraphQL";
import { DocumentResourceFolderFormConversion } from "../ModelFormConversion";
import { DocumentResourceFolderFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the DocumentResourceFolder model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the DocumentResourceFolder model.
 */
export function useCreate() {
	const [createDocumentResourceFolder] = useDocumentResourceFolderCreateMutation();

	return React.useCallback(
		async (formValues: DocumentResourceFolderFormValues.Create) => {
			const input = DocumentResourceFolderFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createDocumentResourceFolder({ variables: { input }, update: updateCache });

			return { data: data?.createDocumentResourceFolder ?? null, errors: errors ?? null };
		},
		[createDocumentResourceFolder],
	);
}

/**
 * Returns an `update` function for the DocumentResourceFolder model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the DocumentResourceFolder model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: DocumentResourceFolder["id"]) {
	const [updateDocumentResourceFolder] = useDocumentResourceFolderUpdateMutation();

	return React.useMemo(() => getUpdate(updateDocumentResourceFolder, id), [updateDocumentResourceFolder, id]);
}

export function getUpdate(
	updateDocumentResourceFolder: DocumentResourceFolderUpdateMutationHookResult[0],
	id: DocumentResourceFolder["id"],
) {
	return async (
		changedFormValues: Partial<DocumentResourceFolderFormValues.Detail>,
		initialFormValues: Partial<DocumentResourceFolderFormValues.Detail>,
	) => {
		const input = DocumentResourceFolderFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
		const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

		const { data, errors } = await updateDocumentResourceFolder({
			variables: { id, input },
			update: updateCache,
		});

		return { data: data?.updateDocumentResourceFolder ?? null, errors: errors ?? null };
	};
}

/**
 * Returns a `del` function for the DocumentResourceFolder model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: DocumentResourceFolder["id"]) {
	const [deleteDocumentResourceFolder] = useDocumentResourceFolderDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteDocumentResourceFolder({ variables: { id }, update: updateCache });

		return { data: data?.deleteDocumentResourceFolder ?? false, errors: errors ?? null };
	}, [deleteDocumentResourceFolder, id]);
}

function getUpdateCacheForCreate(input: DocumentResourceFolderCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<DocumentResourceFolderCreateMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createDocumentResourceFolder;

		cache.writeQuery<DocumentResourceFolderDetailQuery, DocumentResourceFolderDetailQueryVariables>({
			query: DocumentResourceFolderDetailDocument,
			data: { documentResourceFolder: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.childDocumentResourceFolderIDs) {
			for (let i = 0; i < input.childDocumentResourceFolderIDs.length; i++) {
				addToParentDocumentResourceFolderOfDocumentResourceFolderCache(
					cache,
					input.childDocumentResourceFolderIDs[i],
					createdObject,
				);
			}
		}

		if (input.courseSectionIDs) {
			for (let i = 0; i < input.courseSectionIDs.length; i++) {
				addToDocumentResourceFoldersOfCourseSectionCache(cache, input.courseSectionIDs[i], createdObject);
			}
		}

		if (input.courseIDs) {
			for (let i = 0; i < input.courseIDs.length; i++) {
				addToDocumentResourceFoldersOfCourseCache(cache, input.courseIDs[i], createdObject);
			}
		}

		if (input.documentResourceIDs) {
			for (let i = 0; i < input.documentResourceIDs.length; i++) {
				addToDocumentResourceFoldersOfDocumentResourceCache(cache, input.documentResourceIDs[i], createdObject);
			}
		}

		if (input.homeRoomSectionIDs) {
			for (let i = 0; i < input.homeRoomSectionIDs.length; i++) {
				addToDocumentResourceFoldersOfHomeRoomSectionCache(cache, input.homeRoomSectionIDs[i], createdObject);
			}
		}

		if (input.homeRoomIDs) {
			for (let i = 0; i < input.homeRoomIDs.length; i++) {
				addToDocumentResourceFoldersOfHomeRoomCache(cache, input.homeRoomIDs[i], createdObject);
			}
		}

		if (input.parentDocumentResourceFolderID) {
			addToChildDocumentResourceFoldersOfDocumentResourceFolderCache(
				cache,
				input.parentDocumentResourceFolderID,
				createdObject,
			);
		}

		if (input.sharingUserIDs) {
			for (let i = 0; i < input.sharingUserIDs.length; i++) {
				addToSharedDocumentResourceFoldersOfUserCache(cache, input.sharingUserIDs[i], createdObject);
			}
		}

		if (input.sharingUserGroupIDs) {
			for (let i = 0; i < input.sharingUserGroupIDs.length; i++) {
				addToSharedDocumentResourceFoldersOfUserGroupCache(cache, input.sharingUserGroupIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "documentResourceFolderConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: DocumentResourceFolderUpdateInput,
	initialFormValues: Partial<DocumentResourceFolderFormValues.Detail>,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<DocumentResourceFolderUpdateMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateDocumentResourceFolder;

		if (input.addChildDocumentResourceFolderIDs) {
			for (let i = 0; i < input.addChildDocumentResourceFolderIDs.length; i++) {
				addToParentDocumentResourceFolderOfDocumentResourceFolderCache(
					cache,
					input.addChildDocumentResourceFolderIDs[i],
					updatedObject,
				);
			}
		}
		if (input.removeChildDocumentResourceFolderIDs) {
			for (let i = 0; i < input.removeChildDocumentResourceFolderIDs.length; i++) {
				removeFromParentDocumentResourceFolderOfDocumentResourceFolderCache(
					cache,
					input.removeChildDocumentResourceFolderIDs[i],
					updatedObject,
				);
			}
		}

		if (input.addCourseSectionIDs) {
			for (let i = 0; i < input.addCourseSectionIDs.length; i++) {
				addToDocumentResourceFoldersOfCourseSectionCache(cache, input.addCourseSectionIDs[i], updatedObject);
			}
		}
		if (input.removeCourseSectionIDs) {
			for (let i = 0; i < input.removeCourseSectionIDs.length; i++) {
				removeFromDocumentResourceFoldersOfCourseSectionCache(cache, input.removeCourseSectionIDs[i], updatedObject);
			}
		}

		if (input.addCourseIDs) {
			for (let i = 0; i < input.addCourseIDs.length; i++) {
				addToDocumentResourceFoldersOfCourseCache(cache, input.addCourseIDs[i], updatedObject);
			}
		}
		if (input.removeCourseIDs) {
			for (let i = 0; i < input.removeCourseIDs.length; i++) {
				removeFromDocumentResourceFoldersOfCourseCache(cache, input.removeCourseIDs[i], updatedObject);
			}
		}

		if (input.addDocumentResourceIDs) {
			for (let i = 0; i < input.addDocumentResourceIDs.length; i++) {
				addToDocumentResourceFoldersOfDocumentResourceCache(cache, input.addDocumentResourceIDs[i], updatedObject);
			}
		}
		if (input.removeDocumentResourceIDs) {
			for (let i = 0; i < input.removeDocumentResourceIDs.length; i++) {
				removeFromDocumentResourceFoldersOfDocumentResourceCache(
					cache,
					input.removeDocumentResourceIDs[i],
					updatedObject,
				);
			}
		}

		if (input.addHomeRoomSectionIDs) {
			for (let i = 0; i < input.addHomeRoomSectionIDs.length; i++) {
				addToDocumentResourceFoldersOfHomeRoomSectionCache(cache, input.addHomeRoomSectionIDs[i], updatedObject);
			}
		}
		if (input.removeHomeRoomSectionIDs) {
			for (let i = 0; i < input.removeHomeRoomSectionIDs.length; i++) {
				removeFromDocumentResourceFoldersOfHomeRoomSectionCache(
					cache,
					input.removeHomeRoomSectionIDs[i],
					updatedObject,
				);
			}
		}

		if (input.addHomeRoomIDs) {
			for (let i = 0; i < input.addHomeRoomIDs.length; i++) {
				addToDocumentResourceFoldersOfHomeRoomCache(cache, input.addHomeRoomIDs[i], updatedObject);
			}
		}
		if (input.removeHomeRoomIDs) {
			for (let i = 0; i < input.removeHomeRoomIDs.length; i++) {
				removeFromDocumentResourceFoldersOfHomeRoomCache(cache, input.removeHomeRoomIDs[i], updatedObject);
			}
		}

		if (
			initialFormValues.parentDocumentResourceFolderID &&
			(input.parentDocumentResourceFolderID || input.clearParentDocumentResourceFolder)
		) {
			removeFromChildDocumentResourceFoldersOfDocumentResourceFolderCache(
				cache,
				initialFormValues.parentDocumentResourceFolderID,
				updatedObject,
			);
		}
		if (input.parentDocumentResourceFolderID) {
			addToChildDocumentResourceFoldersOfDocumentResourceFolderCache(
				cache,
				input.parentDocumentResourceFolderID,
				updatedObject,
			);
		}
		if (input.parentDocumentResourceFolderID || input.clearParentDocumentResourceFolder) {
			cache.evict({ id: "ROOT_QUERY", fieldName: "documentResourceFolderConnection" }); // for top-level update
		}

		if (input.addSharingUserIDs) {
			for (let i = 0; i < input.addSharingUserIDs.length; i++) {
				addToSharedDocumentResourceFoldersOfUserCache(cache, input.addSharingUserIDs[i], updatedObject);
			}
		}
		if (input.removeSharingUserIDs) {
			for (let i = 0; i < input.removeSharingUserIDs.length; i++) {
				removeFromSharedDocumentResourceFoldersOfUserCache(cache, input.removeSharingUserIDs[i], updatedObject);
			}
		}

		if (input.addSharingUserGroupIDs) {
			for (let i = 0; i < input.addSharingUserGroupIDs.length; i++) {
				addToSharedDocumentResourceFoldersOfUserGroupCache(cache, input.addSharingUserGroupIDs[i], updatedObject);
			}
		}
		if (input.removeSharingUserGroupIDs) {
			for (let i = 0; i < input.removeSharingUserGroupIDs.length; i++) {
				removeFromSharedDocumentResourceFoldersOfUserGroupCache(
					cache,
					input.removeSharingUserGroupIDs[i],
					updatedObject,
				);
			}
		}
	};
}

function getUpdateCacheForDelete(id: DocumentResourceFolder["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<DocumentResourceFolderDeleteMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (!result.data?.deleteDocumentResourceFolder) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.DocumentResourceFolder }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "documentResourceFolderConnection" });
		cache.gc();
	};
}

function addToParentDocumentResourceFolderOfDocumentResourceFolderCache(
	cache: ApolloCache<any>,
	targetID: DocumentResourceFolder["id"],
	object: Pick<DocumentResourceFolder, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.DocumentResourceFolder }),
		fields: {
			parentDocumentResourceFolder: () => objectRef,
			parentDocumentResourceFolderID: () => object.id,
		},
	});
}

function removeFromParentDocumentResourceFolderOfDocumentResourceFolderCache(
	cache: ApolloCache<any>,
	targetID: DocumentResourceFolder["id"],
	_object: Pick<DocumentResourceFolder, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.DocumentResourceFolder }),
		fields: {
			parentDocumentResourceFolder: () => null,
			parentDocumentResourceFolderID: () => null,
		},
	});
}

function addToDocumentResourceFoldersOfCourseSectionCache(
	cache: ApolloCache<any>,
	targetID: CourseSection["id"],
	object: Pick<DocumentResourceFolder, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSection }),
		fields: {
			documentResourceFolders: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromDocumentResourceFoldersOfCourseSectionCache(
	cache: ApolloCache<any>,
	targetID: CourseSection["id"],
	object: Pick<DocumentResourceFolder, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSection }),
		fields: {
			documentResourceFolders: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToDocumentResourceFoldersOfCourseCache(
	cache: ApolloCache<any>,
	targetID: Course["id"],
	object: Pick<DocumentResourceFolder, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Course }),
		fields: {
			documentResourceFolders: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromDocumentResourceFoldersOfCourseCache(
	cache: ApolloCache<any>,
	targetID: Course["id"],
	object: Pick<DocumentResourceFolder, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Course }),
		fields: {
			documentResourceFolders: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToDocumentResourceFoldersOfDocumentResourceCache(
	cache: ApolloCache<any>,
	targetID: DocumentResource["id"],
	object: Pick<DocumentResourceFolder, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.DocumentResource }),
		fields: {
			documentResourceFolders: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromDocumentResourceFoldersOfDocumentResourceCache(
	cache: ApolloCache<any>,
	targetID: DocumentResource["id"],
	object: Pick<DocumentResourceFolder, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.DocumentResource }),
		fields: {
			documentResourceFolders: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToDocumentResourceFoldersOfHomeRoomSectionCache(
	cache: ApolloCache<any>,
	targetID: HomeRoomSection["id"],
	object: Pick<DocumentResourceFolder, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.HomeRoomSection }),
		fields: {
			documentResourceFolders: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromDocumentResourceFoldersOfHomeRoomSectionCache(
	cache: ApolloCache<any>,
	targetID: HomeRoomSection["id"],
	object: Pick<DocumentResourceFolder, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.HomeRoomSection }),
		fields: {
			documentResourceFolders: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToDocumentResourceFoldersOfHomeRoomCache(
	cache: ApolloCache<any>,
	targetID: HomeRoom["id"],
	object: Pick<DocumentResourceFolder, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.HomeRoom }),
		fields: {
			documentResourceFolders: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromDocumentResourceFoldersOfHomeRoomCache(
	cache: ApolloCache<any>,
	targetID: HomeRoom["id"],
	object: Pick<DocumentResourceFolder, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.HomeRoom }),
		fields: {
			documentResourceFolders: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToChildDocumentResourceFoldersOfDocumentResourceFolderCache(
	cache: ApolloCache<any>,
	targetID: DocumentResourceFolder["id"],
	object: Pick<DocumentResourceFolder, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.DocumentResourceFolder }),
		fields: {
			childDocumentResourceFolders: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromChildDocumentResourceFoldersOfDocumentResourceFolderCache(
	cache: ApolloCache<any>,
	targetID: DocumentResourceFolder["id"],
	object: Pick<DocumentResourceFolder, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.DocumentResourceFolder }),
		fields: {
			childDocumentResourceFolders: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToSharedDocumentResourceFoldersOfUserCache(
	cache: ApolloCache<any>,
	targetID: User["id"],
	object: Pick<DocumentResourceFolder, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.User }),
		fields: {
			sharedDocumentResourceFolders: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromSharedDocumentResourceFoldersOfUserCache(
	cache: ApolloCache<any>,
	targetID: User["id"],
	object: Pick<DocumentResourceFolder, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.User }),
		fields: {
			sharedDocumentResourceFolders: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToSharedDocumentResourceFoldersOfUserGroupCache(
	cache: ApolloCache<any>,
	targetID: UserGroup["id"],
	object: Pick<DocumentResourceFolder, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.UserGroup }),
		fields: {
			sharedDocumentResourceFolders: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromSharedDocumentResourceFoldersOfUserGroupCache(
	cache: ApolloCache<any>,
	targetID: UserGroup["id"],
	object: Pick<DocumentResourceFolder, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.UserGroup }),
		fields: {
			sharedDocumentResourceFolders: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<DocumentResourceFolder, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment DocumentResourceFolderRef on DocumentResourceFolder {
				id
			}
		`,
		data: object,
	});
}
