import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { LoginRecordFilterInput } from "../../GraphQL";
import { LoginRecordFilterFormValues } from "../ModelFilterFormValues";

/**
 * Counts the number of active filters for the LoginRecord model.
 *
 * @param formValues The form values of the filter form.
 */
export function countActiveFilters(formValues: LoginRecordFilterFormValues.FormValues) {
	// TODO count quick filters
	const numAdvancedFilters = IndexForms.countActiveAdvancedFilters(
		formValues,
		LoginRecordFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	);
	return numAdvancedFilters;
}

/**
 * Clears the values of the filter form for the LoginRecord model.
 *
 * @param formState The form state of the filter form.
 */
export function clearFilters(formState: FormState<LoginRecordFilterFormValues.FormValues>) {
	// TODO clear quick filters
	formState.formClearFunctions.filterSets();
}

/**
 * Resets the values of the filter form for the LoginRecord model.
 *
 * @param formState The form state of the filter form.
 */
export function resetFilters(formState: FormState<LoginRecordFilterFormValues.FormValues>) {
	// TODO reset quick filters
	formState.formResetFunctions.filterSets();
}

/**
 * Converts the form values of the filter form for the LoginRecord model to the correct GraphQL
 * filter input type.
 *
 * @param formValues The form values of the filter form.
 */
export function toFilterInputs(
	formValues: LoginRecordFilterFormValues.FormValues,
): LoginRecordFilterInput | LoginRecordFilterInput[] {
	return IndexForms.toFilterInput(
		formValues,
		LoginRecordFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	) as LoginRecordFilterInput;
}
