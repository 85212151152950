import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { BusPlanFilterInput, BusPlanOrder, useBusPlanPaginationQuery } from "../../../../Utilities";

export type BusPlanPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: BusPlanFilterInput | BusPlanFilterInput[] | null;
	order: BusPlanOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the BusPlan model.
 */
export function BusPlanPaginationForm({ formState, filters, order, setPaginationInfo }: BusPlanPaginationFormProps) {
	const { loading, data } = useBusPlanPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.busPlanConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
