import { newPageInfo } from "@hex-insights/router";
import { mealMenuHubPageInfo } from "../pageinfo";

export const mealMenuHubIndexPageInfo = newPageInfo({
	name: "Meal Menus Index",
	title: "",
	description: "Index of Meal Menus.",
	exact: true,
	to: mealMenuHubPageInfo.to,
	path: mealMenuHubPageInfo.path,
	parent: mealMenuHubPageInfo,
});
