import { IndexForms } from "@hex-insights/app-modules";
import { ReportCardFilterFormValues } from "../../../../Utilities";

export type ReportCardFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, ReportCardFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the ReportCard model.
 */
export function ReportCardFilterFormController(props: ReportCardFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={ReportCardFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `ReportCardFilterForm`
