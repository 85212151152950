import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { ContractFilterInput } from "../../GraphQL";
import { ContractFilterFormValues } from "../ModelFilterFormValues";

/**
 * Counts the number of active filters for the Contract model.
 *
 * @param formValues The form values of the filter form.
 */
export function countActiveFilters(formValues: ContractFilterFormValues.FormValues) {
	// TODO count quick filters
	const numAdvancedFilters = IndexForms.countActiveAdvancedFilters(
		formValues,
		ContractFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	);
	return numAdvancedFilters;
}

/**
 * Clears the values of the filter form for the Contract model.
 *
 * @param formState The form state of the filter form.
 */
export function clearFilters(formState: FormState<ContractFilterFormValues.FormValues>) {
	// TODO clear quick filters
	formState.formClearFunctions.filterSets();
}

/**
 * Resets the values of the filter form for the Contract model.
 *
 * @param formState The form state of the filter form.
 */
export function resetFilters(formState: FormState<ContractFilterFormValues.FormValues>) {
	// TODO reset quick filters
	formState.formResetFunctions.filterSets();
}

/**
 * Converts the form values of the filter form for the Contract model to the correct GraphQL
 * filter input type.
 *
 * @param formValues The form values of the filter form.
 */
export function toFilterInputs(
	formValues: ContractFilterFormValues.FormValues,
): ContractFilterInput | ContractFilterInput[] {
	return IndexForms.toFilterInput(
		formValues,
		ContractFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	) as ContractFilterInput;
}
