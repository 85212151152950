import { IndexForms } from "@hex-insights/app-modules";
import { NoteAttachmentOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the NoteAttachment model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		NoteAttachmentOrderFormValues.initial,
		NoteAttachmentOrderFormValues.fieldValues,
	);
}
