import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { RoomOrderFormValues } from "../../../../Utilities";

export type RoomOrderFormControllerProps = {
	formState: FormState<RoomOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the Room model.
 */
export function RoomOrderFormController({ formState }: RoomOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={RoomOrderFormValues.fieldOptions}
			fieldTypeMapping={RoomOrderFormValues.fieldTypeMapping}
		/>
	);
}
