import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { PermissionGroupCreateInput, PermissionGroupDetailQuery, PermissionGroupUpdateInput } from "../../GraphQL";
import { PermissionGroupFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the PermissionGroup model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: PermissionGroupFormValues.Create): PermissionGroupCreateInput {
	const input: NullPartial<PermissionGroupCreateInput> = { ...formValues };
	return input as PermissionGroupCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the PermissionGroup model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<PermissionGroupFormValues.Detail>,
	initialFormValues: PermissionGroupFormValues.Detail,
): PermissionGroupUpdateInput {
	const inputFormValues = omitKeys(formValues, ["permissionIDs", "userIDs"]);
	const input: PermissionGroupUpdateInput = { ...inputFormValues };

	if (formValues.permissionIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.permissionIDs, formValues.permissionIDs);
		input.addPermissionIDs = added;
		input.removePermissionIDs = removed;
	}

	if (formValues.userIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.userIDs, formValues.userIDs);
		input.addUserIDs = added;
		input.removeUserIDs = removed;
	}

	return input;
}

/**
 * Converts a PermissionGroup instance to its corresponding detail form values.
 *
 * @param permissionGroup The instance to convert.
 */
export function toFormValues(
	permissionGroup: PermissionGroupDetailQuery["permissionGroup"],
): PermissionGroupFormValues.Detail {
	return {
		name: permissionGroup.name,
		description: permissionGroup.description,
		permissionIDs: permissionGroup.permissions.map((e) => e.id),
		userIDs: permissionGroup.users.map((e) => e.id),
	};
}
