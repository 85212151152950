import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { AcademicYearHubCreatePage } from "./CreatePage";
import { academicYearHubCreatePageInfo } from "./CreatePage/pageinfo";
import { AcademicYearHubDetailPage } from "./DetailPage";
import { academicYearHubDetailPageInfo } from "./DetailPage/pageinfo";
import { AcademicYearHubIndexPage } from "./IndexPage";
import { academicYearHubIndexPageInfo } from "./IndexPage/pageinfo";
import { academicYearHubPageInfo } from "./pageinfo";

export function AcademicYearHub() {
	useActivePageRegistration(academicYearHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...academicYearHubIndexPageInfo.routeProps}>
				<AcademicYearHubIndexPage />
			</Route>
			<Route {...academicYearHubCreatePageInfo.routeProps}>
				<AcademicYearHubCreatePage />
			</Route>
			<Route {...academicYearHubDetailPageInfo.routeProps}>
				<AcademicYearHubDetailPage key={academicYearHubDetailPageInfo.useKey()} />
			</Route>
		</PageRouteSwitch>
	);
}
