import React from "react";
import { Heading } from "@hex-insights/core";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { AcademicYearTuitionForm } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { academicYearTuitionHubDetailPageInfo } from "../DetailPage/pageinfo";
import { academicYearTuitionHubCreatePageInfo } from "./pageinfo";

export function AcademicYearTuitionHubCreatePage() {
	useActivePageRegistration(academicYearTuitionHubCreatePageInfo);

	const { push: pushHistory } = useHistory();
	const onSuccess = React.useCallback<AcademicYearTuitionForm.ControlledCreateProps["onSuccess"]>(
		({ id }) => {
			pushHistory(academicYearTuitionHubDetailPageInfo.to(id));
		},
		[pushHistory],
	);

	return (
		<Main>
			<Heading.H1>New Academic Year Tuition</Heading.H1>

			<AcademicYearTuitionForm.ControlledCreate onSuccess={onSuccess} />
		</Main>
	);
}
