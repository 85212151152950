import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	PermissionGroupForm,
	PermissionGroupFormValues,
	PermissionGroupMutation,
	usePermissionGroupDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { permissionGroupHubDetailPageInfo } from "./pageinfo";

function PermissionGroupHubDetailPageWrapper() {
	// Manages identity of the PermissionGroupHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <PermissionGroupHubDetailPage key={id} />;
}

export { PermissionGroupHubDetailPageWrapper as PermissionGroupHubDetailPage };

function PermissionGroupHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = usePermissionGroupDetailQuery({ variables: { id } });
	const permissionGroup = data?.permissionGroup;
	useActivePageRegistration(
		permissionGroupHubDetailPageInfo,
		!!permissionGroup ? permissionGroupHubDetailPageInfo.title(permissionGroup) : "",
	);

	const update = PermissionGroupMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<PermissionGroupFormValues.Detail>,
			initialFormValues: PermissionGroupFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading permissionGroup</If>
				<Else>
					{!!permissionGroup && (
						<React.Fragment>
							<Heading level={1}>{permissionGroup.name}</Heading>

							<PermissionGroupForm.ControlledDetail
								key={syncID}
								permissionGroup={permissionGroup}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
