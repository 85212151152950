import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { UserGroupHubCreatePage } from "./CreatePage";
import { userGroupHubCreatePageInfo } from "./CreatePage/pageinfo";
import { UserGroupHubDetailPage } from "./DetailPage";
import { userGroupHubDetailPageInfo } from "./DetailPage/pageinfo";
import { UserGroupHubIndexPage } from "./IndexPage";
import { userGroupHubIndexPageInfo } from "./IndexPage/pageinfo";
import { userGroupHubPageInfo } from "./pageinfo";

export function UserGroupHub() {
	useActivePageRegistration(userGroupHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...userGroupHubIndexPageInfo.routeProps}>
				<UserGroupHubIndexPage />
			</Route>
			<Route {...userGroupHubCreatePageInfo.routeProps}>
				<UserGroupHubCreatePage />
			</Route>
			<Route {...userGroupHubDetailPageInfo.routeProps}>
				<UserGroupHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
