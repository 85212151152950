import { IndexForms } from "@hex-insights/app-modules";
import { ServiceSubscriptionOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the ServiceSubscription model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		ServiceSubscriptionOrderFormValues.initial,
		ServiceSubscriptionOrderFormValues.fieldValues,
	);
}
