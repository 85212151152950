import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { UserOrderFormValues } from "../../../../Utilities";

export type UserOrderFormControllerProps = {
	formState: FormState<UserOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the User model.
 */
export function UserOrderFormController({ formState }: UserOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={UserOrderFormValues.fieldOptions}
			fieldTypeMapping={UserOrderFormValues.fieldTypeMapping}
		/>
	);
}
