import React from "react";
import {
	DateTimeField,
	FieldDisplayArgs,
	FormType,
	MultiSelectField,
	RadioField,
	SelectField,
	TextField,
} from "@hex-insights/forms";
import {
	LoginRecordSelect,
	MFAFactorSelect,
	MFAInvalidOTPAttemptSelect,
	PermissionGroupSelect,
	PersonSelect,
	SessionSelect,
	TrustedDeviceSelect,
	useLoginRecordSelectLazyQuery,
	useMFAFactorSelectLazyQuery,
	useMFAInvalidOTPAttemptSelectLazyQuery,
	usePermissionGroupSelectLazyQuery,
	usePersonSelectLazyQuery,
	UserFormValidation,
	UserFormValues,
	UserGroupSelect,
	useSessionSelectLazyQuery,
	useTrustedDeviceSelectLazyQuery,
	useUserGroupSelectLazyQuery,
	validateEmail,
} from "../../../../Utilities";
import {
	LoginRecordLink,
	MFAFactorLink,
	MFAInvalidOTPAttemptLink,
	PermissionGroupLink,
	PersonLink,
	SessionLink,
	TrustedDeviceLink,
	UserGroupLink,
} from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the User model.
 */
type FieldProps<K extends keyof UserFormValues.Base = keyof UserFormValues.Base> = BaseFieldProps<
	Pick<UserFormValues.Base, K>
>;

/**
 * Generic props for fields of the User model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof UserFormValues.Detail = keyof UserFormValues.Detail> = BaseFieldProps<
	Pick<UserFormValues.Detail, K>
>;

/**
 * Renders a field component for the `email` field of the User model.
 */
export function Email({ id, formState }: FieldProps<"email">) {
	const validateUnique = UserFormValidation.useUniqueEmailValidator(id);

	return (
		<TextField
			formState={formState}
			name="email"
			immediateValidation={validateEmail}
			scheduledValidation={validateUnique}
		/>
	);
}

/**
 * Renders a field component for the `status` field of the User model.
 */
export function Status({ formState, formType = FormType.Update }: DetailFieldProps<"status">) {
	return (
		<RadioField
			formState={formState}
			name="status"
			options={UserFormValues.statusOptions}
			blankValue={null}
			optional={FormType.isCreate(formType)}
		/>
	);
}

/**
 * Renders a field component for the `passwordUpdatedAt` field of the User model.
 */
export function PasswordUpdatedAt({ formState, formType = FormType.Update }: DetailFieldProps<"passwordUpdatedAt">) {
	return (
		<DateTimeField
			formState={formState}
			name="passwordUpdatedAt"
			optional={FormType.isCreate(formType)}
			precision="minute"
		/>
	);
}

export type LoginRecordsProps = DetailFieldProps<"loginRecordIDs"> & {
	currentLoginRecords?: LoginRecordSelect.ModelForOption[];
};

/**
 * Renders a field component for the `loginRecords` edge of the User model.
 */
export function LoginRecords({ formState, currentLoginRecords }: LoginRecordsProps) {
	const [loadOptions, { loading, data }] = useLoginRecordSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.loginRecordIDs) {
			loadOptions();
		}
	}, [formState.formEditing.loginRecordIDs, loadOptions]);
	const options = React.useMemo(
		() => LoginRecordSelect.toMultiOptions(data?.loginRecordConnection.edges, currentLoginRecords),
		[data, currentLoginRecords],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="loginRecordIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayLoginRecordInstance}
		/>
	);
}

function displayLoginRecordInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <LoginRecordLink instance={{ id }}>{formattedValue}</LoginRecordLink>;
}

export type MFAFactorProps = DetailFieldProps<"mfaFactorID"> & {
	currentMFAFactor?: MFAFactorSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `mfaFactor` edge of the User model.
 */
export function MFAFactor({ formState, currentMFAFactor }: MFAFactorProps) {
	const [loadOptions, { loading, data }] = useMFAFactorSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.mfaFactorID) {
			loadOptions();
		}
	}, [formState.formEditing.mfaFactorID, loadOptions]);
	const options = React.useMemo(
		() => MFAFactorSelect.toOptions(data?.mfaFactorConnection.edges, currentMFAFactor),
		[data, currentMFAFactor],
	);

	return (
		<SelectField
			formState={formState}
			name="mfaFactorID"
			isLoading={loading}
			options={options}
			optional
			display={displayMFAFactor}
			blankValue={null}
		/>
	);
}

function displayMFAFactor({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <MFAFactorLink instance={{ id }}>{formattedValue}</MFAFactorLink>;
}

export type MFAInvalidOTPAttemptsProps = DetailFieldProps<"mfaInvalidOTPAttemptIDs"> & {
	currentMFAInvalidOTPAttempts?: MFAInvalidOTPAttemptSelect.ModelForOption[];
};

/**
 * Renders a field component for the `mfaInvalidOTPAttempts` edge of the User model.
 */
export function MFAInvalidOTPAttempts({ formState, currentMFAInvalidOTPAttempts }: MFAInvalidOTPAttemptsProps) {
	const [loadOptions, { loading, data }] = useMFAInvalidOTPAttemptSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.mfaInvalidOTPAttemptIDs) {
			loadOptions();
		}
	}, [formState.formEditing.mfaInvalidOTPAttemptIDs, loadOptions]);
	const options = React.useMemo(
		() =>
			MFAInvalidOTPAttemptSelect.toMultiOptions(
				data?.mfaInvalidOTPAttemptConnection.edges,
				currentMFAInvalidOTPAttempts,
			),
		[data, currentMFAInvalidOTPAttempts],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="mfaInvalidOTPAttemptIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayMFAInvalidOTPAttemptInstance}
		/>
	);
}

function displayMFAInvalidOTPAttemptInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <MFAInvalidOTPAttemptLink instance={{ id }}>{formattedValue}</MFAInvalidOTPAttemptLink>;
}

export type PermissionGroupsProps = FieldProps<"permissionGroupIDs"> & {
	currentPermissionGroups?: PermissionGroupSelect.ModelForOption[];
};

/**
 * Renders a field component for the `permissionGroups` edge of the User model.
 */
export function PermissionGroups({ formState, currentPermissionGroups }: PermissionGroupsProps) {
	const [loadOptions, { loading, data }] = usePermissionGroupSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.permissionGroupIDs) {
			loadOptions();
		}
	}, [formState.formEditing.permissionGroupIDs, loadOptions]);
	const options = React.useMemo(
		() => PermissionGroupSelect.toMultiOptions(data?.permissionGroupConnection.edges, currentPermissionGroups),
		[data, currentPermissionGroups],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="permissionGroupIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayPermissionGroupInstance}
		/>
	);
}

function displayPermissionGroupInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <PermissionGroupLink instance={{ id }}>{formattedValue}</PermissionGroupLink>;
}

export type PersonProps = FieldProps<"personID"> & {
	currentPerson?: PersonSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `person` edge of the User model.
 */
export function Person({ formState, currentPerson }: PersonProps) {
	const [loadOptions, { loading, data }] = usePersonSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.personID) {
			loadOptions();
		}
	}, [formState.formEditing.personID, loadOptions]);
	const options = React.useMemo(
		() => PersonSelect.toOptions(data?.personConnection.edges, currentPerson),
		[data, currentPerson],
	);

	return (
		<SelectField
			formState={formState}
			name="personID"
			isLoading={loading}
			options={options}
			optional
			display={displayPerson}
			blankValue={null}
		/>
	);
}

function displayPerson({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <PersonLink instance={{ id }}>{formattedValue}</PersonLink>;
}

export type SessionsProps = DetailFieldProps<"sessionIDs"> & {
	currentSessions?: SessionSelect.ModelForOption[];
};

/**
 * Renders a field component for the `sessions` edge of the User model.
 */
export function Sessions({ formState, currentSessions }: SessionsProps) {
	const [loadOptions, { loading, data }] = useSessionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.sessionIDs) {
			loadOptions();
		}
	}, [formState.formEditing.sessionIDs, loadOptions]);
	const options = React.useMemo(
		() => SessionSelect.toMultiOptions(data?.sessionConnection.edges, currentSessions),
		[data, currentSessions],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="sessionIDs"
			isLoading={loading}
			options={options}
			displayInstance={displaySessionInstance}
		/>
	);
}

function displaySessionInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <SessionLink instance={{ id }}>{formattedValue}</SessionLink>;
}

export type TrustedDevicesProps = DetailFieldProps<"trustedDeviceIDs"> & {
	currentTrustedDevices?: TrustedDeviceSelect.ModelForOption[];
};

/**
 * Renders a field component for the `trustedDevices` edge of the User model.
 */
export function TrustedDevices({ formState, currentTrustedDevices }: TrustedDevicesProps) {
	const [loadOptions, { loading, data }] = useTrustedDeviceSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.trustedDeviceIDs) {
			loadOptions();
		}
	}, [formState.formEditing.trustedDeviceIDs, loadOptions]);
	const options = React.useMemo(
		() => TrustedDeviceSelect.toMultiOptions(data?.trustedDeviceConnection.edges, currentTrustedDevices),
		[data, currentTrustedDevices],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="trustedDeviceIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayTrustedDeviceInstance}
		/>
	);
}

function displayTrustedDeviceInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <TrustedDeviceLink instance={{ id }}>{formattedValue}</TrustedDeviceLink>;
}

export type UserGroupsProps = FieldProps<"userGroupIDs"> & {
	currentUserGroups?: UserGroupSelect.ModelForOption[];
};

/**
 * Renders a field component for the `userGroups` edge of the User model.
 */
export function UserGroups({ formState, currentUserGroups }: UserGroupsProps) {
	const [loadOptions, { loading, data }] = useUserGroupSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.userGroupIDs) {
			loadOptions();
		}
	}, [formState.formEditing.userGroupIDs, loadOptions]);
	const options = React.useMemo(
		() => UserGroupSelect.toMultiOptions(data?.userGroupConnection.edges, currentUserGroups),
		[data, currentUserGroups],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="userGroupIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayUserGroupInstance}
		/>
	);
}

function displayUserGroupInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserGroupLink instance={{ id }}>{formattedValue}</UserGroupLink>;
}
