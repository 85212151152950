import { newPageInfo } from "@hex-insights/router";
import { busListPageInfo } from "../pageinfo";

export const busListIndexPageInfo = newPageInfo({
	name: "Bus List",
	title: "",
	description: "List of students on buses.",
	exact: true,
	to: busListPageInfo.to,
	path: busListPageInfo.path,
	parent: busListPageInfo,
});
