import { newPageInfo } from "@hex-insights/router";
import { Contract } from "@hex-insights/verita.shared";
import { contractHubPageInfo } from "../pageinfo";

type ContractForRouteTitle = Pick<Contract, "id">;

export const contractHubDetailPageInfo = newPageInfo({
	name: "Contract Detail",
	title: (e: ContractForRouteTitle) => e.id,
	description: "Detail page for an contract.",
	exact: false,
	isLeaf: true,
	to: (id: Contract["id"]) => contractHubPageInfo.to + `/${id}`,
	path: contractHubPageInfo.path + "/:id(\\d+)",
	parent: contractHubPageInfo,
});
