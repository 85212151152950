import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	CourseSection,
	CourseSectionPeriod,
	CourseSectionPeriodCreateInput,
	CourseSectionPeriodCreateMutation,
	CourseSectionPeriodDeleteMutation,
	CourseSectionPeriodDetailDocument,
	CourseSectionPeriodDetailQuery,
	CourseSectionPeriodDetailQueryVariables,
	CourseSectionPeriodUpdateInput,
	CourseSectionPeriodUpdateMutation,
	graphTypeNames,
	Room,
	useCourseSectionPeriodCreateMutation,
	useCourseSectionPeriodDeleteMutation,
	useCourseSectionPeriodUpdateMutation,
} from "../../GraphQL";
import { CourseSectionPeriodFormConversion } from "../ModelFormConversion";
import { CourseSectionPeriodFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the CourseSectionPeriod model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the CourseSectionPeriod model.
 */
export function useCreate() {
	const [createCourseSectionPeriod] = useCourseSectionPeriodCreateMutation();

	return React.useCallback(
		async (formValues: CourseSectionPeriodFormValues.Create) => {
			const input = CourseSectionPeriodFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createCourseSectionPeriod({ variables: { input }, update: updateCache });

			return { data: data?.createCourseSectionPeriod ?? null, errors: errors ?? null };
		},
		[createCourseSectionPeriod],
	);
}

/**
 * Returns an `update` function for the CourseSectionPeriod model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the CourseSectionPeriod model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: CourseSectionPeriod["id"]) {
	const [updateCourseSectionPeriod] = useCourseSectionPeriodUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<CourseSectionPeriodFormValues.Detail>,
			initialFormValues: CourseSectionPeriodFormValues.Detail,
		) => {
			const input = CourseSectionPeriodFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateCourseSectionPeriod({ variables: { id, input }, update: updateCache });

			return { data: data?.updateCourseSectionPeriod ?? null, errors: errors ?? null };
		},
		[updateCourseSectionPeriod, id],
	);
}

/**
 * Returns a `del` function for the CourseSectionPeriod model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: CourseSectionPeriod["id"]) {
	const [deleteCourseSectionPeriod] = useCourseSectionPeriodDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteCourseSectionPeriod({ variables: { id }, update: updateCache });

		return { data: data?.deleteCourseSectionPeriod ?? false, errors: errors ?? null };
	}, [deleteCourseSectionPeriod, id]);
}

function getUpdateCacheForCreate(input: CourseSectionPeriodCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<CourseSectionPeriodCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createCourseSectionPeriod;

		cache.writeQuery<CourseSectionPeriodDetailQuery, CourseSectionPeriodDetailQueryVariables>({
			query: CourseSectionPeriodDetailDocument,
			data: { courseSectionPeriod: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.courseSectionID) {
			addToCourseSectionPeriodsOfCourseSectionCache(cache, input.courseSectionID, createdObject);
		}

		if (input.roomID) {
			addToCourseSectionPeriodsOfRoomCache(cache, input.roomID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "courseSectionPeriodConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: CourseSectionPeriodUpdateInput,
	initialFormValues: CourseSectionPeriodFormValues.Detail,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<CourseSectionPeriodUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateCourseSectionPeriod;

		if (initialFormValues.courseSectionID && input.courseSectionID) {
			removeFromCourseSectionPeriodsOfCourseSectionCache(cache, initialFormValues.courseSectionID, updatedObject);
		}
		if (input.courseSectionID) {
			addToCourseSectionPeriodsOfCourseSectionCache(cache, input.courseSectionID, updatedObject);
		}

		if (initialFormValues.roomID && input.roomID) {
			removeFromCourseSectionPeriodsOfRoomCache(cache, initialFormValues.roomID, updatedObject);
		}
		if (input.roomID) {
			addToCourseSectionPeriodsOfRoomCache(cache, input.roomID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: CourseSectionPeriod["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<CourseSectionPeriodDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteCourseSectionPeriod) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.CourseSectionPeriod }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "courseSectionPeriodConnection" });
		cache.gc();
	};
}

function addToCourseSectionPeriodsOfCourseSectionCache(
	cache: ApolloCache<any>,
	targetID: CourseSection["id"],
	object: Pick<CourseSectionPeriod, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSection }),
		fields: {
			courseSectionPeriods: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromCourseSectionPeriodsOfCourseSectionCache(
	cache: ApolloCache<any>,
	targetID: CourseSection["id"],
	object: Pick<CourseSectionPeriod, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSection }),
		fields: {
			courseSectionPeriods: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToCourseSectionPeriodsOfRoomCache(
	cache: ApolloCache<any>,
	targetID: Room["id"],
	object: Pick<CourseSectionPeriod, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Room }),
		fields: {
			courseSectionPeriods: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromCourseSectionPeriodsOfRoomCache(
	cache: ApolloCache<any>,
	targetID: Room["id"],
	object: Pick<CourseSectionPeriod, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Room }),
		fields: {
			courseSectionPeriods: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<CourseSectionPeriod, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment CourseSectionPeriodRef on CourseSectionPeriod {
				id
			}
		`,
		data: object,
	});
}
