import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { InvoiceLineItemHubCreatePage } from "./CreatePage";
import { invoiceLineItemHubCreatePageInfo } from "./CreatePage/pageinfo";
import { InvoiceLineItemHubDetailPage } from "./DetailPage";
import { invoiceLineItemHubDetailPageInfo } from "./DetailPage/pageinfo";
import { InvoiceLineItemHubIndexPage } from "./IndexPage";
import { invoiceLineItemHubIndexPageInfo } from "./IndexPage/pageinfo";
import { invoiceLineItemHubPageInfo } from "./pageinfo";

export function InvoiceLineItemHub() {
	useActivePageRegistration(invoiceLineItemHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...invoiceLineItemHubIndexPageInfo.routeProps}>
				<InvoiceLineItemHubIndexPage />
			</Route>
			<Route {...invoiceLineItemHubCreatePageInfo.routeProps}>
				<InvoiceLineItemHubCreatePage />
			</Route>
			<Route {...invoiceLineItemHubDetailPageInfo.routeProps}>
				<InvoiceLineItemHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
