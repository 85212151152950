import { newPageInfo } from "@hex-insights/router";
import { termSetupDetailHomeRoomSectionsPageInfo } from "../pageinfo";

export const termSetupDetailHomeRoomSectionsCreatePageInfo = newPageInfo({
	title: "New Home Room Sections",
	description: "Create new Home Room Sections for a Term.",
	exact: true,
	noSearch: true,
	to: (...args: Parameters<typeof termSetupDetailHomeRoomSectionsPageInfo.to>) =>
		termSetupDetailHomeRoomSectionsPageInfo.to(...args) + "/new",
	path: termSetupDetailHomeRoomSectionsPageInfo.path + "/new",
	parent: termSetupDetailHomeRoomSectionsPageInfo,
});
