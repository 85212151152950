import { newPageInfo } from "@hex-insights/router";
import { studentHubPageInfo } from "../pageinfo";

export const studentHubIndexPageInfo = newPageInfo({
	name: "Students Index",
	title: "",
	description: "Index of Students.",
	exact: true,
	to: studentHubPageInfo.to,
	path: studentHubPageInfo.path,
	parent: studentHubPageInfo,
});
