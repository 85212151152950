import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { AcademicYearFilterInput, AcademicYearOrder, useAcademicYearPaginationQuery } from "../../../../Utilities";

export type PaginationProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: AcademicYearFilterInput | AcademicYearFilterInput[] | null;
	order: AcademicYearOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the AcademicYear model.
 */
export function Pagination({ formState, filters, order, setPaginationInfo }: PaginationProps) {
	const { loading, data } = useAcademicYearPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.academicYearConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
