import { newPageInfo } from "@hex-insights/router";
import { emailAddressHubPageInfo } from "../pageinfo";

export const emailAddressHubCreatePageInfo = newPageInfo({
	name: "New Email Address",
	title: "New",
	description: "Create a new Email Address record.",
	exact: true,
	to: emailAddressHubPageInfo.to + "/new",
	path: emailAddressHubPageInfo.path + "/new",
	parent: emailAddressHubPageInfo,
});
