import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const phoneNumberHubPageInfo = newPageInfo({
	title: "Phone Numbers",
	description: "Pages related to the Phone Number model.",
	exact: false,
	to: hubsPageInfo.to + "/phone-numbers",
	path: hubsPageInfo.path + "/phone-numbers",
	parent: hubsPageInfo,
});
