import { MFAFactor } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the createdAt field of the MFAFactor model.
 */
export function createdAt(value: MFAFactor["createdAt"] | null): string {
	return toString(value);
}

/**
 * Formats the count field of the MFAFactor model.
 */
export function count(value: MFAFactor["count"] | null): string {
	return toString(value);
}

/**
 * Formats the isApp field of the MFAFactor model.
 */
export function isApp(value: MFAFactor["isApp"]): string {
	return toString(value);
}

/**
 * Formats the email field of the MFAFactor model.
 */
export function email(value: MFAFactor["email"]): string {
	return toString(value);
}

/**
 * Formats the sms field of the MFAFactor model.
 */
export function sms(value: MFAFactor["sms"]): string {
	return toString(value);
}

/**
 * Formats the isVerified field of the MFAFactor model.
 */
export function isVerified(value: MFAFactor["isVerified"]): string {
	return toString(value);
}
