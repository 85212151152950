import { ContractInstallment } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the installmentDate field of the ContractInstallment model.
 */
export function installmentDate(value: ContractInstallment["installmentDate"] | null): string {
	return toString(value);
}

/**
 * Formats the portion field of the ContractInstallment model.
 */
export function portion(value: ContractInstallment["portion"] | null): string {
	return toString(value);
}
