import { Service, ServiceSubscription } from "../../GraphQL";

export type Base = {
	name: Service["name"];
	description: Service["description"];
	price: Service["price"] | null;
};

export type Create = Base;

export type Detail = Base & {
	serviceSubscriptionIDs: ServiceSubscription["id"][];
};

export const initialCreate: Create = {
	name: "",
	description: "",
	price: null,
};
