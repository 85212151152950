import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { PersonOrderFormValues } from "../../../../Utilities";

export type PersonOrderFormControllerProps = {
	formState: FormState<PersonOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the Person model.
 */
export function PersonOrderFormController({ formState }: PersonOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={PersonOrderFormValues.fieldOptions}
			fieldTypeMapping={PersonOrderFormValues.fieldTypeMapping}
		/>
	);
}
