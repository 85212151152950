import { IndexForms } from "@hex-insights/app-modules";
import { NoteCommentOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the NoteComment model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		NoteCommentOrderFormValues.initial,
		NoteCommentOrderFormValues.fieldValues,
	);
}
