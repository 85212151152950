import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { HomeRoomHubCreatePage } from "./CreatePage";
import { homeRoomHubCreatePageInfo } from "./CreatePage/pageinfo";
import { HomeRoomHubDetailPage } from "./DetailPage";
import { homeRoomHubDetailPageInfo } from "./DetailPage/pageinfo";
import { HomeRoomHubIndexPage } from "./IndexPage";
import { homeRoomHubIndexPageInfo } from "./IndexPage/pageinfo";
import { homeRoomHubPageInfo } from "./pageinfo";

export function HomeRoomHub() {
	useActivePageRegistration(homeRoomHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...homeRoomHubIndexPageInfo.routeProps}>
				<HomeRoomHubIndexPage />
			</Route>
			<Route {...homeRoomHubCreatePageInfo.routeProps}>
				<HomeRoomHubCreatePage />
			</Route>
			<Route {...homeRoomHubDetailPageInfo.routeProps}>
				<HomeRoomHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
