import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const expenseBudgetHubPageInfo = newPageInfo({
	title: "Expense Budgets",
	description: "Pages related to the Expense Budget model.",
	exact: false,
	to: hubsPageInfo.to + "/expense-budgets",
	path: hubsPageInfo.path + "/expense-budgets",
	parent: hubsPageInfo,
});
