import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	graphTypeNames,
	Permission,
	PermissionGroup,
	PermissionUpdateInput,
	PermissionUpdateMutation,
	usePermissionUpdateMutation,
} from "../../GraphQL";
import { PermissionFormConversion } from "../ModelFormConversion";
import { PermissionFormValues } from "../ModelFormValues";

/**
 * Returns an `update` function for the Permission model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Permission model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Permission["id"]) {
	const [updatePermission] = usePermissionUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<PermissionFormValues.Detail>, initialFormValues: PermissionFormValues.Detail) => {
			const input = PermissionFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updatePermission({ variables: { id, input }, update: updateCache });

			return { data: data?.updatePermission ?? null, errors: errors ?? null };
		},
		[updatePermission, id],
	);
}

function getUpdateCacheForUpdate(input: PermissionUpdateInput, _initialFormValues: PermissionFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<PermissionUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updatePermission;

		if (input.addPermissionGroupIDs) {
			for (let i = 0; i < input.addPermissionGroupIDs.length; i++) {
				addToPermissionsOfPermissionGroupCache(cache, input.addPermissionGroupIDs[i], updatedObject);
			}
		}
		if (input.removePermissionGroupIDs) {
			for (let i = 0; i < input.removePermissionGroupIDs.length; i++) {
				removeFromPermissionsOfPermissionGroupCache(cache, input.removePermissionGroupIDs[i], updatedObject);
			}
		}
	};
}

function addToPermissionsOfPermissionGroupCache(
	cache: ApolloCache<any>,
	targetID: PermissionGroup["id"],
	object: Pick<Permission, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.PermissionGroup }),
		fields: {
			permissions: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromPermissionsOfPermissionGroupCache(
	cache: ApolloCache<any>,
	targetID: PermissionGroup["id"],
	object: Pick<Permission, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.PermissionGroup }),
		fields: {
			permissions: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Permission, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment PermissionRef on Permission {
				id
			}
		`,
		data: object,
	});
}
