import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { PaymentLineItemOrderFormValues } from "../../../../Utilities";

export type PaymentLineItemOrderFormControllerProps = {
	formState: FormState<PaymentLineItemOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the PaymentLineItem model.
 */
export function PaymentLineItemOrderFormController({ formState }: PaymentLineItemOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={PaymentLineItemOrderFormValues.fieldOptions}
			fieldTypeMapping={PaymentLineItemOrderFormValues.fieldTypeMapping}
		/>
	);
}
