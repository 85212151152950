import { newPageInfo } from "@hex-insights/router";
import { phoneNumberHubPageInfo } from "../pageinfo";

export const phoneNumberHubCreatePageInfo = newPageInfo({
	name: "New Phone Number",
	title: "New",
	description: "Create a new Phone Number record.",
	exact: true,
	to: phoneNumberHubPageInfo.to + "/new",
	path: phoneNumberHubPageInfo.path + "/new",
	parent: phoneNumberHubPageInfo,
});
