import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { ClassYearFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the ClassYear model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => ClassYearFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(initialFormValues, ClassYearFilterFormValues.typeFilterObject);
}
