import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { EnrollmentInvitation } from "../../../../Utilities";

type ModelForLink = Pick<EnrollmentInvitation, "id">;

export type EnrollmentInvitationLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given EnrollmentInvitation instance.
 */
export function EnrollmentInvitationLink(props: EnrollmentInvitationLinkProps) {
	return <Models.Link pageInfoKey="EnrollmentInvitation" format={format} {...props} />;
}

function format(instance: ModelForLink) {
	return instance.id;
}
