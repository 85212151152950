import { newPageInfo } from "@hex-insights/router";
import { enrollmentInvitationHubPageInfo } from "../pageinfo";

export const enrollmentInvitationHubIndexPageInfo = newPageInfo({
	name: "Enrollment Invitations Index",
	title: "",
	description: "Index of Enrollment Invitations.",
	exact: true,
	to: enrollmentInvitationHubPageInfo.to,
	path: enrollmentInvitationHubPageInfo.path,
	parent: enrollmentInvitationHubPageInfo,
});
