import { ReportCard } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the issueDate field of the ReportCard model.
 */
export function issueDate(value: ReportCard["issueDate"] | null): string {
	return toString(value);
}
