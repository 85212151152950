import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { NoteCommentHubCreatePage } from "./CreatePage";
import { noteCommentHubCreatePageInfo } from "./CreatePage/pageinfo";
import { NoteCommentHubDetailPage } from "./DetailPage";
import { noteCommentHubDetailPageInfo } from "./DetailPage/pageinfo";
import { NoteCommentHubIndexPage } from "./IndexPage";
import { noteCommentHubIndexPageInfo } from "./IndexPage/pageinfo";
import { noteCommentHubPageInfo } from "./pageinfo";

export function NoteCommentHub() {
	useActivePageRegistration(noteCommentHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...noteCommentHubIndexPageInfo.routeProps}>
				<NoteCommentHubIndexPage />
			</Route>
			<Route {...noteCommentHubCreatePageInfo.routeProps}>
				<NoteCommentHubCreatePage />
			</Route>
			<Route {...noteCommentHubDetailPageInfo.routeProps}>
				<NoteCommentHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
