import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { DocumentResourceUserViewRecordHubCreatePage } from "./CreatePage";
import { documentResourceUserViewRecordHubCreatePageInfo } from "./CreatePage/pageinfo";
import { DocumentResourceUserViewRecordHubDetailPage } from "./DetailPage";
import { documentResourceUserViewRecordHubDetailPageInfo } from "./DetailPage/pageinfo";
import { DocumentResourceUserViewRecordHubIndexPage } from "./IndexPage";
import { documentResourceUserViewRecordHubIndexPageInfo } from "./IndexPage/pageinfo";
import { documentResourceUserViewRecordHubPageInfo } from "./pageinfo";

export function DocumentResourceUserViewRecordHub() {
	useActivePageRegistration(documentResourceUserViewRecordHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...documentResourceUserViewRecordHubIndexPageInfo.routeProps}>
				<DocumentResourceUserViewRecordHubIndexPage />
			</Route>
			<Route {...documentResourceUserViewRecordHubCreatePageInfo.routeProps}>
				<DocumentResourceUserViewRecordHubCreatePage />
			</Route>
			<Route {...documentResourceUserViewRecordHubDetailPageInfo.routeProps}>
				<DocumentResourceUserViewRecordHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
