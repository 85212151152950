import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { DocumentResourceOrderFormValues } from "../../../../Utilities";

export type DocumentResourceOrderFormControllerProps = {
	formState: FormState<DocumentResourceOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the DocumentResource model.
 */
export function DocumentResourceOrderFormController({ formState }: DocumentResourceOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={DocumentResourceOrderFormValues.fieldOptions}
			fieldTypeMapping={DocumentResourceOrderFormValues.fieldTypeMapping}
		/>
	);
}
