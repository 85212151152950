import { IndexForms } from "@hex-insights/app-modules";
import { UserFilterFormValues } from "../../../../Utilities";

export type UserSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the User model.
 */
export function UserSearchForm(props: UserSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type UserFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, UserFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the User model.
 */
export function UserFilterFormController(props: UserFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={UserFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `UserFilterForm`
