import React from "react";
import {
	BooleanField,
	DateTimeField,
	FieldDisplayArgs,
	FormType,
	MultiSelectField,
	SelectField,
	TextField,
} from "@hex-insights/forms";
import {
	SessionSelect,
	TrustedDeviceFormValues,
	UserSelect,
	useSessionSelectLazyQuery,
	useUserSelectLazyQuery,
} from "../../../../Utilities";
import { SessionLink, UserLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the TrustedDevice model.
 */
type FieldProps<K extends keyof TrustedDeviceFormValues.Base = keyof TrustedDeviceFormValues.Base> = BaseFieldProps<
	Pick<TrustedDeviceFormValues.Base, K>
>;

/**
 * Generic props for fields of the TrustedDevice model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof TrustedDeviceFormValues.Detail = keyof TrustedDeviceFormValues.Detail> =
	BaseFieldProps<Pick<TrustedDeviceFormValues.Detail, K>>;

/**
 * Renders a field component for the `createdAt` field of the TrustedDevice model.
 */
export function CreatedAt({ formState, formType = FormType.Update }: FieldProps<"createdAt">) {
	return (
		<DateTimeField formState={formState} name="createdAt" optional={FormType.isCreate(formType)} precision="minute" />
	);
}

/**
 * Renders a field component for the `name` field of the TrustedDevice model.
 */
export function Name({ formState }: FieldProps<"name">) {
	return <TextField formState={formState} name="name" optional />;
}

/**
 * Renders a field component for the `isCurrent` field of the TrustedDevice model.
 */
export function IsCurrent({ formState }: DetailFieldProps<"isCurrent">) {
	return <BooleanField formState={formState} name="isCurrent" />;
}

export type SessionsProps = DetailFieldProps<"sessionIDs"> & {
	currentSessions?: SessionSelect.ModelForOption[];
};

/**
 * Renders a field component for the `sessions` edge of the TrustedDevice model.
 */
export function Sessions({ formState, currentSessions }: SessionsProps) {
	const [loadOptions, { loading, data }] = useSessionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.sessionIDs) {
			loadOptions();
		}
	}, [formState.formEditing.sessionIDs, loadOptions]);
	const options = React.useMemo(
		() => SessionSelect.toMultiOptions(data?.sessionConnection.edges, currentSessions),
		[data, currentSessions],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="sessionIDs"
			isLoading={loading}
			options={options}
			displayInstance={displaySessionInstance}
		/>
	);
}

function displaySessionInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <SessionLink instance={{ id }}>{formattedValue}</SessionLink>;
}

export type UserProps = DetailFieldProps<"userID"> & {
	currentUser?: UserSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `user` edge of the TrustedDevice model.
 */
export function User({ formState, currentUser }: UserProps) {
	const [loadOptions, { loading, data }] = useUserSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.userID) {
			loadOptions();
		}
	}, [formState.formEditing.userID, loadOptions]);
	const options = React.useMemo(
		() => UserSelect.toOptions(data?.userConnection.edges, currentUser),
		[data, currentUser],
	);

	return (
		<SelectField
			formState={formState}
			name="userID"
			isLoading={loading}
			options={options}
			display={displayUser}
			blankValue={null}
		/>
	);
}

function displayUser({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserLink instance={{ id }}>{formattedValue}</UserLink>;
}
