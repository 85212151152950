import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	Expense,
	ExpenseCategory,
	ExpenseCreateInput,
	ExpenseCreateMutation,
	ExpenseDeleteMutation,
	ExpenseDetailDocument,
	ExpenseDetailQuery,
	ExpenseDetailQueryVariables,
	ExpenseUpdateInput,
	ExpenseUpdateMutation,
	graphTypeNames,
	useExpenseCreateMutation,
	useExpenseDeleteMutation,
	useExpenseUpdateMutation,
} from "../../GraphQL";
import { ExpenseFormConversion } from "../ModelFormConversion";
import { ExpenseFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the Expense model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Expense model.
 */
export function useCreate() {
	const [createExpense] = useExpenseCreateMutation();

	return React.useCallback(
		async (formValues: ExpenseFormValues.Create) => {
			const input = ExpenseFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createExpense({ variables: { input }, update: updateCache });

			return { data: data?.createExpense ?? null, errors: errors ?? null };
		},
		[createExpense],
	);
}

/**
 * Returns an `update` function for the Expense model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Expense model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Expense["id"]) {
	const [updateExpense] = useExpenseUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<ExpenseFormValues.Detail>, initialFormValues: ExpenseFormValues.Detail) => {
			const input = ExpenseFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateExpense({ variables: { id, input }, update: updateCache });

			return { data: data?.updateExpense ?? null, errors: errors ?? null };
		},
		[updateExpense, id],
	);
}

/**
 * Returns a `del` function for the Expense model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Expense["id"]) {
	const [deleteExpense] = useExpenseDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteExpense({ variables: { id }, update: updateCache });

		return { data: data?.deleteExpense ?? false, errors: errors ?? null };
	}, [deleteExpense, id]);
}

function getUpdateCacheForCreate(input: ExpenseCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ExpenseCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createExpense;

		cache.writeQuery<ExpenseDetailQuery, ExpenseDetailQueryVariables>({
			query: ExpenseDetailDocument,
			data: { expense: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.expenseCategoryID) {
			addToExpensesOfExpenseCategoryCache(cache, input.expenseCategoryID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "expenseConnection" });
	};
}

function getUpdateCacheForUpdate(input: ExpenseUpdateInput, initialFormValues: ExpenseFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ExpenseUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateExpense;

		if (initialFormValues.expenseCategoryID && input.expenseCategoryID) {
			removeFromExpensesOfExpenseCategoryCache(cache, initialFormValues.expenseCategoryID, updatedObject);
		}
		if (input.expenseCategoryID) {
			addToExpensesOfExpenseCategoryCache(cache, input.expenseCategoryID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: Expense["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ExpenseDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteExpense) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Expense }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "expenseConnection" });
		cache.gc();
	};
}

function addToExpensesOfExpenseCategoryCache(
	cache: ApolloCache<any>,
	targetID: ExpenseCategory["id"],
	object: Pick<Expense, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ExpenseCategory }),
		fields: {
			expenses: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromExpensesOfExpenseCategoryCache(
	cache: ApolloCache<any>,
	targetID: ExpenseCategory["id"],
	object: Pick<Expense, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ExpenseCategory }),
		fields: {
			expenses: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Expense, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment ExpenseRef on Expense {
				id
			}
		`,
		data: object,
	});
}
