import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import { EnrollmentApplicationNoteForm, useEnrollmentApplicationNoteDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { enrollmentApplicationNoteHubDetailPageInfo } from "./pageinfo";

export function EnrollmentApplicationNoteHubDetailPage() {
	const { enrollmentApplicationNoteID } = enrollmentApplicationNoteHubDetailPageInfo.useParams();

	const { loading, data, error } = useEnrollmentApplicationNoteDetailQuery({
		variables: { id: enrollmentApplicationNoteID },
	});
	const enrollmentApplicationNote = data?.enrollmentApplicationNote;
	useActivePageRegistration(
		enrollmentApplicationNoteHubDetailPageInfo,
		!!enrollmentApplicationNote ? enrollmentApplicationNoteHubDetailPageInfo.title(enrollmentApplicationNote) : "",
	);

	const { syncID, synchronize } = useSync();

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading enrollment application note</If>
				<Else>
					{!!enrollmentApplicationNote && (
						<React.Fragment>
							<Heading.H1>{enrollmentApplicationNote.id}</Heading.H1>

							<EnrollmentApplicationNoteForm.ControlledDetail
								key={syncID}
								enrollmentApplicationNote={enrollmentApplicationNote}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
