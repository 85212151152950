import { StudentDocument } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the name field of the StudentDocument model.
 */
export function name(value: StudentDocument["name"]): string {
	return toString(value);
}

/**
 * Formats the description field of the StudentDocument model.
 */
export function description(value: StudentDocument["description"]): string {
	return toString(value);
}

/**
 * Formats the fileURL field of the StudentDocument model.
 */
export function fileURL(value: StudentDocument["fileURL"]): string {
	return toString(value);
}

/**
 * Formats the isPublic field of the StudentDocument model.
 */
export function isPublic(value: StudentDocument["isPublic"]): string {
	return toString(value);
}

/**
 * Formats the createdAt field of the StudentDocument model.
 */
export function createdAt(value: StudentDocument["createdAt"] | null): string {
	return toString(value);
}
