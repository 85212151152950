import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { ExpenseCategoryHubCreatePage } from "./CreatePage";
import { expenseCategoryHubCreatePageInfo } from "./CreatePage/pageinfo";
import { ExpenseCategoryHubDetailPage } from "./DetailPage";
import { expenseCategoryHubDetailPageInfo } from "./DetailPage/pageinfo";
import { ExpenseCategoryHubIndexPage } from "./IndexPage";
import { expenseCategoryHubIndexPageInfo } from "./IndexPage/pageinfo";
import { expenseCategoryHubPageInfo } from "./pageinfo";

export function ExpenseCategoryHub() {
	useActivePageRegistration(expenseCategoryHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...expenseCategoryHubIndexPageInfo.routeProps}>
				<ExpenseCategoryHubIndexPage />
			</Route>
			<Route {...expenseCategoryHubCreatePageInfo.routeProps}>
				<ExpenseCategoryHubCreatePage />
			</Route>
			<Route {...expenseCategoryHubDetailPageInfo.routeProps}>
				<ExpenseCategoryHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
