import { newPageInfo } from "@hex-insights/router";
import { busListPageInfo } from "../pageinfo";

export const busListDropoffPageInfo = newPageInfo({
	name: "Bus List: Dropoff",
	title: "Dropoff",
	description: "List of students on buses for dropoff.",
	exact: true,
	to: busListPageInfo.to + "/dropoff",
	path: busListPageInfo.path + "/dropoff",
	parent: busListPageInfo,
});
