import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { CourseAttendanceRecordHubCreatePage } from "./CreatePage";
import { courseAttendanceRecordHubCreatePageInfo } from "./CreatePage/pageinfo";
import { CourseAttendanceRecordHubDetailPage } from "./DetailPage";
import { courseAttendanceRecordHubDetailPageInfo } from "./DetailPage/pageinfo";
import { CourseAttendanceRecordHubIndexPage } from "./IndexPage";
import { courseAttendanceRecordHubIndexPageInfo } from "./IndexPage/pageinfo";
import { courseAttendanceRecordHubPageInfo } from "./pageinfo";

export function CourseAttendanceRecordHub() {
	useActivePageRegistration(courseAttendanceRecordHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...courseAttendanceRecordHubIndexPageInfo.routeProps}>
				<CourseAttendanceRecordHubIndexPage />
			</Route>
			<Route {...courseAttendanceRecordHubCreatePageInfo.routeProps}>
				<CourseAttendanceRecordHubCreatePage />
			</Route>
			<Route {...courseAttendanceRecordHubDetailPageInfo.routeProps}>
				<CourseAttendanceRecordHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
