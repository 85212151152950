import React from "react";
import {
	CreateFunction,
	onCreateSubmit,
	onUpdateSubmit,
	UpdateFunction,
	useApplyCreate,
	useApplyUpdate,
} from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import {
	Form,
	FormEditButtonPair,
	FormState,
	FormSubmitFunction,
	SubmitButton,
	useOnSuccess,
} from "@hex-insights/forms";
import {
	EnrollmentInvitationCreateMutation,
	EnrollmentInvitationDetailQuery,
	EnrollmentInvitationFormConversion,
	EnrollmentInvitationFormState,
	EnrollmentInvitationFormValues,
	EnrollmentInvitationMutation,
	EnrollmentInvitationUpdateMutation,
} from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledCreateProps = Pick<
	CreateProps<EnrollmentInvitationCreateMutation["createEnrollmentInvitation"]>,
	"onSuccess" | "formNameSuffix"
> & {
	formStateArgs?: Partial<EnrollmentInvitationFormState.UseCreateFormStateArgs>;
};

/**
 * Renders the create form of the EnrollmentInvitation model using an internally managed form state.
 */
export function ControlledCreate({ formStateArgs, ...props }: ControlledCreateProps) {
	const formState = EnrollmentInvitationFormState.useCreateFormState(formStateArgs);
	const create = EnrollmentInvitationMutation.useCreate();

	return <Create formState={formState} create={create} {...props} />;
}

export type CreateProps<T> = {
	formState: FormState<EnrollmentInvitationFormValues.Create>;
	create: CreateFunction<EnrollmentInvitationFormValues.Create, T>;
	onSuccess: (data: T) => void;
	formNameSuffix?: string;
};

/**
 * Renders the create form of the EnrollmentInvitation model using the given form state.
 */
export function Create<T>({ formState, create, onSuccess, formNameSuffix }: CreateProps<T>) {
	const { applyCreate, resultRef } = useApplyCreate(create);
	const onSubmit = React.useCallback<FormSubmitFunction<EnrollmentInvitationFormValues.Create>>(
		async ({ formValues }) => onCreateSubmit(formValues, applyCreate),
		[applyCreate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	const formName = "enrollmentInvitation.create" + (formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form name={formName} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Form.Column>
				<CreateFields formState={formState} />

				<Form.ActionBar>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Form.ActionBar>
			</Form.Column>
		</Form>
	);
}

type CreateFieldsProps = {
	formState: FormState<EnrollmentInvitationFormValues.Create>;
};

function CreateFields({ formState }: CreateFieldsProps) {
	return (
		<React.Fragment>
			<Field.Response formState={formState} />
			<Field.Student formState={formState} />
		</React.Fragment>
	);
}

export type ControlledDetailProps = Pick<
	DetailProps<EnrollmentInvitationUpdateMutation["updateEnrollmentInvitation"]>,
	"enrollmentInvitation" | "onSuccess" | "formNameSuffix"
> & {
	formStateArgs?: Partial<DistributiveOmit<EnrollmentInvitationFormState.UseDetailFormStateArgs, "initialFormValues">>;
};

/**
 * Renders the detail form of the EnrollmentInvitation model using an internally managed form state.
 */
export function ControlledDetail({ formStateArgs, ...props }: ControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => EnrollmentInvitationFormConversion.toFormValues(props.enrollmentInvitation),
		[props.enrollmentInvitation],
	);
	const formState = EnrollmentInvitationFormState.useDetailFormState({ ...formStateArgs, initialFormValues });
	const update = EnrollmentInvitationMutation.useUpdate(props.enrollmentInvitation.id);

	return <Detail formState={formState} update={update} {...props} />;
}

export type DetailProps<T> = {
	enrollmentInvitation: EnrollmentInvitationDetailQuery["enrollmentInvitation"];
	formState: FormState<EnrollmentInvitationFormValues.Detail>;
	update: UpdateFunction<EnrollmentInvitationFormValues.Detail, T>;
	onSuccess: (data: T) => void;
	formNameSuffix?: string;
};

/**
 * Renders the detail form of the EnrollmentInvitation model using the given form state.
 */
export function Detail<T>({ formState, enrollmentInvitation, update, onSuccess, formNameSuffix }: DetailProps<T>) {
	const { applyUpdate, resultRef } = useApplyUpdate(update);
	const onSubmit = React.useCallback<FormSubmitFunction<EnrollmentInvitationFormValues.Detail>>(
		async (formState) => onUpdateSubmit(formState, applyUpdate),
		[applyUpdate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	const formName =
		`enrollmentInvitation.detail.${enrollmentInvitation.id}` + (formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form name={formName} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Form.Column>
				<FormEditButtonPair formState={formState} />

				<DetailFields formState={formState} enrollmentInvitation={enrollmentInvitation} />
			</Form.Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = Pick<ReadOnlyProps, "enrollmentInvitation"> & {
	formStateArgs?: Partial<
		DistributiveOmit<EnrollmentInvitationFormState.UseReadOnlyFormStateArgs, "initialFormValues">
	>;
};

/**
 * Renders a read-only detail form of the EnrollmentInvitation model using an internally managed form state.
 */
export function ControlledReadOnly({ formStateArgs, ...props }: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => EnrollmentInvitationFormConversion.toFormValues(props.enrollmentInvitation),
		[props.enrollmentInvitation],
	);
	const formState = EnrollmentInvitationFormState.useReadOnlyFormState({ ...formStateArgs, initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = {
	enrollmentInvitation: EnrollmentInvitationDetailQuery["enrollmentInvitation"];
	formState: FormState<EnrollmentInvitationFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the EnrollmentInvitation model using the given form state.
 */
export function ReadOnly({ formState, enrollmentInvitation }: ReadOnlyProps) {
	return (
		<Form.Column>
			<DetailFields formState={formState} enrollmentInvitation={enrollmentInvitation} />
		</Form.Column>
	);
}

type DetailFieldsProps = {
	enrollmentInvitation: EnrollmentInvitationDetailQuery["enrollmentInvitation"];
	formState: FormState<EnrollmentInvitationFormValues.Detail>;
};

function DetailFields({ formState, enrollmentInvitation }: DetailFieldsProps) {
	return (
		<React.Fragment>
			<Field.CreatedAt formState={formState} />
			<Field.Response formState={formState} />
			<Field.Student formState={formState} currentStudent={enrollmentInvitation.student} />
		</React.Fragment>
	);
}
