import React from "react";
import { Heading } from "@hex-insights/core";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { DiscountForm, DiscountFormValues, DiscountMutation } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { discountHubDetailPageInfo } from "../DetailPage/pageinfo";
import { discountHubCreatePageInfo } from "./pageinfo";

export function DiscountHubCreatePage() {
	useActivePageRegistration(discountHubCreatePageInfo);

	const create = DiscountMutation.useCreate();
	const createdIDRef = React.useRef<string | null>(null);
	const { push: pushHistory } = useHistory();

	const applyCreate = React.useCallback(
		async (formValues: DiscountFormValues.Create) => {
			const { data, errors } = await create(formValues);
			createdIDRef.current = data?.id ?? null;
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		if (createdIDRef.current !== null) {
			pushHistory(discountHubDetailPageInfo.to(createdIDRef.current));
		}
	}, [pushHistory]);

	return (
		<Main>
			<Heading level={1}>New Discount</Heading>

			<DiscountForm.ControlledCreate applyCreate={applyCreate} onSuccess={onSuccess} />
		</Main>
	);
}
