import React from "react";
import { Heading } from "@hex-insights/core";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { Main, MealMenuForm, MealMenuFormValues, useMealMenuBulkCreate } from "@hex-insights/verita.shared";
import { mealMenuHubIndexPageInfo } from "../IndexPage/pageinfo";
import { mealMenuHubBulkCreatePageInfo } from "./pageinfo";

export function MealMenuHubBulkCreatePage() {
	useActivePageRegistration(mealMenuHubBulkCreatePageInfo);

	const create = useMealMenuBulkCreate();
	const applyCreate = React.useCallback(
		async (formValues: MealMenuFormValues.BulkCreate) => {
			const { errors } = await create(formValues.mealMenus);
			return errors;
		},
		[create],
	);

	const { push: pushHistory } = useHistory();
	const onSuccess = React.useCallback(() => {
		// TODO add filtering so that only newly created are displayed;
		pushHistory(mealMenuHubIndexPageInfo.to);
	}, [pushHistory]);

	return (
		<Main>
			<Heading level={1}>Meal Menu Setup</Heading>

			<MealMenuForm.ControlledBulkCreate applyCreate={applyCreate} onSuccess={onSuccess} />
		</Main>
	);
}
