import { newPageInfo } from "@hex-insights/router";
import { phoneNumberHubPageInfo } from "../pageinfo";

export const phoneNumberHubIndexPageInfo = newPageInfo({
	name: "Phone Numbers Index",
	title: "",
	description: "Index of Phone Numbers.",
	exact: true,
	to: phoneNumberHubPageInfo.to,
	path: phoneNumberHubPageInfo.path,
	parent: phoneNumberHubPageInfo,
});
