import { newPageInfo } from "@hex-insights/router";
import { termSetupDetailPageInfo } from "../pageinfo";

export const termSetupDetailHomeRoomSectionsPageInfo = newPageInfo({
	title: "Home Room Sections",
	description: "Create, view, and modify new Home Room Sections for a Term.",
	exact: false,
	noSearch: true,
	to: (...args: Parameters<typeof termSetupDetailPageInfo.to>) =>
		termSetupDetailPageInfo.to(...args) + "/home-room-sections",
	path: termSetupDetailPageInfo.path + "/home-room-sections",
	parent: termSetupDetailPageInfo,
});
