import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { DocumentResourceFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the DocumentResource model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => DocumentResourceFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(
		initialFormValues,
		DocumentResourceFilterFormValues.typeFilterObject,
	);
}
