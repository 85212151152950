import { newPageInfo } from "@hex-insights/router";
import { ClassYear } from "@hex-insights/verita.shared";
import { classYearHubPageInfo } from "../pageinfo";

export type ClassYearHubDetailPageRouteParams = {
	id: string;
};

type ClassYearForRouteTitle = Pick<ClassYear, "name">;

export const classYearHubDetailPageInfo = newPageInfo({
	name: "Class Year Detail",
	title: (e: ClassYearForRouteTitle) => e.name,
	description: "Detail page for an classYear.",
	exact: false,
	isLeaf: true,
	to: (id: ClassYear["id"]) => classYearHubPageInfo.to + `/${id}`,
	path: classYearHubPageInfo.path + "/:id(\\d+)",
	parent: classYearHubPageInfo,
});
