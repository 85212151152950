import React from "react";
import {
	FieldDisplayArgs,
	MultiSelectField,
	NumberField,
	RadioField,
	SelectField,
	TextField,
} from "@hex-insights/forms";
import {
	ClassYearSelect,
	CourseFormValues,
	CourseSectionSelect,
	HomeRoomSelect,
	LearningObjectiveSelect,
	useClassYearSelectLazyQuery,
	useCourseSectionSelectLazyQuery,
	useHomeRoomSelectLazyQuery,
	useLearningObjectiveSelectLazyQuery,
} from "../../../../Utilities";
import { ClassYearLink, CourseSectionLink, HomeRoomLink, LearningObjectiveLink } from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Course model.
 */
type FieldProps<K extends keyof CourseFormValues.Base = keyof CourseFormValues.Base> = BaseFieldProps<
	Pick<CourseFormValues.Base, K>
>;

/**
 * Generic props for fields of the Course model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof CourseFormValues.Detail = keyof CourseFormValues.Detail> = BaseFieldProps<
	Pick<CourseFormValues.Detail, K>
>;

/**
 * Renders a field component for the `name` field of the Course model.
 */
export function Name({ formState }: FieldProps<"name">) {
	return <TextField formState={formState} name="name" />;
}

/**
 * Renders a field component for the `description` field of the Course model.
 */
export function Description({ formState }: FieldProps<"description">) {
	return <TextAreaField formState={formState} name="description" optional />;
}

/**
 * Renders a field component for the `subject` field of the Course model.
 */
export function Subject({ formState }: FieldProps<"subject">) {
	return (
		<RadioField formState={formState} name="subject" options={CourseFormValues.subjectOptions} blankValue={null} />
	);
}

/**
 * Renders a field component for the `studentCapacity` field of the Course model.
 */
export function StudentCapacity({ formState }: FieldProps<"studentCapacity">) {
	return <NumberField formState={formState} name="studentCapacity" validationUnit={1} min={0} optional />;
}

export type ClassYearsProps = FieldProps<"classYearIDs"> & {
	currentClassYears?: ClassYearSelect.ModelForOption[];
};

/**
 * Renders a field component for the `classYears` edge of the Course model.
 */
export function ClassYears({ formState, currentClassYears }: ClassYearsProps) {
	const [loadOptions, { loading, data }] = useClassYearSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.classYearIDs) {
			loadOptions();
		}
	}, [formState.formEditing.classYearIDs, loadOptions]);
	const options = React.useMemo(
		() => ClassYearSelect.toMultiOptions(data?.classYearConnection.edges, currentClassYears),
		[data, currentClassYears],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="classYearIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayClassYearInstance}
		/>
	);
}

function displayClassYearInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ClassYearLink instance={{ id }}>{formattedValue}</ClassYearLink>;
}

export type CourseSectionsProps = DetailFieldProps<"courseSectionIDs"> & {
	currentCourseSections?: CourseSectionSelect.ModelForOption[];
};

/**
 * Renders a field component for the `courseSections` edge of the Course model.
 */
export function CourseSections({ formState, currentCourseSections }: CourseSectionsProps) {
	const [loadOptions, { loading, data }] = useCourseSectionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.courseSectionIDs) {
			loadOptions();
		}
	}, [formState.formEditing.courseSectionIDs, loadOptions]);
	const options = React.useMemo(
		() => CourseSectionSelect.toMultiOptions(data?.courseSectionConnection.edges, currentCourseSections),
		[data, currentCourseSections],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="courseSectionIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayCourseSectionInstance}
		/>
	);
}

function displayCourseSectionInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <CourseSectionLink instance={{ id }}>{formattedValue}</CourseSectionLink>;
}

export type HomeRoomProps = FieldProps<"homeRoomID"> & {
	currentHomeRoom?: HomeRoomSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `homeRoom` edge of the Course model.
 */
export function HomeRoom({ formState, currentHomeRoom }: HomeRoomProps) {
	const [loadOptions, { loading, data }] = useHomeRoomSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.homeRoomID) {
			loadOptions();
		}
	}, [formState.formEditing.homeRoomID, loadOptions]);
	const options = React.useMemo(
		() => HomeRoomSelect.toOptions(data?.homeRoomConnection.edges, currentHomeRoom),
		[data, currentHomeRoom],
	);

	return (
		<SelectField
			formState={formState}
			name="homeRoomID"
			isLoading={loading}
			options={options}
			display={displayHomeRoom}
			blankValue={null}
		/>
	);
}

function displayHomeRoom({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <HomeRoomLink instance={{ id }}>{formattedValue}</HomeRoomLink>;
}

export type LearningObjectivesProps = DetailFieldProps<"learningObjectiveIDs"> & {
	currentLearningObjectives?: LearningObjectiveSelect.ModelForOption[];
};

/**
 * Renders a field component for the `learningObjectives` edge of the Course model.
 */
export function LearningObjectives({ formState, currentLearningObjectives }: LearningObjectivesProps) {
	const [loadOptions, { loading, data }] = useLearningObjectiveSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.learningObjectiveIDs) {
			loadOptions();
		}
	}, [formState.formEditing.learningObjectiveIDs, loadOptions]);
	const options = React.useMemo(
		() => LearningObjectiveSelect.toMultiOptions(data?.learningObjectiveConnection.edges, currentLearningObjectives),
		[data, currentLearningObjectives],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="learningObjectiveIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayLearningObjectiveInstance}
		/>
	);
}

function displayLearningObjectiveInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <LearningObjectiveLink instance={{ id }}>{formattedValue}</LearningObjectiveLink>;
}
