import { IndexForms } from "@hex-insights/app-modules";
import { DiscountOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the Discount model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		DiscountOrderFormValues.initial,
		DiscountOrderFormValues.fieldValues,
	);
}
