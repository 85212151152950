import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { categoricalColorSchemes, defaultPieProps } from "@hex-insights/charts";
import {
	addTimeToDate,
	Button,
	Column,
	compareObjects,
	compareStringsAsc,
	Conditional,
	dateTrunc,
	Else,
	formatDateTime,
	Heading,
	Icon,
	If,
	isNumber,
	makeObjectFromArrayMap,
	Modal,
	ModalProps,
	RequiredKeys,
	Row,
	sort,
	stringToLocalDate,
	useToggle,
} from "@hex-insights/core";
import {
	anyFieldChanged,
	Form,
	FormSubmitFunction,
	FormType,
	invalid,
	SubFormField,
	SubFormFieldValue,
	SubmissionStatus,
	SubmitButton,
	SyncValidationFunction,
	useFormState,
	useInternalField,
	valid,
} from "@hex-insights/forms";
import { InternalLink, useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { BasicTable } from "@hex-insights/tables";
import {
	ExpenseBudget,
	ExpenseBudgetField,
	ExpenseBudgetFormat,
	ExpenseBudgetFormState,
	ExpenseBudgetFormValues,
	ExpenseBudgetMutation,
	ExpenseCategoryIndexQuery,
	ExpenseCategoryOrderField,
	Main,
	onCreateBulkSubmit,
	OrderDirection,
	useExpenseBudgetIndexQuery,
	useExpenseBudgetsBulkCreate,
	useExpenseCategoryIndexQuery,
} from "@hex-insights/verita.shared";
import { BudgetPie, ChartContainer, getColor, getPieLabel } from "../../../Components";
import { budgetMonthPageInfo, BudgetMonthPageRouteParams } from "./pageinfo";
import styles from "./styles.module.css";

export function BudgetMonthPage() {
	const { month } = useRouteParams<BudgetMonthPageRouteParams>();
	useActivePageRegistration(budgetMonthPageInfo, budgetMonthPageInfo.title(month));

	const { loading, data } = usePageMonthExpenseBudget();

	const prevMonth = dateTrunc(addTimeToDate(stringToLocalDate(month + "T00:00:00Z", "day"), [-25, "days"]), "month");
	const nextMonth = dateTrunc(addTimeToDate(stringToLocalDate(month + "T00:00:00Z", "day"), [32, "days"]), "month");

	return (
		<Main>
			<Heading level={1}>Budget</Heading>
			<Row justify="spaced-start" align="center">
				<InternalLink to={budgetMonthPageInfo.to(formatDateTime(prevMonth, "YYYY-MM-DD"))}>
					&lt; {formatDateTime(prevMonth, "MMMM")}
				</InternalLink>
				<Heading level={2} noMargin style={{ marginBottom: "0.25rem" }}>
					{formatDateTime(stringToLocalDate(month, "day"), "MMMM YYYY")}
				</Heading>
				<InternalLink to={budgetMonthPageInfo.to(formatDateTime(nextMonth, "YYYY-MM-DD"))}>
					{formatDateTime(nextMonth, "MMMM")} &gt;
				</InternalLink>
			</Row>

			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={(data?.expenseBudgetConnection.edges.length ?? 0) === 0}>
					<NewBudget />
				</If>
				<Else>
					<ExistingBudget />
				</Else>
			</Conditional>
		</Main>
	);
}

function NewBudget() {
	const { loading, data } = useExpenseCategoryIndexQuery({
		variables: { order: { field: ExpenseCategoryOrderField.Name, direction: OrderDirection.Asc } },
	});

	return (
		<Conditional>
			<If condition={loading}>Loading...</If>
			<Else>{data && <NewBudgetInner data={data} />}</Else>
		</Conditional>
	);
}

type ExpenseBudgetFormValuesCreateBulk = {
	items: Omit<ExpenseBudgetFormValues.Create, "budgetMonth">[];
};

function NewBudgetInner({ data }: { data: ExpenseCategoryIndexQuery }) {
	const { month } = useRouteParams<BudgetMonthPageRouteParams>();

	const initialFormValues = React.useMemo<ExpenseBudgetFormValuesCreateBulk>(
		() => ({
			items: data.expenseCategoryConnection.edges.map((e) => ({
				expenseCategoryID: e.node.id,
				amount: 0,
			})),
		}),
		[data],
	);
	const blankItem = React.useMemo<Omit<ExpenseBudgetFormValues.Create, "budgetMonth">>(
		() => ({
			expenseCategoryID: null,
			amount: 0,
		}),
		[],
	);
	const formState = useFormState({ initialFormValues });

	const categoryIDNameMap = makeObjectFromArrayMap(data.expenseCategoryConnection.edges, (e) => [
		e.node.id,
		e.node.name,
	]);
	const pieData = formState.formValues.items.map((e) => ({
		...e,
		expenseCategoryName: categoryIDNameMap[e.expenseCategoryID ?? ""] ?? "",
	}));

	const tooltipFormatter = React.useCallback<(v: ValueType, n: NameType) => [ValueType | string, NameType]>(
		(value, name) => {
			if (!isNumber(value)) {
				return [value, name];
			}
			return [ExpenseBudgetFormat.Fields.amount(value), name];
		},
		[],
	);

	const validateUnique = React.useMemo(() => getValidateUniqueBudgetCategories(categoryIDNameMap), [categoryIDNameMap]);

	const create = useExpenseBudgetsBulkCreate();
	const applyCreate = React.useCallback(
		async (formValuesSets: ExpenseBudgetFormValues.Create[]) => {
			const { errors } = await create(formValuesSets);
			return errors;
		},
		[create],
	);

	const onSubmit = React.useCallback<FormSubmitFunction<ExpenseBudgetFormValuesCreateBulk>>(
		async ({ formValues }) => {
			const formValuesSets = formValues.items.map((e) => ({
				...e,
				budgetMonth: month + "T00:00:00Z",
			}));
			return onCreateBulkSubmit(formValuesSets, applyCreate);
		},
		[applyCreate, month],
	);

	const total = formState.formValues.items.reduce((a, e) => a + (e.amount ?? 0), 0);

	return (
		<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Row justify="spaced-start" align="center">
					<Column style={{ flexShrink: 0 }}>
						<div
							style={
								{
									"--field-set-array---width": "33rem",
									"--number-field---width": "10rem",
								} as React.CSSProperties
							}
						>
							<SubFormField
								formState={formState}
								name="items"
								label="Budget Items"
								blankItem={blankItem}
								immediateValidation={validateUnique}
								dividerElement={<div style={{ marginBottom: "0.5rem" }} />}
							>
								{({ formState, onRemoveClick }) => (
									<Row justify="spaced-start" align="flex-end">
										<ExpenseBudgetField.ExpenseCategory formState={formState} formType={FormType.Create} />
										<ExpenseBudgetField.Amount formState={formState} formType={FormType.Create} />
										<Button
											variant="tertiary"
											size="small"
											onClick={onRemoveClick}
											title="Remove item"
											disabled={formState.disabled}
											style={{ marginBottom: "0.25rem" }}
										>
											<Icon.Trash size="1rem" style={{ display: "block" }} />
										</Button>
									</Row>
								)}
								{({ onClick, disabled }) => (
									<Row justify="center" style={{ marginTop: "0.5rem" }}>
										<Button variant="tertiary" size="small" onClick={onClick} disabled={disabled}>
											Add Item
										</Button>
									</Row>
								)}
							</SubFormField>

							<Row justify="space-between" style={{ paddingRight: "4rem" }}>
								<span>
									<ExpenseBudgetField.CreateExpenseCategory />
								</span>

								<span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
									Total: {ExpenseBudgetFormat.Fields.amount(total)}
								</span>
							</Row>
						</div>
					</Column>

					<ChartContainer>
						<ResponsiveContainer width="99%" height="100%">
							<PieChart>
								<Tooltip isAnimationActive={false} formatter={tooltipFormatter} wrapperStyle={{ outline: "none" }} />

								<Pie
									{...defaultPieProps}
									data={pieData}
									nameKey="expenseCategoryName"
									dataKey="amount"
									label={getPieLabel()}
								>
									{pieData.map((_, i) => (
										<Cell key={i} fill={getColor(categoricalColorSchemes.tableau10, i)} />
									))}
								</Pie>
							</PieChart>
						</ResponsiveContainer>
					</ChartContainer>
				</Row>

				<Row justify="center">
					<SubmitButton onClick={formState.onSubmitWrapper(onSubmit)} submissionStatus={formState.submissionStatus}>
						Create Budget
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

function getValidateUniqueBudgetCategories(
	categoryIDNameMap: Record<string, string>,
): SyncValidationFunction<SubFormFieldValue<ExpenseBudgetFormValuesCreateBulk["items"][0]>> {
	return (value) => {
		const categoryIDs = new Set();
		for (let i = 0; i < value.length; i++) {
			const { expenseCategoryID } = value[i];
			if (expenseCategoryID === null) {
				continue;
			}
			if (categoryIDs.has(expenseCategoryID)) {
				return invalid(`Duplicate budget item for ${categoryIDNameMap[expenseCategoryID]}`);
			}
			categoryIDs.add(expenseCategoryID);
		}

		return valid();
	};
}

function ExistingBudget() {
	const { loading, data } = usePageMonthExpenseBudget();
	const sortedEdges = data
		? sort(
				data.expenseBudgetConnection.edges,
				compareObjects((e) => e.node.expenseCategory.name, compareStringsAsc()),
		  )
		: [];
	const totalBudget = sortedEdges.reduce((a, e) => a + e.node.amount, 0);

	return (
		<Conditional>
			<If condition={loading}>Loading...</If>
			<Else>
				<Row justify="spaced-start" align="center" style={{ height: "100%" }}>
					<Column style={{ flexShrink: 0 }}>
						<BasicTable>
							<BasicTable.Header>
								<BasicTable.Row>
									<BasicTable.Heading>Category</BasicTable.Heading>
									<BasicTable.Heading>Budget Amount</BasicTable.Heading>
									<BasicTable.Heading> </BasicTable.Heading>
								</BasicTable.Row>
							</BasicTable.Header>
							<BasicTable.Body>
								{sortedEdges.map((e) => (
									<BasicTable.Row key={e.node.id} className={styles["budget-row"]}>
										<BasicTable.Cell>{e.node.expenseCategory.name}</BasicTable.Cell>
										<BasicTable.Cell numeric>{ExpenseBudgetFormat.Fields.amount(e.node.amount)}</BasicTable.Cell>
										<BasicTable.Cell>
											<ExpenseBudgetEditor expenseBudget={e.node} />
										</BasicTable.Cell>
									</BasicTable.Row>
								))}
							</BasicTable.Body>
							<BasicTable.Footer>
								<BasicTable.Row>
									<BasicTable.Cell style={{ fontWeight: "bold" }}>Total</BasicTable.Cell>
									<BasicTable.Cell numeric style={{ fontWeight: "bold" }}>
										{ExpenseBudgetFormat.Fields.amount(totalBudget)}
									</BasicTable.Cell>
									<BasicTable.Cell />
								</BasicTable.Row>
							</BasicTable.Footer>
						</BasicTable>

						<Row justify="center">
							<ExpenseBudgetCreator />
						</Row>
					</Column>

					<ChartContainer>
						<BudgetPie data={sortedEdges} />
					</ChartContainer>
				</Row>
			</Else>
		</Conditional>
	);
}

type ExpenseBudgetEditorProps = {
	expenseBudget: Pick<ExpenseBudget, "id" | "amount"> & {
		expenseCategory: Pick<ExpenseBudget["expenseCategory"], "name">;
	};
};

function ExpenseBudgetEditor({ expenseBudget }: ExpenseBudgetEditorProps) {
	const { isOn: isOpen, toggle: toggleIsOpen } = useToggle(false);

	return (
		<React.Fragment>
			<Button
				variant="tertiary"
				size="small"
				onClick={toggleIsOpen}
				className={styles["budget-row__edit-button"]}
				style={{ border: "none", display: "block", transform: "none" }}
			>
				<Icon.Edit2 size="1rem" style={{ display: "block" }} />
			</Button>

			<Modal.If condition={isOpen}>
				<ExpenseBudgetEditModal expenseBudget={expenseBudget} onClose={toggleIsOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type ExpenseBudgetFormValuesDetailAmount = Pick<ExpenseBudgetFormValues.Detail, "amount">;

type ExpenseBudgetEditModalProps = Pick<ExpenseBudgetEditorProps, "expenseBudget"> &
	RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function ExpenseBudgetEditModal({ expenseBudget, ifRef, onClose }: ExpenseBudgetEditModalProps) {
	const initialFormValues = React.useMemo<ExpenseBudgetFormValuesDetailAmount>(
		() => ({ amount: expenseBudget.amount }),
		[expenseBudget.amount],
	);
	const formState = useFormState({ initialFormValues });

	const update = ExpenseBudgetMutation.useUpdate(expenseBudget.id);
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<ExpenseBudgetFormValuesDetailAmount>,
			initialFormValues: ExpenseBudgetFormValuesDetailAmount,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	const onSubmit = React.useCallback<FormSubmitFunction<ExpenseBudgetFormValuesDetailAmount>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onClose();
		}
	}, [formState.submissionStatus, onClose]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose={anyFieldChanged(formState)}>
			<Modal.Header>
				<Heading level={2} noMargin>
					{expenseBudget.expenseCategory.name}
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} noChangesDialog>
					<Column justify="spaced-start">
						<ExpenseBudgetField.Amount formState={formState} formType={FormType.Update} id={expenseBudget.id} />
						<Row justify="flex-end">
							<SubmitButton onClick={formState.onSubmitWrapper(onSubmit)} submissionStatus={formState.submissionStatus}>
								Save
							</SubmitButton>
						</Row>
					</Column>
				</Form>
			</Modal.Body>
		</Modal>
	);
}

function ExpenseBudgetCreator() {
	const { isOn: isOpen, toggle: toggleIsOpen } = useToggle(false);

	return (
		<React.Fragment>
			<Button variant="tertiary" size="small" onClick={toggleIsOpen}>
				Add Budget Item
			</Button>

			<Modal.If condition={isOpen}>
				<ExpenseBudgetCreateModal onClose={toggleIsOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type ExpenseBudgetCreateModalProps = RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function ExpenseBudgetCreateModal({ ifRef, onClose }: ExpenseBudgetCreateModalProps) {
	const { month } = useRouteParams<BudgetMonthPageRouteParams>();
	const initialFormValues = React.useMemo<Partial<ExpenseBudgetFormValues.Create>>(
		() => ({ budgetMonth: month + "T00:00:00Z" }),
		[month],
	);
	const formState = ExpenseBudgetFormState.useCreateFormState(initialFormValues);

	const create = ExpenseBudgetMutation.useCreate();
	const applyCreate = React.useCallback(
		async (formValues: ExpenseBudgetFormValues.Create) => {
			const { errors } = await create(formValues);
			return errors;
		},
		[create],
	);

	const onSubmit = React.useCallback<FormSubmitFunction<ExpenseBudgetFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onClose();
		}
	}, [formState.submissionStatus, onClose]);

	useInternalField(formState, "budgetMonth");

	return (
		<Modal
			ifRef={ifRef}
			onClose={onClose}
			confirmOnClose={anyFieldChanged(formState)}
			style={{ width: "25rem", height: "25rem" }}
		>
			<Modal.Header>
				<Heading level={2} noMargin>
					New Budget Item
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<Column style={{ height: "100%" }}>
					<Form
						formState={formState}
						onSubmit={formState.onSubmitWrapper(onSubmit)}
						noChangesDialog
						style={{ flexGrow: 1 }}
					>
						<Column justify="spaced-start" style={{ height: "100%" }}>
							<ExpenseBudgetField.ExpenseCategory formState={formState} allowCreate />
							<ExpenseBudgetField.Amount formState={formState} />

							<Row justify="flex-end" align="flex-end" style={{ flexGrow: 1 }}>
								<SubmitButton
									onClick={formState.onSubmitWrapper(onSubmit)}
									submissionStatus={formState.submissionStatus}
								>
									Add
								</SubmitButton>
							</Row>
						</Column>
					</Form>
				</Column>
			</Modal.Body>
		</Modal>
	);
}

function usePageMonthExpenseBudget() {
	const { month } = useRouteParams<BudgetMonthPageRouteParams>();

	return useExpenseBudgetIndexQuery({
		variables: {
			filters: { budgetMonthEQ: month + "T00:00:00Z" },
		},
	});
}
