import React from "react";
import { DateTimeField, FieldDisplayArgs, MultiSelectField, SelectField } from "@hex-insights/forms";
import {
	LearningObjectiveMarkSelect,
	ReportCardFormValues,
	StudentSelect,
	TermSelect,
	useLearningObjectiveMarkSelectLazyQuery,
	useStudentSelectLazyQuery,
	useTermSelectLazyQuery,
} from "../../../../Utilities";
import { LearningObjectiveMarkLink, StudentLink, TermLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the ReportCard model.
 */
type FieldProps<K extends keyof ReportCardFormValues.Base = keyof ReportCardFormValues.Base> = BaseFieldProps<
	Pick<ReportCardFormValues.Base, K>
>;

/**
 * Renders a field component for the `issueDate` field of the ReportCard model.
 */
export function IssueDate({ formState }: FieldProps<"issueDate">) {
	return <DateTimeField formState={formState} name="issueDate" precision="day" />;
}

export type LearningObjectiveMarksProps = FieldProps<"learningObjectiveMarkIDs"> & {
	currentLearningObjectiveMarks?: LearningObjectiveMarkSelect.ModelForOption[];
};

/**
 * Renders a field component for the `learningObjectiveMarks` edge of the ReportCard model.
 */
export function LearningObjectiveMarks({ formState, currentLearningObjectiveMarks }: LearningObjectiveMarksProps) {
	const [loadOptions, { loading, data }] = useLearningObjectiveMarkSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.learningObjectiveMarkIDs) {
			loadOptions();
		}
	}, [formState.formEditing.learningObjectiveMarkIDs, loadOptions]);
	const options = React.useMemo(
		() =>
			LearningObjectiveMarkSelect.toMultiOptions(
				data?.learningObjectiveMarkConnection.edges,
				currentLearningObjectiveMarks,
			),
		[data, currentLearningObjectiveMarks],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="learningObjectiveMarkIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayLearningObjectiveMarkInstance}
		/>
	);
}

function displayLearningObjectiveMarkInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <LearningObjectiveMarkLink instance={{ id }}>{formattedValue}</LearningObjectiveMarkLink>;
}

export type StudentProps = FieldProps<"studentID"> & {
	currentStudent?: StudentSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `student` edge of the ReportCard model.
 */
export function Student({ formState, currentStudent }: StudentProps) {
	const [loadOptions, { loading, data }] = useStudentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.studentID) {
			loadOptions();
		}
	}, [formState.formEditing.studentID, loadOptions]);
	const options = React.useMemo(
		() => StudentSelect.toOptions(data?.studentConnection.edges, currentStudent),
		[data, currentStudent],
	);

	return (
		<SelectField
			formState={formState}
			name="studentID"
			isLoading={loading}
			options={options}
			display={displayStudent}
			blankValue={null}
		/>
	);
}

function displayStudent({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <StudentLink instance={{ id }}>{formattedValue}</StudentLink>;
}

export type TermProps = FieldProps<"termID"> & {
	currentTerm?: TermSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `term` edge of the ReportCard model.
 */
export function Term({ formState, currentTerm }: TermProps) {
	const [loadOptions, { loading, data }] = useTermSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.termID) {
			loadOptions();
		}
	}, [formState.formEditing.termID, loadOptions]);
	const options = React.useMemo(
		() => TermSelect.toOptions(data?.termConnection.edges, currentTerm),
		[data, currentTerm],
	);

	return (
		<SelectField
			formState={formState}
			name="termID"
			isLoading={loading}
			options={options}
			display={displayTerm}
			blankValue={null}
		/>
	);
}

function displayTerm({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <TermLink instance={{ id }}>{formattedValue}</TermLink>;
}
