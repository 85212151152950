// Code generated by @hex-insights/frontend-generator; While this comment is present this file may be overwritten. If manual edits are made, remove this comment.
import { Conditional, Else, If } from "@hex-insights/core";
import { Redirect, useActivePageRegistration } from "@hex-insights/router";
import { useModelFromID } from "@hex-insights/verita.shared";
import { Main } from "../../../Components";
import { hubsPageInfo } from "../pageinfo";
import { hubsDetailRedirectPageInfo } from "./pageinfo";

const notFoundPath = hubsPageInfo.to + "/not-found";

export function HubsDetailRedirectPage() {
	useActivePageRegistration(hubsDetailRedirectPageInfo);

	const { id } = hubsDetailRedirectPageInfo.useParams();

	const { loading, detailPageInfo, error } = useModelFromID(id);
	const path = detailPageInfo ? detailPageInfo.to(id) : notFoundPath;

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={!!error}>There was an error loading this page.</If>
				<Else>
					<Redirect to={path} />
				</Else>
			</Conditional>
		</Main>
	);
}
