import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { CourseCreateInput, CourseDetailQuery, CourseUpdateInput } from "../../GraphQL";
import { CourseFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Course model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: CourseFormValues.Create): CourseCreateInput {
	const input: NullPartial<CourseCreateInput> = { ...formValues };
	return input as CourseCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Course model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<CourseFormValues.Detail>,
	initialFormValues: CourseFormValues.Detail,
): CourseUpdateInput {
	const inputFormValues = omitKeys(formValues, ["classYearIDs", "courseSectionIDs", "learningObjectiveIDs"]);
	const input: CourseUpdateInput = { ...inputFormValues };

	if (formValues.studentCapacity === null) {
		input.clearStudentCapacity = true;
	}

	if (formValues.classYearIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.classYearIDs, formValues.classYearIDs);
		input.addClassYearIDs = added;
		input.removeClassYearIDs = removed;
	}

	if (formValues.courseSectionIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.courseSectionIDs, formValues.courseSectionIDs);
		input.addCourseSectionIDs = added;
		input.removeCourseSectionIDs = removed;
	}

	if (formValues.learningObjectiveIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.learningObjectiveIDs, formValues.learningObjectiveIDs);
		input.addLearningObjectiveIDs = added;
		input.removeLearningObjectiveIDs = removed;
	}

	return input;
}

/**
 * Converts a Course instance to its corresponding detail form values.
 *
 * @param course The instance to convert.
 */
export function toFormValues(course: CourseDetailQuery["course"]): CourseFormValues.Detail {
	return {
		name: course.name,
		description: course.description,
		subject: course.subject,
		studentCapacity: course.studentCapacity,
		classYearIDs: course.classYears.map((e) => e.id),
		courseSectionIDs: course.courseSections.map((e) => e.id),
		homeRoomID: course.homeRoom?.id ?? null,
		learningObjectiveIDs: course.learningObjectives.map((e) => e.id),
	};
}
