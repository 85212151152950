import React from "react";
import { Conditional, Else, Heading, If } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { MFAFactorForm, useMFAFactorDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { mfaFactorHubDetailPageInfo } from "./pageinfo";

function MFAFactorHubDetailPageWrapper() {
	// Manages identity of the MFAFactorHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <MFAFactorHubDetailPage key={id} />;
}

export { MFAFactorHubDetailPageWrapper as MFAFactorHubDetailPage };

function MFAFactorHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useMFAFactorDetailQuery({ variables: { id } });
	const mfaFactor = data?.mfaFactor;
	useActivePageRegistration(mfaFactorHubDetailPageInfo, !!mfaFactor ? mfaFactorHubDetailPageInfo.title(mfaFactor) : "");

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading mfaFactor</If>
				<Else>
					{!!mfaFactor && (
						<React.Fragment>
							<Heading level={1}>{mfaFactor.email}</Heading>

							<MFAFactorForm.ControlledReadOnly mfaFactor={mfaFactor} />
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
