import { CaseStyle, convertCase, formatDateTime } from "@hex-insights/core";
import { EnrollmentInvitation, EnrollmentInvitationResponse } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the createdAt field of the EnrollmentInvitation model.
 */
export function createdAt(value: EnrollmentInvitation["createdAt"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return formatDateTime(value);
}

/**
 * Formats the response field of the EnrollmentInvitation model.
 */
export function response(value: EnrollmentInvitation["response"] | null): string {
	if (value === null) {
		return toString(value);
	}
	if (value === EnrollmentInvitationResponse["Unknown"]) {
		return "No Response";
	}
	return convertCase(value, CaseStyle.Title);
}
