import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { QuotationHubCreatePage } from "./CreatePage";
import { quotationHubCreatePageInfo } from "./CreatePage/pageinfo";
import { QuotationHubDetailPage } from "./DetailPage";
import { quotationHubDetailPageInfo } from "./DetailPage/pageinfo";
import { QuotationHubIndexPage } from "./IndexPage";
import { quotationHubIndexPageInfo } from "./IndexPage/pageinfo";
import { quotationHubPageInfo } from "./pageinfo";

export function QuotationHub() {
	useActivePageRegistration(quotationHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...quotationHubIndexPageInfo.routeProps}>
				<QuotationHubIndexPage />
			</Route>
			<Route {...quotationHubCreatePageInfo.routeProps}>
				<QuotationHubCreatePage />
			</Route>
			<Route {...quotationHubDetailPageInfo.routeProps}>
				<QuotationHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
