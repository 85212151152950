import { NullPartial } from "@hex-insights/core";
import {
	BillingSetupSubmissionCreateInput,
	BillingSetupSubmissionDetailQuery,
	BillingSetupSubmissionUpdateInput,
} from "../../GraphQL";
import { BillingSetupSubmissionFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the BillingSetupSubmission model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(
	formValues: BillingSetupSubmissionFormValues.Create,
): BillingSetupSubmissionCreateInput {
	const input: NullPartial<BillingSetupSubmissionCreateInput> = { ...formValues };
	return input as BillingSetupSubmissionCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the BillingSetupSubmission model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<BillingSetupSubmissionFormValues.Detail>,
	_initialFormValues: BillingSetupSubmissionFormValues.Detail,
): BillingSetupSubmissionUpdateInput {
	const input: BillingSetupSubmissionUpdateInput = { ...formValues };
	return input;
}

/**
 * Converts a BillingSetupSubmission instance to its corresponding detail form values.
 *
 * @param billingSetupSubmission The instance to convert.
 */
export function toFormValues(
	billingSetupSubmission: BillingSetupSubmissionDetailQuery["billingSetupSubmission"],
): BillingSetupSubmissionFormValues.Detail {
	return {
		numPayments: billingSetupSubmission.numPayments,
		isInterestedInBusPlan: billingSetupSubmission.isInterestedInBusPlan,
		isInterestedInMealPlan: billingSetupSubmission.isInterestedInMealPlan,
		authorName: billingSetupSubmission.authorName,
		authorEmail: billingSetupSubmission.authorEmail,
		authorPhone: billingSetupSubmission.authorPhone,
		academicYearID: billingSetupSubmission.academicYear?.id ?? null,
		studentID: billingSetupSubmission.student?.id ?? null,
	};
}
