import React from "react";
import { Conditional, Else, If } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import { EmployeeRole, EmployeeWrapperContext } from "@hex-insights/verita.shared";
import { AcademicDashboard, DirectorDashboard, GeneralDashboard, Main } from "../../Components";
import { homePageInfo } from "./pageinfo";

export function HomePage() {
	useActivePageRegistration(homePageInfo);

	const { isLoading, employee } = React.useContext(EmployeeWrapperContext);

	return (
		<Main>
			<Conditional>
				<If condition={isLoading}>Loading...</If>
				<If condition={employee?.role === EmployeeRole.PrincipalAdmin}>
					<DirectorDashboard />
				</If>
				{/* <If condition={employee?.role === EmployeeRole.Financial}>
					<FinancialsDashboard />
				</If>
				<If condition={employee?.role === EmployeeRole.GeneralAdmin}>
					<FrontDeskDashboard />
				</If> */}
				<If condition={employee?.role === EmployeeRole.EducationAdmin}>
					<AcademicDashboard />
				</If>
				<Else>
					<GeneralDashboard />
				</Else>
			</Conditional>
		</Main>
	);
}
