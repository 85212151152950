import { Bus, BusPlan } from "../../GraphQL";

export type Base = {
	name: Bus["name"];
	description: Bus["description"];
};

export type Create = Base;

export type Detail = Base & {
	dropoffBusPlanIDs: BusPlan["id"][];
	pickupBusPlanIDs: BusPlan["id"][];
};

export const initialCreate: Create = {
	name: "",
	description: "",
};
