import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { SessionFilterInput } from "../../GraphQL";
import { SessionFilterFormValues } from "../ModelFilterFormValues";

/**
 * Counts the number of active filters for the Session model.
 *
 * @param formValues The form values of the filter form.
 */
export function countActiveFilters(formValues: SessionFilterFormValues.FormValues) {
	// TODO count quick filters
	const numAdvancedFilters = IndexForms.countActiveAdvancedFilters(
		formValues,
		SessionFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	);
	return numAdvancedFilters;
}

/**
 * Clears the values of the filter form for the Session model.
 *
 * @param formState The form state of the filter form.
 */
export function clearFilters(formState: FormState<SessionFilterFormValues.FormValues>) {
	// TODO clear quick filters
	formState.formClearFunctions.filterSets();
}

/**
 * Resets the values of the filter form for the Session model.
 *
 * @param formState The form state of the filter form.
 */
export function resetFilters(formState: FormState<SessionFilterFormValues.FormValues>) {
	// TODO reset quick filters
	formState.formResetFunctions.filterSets();
}

/**
 * Converts the form values of the filter form for the Session model to the correct GraphQL
 * filter input type.
 *
 * @param formValues The form values of the filter form.
 */
export function toFilterInputs(
	formValues: SessionFilterFormValues.FormValues,
): SessionFilterInput | SessionFilterInput[] {
	return IndexForms.toFilterInput(
		formValues,
		SessionFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	) as SessionFilterInput;
}
