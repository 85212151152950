import { IndexForms } from "@hex-insights/app-modules";
import { ContractInstallmentOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the ContractInstallment model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		ContractInstallmentOrderFormValues.initial,
		ContractInstallmentOrderFormValues.fieldValues,
	);
}
