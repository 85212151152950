import { Note, NoteComment, User } from "../../GraphQL";

export type Base = {
	body: NoteComment["body"];
	noteID: Note["id"] | null;
};

export type Create = Base;

export type Detail = Base & {
	createdAt: NoteComment["createdAt"] | null;
	authorID: User["id"] | null;
};

export const initialCreate: Create = {
	body: "",
	noteID: null,
};
