import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { ServiceSubscriptionOrderFormValues } from "../../../../Utilities";

export type ServiceSubscriptionOrderFormControllerProps = {
	formState: FormState<ServiceSubscriptionOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the ServiceSubscription model.
 */
export function ServiceSubscriptionOrderFormController({ formState }: ServiceSubscriptionOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={ServiceSubscriptionOrderFormValues.fieldOptions}
			fieldTypeMapping={ServiceSubscriptionOrderFormValues.fieldTypeMapping}
		/>
	);
}
