import React from "react";
import { FieldDisplayArgs, MultiSelectField, SelectField, TextField } from "@hex-insights/forms";
import {
	CourseSectionSelect,
	HomeRoomSectionFormValues,
	HomeRoomSelect,
	RoomSelect,
	TermSelect,
	useCourseSectionSelectLazyQuery,
	useHomeRoomSelectLazyQuery,
	useRoomSelectLazyQuery,
	useTermSelectLazyQuery,
} from "../../../../Utilities";
import { CourseSectionLink, HomeRoomLink, RoomLink, TermLink } from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the HomeRoomSection model.
 */
type FieldProps<K extends keyof HomeRoomSectionFormValues.Base = keyof HomeRoomSectionFormValues.Base> = BaseFieldProps<
	Pick<HomeRoomSectionFormValues.Base, K>
>;

/**
 * Generic props for fields of the HomeRoomSection model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof HomeRoomSectionFormValues.Detail = keyof HomeRoomSectionFormValues.Detail> =
	BaseFieldProps<Pick<HomeRoomSectionFormValues.Detail, K>>;

/**
 * Renders a field component for the `name` field of the HomeRoomSection model.
 */
export function Name({ formState }: FieldProps<"name">) {
	return <TextField formState={formState} name="name" />;
}

/**
 * Renders a field component for the `description` field of the HomeRoomSection model.
 */
export function Description({ formState }: FieldProps<"description">) {
	return <TextAreaField formState={formState} name="description" optional />;
}

export type CourseSectionsProps = DetailFieldProps<"courseSectionIDs"> & {
	currentCourseSections?: CourseSectionSelect.ModelForOption[];
};

/**
 * Renders a field component for the `courseSections` edge of the HomeRoomSection model.
 */
export function CourseSections({ formState, currentCourseSections }: CourseSectionsProps) {
	const [loadOptions, { loading, data }] = useCourseSectionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.courseSectionIDs) {
			loadOptions();
		}
	}, [formState.formEditing.courseSectionIDs, loadOptions]);
	const options = React.useMemo(
		() => CourseSectionSelect.toMultiOptions(data?.courseSectionConnection.edges, currentCourseSections),
		[data, currentCourseSections],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="courseSectionIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayCourseSectionInstance}
		/>
	);
}

function displayCourseSectionInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <CourseSectionLink instance={{ id }}>{formattedValue}</CourseSectionLink>;
}

export type HomeRoomProps = FieldProps<"homeRoomID"> & {
	currentHomeRoom?: HomeRoomSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `homeRoom` edge of the HomeRoomSection model.
 */
export function HomeRoom({ formState, currentHomeRoom }: HomeRoomProps) {
	const [loadOptions, { loading, data }] = useHomeRoomSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.homeRoomID) {
			loadOptions();
		}
	}, [formState.formEditing.homeRoomID, loadOptions]);
	const options = React.useMemo(
		() => HomeRoomSelect.toOptions(data?.homeRoomConnection.edges, currentHomeRoom),
		[data, currentHomeRoom],
	);

	return (
		<SelectField
			formState={formState}
			name="homeRoomID"
			isLoading={loading}
			options={options}
			display={displayHomeRoom}
			blankValue={null}
		/>
	);
}

function displayHomeRoom({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <HomeRoomLink instance={{ id }}>{formattedValue}</HomeRoomLink>;
}

export type RoomProps = FieldProps<"roomID"> & {
	currentRoom?: RoomSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `room` edge of the HomeRoomSection model.
 */
export function Room({ formState, currentRoom }: RoomProps) {
	const [loadOptions, { loading, data }] = useRoomSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.roomID) {
			loadOptions();
		}
	}, [formState.formEditing.roomID, loadOptions]);
	const options = React.useMemo(
		() => RoomSelect.toOptions(data?.roomConnection.edges, currentRoom),
		[data, currentRoom],
	);

	return (
		<SelectField
			formState={formState}
			name="roomID"
			isLoading={loading}
			options={options}
			display={displayRoom}
			blankValue={null}
		/>
	);
}

function displayRoom({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <RoomLink instance={{ id }}>{formattedValue}</RoomLink>;
}

export type TermProps = FieldProps<"termID"> & {
	currentTerm?: TermSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `term` edge of the HomeRoomSection model.
 */
export function Term({ formState, currentTerm }: TermProps) {
	const [loadOptions, { loading, data }] = useTermSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.termID) {
			loadOptions();
		}
	}, [formState.formEditing.termID, loadOptions]);
	const options = React.useMemo(
		() => TermSelect.toOptions(data?.termConnection.edges, currentTerm),
		[data, currentTerm],
	);

	return (
		<SelectField
			formState={formState}
			name="termID"
			isLoading={loading}
			options={options}
			display={displayTerm}
			blankValue={null}
		/>
	);
}

function displayTerm({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <TermLink instance={{ id }}>{formattedValue}</TermLink>;
}
