import { IndexForms } from "@hex-insights/app-modules";
import { TrustedDeviceFilterFormValues } from "../../../../Utilities";

export type TrustedDeviceSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the TrustedDevice model.
 */
export function TrustedDeviceSearchForm(props: TrustedDeviceSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type TrustedDeviceFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, TrustedDeviceFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the TrustedDevice model.
 */
export function TrustedDeviceFilterFormController(props: TrustedDeviceFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={TrustedDeviceFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `TrustedDeviceFilterForm`
