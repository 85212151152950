import { dateTrunc, formatDateTime } from "@hex-insights/core";
import { Redirect, Route, useActivePageRegistration, useRouteMatch } from "@hex-insights/router";
import { PageRouteSwitch } from "../../Components";
import { BudgetMonthPage } from "./Month";
import { budgetMonthPageInfo, BudgetMonthPageRouteParams } from "./Month/pageinfo";
import { budgetPageInfo } from "./pageinfo";

export function BudgetPage() {
	useActivePageRegistration(budgetPageInfo);

	return (
		<PageRouteSwitch>
			<Route exact path={budgetPageInfo.path}>
				<Redirect to={budgetMonthPageInfo.to(formatDateTime(dateTrunc(new Date(), "month"), "YYYY-MM-DD"))} />
			</Route>
			<Route {...budgetMonthPageInfo.routeProps}>
				<BudgetMonthPage
					key={useRouteMatch<BudgetMonthPageRouteParams>(budgetMonthPageInfo.path as string)?.params.month}
				/>
			</Route>
		</PageRouteSwitch>
	);
}
