import { IndexForms } from "@hex-insights/app-modules";
import { DocumentResourceFilterFormValues } from "../../../../Utilities";

export type DocumentResourceSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the DocumentResource model.
 */
export function DocumentResourceSearchForm(props: DocumentResourceSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type DocumentResourceFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, DocumentResourceFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the DocumentResource model.
 */
export function DocumentResourceFilterFormController(props: DocumentResourceFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={DocumentResourceFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `DocumentResourceFilterForm`
