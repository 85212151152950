import React from "react";
import { Button, Column, Conditional, Else, Grid, Heading, If, List, Row, Section } from "@hex-insights/core";
import { useActivePageRegistration, useHistory, useRouteParams } from "@hex-insights/router";
import {
	HomeRoomOrderField,
	HomeRoomSectionOrderField,
	OrderDirection,
	Tile,
	useHomeRoomIndexQuery,
	useHomeRoomSectionIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { termSetupDetailHomeRoomSectionsCreatePageInfo } from "../HomeRoomSections/CreatePage/pageinfo";
import { useCurrentTerm } from "../Utilities";
import { termSetupDetailOverviewPageInfo } from "./pageinfo";
import generalStyles from "../general.module.css";

export function TermSetupDetailOverviewPage() {
	useActivePageRegistration(termSetupDetailOverviewPageInfo);

	const term = useCurrentTerm()!;

	const { push: pushHistory } = useHistory();
	const onSetupHomeRoomClick = React.useCallback(() => {
		pushHistory(termSetupDetailHomeRoomSectionsCreatePageInfo.to(term.id));
	}, [pushHistory, term.id]);

	return (
		<Main>
			<Row justify="spaced-start" className={generalStyles["main-row"]}>
				<Tile className={generalStyles["controller-tile"]}>
					<Tile.Header>
						<Heading level={1} noMargin>
							{term.name}
						</Heading>
					</Tile.Header>
					<Tile.Body>
						<HomeRoomSections />
						<HomeRoomsCatalogue />
					</Tile.Body>
				</Tile>

				<Tile className={generalStyles["preview-tile--full"]} style={{ flexGrow: 1 }}>
					<Tile.Header>
						<Heading level={2} noMargin>
							Overview
						</Heading>
					</Tile.Header>
					<Tile.Body>
						<Column style={{ minHeight: "100%" }}>
							<HomeRoomSectionsPreview />

							<Row justify="center" align="center" style={{ flexGrow: 1 }}>
								<Button variant="primary" size="large" onClick={onSetupHomeRoomClick}>
									Setup Home Room
								</Button>
							</Row>
						</Column>
					</Tile.Body>
				</Tile>
			</Row>
		</Main>
	);
}

function HomeRoomSections() {
	const { termID } = useRouteParams<{ termID: string }>();

	const { loading, data, error } = useHomeRoomSectionIndexQuery({
		variables: {
			filters: { termIDEQ: termID },
			order: { field: HomeRoomSectionOrderField.Name, direction: OrderDirection.Asc },
		},
	});
	const edges = data?.homeRoomSectionConnection?.edges;

	return (
		<Section>
			<Section.Header>
				<Heading level={3} noMargin>
					Home Rooms this Term
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<If condition={!!error || !edges}>There was a problem loading home rooms.</If>
					<Else>
						<List>
							{edges?.map((e) => (
								<List.Item key={e.node.id}>{e.node.name}</List.Item>
							))}
						</List>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}

function HomeRoomsCatalogue() {
	const { loading, data, error } = useHomeRoomIndexQuery({
		variables: { order: { field: HomeRoomOrderField.Name, direction: OrderDirection.Asc } },
	});
	const edges = data?.homeRoomConnection?.edges;

	return (
		<Section>
			<Section.Header>
				<Heading level={3} noMargin>
					Home Rooms in Catalogue
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<If condition={!!error || !edges}>There was a problem loading home rooms.</If>
					<Else>
						<List>
							{edges?.map((e) => (
								<List.Item key={e.node.id}>{e.node.name}</List.Item>
							))}
						</List>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}

function HomeRoomSectionsPreview() {
	const { termID } = useRouteParams<{ termID: string }>();

	const { loading, data, error } = useHomeRoomSectionIndexQuery({
		variables: {
			filters: { termIDEQ: termID },
			order: { field: HomeRoomSectionOrderField.Name, direction: OrderDirection.Asc },
		},
	});
	const edges = data?.homeRoomSectionConnection?.edges;

	return (
		<Section>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<If condition={!!error || !edges}>There was a problem loading home rooms.</If>
					<Else>
						<Grid columns={4} gap="1rem">
							{edges?.map((e) => (
								<div
									key={e.node.id}
									style={{
										textAlign: "center",
										padding: "0.5rem 1rem",
										border: "1px solid var(--verita-blue)",
										borderRadius: "5rem",
									}}
								>
									{e.node.name}
								</div>
							))}
						</Grid>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}
