import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	AcademicYear,
	BillingSetupSubmission,
	BillingSetupSubmissionCreateInput,
	BillingSetupSubmissionCreateMutation,
	BillingSetupSubmissionDeleteMutation,
	BillingSetupSubmissionDetailDocument,
	BillingSetupSubmissionDetailQuery,
	BillingSetupSubmissionDetailQueryVariables,
	BillingSetupSubmissionUpdateInput,
	BillingSetupSubmissionUpdateMutation,
	graphTypeNames,
	Student,
	useBillingSetupSubmissionCreateMutation,
	useBillingSetupSubmissionDeleteMutation,
	useBillingSetupSubmissionUpdateMutation,
} from "../../GraphQL";
import { BillingSetupSubmissionFormConversion } from "../ModelFormConversion";
import { BillingSetupSubmissionFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the BillingSetupSubmission model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the BillingSetupSubmission model.
 */
export function useCreate() {
	const [createBillingSetupSubmission] = useBillingSetupSubmissionCreateMutation();

	return React.useCallback(
		async (formValues: BillingSetupSubmissionFormValues.Create) => {
			const input = BillingSetupSubmissionFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createBillingSetupSubmission({ variables: { input }, update: updateCache });

			return { data: data?.createBillingSetupSubmission ?? null, errors: errors ?? null };
		},
		[createBillingSetupSubmission],
	);
}

/**
 * Returns an `update` function for the BillingSetupSubmission model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the BillingSetupSubmission model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: BillingSetupSubmission["id"]) {
	const [updateBillingSetupSubmission] = useBillingSetupSubmissionUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<BillingSetupSubmissionFormValues.Detail>,
			initialFormValues: BillingSetupSubmissionFormValues.Detail,
		) => {
			const input = BillingSetupSubmissionFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateBillingSetupSubmission({
				variables: { id, input },
				update: updateCache,
			});

			return { data: data?.updateBillingSetupSubmission ?? null, errors: errors ?? null };
		},
		[updateBillingSetupSubmission, id],
	);
}

/**
 * Returns a `del` function for the BillingSetupSubmission model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: BillingSetupSubmission["id"]) {
	const [deleteBillingSetupSubmission] = useBillingSetupSubmissionDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteBillingSetupSubmission({ variables: { id }, update: updateCache });

		return { data: data?.deleteBillingSetupSubmission ?? false, errors: errors ?? null };
	}, [deleteBillingSetupSubmission, id]);
}

function getUpdateCacheForCreate(input: BillingSetupSubmissionCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<BillingSetupSubmissionCreateMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createBillingSetupSubmission;

		cache.writeQuery<BillingSetupSubmissionDetailQuery, BillingSetupSubmissionDetailQueryVariables>({
			query: BillingSetupSubmissionDetailDocument,
			data: { billingSetupSubmission: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.academicYearID) {
			addToBillingSetupSubmissionsOfAcademicYearCache(cache, input.academicYearID, createdObject);
		}

		if (input.studentID) {
			addToBillingSetupSubmissionsOfStudentCache(cache, input.studentID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "billingSetupSubmissionConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: BillingSetupSubmissionUpdateInput,
	initialFormValues: BillingSetupSubmissionFormValues.Detail,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<BillingSetupSubmissionUpdateMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateBillingSetupSubmission;

		if (initialFormValues.academicYearID && input.academicYearID) {
			removeFromBillingSetupSubmissionsOfAcademicYearCache(cache, initialFormValues.academicYearID, updatedObject);
		}
		if (input.academicYearID) {
			addToBillingSetupSubmissionsOfAcademicYearCache(cache, input.academicYearID, updatedObject);
		}

		if (initialFormValues.studentID && input.studentID) {
			removeFromBillingSetupSubmissionsOfStudentCache(cache, initialFormValues.studentID, updatedObject);
		}
		if (input.studentID) {
			addToBillingSetupSubmissionsOfStudentCache(cache, input.studentID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: BillingSetupSubmission["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<BillingSetupSubmissionDeleteMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (!result.data?.deleteBillingSetupSubmission) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.BillingSetupSubmission }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "billingSetupSubmissionConnection" });
		cache.gc();
	};
}

function addToBillingSetupSubmissionsOfAcademicYearCache(
	cache: ApolloCache<any>,
	targetID: AcademicYear["id"],
	object: Pick<BillingSetupSubmission, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.AcademicYear }),
		fields: {
			billingSetupSubmissions: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromBillingSetupSubmissionsOfAcademicYearCache(
	cache: ApolloCache<any>,
	targetID: AcademicYear["id"],
	object: Pick<BillingSetupSubmission, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.AcademicYear }),
		fields: {
			billingSetupSubmissions: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToBillingSetupSubmissionsOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<BillingSetupSubmission, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			billingSetupSubmissions: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromBillingSetupSubmissionsOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<BillingSetupSubmission, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			billingSetupSubmissions: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<BillingSetupSubmission, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment BillingSetupSubmissionRef on BillingSetupSubmission {
				id
			}
		`,
		data: object,
	});
}
