import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { NoteCommentOrderFormValues } from "../../../../Utilities";

export type NoteCommentOrderFormControllerProps = {
	formState: FormState<NoteCommentOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the NoteComment model.
 */
export function NoteCommentOrderFormController({ formState }: NoteCommentOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={NoteCommentOrderFormValues.fieldOptions}
			fieldTypeMapping={NoteCommentOrderFormValues.fieldTypeMapping}
		/>
	);
}
