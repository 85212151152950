import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const contractHubPageInfo = newPageInfo({
	title: "Contracts",
	description: "Pages related to the Contract model.",
	exact: false,
	to: hubsPageInfo.to + "/contracts",
	path: hubsPageInfo.path + "/contracts",
	parent: hubsPageInfo,
});
