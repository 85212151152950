import React from "react";
import { useApolloClient } from "@apollo/client";
import { AsyncValidationFunction, invalid, valid } from "@hex-insights/forms";
import {
	PermissionGroup,
	PermissionGroupCheckUniqueNameDocument,
	PermissionGroupCheckUniqueNameQuery,
	PermissionGroupCheckUniqueNameQueryVariables,
} from "../../GraphQL";
import { PermissionGroupFormValues } from "../ModelFormValues";

/**
 * Returns an asynchronous validation function to check that the given name is unique for the
 * PermissionGroup model.
 *
 * @param ignoreID The ID of the instance to ignore (generally the current instance in an update form).
 */
export function useUniqueNameValidator(
	ignoreID?: PermissionGroup["id"],
): AsyncValidationFunction<PermissionGroupFormValues.Base["name"]> {
	const client = useApolloClient();
	return React.useCallback(
		async (value: PermissionGroupFormValues.Base["name"]) => {
			if (value === "") {
				return valid();
			}

			const { data } = await client.query<
				PermissionGroupCheckUniqueNameQuery,
				PermissionGroupCheckUniqueNameQueryVariables
			>({
				query: PermissionGroupCheckUniqueNameDocument,
				variables: { name: value, ignoreID },
			});

			if (data.permissionGroupConnection.totalCount === 0) {
				return valid();
			}

			return invalid("This name is taken.");
		},
		[client, ignoreID],
	);
}
