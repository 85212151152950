import React from "react";
import { Conditional, Else, Heading, If } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { MFAInvalidOTPAttemptForm, useMFAInvalidOTPAttemptDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { mfaInvalidOTPAttemptHubDetailPageInfo } from "./pageinfo";

function MFAInvalidOTPAttemptHubDetailPageWrapper() {
	// Manages identity of the MFAInvalidOTPAttemptHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <MFAInvalidOTPAttemptHubDetailPage key={id} />;
}

export { MFAInvalidOTPAttemptHubDetailPageWrapper as MFAInvalidOTPAttemptHubDetailPage };

function MFAInvalidOTPAttemptHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useMFAInvalidOTPAttemptDetailQuery({ variables: { id } });
	const mfaInvalidOTPAttempt = data?.mfaInvalidOTPAttempt;
	useActivePageRegistration(
		mfaInvalidOTPAttemptHubDetailPageInfo,
		!!mfaInvalidOTPAttempt ? mfaInvalidOTPAttemptHubDetailPageInfo.title(mfaInvalidOTPAttempt) : "",
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading mfaInvalidOTPAttempt</If>
				<Else>
					{!!mfaInvalidOTPAttempt && (
						<React.Fragment>
							<Heading level={1}>{mfaInvalidOTPAttempt.id}</Heading>

							<MFAInvalidOTPAttemptForm.ControlledReadOnly mfaInvalidOTPAttempt={mfaInvalidOTPAttempt} />
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
