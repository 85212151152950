import "@hex-insights/core";
import { SessionDetailQuery } from "../../GraphQL";
import { SessionFormValues } from "../ModelFormValues";

/**
 * Converts a Session instance to its corresponding detail form values.
 *
 * @param session The instance to convert.
 */
export function toFormValues(session: SessionDetailQuery["session"]): SessionFormValues.Detail {
	return {
		createdAt: session.createdAt,
		updatedAt: session.updatedAt,
		expiresAt: session.expiresAt,
		longestInactiveSec: session.longestInactiveSec,
		isVerified: session.isVerified,
		isCurrent: session.isCurrent,
		loginRecordID: session.loginRecord?.id ?? null,
		mfaInvalidOTPAttemptIDs: session.mfaInvalidOTPAttempts.map((e) => e.id),
		trustedDeviceID: session.trustedDevice?.id ?? null,
		userID: session.user?.id ?? null,
	};
}
