import { NoteAttachment } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the order field of the NoteAttachment model.
 */
export function order(value: NoteAttachment["order"] | null): string {
	return toString(value);
}

/**
 * Formats the isFile field of the NoteAttachment model.
 */
export function isFile(value: NoteAttachment["isFile"]): string {
	return toString(value);
}

/**
 * Formats the linkURL field of the NoteAttachment model.
 */
export function linkURL(value: NoteAttachment["linkURL"]): string {
	return toString(value);
}

/**
 * Formats the url field of the NoteAttachment model.
 */
export function url(value: NoteAttachment["url"]): string {
	return toString(value);
}
