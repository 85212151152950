import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { PaymentHubCreatePage } from "./CreatePage";
import { paymentHubCreatePageInfo } from "./CreatePage/pageinfo";
import { PaymentHubDetailPage } from "./DetailPage";
import { paymentHubDetailPageInfo } from "./DetailPage/pageinfo";
import { PaymentHubIndexPage } from "./IndexPage";
import { paymentHubIndexPageInfo } from "./IndexPage/pageinfo";
import { paymentHubPageInfo } from "./pageinfo";

export function PaymentHub() {
	useActivePageRegistration(paymentHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...paymentHubIndexPageInfo.routeProps}>
				<PaymentHubIndexPage />
			</Route>
			<Route {...paymentHubCreatePageInfo.routeProps}>
				<PaymentHubCreatePage />
			</Route>
			<Route {...paymentHubDetailPageInfo.routeProps}>
				<PaymentHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
