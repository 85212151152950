import { newPageInfo } from "@hex-insights/router";
import { emailAddressHubPageInfo } from "../pageinfo";

export const emailAddressHubIndexPageInfo = newPageInfo({
	name: "Email Addresses Index",
	title: "",
	description: "Index of Email Addresses.",
	exact: true,
	to: emailAddressHubPageInfo.to,
	path: emailAddressHubPageInfo.path,
	parent: emailAddressHubPageInfo,
});
