import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import {
	ExpenseCategoryFilterInput,
	ExpenseCategoryOrder,
	useExpenseCategoryPaginationQuery,
} from "../../../../Utilities";

export type ExpenseCategoryPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: ExpenseCategoryFilterInput | ExpenseCategoryFilterInput[] | null;
	order: ExpenseCategoryOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the ExpenseCategory model.
 */
export function ExpenseCategoryPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: ExpenseCategoryPaginationFormProps) {
	const { loading, data } = useExpenseCategoryPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.expenseCategoryConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
