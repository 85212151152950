import { Expense, ExpenseCategory } from "../../GraphQL";

export type Base = {
	createdDate: Expense["createdDate"] | null;
	amount: Expense["amount"] | null;
	notes: Expense["notes"];
	expenseCategoryID: ExpenseCategory["id"] | null;
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	createdDate: null,
	amount: null,
	notes: "",
	expenseCategoryID: null,
};
