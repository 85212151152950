import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import {
	LearningObjectiveCreateInput,
	LearningObjectiveDetailQuery,
	LearningObjectiveUpdateInput,
} from "../../GraphQL";
import { LearningObjectiveFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the LearningObjective model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: LearningObjectiveFormValues.Create): LearningObjectiveCreateInput {
	const input: NullPartial<LearningObjectiveCreateInput> = { ...formValues };
	return input as LearningObjectiveCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the LearningObjective model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<LearningObjectiveFormValues.Detail>,
	initialFormValues: LearningObjectiveFormValues.Detail,
): LearningObjectiveUpdateInput {
	const inputFormValues = omitKeys(formValues, ["learningObjectiveMarkIDs"]);
	const input: LearningObjectiveUpdateInput = { ...inputFormValues };

	if (formValues.learningObjectiveMarkIDs !== undefined) {
		const { added, removed } = getArrayDiff(
			initialFormValues.learningObjectiveMarkIDs,
			formValues.learningObjectiveMarkIDs,
		);
		input.addLearningObjectiveMarkIDs = added;
		input.removeLearningObjectiveMarkIDs = removed;
	}

	return input;
}

/**
 * Converts a LearningObjective instance to its corresponding detail form values.
 *
 * @param learningObjective The instance to convert.
 */
export function toFormValues(
	learningObjective: LearningObjectiveDetailQuery["learningObjective"],
): LearningObjectiveFormValues.Detail {
	return {
		category: learningObjective.category,
		description: learningObjective.description,
		isActive: learningObjective.isActive,
		courseID: learningObjective.course?.id ?? null,
		learningObjectiveMarkIDs: learningObjective.learningObjectiveMarks.map((e) => e.id),
	};
}
