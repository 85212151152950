import { newPageInfo } from "@hex-insights/router";
import { reportCardHubPageInfo } from "../pageinfo";

export const reportCardHubCreatePageInfo = newPageInfo({
	name: "New Report Card",
	title: "New",
	description: "Create a new Report Card record.",
	exact: true,
	to: reportCardHubPageInfo.to + "/new",
	path: reportCardHubPageInfo.path + "/new",
	parent: reportCardHubPageInfo,
});
