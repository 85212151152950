import { newPageInfo } from "@hex-insights/router";
import { dashboardsPageInfo } from "../pageinfo";

export const dashboardsAcademicPageInfo = newPageInfo({
	name: "Academic Dashboard",
	title: "Academic",
	description: "Academic overview dashboard.",
	exact: true,
	to: dashboardsPageInfo.to + "/academic",
	path: dashboardsPageInfo.path + "/academic",
	parent: dashboardsPageInfo,
});
