import { newPageInfo } from "@hex-insights/router";
import { CourseSectionPeriod, CourseSectionPeriodFormat } from "@hex-insights/verita.shared";
import { courseSectionPeriodHubPageInfo } from "../pageinfo";

type CourseSectionPeriodForRouteTitle = Pick<CourseSectionPeriod, "dayOfWeek" | "startTimeMinutes" | "endTimeMinutes">;

export const courseSectionPeriodHubDetailPageInfo = newPageInfo({
	name: "Course Section Period Detail",
	title: (e: CourseSectionPeriodForRouteTitle) =>
		`${CourseSectionPeriodFormat.Fields.dayOfWeek(e.dayOfWeek)} ${CourseSectionPeriodFormat.Fields.startTimeMinutes(
			e.startTimeMinutes,
		)} to ${CourseSectionPeriodFormat.Fields.endTimeMinutes(e.endTimeMinutes)}`,
	description: "Detail page for an courseSectionPeriod.",
	exact: false,
	isLeaf: true,
	to: (id: CourseSectionPeriod["id"]) => courseSectionPeriodHubPageInfo.to + `/${id}`,
	path: courseSectionPeriodHubPageInfo.path + "/:id(\\d+)",
	parent: courseSectionPeriodHubPageInfo,
});
