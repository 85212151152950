import { CaseStyle, convertCase, stringToLocalDate } from "@hex-insights/core";
import { EnrollmentApplicationStudent } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the imageURL field of the EnrollmentApplicationStudent model.
 */
export function imageURL(value: EnrollmentApplicationStudent["imageURL"]): string {
	return toString(value);
}

/**
 * Formats the name field of the EnrollmentApplicationStudent model.
 */
export function name(value: EnrollmentApplicationStudent["name"]): string {
	return toString(value);
}

/**
 * Formats the firstName field of the EnrollmentApplicationStudent model.
 */
export function firstName(value: EnrollmentApplicationStudent["firstName"]): string {
	return toString(value);
}

/**
 * Formats the lastName field of the EnrollmentApplicationStudent model.
 */
export function lastName(value: EnrollmentApplicationStudent["lastName"]): string {
	return toString(value);
}

/**
 * Formats the nickname field of the EnrollmentApplicationStudent model.
 */
export function nickname(value: EnrollmentApplicationStudent["nickname"]): string {
	return toString(value);
}

/**
 * Formats the dateOfBirth field of the EnrollmentApplicationStudent model.
 */
export function dateOfBirth(value: EnrollmentApplicationStudent["dateOfBirth"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return stringToLocalDate(value, "day").toLocaleDateString(undefined, {
		year: "2-digit",
		month: "numeric",
		day: "numeric",
	});
}

/**
 * Formats the gender field of the EnrollmentApplicationStudent model.
 */
export function gender(value: EnrollmentApplicationStudent["gender"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return convertCase(value, CaseStyle.Title);
}

/**
 * Formats the genderCustom field of the EnrollmentApplicationStudent model.
 */
export function genderCustom(value: EnrollmentApplicationStudent["genderCustom"]): string {
	return toString(value);
}

/**
 * Formats the nationality field of the EnrollmentApplicationStudent model.
 */
export function nationality(value: EnrollmentApplicationStudent["nationality"]): string {
	return toString(value);
}

/**
 * Formats the primaryLanguage field of the EnrollmentApplicationStudent model.
 */
export function primaryLanguage(value: EnrollmentApplicationStudent["primaryLanguage"]): string {
	return toString(value);
}

/**
 * Formats the englishLanguageFluency field of the EnrollmentApplicationStudent model.
 */
export function englishLanguageFluency(value: EnrollmentApplicationStudent["englishLanguageFluency"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return convertCase(value, CaseStyle.Title);
}

/**
 * Formats the currentGradeLevel field of the EnrollmentApplicationStudent model.
 */
export function currentGradeLevel(value: EnrollmentApplicationStudent["currentGradeLevel"] | null): string {
	if (value === null) {
		return "Not Specified";
	}
	return convertCase(value, CaseStyle.Title);
}

/**
 * Formats the gradeLevelApplyingFor field of the EnrollmentApplicationStudent model.
 */
export function gradeLevelApplyingFor(value: EnrollmentApplicationStudent["gradeLevelApplyingFor"] | null): string {
	if (value === null) {
		return "Not Specified";
	}
	return convertCase(value, CaseStyle.Title);
}

/**
 * Formats the hasPreviousSchooling field of the EnrollmentApplicationStudent model.
 */
export function hasPreviousSchooling(value: EnrollmentApplicationStudent["hasPreviousSchooling"]): string {
	return toString(value);
}

/**
 * Formats the previousSchoolInformation field of the EnrollmentApplicationStudent model.
 */
export function previousSchoolInformation(value: EnrollmentApplicationStudent["previousSchoolInformation"]): string {
	return toString(value);
}

/**
 * Formats the previousSchoolLocation field of the EnrollmentApplicationStudent model.
 */
export function previousSchoolLocation(value: EnrollmentApplicationStudent["previousSchoolLocation"]): string {
	return toString(value);
}

/**
 * Formats the hasLearningDifficulties field of the EnrollmentApplicationStudent model.
 */
export function hasLearningDifficulties(value: EnrollmentApplicationStudent["hasLearningDifficulties"]): string {
	return toString(value);
}

/**
 * Formats the learningDifficultiesDescription field of the EnrollmentApplicationStudent model.
 */
export function learningDifficultiesDescription(
	value: EnrollmentApplicationStudent["learningDifficultiesDescription"],
): string {
	return toString(value);
}

/**
 * Formats the hasMedicalPhysicalExceptionalities field of the EnrollmentApplicationStudent model.
 */
export function hasMedicalPhysicalExceptionalities(
	value: EnrollmentApplicationStudent["hasMedicalPhysicalExceptionalities"],
): string {
	return toString(value);
}

/**
 * Formats the medicalPhysicalExceptionalitiesDescription field of the EnrollmentApplicationStudent model.
 */
export function medicalPhysicalExceptionalitiesDescription(
	value: EnrollmentApplicationStudent["medicalPhysicalExceptionalitiesDescription"],
): string {
	return toString(value);
}

/**
 * Formats the hasBehaviorDisciplineChallenges field of the EnrollmentApplicationStudent model.
 */
export function hasBehaviorDisciplineChallenges(
	value: EnrollmentApplicationStudent["hasBehaviorDisciplineChallenges"],
): string {
	return toString(value);
}

/**
 * Formats the behaviorDisciplineChallengesDescription field of the EnrollmentApplicationStudent model.
 */
export function behaviorDisciplineChallengesDescription(
	value: EnrollmentApplicationStudent["behaviorDisciplineChallengesDescription"],
): string {
	return toString(value);
}

/**
 * Formats the additionalInformation field of the EnrollmentApplicationStudent model.
 */
export function additionalInformation(value: EnrollmentApplicationStudent["additionalInformation"]): string {
	return toString(value);
}
