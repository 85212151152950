import React from "react";
import {
	DateTimeField,
	FieldDisplayArgs,
	MultiSelectField,
	RadioField,
	SelectField,
	TextField,
} from "@hex-insights/forms";
import {
	CampusSelect,
	EmployeeFormValues,
	PersonSelect,
	useCampusSelectLazyQuery,
	usePersonSelectLazyQuery,
	validateEmail,
} from "../../../../Utilities";
import { CampusLink, PersonLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Employee model.
 */
type FieldProps<K extends keyof EmployeeFormValues.Base = keyof EmployeeFormValues.Base> = BaseFieldProps<
	Pick<EmployeeFormValues.Base, K>
>;

/**
 * Renders a field component for the `email` field of the Employee model.
 */
export function Email({ formState }: FieldProps<"email">) {
	return <TextField formState={formState} name="email" label="Work Email" immediateValidation={validateEmail} />;
}

/**
 * Renders a field component for the `role` field of the Employee model.
 */
export function Role({ formState }: FieldProps<"role">) {
	return <RadioField formState={formState} name="role" options={EmployeeFormValues.roleOptions} blankValue={null} />;
}

/**
 * Renders a field component for the `jobTitle` field of the Employee model.
 */
export function JobTitle({ formState }: FieldProps<"jobTitle">) {
	return <TextField formState={formState} name="jobTitle" />;
}

/**
 * Renders a field component for the `startDate` field of the Employee model.
 */
export function StartDate({ formState }: FieldProps<"startDate">) {
	return <DateTimeField formState={formState} name="startDate" precision="day" />;
}

/**
 * Renders a field component for the `endDate` field of the Employee model.
 */
export function EndDate({ formState }: FieldProps<"endDate">) {
	return <DateTimeField formState={formState} name="endDate" optional precision="day" />;
}

export type CampusesProps = FieldProps<"campusIDs"> & {
	currentCampuses?: CampusSelect.ModelForOption[];
};

/**
 * Renders a field component for the `campuses` edge of the Employee model.
 */
export function Campuses({ formState, currentCampuses }: CampusesProps) {
	const [loadOptions, { loading, data }] = useCampusSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.campusIDs) {
			loadOptions();
		}
	}, [formState.formEditing.campusIDs, loadOptions]);
	const options = React.useMemo(
		() => CampusSelect.toMultiOptions(data?.campusConnection.edges, currentCampuses),
		[data, currentCampuses],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="campusIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayCampusInstance}
		/>
	);
}

function displayCampusInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <CampusLink instance={{ id }}>{formattedValue}</CampusLink>;
}

export type PersonProps = FieldProps<"personID"> & {
	currentPerson?: PersonSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `person` edge of the Employee model.
 */
export function Person({ formState, currentPerson }: PersonProps) {
	const [loadOptions, { loading, data }] = usePersonSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.personID) {
			loadOptions();
		}
	}, [formState.formEditing.personID, loadOptions]);
	const options = React.useMemo(
		() => PersonSelect.toOptions(data?.personConnection.edges, currentPerson),
		[data, currentPerson],
	);

	return (
		<SelectField
			formState={formState}
			name="personID"
			label="Person Record"
			isLoading={loading}
			options={options}
			display={displayPerson}
			blankValue={null}
		/>
	);
}

function displayPerson({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <PersonLink instance={{ id }}>{formattedValue}</PersonLink>;
}
