import React from "react";
import { useApolloClient } from "@apollo/client";
import { AsyncValidationFunction, invalid, valid } from "@hex-insights/forms";
import {
	ExpenseCategory,
	ExpenseCategoryCheckUniqueNameDocument,
	ExpenseCategoryCheckUniqueNameQuery,
	ExpenseCategoryCheckUniqueNameQueryVariables,
} from "../../GraphQL";
import { ExpenseCategoryFormValues } from "../ModelFormValues";

/**
 * Returns an asynchronous validation function to check that the given name is unique for the
 * ExpenseCategory model.
 *
 * @param ignoreID The ID of the instance to ignore (generally the current instance in an update form).
 */
export function useUniqueNameValidator(
	ignoreID?: ExpenseCategory["id"],
): AsyncValidationFunction<ExpenseCategoryFormValues.Base["name"]> {
	const client = useApolloClient();
	return React.useCallback(
		async (value: ExpenseCategoryFormValues.Base["name"]) => {
			if (value === "") {
				return valid();
			}

			const { data } = await client.query<
				ExpenseCategoryCheckUniqueNameQuery,
				ExpenseCategoryCheckUniqueNameQueryVariables
			>({
				query: ExpenseCategoryCheckUniqueNameDocument,
				variables: { name: value, ignoreID },
			});

			if (data.expenseCategoryConnection.totalCount === 0) {
				return valid();
			}

			return invalid("This name is taken.");
		},
		[client, ignoreID],
	);
}
