import { newPageInfo } from "@hex-insights/router";
import { relationshipHubPageInfo } from "../pageinfo";

export const relationshipHubIndexPageInfo = newPageInfo({
	name: "Relationships Index",
	title: "",
	description: "Index of Relationships.",
	exact: true,
	to: relationshipHubPageInfo.to,
	path: relationshipHubPageInfo.path,
	parent: relationshipHubPageInfo,
});
