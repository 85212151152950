import { newPageInfo } from "@hex-insights/router";
import { paymentLineItemHubPageInfo } from "../pageinfo";

export const paymentLineItemHubCreatePageInfo = newPageInfo({
	name: "New Payment Line Item",
	title: "New",
	description: "Create a new Payment Line Item record.",
	exact: true,
	to: paymentLineItemHubPageInfo.to + "/new",
	path: paymentLineItemHubPageInfo.path + "/new",
	parent: paymentLineItemHubPageInfo,
});
