import { newPageInfo } from "@hex-insights/router";
import { enrollmentApplicationStudentHubPageInfo } from "../pageinfo";

export const enrollmentApplicationStudentHubCreatePageInfo = newPageInfo({
	name: "New Enrollment Application Student",
	title: "New",
	description: "Create a new Enrollment Application Student record.",
	exact: true,
	to: enrollmentApplicationStudentHubPageInfo.to + "/new",
	path: enrollmentApplicationStudentHubPageInfo.path + "/new",
	parent: enrollmentApplicationStudentHubPageInfo,
});
