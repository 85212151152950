import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { DocumentResourceFolderHubCreatePage } from "./CreatePage";
import { documentResourceFolderHubCreatePageInfo } from "./CreatePage/pageinfo";
import { DocumentResourceFolderHubDetailPage } from "./DetailPage";
import { documentResourceFolderHubDetailPageInfo } from "./DetailPage/pageinfo";
import { DocumentResourceFolderHubIndexPage } from "./IndexPage";
import { documentResourceFolderHubIndexPageInfo } from "./IndexPage/pageinfo";
import { documentResourceFolderHubPageInfo } from "./pageinfo";

export function DocumentResourceFolderHub() {
	useActivePageRegistration(documentResourceFolderHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...documentResourceFolderHubIndexPageInfo.routeProps}>
				<DocumentResourceFolderHubIndexPage />
			</Route>
			<Route {...documentResourceFolderHubCreatePageInfo.routeProps}>
				<DocumentResourceFolderHubCreatePage />
			</Route>
			<Route {...documentResourceFolderHubDetailPageInfo.routeProps}>
				<DocumentResourceFolderHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
