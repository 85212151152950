import { newPageInfo, newRouteParamsType } from "@hex-insights/router";
import {
	BillingSetupSubmission,
	BillingSetupSubmissionFormat,
	BillingSetupSubmissionNameFragment,
} from "@hex-insights/verita.shared";
import { billingSetupSubmissionHubPageInfo } from "../pageinfo";

type BillingSetupSubmissionForRouteTitle = BillingSetupSubmissionNameFragment;

export const billingSetupSubmissionHubDetailPageInfo = newPageInfo({
	name: "Billing Setup Submission Detail",
	title: (instance: BillingSetupSubmissionForRouteTitle) => BillingSetupSubmissionFormat.name(instance),
	description: "Billing Setup Submission detail page.",
	exact: false,
	isLeaf: true,
	parent: billingSetupSubmissionHubPageInfo,
	to: (id: BillingSetupSubmission["id"]) => billingSetupSubmissionHubPageInfo.to + `/${id}`,
	path: billingSetupSubmissionHubPageInfo.path + "/:billingSetupSubmissionID(\\d+)",
	paramsType: newRouteParamsType(billingSetupSubmissionHubPageInfo.paramsType, "billingSetupSubmissionID"),
});
