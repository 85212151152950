import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	InvoiceLineItemForm,
	InvoiceLineItemFormValues,
	InvoiceLineItemMutation,
	useInvoiceLineItemDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { invoiceLineItemHubDetailPageInfo } from "./pageinfo";

function InvoiceLineItemHubDetailPageWrapper() {
	// Manages identity of the InvoiceLineItemHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <InvoiceLineItemHubDetailPage key={id} />;
}

export { InvoiceLineItemHubDetailPageWrapper as InvoiceLineItemHubDetailPage };

function InvoiceLineItemHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useInvoiceLineItemDetailQuery({ variables: { id } });
	const invoiceLineItem = data?.invoiceLineItem;
	useActivePageRegistration(
		invoiceLineItemHubDetailPageInfo,
		!!invoiceLineItem ? invoiceLineItemHubDetailPageInfo.title(invoiceLineItem) : "",
	);

	const update = InvoiceLineItemMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<InvoiceLineItemFormValues.Detail>,
			initialFormValues: InvoiceLineItemFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading invoiceLineItem</If>
				<Else>
					{!!invoiceLineItem && (
						<React.Fragment>
							<Heading level={1}>{invoiceLineItem.id}</Heading>

							<InvoiceLineItemForm.ControlledDetail
								key={syncID}
								invoiceLineItem={invoiceLineItem}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
