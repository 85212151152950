import { newPageInfo } from "@hex-insights/router";
import { LearningObjectiveMark } from "@hex-insights/verita.shared";
import { learningObjectiveMarkHubPageInfo } from "../pageinfo";

type LearningObjectiveMarkForRouteTitle = Pick<LearningObjectiveMark, "id">;

export const learningObjectiveMarkHubDetailPageInfo = newPageInfo({
	name: "Learning Objective Mark Detail",
	title: (e: LearningObjectiveMarkForRouteTitle) => e.id,
	description: "Detail page for an learningObjectiveMark.",
	exact: false,
	isLeaf: true,
	to: (id: LearningObjectiveMark["id"]) => learningObjectiveMarkHubPageInfo.to + `/${id}`,
	path: learningObjectiveMarkHubPageInfo.path + "/:id(\\d+)",
	parent: learningObjectiveMarkHubPageInfo,
});
