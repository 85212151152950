import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import {
	HomeRoomSectionFilterInput,
	HomeRoomSectionOrder,
	useHomeRoomSectionPaginationQuery,
} from "../../../../Utilities";

export type HomeRoomSectionPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: HomeRoomSectionFilterInput | HomeRoomSectionFilterInput[] | null;
	order: HomeRoomSectionOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the HomeRoomSection model.
 */
export function HomeRoomSectionPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: HomeRoomSectionPaginationFormProps) {
	const { loading, data } = useHomeRoomSectionPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.homeRoomSectionConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
