import React from "react";
import { FieldDisplayArgs, RadioField, SelectField } from "@hex-insights/forms";
import {
	LearningObjectiveMarkFormValues,
	LearningObjectiveSelect,
	ReportCardSelect,
	useLearningObjectiveSelectLazyQuery,
	useReportCardSelectLazyQuery,
} from "../../../../Utilities";
import { LearningObjectiveLink, ReportCardLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the LearningObjectiveMark model.
 */
type FieldProps<K extends keyof LearningObjectiveMarkFormValues.Base = keyof LearningObjectiveMarkFormValues.Base> =
	BaseFieldProps<Pick<LearningObjectiveMarkFormValues.Base, K>>;

/**
 * Renders a field component for the `value` field of the LearningObjectiveMark model.
 */
export function Value({ formState }: FieldProps<"value">) {
	return (
		<RadioField
			formState={formState}
			name="value"
			options={LearningObjectiveMarkFormValues.valueOptions}
			blankValue={null}
			optional
		/>
	);
}

export type LearningObjectiveProps = FieldProps<"learningObjectiveID"> & {
	currentLearningObjective?: LearningObjectiveSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `learningObjective` edge of the LearningObjectiveMark model.
 */
export function LearningObjective({ formState, currentLearningObjective }: LearningObjectiveProps) {
	const [loadOptions, { loading, data }] = useLearningObjectiveSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.learningObjectiveID) {
			loadOptions();
		}
	}, [formState.formEditing.learningObjectiveID, loadOptions]);
	const options = React.useMemo(
		() => LearningObjectiveSelect.toOptions(data?.learningObjectiveConnection.edges, currentLearningObjective),
		[data, currentLearningObjective],
	);

	return (
		<SelectField
			formState={formState}
			name="learningObjectiveID"
			isLoading={loading}
			options={options}
			display={displayLearningObjective}
			blankValue={null}
		/>
	);
}

function displayLearningObjective({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <LearningObjectiveLink instance={{ id }}>{formattedValue}</LearningObjectiveLink>;
}

export type ReportCardProps = FieldProps<"reportCardID"> & {
	currentReportCard?: ReportCardSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `reportCard` edge of the LearningObjectiveMark model.
 */
export function ReportCard({ formState, currentReportCard }: ReportCardProps) {
	const [loadOptions, { loading, data }] = useReportCardSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.reportCardID) {
			loadOptions();
		}
	}, [formState.formEditing.reportCardID, loadOptions]);
	const options = React.useMemo(
		() => ReportCardSelect.toOptions(data?.reportCardConnection.edges, currentReportCard),
		[data, currentReportCard],
	);

	return (
		<SelectField
			formState={formState}
			name="reportCardID"
			isLoading={loading}
			options={options}
			display={displayReportCard}
			blankValue={null}
		/>
	);
}

function displayReportCard({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ReportCardLink instance={{ id }}>{formattedValue}</ReportCardLink>;
}
