import { IndexForms } from "@hex-insights/app-modules";
import { AcademicYearTuitionFilterFormValues } from "../../../../Utilities";

export type AcademicYearTuitionFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, AcademicYearTuitionFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the AcademicYearTuition model.
 */
export function AcademicYearTuitionFilterFormController(props: AcademicYearTuitionFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={AcademicYearTuitionFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define QuickFilters component and add to `AcademicYearTuitionFilterFormController`
