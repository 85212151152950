import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { ExpenseBudgetFilterInput, ExpenseBudgetOrder, useExpenseBudgetPaginationQuery } from "../../../../Utilities";

export type ExpenseBudgetPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: ExpenseBudgetFilterInput | ExpenseBudgetFilterInput[] | null;
	order: ExpenseBudgetOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the ExpenseBudget model.
 */
export function ExpenseBudgetPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: ExpenseBudgetPaginationFormProps) {
	const { loading, data } = useExpenseBudgetPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.expenseBudgetConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
