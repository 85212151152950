import { Expense } from "../../../GraphQL";
import { formatPrice } from "../../money";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the createdDate field of the Expense model.
 */
export function createdDate(value: Expense["createdDate"] | null): string {
	return toString(value);
}

/**
 * Formats the amount field of the Expense model.
 */
export function amount(value: Expense["amount"] | null): string {
	return formatPrice(value);
}

/**
 * Formats the notes field of the Expense model.
 */
export function notes(value: Expense["notes"]): string {
	return toString(value);
}
