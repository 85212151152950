import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	InvoiceFilterFormController,
	InvoiceFilterFormState,
	InvoiceFilterFormUtils,
	InvoiceFormat,
	InvoiceIndexQuery,
	InvoiceOrderField,
	InvoiceOrderFormController,
	InvoiceOrderFormState,
	InvoiceOrderFormValues,
	InvoicePaginationForm,
	InvoiceSearchForm,
	useInvoiceIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { invoiceHubCreatePageInfo } from "../CreatePage/pageinfo";
import { invoiceHubDetailPageInfo } from "../DetailPage/pageinfo";
import { invoiceHubIndexPageInfo } from "./pageinfo";

const columns: (keyof InvoiceIndexQuery["invoiceConnection"]["edges"][0]["node"])[] = [
	"invoiceNumber",
	"createdDate",
	"dueDate",
	"description",
];

function orderFieldToColumn(field: InvoiceOrderField | null) {
	if (field === null) {
		return "id";
	}
	return InvoiceOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return InvoiceOrderFormValues.modelFieldToOrderField[column];
}

export function InvoiceHubIndexPage() {
	useActivePageRegistration(invoiceHubIndexPageInfo);

	const filterFormState = InvoiceFilterFormState.useFormState();
	const orderFormState = InvoiceOrderFormState.useFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(filterFormState.formValues, InvoiceFilterFormUtils.toFilterInputs);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useInvoiceIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		InvoiceFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = InvoiceFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.invoiceConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading level={1} noMargin>
					Invoices
				</Heading>

				<InternalLink to={invoiceHubCreatePageInfo.to}>New Invoice</InternalLink>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<InvoiceSearchForm formState={filterFormState} />
				<InvoiceFilterFormController formState={filterFormState} numActiveFilters={numActiveFilters} />
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<InvoiceOrderFormController formState={orderFormState} />
			</Row>

			<Table.Container className="hub__index-page__table__container">
				<Table columns={columns} minColumnWidth="10rem" {...tableSortingProps} className="hub__index-page__table">
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="invoiceNumber">Invoice Number</Table.Heading>
							<Table.Heading column="createdDate">Created Date</Table.Heading>
							<Table.Heading column="dueDate">Due Date</Table.Heading>
							<Table.Heading column="description" noSort>
								Description
							</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.invoiceConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="invoiceNumber" oneLine>
											<InternalLink to={invoiceHubDetailPageInfo.to(e.node.id)}>
												{InvoiceFormat.Fields.invoiceNumber(e.node.invoiceNumber)}
											</InternalLink>
										</Table.Cell>
										<Table.Cell column="createdDate" oneLine>
											{InvoiceFormat.Fields.createdDate(e.node.createdDate)}
										</Table.Cell>
										<Table.Cell column="dueDate" oneLine>
											{InvoiceFormat.Fields.dueDate(e.node.dueDate)}
										</Table.Cell>
										<Table.Cell column="description" oneLine>
											{InvoiceFormat.Fields.description(e.node.description)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<InvoicePaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.invoiceConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
