import { ChildrenProps, Row } from "@hex-insights/core";
import { RadioInput, RadioInputProps, ScalarFieldValue } from "@hex-insights/forms";
import styles from "./styles.module.css";

// TODO move to hex-core

export type RadioRowInputProps<T extends ScalarFieldValue> = Omit<RadioInputProps<T>, "OptionsContainer">;

export function RadioRowInput<T extends ScalarFieldValue>(props: RadioRowInputProps<T>) {
	return <RadioInput {...props} OptionsContainer={RadioRowOptionsContainer} />;
}

function RadioRowOptionsContainer({ children }: Partial<ChildrenProps>) {
	return (
		<Row justify="spaced-start" align="spaced-start" overflow="wrap" className={styles["radio-row-options__container"]}>
			{children}
		</Row>
	);
}
