import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { CourseAttendanceRecordOrderFormValues } from "../../../../Utilities";

export type CourseAttendanceRecordOrderFormControllerProps = {
	formState: FormState<CourseAttendanceRecordOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the CourseAttendanceRecord model.
 */
export function CourseAttendanceRecordOrderFormController({
	formState,
}: CourseAttendanceRecordOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={CourseAttendanceRecordOrderFormValues.fieldOptions}
			fieldTypeMapping={CourseAttendanceRecordOrderFormValues.fieldTypeMapping}
		/>
	);
}
