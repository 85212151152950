import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { LearningObjectiveOrderFormValues } from "../../../../Utilities";

export type LearningObjectiveOrderFormControllerProps = {
	formState: FormState<LearningObjectiveOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the LearningObjective model.
 */
export function LearningObjectiveOrderFormController({ formState }: LearningObjectiveOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={LearningObjectiveOrderFormValues.fieldOptions}
			fieldTypeMapping={LearningObjectiveOrderFormValues.fieldTypeMapping}
		/>
	);
}
