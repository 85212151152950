import React from "react";
import { Column, Conditional, Else, Heading, If, Row, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	TermForm,
	TermFormValues,
	TermMutation,
	Tile,
	Timeline,
	TimelineObjectType,
	usePaginatedTimelineQuery,
	useTermDetailListQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { termHubDetailPageInfo } from "./pageinfo";

function TermHubDetailPageWrapper() {
	// Manages identity of the TermHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <TermHubDetailPage key={id} />;
}

export { TermHubDetailPageWrapper as TermHubDetailPage };

function TermHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	// TODO move back to plain detail query when data loaders work for detail queries
	const { loading, data, error } = useTermDetailListQuery({ variables: { filters: { idEQ: id } } });
	const term = data?.termConnection.edges[0]?.node;
	useActivePageRegistration(termHubDetailPageInfo, !!term ? termHubDetailPageInfo.title(term) : "");

	const update = TermMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<TermFormValues.Detail>, initialFormValues: TermFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined || !term}>Error loading term</If>
				<Else>
					{!!term && (
						<Column
							style={{
								height: "calc(100vh - var(--header---height) - 2 * var(--main---padding-top))",
							}}
						>
							<Heading.H1>{term.name}</Heading.H1>

							<Row justify="spaced-start" style={{ height: "100%", minHeight: 0 }}>
								<Tile>
									<Tile.Body>
										<TermForm.ControlledDetail
											key={syncID}
											term={term}
											applyUpdate={applyUpdate}
											onSuccess={synchronize}
										/>
									</Tile.Body>
								</Tile>

								<TimelineTile startDate={term.startDate} endDate={term.endDate} />
							</Row>
						</Column>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}

const timelineObjectTypes = [TimelineObjectType.Note, TimelineObjectType.StudentDocument];

type TimelineTileProps = {
	startDate: string;
	endDate: string;
};

function TimelineTile({ startDate, endDate }: TimelineTileProps) {
	const { data, networkStatus, refetch, hasMore, onLoadMoreClick } = usePaginatedTimelineQuery({
		filter: {
			startDate,
			endDate,
			objectTypes: timelineObjectTypes,
		},
	});

	return (
		<Tile style={{ flexGrow: 1, width: "100%", height: "100%", minHeight: 0 }}>
			<Tile.Body
				className="timeline__scrolling-container__container"
				style={{ height: "100%", overflow: "hidden", padding: 0 }}
			>
				<div
					className="timeline__scrolling-container"
					style={{ height: "100%", overflow: "scroll", padding: "var(--section__body---padding)" }}
				>
					<Timeline data={data?.timeline} networkStatus={networkStatus} refetch={refetch} />

					<Timeline.LoadMoreButton hasMore={hasMore} networkStatus={networkStatus} onClick={onLoadMoreClick} />
				</div>
			</Tile.Body>
		</Tile>
	);
}
