import React from "react";
import {
	addTimeToDate,
	Column,
	Conditional,
	dateTrunc,
	Else,
	Icon,
	If,
	mappedArray,
	Row,
	Section,
} from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import {
	DocumentResourceOrderField,
	HorizontalScrollingContainer,
	OrderDirection,
	useDocumentResourceFolderIndexQuery,
	useDocumentResourceIndexQuery,
	useDocumentResourceUserViewRecordRecentsQuery,
} from "@hex-insights/verita.shared";
import { AuthenticationContext } from "../../../Contexts";
import {
	DocumentResourceDisplay,
	DocumentResourceFolderCreator,
	DocumentResourceFolderDisplay,
	FoldersGrid,
	ResourcesGrid,
	ResourcesRowSection,
	SectionDivider,
} from "../Components";
import { resourcesIndexPageInfo } from "./pageinfo";
import styles from "./styles.module.css";

export function ResourcesIndexPage() {
	useActivePageRegistration(resourcesIndexPageInfo);

	return (
		<Column justify="spaced-start" style={{ width: "100%", overflowX: "hidden" }}>
			<Bookmarked />
			<SectionDivider />
			<RecentlyViewed />
			<RecentlyCreated />
			<TopLevelFolders />
			<TopLevelDocuments />
		</Column>
	);
}

const bookmarkedBackgroundColor = "#ded7f6";

function Bookmarked() {
	const { user } = React.useContext(AuthenticationContext);
	const { loading, data } = useDocumentResourceIndexQuery({
		variables: {
			filters: { bookmarkingUsers: [{ idEQ: user?.id }] },
			order: { field: DocumentResourceOrderField.Name, direction: OrderDirection.Asc },
		},
		fetchPolicy: "cache-and-network",
	});

	return (
		<ResourcesRowSection
			icon={<Icon.Bookmark />}
			className={styles["bookmarked"]}
			style={{ backgroundColor: bookmarkedBackgroundColor, width: "100%" }}
		>
			<Conditional>
				<If condition={!!data && data.documentResourceConnection.edges.length === 0}>
					<Row justify="center" align="center">
						<span>You haven't bookmarked any resources yet</span>
					</Row>
				</If>
				<Else>
					<HorizontalScrollingContainer backgroundColor={bookmarkedBackgroundColor}>
						<Row justify="spaced-start" style={{ width: "100%", minWidth: 0 }}>
							<Conditional>
								<If condition={loading && !data}>
									{mappedArray(12, (i) => (
										<DocumentResourceDisplay.Loading key={i} />
									))}
								</If>
								<Else>
									{data?.documentResourceConnection.edges.map(({ node }) => (
										<DocumentResourceDisplay key={node.id} documentResource={node} />
									))}
								</Else>
							</Conditional>
						</Row>
					</HorizontalScrollingContainer>
				</Else>
			</Conditional>
		</ResourcesRowSection>
	);
}

function RecentlyViewed() {
	const { user } = React.useContext(AuthenticationContext);
	const { loading, data } = useDocumentResourceUserViewRecordRecentsQuery({
		variables: {
			userID: user?.id ?? "0",
		},
		fetchPolicy: "cache-and-network",
	});

	if (data && data.documentResourceUserViewRecordConnectionRecents.edges.length === 0) {
		return null;
	}

	return (
		<React.Fragment>
			<ResourcesRowSection icon={<Icon.Clock />} label="Recents" style={{ width: "100%" }}>
				<HorizontalScrollingContainer>
					<Row justify="spaced-start" style={{ width: "100%", minWidth: 0 }}>
						<Conditional>
							<If condition={loading && !data}>
								{mappedArray(12, (i) => (
									<DocumentResourceDisplay.Loading key={i} />
								))}
							</If>
							<Else>
								{data?.documentResourceUserViewRecordConnectionRecents.edges.map(({ node }) => (
									<DocumentResourceDisplay key={node.id} documentResource={node.documentResource} />
								))}
							</Else>
						</Conditional>
					</Row>
				</HorizontalScrollingContainer>
			</ResourcesRowSection>

			<SectionDivider />
		</React.Fragment>
	);
}

function RecentlyCreated() {
	const cutoffTime = dateTrunc(addTimeToDate(new Date(), [-48, "hours"]), "hour");
	const { loading, data } = useDocumentResourceIndexQuery({
		variables: {
			filters: { createdAtGT: cutoffTime.toISOString() },
			order: { field: DocumentResourceOrderField.CreatedAt, direction: OrderDirection.Desc },
		},
		fetchPolicy: "cache-and-network",
	});

	if (data && data.documentResourceConnection.edges.length === 0) {
		return null;
	}

	return (
		<React.Fragment>
			<ResourcesRowSection icon={<Icon.Star />} label="New" style={{ width: "100%" }}>
				<HorizontalScrollingContainer>
					<Conditional>
						<If condition={loading && !data}>
							{mappedArray(12, (i) => (
								<DocumentResourceDisplay.Loading key={i} />
							))}
						</If>
						<Else>
							<Row justify="spaced-start" style={{ width: "100%", minWidth: 0 }}>
								{data?.documentResourceConnection.edges.map(({ node }) => (
									<DocumentResourceDisplay key={node.id} documentResource={node} />
								))}
							</Row>
						</Else>
					</Conditional>
				</HorizontalScrollingContainer>
			</ResourcesRowSection>

			<SectionDivider />
		</React.Fragment>
	);
}

function TopLevelFolders() {
	const { loading, data } = useDocumentResourceFolderIndexQuery({
		variables: {
			filters: { hasParentDocumentResourceFolder: false },
		},
	});

	return (
		<Section>
			<Section.Body>
				<FoldersGrid>
					<Conditional>
						<If condition={loading && !data}>
							{mappedArray(8, (i) => (
								<DocumentResourceFolderDisplay.Loading key={i} />
							))}
						</If>
						<Else>
							{data?.documentResourceFolderConnection.edges.map(({ node }) => (
								<DocumentResourceFolderDisplay
									key={node.id}
									documentResourceFolder={node}
									draggable
									dropTarget
									style={{ width: "100%" }}
								/>
							))}
							<DocumentResourceFolderCreator style={{ width: "100%" }} />
						</Else>
					</Conditional>
				</FoldersGrid>
			</Section.Body>
		</Section>
	);
}

function TopLevelDocuments() {
	const { loading, data } = useDocumentResourceIndexQuery({
		variables: {
			filters: { hasDocumentResourceFolders: false },
		},
	});

	return (
		<Section>
			<Section.Body style={{ paddingLeft: 0, paddingRight: 0 }}>
				<ResourcesGrid>
					<Conditional>
						<If condition={loading && !data}>
							{mappedArray(24, (i) => (
								<DocumentResourceDisplay.Loading key={i} />
							))}
						</If>
						<Else>
							{data?.documentResourceConnection.edges.map(({ node }) => (
								<DocumentResourceDisplay key={node.id} documentResource={node} draggable style={{ width: "100%" }} />
							))}
						</Else>
					</Conditional>
				</ResourcesGrid>
			</Section.Body>
		</Section>
	);
}
