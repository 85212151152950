import { CaseStyle, convertCase } from "@hex-insights/core";
import { Course, CourseSubject } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the name field of the Course model.
 */
export function name(value: Course["name"]): string {
	return toString(value);
}

/**
 * Formats the description field of the Course model.
 */
export function description(value: Course["description"]): string {
	return toString(value);
}

/**
 * Formats the subject field of the Course model.
 */
export function subject(value: Course["subject"] | null): string {
	if (value === null) {
		return toString(value);
	}

	switch (value) {
		case CourseSubject.Ell:
			return "ELL";
		case CourseSubject.Ipc:
			return "IPC/IEYC";
		case CourseSubject.Pe:
			return "PE";
		case CourseSubject.SelTime:
			return "SEL Time";
		default:
			return convertCase(value, CaseStyle.Title);
	}
}

/**
 * Formats the studentCapacity field of the Course model.
 */
export function studentCapacity(value: Course["studentCapacity"] | null): string {
	return toString(value);
}
