import { Column, Row } from "@hex-insights/core";
import { EventCalendarTile, Tile } from "@hex-insights/verita.shared";
import { ExpensesTile } from "../Components";
import { ReportingTile } from "./ReportingTile";
import { RevenueTile } from "./RevenueTile";

export function FinancialsDashboard() {
	return (
		<Row
			justify="spaced-start"
			style={{
				height: "calc(100vh - var(--header---height) - 2 * var(--main---padding-top))",
			}}
		>
			<Column justify="spaced-start" style={{ flexGrow: 1 }}>
				<Tile>
					<Tile.Body>Announcements</Tile.Body>
				</Tile>
				<ExpensesTile />
				<RevenueTile style={{ flexGrow: 1, height: "100%", overflow: "hidden" }} />
			</Column>
			<Column justify="spaced-start" style={{ width: "33%", height: "100%" }}>
				<Tile>
					<Tile.Body>QUICK ACTIONS</Tile.Body>
				</Tile>
				<EventCalendarTile style={{ flexGrow: 1, height: "100%", overflow: "hidden" }} />
				<ReportingTile />
			</Column>
		</Row>
	);
}
