import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	DiscountForm,
	DiscountFormValues,
	DiscountMutation,
	useDiscountDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { discountHubDetailPageInfo } from "./pageinfo";

function DiscountHubDetailPageWrapper() {
	// Manages identity of the DiscountHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <DiscountHubDetailPage key={id} />;
}

export { DiscountHubDetailPageWrapper as DiscountHubDetailPage };

function DiscountHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useDiscountDetailQuery({ variables: { id } });
	const discount = data?.discount;
	useActivePageRegistration(discountHubDetailPageInfo, !!discount ? discountHubDetailPageInfo.title(discount) : "");

	const update = DiscountMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<DiscountFormValues.Detail>, initialFormValues: DiscountFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading discount</If>
				<Else>
					{!!discount && (
						<React.Fragment>
							<Heading level={1}>{discount.id}</Heading>

							<DiscountForm.ControlledDetail
								key={syncID}
								discount={discount}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
