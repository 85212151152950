import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const contractInstallmentHubPageInfo = newPageInfo({
	title: "Contract Installments",
	description: "Pages related to the Contract Installment model.",
	exact: false,
	to: hubsPageInfo.to + "/contract-installments",
	path: hubsPageInfo.path + "/contract-installments",
	parent: hubsPageInfo,
});
