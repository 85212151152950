import { newPageInfo } from "@hex-insights/router";
import { personHubPageInfo } from "../pageinfo";

export const personHubCreatePageInfo = newPageInfo({
	name: "New Person",
	title: "New",
	description: "Create a new Person record.",
	exact: true,
	to: personHubPageInfo.to + "/new",
	path: personHubPageInfo.path + "/new",
	parent: personHubPageInfo,
});
