import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { DocumentResource, DocumentResourceFormat, DocumentResourceNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<DocumentResource, "id"> & DocumentResourceNameFragment;

export type DocumentResourceLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given DocumentResource instance.
 */
export function DocumentResourceLink(props: DocumentResourceLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="DocumentResource" format={DocumentResourceFormat.name} {...props} />;
}
