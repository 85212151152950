import { newPageInfo } from "@hex-insights/router";
import { billingSetupSubmissionHubPageInfo } from "../pageinfo";

export const billingSetupSubmissionHubIndexPageInfo = newPageInfo({
	name: "Billing Setup Submissions Index",
	title: "",
	description: "Billing Setup Submission index page.",
	exact: true,
	to: billingSetupSubmissionHubPageInfo.to,
	path: billingSetupSubmissionHubPageInfo.path,
	parent: billingSetupSubmissionHubPageInfo,
});
