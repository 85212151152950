import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const courseAttendanceRecordHubPageInfo = newPageInfo({
	title: "Course Attendance Records",
	description: "Pages related to the Course Attendance Record model.",
	exact: false,
	to: hubsPageInfo.to + "/course-attendance-records",
	path: hubsPageInfo.path + "/course-attendance-records",
	parent: hubsPageInfo,
});
