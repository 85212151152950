import React from "react";
import { Column, Heading } from "@hex-insights/core";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { ClassYearForm, ClassYearFormValues, ClassYearMutation } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { classYearHubDetailPageInfo } from "../DetailPage/pageinfo";
import { classYearHubCreatePageInfo } from "./pageinfo";

export function ClassYearHubCreatePage() {
	useActivePageRegistration(classYearHubCreatePageInfo);

	const create = ClassYearMutation.useCreate();
	const createdIDRef = React.useRef<string | null>(null);
	const { push: pushHistory } = useHistory();

	const applyCreate = React.useCallback(
		async (formValues: ClassYearFormValues.Create) => {
			const { data, errors } = await create(formValues);
			createdIDRef.current = data?.id ?? null;
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		if (createdIDRef.current !== null) {
			pushHistory(classYearHubDetailPageInfo.to(createdIDRef.current));
		}
	}, [pushHistory]);

	return (
		<Main>
			<Heading level={1}>New Class Year</Heading>

			<Column style={{ width: "fit-content" }}>
				<ClassYearForm.ControlledCreate applyCreate={applyCreate} onSuccess={onSuccess} />
			</Column>
		</Main>
	);
}
