import React from "react";
import { FieldDisplayArgs, MultiSelectField, NumberField, TextField } from "@hex-insights/forms";
import {
	AcademicYearTuitionSelect,
	StudentSelect,
	TuitionFormValues,
	useAcademicYearTuitionSelectLazyQuery,
	useStudentSelectLazyQuery,
} from "../../../../Utilities";
import { AcademicYearTuitionLink, StudentLink } from "../../../Links";
import { FutureBaseFieldProps as BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Tuition model.
 */
type FieldProps<K extends keyof TuitionFormValues.Base = keyof TuitionFormValues.Base> = BaseFieldProps<
	Pick<TuitionFormValues.Base, K>
>;

/**
 * Renders a field component for the `name` field of the Tuition model.
 */
export function Name({ formState, ...props }: FieldProps<"name">) {
	return <TextField formState={formState} name="name" {...props} />;
}

/**
 * Renders a field component for the `initialYear` field of the Tuition model.
 */
export function InitialYear({ formState, ...props }: FieldProps<"initialYear">) {
	return <NumberField formState={formState} name="initialYear" validationUnit={1} min={0} {...props} />;
}

export type AcademicYearTuitionsProps = FieldProps<"academicYearTuitionIDs"> & {
	currentAcademicYearTuitions?: AcademicYearTuitionSelect.ModelForOption[];
};

/**
 * Renders a field component for the `academicYearTuitions` edge of the Tuition model.
 */
export function AcademicYearTuitions({ formState, currentAcademicYearTuitions, ...props }: AcademicYearTuitionsProps) {
	const [loadOptions, { loading, data }] = useAcademicYearTuitionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.academicYearTuitionIDs) {
			loadOptions();
		}
	}, [formState.formEditing.academicYearTuitionIDs, loadOptions]);
	const options = React.useMemo(
		() =>
			AcademicYearTuitionSelect.toMultiOptions(data?.academicYearTuitionConnection.edges, currentAcademicYearTuitions),
		[data, currentAcademicYearTuitions],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="academicYearTuitionIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayAcademicYearTuitionInstance}
			{...props}
		/>
	);
}

function displayAcademicYearTuitionInstance({ value: id, formattedValue }: FieldDisplayArgs<string>) {
	return <AcademicYearTuitionLink instance={{ id }}>{formattedValue}</AcademicYearTuitionLink>;
}

export type StudentsProps = FieldProps<"studentIDs"> & {
	currentStudents?: StudentSelect.ModelForOption[];
};

/**
 * Renders a field component for the `students` edge of the Tuition model.
 */
export function Students({ formState, currentStudents, ...props }: StudentsProps) {
	const [loadOptions, { loading, data }] = useStudentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.studentIDs) {
			loadOptions();
		}
	}, [formState.formEditing.studentIDs, loadOptions]);
	const options = React.useMemo(
		() => StudentSelect.toMultiOptions(data?.studentConnection.edges, currentStudents),
		[data, currentStudents],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="studentIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayStudentInstance}
			{...props}
		/>
	);
}

function displayStudentInstance({ value: id, formattedValue }: FieldDisplayArgs<string>) {
	return <StudentLink instance={{ id }}>{formattedValue}</StudentLink>;
}
