import { Route, useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { useTermDetailQuery } from "@hex-insights/verita.shared";
import { Main, PageRouteSwitch } from "../../../Components";
import { TermSetupDetailHomeRoomSectionsPage } from "./HomeRoomSections";
import { termSetupDetailHomeRoomSectionsPageInfo } from "./HomeRoomSections/pageinfo";
import { TermSetupDetailOverviewPage } from "./OverviewPage";
import { termSetupDetailOverviewPageInfo } from "./OverviewPage/pageinfo";
import { termSetupDetailPageInfo } from "./pageinfo";

export function TermSetupDetailPage() {
	const { termID } = useRouteParams<{ termID: string }>();

	const { loading, data, error } = useTermDetailQuery({ variables: { id: termID } });
	const term = data?.term;
	useActivePageRegistration(termSetupDetailPageInfo, !!term ? termSetupDetailPageInfo.title(term) : "");

	if (loading) {
		return <Main>Loading...</Main>;
	}

	if (error || !term) {
		return <Main>There was a problem loading this term.</Main>;
	}

	return (
		<PageRouteSwitch>
			<Route {...termSetupDetailOverviewPageInfo.routeProps}>
				<TermSetupDetailOverviewPage />
			</Route>
			<Route {...termSetupDetailHomeRoomSectionsPageInfo.routeProps}>
				<TermSetupDetailHomeRoomSectionsPage />
			</Route>
		</PageRouteSwitch>
	);
}
