import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { EnrollmentApplicationParentOrderFormValues } from "../../../../Utilities";

export type EnrollmentApplicationParentOrderFormControllerProps = {
	formState: FormState<EnrollmentApplicationParentOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the EnrollmentApplicationParent model.
 */
export function EnrollmentApplicationParentOrderFormController({
	formState,
}: EnrollmentApplicationParentOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={EnrollmentApplicationParentOrderFormValues.fieldOptions}
			fieldTypeMapping={EnrollmentApplicationParentOrderFormValues.fieldTypeMapping}
		/>
	);
}
