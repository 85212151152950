import { IndexForms } from "@hex-insights/app-modules";
import { AcademicYearFilterFormValues } from "../../../../Utilities";

export type AcademicYearSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the AcademicYear model.
 */
export function AcademicYearSearchForm(props: AcademicYearSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type AcademicYearFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, AcademicYearFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the AcademicYear model.
 */
export function AcademicYearFilterFormController(props: AcademicYearFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={AcademicYearFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `<Filter>`
