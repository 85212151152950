import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	Campus,
	CampusCreateInput,
	CampusCreateMutation,
	CampusDeleteMutation,
	CampusDetailDocument,
	CampusDetailQuery,
	CampusDetailQueryVariables,
	CampusUpdateInput,
	CampusUpdateMutation,
	Employee,
	graphTypeNames,
	Room,
	useCampusCreateMutation,
	useCampusDeleteMutation,
	useCampusUpdateMutation,
} from "../../GraphQL";
import { CampusFormConversion } from "../ModelFormConversion";
import { CampusFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the Campus model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Campus model.
 */
export function useCreate() {
	const [createCampus] = useCampusCreateMutation();

	return React.useCallback(
		async (formValues: CampusFormValues.Create) => {
			const input = CampusFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createCampus({ variables: { input }, update: updateCache });

			return { data: data?.createCampus ?? null, errors: errors ?? null };
		},
		[createCampus],
	);
}

/**
 * Returns an `update` function for the Campus model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Campus model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Campus["id"]) {
	const [updateCampus] = useCampusUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<CampusFormValues.Detail>, initialFormValues: CampusFormValues.Detail) => {
			const input = CampusFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateCampus({ variables: { id, input }, update: updateCache });

			return { data: data?.updateCampus ?? null, errors: errors ?? null };
		},
		[updateCampus, id],
	);
}

/**
 * Returns a `del` function for the Campus model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Campus["id"]) {
	const [deleteCampus] = useCampusDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteCampus({ variables: { id }, update: updateCache });

		return { data: data?.deleteCampus ?? false, errors: errors ?? null };
	}, [deleteCampus, id]);
}

function getUpdateCacheForCreate(input: CampusCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<CampusCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createCampus;

		cache.writeQuery<CampusDetailQuery, CampusDetailQueryVariables>({
			query: CampusDetailDocument,
			data: { campus: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.employeeIDs) {
			for (let i = 0; i < input.employeeIDs.length; i++) {
				addToCampusesOfEmployeeCache(cache, input.employeeIDs[i], createdObject);
			}
		}

		if (input.roomIDs) {
			for (let i = 0; i < input.roomIDs.length; i++) {
				addToCampusOfRoomCache(cache, input.roomIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "campusConnection" });
	};
}

function getUpdateCacheForUpdate(input: CampusUpdateInput, _initialFormValues: CampusFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<CampusUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateCampus;

		if (input.addEmployeeIDs) {
			for (let i = 0; i < input.addEmployeeIDs.length; i++) {
				addToCampusesOfEmployeeCache(cache, input.addEmployeeIDs[i], updatedObject);
			}
		}
		if (input.removeEmployeeIDs) {
			for (let i = 0; i < input.removeEmployeeIDs.length; i++) {
				removeFromCampusesOfEmployeeCache(cache, input.removeEmployeeIDs[i], updatedObject);
			}
		}

		if (input.addRoomIDs) {
			for (let i = 0; i < input.addRoomIDs.length; i++) {
				addToCampusOfRoomCache(cache, input.addRoomIDs[i], updatedObject);
			}
		}
		if (input.removeRoomIDs) {
			for (let i = 0; i < input.removeRoomIDs.length; i++) {
				removeFromCampusOfRoomCache(cache, input.removeRoomIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: Campus["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<CampusDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteCampus) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Campus }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "campusConnection" });
		cache.gc();
	};
}

function addToCampusesOfEmployeeCache(cache: ApolloCache<any>, targetID: Employee["id"], object: Pick<Campus, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Employee }),
		fields: {
			campuses: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromCampusesOfEmployeeCache(
	cache: ApolloCache<any>,
	targetID: Employee["id"],
	object: Pick<Campus, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Employee }),
		fields: {
			campuses: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToCampusOfRoomCache(cache: ApolloCache<any>, targetID: Room["id"], object: Pick<Campus, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Room }),
		fields: {
			campus: () => objectRef,
			campusID: () => object.id,
		},
	});
}

function removeFromCampusOfRoomCache(cache: ApolloCache<any>, targetID: Room["id"], _object: Pick<Campus, "id">) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Room }),
		fields: {
			campus: () => null,
			campusID: () => null,
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Campus, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment CampusRef on Campus {
				id
			}
		`,
		data: object,
	});
}
