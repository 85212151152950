import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { LoginRecord, LoginRecordFormat, LoginRecordNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<LoginRecord, "id"> & LoginRecordNameFragment;

export type LoginRecordLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given LoginRecord instance.
 */
export function LoginRecordLink(props: LoginRecordLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="LoginRecord" format={LoginRecordFormat.name} {...props} />;
}
