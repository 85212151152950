import React from "react";
import { ApolloCache, FetchResult } from "@apollo/client";
import {
	EnrollmentApplicationSubmission,
	EnrollmentApplicationSubmissionUpdateInput,
	EnrollmentApplicationSubmissionUpdateMutation,
	useEnrollmentApplicationSubmissionUpdateMutation,
} from "../../GraphQL";
import { EnrollmentApplicationSubmissionFormConversion } from "../ModelFormConversion";
import { EnrollmentApplicationSubmissionFormValues } from "../ModelFormValues";

/**
 * Returns an `update` function for the EnrollmentApplicationSubmission model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the EnrollmentApplicationSubmission model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: EnrollmentApplicationSubmission["id"]) {
	const [updateEnrollmentApplicationSubmission] = useEnrollmentApplicationSubmissionUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<EnrollmentApplicationSubmissionFormValues.Detail>,
			initialFormValues: EnrollmentApplicationSubmissionFormValues.Detail,
		) => {
			const input = EnrollmentApplicationSubmissionFormConversion.toGQLUpdateInput(
				changedFormValues,
				initialFormValues,
			);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateEnrollmentApplicationSubmission({
				variables: { id, input },
				update: updateCache,
			});

			return { data: data?.updateEnrollmentApplicationSubmission ?? null, errors: errors ?? null };
		},
		[updateEnrollmentApplicationSubmission, id],
	);
}

function getUpdateCacheForUpdate(
	_input: EnrollmentApplicationSubmissionUpdateInput,
	_initialFormValues: EnrollmentApplicationSubmissionFormValues.Detail,
) {
	return (
		_cache: ApolloCache<any>,
		result: Omit<
			FetchResult<EnrollmentApplicationSubmissionUpdateMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}
	};
}

// function toCacheRef(cache: ApolloCache<any>, object: Pick<EnrollmentApplicationSubmission, "id">) {
//     return cache.writeFragment({
//         fragment: gql`
//             fragment EnrollmentApplicationSubmissionRef on EnrollmentApplicationSubmission {
//                 id
//             }
//         `,
//         data: object,
//     });
// }
