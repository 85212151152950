import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import { AcademicYearTuitionForm, useAcademicYearTuitionDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { academicYearTuitionHubDetailPageInfo } from "./pageinfo";

export function AcademicYearTuitionHubDetailPage() {
	const { academicYearTuitionID } = academicYearTuitionHubDetailPageInfo.useParams();

	const { loading, data, error } = useAcademicYearTuitionDetailQuery({ variables: { id: academicYearTuitionID } });
	const academicYearTuition = data?.academicYearTuition;
	useActivePageRegistration(
		academicYearTuitionHubDetailPageInfo,
		!!academicYearTuition ? academicYearTuitionHubDetailPageInfo.title(academicYearTuition) : "",
	);

	const { syncID, synchronize } = useSync();

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading academic year tuition</If>
				<Else>
					{!!academicYearTuition && (
						<React.Fragment>
							<Heading.H1>{academicYearTuition.id}</Heading.H1>

							<AcademicYearTuitionForm.ControlledDetail
								key={syncID}
								academicYearTuition={academicYearTuition}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
