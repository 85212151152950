import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { InvoiceFilterInput, InvoiceOrder, useInvoicePaginationQuery } from "../../../../Utilities";

export type InvoicePaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: InvoiceFilterInput | InvoiceFilterInput[] | null;
	order: InvoiceOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the Invoice model.
 */
export function InvoicePaginationForm({ formState, filters, order, setPaginationInfo }: InvoicePaginationFormProps) {
	const { loading, data } = useInvoicePaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.invoiceConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
