import { PhoneNumber } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the phoneNumber field of the PhoneNumber model.
 */
export function phoneNumber(value: PhoneNumber["phoneNumber"]): string {
	return toString(value);
}

/**
 * Formats the phoneType field of the PhoneNumber model.
 */
export function phoneType(value: PhoneNumber["phoneType"] | null): string {
	return toString(value);
}
