import React from "react";
import { Conditional, Else, Grid, Heading, If, Row, Section, toLocalDateString, useSync } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	EmployeeForm,
	EmployeeFormValues,
	EmployeeMutation,
	PersonIcon,
	Tile,
	useEmployeeDetailQuery,
	useStudentListQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { studentHubDetailPageInfo } from "../../Student/DetailPage/pageinfo";
import { employeeHubDetailPageInfo, EmployeeHubDetailPageRouteParams } from "./pageinfo";

function EmployeeHubDetailPageWrapper() {
	// Manages identity of the EmployeeHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <EmployeeHubDetailPage key={id} />;
}

export { EmployeeHubDetailPageWrapper as EmployeeHubDetailPage };

function EmployeeHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useEmployeeDetailQuery({ variables: { id } });
	const employee = data?.employee;
	useActivePageRegistration(employeeHubDetailPageInfo, !!employee ? employeeHubDetailPageInfo.title(employee) : "");

	const update = EmployeeMutation.useUpdateWithPerson(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<EmployeeFormValues.DetailWithPerson>,
			initialFormValues: EmployeeFormValues.DetailWithPerson,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading employee</If>
				<Else>
					{!!employee && (
						<React.Fragment>
							<Row
								justify="spaced-start"
								horizontalSpacing="1.5rem"
								align="center"
								style={{ margin: "var(--heading--level-1---margin)" }}
							>
								<PersonIcon person={employee.person} imageSize="7rem" />
								<Heading level={1} noMargin>
									{employee.person.name}
								</Heading>
							</Row>

							<Row justify="spaced-start">
								<Tile>
									<Tile.Body>
										<EmployeeForm.ControlledDetailWithPerson
											key={syncID}
											employee={employee}
											applyUpdate={applyUpdate}
											onSuccess={synchronize}
										/>
									</Tile.Body>
								</Tile>

								<Tile style={{ flexGrow: 1, width: "100%", overflow: "hidden" }}>
									<Tile.Body>
										<StudentsSection />
									</Tile.Body>
								</Tile>
							</Row>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}

function StudentsSection() {
	const { id } = useRouteParams<EmployeeHubDetailPageRouteParams>();
	const today = toLocalDateString(new Date());
	const { loading, data } = useStudentListQuery({
		variables: {
			filters: [
				{
					homeRoomSectionStudentEnrollments: [
						{
							homeRoomSection: [
								{
									homeRoomSectionTeacherEnrollments: [{ teacherIDEQ: id }],
									term: [{ startDateLTE: today, endDateGTE: today }],
								},
							],
						},
					],
				},
				{
					courseSectionStudentEnrollments: [
						{
							courseSection: [
								{
									courseSectionTeacherEnrollments: [{ teacherIDEQ: id }],
									term: [{ startDateLTE: today, endDateGTE: today }],
								},
							],
						},
					],
				},
			],
		},
	});

	return (
		<Section>
			<Section.Header>
				<Heading level={3} noMargin>
					Students
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Grid columns={5} gap="1rem">
							{data?.studentConnection.edges.map((e) => (
								<InternalLink
									key={e.node.id}
									to={studentHubDetailPageInfo.to(e.node.id)}
									className="link--no-text-decoration"
								>
									<PersonIcon person={e.node.person} withName withTooltipPreview studentID={e.node.id} />
								</InternalLink>
							))}
						</Grid>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}
