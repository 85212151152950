import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { UserGroupFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the UserGroup model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => UserGroupFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(initialFormValues, UserGroupFilterFormValues.typeFilterObject);
}
