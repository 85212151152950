import { IndexForms } from "@hex-insights/app-modules";
import { DocumentResourceFolderFilterFormValues } from "../../../../Utilities";

export type DocumentResourceFolderSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the DocumentResourceFolder model.
 */
export function DocumentResourceFolderSearchForm(props: DocumentResourceFolderSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type DocumentResourceFolderFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, DocumentResourceFolderFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the DocumentResourceFolder model.
 */
export function DocumentResourceFolderFilterFormController(props: DocumentResourceFolderFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={DocumentResourceFolderFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `DocumentResourceFolderFilterForm`
