import React from "react";
import {
	FieldDisplayArgs,
	FileField,
	FormType,
	NumberField,
	RadioButtonsInput,
	RadioField,
	SelectField,
	TextField,
} from "@hex-insights/forms";
import { NoteAttachmentFormValues, NoteSelect, useNoteSelectLazyQuery } from "../../../../Utilities";
import { NoteLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the NoteAttachment model.
 */
type FieldProps<K extends keyof NoteAttachmentFormValues.Base = keyof NoteAttachmentFormValues.Base> = BaseFieldProps<
	Pick<NoteAttachmentFormValues.Base, K>
>;

/**
 * Generic props for fields of the NoteAttachment model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof NoteAttachmentFormValues.Detail = keyof NoteAttachmentFormValues.Detail> =
	BaseFieldProps<Pick<NoteAttachmentFormValues.Detail, K>>;

/**
 * Renders a field component for the `order` field of the NoteAttachment model.
 */
export function Order({ formState, formType = FormType.Update }: FieldProps<"order">) {
	return (
		<NumberField formState={formState} name="order" optional={FormType.isCreate(formType)} validationUnit={1} min={0} />
	);
}

const isFileOptions = [
	{ label: "File", value: true },
	{ label: "Link", value: false },
];

/**
 * Renders a field component for the `isFile` field of the NoteAttachment model.
 */
export function IsFile({ formState }: FieldProps<"isFile">) {
	return (
		<RadioField
			formState={formState}
			name="isFile"
			label="Resource Type"
			options={isFileOptions}
			noClear
			Input={RadioButtonsInput}
		/>
	);
}

/**
 * Renders a field component for the `file` field of the NoteAttachment model.
 */
export function File({ formState }: FieldProps<"file">) {
	return <FileField formState={formState} name="file" optional />;
}

/**
 * Renders a field component for the `linkURL` field of the NoteAttachment model.
 */
export function LinkURL({ formState }: FieldProps<"linkURL">) {
	return <TextField formState={formState} name="linkURL" optional />;
}

/**
 * Renders a field component for the `url` field of the NoteAttachment model.
 */
export function URL({ formState }: DetailFieldProps<"url">) {
	return <TextField formState={formState} name="url" />;
}

export type NoteProps = FieldProps<"noteID"> & {
	currentNote?: NoteSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `note` edge of the NoteAttachment model.
 */
export function Note({ formState, currentNote }: NoteProps) {
	const [loadOptions, { loading, data }] = useNoteSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.noteID) {
			loadOptions();
		}
	}, [formState.formEditing.noteID, loadOptions]);
	const options = React.useMemo(
		() => NoteSelect.toOptions(data?.noteConnection.edges, currentNote),
		[data, currentNote],
	);

	return (
		<SelectField
			formState={formState}
			name="noteID"
			isLoading={loading}
			options={options}
			display={displayNote}
			blankValue={null}
		/>
	);
}

function displayNote({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <NoteLink instance={{ id }}>{formattedValue}</NoteLink>;
}
