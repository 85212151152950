import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { ExpenseBudgetOrderFormValues } from "../../../../Utilities";

export type ExpenseBudgetOrderFormControllerProps = {
	formState: FormState<ExpenseBudgetOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the ExpenseBudget model.
 */
export function ExpenseBudgetOrderFormController({ formState }: ExpenseBudgetOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={ExpenseBudgetOrderFormValues.fieldOptions}
			fieldTypeMapping={ExpenseBudgetOrderFormValues.fieldTypeMapping}
		/>
	);
}
