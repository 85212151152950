import { IndexForms } from "@hex-insights/app-modules";
import { CourseOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the Course model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		CourseOrderFormValues.initial,
		CourseOrderFormValues.fieldValues,
	);
}
