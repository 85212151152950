import React from "react";
import { Heading } from "@hex-insights/core";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { NoteForm, NoteFormValues, NoteMutation } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { noteHubDetailPageInfo } from "../DetailPage/pageinfo";
import { noteHubCreatePageInfo } from "./pageinfo";

export function NoteHubCreatePage() {
	useActivePageRegistration(noteHubCreatePageInfo);

	const create = NoteMutation.useCreate();
	const createdIDRef = React.useRef<string | null>(null);
	const { push: pushHistory } = useHistory();

	const applyCreate = React.useCallback(
		async (formValues: NoteFormValues.Create) => {
			const { data, errors } = await create(formValues);
			createdIDRef.current = data?.id ?? null;
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		if (createdIDRef.current !== null) {
			pushHistory(noteHubDetailPageInfo.to(createdIDRef.current));
		}
	}, [pushHistory]);

	return (
		<Main>
			<Heading level={1}>New Note</Heading>

			<NoteForm.ControlledCreate applyCreate={applyCreate} onSuccess={onSuccess} />
		</Main>
	);
}
