import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { ExpenseCategory, ExpenseCategoryFormat, ExpenseCategoryNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<ExpenseCategory, "id"> & ExpenseCategoryNameFragment;

export type ExpenseCategoryLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given ExpenseCategory instance.
 */
export function ExpenseCategoryLink(props: ExpenseCategoryLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="ExpenseCategory" format={ExpenseCategoryFormat.name} {...props} />;
}
