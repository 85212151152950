import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { TrustedDeviceOrderFormValues } from "../../../../Utilities";

export type TrustedDeviceOrderFormControllerProps = {
	formState: FormState<TrustedDeviceOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the TrustedDevice model.
 */
export function TrustedDeviceOrderFormController({ formState }: TrustedDeviceOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={TrustedDeviceOrderFormValues.fieldOptions}
			fieldTypeMapping={TrustedDeviceOrderFormValues.fieldTypeMapping}
		/>
	);
}
