// Code generated by @hex-insights/frontend-generator; DO NOT EDIT.
import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../Components";
import { AcademicYearHub } from "./AcademicYear";
import { academicYearHubPageInfo } from "./AcademicYear/pageinfo";
import { AcademicYearTuitionHub } from "./AcademicYearTuition";
import { academicYearTuitionHubPageInfo } from "./AcademicYearTuition/pageinfo";
import { AddressHub } from "./Address";
import { addressHubPageInfo } from "./Address/pageinfo";
import { BillingSetupSubmissionHub } from "./BillingSetupSubmission";
import { billingSetupSubmissionHubPageInfo } from "./BillingSetupSubmission/pageinfo";
import { BusHub } from "./Bus";
import { busHubPageInfo } from "./Bus/pageinfo";
import { BusPlanHub } from "./BusPlan";
import { busPlanHubPageInfo } from "./BusPlan/pageinfo";
import { CalendarEventHub } from "./CalendarEvent";
import { calendarEventHubPageInfo } from "./CalendarEvent/pageinfo";
import { CampusHub } from "./Campus";
import { campusHubPageInfo } from "./Campus/pageinfo";
import { ClassYearHub } from "./ClassYear";
import { classYearHubPageInfo } from "./ClassYear/pageinfo";
import { ContractHub } from "./Contract";
import { contractHubPageInfo } from "./Contract/pageinfo";
import { ContractInstallmentHub } from "./ContractInstallment";
import { contractInstallmentHubPageInfo } from "./ContractInstallment/pageinfo";
import { CourseHub } from "./Course";
import { courseHubPageInfo } from "./Course/pageinfo";
import { CourseAttendanceRecordHub } from "./CourseAttendanceRecord";
import { courseAttendanceRecordHubPageInfo } from "./CourseAttendanceRecord/pageinfo";
import { CourseSectionHub } from "./CourseSection";
import { courseSectionHubPageInfo } from "./CourseSection/pageinfo";
import { CourseSectionPeriodHub } from "./CourseSectionPeriod";
import { courseSectionPeriodHubPageInfo } from "./CourseSectionPeriod/pageinfo";
import { HubsDetailRedirectPage } from "./DetailRedirectPage";
import { hubsDetailRedirectPageInfo } from "./DetailRedirectPage/pageinfo";
import { DiscountHub } from "./Discount";
import { discountHubPageInfo } from "./Discount/pageinfo";
import { DocumentResourceHub } from "./DocumentResource";
import { documentResourceHubPageInfo } from "./DocumentResource/pageinfo";
import { DocumentResourceFolderHub } from "./DocumentResourceFolder";
import { documentResourceFolderHubPageInfo } from "./DocumentResourceFolder/pageinfo";
import { DocumentResourceUserViewRecordHub } from "./DocumentResourceUserViewRecord";
import { documentResourceUserViewRecordHubPageInfo } from "./DocumentResourceUserViewRecord/pageinfo";
import { EmailAddressHub } from "./EmailAddress";
import { emailAddressHubPageInfo } from "./EmailAddress/pageinfo";
import { EmployeeHub } from "./Employee";
import { employeeHubPageInfo } from "./Employee/pageinfo";
import { EnrollmentApplicationHub } from "./EnrollmentApplication";
import { enrollmentApplicationHubPageInfo } from "./EnrollmentApplication/pageinfo";
import { EnrollmentApplicationNoteHub } from "./EnrollmentApplicationNote";
import { enrollmentApplicationNoteHubPageInfo } from "./EnrollmentApplicationNote/pageinfo";
import { EnrollmentApplicationParentHub } from "./EnrollmentApplicationParent";
import { enrollmentApplicationParentHubPageInfo } from "./EnrollmentApplicationParent/pageinfo";
import { EnrollmentApplicationStudentHub } from "./EnrollmentApplicationStudent";
import { enrollmentApplicationStudentHubPageInfo } from "./EnrollmentApplicationStudent/pageinfo";
import { EnrollmentApplicationSubmissionHub } from "./EnrollmentApplicationSubmission";
import { enrollmentApplicationSubmissionHubPageInfo } from "./EnrollmentApplicationSubmission/pageinfo";
import { EnrollmentApplicationUserViewRecordHub } from "./EnrollmentApplicationUserViewRecord";
import { enrollmentApplicationUserViewRecordHubPageInfo } from "./EnrollmentApplicationUserViewRecord/pageinfo";
import { EnrollmentInvitationHub } from "./EnrollmentInvitation";
import { enrollmentInvitationHubPageInfo } from "./EnrollmentInvitation/pageinfo";
import { ExpenseHub } from "./Expense";
import { expenseHubPageInfo } from "./Expense/pageinfo";
import { ExpenseBudgetHub } from "./ExpenseBudget";
import { expenseBudgetHubPageInfo } from "./ExpenseBudget/pageinfo";
import { ExpenseCategoryHub } from "./ExpenseCategory";
import { expenseCategoryHubPageInfo } from "./ExpenseCategory/pageinfo";
import { HomeRoomHub } from "./HomeRoom";
import { homeRoomHubPageInfo } from "./HomeRoom/pageinfo";
import { HomeRoomSectionHub } from "./HomeRoomSection";
import { homeRoomSectionHubPageInfo } from "./HomeRoomSection/pageinfo";
import { HubsIndexPage } from "./IndexPage";
import { hubsIndexPageInfo } from "./IndexPage/pageinfo";
import { InvoiceHub } from "./Invoice";
import { invoiceHubPageInfo } from "./Invoice/pageinfo";
import { InvoiceLineItemHub } from "./InvoiceLineItem";
import { invoiceLineItemHubPageInfo } from "./InvoiceLineItem/pageinfo";
import { LearningObjectiveHub } from "./LearningObjective";
import { learningObjectiveHubPageInfo } from "./LearningObjective/pageinfo";
import { LearningObjectiveMarkHub } from "./LearningObjectiveMark";
import { learningObjectiveMarkHubPageInfo } from "./LearningObjectiveMark/pageinfo";
import { LessonPlanHub } from "./LessonPlan";
import { lessonPlanHubPageInfo } from "./LessonPlan/pageinfo";
import { LoginRecordHub } from "./LoginRecord";
import { loginRecordHubPageInfo } from "./LoginRecord/pageinfo";
import { MealMenuHub } from "./MealMenu";
import { mealMenuHubPageInfo } from "./MealMenu/pageinfo";
import { MealPlanHub } from "./MealPlan";
import { mealPlanHubPageInfo } from "./MealPlan/pageinfo";
import { MFAFactorHub } from "./MFAFactor";
import { mfaFactorHubPageInfo } from "./MFAFactor/pageinfo";
import { MFAInvalidOTPAttemptHub } from "./MFAInvalidOTPAttempt";
import { mfaInvalidOTPAttemptHubPageInfo } from "./MFAInvalidOTPAttempt/pageinfo";
import { NoteHub } from "./Note";
import { noteHubPageInfo } from "./Note/pageinfo";
import { NoteAttachmentHub } from "./NoteAttachment";
import { noteAttachmentHubPageInfo } from "./NoteAttachment/pageinfo";
import { NoteCommentHub } from "./NoteComment";
import { noteCommentHubPageInfo } from "./NoteComment/pageinfo";
import { NoteReactionHub } from "./NoteReaction";
import { noteReactionHubPageInfo } from "./NoteReaction/pageinfo";
import { hubsPageInfo } from "./pageinfo";
import { ParentHub } from "./Parent";
import { parentHubPageInfo } from "./Parent/pageinfo";
import { PaymentHub } from "./Payment";
import { paymentHubPageInfo } from "./Payment/pageinfo";
import { PaymentLineItemHub } from "./PaymentLineItem";
import { paymentLineItemHubPageInfo } from "./PaymentLineItem/pageinfo";
import { PermissionHub } from "./Permission";
import { permissionHubPageInfo } from "./Permission/pageinfo";
import { PermissionGroupHub } from "./PermissionGroup";
import { permissionGroupHubPageInfo } from "./PermissionGroup/pageinfo";
import { PersonHub } from "./Person";
import { personHubPageInfo } from "./Person/pageinfo";
import { PhoneNumberHub } from "./PhoneNumber";
import { phoneNumberHubPageInfo } from "./PhoneNumber/pageinfo";
import { QuotationHub } from "./Quotation";
import { quotationHubPageInfo } from "./Quotation/pageinfo";
import { RelationshipHub } from "./Relationship";
import { relationshipHubPageInfo } from "./Relationship/pageinfo";
import { ReportCardHub } from "./ReportCard";
import { reportCardHubPageInfo } from "./ReportCard/pageinfo";
import { RoomHub } from "./Room";
import { roomHubPageInfo } from "./Room/pageinfo";
import { SchoolAttendanceRecordHub } from "./SchoolAttendanceRecord";
import { schoolAttendanceRecordHubPageInfo } from "./SchoolAttendanceRecord/pageinfo";
import { SchoolStudentEnrollmentHub } from "./SchoolStudentEnrollment";
import { schoolStudentEnrollmentHubPageInfo } from "./SchoolStudentEnrollment/pageinfo";
import { ServiceHub } from "./Service";
import { serviceHubPageInfo } from "./Service/pageinfo";
import { ServiceSubscriptionHub } from "./ServiceSubscription";
import { serviceSubscriptionHubPageInfo } from "./ServiceSubscription/pageinfo";
import { SessionHub } from "./Session";
import { sessionHubPageInfo } from "./Session/pageinfo";
import { StudentHub } from "./Student";
import { studentHubPageInfo } from "./Student/pageinfo";
import { StudentDocumentHub } from "./StudentDocument";
import { studentDocumentHubPageInfo } from "./StudentDocument/pageinfo";
import { TermHub } from "./Term";
import { termHubPageInfo } from "./Term/pageinfo";
import { TrustedDeviceHub } from "./TrustedDevice";
import { trustedDeviceHubPageInfo } from "./TrustedDevice/pageinfo";
import { TuitionHub } from "./Tuition";
import { tuitionHubPageInfo } from "./Tuition/pageinfo";
import { UserHub } from "./User";
import { userHubPageInfo } from "./User/pageinfo";
import { UserGroupHub } from "./UserGroup";
import { userGroupHubPageInfo } from "./UserGroup/pageinfo";
import "./styles.css";

export function HubsPage() {
	useActivePageRegistration(hubsPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...hubsIndexPageInfo.routeProps}>
				<HubsIndexPage />
			</Route>
			<Route {...academicYearHubPageInfo.routeProps}>
				<AcademicYearHub />
			</Route>
			<Route {...academicYearTuitionHubPageInfo.routeProps}>
				<AcademicYearTuitionHub />
			</Route>
			<Route {...addressHubPageInfo.routeProps}>
				<AddressHub />
			</Route>
			<Route {...billingSetupSubmissionHubPageInfo.routeProps}>
				<BillingSetupSubmissionHub />
			</Route>
			<Route {...busHubPageInfo.routeProps}>
				<BusHub />
			</Route>
			<Route {...busPlanHubPageInfo.routeProps}>
				<BusPlanHub />
			</Route>
			<Route {...calendarEventHubPageInfo.routeProps}>
				<CalendarEventHub />
			</Route>
			<Route {...campusHubPageInfo.routeProps}>
				<CampusHub />
			</Route>
			<Route {...classYearHubPageInfo.routeProps}>
				<ClassYearHub />
			</Route>
			<Route {...contractHubPageInfo.routeProps}>
				<ContractHub />
			</Route>
			<Route {...contractInstallmentHubPageInfo.routeProps}>
				<ContractInstallmentHub />
			</Route>
			<Route {...courseHubPageInfo.routeProps}>
				<CourseHub />
			</Route>
			<Route {...courseAttendanceRecordHubPageInfo.routeProps}>
				<CourseAttendanceRecordHub />
			</Route>
			<Route {...courseSectionHubPageInfo.routeProps}>
				<CourseSectionHub />
			</Route>
			<Route {...courseSectionPeriodHubPageInfo.routeProps}>
				<CourseSectionPeriodHub />
			</Route>
			<Route {...discountHubPageInfo.routeProps}>
				<DiscountHub />
			</Route>
			<Route {...documentResourceHubPageInfo.routeProps}>
				<DocumentResourceHub />
			</Route>
			<Route {...documentResourceFolderHubPageInfo.routeProps}>
				<DocumentResourceFolderHub />
			</Route>
			<Route {...documentResourceUserViewRecordHubPageInfo.routeProps}>
				<DocumentResourceUserViewRecordHub />
			</Route>
			<Route {...emailAddressHubPageInfo.routeProps}>
				<EmailAddressHub />
			</Route>
			<Route {...employeeHubPageInfo.routeProps}>
				<EmployeeHub />
			</Route>
			<Route {...enrollmentApplicationHubPageInfo.routeProps}>
				<EnrollmentApplicationHub />
			</Route>
			<Route {...enrollmentApplicationNoteHubPageInfo.routeProps}>
				<EnrollmentApplicationNoteHub />
			</Route>
			<Route {...enrollmentApplicationParentHubPageInfo.routeProps}>
				<EnrollmentApplicationParentHub />
			</Route>
			<Route {...enrollmentApplicationStudentHubPageInfo.routeProps}>
				<EnrollmentApplicationStudentHub />
			</Route>
			<Route {...enrollmentApplicationSubmissionHubPageInfo.routeProps}>
				<EnrollmentApplicationSubmissionHub />
			</Route>
			<Route {...enrollmentApplicationUserViewRecordHubPageInfo.routeProps}>
				<EnrollmentApplicationUserViewRecordHub />
			</Route>
			<Route {...enrollmentInvitationHubPageInfo.routeProps}>
				<EnrollmentInvitationHub />
			</Route>
			<Route {...expenseHubPageInfo.routeProps}>
				<ExpenseHub />
			</Route>
			<Route {...expenseBudgetHubPageInfo.routeProps}>
				<ExpenseBudgetHub />
			</Route>
			<Route {...expenseCategoryHubPageInfo.routeProps}>
				<ExpenseCategoryHub />
			</Route>
			<Route {...homeRoomHubPageInfo.routeProps}>
				<HomeRoomHub />
			</Route>
			<Route {...homeRoomSectionHubPageInfo.routeProps}>
				<HomeRoomSectionHub />
			</Route>
			<Route {...invoiceHubPageInfo.routeProps}>
				<InvoiceHub />
			</Route>
			<Route {...invoiceLineItemHubPageInfo.routeProps}>
				<InvoiceLineItemHub />
			</Route>
			<Route {...learningObjectiveHubPageInfo.routeProps}>
				<LearningObjectiveHub />
			</Route>
			<Route {...learningObjectiveMarkHubPageInfo.routeProps}>
				<LearningObjectiveMarkHub />
			</Route>
			<Route {...lessonPlanHubPageInfo.routeProps}>
				<LessonPlanHub />
			</Route>
			<Route {...loginRecordHubPageInfo.routeProps}>
				<LoginRecordHub />
			</Route>
			<Route {...mfaFactorHubPageInfo.routeProps}>
				<MFAFactorHub />
			</Route>
			<Route {...mfaInvalidOTPAttemptHubPageInfo.routeProps}>
				<MFAInvalidOTPAttemptHub />
			</Route>
			<Route {...mealMenuHubPageInfo.routeProps}>
				<MealMenuHub />
			</Route>
			<Route {...mealPlanHubPageInfo.routeProps}>
				<MealPlanHub />
			</Route>
			<Route {...noteHubPageInfo.routeProps}>
				<NoteHub />
			</Route>
			<Route {...noteAttachmentHubPageInfo.routeProps}>
				<NoteAttachmentHub />
			</Route>
			<Route {...noteCommentHubPageInfo.routeProps}>
				<NoteCommentHub />
			</Route>
			<Route {...noteReactionHubPageInfo.routeProps}>
				<NoteReactionHub />
			</Route>
			<Route {...parentHubPageInfo.routeProps}>
				<ParentHub />
			</Route>
			<Route {...paymentHubPageInfo.routeProps}>
				<PaymentHub />
			</Route>
			<Route {...paymentLineItemHubPageInfo.routeProps}>
				<PaymentLineItemHub />
			</Route>
			<Route {...permissionHubPageInfo.routeProps}>
				<PermissionHub />
			</Route>
			<Route {...permissionGroupHubPageInfo.routeProps}>
				<PermissionGroupHub />
			</Route>
			<Route {...personHubPageInfo.routeProps}>
				<PersonHub />
			</Route>
			<Route {...phoneNumberHubPageInfo.routeProps}>
				<PhoneNumberHub />
			</Route>
			<Route {...quotationHubPageInfo.routeProps}>
				<QuotationHub />
			</Route>
			<Route {...relationshipHubPageInfo.routeProps}>
				<RelationshipHub />
			</Route>
			<Route {...reportCardHubPageInfo.routeProps}>
				<ReportCardHub />
			</Route>
			<Route {...roomHubPageInfo.routeProps}>
				<RoomHub />
			</Route>
			<Route {...schoolAttendanceRecordHubPageInfo.routeProps}>
				<SchoolAttendanceRecordHub />
			</Route>
			<Route {...schoolStudentEnrollmentHubPageInfo.routeProps}>
				<SchoolStudentEnrollmentHub />
			</Route>
			<Route {...serviceHubPageInfo.routeProps}>
				<ServiceHub />
			</Route>
			<Route {...serviceSubscriptionHubPageInfo.routeProps}>
				<ServiceSubscriptionHub />
			</Route>
			<Route {...sessionHubPageInfo.routeProps}>
				<SessionHub />
			</Route>
			<Route {...studentHubPageInfo.routeProps}>
				<StudentHub />
			</Route>
			<Route {...studentDocumentHubPageInfo.routeProps}>
				<StudentDocumentHub />
			</Route>
			<Route {...termHubPageInfo.routeProps}>
				<TermHub />
			</Route>
			<Route {...trustedDeviceHubPageInfo.routeProps}>
				<TrustedDeviceHub />
			</Route>
			<Route {...tuitionHubPageInfo.routeProps}>
				<TuitionHub />
			</Route>
			<Route {...userHubPageInfo.routeProps}>
				<UserHub />
			</Route>
			<Route {...userGroupHubPageInfo.routeProps}>
				<UserGroupHub />
			</Route>
			<Route {...hubsDetailRedirectPageInfo.routeProps}>
				<HubsDetailRedirectPage />
			</Route>
		</PageRouteSwitch>
	);
}
