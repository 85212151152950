import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	Campus,
	CourseSectionPeriod,
	graphTypeNames,
	Room,
	RoomCreateInput,
	RoomCreateMutation,
	RoomDeleteMutation,
	RoomDetailDocument,
	RoomDetailQuery,
	RoomDetailQueryVariables,
	RoomUpdateInput,
	RoomUpdateMutation,
	useRoomCreateMutation,
	useRoomDeleteMutation,
	useRoomUpdateMutation,
} from "../../GraphQL";
import { RoomFormConversion } from "../ModelFormConversion";
import { RoomFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the Room model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Room model.
 */
export function useCreate() {
	const [createRoom] = useRoomCreateMutation();

	return React.useCallback(
		async (formValues: RoomFormValues.Create) => {
			const input = RoomFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createRoom({ variables: { input }, update: updateCache });

			return { data: data?.createRoom ?? null, errors: errors ?? null };
		},
		[createRoom],
	);
}

/**
 * Returns an `update` function for the Room model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Room model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Room["id"]) {
	const [updateRoom] = useRoomUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<RoomFormValues.Detail>, initialFormValues: RoomFormValues.Detail) => {
			const input = RoomFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateRoom({ variables: { id, input }, update: updateCache });

			return { data: data?.updateRoom ?? null, errors: errors ?? null };
		},
		[updateRoom, id],
	);
}

/**
 * Returns a `del` function for the Room model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Room["id"]) {
	const [deleteRoom] = useRoomDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteRoom({ variables: { id }, update: updateCache });

		return { data: data?.deleteRoom ?? false, errors: errors ?? null };
	}, [deleteRoom, id]);
}

function getUpdateCacheForCreate(input: RoomCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<RoomCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createRoom;

		cache.writeQuery<RoomDetailQuery, RoomDetailQueryVariables>({
			query: RoomDetailDocument,
			data: { room: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.campusID) {
			addToRoomsOfCampusCache(cache, input.campusID, createdObject);
		}

		if (input.courseSectionPeriodIDs) {
			for (let i = 0; i < input.courseSectionPeriodIDs.length; i++) {
				addToRoomOfCourseSectionPeriodCache(cache, input.courseSectionPeriodIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "roomConnection" });
	};
}

function getUpdateCacheForUpdate(input: RoomUpdateInput, initialFormValues: RoomFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<RoomUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateRoom;

		if (initialFormValues.campusID && input.campusID) {
			removeFromRoomsOfCampusCache(cache, initialFormValues.campusID, updatedObject);
		}
		if (input.campusID) {
			addToRoomsOfCampusCache(cache, input.campusID, updatedObject);
		}

		if (input.addCourseSectionPeriodIDs) {
			for (let i = 0; i < input.addCourseSectionPeriodIDs.length; i++) {
				addToRoomOfCourseSectionPeriodCache(cache, input.addCourseSectionPeriodIDs[i], updatedObject);
			}
		}
		if (input.removeCourseSectionPeriodIDs) {
			for (let i = 0; i < input.removeCourseSectionPeriodIDs.length; i++) {
				removeFromRoomOfCourseSectionPeriodCache(cache, input.removeCourseSectionPeriodIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: Room["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<RoomDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteRoom) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Room }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "roomConnection" });
		cache.gc();
	};
}

function addToRoomsOfCampusCache(cache: ApolloCache<any>, targetID: Campus["id"], object: Pick<Room, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Campus }),
		fields: {
			rooms: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromRoomsOfCampusCache(cache: ApolloCache<any>, targetID: Campus["id"], object: Pick<Room, "id">) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Campus }),
		fields: {
			rooms: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToRoomOfCourseSectionPeriodCache(
	cache: ApolloCache<any>,
	targetID: CourseSectionPeriod["id"],
	object: Pick<Room, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSectionPeriod }),
		fields: {
			room: () => objectRef,
			roomID: () => object.id,
		},
	});
}

function removeFromRoomOfCourseSectionPeriodCache(
	cache: ApolloCache<any>,
	targetID: CourseSectionPeriod["id"],
	_object: Pick<Room, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSectionPeriod }),
		fields: {
			room: () => null,
			roomID: () => null,
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Room, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment RoomRef on Room {
				id
			}
		`,
		data: object,
	});
}
