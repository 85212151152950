import { newPageInfo } from "@hex-insights/router";
import { studentHubPageInfo } from "../pageinfo";

export const studentHubCreatePageInfo = newPageInfo({
	name: "New Student",
	title: "New",
	description: "Create a new Student record.",
	exact: true,
	to: studentHubPageInfo.to + "/new",
	path: studentHubPageInfo.path + "/new",
	parent: studentHubPageInfo,
});
