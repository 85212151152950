import { User, UserGroup } from "../../GraphQL";

export type Base = {
	name: UserGroup["name"];
	description: UserGroup["description"];
	image: File | null;
	color: UserGroup["color"];
	userIDs: User["id"][];
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	name: "",
	description: "",
	image: null,
	color: "",
	userIDs: [],
};
