import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { TuitionFilterInput, TuitionOrder, useTuitionPaginationQuery } from "../../../../Utilities";

export type TuitionPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: TuitionFilterInput | TuitionFilterInput[] | null;
	order: TuitionOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the Tuition model.
 */
export function TuitionPaginationForm({ formState, filters, order, setPaginationInfo }: TuitionPaginationFormProps) {
	const { loading, data } = useTuitionPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.tuitionConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
