import { NoteReaction } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the reactionType field of the NoteReaction model.
 */
export function reactionType(value: NoteReaction["reactionType"] | null): string {
	return toString(value);
}

/**
 * Formats the createdAt field of the NoteReaction model.
 */
export function createdAt(value: NoteReaction["createdAt"] | null): string {
	return toString(value);
}
