import React from "react";
import { Column, Conditional, Else, If, Row } from "@hex-insights/core";
import { Route, RouteSwitch, useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { StudentHub, StudentRecordCreateButton, useStudentDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { AcademicsLeftColumn, AcademicsMain } from "./AcademicsPage";
import { studentHubDetailAcademicsPageInfo } from "./AcademicsPage/pageinfo";
import { FamilyAndHealthLeftColumn, FamilyAndHealthMain } from "./FamilyAndHealth";
import { studentHubDetailFamilyAndHealthPageInfo } from "./FamilyAndHealth/pageinfo";
import { FinancialsLeftColumn, FinancialsMain } from "./FinancialsPage";
import { studentHubDetailFinancialsPageInfo } from "./FinancialsPage/pageinfo";
import { GeneralInfoSection, OverviewMain } from "./OverviewPage";
import { studentHubDetailOverviewPageInfo } from "./OverviewPage/pageinfo";
import { studentHubDetailPageInfo, StudentHubDetailPageRouteParams } from "./pageinfo";
import styles from "./styles.module.css";

function StudentHubDetailPageWrapper() {
	// Manages identity of the StudentHubDetailPage component for path param updates.
	const { id } = useRouteParams<StudentHubDetailPageRouteParams>();
	return <StudentHubDetailPage key={id} />;
}

export { StudentHubDetailPageWrapper as StudentHubDetailPage };

const studentDetailPageContextValue: StudentHub.Detail.PageContextValue = {
	overviewPageInfo: studentHubDetailPageInfo,
	academicsPageInfo: studentHubDetailAcademicsPageInfo,
	financialsPageInfo: studentHubDetailFinancialsPageInfo,
	familyAndHealthPageInfo: studentHubDetailFamilyAndHealthPageInfo,
};

function StudentHubDetailPage() {
	const { id } = useRouteParams<StudentHubDetailPageRouteParams>();

	const { loading, data, error } = useStudentDetailQuery({ variables: { id } });
	const student = data?.student;
	useActivePageRegistration(studentHubDetailPageInfo, !!student ? studentHubDetailPageInfo.title(student) : "");

	const navLinks = React.useMemo<[string, boolean, string][]>(() => {
		return [
			["Overview", true, studentHubDetailPageInfo.to(id)],
			["Academics", false, studentHubDetailAcademicsPageInfo.to(id)],
			["Financials", false, studentHubDetailFinancialsPageInfo.to(id)],
			["Family & Health", false, studentHubDetailFamilyAndHealthPageInfo.to(id)],
		];
	}, [id]);

	return (
		<StudentHub.Detail.PageContext.Provider value={studentDetailPageContextValue}>
			<Main>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<If condition={error !== undefined}>Error loading student</If>
					<Else>
						{!!student && (
							<Row
								justify="spaced-start"
								className={styles["main-row"]}
								style={{
									height:
										"calc(100vh - var(--header---height) - 2 * var(--main---padding-top) - var(--main-row---margin-top))",
								}}
							>
								<Column justify="spaced-start" verticalSpacing="0.5rem" className={styles["left-column"]}>
									<GeneralInfoSection />

									<RouteSwitch>
										<Route {...studentHubDetailOverviewPageInfo.routeProps} />
										<Route>
											<Column className={styles["left-sub-items-column"]} style={{ flexGrow: 1 }}>
												<RouteSwitch>
													<Route {...studentHubDetailAcademicsPageInfo.routeProps}>
														<AcademicsLeftColumn />
													</Route>
													<Route {...studentHubDetailFinancialsPageInfo.routeProps}>
														<FinancialsLeftColumn />
													</Route>
													<Route {...studentHubDetailFamilyAndHealthPageInfo.routeProps}>
														<FamilyAndHealthLeftColumn />
													</Route>
												</RouteSwitch>
											</Column>
										</Route>
									</RouteSwitch>
								</Column>

								<Column justify="spaced-start" style={{ flexGrow: 1, width: "100%", minWidth: 0 }}>
									<Row justify="spaced-start" align="center" className={styles["top-row"]}>
										<StudentHub.Detail.Nav links={navLinks} />
										<StudentRecordCreateButton studentID={id} />
									</Row>

									<RouteSwitch>
										<Route {...studentHubDetailOverviewPageInfo.routeProps}>
											<OverviewMain />
										</Route>
										<Route {...studentHubDetailAcademicsPageInfo.routeProps}>
											<AcademicsMain />
										</Route>
										<Route {...studentHubDetailFinancialsPageInfo.routeProps}>
											<FinancialsMain />
										</Route>
										<Route {...studentHubDetailFamilyAndHealthPageInfo.routeProps}>
											<FamilyAndHealthMain />
										</Route>
									</RouteSwitch>
								</Column>
							</Row>
						)}
					</Else>
				</Conditional>
			</Main>
		</StudentHub.Detail.PageContext.Provider>
	);
}
