import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { CourseFilterInput, CourseOrder, useCoursePaginationQuery } from "../../../../Utilities";

export type CoursePaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: CourseFilterInput | CourseFilterInput[] | null;
	order: CourseOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the Course model.
 */
export function CoursePaginationForm({ formState, filters, order, setPaginationInfo }: CoursePaginationFormProps) {
	const { loading, data } = useCoursePaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.courseConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
