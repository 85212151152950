import { newPageInfo } from "@hex-insights/router";
import { expenseCategoryHubPageInfo } from "../pageinfo";

export const expenseCategoryHubIndexPageInfo = newPageInfo({
	name: "Expense Categories Index",
	title: "",
	description: "Index of Expense Categories.",
	exact: true,
	to: expenseCategoryHubPageInfo.to,
	path: expenseCategoryHubPageInfo.path,
	parent: expenseCategoryHubPageInfo,
});
