import React from "react";
import { ApolloCache, FetchResult } from "@apollo/client";
import {
	graphTypeNames,
	MealMenu,
	MealMenuCreateInput,
	MealMenuCreateMutation,
	MealMenuDeleteMutation,
	MealMenuDetailDocument,
	MealMenuDetailQuery,
	MealMenuDetailQueryVariables,
	MealMenuUpdateInput,
	MealMenuUpdateMutation,
	useMealMenuCreateMutation,
	useMealMenuDeleteMutation,
	useMealMenuUpdateMutation,
} from "../../GraphQL";
import { MealMenuFormConversion } from "../ModelFormConversion";
import { MealMenuFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the MealMenu model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the MealMenu model.
 */
export function useCreate() {
	const [createMealMenu] = useMealMenuCreateMutation();

	return React.useCallback(
		async (formValues: MealMenuFormValues.Create) => {
			const input = MealMenuFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createMealMenu({ variables: { input }, update: updateCache });

			return { data: data?.createMealMenu ?? null, errors: errors ?? null };
		},
		[createMealMenu],
	);
}

/**
 * Returns an `update` function for the MealMenu model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the MealMenu model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: MealMenu["id"]) {
	const [updateMealMenu] = useMealMenuUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<MealMenuFormValues.Detail>, initialFormValues: MealMenuFormValues.Detail) => {
			const input = MealMenuFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateMealMenu({ variables: { id, input }, update: updateCache });

			return { data: data?.updateMealMenu ?? null, errors: errors ?? null };
		},
		[updateMealMenu, id],
	);
}

/**
 * Returns a `del` function for the MealMenu model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: MealMenu["id"]) {
	const [deleteMealMenu] = useMealMenuDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteMealMenu({ variables: { id }, update: updateCache });

		return { data: data?.deleteMealMenu ?? false, errors: errors ?? null };
	}, [deleteMealMenu, id]);
}

export function getUpdateCacheForCreate(_input: MealMenuCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<MealMenuCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createMealMenu;

		cache.writeQuery<MealMenuDetailQuery, MealMenuDetailQueryVariables>({
			query: MealMenuDetailDocument,
			data: { mealMenu: createdObject },
			variables: { id: createdObject.id },
		});

		cache.evict({ id: "ROOT_QUERY", fieldName: "mealMenuConnection" });
	};
}

function getUpdateCacheForUpdate(_input: MealMenuUpdateInput, _initialFormValues: MealMenuFormValues.Detail) {
	return (
		_cache: ApolloCache<any>,
		result: Omit<FetchResult<MealMenuUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}
	};
}

function getUpdateCacheForDelete(id: MealMenu["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<MealMenuDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteMealMenu) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.MealMenu }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "mealMenuConnection" });
		cache.gc();
	};
}

// function toCacheRef(cache: ApolloCache<any>, object: Pick<MealMenu, "id">) {
//     return cache.writeFragment({
//         fragment: gql`
//             fragment MealMenuRef on MealMenu {
//                 id
//             }
//         `,
//         data: object,
//     });
// }
