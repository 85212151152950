import { newPageInfo } from "@hex-insights/router";
import { sessionHubPageInfo } from "../pageinfo";

export const sessionHubIndexPageInfo = newPageInfo({
	name: "Sessions Index",
	title: "",
	description: "Index of Sessions.",
	exact: true,
	to: sessionHubPageInfo.to,
	path: sessionHubPageInfo.path,
	parent: sessionHubPageInfo,
});
