import { newPageInfo } from "@hex-insights/router";
import { resourcesPageInfo, ResourcesPageRouteParams } from "../pageinfo";

export type ResourcesSearchPageRouteParams = ResourcesPageRouteParams;

export const resourcesSearchPageInfo = newPageInfo({
	name: "Search Resources",
	title: "Search",
	description: "Search for documents and folders.",
	exact: true,
	to: resourcesPageInfo.to + "/search",
	path: resourcesPageInfo.path + "/search",
	parent: resourcesPageInfo,
});
