import { newPageInfo } from "@hex-insights/router";
import { termSetupDetailPageInfo } from "../pageinfo";

export const termSetupDetailOverviewPageInfo = newPageInfo({
	name: "Term Setup Overview",
	title: "",
	description: "Overview of setup for a term.",
	exact: true,
	noSearch: true,
	to: termSetupDetailPageInfo.to,
	path: termSetupDetailPageInfo.path,
	parent: termSetupDetailPageInfo,
});
