import { newPageInfo } from "@hex-insights/router";
import { HomeRoomSection } from "@hex-insights/verita.shared";
import { homeRoomSectionHubPageInfo } from "../pageinfo";

export type HomeRoomSectionHubDetailPageRouteParams = {
	id: string;
};

type HomeRoomSectionForRouteTitle = Pick<HomeRoomSection, "name">;

export const homeRoomSectionHubDetailPageInfo = newPageInfo({
	name: "Home Room Section Detail",
	title: (e: HomeRoomSectionForRouteTitle) => e.name,
	description: "Detail page for an homeRoomSection.",
	exact: false,
	isLeaf: true,
	to: (id: HomeRoomSection["id"]) => homeRoomSectionHubPageInfo.to + `/${id}`,
	path: homeRoomSectionHubPageInfo.path + "/:id(\\d+)",
	parent: homeRoomSectionHubPageInfo,
});
