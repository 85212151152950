import { newPageInfo } from "@hex-insights/router";
import { noteHubPageInfo } from "../pageinfo";

export const noteHubIndexPageInfo = newPageInfo({
	name: "Notes Index",
	title: "",
	description: "Index of Notes.",
	exact: true,
	to: noteHubPageInfo.to,
	path: noteHubPageInfo.path,
	parent: noteHubPageInfo,
});
