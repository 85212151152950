import { newPageInfo } from "@hex-insights/router";
import { StudentDocument } from "@hex-insights/verita.shared";
import { studentDocumentHubPageInfo } from "../pageinfo";

type StudentDocumentForRouteTitle = Pick<StudentDocument, "name">;

export const studentDocumentHubDetailPageInfo = newPageInfo({
	name: "Student Document Detail",
	title: (e: StudentDocumentForRouteTitle) => e.name,
	description: "Detail page for an studentDocument.",
	exact: false,
	isLeaf: true,
	to: (id: StudentDocument["id"]) => studentDocumentHubPageInfo.to + `/${id}`,
	path: studentDocumentHubPageInfo.path + "/:id(\\d+)",
	parent: studentDocumentHubPageInfo,
});
