import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { RelationshipHubCreatePage } from "./CreatePage";
import { relationshipHubCreatePageInfo } from "./CreatePage/pageinfo";
import { RelationshipHubDetailPage } from "./DetailPage";
import { relationshipHubDetailPageInfo } from "./DetailPage/pageinfo";
import { RelationshipHubIndexPage } from "./IndexPage";
import { relationshipHubIndexPageInfo } from "./IndexPage/pageinfo";
import { relationshipHubPageInfo } from "./pageinfo";

export function RelationshipHub() {
	useActivePageRegistration(relationshipHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...relationshipHubIndexPageInfo.routeProps}>
				<RelationshipHubIndexPage />
			</Route>
			<Route {...relationshipHubCreatePageInfo.routeProps}>
				<RelationshipHubCreatePage />
			</Route>
			<Route {...relationshipHubDetailPageInfo.routeProps}>
				<RelationshipHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
