import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	DocumentResource,
	DocumentResourceFolder,
	graphTypeNames,
	Note,
	PermissionGroup,
	Person,
	User,
	UserCreateInput,
	UserCreateMutation,
	UserDeleteMutation,
	UserDetailDocument,
	UserDetailQuery,
	UserDetailQueryVariables,
	UserGroup,
	UserUpdateInput,
	UserUpdateMutation,
	useUserCreateMutation,
	useUserDeleteMutation,
	useUserUpdateMutation,
} from "../../GraphQL";
import { UserFormConversion } from "../ModelFormConversion";
import { UserFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the User model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the User model.
 */
export function useCreate() {
	const [createUser] = useUserCreateMutation();

	return React.useCallback(
		async (formValues: UserFormValues.Create) => {
			const input = UserFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createUser({ variables: { input }, update: updateCache });

			return { data: data?.createUser ?? null, errors: errors ?? null };
		},
		[createUser],
	);
}

/**
 * Returns an `update` function for the User model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the User model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: User["id"]) {
	const [updateUser] = useUserUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<UserFormValues.Detail>, initialFormValues: UserFormValues.Detail) => {
			const input = UserFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateUser({ variables: { id, input }, update: updateCache });

			return { data: data?.updateUser ?? null, errors: errors ?? null };
		},
		[updateUser, id],
	);
}

/**
 * Returns a `del` function for the User model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: User["id"]) {
	const [deleteUser] = useUserDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteUser({ variables: { id }, update: updateCache });

		return { data: data?.deleteUser ?? false, errors: errors ?? null };
	}, [deleteUser, id]);
}

function getUpdateCacheForCreate(input: UserCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<UserCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createUser;

		cache.writeQuery<UserDetailQuery, UserDetailQueryVariables>({
			query: UserDetailDocument,
			data: { user: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.bookmarkedDocumentResourceIDs) {
			for (let i = 0; i < input.bookmarkedDocumentResourceIDs.length; i++) {
				addToBookmarkingUsersOfDocumentResourceCache(cache, input.bookmarkedDocumentResourceIDs[i], createdObject);
			}
		}

		if (input.permissionGroupIDs) {
			for (let i = 0; i < input.permissionGroupIDs.length; i++) {
				addToUsersOfPermissionGroupCache(cache, input.permissionGroupIDs[i], createdObject);
			}
		}

		if (input.personID) {
			addToUserOfPersonCache(cache, input.personID, createdObject);
		}

		if (input.sharedDocumentResourceFolderIDs) {
			for (let i = 0; i < input.sharedDocumentResourceFolderIDs.length; i++) {
				addToSharingUsersOfDocumentResourceFolderCache(cache, input.sharedDocumentResourceFolderIDs[i], createdObject);
			}
		}

		if (input.sharedNoteIDs) {
			for (let i = 0; i < input.sharedNoteIDs.length; i++) {
				addToSharingUsersOfNoteCache(cache, input.sharedNoteIDs[i], createdObject);
			}
		}

		if (input.userGroupIDs) {
			for (let i = 0; i < input.userGroupIDs.length; i++) {
				addToUsersOfUserGroupCache(cache, input.userGroupIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "userConnection" });
	};
}

function getUpdateCacheForUpdate(input: UserUpdateInput, initialFormValues: UserFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<UserUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateUser;

		if (input.addBookmarkedDocumentResourceIDs) {
			for (let i = 0; i < input.addBookmarkedDocumentResourceIDs.length; i++) {
				addToBookmarkingUsersOfDocumentResourceCache(cache, input.addBookmarkedDocumentResourceIDs[i], updatedObject);
			}
		}
		if (input.removeBookmarkedDocumentResourceIDs) {
			for (let i = 0; i < input.removeBookmarkedDocumentResourceIDs.length; i++) {
				removeFromBookmarkingUsersOfDocumentResourceCache(
					cache,
					input.removeBookmarkedDocumentResourceIDs[i],
					updatedObject,
				);
			}
		}

		if (input.addPermissionGroupIDs) {
			for (let i = 0; i < input.addPermissionGroupIDs.length; i++) {
				addToUsersOfPermissionGroupCache(cache, input.addPermissionGroupIDs[i], updatedObject);
			}
		}
		if (input.removePermissionGroupIDs) {
			for (let i = 0; i < input.removePermissionGroupIDs.length; i++) {
				removeFromUsersOfPermissionGroupCache(cache, input.removePermissionGroupIDs[i], updatedObject);
			}
		}

		if (initialFormValues.personID && (input.personID || input.clearPerson)) {
			removeFromUserOfPersonCache(cache, initialFormValues.personID, updatedObject);
		}
		if (input.personID) {
			addToUserOfPersonCache(cache, input.personID, updatedObject);
		}

		if (input.addSharedDocumentResourceFolderIDs) {
			for (let i = 0; i < input.addSharedDocumentResourceFolderIDs.length; i++) {
				addToSharingUsersOfDocumentResourceFolderCache(
					cache,
					input.addSharedDocumentResourceFolderIDs[i],
					updatedObject,
				);
			}
		}
		if (input.removeSharedDocumentResourceFolderIDs) {
			for (let i = 0; i < input.removeSharedDocumentResourceFolderIDs.length; i++) {
				removeFromSharingUsersOfDocumentResourceFolderCache(
					cache,
					input.removeSharedDocumentResourceFolderIDs[i],
					updatedObject,
				);
			}
		}

		if (input.addSharedNoteIDs) {
			for (let i = 0; i < input.addSharedNoteIDs.length; i++) {
				addToSharingUsersOfNoteCache(cache, input.addSharedNoteIDs[i], updatedObject);
			}
		}
		if (input.removeSharedNoteIDs) {
			for (let i = 0; i < input.removeSharedNoteIDs.length; i++) {
				removeFromSharingUsersOfNoteCache(cache, input.removeSharedNoteIDs[i], updatedObject);
			}
		}

		if (input.addUserGroupIDs) {
			for (let i = 0; i < input.addUserGroupIDs.length; i++) {
				addToUsersOfUserGroupCache(cache, input.addUserGroupIDs[i], updatedObject);
			}
		}
		if (input.removeUserGroupIDs) {
			for (let i = 0; i < input.removeUserGroupIDs.length; i++) {
				removeFromUsersOfUserGroupCache(cache, input.removeUserGroupIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: User["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<UserDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteUser) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.User }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "userConnection" });
		cache.gc();
	};
}

function addToBookmarkingUsersOfDocumentResourceCache(
	cache: ApolloCache<any>,
	targetID: DocumentResource["id"],
	object: Pick<User, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.DocumentResource }),
		fields: {
			bookmarkingUsers: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromBookmarkingUsersOfDocumentResourceCache(
	cache: ApolloCache<any>,
	targetID: DocumentResource["id"],
	object: Pick<User, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.DocumentResource }),
		fields: {
			bookmarkingUsers: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToUsersOfPermissionGroupCache(
	cache: ApolloCache<any>,
	targetID: PermissionGroup["id"],
	object: Pick<User, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.PermissionGroup }),
		fields: {
			users: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromUsersOfPermissionGroupCache(
	cache: ApolloCache<any>,
	targetID: PermissionGroup["id"],
	object: Pick<User, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.PermissionGroup }),
		fields: {
			users: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToUserOfPersonCache(cache: ApolloCache<any>, targetID: Person["id"], object: Pick<User, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Person }),
		fields: {
			user: () => objectRef,
			userID: () => object.id,
		},
	});
}

function removeFromUserOfPersonCache(cache: ApolloCache<any>, targetID: Person["id"], _object: Pick<User, "id">) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Person }),
		fields: {
			user: () => null,
			userID: () => null,
		},
	});
}

function addToSharingUsersOfDocumentResourceFolderCache(
	cache: ApolloCache<any>,
	targetID: DocumentResourceFolder["id"],
	object: Pick<User, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.DocumentResourceFolder }),
		fields: {
			sharingUsers: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromSharingUsersOfDocumentResourceFolderCache(
	cache: ApolloCache<any>,
	targetID: DocumentResourceFolder["id"],
	object: Pick<User, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.DocumentResourceFolder }),
		fields: {
			sharingUsers: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToSharingUsersOfNoteCache(cache: ApolloCache<any>, targetID: Note["id"], object: Pick<User, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Note }),
		fields: {
			sharingUsers: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromSharingUsersOfNoteCache(cache: ApolloCache<any>, targetID: Note["id"], object: Pick<User, "id">) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Note }),
		fields: {
			sharingUsers: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToUsersOfUserGroupCache(cache: ApolloCache<any>, targetID: UserGroup["id"], object: Pick<User, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.UserGroup }),
		fields: {
			users: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromUsersOfUserGroupCache(cache: ApolloCache<any>, targetID: UserGroup["id"], object: Pick<User, "id">) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.UserGroup }),
		fields: {
			users: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<User, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment UserRef on User {
				id
			}
		`,
		data: object,
	});
}
