import { newPageInfo } from "@hex-insights/router";
import { homeRoomHubPageInfo } from "../pageinfo";

export const homeRoomHubIndexPageInfo = newPageInfo({
	name: "Home Rooms Index",
	title: "",
	description: "Index of Home Rooms.",
	exact: true,
	to: homeRoomHubPageInfo.to,
	path: homeRoomHubPageInfo.path,
	parent: homeRoomHubPageInfo,
});
