import { newPageInfo } from "@hex-insights/router";
import { Bus } from "@hex-insights/verita.shared";
import { busHubPageInfo } from "../pageinfo";

type BusForRouteTitle = Pick<Bus, "name">;

export const busHubDetailPageInfo = newPageInfo({
	name: "Bus Detail",
	title: (e: BusForRouteTitle) => e.name,
	description: "Detail page for an bus.",
	exact: false,
	isLeaf: true,
	to: (id: Bus["id"]) => busHubPageInfo.to + `/${id}`,
	path: busHubPageInfo.path + "/:id(\\d+)",
	parent: busHubPageInfo,
});
