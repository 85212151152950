import { Course, LearningObjective, LearningObjectiveMark } from "../../GraphQL";

export type Base = {
	category: LearningObjective["category"];
	description: LearningObjective["description"];
	isActive: LearningObjective["isActive"];
	courseID: Course["id"] | null;
	learningObjectiveMarkIDs: LearningObjectiveMark["id"][];
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	category: "",
	description: "",
	isActive: false,
	courseID: null,
	learningObjectiveMarkIDs: [],
};
