import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { StudentFilterInput, StudentOrder, useStudentPaginationQuery } from "../../../../Utilities";

export type StudentPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: StudentFilterInput | StudentFilterInput[] | null;
	order: StudentOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the Student model.
 */
export function StudentPaginationForm({ formState, filters, order, setPaginationInfo }: StudentPaginationFormProps) {
	const { loading, data } = useStudentPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.studentConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
