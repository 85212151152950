import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { ReportCardFilterInput, ReportCardOrder, useReportCardPaginationQuery } from "../../../../Utilities";

export type ReportCardPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: ReportCardFilterInput | ReportCardFilterInput[] | null;
	order: ReportCardOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the ReportCard model.
 */
export function ReportCardPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: ReportCardPaginationFormProps) {
	const { loading, data } = useReportCardPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.reportCardConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
