import { newPageInfo } from "@hex-insights/router";
import { EmailAddress } from "@hex-insights/verita.shared";
import { emailAddressHubPageInfo } from "../pageinfo";

type EmailAddressForRouteTitle = Pick<EmailAddress, "email">;

export const emailAddressHubDetailPageInfo = newPageInfo({
	name: "Email Address Detail",
	title: (e: EmailAddressForRouteTitle) => e.email,
	description: "Detail page for an emailAddress.",
	exact: false,
	isLeaf: true,
	to: (id: EmailAddress["id"]) => emailAddressHubPageInfo.to + `/${id}`,
	path: emailAddressHubPageInfo.path + "/:id(\\d+)",
	parent: emailAddressHubPageInfo,
});
