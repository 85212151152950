import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { MFAInvalidOTPAttemptHubDetailPage } from "./DetailPage";
import { mfaInvalidOTPAttemptHubDetailPageInfo } from "./DetailPage/pageinfo";
import { MFAInvalidOTPAttemptHubIndexPage } from "./IndexPage";
import { mfaInvalidOTPAttemptHubIndexPageInfo } from "./IndexPage/pageinfo";
import { mfaInvalidOTPAttemptHubPageInfo } from "./pageinfo";

export function MFAInvalidOTPAttemptHub() {
	useActivePageRegistration(mfaInvalidOTPAttemptHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...mfaInvalidOTPAttemptHubIndexPageInfo.routeProps}>
				<MFAInvalidOTPAttemptHubIndexPage />
			</Route>
			<Route {...mfaInvalidOTPAttemptHubDetailPageInfo.routeProps}>
				<MFAInvalidOTPAttemptHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
