import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	Bus,
	BusCreateInput,
	BusCreateMutation,
	BusDeleteMutation,
	BusDetailDocument,
	BusDetailQuery,
	BusDetailQueryVariables,
	BusPlan,
	BusUpdateInput,
	BusUpdateMutation,
	graphTypeNames,
	useBusCreateMutation,
	useBusDeleteMutation,
	useBusUpdateMutation,
} from "../../GraphQL";
import { BusFormConversion } from "../ModelFormConversion";
import { BusFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the Bus model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Bus model.
 */
export function useCreate() {
	const [createBus] = useBusCreateMutation();

	return React.useCallback(
		async (formValues: BusFormValues.Create) => {
			const input = BusFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createBus({ variables: { input }, update: updateCache });

			return { data: data?.createBus ?? null, errors: errors ?? null };
		},
		[createBus],
	);
}

/**
 * Returns an `update` function for the Bus model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Bus model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Bus["id"]) {
	const [updateBus] = useBusUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<BusFormValues.Detail>, initialFormValues: BusFormValues.Detail) => {
			const input = BusFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateBus({ variables: { id, input }, update: updateCache });

			return { data: data?.updateBus ?? null, errors: errors ?? null };
		},
		[updateBus, id],
	);
}

/**
 * Returns a `del` function for the Bus model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Bus["id"]) {
	const [deleteBus] = useBusDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteBus({ variables: { id }, update: updateCache });

		return { data: data?.deleteBus ?? false, errors: errors ?? null };
	}, [deleteBus, id]);
}

function getUpdateCacheForCreate(input: BusCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<BusCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createBus;

		cache.writeQuery<BusDetailQuery, BusDetailQueryVariables>({
			query: BusDetailDocument,
			data: { bus: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.dropoffBusPlanIDs) {
			for (let i = 0; i < input.dropoffBusPlanIDs.length; i++) {
				addToDropoffBusOfBusPlanCache(cache, input.dropoffBusPlanIDs[i], createdObject);
			}
		}

		if (input.pickupBusPlanIDs) {
			for (let i = 0; i < input.pickupBusPlanIDs.length; i++) {
				addToPickupBusOfBusPlanCache(cache, input.pickupBusPlanIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "busConnection" });
	};
}

function getUpdateCacheForUpdate(input: BusUpdateInput, _initialFormValues: BusFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<BusUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateBus;

		if (input.addDropoffBusPlanIDs) {
			for (let i = 0; i < input.addDropoffBusPlanIDs.length; i++) {
				addToDropoffBusOfBusPlanCache(cache, input.addDropoffBusPlanIDs[i], updatedObject);
			}
		}
		if (input.removeDropoffBusPlanIDs) {
			for (let i = 0; i < input.removeDropoffBusPlanIDs.length; i++) {
				removeFromDropoffBusOfBusPlanCache(cache, input.removeDropoffBusPlanIDs[i], updatedObject);
			}
		}

		if (input.addPickupBusPlanIDs) {
			for (let i = 0; i < input.addPickupBusPlanIDs.length; i++) {
				addToPickupBusOfBusPlanCache(cache, input.addPickupBusPlanIDs[i], updatedObject);
			}
		}
		if (input.removePickupBusPlanIDs) {
			for (let i = 0; i < input.removePickupBusPlanIDs.length; i++) {
				removeFromPickupBusOfBusPlanCache(cache, input.removePickupBusPlanIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: Bus["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<BusDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteBus) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Bus }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "busConnection" });
		cache.gc();
	};
}

function addToDropoffBusOfBusPlanCache(cache: ApolloCache<any>, targetID: BusPlan["id"], object: Pick<Bus, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.BusPlan }),
		fields: {
			dropoffBus: () => objectRef,
			dropoffBusID: () => object.id,
		},
	});
}

function removeFromDropoffBusOfBusPlanCache(
	cache: ApolloCache<any>,
	targetID: BusPlan["id"],
	_object: Pick<Bus, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.BusPlan }),
		fields: {
			dropoffBus: () => null,
			dropoffBusID: () => null,
		},
	});
}

function addToPickupBusOfBusPlanCache(cache: ApolloCache<any>, targetID: BusPlan["id"], object: Pick<Bus, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.BusPlan }),
		fields: {
			pickupBus: () => objectRef,
			pickupBusID: () => object.id,
		},
	});
}

function removeFromPickupBusOfBusPlanCache(cache: ApolloCache<any>, targetID: BusPlan["id"], _object: Pick<Bus, "id">) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.BusPlan }),
		fields: {
			pickupBus: () => null,
			pickupBusID: () => null,
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Bus, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment BusRef on Bus {
				id
			}
		`,
		data: object,
	});
}
