import { IndexForms } from "@hex-insights/app-modules";
import { ExpenseFilterFormValues } from "../../../../Utilities";

export type ExpenseSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the Expense model.
 */
export function ExpenseSearchForm(props: ExpenseSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type ExpenseFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, ExpenseFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Expense model.
 */
export function ExpenseFilterFormController(props: ExpenseFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={ExpenseFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `ExpenseFilterForm`
