import { newPageInfo } from "@hex-insights/router";
import { termHubPageInfo } from "../pageinfo";

export const termHubCreatePageInfo = newPageInfo({
	name: "New Term",
	title: "New",
	description: "Create a new Term record.",
	exact: true,
	to: termHubPageInfo.to + "/new",
	path: termHubPageInfo.path + "/new",
	parent: termHubPageInfo,
});
