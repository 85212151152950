import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const enrollmentApplicationUserViewRecordHubPageInfo = newPageInfo({
	title: "Enrollment Application User View Records",
	description: "Pages related to the Enrollment Application User View Record model.",
	exact: false,
	to: hubsPageInfo.to + "/enrollment-application-user-view-records",
	path: hubsPageInfo.path + "/enrollment-application-user-view-records",
	parent: hubsPageInfo,
});
