import { IndexForms } from "@hex-insights/app-modules";
import { CourseFilterFormValues } from "../../../../Utilities";

export type CourseSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the Course model.
 */
export function CourseSearchForm(props: CourseSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type CourseFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, CourseFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Course model.
 */
export function CourseFilterFormController(props: CourseFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={CourseFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `CourseFilterForm`
