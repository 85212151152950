import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { AddressForm, AddressFormValues, AddressMutation, useAddressDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { addressHubDetailPageInfo } from "./pageinfo";

function AddressHubDetailPageWrapper() {
	// Manages identity of the AddressHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <AddressHubDetailPage key={id} />;
}

export { AddressHubDetailPageWrapper as AddressHubDetailPage };

function AddressHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useAddressDetailQuery({ variables: { id } });
	const address = data?.address;
	useActivePageRegistration(addressHubDetailPageInfo, !!address ? addressHubDetailPageInfo.title(address) : "");

	const update = AddressMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<AddressFormValues.Detail>, initialFormValues: AddressFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading address</If>
				<Else>
					{!!address && (
						<React.Fragment>
							<Heading level={1}>{address.shortAddress}</Heading>

							<AddressForm.ControlledDetail
								key={syncID}
								address={address}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
