import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import {
	DocumentResourceFolderCreateInput,
	DocumentResourceFolderDetailQuery,
	DocumentResourceFolderUpdateInput,
} from "../../GraphQL";
import { DocumentResourceFolderFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the DocumentResourceFolder model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(
	formValues: DocumentResourceFolderFormValues.Create,
): DocumentResourceFolderCreateInput {
	const input: NullPartial<DocumentResourceFolderCreateInput> = { ...formValues };
	return input as DocumentResourceFolderCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the DocumentResourceFolder model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<DocumentResourceFolderFormValues.Detail>,
	initialFormValues: Partial<DocumentResourceFolderFormValues.Detail>,
): DocumentResourceFolderUpdateInput {
	const inputFormValues = omitKeys(formValues, [
		"createdAt",
		"authorID",
		"childDocumentResourceFolderIDs",
		"courseSectionIDs",
		"courseIDs",
		"documentResourceIDs",
		"homeRoomSectionIDs",
		"homeRoomIDs",
		"sharingUserGroupIDs",
		"sharingUserIDs",
	]);
	const input: DocumentResourceFolderUpdateInput = { ...inputFormValues };

	if (formValues.parentDocumentResourceFolderID === null) {
		input.parentDocumentResourceFolderID = null;
		input.clearParentDocumentResourceFolder = true;
	}

	if (formValues.childDocumentResourceFolderIDs !== undefined) {
		const { added, removed } = getArrayDiff(
			initialFormValues.childDocumentResourceFolderIDs ?? [],
			formValues.childDocumentResourceFolderIDs,
		);
		input.addChildDocumentResourceFolderIDs = added;
		input.removeChildDocumentResourceFolderIDs = removed;
	}

	if (formValues.courseSectionIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.courseSectionIDs ?? [], formValues.courseSectionIDs);
		input.addCourseSectionIDs = added;
		input.removeCourseSectionIDs = removed;
	}

	if (formValues.courseIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.courseIDs ?? [], formValues.courseIDs);
		input.addCourseIDs = added;
		input.removeCourseIDs = removed;
	}

	if (formValues.documentResourceIDs !== undefined) {
		const { added, removed } = getArrayDiff(
			initialFormValues.documentResourceIDs ?? [],
			formValues.documentResourceIDs,
		);
		input.addDocumentResourceIDs = added;
		input.removeDocumentResourceIDs = removed;
	}

	if (formValues.homeRoomSectionIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.homeRoomSectionIDs ?? [], formValues.homeRoomSectionIDs);
		input.addHomeRoomSectionIDs = added;
		input.removeHomeRoomSectionIDs = removed;
	}

	if (formValues.homeRoomIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.homeRoomIDs ?? [], formValues.homeRoomIDs);
		input.addHomeRoomIDs = added;
		input.removeHomeRoomIDs = removed;
	}

	if (formValues.sharingUserGroupIDs !== undefined) {
		const { added, removed } = getArrayDiff(
			initialFormValues.sharingUserGroupIDs ?? [],
			formValues.sharingUserGroupIDs,
		);
		input.addSharingUserGroupIDs = added;
		input.removeSharingUserGroupIDs = removed;
	}

	if (formValues.sharingUserIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.sharingUserIDs ?? [], formValues.sharingUserIDs);
		input.addSharingUserIDs = added;
		input.removeSharingUserIDs = removed;
	}

	return input;
}

/**
 * Converts a DocumentResourceFolder instance to its corresponding detail form values.
 *
 * @param documentResourceFolder The instance to convert.
 */
export function toFormValues(
	documentResourceFolder: DocumentResourceFolderDetailQuery["documentResourceFolder"],
): DocumentResourceFolderFormValues.Detail {
	return {
		name: documentResourceFolder.name,
		description: documentResourceFolder.description,
		category: documentResourceFolder.category,
		isPublic: documentResourceFolder.isPublic,
		createdAt: documentResourceFolder.createdAt,
		authorID: documentResourceFolder.author?.id ?? null,
		childDocumentResourceFolderIDs: documentResourceFolder.childDocumentResourceFolders.map((e) => e.id),
		courseSectionIDs: documentResourceFolder.courseSections.map((e) => e.id),
		courseIDs: documentResourceFolder.courses.map((e) => e.id),
		documentResourceIDs: documentResourceFolder.documentResources.map((e) => e.id),
		homeRoomSectionIDs: documentResourceFolder.homeRoomSections.map((e) => e.id),
		homeRoomIDs: documentResourceFolder.homeRooms.map((e) => e.id),
		parentDocumentResourceFolderID: documentResourceFolder.parentDocumentResourceFolder?.id ?? null,
		sharingUserGroupIDs: documentResourceFolder.sharingUserGroups.map((e) => e.id),
		sharingUserIDs: documentResourceFolder.sharingUsers.map((e) => e.id),
	};
}
