import { newPageInfo } from "@hex-insights/router";
import { CourseSection } from "@hex-insights/verita.shared";
import { courseSectionHubPageInfo } from "../pageinfo";

export type CourseSectionHubDetailPageRouteParams = {
	id: string;
};

type CourseSectionForRouteTitle = Pick<CourseSection, "name">;

export const courseSectionHubDetailPageInfo = newPageInfo({
	name: "Course Section Detail",
	title: (e: CourseSectionForRouteTitle) => e.name,
	description: "Detail page for an courseSection.",
	exact: false,
	isLeaf: true,
	to: (id: CourseSection["id"]) => courseSectionHubPageInfo.to + `/${id}`,
	path: courseSectionHubPageInfo.path + "/:id(\\d+)",
	parent: courseSectionHubPageInfo,
});
