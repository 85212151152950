import React from "react";
import {
	Button,
	Column,
	compareObjects,
	compareWithQueryDesc,
	Conditional,
	Else,
	Grid,
	Heading,
	Icon,
	If,
	List,
	makeClassName,
	matchQuery,
	Modal,
	ModalProps,
	RequiredKeys,
	Row,
	Section,
	StyleProps,
	toGrammaticalNumber,
	toLocalDateString,
} from "@hex-insights/core";
import { TextField, useFormState, ValidationDisplayPolicy } from "@hex-insights/forms";
import { InternalLink } from "@hex-insights/router";
import {
	Campus,
	ControlledStudentListModal,
	EmployeeFilterInput,
	EmployeeIndexQuery,
	HomeRoomSectionFilterInput,
	HomeRoomSectionIndexQuery,
	HR,
	PersonIcon,
	StackedScrollRow,
	StaticURLs,
	StudentFilterInput,
	Tile,
	useCampusIndexQuery,
	useCampusObjectCountsLazyQuery,
	useEmployeeIndexQuery,
	useHomeRoomSectionIndexQuery,
	useHomeRoomSectionPeopleListQuery,
} from "@hex-insights/verita.shared";
import { campusHubDetailPageInfo } from "../../Pages/Hubs/Campus/DetailPage/pageinfo";
import { employeeHubDetailPageInfo } from "../../Pages/Hubs/Employee/DetailPage/pageinfo";
import { homeRoomSectionHubDetailPageInfo } from "../../Pages/Hubs/HomeRoomSection/DetailPage/pageinfo";
import { studentHubDetailPageInfo } from "../../Pages/Hubs/Student/DetailPage/pageinfo";
import styles from "./styles.module.css";

export function CampusOverviews() {
	const width = "calc(50% - var(--row--spaced---horizontal-spacing, var(--general--spaced---horizontal-spacing)) / 2)";

	return (
		<Row justify="spaced-start">
			<CampusOverview
				name="Early Years Campus"
				imageURL={StaticURLs.Images.veritaEarlyYearsCampusBlob}
				style={{ width }}
			/>
			<CampusOverview
				name="Primary School Campus"
				imageURL={StaticURLs.Images.veritaPrimarySchoolCampusBlob}
				style={{ width }}
			/>
		</Row>
	);
}

type CampusOverviewProps = {
	name: string;
	imageURL: string;
} & Partial<StyleProps>;

function CampusOverview({ name, imageURL, style }: CampusOverviewProps) {
	const { loading: loadingCampus, data: campusData } = useCampusIndexQuery({
		variables: { filters: { nameEQ: name } },
	});
	const edges = campusData?.campusConnection.edges ?? [];
	const campusID = edges.length > 0 ? edges[0].node.id : null;

	const [loadCounts, { loading: loadingCounts, data: countsData }] = useCampusObjectCountsLazyQuery({
		variables: {
			studentFilters: getStudentFiltersForCampus(campusID ?? ""),
			homeRoomSectionFilters: getHomeRoomSectionFiltersForCampus(campusID ?? ""),
			employeeFilters: getEmployeeFiltersForCampus(campusID ?? ""),
		},
	});
	React.useEffect(() => {
		if (campusID !== null) {
			loadCounts();
		}
	}, [campusID, loadCounts]);

	if (!loadingCampus && campusID === null) {
		return null;
	}

	return (
		<Tile className={styles["campus-overview"]} style={style}>
			<Tile.Header style={{ position: "relative", zIndex: 1 }}>
				<InternalLink
					to={campusID ? campusHubDetailPageInfo.to(campusID) : "#"}
					className={makeClassName(styles["campus-overview__heading-link"], "link--no-text-decoration")}
				>
					<Heading level={2} noMargin className={styles["campus-overview__heading"]}>
						<Row justify="spaced-start" horizontalSpacing="0.5rem" align="center">
							{name}
							<Icon.ArrowRight className={styles["campus-overview__heading__icon"]} style={{ display: "block" }} />
						</Row>
					</Heading>
				</InternalLink>
			</Tile.Header>
			<Tile.Body style={{ paddingTop: "0.5rem" }}>
				<Column style={{ position: "relative", zIndex: 1 }}>
					<List className={styles["campus-overview__stats"]}>
						<List.Item className={styles["campus-overview__stat"]}>
							<StudentsPreviewer
								campusID={campusID ?? ""}
								campusName={name}
								isLoading={loadingCampus || loadingCounts}
								numStudents={countsData?.studentConnection.totalCount ?? 0}
							/>
						</List.Item>
						<List.Item className={styles["campus-overview__stat"]}>
							<HomeRoomSectionsPreviewer
								campusID={campusID ?? ""}
								campusName={name}
								isLoading={loadingCampus || loadingCounts}
								numHomeRoomSections={countsData?.homeRoomSectionConnection.totalCount ?? 0}
							/>
						</List.Item>
						<List.Item className={styles["campus-overview__stat"]}>
							<EmployeesPreviewer
								campusID={campusID ?? ""}
								campusName={name}
								isLoading={loadingCampus || loadingCounts}
								numEmployees={countsData?.employeeConnection.totalCount ?? 0}
							/>
						</List.Item>
					</List>
				</Column>

				<div className={styles["campus-image-container"]}>
					<img src={imageURL} alt={name} />
				</div>
			</Tile.Body>
		</Tile>
	);
}

type StudentsPreviewerProps = {
	campusID: Campus["id"];
	campusName: string;
	isLoading: boolean;
	numStudents: number;
};

function StudentsPreviewer({ campusID, campusName, isLoading, numStudents }: StudentsPreviewerProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<Button onClick={toggleIsModalOpen} className={styles["campus-overview__stat__button"]}>
				<Row justify="spaced-start" horizontalSpacing="0.3rem" align="center">
					<span className={styles["campus-overview__stat__number"]}>{isLoading ? "..." : numStudents}</span>{" "}
					{toGrammaticalNumber("Student", numStudents)}{" "}
					<div title="Click to view" className={styles["campus-overview__stat__button__show-icon-container"]}>
						<Icon.Eye
							size="1rem"
							className={styles["campus-overview__stat__button__show-icon"]}
							style={{ display: "block" }}
						/>
					</div>
				</Row>
			</Button>

			<Modal.If condition={isModalOpen}>
				<ControlledStudentListModal
					title={`${campusName} Students`}
					initialFilterFormValues={React.useMemo(() => ({ campusIDs: [campusID] }), [campusID])}
					noCampusFilters
					homeRoomFilterInputs={React.useMemo(
						() => ({ homeRoomSections: [{ room: [{ campusIDEQ: campusID }] }] }),
						[campusID],
					)}
					onClose={toggleIsModalOpen}
				/>
			</Modal.If>
		</React.Fragment>
	);
}

function getStudentFiltersForCampus(campusID: Campus["id"]): StudentFilterInput | StudentFilterInput[] {
	const today = toLocalDateString(new Date());

	return [
		{
			homeRoomSectionStudentEnrollments: [
				{
					homeRoomSection: [
						{
							room: [{ campus: [{ idEQ: campusID }] }],
							term: [{ startDateLTE: today, endDateGTE: today }],
						},
					],
				},
			],
		},
		{
			courseSectionStudentEnrollments: [
				{
					courseSection: [
						{
							courseSectionPeriods: [
								{
									room: [{ campus: [{ idEQ: campusID }] }],
								},
							],
							term: [{ startDateLTE: today, endDateGTE: today }],
						},
					],
				},
			],
		},
	];
}

type HomeRoomSectionsPreviewerProps = {
	campusID: Campus["id"];
	campusName: string;
	isLoading: boolean;
	numHomeRoomSections: number;
};

function HomeRoomSectionsPreviewer({
	campusID,
	campusName,
	isLoading,
	numHomeRoomSections,
}: HomeRoomSectionsPreviewerProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<Button onClick={toggleIsModalOpen} className={styles["campus-overview__stat__button"]}>
				<Row justify="spaced-start" horizontalSpacing="0.3rem" align="center">
					<span className={styles["campus-overview__stat__number"]}>{isLoading ? "..." : numHomeRoomSections}</span>{" "}
					{toGrammaticalNumber("Home Room", numHomeRoomSections)}{" "}
					<div title="Click to view" className={styles["campus-overview__stat__button__show-icon-container"]}>
						<Icon.Eye
							size="1rem"
							className={styles["campus-overview__stat__button__show-icon"]}
							style={{ display: "block" }}
						/>
					</div>
				</Row>
			</Button>

			<Modal.If condition={isModalOpen}>
				<ControlledHomeRoomSectionsListModal
					title={`${campusName} Home Rooms`}
					campusID={campusID}
					onClose={toggleIsModalOpen}
				/>
			</Modal.If>
		</React.Fragment>
	);
}

type ControlledHomeRoomSectionsListModalProps = {
	title: React.ReactNode;
	campusID: Campus["id"];
} & Pick<HomeRoomSectionsListModalProps, "ifRef" | "onClose">;

function ControlledHomeRoomSectionsListModal({
	title,
	campusID,
	ifRef,
	onClose,
}: ControlledHomeRoomSectionsListModalProps) {
	const filters = getHomeRoomSectionFiltersForCampus(campusID);
	const { loading, data } = useHomeRoomSectionIndexQuery({ variables: { filters } });

	return (
		<HomeRoomSectionsListModal
			title={title}
			isLoading={loading}
			edges={data?.homeRoomSectionConnection.edges}
			filters={filters}
			ifRef={ifRef}
			onClose={onClose}
		/>
	);
}

function getHomeRoomSectionFiltersForCampus(
	campusID: Campus["id"],
): HomeRoomSectionFilterInput | HomeRoomSectionFilterInput[] {
	const today = toLocalDateString(new Date());

	return {
		room: [{ campus: [{ idEQ: campusID }] }],
		term: [{ startDateLTE: today, endDateGTE: today }],
	};
}

type SearchFormValues = {
	search: string;
};

const initialSearchFormValues: SearchFormValues = {
	search: "",
};

export type HomeRoomSectionsListModalProps = {
	title: React.ReactNode;
	isLoading?: boolean;
	edges?: HomeRoomSectionIndexQuery["homeRoomSectionConnection"]["edges"];
	filters?: HomeRoomSectionFilterInput | HomeRoomSectionFilterInput[];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

export function HomeRoomSectionsListModal({
	title,
	isLoading = false,
	edges,
	filters,
	ifRef,
	onClose,
}: HomeRoomSectionsListModalProps) {
	const { loading: loadingWithPeople, data: dataWithPeople } = useHomeRoomSectionPeopleListQuery({
		variables: { filters },
	});

	const searchFormState = useFormState({
		initialFormValues: initialSearchFormValues,
		validationDisplayPolicy: ValidationDisplayPolicy.none,
	});

	const { search: searchValue } = searchFormState.formValues;
	const filteredHomeRoomSectionEdges = React.useMemo(() => {
		const filter = matchQuery(searchValue);
		const compare = compareObjects<{ node: { name: string } }>((e) => e.node.name, compareWithQueryDesc(searchValue));

		if (dataWithPeople) {
			return dataWithPeople.homeRoomSectionConnection.edges.filter((e) => filter(e.node.name)).sort(compare);
		}
		if (!edges) {
			return [];
		}
		return edges
			.map((e) => ({
				...e,
				node: { ...e.node, homeRoomSectionTeacherEnrollments: [], homeRoomSectionStudentEnrollments: [] },
			}))
			.filter((e) => filter(e.node.name))
			.sort(compare);
	}, [searchValue, dataWithPeople, edges]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} style={{ width: "80vw", maxWidth: "none", height: "90vh" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					{title}
				</Heading>
			</Modal.Header>
			<Modal.Body style={{ height: "100%", overflow: "hidden" }}>
				<Column justify="spaced-start" style={{ height: "100%", overflow: "hidden" }}>
					<Row>
						<TextField formState={searchFormState} name="search" />
					</Row>

					<Section style={{ height: "100%", overflow: "hidden" }}>
						<Section.Body style={{ height: "100%", overflow: "scroll" }}>
							<Conditional>
								<If condition={isLoading}>Loading...</If>
								<Else>
									<Grid columns={2} gap="1.5rem">
										{filteredHomeRoomSectionEdges.map(({ node: homeRoomSection }) => (
											<Column justify="spaced-start" key={homeRoomSection.id} style={{ width: "100%", minWidth: 0 }}>
												<Row justify="space-between" align="flex-end" style={{ borderBottom: "1px solid #ddd" }}>
													<InternalLink
														to={homeRoomSectionHubDetailPageInfo.to(homeRoomSection.id)}
														className={makeClassName(styles["home-room-section-link"], "link--no-text-decoration")}
													>
														{homeRoomSection.name}
													</InternalLink>

													<If condition={!!dataWithPeople}>
														<span>
															{toGrammaticalNumber(
																"Student",
																homeRoomSection.homeRoomSectionStudentEnrollments.length,
																true,
															)}
														</span>
													</If>
												</Row>

												<Conditional>
													<If condition={loadingWithPeople}>Loading teachers and students...</If>
													<Else>
														<Row justify="spaced-start" align="center" style={{ width: "100%" }}>
															<If condition={homeRoomSection.homeRoomSectionTeacherEnrollments.length > 0}>
																<Row justify="spaced-start" horizontalSpacing="0.25rem">
																	{homeRoomSection.homeRoomSectionTeacherEnrollments.map(({ teacher }) => (
																		<InternalLink
																			key={teacher.id}
																			to={employeeHubDetailPageInfo.to(teacher.id)}
																			title={teacher.person.name}
																			className="link--no-text-decoration"
																		>
																			<PersonIcon person={teacher.person} imageSize="1.5rem" />
																		</InternalLink>
																	))}
																</Row>
															</If>
															<If
																condition={
																	homeRoomSection.homeRoomSectionTeacherEnrollments.length > 0 &&
																	homeRoomSection.homeRoomSectionStudentEnrollments.length > 0
																}
															>
																<HR color="#ddd" style={{ height: "1rem" }} />
															</If>
															<If condition={homeRoomSection.homeRoomSectionStudentEnrollments.length > 0}>
																<StackedScrollRow
																	justify="spaced-start"
																	horizontalSpacing="0.25rem"
																	style={{ width: "100%" }}
																>
																	{homeRoomSection.homeRoomSectionStudentEnrollments.map(({ student }) => (
																		<InternalLink
																			key={student.id}
																			to={studentHubDetailPageInfo.to(student.id)}
																			className="link--no-text-decoration"
																		>
																			<PersonIcon person={student.person} imageSize="1.5rem" />
																		</InternalLink>
																	))}
																</StackedScrollRow>
															</If>
														</Row>
													</Else>
												</Conditional>
											</Column>
										))}
									</Grid>
								</Else>
							</Conditional>
						</Section.Body>
					</Section>
				</Column>
			</Modal.Body>
		</Modal>
	);
}

type EmployeesPreviewerProps = {
	campusID: Campus["id"];
	campusName: string;
	isLoading: boolean;
	numEmployees: number;
};

function EmployeesPreviewer({ campusID, campusName, isLoading, numEmployees }: EmployeesPreviewerProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<Button onClick={toggleIsModalOpen} className={styles["campus-overview__stat__button"]}>
				<Row justify="spaced-start" horizontalSpacing="0.3rem" align="center">
					<span className={styles["campus-overview__stat__number"]}>{isLoading ? "..." : numEmployees}</span> Faculty
					&amp; Staff
					<div title="Click to view" className={styles["campus-overview__stat__button__show-icon-container"]}>
						<Icon.Eye
							size="1rem"
							className={styles["campus-overview__stat__button__show-icon"]}
							style={{ display: "block" }}
						/>
					</div>
				</Row>
			</Button>

			<Modal.If condition={isModalOpen}>
				<ControlledEmployeeListModal
					title={`${campusName} Faculty & Staff`}
					campusID={campusID}
					onClose={toggleIsModalOpen}
				/>
			</Modal.If>
		</React.Fragment>
	);
}

type ControlledEmployeeListModalProps = {
	title: React.ReactNode;
	campusID: Campus["id"];
} & Pick<EmployeeListModalProps, "ifRef" | "onClose">;

function ControlledEmployeeListModal({ title, campusID, ifRef, onClose }: ControlledEmployeeListModalProps) {
	const { loading, data } = useEmployeeIndexQuery({
		variables: { filters: getEmployeeFiltersForCampus(campusID) },
	});

	return (
		<EmployeeListModal
			title={title}
			isLoading={loading}
			edges={data?.employeeConnection.edges}
			ifRef={ifRef}
			onClose={onClose}
		/>
	);
}

function getEmployeeFiltersForCampus(campusID: Campus["id"]): EmployeeFilterInput | EmployeeFilterInput[] {
	return { campuses: [{ idEQ: campusID }], endDateIsNull: true };
}

export type EmployeeListModalProps = {
	title: React.ReactNode;
	isLoading?: boolean;
	edges: EmployeeIndexQuery["employeeConnection"]["edges"] | undefined;
} & Pick<ModalProps, "ifRef" | "onClose">;

export function EmployeeListModal({ title, isLoading = false, edges, ifRef, onClose }: EmployeeListModalProps) {
	const searchFormState = useFormState({
		initialFormValues: initialSearchFormValues,
		validationDisplayPolicy: ValidationDisplayPolicy.none,
	});

	const { search: searchValue } = searchFormState.formValues;
	const filteredEmployeeEdges = React.useMemo(() => {
		if (!edges) {
			return [];
		}

		const filter = matchQuery(searchValue);
		const compare = compareObjects<{ node: { person: { name: string } } }>(
			(e) => e.node.person.name,
			compareWithQueryDesc(searchValue),
		);
		return edges.filter((e) => filter(e.node.person.name)).sort(compare);
	}, [searchValue, edges]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} style={{ width: "80vw", maxWidth: "none", height: "90vh" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					{title}
				</Heading>
			</Modal.Header>
			<Modal.Body style={{ height: "100%", overflow: "hidden" }}>
				<Column justify="spaced-start" style={{ height: "100%", overflow: "hidden" }}>
					<Row>
						<TextField formState={searchFormState} name="search" />
					</Row>

					<Section style={{ height: "100%", overflow: "hidden" }}>
						<Section.Body style={{ height: "100%", overflow: "scroll" }}>
							<Conditional>
								<If condition={isLoading}>Loading...</If>
								<Else>
									<Grid columns={5} gap="0.5rem">
										{filteredEmployeeEdges.map((e) => (
											<InternalLink
												key={e.node.id}
												to={employeeHubDetailPageInfo.to(e.node.id)}
												className="link--no-text-decoration"
											>
												<PersonIcon person={e.node.person} withName withTooltipPreview employeeID={e.node.id} />
											</InternalLink>
										))}
									</Grid>
								</Else>
							</Conditional>
						</Section.Body>
					</Section>
				</Column>
			</Modal.Body>
		</Modal>
	);
}
