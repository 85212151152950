import React from "react";
import { count, Icon, If, StyleProps, useUpdatingRef } from "@hex-insights/core";
import { usePermissionCheck } from "@hex-insights/permissioning";
import { useHistory, useLocation } from "@hex-insights/router";
import {
	EmployeeRole,
	EmployeeWrapperContext,
	permissions,
	Header as SharedHeader,
	HeaderProps as SharedHeaderProps,
	useEnrollmentApplicationNotificationListQuery,
} from "@hex-insights/verita.shared";
import { AuthenticationContext } from "../../Contexts";
import { admissionsApplicationsPageInfo } from "../../Pages/Admissions/Applications/pageinfo";
import { admissionsEnrollmentsPageInfo } from "../../Pages/Admissions/Enrollments/pageinfo";
import { admissionsMeetingsPageInfo } from "../../Pages/Admissions/Meetings/pageinfo";
import { admissionsPageInfo } from "../../Pages/Admissions/pageinfo";
import { logoutPageInfo } from "../../Pages/Authentication/Logout/pageinfo";
import { homePageInfo } from "../../Pages/Home/pageinfo";
import { hubsPageInfo } from "../../Pages/Hubs/pageinfo";
import { resourcesPageInfo } from "../../Pages/Resources/pageinfo";
import { GlobalSearchBar } from "../GlobalSearch";
import styles from "./styles.module.css";

export function Header() {
	const hasAdmissionsPermission = usePermissionCheck({
		requiredPermissions: [permissions.EnrollmentApplication.View.Basic, permissions.EnrollmentApplication.View.Admin],
		checkMethod: "or",
	});

	const links = React.useMemo(() => {
		const links: SharedHeaderProps["links"] = [
			[<Icon.Home size="1.3rem" strokeWidth="2.5px" style={{ display: "block" }} />, "Home", homePageInfo],
			[<Icon.Folder size="1.3rem" strokeWidth="2.5px" style={{ display: "block" }} />, "Resources", resourcesPageInfo],
		];
		if (hasAdmissionsPermission) {
			links.push([<AdmissionsIcon />, "Admissions", admissionsPageInfo]);
		}
		links.push(
			[<HubsIcon size="1.3rem" strokeWidth="2.5px" style={{ display: "block" }} />, "Hubs", hubsPageInfo],
			[<Icon.LogOut size="1.3rem" strokeWidth="2.5px" style={{ display: "block" }} />, "Logout", logoutPageInfo],
		);
		return links;
	}, [hasAdmissionsPermission]);

	return <SharedHeader homePageInfo={homePageInfo} GlobalSearchBar={GlobalSearchBar} links={links} />;
}

const admissionsNotificationPollIntervalMS = 5 * 60 * 1000;

function AdmissionsIcon() {
	const { user } = React.useContext(AuthenticationContext);
	const { data } = useEnrollmentApplicationNotificationListQuery({
		variables: { userID: user?.id ?? "0" },
		pollInterval: admissionsNotificationPollIntervalMS,
	});
	const [numNotifications, setNumNotifications] = React.useState(0);

	const { pathname } = useLocation();
	const isOnAdmissionsApplicationsPage =
		pathname === admissionsApplicationsPageInfo.to.split("?")[0] ||
		pathname === admissionsMeetingsPageInfo.to.split("?")[0];
	const isOnAdmissionsApplicationsPageRef = useUpdatingRef(isOnAdmissionsApplicationsPage);

	React.useEffect(() => {
		if (!data || isOnAdmissionsApplicationsPageRef.current) {
			setNumNotifications(0);
			return;
		}
		setNumNotifications(
			count(
				data.enrollmentApplicationConnection.edges,
				({ node }) => node.enrollmentApplicationUserViewRecords.length === 0,
			),
		);
	}, [data, isOnAdmissionsApplicationsPageRef]);

	React.useEffect(() => {
		if (isOnAdmissionsApplicationsPage) {
			setNumNotifications(0);
		}
	}, [isOnAdmissionsApplicationsPage]);

	// TODO better/more general way to do this (Financials employees should be sent to Enrollments page by default)
	const { employee } = React.useContext(EmployeeWrapperContext);
	const isFinancial = employee?.role === EmployeeRole.Financial;
	const isOnAdmissionsPage = pathname.startsWith(admissionsPageInfo.to);
	const history = useHistory();
	React.useEffect(() => {
		if (isFinancial && isOnAdmissionsPage) {
			history.push(admissionsEnrollmentsPageInfo.to);
		}
	}, [isFinancial, isOnAdmissionsPage, history]);

	return (
		<div className={styles["admissions__wrapper"]}>
			<Icon.UserCheck size="1.3rem" strokeWidth="2.5px" style={{ display: "block" }} />

			<If condition={!isFinancial && numNotifications > 0}>
				<div className={styles["admissions__notification"]}>
					<span className={styles["admissions__notification__text"]}>
						{numNotifications > 9 ? "9+" : numNotifications}
					</span>
				</div>
			</If>
		</div>
	);
}

type HubsIconProps = { size: string; strokeWidth: string } & Partial<StyleProps>;

function HubsIcon({ size, strokeWidth, style }: HubsIconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 26 26"
			stroke="currentColor"
			strokeWidth={strokeWidth}
			style={{ width: size, height: size, ...style }}
			fill="none"
		>
			<g>
				<g>
					<rect x="1" y="1" width="9" height="12" rx="1" ry="1" />
					<rect x="14" y="11" width="9" height="12" rx="1" ry="1" />
					<rect x="1" y="16.5" width="9" height="6.5" rx="1" ry="1" />
					<rect x="14" y="1" width="9" height="6.5" rx="1" ry="1" />
				</g>
			</g>
		</svg>
	);
}
