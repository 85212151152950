import { newPageInfo } from "@hex-insights/router";
import { discountHubPageInfo } from "../pageinfo";

export const discountHubIndexPageInfo = newPageInfo({
	name: "Discounts Index",
	title: "",
	description: "Index of Discounts.",
	exact: true,
	to: discountHubPageInfo.to,
	path: discountHubPageInfo.path,
	parent: discountHubPageInfo,
});
