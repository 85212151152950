import React from "react";
import { ClassNameProps, Conditional, Else, If, mappedArray, Row, StyleProps } from "@hex-insights/core";
import {
	DocumentResourceIndexQuery,
	DocumentResourceOrderField,
	HorizontalScrollingContainer,
	OrderDirection,
	Tile,
	useDocumentResourceIndexQuery,
} from "@hex-insights/verita.shared";
import { AuthenticationContext } from "../../../../Contexts";
import { DocumentResourceDisplay } from "../../../../Pages/Resources/Components/DocumentResourceDisplay";

export type GeneralDashboardResourcesTileProps = Pick<ResourcesTileProps, "className" | "style">;

export function GeneralDashboardResourcesTile({ className, style }: GeneralDashboardResourcesTileProps) {
	const { user } = React.useContext(AuthenticationContext);
	const { loading, data } = useDocumentResourceIndexQuery({
		variables: {
			filters: {
				bookmarkingUsers: [{ idEQ: user?.id }],
			},
			order: { field: DocumentResourceOrderField.Name, direction: OrderDirection.Asc },
		},
	});

	return (
		<ResourcesTile
			isLoading={loading}
			edges={data?.documentResourceConnection.edges}
			className={className}
			style={style}
		/>
	);
}

export type ResourcesTileProps = {
	isLoading?: boolean;
	edges: DocumentResourceIndexQuery["documentResourceConnection"]["edges"] | undefined;
	backgroundColor?: string;
} & Partial<ClassNameProps & StyleProps>;

export function ResourcesTile({
	isLoading = false,
	edges,
	backgroundColor = "#fff",
	className,
	style,
}: ResourcesTileProps) {
	if (!isLoading && (!edges || edges.length === 0)) {
		return null;
	}

	return (
		<Tile
			className={className}
			style={{ ...style, "--section__body---padding": "0.75rem 1rem" } as React.CSSProperties}
		>
			<Tile.Body>
				<HorizontalScrollingContainer backgroundColor={backgroundColor}>
					<Row justify="spaced-start">
						<Conditional>
							<If condition={isLoading && !edges}>
								{mappedArray(12, (i) => (
									<DocumentResourceDisplay.Loading key={i} size="small" />
								))}
							</If>
							<Else>
								{edges?.map((e) => (
									<DocumentResourceDisplay key={e.node.id} documentResource={e.node} size="small" />
								))}
							</Else>
						</Conditional>
					</Row>
				</HorizontalScrollingContainer>
			</Tile.Body>
		</Tile>
	);
}
