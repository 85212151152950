import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import {
	EnrollmentApplicationStudentForm,
	useEnrollmentApplicationStudentDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { enrollmentApplicationStudentHubDetailPageInfo } from "./pageinfo";

export function EnrollmentApplicationStudentHubDetailPage() {
	const { enrollmentApplicationStudentID } = enrollmentApplicationStudentHubDetailPageInfo.useParams();

	const { loading, data, error } = useEnrollmentApplicationStudentDetailQuery({
		variables: { id: enrollmentApplicationStudentID },
	});
	const enrollmentApplicationStudent = data?.enrollmentApplicationStudent;
	useActivePageRegistration(
		enrollmentApplicationStudentHubDetailPageInfo,
		!!enrollmentApplicationStudent
			? enrollmentApplicationStudentHubDetailPageInfo.title(enrollmentApplicationStudent)
			: "",
	);

	const { syncID, synchronize } = useSync();

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading enrollment application student</If>
				<Else>
					{!!enrollmentApplicationStudent && (
						<React.Fragment>
							<Heading.H1>{enrollmentApplicationStudent.name}</Heading.H1>

							<EnrollmentApplicationStudentForm.ControlledDetail
								key={syncID}
								enrollmentApplicationStudent={enrollmentApplicationStudent}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
