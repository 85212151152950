import { NullPartial } from "@hex-insights/core";
import {
	AcademicYearTuitionCreateInput,
	AcademicYearTuitionDetailQuery,
	AcademicYearTuitionUpdateInput,
} from "../../GraphQL";
import { AcademicYearTuitionFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the AcademicYearTuition model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: AcademicYearTuitionFormValues.Create): AcademicYearTuitionCreateInput {
	const input: NullPartial<AcademicYearTuitionCreateInput> = { ...formValues };
	return input as AcademicYearTuitionCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the AcademicYearTuition model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<AcademicYearTuitionFormValues.Detail>,
	_initialFormValues: AcademicYearTuitionFormValues.Detail,
): AcademicYearTuitionUpdateInput {
	const input: AcademicYearTuitionUpdateInput = { ...formValues };
	return input;
}

/**
 * Converts a AcademicYearTuition instance to its corresponding detail form values.
 *
 * @param academicYearTuition The instance to convert.
 */
export function toFormValues(
	academicYearTuition: AcademicYearTuitionDetailQuery["academicYearTuition"],
): AcademicYearTuitionFormValues.Detail {
	return {
		year: academicYearTuition.year,
		nurseryPrice: academicYearTuition.nurseryPrice,
		receptionPrice: academicYearTuition.receptionPrice,
		primaryPrice: academicYearTuition.primaryPrice,
		secondaryPrice: academicYearTuition.secondaryPrice,
		tuitionID: academicYearTuition.tuition?.id ?? null,
	};
}
