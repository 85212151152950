import { newPageInfo } from "@hex-insights/router";
import { PaymentLineItem } from "@hex-insights/verita.shared";
import { paymentLineItemHubPageInfo } from "../pageinfo";

type PaymentLineItemForRouteTitle = Pick<PaymentLineItem, "id">;

export const paymentLineItemHubDetailPageInfo = newPageInfo({
	name: "Payment Line Item Detail",
	title: (e: PaymentLineItemForRouteTitle) => e.id,
	description: "Detail page for an paymentLineItem.",
	exact: false,
	isLeaf: true,
	to: (id: PaymentLineItem["id"]) => paymentLineItemHubPageInfo.to + `/${id}`,
	path: paymentLineItemHubPageInfo.path + "/:id(\\d+)",
	parent: paymentLineItemHubPageInfo,
});
