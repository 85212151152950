import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const academicYearHubPageInfo = newPageInfo({
	title: "Academic Years",
	description: "Pages related to the AcademicYear model.",
	exact: false,
	to: hubsPageInfo.to + "/academic-years",
	path: hubsPageInfo.path + "/academic-years",
	parent: hubsPageInfo,
});
