import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Note, NoteFormat, NoteNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Note, "id"> & NoteNameFragment;

export type NoteLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Note instance.
 */
export function NoteLink(props: NoteLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Note" format={NoteFormat.name} {...props} />;
}
