import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { UserFilterInput, UserOrder, useUserPaginationQuery } from "../../../../Utilities";

export type UserPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: UserFilterInput | UserFilterInput[] | null;
	order: UserOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the User model.
 */
export function UserPaginationForm({ formState, filters, order, setPaginationInfo }: UserPaginationFormProps) {
	const { loading, data } = useUserPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.userConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
