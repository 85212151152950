import { IndexForms } from "@hex-insights/app-modules";
import { ParentFilterFormValues } from "../../../../Utilities";

export type ParentSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the Parent model.
 */
export function ParentSearchForm(props: ParentSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type ParentFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, ParentFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Parent model.
 */
export function ParentFilterFormController(props: ParentFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={ParentFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `ParentFilterForm`
