import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { BusCreateInput, BusDetailQuery, BusUpdateInput } from "../../GraphQL";
import { BusFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Bus model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: BusFormValues.Create): BusCreateInput {
	const input: NullPartial<BusCreateInput> = { ...formValues };
	return input as BusCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Bus model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<BusFormValues.Detail>,
	initialFormValues: BusFormValues.Detail,
): BusUpdateInput {
	const inputFormValues = omitKeys(formValues, ["dropoffBusPlanIDs", "pickupBusPlanIDs"]);
	const input: BusUpdateInput = { ...inputFormValues };

	if (formValues.dropoffBusPlanIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.dropoffBusPlanIDs, formValues.dropoffBusPlanIDs);
		input.addDropoffBusPlanIDs = added;
		input.removeDropoffBusPlanIDs = removed;
	}

	if (formValues.pickupBusPlanIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.pickupBusPlanIDs, formValues.pickupBusPlanIDs);
		input.addPickupBusPlanIDs = added;
		input.removePickupBusPlanIDs = removed;
	}

	return input;
}

/**
 * Converts a Bus instance to its corresponding detail form values.
 *
 * @param bus The instance to convert.
 */
export function toFormValues(bus: BusDetailQuery["bus"]): BusFormValues.Detail {
	return {
		name: bus.name,
		description: bus.description,
		dropoffBusPlanIDs: bus.dropoffBusPlans.map((e) => e.id),
		pickupBusPlanIDs: bus.pickupBusPlans.map((e) => e.id),
	};
}
