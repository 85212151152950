import { newPageInfo } from "@hex-insights/router";
import { admissionsPageInfo } from "../pageinfo";

export const admissionsEnrollmentsPageInfo = newPageInfo({
	title: "Enrollments",
	description: "Overview of scheduled enrollments.",
	exact: true,
	to: admissionsPageInfo.to + "/enrollments",
	path: admissionsPageInfo.path + "/enrollments",
	parent: admissionsPageInfo,
});
