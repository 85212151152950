import React from "react";
import { ApplyUpdateFunction, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, If, Row } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
} from "@hex-insights/forms";
import {
	PermissionDetailQuery,
	PermissionFormConversion,
	PermissionFormState,
	PermissionFormValues,
} from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledDetailProps = {
	permission: PermissionDetailQuery["permission"];
	applyUpdate: ApplyUpdateFunction<PermissionFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the Permission model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => PermissionFormConversion.toFormValues(props.permission),
		[props.permission],
	);
	const formState = PermissionFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<PermissionFormValues.Detail>;
};

/**
 * Renders the detail form of the Permission model using the given form state.
 */
export function Detail({ formState, permission, applyUpdate, onSuccess }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<PermissionFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form
			name={`permission.detail.${permission.id}`}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
		>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.Name formState={formState} formType={FormType.Update} id={permission.id} />
				<Field.Family formState={formState} formType={FormType.Update} id={permission.id} />
				<Field.Action formState={formState} formType={FormType.Update} id={permission.id} />
				<Field.Level formState={formState} formType={FormType.Update} id={permission.id} />
				<Field.Description formState={formState} formType={FormType.Update} id={permission.id} />
				<Field.PermissionGroups
					formState={formState}
					formType={FormType.Update}
					id={permission.id}
					currentPermissionGroups={permission.permissionGroups}
				/>
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	permission: PermissionDetailQuery["permission"];
};

/**
 * Renders a read-only detail form of the Permission model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => PermissionFormConversion.toFormValues(props.permission),
		[props.permission],
	);
	const formState = PermissionFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<PermissionFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the Permission model using the given form state.
 */
export function ReadOnly({ formState, permission }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.Name formState={formState} formType={FormType.Update} id={permission.id} />
			<Field.Family formState={formState} formType={FormType.Update} id={permission.id} />
			<Field.Action formState={formState} formType={FormType.Update} id={permission.id} />
			<Field.Level formState={formState} formType={FormType.Update} id={permission.id} />
			<Field.Description formState={formState} formType={FormType.Update} id={permission.id} />
			<Field.PermissionGroups
				formState={formState}
				formType={FormType.Update}
				id={permission.id}
				currentPermissionGroups={permission.permissionGroups}
			/>
		</Column>
	);
}
