import { IndexForms } from "@hex-insights/app-modules";
import { CampusFilterFormValues } from "../../../../Utilities";

export type CampusSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the Campus model.
 */
export function CampusSearchForm(props: CampusSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type CampusFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, CampusFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Campus model.
 */
export function CampusFilterFormController(props: CampusFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={CampusFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `CampusFilterForm`
