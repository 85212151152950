import React from "react";
import { Column } from "@hex-insights/core";
import { FormState, FormType } from "@hex-insights/forms";
import {
	EnrollmentApplicationUserViewRecordDetailQuery,
	EnrollmentApplicationUserViewRecordFormConversion,
	EnrollmentApplicationUserViewRecordFormState,
	EnrollmentApplicationUserViewRecordFormValues,
} from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledReadOnlyProps = {
	enrollmentApplicationUserViewRecord: EnrollmentApplicationUserViewRecordDetailQuery["enrollmentApplicationUserViewRecord"];
};

/**
 * Renders a read-only detail form of the EnrollmentApplicationUserViewRecord model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => EnrollmentApplicationUserViewRecordFormConversion.toFormValues(props.enrollmentApplicationUserViewRecord),
		[props.enrollmentApplicationUserViewRecord],
	);
	const formState = EnrollmentApplicationUserViewRecordFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<EnrollmentApplicationUserViewRecordFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the EnrollmentApplicationUserViewRecord model using the given form state.
 */
export function ReadOnly({ formState, enrollmentApplicationUserViewRecord }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.CreatedAt formState={formState} formType={FormType.Update} id={enrollmentApplicationUserViewRecord.id} />
			<Field.IsDetail formState={formState} formType={FormType.Update} id={enrollmentApplicationUserViewRecord.id} />
			<Field.EnrollmentApplication
				formState={formState}
				formType={FormType.Update}
				id={enrollmentApplicationUserViewRecord.id}
				currentEnrollmentApplication={enrollmentApplicationUserViewRecord.enrollmentApplication}
			/>
			<Field.User
				formState={formState}
				formType={FormType.Update}
				id={enrollmentApplicationUserViewRecord.id}
				currentUser={enrollmentApplicationUserViewRecord.user}
			/>
		</Column>
	);
}
