import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const employeeHubPageInfo = newPageInfo({
	title: "Employees",
	description: "Pages related to the Employee model.",
	exact: false,
	to: hubsPageInfo.to + "/employees",
	path: hubsPageInfo.path + "/employees",
	parent: hubsPageInfo,
});
