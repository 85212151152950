import { newPageInfo } from "@hex-insights/router";
import { courseSectionPeriodHubPageInfo } from "../pageinfo";

export const courseSectionPeriodHubIndexPageInfo = newPageInfo({
	name: "Course Section Periods Index",
	title: "",
	description: "Index of Course Section Periods.",
	exact: true,
	to: courseSectionPeriodHubPageInfo.to,
	path: courseSectionPeriodHubPageInfo.path,
	parent: courseSectionPeriodHubPageInfo,
});
