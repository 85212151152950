import React from "react";
import { ApplyCreateFunction, onCreateSubmit } from "@hex-insights/app-modules";
import { Column } from "@hex-insights/core";
import { Form, FormState, FormSubmitFunction, FormType, SubmissionStatus, SubmitButton } from "@hex-insights/forms";
import {
	DocumentResourceUserViewRecordDetailQuery,
	DocumentResourceUserViewRecordFormConversion,
	DocumentResourceUserViewRecordFormState,
	DocumentResourceUserViewRecordFormValues,
} from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<DocumentResourceUserViewRecordFormValues.Create>;
	onSuccess: () => void;
};

/**
 * Renders the create form of the DocumentResourceUserViewRecord model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = DocumentResourceUserViewRecordFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<DocumentResourceUserViewRecordFormValues.Create>;
};

/**
 * Renders the create form of the DocumentResourceUserViewRecord model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<DocumentResourceUserViewRecordFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form
			name="documentResourceUserViewRecord.create"
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
		>
			<Column justify="spaced-start">
				<Field.DocumentResource formState={formState} formType={FormType.Create} />

				<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
					Submit
				</SubmitButton>
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	documentResourceUserViewRecord: DocumentResourceUserViewRecordDetailQuery["documentResourceUserViewRecord"];
};

/**
 * Renders a read-only detail form of the DocumentResourceUserViewRecord model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => DocumentResourceUserViewRecordFormConversion.toFormValues(props.documentResourceUserViewRecord),
		[props.documentResourceUserViewRecord],
	);
	const formState = DocumentResourceUserViewRecordFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<DocumentResourceUserViewRecordFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the DocumentResourceUserViewRecord model using the given form state.
 */
export function ReadOnly({ formState, documentResourceUserViewRecord }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.ViewedAt formState={formState} formType={FormType.Update} id={documentResourceUserViewRecord.id} />
			<Field.DocumentResource
				formState={formState}
				formType={FormType.Update}
				id={documentResourceUserViewRecord.id}
				currentDocumentResource={documentResourceUserViewRecord.documentResource}
			/>
			<Field.User
				formState={formState}
				formType={FormType.Update}
				id={documentResourceUserViewRecord.id}
				currentUser={documentResourceUserViewRecord.user}
			/>
		</Column>
	);
}
