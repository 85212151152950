import { newPageInfo } from "@hex-insights/router";
import { studentDocumentHubPageInfo } from "../pageinfo";

export const studentDocumentHubIndexPageInfo = newPageInfo({
	name: "Student Documents Index",
	title: "",
	description: "Index of Student Documents.",
	exact: true,
	to: studentDocumentHubPageInfo.to,
	path: studentDocumentHubPageInfo.path,
	parent: studentDocumentHubPageInfo,
});
