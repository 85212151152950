import { newPageInfo } from "@hex-insights/router";
import { roomHubPageInfo } from "../pageinfo";

export const roomHubCreatePageInfo = newPageInfo({
	name: "New Room",
	title: "New",
	description: "Create a new Room record.",
	exact: true,
	to: roomHubPageInfo.to + "/new",
	path: roomHubPageInfo.path + "/new",
	parent: roomHubPageInfo,
});
