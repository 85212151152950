import { NullPartial } from "@hex-insights/core";
import { ExpenseCreateInput, ExpenseDetailQuery, ExpenseUpdateInput } from "../../GraphQL";
import { ExpenseFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Expense model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: ExpenseFormValues.Create): ExpenseCreateInput {
	const input: NullPartial<ExpenseCreateInput> = { ...formValues };
	return input as ExpenseCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Expense model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<ExpenseFormValues.Detail>,
	_initialFormValues: ExpenseFormValues.Detail,
): ExpenseUpdateInput {
	const input: ExpenseUpdateInput = { ...formValues };
	return input;
}

/**
 * Converts a Expense instance to its corresponding detail form values.
 *
 * @param expense The instance to convert.
 */
export function toFormValues(expense: ExpenseDetailQuery["expense"]): ExpenseFormValues.Detail {
	return {
		createdDate: expense.createdDate,
		amount: expense.amount,
		notes: expense.notes,
		expenseCategoryID: expense.expenseCategory?.id ?? null,
	};
}
