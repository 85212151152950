import { IndexForms } from "@hex-insights/app-modules";
import { PermissionFilterFormValues } from "../../../../Utilities";

export type PermissionSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the Permission model.
 */
export function PermissionSearchForm(props: PermissionSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type PermissionFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, PermissionFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Permission model.
 */
export function PermissionFilterFormController(props: PermissionFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={PermissionFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `PermissionFilterForm`
