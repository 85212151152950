import { IndexForms } from "@hex-insights/app-modules";
import { BillingSetupSubmissionFilterFormValues } from "../../../../Utilities";

export type BillingSetupSubmissionSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the BillingSetupSubmission model.
 */
export function BillingSetupSubmissionSearchForm(props: BillingSetupSubmissionSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type BillingSetupSubmissionFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, BillingSetupSubmissionFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the BillingSetupSubmission model.
 */
export function BillingSetupSubmissionFilterFormController(props: BillingSetupSubmissionFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={BillingSetupSubmissionFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `<Filter>`
