import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	NoteAttachmentForm,
	NoteAttachmentFormValues,
	NoteAttachmentMutation,
	useNoteAttachmentDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { noteAttachmentHubDetailPageInfo } from "./pageinfo";

function NoteAttachmentHubDetailPageWrapper() {
	// Manages identity of the NoteAttachmentHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <NoteAttachmentHubDetailPage key={id} />;
}

export { NoteAttachmentHubDetailPageWrapper as NoteAttachmentHubDetailPage };

function NoteAttachmentHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useNoteAttachmentDetailQuery({ variables: { id } });
	const noteAttachment = data?.noteAttachment;
	useActivePageRegistration(
		noteAttachmentHubDetailPageInfo,
		!!noteAttachment ? noteAttachmentHubDetailPageInfo.title(noteAttachment) : "",
	);

	const update = NoteAttachmentMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<NoteAttachmentFormValues.Detail>,
			initialFormValues: NoteAttachmentFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading noteAttachment</If>
				<Else>
					{!!noteAttachment && (
						<React.Fragment>
							<Heading level={1}>{noteAttachment.id}</Heading>

							<NoteAttachmentForm.ControlledDetail
								key={syncID}
								noteAttachment={noteAttachment}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
