import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { HomeRoomSectionHubCreatePage } from "./CreatePage";
import { homeRoomSectionHubCreatePageInfo } from "./CreatePage/pageinfo";
import { HomeRoomSectionHubDetailPage } from "./DetailPage";
import { homeRoomSectionHubDetailPageInfo } from "./DetailPage/pageinfo";
import { HomeRoomSectionHubIndexPage } from "./IndexPage";
import { homeRoomSectionHubIndexPageInfo } from "./IndexPage/pageinfo";
import { homeRoomSectionHubPageInfo } from "./pageinfo";

export function HomeRoomSectionHub() {
	useActivePageRegistration(homeRoomSectionHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...homeRoomSectionHubIndexPageInfo.routeProps}>
				<HomeRoomSectionHubIndexPage />
			</Route>
			<Route {...homeRoomSectionHubCreatePageInfo.routeProps}>
				<HomeRoomSectionHubCreatePage />
			</Route>
			<Route {...homeRoomSectionHubDetailPageInfo.routeProps}>
				<HomeRoomSectionHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
