import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { RelationshipFilterInput, RelationshipOrder, useRelationshipPaginationQuery } from "../../../../Utilities";

export type RelationshipPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: RelationshipFilterInput | RelationshipFilterInput[] | null;
	order: RelationshipOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the Relationship model.
 */
export function RelationshipPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: RelationshipPaginationFormProps) {
	const { loading, data } = useRelationshipPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.relationshipConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
