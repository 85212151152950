import { NullPartial, omitKeys } from "@hex-insights/core";
import {
	EnrollmentApplicationStudentCreateInput,
	EnrollmentApplicationStudentDetailQuery,
	EnrollmentApplicationStudentUpdateInput,
} from "../../GraphQL";
import { EnrollmentApplicationStudentFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the EnrollmentApplicationStudent model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(
	formValues: EnrollmentApplicationStudentFormValues.Create,
): EnrollmentApplicationStudentCreateInput {
	const inputFormValues = { ...formValues };
	const input: NullPartial<EnrollmentApplicationStudentCreateInput> = { ...inputFormValues };
	return input as EnrollmentApplicationStudentCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the EnrollmentApplicationStudent model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<EnrollmentApplicationStudentFormValues.Detail>,
	_initialFormValues: EnrollmentApplicationStudentFormValues.Detail,
): EnrollmentApplicationStudentUpdateInput {
	const inputFormValues = omitKeys(formValues, ["name"]);
	const input: EnrollmentApplicationStudentUpdateInput = { ...inputFormValues };

	if (formValues.image === null) {
		input.clearImage = true;
	}

	if (formValues.gender === null) {
		input.clearGender = true;
	}

	if (formValues.enrollmentApplicationID === null) {
		input.enrollmentApplicationID = null;
		input.clearEnrollmentApplication = true;
	}

	return input;
}

/**
 * Converts a EnrollmentApplicationStudent instance to its corresponding detail form values.
 *
 * @param enrollmentApplicationStudent The instance to convert.
 */
export function toFormValues(
	enrollmentApplicationStudent: EnrollmentApplicationStudentDetailQuery["enrollmentApplicationStudent"],
): EnrollmentApplicationStudentFormValues.Detail {
	return {
		formIdentifier: enrollmentApplicationStudent.formIdentifier,
		image: enrollmentApplicationStudent.imageURL !== "" ? new File([], "image") : null,
		name: enrollmentApplicationStudent.name,
		firstName: enrollmentApplicationStudent.firstName,
		lastName: enrollmentApplicationStudent.lastName,
		nickname: enrollmentApplicationStudent.nickname,
		dateOfBirth: enrollmentApplicationStudent.dateOfBirth,
		gender: enrollmentApplicationStudent.gender,
		genderCustom: enrollmentApplicationStudent.genderCustom,
		nationality: enrollmentApplicationStudent.nationality,
		primaryLanguage: enrollmentApplicationStudent.primaryLanguage,
		englishLanguageFluency: enrollmentApplicationStudent.englishLanguageFluency,
		currentGradeLevel: enrollmentApplicationStudent.currentGradeLevel,
		gradeLevelApplyingFor: enrollmentApplicationStudent.gradeLevelApplyingFor,
		hasPreviousSchooling: enrollmentApplicationStudent.hasPreviousSchooling,
		previousSchoolInformation: enrollmentApplicationStudent.previousSchoolInformation,
		previousSchoolLocation: enrollmentApplicationStudent.previousSchoolLocation,
		hasLearningDifficulties: enrollmentApplicationStudent.hasLearningDifficulties,
		learningDifficultiesDescription: enrollmentApplicationStudent.learningDifficultiesDescription,
		hasMedicalPhysicalExceptionalities: enrollmentApplicationStudent.hasMedicalPhysicalExceptionalities,
		medicalPhysicalExceptionalitiesDescription: enrollmentApplicationStudent.medicalPhysicalExceptionalitiesDescription,
		hasBehaviorDisciplineChallenges: enrollmentApplicationStudent.hasBehaviorDisciplineChallenges,
		behaviorDisciplineChallengesDescription: enrollmentApplicationStudent.behaviorDisciplineChallengesDescription,
		additionalInformation: enrollmentApplicationStudent.additionalInformation,
		enrollmentApplicationID: enrollmentApplicationStudent.enrollmentApplication?.id ?? null,
		enrollmentApplicationSubmissionID: enrollmentApplicationStudent.enrollmentApplicationSubmission?.id ?? null,
	};
}
