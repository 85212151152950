import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export const busListPageInfo = newPageInfo({
	title: "Bus List",
	description: "List of students on buses.",
	exact: false,
	to: "/bus-list",
	path: "/bus-list",
	parent: rootPageInfo,
});
