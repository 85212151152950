import { IndexForms } from "@hex-insights/app-modules";
import { PaymentLineItemFilterFormValues } from "../../../../Utilities";

export type PaymentLineItemFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, PaymentLineItemFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the PaymentLineItem model.
 */
export function PaymentLineItemFilterFormController(props: PaymentLineItemFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={PaymentLineItemFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `PaymentLineItemFilterForm`
