import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { CalendarEventFilterInput, CalendarEventOrder, useCalendarEventPaginationQuery } from "../../../../Utilities";

export type CalendarEventPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: CalendarEventFilterInput | CalendarEventFilterInput[] | null;
	order: CalendarEventOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the CalendarEvent model.
 */
export function CalendarEventPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: CalendarEventPaginationFormProps) {
	const { loading, data } = useCalendarEventPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.calendarEventConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
