import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../Components";
import { BusListDropoffPage } from "./Dropoff";
import { busListDropoffPageInfo } from "./Dropoff/pageinfo";
import { BusListIndexPage } from "./IndexPage";
import { busListIndexPageInfo } from "./IndexPage/pageinfo";
import { busListPageInfo } from "./pageinfo";
import { BusListPickupPage } from "./Pickup";
import { busListPickupPageInfo } from "./Pickup/pageinfo";

// TODO refactor all subpages

export function BusListPage() {
	useActivePageRegistration(busListPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...busListIndexPageInfo.routeProps}>
				<BusListIndexPage />
			</Route>
			<Route {...busListPickupPageInfo.routeProps}>
				<BusListPickupPage />
			</Route>
			<Route {...busListDropoffPageInfo.routeProps}>
				<BusListDropoffPage />
			</Route>
		</PageRouteSwitch>
	);
}
