import { IndexForms } from "@hex-insights/app-modules";
import { ExpenseCategoryOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the ExpenseCategory model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		ExpenseCategoryOrderFormValues.initial,
		ExpenseCategoryOrderFormValues.fieldValues,
	);
}
