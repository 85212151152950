import React from "react";
import { Column, Heading } from "@hex-insights/core";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { RoomForm, RoomFormValues, RoomMutation } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { roomHubDetailPageInfo } from "../DetailPage/pageinfo";
import { roomHubCreatePageInfo } from "./pageinfo";

export function RoomHubCreatePage() {
	useActivePageRegistration(roomHubCreatePageInfo);

	const create = RoomMutation.useCreate();
	const createdIDRef = React.useRef<string | null>(null);
	const { push: pushHistory } = useHistory();

	const applyCreate = React.useCallback(
		async (formValues: RoomFormValues.Create) => {
			const { data, errors } = await create(formValues);
			createdIDRef.current = data?.id ?? null;
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		if (createdIDRef.current !== null) {
			pushHistory(roomHubDetailPageInfo.to(createdIDRef.current));
		}
	}, [pushHistory]);

	return (
		<Main>
			<Heading level={1}>New Room</Heading>

			<Column style={{ width: "fit-content" }}>
				<RoomForm.ControlledCreate applyCreate={applyCreate} onSuccess={onSuccess} />
			</Column>
		</Main>
	);
}
