import { newPageInfo } from "@hex-insights/router";
import { LessonPlan } from "@hex-insights/verita.shared";
import { lessonPlanHubPageInfo } from "../pageinfo";

type LessonPlanForRouteTitle = Pick<LessonPlan, "id">;

export const lessonPlanHubDetailPageInfo = newPageInfo({
	name: "Lesson Plan Detail",
	title: (e: LessonPlanForRouteTitle) => e.id,
	description: "Detail page for an lessonPlan.",
	exact: false,
	isLeaf: true,
	to: (id: LessonPlan["id"]) => lessonPlanHubPageInfo.to + `/${id}`,
	path: lessonPlanHubPageInfo.path + "/:id(\\d+)",
	parent: lessonPlanHubPageInfo,
});
