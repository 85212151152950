import { newPageInfo } from "@hex-insights/router";
import { learningObjectiveMarkHubPageInfo } from "../pageinfo";

export const learningObjectiveMarkHubIndexPageInfo = newPageInfo({
	name: "Learning Objective Marks Index",
	title: "",
	description: "Index of Learning Objective Marks.",
	exact: true,
	to: learningObjectiveMarkHubPageInfo.to,
	path: learningObjectiveMarkHubPageInfo.path,
	parent: learningObjectiveMarkHubPageInfo,
});
