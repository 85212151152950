import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	Course,
	graphTypeNames,
	LearningObjective,
	LearningObjectiveCreateInput,
	LearningObjectiveCreateMutation,
	LearningObjectiveDeleteMutation,
	LearningObjectiveDetailDocument,
	LearningObjectiveDetailQuery,
	LearningObjectiveDetailQueryVariables,
	LearningObjectiveMark,
	LearningObjectiveUpdateInput,
	LearningObjectiveUpdateMutation,
	useLearningObjectiveCreateMutation,
	useLearningObjectiveDeleteMutation,
	useLearningObjectiveUpdateMutation,
} from "../../GraphQL";
import { LearningObjectiveFormConversion } from "../ModelFormConversion";
import { LearningObjectiveFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the LearningObjective model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the LearningObjective model.
 */
export function useCreate() {
	const [createLearningObjective] = useLearningObjectiveCreateMutation();

	return React.useCallback(
		async (formValues: LearningObjectiveFormValues.Create) => {
			const input = LearningObjectiveFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createLearningObjective({ variables: { input }, update: updateCache });

			return { data: data?.createLearningObjective ?? null, errors: errors ?? null };
		},
		[createLearningObjective],
	);
}

/**
 * Returns an `update` function for the LearningObjective model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the LearningObjective model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: LearningObjective["id"]) {
	const [updateLearningObjective] = useLearningObjectiveUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<LearningObjectiveFormValues.Detail>,
			initialFormValues: LearningObjectiveFormValues.Detail,
		) => {
			const input = LearningObjectiveFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateLearningObjective({ variables: { id, input }, update: updateCache });

			return { data: data?.updateLearningObjective ?? null, errors: errors ?? null };
		},
		[updateLearningObjective, id],
	);
}

/**
 * Returns a `del` function for the LearningObjective model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: LearningObjective["id"]) {
	const [deleteLearningObjective] = useLearningObjectiveDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteLearningObjective({ variables: { id }, update: updateCache });

		return { data: data?.deleteLearningObjective ?? false, errors: errors ?? null };
	}, [deleteLearningObjective, id]);
}

function getUpdateCacheForCreate(input: LearningObjectiveCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<LearningObjectiveCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createLearningObjective;

		cache.writeQuery<LearningObjectiveDetailQuery, LearningObjectiveDetailQueryVariables>({
			query: LearningObjectiveDetailDocument,
			data: { learningObjective: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.courseID) {
			addToLearningObjectivesOfCourseCache(cache, input.courseID, createdObject);
		}

		if (input.learningObjectiveMarkIDs) {
			for (let i = 0; i < input.learningObjectiveMarkIDs.length; i++) {
				addToLearningObjectiveOfLearningObjectiveMarkCache(cache, input.learningObjectiveMarkIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "learningObjectiveConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: LearningObjectiveUpdateInput,
	initialFormValues: LearningObjectiveFormValues.Detail,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<LearningObjectiveUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateLearningObjective;

		if (initialFormValues.courseID && input.courseID) {
			removeFromLearningObjectivesOfCourseCache(cache, initialFormValues.courseID, updatedObject);
		}
		if (input.courseID) {
			addToLearningObjectivesOfCourseCache(cache, input.courseID, updatedObject);
		}

		if (input.addLearningObjectiveMarkIDs) {
			for (let i = 0; i < input.addLearningObjectiveMarkIDs.length; i++) {
				addToLearningObjectiveOfLearningObjectiveMarkCache(cache, input.addLearningObjectiveMarkIDs[i], updatedObject);
			}
		}
		if (input.removeLearningObjectiveMarkIDs) {
			for (let i = 0; i < input.removeLearningObjectiveMarkIDs.length; i++) {
				removeFromLearningObjectiveOfLearningObjectiveMarkCache(
					cache,
					input.removeLearningObjectiveMarkIDs[i],
					updatedObject,
				);
			}
		}
	};
}

function getUpdateCacheForDelete(id: LearningObjective["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<LearningObjectiveDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteLearningObjective) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.LearningObjective }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "learningObjectiveConnection" });
		cache.gc();
	};
}

function addToLearningObjectivesOfCourseCache(
	cache: ApolloCache<any>,
	targetID: Course["id"],
	object: Pick<LearningObjective, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Course }),
		fields: {
			learningObjectives: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromLearningObjectivesOfCourseCache(
	cache: ApolloCache<any>,
	targetID: Course["id"],
	object: Pick<LearningObjective, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Course }),
		fields: {
			learningObjectives: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToLearningObjectiveOfLearningObjectiveMarkCache(
	cache: ApolloCache<any>,
	targetID: LearningObjectiveMark["id"],
	object: Pick<LearningObjective, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.LearningObjectiveMark }),
		fields: {
			learningObjective: () => objectRef,
			learningObjectiveID: () => object.id,
		},
	});
}

function removeFromLearningObjectiveOfLearningObjectiveMarkCache(
	cache: ApolloCache<any>,
	targetID: LearningObjectiveMark["id"],
	_object: Pick<LearningObjective, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.LearningObjectiveMark }),
		fields: {
			learningObjective: () => null,
			learningObjectiveID: () => null,
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<LearningObjective, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment LearningObjectiveRef on LearningObjective {
				id
			}
		`,
		data: object,
	});
}
