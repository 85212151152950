import { StyleProps } from "@hex-insights/core";
import { FormState, FormType, useFormState } from "@hex-insights/forms";
import { EnrollmentApplicationSubmissionField } from "../../../../Components";
import { EnrollmentApplicationFormValues } from "../../../Utilities";

export type ControlledReadOnlyProps = {
	initialFormValues: EnrollmentApplicationFormValues.DataConsentFormValues;
	fieldsStyle?: React.CSSProperties;
};

export function ControlledReadOnly({ initialFormValues, fieldsStyle }: ControlledReadOnlyProps) {
	const formState = useFormState({ initialFormValues, formType: FormType.View });

	return <Fields formState={formState} style={fieldsStyle} />;
}

export type FieldsProps = {
	formState: FormState<EnrollmentApplicationFormValues.DataConsentFormValues>;
} & Partial<StyleProps>;

export function Fields({ formState, style }: FieldsProps) {
	return <EnrollmentApplicationSubmissionField.DoesConsentToDataUse formState={formState} noToolbar style={style} />;
}
