import { newPageInfo, newRouteParamsType } from "@hex-insights/router";
import { AcademicYearTuition } from "@hex-insights/verita.shared";
import { academicYearTuitionHubPageInfo } from "../pageinfo";

type AcademicYearTuitionForRouteTitle = Pick<AcademicYearTuition, "id">;

export const academicYearTuitionHubDetailPageInfo = newPageInfo({
	name: "Academic Year Tuition Detail",
	title: (e: AcademicYearTuitionForRouteTitle) => e.id,
	description: "Detail page for an academicYearTuition.",
	exact: false,
	isLeaf: true,
	parent: academicYearTuitionHubPageInfo,
	to: (id: AcademicYearTuition["id"]) => academicYearTuitionHubPageInfo.to + `/${id}`,
	path: academicYearTuitionHubPageInfo.path + "/:academicYearTuitionID(\\d+)",
	paramsType: newRouteParamsType(academicYearTuitionHubPageInfo.paramsType, "academicYearTuitionID"),
});
