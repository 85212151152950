import React from "react";
import { DateTimeField, FieldDisplayArgs, FormType, SelectField } from "@hex-insights/forms";
import {
	DocumentResourceSelect,
	DocumentResourceUserViewRecordFormValues,
	useDocumentResourceSelectLazyQuery,
	UserSelect,
	useUserSelectLazyQuery,
} from "../../../../Utilities";
import { DocumentResourceLink, UserLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the DocumentResourceUserViewRecord model.
 */
type FieldProps<
	K extends keyof DocumentResourceUserViewRecordFormValues.Base = keyof DocumentResourceUserViewRecordFormValues.Base,
> = BaseFieldProps<Pick<DocumentResourceUserViewRecordFormValues.Base, K>>;

/**
 * Generic props for fields of the DocumentResourceUserViewRecord model that only appear in the detail form.
 */
type DetailFieldProps<
	K extends keyof DocumentResourceUserViewRecordFormValues.Detail = keyof DocumentResourceUserViewRecordFormValues.Detail,
> = BaseFieldProps<Pick<DocumentResourceUserViewRecordFormValues.Detail, K>>;

/**
 * Renders a field component for the `viewedAt` field of the DocumentResourceUserViewRecord model.
 */
export function ViewedAt({ formState, formType = FormType.Update }: DetailFieldProps<"viewedAt">) {
	return (
		<DateTimeField formState={formState} name="viewedAt" optional={FormType.isCreate(formType)} precision="minute" />
	);
}

export type DocumentResourceProps = FieldProps<"documentResourceID"> & {
	currentDocumentResource?: DocumentResourceSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `documentResource` edge of the DocumentResourceUserViewRecord model.
 */
export function DocumentResource({ formState, currentDocumentResource }: DocumentResourceProps) {
	const [loadOptions, { loading, data }] = useDocumentResourceSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.documentResourceID) {
			loadOptions();
		}
	}, [formState.formEditing.documentResourceID, loadOptions]);
	const options = React.useMemo(
		() => DocumentResourceSelect.toOptions(data?.documentResourceConnection.edges, currentDocumentResource),
		[data, currentDocumentResource],
	);

	return (
		<SelectField
			formState={formState}
			name="documentResourceID"
			isLoading={loading}
			options={options}
			display={displayDocumentResource}
			blankValue={null}
		/>
	);
}

function displayDocumentResource({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <DocumentResourceLink instance={{ id }}>{formattedValue}</DocumentResourceLink>;
}

export type UserProps = DetailFieldProps<"userID"> & {
	currentUser?: UserSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `user` edge of the DocumentResourceUserViewRecord model.
 */
export function User({ formState, currentUser }: UserProps) {
	const [loadOptions, { loading, data }] = useUserSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.userID) {
			loadOptions();
		}
	}, [formState.formEditing.userID, loadOptions]);
	const options = React.useMemo(
		() => UserSelect.toOptions(data?.userConnection.edges, currentUser),
		[data, currentUser],
	);

	return (
		<SelectField
			formState={formState}
			name="userID"
			isLoading={loading}
			options={options}
			optional
			display={displayUser}
			blankValue={null}
		/>
	);
}

function displayUser({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserLink instance={{ id }}>{formattedValue}</UserLink>;
}
