import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { EnrollmentApplicationUserViewRecordOrderFormValues } from "../../../../Utilities";

export type EnrollmentApplicationUserViewRecordOrderFormControllerProps = {
	formState: FormState<EnrollmentApplicationUserViewRecordOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the EnrollmentApplicationUserViewRecord model.
 */
export function EnrollmentApplicationUserViewRecordOrderFormController({
	formState,
}: EnrollmentApplicationUserViewRecordOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={EnrollmentApplicationUserViewRecordOrderFormValues.fieldOptions}
			fieldTypeMapping={EnrollmentApplicationUserViewRecordOrderFormValues.fieldTypeMapping}
		/>
	);
}
