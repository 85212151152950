import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	ClassYear,
	ClassYearCreateInput,
	ClassYearCreateMutation,
	ClassYearDeleteMutation,
	ClassYearDetailDocument,
	ClassYearDetailQuery,
	ClassYearDetailQueryVariables,
	ClassYearUpdateInput,
	ClassYearUpdateMutation,
	Course,
	graphTypeNames,
	HomeRoom,
	useClassYearCreateMutation,
	useClassYearDeleteMutation,
	useClassYearUpdateMutation,
} from "../../GraphQL";
import { ClassYearFormConversion } from "../ModelFormConversion";
import { ClassYearFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the ClassYear model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the ClassYear model.
 */
export function useCreate() {
	const [createClassYear] = useClassYearCreateMutation();

	return React.useCallback(
		async (formValues: ClassYearFormValues.Create) => {
			const input = ClassYearFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createClassYear({ variables: { input }, update: updateCache });

			return { data: data?.createClassYear ?? null, errors: errors ?? null };
		},
		[createClassYear],
	);
}

/**
 * Returns an `update` function for the ClassYear model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the ClassYear model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: ClassYear["id"]) {
	const [updateClassYear] = useClassYearUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<ClassYearFormValues.Detail>, initialFormValues: ClassYearFormValues.Detail) => {
			const input = ClassYearFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateClassYear({ variables: { id, input }, update: updateCache });

			return { data: data?.updateClassYear ?? null, errors: errors ?? null };
		},
		[updateClassYear, id],
	);
}

/**
 * Returns a `del` function for the ClassYear model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: ClassYear["id"]) {
	const [deleteClassYear] = useClassYearDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteClassYear({ variables: { id }, update: updateCache });

		return { data: data?.deleteClassYear ?? false, errors: errors ?? null };
	}, [deleteClassYear, id]);
}

function getUpdateCacheForCreate(input: ClassYearCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ClassYearCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createClassYear;

		cache.writeQuery<ClassYearDetailQuery, ClassYearDetailQueryVariables>({
			query: ClassYearDetailDocument,
			data: { classYear: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.courseIDs) {
			for (let i = 0; i < input.courseIDs.length; i++) {
				addToClassYearsOfCourseCache(cache, input.courseIDs[i], createdObject);
			}
		}

		if (input.homeRoomIDs) {
			for (let i = 0; i < input.homeRoomIDs.length; i++) {
				addToClassYearOfHomeRoomCache(cache, input.homeRoomIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "classYearConnection" });
	};
}

function getUpdateCacheForUpdate(input: ClassYearUpdateInput, _initialFormValues: ClassYearFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ClassYearUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateClassYear;

		if (input.addCourseIDs) {
			for (let i = 0; i < input.addCourseIDs.length; i++) {
				addToClassYearsOfCourseCache(cache, input.addCourseIDs[i], updatedObject);
			}
		}
		if (input.removeCourseIDs) {
			for (let i = 0; i < input.removeCourseIDs.length; i++) {
				removeFromClassYearsOfCourseCache(cache, input.removeCourseIDs[i], updatedObject);
			}
		}

		if (input.addHomeRoomIDs) {
			for (let i = 0; i < input.addHomeRoomIDs.length; i++) {
				addToClassYearOfHomeRoomCache(cache, input.addHomeRoomIDs[i], updatedObject);
			}
		}
		if (input.removeHomeRoomIDs) {
			for (let i = 0; i < input.removeHomeRoomIDs.length; i++) {
				removeFromClassYearOfHomeRoomCache(cache, input.removeHomeRoomIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: ClassYear["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ClassYearDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteClassYear) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.ClassYear }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "classYearConnection" });
		cache.gc();
	};
}

function addToClassYearsOfCourseCache(cache: ApolloCache<any>, targetID: Course["id"], object: Pick<ClassYear, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Course }),
		fields: {
			classYears: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromClassYearsOfCourseCache(
	cache: ApolloCache<any>,
	targetID: Course["id"],
	object: Pick<ClassYear, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Course }),
		fields: {
			classYears: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToClassYearOfHomeRoomCache(
	cache: ApolloCache<any>,
	targetID: HomeRoom["id"],
	object: Pick<ClassYear, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.HomeRoom }),
		fields: {
			classYear: () => objectRef,
			classYearID: () => object.id,
		},
	});
}

function removeFromClassYearOfHomeRoomCache(
	cache: ApolloCache<any>,
	targetID: HomeRoom["id"],
	_object: Pick<ClassYear, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.HomeRoom }),
		fields: {
			classYear: () => null,
			classYearID: () => null,
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<ClassYear, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment ClassYearRef on ClassYear {
				id
			}
		`,
		data: object,
	});
}
