import { IndexForms } from "@hex-insights/app-modules";
import { BusPlanFilterFormValues } from "../../../../Utilities";

export type BusPlanSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the BusPlan model.
 */
export function BusPlanSearchForm(props: BusPlanSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type BusPlanFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, BusPlanFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the BusPlan model.
 */
export function BusPlanFilterFormController(props: BusPlanFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={BusPlanFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `BusPlanFilterForm`
