import { IndexForms } from "@hex-insights/app-modules";
import { CalendarEventFilterFormValues } from "../../../../Utilities";

export type CalendarEventSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the CalendarEvent model.
 */
export function CalendarEventSearchForm(props: CalendarEventSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type CalendarEventFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, CalendarEventFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the CalendarEvent model.
 */
export function CalendarEventFilterFormController(props: CalendarEventFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={CalendarEventFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `CalendarEventFilterForm`
