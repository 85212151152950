import { isSameTimeOrBefore, stringToLocalDate } from "@hex-insights/core";
import { SchoolStudentEnrollment, SchoolStudentEnrollmentNameFragment } from "../../../GraphQL";
import * as Fields from "./fields";

export { Fields };

/**
 * Formats the full name of the given instance.
 */
export function name(instance: SchoolStudentEnrollmentNameFragment) {
	return instance.id;
}

export function paymentStatus(instance: Pick<SchoolStudentEnrollment, "hasPaidFee" | "paymentDueDate">) {
	if (instance.hasPaidFee) {
		return "Paid";
	}
	if (isSameTimeOrBefore(new Date(), stringToLocalDate(instance.paymentDueDate, "day"))) {
		return "Unpaid";
	}
	return "Overdue";
}
