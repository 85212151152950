import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { EmployeeFilterInput } from "../../GraphQL";
import { EmployeeFilterFormValues } from "../ModelFilterFormValues";

/**
 * Counts the number of active filters for the Employee model.
 *
 * @param formValues The form values of the filter form.
 */
export function countActiveFilters(formValues: EmployeeFilterFormValues.FormValues) {
	// TODO count quick filters
	const numAdvancedFilters = IndexForms.countActiveAdvancedFilters(
		formValues,
		EmployeeFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	);
	return numAdvancedFilters;
}

/**
 * Clears the values of the filter form for the Employee model.
 *
 * @param formState The form state of the filter form.
 */
export function clearFilters(formState: FormState<EmployeeFilterFormValues.FormValues>) {
	// TODO clear quick filters
	formState.formClearFunctions.filterSets();
}

/**
 * Resets the values of the filter form for the Employee model.
 *
 * @param formState The form state of the filter form.
 */
export function resetFilters(formState: FormState<EmployeeFilterFormValues.FormValues>) {
	// TODO reset quick filters
	formState.formResetFunctions.filterSets();
}

/**
 * Converts the form values of the filter form for the Employee model to the correct GraphQL
 * filter input type.
 *
 * @param formValues The form values of the filter form.
 */
export function toFilterInputs(
	formValues: EmployeeFilterFormValues.FormValues,
): EmployeeFilterInput | EmployeeFilterInput[] {
	const filterInput = IndexForms.toFilterInput(
		formValues,
		EmployeeFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	) as EmployeeFilterInput;

	const filterInputs = [filterInput];
	if (formValues.search !== "") {
		filterInputs.push({ ...filterInput, search: "", person: [{ search: formValues.search }] });
	}

	return filterInputs;
}
