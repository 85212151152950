import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { SessionHubDetailPage } from "./DetailPage";
import { sessionHubDetailPageInfo } from "./DetailPage/pageinfo";
import { SessionHubIndexPage } from "./IndexPage";
import { sessionHubIndexPageInfo } from "./IndexPage/pageinfo";
import { sessionHubPageInfo } from "./pageinfo";

export function SessionHub() {
	useActivePageRegistration(sessionHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...sessionHubIndexPageInfo.routeProps}>
				<SessionHubIndexPage />
			</Route>
			<Route {...sessionHubDetailPageInfo.routeProps}>
				<SessionHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
