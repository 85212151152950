import { ClassYear, Course, HomeRoom } from "../../GraphQL";

export type Base = {
	name: ClassYear["name"];
	yearsFromGraduation: ClassYear["yearsFromGraduation"] | null;
};

export type Create = Base;

export type Detail = Base & {
	homeRoomIDs: HomeRoom["id"][];
	courseIDs: Course["id"][];
};

export const initialCreate: Create = {
	name: "",
	yearsFromGraduation: null,
};
