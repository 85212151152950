import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { MealPlanHubCreatePage } from "./CreatePage";
import { mealPlanHubCreatePageInfo } from "./CreatePage/pageinfo";
import { MealPlanHubDetailPage } from "./DetailPage";
import { mealPlanHubDetailPageInfo } from "./DetailPage/pageinfo";
import { MealPlanHubIndexPage } from "./IndexPage";
import { mealPlanHubIndexPageInfo } from "./IndexPage/pageinfo";
import { mealPlanHubPageInfo } from "./pageinfo";

export function MealPlanHub() {
	useActivePageRegistration(mealPlanHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...mealPlanHubIndexPageInfo.routeProps}>
				<MealPlanHubIndexPage />
			</Route>
			<Route {...mealPlanHubCreatePageInfo.routeProps}>
				<MealPlanHubCreatePage />
			</Route>
			<Route {...mealPlanHubDetailPageInfo.routeProps}>
				<MealPlanHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
