import { newPageInfo } from "@hex-insights/router";
import { homeRoomSectionHubPageInfo } from "../pageinfo";

export const homeRoomSectionHubCreatePageInfo = newPageInfo({
	name: "New Home Room Section",
	title: "New",
	description: "Create a new Home Room Section record.",
	exact: true,
	to: homeRoomSectionHubPageInfo.to + "/new",
	path: homeRoomSectionHubPageInfo.path + "/new",
	parent: homeRoomSectionHubPageInfo,
});
