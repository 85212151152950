import { Row } from "@hex-insights/core";
import { FormState, FormType, useFormState } from "@hex-insights/forms";
import { EnrollmentApplicationSubmissionField, FormColumn } from "../../../../Components";
import { EnrollmentApplicationFormValues } from "../../../Utilities";

export type ControlledReadOnlyProps = {
	initialFormValues: EnrollmentApplicationFormValues.SignatureFormValues;
};

export function ControlledReadOnly({ initialFormValues }: ControlledReadOnlyProps) {
	const formState = useFormState({ initialFormValues, formType: FormType.View });

	return <Fields formState={formState} />;
}

export type FieldsProps = {
	formState: FormState<EnrollmentApplicationFormValues.SignatureFormValues>;
};

export function Fields({ formState }: FieldsProps) {
	return (
		<FormColumn>
			<Row justify="spaced-start" align="spaced-start" overflow="wrap">
				<EnrollmentApplicationSubmissionField.SignatureName formState={formState} />
				<EnrollmentApplicationSubmissionField.SignatureDate formState={formState} />
			</Row>
		</FormColumn>
	);
}
