import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	CourseAttendanceRecordForm,
	CourseAttendanceRecordFormValues,
	CourseAttendanceRecordMutation,
	useCourseAttendanceRecordDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { courseAttendanceRecordHubDetailPageInfo } from "./pageinfo";

function CourseAttendanceRecordHubDetailPageWrapper() {
	// Manages identity of the CourseAttendanceRecordHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <CourseAttendanceRecordHubDetailPage key={id} />;
}

export { CourseAttendanceRecordHubDetailPageWrapper as CourseAttendanceRecordHubDetailPage };

function CourseAttendanceRecordHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useCourseAttendanceRecordDetailQuery({ variables: { id } });
	const courseAttendanceRecord = data?.courseAttendanceRecord;
	useActivePageRegistration(
		courseAttendanceRecordHubDetailPageInfo,
		!!courseAttendanceRecord ? courseAttendanceRecordHubDetailPageInfo.title(courseAttendanceRecord) : "",
	);

	const update = CourseAttendanceRecordMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<CourseAttendanceRecordFormValues.Detail>,
			initialFormValues: CourseAttendanceRecordFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading courseAttendanceRecord</If>
				<Else>
					{!!courseAttendanceRecord && (
						<React.Fragment>
							<Heading level={1}>{courseAttendanceRecord.id}</Heading>

							<CourseAttendanceRecordForm.ControlledDetail
								key={syncID}
								courseAttendanceRecord={courseAttendanceRecord}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
