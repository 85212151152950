import { DocumentResource } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the name field of the DocumentResource model.
 */
export function name(value: DocumentResource["name"]): string {
	return toString(value);
}

/**
 * Formats the description field of the DocumentResource model.
 */
export function description(value: DocumentResource["description"]): string {
	return toString(value);
}

/**
 * Formats the imageURL field of the DocumentResource model.
 */
export function imageURL(value: DocumentResource["imageURL"]): string {
	return toString(value);
}

/**
 * Formats the isFile field of the DocumentResource model.
 */
export function isFile(value: DocumentResource["isFile"]): string {
	return toString(value);
}

/**
 * Formats the fileURL field of the DocumentResource model.
 */
export function fileURL(value: DocumentResource["fileURL"]): string {
	return toString(value);
}

/**
 * Formats the resourceURL field of the DocumentResource model.
 */
export function resourceURL(value: DocumentResource["resourceURL"]): string {
	return toString(value);
}
