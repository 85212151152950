import React from "react";
import {
	BooleanField,
	DateTimeField,
	FieldDisplayArgs,
	FormType,
	MultiSelectField,
	NumberField,
	SelectField,
} from "@hex-insights/forms";
import {
	LoginRecordSelect,
	MFAInvalidOTPAttemptSelect,
	SessionFormValues,
	TrustedDeviceSelect,
	useLoginRecordSelectLazyQuery,
	useMFAInvalidOTPAttemptSelectLazyQuery,
	UserSelect,
	useTrustedDeviceSelectLazyQuery,
	useUserSelectLazyQuery,
} from "../../../../Utilities";
import { LoginRecordLink, MFAInvalidOTPAttemptLink, TrustedDeviceLink, UserLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Session model.
 */
type FieldProps<K extends keyof SessionFormValues.Base = keyof SessionFormValues.Base> = BaseFieldProps<
	Pick<SessionFormValues.Base, K>
>;

/**
 * Generic props for fields of the Session model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof SessionFormValues.Detail = keyof SessionFormValues.Detail> = BaseFieldProps<
	Pick<SessionFormValues.Detail, K>
>;

/**
 * Renders a field component for the `createdAt` field of the Session model.
 */
export function CreatedAt({ formState, formType = FormType.Update }: FieldProps<"createdAt">) {
	return (
		<DateTimeField formState={formState} name="createdAt" optional={FormType.isCreate(formType)} precision="minute" />
	);
}

/**
 * Renders a field component for the `updatedAt` field of the Session model.
 */
export function UpdatedAt({ formState, formType = FormType.Update }: FieldProps<"updatedAt">) {
	return (
		<DateTimeField formState={formState} name="updatedAt" optional={FormType.isCreate(formType)} precision="minute" />
	);
}

/**
 * Renders a field component for the `expiresAt` field of the Session model.
 */
export function ExpiresAt({ formState }: FieldProps<"expiresAt">) {
	return <DateTimeField formState={formState} name="expiresAt" precision="minute" />;
}

/**
 * Renders a field component for the `longestInactiveSec` field of the Session model.
 */
export function LongestInactiveSec({ formState, formType = FormType.Update }: FieldProps<"longestInactiveSec">) {
	return (
		<NumberField
			formState={formState}
			name="longestInactiveSec"
			optional={FormType.isCreate(formType)}
			validationUnit={1}
		/>
	);
}

/**
 * Renders a field component for the `isVerified` field of the Session model.
 */
export function IsVerified({ formState }: FieldProps<"isVerified">) {
	return <BooleanField formState={formState} name="isVerified" />;
}

/**
 * Renders a field component for the `isCurrent` field of the Session model.
 */
export function IsCurrent({ formState }: DetailFieldProps<"isCurrent">) {
	return <BooleanField formState={formState} name="isCurrent" />;
}

export type LoginRecordProps = DetailFieldProps<"loginRecordID"> & {
	currentLoginRecord?: LoginRecordSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `loginRecord` edge of the Session model.
 */
export function LoginRecord({ formState, currentLoginRecord }: LoginRecordProps) {
	const [loadOptions, { loading, data }] = useLoginRecordSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.loginRecordID) {
			loadOptions();
		}
	}, [formState.formEditing.loginRecordID, loadOptions]);
	const options = React.useMemo(
		() => LoginRecordSelect.toOptions(data?.loginRecordConnection.edges, currentLoginRecord),
		[data, currentLoginRecord],
	);

	return (
		<SelectField
			formState={formState}
			name="loginRecordID"
			isLoading={loading}
			options={options}
			optional
			display={displayLoginRecord}
			blankValue={null}
		/>
	);
}

function displayLoginRecord({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <LoginRecordLink instance={{ id }}>{formattedValue}</LoginRecordLink>;
}

export type MFAInvalidOTPAttemptsProps = DetailFieldProps<"mfaInvalidOTPAttemptIDs"> & {
	currentMFAInvalidOTPAttempts?: MFAInvalidOTPAttemptSelect.ModelForOption[];
};

/**
 * Renders a field component for the `mfaInvalidOTPAttempts` edge of the Session model.
 */
export function MFAInvalidOTPAttempts({ formState, currentMFAInvalidOTPAttempts }: MFAInvalidOTPAttemptsProps) {
	const [loadOptions, { loading, data }] = useMFAInvalidOTPAttemptSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.mfaInvalidOTPAttemptIDs) {
			loadOptions();
		}
	}, [formState.formEditing.mfaInvalidOTPAttemptIDs, loadOptions]);
	const options = React.useMemo(
		() =>
			MFAInvalidOTPAttemptSelect.toMultiOptions(
				data?.mfaInvalidOTPAttemptConnection.edges,
				currentMFAInvalidOTPAttempts,
			),
		[data, currentMFAInvalidOTPAttempts],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="mfaInvalidOTPAttemptIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayMFAInvalidOTPAttemptInstance}
		/>
	);
}

function displayMFAInvalidOTPAttemptInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <MFAInvalidOTPAttemptLink instance={{ id }}>{formattedValue}</MFAInvalidOTPAttemptLink>;
}

export type TrustedDeviceProps = DetailFieldProps<"trustedDeviceID"> & {
	currentTrustedDevice?: TrustedDeviceSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `trustedDevice` edge of the Session model.
 */
export function TrustedDevice({ formState, currentTrustedDevice }: TrustedDeviceProps) {
	const [loadOptions, { loading, data }] = useTrustedDeviceSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.trustedDeviceID) {
			loadOptions();
		}
	}, [formState.formEditing.trustedDeviceID, loadOptions]);
	const options = React.useMemo(
		() => TrustedDeviceSelect.toOptions(data?.trustedDeviceConnection.edges, currentTrustedDevice),
		[data, currentTrustedDevice],
	);

	return (
		<SelectField
			formState={formState}
			name="trustedDeviceID"
			isLoading={loading}
			options={options}
			optional
			display={displayTrustedDevice}
			blankValue={null}
		/>
	);
}

function displayTrustedDevice({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <TrustedDeviceLink instance={{ id }}>{formattedValue}</TrustedDeviceLink>;
}

export type UserProps = DetailFieldProps<"userID"> & {
	currentUser?: UserSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `user` edge of the Session model.
 */
export function User({ formState, currentUser }: UserProps) {
	const [loadOptions, { loading, data }] = useUserSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.userID) {
			loadOptions();
		}
	}, [formState.formEditing.userID, loadOptions]);
	const options = React.useMemo(
		() => UserSelect.toOptions(data?.userConnection.edges, currentUser),
		[data, currentUser],
	);

	return (
		<SelectField
			formState={formState}
			name="userID"
			isLoading={loading}
			options={options}
			display={displayUser}
			blankValue={null}
		/>
	);
}

function displayUser({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserLink instance={{ id }}>{formattedValue}</UserLink>;
}
