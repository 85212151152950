import { EnrollmentApplication, EnrollmentApplicationNote, User } from "../../GraphQL";

export type Base = {
	body: EnrollmentApplicationNote["body"];
	enrollmentApplicationID: EnrollmentApplication["id"] | null;
};

export type Create = Base;

export type Detail = Base & {
	createdAt: EnrollmentApplicationNote["createdAt"] | null;
	authorID: User["id"] | null;
};

export const initialCreate: Create = {
	body: "",
	enrollmentApplicationID: null,
};
