import { IndexForms } from "@hex-insights/app-modules";
import { CampusOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the Campus model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		CampusOrderFormValues.initial,
		CampusOrderFormValues.fieldValues,
	);
}
