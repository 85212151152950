import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { MFAFactorOrderFormValues } from "../../../../Utilities";

export type MFAFactorOrderFormControllerProps = {
	formState: FormState<MFAFactorOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the MFAFactor model.
 */
export function MFAFactorOrderFormController({ formState }: MFAFactorOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={MFAFactorOrderFormValues.fieldOptions}
			fieldTypeMapping={MFAFactorOrderFormValues.fieldTypeMapping}
		/>
	);
}
