import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const studentDocumentHubPageInfo = newPageInfo({
	title: "Student Documents",
	description: "Pages related to the Student Document model.",
	exact: false,
	to: hubsPageInfo.to + "/student-documents",
	path: hubsPageInfo.path + "/student-documents",
	parent: hubsPageInfo,
});
