import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	RelationshipFilterFormController,
	RelationshipFilterFormState,
	RelationshipFilterFormUtils,
	RelationshipFormat,
	RelationshipIndexQuery,
	RelationshipOrderField,
	RelationshipOrderFormController,
	RelationshipOrderFormState,
	RelationshipOrderFormValues,
	RelationshipPaginationForm,
	useRelationshipIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { relationshipHubCreatePageInfo } from "../CreatePage/pageinfo";
import { relationshipHubDetailPageInfo } from "../DetailPage/pageinfo";
import { relationshipHubIndexPageInfo } from "./pageinfo";

const columns: (keyof RelationshipIndexQuery["relationshipConnection"]["edges"][0]["node"])[] = [
	"id",
	"relationshipType",
	"isPrimary",
	"isFinancialContact",
	"isEmergencyContact",
	"isAuthorizedForPickup",
	"livesWithStudent",
];

function orderFieldToColumn(field: RelationshipOrderField | null) {
	if (field === null) {
		return "id";
	}
	return RelationshipOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return RelationshipOrderFormValues.modelFieldToOrderField[column];
}

export function RelationshipHubIndexPage() {
	useActivePageRegistration(relationshipHubIndexPageInfo);

	const filterFormState = RelationshipFilterFormState.useFormState();
	const orderFormState = RelationshipOrderFormState.useFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(
		filterFormState.formValues,
		RelationshipFilterFormUtils.toFilterInputs,
	);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useRelationshipIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		RelationshipFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = RelationshipFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.relationshipConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading level={1} noMargin>
					Relationships
				</Heading>

				<InternalLink to={relationshipHubCreatePageInfo.to}>New Relationship</InternalLink>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<RelationshipFilterFormController formState={filterFormState} numActiveFilters={numActiveFilters} />
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<RelationshipOrderFormController formState={orderFormState} />
			</Row>

			<Table.Container className="hub__index-page__table__container">
				<Table columns={columns} minColumnWidth="10rem" {...tableSortingProps} className="hub__index-page__table">
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="id">ID</Table.Heading>
							<Table.Heading column="relationshipType">Relationship Type</Table.Heading>
							<Table.Heading column="isPrimary">Is Primary</Table.Heading>
							<Table.Heading column="isFinancialContact">Is Financial Contact</Table.Heading>
							<Table.Heading column="isEmergencyContact">Is Emergency Contact</Table.Heading>
							<Table.Heading column="isAuthorizedForPickup">Is Authorized for Pickup</Table.Heading>
							<Table.Heading column="livesWithStudent">Lives with Student</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.relationshipConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="id" oneLine numeric>
											<InternalLink to={relationshipHubDetailPageInfo.to(e.node.id)}>{e.node.id}</InternalLink>
										</Table.Cell>
										<Table.Cell column="relationshipType" oneLine>
											{RelationshipFormat.Fields.relationshipType(e.node.relationshipType)}
										</Table.Cell>
										<Table.Cell column="isPrimary" oneLine>
											{RelationshipFormat.Fields.isPrimary(e.node.isPrimary)}
										</Table.Cell>
										<Table.Cell column="isFinancialContact" oneLine>
											{RelationshipFormat.Fields.isFinancialContact(e.node.isFinancialContact)}
										</Table.Cell>
										<Table.Cell column="isEmergencyContact" oneLine>
											{RelationshipFormat.Fields.isEmergencyContact(e.node.isEmergencyContact)}
										</Table.Cell>
										<Table.Cell column="isAuthorizedForPickup" oneLine>
											{RelationshipFormat.Fields.isAuthorizedForPickup(e.node.isAuthorizedForPickup)}
										</Table.Cell>
										<Table.Cell column="livesWithStudent" oneLine>
											{RelationshipFormat.Fields.livesWithStudent(e.node.livesWithStudent)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<RelationshipPaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.relationshipConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
