import { newPageInfo } from "@hex-insights/router";
import { resourcesPageInfo, ResourcesPageRouteParams } from "../pageinfo";

export type ResourcesIndexPageRouteParams = ResourcesPageRouteParams;

export const resourcesIndexPageInfo = newPageInfo({
	name: "Resources Index",
	title: "",
	description: "Index page for resources.",
	exact: true,
	to: resourcesPageInfo.to,
	path: resourcesPageInfo.path,
	parent: resourcesPageInfo,
});
