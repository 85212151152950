import { ExpenseBudget, ExpenseCategory } from "../../GraphQL";

export type Base = {
	budgetMonth: ExpenseBudget["budgetMonth"] | null;
	amount: ExpenseBudget["amount"] | null;
	expenseCategoryID: ExpenseCategory["id"] | null;
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	budgetMonth: null,
	amount: null,
	expenseCategoryID: null,
};
