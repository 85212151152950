import { IndexForms } from "@hex-insights/app-modules";
import { DocumentResourceOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the DocumentResource model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		DocumentResourceOrderFormValues.initial,
		DocumentResourceOrderFormValues.fieldValues,
	);
}
