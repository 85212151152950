import { newPageInfo } from "@hex-insights/router";
import { busHubPageInfo } from "../pageinfo";

export const busHubIndexPageInfo = newPageInfo({
	name: "Buses Index",
	title: "",
	description: "Index of Buses.",
	exact: true,
	to: busHubPageInfo.to,
	path: busHubPageInfo.path,
	parent: busHubPageInfo,
});
