import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	SchoolStudentEnrollmentFilterFormController,
	SchoolStudentEnrollmentFilterFormState,
	SchoolStudentEnrollmentFilterFormUtils,
	SchoolStudentEnrollmentFormat,
	SchoolStudentEnrollmentIndexQuery,
	SchoolStudentEnrollmentLink,
	SchoolStudentEnrollmentOrderField,
	SchoolStudentEnrollmentOrderForm,
	SchoolStudentEnrollmentOrderFormState,
	SchoolStudentEnrollmentOrderFormValues,
	SchoolStudentEnrollmentPaginationForm,
	SchoolStudentEnrollmentSearchForm,
	useSchoolStudentEnrollmentIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { schoolStudentEnrollmentHubCreatePageInfo } from "../CreatePage/pageinfo";
import { schoolStudentEnrollmentHubIndexPageInfo } from "./pageinfo";

const columns: (keyof SchoolStudentEnrollmentIndexQuery["schoolStudentEnrollmentConnection"]["edges"][0]["node"])[] = [
	"id",
	"createdDate",
	"hasPaidFee",
	"isVoided",
];

function orderFieldToColumn(field: SchoolStudentEnrollmentOrderField | null) {
	if (field === null) {
		return "id";
	}
	return SchoolStudentEnrollmentOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return SchoolStudentEnrollmentOrderFormValues.modelFieldToOrderField[column];
}

export function SchoolStudentEnrollmentHubIndexPage() {
	useActivePageRegistration(schoolStudentEnrollmentHubIndexPageInfo);

	const filterFormState = SchoolStudentEnrollmentFilterFormState.useFormState();
	const orderFormState = SchoolStudentEnrollmentOrderFormState.useOrderFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(
		filterFormState.formValues,
		SchoolStudentEnrollmentFilterFormUtils.toFilterInputs,
	);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useSchoolStudentEnrollmentIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		SchoolStudentEnrollmentFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = SchoolStudentEnrollmentFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.schoolStudentEnrollmentConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading.H1 noMargin>School Student Enrollments</Heading.H1>

				<InternalLink to={schoolStudentEnrollmentHubCreatePageInfo.to}>New School Student Enrollment</InternalLink>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<SchoolStudentEnrollmentSearchForm formState={filterFormState} />
				<SchoolStudentEnrollmentFilterFormController formState={filterFormState} numActiveFilters={numActiveFilters} />
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<SchoolStudentEnrollmentOrderForm.OrderController formState={orderFormState} />
			</Row>

			<Table.Container className="hub__index-page__table__container">
				<Table
					columns={columns}
					minColumnWidth="10rem"
					scrollSyncID={paginationFormState.formValues.cursor}
					{...tableSortingProps}
					className="hub__index-page__table"
				>
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="id">ID</Table.Heading>
							<Table.Heading column="createdDate">Created Date</Table.Heading>
							<Table.Heading column="hasPaidFee">Has Paid Fee</Table.Heading>
							<Table.Heading column="isVoided">Is Voided</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.schoolStudentEnrollmentConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="id" oneLine numeric>
											<SchoolStudentEnrollmentLink instance={e.node}>{e.node.id}</SchoolStudentEnrollmentLink>
										</Table.Cell>
										<Table.Cell column="createdDate" oneLine>
											{SchoolStudentEnrollmentFormat.Fields.createdDate(e.node.createdDate)}
										</Table.Cell>
										<Table.Cell column="hasPaidFee" oneLine>
											{SchoolStudentEnrollmentFormat.Fields.hasPaidFee(e.node.hasPaidFee)}
										</Table.Cell>
										<Table.Cell column="isVoided" oneLine>
											{SchoolStudentEnrollmentFormat.Fields.isVoided(e.node.isVoided)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<SchoolStudentEnrollmentPaginationForm.Pagination
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.schoolStudentEnrollmentConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
