import { Conditional, If, mappedArray } from "@hex-insights/core";
import { BasicTable } from "@hex-insights/tables";
import { formatPrice } from "../../Utilities";
import styles from "./styles.module.css";

const pricingTableData = {
	mealPlan: 975_00,
};

export type EnrollmentPricingTableProps = {
	numPayments: number;
	isInterestedInBusPlan: boolean;
	isInterestedInMealPlan: boolean;
	tuitionPrice: number;
};

export function EnrollmentPricingTable({
	numPayments,
	isInterestedInBusPlan,
	isInterestedInMealPlan,
	tuitionPrice,
}: EnrollmentPricingTableProps) {
	const tuitionTotal = tuitionPrice;
	const tuitionInstallment = tuitionTotal / numPayments;

	const mealPlanTotal = pricingTableData.mealPlan;
	const mealPlanInstallment = mealPlanTotal / numPayments;

	const totalTotal = tuitionTotal + (isInterestedInMealPlan ? mealPlanTotal : 0);
	const totalInstallment = tuitionInstallment + (isInterestedInMealPlan ? mealPlanInstallment : 0);

	return (
		<BasicTable className={styles["enrollment-pricing-table"]}>
			<BasicTable.Header style={{ position: "static" }}>
				<BasicTable.Row className={styles["enrollment-pricing-table__header__row"]}>
					<BasicTable.Heading>Tuition &amp; Fees</BasicTable.Heading>
					<Conditional>
						<If condition={numPayments === 4}>
							<BasicTable.Heading>Jun 1</BasicTable.Heading>
							<BasicTable.Heading>Nov 1</BasicTable.Heading>
							<BasicTable.Heading>Feb 1</BasicTable.Heading>
							<BasicTable.Heading>Apr 1</BasicTable.Heading>
						</If>
						<If condition={numPayments === 2}>
							<BasicTable.Heading>Jul 15</BasicTable.Heading>
							<BasicTable.Heading>Feb 1</BasicTable.Heading>
						</If>
						<If condition={numPayments === 1}>
							<BasicTable.Heading>Jul 15</BasicTable.Heading>
						</If>
					</Conditional>
					<BasicTable.Heading>Total</BasicTable.Heading>
				</BasicTable.Row>
			</BasicTable.Header>
			<BasicTable.Body>
				<BasicTable.Row className={styles["enrollment-pricing-table__body__row"]}>
					<BasicTable.Cell oneLine>Tuition</BasicTable.Cell>
					{mappedArray(numPayments, (i) => (
						<BasicTable.Cell key={i} numeric>
							{formatPrice(tuitionInstallment)}
						</BasicTable.Cell>
					))}
					<BasicTable.Cell numeric>{formatPrice(tuitionTotal)}</BasicTable.Cell>
				</BasicTable.Row>
				<If condition={isInterestedInBusPlan}>
					<BasicTable.Row className={styles["enrollment-pricing-table__body__row"]}>
						<BasicTable.Cell oneLine>Bus</BasicTable.Cell>
						{mappedArray(numPayments, (i) => (
							<BasicTable.Cell key={i} numeric>
								TBD
							</BasicTable.Cell>
						))}
						<BasicTable.Cell numeric>TBD</BasicTable.Cell>
					</BasicTable.Row>
				</If>
				<If condition={isInterestedInMealPlan}>
					<BasicTable.Row className={styles["enrollment-pricing-table__body__row"]}>
						<BasicTable.Cell oneLine>Meal Plan</BasicTable.Cell>
						{mappedArray(numPayments, (i) => (
							<BasicTable.Cell key={i} numeric>
								{formatPrice(mealPlanInstallment)}
							</BasicTable.Cell>
						))}
						<BasicTable.Cell numeric>{formatPrice(mealPlanTotal)}</BasicTable.Cell>
					</BasicTable.Row>
				</If>
			</BasicTable.Body>
			<BasicTable.Footer>
				<BasicTable.Row className={styles["enrollment-pricing-table__footer__row"]}>
					<BasicTable.Cell oneLine>Total</BasicTable.Cell>
					{mappedArray(numPayments, (i) => (
						<BasicTable.Cell key={i} numeric>
							{formatPrice(totalInstallment)}
						</BasicTable.Cell>
					))}
					<BasicTable.Cell numeric>{formatPrice(totalTotal)}</BasicTable.Cell>
				</BasicTable.Row>
			</BasicTable.Footer>
		</BasicTable>
	);
}
