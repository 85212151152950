import "@hex-insights/core";
import { LoginRecordDetailQuery } from "../../GraphQL";
import { LoginRecordFormValues } from "../ModelFormValues";

/**
 * Converts a LoginRecord instance to its corresponding detail form values.
 *
 * @param loginRecord The instance to convert.
 */
export function toFormValues(loginRecord: LoginRecordDetailQuery["loginRecord"]): LoginRecordFormValues.Detail {
	return {
		createdAt: loginRecord.createdAt,
		userEmail: loginRecord.userEmail,
		wasBlocked: loginRecord.wasBlocked,
		wasSuccessful: loginRecord.wasSuccessful,
		isTrusted: loginRecord.isTrusted,
		sessionID: loginRecord.session?.id ?? null,
		userID: loginRecord.user?.id ?? null,
	};
}
