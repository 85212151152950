import React from "react";
import { Conditional, Else, Grid, Heading, If, Row, Section, toLocalDateString, useSync } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	HomeRoomForm,
	HomeRoomFormValues,
	HomeRoomMutation,
	PersonIcon,
	ScaledText,
	Tile,
	useEmployeeIndexQuery,
	useHomeRoomDetailQuery,
	useHomeRoomSectionIndexQuery,
	useStudentListQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { employeeHubDetailPageInfo } from "../../Employee/DetailPage/pageinfo";
import { homeRoomSectionHubDetailPageInfo } from "../../HomeRoomSection/DetailPage/pageinfo";
import { studentHubDetailPageInfo } from "../../Student/DetailPage/pageinfo";
import { homeRoomHubDetailPageInfo, HomeRoomHubDetailPageRouteParams } from "./pageinfo";

function HomeRoomHubDetailPageWrapper() {
	// Manages identity of the HomeRoomHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <HomeRoomHubDetailPage key={id} />;
}

export { HomeRoomHubDetailPageWrapper as HomeRoomHubDetailPage };

function HomeRoomHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useHomeRoomDetailQuery({ variables: { id } });
	const homeRoom = data?.homeRoom;
	useActivePageRegistration(homeRoomHubDetailPageInfo, !!homeRoom ? homeRoomHubDetailPageInfo.title(homeRoom) : "");

	const update = HomeRoomMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<HomeRoomFormValues.Detail>, initialFormValues: HomeRoomFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading homeRoom</If>
				<Else>
					{!!homeRoom && (
						<React.Fragment>
							<Heading level={1}>{homeRoom.name}</Heading>

							<Row justify="spaced-start">
								<Tile>
									<Tile.Body>
										<HomeRoomForm.ControlledDetail
											key={syncID}
											homeRoom={homeRoom}
											applyUpdate={applyUpdate}
											onSuccess={synchronize}
										/>
									</Tile.Body>
								</Tile>

								<Tile style={{ flexGrow: 1, width: "100%", overflow: "hidden" }}>
									<Tile.Body>
										<EmployeesSection />
										<StudentsSection />
										<HomeRoomSectionsSection />
									</Tile.Body>
								</Tile>
							</Row>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}

// TODO unite with campus detail page

function EmployeesSection() {
	const { id } = useRouteParams<HomeRoomHubDetailPageRouteParams>();
	const today = toLocalDateString(new Date());
	const { loading, data } = useEmployeeIndexQuery({
		variables: {
			filters: {
				homeRoomSectionTeacherEnrollments: [
					{
						homeRoomSection: [
							{
								homeRoomIDEQ: id,
								term: [{ startDateLTE: today, endDateGTE: today }],
							},
						],
					},
				],
			},
		},
	});

	return (
		<Section>
			<Section.Header>
				<Heading level={3} noMargin>
					Teachers
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Row justify="spaced-start" overflow="scroll" style={{ width: "100%" }}>
							{data?.employeeConnection.edges.map((e) => (
								<InternalLink
									key={e.node.id}
									to={employeeHubDetailPageInfo.to(e.node.id)}
									className="link--no-text-decoration"
								>
									<PersonIcon person={e.node.person} />
								</InternalLink>
							))}
						</Row>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}

function StudentsSection() {
	const { id } = useRouteParams<HomeRoomHubDetailPageRouteParams>();
	const today = toLocalDateString(new Date());
	const { loading, data } = useStudentListQuery({
		variables: {
			filters: [
				{
					homeRoomSectionStudentEnrollments: [
						{
							homeRoomSection: [
								{
									homeRoomIDEQ: id,
									term: [{ startDateLTE: today, endDateGTE: today }],
								},
							],
						},
					],
				},
			],
		},
	});

	return (
		<Section>
			<Section.Header>
				<Heading level={3} noMargin>
					Students
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Row justify="spaced-start" overflow="scroll" style={{ width: "100%" }}>
							{data?.studentConnection.edges.map((e) => (
								<InternalLink
									key={e.node.id}
									to={studentHubDetailPageInfo.to(e.node.id)}
									className="link--no-text-decoration"
								>
									<PersonIcon person={e.node.person} />
								</InternalLink>
							))}
						</Row>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}

function HomeRoomSectionsSection() {
	const { id } = useRouteParams<HomeRoomHubDetailPageRouteParams>();

	const { loading, data } = useHomeRoomSectionIndexQuery({
		variables: {
			filters: {
				homeRoomIDEQ: id,
			},
		},
	});

	return (
		<Section>
			<Section.Header>
				<Heading level={3} noMargin>
					Home Room Sections
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Grid columns={4} gap="1rem">
							{data?.homeRoomSectionConnection.edges.map((e) => (
								<InternalLink
									key={e.node.id}
									to={homeRoomSectionHubDetailPageInfo.to(e.node.id)}
									className="link--no-text-decoration"
									style={{
										width: "100%",
										height: "5rem",
										padding: "0.25rem",
										border: "2px solid var(--light-blue)",
										borderRadius: "0.5rem",
										display: "block",
									}}
								>
									<Row justify="center" align="center" style={{ width: "100%", height: "100%" }}>
										<ScaledText maxFontSize={24} style={{ textAlign: "center", maxWidth: "100%", maxHeight: "100%" }}>
											{e.node.name}
										</ScaledText>
									</Row>
								</InternalLink>
							))}
						</Grid>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}
