import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { PaymentOrderFormValues } from "../../../../Utilities";

export type PaymentOrderFormControllerProps = {
	formState: FormState<PaymentOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the Payment model.
 */
export function PaymentOrderFormController({ formState }: PaymentOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={PaymentOrderFormValues.fieldOptions}
			fieldTypeMapping={PaymentOrderFormValues.fieldTypeMapping}
		/>
	);
}
