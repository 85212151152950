import { IndexForms } from "@hex-insights/app-modules";
import { CourseSectionOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the CourseSection model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		CourseSectionOrderFormValues.initial,
		CourseSectionOrderFormValues.fieldValues,
	);
}
