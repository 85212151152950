import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	MealMenuForm,
	MealMenuFormValues,
	MealMenuMutation,
	useMealMenuDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { mealMenuHubDetailPageInfo } from "./pageinfo";

function MealMenuHubDetailPageWrapper() {
	// Manages identity of the MealMenuHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <MealMenuHubDetailPage key={id} />;
}

export { MealMenuHubDetailPageWrapper as MealMenuHubDetailPage };

function MealMenuHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useMealMenuDetailQuery({ variables: { id } });
	const mealMenu = data?.mealMenu;
	useActivePageRegistration(mealMenuHubDetailPageInfo, !!mealMenu ? mealMenuHubDetailPageInfo.title(mealMenu) : "");

	const update = MealMenuMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<MealMenuFormValues.Detail>, initialFormValues: MealMenuFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading mealMenu</If>
				<Else>
					{!!mealMenu && (
						<React.Fragment>
							<Heading level={1}>{mealMenu.id}</Heading>

							<MealMenuForm.ControlledDetail
								key={syncID}
								mealMenu={mealMenu}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
