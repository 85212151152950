import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export type BudgetPageRouteParams = {};

export const budgetPageInfo = newPageInfo({
	title: "Budget",
	description: "Manage the school budget.",
	exact: false,
	to: "/budget",
	path: "/budget",
	parent: rootPageInfo,
});
