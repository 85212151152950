import { newPageInfo, newRouteParamsType } from "@hex-insights/router";
import { Tuition } from "@hex-insights/verita.shared";
import { tuitionHubPageInfo } from "../pageinfo";

type TuitionForRouteTitle = Pick<Tuition, "name">;

export const tuitionHubDetailPageInfo = newPageInfo({
	name: "Tuition Detail",
	title: (e: TuitionForRouteTitle) => e.name,
	description: "Detail page for an tuition.",
	exact: false,
	isLeaf: true,
	parent: tuitionHubPageInfo,
	to: (id: Tuition["id"]) => tuitionHubPageInfo.to + `/${id}`,
	path: tuitionHubPageInfo.path + "/:tuitionID(\\d+)",
	paramsType: newRouteParamsType(tuitionHubPageInfo.paramsType, "tuitionID"),
});
