import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { EmployeeHubCreatePage } from "./CreatePage";
import { employeeHubCreatePageInfo } from "./CreatePage/pageinfo";
import { EmployeeHubDetailPage } from "./DetailPage";
import { employeeHubDetailPageInfo } from "./DetailPage/pageinfo";
import { EmployeeHubIndexPage } from "./IndexPage";
import { employeeHubIndexPageInfo } from "./IndexPage/pageinfo";
import { employeeHubPageInfo } from "./pageinfo";

export function EmployeeHub() {
	useActivePageRegistration(employeeHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...employeeHubIndexPageInfo.routeProps}>
				<EmployeeHubIndexPage />
			</Route>
			<Route {...employeeHubCreatePageInfo.routeProps}>
				<EmployeeHubCreatePage />
			</Route>
			<Route {...employeeHubDetailPageInfo.routeProps}>
				<EmployeeHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
