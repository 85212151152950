import { FieldInputOption } from "@hex-insights/forms";
import { LoginRecordFormat } from "../../Format";
import { LoginRecord, LoginRecordNameFragment, Maybe } from "../../GraphQL";

/**
 * A slim version of the LoginRecord model for use in select fields.
 */
export type ModelForOption = Pick<LoginRecord, "id"> & LoginRecordNameFragment;

/**
 * A slim version of the LoginRecord model edge for use in select fields.
 */
export type EdgeForOption = {
	node: ModelForOption;
};

/**
 * Converts the given `edges` to options to be given to a radio or select field.
 *
 * @param edges The edges to convert to options.
 * @param current The current instance for the field. Used to display when `edges` is blank (e.g. with lazy loading).
 */
export function toOptions(edges?: EdgeForOption[], current?: Maybe<ModelForOption>) {
	if (edges === undefined) {
		if (!(current === null || current === undefined)) {
			return [toOption(current)];
		}
		return [];
	}
	return edges.map((e) => toOption(e.node));
}

/**
 * Converts the given `edges` to options to be given to a multi-select field.
 *
 * @param edges The edges to convert to options.
 * @param current The current instances for the field. Used to display when `edges` is blank (e.g. with lazy loading).
 */
export function toMultiOptions(edges?: EdgeForOption[], currents?: ModelForOption[]) {
	if (edges === undefined) {
		if (currents !== undefined) {
			return currents.map(toOption);
		}
		return [];
	}
	return edges.map((e) => toOption(e.node));
}

/**
 * Converts a single LoginRecord instance to an option to be given to a radio, select, or
 * multi-select field.
 *
 * @param instance The instance to convert to an option.
 */
export function toOption(instance: ModelForOption): FieldInputOption<LoginRecord["id"]> {
	return {
		label: LoginRecordFormat.name(instance),
		value: instance.id,
	};
}
