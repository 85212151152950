import { Models } from "@hex-insights/app-modules";
import { DocumentResourceFolder, DocumentResourceFolderMutation } from "../../../../Utilities";

export type DocumentResourceFolderDeleteButtonProps = Omit<Models.DeleteButtonProps, "deleteInstance"> & {
	instanceID: DocumentResourceFolder["id"];
};

/**
 * A delete button for the DocumentResourceFolder model.
 */
export function DocumentResourceFolderDeleteButton({
	instanceID,
	confirmationContent = "Are you sure you want to delete this folder?",
	children = "Delete Folder",
	...props
}: DocumentResourceFolderDeleteButtonProps) {
	const del = DocumentResourceFolderMutation.useDelete(instanceID);

	return (
		<Models.DeleteButton deleteInstance={del} confirmationContent={confirmationContent} {...props}>
			{children}
		</Models.DeleteButton>
	);
}
