import { newPageInfo } from "@hex-insights/router";
import { dashboardsPageInfo } from "../pageinfo";

export const dashboardsFrontDeskPageInfo = newPageInfo({
	name: "Front Desk Dashboard",
	title: "Front Desk",
	description: "Front desk dashboard.",
	exact: true,
	to: dashboardsPageInfo.to + "/front-desk",
	path: dashboardsPageInfo.path + "/front-desk",
	parent: dashboardsPageInfo,
});
