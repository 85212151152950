import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const mfaInvalidOTPAttemptHubPageInfo = newPageInfo({
	title: "MFA Invalid OTP Attempts",
	description: "Pages related to the MFA Invalid OTP Attempt model.",
	exact: false,
	to: hubsPageInfo.to + "/mfa-invalid-otp-attempts",
	path: hubsPageInfo.path + "/mfa-invalid-otp-attempts",
	parent: hubsPageInfo,
});
