import React from "react";
import {
	addTimeToDate,
	Button,
	ClassNameProps,
	Icon,
	Modal,
	Row,
	StyleProps,
	toLocalDateString,
} from "@hex-insights/core";
import { InternalLink } from "@hex-insights/router";
import {
	BusIcon,
	EmployeeWrapperContext,
	MealIcon,
	partitionStudentSchoolAttendance,
	SchoolAttendanceOverviewModal,
	StudentFilterFormUtils,
	StudentFilterFormValues,
	Tile,
	useSchoolAttendanceRecordDailyReportListQuery,
} from "@hex-insights/verita.shared";
import { busListIndexPageInfo } from "../../../../Pages/BusList/IndexPage/pageinfo";
import { mealPlanListPageInfo } from "../../../../Pages/MealPlanList/pageinfo";
import styles from "./styles.module.css";

export type AttendanceTileProps = {
	tileStyle?: React.CSSProperties;
} & Partial<ClassNameProps & StyleProps>;

export function AttendanceTile({ tileStyle, className, style }: AttendanceTileProps) {
	const { employee } = React.useContext(EmployeeWrapperContext);
	const campusIDs = employee?.campuses.map((e) => e.id) ?? [];
	const { data } = useSchoolAttendanceRecordDailyReportListQuery({
		variables: {
			filters: StudentFilterFormUtils.toFilterInputs({
				...StudentFilterFormValues.initial,
				hasHomeRoomSectionStudentEnrollments: true,
				campusIDs,
			}),
			attendanceFilters: {
				checkInTimeGTE: toLocalDateString(new Date()),
				checkInTimeLT: toLocalDateString(addTimeToDate(new Date(), [1, "day"])),
			},
		},
	});

	const { presentList, absentList, notRecordedList } = React.useMemo(
		() => partitionStudentSchoolAttendance(data?.studentConnection.edges),
		[data],
	);
	const studentsPresent = presentList.length;
	const totalStudents = presentList.length + absentList.length + notRecordedList.length;

	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<Button onClick={toggleIsModalOpen} className={className} style={{ padding: 0, border: "none", ...style }}>
				<Tile style={tileStyle}>
					<Tile.Body className={styles["quick-list__tile__body"]}>
						<Row justify="center" className={styles["quick-list__row"]}>
							<Icon.UserCheck size="1.25rem" className={styles["quick-list__icon"]} />
							<span>
								Attendance: {studentsPresent}/{totalStudents}
							</span>
						</Row>
					</Tile.Body>
				</Tile>
			</Button>

			<Modal.If condition={isModalOpen}>
				<SchoolAttendanceOverviewModal onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

export type BusPlanListTileProps = Partial<ClassNameProps & StyleProps>;

export function BusPlanListTile({ className, style }: BusPlanListTileProps) {
	return (
		<Tile className={className} style={style}>
			<Tile.Body className={styles["quick-list__tile__body"]}>
				<InternalLink
					to={busListIndexPageInfo.to}
					style={{ "--internal-link---text-decoration": "none" } as React.CSSProperties}
				>
					<Row justify="center" className={styles["quick-list__row"]}>
						<BusIcon size="2rem" className={styles["quick-list__icon"]} style={{ marginLeft: 0 }} />
						<span>Bus List</span>
					</Row>
				</InternalLink>
			</Tile.Body>
		</Tile>
	);
}

export type MealPlanListTileProps = Partial<ClassNameProps & StyleProps>;

export function MealPlanListTile({ className, style }: MealPlanListTileProps) {
	return (
		<Tile className={className} style={style}>
			<Tile.Body className={styles["quick-list__tile__body"]}>
				<InternalLink
					to={mealPlanListPageInfo.to}
					style={{ "--internal-link---text-decoration": "none" } as React.CSSProperties}
				>
					<Row justify="center" className={styles["quick-list__row"]}>
						<MealIcon size="1.25rem" className={styles["quick-list__icon"]} />
						<span>Meal List</span>
					</Row>
				</InternalLink>
			</Tile.Body>
		</Tile>
	);
}
