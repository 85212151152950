import { TrustedDevice } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the createdAt field of the TrustedDevice model.
 */
export function createdAt(value: TrustedDevice["createdAt"] | null): string {
	return toString(value);
}

/**
 * Formats the name field of the TrustedDevice model.
 */
export function name(value: TrustedDevice["name"]): string {
	return toString(value);
}

/**
 * Formats the isCurrent field of the TrustedDevice model.
 */
export function isCurrent(value: TrustedDevice["isCurrent"]): string {
	return toString(value);
}
