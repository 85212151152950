import { newPageInfo } from "@hex-insights/router";
import { homeRoomHubPageInfo } from "../pageinfo";

export const homeRoomHubCreatePageInfo = newPageInfo({
	name: "New Home Room",
	title: "New",
	description: "Create a new Home Room record.",
	exact: true,
	to: homeRoomHubPageInfo.to + "/new",
	path: homeRoomHubPageInfo.path + "/new",
	parent: homeRoomHubPageInfo,
});
