import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const busPlanHubPageInfo = newPageInfo({
	title: "Bus Plans",
	description: "Pages related to the Bus Plan model.",
	exact: false,
	to: hubsPageInfo.to + "/bus-plans",
	path: hubsPageInfo.path + "/bus-plans",
	parent: hubsPageInfo,
});
