import { ChildrenProps, ClassNameProps, Column, makeClassName, Section, StyleProps } from "@hex-insights/core";
import styles from "./styles.module.css";

export type ResourcesRowSectionProps = {
	icon?: React.ReactNode;
	label?: React.ReactNode;
} & Partial<ClassNameProps & StyleProps & ChildrenProps>;

export function ResourcesRowSection({ icon, label, className, style, children }: ResourcesRowSectionProps) {
	return (
		<Section className={makeClassName(styles["resources-row-section"], className)} style={style}>
			<Section.Body style={{ width: "100%", overflowX: "hidden" }}>
				<Column justify="spaced-start" align="center" className={styles["resources-row-section__icon-label-column"]}>
					{icon}
					{!!label && <span className={styles["resources-row-section__label"]}>{label}</span>}
				</Column>

				{children}
			</Section.Body>
		</Section>
	);
}
