import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const expenseHubPageInfo = newPageInfo({
	title: "Expenses",
	description: "Pages related to the Expense model.",
	exact: false,
	to: hubsPageInfo.to + "/expenses",
	path: hubsPageInfo.path + "/expenses",
	parent: hubsPageInfo,
});
