import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	UserGroupForm,
	UserGroupFormValues,
	UserGroupMutation,
	useUserGroupDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { userGroupHubDetailPageInfo } from "./pageinfo";

function UserGroupHubDetailPageWrapper() {
	// Manages identity of the UserGroupHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <UserGroupHubDetailPage key={id} />;
}

export { UserGroupHubDetailPageWrapper as UserGroupHubDetailPage };

function UserGroupHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useUserGroupDetailQuery({ variables: { id } });
	const userGroup = data?.userGroup;
	useActivePageRegistration(userGroupHubDetailPageInfo, !!userGroup ? userGroupHubDetailPageInfo.title(userGroup) : "");

	const update = UserGroupMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<UserGroupFormValues.Detail>, initialFormValues: UserGroupFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading userGroup</If>
				<Else>
					{!!userGroup && (
						<React.Fragment>
							<Heading level={1}>{userGroup.name}</Heading>

							<UserGroupForm.ControlledDetail
								key={syncID}
								userGroup={userGroup}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
