import { IndexForms } from "@hex-insights/app-modules";
import { ReportCardOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the ReportCard model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		ReportCardOrderFormValues.initial,
		ReportCardOrderFormValues.fieldValues,
	);
}
