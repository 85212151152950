import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { NoteFilterInput } from "../../GraphQL";
import { NoteFilterFormValues } from "../ModelFilterFormValues";

/**
 * Counts the number of active filters for the Note model.
 *
 * @param formValues The form values of the filter form.
 */
export function countActiveFilters(formValues: NoteFilterFormValues.FormValues) {
	// TODO count quick filters
	const numAdvancedFilters = IndexForms.countActiveAdvancedFilters(
		formValues,
		NoteFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	);
	return numAdvancedFilters;
}

/**
 * Clears the values of the filter form for the Note model.
 *
 * @param formState The form state of the filter form.
 */
export function clearFilters(formState: FormState<NoteFilterFormValues.FormValues>) {
	// TODO clear quick filters
	formState.formClearFunctions.filterSets();
}

/**
 * Resets the values of the filter form for the Note model.
 *
 * @param formState The form state of the filter form.
 */
export function resetFilters(formState: FormState<NoteFilterFormValues.FormValues>) {
	// TODO reset quick filters
	formState.formResetFunctions.filterSets();
}

/**
 * Converts the form values of the filter form for the Note model to the correct GraphQL
 * filter input type.
 *
 * @param formValues The form values of the filter form.
 */
export function toFilterInputs(formValues: NoteFilterFormValues.FormValues): NoteFilterInput | NoteFilterInput[] {
	return IndexForms.toFilterInput(
		formValues,
		NoteFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	) as NoteFilterInput;
}
