import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { MealMenuFilterInput, MealMenuOrder, useMealMenuPaginationQuery } from "../../../../Utilities";

export type MealMenuPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: MealMenuFilterInput | MealMenuFilterInput[] | null;
	order: MealMenuOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the MealMenu model.
 */
export function MealMenuPaginationForm({ formState, filters, order, setPaginationInfo }: MealMenuPaginationFormProps) {
	const { loading, data } = useMealMenuPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.mealMenuConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
