import React from "react";
import {
	CreateFunction,
	onCreateSubmit,
	onUpdateSubmit,
	UpdateFunction,
	useApplyCreate,
	useApplyUpdate,
} from "@hex-insights/app-modules";
import { ClassNameProps, DistributiveOmit, StyleProps } from "@hex-insights/core";
import {
	Form,
	FormEditButtonPair,
	FormState,
	FormSubmitFunction,
	SubmitButton,
	useOnSuccess,
} from "@hex-insights/forms";
import {
	SchoolStudentEnrollmentCreateMutation,
	SchoolStudentEnrollmentDetailQuery,
	SchoolStudentEnrollmentFormConversion,
	SchoolStudentEnrollmentFormState,
	SchoolStudentEnrollmentFormValues,
	SchoolStudentEnrollmentMutation,
	SchoolStudentEnrollmentUpdateMutation,
} from "../../../../Utilities";
import { BaseFormProps } from "../Shared";
import * as Field from "./Field";

export type ControlledCreateProps = Omit<
	CreateProps<SchoolStudentEnrollmentCreateMutation["createSchoolStudentEnrollment"]>,
	"formState" | "create"
> & {
	formStateArgs?: Partial<SchoolStudentEnrollmentFormState.UseCreateFormStateArgs>;
};

/**
 * Renders the create form of the SchoolStudentEnrollment model using an internally managed form state.
 */
export function ControlledCreate({ formStateArgs, ...props }: ControlledCreateProps) {
	const formState = SchoolStudentEnrollmentFormState.useCreateFormState(formStateArgs);
	const create = SchoolStudentEnrollmentMutation.useCreate();

	return <Create formState={formState} create={create} {...props} />;
}

export type CreateProps<T> = {
	formState: FormState<SchoolStudentEnrollmentFormValues.Create>;
	create: CreateFunction<SchoolStudentEnrollmentFormValues.Create, T>;
	onSuccess: (data: T) => void;
	formNameSuffix?: string;
} & BaseFormProps<SchoolStudentEnrollmentFormValues.Create> &
	Partial<ClassNameProps & StyleProps>;

/**
 * Renders the create form of the SchoolStudentEnrollment model using the given form state.
 */
export function Create<T>({ formState, create, onSuccess, formNameSuffix, ...props }: CreateProps<T>) {
	const { applyCreate, resultRef } = useApplyCreate(create);
	const onSubmit = React.useCallback<FormSubmitFunction<SchoolStudentEnrollmentFormValues.Create>>(
		async ({ formValues }) => onCreateSubmit(formValues, applyCreate),
		[applyCreate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	const formName = "schoolStudentEnrollment.create" + (formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form name={formName} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} {...props}>
			<Form.Column>
				<CreateFields formState={formState} />

				<Form.ActionBar>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Form.ActionBar>
			</Form.Column>
		</Form>
	);
}

type CreateFieldsProps = {
	formState: FormState<SchoolStudentEnrollmentFormValues.Create>;
};

function CreateFields({ formState }: CreateFieldsProps) {
	return (
		<React.Fragment>
			<Field.Student formState={formState} />
			<Field.AcademicYear formState={formState} />
			<Field.ClassYear formState={formState} />
			<Field.CreatedDate formState={formState} />
			<Field.PaymentDueDate formState={formState} />
			<Field.HasPaidFee formState={formState} />
			<Field.IsVoided formState={formState} />
		</React.Fragment>
	);
}

export type ControlledDetailProps = Omit<
	DetailProps<SchoolStudentEnrollmentUpdateMutation["updateSchoolStudentEnrollment"]>,
	"formState" | "update"
> & {
	formStateArgs?: Partial<
		DistributiveOmit<SchoolStudentEnrollmentFormState.UseDetailFormStateArgs, "initialFormValues">
	>;
};

/**
 * Renders the detail form of the SchoolStudentEnrollment model using an internally managed form state.
 */
export function ControlledDetail({ formStateArgs, ...props }: ControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => SchoolStudentEnrollmentFormConversion.toFormValues(props.schoolStudentEnrollment),
		[props.schoolStudentEnrollment],
	);
	const formState = SchoolStudentEnrollmentFormState.useDetailFormState({ ...formStateArgs, initialFormValues });
	const update = SchoolStudentEnrollmentMutation.useUpdate(props.schoolStudentEnrollment.id);

	return <Detail formState={formState} update={update} {...props} />;
}

export type DetailProps<T> = {
	schoolStudentEnrollment: SchoolStudentEnrollmentDetailQuery["schoolStudentEnrollment"];
	formState: FormState<SchoolStudentEnrollmentFormValues.Detail>;
	update: UpdateFunction<SchoolStudentEnrollmentFormValues.Detail, T>;
	onSuccess: (data: T) => void;
	formNameSuffix?: string;
} & BaseFormProps<SchoolStudentEnrollmentFormValues.Detail> &
	Partial<ClassNameProps & StyleProps>;

/**
 * Renders the detail form of the SchoolStudentEnrollment model using the given form state.
 */
export function Detail<T>({
	formState,
	schoolStudentEnrollment,
	update,
	onSuccess,
	formNameSuffix,
	...props
}: DetailProps<T>) {
	const { applyUpdate, resultRef } = useApplyUpdate(update);
	const onSubmit = React.useCallback<FormSubmitFunction<SchoolStudentEnrollmentFormValues.Detail>>(
		async (formState) => onUpdateSubmit(formState, applyUpdate),
		[applyUpdate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	const formName =
		`schoolStudentEnrollment.detail.${schoolStudentEnrollment.id}` + (formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form name={formName} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} {...props}>
			<Form.Column>
				<FormEditButtonPair formState={formState} />

				<DetailFields formState={formState} schoolStudentEnrollment={schoolStudentEnrollment} />
			</Form.Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = Omit<ReadOnlyProps, "formState"> & {
	formStateArgs?: Partial<
		DistributiveOmit<SchoolStudentEnrollmentFormState.UseReadOnlyFormStateArgs, "initialFormValues">
	>;
};

/**
 * Renders a read-only detail form of the SchoolStudentEnrollment model using an internally managed form state.
 */
export function ControlledReadOnly({ formStateArgs, ...props }: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => SchoolStudentEnrollmentFormConversion.toFormValues(props.schoolStudentEnrollment),
		[props.schoolStudentEnrollment],
	);
	const formState = SchoolStudentEnrollmentFormState.useReadOnlyFormState({ ...formStateArgs, initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = {
	schoolStudentEnrollment: SchoolStudentEnrollmentDetailQuery["schoolStudentEnrollment"];
	formState: FormState<SchoolStudentEnrollmentFormValues.Detail>;
} & Partial<ClassNameProps & StyleProps>;

/**
 * Renders a read-only detail form of the SchoolStudentEnrollment model using the given form state.
 */
export function ReadOnly({ formState, schoolStudentEnrollment, className, style }: ReadOnlyProps) {
	return (
		<div className={className} style={style}>
			<Form.Column>
				<DetailFields formState={formState} schoolStudentEnrollment={schoolStudentEnrollment} />
			</Form.Column>
		</div>
	);
}

type DetailFieldsProps = {
	schoolStudentEnrollment: SchoolStudentEnrollmentDetailQuery["schoolStudentEnrollment"];
	formState: FormState<SchoolStudentEnrollmentFormValues.Detail>;
};

function DetailFields({ formState, schoolStudentEnrollment }: DetailFieldsProps) {
	return (
		<React.Fragment>
			<Field.Student formState={formState} currentStudent={schoolStudentEnrollment.student} />
			<Field.AcademicYear formState={formState} currentAcademicYear={schoolStudentEnrollment.academicYear} />
			<Field.ClassYear formState={formState} currentClassYear={schoolStudentEnrollment.classYear} />
			<Field.CreatedDate formState={formState} />
			<Field.PaymentDueDate formState={formState} />
			<Field.HasPaidFee formState={formState} />
			<Field.IsVoided formState={formState} />
		</React.Fragment>
	);
}
