import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { ContractInstallmentOrderFormValues } from "../../../../Utilities";

export type ContractInstallmentOrderFormControllerProps = {
	formState: FormState<ContractInstallmentOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the ContractInstallment model.
 */
export function ContractInstallmentOrderFormController({ formState }: ContractInstallmentOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={ContractInstallmentOrderFormValues.fieldOptions}
			fieldTypeMapping={ContractInstallmentOrderFormValues.fieldTypeMapping}
		/>
	);
}
