import { AcademicYearTuition, Student, Tuition } from "../../GraphQL";

export type Base = {
	name: Tuition["name"];
	initialYear: Tuition["initialYear"] | null;
	academicYearTuitionIDs: AcademicYearTuition["id"][];
	studentIDs: Student["id"][];
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	name: "",
	initialYear: null,
	academicYearTuitionIDs: [],
	studentIDs: [],
};
