import React from "react";
import { DateTimeField, FieldDisplayArgs, FormType, RadioField, SelectField } from "@hex-insights/forms";
import { EnrollmentInvitationFormValues, StudentSelect, useStudentSelectLazyQuery } from "../../../../Utilities";
import { StudentLink } from "../../../Links";
import { FutureBaseFieldProps as BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the EnrollmentInvitation model.
 */
type FieldProps<K extends keyof EnrollmentInvitationFormValues.Base = keyof EnrollmentInvitationFormValues.Base> =
	BaseFieldProps<Pick<EnrollmentInvitationFormValues.Base, K>>;

/**
 * Generic props for fields of the EnrollmentInvitation model that only appear in the detail form.
 */
type DetailFieldProps<
	K extends keyof EnrollmentInvitationFormValues.Detail = keyof EnrollmentInvitationFormValues.Detail,
> = BaseFieldProps<Pick<EnrollmentInvitationFormValues.Detail, K>>;

/**
 * Renders a field component for the `createdAt` field of the EnrollmentInvitation model.
 */
export function CreatedAt({ formState, ...props }: DetailFieldProps<"createdAt">) {
	return (
		<DateTimeField
			formState={formState}
			name="createdAt"
			optional={FormType.isCreate(formState.formType)}
			precision="minute"
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `response` field of the EnrollmentInvitation model.
 */
export function Response({ formState, ...props }: FieldProps<"response">) {
	return (
		<RadioField
			formState={formState}
			name="response"
			options={EnrollmentInvitationFormValues.responseOptions}
			blankValue={null}
			optional={FormType.isCreate(formState.formType)}
			{...props}
		/>
	);
}

export type StudentProps = FieldProps<"studentID"> & {
	currentStudent?: StudentSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `student` edge of the EnrollmentInvitation model.
 */
export function Student({ formState, currentStudent, ...props }: StudentProps) {
	const [loadOptions, { loading, data }] = useStudentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.studentID) {
			loadOptions();
		}
	}, [formState.formEditing.studentID, loadOptions]);
	const options = React.useMemo(
		() => StudentSelect.toOptions(data?.studentConnection.edges, currentStudent),
		[data, currentStudent],
	);

	return (
		<SelectField
			formState={formState}
			name="studentID"
			isLoading={loading}
			options={options}
			optional
			display={displayStudent}
			blankValue={null}
			{...props}
		/>
	);
}

function displayStudent({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <StudentLink instance={{ id }}>{formattedValue}</StudentLink>;
}
