import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { SchoolStudentEnrollmentOrderFormValues } from "../../../../Utilities";

export type OrderControllerProps = {
	formState: FormState<SchoolStudentEnrollmentOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the SchoolStudentEnrollment model.
 */
export function OrderController({ formState }: OrderControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={SchoolStudentEnrollmentOrderFormValues.fieldOptions}
			fieldTypeMapping={SchoolStudentEnrollmentOrderFormValues.fieldTypeMapping}
		/>
	);
}
