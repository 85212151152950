import { newPageInfo } from "@hex-insights/router";
import { serviceHubPageInfo } from "../pageinfo";

export const serviceHubIndexPageInfo = newPageInfo({
	name: "Services Index",
	title: "",
	description: "Index of Services.",
	exact: true,
	to: serviceHubPageInfo.to,
	path: serviceHubPageInfo.path,
	parent: serviceHubPageInfo,
});
