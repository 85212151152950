import { IndexForms } from "@hex-insights/app-modules";
import { omitKeys } from "@hex-insights/core";
import { FormState } from "@hex-insights/forms";
import { ParentFilterInput } from "../../GraphQL";
import { ParentFilterFormValues } from "../ModelFilterFormValues";

/**
 * Counts the number of active filters for the Parent model.
 *
 * @param formValues The form values of the filter form.
 */
export function countActiveFilters(formValues: ParentFilterFormValues.FormValues) {
	// TODO count quick filters
	const numAdvancedFilters = IndexForms.countActiveAdvancedFilters(
		formValues,
		ParentFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	);
	return numAdvancedFilters;
}

/**
 * Clears the values of the filter form for the Parent model.
 *
 * @param formState The form state of the filter form.
 */
export function clearFilters(formState: FormState<ParentFilterFormValues.FormValues>) {
	// TODO clear quick filters
	formState.formClearFunctions.filterSets();
}

/**
 * Resets the values of the filter form for the Parent model.
 *
 * @param formState The form state of the filter form.
 */
export function resetFilters(formState: FormState<ParentFilterFormValues.FormValues>) {
	// TODO reset quick filters
	formState.formResetFunctions.filterSets();
}

/**
 * Converts the form values of the filter form for the Parent model to the correct GraphQL
 * filter input type.
 *
 * @param formValues The form values of the filter form.
 */
export function toFilterInputs(formValues: ParentFilterFormValues.FormValues): ParentFilterInput | ParentFilterInput[] {
	const preparedFormValues = omitKeys(formValues, ["search"]);
	const filterInput = IndexForms.toFilterInput(
		preparedFormValues,
		ParentFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	) as ParentFilterInput;

	if (formValues.search !== "") {
		filterInput.person = [{ search: formValues.search }];
	}

	return filterInput;
}
