import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const addressHubPageInfo = newPageInfo({
	title: "Addresses",
	description: "Pages related to the Address model.",
	exact: false,
	to: hubsPageInfo.to + "/addresses",
	path: hubsPageInfo.path + "/addresses",
	parent: hubsPageInfo,
});
