import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { InvoiceFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the Invoice model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => InvoiceFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(initialFormValues, InvoiceFilterFormValues.typeFilterObject);
}
