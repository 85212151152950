import React from "react";
import { GraphQLError } from "graphql";
import {
	Button,
	ButtonProps,
	ClassNameProps,
	Column,
	Conditional,
	Drawer,
	DrawerProps,
	Else,
	Heading,
	Icon,
	If,
	makeClassName,
	Modal,
	ModalProps,
	NotificationContainer,
	RequiredKeys,
	Row,
	StyleProps,
	useNotifications,
	useOpenToggle,
	useToggle,
} from "@hex-insights/core";
import { PermissionRequired } from "@hex-insights/permissioning";
import { InternalLink, useHistory, useRouteMatch } from "@hex-insights/router";
import {
	DocumentResourceFolder,
	DocumentResourceFolderDeleteButton,
	DocumentResourceFolderDetailQuery,
	DocumentResourceFolderForm,
	DocumentResourceFolderFormConversion,
	DocumentResourceFolderFormState,
	DocumentResourceFolderFormValues,
	DocumentResourceFolderMutation,
	DocumentResourceFormValues,
	DocumentResourceMutation,
	permissions,
	useDocumentResourceFolderDetailQuery,
	useDocumentResourceFolderUpdateMutation,
	useDocumentResourceUpdateMutation,
} from "@hex-insights/verita.shared";
import {
	resourcesFoldersDetailPageInfo,
	ResourcesFoldersDetailPageRouteParams,
} from "../../Folders/DetailPage/pageinfo";
import { resourcesIndexPageInfo } from "../../IndexPage/pageinfo";
import { getDocumentResourceFolderOnDragStart, getOnDrop, onDragOver } from "../../Utilities";
import styles from "./styles.module.css";

export type DocumentResourceFolderDisplayProps = {
	documentResourceFolder: Pick<DocumentResourceFolder, "id" | "name">;
	draggable?: boolean;
	dropTarget?: boolean;
} & Partial<ClassNameProps & StyleProps>;

export function DocumentResourceFolderDisplay({
	documentResourceFolder,
	draggable = false,
	dropTarget = false,
	className,
	style,
}: DocumentResourceFolderDisplayProps) {
	const { id, name } = documentResourceFolder;

	const onDragStart = React.useMemo(
		() => getDocumentResourceFolderOnDragStart(documentResourceFolder),
		[documentResourceFolder],
	);

	const { isDraggingOver, onDragOver, onDragLeave, onDrop } = useDropTarget(documentResourceFolder, dropTarget);

	const fullClassName = makeClassName(
		styles["document-resource-folder-display"],
		isDraggingOver ? styles["document-resource-folder-display--dragging-over"] : "",
		className,
	);

	return (
		<div
			onDragOver={dropTarget ? onDragOver : undefined}
			onDragLeave={dropTarget ? onDragLeave : undefined}
			onDrop={dropTarget ? onDrop : undefined}
			draggable={draggable}
			onDragStart={draggable ? onDragStart : undefined}
			className={fullClassName}
			style={style}
		>
			<Column justify="spaced-start" verticalSpacing="1.5rem" align="center">
				<Row justify="space-between" align="center" style={{ width: "100%" }}>
					<InternalLink to={resourcesFoldersDetailPageInfo.to(id)} draggable={false}>
						<Icon.Folder size="1.75rem" color="var(--verita-blue)" style={{ display: "block" }} />
					</InternalLink>

					<div>
						<DocumentResourceFolderEditor id={id} />
					</div>
				</Row>

				<InternalLink
					to={resourcesFoldersDetailPageInfo.to(id)}
					draggable={false}
					className={styles["document-resource-folder-display__name"]}
				>
					{name}
				</InternalLink>
			</Column>
		</div>
	);
}

export type DocumentResourceFolderDisplayLoading = Partial<ClassNameProps & StyleProps>;

DocumentResourceFolderDisplay.Loading = function ({ className, style }: DocumentResourceFolderDisplayLoading) {
	return (
		<div
			className={makeClassName(
				styles["document-resource-folder-display"],
				styles["document-resource-folder-display--loading"],
				className,
			)}
			style={style}
		>
			<Column justify="spaced-start" verticalSpacing="1.5rem">
				<Row>
					<Icon.Folder
						size="1.75rem"
						color="var(--verita-blue)"
						className={styles["document-resource-folder-display--loading__icon"]}
					/>
					<div className={styles["document-resource-folder-display__options-button"]}></div>
				</Row>
				<span
					className={makeClassName(
						styles["document-resource-folder-display__name"],
						styles["document-resource-folder-display--loading__name"],
					)}
				>
					Loading...
				</span>
			</Column>
		</div>
	);
};

export function useDropTarget(
	documentResourceFolder: Pick<DocumentResourceFolder, "id" | "name"> | null,
	isEnabled: boolean,
) {
	const [isDraggingOver, setIsDraggingOver] = React.useState(false);

	React.useEffect(() => {
		if (!isEnabled) {
			setIsDraggingOver(false);
		}
	}, [isEnabled]);

	const currentDocumentResourceFolderID = useRouteMatch<ResourcesFoldersDetailPageRouteParams>(
		resourcesFoldersDetailPageInfo.path as string,
	)?.params.documentResourceFolderID;

	const { addNotification } = useNotifications();

	const [updateDocumentResource] = useDocumentResourceUpdateMutation();
	const [updateDocumentResourceFolder] = useDocumentResourceFolderUpdateMutation();
	const onDrop = React.useMemo(() => {
		return getOnDrop(async (data, isCopyArg) => {
			const isCopy = isCopyArg && !!currentDocumentResourceFolderID;

			setIsDraggingOver(false);

			let errors: readonly GraphQLError[] | null = null;
			if (data.model === "DocumentResource") {
				const update = DocumentResourceMutation.getUpdate(updateDocumentResource, data.id);
				const initialFormValues: Partial<DocumentResourceFormValues.Detail> = {};
				if (!isCopy && currentDocumentResourceFolderID) {
					initialFormValues.documentResourceFolderIDs = [currentDocumentResourceFolderID];
				}
				const result = await update(
					{ documentResourceFolderIDs: documentResourceFolder ? [documentResourceFolder.id] : [] },
					initialFormValues,
				);
				errors = result.errors;
			} else {
				if (data.id === documentResourceFolder?.id) {
					return;
				}
				const update = DocumentResourceFolderMutation.getUpdate(updateDocumentResourceFolder, data.id);
				const initialFormValues: Partial<DocumentResourceFolderFormValues.Detail> = {};
				if (currentDocumentResourceFolderID) {
					initialFormValues.parentDocumentResourceFolderID = currentDocumentResourceFolderID;
				}
				const result = await update(
					{ parentDocumentResourceFolderID: documentResourceFolder?.id ?? null },
					initialFormValues,
				);
				errors = result.errors;
			}

			if (errors) {
				addNotification(
					(props) => (
						<NotificationContainer variant="danger" {...props}>
							There was a problem {isCopy ? "copying" : "moving"} {data.name} to{" "}
							{documentResourceFolder?.name ?? "Home"}.
						</NotificationContainer>
					),
					2500,
				);
			} else {
				addNotification(
					(props) => (
						<NotificationContainer variant="success" {...props}>
							{isCopy ? "Copied" : "Moved"} {data.name} to {documentResourceFolder?.name ?? "Home"}.
						</NotificationContainer>
					),
					2000,
				);
			}
		});
	}, [
		documentResourceFolder,
		updateDocumentResource,
		updateDocumentResourceFolder,
		currentDocumentResourceFolderID,
		addNotification,
	]);

	const localOnDragOver = React.useCallback((event: React.DragEvent<HTMLDivElement>) => {
		setIsDraggingOver(true);
		onDragOver(event);
	}, []);

	const onDragLeave = React.useCallback(() => {
		setIsDraggingOver(false);
	}, []);

	return { isDraggingOver, onDrop, onDragOver: localOnDragOver, onDragLeave };
}

export type DocumentResourceFolderEditorProps = {
	id: DocumentResourceFolder["id"];
};

export function DocumentResourceFolderEditor({ id }: DocumentResourceFolderEditorProps) {
	const { isOn: isOpen, toggle: toggleIsOpen } = useToggle(false);

	return (
		<React.Fragment>
			<Button onClick={toggleIsOpen} className={styles["document-resource-folder-display__options-button"]}>
				<div className={styles["document-resource-folder-display__options-button__icon-container"]}>
					<Icon.MoreVertical size="1.25rem" color="#888" />
				</div>
			</Button>

			<Drawer.If condition={isOpen}>
				<DocumentResourceFolderEditDrawer id={id} onClose={toggleIsOpen} />
			</Drawer.If>
		</React.Fragment>
	);
}

type DocumentResourceFolderEditDrawerProps = {
	id: DocumentResourceFolder["id"];
} & RequiredKeys<Pick<DrawerProps, "ifRef" | "onClose">, "onClose">;

function DocumentResourceFolderEditDrawer({ id, ifRef, onClose }: DocumentResourceFolderEditDrawerProps) {
	const { loading, data } = useDocumentResourceFolderDetailQuery({ variables: { id } });
	const documentResourceFolder = data?.documentResourceFolder;

	const isOnDetailPage = !!useRouteMatch(resourcesFoldersDetailPageInfo.path);
	const history = useHistory();
	const onDelete = React.useCallback(() => {
		if (isOnDetailPage) {
			history.push(resourcesIndexPageInfo.to);
		} else {
			onClose();
		}
	}, [isOnDetailPage, history, onClose]);

	return (
		<Drawer
			ifRef={ifRef}
			onClose={onClose}
			style={{ width: "fit-content", minWidth: "calc(var(--general__field---width) + 3rem)" }}
		>
			<Drawer.Header>
				<Heading level={2} noMargin>
					{documentResourceFolder?.name ?? "Loading..."}
				</Heading>
			</Drawer.Header>
			<Drawer.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						{!!documentResourceFolder && (
							<DocumentResourceEditForm documentResourceFolder={documentResourceFolder} onClose={onClose} />
						)}
					</Else>
				</Conditional>
			</Drawer.Body>

			<PermissionRequired requiredPermission={permissions.DocumentResourceFolder.Delete.Admin}>
				<Drawer.Footer>
					<Row justify="center" align="center">
						<DocumentResourceFolderDeleteButton instanceID={id} onSuccess={onDelete} size="small" />
					</Row>
				</Drawer.Footer>
			</PermissionRequired>
		</Drawer>
	);
}

type DocumentResourceEditFormProps = {
	documentResourceFolder: DocumentResourceFolderDetailQuery["documentResourceFolder"];
	onClose: () => void;
};

function DocumentResourceEditForm({ documentResourceFolder, onClose }: DocumentResourceEditFormProps) {
	const initialFormValues = DocumentResourceFolderFormConversion.toFormValues(documentResourceFolder);
	const formState = DocumentResourceFolderFormState.useDetailFormState({ initialFormValues });

	const update = DocumentResourceFolderMutation.useUpdate(documentResourceFolder.id);
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<DocumentResourceFolderFormValues.Detail>,
			initialFormValues: DocumentResourceFolderFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<DocumentResourceFolderForm.Detail
			formState={formState}
			documentResourceFolder={documentResourceFolder}
			applyUpdate={applyUpdate}
			onSuccess={onClose}
		/>
	);
}

export type DocumentResourceFolderCreatorProps = Partial<ClassNameProps & StyleProps>;

export function DocumentResourceFolderCreator({ className, style }: DocumentResourceFolderCreatorProps) {
	const { isOpen, toggleIsOpen } = useOpenToggle(false);

	return (
		<React.Fragment>
			<Button
				onClick={toggleIsOpen}
				className={makeClassName(styles["document-resource-folder-display"], className)}
				title="Create a new folder"
				style={{ height: "7.375rem", ...style }}
			>
				<Row justify="center" align="center" style={{ width: "100%", height: "100%" }}>
					<Icon.FolderPlus size="3rem" color="var(--verita-blue)" />
				</Row>
			</Button>

			<Modal.If condition={isOpen}>
				<DocumentResourceFolderCreateModal onClose={toggleIsOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

export type DocumentResourceFolderCreateButton = Pick<ButtonProps, "variant" | "size" | "className" | "style"> & {
	iconSize?: string;
};

export function DocumentResourceFolderCreateButton({
	variant,
	size,
	iconSize,
	className,
	style,
}: DocumentResourceFolderCreateButton) {
	const { isOpen, toggleIsOpen } = useOpenToggle(false);

	return (
		<React.Fragment>
			<Button
				variant={variant}
				size={size}
				onClick={toggleIsOpen}
				title="Create a new folder"
				className={className}
				style={style}
			>
				<Row justify="center" align="center" style={{ width: "100%", height: "100%" }}>
					<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
						<Icon.FolderPlus size={iconSize} /> <span>Create Folder</span>
					</Row>
				</Row>
			</Button>

			<Modal.If condition={isOpen}>
				<DocumentResourceFolderCreateModal onClose={toggleIsOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type DocumentResourceFolderCreateModalProps = RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function DocumentResourceFolderCreateModal({ ifRef, onClose }: DocumentResourceFolderCreateModalProps) {
	const documentResourceFolderID = useRouteMatch<ResourcesFoldersDetailPageRouteParams>(
		resourcesFoldersDetailPageInfo.path as string,
	)?.params.documentResourceFolderID;
	const initialFormValues = React.useMemo(
		() => ({
			parentDocumentResourceFolderID: documentResourceFolderID ?? null,
		}),
		[documentResourceFolderID],
	);
	const formState = DocumentResourceFolderFormState.useCreateFormState(initialFormValues);

	const create = DocumentResourceFolderMutation.useCreate();
	const applyCreate = React.useCallback(
		async (formValues: DocumentResourceFolderFormValues.Create) => {
			const { errors } = await create(formValues);
			return errors;
		},
		[create],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose} style={{ width: "fit-content" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					New Folder
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<DocumentResourceFolderForm.Create formState={formState} applyCreate={applyCreate} onSuccess={onClose} />
			</Modal.Body>
		</Modal>
	);
}
