import React from "react";
import { useApolloClient } from "@apollo/client";
import { FieldInputOption } from "@hex-insights/forms";
import { ReportCardFormat } from "../../Format";
import {
	graphTypeNames,
	Maybe,
	ReportCard,
	ReportCardCreateMutation,
	ReportCardNameFragment,
	ReportCardSelectDocument,
	ReportCardSelectQuery,
	ReportCardSelectQueryVariables,
} from "../../GraphQL";

/**
 * A slim version of the ReportCard model for use in select fields.
 */
export type ModelForOption = Pick<ReportCard, "id"> & ReportCardNameFragment;

/**
 * A slim version of the ReportCard model edge for use in select fields.
 */
export type EdgeForOption = {
	node: ModelForOption;
};

/**
 * Converts the given `edges` to options to be given to a radio or select field.
 *
 * @param edges The edges to convert to options.
 * @param current The current instance for the field. Used to display when `edges` is blank (e.g. with lazy loading).
 */
export function toOptions(edges?: EdgeForOption[], current?: Maybe<ModelForOption>) {
	if (edges === undefined) {
		if (!(current === null || current === undefined)) {
			return [toOption(current)];
		}
		return [];
	}
	return edges.map((e) => toOption(e.node));
}

/**
 * Converts the given `edges` to options to be given to a multi-select field.
 *
 * @param edges The edges to convert to options.
 * @param current The current instances for the field. Used to display when `edges` is blank (e.g. with lazy loading).
 */
export function toMultiOptions(edges?: EdgeForOption[], currents?: ModelForOption[]) {
	if (edges === undefined) {
		if (currents !== undefined) {
			return currents.map(toOption);
		}
		return [];
	}
	return edges.map((e) => toOption(e.node));
}

/**
 * Converts a single ReportCard instance to an option to be given to a radio, select, or
 * multi-select field.
 *
 * @param instance The instance to convert to an option.
 */
export function toOption(instance: ModelForOption): FieldInputOption<ReportCard["id"]> {
	return {
		label: ReportCardFormat.name(instance),
		value: instance.id,
	};
}

/**
 * Returns an `addToCache` function that accepts a single ReportCard instance and adds it to the
 * cache for the select query. Useful when allowing creating of ReportCard instance in other
 * models' forms.
 */
export function useAddToCache() {
	const client = useApolloClient();

	return React.useCallback(
		(reportCard: ReportCardCreateMutation["createReportCard"]) => {
			const cacheResult = client.readQuery<ReportCardSelectQuery, ReportCardSelectQueryVariables>({
				query: ReportCardSelectDocument,
			});
			client.writeQuery<ReportCardSelectQuery, ReportCardSelectQueryVariables>({
				query: ReportCardSelectDocument,
				data: {
					reportCardConnection: {
						...cacheResult?.reportCardConnection,
						edges: [
							...(cacheResult?.reportCardConnection.edges ?? []),
							{
								__typename: graphTypeNames.ReportCardEdge,
								node: reportCard,
							},
						],
					},
				},
			});
		},
		[client],
	);
}
