import { IndexForms } from "@hex-insights/app-modules";
import { MFAInvalidOTPAttemptFilterFormValues } from "../../../../Utilities";

export type MFAInvalidOTPAttemptFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, MFAInvalidOTPAttemptFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the MFAInvalidOTPAttempt model.
 */
export function MFAInvalidOTPAttemptFilterFormController(props: MFAInvalidOTPAttemptFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={MFAInvalidOTPAttemptFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `MFAInvalidOTPAttemptFilterForm`
