import { newPageInfo } from "@hex-insights/router";
import { CourseAttendanceRecord } from "@hex-insights/verita.shared";
import { courseAttendanceRecordHubPageInfo } from "../pageinfo";

type CourseAttendanceRecordForRouteTitle = Pick<CourseAttendanceRecord, "id">;

export const courseAttendanceRecordHubDetailPageInfo = newPageInfo({
	name: "Course Attendance Record Detail",
	title: (e: CourseAttendanceRecordForRouteTitle) => e.id,
	description: "Detail page for an courseAttendanceRecord.",
	exact: false,
	isLeaf: true,
	to: (id: CourseAttendanceRecord["id"]) => courseAttendanceRecordHubPageInfo.to + `/${id}`,
	path: courseAttendanceRecordHubPageInfo.path + "/:id(\\d+)",
	parent: courseAttendanceRecordHubPageInfo,
});
