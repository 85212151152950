import { newPageInfo } from "@hex-insights/router";
import { expenseBudgetHubPageInfo } from "../pageinfo";

export const expenseBudgetHubCreatePageInfo = newPageInfo({
	name: "New Expense Budget",
	title: "New",
	description: "Create a new Expense Budget record.",
	exact: true,
	to: expenseBudgetHubPageInfo.to + "/new",
	path: expenseBudgetHubPageInfo.path + "/new",
	parent: expenseBudgetHubPageInfo,
});
