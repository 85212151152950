import { SchoolAttendanceRecord } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the checkInTime field of the SchoolAttendanceRecord model.
 */
export function checkInTime(value: SchoolAttendanceRecord["checkInTime"] | null): string {
	return toString(value);
}

/**
 * Formats the checkOutTime field of the SchoolAttendanceRecord model.
 */
export function checkOutTime(value: SchoolAttendanceRecord["checkOutTime"] | null): string {
	return toString(value);
}

/**
 * Formats the isPresent field of the SchoolAttendanceRecord model.
 */
export function isPresent(value: SchoolAttendanceRecord["isPresent"]): string {
	return toString(value);
}

/**
 * Formats the isLate field of the SchoolAttendanceRecord model.
 */
export function isLate(value: SchoolAttendanceRecord["isLate"]): string {
	return toString(value);
}

/**
 * Formats the isExcused field of the SchoolAttendanceRecord model.
 */
export function isExcused(value: SchoolAttendanceRecord["isExcused"]): string {
	return toString(value);
}

/**
 * Formats the notes field of the SchoolAttendanceRecord model.
 */
export function notes(value: SchoolAttendanceRecord["notes"]): string {
	return toString(value);
}
