import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { ServiceOrderFormValues } from "../../../../Utilities";

export type ServiceOrderFormControllerProps = {
	formState: FormState<ServiceOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the Service model.
 */
export function ServiceOrderFormController({ formState }: ServiceOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={ServiceOrderFormValues.fieldOptions}
			fieldTypeMapping={ServiceOrderFormValues.fieldTypeMapping}
		/>
	);
}
