import React from "react";
import {
	CreateFunction,
	onCreateSubmit,
	onUpdateSubmit,
	UpdateFunction,
	useApplyCreate,
	useApplyUpdate,
} from "@hex-insights/app-modules";
import { Column, DistributiveOmit, Heading, HeadingLevel, Row, Section } from "@hex-insights/core";
import {
	FieldIf,
	Form,
	FormEditButtonPair,
	FormState,
	FormSubmitFunction,
	RadioInputOption,
	SubmitButton,
	useOnSuccess,
} from "@hex-insights/forms";
import {
	EnrollmentApplicationParentCreateMutation,
	EnrollmentApplicationParentDetailQuery,
	EnrollmentApplicationParentFormat,
	EnrollmentApplicationParentFormConversion,
	EnrollmentApplicationParentFormState,
	EnrollmentApplicationParentFormValues,
	EnrollmentApplicationParentMutation,
	EnrollmentApplicationParentRelationshipType,
	EnrollmentApplicationParentUpdateMutation,
} from "../../../../Utilities";
import * as Field from "./Field";
import styles from "./styles.module.css";

export type ControlledCreateProps = Pick<
	CreateProps<EnrollmentApplicationParentCreateMutation["createEnrollmentApplicationParent"]>,
	"onSuccess" | "formNameSuffix"
> & {
	formStateArgs?: Partial<EnrollmentApplicationParentFormState.UseCreateFormStateArgs>;
};

/**
 * Renders the create form of the EnrollmentApplicationParent model using an internally managed form state.
 */
export function ControlledCreate({ formStateArgs, ...props }: ControlledCreateProps) {
	const formState = EnrollmentApplicationParentFormState.useCreateFormState(formStateArgs);
	const create = EnrollmentApplicationParentMutation.useCreate();

	return <Create formState={formState} create={create} {...props} />;
}

export type CreateProps<T> = {
	formState: FormState<EnrollmentApplicationParentFormValues.Create>;
	create: CreateFunction<EnrollmentApplicationParentFormValues.Create, T>;
	onSuccess: (data: T) => void;
	formNameSuffix?: string;
};

/**
 * Renders the create form of the EnrollmentApplicationParent model using the given form state.
 */
export function Create<T>({ formState, create, onSuccess, formNameSuffix }: CreateProps<T>) {
	const { applyCreate, resultRef } = useApplyCreate(create);
	const onSubmit = React.useCallback<FormSubmitFunction<EnrollmentApplicationParentFormValues.Create>>(
		async ({ formValues }) => onCreateSubmit(formValues, applyCreate),
		[applyCreate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	const formName = "enrollmentApplicationParent.create" + (formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form name={formName} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Form.Column>
				<CreateFields formState={formState} />

				<Form.ActionBar>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Form.ActionBar>
			</Form.Column>
		</Form>
	);
}

type CreateFieldsProps = {
	formState: FormState<EnrollmentApplicationParentFormValues.Create>;
};

function CreateFields({ formState }: CreateFieldsProps) {
	return (
		<React.Fragment>
			<Field.FormIdentifier formState={formState} />
			<Field.FirstName formState={formState} />
			<Field.LastName formState={formState} />
			<Field.Nationality formState={formState} />
			<Field.Occupation formState={formState} />
			<Field.RelationshipType formState={formState} />
			<Field.RelationshipTypeCustom formState={formState} />
			<Field.IsPrimaryContact formState={formState} />
			<Field.IsFinancialContact formState={formState} />
			<Field.IsEmergencyContact formState={formState} />
			<Field.LivesWithStudent formState={formState} />
			<Field.IsAuthorizedForPickup formState={formState} />
			<Field.AddressLine1 formState={formState} />
			<Field.AddressLine2 formState={formState} />
			<Field.City formState={formState} />
			<Field.StateOrProvince formState={formState} />
			<Field.PostalCode formState={formState} />
			<Field.Country formState={formState} />
			<Field.HomePhone formState={formState} />
			<Field.WorkPhone formState={formState} />
			<Field.MobilePhone formState={formState} />
			<Field.Email formState={formState} />
			<Field.EnrollmentApplicationSubmission formState={formState} />
		</React.Fragment>
	);
}

export type ControlledDetailProps = Pick<
	DetailProps<EnrollmentApplicationParentUpdateMutation["updateEnrollmentApplicationParent"]>,
	"enrollmentApplicationParent" | "onSuccess" | "formNameSuffix"
> & {
	formStateArgs?: Partial<
		DistributiveOmit<EnrollmentApplicationParentFormState.UseDetailFormStateArgs, "initialFormValues">
	>;
};

/**
 * Renders the detail form of the EnrollmentApplicationParent model using an internally managed form state.
 */
export function ControlledDetail({ formStateArgs, ...props }: ControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => EnrollmentApplicationParentFormConversion.toFormValues(props.enrollmentApplicationParent),
		[props.enrollmentApplicationParent],
	);
	const formState = EnrollmentApplicationParentFormState.useDetailFormState({ ...formStateArgs, initialFormValues });
	const update = EnrollmentApplicationParentMutation.useUpdate(props.enrollmentApplicationParent.id);

	return <Detail formState={formState} update={update} {...props} />;
}

export type DetailProps<T> = {
	enrollmentApplicationParent: EnrollmentApplicationParentDetailQuery["enrollmentApplicationParent"];
	formState: FormState<EnrollmentApplicationParentFormValues.Detail>;
	update: UpdateFunction<EnrollmentApplicationParentFormValues.Detail, T>;
	onSuccess: (data: T) => void;
	formNameSuffix?: string;
};

/**
 * Renders the detail form of the EnrollmentApplicationParent model using the given form state.
 */
export function Detail<T>({
	formState,
	enrollmentApplicationParent,
	update,
	onSuccess,
	formNameSuffix,
}: DetailProps<T>) {
	const { applyUpdate, resultRef } = useApplyUpdate(update);
	const onSubmit = React.useCallback<FormSubmitFunction<EnrollmentApplicationParentFormValues.Detail>>(
		async (formState) => onUpdateSubmit(formState, applyUpdate),
		[applyUpdate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	const formName =
		`enrollmentApplicationParent.detail.${enrollmentApplicationParent.id}` +
		(formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form name={formName} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Form.Column>
				<FormEditButtonPair formState={formState} />

				<DetailFields formState={formState} enrollmentApplicationParent={enrollmentApplicationParent} />
			</Form.Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = Pick<
	ReadOnlyProps,
	"enrollmentApplicationParent" | "allParents" | "headingLevel"
> & {
	formStateArgs?: Partial<
		DistributiveOmit<EnrollmentApplicationParentFormState.UseReadOnlyFormStateArgs, "initialFormValues">
	>;
};

/**
 * Renders a read-only detail form of the EnrollmentApplicationParent model using an internally managed form state.
 */
export function ControlledReadOnly({ formStateArgs, ...props }: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => EnrollmentApplicationParentFormConversion.toFormValues(props.enrollmentApplicationParent),
		[props.enrollmentApplicationParent],
	);
	const formState = EnrollmentApplicationParentFormState.useReadOnlyFormState({
		...formStateArgs,
		initialFormValues,
	});

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = {
	enrollmentApplicationParent: EnrollmentApplicationParentDetailQuery["enrollmentApplicationParent"];
	formState: FormState<EnrollmentApplicationParentFormValues.Detail>;
	allParents: DetailFormSectionsProps["allParents"];
	headingLevel?: HeadingLevel;
};

/**
 * Renders a read-only detail form of the EnrollmentApplicationParent model using the given form state.
 */
export function ReadOnly({ formState, allParents, headingLevel }: ReadOnlyProps) {
	return (
		<Form.Column>
			<DetailFormSections formState={formState} allParents={allParents} headingLevel={headingLevel} />
		</Form.Column>
	);
}

type DetailFieldsProps = {
	enrollmentApplicationParent: EnrollmentApplicationParentDetailQuery["enrollmentApplicationParent"];
	formState: FormState<EnrollmentApplicationParentFormValues.Detail>;
};

function DetailFields({ formState, enrollmentApplicationParent }: DetailFieldsProps) {
	return (
		<React.Fragment>
			<Field.FormIdentifier formState={formState} />
			<Field.Name formState={formState} />
			<Field.FirstName formState={formState} />
			<Field.LastName formState={formState} />
			<Field.Nationality formState={formState} />
			<Field.Occupation formState={formState} />
			<Field.RelationshipType formState={formState} />
			<Field.RelationshipTypeCustom formState={formState} />
			<Field.IsPrimaryContact formState={formState} />
			<Field.IsFinancialContact formState={formState} />
			<Field.IsEmergencyContact formState={formState} />
			<Field.LivesWithStudent formState={formState} />
			<Field.IsAuthorizedForPickup formState={formState} />
			<Field.AddressLine1 formState={formState} />
			<Field.AddressLine2 formState={formState} />
			<Field.City formState={formState} />
			<Field.StateOrProvince formState={formState} />
			<Field.PostalCode formState={formState} />
			<Field.Country formState={formState} />
			<Field.HomePhone formState={formState} />
			<Field.WorkPhone formState={formState} />
			<Field.MobilePhone formState={formState} />
			<Field.Email formState={formState} />
			<Field.EnrollmentApplicationSubmission
				formState={formState}
				currentEnrollmentApplicationSubmission={enrollmentApplicationParent.enrollmentApplicationSubmission}
			/>
		</React.Fragment>
	);
}

const addressFieldNames: (keyof EnrollmentApplicationParentFormValues.Address)[] = [
	"addressLine1",
	"addressLine2",
	"city",
	"stateOrProvince",
	"postalCode",
	"country",
];

export type EnrollmentApplicationCreateFormSectionsProps = {
	formState: FormState<EnrollmentApplicationParentFormValues.EnrollmentApplicationCreate>;
	headingLevel?: HeadingLevel;
	allParents: (Pick<EnrollmentApplicationParentFormValues.Create, "formIdentifier" | "firstName" | "lastName"> &
		EnrollmentApplicationParentFormValues.Address)[];
};

export function EnrollmentApplicationCreateFormSections({
	formState,
	headingLevel = 2,
	allParents,
}: EnrollmentApplicationCreateFormSectionsProps) {
	const { formIdentifier } = formState.formValues;

	const addressSameAsFormIdentifierOptions = React.useMemo<RadioInputOption<string>[]>(() => {
		const otherParentsWithAddresses = allParents.filter(
			(e) => e.formIdentifier !== formIdentifier && e.addressLine1 !== "",
		);
		return [
			{ value: "", label: "New Address" },
			...otherParentsWithAddresses.map((e) => ({
				value: e.formIdentifier,
				label: `Same as ${EnrollmentApplicationParentFormat.nameComputed(
					e,
				)} at ${EnrollmentApplicationParentFormat.address(e)}`,
			})),
		];
	}, [allParents, formIdentifier]);

	return (
		<React.Fragment>
			<Section>
				<Section.Header>
					<Heading level={headingLevel} noMargin>
						Basic Information
					</Heading>
				</Section.Header>
				<Section.Body>
					<Column justify="spaced-start">
						<Row justify="spaced-start" align="flex-end" withVerticalSpacing overflow="wrap">
							<Field.RelationshipType formState={formState} />
							<FieldIf
								formState={formState}
								name="relationshipTypeCustom"
								condition={formState.formValues.relationshipType === EnrollmentApplicationParentRelationshipType.Other}
							>
								<Field.RelationshipTypeCustom formState={formState} />
							</FieldIf>
						</Row>

						<Row justify="spaced-start" align="spaced-start" overflow="wrap">
							<Field.FirstName formState={formState} />
							<Field.LastName formState={formState} />
						</Row>
						<Row justify="spaced-start" align="spaced-start" overflow="wrap">
							<Field.NationalityList formState={formState} />
							<Field.Occupation formState={formState} />
						</Row>
					</Column>
				</Section.Body>
			</Section>

			<Section>
				<Section.Header>
					<Heading level={headingLevel} noMargin>
						Relationship with Student
					</Heading>
				</Section.Header>
				<Section.Body>
					<Column justify="spaced-start">
						<span>Please select all that apply.</span>

						<Row
							justify="spaced-start"
							align="center"
							horizontalSpacing="0.25rem"
							withVerticalSpacing
							overflow="wrap"
							className={styles["relationship-fields"]}
						>
							<Field.IsPrimaryContact formState={formState} noToolbar />
							<Field.IsFinancialContact formState={formState} noToolbar />
							<Field.IsEmergencyContact formState={formState} noToolbar />
							<Field.LivesWithStudent formState={formState} noToolbar />
							<Field.IsAuthorizedForPickup formState={formState} noToolbar />
						</Row>
					</Column>
				</Section.Body>
			</Section>

			<Section>
				<Section.Header>
					<Heading level={headingLevel} noMargin>
						Address
					</Heading>
				</Section.Header>
				<Section.Body>
					<Column justify="spaced-start">
						<FieldIf
							formState={formState}
							name="addressSameAsFormIdentifier"
							condition={addressSameAsFormIdentifierOptions.length > 1}
						>
							<Field.AddressSameAsFormIdentifier
								formState={formState}
								options={addressSameAsFormIdentifierOptions}
								className={styles["address-same-as"]}
							/>
						</FieldIf>
						<FieldIf
							formState={formState}
							names={addressFieldNames}
							condition={formState.formValues.addressSameAsFormIdentifier === ""}
						>
							<Field.AddressLine1 formState={formState} className={styles["address-line"]} />
							<Field.AddressLine2 formState={formState} className={styles["address-line"]} />
							<Row justify="spaced-start" align="spaced-start" overflow="wrap">
								<Field.City formState={formState} />
								<Field.StateOrProvince formState={formState} />
							</Row>
							<Row justify="spaced-start" align="spaced-start" overflow="wrap">
								<Field.PostalCode formState={formState} />
								<Field.Country formState={formState} />
							</Row>
						</FieldIf>
					</Column>
				</Section.Body>
			</Section>

			<Section>
				<Section.Header>
					<Heading level={headingLevel} noMargin>
						Contact Information
					</Heading>
				</Section.Header>
				<Section.Body>
					<Column justify="spaced-start">
						<Field.PhoneValidation formState={formState} />
						<Row justify="spaced-start" align="spaced-start" overflow="wrap">
							<Field.HomePhone formState={formState} />
							<Field.WorkPhone formState={formState} />
						</Row>
						<Row justify="spaced-start" align="spaced-start" overflow="wrap">
							<Field.MobilePhone formState={formState} />
							<Field.Email formState={formState} />
						</Row>
					</Column>
				</Section.Body>
			</Section>
		</React.Fragment>
	);
}

export type DetailFormSectionsProps = {
	formState: FormState<EnrollmentApplicationParentFormValues.Detail>;
	headingLevel?: HeadingLevel;
	allParents: (Pick<EnrollmentApplicationParentFormValues.Detail, "formIdentifier" | "name"> &
		EnrollmentApplicationParentFormValues.Address)[];
};

export function DetailFormSections({ formState, headingLevel = 2, allParents }: DetailFormSectionsProps) {
	const { formIdentifier } = formState.formValues;

	const addressSameAsFormIdentifierOptions = React.useMemo<RadioInputOption<string>[]>(() => {
		const otherParentsWithAddresses = allParents.filter(
			(e) => e.formIdentifier !== formIdentifier && e.addressLine1 !== "",
		);
		return [
			{ value: "", label: "New Address" },
			...otherParentsWithAddresses.map((e) => ({
				value: e.formIdentifier,
				label: `Same as ${EnrollmentApplicationParentFormat.name(e)} at ${EnrollmentApplicationParentFormat.address(
					e,
				)}`,
			})),
		];
	}, [allParents, formIdentifier]);

	return (
		<React.Fragment>
			<Section>
				<Section.Header>
					<Heading level={headingLevel} noMargin>
						Basic Information
					</Heading>
				</Section.Header>
				<Section.Body>
					<Column justify="spaced-start">
						<Row justify="spaced-start" align="flex-end" withVerticalSpacing overflow="wrap">
							<Field.RelationshipType formState={formState} />
							<FieldIf
								formState={formState}
								name="relationshipTypeCustom"
								condition={formState.formValues.relationshipType === EnrollmentApplicationParentRelationshipType.Other}
							>
								<Field.RelationshipTypeCustom formState={formState} />
							</FieldIf>
						</Row>

						<Row justify="spaced-start" align="spaced-start" overflow="wrap">
							<Field.FirstName formState={formState} />
							<Field.LastName formState={formState} />
						</Row>
						<Row justify="spaced-start" align="spaced-start" overflow="wrap">
							<Field.Nationality formState={formState} />
							<Field.Occupation formState={formState} />
						</Row>
					</Column>
				</Section.Body>
			</Section>

			<Section>
				<Section.Header>
					<Heading level={headingLevel} noMargin>
						Relationship with Student
					</Heading>
				</Section.Header>
				<Section.Body>
					<Column justify="spaced-start" verticalSpacing="0.25rem">
						{/* TODO rework as fieldset */}
						<FieldIf formState={formState} name="isPrimaryContact" condition={formState.formValues.isPrimaryContact}>
							<Field.IsPrimaryContact formState={formState} noToolbar />
						</FieldIf>
						<FieldIf
							formState={formState}
							name="isFinancialContact"
							condition={formState.formValues.isFinancialContact}
						>
							<Field.IsFinancialContact formState={formState} noToolbar />
						</FieldIf>
						<FieldIf
							formState={formState}
							name="isEmergencyContact"
							condition={formState.formValues.isEmergencyContact}
						>
							<Field.IsEmergencyContact formState={formState} noToolbar />
						</FieldIf>
						<FieldIf formState={formState} name="livesWithStudent" condition={formState.formValues.livesWithStudent}>
							<Field.LivesWithStudent formState={formState} noToolbar />
						</FieldIf>
						<FieldIf
							formState={formState}
							name="isAuthorizedForPickup"
							condition={formState.formValues.isAuthorizedForPickup}
						>
							<Field.IsAuthorizedForPickup formState={formState} noToolbar />
						</FieldIf>
					</Column>
				</Section.Body>
			</Section>

			<Section>
				<Section.Header>
					<Heading level={headingLevel} noMargin>
						Address
					</Heading>
				</Section.Header>
				<Section.Body>
					<Column justify="spaced-start">
						<FieldIf
							formState={formState}
							name="addressSameAsFormIdentifier"
							condition={addressSameAsFormIdentifierOptions.length > 1}
						>
							<Field.AddressSameAsFormIdentifier
								formState={formState}
								options={addressSameAsFormIdentifierOptions}
								className={styles["address-same-as"]}
							/>
						</FieldIf>
						<FieldIf
							formState={formState}
							names={addressFieldNames}
							condition={formState.formValues.addressSameAsFormIdentifier === ""}
						>
							<Field.AddressLine1 formState={formState} className={styles["address-line"]} />
							<Field.AddressLine2 formState={formState} className={styles["address-line"]} />
							<Row justify="spaced-start" align="spaced-start" overflow="wrap">
								<Field.City formState={formState} />
								<Field.StateOrProvince formState={formState} />
							</Row>
							<Row justify="spaced-start" align="spaced-start" overflow="wrap">
								<Field.PostalCode formState={formState} />
								<Field.Country formState={formState} />
							</Row>
						</FieldIf>
					</Column>
				</Section.Body>
			</Section>

			<Section>
				<Section.Header>
					<Heading level={headingLevel} noMargin>
						Contact Information
					</Heading>
				</Section.Header>
				<Section.Body>
					<Column justify="spaced-start">
						<Row justify="spaced-start" align="spaced-start" overflow="wrap">
							<Field.HomePhone formState={formState} />
							<Field.WorkPhone formState={formState} />
						</Row>
						<Row justify="spaced-start" align="spaced-start" overflow="wrap">
							<Field.MobilePhone formState={formState} />
							<Field.Email formState={formState} />
						</Row>
					</Column>
				</Section.Body>
			</Section>
		</React.Fragment>
	);
}
