import { newPageInfo } from "@hex-insights/router";
import { academicYearTuitionHubPageInfo } from "../pageinfo";

export const academicYearTuitionHubIndexPageInfo = newPageInfo({
	name: "Academic Year Tuitions Index",
	title: "",
	description: "Index of Academic Year Tuitions.",
	exact: true,
	to: academicYearTuitionHubPageInfo.to,
	path: academicYearTuitionHubPageInfo.path,
	parent: academicYearTuitionHubPageInfo,
});
