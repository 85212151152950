import { newPageInfo } from "@hex-insights/router";
import { quotationHubPageInfo } from "../pageinfo";

export const quotationHubIndexPageInfo = newPageInfo({
	name: "Quotations Index",
	title: "",
	description: "Index of Quotations.",
	exact: true,
	to: quotationHubPageInfo.to,
	path: quotationHubPageInfo.path,
	parent: quotationHubPageInfo,
});
