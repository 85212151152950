import { IndexForms } from "@hex-insights/app-modules";
import { PhoneNumberFilterFormValues } from "../../../../Utilities";

export type PhoneNumberSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the PhoneNumber model.
 */
export function PhoneNumberSearchForm(props: PhoneNumberSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type PhoneNumberFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, PhoneNumberFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the PhoneNumber model.
 */
export function PhoneNumberFilterFormController(props: PhoneNumberFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={PhoneNumberFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `PhoneNumberFilterForm`
