import React from "react";
import {
	Button,
	Column,
	Heading,
	Icon,
	If,
	makeClassName,
	Modal,
	ModalProps,
	NotificationContainer,
	NotificationRenderProps,
	Paragraph,
	preventDefault,
	RequiredKeys,
	Row,
	toGrammaticalNumber,
	Tooltip,
	useEventListener,
	useNotifications,
} from "@hex-insights/core";
import { anyFieldChanged } from "@hex-insights/forms";
import { useRouteMatch } from "@hex-insights/router";
import {
	DocumentResourceForm,
	DocumentResourceFormState,
	DocumentResourceFormValues,
	DocumentResourceMutation,
} from "@hex-insights/verita.shared";
import {
	resourcesFoldersDetailPageInfo,
	ResourcesFoldersDetailPageRouteParams,
} from "../../Folders/DetailPage/pageinfo";
import styles from "./styles.module.css";

export function UploadButton() {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<Button variant="primary" size="large" onClick={toggleIsModalOpen} style={{ width: "100%" }}>
				<Row justify="spaced-center" horizontalSpacing="0.5rem" align="center">
					<Icon.Plus size="1.5rem" /> Upload
				</Row>
			</Button>

			<Modal.If condition={isModalOpen}>
				<DocumentResourceCreateModal onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

export function UploadBox() {
	const [isDragging, setIsDragging] = React.useState(false);
	const dragTargetRef = React.useRef<any>(null);
	const onDragEnter = React.useCallback<React.DragEventHandler>((event) => {
		if (!event.dataTransfer.types.includes("Files")) {
			return;
		}
		dragTargetRef.current = event.target;
		setIsDragging(true);
	}, []);
	const onDragLeave = React.useCallback<React.DragEventHandler>((event) => {
		if (event.target === dragTargetRef.current || event.target === document) {
			setIsDragging(false);
		}
	}, []);

	useEventListener(window, "dragenter", onDragEnter as unknown as (event: Event) => void);

	const { isModalOpen, toggleIsModalOpen, closeModal } = Modal.useToggle(false);

	const [numFilesUploading, setNumFilesUploading] = React.useState(0);

	const documentResourceFolderID = useRouteMatch<ResourcesFoldersDetailPageRouteParams>(
		resourcesFoldersDetailPageInfo.path as string,
	)?.params.documentResourceFolderID;
	const create = DocumentResourceMutation.useCreate();
	const { addNotification, removeNotification } = useNotifications();
	const onDrop = React.useCallback<React.DragEventHandler>(
		(event) => {
			event.preventDefault();
			setIsDragging(false);

			const numFiles = event.dataTransfer.files.length;
			const notificationID = addNotification(
				(props) => <UploadStartNotification numFiles={numFiles} {...props} />,
				1500,
			);

			const promises: Promise<any>[] = [];
			setNumFilesUploading((prev) => prev + event.dataTransfer.files.length);

			for (let i = 0; i < event.dataTransfer.files.length; i++) {
				const file = event.dataTransfer.files[i];
				const promise = create({
					name: file.name ?? "",
					description: "",
					file,
					hasImage: false,
					image: null,
					isFile: true,
					resourceURL: "",
					documentResourceFolderIDs: documentResourceFolderID ? [documentResourceFolderID] : [],
				}).then(() => setNumFilesUploading((prev) => prev - 1));
				promises.push(promise);
			}

			(async () => {
				await Promise.all(promises);
				removeNotification(notificationID);
				addNotification(UploadCompleteNotification, 2000);
			})();
		},
		[create, documentResourceFolderID, addNotification, removeNotification],
	);

	return (
		<React.Fragment>
			<Column justify="spaced-start" align="center">
				<If condition={numFilesUploading > 0}>
					<span className={styles["upload-box__uploading-text"]}>
						Uploading {toGrammaticalNumber("file", numFilesUploading, true)}...
					</span>
				</If>

				<Tooltip.Container openDelayMS={1000} style={{ width: "100%" }}>
					<Tooltip>
						<Tooltip.Body>
							<Paragraph style={{ width: "10rem" }}>
								Drag and drop one or multiple files to upload to the current folder.
							</Paragraph>
						</Tooltip.Body>
					</Tooltip>

					<div
						onDragOver={preventDefault}
						onDrop={onDrop}
						onClick={toggleIsModalOpen}
						className={makeClassName(styles["upload-box"], isDragging ? styles["upload-box--dragging"] : "")}
					>
						<Column
							justify="center"
							align="center"
							style={{ width: "100%", height: "100%", position: "relative", zIndex: 1 }}
						>
							<span>
								<Icon.UploadCloud size="4rem" />
							</span>
							<span style={{ fontSize: "1.5rem" }}>Upload</span>
						</Column>

						<div className={styles["upload-box__background"]}></div>
					</div>
				</Tooltip.Container>
			</Column>

			<div
				onDragLeave={onDragLeave}
				onDragOver={preventDefault}
				onDrop={onDrop}
				className={makeClassName(
					styles["upload-box__full-screen-overlay"],
					isDragging ? styles["upload-box__full-screen-overlay--dragging"] : "",
				)}
			></div>

			<Modal.If condition={isModalOpen}>
				<DocumentResourceCreateModal onClose={closeModal} />
			</Modal.If>
		</React.Fragment>
	);
}

function UploadStartNotification({ numFiles, ...props }: NotificationRenderProps & { numFiles: number }) {
	return (
		<NotificationContainer variant="general" location="bottom-left" size="small" {...props}>
			Uploading {toGrammaticalNumber("File", numFiles, true)}.{" "}
			<div style={{ width: "1rem", display: "inline-block" }}></div>
		</NotificationContainer>
	);
}

function UploadCompleteNotification(props: NotificationRenderProps) {
	return (
		<NotificationContainer variant="success" location="bottom-left" size="small" {...props}>
			Upload complete. <div style={{ width: "1rem", display: "inline-block" }}></div>
		</NotificationContainer>
	);
}

type DocumentResourceCreateModalProps = {
	file?: File | null;
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function DocumentResourceCreateModal({ file, ifRef, onClose }: DocumentResourceCreateModalProps) {
	const documentResourceFolderID = useRouteMatch<ResourcesFoldersDetailPageRouteParams>(
		resourcesFoldersDetailPageInfo.path as string,
	)?.params.documentResourceFolderID;
	const initialFormValues = React.useMemo(
		() => ({
			name: file ? file.name : "",
			documentResourceFolderIDs: documentResourceFolderID ? [documentResourceFolderID] : [],
			file: file ?? null,
		}),
		[documentResourceFolderID, file],
	);
	const formState = DocumentResourceFormState.useCreateFormState(initialFormValues);

	const create = DocumentResourceMutation.useCreate();
	const applyCreate = React.useCallback(
		async (formValues: DocumentResourceFormValues.Create) => {
			const { errors } = await create(formValues);
			return errors;
		},
		[create],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose={anyFieldChanged(formState)} style={{ width: "fit-content" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					New Resource
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<DocumentResourceForm.Create
					formState={formState}
					applyCreate={applyCreate}
					onSuccess={onClose}
					headingLevel={3}
				/>
			</Modal.Body>
		</Modal>
	);
}
