import React from "react";
import { Heading } from "@hex-insights/core";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { EnrollmentApplicationParentForm } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { enrollmentApplicationParentHubDetailPageInfo } from "../DetailPage/pageinfo";
import { enrollmentApplicationParentHubCreatePageInfo } from "./pageinfo";

export function EnrollmentApplicationParentHubCreatePage() {
	useActivePageRegistration(enrollmentApplicationParentHubCreatePageInfo);

	const { push: pushHistory } = useHistory();
	const onSuccess = React.useCallback<EnrollmentApplicationParentForm.ControlledCreateProps["onSuccess"]>(
		({ id }) => {
			pushHistory(enrollmentApplicationParentHubDetailPageInfo.to(id));
		},
		[pushHistory],
	);

	return (
		<Main>
			<Heading.H1>New Enrollment Application Parent</Heading.H1>

			<EnrollmentApplicationParentForm.ControlledCreate onSuccess={onSuccess} />
		</Main>
	);
}
