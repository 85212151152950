import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const loginRecordHubPageInfo = newPageInfo({
	title: "Login Records",
	description: "Pages related to the Login Record model.",
	exact: false,
	to: hubsPageInfo.to + "/login-records",
	path: hubsPageInfo.path + "/login-records",
	parent: hubsPageInfo,
});
