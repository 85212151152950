import { NullPartial, omitKeys } from "@hex-insights/core";
import {
	EnrollmentApplicationParentCreateInput,
	EnrollmentApplicationParentDetailQuery,
	EnrollmentApplicationParentUpdateInput,
} from "../../GraphQL";
import { EnrollmentApplicationParentFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the EnrollmentApplicationParent model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(
	formValues: EnrollmentApplicationParentFormValues.Create,
): EnrollmentApplicationParentCreateInput {
	const inputFormValues = { ...formValues };
	const input: NullPartial<EnrollmentApplicationParentCreateInput> = { ...inputFormValues };
	return input as EnrollmentApplicationParentCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the EnrollmentApplicationParent model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<EnrollmentApplicationParentFormValues.Detail>,
	_initialFormValues: EnrollmentApplicationParentFormValues.Detail,
): EnrollmentApplicationParentUpdateInput {
	const inputFormValues = omitKeys(formValues, ["name"]);
	const input: EnrollmentApplicationParentUpdateInput = { ...inputFormValues };
	return input;
}

/**
 * Converts a EnrollmentApplicationParent instance to its corresponding detail form values.
 *
 * @param enrollmentApplicationParent The instance to convert.
 */
export function toFormValues(
	enrollmentApplicationParent: EnrollmentApplicationParentDetailQuery["enrollmentApplicationParent"],
): EnrollmentApplicationParentFormValues.Detail {
	return {
		formIdentifier: enrollmentApplicationParent.formIdentifier,
		name: enrollmentApplicationParent.name,
		firstName: enrollmentApplicationParent.firstName,
		lastName: enrollmentApplicationParent.lastName,
		nationality: enrollmentApplicationParent.nationality,
		occupation: enrollmentApplicationParent.occupation,
		relationshipType: enrollmentApplicationParent.relationshipType,
		relationshipTypeCustom: enrollmentApplicationParent.relationshipTypeCustom,
		isPrimaryContact: enrollmentApplicationParent.isPrimaryContact,
		livesWithStudent: enrollmentApplicationParent.livesWithStudent,
		isAuthorizedForPickup: enrollmentApplicationParent.isAuthorizedForPickup,
		isEmergencyContact: enrollmentApplicationParent.isEmergencyContact,
		isFinancialContact: enrollmentApplicationParent.isFinancialContact,
		addressSameAsFormIdentifier: enrollmentApplicationParent.addressSameAsFormIdentifier,
		addressLine1: enrollmentApplicationParent.addressLine1,
		addressLine2: enrollmentApplicationParent.addressLine2,
		city: enrollmentApplicationParent.city,
		stateOrProvince: enrollmentApplicationParent.stateOrProvince,
		postalCode: enrollmentApplicationParent.postalCode,
		country: enrollmentApplicationParent.country,
		homePhone: enrollmentApplicationParent.homePhone,
		workPhone: enrollmentApplicationParent.workPhone,
		mobilePhone: enrollmentApplicationParent.mobilePhone,
		email: enrollmentApplicationParent.email,
		enrollmentApplicationSubmissionID: enrollmentApplicationParent.enrollmentApplicationSubmission?.id ?? null,
	};
}
