import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { HomeRoomSectionFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the HomeRoomSection model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => HomeRoomSectionFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(
		initialFormValues,
		HomeRoomSectionFilterFormValues.typeFilterObject,
	);
}
