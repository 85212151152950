import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { TuitionOrderFormValues } from "../../../../Utilities";

export type TuitionOrderFormControllerProps = {
	formState: FormState<TuitionOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the Tuition model.
 */
export function TuitionOrderFormController({ formState }: TuitionOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={TuitionOrderFormValues.fieldOptions}
			fieldTypeMapping={TuitionOrderFormValues.fieldTypeMapping}
		/>
	);
}
