import React from "react";
import { Conditional, Else, Grid, Heading, If, Row, Section, useSync } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	CourseSectionForm,
	CourseSectionFormValues,
	CourseSectionMutation,
	PersonIcon,
	Tile,
	useCourseSectionDetailQuery,
	useEmployeeIndexQuery,
	useStudentListQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { employeeHubDetailPageInfo } from "../../Employee/DetailPage/pageinfo";
import { studentHubDetailPageInfo } from "../../Student/DetailPage/pageinfo";
import { courseSectionHubDetailPageInfo, CourseSectionHubDetailPageRouteParams } from "./pageinfo";

function CourseSectionHubDetailPageWrapper() {
	// Manages identity of the CourseSectionHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <CourseSectionHubDetailPage key={id} />;
}

export { CourseSectionHubDetailPageWrapper as CourseSectionHubDetailPage };

function CourseSectionHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useCourseSectionDetailQuery({ variables: { id } });
	const courseSection = data?.courseSection;
	useActivePageRegistration(
		courseSectionHubDetailPageInfo,
		!!courseSection ? courseSectionHubDetailPageInfo.title(courseSection) : "",
	);

	const update = CourseSectionMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<CourseSectionFormValues.Detail>,
			initialFormValues: CourseSectionFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading courseSection</If>
				<Else>
					{!!courseSection && (
						<React.Fragment>
							<Heading level={1}>{courseSection.name}</Heading>

							<Row justify="spaced-start">
								<Tile>
									<Tile.Body>
										<CourseSectionForm.ControlledDetail
											key={syncID}
											courseSection={courseSection}
											applyUpdate={applyUpdate}
											onSuccess={synchronize}
										/>
									</Tile.Body>
								</Tile>

								<Tile style={{ flexGrow: 1, width: "100%", overflow: "hidden" }}>
									<Tile.Body>
										<EmployeesSection />
										<StudentsSection />
									</Tile.Body>
								</Tile>
							</Row>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}

function EmployeesSection() {
	const { id } = useRouteParams<CourseSectionHubDetailPageRouteParams>();
	const { loading, data } = useEmployeeIndexQuery({
		variables: {
			filters: {
				courseSectionTeacherEnrollments: [
					{
						courseSection: [
							{
								idEQ: id,
							},
						],
					},
				],
			},
		},
	});

	return (
		<Section>
			<Section.Header>
				<Heading level={3} noMargin>
					Teachers
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Grid columns={5} gap="1rem">
							{data?.employeeConnection.edges.map((e) => (
								<InternalLink
									key={e.node.id}
									to={employeeHubDetailPageInfo.to(e.node.id)}
									className="link--no-text-decoration"
								>
									<PersonIcon person={e.node.person} withTooltipPreview employeeID={e.node.id} />
								</InternalLink>
							))}
						</Grid>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}

function StudentsSection() {
	const { id } = useRouteParams<CourseSectionHubDetailPageRouteParams>();
	const { loading, data } = useStudentListQuery({
		variables: {
			filters: [
				{
					courseSectionStudentEnrollments: [
						{
							courseSection: [
								{
									idEQ: id,
								},
							],
						},
					],
				},
			],
		},
	});

	return (
		<Section>
			<Section.Header>
				<Heading level={3} noMargin>
					Students
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Grid columns={5} gap="1rem">
							{data?.studentConnection.edges.map((e) => (
								<InternalLink
									key={e.node.id}
									to={studentHubDetailPageInfo.to(e.node.id)}
									className="link--no-text-decoration"
								>
									<PersonIcon person={e.node.person} withName withTooltipPreview studentID={e.node.id} />
								</InternalLink>
							))}
						</Grid>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}
