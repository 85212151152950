import React from "react";
import { Column, Conditional, Else, If, Section, useTimeout, useUpdateEffect } from "@hex-insights/core";
import { TextField, useFormState, ValidationDisplayPolicy } from "@hex-insights/forms";
import { useActivePageRegistration } from "@hex-insights/router";
import { useDocumentResourceFolderIndexQuery, useDocumentResourceIndexQuery } from "@hex-insights/verita.shared";
import {
	DocumentResourceDisplay,
	DocumentResourceDisplayProps,
	DocumentResourceFolderDisplay,
	DocumentResourceFolderDisplayProps,
	FoldersGrid,
	ResourcesGrid,
} from "../Components";
import { resourcesSearchPageInfo } from "./pageinfo";

type SearchFormValues = {
	search: string;
};

const initialSearchFormValues: SearchFormValues = {
	search: "",
};

export function ResourcesSearchPage() {
	useActivePageRegistration(resourcesSearchPageInfo);

	const formState = useFormState({
		initialFormValues: initialSearchFormValues,
		validationDisplayPolicy: ValidationDisplayPolicy.none,
	});

	const { search: searchValue } = formState.formValues;
	const [querySearchValue, setQuerySearchValue] = React.useState("");
	const { setTimeout } = useTimeout();
	useUpdateEffect(() => {
		setTimeout(() => {
			setQuerySearchValue(searchValue);
		}, 450);
	}, [searchValue]);

	const { loading: loadingFolders, data: foldersData } = useDocumentResourceFolderIndexQuery({
		variables: {
			filters: { search: querySearchValue },
			pagination: { first: 100 },
		},
	});
	const { loading: loadingResources, data: resourcesData } = useDocumentResourceIndexQuery({
		variables: {
			filters: { search: querySearchValue },
			pagination: { first: 100 },
		},
	});

	return (
		<Column justify="spaced-start" style={{ width: "100%", overflowX: "hidden" }}>
			<form>
				<TextField formState={formState} name="search" autoFocus />
			</form>
			<Conditional>
				<If condition={loadingFolders || loadingResources}>Loading...</If>
				<Else>
					<Folders documentResourceFolderEdges={foldersData?.documentResourceFolderConnection.edges ?? []} />
					<Documents documentResourceEdges={resourcesData?.documentResourceConnection.edges ?? []} />
				</Else>
			</Conditional>
		</Column>
	);
}

type FoldersProps = {
	documentResourceFolderEdges: { node: DocumentResourceFolderDisplayProps["documentResourceFolder"] }[];
};

function Folders({ documentResourceFolderEdges }: FoldersProps) {
	if (documentResourceFolderEdges.length === 0) {
		return null;
	}

	return (
		<Section>
			<Section.Body style={{ paddingLeft: 0, paddingRight: 0 }}>
				<FoldersGrid>
					{documentResourceFolderEdges.map((e) => (
						<DocumentResourceFolderDisplay key={e.node.id} documentResourceFolder={e.node} style={{ width: "100%" }} />
					))}
				</FoldersGrid>
			</Section.Body>
		</Section>
	);
}

type DocumentsProps = {
	documentResourceEdges: { node: DocumentResourceDisplayProps["documentResource"] }[];
};

function Documents({ documentResourceEdges }: DocumentsProps) {
	if (documentResourceEdges.length === 0) {
		return null;
	}

	return (
		<Section>
			<Section.Body style={{ paddingLeft: 0, paddingRight: 0 }}>
				<ResourcesGrid>
					{documentResourceEdges.map((e) => (
						<DocumentResourceDisplay key={e.node.id} documentResource={e.node} style={{ width: "100%" }} />
					))}
				</ResourcesGrid>
			</Section.Body>
		</Section>
	);
}
