import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { CourseSectionPeriod, CourseSectionPeriodFormat, CourseSectionPeriodNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<CourseSectionPeriod, "id"> & CourseSectionPeriodNameFragment;

export type CourseSectionPeriodLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given CourseSectionPeriod instance.
 */
export function CourseSectionPeriodLink(props: CourseSectionPeriodLinkProps) {
	return (
		<Models.Link<ModelForLink> pageInfoKey="CourseSectionPeriod" format={CourseSectionPeriodFormat.name} {...props} />
	);
}
