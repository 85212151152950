import { newPageInfo } from "@hex-insights/router";
import { courseSectionPeriodHubPageInfo } from "../pageinfo";

export const courseSectionPeriodHubCreatePageInfo = newPageInfo({
	name: "New Course Section Period",
	title: "New",
	description: "Create a new Course Section Period record.",
	exact: true,
	to: courseSectionPeriodHubPageInfo.to + "/new",
	path: courseSectionPeriodHubPageInfo.path + "/new",
	parent: courseSectionPeriodHubPageInfo,
});
