import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, If, mappedArray, Row } from "@hex-insights/core";
import { FormState } from "@hex-insights/forms";
import { useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	EnrollmentInvitationFilterFormController,
	EnrollmentInvitationFilterFormState,
	EnrollmentInvitationFilterFormUtils,
	EnrollmentInvitationFilterFormValues,
	EnrollmentInvitationFormat,
	EnrollmentInvitationOrderField,
	EnrollmentInvitationOrderFormController,
	EnrollmentInvitationOrderFormState,
	EnrollmentInvitationOrderFormValues,
	EnrollmentInvitationPaginationForm,
	EnrollmentInvitationResponse,
	EnrollmentInvitationResponseFilterButtons,
	EnrollmentInvitationResponseFilterButtonsProps,
	EnrollmentInvitationSearchForm,
	HR,
	PersonIcon,
	StudentFormat,
	useEnrollmentInvitationIndexQuery,
} from "@hex-insights/verita.shared";
import { admissionsEnrollmentInvitationsPageInfo } from "./pageinfo";
import styles from "./styles.module.css";

const columns = ["studentImageURL", "student", "createdAt", "response"];

const columnWidths = {
	studentImageURL: "4rem",
};

function orderFieldToColumn(field: EnrollmentInvitationOrderField | null) {
	if (field === null) {
		return "id";
	}
	return EnrollmentInvitationOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return EnrollmentInvitationOrderFormValues.modelFieldToOrderField[column];
}

export function AdmissionsEnrollmentInvitationsPage() {
	useActivePageRegistration(admissionsEnrollmentInvitationsPageInfo);

	const filterFormState = EnrollmentInvitationFilterFormState.useFormState();
	const orderFormState = EnrollmentInvitationOrderFormState.useFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(
		filterFormState.formValues,
		EnrollmentInvitationFilterFormUtils.toFilterInputs,
	);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useEnrollmentInvitationIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		EnrollmentInvitationFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = EnrollmentInvitationFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.enrollmentInvitationConnection.edges.length ?? 0),
		0,
	);

	return (
		<div>
			<Row justify="spaced-start" align="center" overflow="wrap" style={{ marginBottom: "0.75rem" }}>
				<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
					<EnrollmentInvitationSearchForm formState={filterFormState} />
					<EnrollmentInvitationFilterFormController formState={filterFormState} numActiveFilters={numActiveFilters} />
					<If condition={numActiveFilters > 0}>
						<Button variant="link" size="small" onClick={clearFilters}>
							Clear
						</Button>
					</If>
					<EnrollmentInvitationOrderFormController formState={orderFormState} />
				</Row>

				<HR color="var(--verita-blue)" style={{ height: "1.5rem" }} />
				<QuickFilters filterFormState={filterFormState} />
			</Row>

			<Table.Container className="hub__index-page__table__container">
				<Table
					columns={columns}
					columnWidths={columnWidths}
					minColumnWidth="10rem"
					scrollSyncID={paginationFormState.formValues.cursor}
					{...tableSortingProps}
					className="hub__index-page__table"
				>
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="studentImageURL" noSort></Table.Heading>
							<Table.Heading column="student" noSort>
								Student
							</Table.Heading>
							<Table.Heading column="createdAt">Sent At</Table.Heading>
							<Table.Heading column="response">Response</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.enrollmentInvitationConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell
											column="studentImageURL"
											oneLine
											style={{ paddingTop: "0.1rem", paddingBottom: "0.1rem" }}
										>
											{e.node.student && (
												<Row justify="center" align="center" style={{ height: "100%" }}>
													<PersonIcon person={e.node.student.person} imageSize="3rem" />
												</Row>
											)}
										</Table.Cell>
										<Table.Cell column="student" oneLine>
											{e.node.student && StudentFormat.name(e.node.student)}
										</Table.Cell>
										<Table.Cell column="createdAt" oneLine>
											{EnrollmentInvitationFormat.Fields.createdAt(e.node.createdAt)}
										</Table.Cell>
										<Table.Cell column="response" oneLine className={styles["table-cell--response"]}>
											<Response response={e.node.response} />
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<EnrollmentInvitationPaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.enrollmentInvitationConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</div>
	);
}

type ResponseProps = {
	response: EnrollmentInvitationResponse;
};

function Response({ response }: ResponseProps) {
	return (
		<span className={styles["response"] + " " + styles[`response--${response}`]}>
			{EnrollmentInvitationFormat.Fields.response(response)}
		</span>
	);
}

const statusFilterButtonProps: EnrollmentInvitationResponseFilterButtonsProps["buttonProps"] = {
	size: "small",
	style: {
		paddingTop: "0.5rem",
		paddingBottom: "0.5rem",
		borderRadius: "1rem",
	},
};

type QuickFiltersProps = {
	filterFormState: FormState<EnrollmentInvitationFilterFormValues.FormValues>;
};

function QuickFilters({ filterFormState }: QuickFiltersProps) {
	return (
		<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
			<EnrollmentInvitationResponseFilterButtons formState={filterFormState} buttonProps={statusFilterButtonProps} />
		</Row>
	);
}
