import { IndexForms } from "@hex-insights/app-modules";
import { BusFilterFormValues } from "../../../../Utilities";

export type BusSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the Bus model.
 */
export function BusSearchForm(props: BusSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type BusFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, BusFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Bus model.
 */
export function BusFilterFormController(props: BusFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={BusFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `BusFilterForm`
