import { newPageInfo } from "@hex-insights/router";
import { academicYearHubPageInfo } from "../pageinfo";

export const academicYearHubCreatePageInfo = newPageInfo({
	name: "New Academic Year",
	title: "New",
	description: "Academic Year create page.",
	exact: true,
	to: academicYearHubPageInfo.to + "/new",
	path: academicYearHubPageInfo.path + "/new",
	parent: academicYearHubPageInfo,
});
