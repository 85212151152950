import { newPageInfo } from "@hex-insights/router";
import { enrollmentApplicationParentHubPageInfo } from "../pageinfo";

export const enrollmentApplicationParentHubCreatePageInfo = newPageInfo({
	name: "New Enrollment Application Parent",
	title: "New",
	description: "Create a new Enrollment Application Parent record.",
	exact: true,
	to: enrollmentApplicationParentHubPageInfo.to + "/new",
	path: enrollmentApplicationParentHubPageInfo.path + "/new",
	parent: enrollmentApplicationParentHubPageInfo,
});
