import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { BusPlan, BusPlanFormat, BusPlanNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<BusPlan, "id"> & BusPlanNameFragment;

export type BusPlanLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given BusPlan instance.
 */
export function BusPlanLink(props: BusPlanLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="BusPlan" format={BusPlanFormat.name} {...props} />;
}
