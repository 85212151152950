import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export type ResourcesPageRouteParams = {};

export const resourcesPageInfo = newPageInfo({
	title: "Resources",
	description: "School resources.",
	exact: false,
	to: "/resources",
	path: "/resources",
	parent: rootPageInfo,
});
