import React from "react";
import { Column } from "@hex-insights/core";
import { FormState, FormType } from "@hex-insights/forms";
import {
	LoginRecordDetailQuery,
	LoginRecordFormConversion,
	LoginRecordFormState,
	LoginRecordFormValues,
} from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledReadOnlyProps = {
	loginRecord: LoginRecordDetailQuery["loginRecord"];
};

/**
 * Renders a read-only detail form of the LoginRecord model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => LoginRecordFormConversion.toFormValues(props.loginRecord),
		[props.loginRecord],
	);
	const formState = LoginRecordFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<LoginRecordFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the LoginRecord model using the given form state.
 */
export function ReadOnly({ formState, loginRecord }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.CreatedAt formState={formState} formType={FormType.Update} id={loginRecord.id} />
			<Field.UserEmail formState={formState} formType={FormType.Update} id={loginRecord.id} />
			<Field.WasBlocked formState={formState} formType={FormType.Update} id={loginRecord.id} />
			<Field.WasSuccessful formState={formState} formType={FormType.Update} id={loginRecord.id} />
			<Field.IsTrusted formState={formState} formType={FormType.Update} id={loginRecord.id} />
			<Field.Session
				formState={formState}
				formType={FormType.Update}
				id={loginRecord.id}
				currentSession={loginRecord.session}
			/>
			<Field.User formState={formState} formType={FormType.Update} id={loginRecord.id} currentUser={loginRecord.user} />
		</Column>
	);
}
