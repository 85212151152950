import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	graphTypeNames,
	Note,
	NoteAttachment,
	NoteAttachmentCreateInput,
	NoteAttachmentCreateMutation,
	NoteAttachmentDeleteMutation,
	NoteAttachmentDetailDocument,
	NoteAttachmentDetailQuery,
	NoteAttachmentDetailQueryVariables,
	NoteAttachmentUpdateInput,
	NoteAttachmentUpdateMutation,
	useNoteAttachmentCreateMutation,
	useNoteAttachmentDeleteMutation,
	useNoteAttachmentUpdateMutation,
} from "../../GraphQL";
import { NoteAttachmentFormConversion } from "../ModelFormConversion";
import { NoteAttachmentFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the NoteAttachment model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the NoteAttachment model.
 */
export function useCreate() {
	const [createNoteAttachment] = useNoteAttachmentCreateMutation();

	return React.useCallback(
		async (formValues: NoteAttachmentFormValues.Create) => {
			const input = NoteAttachmentFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createNoteAttachment({ variables: { input }, update: updateCache });

			return { data: data?.createNoteAttachment ?? null, errors: errors ?? null };
		},
		[createNoteAttachment],
	);
}

/**
 * Returns an `update` function for the NoteAttachment model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the NoteAttachment model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: NoteAttachment["id"]) {
	const [updateNoteAttachment] = useNoteAttachmentUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<NoteAttachmentFormValues.Detail>,
			initialFormValues: NoteAttachmentFormValues.Detail,
		) => {
			const input = NoteAttachmentFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateNoteAttachment({ variables: { id, input }, update: updateCache });

			return { data: data?.updateNoteAttachment ?? null, errors: errors ?? null };
		},
		[updateNoteAttachment, id],
	);
}

/**
 * Returns a `del` function for the NoteAttachment model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: NoteAttachment["id"]) {
	const [deleteNoteAttachment] = useNoteAttachmentDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteNoteAttachment({ variables: { id }, update: updateCache });

		return { data: data?.deleteNoteAttachment ?? false, errors: errors ?? null };
	}, [deleteNoteAttachment, id]);
}

function getUpdateCacheForCreate(input: NoteAttachmentCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<NoteAttachmentCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createNoteAttachment;

		cache.writeQuery<NoteAttachmentDetailQuery, NoteAttachmentDetailQueryVariables>({
			query: NoteAttachmentDetailDocument,
			data: { noteAttachment: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.noteID) {
			addToAttachmentsOfNoteCache(cache, input.noteID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "noteAttachmentConnection" });
	};
}

function getUpdateCacheForUpdate(
	_input: NoteAttachmentUpdateInput,
	_initialFormValues: NoteAttachmentFormValues.Detail,
) {
	return (
		_cache: ApolloCache<any>,
		result: Omit<FetchResult<NoteAttachmentUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}
	};
}

function getUpdateCacheForDelete(id: NoteAttachment["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<NoteAttachmentDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteNoteAttachment) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.NoteAttachment }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "noteAttachmentConnection" });
		cache.gc();
	};
}

function addToAttachmentsOfNoteCache(
	cache: ApolloCache<any>,
	targetID: Note["id"],
	object: Pick<NoteAttachment, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Note }),
		fields: {
			attachments: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<NoteAttachment, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment NoteAttachmentRef on NoteAttachment {
				id
			}
		`,
		data: object,
	});
}
