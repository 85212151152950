import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Drawer, Else, If, makeClassName, mappedArray, Row } from "@hex-insights/core";
import { FormState } from "@hex-insights/forms";
import { useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	EnrollmentApplicationFilterFormController,
	EnrollmentApplicationFilterFormValues,
	EnrollmentApplicationFormat,
	EnrollmentApplicationOrderFormController,
	EnrollmentApplicationPaginationForm,
	EnrollmentApplicationParentFormat,
	EnrollmentApplicationSearchForm,
	EnrollmentApplicationSeenStatusFilterButtons,
	EnrollmentApplicationStatusFilterButtons,
	EnrollmentApplicationStatusFilterButtonsProps,
	EnrollmentApplicationStudentFormat,
	HR,
	PersonIcon,
} from "@hex-insights/verita.shared";
import {
	EnrollmentApplicationDrawer,
	EnrollmentApplicationStatusBadge,
	EnrollmentApplicationViewStatus,
	InviteEmailController,
	useEnrollmentApplicationDetailDrawer,
} from "../Components";
import { useEnrollmentApplicationsIndex } from "../use-enrollment-applications-index";
import { admissionsApplicationsPageInfo } from "./pageinfo";
import generalStyles from "../enrollment-applications.module.css";

const columns = [
	"viewStatus",
	"studentImageURL",
	"studentName",
	"status",
	"createdAt",
	"interviewMeetingTime",
	"studentDateOfBirth",
	"studentGradeLevelApplyingFor",
	"parent1Name",
	"parent2Name",
];

const columnWidths = {
	viewStatus: "3rem",
	studentImageURL: "4rem",
	status: "7rem",
	createdAt: "8rem",
	interviewMeetingTime: "8rem",
	studentGradeLevelApplyingFor: "6rem",
};

export function AdmissionsApplicationsPage() {
	useActivePageRegistration(admissionsApplicationsPageInfo);

	const {
		filterFormState,
		orderFormState,
		paginationFormState,
		filterInputs,
		orderInput,
		numActiveFilters,
		data,
		error,
		clearFilters,
		paginationInfo,
		setPaginationInfo,
		tableSortingProps,
		numFillerRows,
	} = useEnrollmentApplicationsIndex();

	const { openEnrollmentApplicationID, isDrawerOpen, openDrawer, closeDrawer } = useEnrollmentApplicationDetailDrawer();

	return (
		<div className="admissions__applications">
			<Row justify="space-between" style={{ marginBottom: "0.75rem" }}>
				<Row justify="spaced-start" align="center" overflow="wrap">
					<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
						<EnrollmentApplicationSearchForm formState={filterFormState} />
						<EnrollmentApplicationFilterFormController
							formState={filterFormState}
							numActiveFilters={numActiveFilters}
						/>
						<If condition={numActiveFilters > 0}>
							<Button variant="link" size="small" onClick={clearFilters}>
								Clear
							</Button>
						</If>
						<EnrollmentApplicationOrderFormController formState={orderFormState} />
					</Row>
					<HR color="var(--verita-blue)" style={{ height: "1.5rem" }} />
					<QuickFilters filterFormState={filterFormState} />
				</Row>

				<InviteEmailController />
			</Row>

			<Drawer.If condition={isDrawerOpen}>
				<EnrollmentApplicationDrawer
					key={openEnrollmentApplicationID}
					enrollmentApplicationID={openEnrollmentApplicationID ?? ""}
					onClose={closeDrawer}
				/>
			</Drawer.If>

			<Table.Container className="hub__index-page__table__container">
				<Table
					columns={columns}
					columnWidths={columnWidths}
					minColumnWidth="10rem"
					{...tableSortingProps}
					className="hub__index-page__table"
				>
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="viewStatus" noSort></Table.Heading>
							<Table.Heading column="studentImageURL"></Table.Heading>
							<Table.Heading column="studentName" noSort>
								Student
							</Table.Heading>
							<Table.Heading column="status">Status</Table.Heading>
							<Table.Heading column="createdAt">Submitted</Table.Heading>
							<Table.Heading column="interviewMeetingTime">Interview</Table.Heading>
							<Table.Heading column="studentDateOfBirth" noSort>
								Age (DOB)
							</Table.Heading>
							<Table.Heading column="studentGradeLevelApplyingFor" noSort>
								Grade Level
							</Table.Heading>
							<Table.Heading column="parent1Name" noSort>
								Parent/Guardian 1
							</Table.Heading>
							<Table.Heading column="parent2Name" noSort>
								Parent/Guardian 2
							</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={!data}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.enrollmentApplicationConnection.edges.map((e) => {
									const student = e.node.enrollmentApplicationStudent;
									const parents = student.enrollmentApplicationSubmission.enrollmentApplicationParents;
									const isNew = e.node.enrollmentApplicationUserViewRecords.length === 0;
									const isSeen = e.node.enrollmentApplicationUserViewRecords.some((e) => e.isDetail);

									return (
										<Table.Row
											key={e.node.id}
											onClick={() => openDrawer(e.node.id)}
											className={makeClassName(
												"hub__index-page__table__row",
												isNew ? generalStyles["table-row--new"] : "",
												!isSeen ? generalStyles["table-row--unseen"] : "",
											)}
										>
											<Table.Cell column="viewStatus" oneLine>
												<EnrollmentApplicationViewStatus viewRecords={e.node.enrollmentApplicationUserViewRecords} />
											</Table.Cell>
											<Table.Cell
												column="studentImageURL"
												oneLine
												style={{ paddingTop: "0.1rem", paddingBottom: "0.1rem" }}
											>
												<Row justify="center" align="center" style={{ height: "100%" }}>
													<PersonIcon person={e.node.enrollmentApplicationStudent} imageSize="3rem" />
												</Row>
											</Table.Cell>
											<Table.Cell column="studentName" oneLine>
												{EnrollmentApplicationStudentFormat.name(student)}
											</Table.Cell>
											<Table.Cell column="status" oneLine>
												<EnrollmentApplicationStatusBadge status={e.node.status} hasConfirmed={!!student.studentID} />
											</Table.Cell>
											<Table.Cell column="createdAt" oneLine>
												{EnrollmentApplicationFormat.Fields.createdAt(e.node.createdAt)}
											</Table.Cell>
											<Table.Cell column="interviewMeetingTime" oneLine>
												{EnrollmentApplicationFormat.Fields.interviewMeetingTime(e.node.interviewMeetingTime)}
											</Table.Cell>
											<Table.Cell column="studentDateOfBirth" oneLine>
												{EnrollmentApplicationStudentFormat.age(student)} (
												{EnrollmentApplicationStudentFormat.Fields.dateOfBirth(student.dateOfBirth)})
											</Table.Cell>
											<Table.Cell column="studentGradeLevelApplyingFor" oneLine>
												{EnrollmentApplicationStudentFormat.Fields.gradeLevelApplyingFor(student.gradeLevelApplyingFor)}
											</Table.Cell>
											<Table.Cell column="parent1Name" oneLine>
												{parents.length > 0 ? EnrollmentApplicationParentFormat.name(parents[0]) : ""}
											</Table.Cell>
											<Table.Cell column="parent2Name" oneLine>
												{parents.length > 1 ? EnrollmentApplicationParentFormat.name(parents[1]) : ""}
											</Table.Cell>
										</Table.Row>
									);
								})}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<EnrollmentApplicationPaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={!data}>Loading...</If>
								<If condition={data?.enrollmentApplicationConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</div>
	);
}

const statusFilterButtonProps: EnrollmentApplicationStatusFilterButtonsProps["buttonProps"] = {
	size: "small",
	style: {
		paddingTop: "0.5rem",
		paddingBottom: "0.5rem",
		borderRadius: "1rem",
	},
};

type QuickFiltersProps = {
	filterFormState: FormState<EnrollmentApplicationFilterFormValues.FormValues>;
};

function QuickFilters({ filterFormState }: QuickFiltersProps) {
	return (
		<Row justify="spaced-start" align="center">
			<Row justify="spaced-start" horizontalSpacing="0.25rem">
				<EnrollmentApplicationSeenStatusFilterButtons
					formState={filterFormState}
					buttonProps={statusFilterButtonProps}
				/>
			</Row>
			<div style={{ height: "1.5rem", borderLeft: "1px solid #eee" }}></div>
			<Row justify="spaced-start" horizontalSpacing="0.25rem">
				<EnrollmentApplicationStatusFilterButtons formState={filterFormState} buttonProps={statusFilterButtonProps} />
			</Row>
		</Row>
	);
}
