import { IndexForms } from "@hex-insights/app-modules";
import { CourseAttendanceRecordFilterFormValues } from "../../../../Utilities";

export type CourseAttendanceRecordSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the CourseAttendanceRecord model.
 */
export function CourseAttendanceRecordSearchForm(props: CourseAttendanceRecordSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type CourseAttendanceRecordFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, CourseAttendanceRecordFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the CourseAttendanceRecord model.
 */
export function CourseAttendanceRecordFilterFormController(props: CourseAttendanceRecordFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={CourseAttendanceRecordFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `CourseAttendanceRecordFilterForm`
