import { EnrollmentApplicationSubmission } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the createdAt field of the EnrollmentApplicationSubmission model.
 */
export function createdAt(value: EnrollmentApplicationSubmission["createdAt"] | null): string {
	return toString(value);
}

/**
 * Formats the doesConsentToDataUse field of the EnrollmentApplicationSubmission model.
 */
export function doesConsentToDataUse(value: EnrollmentApplicationSubmission["doesConsentToDataUse"]): string {
	return toString(value);
}

/**
 * Formats the signatureName field of the EnrollmentApplicationSubmission model.
 */
export function signatureName(value: EnrollmentApplicationSubmission["signatureName"]): string {
	return toString(value);
}

/**
 * Formats the signatureDate field of the EnrollmentApplicationSubmission model.
 */
export function signatureDate(value: EnrollmentApplicationSubmission["signatureDate"]): string {
	return toString(value);
}
