import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { EnrollmentApplicationStudentFilterInput } from "../../GraphQL";
import { EnrollmentApplicationStudentFilterFormValues } from "../ModelFilterFormValues";

/**
 * Counts the number of active filters for the EnrollmentApplicationStudent model.
 *
 * @param formValues The form values of the filter form.
 */
export function countActiveFilters(formValues: EnrollmentApplicationStudentFilterFormValues.FormValues) {
	// TODO count quick filters
	const numAdvancedFilters = IndexForms.countActiveAdvancedFilters(
		formValues,
		EnrollmentApplicationStudentFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	);
	return numAdvancedFilters;
}

/**
 * Clears the values of the filter form for the EnrollmentApplicationStudent model.
 *
 * @param formState The form state of the filter form.
 */
export function clearFilters(formState: FormState<EnrollmentApplicationStudentFilterFormValues.FormValues>) {
	// TODO clear quick filters
	formState.formClearFunctions.filterSets();
}

/**
 * Resets the values of the filter form for the EnrollmentApplicationStudent model.
 *
 * @param formState The form state of the filter form.
 */
export function resetFilters(formState: FormState<EnrollmentApplicationStudentFilterFormValues.FormValues>) {
	// TODO reset quick filters
	formState.formResetFunctions.filterSets();
}

/**
 * Converts the form values of the filter form for the EnrollmentApplicationStudent model to the correct GraphQL
 * filter input type.
 *
 * @param formValues The form values of the filter form.
 */
export function toFilterInputs(
	formValues: EnrollmentApplicationStudentFilterFormValues.FormValues,
): EnrollmentApplicationStudentFilterInput | EnrollmentApplicationStudentFilterInput[] {
	return IndexForms.toFilterInput(
		formValues,
		EnrollmentApplicationStudentFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	) as EnrollmentApplicationStudentFilterInput;
}
