import { newPageInfo } from "@hex-insights/router";
import { DocumentResource } from "@hex-insights/verita.shared";
import { documentResourceHubPageInfo } from "../pageinfo";

type DocumentResourceForRouteTitle = Pick<DocumentResource, "name">;

export const documentResourceHubDetailPageInfo = newPageInfo({
	name: "Document Resource Detail",
	title: (e: DocumentResourceForRouteTitle) => e.name,
	description: "Detail page for an documentResource.",
	exact: false,
	isLeaf: true,
	to: (id: DocumentResource["id"]) => documentResourceHubPageInfo.to + `/${id}`,
	path: documentResourceHubPageInfo.path + "/:id(\\d+)",
	parent: documentResourceHubPageInfo,
});
