import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const serviceSubscriptionHubPageInfo = newPageInfo({
	title: "Service Subscriptions",
	description: "Pages related to the Service Subscription model.",
	exact: false,
	to: hubsPageInfo.to + "/service-subscriptions",
	path: hubsPageInfo.path + "/service-subscriptions",
	parent: hubsPageInfo,
});
