import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const enrollmentApplicationSubmissionHubPageInfo = newPageInfo({
	title: "Enrollment Application Submissions",
	description: "Pages related to the Enrollment Application Submission model.",
	exact: false,
	to: hubsPageInfo.to + "/enrollment-application-submissions",
	path: hubsPageInfo.path + "/enrollment-application-submissions",
	parent: hubsPageInfo,
});
