import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { LessonPlanOrderFormValues } from "../../../../Utilities";

export type LessonPlanOrderFormControllerProps = {
	formState: FormState<LessonPlanOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the LessonPlan model.
 */
export function LessonPlanOrderFormController({ formState }: LessonPlanOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={LessonPlanOrderFormValues.fieldOptions}
			fieldTypeMapping={LessonPlanOrderFormValues.fieldTypeMapping}
		/>
	);
}
