import React from "react";
import { Heading } from "@hex-insights/core";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { BillingSetupSubmissionForm } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { billingSetupSubmissionHubDetailPageInfo } from "../DetailPage/pageinfo";
import { billingSetupSubmissionHubCreatePageInfo } from "./pageinfo";

export function BillingSetupSubmissionHubCreatePage() {
	useActivePageRegistration(billingSetupSubmissionHubCreatePageInfo);

	const { push: pushHistory } = useHistory();
	const onSuccess = React.useCallback<BillingSetupSubmissionForm.ControlledCreateProps["onSuccess"]>(
		({ id }) => {
			pushHistory(billingSetupSubmissionHubDetailPageInfo.to(id));
		},
		[pushHistory],
	);

	return (
		<Main>
			<Heading.H1>New Billing Setup Submission</Heading.H1>

			<BillingSetupSubmissionForm.ControlledCreate onSuccess={onSuccess} />
		</Main>
	);
}
