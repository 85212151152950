import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { EnrollmentInvitationOrderFormValues } from "../../../../Utilities";

export type EnrollmentInvitationOrderFormControllerProps = {
	formState: FormState<EnrollmentInvitationOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the EnrollmentInvitation model.
 */
export function EnrollmentInvitationOrderFormController({ formState }: EnrollmentInvitationOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={EnrollmentInvitationOrderFormValues.fieldOptions}
			fieldTypeMapping={EnrollmentInvitationOrderFormValues.fieldTypeMapping}
		/>
	);
}
