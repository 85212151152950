import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { CourseSectionCreateInput, CourseSectionDetailQuery, CourseSectionUpdateInput } from "../../GraphQL";
import { CourseSectionFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the CourseSection model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: CourseSectionFormValues.Create): CourseSectionCreateInput {
	const input: NullPartial<CourseSectionCreateInput> = { ...formValues };
	return input as CourseSectionCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the CourseSection model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<CourseSectionFormValues.Detail>,
	initialFormValues: CourseSectionFormValues.Detail,
): CourseSectionUpdateInput {
	const inputFormValues = omitKeys(formValues, ["courseSectionPeriodIDs"]);
	const input: CourseSectionUpdateInput = { ...inputFormValues };

	if (formValues.courseSectionPeriodIDs !== undefined) {
		const { added, removed } = getArrayDiff(
			initialFormValues.courseSectionPeriodIDs,
			formValues.courseSectionPeriodIDs,
		);
		input.addCourseSectionPeriodIDs = added;
		input.removeCourseSectionPeriodIDs = removed;
	}

	return input;
}

/**
 * Converts a CourseSection instance to its corresponding detail form values.
 *
 * @param courseSection The instance to convert.
 */
export function toFormValues(courseSection: CourseSectionDetailQuery["courseSection"]): CourseSectionFormValues.Detail {
	return {
		name: courseSection.name,
		description: courseSection.description,
		courseID: courseSection.course?.id ?? null,
		courseSectionPeriodIDs: courseSection.courseSectionPeriods.map((e) => e.id),
		homeRoomSectionID: courseSection.homeRoomSection?.id ?? null,
		termID: courseSection.term?.id ?? null,
	};
}
