import { Column, Icon, Row, Section, StyleProps } from "@hex-insights/core";
import { NavLink, Route, RouteSwitch, useActivePageRegistration } from "@hex-insights/router";
import { Main } from "../../Components";
import { DocumentResourceFolderCreateButton, UploadBox, UploadButton } from "./Components";
import { ResourcesFoldersPage } from "./Folders";
import { resourcesFoldersPageInfo } from "./Folders/pageinfo";
import { ResourcesIndexPage } from "./IndexPage";
import { resourcesIndexPageInfo } from "./IndexPage/pageinfo";
import { resourcesPageInfo } from "./pageinfo";
import { ResourcesRecentsPage } from "./Recents";
import { resourcesRecentsPageInfo } from "./Recents/pageinfo";
import { ResourcesSearchPage } from "./Search";
import { resourcesSearchPageInfo } from "./Search/pageinfo";
import { ResourcesSharedPage } from "./Shared";
import { resourcesSharedPageInfo } from "./Shared/pageinfo";
import styles from "./styles.module.css";

export function ResourcesPage() {
	useActivePageRegistration(resourcesPageInfo);

	return (
		<Main style={{ paddingLeft: 0 }}>
			<Row
				justify="spaced-start"
				style={{
					minHeight: "calc(100vh - var(--header---height) - 2 * var(--main---padding-top))",
					width: "100%",
				}}
			>
				<LeftBar style={{ width: "25%", maxWidth: "15rem" }} />

				<RouteSwitch>
					<Route {...resourcesIndexPageInfo.routeProps}>
						<ResourcesIndexPage />
					</Route>
					<Route {...resourcesSearchPageInfo.routeProps}>
						<ResourcesSearchPage />
					</Route>
					<Route {...resourcesSharedPageInfo.routeProps}>
						<ResourcesSharedPage />
					</Route>
					<Route {...resourcesRecentsPageInfo.routeProps}>
						<ResourcesRecentsPage />
					</Route>
					<Route {...resourcesFoldersPageInfo.routeProps}>
						<ResourcesFoldersPage />
					</Route>
				</RouteSwitch>
			</Row>
		</Main>
	);
}

function LeftBar({ style }: Partial<StyleProps>) {
	return (
		<Section className={styles["left-bar"]} style={style}>
			<Section.Body style={{ height: "100%" }}>
				<Column justify="space-between" style={{ height: "100%" }}>
					<Column justify="spaced-start">
						<NavLink
							exact={resourcesSearchPageInfo.exact}
							to={resourcesSearchPageInfo.to}
							className={styles["left-bar__nav__link"]}
							activeClassName={styles["left-bar__nav__link--location-active"]}
						>
							<Row justify="spaced-start" align="center">
								<Icon.Search size="1.5rem" /> <span>Search</span>
							</Row>
						</NavLink>

						<Column justify="spaced-start" verticalSpacing="0.25rem">
							<UploadButton />
							<DocumentResourceFolderCreateButton
								variant="tertiary"
								size="small"
								iconSize="0.9rem"
								style={{ width: "100%" }}
							/>
						</Column>

						<nav className={styles["left-bar__nav"]}>
							<Column justify="spaced-start">
								<NavLink
									exact={resourcesIndexPageInfo.exact}
									to={resourcesIndexPageInfo.to}
									className={styles["left-bar__nav__link"]}
									activeClassName={styles["left-bar__nav__link--location-active"]}
								>
									<Row justify="spaced-start" align="center">
										<Icon.Folder size="1.5rem" /> <span>My Folders</span>
									</Row>
								</NavLink>
								<NavLink
									to={resourcesSharedPageInfo.to}
									className={styles["left-bar__nav__link"]}
									activeClassName={styles["left-bar__nav__link--location-active"]}
								>
									<Row justify="spaced-start" align="center">
										<Icon.Users size="1.5rem" /> <span>Shared with Me</span>
									</Row>
								</NavLink>
								<NavLink
									to={resourcesRecentsPageInfo.to}
									className={styles["left-bar__nav__link"]}
									activeClassName={styles["left-bar__nav__link--location-active"]}
								>
									<Row justify="spaced-start" align="center">
										<Icon.Clock size="1.5rem" /> <span>Recents</span>
									</Row>
								</NavLink>
							</Column>
						</nav>
					</Column>

					<UploadBox />
				</Column>
			</Section.Body>
		</Section>
	);
}
