import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, If, Row } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	FieldConditional,
	FieldElse,
	FieldIf,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
	SubmitButton,
	useInternalField,
} from "@hex-insights/forms";
import {
	NoteAttachmentDetailQuery,
	NoteAttachmentFormConversion,
	NoteAttachmentFormState,
	NoteAttachmentFormValues,
} from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<NoteAttachmentFormValues.Create>;
	onSuccess: () => void;
};

/**
 * Renders the create form of the NoteAttachment model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = NoteAttachmentFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<NoteAttachmentFormValues.Create>;
};

/**
 * Renders the create form of the NoteAttachment model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<NoteAttachmentFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="noteAttachment.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Field.Order formState={formState} formType={FormType.Create} />
				<Field.IsFile formState={formState} formType={FormType.Create} />
				<FieldConditional>
					<FieldIf formState={formState} name="file" condition={formState.formValues.isFile}>
						<Field.File formState={formState} formType={FormType.Create} />
					</FieldIf>
					<FieldElse formState={formState} name="linkURL">
						<Field.LinkURL formState={formState} formType={FormType.Create} />
					</FieldElse>
				</FieldConditional>
				<Field.Note formState={formState} formType={FormType.Create} />

				<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
					Submit
				</SubmitButton>
			</Column>
		</Form>
	);
}

export type CreateInNoteProps = {
	formState: FormState<NoteAttachmentFormValues.CreateInNote>;
};

export function CreateInNote({ formState }: CreateInNoteProps) {
	useInternalField(formState, "order");

	return (
		<Column justify="spaced-start">
			<Field.IsFile formState={formState} formType={FormType.Create} />
			<FieldConditional>
				<FieldIf formState={formState} name="file" condition={formState.formValues.isFile}>
					<Field.File formState={formState} formType={FormType.Create} />
				</FieldIf>
				<FieldElse formState={formState} name="linkURL">
					<Field.LinkURL formState={formState} formType={FormType.Create} />
				</FieldElse>
			</FieldConditional>
		</Column>
	);
}

export type ControlledDetailProps = {
	noteAttachment: NoteAttachmentDetailQuery["noteAttachment"];
	applyUpdate: ApplyUpdateFunction<NoteAttachmentFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the NoteAttachment model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => NoteAttachmentFormConversion.toFormValues(props.noteAttachment),
		[props.noteAttachment],
	);
	const formState = NoteAttachmentFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<NoteAttachmentFormValues.Detail>;
};

/**
 * Renders the detail form of the NoteAttachment model using the given form state.
 */
export function Detail({ formState, noteAttachment, applyUpdate, onSuccess }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<NoteAttachmentFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form
			name={`noteAttachment.detail.${noteAttachment.id}`}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
		>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.Order formState={formState} formType={FormType.Update} id={noteAttachment.id} />
				<Field.IsFile formState={formState} formType={FormType.Update} id={noteAttachment.id} />
				<FieldConditional>
					<FieldIf formState={formState} name="file" condition={formState.formValues.isFile}>
						<Field.File formState={formState} formType={FormType.Update} id={noteAttachment.id} />
					</FieldIf>
					<FieldElse formState={formState} name="linkURL">
						<Field.LinkURL formState={formState} formType={FormType.Update} id={noteAttachment.id} />
					</FieldElse>
				</FieldConditional>
				<Field.URL formState={formState} formType={FormType.Update} id={noteAttachment.id} />
				<Field.Note
					formState={formState}
					formType={FormType.Update}
					id={noteAttachment.id}
					currentNote={noteAttachment.note}
				/>
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	noteAttachment: NoteAttachmentDetailQuery["noteAttachment"];
};

/**
 * Renders a read-only detail form of the NoteAttachment model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => NoteAttachmentFormConversion.toFormValues(props.noteAttachment),
		[props.noteAttachment],
	);
	const formState = NoteAttachmentFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<NoteAttachmentFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the NoteAttachment model using the given form state.
 */
export function ReadOnly({ formState, noteAttachment }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.Order formState={formState} formType={FormType.Update} id={noteAttachment.id} />
			<Field.IsFile formState={formState} formType={FormType.Update} id={noteAttachment.id} />
			<Field.URL formState={formState} formType={FormType.Update} id={noteAttachment.id} />
			<Field.Note
				formState={formState}
				formType={FormType.Update}
				id={noteAttachment.id}
				currentNote={noteAttachment.note}
			/>
		</Column>
	);
}
