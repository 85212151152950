import { newPageInfo } from "@hex-insights/router";
import { invoiceLineItemHubPageInfo } from "../pageinfo";

export const invoiceLineItemHubIndexPageInfo = newPageInfo({
	name: "Invoice Line Items Index",
	title: "",
	description: "Index of Invoice Line Items.",
	exact: true,
	to: invoiceLineItemHubPageInfo.to,
	path: invoiceLineItemHubPageInfo.path,
	parent: invoiceLineItemHubPageInfo,
});
