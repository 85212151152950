import { newPageInfo } from "@hex-insights/router";
import { paymentLineItemHubPageInfo } from "../pageinfo";

export const paymentLineItemHubIndexPageInfo = newPageInfo({
	name: "Payment Line Items Index",
	title: "",
	description: "Index of Payment Line Items.",
	exact: true,
	to: paymentLineItemHubPageInfo.to,
	path: paymentLineItemHubPageInfo.path,
	parent: paymentLineItemHubPageInfo,
});
