import React from "react";
import { Column } from "@hex-insights/core";
import { FormState, FormType } from "@hex-insights/forms";
import { SessionDetailQuery, SessionFormConversion, SessionFormState, SessionFormValues } from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledReadOnlyProps = {
	session: SessionDetailQuery["session"];
};

/**
 * Renders a read-only detail form of the Session model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(() => SessionFormConversion.toFormValues(props.session), [props.session]);
	const formState = SessionFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<SessionFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the Session model using the given form state.
 */
export function ReadOnly({ formState, session }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.CreatedAt formState={formState} formType={FormType.Update} id={session.id} />
			<Field.UpdatedAt formState={formState} formType={FormType.Update} id={session.id} />
			<Field.ExpiresAt formState={formState} formType={FormType.Update} id={session.id} />
			<Field.LongestInactiveSec formState={formState} formType={FormType.Update} id={session.id} />
			<Field.IsVerified formState={formState} formType={FormType.Update} id={session.id} />
			<Field.IsCurrent formState={formState} formType={FormType.Update} id={session.id} />
			<Field.LoginRecord
				formState={formState}
				formType={FormType.Update}
				id={session.id}
				currentLoginRecord={session.loginRecord}
			/>
			<Field.MFAInvalidOTPAttempts
				formState={formState}
				formType={FormType.Update}
				id={session.id}
				currentMFAInvalidOTPAttempts={session.mfaInvalidOTPAttempts}
			/>
			<Field.TrustedDevice
				formState={formState}
				formType={FormType.Update}
				id={session.id}
				currentTrustedDevice={session.trustedDevice}
			/>
			<Field.User formState={formState} formType={FormType.Update} id={session.id} currentUser={session.user} />
		</Column>
	);
}
