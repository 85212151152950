import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const noteCommentHubPageInfo = newPageInfo({
	title: "Note Comments",
	description: "Pages related to the Note Comment model.",
	exact: false,
	to: hubsPageInfo.to + "/note-comments",
	path: hubsPageInfo.path + "/note-comments",
	parent: hubsPageInfo,
});
