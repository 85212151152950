import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export const admissionsPageInfo = newPageInfo({
	title: "Admissions",
	description: "Admissions information and reporting.",
	exact: false,
	to: "/admissions",
	path: "/admissions",
	parent: rootPageInfo,
});
