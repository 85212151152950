import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { MFAFactorFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the MFAFactor model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => MFAFactorFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(initialFormValues, MFAFactorFilterFormValues.typeFilterObject);
}
