import React from "react";
import { Conditional, Else, Heading, If } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { LoginRecordForm, useLoginRecordDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { loginRecordHubDetailPageInfo } from "./pageinfo";

function LoginRecordHubDetailPageWrapper() {
	// Manages identity of the LoginRecordHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <LoginRecordHubDetailPage key={id} />;
}

export { LoginRecordHubDetailPageWrapper as LoginRecordHubDetailPage };

function LoginRecordHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useLoginRecordDetailQuery({ variables: { id } });
	const loginRecord = data?.loginRecord;
	useActivePageRegistration(
		loginRecordHubDetailPageInfo,
		!!loginRecord ? loginRecordHubDetailPageInfo.title(loginRecord) : "",
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading loginRecord</If>
				<Else>
					{!!loginRecord && (
						<React.Fragment>
							<Heading level={1}>{loginRecord.id}</Heading>

							<LoginRecordForm.ControlledReadOnly loginRecord={loginRecord} />
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
