import { newPageInfo } from "@hex-insights/router";
import { noteReactionHubPageInfo } from "../pageinfo";

export const noteReactionHubCreatePageInfo = newPageInfo({
	name: "New Note Reaction",
	title: "New",
	description: "Create a new Note Reaction record.",
	exact: true,
	to: noteReactionHubPageInfo.to + "/new",
	path: noteReactionHubPageInfo.path + "/new",
	parent: noteReactionHubPageInfo,
});
