import { IndexForms } from "@hex-insights/app-modules";
import { LearningObjectiveMarkFilterFormValues } from "../../../../Utilities";

export type LearningObjectiveMarkFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, LearningObjectiveMarkFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the LearningObjectiveMark model.
 */
export function LearningObjectiveMarkFilterFormController(props: LearningObjectiveMarkFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={LearningObjectiveMarkFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `LearningObjectiveMarkFilterForm`
