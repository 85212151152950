import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const enrollmentInvitationHubPageInfo = newPageInfo({
	title: "Enrollment Invitations",
	description: "Pages related to the Enrollment Invitation model.",
	exact: false,
	to: hubsPageInfo.to + "/enrollment-invitations",
	path: hubsPageInfo.path + "/enrollment-invitations",
	parent: hubsPageInfo,
});
