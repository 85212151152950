import React from "react";
import { DateTimeField, FieldDisplayArgs, FormType, SelectField } from "@hex-insights/forms";
import {
	MFAInvalidOTPAttemptFormValues,
	SessionSelect,
	UserSelect,
	useSessionSelectLazyQuery,
	useUserSelectLazyQuery,
} from "../../../../Utilities";
import { SessionLink, UserLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the MFAInvalidOTPAttempt model.
 */
type FieldProps<K extends keyof MFAInvalidOTPAttemptFormValues.Base = keyof MFAInvalidOTPAttemptFormValues.Base> =
	BaseFieldProps<Pick<MFAInvalidOTPAttemptFormValues.Base, K>>;

/**
 * Generic props for fields of the MFAInvalidOTPAttempt model that only appear in the detail form.
 */
type DetailFieldProps<
	K extends keyof MFAInvalidOTPAttemptFormValues.Detail = keyof MFAInvalidOTPAttemptFormValues.Detail,
> = BaseFieldProps<Pick<MFAInvalidOTPAttemptFormValues.Detail, K>>;

/**
 * Renders a field component for the `createdAt` field of the MFAInvalidOTPAttempt model.
 */
export function CreatedAt({ formState, formType = FormType.Update }: FieldProps<"createdAt">) {
	return (
		<DateTimeField formState={formState} name="createdAt" optional={FormType.isCreate(formType)} precision="minute" />
	);
}

export type SessionProps = DetailFieldProps<"sessionID"> & {
	currentSession?: SessionSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `session` edge of the MFAInvalidOTPAttempt model.
 */
export function Session({ formState, currentSession }: SessionProps) {
	const [loadOptions, { loading, data }] = useSessionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.sessionID) {
			loadOptions();
		}
	}, [formState.formEditing.sessionID, loadOptions]);
	const options = React.useMemo(
		() => SessionSelect.toOptions(data?.sessionConnection.edges, currentSession),
		[data, currentSession],
	);

	return (
		<SelectField
			formState={formState}
			name="sessionID"
			isLoading={loading}
			options={options}
			optional
			display={displaySession}
			blankValue={null}
		/>
	);
}

function displaySession({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <SessionLink instance={{ id }}>{formattedValue}</SessionLink>;
}

export type UserProps = DetailFieldProps<"userID"> & {
	currentUser?: UserSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `user` edge of the MFAInvalidOTPAttempt model.
 */
export function User({ formState, currentUser }: UserProps) {
	const [loadOptions, { loading, data }] = useUserSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.userID) {
			loadOptions();
		}
	}, [formState.formEditing.userID, loadOptions]);
	const options = React.useMemo(
		() => UserSelect.toOptions(data?.userConnection.edges, currentUser),
		[data, currentUser],
	);

	return (
		<SelectField
			formState={formState}
			name="userID"
			isLoading={loading}
			options={options}
			display={displayUser}
			blankValue={null}
		/>
	);
}

function displayUser({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserLink instance={{ id }}>{formattedValue}</UserLink>;
}
