import React from "react";
import { Conditional, Else, Grid, Heading, If, Row, Section, useSync } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	ParentDetailQuery,
	ParentForm,
	ParentFormValues,
	ParentMutation,
	PersonIcon,
	Tile,
	useParentDetailQuery,
	useParentIndexQuery,
	useStudentListQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { studentHubDetailPageInfo } from "../../Student/DetailPage/pageinfo";
import { parentHubDetailPageInfo, ParentHubDetailPageRouteParams } from "./pageinfo";

function ParentHubDetailPageWrapper() {
	// Manages identity of the ParentHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <ParentHubDetailPage key={id} />;
}

export { ParentHubDetailPageWrapper as ParentHubDetailPage };

function ParentHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useParentDetailQuery({ variables: { id } });
	const parent = data?.parent;
	useActivePageRegistration(parentHubDetailPageInfo, !!parent ? parentHubDetailPageInfo.title(parent) : "");

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading parent</If>
				<Else>
					{!!parent && (
						<React.Fragment>
							<Row
								justify="spaced-start"
								horizontalSpacing="1.5rem"
								align="center"
								style={{ margin: "var(--heading--level-1---margin)" }}
							>
								<PersonIcon person={parent.person} imageSize="7rem" />
								<Heading level={1} noMargin>
									{parent.person.name}
								</Heading>
							</Row>

							<Row justify="spaced-start">
								<Tile>
									<Tile.Body>
										<FormSection parent={parent} />
									</Tile.Body>
								</Tile>

								<Tile style={{ flexGrow: 1, width: "100%", overflow: "hidden" }}>
									<Tile.Body>
										<StudentsSection />
										<ParentsSection />
									</Tile.Body>
								</Tile>
							</Row>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}

type FormSectionProps = {
	parent: ParentDetailQuery["parent"];
};

function FormSection({ parent }: FormSectionProps) {
	const update = ParentMutation.useUpdateWithPerson(parent.id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<ParentFormValues.DetailWithPerson>,
			initialFormValues: ParentFormValues.DetailWithPerson,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<ParentForm.ControlledDetailWithPerson
			key={syncID}
			parent={parent}
			applyUpdate={applyUpdate}
			onSuccess={synchronize}
		/>
	);
}

function StudentsSection() {
	const { id } = useRouteParams<ParentHubDetailPageRouteParams>();
	const { loading, data } = useStudentListQuery({
		variables: {
			filters: {
				relationships: [{ parentIDEQ: id }],
			},
		},
	});

	return (
		<Section>
			<Section.Header>
				<Heading level={3} noMargin>
					Students
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Grid columns={5} gap="1rem">
							{data?.studentConnection.edges.map((e) => (
								<InternalLink
									key={e.node.id}
									to={studentHubDetailPageInfo.to(e.node.id)}
									className="link--no-text-decoration"
								>
									<PersonIcon person={e.node.person} withName withTooltipPreview studentID={e.node.id} />
								</InternalLink>
							))}
						</Grid>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}

function ParentsSection() {
	const { id } = useRouteParams<ParentHubDetailPageRouteParams>();
	const { loading, data } = useParentIndexQuery({
		variables: {
			filters: {
				idNEQ: id,
				relationships: [{ student: [{ relationships: [{ parentIDEQ: id }] }] }],
			},
		},
	});

	return (
		<Section>
			<Section.Header>
				<Heading level={3} noMargin>
					Related Parents
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Row justify="spaced-start" overflow="scroll" style={{ width: "100%" }}>
							{data?.parentConnection.edges.map((e) => (
								<InternalLink
									key={e.node.id}
									to={parentHubDetailPageInfo.to(e.node.id)}
									className="link--no-text-decoration"
								>
									<PersonIcon person={e.node.person} withName />
								</InternalLink>
							))}
						</Row>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}
