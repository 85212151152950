import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import {
	ServiceSubscriptionFilterInput,
	ServiceSubscriptionOrder,
	useServiceSubscriptionPaginationQuery,
} from "../../../../Utilities";

export type ServiceSubscriptionPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: ServiceSubscriptionFilterInput | ServiceSubscriptionFilterInput[] | null;
	order: ServiceSubscriptionOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the ServiceSubscription model.
 */
export function ServiceSubscriptionPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: ServiceSubscriptionPaginationFormProps) {
	const { loading, data } = useServiceSubscriptionPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.serviceSubscriptionConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
