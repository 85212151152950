import { newPageInfo } from "@hex-insights/router";
import { schoolAttendanceRecordHubPageInfo } from "../pageinfo";

export const schoolAttendanceRecordHubIndexPageInfo = newPageInfo({
	name: "School Attendance Records Index",
	title: "",
	description: "Index of School Attendance Records.",
	exact: true,
	to: schoolAttendanceRecordHubPageInfo.to,
	path: schoolAttendanceRecordHubPageInfo.path,
	parent: schoolAttendanceRecordHubPageInfo,
});
