import { newPageInfo } from "@hex-insights/router";
import { MealMenu } from "@hex-insights/verita.shared";
import { mealMenuHubPageInfo } from "../pageinfo";

type MealMenuForRouteTitle = Pick<MealMenu, "id">;

export const mealMenuHubDetailPageInfo = newPageInfo({
	name: "Meal Menu Detail",
	title: (e: MealMenuForRouteTitle) => e.id,
	description: "Detail page for an mealMenu.",
	exact: false,
	isLeaf: true,
	to: (id: MealMenu["id"]) => mealMenuHubPageInfo.to + `/${id}`,
	path: mealMenuHubPageInfo.path + "/:id(\\d+)",
	parent: mealMenuHubPageInfo,
});
