import { MFAInvalidOTPAttempt } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the createdAt field of the MFAInvalidOTPAttempt model.
 */
export function createdAt(value: MFAInvalidOTPAttempt["createdAt"] | null): string {
	return toString(value);
}
