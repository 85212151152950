import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const noteHubPageInfo = newPageInfo({
	title: "Notes",
	description: "Pages related to the Note model.",
	exact: false,
	to: hubsPageInfo.to + "/notes",
	path: hubsPageInfo.path + "/notes",
	parent: hubsPageInfo,
});
