import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	ExpenseBudgetForm,
	ExpenseBudgetFormValues,
	ExpenseBudgetMutation,
	useExpenseBudgetDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { expenseBudgetHubDetailPageInfo } from "./pageinfo";

function ExpenseBudgetHubDetailPageWrapper() {
	// Manages identity of the ExpenseBudgetHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <ExpenseBudgetHubDetailPage key={id} />;
}

export { ExpenseBudgetHubDetailPageWrapper as ExpenseBudgetHubDetailPage };

function ExpenseBudgetHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useExpenseBudgetDetailQuery({ variables: { id } });
	const expenseBudget = data?.expenseBudget;
	useActivePageRegistration(
		expenseBudgetHubDetailPageInfo,
		!!expenseBudget ? expenseBudgetHubDetailPageInfo.title(expenseBudget) : "",
	);

	const update = ExpenseBudgetMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<ExpenseBudgetFormValues.Detail>,
			initialFormValues: ExpenseBudgetFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading expenseBudget</If>
				<Else>
					{!!expenseBudget && (
						<React.Fragment>
							<Heading level={1}>{expenseBudget.id}</Heading>

							<ExpenseBudgetForm.ControlledDetail
								key={syncID}
								expenseBudget={expenseBudget}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
