import { newPageInfo } from "@hex-insights/router";
import { Quotation } from "@hex-insights/verita.shared";
import { quotationHubPageInfo } from "../pageinfo";

type QuotationForRouteTitle = Pick<Quotation, "id">;

export const quotationHubDetailPageInfo = newPageInfo({
	name: "Quotation Detail",
	title: (e: QuotationForRouteTitle) => e.id,
	description: "Detail page for an quotation.",
	exact: false,
	isLeaf: true,
	to: (id: Quotation["id"]) => quotationHubPageInfo.to + `/${id}`,
	path: quotationHubPageInfo.path + "/:id(\\d+)",
	parent: quotationHubPageInfo,
});
