import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Expense, ExpenseFormat, ExpenseNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Expense, "id"> & ExpenseNameFragment;

export type ExpenseLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Expense instance.
 */
export function ExpenseLink(props: ExpenseLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Expense" format={ExpenseFormat.name} {...props} />;
}
