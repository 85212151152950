import { newPageInfo, newRouteParamsType } from "@hex-insights/router";
import { EnrollmentApplicationParent } from "@hex-insights/verita.shared";
import { enrollmentApplicationParentHubPageInfo } from "../pageinfo";

type EnrollmentApplicationParentForRouteTitle = Pick<EnrollmentApplicationParent, "name">;

export const enrollmentApplicationParentHubDetailPageInfo = newPageInfo({
	name: "Enrollment Application Parent Detail",
	title: (e: EnrollmentApplicationParentForRouteTitle) => e.name,
	description: "Detail page for an enrollmentApplicationParent.",
	exact: false,
	isLeaf: true,
	parent: enrollmentApplicationParentHubPageInfo,
	to: (id: EnrollmentApplicationParent["id"]) => enrollmentApplicationParentHubPageInfo.to + `/${id}`,
	path: enrollmentApplicationParentHubPageInfo.path + "/:enrollmentApplicationParentID(\\d+)",
	paramsType: newRouteParamsType(enrollmentApplicationParentHubPageInfo.paramsType, "enrollmentApplicationParentID"),
});
