import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, Heading, HeadingLevel, If, Row } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
	SubmitButton,
} from "@hex-insights/forms";
import { CampusDetailQuery, CampusFormConversion, CampusFormState, CampusFormValues } from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<CampusFormValues.Create>;
	onSuccess: () => void;
	headingLevel?: HeadingLevel;
};

/**
 * Renders the create form of the Campus model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = CampusFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<CampusFormValues.Create>;
};

/**
 * Renders the create form of the Campus model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess, headingLevel = 2 }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<CampusFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="campus.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Field.Name formState={formState} formType={FormType.Create} />

				<Heading level={headingLevel} noMargin>
					Address
				</Heading>
				<Field.AddressLine1 formState={formState} formType={FormType.Create} />
				<Field.AddressLine2 formState={formState} formType={FormType.Create} />
				<Field.PostalCode formState={formState} formType={FormType.Create} />
				<Field.City formState={formState} formType={FormType.Create} />
				<Field.StateOrProvince formState={formState} formType={FormType.Create} />
				<Field.Country formState={formState} formType={FormType.Create} />

				<Row justify="flex-end">
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

export type ControlledDetailProps = {
	campus: CampusDetailQuery["campus"];
	applyUpdate: ApplyUpdateFunction<CampusFormValues.Detail>;
	onSuccess: () => void;
	headingLevel?: HeadingLevel;
};

/**
 * Renders the detail form of the Campus model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(() => CampusFormConversion.toFormValues(props.campus), [props.campus]);
	const formState = CampusFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<CampusFormValues.Detail>;
};

/**
 * Renders the detail form of the Campus model using the given form state.
 */
export function Detail({ formState, campus, applyUpdate, onSuccess, headingLevel = 2 }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<CampusFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form name={`campus.detail.${campus.id}`} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.Name formState={formState} formType={FormType.Update} id={campus.id} />

				<Heading level={headingLevel} noMargin>
					Address
				</Heading>
				<Field.AddressLine1 formState={formState} formType={FormType.Update} id={campus.id} />
				<Field.AddressLine2 formState={formState} formType={FormType.Update} id={campus.id} />
				<Field.PostalCode formState={formState} formType={FormType.Update} id={campus.id} />
				<Field.City formState={formState} formType={FormType.Update} id={campus.id} />
				<Field.StateOrProvince formState={formState} formType={FormType.Update} id={campus.id} />
				<Field.Country formState={formState} formType={FormType.Update} id={campus.id} />

				<Heading level={headingLevel} noMargin>
					Employees
				</Heading>
				<Field.Employees
					formState={formState}
					formType={FormType.Update}
					id={campus.id}
					currentEmployees={campus.employees}
				/>

				<Heading level={headingLevel} noMargin>
					Rooms
				</Heading>
				<Field.Rooms formState={formState} formType={FormType.Update} id={campus.id} currentRooms={campus.rooms} />
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	campus: CampusDetailQuery["campus"];
	headingLevel?: HeadingLevel;
};

/**
 * Renders a read-only detail form of the Campus model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(() => CampusFormConversion.toFormValues(props.campus), [props.campus]);
	const formState = CampusFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<CampusFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the Campus model using the given form state.
 */
export function ReadOnly({ formState, campus, headingLevel = 2 }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.Name formState={formState} formType={FormType.Update} id={campus.id} />

			<Heading level={headingLevel} noMargin>
				Address
			</Heading>
			<Field.AddressLine1 formState={formState} formType={FormType.Update} id={campus.id} />
			<Field.AddressLine2 formState={formState} formType={FormType.Update} id={campus.id} />
			<Field.PostalCode formState={formState} formType={FormType.Update} id={campus.id} />
			<Field.City formState={formState} formType={FormType.Update} id={campus.id} />
			<Field.StateOrProvince formState={formState} formType={FormType.Update} id={campus.id} />
			<Field.Country formState={formState} formType={FormType.Update} id={campus.id} />

			<Heading level={headingLevel} noMargin>
				Employees
			</Heading>
			<Field.Employees
				formState={formState}
				formType={FormType.Update}
				id={campus.id}
				currentEmployees={campus.employees}
			/>

			<Heading level={headingLevel} noMargin>
				Rooms
			</Heading>
			<Field.Rooms formState={formState} formType={FormType.Update} id={campus.id} currentRooms={campus.rooms} />
		</Column>
	);
}
