import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { EnrollmentApplicationSubmissionOrderFormValues } from "../../../../Utilities";

export type EnrollmentApplicationSubmissionOrderFormControllerProps = {
	formState: FormState<EnrollmentApplicationSubmissionOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the EnrollmentApplicationSubmission model.
 */
export function EnrollmentApplicationSubmissionOrderFormController({
	formState,
}: EnrollmentApplicationSubmissionOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={EnrollmentApplicationSubmissionOrderFormValues.fieldOptions}
			fieldTypeMapping={EnrollmentApplicationSubmissionOrderFormValues.fieldTypeMapping}
		/>
	);
}
