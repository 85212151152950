import React from "react";
import { Column, Conditional, Else, Heading, If, mappedArray, Section } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import { useDocumentResourceFolderIndexQuery } from "@hex-insights/verita.shared";
import { AuthenticationContext } from "../../../Contexts";
import { DocumentResourceFolderDisplay, FoldersGrid } from "../Components";
import { resourcesSharedPageInfo } from "./pageinfo";

export function ResourcesSharedPage() {
	useActivePageRegistration(resourcesSharedPageInfo);

	return (
		<Column justify="spaced-start" style={{ width: "100%", overflowX: "hidden" }}>
			<Heading level={1} noMargin>
				Shared with Me
			</Heading>

			<Folders />
		</Column>
	);
}

function Folders() {
	const { user } = React.useContext(AuthenticationContext);
	const { loading, data } = useDocumentResourceFolderIndexQuery({
		variables: {
			filters: { sharingUsers: [{ idEQ: user?.id }] },
		},
		fetchPolicy: "cache-and-network",
	});

	return (
		<Section>
			<Section.Body>
				<FoldersGrid>
					<Conditional>
						<If condition={loading && !data}>
							{mappedArray(12, (i) => (
								<DocumentResourceFolderDisplay.Loading key={i} />
							))}
						</If>
						<Else>
							{data?.documentResourceFolderConnection.edges.map(({ node }) => (
								<DocumentResourceFolderDisplay key={node.id} documentResourceFolder={node} style={{ width: "100%" }} />
							))}
						</Else>
					</Conditional>
				</FoldersGrid>
			</Section.Body>
		</Section>
	);
}
