import { useActivePageRegistration } from "@hex-insights/router";
import { Main } from "@hex-insights/verita.shared";
import { AcademicDashboard } from "../../../Components";
import { dashboardsAcademicPageInfo } from "./pageinfo";

export function DashboardsAcademicPage() {
	useActivePageRegistration(dashboardsAcademicPageInfo);

	return (
		<Main>
			<AcademicDashboard />
		</Main>
	);
}
