import React from "react";
import {
	CreateFunction,
	onCreateSubmit,
	onUpdateSubmit,
	UpdateFunction,
	useApplyCreate,
	useApplyUpdate,
} from "@hex-insights/app-modules";
import { ClassNameProps, DistributiveOmit, StyleProps } from "@hex-insights/core";
import {
	Form,
	FormEditButtonPair,
	FormState,
	FormSubmitFunction,
	SubmitButton,
	useOnSuccess,
} from "@hex-insights/forms";
import {
	AcademicYearCreateMutation,
	AcademicYearDetailQuery,
	AcademicYearFormConversion,
	AcademicYearFormState,
	AcademicYearFormValues,
	AcademicYearMutation,
	AcademicYearUpdateMutation,
} from "../../../../Utilities";
import { BaseFormProps } from "../Shared";
import * as Field from "./Field";

export type ControlledCreateProps = Omit<
	CreateProps<AcademicYearCreateMutation["createAcademicYear"]>,
	"formState" | "create"
> & {
	formStateArgs?: Partial<AcademicYearFormState.UseCreateFormStateArgs>;
};

/**
 * Renders the create form of the AcademicYear model using an internally managed form state.
 */
export function ControlledCreate({ formStateArgs, ...props }: ControlledCreateProps) {
	const formState = AcademicYearFormState.useCreateFormState(formStateArgs);
	const create = AcademicYearMutation.useCreate();

	return <Create formState={formState} create={create} {...props} />;
}

export type CreateProps<T> = {
	formState: FormState<AcademicYearFormValues.Create>;
	create: CreateFunction<AcademicYearFormValues.Create, T>;
	onSuccess: (data: T) => void;
	formNameSuffix?: string;
} & BaseFormProps<AcademicYearFormValues.Create> &
	Partial<ClassNameProps & StyleProps>;

/**
 * Renders the create form of the AcademicYear model using the given form state.
 */
export function Create<T>({ formState, create, onSuccess, formNameSuffix, ...props }: CreateProps<T>) {
	const { applyCreate, resultRef } = useApplyCreate(create);
	const onSubmit = React.useCallback<FormSubmitFunction<AcademicYearFormValues.Create>>(
		async ({ formValues }) => onCreateSubmit(formValues, applyCreate),
		[applyCreate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	const formName = "academicYear.create" + (formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form name={formName} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} {...props}>
			<Form.Column>
				<CreateFields formState={formState} />

				<Form.ActionBar>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Form.ActionBar>
			</Form.Column>
		</Form>
	);
}

type CreateFieldsProps = {
	formState: FormState<AcademicYearFormValues.Create>;
};

function CreateFields({ formState }: CreateFieldsProps) {
	return (
		<React.Fragment>
			<Field.Name formState={formState} />
			<Field.BillingSetupSubmissions formState={formState} />
			<Field.SchoolStudentEnrollments formState={formState} />
			<Field.Terms formState={formState} />
		</React.Fragment>
	);
}

export type ControlledDetailProps = Omit<
	DetailProps<AcademicYearUpdateMutation["updateAcademicYear"]>,
	"formState" | "update"
> & {
	formStateArgs?: Partial<DistributiveOmit<AcademicYearFormState.UseDetailFormStateArgs, "initialFormValues">>;
};

/**
 * Renders the detail form of the AcademicYear model using an internally managed form state.
 */
export function ControlledDetail({ formStateArgs, ...props }: ControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => AcademicYearFormConversion.toFormValues(props.academicYear),
		[props.academicYear],
	);
	const formState = AcademicYearFormState.useDetailFormState({ ...formStateArgs, initialFormValues });
	const update = AcademicYearMutation.useUpdate(props.academicYear.id);

	return <Detail formState={formState} update={update} {...props} />;
}

export type DetailProps<T> = {
	academicYear: AcademicYearDetailQuery["academicYear"];
	formState: FormState<AcademicYearFormValues.Detail>;
	update: UpdateFunction<AcademicYearFormValues.Detail, T>;
	onSuccess: (data: T) => void;
	formNameSuffix?: string;
} & BaseFormProps<AcademicYearFormValues.Detail> &
	Partial<ClassNameProps & StyleProps>;

/**
 * Renders the detail form of the AcademicYear model using the given form state.
 */
export function Detail<T>({ formState, academicYear, update, onSuccess, formNameSuffix, ...props }: DetailProps<T>) {
	const { applyUpdate, resultRef } = useApplyUpdate(update);
	const onSubmit = React.useCallback<FormSubmitFunction<AcademicYearFormValues.Detail>>(
		async (formState) => onUpdateSubmit(formState, applyUpdate),
		[applyUpdate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	const formName = `academicYear.detail.${academicYear.id}` + (formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form name={formName} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} {...props}>
			<Form.Column>
				<FormEditButtonPair formState={formState} />

				<DetailFields formState={formState} academicYear={academicYear} />
			</Form.Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = Omit<ReadOnlyProps, "formState"> & {
	formStateArgs?: Partial<DistributiveOmit<AcademicYearFormState.UseReadOnlyFormStateArgs, "initialFormValues">>;
};

/**
 * Renders a read-only detail form of the AcademicYear model using an internally managed form state.
 */
export function ControlledReadOnly({ formStateArgs, ...props }: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => AcademicYearFormConversion.toFormValues(props.academicYear),
		[props.academicYear],
	);
	const formState = AcademicYearFormState.useReadOnlyFormState({ ...formStateArgs, initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = {
	academicYear: AcademicYearDetailQuery["academicYear"];
	formState: FormState<AcademicYearFormValues.Detail>;
} & Partial<ClassNameProps & StyleProps>;

/**
 * Renders a read-only detail form of the AcademicYear model using the given form state.
 */
export function ReadOnly({ formState, academicYear, className, style }: ReadOnlyProps) {
	return (
		<div className={className} style={style}>
			<Form.Column>
				<DetailFields formState={formState} academicYear={academicYear} />
			</Form.Column>
		</div>
	);
}

type DetailFieldsProps = {
	academicYear: AcademicYearDetailQuery["academicYear"];
	formState: FormState<AcademicYearFormValues.Detail>;
};

function DetailFields({ formState, academicYear }: DetailFieldsProps) {
	return (
		<React.Fragment>
			<Field.Name formState={formState} />
			<Field.BillingSetupSubmissions
				formState={formState}
				currentBillingSetupSubmissions={academicYear.billingSetupSubmissions}
			/>
			<Field.SchoolStudentEnrollments
				formState={formState}
				currentSchoolStudentEnrollments={academicYear.schoolStudentEnrollments}
			/>
			<Field.Terms formState={formState} currentTerms={academicYear.terms} />
		</React.Fragment>
	);
}
