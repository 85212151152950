import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Person, PersonFormat, PersonNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Person, "id"> & PersonNameFragment;

export type PersonLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Person instance.
 */
export function PersonLink(props: PersonLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Person" format={PersonFormat.name} {...props} />;
}
