import { NullPartial } from "@hex-insights/core";
import {
	LearningObjectiveMarkCreateInput,
	LearningObjectiveMarkDetailQuery,
	LearningObjectiveMarkUpdateInput,
} from "../../GraphQL";
import { LearningObjectiveMarkFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the LearningObjectiveMark model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: LearningObjectiveMarkFormValues.Create): LearningObjectiveMarkCreateInput {
	const input: NullPartial<LearningObjectiveMarkCreateInput> = { ...formValues };
	return input as LearningObjectiveMarkCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the LearningObjectiveMark model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<LearningObjectiveMarkFormValues.Detail>,
	_initialFormValues: LearningObjectiveMarkFormValues.Detail,
): LearningObjectiveMarkUpdateInput {
	const input: LearningObjectiveMarkUpdateInput = { ...formValues };

	if (formValues.value === null) {
		input.clearValue = true;
	}

	return input;
}

/**
 * Converts a LearningObjectiveMark instance to its corresponding detail form values.
 *
 * @param learningObjectiveMark The instance to convert.
 */
export function toFormValues(
	learningObjectiveMark: LearningObjectiveMarkDetailQuery["learningObjectiveMark"],
): LearningObjectiveMarkFormValues.Detail {
	return {
		value: learningObjectiveMark.value,
		learningObjectiveID: learningObjectiveMark.learningObjective?.id ?? null,
		reportCardID: learningObjectiveMark.reportCard?.id ?? null,
	};
}
