import { newPageInfo } from "@hex-insights/router";
import { homeRoomSectionHubPageInfo } from "../pageinfo";

export const homeRoomSectionHubIndexPageInfo = newPageInfo({
	name: "Home Room Sections Index",
	title: "",
	description: "Index of Home Room Sections.",
	exact: true,
	to: homeRoomSectionHubPageInfo.to,
	path: homeRoomSectionHubPageInfo.path,
	parent: homeRoomSectionHubPageInfo,
});
