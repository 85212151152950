import { newPageInfo } from "@hex-insights/router";
import { tuitionHubPageInfo } from "../pageinfo";

export const tuitionHubCreatePageInfo = newPageInfo({
	name: "New Tuition",
	title: "New",
	description: "Create a new Tuition record.",
	exact: true,
	to: tuitionHubPageInfo.to + "/new",
	path: tuitionHubPageInfo.path + "/new",
	parent: tuitionHubPageInfo,
});
