import { newPageInfo } from "@hex-insights/router";
import { Note } from "@hex-insights/verita.shared";
import { noteHubPageInfo } from "../pageinfo";

type NoteForRouteTitle = Pick<Note, "title">;

export const noteHubDetailPageInfo = newPageInfo({
	name: "Note Detail",
	title: (e: NoteForRouteTitle) => e.title,
	description: "Detail page for an note.",
	exact: false,
	isLeaf: true,
	to: (id: Note["id"]) => noteHubPageInfo.to + `/${id}`,
	path: noteHubPageInfo.path + "/:id(\\d+)",
	parent: noteHubPageInfo,
});
