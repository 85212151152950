import React from "react";
import { Conditional, Else, Heading, If, Row, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { RoomForm, RoomFormValues, RoomMutation, Tile, useRoomDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { roomHubDetailPageInfo } from "./pageinfo";

function RoomHubDetailPageWrapper() {
	// Manages identity of the RoomHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <RoomHubDetailPage key={id} />;
}

export { RoomHubDetailPageWrapper as RoomHubDetailPage };

function RoomHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useRoomDetailQuery({ variables: { id } });
	const room = data?.room;
	useActivePageRegistration(roomHubDetailPageInfo, !!room ? roomHubDetailPageInfo.title(room) : "");

	const update = RoomMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<RoomFormValues.Detail>, initialFormValues: RoomFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading room</If>
				<Else>
					{!!room && (
						<React.Fragment>
							<Heading level={1}>{room.name}</Heading>

							<Row justify="spaced-start">
								<Tile>
									<Tile.Body>
										<RoomForm.ControlledDetail
											key={syncID}
											room={room}
											applyUpdate={applyUpdate}
											onSuccess={synchronize}
										/>
									</Tile.Body>
								</Tile>
							</Row>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
