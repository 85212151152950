import { newPageInfo } from "@hex-insights/router";
import { MFAFactor } from "@hex-insights/verita.shared";
import { mfaFactorHubPageInfo } from "../pageinfo";

type MFAFactorForRouteTitle = Pick<MFAFactor, "email">;

export const mfaFactorHubDetailPageInfo = newPageInfo({
	name: "MFA Factor Detail",
	title: (e: MFAFactorForRouteTitle) => e.email,
	description: "Detail page for an mfaFactor.",
	exact: false,
	isLeaf: true,
	to: (id: MFAFactor["id"]) => mfaFactorHubPageInfo.to + `/${id}`,
	path: mfaFactorHubPageInfo.path + "/:id(\\d+)",
	parent: mfaFactorHubPageInfo,
});
