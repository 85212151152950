import { newPageInfo } from "@hex-insights/router";
import { studentDocumentHubPageInfo } from "../pageinfo";

export const studentDocumentHubCreatePageInfo = newPageInfo({
	name: "New Student Document",
	title: "New",
	description: "Create a new Student Document record.",
	exact: true,
	to: studentDocumentHubPageInfo.to + "/new",
	path: studentDocumentHubPageInfo.path + "/new",
	parent: studentDocumentHubPageInfo,
});
