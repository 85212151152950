import { IndexForms } from "@hex-insights/app-modules";
import { CourseAttendanceRecordOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the CourseAttendanceRecord model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		CourseAttendanceRecordOrderFormValues.initial,
		CourseAttendanceRecordOrderFormValues.fieldValues,
	);
}
