import { useActivePageRegistration } from "@hex-insights/router";
import { Main } from "@hex-insights/verita.shared";
import { GeneralDashboard } from "../../../Components";
import { dashboardsGeneralPageInfo } from "./pageinfo";

export function DashboardsGeneralPage() {
	useActivePageRegistration(dashboardsGeneralPageInfo);

	return (
		<Main>
			<GeneralDashboard />
		</Main>
	);
}
