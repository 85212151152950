import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	ContractInstallmentForm,
	ContractInstallmentFormValues,
	ContractInstallmentMutation,
	useContractInstallmentDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { contractInstallmentHubDetailPageInfo } from "./pageinfo";

function ContractInstallmentHubDetailPageWrapper() {
	// Manages identity of the ContractInstallmentHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <ContractInstallmentHubDetailPage key={id} />;
}

export { ContractInstallmentHubDetailPageWrapper as ContractInstallmentHubDetailPage };

function ContractInstallmentHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useContractInstallmentDetailQuery({ variables: { id } });
	const contractInstallment = data?.contractInstallment;
	useActivePageRegistration(
		contractInstallmentHubDetailPageInfo,
		!!contractInstallment ? contractInstallmentHubDetailPageInfo.title(contractInstallment) : "",
	);

	const update = ContractInstallmentMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<ContractInstallmentFormValues.Detail>,
			initialFormValues: ContractInstallmentFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading contractInstallment</If>
				<Else>
					{!!contractInstallment && (
						<React.Fragment>
							<Heading level={1}>{contractInstallment.id}</Heading>

							<ContractInstallmentForm.ControlledDetail
								key={syncID}
								contractInstallment={contractInstallment}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
