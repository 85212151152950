import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import { EnrollmentInvitationForm, useEnrollmentInvitationDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { enrollmentInvitationHubDetailPageInfo } from "./pageinfo";

export function EnrollmentInvitationHubDetailPage() {
	const { enrollmentInvitationID } = enrollmentInvitationHubDetailPageInfo.useParams();

	const { loading, data, error } = useEnrollmentInvitationDetailQuery({ variables: { id: enrollmentInvitationID } });
	const enrollmentInvitation = data?.enrollmentInvitation;
	useActivePageRegistration(
		enrollmentInvitationHubDetailPageInfo,
		!!enrollmentInvitation ? enrollmentInvitationHubDetailPageInfo.title(enrollmentInvitation) : "",
	);

	const { syncID, synchronize } = useSync();

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading enrollment invitation</If>
				<Else>
					{!!enrollmentInvitation && (
						<React.Fragment>
							<Heading.H1>{enrollmentInvitation.id}</Heading.H1>

							<EnrollmentInvitationForm.ControlledDetail
								key={syncID}
								enrollmentInvitation={enrollmentInvitation}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
