import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const courseSectionPeriodHubPageInfo = newPageInfo({
	title: "Course Section Periods",
	description: "Pages related to the Course Section Period model.",
	exact: false,
	to: hubsPageInfo.to + "/course-section-periods",
	path: hubsPageInfo.path + "/course-section-periods",
	parent: hubsPageInfo,
});
