import "@hex-insights/core";
import { TrustedDeviceDetailQuery } from "../../GraphQL";
import { TrustedDeviceFormValues } from "../ModelFormValues";

/**
 * Converts a TrustedDevice instance to its corresponding detail form values.
 *
 * @param trustedDevice The instance to convert.
 */
export function toFormValues(trustedDevice: TrustedDeviceDetailQuery["trustedDevice"]): TrustedDeviceFormValues.Detail {
	return {
		createdAt: trustedDevice.createdAt,
		name: trustedDevice.name,
		isCurrent: trustedDevice.isCurrent,
		sessionIDs: trustedDevice.sessions.map((e) => e.id),
		userID: trustedDevice.user?.id ?? null,
	};
}
