import { IndexForms } from "@hex-insights/app-modules";
import { omitKeys } from "@hex-insights/core";
import { FormState } from "@hex-insights/forms";
import { SchoolStudentEnrollmentFilterInput } from "../../GraphQL";
import { SchoolStudentEnrollmentFilterFormValues } from "../ModelFilterFormValues";

/**
 * Counts the number of active filters for the SchoolStudentEnrollment model.
 *
 * @param formValues The form values of the filter form.
 */
export function countActiveFilters(formValues: SchoolStudentEnrollmentFilterFormValues.FormValues) {
	let numStandardFilters = 0;
	if (formValues.hasPaid) {
		numStandardFilters++;
	}
	if (formValues.hasNotPaid) {
		numStandardFilters++;
	}
	if (formValues.is20232024) {
		numStandardFilters++;
	}

	const numAdvancedFilters = IndexForms.countActiveAdvancedFilters(
		formValues,
		SchoolStudentEnrollmentFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	);
	return numStandardFilters + numAdvancedFilters;
}

/**
 * Clears the values of the filter form for the SchoolStudentEnrollment model.
 *
 * @param formState The form state of the filter form.
 */
export function clearFilters(formState: FormState<SchoolStudentEnrollmentFilterFormValues.FormValues>) {
	formState.formClearFunctions.hasPaid();
	formState.formClearFunctions.hasNotPaid();
	formState.formClearFunctions.is20232024();

	formState.formClearFunctions.filterSets();
}

/**
 * Resets the values of the filter form for the SchoolStudentEnrollment model.
 *
 * @param formState The form state of the filter form.
 */
export function resetFilters(formState: FormState<SchoolStudentEnrollmentFilterFormValues.FormValues>) {
	formState.formResetFunctions.hasPaid();
	formState.formResetFunctions.hasNotPaid();
	formState.formResetFunctions.is20232024();

	formState.formResetFunctions.filterSets();
}

/**
 * Converts the form values of the filter form for the SchoolStudentEnrollment model to the correct GraphQL
 * filter input type.
 *
 * @param formValues The form values of the filter form.
 */
export function toFilterInputs(
	formValues: SchoolStudentEnrollmentFilterFormValues.FormValues,
): SchoolStudentEnrollmentFilterInput | SchoolStudentEnrollmentFilterInput[] {
	const preparedFormValues = omitKeys(formValues, ["search", "hasPaid", "hasNotPaid", "is20232024"]);
	const baseFilterInput = IndexForms.toFilterInput(
		preparedFormValues,
		SchoolStudentEnrollmentFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	) as SchoolStudentEnrollmentFilterInput;

	const filterInput: SchoolStudentEnrollmentFilterInput = {
		and: [baseFilterInput],
	};

	if (formValues.hasPaid !== formValues.hasNotPaid) {
		if (formValues.hasPaid) {
			filterInput.and!.push({
				hasPaidFeeEQ: true,
			});
		}
		if (formValues.hasNotPaid) {
			filterInput.and!.push({
				hasPaidFeeEQ: false,
			});
		}
	}

	if (formValues.is20232024) {
		filterInput.and!.push({
			academicYear: [{ nameEQ: "2023-2024" }],
		});
	}

	if (formValues.search !== "") {
		filterInput.student = [{ person: [{ search: formValues.search }] }];
	}

	return filterInput;
}
