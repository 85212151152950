import { newPageInfo } from "@hex-insights/router";
import { admissionsPageInfo } from "../pageinfo";

export const admissionsApplicationsPageInfo = newPageInfo({
	title: "Applications",
	description: "Overview of enrollment applications.",
	exact: true,
	to:
		admissionsPageInfo.to +
		"/applications" +
		"?filter.interviewMeetingInFuture=false&order.field=CREATED_AT&order.direction=DESC",
	path: admissionsPageInfo.path + "/applications",
	parent: admissionsPageInfo,
});
