import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { TrustedDeviceFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the TrustedDevice model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => TrustedDeviceFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(
		initialFormValues,
		TrustedDeviceFilterFormValues.typeFilterObject,
	);
}
