import { newPageInfo } from "@hex-insights/router";
import { noteAttachmentHubPageInfo } from "../pageinfo";

export const noteAttachmentHubCreatePageInfo = newPageInfo({
	name: "New Note Attachment",
	title: "New",
	description: "Create a new Note Attachment record.",
	exact: true,
	to: noteAttachmentHubPageInfo.to + "/new",
	path: noteAttachmentHubPageInfo.path + "/new",
	parent: noteAttachmentHubPageInfo,
});
