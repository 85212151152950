import React from "react";
import { Heading } from "@hex-insights/core";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { EnrollmentInvitationForm } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { enrollmentInvitationHubDetailPageInfo } from "../DetailPage/pageinfo";
import { enrollmentInvitationHubCreatePageInfo } from "./pageinfo";

export function EnrollmentInvitationHubCreatePage() {
	useActivePageRegistration(enrollmentInvitationHubCreatePageInfo);

	const { push: pushHistory } = useHistory();
	const onSuccess = React.useCallback<EnrollmentInvitationForm.ControlledCreateProps["onSuccess"]>(
		({ id }) => {
			pushHistory(enrollmentInvitationHubDetailPageInfo.to(id));
		},
		[pushHistory],
	);

	return (
		<Main>
			<Heading.H1>New Enrollment Invitation</Heading.H1>

			<EnrollmentInvitationForm.ControlledCreate onSuccess={onSuccess} />
		</Main>
	);
}
