import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { NoteReactionHubCreatePage } from "./CreatePage";
import { noteReactionHubCreatePageInfo } from "./CreatePage/pageinfo";
import { NoteReactionHubDetailPage } from "./DetailPage";
import { noteReactionHubDetailPageInfo } from "./DetailPage/pageinfo";
import { NoteReactionHubIndexPage } from "./IndexPage";
import { noteReactionHubIndexPageInfo } from "./IndexPage/pageinfo";
import { noteReactionHubPageInfo } from "./pageinfo";

export function NoteReactionHub() {
	useActivePageRegistration(noteReactionHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...noteReactionHubIndexPageInfo.routeProps}>
				<NoteReactionHubIndexPage />
			</Route>
			<Route {...noteReactionHubCreatePageInfo.routeProps}>
				<NoteReactionHubCreatePage />
			</Route>
			<Route {...noteReactionHubDetailPageInfo.routeProps}>
				<NoteReactionHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
