import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { AcademicYearTuitionFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the AcademicYearTuition model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => AcademicYearTuitionFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(
		initialFormValues,
		AcademicYearTuitionFilterFormValues.typeFilterObject,
	);
}
