import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	AcademicYearTuition,
	graphTypeNames,
	Student,
	Tuition,
	TuitionCreateInput,
	TuitionCreateMutation,
	TuitionDeleteMutation,
	TuitionDetailDocument,
	TuitionDetailQuery,
	TuitionDetailQueryVariables,
	TuitionUpdateInput,
	TuitionUpdateMutation,
	useTuitionCreateMutation,
	useTuitionDeleteMutation,
	useTuitionUpdateMutation,
} from "../../GraphQL";
import { TuitionFormConversion } from "../ModelFormConversion";
import { TuitionFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the Tuition model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Tuition model.
 */
export function useCreate() {
	const [createTuition] = useTuitionCreateMutation();

	return React.useCallback(
		async (formValues: TuitionFormValues.Create) => {
			const input = TuitionFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createTuition({ variables: { input }, update: updateCache });

			return { data: data?.createTuition ?? null, errors: errors ?? null };
		},
		[createTuition],
	);
}

/**
 * Returns an `update` function for the Tuition model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Tuition model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Tuition["id"]) {
	const [updateTuition] = useTuitionUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<TuitionFormValues.Detail>, initialFormValues: TuitionFormValues.Detail) => {
			const input = TuitionFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateTuition({ variables: { id, input }, update: updateCache });

			return { data: data?.updateTuition ?? null, errors: errors ?? null };
		},
		[updateTuition, id],
	);
}

/**
 * Returns a `del` function for the Tuition model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Tuition["id"]) {
	const [deleteTuition] = useTuitionDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteTuition({ variables: { id }, update: updateCache });

		return { data: data?.deleteTuition ?? false, errors: errors ?? null };
	}, [deleteTuition, id]);
}

function getUpdateCacheForCreate(input: TuitionCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<TuitionCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createTuition;

		cache.writeQuery<TuitionDetailQuery, TuitionDetailQueryVariables>({
			query: TuitionDetailDocument,
			data: { tuition: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.academicYearTuitionIDs) {
			for (let i = 0; i < input.academicYearTuitionIDs.length; i++) {
				addToTuitionOfAcademicYearTuitionCache(cache, input.academicYearTuitionIDs[i], createdObject);
			}
		}

		if (input.studentIDs) {
			for (let i = 0; i < input.studentIDs.length; i++) {
				addToTuitionOfStudentCache(cache, input.studentIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "tuitionConnection" });
	};
}

function getUpdateCacheForUpdate(input: TuitionUpdateInput, _initialFormValues: TuitionFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<TuitionUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateTuition;

		if (input.addAcademicYearTuitionIDs) {
			for (let i = 0; i < input.addAcademicYearTuitionIDs.length; i++) {
				addToTuitionOfAcademicYearTuitionCache(cache, input.addAcademicYearTuitionIDs[i], updatedObject);
			}
		}
		if (input.removeAcademicYearTuitionIDs) {
			for (let i = 0; i < input.removeAcademicYearTuitionIDs.length; i++) {
				removeFromTuitionOfAcademicYearTuitionCache(cache, input.removeAcademicYearTuitionIDs[i], updatedObject);
			}
		}

		if (input.addStudentIDs) {
			for (let i = 0; i < input.addStudentIDs.length; i++) {
				addToTuitionOfStudentCache(cache, input.addStudentIDs[i], updatedObject);
			}
		}
		if (input.removeStudentIDs) {
			for (let i = 0; i < input.removeStudentIDs.length; i++) {
				removeFromTuitionOfStudentCache(cache, input.removeStudentIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: Tuition["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<TuitionDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteTuition) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Tuition }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "tuitionConnection" });
		cache.gc();
	};
}

function addToTuitionOfAcademicYearTuitionCache(
	cache: ApolloCache<any>,
	targetID: AcademicYearTuition["id"],
	object: Pick<Tuition, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.AcademicYearTuition }),
		fields: {
			tuition: () => objectRef,
			tuitionID: () => object.id,
		},
	});
}

function removeFromTuitionOfAcademicYearTuitionCache(
	cache: ApolloCache<any>,
	targetID: AcademicYearTuition["id"],
	_object: Pick<Tuition, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.AcademicYearTuition }),
		fields: {
			tuition: () => null,
			tuitionID: () => null,
		},
	});
}

function addToTuitionOfStudentCache(cache: ApolloCache<any>, targetID: Student["id"], object: Pick<Tuition, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			tuition: () => objectRef,
			tuitionID: () => object.id,
		},
	});
}

function removeFromTuitionOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	_object: Pick<Tuition, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			tuition: () => null,
			tuitionID: () => null,
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Tuition, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment TuitionRef on Tuition {
				id
			}
		`,
		data: object,
	});
}
