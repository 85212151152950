import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import {
	StudentDocumentFilterInput,
	StudentDocumentOrder,
	useStudentDocumentPaginationQuery,
} from "../../../../Utilities";

export type StudentDocumentPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: StudentDocumentFilterInput | StudentDocumentFilterInput[] | null;
	order: StudentDocumentOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the StudentDocument model.
 */
export function StudentDocumentPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: StudentDocumentPaginationFormProps) {
	const { loading, data } = useStudentDocumentPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.studentDocumentConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
