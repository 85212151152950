import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { LoginRecordFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the LoginRecord model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => LoginRecordFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(
		initialFormValues,
		LoginRecordFilterFormValues.typeFilterObject,
	);
}
