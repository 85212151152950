import { newPageInfo } from "@hex-insights/router";
import { Address } from "@hex-insights/verita.shared";
import { addressHubPageInfo } from "../pageinfo";

type AddressForRouteTitle = Pick<Address, "id">;

export const addressHubDetailPageInfo = newPageInfo({
	name: "Address Detail",
	title: (e: AddressForRouteTitle) => e.id,
	description: "Detail page for an address.",
	exact: false,
	isLeaf: true,
	to: (id: Address["id"]) => addressHubPageInfo.to + `/${id}`,
	path: addressHubPageInfo.path + "/:id(\\d+)",
	parent: addressHubPageInfo,
});
