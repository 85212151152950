import React from "react";
import { DistributiveOmit } from "@hex-insights/core";
import { FormType, useFormState, UseFormStateArgs } from "@hex-insights/forms";
import { EnrollmentApplicationParentFormValues } from "../ModelFormValues";

export type UseCreateFormStateArgs = DistributiveOmit<
	UseFormStateArgs<EnrollmentApplicationParentFormValues.Create>,
	"initialFormValues"
> & {
	initialFormValues?: Partial<EnrollmentApplicationParentFormValues.Create>;
};

/**
 * Returns a `formState` object for use in the create form for the EnrollmentApplicationParent model.
 */
export function useCreateFormState({
	initialFormValues,
	formType = FormType.Create,
	...args
}: UseCreateFormStateArgs = {}) {
	return useFormState({
		initialFormValues: React.useMemo(
			() => ({ ...EnrollmentApplicationParentFormValues.initialCreate, ...initialFormValues }),
			[initialFormValues],
		),
		formType,
		...args,
	});
}

export type UseDetailFormStateArgs = UseFormStateArgs<EnrollmentApplicationParentFormValues.Detail>;

/**
 * Returns a `formState` object for use in the detail form of the EnrollmentApplicationParent model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link EnrollmentApplicationParentFormConversion.toFormValues}.
 */
export function useDetailFormState({ initialFormValues, formType = FormType.Update, ...args }: UseDetailFormStateArgs) {
	return useFormState({ initialFormValues, formType, ...args });
}

export type UseReadOnlyFormStateArgs = UseFormStateArgs<EnrollmentApplicationParentFormValues.Detail>;

/**
 * Returns a read-only `formState` object for use in the detail form of the EnrollmentApplicationParent model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link EnrollmentApplicationParentFormConversion.toFormValues}.
 */
export function useReadOnlyFormState({
	initialFormValues,
	formType = FormType.View,
	...args
}: UseReadOnlyFormStateArgs) {
	return useFormState({ initialFormValues, formType, ...args });
}
