import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import {
	EnrollmentApplicationStudent,
	EnrollmentApplicationStudentFormat,
	EnrollmentApplicationStudentNameFragment,
} from "../../../../Utilities";

type ModelForLink = Pick<EnrollmentApplicationStudent, "id"> & EnrollmentApplicationStudentNameFragment;

export type EnrollmentApplicationStudentLinkProps = DistributiveOmit<
	Models.LinkProps<ModelForLink>,
	"pageInfoKey" | "format"
>;

/**
 * Renders a link to the given EnrollmentApplicationStudent instance.
 */
export function EnrollmentApplicationStudentLink(props: EnrollmentApplicationStudentLinkProps) {
	return (
		<Models.Link<ModelForLink>
			pageInfoKey="EnrollmentApplicationStudent"
			format={EnrollmentApplicationStudentFormat.name}
			{...props}
		/>
	);
}
