import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	MealPlanForm,
	MealPlanFormValues,
	MealPlanMutation,
	useMealPlanDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { mealPlanHubDetailPageInfo } from "./pageinfo";

function MealPlanHubDetailPageWrapper() {
	// Manages identity of the MealPlanHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <MealPlanHubDetailPage key={id} />;
}

export { MealPlanHubDetailPageWrapper as MealPlanHubDetailPage };

function MealPlanHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useMealPlanDetailQuery({ variables: { id } });
	const mealPlan = data?.mealPlan;
	useActivePageRegistration(mealPlanHubDetailPageInfo, !!mealPlan ? mealPlanHubDetailPageInfo.title(mealPlan) : "");

	const update = MealPlanMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<MealPlanFormValues.Detail>, initialFormValues: MealPlanFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading mealPlan</If>
				<Else>
					{!!mealPlan && (
						<React.Fragment>
							<Heading level={1}>{mealPlan.id}</Heading>

							<MealPlanForm.ControlledDetail
								key={syncID}
								mealPlan={mealPlan}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
