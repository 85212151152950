import { EnrollmentApplicationUserViewRecord } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the createdAt field of the EnrollmentApplicationUserViewRecord model.
 */
export function createdAt(value: EnrollmentApplicationUserViewRecord["createdAt"] | null): string {
	return toString(value);
}

/**
 * Formats the isDetail field of the EnrollmentApplicationUserViewRecord model.
 */
export function isDetail(value: EnrollmentApplicationUserViewRecord["isDetail"]): string {
	return toString(value);
}
