import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { RoomCreateInput, RoomDetailQuery, RoomUpdateInput } from "../../GraphQL";
import { RoomFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Room model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: RoomFormValues.Create): RoomCreateInput {
	const input: NullPartial<RoomCreateInput> = { ...formValues };
	return input as RoomCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Room model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<RoomFormValues.Detail>,
	initialFormValues: RoomFormValues.Detail,
): RoomUpdateInput {
	const inputFormValues = omitKeys(formValues, ["courseSectionPeriodIDs"]);
	const input: RoomUpdateInput = { ...inputFormValues };

	if (formValues.courseSectionPeriodIDs !== undefined) {
		const { added, removed } = getArrayDiff(
			initialFormValues.courseSectionPeriodIDs,
			formValues.courseSectionPeriodIDs,
		);
		input.addCourseSectionPeriodIDs = added;
		input.removeCourseSectionPeriodIDs = removed;
	}

	return input;
}

/**
 * Converts a Room instance to its corresponding detail form values.
 *
 * @param room The instance to convert.
 */
export function toFormValues(room: RoomDetailQuery["room"]): RoomFormValues.Detail {
	return {
		name: room.name,
		number: room.number,
		campusID: room.campus?.id ?? null,
		courseSectionPeriodIDs: room.courseSectionPeriods.map((e) => e.id),
	};
}
