import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { CampusOrderFormValues } from "../../../../Utilities";

export type CampusOrderFormControllerProps = {
	formState: FormState<CampusOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the Campus model.
 */
export function CampusOrderFormController({ formState }: CampusOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={CampusOrderFormValues.fieldOptions}
			fieldTypeMapping={CampusOrderFormValues.fieldTypeMapping}
		/>
	);
}
