import { newPageInfo } from "@hex-insights/router";
import { parentHubPageInfo } from "../pageinfo";

export const parentHubIndexPageInfo = newPageInfo({
	name: "Parents Index",
	title: "",
	description: "Index of Parents.",
	exact: true,
	to: parentHubPageInfo.to,
	path: parentHubPageInfo.path,
	parent: parentHubPageInfo,
});
