import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	graphTypeNames,
	LearningObjectiveMark,
	ReportCard,
	ReportCardCreateInput,
	ReportCardCreateMutation,
	ReportCardDeleteMutation,
	ReportCardDetailDocument,
	ReportCardDetailQuery,
	ReportCardDetailQueryVariables,
	ReportCardUpdateInput,
	ReportCardUpdateMutation,
	Student,
	Term,
	useReportCardCreateMutation,
	useReportCardDeleteMutation,
	useReportCardUpdateMutation,
} from "../../GraphQL";
import { ReportCardFormConversion } from "../ModelFormConversion";
import { ReportCardFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the ReportCard model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the ReportCard model.
 */
export function useCreate() {
	const [createReportCard] = useReportCardCreateMutation();

	return React.useCallback(
		async (formValues: ReportCardFormValues.Create) => {
			const input = ReportCardFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createReportCard({ variables: { input }, update: updateCache });

			return { data: data?.createReportCard ?? null, errors: errors ?? null };
		},
		[createReportCard],
	);
}

/**
 * Returns an `update` function for the ReportCard model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the ReportCard model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: ReportCard["id"]) {
	const [updateReportCard] = useReportCardUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<ReportCardFormValues.Detail>, initialFormValues: ReportCardFormValues.Detail) => {
			const input = ReportCardFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateReportCard({ variables: { id, input }, update: updateCache });

			return { data: data?.updateReportCard ?? null, errors: errors ?? null };
		},
		[updateReportCard, id],
	);
}

/**
 * Returns a `del` function for the ReportCard model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: ReportCard["id"]) {
	const [deleteReportCard] = useReportCardDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteReportCard({ variables: { id }, update: updateCache });

		return { data: data?.deleteReportCard ?? false, errors: errors ?? null };
	}, [deleteReportCard, id]);
}

function getUpdateCacheForCreate(input: ReportCardCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ReportCardCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createReportCard;

		cache.writeQuery<ReportCardDetailQuery, ReportCardDetailQueryVariables>({
			query: ReportCardDetailDocument,
			data: { reportCard: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.learningObjectiveMarkIDs) {
			for (let i = 0; i < input.learningObjectiveMarkIDs.length; i++) {
				addToReportCardOfLearningObjectiveMarkCache(cache, input.learningObjectiveMarkIDs[i], createdObject);
			}
		}

		if (input.studentID) {
			addToReportCardsOfStudentCache(cache, input.studentID, createdObject);
		}

		if (input.termID) {
			addToReportCardsOfTermCache(cache, input.termID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "reportCardConnection" });
	};
}

function getUpdateCacheForUpdate(input: ReportCardUpdateInput, initialFormValues: ReportCardFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ReportCardUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateReportCard;

		if (input.addLearningObjectiveMarkIDs) {
			for (let i = 0; i < input.addLearningObjectiveMarkIDs.length; i++) {
				addToReportCardOfLearningObjectiveMarkCache(cache, input.addLearningObjectiveMarkIDs[i], updatedObject);
			}
		}
		if (input.removeLearningObjectiveMarkIDs) {
			for (let i = 0; i < input.removeLearningObjectiveMarkIDs.length; i++) {
				removeFromReportCardOfLearningObjectiveMarkCache(cache, input.removeLearningObjectiveMarkIDs[i], updatedObject);
			}
		}

		if (initialFormValues.studentID && input.studentID) {
			removeFromReportCardsOfStudentCache(cache, initialFormValues.studentID, updatedObject);
		}
		if (input.studentID) {
			addToReportCardsOfStudentCache(cache, input.studentID, updatedObject);
		}

		if (initialFormValues.termID && input.termID) {
			removeFromReportCardsOfTermCache(cache, initialFormValues.termID, updatedObject);
		}
		if (input.termID) {
			addToReportCardsOfTermCache(cache, input.termID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: ReportCard["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ReportCardDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteReportCard) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.ReportCard }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "reportCardConnection" });
		cache.gc();
	};
}

function addToReportCardOfLearningObjectiveMarkCache(
	cache: ApolloCache<any>,
	targetID: LearningObjectiveMark["id"],
	object: Pick<ReportCard, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.LearningObjectiveMark }),
		fields: {
			reportCard: () => objectRef,
			reportCardID: () => object.id,
		},
	});
}

function removeFromReportCardOfLearningObjectiveMarkCache(
	cache: ApolloCache<any>,
	targetID: LearningObjectiveMark["id"],
	_object: Pick<ReportCard, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.LearningObjectiveMark }),
		fields: {
			reportCard: () => null,
			reportCardID: () => null,
		},
	});
}

function addToReportCardsOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<ReportCard, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			reportCards: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromReportCardsOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<ReportCard, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			reportCards: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToReportCardsOfTermCache(cache: ApolloCache<any>, targetID: Term["id"], object: Pick<ReportCard, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Term }),
		fields: {
			reportCards: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromReportCardsOfTermCache(
	cache: ApolloCache<any>,
	targetID: Term["id"],
	object: Pick<ReportCard, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Term }),
		fields: {
			reportCards: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<ReportCard, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment ReportCardRef on ReportCard {
				id
			}
		`,
		data: object,
	});
}
