import React from "react";
import { Column, Heading } from "@hex-insights/core";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { ParentForm, ParentFormValues, ParentMutation } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { parentHubDetailPageInfo } from "../DetailPage/pageinfo";
import { parentHubCreatePageInfo } from "./pageinfo";

export function ParentHubCreatePage() {
	useActivePageRegistration(parentHubCreatePageInfo);

	const create = ParentMutation.useCreateWithPerson();
	const createdIDRef = React.useRef<string | null>(null);
	const { push: pushHistory } = useHistory();

	const applyCreate = React.useCallback(
		async (formValues: ParentFormValues.CreateWithPerson) => {
			const { data, errors } = await create(formValues);
			createdIDRef.current = data?.id ?? null;
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		if (createdIDRef.current !== null) {
			pushHistory(parentHubDetailPageInfo.to(createdIDRef.current));
		}
	}, [pushHistory]);

	return (
		<Main>
			<Heading level={1}>New Parent</Heading>

			<Column style={{ width: "fit-content" }}>
				<ParentForm.ControlledCreateWithPerson applyCreate={applyCreate} onSuccess={onSuccess} />
			</Column>
		</Main>
	);
}
