import React from "react";
import { Conditional, Else, Grid, Heading, If, Row, Section, toLocalDateString, useSync } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	CourseForm,
	CourseFormValues,
	CourseMutation,
	PersonIcon,
	ScaledText,
	Tile,
	useCourseDetailQuery,
	useCourseSectionIndexQuery,
	useEmployeeIndexQuery,
	useStudentListQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { courseSectionHubDetailPageInfo } from "../../CourseSection/DetailPage/pageinfo";
import { employeeHubDetailPageInfo } from "../../Employee/DetailPage/pageinfo";
import { studentHubDetailPageInfo } from "../../Student/DetailPage/pageinfo";
import { courseHubDetailPageInfo, CourseHubDetailPageRouteParams } from "./pageinfo";

function CourseHubDetailPageWrapper() {
	// Manages identity of the CourseHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <CourseHubDetailPage key={id} />;
}

export { CourseHubDetailPageWrapper as CourseHubDetailPage };

function CourseHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useCourseDetailQuery({ variables: { id } });
	const course = data?.course;
	useActivePageRegistration(courseHubDetailPageInfo, !!course ? courseHubDetailPageInfo.title(course) : "");

	const update = CourseMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<CourseFormValues.Detail>, initialFormValues: CourseFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading course</If>
				<Else>
					{!!course && (
						<React.Fragment>
							<Heading level={1}>{course.name}</Heading>

							<Row justify="spaced-start">
								<Tile>
									<Tile.Body>
										<CourseForm.ControlledDetail
											key={syncID}
											course={course}
											applyUpdate={applyUpdate}
											onSuccess={synchronize}
										/>
									</Tile.Body>
								</Tile>

								<Tile style={{ flexGrow: 1, width: "100%", overflow: "hidden" }}>
									<Tile.Body>
										<EmployeesSection />
										<StudentsSection />
										<HomeRoomSectionsSection />
									</Tile.Body>
								</Tile>
							</Row>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}

function EmployeesSection() {
	const { id } = useRouteParams<CourseHubDetailPageRouteParams>();
	const today = toLocalDateString(new Date());
	const { loading, data } = useEmployeeIndexQuery({
		variables: {
			filters: {
				courseSectionTeacherEnrollments: [
					{
						courseSection: [
							{
								courseIDEQ: id,
								term: [{ startDateLTE: today, endDateGTE: today }],
							},
						],
					},
				],
			},
		},
	});

	return (
		<Section>
			<Section.Header>
				<Heading level={3} noMargin>
					Teachers
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Row justify="spaced-start" overflow="scroll" style={{ width: "100%" }}>
							{data?.employeeConnection.edges.map((e) => (
								<InternalLink
									key={e.node.id}
									to={employeeHubDetailPageInfo.to(e.node.id)}
									className="link--no-text-decoration"
								>
									<PersonIcon person={e.node.person} />
								</InternalLink>
							))}
						</Row>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}

function StudentsSection() {
	const { id } = useRouteParams<CourseHubDetailPageRouteParams>();
	const today = toLocalDateString(new Date());
	const { loading, data } = useStudentListQuery({
		variables: {
			filters: [
				{
					courseSectionStudentEnrollments: [
						{
							courseSection: [
								{
									courseIDEQ: id,
									term: [{ startDateLTE: today, endDateGTE: today }],
								},
							],
						},
					],
				},
			],
		},
	});

	return (
		<Section>
			<Section.Header>
				<Heading level={3} noMargin>
					Students
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Row justify="spaced-start" overflow="scroll" style={{ width: "100%" }}>
							{data?.studentConnection.edges.map((e) => (
								<InternalLink
									key={e.node.id}
									to={studentHubDetailPageInfo.to(e.node.id)}
									className="link--no-text-decoration"
								>
									<PersonIcon person={e.node.person} />
								</InternalLink>
							))}
						</Row>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}

function HomeRoomSectionsSection() {
	const { id } = useRouteParams<CourseHubDetailPageRouteParams>();
	const { loading, data } = useCourseSectionIndexQuery({
		variables: {
			filters: {
				courseIDEQ: id,
			},
		},
	});

	return (
		<Section>
			<Section.Header>
				<Heading level={3} noMargin>
					Course Sections
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Grid columns={4} gap="1rem">
							{data?.courseSectionConnection.edges.map((e) => (
								<InternalLink
									key={e.node.id}
									to={courseSectionHubDetailPageInfo.to(e.node.id)}
									className="link--no-text-decoration"
									style={{
										width: "100%",
										height: "5rem",
										padding: "0.25rem",
										border: "2px solid var(--light-blue)",
										borderRadius: "0.5rem",
										display: "block",
									}}
								>
									<Row justify="center" align="center" style={{ width: "100%", height: "100%" }}>
										<ScaledText maxFontSize={24} style={{ textAlign: "center", maxWidth: "100%", maxHeight: "100%" }}>
											{e.node.name}
										</ScaledText>
									</Row>
								</InternalLink>
							))}
						</Grid>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}
