import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	NoteCommentFilterFormController,
	NoteCommentFilterFormState,
	NoteCommentFilterFormUtils,
	NoteCommentFormat,
	NoteCommentIndexQuery,
	NoteCommentOrderField,
	NoteCommentOrderFormController,
	NoteCommentOrderFormState,
	NoteCommentOrderFormValues,
	NoteCommentPaginationForm,
	NoteCommentSearchForm,
	useNoteCommentIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { noteCommentHubCreatePageInfo } from "../CreatePage/pageinfo";
import { noteCommentHubDetailPageInfo } from "../DetailPage/pageinfo";
import { noteCommentHubIndexPageInfo } from "./pageinfo";

const columns: (keyof NoteCommentIndexQuery["noteCommentConnection"]["edges"][0]["node"])[] = [
	"id",
	"body",
	"createdAt",
];

function orderFieldToColumn(field: NoteCommentOrderField | null) {
	if (field === null) {
		return "id";
	}
	return NoteCommentOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return NoteCommentOrderFormValues.modelFieldToOrderField[column];
}

export function NoteCommentHubIndexPage() {
	useActivePageRegistration(noteCommentHubIndexPageInfo);

	const filterFormState = NoteCommentFilterFormState.useFormState();
	const orderFormState = NoteCommentOrderFormState.useFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(filterFormState.formValues, NoteCommentFilterFormUtils.toFilterInputs);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useNoteCommentIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		NoteCommentFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = NoteCommentFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.noteCommentConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading level={1} noMargin>
					Note Comments
				</Heading>

				<InternalLink to={noteCommentHubCreatePageInfo.to}>New Note Comment</InternalLink>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<NoteCommentSearchForm formState={filterFormState} />
				<NoteCommentFilterFormController formState={filterFormState} numActiveFilters={numActiveFilters} />
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<NoteCommentOrderFormController formState={orderFormState} />
			</Row>

			<Table.Container className="hub__index-page__table__container">
				<Table columns={columns} minColumnWidth="10rem" {...tableSortingProps} className="hub__index-page__table">
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="id">ID</Table.Heading>
							<Table.Heading column="body" noSort>
								Body
							</Table.Heading>
							<Table.Heading column="createdAt">Created At</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.noteCommentConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="id" oneLine numeric>
											<InternalLink to={noteCommentHubDetailPageInfo.to(e.node.id)}>{e.node.id}</InternalLink>
										</Table.Cell>
										<Table.Cell column="body" oneLine>
											{NoteCommentFormat.Fields.body(e.node.body)}
										</Table.Cell>
										<Table.Cell column="createdAt" oneLine>
											{NoteCommentFormat.Fields.createdAt(e.node.createdAt)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<NoteCommentPaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.noteCommentConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
