import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { EnrollmentInvitationHubCreatePage } from "./CreatePage";
import { enrollmentInvitationHubCreatePageInfo } from "./CreatePage/pageinfo";
import { EnrollmentInvitationHubDetailPage } from "./DetailPage";
import { enrollmentInvitationHubDetailPageInfo } from "./DetailPage/pageinfo";
import { EnrollmentInvitationHubIndexPage } from "./IndexPage";
import { enrollmentInvitationHubIndexPageInfo } from "./IndexPage/pageinfo";
import { enrollmentInvitationHubPageInfo } from "./pageinfo";

export function EnrollmentInvitationHub() {
	useActivePageRegistration(enrollmentInvitationHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...enrollmentInvitationHubIndexPageInfo.routeProps}>
				<EnrollmentInvitationHubIndexPage />
			</Route>
			<Route {...enrollmentInvitationHubCreatePageInfo.routeProps}>
				<EnrollmentInvitationHubCreatePage />
			</Route>
			<Route {...enrollmentInvitationHubDetailPageInfo.routeProps}>
				<EnrollmentInvitationHubDetailPage key={enrollmentInvitationHubDetailPageInfo.useKey()} />
			</Route>
		</PageRouteSwitch>
	);
}
