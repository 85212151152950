import { newPageInfo } from "@hex-insights/router";
import { HomeRoomSection } from "@hex-insights/verita.shared";
import { termSetupDetailHomeRoomSectionsPageInfo } from "../pageinfo";

type HomeRoomSectionForRouteTitle = Pick<HomeRoomSection, "name">;

export const termSetupDetailHomeRoomSectionsDetailPageInfo = newPageInfo({
	name: "Home Room Section Detail Page",
	title: (homeRoomSection: HomeRoomSectionForRouteTitle) => homeRoomSection.name,
	description: "Detail page for a Home Room Section.",
	exact: false,
	noSearch: true,
	to: (arg1: Parameters<typeof termSetupDetailHomeRoomSectionsPageInfo.to>[0], id: HomeRoomSection["id"]) =>
		termSetupDetailHomeRoomSectionsPageInfo.to(arg1) + `/${id}`,
	path: termSetupDetailHomeRoomSectionsPageInfo.path + "/:homeRoomSectionID(\\d+)",
	parent: termSetupDetailHomeRoomSectionsPageInfo,
});
