import { newPageInfo } from "@hex-insights/router";
import { DocumentResourceFolder } from "@hex-insights/verita.shared";
import { documentResourceFolderHubPageInfo } from "../pageinfo";

type DocumentResourceFolderForRouteTitle = Pick<DocumentResourceFolder, "name">;

export const documentResourceFolderHubDetailPageInfo = newPageInfo({
	name: "Document Resource Folder Detail",
	title: (e: DocumentResourceFolderForRouteTitle) => e.name,
	description: "Detail page for an documentResourceFolder.",
	exact: false,
	isLeaf: true,
	to: (id: DocumentResourceFolder["id"]) => documentResourceFolderHubPageInfo.to + `/${id}`,
	path: documentResourceFolderHubPageInfo.path + "/:id(\\d+)",
	parent: documentResourceFolderHubPageInfo,
});
