import { IndexForms } from "@hex-insights/app-modules";
import { SchoolAttendanceRecordOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the SchoolAttendanceRecord model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		SchoolAttendanceRecordOrderFormValues.initial,
		SchoolAttendanceRecordOrderFormValues.fieldValues,
	);
}
