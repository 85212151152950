import React from "react";
import {
	CreateFunction,
	onCreateSubmit,
	onUpdateSubmit,
	UpdateFunction,
	useApplyCreate,
	useApplyUpdate,
} from "@hex-insights/app-modules";
import { Column, DistributiveOmit, Heading, HeadingLevel, Row, Section } from "@hex-insights/core";
import {
	FieldIf,
	Form,
	FormEditButtonPair,
	FormState,
	FormSubmitFunction,
	SubmitButton,
	useOnSuccess,
} from "@hex-insights/forms";
import {
	EnrollmentApplicationStudentCreateMutation,
	EnrollmentApplicationStudentDetailQuery,
	EnrollmentApplicationStudentFormConversion,
	EnrollmentApplicationStudentFormState,
	EnrollmentApplicationStudentFormValues,
	EnrollmentApplicationStudentGender,
	EnrollmentApplicationStudentMutation,
	EnrollmentApplicationStudentUpdateMutation,
} from "../../../../Utilities";
import { EnrollmentApplicationStudentIcon } from "../../../EnrollmentApplicationStudentIcon";
import * as Field from "./Field";
import styles from "./styles.module.css";

export type ControlledCreateProps = Pick<
	CreateProps<EnrollmentApplicationStudentCreateMutation["createEnrollmentApplicationStudent"]>,
	"onSuccess" | "formNameSuffix"
> & {
	formStateArgs?: Partial<EnrollmentApplicationStudentFormState.UseCreateFormStateArgs>;
};

/**
 * Renders the create form of the EnrollmentApplicationStudent model using an internally managed form state.
 */
export function ControlledCreate({ formStateArgs, ...props }: ControlledCreateProps) {
	const formState = EnrollmentApplicationStudentFormState.useCreateFormState(formStateArgs);
	const create = EnrollmentApplicationStudentMutation.useCreate();

	return <Create formState={formState} create={create} {...props} />;
}

export type CreateProps<T> = {
	formState: FormState<EnrollmentApplicationStudentFormValues.Create>;
	create: CreateFunction<EnrollmentApplicationStudentFormValues.Create, T>;
	onSuccess: (data: T) => void;
	formNameSuffix?: string;
};

/**
 * Renders the create form of the EnrollmentApplicationStudent model using the given form state.
 */
export function Create<T>({ formState, create, onSuccess, formNameSuffix }: CreateProps<T>) {
	const { applyCreate, resultRef } = useApplyCreate(create);
	const onSubmit = React.useCallback<FormSubmitFunction<EnrollmentApplicationStudentFormValues.Create>>(
		async ({ formValues }) => onCreateSubmit(formValues, applyCreate),
		[applyCreate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	const formName = "enrollmentApplicationStudent.create" + (formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form name={formName} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Form.Column>
				<CreateFields formState={formState} />

				<Form.ActionBar>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Form.ActionBar>
			</Form.Column>
		</Form>
	);
}

type CreateFieldsProps = {
	formState: FormState<EnrollmentApplicationStudentFormValues.Create>;
};

function CreateFields({ formState }: CreateFieldsProps) {
	return (
		<React.Fragment>
			<Field.Image formState={formState} />
			<Field.FirstName formState={formState} />
			<Field.LastName formState={formState} />
			<Field.Nickname formState={formState} />
			<Field.DateOfBirth formState={formState} />
			<Field.Gender formState={formState} />
			<Field.GenderCustom formState={formState} />
			<Field.Nationality formState={formState} />
			<Field.PrimaryLanguage formState={formState} />
			<Field.EnglishLanguageFluency formState={formState} />
			<Field.CurrentGradeLevel formState={formState} />
			<Field.GradeLevelApplyingFor formState={formState} />
			<Field.HasPreviousSchooling formState={formState} />
			<Field.PreviousSchoolInformation formState={formState} />
			<Field.PreviousSchoolLocation formState={formState} />
			<PersonalityFields formState={formState} />
			<Field.EnrollmentApplication formState={formState} />
			<Field.EnrollmentApplicationSubmission formState={formState} />
		</React.Fragment>
	);
}

export type ControlledDetailProps = Pick<
	DetailProps<EnrollmentApplicationStudentUpdateMutation["updateEnrollmentApplicationStudent"]>,
	"enrollmentApplicationStudent" | "onSuccess" | "formNameSuffix"
> & {
	formStateArgs?: Partial<
		DistributiveOmit<EnrollmentApplicationStudentFormState.UseDetailFormStateArgs, "initialFormValues">
	>;
};

/**
 * Renders the detail form of the EnrollmentApplicationStudent model using an internally managed form state.
 */
export function ControlledDetail({ formStateArgs, ...props }: ControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => EnrollmentApplicationStudentFormConversion.toFormValues(props.enrollmentApplicationStudent),
		[props.enrollmentApplicationStudent],
	);
	const formState = EnrollmentApplicationStudentFormState.useDetailFormState({ ...formStateArgs, initialFormValues });
	const update = EnrollmentApplicationStudentMutation.useUpdate(props.enrollmentApplicationStudent.id);

	return <Detail formState={formState} update={update} {...props} />;
}

export type DetailProps<T> = {
	enrollmentApplicationStudent: EnrollmentApplicationStudentDetailQuery["enrollmentApplicationStudent"];
	formState: FormState<EnrollmentApplicationStudentFormValues.Detail>;
	update: UpdateFunction<EnrollmentApplicationStudentFormValues.Detail, T>;
	onSuccess: (data: T) => void;
	formNameSuffix?: string;
};

/**
 * Renders the detail form of the EnrollmentApplicationStudent model using the given form state.
 */
export function Detail<T>({
	formState,
	enrollmentApplicationStudent,
	update,
	onSuccess,
	formNameSuffix,
}: DetailProps<T>) {
	const { applyUpdate, resultRef } = useApplyUpdate(update);
	const onSubmit = React.useCallback<FormSubmitFunction<EnrollmentApplicationStudentFormValues.Detail>>(
		async (formState) => onUpdateSubmit(formState, applyUpdate),
		[applyUpdate],
	);
	useOnSuccess(formState, () => onSuccess(resultRef.current!));

	const formName =
		`enrollmentApplicationStudent.detail.${enrollmentApplicationStudent.id}` +
		(formNameSuffix ? "." + formNameSuffix : "");

	return (
		<Form name={formName} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Form.Column>
				<FormEditButtonPair formState={formState} />

				<DetailFields formState={formState} enrollmentApplicationStudent={enrollmentApplicationStudent} />
			</Form.Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = Pick<ReadOnlyProps, "enrollmentApplicationStudent" | "headingLevel"> & {
	formStateArgs?: Partial<
		DistributiveOmit<EnrollmentApplicationStudentFormState.UseReadOnlyFormStateArgs, "initialFormValues">
	>;
};

/**
 * Renders a read-only detail form of the EnrollmentApplicationStudent model using an internally managed form state.
 */
export function ControlledReadOnly({ formStateArgs, ...props }: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => EnrollmentApplicationStudentFormConversion.toFormValues(props.enrollmentApplicationStudent),
		[props.enrollmentApplicationStudent],
	);
	const formState = EnrollmentApplicationStudentFormState.useReadOnlyFormState({
		...formStateArgs,
		initialFormValues,
	});

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = {
	enrollmentApplicationStudent: EnrollmentApplicationStudentDetailQuery["enrollmentApplicationStudent"];
	formState: FormState<EnrollmentApplicationStudentFormValues.Detail>;
	headingLevel?: HeadingLevel;
};

/**
 * Renders a read-only detail form of the EnrollmentApplicationStudent model using the given form state.
 */
export function ReadOnly({ formState, headingLevel = 2 }: ReadOnlyProps) {
	return (
		<Form.Column>
			<Section>
				<Section.Header>
					<Heading level={headingLevel} noMargin>
						Basic Information
					</Heading>
				</Section.Header>
				<Section.Body className={styles["form-section__body"]}>
					<Column justify="spaced-start" className={styles["form-section__column"]}>
						<BasicDetailFields formState={formState} />
					</Column>
				</Section.Body>
			</Section>

			<Section>
				<Section.Header>
					<Heading level={headingLevel} noMargin>
						Personality
					</Heading>
				</Section.Header>
				<Section.Body className={styles["form-section__body"]}>
					<Column
						justify="spaced-start"
						className={styles["form-section__column"] + " " + styles["personality-fields"]}
					>
						<PersonalityFields formState={formState} />
					</Column>
				</Section.Body>
			</Section>
		</Form.Column>
	);
}

type DetailFieldsProps = {
	enrollmentApplicationStudent: EnrollmentApplicationStudentDetailQuery["enrollmentApplicationStudent"];
	formState: FormState<EnrollmentApplicationStudentFormValues.Detail>;
};

function DetailFields({ formState, enrollmentApplicationStudent }: DetailFieldsProps) {
	return (
		<React.Fragment>
			<BasicDetailFields formState={formState} />
			<PersonalityFields formState={formState} />
			<Field.EnrollmentApplication
				formState={formState}
				currentEnrollmentApplication={enrollmentApplicationStudent.enrollmentApplication}
			/>
			<Field.EnrollmentApplicationSubmission
				formState={formState}
				currentEnrollmentApplicationSubmission={enrollmentApplicationStudent.enrollmentApplicationSubmission}
			/>
		</React.Fragment>
	);
}

const conditionalPreviousSchoolingCreateFields: (keyof EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateBasic)[] =
	["previousSchoolInformation", "previousSchoolLocation"];

export type BasicCreateFieldsProps = {
	formState: FormState<EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateBasic>;
	headingLevel?: HeadingLevel;
};

export function BasicCreateFields({ formState, headingLevel = 2 }: BasicCreateFieldsProps) {
	return (
		<React.Fragment>
			<Section>
				<Section.Body>
					<Form.Column>
						<Row justify="spaced-start" align="center" overflow="wrap">
							<EnrollmentApplicationStudentIcon image={formState.formValues.image} imageSize="6rem" />
							<Field.Image formState={formState} />
						</Row>
						<Row justify="spaced-start" overflow="wrap" align="spaced-start">
							<Field.FirstName formState={formState} />
							<Field.LastName formState={formState} />
							<Field.Nickname formState={formState} />
							<Field.DateOfBirth formState={formState} />
						</Row>
						<Row justify="spaced-start" overflow="wrap" align="spaced-start">
							<Field.Gender formState={formState} />
							<FieldIf
								formState={formState}
								name="genderCustom"
								condition={formState.formValues.gender === EnrollmentApplicationStudentGender.Other}
							>
								<Field.GenderCustom formState={formState} />
							</FieldIf>
						</Row>
						<Row justify="spaced-start" overflow="wrap" align="spaced-start">
							<Field.NationalityList formState={formState} />
							<Field.PrimaryLanguageList formState={formState} />
							<Field.EnglishLanguageFluency formState={formState} />
						</Row>
					</Form.Column>
				</Section.Body>
			</Section>

			<Section>
				<Section.Header>
					<Heading level={headingLevel} noMargin>
						School Information
					</Heading>
				</Section.Header>
				<Section.Body>
					<Form.Column>
						<Row justify="spaced-start" overflow="wrap" align="spaced-start">
							<Field.CurrentGradeLevel formState={formState} />
							<Field.GradeLevelApplyingFor formState={formState} />
						</Row>
						<Row justify="spaced-start" overflow="wrap" align="spaced-start">
							<Field.HasPreviousSchooling formState={formState} />
							<FieldIf
								formState={formState}
								names={conditionalPreviousSchoolingCreateFields}
								condition={formState.formValues.hasPreviousSchooling}
							>
								<Row justify="spaced-start" overflow="wrap" align="spaced-start">
									<Field.PreviousSchoolInformation formState={formState} />
									<Field.PreviousSchoolLocation formState={formState} />
								</Row>
							</FieldIf>
						</Row>
					</Form.Column>
				</Section.Body>
			</Section>
		</React.Fragment>
	);
}

type BasicDetailFormValues = Pick<
	EnrollmentApplicationStudentFormValues.Detail,
	| "image"
	| "firstName"
	| "lastName"
	| "nickname"
	| "dateOfBirth"
	| "gender"
	| "genderCustom"
	| "nationality"
	| "primaryLanguage"
	| "englishLanguageFluency"
	| "currentGradeLevel"
	| "gradeLevelApplyingFor"
	| "hasPreviousSchooling"
	| "previousSchoolInformation"
	| "previousSchoolLocation"
>;

const conditionalPreviousSchoolingDetailFields: (keyof BasicDetailFormValues)[] = [
	"previousSchoolInformation",
	"previousSchoolLocation",
];

export type BasicDetailFieldsProps = {
	formState: FormState<BasicDetailFormValues>;
};

export function BasicDetailFields({ formState }: BasicDetailFieldsProps) {
	return (
		<React.Fragment>
			<Row justify="spaced-start" overflow="wrap" align="spaced-start">
				<Field.FirstName formState={formState} />
				<Field.LastName formState={formState} />
				<Field.Nickname formState={formState} />
			</Row>
			<Row justify="spaced-start" overflow="wrap" align="spaced-start">
				<Field.DateOfBirth formState={formState} />

				<Field.Gender formState={formState} />
				<FieldIf
					formState={formState}
					name="genderCustom"
					condition={formState.formValues.gender === EnrollmentApplicationStudentGender.Other}
				>
					<Field.GenderCustom formState={formState} />
				</FieldIf>
			</Row>
			<Row justify="spaced-start" overflow="wrap" align="spaced-start">
				<Field.Nationality formState={formState} />
				<Field.PrimaryLanguage formState={formState} />
				<Field.EnglishLanguageFluency formState={formState} />
			</Row>

			<Row justify="spaced-start" overflow="wrap" align="spaced-start">
				<Field.CurrentGradeLevel formState={formState} />
				<Field.GradeLevelApplyingFor formState={formState} />
			</Row>
			<Row justify="spaced-start" overflow="wrap" align="spaced-start">
				<Field.HasPreviousSchooling formState={formState} />
				<FieldIf
					formState={formState}
					names={conditionalPreviousSchoolingDetailFields}
					condition={formState.formValues.hasPreviousSchooling}
				>
					<Field.PreviousSchoolInformation formState={formState} />
					<Field.PreviousSchoolLocation formState={formState} />
				</FieldIf>
			</Row>
		</React.Fragment>
	);
}

export type PersonalityFieldsProps = {
	formState: FormState<EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreatePersonality>;
};

export function PersonalityFields({ formState }: PersonalityFieldsProps) {
	return (
		<React.Fragment>
			<Field.HasLearningDifficulties formState={formState} />
			<FieldIf
				formState={formState}
				name="learningDifficultiesDescription"
				condition={formState.formValues.hasLearningDifficulties}
			>
				<Field.LearningDifficultiesDescription formState={formState} label="Please explain" />
			</FieldIf>

			<Field.HasMedicalPhysicalExceptionalities formState={formState} />
			<FieldIf
				formState={formState}
				name="medicalPhysicalExceptionalitiesDescription"
				condition={formState.formValues.hasMedicalPhysicalExceptionalities}
			>
				<Field.MedicalPhysicalExceptionalitiesDescription formState={formState} label="Please explain" />
			</FieldIf>

			<Field.HasBehaviorDisciplineChallenges formState={formState} />
			<FieldIf
				formState={formState}
				name="behaviorDisciplineChallengesDescription"
				condition={formState.formValues.hasBehaviorDisciplineChallenges}
			>
				<Field.BehaviorDisciplineChallengesDescription formState={formState} label="Please explain" />
			</FieldIf>

			<Field.AdditionalInformation formState={formState} />
		</React.Fragment>
	);
}

export type InterviewFieldsProps = {
	formState: FormState<EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateInterview>;
	gradeLevelApplyingFor: EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreate["gradeLevelApplyingFor"];
};

export function InterviewFields({ formState, gradeLevelApplyingFor }: InterviewFieldsProps) {
	return (
		<React.Fragment>
			<Field.InterviewMeetingTime formState={formState} gradeLevelApplyingFor={gradeLevelApplyingFor} />
		</React.Fragment>
	);
}
