import { newPageInfo } from "@hex-insights/router";
import { documentResourceUserViewRecordHubPageInfo } from "../pageinfo";

export const documentResourceUserViewRecordHubIndexPageInfo = newPageInfo({
	name: "Document Resource User View Records Index",
	title: "",
	description: "Index of Document Resource User View Records.",
	exact: true,
	to: documentResourceUserViewRecordHubPageInfo.to,
	path: documentResourceUserViewRecordHubPageInfo.path,
	parent: documentResourceUserViewRecordHubPageInfo,
});
