import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { LoginRecordHubDetailPage } from "./DetailPage";
import { loginRecordHubDetailPageInfo } from "./DetailPage/pageinfo";
import { LoginRecordHubIndexPage } from "./IndexPage";
import { loginRecordHubIndexPageInfo } from "./IndexPage/pageinfo";
import { loginRecordHubPageInfo } from "./pageinfo";

export function LoginRecordHub() {
	useActivePageRegistration(loginRecordHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...loginRecordHubIndexPageInfo.routeProps}>
				<LoginRecordHubIndexPage />
			</Route>
			<Route {...loginRecordHubDetailPageInfo.routeProps}>
				<LoginRecordHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
