import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const schoolAttendanceRecordHubPageInfo = newPageInfo({
	title: "School Attendance Records",
	description: "Pages related to the School Attendance Record model.",
	exact: false,
	to: hubsPageInfo.to + "/school-attendance-records",
	path: hubsPageInfo.path + "/school-attendance-records",
	parent: hubsPageInfo,
});
