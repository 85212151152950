import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { TuitionHubCreatePage } from "./CreatePage";
import { tuitionHubCreatePageInfo } from "./CreatePage/pageinfo";
import { TuitionHubDetailPage } from "./DetailPage";
import { tuitionHubDetailPageInfo } from "./DetailPage/pageinfo";
import { TuitionHubIndexPage } from "./IndexPage";
import { tuitionHubIndexPageInfo } from "./IndexPage/pageinfo";
import { tuitionHubPageInfo } from "./pageinfo";

export function TuitionHub() {
	useActivePageRegistration(tuitionHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...tuitionHubIndexPageInfo.routeProps}>
				<TuitionHubIndexPage />
			</Route>
			<Route {...tuitionHubCreatePageInfo.routeProps}>
				<TuitionHubCreatePage />
			</Route>
			<Route {...tuitionHubDetailPageInfo.routeProps}>
				<TuitionHubDetailPage key={tuitionHubDetailPageInfo.useKey()} />
			</Route>
		</PageRouteSwitch>
	);
}
