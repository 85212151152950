import React from "react";
import { Cell, Tooltip as ChartTooltip, Pie, PieChart, ResponsiveContainer } from "recharts";
import { defaultPieProps } from "@hex-insights/charts";
import {
	addTimeToDate,
	Button,
	ClassNameProps,
	Column,
	Conditional,
	dateTrunc,
	Else,
	formatDateTime,
	formatPercentage,
	getObjectEntries,
	Grid,
	Heading,
	Icon,
	If,
	List,
	makeClassName,
	makeObjectFromEntries,
	Modal,
	ModalProps,
	pickKeys,
	Row,
	stringToLocalDate,
	StyleProps,
	toGrammaticalNumber,
	toLocalDateString,
	Tooltip,
} from "@hex-insights/core";
import { InternalLink } from "@hex-insights/router";
import {
	HomeRoomSectionOrderField,
	HR,
	LessonPlansOverviewQuery,
	OrderDirection,
	PersonIcon,
	Tile,
	useLessonPlansOverviewQuery,
	useWeekBounds,
} from "@hex-insights/verita.shared";
import { employeeHubDetailPageInfo } from "../../../Pages/Hubs/Employee/DetailPage/pageinfo";
import { homeRoomSectionHubDetailPageInfo } from "../../../Pages/Hubs/HomeRoomSection/DetailPage/pageinfo";
import styles from "./lesson-plans-tile.module.css";

const chartSize = "8rem";

export type LessonPlansTileProps = Partial<ClassNameProps & StyleProps>;

export function LessonPlansTile({ className, style }: LessonPlansTileProps) {
	const startDate = dateTrunc(new Date(), "week");
	const endDate = addTimeToDate(startDate, [6, "days"]);

	// TODO add dedicated API method for this
	const { data } = useLessonPlansOverviewQuery({
		variables: {
			startDate: toLocalDateString(startDate),
			endDate: toLocalDateString(endDate),
			order: { field: HomeRoomSectionOrderField.Name, direction: OrderDirection.Asc },
		},
	});
	const aggregatedData = React.useMemo(() => {
		const aggregatedData = {
			soFar: {
				done: 0,
				notDone: 0,
				total: 0,
			},
			future: {
				done: 0,
				notDone: 0,
				total: 0,
			},
		};
		if (!data) {
			return aggregatedData;
		}

		const currentDOW = new Date().getDay();

		for (let i = 0; i < data.homeRoomSectionConnection.edges.length; i++) {
			const homeRoomSection = data.homeRoomSectionConnection.edges[i].node;
			const agg = aggregateHomeRoomSectionLessonPlans(homeRoomSection);
			for (let j = 0; j < agg.lessonPlanDays.length; j++) {
				const day = agg.lessonPlanDays[j];
				if (day.dow <= currentDOW) {
					aggregatedData.soFar.done += day.stats.act;
					aggregatedData.soFar.notDone += day.stats.exp - day.stats.act;
					aggregatedData.soFar.total += day.stats.exp;
				} else {
					aggregatedData.future.done += day.stats.act;
					aggregatedData.future.notDone += day.stats.exp - day.stats.act;
					aggregatedData.future.total += day.stats.exp;
				}
			}
		}

		return aggregatedData;
	}, [data]);

	const pieData = React.useMemo(() => {
		return [
			{
				name: "Ready",
				number: aggregatedData.future.done,
			},
			{
				name: "Not Yet Done",
				number: aggregatedData.future.notDone,
			},
			{
				name: "Complete",
				number: aggregatedData.soFar.done,
			},
			{
				name: "Missing",
				number: aggregatedData.soFar.notDone,
			},
		];
	}, [aggregatedData]);

	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	const currentDOW = new Date().getDay();

	return (
		<Tile className={className} style={style}>
			<Tile.Body style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
				<Row justify="spaced-start">
					<Column>
						<Heading level={2} noMargin>
							Lesson Plans
						</Heading>

						<If condition={currentDOW >= 1}>
							<div>
								This week{currentDOW < 5 ? " so far" : ""}:{" "}
								<Tooltip.Container style={{ display: "inline" }}>
									<Tooltip>
										<Tooltip.Body style={{ width: "10rem" }}>
											{displayDaysSoFar()} of this week:
											<List>
												<List.Item>{aggregatedData.soFar.done} Complete</List.Item>
												<List.Item>{aggregatedData.soFar.notDone} Missing</List.Item>
											</List>
										</Tooltip.Body>
									</Tooltip>

									<span>
										{formatPercentage(aggregatedData.soFar.done / aggregatedData.soFar.total, {
											precision: 0,
										})}
									</span>
								</Tooltip.Container>
							</div>
						</If>

						<If condition={currentDOW < 5}>
							<div>
								Upcoming:{" "}
								<Tooltip.Container style={{ display: "inline" }}>
									<Tooltip>
										<Tooltip.Body style={{ width: "10rem" }}>
											{displayFutureDays()} of this week:
											<List>
												<List.Item>{aggregatedData.future.done} Ready</List.Item>
												<List.Item>{aggregatedData.future.notDone} Not Yet Done</List.Item>
											</List>
										</Tooltip.Body>
									</Tooltip>

									<span>
										{formatPercentage(aggregatedData.future.done / aggregatedData.future.total, {
											precision: 0,
										})}
									</span>
								</Tooltip.Container>
							</div>
						</If>

						<div style={{ marginTop: "auto" }}>
							<Button variant="link" size="small" onClick={toggleIsModalOpen}>
								View Details
							</Button>
						</div>
					</Column>

					<div style={{ width: chartSize, height: chartSize, marginLeft: "auto" }}>
						<LessonPlansPieChart data={pieData} />
					</div>
				</Row>

				<Modal.If condition={isModalOpen}>
					<LessonPlansOverviewModal onClose={toggleIsModalOpen} />
				</Modal.If>
			</Tile.Body>
		</Tile>
	);
}

function displayDaysSoFar() {
	return displayDayRanges([1, 2, 3, 4, 5].slice(0, new Date().getDay()));
}

function displayFutureDays() {
	return displayDayRanges([1, 2, 3, 4, 5].slice(new Date().getDay()));
}

const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

function displayDayRanges(dows: number[]) {
	if (dows.length === 1) {
		return dayNames[dows[0]];
	}
	if (dows.length === 2) {
		return `${dayNames[dows[0]]} and ${dayNames[dows[1]]}`;
	}
	if (dows.length > 2) {
		return `${dayNames[dows[0]]} to ${dayNames[dows[dows.length - 1]]}`;
	}
	return "";
}

const colors = {
	Ready: "#198504",
	Complete: "var(--success-color)",
	Missing: "var(--danger-color)",
	"Not Yet Done": "#eee",
};

type LessonPlansPieChartProps = {
	data: { name: string; number: number }[];
};

function LessonPlansPieChart({ data }: LessonPlansPieChartProps) {
	return (
		<ResponsiveContainer width="100%" height="100%">
			<PieChart>
				<ChartTooltip isAnimationActive={false} />

				<Pie {...defaultPieProps} data={data} nameKey="name" dataKey="number" label={false}>
					{data.map((e) => (
						<Cell key={e.name} fill={colors[e.name]} />
					))}
				</Pie>
			</PieChart>
		</ResponsiveContainer>
	);
}

export type LessonPlansOverviewModalProps = Pick<ModalProps, "ifRef" | "onClose">;

export function LessonPlansOverviewModal({ ifRef, onClose }: LessonPlansOverviewModalProps) {
	const { startDate, endDate, isCurrentWeek, onPrevWeekClick, onCurrentWeekClick, onNextWeekClick } = useWeekBounds();

	const { loading, data } = useLessonPlansOverviewQuery({
		variables: {
			startDate: toLocalDateString(startDate),
			endDate: toLocalDateString(endDate),
			order: { field: HomeRoomSectionOrderField.Name, direction: OrderDirection.Asc },
		},
	});

	const aggregatedData = React.useMemo(() => {
		if (!data) {
			return [];
		}
		return data.homeRoomSectionConnection.edges.map((e) => aggregateHomeRoomSectionLessonPlans(e.node));
	}, [data]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} style={{ width: "80vw", maxWidth: "none", height: "90vh" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					Lesson Plans: Week of {formatDateTime(addTimeToDate(startDate, [1, "day"]), "D MMMM")}
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<Row justify="spaced-center" style={{ marginBottom: "1rem" }}>
					<Button variant="link" size="small" onClick={onPrevWeekClick}>
						<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
							<Icon.ChevronLeft size="1rem" style={{ display: "block" }} /> Previous Week
						</Row>
					</Button>
					<If condition={!isCurrentWeek}>
						<Button variant="link" size="small" onClick={onCurrentWeekClick}>
							To Current Week
						</Button>
					</If>
					<Button variant="link" size="small" onClick={onNextWeekClick}>
						<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
							Next Week <Icon.ChevronRight size="1rem" style={{ display: "block" }} />
						</Row>
					</Button>
				</Row>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Grid columns={2} gap="1.5rem">
							{aggregatedData.map((e) => (
								<Column justify="spaced-start" key={e.homeRoomSection.id} style={{ width: "100%", minWidth: 0 }}>
									<Row justify="space-between" align="flex-end" style={{ borderBottom: "1px solid #ddd" }}>
										<InternalLink
											to={homeRoomSectionHubDetailPageInfo.to(e.homeRoomSection.id)}
											className={makeClassName(styles["home-room-section-link"], "link--no-text-decoration")}
										>
											{e.homeRoomSection.name}
										</InternalLink>

										<span>
											{e.summary.act} of {e.summary.exp}
										</span>
									</Row>

									<Row justify="spaced-start" align="center" style={{ width: "100%" }}>
										<If condition={e.homeRoomSection.homeRoomSectionTeacherEnrollments.length > 0}>
											<Row justify="spaced-start" horizontalSpacing="0.25rem">
												{e.homeRoomSection.homeRoomSectionTeacherEnrollments.map(({ teacher }) => (
													<InternalLink
														key={teacher.id}
														to={employeeHubDetailPageInfo.to(teacher.id)}
														title={teacher.person.name}
														className="link--no-text-decoration"
													>
														<PersonIcon person={teacher.person} imageSize="1.5rem" />
													</InternalLink>
												))}
											</Row>
										</If>

										<If
											condition={
												e.homeRoomSection.homeRoomSectionTeacherEnrollments.length > 0 && e.lessonPlanDays.length > 0
											}
										>
											<HR color="#ddd" style={{ height: "1rem" }} />
										</If>

										<Row justify="spaced-start" horizontalSpacing="0.5rem">
											{e.lessonPlanDays.map(({ dow, dateText, stats }) => {
												return (
													<Tooltip.Container key={dow}>
														<Tooltip>
															<Tooltip.Header>
																<strong>{dateText}</strong>
															</Tooltip.Header>
															<Tooltip.Body style={{ width: "8rem" }}>
																{stats.act} of {toGrammaticalNumber("lesson plan", stats.exp, true)} completed
															</Tooltip.Body>
														</Tooltip>

														<HomeRoomSectionLessonPlanDayIcon dow={dow} exp={stats.exp} act={stats.act} />
													</Tooltip.Container>
												);
											})}
										</Row>
									</Row>
								</Column>
							))}
						</Grid>
					</Else>
				</Conditional>
			</Modal.Body>
		</Modal>
	);
}

const dayLetters = ["S", "M", "T", "W", "T", "F", "S"];

type HomeRoomSectionLessonPlanDayIconProps = {
	dow: number;
	exp: number;
	act: number;
};

function HomeRoomSectionLessonPlanDayIcon({ dow, exp, act }: HomeRoomSectionLessonPlanDayIconProps) {
	let statusClassName = "";
	if (exp > 0) {
		if (act === exp) {
			statusClassName = styles["lesson-plan-day-icon--all"];
		} else if (act > 0) {
			statusClassName = styles["lesson-plan-day-icon--partial"];
		}
	}

	return (
		<div className={makeClassName(styles["lesson-plan-day-icon"], statusClassName)}>
			<div className={styles["lesson-plan-day-icon__text"]}>{dayLetters[dow]}</div>
		</div>
	);
}

function aggregateHomeRoomSectionLessonPlans(
	homeRoomSection: LessonPlansOverviewQuery["homeRoomSectionConnection"]["edges"][0]["node"],
) {
	const summary = {
		act: 0,
		exp: 0,
	};
	const lessonPlanDays = makeObjectFromEntries([
		[1, { act: 0, exp: 0 }],
		[2, { act: 0, exp: 0 }],
		[3, { act: 0, exp: 0 }],
		[4, { act: 0, exp: 0 }],
		[5, { act: 0, exp: 0 }],
	]);

	for (let i = 0; i < homeRoomSection.courseSections.length; i++) {
		const courseSection = homeRoomSection.courseSections[i];
		for (let j = 0; j < courseSection.courseSectionPeriods.length; j++) {
			const period = courseSection.courseSectionPeriods[j];
			if (!lessonPlanDays[period.dayOfWeek]) {
				continue;
			}
			lessonPlanDays[period.dayOfWeek].exp++;
			summary.exp++;
		}

		for (let j = 0; j < courseSection.lessonPlans.length; j++) {
			const lessonPlan = courseSection.lessonPlans[j];
			const dow = stringToLocalDate(lessonPlan.courseDate, "day").getDay();
			if (!lessonPlanDays[dow]) {
				continue;
			}
			lessonPlanDays[dow].act++;
			summary.act++;
		}
	}

	const sunday = dateTrunc(new Date(), "week");

	const lessonPlanDaysList = getObjectEntries(lessonPlanDays)
		.map(([dow, stats]) => ({
			dow,
			dateText: formatDateTime(addTimeToDate(sunday, [dow, "days"]), "dddd, D MMM"),
			stats,
		}))
		.filter((e) => e.stats.exp > 0);

	return {
		homeRoomSection: pickKeys(homeRoomSection, ["id", "name", "homeRoomSectionTeacherEnrollments"]),
		summary,
		lessonPlanDays: lessonPlanDaysList,
	};
}
