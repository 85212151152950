import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const classYearHubPageInfo = newPageInfo({
	title: "Class Years",
	description: "Pages related to the Class Year model.",
	exact: false,
	to: hubsPageInfo.to + "/class-years",
	path: hubsPageInfo.path + "/class-years",
	parent: hubsPageInfo,
});
