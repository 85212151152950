import { newPageInfo } from "@hex-insights/router";
import { noteHubPageInfo } from "../pageinfo";

export const noteHubCreatePageInfo = newPageInfo({
	name: "New Note",
	title: "New",
	description: "Create a new Note record.",
	exact: true,
	to: noteHubPageInfo.to + "/new",
	path: noteHubPageInfo.path + "/new",
	parent: noteHubPageInfo,
});
