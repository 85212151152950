import React from "react";
import { FieldDisplayArgs, NumberField, RadioField, SelectField } from "@hex-insights/forms";
import {
	CourseSectionPeriodFormat,
	CourseSectionPeriodFormValues,
	CourseSectionSelect,
	RoomSelect,
	useCourseSectionSelectLazyQuery,
	useRoomSelectLazyQuery,
} from "../../../../Utilities";
import { CourseSectionLink, RoomLink } from "../../../Links";
import { TimeInput } from "../../../TimeInput";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the CourseSectionPeriod model.
 */
type FieldProps<K extends keyof CourseSectionPeriodFormValues.Base = keyof CourseSectionPeriodFormValues.Base> =
	BaseFieldProps<Pick<CourseSectionPeriodFormValues.Base, K>>;

/**
 * Renders a field component for the `dayOfWeek` field of the CourseSectionPeriod model.
 */
export function DayOfWeek({ formState }: FieldProps<"dayOfWeek">) {
	return (
		<RadioField
			formState={formState}
			name="dayOfWeek"
			options={CourseSectionPeriodFormValues.dayOfWeekOptions}
			blankValue={null}
		/>
	);
}

/**
 * Renders a field component for the `startTimeMinutes` field of the CourseSectionPeriod model.
 */
export function StartTimeMinutes({ formState }: FieldProps<"startTimeMinutes">) {
	return (
		<NumberField
			formState={formState}
			name="startTimeMinutes"
			label="Start Time"
			validationUnit={1}
			min={0}
			max={1439}
			Input={TimeInput}
			format={CourseSectionPeriodFormat.Fields.startTimeMinutes}
		/>
	);
}

/**
 * Renders a field component for the `endTimeMinutes` field of the CourseSectionPeriod model.
 */
export function EndTimeMinutes({ formState }: FieldProps<"endTimeMinutes">) {
	return (
		<NumberField
			formState={formState}
			name="endTimeMinutes"
			label="End Time"
			validationUnit={1}
			min={0}
			max={1439}
			Input={TimeInput}
			format={CourseSectionPeriodFormat.Fields.endTimeMinutes}
		/>
	);
}

export type CourseSectionProps = FieldProps<"courseSectionID"> & {
	currentCourseSection?: CourseSectionSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `courseSection` edge of the CourseSectionPeriod model.
 */
export function CourseSection({ formState, currentCourseSection }: CourseSectionProps) {
	const [loadOptions, { loading, data }] = useCourseSectionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.courseSectionID) {
			loadOptions();
		}
	}, [formState.formEditing.courseSectionID, loadOptions]);
	const options = React.useMemo(
		() => CourseSectionSelect.toOptions(data?.courseSectionConnection.edges, currentCourseSection),
		[data, currentCourseSection],
	);

	return (
		<SelectField
			formState={formState}
			name="courseSectionID"
			isLoading={loading}
			options={options}
			display={displayCourseSection}
			blankValue={null}
		/>
	);
}

function displayCourseSection({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <CourseSectionLink instance={{ id }}>{formattedValue}</CourseSectionLink>;
}

export type RoomProps = FieldProps<"roomID"> & {
	currentRoom?: RoomSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `room` edge of the CourseSectionPeriod model.
 */
export function Room({ formState, currentRoom }: RoomProps) {
	const [loadOptions, { loading, data }] = useRoomSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.roomID) {
			loadOptions();
		}
	}, [formState.formEditing.roomID, loadOptions]);
	const options = React.useMemo(
		() => RoomSelect.toOptions(data?.roomConnection.edges, currentRoom),
		[data, currentRoom],
	);

	return (
		<SelectField
			formState={formState}
			name="roomID"
			isLoading={loading}
			options={options}
			display={displayRoom}
			blankValue={null}
		/>
	);
}

function displayRoom({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <RoomLink instance={{ id }}>{formattedValue}</RoomLink>;
}
