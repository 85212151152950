import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { UserGroupFilterInput } from "../../GraphQL";
import { UserGroupFilterFormValues } from "../ModelFilterFormValues";

/**
 * Counts the number of active filters for the UserGroup model.
 *
 * @param formValues The form values of the filter form.
 */
export function countActiveFilters(formValues: UserGroupFilterFormValues.FormValues) {
	// TODO count quick filters
	const numAdvancedFilters = IndexForms.countActiveAdvancedFilters(
		formValues,
		UserGroupFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	);
	return numAdvancedFilters;
}

/**
 * Clears the values of the filter form for the UserGroup model.
 *
 * @param formState The form state of the filter form.
 */
export function clearFilters(formState: FormState<UserGroupFilterFormValues.FormValues>) {
	// TODO clear quick filters
	formState.formClearFunctions.filterSets();
}

/**
 * Resets the values of the filter form for the UserGroup model.
 *
 * @param formState The form state of the filter form.
 */
export function resetFilters(formState: FormState<UserGroupFilterFormValues.FormValues>) {
	// TODO reset quick filters
	formState.formResetFunctions.filterSets();
}

/**
 * Converts the form values of the filter form for the UserGroup model to the correct GraphQL
 * filter input type.
 *
 * @param formValues The form values of the filter form.
 */
export function toFilterInputs(
	formValues: UserGroupFilterFormValues.FormValues,
): UserGroupFilterInput | UserGroupFilterInput[] {
	return IndexForms.toFilterInput(
		formValues,
		UserGroupFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	) as UserGroupFilterInput;
}
