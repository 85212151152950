import React from "react";
import { Conditional, Else, Grid, Heading, If, Row, Section, toLocalDateString, useSync } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	CampusForm,
	CampusFormValues,
	CampusMutation,
	PersonIcon,
	ScaledText,
	Tile,
	useCampusDetailQuery,
	useEmployeeIndexQuery,
	useHomeRoomIndexQuery,
	useStudentListQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { employeeHubDetailPageInfo } from "../../Employee/DetailPage/pageinfo";
import { homeRoomHubDetailPageInfo } from "../../HomeRoom/DetailPage/pageinfo";
import { studentHubDetailPageInfo } from "../../Student/DetailPage/pageinfo";
import { campusHubDetailPageInfo, CampusHubDetailPageRouteParams } from "./pageinfo";

function CampusHubDetailPageWrapper() {
	// Manages identity of the CampusHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <CampusHubDetailPage key={id} />;
}

export { CampusHubDetailPageWrapper as CampusHubDetailPage };

function CampusHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useCampusDetailQuery({ variables: { id } });
	const campus = data?.campus;
	useActivePageRegistration(campusHubDetailPageInfo, !!campus ? campusHubDetailPageInfo.title(campus) : "");

	const update = CampusMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<CampusFormValues.Detail>, initialFormValues: CampusFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading campus</If>
				<Else>
					{!!campus && (
						<React.Fragment>
							<Heading level={1}>{campus.name}</Heading>

							<Row justify="spaced-start">
								<Tile>
									<Tile.Body>
										<CampusForm.ControlledDetail
											key={syncID}
											campus={campus}
											applyUpdate={applyUpdate}
											onSuccess={synchronize}
										/>
									</Tile.Body>
								</Tile>

								<Tile style={{ flexGrow: 1, width: "100%", overflow: "hidden" }}>
									<Tile.Body>
										<EmployeesSection />
										<StudentsSection />
										<HomeRoomsSection />
									</Tile.Body>
								</Tile>
							</Row>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}

function EmployeesSection() {
	const { id } = useRouteParams<CampusHubDetailPageRouteParams>();
	const { loading, data } = useEmployeeIndexQuery({
		variables: {
			filters: {
				campuses: [{ idEQ: id }],
				endDateIsNull: true,
			},
		},
	});

	return (
		<Section>
			<Section.Header>
				<Heading level={3} noMargin>
					Teachers &amp; Staff
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Row justify="spaced-start" overflow="scroll" style={{ width: "100%" }}>
							{data?.employeeConnection.edges.map((e) => (
								<InternalLink
									key={e.node.id}
									to={employeeHubDetailPageInfo.to(e.node.id)}
									className="link--no-text-decoration"
								>
									<PersonIcon person={e.node.person} />
								</InternalLink>
							))}
						</Row>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}

function StudentsSection() {
	const { id } = useRouteParams<CampusHubDetailPageRouteParams>();

	const today = toLocalDateString(new Date());

	// TODO unite with campus overviews
	const { loading, data } = useStudentListQuery({
		variables: {
			filters: [
				{
					homeRoomSectionStudentEnrollments: [
						{
							homeRoomSection: [
								{
									room: [{ campusIDEQ: id }],
									term: [{ startDateLTE: today, endDateGTE: today }],
								},
							],
						},
					],
				},
				{
					courseSectionStudentEnrollments: [
						{
							courseSection: [
								{
									courseSectionPeriods: [
										{
											room: [{ campusIDEQ: id }],
										},
									],
									term: [{ startDateLTE: today, endDateGTE: today }],
								},
							],
						},
					],
				},
			],
		},
	});

	return (
		<Section>
			<Section.Header>
				<Heading level={3} noMargin>
					Students
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Row justify="spaced-start" overflow="scroll" style={{ width: "100%" }}>
							{data?.studentConnection.edges.map((e) => (
								<InternalLink
									key={e.node.id}
									to={studentHubDetailPageInfo.to(e.node.id)}
									className="link--no-text-decoration"
								>
									<PersonIcon person={e.node.person} />
								</InternalLink>
							))}
						</Row>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}

function HomeRoomsSection() {
	const { id } = useRouteParams<CampusHubDetailPageRouteParams>();

	const { loading, data } = useHomeRoomIndexQuery({
		variables: {
			filters: {
				homeRoomSections: [
					{
						room: [{ campusIDEQ: id }],
					},
				],
			},
		},
	});

	return (
		<Section>
			<Section.Header>
				<Heading level={3} noMargin>
					Home Rooms
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Grid columns={4} gap="1rem">
							{data?.homeRoomConnection.edges.map((e) => (
								<InternalLink
									key={e.node.id}
									to={homeRoomHubDetailPageInfo.to(e.node.id)}
									className="link--no-text-decoration"
									style={{
										width: "100%",
										height: "5rem",
										padding: "0.25rem",
										border: "2px solid var(--light-blue)",
										borderRadius: "0.5rem",
										display: "block",
									}}
								>
									<Row justify="center" align="center" style={{ width: "100%", height: "100%" }}>
										<ScaledText maxFontSize={24} style={{ textAlign: "center", maxWidth: "100%", maxHeight: "100%" }}>
											{e.node.name}
										</ScaledText>
									</Row>
								</InternalLink>
							))}
						</Grid>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}
