import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	EnrollmentApplicationParent,
	EnrollmentApplicationParentCreateInput,
	EnrollmentApplicationParentCreateMutation,
	EnrollmentApplicationParentDeleteMutation,
	EnrollmentApplicationParentDetailDocument,
	EnrollmentApplicationParentDetailQuery,
	EnrollmentApplicationParentDetailQueryVariables,
	EnrollmentApplicationParentUpdateInput,
	EnrollmentApplicationParentUpdateMutation,
	EnrollmentApplicationSubmission,
	graphTypeNames,
	useEnrollmentApplicationParentCreateMutation,
	useEnrollmentApplicationParentDeleteMutation,
	useEnrollmentApplicationParentUpdateMutation,
} from "../../GraphQL";
import { EnrollmentApplicationParentFormConversion } from "../ModelFormConversion";
import { EnrollmentApplicationParentFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the EnrollmentApplicationParent model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the EnrollmentApplicationParent model.
 */
export function useCreate() {
	const [createEnrollmentApplicationParent] = useEnrollmentApplicationParentCreateMutation();

	return React.useCallback(
		async (formValues: EnrollmentApplicationParentFormValues.Create) => {
			const input = EnrollmentApplicationParentFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createEnrollmentApplicationParent({
				variables: { input },
				update: updateCache,
			});

			return { data: data?.createEnrollmentApplicationParent ?? null, errors: errors ?? null };
		},
		[createEnrollmentApplicationParent],
	);
}

/**
 * Returns an `update` function for the EnrollmentApplicationParent model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the EnrollmentApplicationParent model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: EnrollmentApplicationParent["id"]) {
	const [updateEnrollmentApplicationParent] = useEnrollmentApplicationParentUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<EnrollmentApplicationParentFormValues.Detail>,
			initialFormValues: EnrollmentApplicationParentFormValues.Detail,
		) => {
			const input = EnrollmentApplicationParentFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateEnrollmentApplicationParent({
				variables: { id, input },
				update: updateCache,
			});

			return { data: data?.updateEnrollmentApplicationParent ?? null, errors: errors ?? null };
		},
		[updateEnrollmentApplicationParent, id],
	);
}

/**
 * Returns a `del` function for the EnrollmentApplicationParent model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: EnrollmentApplicationParent["id"]) {
	const [deleteEnrollmentApplicationParent] = useEnrollmentApplicationParentDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteEnrollmentApplicationParent({ variables: { id }, update: updateCache });

		return { data: data?.deleteEnrollmentApplicationParent ?? false, errors: errors ?? null };
	}, [deleteEnrollmentApplicationParent, id]);
}

function getUpdateCacheForCreate(input: EnrollmentApplicationParentCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<EnrollmentApplicationParentCreateMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createEnrollmentApplicationParent;

		cache.writeQuery<EnrollmentApplicationParentDetailQuery, EnrollmentApplicationParentDetailQueryVariables>({
			query: EnrollmentApplicationParentDetailDocument,
			data: { enrollmentApplicationParent: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.enrollmentApplicationSubmissionID) {
			addToEnrollmentApplicationParentsOfEnrollmentApplicationSubmissionCache(
				cache,
				input.enrollmentApplicationSubmissionID,
				createdObject,
			);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "enrollmentApplicationParentConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: EnrollmentApplicationParentUpdateInput,
	initialFormValues: EnrollmentApplicationParentFormValues.Detail,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<EnrollmentApplicationParentUpdateMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateEnrollmentApplicationParent;

		if (initialFormValues.enrollmentApplicationSubmissionID && input.enrollmentApplicationSubmissionID) {
			removeFromEnrollmentApplicationParentsOfEnrollmentApplicationSubmissionCache(
				cache,
				initialFormValues.enrollmentApplicationSubmissionID,
				updatedObject,
			);
		}
		if (input.enrollmentApplicationSubmissionID) {
			addToEnrollmentApplicationParentsOfEnrollmentApplicationSubmissionCache(
				cache,
				input.enrollmentApplicationSubmissionID,
				updatedObject,
			);
		}
	};
}

function getUpdateCacheForDelete(id: EnrollmentApplicationParent["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<EnrollmentApplicationParentDeleteMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (!result.data?.deleteEnrollmentApplicationParent) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.EnrollmentApplicationParent }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "enrollmentApplicationParentConnection" });
		cache.gc();
	};
}

function addToEnrollmentApplicationParentsOfEnrollmentApplicationSubmissionCache(
	cache: ApolloCache<any>,
	targetID: EnrollmentApplicationSubmission["id"],
	object: Pick<EnrollmentApplicationParent, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.EnrollmentApplicationSubmission }),
		fields: {
			enrollmentApplicationParents: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromEnrollmentApplicationParentsOfEnrollmentApplicationSubmissionCache(
	cache: ApolloCache<any>,
	targetID: EnrollmentApplicationSubmission["id"],
	object: Pick<EnrollmentApplicationParent, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.EnrollmentApplicationSubmission }),
		fields: {
			enrollmentApplicationParents: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<EnrollmentApplicationParent, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment EnrollmentApplicationParentRef on EnrollmentApplicationParent {
				id
			}
		`,
		data: object,
	});
}
