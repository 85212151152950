export function TermSetupDetailHomeRoomSectionsDetailPage() {
	return <div>TODO</div>;
}

// import React from "react";
// import {
//     addTimeToDate,
//     Button,
//     ChildrenProps,
//     Column,
//     dateTrunc,
//     Heading,
//     KeyWithValueType,
//     Row,
// } from "@hex-insights/core";
// import {
//     BooleanField,
//     BooleanFieldInputProps,
//     SubFormField,
//     SubFormRenderProps,
//     Form,
//     FormState,
//     FormSubmitFunction,
//     FormType,
//     Label,
//     NumberField,
//     SelectField,
//     SubmissionStatus,
//     SubmitButton,
//     useFormState,
// } from "@hex-insights/forms";
// import { useActivePageRegistration, useHistory, useRouteParams } from "@hex-insights/router";
// import {
//     CalendarItem,
//     Course,
//     CourseSectionFormValues,
//     CourseSelect,
//     courseSubjectColors,
//     HomeRoom,
//     HomeRoomSection,
//     HomeRoomSectionOrderField,
//     minutesToTime,
//     onCreateBulkSubmit,
//     OrderDirection,
//     Tile,
//     TimeInput,
//     useCourseIndexLazyQuery,
//     useCourseSectionsBulkCreate,
//     useCourseSelectQuery,
//     useHomeRoomSectionDetailQuery,
//     useHomeRoomSectionIndexLazyQuery,
//     WeeklyCourseScheduleCalendar,
// } from "@hex-insights/verita.shared";
// import { Main } from "../../../../../Components";
// import { termSetupDetailOverviewPageInfo } from "../../OverviewPage/pageinfo";
// import { termSetupDetailHomeRoomSectionsDetailPageInfo } from "./pageinfo";
// import generalStyles from "../../general.module.css";
// import "./styles.css";
// import styles from "./styles.module.css";

// type CourseSectionScheduleFormValues = {
//     courseID: Course["id"] | null;
//     onMonday: boolean;
//     onTuesday: boolean;
//     onWednesday: boolean;
//     onThursday: boolean;
//     onFriday: boolean;
//     startTime: number | null;
//     endTime: number | null;
// };

// const blankCourseSectionScheduleFormValues: CourseSectionScheduleFormValues = {
//     courseID: null,
//     onMonday: true,
//     onTuesday: true,
//     onWednesday: true,
//     onThursday: true,
//     onFriday: true,
//     startTime: null,
//     endTime: null,
// };

// type HomeRoomScheduleFormValues = {
//     courseSections: CourseSectionScheduleFormValues[];
// };

// const initialHomeRoomScheduleFormValues: HomeRoomScheduleFormValues = {
//     courseSections: [blankCourseSectionScheduleFormValues],
// };

// function TermSetupDetailHomeRoomSectionsDetailPageWrapper() {
//     const { homeRoomSectionID } = useRouteParams<{ homeRoomSectionID: string }>();

//     return <TermSetupDetailHomeRoomSectionsDetailPage key={homeRoomSectionID} />;
// }

// export { TermSetupDetailHomeRoomSectionsDetailPageWrapper as TermSetupDetailHomeRoomSectionsDetailPage };

// function TermSetupDetailHomeRoomSectionsDetailPage() {
//     const { termID, homeRoomSectionID } = useRouteParams<{ termID: string; homeRoomSectionID: string }>();

//     const { data: homeRoomSectionData } = useHomeRoomSectionDetailQuery({ variables: { id: homeRoomSectionID } });
//     const homeRoomSection = homeRoomSectionData?.homeRoomSection;

//     const [loadCourses, { data: coursesData }] = useCourseIndexLazyQuery({
//         variables: { filters: { homeRoomIDEQ: homeRoomSection?.homeRoom.id } },
//     });
//     React.useEffect(() => {
//         if (homeRoomSection) {
//             loadCourses();
//         }
//     }, [homeRoomSection, loadCourses]);
//     const courseEdges = coursesData?.courseConnection.edges;

//     useActivePageRegistration(
//         termSetupDetailHomeRoomSectionsDetailPageInfo,
//         !!homeRoomSection ? termSetupDetailHomeRoomSectionsDetailPageInfo.title(homeRoomSection) : "",
//     );

//     const formState = useFormState({ initialFormValues: initialHomeRoomScheduleFormValues, formType: FormType.Create });

//     const create = useCourseSectionsBulkCreate();
//     const applyCreate = React.useCallback(
//         async (formValuesSets: CourseSectionFormValues.Create[]) => {
//             const { errors } = await create(formValuesSets);
//             return errors;
//         },
//         [create],
//     );

//     const onSubmit = React.useCallback<FormSubmitFunction<HomeRoomScheduleFormValues>>(
//         async (formState) => {
//             const formValuesSets: CourseSectionFormValues.CreateWithNestedPeriods[] =
//                 formState.formValues.courseSections.map((fv) => {
//                     const course = (courseEdges ?? []).find((e) => e.node.id === fv.courseID)?.node ?? {
//                         name: "",
//                         description: "",
//                     };
//                     return {
//                         courseID: fv.courseID,
//                         courseSectionPeriods: courseSectionPeriodFormValuesSetsFromFormValues(
//                             fv,
//                             homeRoomSection?.room.id ?? null,
//                         ),
//                         courseSectionPeriodIDs: [],
//                         name: course.name,
//                         description: course.description,
//                         studentIDs: homeRoomSection?.students.map((e) => e.id) ?? [],
//                         teacherIDs: homeRoomSection?.teachers.map((e) => e.id) ?? [],
//                         homeRoomSectionID,
//                         termID,
//                     };
//                 });
//             return onCreateBulkSubmit(formValuesSets, applyCreate);
//         },
//         [applyCreate, courseEdges, homeRoomSectionID, homeRoomSection, termID],
//     );

//     const [loadHomeRoomSections, { data: homeRoomSectionsData }] = useHomeRoomSectionIndexLazyQuery({
//         variables: {
//             filters: { homeRoomIDEQ: homeRoomSection?.homeRoom.id, termIDEQ: termID },
//             order: { field: HomeRoomSectionOrderField.Name, direction: OrderDirection.Asc },
//         },
//     });
//     React.useEffect(() => {
//         if (homeRoomSection) {
//             loadHomeRoomSections();
//         }
//     }, [homeRoomSection, loadHomeRoomSections]);

//     const { push: pushHistory } = useHistory();
//     React.useEffect(() => {
//         if (SubmissionStatus.isSuccess(formState.submissionStatus) && homeRoomSectionsData) {
//             let foundSection = false;
//             let nextSectionID: HomeRoomSection["id"] | null = null;
//             const homeRoomSectionEdges = homeRoomSectionsData.homeRoomSectionConnection.edges;
//             for (let i = 0; i < homeRoomSectionEdges.length; i++) {
//                 const node = homeRoomSectionEdges[i].node;
//                 if (node.id === homeRoomSectionID) {
//                     foundSection = true;
//                     continue;
//                 }
//                 if (foundSection) {
//                     nextSectionID = node.id;
//                     break;
//                 }
//             }
//             if (nextSectionID !== null) {
//                 pushHistory(termSetupDetailHomeRoomSectionsDetailPageInfo.to(termID, nextSectionID));
//             } else {
//                 pushHistory(termSetupDetailOverviewPageInfo.to(termID));
//             }
//         }
//     }, [formState.submissionStatus, homeRoomSectionsData, homeRoomSectionID, termID, pushHistory]);

//     if (!homeRoomSection) {
//         return <Main>Loading...</Main>;
//     }

//     return (
//         <Main>
//             <Row justify="spaced-start" className={generalStyles["main-row"]}>
//                 <Tile className={generalStyles["controller-tile"]}>
//                     <Tile.Header>
//                         <Heading level={1} noMargin style={{ marginBottom: "0.5rem" }}>
//                             {homeRoomSection.name}
//                         </Heading>
//                         <Heading level={2} noMargin>
//                             Schedule
//                         </Heading>
//                     </Tile.Header>
//                     <Tile.Body>
//                         <Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
//                             <Column justify="spaced-start">
//                                 <SubFormField
//                                     formState={formState}
//                                     name="courseSections"
//                                     blankItem={blankCourseSectionScheduleFormValues}
//                                     dividerElement={<hr style={{ margin: "0.75rem auto", width: "60%" }} />}
//                                 >
//                                     {(props) => (
//                                         <CourseSectionScheduleFields
//                                             {...props}
//                                             homeRoomID={homeRoomSection.homeRoom.id}
//                                         />
//                                     )}
//                                     {({ onClick, disabled }) => (
//                                         <Row justify="center">
//                                             <Button variant="secondary" onClick={onClick} disabled={disabled}>
//                                                 Add Course
//                                             </Button>
//                                         </Row>
//                                     )}
//                                 </SubFormField>

//                                 <Row justify="flex-end">
//                                     <SubmitButton
//                                         onClick={formState.onSubmitWrapper(onSubmit)}
//                                         submissionStatus={formState.submissionStatus}
//                                     >
//                                         Save and Continue
//                                     </SubmitButton>
//                                 </Row>
//                             </Column>
//                         </Form>
//                     </Tile.Body>
//                 </Tile>

//                 <Tile className={generalStyles["preview-tile--full"]} style={{ flexGrow: 1 }}>
//                     <Tile.Header>
//                         <Heading level={2} noMargin>
//                             Schedule
//                         </Heading>
//                     </Tile.Header>
//                     <Tile.Body>
//                         <ScheduleCalendarView
//                             homeRoomID={homeRoomSection.homeRoom.id}
//                             courseSections={formState.formValues.courseSections}
//                         />
//                     </Tile.Body>
//                 </Tile>
//             </Row>
//         </Main>
//     );
// }

// function courseSectionPeriodFormValuesSetsFromFormValues(
//     formValues: CourseSectionScheduleFormValues,
//     roomID: string | null,
// ) {
//     const periodFormValuesSets: CourseSectionFormValues.CreateWithNestedPeriods["courseSectionPeriods"] = [];
//     if (formValues.onMonday) {
//         periodFormValuesSets.push({
//             roomID,
//             dayOfWeek: 1,
//             startTimeMinutes: formValues.startTime,
//             endTimeMinutes: formValues.endTime,
//         });
//     }
//     if (formValues.onTuesday) {
//         periodFormValuesSets.push({
//             roomID,
//             dayOfWeek: 2,
//             startTimeMinutes: formValues.startTime,
//             endTimeMinutes: formValues.endTime,
//         });
//     }
//     if (formValues.onWednesday) {
//         periodFormValuesSets.push({
//             roomID,
//             dayOfWeek: 3,
//             startTimeMinutes: formValues.startTime,
//             endTimeMinutes: formValues.endTime,
//         });
//     }
//     if (formValues.onThursday) {
//         periodFormValuesSets.push({
//             roomID,
//             dayOfWeek: 4,
//             startTimeMinutes: formValues.startTime,
//             endTimeMinutes: formValues.endTime,
//         });
//     }
//     if (formValues.onFriday) {
//         periodFormValuesSets.push({
//             roomID,
//             dayOfWeek: 5,
//             startTimeMinutes: formValues.startTime,
//             endTimeMinutes: formValues.endTime,
//         });
//     }
//     return periodFormValuesSets;
// }

// function CourseSectionScheduleFields({
//     formState,
//     onRemoveClick,
//     homeRoomID,
// }: SubFormRenderProps<CourseSectionScheduleFormValues> & { homeRoomID: HomeRoom["id"] }) {
//     return (
//         <Column justify="spaced-start" style={{ marginBottom: "1rem" }}>
//             <CourseSelectField formState={formState} homeRoomID={homeRoomID} />

//             <Column justify="spaced-start">
//                 <Label>Days of Week</Label>

//                 <Row justify="space-between">
//                     <DayOfWeekField formState={formState} name="onMonday">
//                         M
//                     </DayOfWeekField>
//                     <DayOfWeekField formState={formState} name="onTuesday">
//                         T
//                     </DayOfWeekField>
//                     <DayOfWeekField formState={formState} name="onWednesday">
//                         W
//                     </DayOfWeekField>
//                     <DayOfWeekField formState={formState} name="onThursday">
//                         T
//                     </DayOfWeekField>
//                     <DayOfWeekField formState={formState} name="onFriday">
//                         F
//                     </DayOfWeekField>
//                 </Row>
//             </Column>

//             <NumberField formState={formState} name="startTime" Input={TimeInput} />
//             <NumberField formState={formState} name="endTime" Input={TimeInput} />

//             <Row justify="center">
//                 <Button variant="tertiary" size="small" onClick={onRemoveClick} disabled={formState.disabled}>
//                     Remove Course
//                 </Button>
//             </Row>
//         </Column>
//     );
// }

// type CourseSelectFieldProps = {
//     formState: FormState<Pick<CourseSectionScheduleFormValues, "courseID">>;
//     homeRoomID: HomeRoom["id"];
// };

// function CourseSelectField({ formState, homeRoomID }: CourseSelectFieldProps) {
//     const { loading, data } = useCourseSelectQuery({
//         variables: { filters: { homeRoomIDEQ: homeRoomID } },
//     });
//     const options = React.useMemo(() => CourseSelect.toOptions(data?.courseConnection.edges), [data]);

//     return (
//         <SelectField formState={formState} name="courseID" isLoading={loading} options={options} blankValue={null} />
//     );
// }

// type DayOfWeekFieldProps = {
//     formState: FormState<CourseSectionScheduleFormValues>;
//     name: KeyWithValueType<CourseSectionScheduleFormValues, boolean>;
// } & ChildrenProps;

// function DayOfWeekField({ formState, name, children }: DayOfWeekFieldProps) {
//     return (
//         <BooleanField
//             formState={formState}
//             name={name}
//             Input={(props) => <DayOfWeekButton {...props}>{children}</DayOfWeekButton>}
//             className="day-of-week-field"
//         />
//     );
// }

// function DayOfWeekButton({
//     value,
//     setValue,
//     onFocus,
//     onBlur,
//     disabled,
//     children,
// }: BooleanFieldInputProps & ChildrenProps) {
//     const onClick = React.useCallback(() => {
//         setValue(!value);
//         onFocus && onFocus();
//         onBlur && onBlur();
//     }, [setValue, value, onFocus, onBlur]);

//     return (
//         <Button
//             variant={value ? "primary" : "secondary"}
//             onClick={onClick}
//             disabled={disabled}
//             className={styles["day-of-week-button"]}
//         >
//             <span className={styles["day-of-week-button__text-container"]}>{children}</span>
//         </Button>
//     );
// }

// type ScheduleCalendarViewProps = {
//     homeRoomID: HomeRoom["id"];
//     courseSections: CourseSectionScheduleFormValues[];
// };

// function ScheduleCalendarView({ homeRoomID, courseSections }: ScheduleCalendarViewProps) {
//     const { data: coursesData } = useCourseSelectQuery({
//         variables: { filters: { homeRoomIDEQ: homeRoomID } },
//     });
//     const coursesMap = React.useMemo(() => {
//         if (!coursesData) {
//             return {};
//         }
//         const coursesMap: Record<Course["id"], Pick<Course, "name" | "subject">> = {};
//         for (let i = 0; i < coursesData.courseConnection.edges.length; i++) {
//             const node = coursesData.courseConnection.edges[i].node;
//             coursesMap[node.id] = node;
//         }
//         return coursesMap;
//     }, [coursesData]);

//     const events = React.useMemo(() => toEvents(courseSections, coursesMap), [courseSections, coursesMap]);

//     return <WeeklyCourseScheduleCalendar events={events} containerStyle={{ height: "100%" }} />;
// }

// // TODO unite with function in WeeklyCourseScheduleCalendar
// function toEvents(
//     courseSections: CourseSectionScheduleFormValues[],
//     coursesMap: Record<Course["id"], Pick<Course, "name" | "subject">>,
// ) {
//     const events: CalendarItem[] = [];

//     const today = new Date();
//     today.setHours(0, 0, 0, 0);
//     const sunday = dateTrunc(today, "week").toDate();

//     const monday = addTimeToDate(sunday, [1, "day"]).toDate();
//     const tuesday = addTimeToDate(sunday, [2, "days"]).toDate();
//     const wednesday = addTimeToDate(sunday, [3, "days"]).toDate();
//     const thursday = addTimeToDate(sunday, [4, "days"]).toDate();
//     const friday = addTimeToDate(sunday, [5, "days"]).toDate();

//     for (let i = 0; i < courseSections.length; i++) {
//         const section = courseSections[i];
//         if (section.courseID == null || section.startTime === null || section.endTime === null) {
//             continue;
//         }

//         const course = coursesMap[section.courseID];

//         if (section.onMonday) {
//             events.push({
//                 title: course.name,
//                 start: minutesToTime(section.startTime, new Date(monday)),
//                 end: minutesToTime(section.endTime, new Date(monday)),
//                 original: {
//                     groups: [{ color: courseSubjectColors[course.subject] }],
//                 },
//             });
//         }
//         if (section.onTuesday) {
//             events.push({
//                 title: course.name,
//                 start: minutesToTime(section.startTime, new Date(tuesday)),
//                 end: minutesToTime(section.endTime, new Date(tuesday)),
//                 original: {
//                     groups: [{ color: courseSubjectColors[course.subject] }],
//                 },
//             });
//         }
//         if (section.onWednesday) {
//             events.push({
//                 title: course.name,
//                 start: minutesToTime(section.startTime, new Date(wednesday)),
//                 end: minutesToTime(section.endTime, new Date(wednesday)),
//                 original: {
//                     groups: [{ color: courseSubjectColors[course.subject] }],
//                 },
//             });
//         }
//         if (section.onThursday) {
//             events.push({
//                 title: course.name,
//                 start: minutesToTime(section.startTime, new Date(thursday)),
//                 end: minutesToTime(section.endTime, new Date(thursday)),
//                 original: {
//                     groups: [{ color: courseSubjectColors[course.subject] }],
//                 },
//             });
//         }
//         if (section.onFriday) {
//             events.push({
//                 title: course.name,
//                 start: minutesToTime(section.startTime, new Date(friday)),
//                 end: minutesToTime(section.endTime, new Date(friday)),
//                 original: {
//                     groups: [{ color: courseSubjectColors[course.subject] }],
//                 },
//             });
//         }
//     }

//     return events;
// }
