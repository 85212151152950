import { newPageInfo, newRouteParamsType } from "@hex-insights/router";
import { AcademicYear, AcademicYearFormat, AcademicYearNameFragment } from "@hex-insights/verita.shared";
import { academicYearHubPageInfo } from "../pageinfo";

type AcademicYearForRouteTitle = AcademicYearNameFragment;

export const academicYearHubDetailPageInfo = newPageInfo({
	name: "Academic Year Detail",
	title: (instance: AcademicYearForRouteTitle) => AcademicYearFormat.name(instance),
	description: "Academic Year detail page.",
	exact: false,
	isLeaf: true,
	parent: academicYearHubPageInfo,
	to: (id: AcademicYear["id"]) => academicYearHubPageInfo.to + `/${id}`,
	path: academicYearHubPageInfo.path + "/:academicYearID(\\d+)",
	paramsType: newRouteParamsType(academicYearHubPageInfo.paramsType, "academicYearID"),
});
