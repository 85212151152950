import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { NoteHubCreatePage } from "./CreatePage";
import { noteHubCreatePageInfo } from "./CreatePage/pageinfo";
import { NoteHubDetailPage } from "./DetailPage";
import { noteHubDetailPageInfo } from "./DetailPage/pageinfo";
import { NoteHubIndexPage } from "./IndexPage";
import { noteHubIndexPageInfo } from "./IndexPage/pageinfo";
import { noteHubPageInfo } from "./pageinfo";

export function NoteHub() {
	useActivePageRegistration(noteHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...noteHubIndexPageInfo.routeProps}>
				<NoteHubIndexPage />
			</Route>
			<Route {...noteHubCreatePageInfo.routeProps}>
				<NoteHubCreatePage />
			</Route>
			<Route {...noteHubDetailPageInfo.routeProps}>
				<NoteHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
