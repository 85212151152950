import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { DocumentResourceUserViewRecordFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the DocumentResourceUserViewRecord model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => DocumentResourceUserViewRecordFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(
		initialFormValues,
		DocumentResourceUserViewRecordFilterFormValues.typeFilterObject,
	);
}
