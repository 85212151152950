import { IndexForms } from "@hex-insights/app-modules";
import { StudentDocumentOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the StudentDocument model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		StudentDocumentOrderFormValues.initial,
		StudentDocumentOrderFormValues.fieldValues,
	);
}
