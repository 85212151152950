import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { TermHubCreatePage } from "./CreatePage";
import { termHubCreatePageInfo } from "./CreatePage/pageinfo";
import { TermHubDetailPage } from "./DetailPage";
import { termHubDetailPageInfo } from "./DetailPage/pageinfo";
import { TermHubIndexPage } from "./IndexPage";
import { termHubIndexPageInfo } from "./IndexPage/pageinfo";
import { termHubPageInfo } from "./pageinfo";

export function TermHub() {
	useActivePageRegistration(termHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...termHubIndexPageInfo.routeProps}>
				<TermHubIndexPage />
			</Route>
			<Route {...termHubCreatePageInfo.routeProps}>
				<TermHubCreatePage />
			</Route>
			<Route {...termHubDetailPageInfo.routeProps}>
				<TermHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
