import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import {
	DocumentResourceUserViewRecordFilterInput,
	DocumentResourceUserViewRecordOrder,
	useDocumentResourceUserViewRecordPaginationQuery,
} from "../../../../Utilities";

export type DocumentResourceUserViewRecordPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: DocumentResourceUserViewRecordFilterInput | DocumentResourceUserViewRecordFilterInput[] | null;
	order: DocumentResourceUserViewRecordOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the DocumentResourceUserViewRecord model.
 */
export function DocumentResourceUserViewRecordPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: DocumentResourceUserViewRecordPaginationFormProps) {
	const { loading, data } = useDocumentResourceUserViewRecordPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.documentResourceUserViewRecordConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
