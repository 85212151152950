import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { StudentHubCreatePage } from "./CreatePage";
import { studentHubCreatePageInfo } from "./CreatePage/pageinfo";
import { StudentHubDetailPage } from "./DetailPage";
import { studentHubDetailPageInfo } from "./DetailPage/pageinfo";
import { StudentHubIndexPage } from "./IndexPage";
import { studentHubIndexPageInfo } from "./IndexPage/pageinfo";
import { studentHubPageInfo } from "./pageinfo";

export function StudentHub() {
	useActivePageRegistration(studentHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...studentHubIndexPageInfo.routeProps}>
				<StudentHubIndexPage />
			</Route>
			<Route {...studentHubCreatePageInfo.routeProps}>
				<StudentHubCreatePage />
			</Route>
			<Route {...studentHubDetailPageInfo.routeProps}>
				<StudentHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
