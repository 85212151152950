import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { CampusFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the Campus model.
 */
export function useFormState() {
	const initialFormValues = React.useMemo(() => CampusFilterFormValues.getInitial(), []);
	return IndexForms.useFilterFormStateWithQueryStateSync(initialFormValues, CampusFilterFormValues.typeFilterObject);
}
