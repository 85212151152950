import "@hex-insights/core";
import { EnrollmentApplicationUserViewRecordDetailQuery } from "../../GraphQL";
import { EnrollmentApplicationUserViewRecordFormValues } from "../ModelFormValues";

/**
 * Converts a EnrollmentApplicationUserViewRecord instance to its corresponding detail form values.
 *
 * @param enrollmentApplicationUserViewRecord The instance to convert.
 */
export function toFormValues(
	enrollmentApplicationUserViewRecord: EnrollmentApplicationUserViewRecordDetailQuery["enrollmentApplicationUserViewRecord"],
): EnrollmentApplicationUserViewRecordFormValues.Detail {
	return {
		createdAt: enrollmentApplicationUserViewRecord.createdAt,
		isDetail: enrollmentApplicationUserViewRecord.isDetail,
		enrollmentApplicationID: enrollmentApplicationUserViewRecord.enrollmentApplication?.id ?? null,
		userID: enrollmentApplicationUserViewRecord.user?.id ?? null,
	};
}
