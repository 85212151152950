import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { PersonHubCreatePage } from "./CreatePage";
import { personHubCreatePageInfo } from "./CreatePage/pageinfo";
import { PersonHubDetailPage } from "./DetailPage";
import { personHubDetailPageInfo } from "./DetailPage/pageinfo";
import { PersonHubIndexPage } from "./IndexPage";
import { personHubIndexPageInfo } from "./IndexPage/pageinfo";
import { personHubPageInfo } from "./pageinfo";

export function PersonHub() {
	useActivePageRegistration(personHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...personHubIndexPageInfo.routeProps}>
				<PersonHubIndexPage />
			</Route>
			<Route {...personHubCreatePageInfo.routeProps}>
				<PersonHubCreatePage />
			</Route>
			<Route {...personHubDetailPageInfo.routeProps}>
				<PersonHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
