import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	EnrollmentApplicationParentFilterFormController,
	EnrollmentApplicationParentFilterFormState,
	EnrollmentApplicationParentFilterFormUtils,
	EnrollmentApplicationParentFormat,
	EnrollmentApplicationParentIndexQuery,
	EnrollmentApplicationParentLink,
	EnrollmentApplicationParentOrderField,
	EnrollmentApplicationParentOrderFormController,
	EnrollmentApplicationParentOrderFormState,
	EnrollmentApplicationParentOrderFormValues,
	EnrollmentApplicationParentPaginationForm,
	EnrollmentApplicationParentSearchForm,
	useEnrollmentApplicationParentIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { enrollmentApplicationParentHubCreatePageInfo } from "../CreatePage/pageinfo";
import { enrollmentApplicationParentHubIndexPageInfo } from "./pageinfo";

const columns: (keyof EnrollmentApplicationParentIndexQuery["enrollmentApplicationParentConnection"]["edges"][0]["node"])[] =
	[
		"name",
		"formIdentifier",
		"firstName",
		"lastName",
		"nationality",
		"occupation",
		"relationshipType",
		"relationshipTypeCustom",
		"isPrimaryContact",
		"livesWithStudent",
		"isAuthorizedForPickup",
		"isEmergencyContact",
		"isFinancialContact",
		"addressSameAsFormIdentifier",
		"addressLine1",
		"addressLine2",
		"city",
		"stateOrProvince",
		"postalCode",
		"country",
		"homePhone",
		"workPhone",
		"mobilePhone",
		"email",
	];

function orderFieldToColumn(field: EnrollmentApplicationParentOrderField | null) {
	if (field === null) {
		return "id";
	}
	return EnrollmentApplicationParentOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return EnrollmentApplicationParentOrderFormValues.modelFieldToOrderField[column];
}

export function EnrollmentApplicationParentHubIndexPage() {
	useActivePageRegistration(enrollmentApplicationParentHubIndexPageInfo);

	const filterFormState = EnrollmentApplicationParentFilterFormState.useFormState();
	const orderFormState = EnrollmentApplicationParentOrderFormState.useFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(
		filterFormState.formValues,
		EnrollmentApplicationParentFilterFormUtils.toFilterInputs,
	);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useEnrollmentApplicationParentIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		EnrollmentApplicationParentFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = EnrollmentApplicationParentFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) -
			(data?.enrollmentApplicationParentConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading.H1 noMargin>Enrollment Application Parents</Heading.H1>

				<InternalLink to={enrollmentApplicationParentHubCreatePageInfo.to}>
					New Enrollment Application Parent
				</InternalLink>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<EnrollmentApplicationParentSearchForm formState={filterFormState} />
				<EnrollmentApplicationParentFilterFormController
					formState={filterFormState}
					numActiveFilters={numActiveFilters}
				/>
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<EnrollmentApplicationParentOrderFormController formState={orderFormState} />
			</Row>

			<>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<If condition={!!error}>Something went wrong.</If>
					<If condition={data?.enrollmentApplicationParentConnection.edges.length === 0}>No results found.</If>
					<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
				</Conditional>
			</>

			<Table.Container className="hub__index-page__table__container">
				<Table
					columns={columns}
					minColumnWidth="10rem"
					scrollSyncID={paginationFormState.formValues.cursor}
					{...tableSortingProps}
					className="hub__index-page__table"
				>
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="name" noSort>
								Name
							</Table.Heading>
							<Table.Heading column="formIdentifier" noSort>
								Form Identifier
							</Table.Heading>
							<Table.Heading column="firstName">First Name</Table.Heading>
							<Table.Heading column="lastName">Last Name</Table.Heading>
							<Table.Heading column="nationality">Nationality</Table.Heading>
							<Table.Heading column="occupation">Occupation</Table.Heading>
							<Table.Heading column="relationshipType">Relationship Type</Table.Heading>
							<Table.Heading column="relationshipTypeCustom">Relationship Type Custom</Table.Heading>
							<Table.Heading column="isPrimaryContact">Is Primary Contact</Table.Heading>
							<Table.Heading column="livesWithStudent">Lives with Student</Table.Heading>
							<Table.Heading column="isAuthorizedForPickup">Is Authorized for Pickup</Table.Heading>
							<Table.Heading column="isEmergencyContact">Is Emergency Contact</Table.Heading>
							<Table.Heading column="isFinancialContact">Is Financial Contact</Table.Heading>
							<Table.Heading column="addressSameAsFormIdentifier" noSort>
								Address Same As Form Identifier
							</Table.Heading>
							<Table.Heading column="addressLine1" noSort>
								Address Line 1
							</Table.Heading>
							<Table.Heading column="addressLine2" noSort>
								Address Line 2
							</Table.Heading>
							<Table.Heading column="city" noSort>
								City
							</Table.Heading>
							<Table.Heading column="stateOrProvince" noSort>
								State or Province
							</Table.Heading>
							<Table.Heading column="postalCode" noSort>
								Postal Code
							</Table.Heading>
							<Table.Heading column="country" noSort>
								Country
							</Table.Heading>
							<Table.Heading column="homePhone" noSort>
								Home Phone
							</Table.Heading>
							<Table.Heading column="workPhone" noSort>
								Work Phone
							</Table.Heading>
							<Table.Heading column="mobilePhone" noSort>
								Mobile Phone
							</Table.Heading>
							<Table.Heading column="email" noSort>
								Email
							</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.enrollmentApplicationParentConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="name" oneLine>
											<EnrollmentApplicationParentLink instance={e.node}>
												{EnrollmentApplicationParentFormat.Fields.name(e.node.name)}
											</EnrollmentApplicationParentLink>
										</Table.Cell>
										<Table.Cell column="formIdentifier" oneLine>
											{EnrollmentApplicationParentFormat.Fields.formIdentifier(e.node.formIdentifier)}
										</Table.Cell>
										<Table.Cell column="firstName" oneLine>
											{EnrollmentApplicationParentFormat.Fields.firstName(e.node.firstName)}
										</Table.Cell>
										<Table.Cell column="lastName" oneLine>
											{EnrollmentApplicationParentFormat.Fields.lastName(e.node.lastName)}
										</Table.Cell>
										<Table.Cell column="nationality" oneLine>
											{EnrollmentApplicationParentFormat.Fields.nationality(e.node.nationality)}
										</Table.Cell>
										<Table.Cell column="occupation" oneLine>
											{EnrollmentApplicationParentFormat.Fields.occupation(e.node.occupation)}
										</Table.Cell>
										<Table.Cell column="relationshipType" oneLine>
											{EnrollmentApplicationParentFormat.Fields.relationshipType(e.node.relationshipType)}
										</Table.Cell>
										<Table.Cell column="relationshipTypeCustom" oneLine>
											{EnrollmentApplicationParentFormat.Fields.relationshipTypeCustom(e.node.relationshipTypeCustom)}
										</Table.Cell>
										<Table.Cell column="isPrimaryContact" oneLine>
											{EnrollmentApplicationParentFormat.Fields.isPrimaryContact(e.node.isPrimaryContact)}
										</Table.Cell>
										<Table.Cell column="livesWithStudent" oneLine>
											{EnrollmentApplicationParentFormat.Fields.livesWithStudent(e.node.livesWithStudent)}
										</Table.Cell>
										<Table.Cell column="isAuthorizedForPickup" oneLine>
											{EnrollmentApplicationParentFormat.Fields.isAuthorizedForPickup(e.node.isAuthorizedForPickup)}
										</Table.Cell>
										<Table.Cell column="isEmergencyContact" oneLine>
											{EnrollmentApplicationParentFormat.Fields.isEmergencyContact(e.node.isEmergencyContact)}
										</Table.Cell>
										<Table.Cell column="isFinancialContact" oneLine>
											{EnrollmentApplicationParentFormat.Fields.isFinancialContact(e.node.isFinancialContact)}
										</Table.Cell>
										<Table.Cell column="addressSameAsFormIdentifier" oneLine>
											{EnrollmentApplicationParentFormat.Fields.addressSameAsFormIdentifier(
												e.node.addressSameAsFormIdentifier,
											)}
										</Table.Cell>
										<Table.Cell column="addressLine1" oneLine>
											{EnrollmentApplicationParentFormat.Fields.addressLine1(e.node.addressLine1)}
										</Table.Cell>
										<Table.Cell column="addressLine2" oneLine>
											{EnrollmentApplicationParentFormat.Fields.addressLine2(e.node.addressLine2)}
										</Table.Cell>
										<Table.Cell column="city" oneLine>
											{EnrollmentApplicationParentFormat.Fields.city(e.node.city)}
										</Table.Cell>
										<Table.Cell column="stateOrProvince" oneLine>
											{EnrollmentApplicationParentFormat.Fields.stateOrProvince(e.node.stateOrProvince)}
										</Table.Cell>
										<Table.Cell column="postalCode" oneLine>
											{EnrollmentApplicationParentFormat.Fields.postalCode(e.node.postalCode)}
										</Table.Cell>
										<Table.Cell column="country" oneLine>
											{EnrollmentApplicationParentFormat.Fields.country(e.node.country)}
										</Table.Cell>
										<Table.Cell column="homePhone" oneLine>
											{EnrollmentApplicationParentFormat.Fields.homePhone(e.node.homePhone)}
										</Table.Cell>
										<Table.Cell column="workPhone" oneLine>
											{EnrollmentApplicationParentFormat.Fields.workPhone(e.node.workPhone)}
										</Table.Cell>
										<Table.Cell column="mobilePhone" oneLine>
											{EnrollmentApplicationParentFormat.Fields.mobilePhone(e.node.mobilePhone)}
										</Table.Cell>
										<Table.Cell column="email" oneLine>
											{EnrollmentApplicationParentFormat.Fields.email(e.node.email)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<EnrollmentApplicationParentPaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.enrollmentApplicationParentConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
