import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const studentHubPageInfo = newPageInfo({
	title: "Students",
	description: "Pages related to the Student model.",
	exact: false,
	to: hubsPageInfo.to + "/students",
	path: hubsPageInfo.path + "/students",
	parent: hubsPageInfo,
});
