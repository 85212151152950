import { newPageInfo } from "@hex-insights/router";
import { contractInstallmentHubPageInfo } from "../pageinfo";

export const contractInstallmentHubIndexPageInfo = newPageInfo({
	name: "Contract Installments Index",
	title: "",
	description: "Index of Contract Installments.",
	exact: true,
	to: contractInstallmentHubPageInfo.to,
	path: contractInstallmentHubPageInfo.path,
	parent: contractInstallmentHubPageInfo,
});
