import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const learningObjectiveMarkHubPageInfo = newPageInfo({
	title: "Learning Objective Marks",
	description: "Pages related to the Learning Objective Mark model.",
	exact: false,
	to: hubsPageInfo.to + "/learning-objective-marks",
	path: hubsPageInfo.path + "/learning-objective-marks",
	parent: hubsPageInfo,
});
