import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	DocumentResource,
	DocumentResourceCreateInput,
	DocumentResourceCreateMutation,
	DocumentResourceDeleteMutation,
	DocumentResourceDetailDocument,
	DocumentResourceDetailQuery,
	DocumentResourceDetailQueryVariables,
	DocumentResourceFolder,
	DocumentResourceUpdateInput,
	DocumentResourceUpdateMutation,
	DocumentResourceUpdateMutationHookResult,
	graphTypeNames,
	useDocumentResourceCreateMutation,
	useDocumentResourceDeleteMutation,
	useDocumentResourceUpdateMutation,
	User,
} from "../../GraphQL";
import { DocumentResourceFormConversion } from "../ModelFormConversion";
import { DocumentResourceFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the DocumentResource model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the DocumentResource model.
 */
export function useCreate() {
	const [createDocumentResource] = useDocumentResourceCreateMutation();

	return React.useCallback(
		async (formValues: DocumentResourceFormValues.Create) => {
			const input = DocumentResourceFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createDocumentResource({ variables: { input }, update: updateCache });

			return { data: data?.createDocumentResource ?? null, errors: errors ?? null };
		},
		[createDocumentResource],
	);
}

/**
 * Returns an `update` function for the DocumentResource model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the DocumentResource model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: DocumentResource["id"]) {
	const [updateDocumentResource] = useDocumentResourceUpdateMutation();

	return React.useMemo(() => getUpdate(updateDocumentResource, id), [updateDocumentResource, id]);
}

export function getUpdate(
	updateDocumentResource: DocumentResourceUpdateMutationHookResult[0],
	id: DocumentResource["id"],
) {
	return async (
		changedFormValues: Partial<DocumentResourceFormValues.Detail>,
		initialFormValues: Partial<DocumentResourceFormValues.Detail>,
	) => {
		const input = DocumentResourceFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
		const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

		const { data, errors } = await updateDocumentResource({ variables: { id, input }, update: updateCache });

		return { data: data?.updateDocumentResource ?? null, errors: errors ?? null };
	};
}

/**
 * Returns a `del` function for the DocumentResource model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: DocumentResource["id"]) {
	const [deleteDocumentResource] = useDocumentResourceDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteDocumentResource({ variables: { id }, update: updateCache });

		return { data: data?.deleteDocumentResource ?? false, errors: errors ?? null };
	}, [deleteDocumentResource, id]);
}

function getUpdateCacheForCreate(input: DocumentResourceCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<DocumentResourceCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createDocumentResource;

		cache.writeQuery<DocumentResourceDetailQuery, DocumentResourceDetailQueryVariables>({
			query: DocumentResourceDetailDocument,
			data: { documentResource: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.bookmarkingUserIDs) {
			for (let i = 0; i < input.bookmarkingUserIDs.length; i++) {
				addToBookmarkedDocumentResourcesOfUserCache(cache, input.bookmarkingUserIDs[i], createdObject);
			}
		}

		if (input.documentResourceFolderIDs) {
			for (let i = 0; i < input.documentResourceFolderIDs.length; i++) {
				addToDocumentResourcesOfDocumentResourceFolderCache(cache, input.documentResourceFolderIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "documentResourceConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: DocumentResourceUpdateInput,
	_initialFormValues: Partial<DocumentResourceFormValues.Detail>,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<DocumentResourceUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateDocumentResource;

		if (input.addBookmarkingUserIDs) {
			for (let i = 0; i < input.addBookmarkingUserIDs.length; i++) {
				addToBookmarkedDocumentResourcesOfUserCache(cache, input.addBookmarkingUserIDs[i], updatedObject);
			}
		}
		if (input.removeBookmarkingUserIDs) {
			for (let i = 0; i < input.removeBookmarkingUserIDs.length; i++) {
				removeFromBookmarkedDocumentResourcesOfUserCache(cache, input.removeBookmarkingUserIDs[i], updatedObject);
			}
		}

		if (input.addDocumentResourceFolderIDs) {
			for (let i = 0; i < input.addDocumentResourceFolderIDs.length; i++) {
				addToDocumentResourcesOfDocumentResourceFolderCache(
					cache,
					input.addDocumentResourceFolderIDs[i],
					updatedObject,
				);
			}
			cache.evict({ id: "ROOT_QUERY", fieldName: "documentResourceConnection" }); // for top-level update
		}
		if (input.removeDocumentResourceFolderIDs) {
			for (let i = 0; i < input.removeDocumentResourceFolderIDs.length; i++) {
				removeFromDocumentResourcesOfDocumentResourceFolderCache(
					cache,
					input.removeDocumentResourceFolderIDs[i],
					updatedObject,
				);
			}
			if (result.data.updateDocumentResource.documentResourceFolders.length === 0) {
				cache.evict({ id: "ROOT_QUERY", fieldName: "documentResourceConnection" }); // for top-level update
			}
		}
	};
}

function getUpdateCacheForDelete(id: DocumentResource["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<DocumentResourceDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteDocumentResource) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.DocumentResource }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "documentResourceConnection" });
		cache.gc();
	};
}

function addToBookmarkedDocumentResourcesOfUserCache(
	cache: ApolloCache<any>,
	targetID: User["id"],
	object: Pick<DocumentResource, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.User }),
		fields: {
			bookmarkedDocumentResources: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromBookmarkedDocumentResourcesOfUserCache(
	cache: ApolloCache<any>,
	targetID: User["id"],
	object: Pick<DocumentResource, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.User }),
		fields: {
			bookmarkedDocumentResources: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToDocumentResourcesOfDocumentResourceFolderCache(
	cache: ApolloCache<any>,
	targetID: DocumentResourceFolder["id"],
	object: Pick<DocumentResource, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.DocumentResourceFolder }),
		fields: {
			documentResources: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromDocumentResourcesOfDocumentResourceFolderCache(
	cache: ApolloCache<any>,
	targetID: DocumentResourceFolder["id"],
	object: Pick<DocumentResource, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.DocumentResourceFolder }),
		fields: {
			documentResources: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<DocumentResource, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment DocumentResourceRef on DocumentResource {
				id
			}
		`,
		data: object,
	});
}
