import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	ContractForm,
	ContractFormValues,
	ContractMutation,
	useContractDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { contractHubDetailPageInfo } from "./pageinfo";

function ContractHubDetailPageWrapper() {
	// Manages identity of the ContractHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <ContractHubDetailPage key={id} />;
}

export { ContractHubDetailPageWrapper as ContractHubDetailPage };

function ContractHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useContractDetailQuery({ variables: { id } });
	const contract = data?.contract;
	useActivePageRegistration(contractHubDetailPageInfo, !!contract ? contractHubDetailPageInfo.title(contract) : "");

	const update = ContractMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<ContractFormValues.Detail>, initialFormValues: ContractFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading contract</If>
				<Else>
					{!!contract && (
						<React.Fragment>
							<Heading level={1}>{contract.id}</Heading>

							<ContractForm.ControlledDetail
								key={syncID}
								contract={contract}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
