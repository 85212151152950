import { IndexForms } from "@hex-insights/app-modules";
import { DiscountFilterFormValues } from "../../../../Utilities";

export type DiscountSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the Discount model.
 */
export function DiscountSearchForm(props: DiscountSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type DiscountFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, DiscountFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Discount model.
 */
export function DiscountFilterFormController(props: DiscountFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={DiscountFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `DiscountFilterForm`
