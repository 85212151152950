import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { StudentDocumentHubCreatePage } from "./CreatePage";
import { studentDocumentHubCreatePageInfo } from "./CreatePage/pageinfo";
import { StudentDocumentHubDetailPage } from "./DetailPage";
import { studentDocumentHubDetailPageInfo } from "./DetailPage/pageinfo";
import { StudentDocumentHubIndexPage } from "./IndexPage";
import { studentDocumentHubIndexPageInfo } from "./IndexPage/pageinfo";
import { studentDocumentHubPageInfo } from "./pageinfo";

export function StudentDocumentHub() {
	useActivePageRegistration(studentDocumentHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...studentDocumentHubIndexPageInfo.routeProps}>
				<StudentDocumentHubIndexPage />
			</Route>
			<Route {...studentDocumentHubCreatePageInfo.routeProps}>
				<StudentDocumentHubCreatePage />
			</Route>
			<Route {...studentDocumentHubDetailPageInfo.routeProps}>
				<StudentDocumentHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
