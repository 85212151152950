import { DateTimeField, FormType, RadioField, RadioInputOption, TextField } from "@hex-insights/forms";
import {
	EnrollmentApplicationStudentFormValues,
	EnrollmentApplicationSubmissionFormValues,
} from "../../../../Utilities";
import { RadioRowInput } from "../../../RadioRowInput";
import { FutureBaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the EnrollmentApplicationSubmission model.
 */
type FieldProps<
	K extends keyof EnrollmentApplicationSubmissionFormValues.Base = keyof EnrollmentApplicationSubmissionFormValues.Base,
> = FutureBaseFieldProps<Pick<EnrollmentApplicationSubmissionFormValues.Base, K>>;

/**
 * Generic props for fields of the EnrollmentApplicationSubmission model that only appear in the create form.
 */
type CreateFieldProps<
	K extends keyof EnrollmentApplicationSubmissionFormValues.Create = keyof EnrollmentApplicationSubmissionFormValues.Create,
> = FutureBaseFieldProps<Pick<EnrollmentApplicationSubmissionFormValues.Create, K>>;

/**
 * Generic props for fields of the EnrollmentApplicationSubmission model that only appear in the detail form.
 */
type DetailFieldProps<
	K extends keyof EnrollmentApplicationSubmissionFormValues.Detail = keyof EnrollmentApplicationSubmissionFormValues.Detail,
> = FutureBaseFieldProps<Pick<EnrollmentApplicationSubmissionFormValues.Detail, K>>;

/**
 * Renders a field component for the `createdAt` field of the EnrollmentApplicationSubmission model.
 */
export function CreatedAt({ formState, ...props }: DetailFieldProps<"createdAt">) {
	return (
		<DateTimeField
			formState={formState}
			name="createdAt"
			optional={FormType.isCreate(formState.formType)}
			precision="minute"
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `doesConsentToDataUse` field of the EnrollmentApplicationSubmission model.
 */
export function DoesConsentToDataUse({
	formState,
	label = "Do you consent to provide us with the personal data of the parent and student for the above-mentioned purposes?",
	...props
}: CreateFieldProps<"doesConsentToDataUse">) {
	return (
		<RadioField
			formState={formState}
			name="doesConsentToDataUse"
			label={label}
			options={EnrollmentApplicationStudentFormValues.yesNoOptions as RadioInputOption<boolean | null>[]}
			blankValue={null}
			Input={RadioRowInput}
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `doesConsentToDataUse` field of the EnrollmentApplicationSubmission model.
 */
export function DoesConsentToDataUseDetail({
	formState,
	label = "Do you consent to provide us with the personal data of the parent and student for the above-mentioned purposes?",
	...props
}: DetailFieldProps<"doesConsentToDataUse">) {
	return (
		<RadioField
			formState={formState}
			name="doesConsentToDataUse"
			label={label}
			options={EnrollmentApplicationStudentFormValues.yesNoOptions}
			Input={RadioRowInput}
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `signatureName` field of the EnrollmentApplicationSubmission model.
 */
export function SignatureName({ formState, label = "Name", ...props }: FieldProps<"signatureName">) {
	return <TextField formState={formState} name="signatureName" label={label} {...props} />;
}

/**
 * Renders a field component for the `signatureDate` field of the EnrollmentApplicationSubmission model.
 */
export function SignatureDate({ formState, label = "Date", ...props }: FieldProps<"signatureDate">) {
	return <DateTimeField formState={formState} name="signatureDate" label={label} precision="day" {...props} />;
}
