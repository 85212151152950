import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	DocumentResourceUserViewRecordFilterFormController,
	DocumentResourceUserViewRecordFilterFormState,
	DocumentResourceUserViewRecordFilterFormUtils,
	DocumentResourceUserViewRecordFormat,
	DocumentResourceUserViewRecordIndexQuery,
	DocumentResourceUserViewRecordOrderField,
	DocumentResourceUserViewRecordOrderFormController,
	DocumentResourceUserViewRecordOrderFormState,
	DocumentResourceUserViewRecordOrderFormValues,
	DocumentResourceUserViewRecordPaginationForm,
	useDocumentResourceUserViewRecordIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { documentResourceUserViewRecordHubCreatePageInfo } from "../CreatePage/pageinfo";
import { documentResourceUserViewRecordHubDetailPageInfo } from "../DetailPage/pageinfo";
import { documentResourceUserViewRecordHubIndexPageInfo } from "./pageinfo";

const columns: (keyof DocumentResourceUserViewRecordIndexQuery["documentResourceUserViewRecordConnection"]["edges"][0]["node"])[] =
	["id", "viewedAt"];

function orderFieldToColumn(field: DocumentResourceUserViewRecordOrderField | null) {
	if (field === null) {
		return "id";
	}
	return DocumentResourceUserViewRecordOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return DocumentResourceUserViewRecordOrderFormValues.modelFieldToOrderField[column];
}

export function DocumentResourceUserViewRecordHubIndexPage() {
	useActivePageRegistration(documentResourceUserViewRecordHubIndexPageInfo);

	const filterFormState = DocumentResourceUserViewRecordFilterFormState.useFormState();
	const orderFormState = DocumentResourceUserViewRecordOrderFormState.useFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(
		filterFormState.formValues,
		DocumentResourceUserViewRecordFilterFormUtils.toFilterInputs,
	);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useDocumentResourceUserViewRecordIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		DocumentResourceUserViewRecordFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = DocumentResourceUserViewRecordFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) -
			(data?.documentResourceUserViewRecordConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading level={1} noMargin>
					Document Resource User View Records
				</Heading>

				<InternalLink to={documentResourceUserViewRecordHubCreatePageInfo.to}>
					New Document Resource User View Record
				</InternalLink>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<DocumentResourceUserViewRecordFilterFormController
					formState={filterFormState}
					numActiveFilters={numActiveFilters}
				/>
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<DocumentResourceUserViewRecordOrderFormController formState={orderFormState} />
			</Row>

			<>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<If condition={!!error}>Something went wrong.</If>
					<If condition={data?.documentResourceUserViewRecordConnection.edges.length === 0}>No results found.</If>
					<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
				</Conditional>
			</>

			<Table.Container className="hub__index-page__table__container">
				<Table columns={columns} minColumnWidth="10rem" {...tableSortingProps} className="hub__index-page__table">
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="id">ID</Table.Heading>
							<Table.Heading column="viewedAt">Viewed At</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.documentResourceUserViewRecordConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="id" oneLine numeric>
											<InternalLink to={documentResourceUserViewRecordHubDetailPageInfo.to(e.node.id)}>
												{e.node.id}
											</InternalLink>
										</Table.Cell>
										<Table.Cell column="viewedAt" oneLine>
											{DocumentResourceUserViewRecordFormat.Fields.viewedAt(e.node.viewedAt)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<DocumentResourceUserViewRecordPaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.documentResourceUserViewRecordConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
