import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { EnrollmentApplicationNote } from "../../../../Utilities";

type ModelForLink = Pick<EnrollmentApplicationNote, "id">;

export type EnrollmentApplicationNoteLinkProps = DistributiveOmit<
	Models.LinkProps<ModelForLink>,
	"pageInfoKey" | "format"
>;

/**
 * Renders a link to the given EnrollmentApplicationNote instance.
 */
export function EnrollmentApplicationNoteLink(props: EnrollmentApplicationNoteLinkProps) {
	return <Models.Link pageInfoKey="EnrollmentApplicationNote" format={format} {...props} />;
}

function format(instance: ModelForLink) {
	return instance.id;
}
