import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const mealPlanHubPageInfo = newPageInfo({
	title: "Meal Plans",
	description: "Pages related to the Meal Plan model.",
	exact: false,
	to: hubsPageInfo.to + "/meal-plans",
	path: hubsPageInfo.path + "/meal-plans",
	parent: hubsPageInfo,
});
