import { newPageInfo } from "@hex-insights/router";
import { ExpenseCategory } from "@hex-insights/verita.shared";
import { expenseCategoryHubPageInfo } from "../pageinfo";

type ExpenseCategoryForRouteTitle = Pick<ExpenseCategory, "name">;

export const expenseCategoryHubDetailPageInfo = newPageInfo({
	name: "Expense Category Detail",
	title: (e: ExpenseCategoryForRouteTitle) => e.name,
	description: "Detail page for an expenseCategory.",
	exact: false,
	isLeaf: true,
	to: (id: ExpenseCategory["id"]) => expenseCategoryHubPageInfo.to + `/${id}`,
	path: expenseCategoryHubPageInfo.path + "/:id(\\d+)",
	parent: expenseCategoryHubPageInfo,
});
