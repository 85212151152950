import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export const termSetupPageInfo = newPageInfo({
	title: "Term Setup",
	description: "Term setup.",
	exact: false,
	to: "/term-setup",
	path: "/term-setup",
	parent: rootPageInfo,
});
