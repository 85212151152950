import { IndexForms } from "@hex-insights/app-modules";
import { InvoiceLineItemFilterFormValues } from "../../../../Utilities";

export type InvoiceLineItemSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the InvoiceLineItem model.
 */
export function InvoiceLineItemSearchForm(props: InvoiceLineItemSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type InvoiceLineItemFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, InvoiceLineItemFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the InvoiceLineItem model.
 */
export function InvoiceLineItemFilterFormController(props: InvoiceLineItemFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={InvoiceLineItemFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `InvoiceLineItemFilterForm`
