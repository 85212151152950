import { newPageInfo } from "@hex-insights/router";
import { mealMenuHubPageInfo } from "../pageinfo";

export const mealMenuHubCreatePageInfo = newPageInfo({
	name: "New Meal Menu",
	title: "New",
	description: "Create a new Meal Menu record.",
	exact: true,
	to: mealMenuHubPageInfo.to + "/new",
	path: mealMenuHubPageInfo.path + "/new",
	parent: mealMenuHubPageInfo,
});
