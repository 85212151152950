import React from "react";
import { DateTimeField, FieldDisplayArgs, FormType, RadioField, SelectField } from "@hex-insights/forms";
import {
	NoteReactionFormValues,
	NoteSelect,
	useNoteSelectLazyQuery,
	UserSelect,
	useUserSelectLazyQuery,
} from "../../../../Utilities";
import { NoteLink, UserLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the NoteReaction model.
 */
type FieldProps<K extends keyof NoteReactionFormValues.Base = keyof NoteReactionFormValues.Base> = BaseFieldProps<
	Pick<NoteReactionFormValues.Base, K>
>;

/**
 * Generic props for fields of the NoteReaction model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof NoteReactionFormValues.Detail = keyof NoteReactionFormValues.Detail> =
	BaseFieldProps<Pick<NoteReactionFormValues.Detail, K>>;

/**
 * Renders a field component for the `reactionType` field of the NoteReaction model.
 */
export function ReactionType({ formState, formType = FormType.Update }: FieldProps<"reactionType">) {
	return (
		<RadioField
			formState={formState}
			name="reactionType"
			options={NoteReactionFormValues.reactionTypeOptions}
			blankValue={null}
			optional={FormType.isCreate(formType)}
		/>
	);
}

/**
 * Renders a field component for the `createdAt` field of the NoteReaction model.
 */
export function CreatedAt({ formState, formType = FormType.Update }: DetailFieldProps<"createdAt">) {
	return (
		<DateTimeField formState={formState} name="createdAt" optional={FormType.isCreate(formType)} precision="minute" />
	);
}

export type AuthorProps = DetailFieldProps<"authorID"> & {
	currentAuthor?: UserSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `author` edge of the NoteReaction model.
 */
export function Author({ formState, currentAuthor }: AuthorProps) {
	const [loadOptions, { loading, data }] = useUserSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.authorID) {
			loadOptions();
		}
	}, [formState.formEditing.authorID, loadOptions]);
	const options = React.useMemo(
		() => UserSelect.toOptions(data?.userConnection.edges, currentAuthor),
		[data, currentAuthor],
	);

	return (
		<SelectField
			formState={formState}
			name="authorID"
			isLoading={loading}
			options={options}
			optional
			display={displayAuthor}
			blankValue={null}
		/>
	);
}

function displayAuthor({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserLink instance={{ id }}>{formattedValue}</UserLink>;
}

export type NoteProps = FieldProps<"noteID"> & {
	currentNote?: NoteSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `note` edge of the NoteReaction model.
 */
export function Note({ formState, currentNote }: NoteProps) {
	const [loadOptions, { loading, data }] = useNoteSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.noteID) {
			loadOptions();
		}
	}, [formState.formEditing.noteID, loadOptions]);
	const options = React.useMemo(
		() => NoteSelect.toOptions(data?.noteConnection.edges, currentNote),
		[data, currentNote],
	);

	return (
		<SelectField
			formState={formState}
			name="noteID"
			isLoading={loading}
			options={options}
			display={displayNote}
			blankValue={null}
		/>
	);
}

function displayNote({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <NoteLink instance={{ id }}>{formattedValue}</NoteLink>;
}
