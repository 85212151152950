import { IndexForms } from "@hex-insights/app-modules";
import { RoomFilterFormValues } from "../../../../Utilities";

export type RoomSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the Room model.
 */
export function RoomSearchForm(props: RoomSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type RoomFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, RoomFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Room model.
 */
export function RoomFilterFormController(props: RoomFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={RoomFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `RoomFilterForm`
