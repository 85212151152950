import { NullPartial } from "@hex-insights/core";
import { QuotationCreateInput, QuotationDetailQuery, QuotationUpdateInput } from "../../GraphQL";
import { QuotationFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Quotation model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: QuotationFormValues.Create): QuotationCreateInput {
	const input: NullPartial<QuotationCreateInput> = { ...formValues };
	return input as QuotationCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Quotation model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<QuotationFormValues.Detail>,
	_initialFormValues: QuotationFormValues.Detail,
): QuotationUpdateInput {
	const input: QuotationUpdateInput = { ...formValues };
	return input;
}

/**
 * Converts a Quotation instance to its corresponding detail form values.
 *
 * @param quotation The instance to convert.
 */
export function toFormValues(quotation: QuotationDetailQuery["quotation"]): QuotationFormValues.Detail {
	return {
		body: quotation.body,
		attribution: quotation.attribution,
	};
}
