import React from "react";
import { Conditional, Else, Heading, If } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	DocumentResourceUserViewRecordForm,
	useDocumentResourceUserViewRecordDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { documentResourceUserViewRecordHubDetailPageInfo } from "./pageinfo";

function DocumentResourceUserViewRecordHubDetailPageWrapper() {
	// Manages identity of the DocumentResourceUserViewRecordHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <DocumentResourceUserViewRecordHubDetailPage key={id} />;
}

export { DocumentResourceUserViewRecordHubDetailPageWrapper as DocumentResourceUserViewRecordHubDetailPage };

function DocumentResourceUserViewRecordHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useDocumentResourceUserViewRecordDetailQuery({ variables: { id } });
	const documentResourceUserViewRecord = data?.documentResourceUserViewRecord;
	useActivePageRegistration(
		documentResourceUserViewRecordHubDetailPageInfo,
		!!documentResourceUserViewRecord
			? documentResourceUserViewRecordHubDetailPageInfo.title(documentResourceUserViewRecord)
			: "",
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading documentResourceUserViewRecord</If>
				<Else>
					{!!documentResourceUserViewRecord && (
						<React.Fragment>
							<Heading level={1}>{documentResourceUserViewRecord.id}</Heading>

							<DocumentResourceUserViewRecordForm.ControlledReadOnly
								documentResourceUserViewRecord={documentResourceUserViewRecord}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
