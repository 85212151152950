import React from "react";
import { AuthenticationContext } from "packages/portal/src/Contexts";
import { Models, onUpdateSubmit, useApplyUpdate } from "@hex-insights/app-modules";
import {
	Button,
	ButtonProps,
	ButtonWithConfirmation,
	CardinalLocation,
	ChildrenProps,
	ClassNameProps,
	Column,
	Conditional,
	dateDiff,
	Drawer,
	DrawerProps,
	Else,
	filterMap,
	Grid,
	Heading,
	Icon,
	If,
	isAfter,
	isBefore,
	jsxJoin,
	List,
	makeClassName,
	NotificationContainer,
	NotificationRenderProps,
	Paragraph,
	pickKeys,
	preventDefault,
	relativeTime,
	RequiredKeys,
	Row,
	Section,
	StyleProps,
	toGrammaticalNumber,
	Tooltip,
	useClickOutListener,
	useNotifications,
	useOpenToggle,
	useSync,
	useToggle,
} from "@hex-insights/core";
import {
	EditMode,
	Form,
	FormSubmitFunction,
	FormType,
	Label,
	SubmitButton,
	useFormState,
	useOnSuccess,
	ValidationDisplayPolicy,
} from "@hex-insights/forms";
import { ExternalLink, InternalLink } from "@hex-insights/router";
import {
	EnrollmentApplication,
	EnrollmentApplicationDetailQuery,
	EnrollmentApplicationField,
	EnrollmentApplicationFormat,
	EnrollmentApplicationFormConversion,
	EnrollmentApplicationFormValues,
	EnrollmentApplicationModules,
	EnrollmentApplicationMutation,
	EnrollmentApplicationNote,
	EnrollmentApplicationNoteDeleteButton,
	EnrollmentApplicationNoteField,
	EnrollmentApplicationNoteForm,
	EnrollmentApplicationNoteFormValues,
	EnrollmentApplicationNoteMutation,
	EnrollmentApplicationParentDetailQuery,
	EnrollmentApplicationParentForm,
	EnrollmentApplicationParentFormat,
	EnrollmentApplicationStatus,
	EnrollmentApplicationStudent,
	EnrollmentApplicationStudentDetailQuery,
	EnrollmentApplicationStudentField,
	EnrollmentApplicationStudentForm,
	EnrollmentApplicationStudentFormat,
	EnrollmentApplicationStudentFormValues,
	EnrollmentApplicationStudentMutation,
	EnrollmentApplicationSubmission,
	graphTypeNames,
	HR,
	PersonIcon,
	StudentLink,
	TextAreaFieldDisplay,
	useEnrollmentApplicationDetailQuery,
	useEnrollmentApplicationUpdateMutation,
	useSendEnrollmentApplicationApprovedEmailMutation,
} from "@hex-insights/verita.shared";
import { EnrollmentApplicationStatusBadge } from "../EnrollmentApplicationStatusBadge";
import styles from "./styles.module.css";

export * from "./use-drawer";

export type EnrollmentApplicationDrawerProps = {
	enrollmentApplicationID: EnrollmentApplication["id"];
} & RequiredKeys<Pick<DrawerProps, "ifRef" | "onClose">, "onClose">;

export function EnrollmentApplicationDrawer({
	enrollmentApplicationID,
	ifRef,
	onClose,
}: EnrollmentApplicationDrawerProps) {
	const { loading, data } = useEnrollmentApplicationDetailQuery({
		variables: { id: enrollmentApplicationID },
		fetchPolicy: "cache-and-network",
	});
	const enrollmentApplication = data?.enrollmentApplication;
	const student = enrollmentApplication?.enrollmentApplicationStudent;

	const [activeTab, setActiveTab] = React.useState<"info" | "notes">("info");

	const academicYearStart = 2023;
	const ageInSeptember = student
		? toGrammaticalNumber("year", dateDiff(`${academicYearStart}-09-01T00:00:00Z`, student.dateOfBirth, "years"), true)
		: "0 years";

	let numChallenges = 0;
	if (student?.hasLearningDifficulties) {
		numChallenges++;
	}
	if (student?.hasMedicalPhysicalExceptionalities) {
		numChallenges++;
	}
	if (student?.hasBehaviorDisciplineChallenges) {
		numChallenges++;
	}

	const relatedStudents =
		student?.enrollmentApplicationSubmission.enrollmentApplicationStudents.filter((e) => e.id !== student?.id) ?? [];

	useClickOutListener(ifRef?.current, onClose);

	const { syncID, synchronize } = useSync();

	return (
		<Drawer ifRef={ifRef} onClose={onClose} className={styles["drawer"]}>
			<Drawer.Body className={styles["drawer__body"]}>
				<Conditional>
					<If condition={loading && !data}>Loading...</If>
					<Else>
						{!!(enrollmentApplication && student) && (
							<Column justify="spaced-start" style={{ paddingTop: "0.75rem" }}>
								<Row justify="space-between" align="center" className={styles["drawer__top-bar"]}>
									<span>
										Submitted: {EnrollmentApplicationFormat.Fields.createdAt(enrollmentApplication.createdAt)}
									</span>

									<ProgressBar enrollmentApplication={enrollmentApplication} style={{ flexGrow: 1, margin: "0 8%" }} />

									<If condition={enrollmentApplication.enrollmentApplicationUserViewRecords.length > 0}>
										<Row justify="spaced-start" align="center">
											Viewed{" "}
											<Row justify="spaced-start" horizontalSpacing="0.25rem">
												{filterMap(
													enrollmentApplication.enrollmentApplicationUserViewRecords,
													(e) => !!e.user.person,
													(e) => (
														<Tooltip.Container key={e.id}>
															<PersonIcon person={e.user.person!} imageSize="1.25rem" />

															<Tooltip>
																<Tooltip.Body>
																	<span style={{ whiteSpace: "nowrap" }}>{e.user.person!.name}</span>
																</Tooltip.Body>
															</Tooltip>
														</Tooltip.Container>
													),
												).slice(0, 3)}
											</Row>
										</Row>
									</If>
								</Row>

								<HR color="#eee" style={{ width: "calc(100% - 2rem)", margin: "0 auto" }} />

								<Row justify="space-between">
									<Conditional>
										<If
											condition={
												!!enrollmentApplication.interviewMeetingTime &&
												isBefore(new Date(), enrollmentApplication.interviewMeetingTime)
											}
										>
											<Row justify="spaced-start" align="center">
												<CollapsedStatus enrollmentApplication={enrollmentApplication} />

												<Row justify="spaced-start" align="center" horizontalSpacing="0.5rem">
													<Icon.Calendar size="1.5rem" />{" "}
													<span>
														{EnrollmentApplicationFormat.Fields.interviewMeetingTime(
															enrollmentApplication.interviewMeetingTime,
														)}
													</span>
												</Row>
											</Row>
										</If>
										<Else>
											<Row justify="spaced-start">
												<Status enrollmentApplication={enrollmentApplication} />
											</Row>
										</Else>
									</Conditional>

									<Row justify="spaced-start" align="center">
										<EnrollmentApplicationExternalLink enrollmentApplication={enrollmentApplication} />
										<ContactInformation enrollmentApplication={enrollmentApplication} />
									</Row>
								</Row>

								<Row justify="spaced-start" align="center" className={styles["overview-box"]}>
									<PersonIcon
										person={{
											name: EnrollmentApplicationStudentFormat.name(student),
											imageURL: student.imageURL ?? "",
										}}
										imageSize="6.5rem"
										expandable
									/>
									<Column style={{ flexGrow: 1, paddingBottom: "0.75rem" }}>
										<Heading.H3 noMargin style={{ paddingLeft: "1rem" }}>
											{EnrollmentApplicationStudentFormat.name(student)}
										</Heading.H3>

										<Row justify="spaced-start">
											<Column justify="spaced-start" verticalSpacing="0.25rem" className={styles["quick-stat"]}>
												<Row justify="space-between" align="center">
													<Label>Age</Label>

													<Tooltip.Container>
														<Tooltip>
															<Tooltip.Body style={{ whiteSpace: "nowrap" }}>
																Age in September {academicYearStart}: {ageInSeptember}
															</Tooltip.Body>
														</Tooltip>

														<Icon.Info size="0.8rem" block />
													</Tooltip.Container>
												</Row>
												<span className={styles["quick-stat__value"]}>
													{EnrollmentApplicationStudentFormat.age(student)}
												</span>
											</Column>
											<GradeApplyingForQuickStat student={student} onUpdate={synchronize} />
											<Column justify="spaced-start" verticalSpacing="0.25rem" className={styles["quick-stat"]}>
												<Label>Academic Year</Label>
												<span className={styles["quick-stat__value"]}>2023-2024</span>
											</Column>
											<Column justify="spaced-start" verticalSpacing="0.25rem" className={styles["quick-stat"]}>
												<Label>Learning Difficulties</Label>
												<span className={styles["quick-stat__value"]}>{numChallenges} Noted</span>
											</Column>
										</Row>
									</Column>
								</Row>

								<Row justify="spaced-start" className={styles["nav"]}>
									<Button
										variant={activeTab === "info" ? "primary" : "tertiary"}
										onClick={() => setActiveTab("info")}
										className={styles["nav__button"]}
									>
										Information
									</Button>
									<Button
										variant={activeTab === "notes" ? "primary" : "tertiary"}
										onClick={() => setActiveTab("notes")}
										className={styles["nav__button"]}
									>
										<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
											<If condition={enrollmentApplication.enrollmentApplicationNotes.length > 0}>
												<span className={styles["nav__button__num-notes"]}>
													{enrollmentApplication.enrollmentApplicationNotes.length}
												</span>
											</If>
											{toGrammaticalNumber("Note", enrollmentApplication.enrollmentApplicationNotes.length)}
										</Row>
									</Button>
								</Row>

								<Conditional>
									<If condition={activeTab === "info"}>
										<Column justify="spaced-start">
											<FormSubmissionDataSections
												key={syncID}
												student={enrollmentApplication.enrollmentApplicationStudent}
												submission={enrollmentApplication.enrollmentApplicationStudent.enrollmentApplicationSubmission}
											/>

											<If condition={relatedStudents.length > 0}>
												<SectionDivider />

												<Section className={styles["form-submission-data-section"]}>
													<Section.Header>
														<Heading.H2 noMargin>Related Students</Heading.H2>
													</Section.Header>
													<Section.Body>
														<List style={{ listStyleType: "none" }}>
															{relatedStudents.map((e) => (
																<List.Item key={e.id}>
																	<InternalLink
																		to={`?id=${e.enrollmentApplication?.id}`}
																		className="link--no-text-decoration"
																	>
																		<Row justify="spaced-start" align="center">
																			<PersonIcon person={e} imageSize="3rem" />
																			{EnrollmentApplicationStudentFormat.name(e)}
																		</Row>
																	</InternalLink>
																</List.Item>
															))}
														</List>
													</Section.Body>
												</Section>
											</If>

											<SectionDivider />

											<Row justify="center">
												<ArchiveButton enrollmentApplicationID={enrollmentApplication.id} onSuccess={onClose} />
											</Row>
										</Column>
									</If>
									<Else>
										<Section className={styles["form-submission-data-section"]}>
											<Section.Body>
												<Notes
													notes={enrollmentApplication.enrollmentApplicationNotes}
													enrollmentApplicationID={enrollmentApplication.id}
												/>
											</Section.Body>
										</Section>
									</Else>
								</Conditional>
							</Column>
						)}
					</Else>
				</Conditional>
			</Drawer.Body>
		</Drawer>
	);
}

type GradeApplyingForQuickStatProps = {
	student: Pick<EnrollmentApplicationStudent, "id" | "gradeLevelApplyingFor">;
	onUpdate: () => void;
};

function GradeApplyingForQuickStat({ student, onUpdate }: GradeApplyingForQuickStatProps) {
	const { isTooltipOpen, toggleIsTooltipOpen, closeTooltip } = Tooltip.useToggle(false);
	const ref = React.useRef<HTMLDivElement | null>(null);
	useClickOutListener(ref.current, closeTooltip);

	const { syncID, synchronize } = useSync();
	const onSuccess = React.useCallback(() => {
		closeTooltip();
		synchronize();
		onUpdate();
	}, [closeTooltip, synchronize, onUpdate]);

	return (
		<div ref={ref} style={{ flexGrow: 1 }}>
			<Tooltip.Container isControlled isOpen={isTooltipOpen} side="bottom" style={{ width: "100%" }}>
				<Column justify="spaced-start" verticalSpacing="0.25rem" className={styles["quick-stat"]}>
					<Row justify="space-between" align="center">
						<Label>Applying for</Label>

						<Button onClick={toggleIsTooltipOpen} style={{ padding: 0 }} title="Edit">
							<Icon.Edit2 size="0.8rem" block />
						</Button>
					</Row>
					<span className={styles["quick-stat__value"]}>
						{EnrollmentApplicationStudentFormat.Fields.gradeLevelApplyingFor(student.gradeLevelApplyingFor)}
					</span>
				</Column>

				<Tooltip isInteractable className={styles["form-tooltip"]}>
					<Tooltip.Body>
						<EnrollmentApplicationStudentGradeLevelApplyingForForm
							key={syncID}
							student={student}
							onSuccess={onSuccess}
						/>
					</Tooltip.Body>
				</Tooltip>
			</Tooltip.Container>
		</div>
	);
}

type EnrollmentApplicationStudentGradeLevelApplyingForFormValues = Pick<
	EnrollmentApplicationStudentFormValues.Detail,
	"gradeLevelApplyingFor"
>;

type EnrollmentApplicationStudentGradeLevelApplyingForFormProps = {
	student: Pick<EnrollmentApplicationStudent, "id" | "gradeLevelApplyingFor">;
	onSuccess: () => void;
};

function EnrollmentApplicationStudentGradeLevelApplyingForForm({
	student,
	onSuccess,
}: EnrollmentApplicationStudentGradeLevelApplyingForFormProps) {
	const formState = useFormState<EnrollmentApplicationStudentGradeLevelApplyingForFormValues>({
		initialFormValues: { gradeLevelApplyingFor: student.gradeLevelApplyingFor },
		formType: FormType.Update,
		editMode: EditMode.WriteOnly,
	});
	const update = EnrollmentApplicationStudentMutation.useUpdate(student.id);
	const { applyUpdate } = useApplyUpdate(update);
	const onSubmit = React.useCallback<FormSubmitFunction<EnrollmentApplicationStudentGradeLevelApplyingForFormValues>>(
		async (formState) => onUpdateSubmit(formState as any, applyUpdate),
		[applyUpdate],
	);
	useOnSuccess(formState, onSuccess);

	return (
		<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} noLocalStorage noChangesDialog>
			<Form.Column>
				<EnrollmentApplicationStudentField.GradeLevelApplyingFor formState={formState} />
				<Form.ActionBar>
					<SubmitButton onClick={formState.onSubmitWrapper(onSubmit)} submissionStatus={formState.submissionStatus}>
						Save
					</SubmitButton>
				</Form.ActionBar>
			</Form.Column>
		</Form>
	);
}

type ProgressBarProps = {
	enrollmentApplication: EnrollmentApplicationDetailQuery["enrollmentApplication"];
} & Partial<ClassNameProps & StyleProps>;

function ProgressBar({ enrollmentApplication, className, style }: ProgressBarProps) {
	const enrollment = enrollmentApplication.enrollmentApplicationStudent.student?.schoolStudentEnrollments.find(
		(e) => e.academicYear.name === "2023-2024",
	);

	const hasScheduledMeeting = enrollmentApplication.interviewMeetingTime !== null;
	const hasHadMeeting =
		enrollmentApplication.interviewMeetingTime !== null &&
		isAfter(new Date(), enrollmentApplication.interviewMeetingTime);

	let approvalStatus: ProgressBarStepProps["status"] = "incomplete";
	let approvalText = "Approval pending";
	if (enrollmentApplication.status === EnrollmentApplicationStatus.Approved) {
		approvalStatus = "success";
		approvalText = "Accepted";
	}
	if (enrollmentApplication.status === EnrollmentApplicationStatus.Rejected) {
		approvalStatus = "failure";
		approvalText = "Rejected";
	}

	return (
		<div className={makeClassName(styles["progress-bar"], className)} style={style}>
			<Row justify="spaced-start" horizontalSpacing="0.25rem" style={{ width: "100%" }}>
				<ProgressBarStep status="success">
					<span style={{ whiteSpace: "nowrap" }}>Application Submitted</span>
					<Paragraph style={{ whiteSpace: "nowrap" }}>
						{EnrollmentApplicationFormat.Fields.createdAt(enrollmentApplication.createdAt)}
					</Paragraph>
				</ProgressBarStep>
				<ProgressBarStep status={hasScheduledMeeting ? "success" : "incomplete"}>
					<span style={{ whiteSpace: "nowrap" }}>
						{hasScheduledMeeting ? "Meeting Scheduled" : "Meeting Not Scheduled"}
					</span>
					<If condition={hasScheduledMeeting}>
						<Paragraph style={{ whiteSpace: "nowrap" }}>
							{EnrollmentApplicationFormat.Fields.interviewMeetingTime(enrollmentApplication.interviewMeetingTime)}
						</Paragraph>
					</If>
				</ProgressBarStep>
				<ProgressBarStep status={hasHadMeeting ? "success" : "incomplete"}>
					<span style={{ whiteSpace: "nowrap" }}>{hasHadMeeting ? "Meeting Held" : "Meeting "}</span>
					<Paragraph style={{ whiteSpace: "nowrap" }}>
						{EnrollmentApplicationFormat.Fields.interviewMeetingTime(enrollmentApplication.interviewMeetingTime)}
					</Paragraph>
				</ProgressBarStep>
				<ProgressBarStep status={approvalStatus}>
					<span style={{ whiteSpace: "nowrap" }}>{approvalText}</span>
					<If
						condition={
							enrollmentApplication.status === EnrollmentApplicationStatus.Approved ||
							enrollmentApplication.status === EnrollmentApplicationStatus.Rejected
						}
					>
						<Paragraph style={{ whiteSpace: "nowrap" }}>
							{EnrollmentApplicationFormat.Fields.statusUpdatedAt(enrollmentApplication.statusUpdatedAt)}
						</Paragraph>
					</If>
				</ProgressBarStep>
				<ProgressBarStep status={!!enrollment ? "success" : "incomplete"}>
					<span style={{ whiteSpace: "nowrap" }}>
						{!!enrollment ? "Enrollment Confirmed" : "Enrollment Not Yet Confirmed"}
					</span>
				</ProgressBarStep>
				<ProgressBarStep status={enrollment?.hasPaidFee ? "success" : "incomplete"}>
					<span style={{ whiteSpace: "nowrap" }}>
						{enrollment?.hasPaidFee ? "Deposit Paid" : "Deposit Not Yet Paid"}
					</span>
				</ProgressBarStep>
			</Row>

			<span className={styles["progress-bar__next-step"]}>Next Step: Interview Meeting</span>
		</div>
	);
}

type ProgressBarStepProps = {
	status: "incomplete" | "success" | "failure";
} & Partial<ChildrenProps>;

function ProgressBarStep({ status, children }: ProgressBarStepProps) {
	return (
		<Tooltip.Container
			side="bottom"
			className={makeClassName(styles["progress-bar__step"], styles[`progress-bar__step--${status}`])}
		>
			<div className={styles["progress-bar__step__pill"]}></div>

			<Tooltip>
				<Tooltip.Body>{children}</Tooltip.Body>
			</Tooltip>
		</Tooltip.Container>
	);
}

const statusUpdateButtonsTooltipSideOrder: CardinalLocation[] = ["right", "bottom", "left"];

type CollapsedStatusProps = StatusProps;

function CollapsedStatus({ enrollmentApplication, style }: CollapsedStatusProps) {
	const { isTooltipOpen, toggleIsTooltipOpen, closeTooltip } = Tooltip.useToggle(false);
	const ref = React.useRef<HTMLDivElement | null>(null);
	useClickOutListener(ref.current, closeTooltip);

	return (
		<div ref={ref} style={style}>
			<Tooltip.Container isControlled isOpen={isTooltipOpen} sideOrder={statusUpdateButtonsTooltipSideOrder}>
				<Button onClick={toggleIsTooltipOpen} className={styles["collapsed-status__button"]}>
					<Row justify="center" align="center" style={{ height: "100%" }}>
						<Icon.MoreVertical size="1.1rem" style={{ display: "block" }} />
					</Row>
				</Button>

				<Tooltip isInteractable className={styles["status-update-buttons__tooltip"]}>
					<Tooltip.Body>
						<Row justify="spaced-start">
							<Status enrollmentApplication={enrollmentApplication} />
						</Row>
					</Tooltip.Body>
				</Tooltip>
			</Tooltip.Container>
		</div>
	);
}

type StatusProps = {
	enrollmentApplication: EnrollmentApplicationDetailQuery["enrollmentApplication"];
} & Partial<StyleProps>;

function Status({ enrollmentApplication, style }: StatusProps) {
	if (enrollmentApplication.status === EnrollmentApplicationStatus.Pending) {
		return <StatusUpdateButtons enrollmentApplication={enrollmentApplication} style={style} />;
	}

	return (
		<Row justify="spaced-start" align="center" style={style}>
			<StatusBadgeWithRevert enrollmentApplication={enrollmentApplication} style={{ height: "100%" }} />
			{!!enrollmentApplication.enrollmentApplicationStudent.student && (
				<StudentLink
					instance={enrollmentApplication.enrollmentApplicationStudent.student}
					style={{ fontSize: "0.9rem" }}
				>
					View Student Record
				</StudentLink>
			)}
		</Row>
	);
}

type StatusBadgeWithRevertProps = {
	enrollmentApplication: EnrollmentApplicationDetailQuery["enrollmentApplication"];
} & Partial<StyleProps>;

function StatusBadgeWithRevert({ enrollmentApplication, style }: StatusBadgeWithRevertProps) {
	const { isOpen, toggleIsOpen, close } = useOpenToggle();
	const ref = React.useRef<HTMLDivElement | null>(null);
	useClickOutListener(ref.current, close);

	const update = EnrollmentApplicationMutation.useUpdate(enrollmentApplication.id);
	const { addNotification } = useNotifications();

	const [isReverting, setIsReverting] = React.useState(false);
	const revert = React.useCallback(async () => {
		if (!enrollmentApplication) {
			return;
		}
		setIsReverting(true);
		const initialFormValues = EnrollmentApplicationFormConversion.toFormValues(enrollmentApplication);
		const { errors } = await update({ status: EnrollmentApplicationStatus.Pending }, initialFormValues);
		if (errors) {
			addNotification(ErrorNotification, 3000);
		} else {
			addNotification(SuccessNotification, 2000);
		}
		setIsReverting(false);
	}, [update, enrollmentApplication, addNotification]);

	return (
		<div ref={ref} style={style}>
			<Tooltip.Container
				isControlled
				isOpen={isOpen}
				sideOrder={statusUpdateButtonsTooltipSideOrder}
				style={{ height: "100%" }}
			>
				<Button onClick={toggleIsOpen} style={{ width: "100%", height: "100%", padding: 0, border: "none" }}>
					<EnrollmentApplicationStatusBadge
						status={enrollmentApplication.status}
						hasConfirmed={!!enrollmentApplication.enrollmentApplicationStudent.student}
						style={{ fontSize: "1rem", width: "100%", height: "100%", flexGrow: 1 }}
					/>
				</Button>

				<Tooltip
					isInteractable
					className={styles["status-update-buttons__tooltip"] + " enrollment-application__status-revert__tooltip"}
				>
					<Tooltip.Body>
						<Column justify="spaced-start" align="center" style={{ width: "15rem" }}>
							<Column justify="spaced-start" align="center" verticalSpacing="0.25rem">
								<span style={{ fontSize: "0.9rem", color: "#777", whiteSpace: "nowrap" }}>
									{EnrollmentApplicationFormat.Fields.status(enrollmentApplication.status)}:{" "}
									{EnrollmentApplicationFormat.Fields.statusUpdatedAt(enrollmentApplication.statusUpdatedAt)}
								</span>

								<ButtonWithConfirmation
									variant="warning"
									onClick={revert}
									confirmationContent="Are you sure?"
									isLoading={isReverting}
									size="small"
								>
									<span style={{ whiteSpace: "nowrap" }}>Revert to Pending</span>
								</ButtonWithConfirmation>
							</Column>

							<HR style={{ width: "calc(100% - 2rem)" }} />

							<Column justify="spaced-start" align="center" verticalSpacing="0.25rem">
								<If condition={enrollmentApplication.status === EnrollmentApplicationStatus.Approved}>
									<span style={{ fontSize: "0.9rem", color: "#777", textAlign: "center" }}>
										<Conditional>
											<If condition={!enrollmentApplication.approvedEmailSentAt}>
												Acceptance email not sent yet
												<br />
												<br />
												Acceptance emails are sent automatically every few minutes
											</If>
											<Else>
												Acceptance email sent:{" "}
												{EnrollmentApplicationFormat.Fields.approvedEmailSentAt(
													enrollmentApplication.approvedEmailSentAt,
												)}
											</Else>
										</Conditional>
									</span>

									<SendEnrollmentApplicationApprovedEmailButton enrollmentApplication={enrollmentApplication} />
								</If>
							</Column>
						</Column>
					</Tooltip.Body>
				</Tooltip>
			</Tooltip.Container>
		</div>
	);
}

const sendEnrollmentApplicationApprovedEmailButtonConfirmButtonProps: Partial<ButtonProps> = { variant: "success" };

type SendEnrollmentApplicationApprovedEmailButtonProps = {
	enrollmentApplication: Pick<EnrollmentApplication, "id" | "approvedEmailSentAt">;
};

function SendEnrollmentApplicationApprovedEmailButton({
	enrollmentApplication,
}: SendEnrollmentApplicationApprovedEmailButtonProps) {
	const [sendEmail] = useSendEnrollmentApplicationApprovedEmailMutation();
	const enrollmentApplicationID = enrollmentApplication.id;
	const performAction = React.useCallback(async () => {
		return sendEmail({
			variables: { enrollmentApplicationID },
			update: (cache) => {
				cache.modify({
					id: cache.identify({ id: enrollmentApplicationID, __typename: graphTypeNames.EnrollmentApplication }),
					fields: {
						approvedEmailSentAt: () => new Date().toISOString(),
					},
				});
			},
		});
	}, [sendEmail, enrollmentApplicationID]);

	return (
		<Models.ActionButtonWithConfirmation
			variant="link"
			size="small"
			confirmationContent="Are you sure?"
			performAction={performAction}
			rateLimitMS={5000}
			SuccessNotification={SendEnrollmentApplicationApprovedEmailButton.SuccessNotification}
			confirmButtonProps={sendEnrollmentApplicationApprovedEmailButtonConfirmButtonProps}
		>
			{enrollmentApplication.approvedEmailSentAt ? "Resend Email" : "Send Now"}
		</Models.ActionButtonWithConfirmation>
	);
}

SendEnrollmentApplicationApprovedEmailButton.SuccessNotification = function (props: NotificationRenderProps) {
	return (
		<NotificationContainer variant="success" {...props}>
			Acceptance email sent.
		</NotificationContainer>
	);
};

type StatusUpdateButtonsProps = {
	enrollmentApplication: EnrollmentApplicationDetailQuery["enrollmentApplication"];
} & Partial<StyleProps>;

function StatusUpdateButtons({ enrollmentApplication, style }: StatusUpdateButtonsProps) {
	const update = EnrollmentApplicationMutation.useUpdate(enrollmentApplication.id);
	const { addNotification } = useNotifications();

	const [isApproving, setIsApproving] = React.useState(false);
	const approve = React.useCallback(async () => {
		if (!enrollmentApplication) {
			return;
		}
		setIsApproving(true);
		const initialFormValues = EnrollmentApplicationFormConversion.toFormValues(enrollmentApplication);
		const { errors } = await update({ status: EnrollmentApplicationStatus.Approved }, initialFormValues);
		if (errors) {
			addNotification(ErrorNotification, 3000);
		} else {
			addNotification(SuccessNotification, 2000);
		}
		setIsApproving(false);
	}, [update, enrollmentApplication, addNotification]);

	const [isRejecting, setIsRejecting] = React.useState(false);
	const reject = React.useCallback(async () => {
		if (!enrollmentApplication) {
			return;
		}
		setIsRejecting(true);
		const initialFormValues = EnrollmentApplicationFormConversion.toFormValues(enrollmentApplication);
		const { errors } = await update({ status: EnrollmentApplicationStatus.Rejected }, initialFormValues);
		if (errors) {
			addNotification(ErrorNotification, 3000);
		} else {
			addNotification(SuccessNotification, 2000);
		}
		setIsRejecting(false);
	}, [update, enrollmentApplication, addNotification]);

	return (
		<React.Fragment>
			<div className="enrollment-application__status-update-button-container">
				<ButtonWithConfirmation
					variant="success"
					onClick={approve}
					isLoading={isApproving}
					disabled={isApproving || isRejecting}
					confirmationContent="The parents will be notified of approval."
					style={style}
				>
					Approve
				</ButtonWithConfirmation>
			</div>
			<Button
				variant="danger"
				onClick={reject}
				isLoading={isRejecting}
				disabled={isApproving || isRejecting}
				style={style}
			>
				Reject
			</Button>
		</React.Fragment>
	);
}

function SuccessNotification(props: NotificationRenderProps) {
	return (
		<NotificationContainer {...props} variant="success">
			Successfully saved.
		</NotificationContainer>
	);
}

function ErrorNotification(props: NotificationRenderProps) {
	return (
		<NotificationContainer {...props} variant="danger">
			Error saving. Please try again later.
		</NotificationContainer>
	);
}

type ContactInformationProps = {
	enrollmentApplication: EnrollmentApplicationDetailQuery["enrollmentApplication"];
} & Partial<StyleProps>;

function ContactInformation({ enrollmentApplication, style }: ContactInformationProps) {
	const { isOpen, toggleIsOpen, close } = useOpenToggle(false);
	const ref = React.useRef<HTMLDivElement | null>(null);
	useClickOutListener(ref.current, close);

	const parents =
		enrollmentApplication.enrollmentApplicationStudent.enrollmentApplicationSubmission.enrollmentApplicationParents;

	return (
		<div ref={ref}>
			<Tooltip.Container isControlled isOpen={isOpen} style={style}>
				<Button variant="tertiary" onClick={toggleIsOpen}>
					<Row justify="spaced-start" align="center">
						<Icon.Mail size="1.25rem" />
						<Icon.Phone size="1.25rem" />
						<span style={{ flexGrow: 1, textAlign: "center" }}>View Contact</span>
					</Row>
				</Button>

				<Tooltip isInteractable className={styles["contact-information__tooltip"]}>
					<Tooltip.Body>
						<Column justify="spaced-start">
							{parents.map((parent) => (
								<Section key={parent.id}>
									<Section.Header>
										<strong style={{ fontSize: "1.2rem" }}>
											{parent.firstName} {parent.lastName}{" "}
										</strong>
										<If condition={!!parent.relationshipType}>
											<span style={{ color: "#777" }}>
												{EnrollmentApplicationParentFormat.relationshipType(parent)}
											</span>
										</If>
									</Section.Header>
									<Section.Body style={{ padding: "0.25rem 0.5rem" }}>
										<Grid columns={2} gap="0.5rem">
											<If condition={!!parent.homePhone}>
												<span>
													Home: <ExternalLink href={`tel:${parent.homePhone}`}>{parent.homePhone}</ExternalLink>
												</span>
											</If>
											<If condition={!!parent.mobilePhone}>
												<span>
													Mobile: <ExternalLink href={`tel:${parent.mobilePhone}`}>{parent.mobilePhone}</ExternalLink>
												</span>
											</If>
											<If condition={!!parent.workPhone}>
												<span>
													Work: <ExternalLink href={`tel:${parent.workPhone}`}>{parent.workPhone}</ExternalLink>
												</span>
											</If>
											<If condition={!!parent.email}>
												<span>
													Email: <ExternalLink href={`mailto:${parent.email}`}>{parent.email}</ExternalLink>
												</span>
											</If>
										</Grid>
									</Section.Body>
								</Section>
							))}
						</Column>
					</Tooltip.Body>
				</Tooltip>
			</Tooltip.Container>
		</div>
	);
}

type EnrollmentApplicationExternalLinkProps = {
	enrollmentApplication: Pick<EnrollmentApplicationDetailQuery["enrollmentApplication"], "id" | "externalLink">;
};

function EnrollmentApplicationExternalLink({ enrollmentApplication }: EnrollmentApplicationExternalLinkProps) {
	const { isTooltipOpen, toggleIsTooltipOpen, closeTooltip } = Tooltip.useToggle(false);
	const ref = React.useRef<HTMLDivElement | null>(null);
	useClickOutListener(ref.current, closeTooltip);

	const { syncID, synchronize } = useSync();
	const onSuccess = React.useCallback(() => {
		closeTooltip();
		synchronize();
	}, [closeTooltip, synchronize]);

	return (
		<div ref={ref}>
			<Tooltip.Container isControlled isOpen={isTooltipOpen} side="bottom" style={{ marginBottom: "-1.5rem" }}>
				<Conditional>
					<If condition={enrollmentApplication.externalLink === ""}>
						<Button variant="link" size="small" onClick={toggleIsTooltipOpen}>
							Add HubSpot Link
						</Button>
					</If>
					<Else>
						<Column justify="spaced-start" align="center" verticalSpacing="0.1rem">
							<ExternalLink href={enrollmentApplication.externalLink}>
								<div style={{ width: "1.5rem", height: "fit-content" }}>
									<img
										src="https://cdn-icons-png.flaticon.com/512/5968/5968872.png"
										alt="HubSpot"
										style={{ width: "100%", height: "auto", display: "block" }}
									/>
								</div>
							</ExternalLink>
							<Button variant="link" size="small" onClick={toggleIsTooltipOpen}>
								Edit
							</Button>
						</Column>
					</Else>
				</Conditional>

				<Tooltip isInteractable className={styles["form-tooltip"]}>
					<Tooltip.Body>
						<EnrollmentApplicationExternalLinkForm
							key={syncID}
							enrollmentApplication={enrollmentApplication}
							onSuccess={onSuccess}
						/>
					</Tooltip.Body>
				</Tooltip>
			</Tooltip.Container>
		</div>
	);
}

type EnrollmentApplicationExternalLinkFormProps = {
	enrollmentApplication: Pick<EnrollmentApplicationDetailQuery["enrollmentApplication"], "id" | "externalLink">;
	onSuccess: () => void;
};

function EnrollmentApplicationExternalLinkForm({
	enrollmentApplication,
	onSuccess,
}: EnrollmentApplicationExternalLinkFormProps) {
	const formState = useFormState({
		initialFormValues: { externalLink: enrollmentApplication.externalLink },
		formType: FormType.Update,
		editMode: EditMode.WriteOnly,
	});
	const update = EnrollmentApplicationMutation.useUpdate(enrollmentApplication.id);
	const { applyUpdate } = useApplyUpdate(update);
	const onSubmit = React.useCallback<FormSubmitFunction<Pick<EnrollmentApplicationFormValues.Detail, "externalLink">>>(
		async (formState) => onUpdateSubmit(formState as any, applyUpdate),
		[applyUpdate],
	);
	useOnSuccess(formState, onSuccess);

	return (
		<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} noLocalStorage noChangesDialog>
			<Form.Column>
				<EnrollmentApplicationField.ExternalLink formState={formState} />
				<Form.ActionBar>
					<SubmitButton onClick={formState.onSubmitWrapper(onSubmit)} submissionStatus={formState.submissionStatus}>
						Save
					</SubmitButton>
				</Form.ActionBar>
			</Form.Column>
		</Form>
	);
}

type FormSubmissionDataSectionsProps = {
	student: EnrollmentApplicationStudentDetailQuery["enrollmentApplicationStudent"];
	submission: Pick<EnrollmentApplicationSubmission, "doesConsentToDataUse" | "signatureName" | "signatureDate"> & {
		enrollmentApplicationParents: EnrollmentApplicationParentDetailQuery["enrollmentApplicationParent"][];
	};
} & Partial<StyleProps>;

function FormSubmissionDataSections({ student, submission, style }: FormSubmissionDataSectionsProps) {
	const dataConsent = React.useMemo(() => pickKeys(submission, ["doesConsentToDataUse"]), [submission]);
	const signature = React.useMemo(() => pickKeys(submission, ["signatureName", "signatureDate"]), [submission]);

	return (
		<React.Fragment>
			<Section className={styles["form-submission-data-section"]} style={style}>
				<Section.Body>
					<Section>
						<Section.Body>
							<EnrollmentApplicationStudentForm.ControlledReadOnly
								enrollmentApplicationStudent={student}
								headingLevel={4}
							/>
						</Section.Body>
					</Section>
				</Section.Body>
			</Section>

			<SectionDivider />

			<Section className={styles["form-submission-data-section"]} style={style}>
				<Section.Header>
					<Heading.H2 noMargin>Parents/Guardians</Heading.H2>
				</Section.Header>
				<Section.Body>
					<Column justify="spaced-start">
						{submission.enrollmentApplicationParents.map((parent, i) => (
							<React.Fragment key={parent.id}>
								<If condition={i > 0}>
									<SectionDivider />
								</If>

								<Section>
									<Section.Header>
										<Row justify="spaced-start" align="center">
											<Heading.H3 noMargin>
												<Row justify="spaced-start" align="center">
													{EnrollmentApplicationParentFormat.name(parent)}

													<span style={{ color: "#777", fontSize: "1rem" }}>
														{EnrollmentApplicationParentFormat.relationshipType(parent)}
													</span>
												</Row>
											</Heading.H3>
										</Row>
									</Section.Header>
									<Section.Body>
										<EnrollmentApplicationParentForm.ControlledReadOnly
											enrollmentApplicationParent={parent}
											headingLevel={4}
											allParents={submission.enrollmentApplicationParents}
										/>
									</Section.Body>
								</Section>
							</React.Fragment>
						))}
					</Column>
				</Section.Body>
			</Section>

			<SectionDivider />

			<Section className={styles["form-submission-data-section"]} style={style}>
				<Section.Header>
					<Heading.H2 noMargin>Declarations</Heading.H2>
				</Section.Header>
				<Section.Body>
					<Column justify="spaced-start">
						<EnrollmentApplicationModules.DataConsentForm.ControlledReadOnly
							initialFormValues={dataConsent}
							fieldsStyle={{ width: "100%" }}
						/>
						<Section>
							<Section.Header>
								<Heading.H3 noMargin>Electronic Signature</Heading.H3>
							</Section.Header>
							<Section.Body>
								<EnrollmentApplicationModules.SignatureForm.ControlledReadOnly initialFormValues={signature} />
							</Section.Body>
						</Section>
					</Column>
				</Section.Body>
			</Section>
		</React.Fragment>
	);
}

function SectionDivider() {
	return <HR color="#eee" style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }} />;
}

type NotesProps = {
	notes: EnrollmentApplicationDetailQuery["enrollmentApplication"]["enrollmentApplicationNotes"];
	enrollmentApplicationID: EnrollmentApplication["id"];
};

function Notes({ notes, enrollmentApplicationID }: NotesProps) {
	const { syncID, synchronize } = useSync();

	return (
		<Column justify="spaced-start">
			{jsxJoin(
				notes.map((note) => <NoteDisplay key={note.id} note={note} />),
				<HR color="#eee" style={{ width: "10rem", marginLeft: "2.5rem" }} />,
			)}
			<NewNote key={syncID} enrollmentApplicationID={enrollmentApplicationID} onSuccess={synchronize} />
		</Column>
	);
}

const noteDeleteConfirmationButtonProps: Partial<ButtonProps> = { variant: "danger" };

type NoteDisplayProps = {
	note: EnrollmentApplicationDetailQuery["enrollmentApplication"]["enrollmentApplicationNotes"][0];
};

function NoteDisplay({ note }: NoteDisplayProps) {
	const author = {
		name: note.author.person?.name ?? note.author.email,
		imageURL: note.author.person?.imageURL ?? "",
	};

	const { user } = React.useContext(AuthenticationContext);
	const isAuthor = !!user && user.id === note.author.id;

	const { isOn: isEditing, turnOn: startEditing, turnOff: stopEditing } = useToggle();

	return (
		<Row justify="spaced-start">
			<div style={{ paddingTop: "0.25rem" }}>
				<Tooltip.Container>
					<Tooltip>
						<Tooltip.Body>
							<span style={{ whiteSpace: "nowrap" }}>{author.name}</span>
						</Tooltip.Body>
					</Tooltip>

					<PersonIcon person={author} imageSize="2rem" />
				</Tooltip.Container>
			</div>
			<Column justify="spaced-start" verticalSpacing="0.25rem">
				<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
					<span style={{ fontSize: "0.9rem", color: "#555" }}>{relativeTime(note.createdAt)}</span>
					<If condition={isAuthor}>
						<div>
							<Button variant="link" size="small" onClick={startEditing} disabled={isEditing}>
								Edit
							</Button>
						</div>
						<EnrollmentApplicationNoteDeleteButton
							instanceID={note.id}
							variant="link"
							size="small"
							confirmationContent="Are you sure you want to delete this note?"
							confirmButtonProps={noteDeleteConfirmationButtonProps}
						>
							Delete
						</EnrollmentApplicationNoteDeleteButton>
					</If>
				</Row>

				<Conditional>
					<If condition={isEditing}>
						<EditNote note={note} onSuccess={stopEditing} onCancel={stopEditing} />
					</If>
					<Else>
						<TextAreaFieldDisplay value="" formattedValue={note.body} style={{ fontSize: "1.1rem" }} />
					</Else>
				</Conditional>
			</Column>
		</Row>
	);
}

type EditNoteProps = {
	note: Pick<EnrollmentApplicationNote, "id" | "body">;
	onSuccess: () => void;
	onCancel: () => void;
};

function EditNote({ note, onSuccess, onCancel }: EditNoteProps) {
	// TODO refactor after forms update
	const formState = useFormState({
		initialFormValues: { body: note.body },
		validationDisplayPolicy: ValidationDisplayPolicy.none,
	});
	const update = EnrollmentApplicationNoteMutation.useUpdate(note.id);
	const { applyUpdate } = useApplyUpdate(update);
	const onSubmit = React.useCallback<FormSubmitFunction<Pick<EnrollmentApplicationNoteFormValues.Detail, "body">>>(
		async (formState) => onUpdateSubmit(formState as any, applyUpdate),
		[applyUpdate],
	);
	useOnSuccess(formState, onSuccess);

	return (
		<form noValidate onSubmit={preventDefault}>
			<Form.Column>
				<EnrollmentApplicationNoteField.Body formState={formState} noHeader />
				<Form.ActionBar>
					<Button variant="secondary" size="small" onClick={onCancel}>
						Cancel
					</Button>
					<SubmitButton
						size="small"
						onClick={formState.onSubmitWrapper(onSubmit)}
						submissionStatus={formState.submissionStatus}
						disabled={formState.formValues.body === ""}
					>
						Save
					</SubmitButton>
				</Form.ActionBar>
			</Form.Column>
		</form>
	);
}

type NewNoteProps = {
	enrollmentApplicationID: EnrollmentApplication["id"];
	onSuccess: () => void;
};

function NewNote({ enrollmentApplicationID, onSuccess }: NewNoteProps) {
	return (
		<div className="enrollment-application__new-note" style={{ marginLeft: "2.5rem" }}>
			<EnrollmentApplicationNoteForm.ControlledCreateOnEnrollmentApplication
				onSuccess={onSuccess}
				enrollmentApplicationID={enrollmentApplicationID}
			/>
		</div>
	);
}

type ArchiveButtonProps = {
	enrollmentApplicationID: EnrollmentApplication["id"];
	onSuccess: () => void;
} & Partial<StyleProps>;

function ArchiveButton({ enrollmentApplicationID, onSuccess, style }: ArchiveButtonProps) {
	const [updateEnrollmentApplication] = useEnrollmentApplicationUpdateMutation();
	const { addNotification } = useNotifications();

	const [isArchiving, setIsArchiving] = React.useState(false);
	const archive = React.useCallback(async () => {
		setIsArchiving(true);

		const { errors } = await updateEnrollmentApplication({
			variables: { id: enrollmentApplicationID, input: { isArchived: true } },
			update: (cache, result) => {
				if (!result.data?.updateEnrollmentApplication) {
					return;
				}

				cache.evict({
					id: cache.identify({
						id: enrollmentApplicationID,
						__typename: graphTypeNames.EnrollmentApplication,
					}),
				});
				cache.evict({ id: "ROOT_QUERY", fieldName: "enrollmentApplicationConnection" });
				cache.gc();
			},
		});

		if (errors) {
			addNotification(ErrorNotification, 3000);
		} else {
			addNotification(SuccessNotification, 2000);
			onSuccess();
		}
		setIsArchiving(false);
	}, [updateEnrollmentApplication, enrollmentApplicationID, addNotification, onSuccess]);

	return (
		<ButtonWithConfirmation
			variant="danger"
			onClick={archive}
			confirmationContent="Are you sure? This application will no longer be viewable."
			isLoading={isArchiving}
			style={style}
		>
			<span style={{ whiteSpace: "nowrap" }}>Archive</span>
		</ButtonWithConfirmation>
	);
}
