import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { BusPlanOrderFormValues } from "../../../../Utilities";

export type BusPlanOrderFormControllerProps = {
	formState: FormState<BusPlanOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the BusPlan model.
 */
export function BusPlanOrderFormController({ formState }: BusPlanOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={BusPlanOrderFormValues.fieldOptions}
			fieldTypeMapping={BusPlanOrderFormValues.fieldTypeMapping}
		/>
	);
}
