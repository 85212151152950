import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	NoteReactionForm,
	NoteReactionFormValues,
	NoteReactionMutation,
	useNoteReactionDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { noteReactionHubDetailPageInfo } from "./pageinfo";

function NoteReactionHubDetailPageWrapper() {
	// Manages identity of the NoteReactionHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <NoteReactionHubDetailPage key={id} />;
}

export { NoteReactionHubDetailPageWrapper as NoteReactionHubDetailPage };

function NoteReactionHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useNoteReactionDetailQuery({ variables: { id } });
	const noteReaction = data?.noteReaction;
	useActivePageRegistration(
		noteReactionHubDetailPageInfo,
		!!noteReaction ? noteReactionHubDetailPageInfo.title(noteReaction) : "",
	);

	const update = NoteReactionMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<NoteReactionFormValues.Detail>,
			initialFormValues: NoteReactionFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading noteReaction</If>
				<Else>
					{!!noteReaction && (
						<React.Fragment>
							<Heading level={1}>{noteReaction.id}</Heading>

							<NoteReactionForm.ControlledDetail
								key={syncID}
								noteReaction={noteReaction}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
