import { newPageInfo } from "@hex-insights/router";
import { noteCommentHubPageInfo } from "../pageinfo";

export const noteCommentHubIndexPageInfo = newPageInfo({
	name: "Note Comments Index",
	title: "",
	description: "Index of Note Comments.",
	exact: true,
	to: noteCommentHubPageInfo.to,
	path: noteCommentHubPageInfo.path,
	parent: noteCommentHubPageInfo,
});
