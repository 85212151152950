import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, If, Row } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	FieldIf,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubFormField,
	SubmissionStatus,
	SubmitButton,
} from "@hex-insights/forms";
import {
	NoteAttachmentFormValues,
	NoteDetailQuery,
	NoteFormConversion,
	NoteFormState,
	NoteFormValues,
} from "../../../../Utilities";
import * as Field from "./Field";
import { NoteAttachmentItemForm } from "./NoteAttachment";

export * as Academic from "./Academic";
export * as Announcement from "./Announcement";
export * as Attendance from "./Attendance";
export * as Behavior from "./Behavior";
export * as Financial from "./Financial";
export * as General from "./General";
export * as Health from "./Health";
export * as Incident from "./Incident";
export * as Injury from "./Injury";
export * as SocialEmotional from "./SocialEmotional";

export const dateFieldNames: (keyof NoteFormValues.Base)[] = ["startDate", "endDate"];

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<NoteFormValues.Create>;
	onSuccess: () => void;
};

/**
 * Renders the create form of the Note model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = NoteFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<NoteFormValues.Create>;
};

/**
 * Renders the create form of the Note model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<NoteFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="note.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Field.NoteType formState={formState} formType={FormType.Create} />
				<Field.Title formState={formState} formType={FormType.Create} />
				<Field.Body formState={formState} formType={FormType.Create} />
				<Field.IsTimeDependent formState={formState} formType={FormType.Create} />
				<FieldIf formState={formState} names={dateFieldNames} condition={formState.formValues.isTimeDependent}>
					<Field.StartDate formState={formState} formType={FormType.Create} />
					<Field.EndDate formState={formState} formType={FormType.Create} />
				</FieldIf>
				<SubFormField formState={formState} name="attachments" blankItem={NoteAttachmentFormValues.blankItemInNote}>
					{(props) => <NoteAttachmentItemForm {...props} />}
					{({ onClick, disabled }) => (
						<div style={{ marginTop: "0.75rem" }}>
							<Button variant="tertiary" size="small" onClick={onClick} disabled={disabled}>
								Add Attachment
							</Button>
						</div>
					)}
				</SubFormField>

				<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
					Submit
				</SubmitButton>
			</Column>
		</Form>
	);
}

export type ControlledDetailProps = {
	note: NoteDetailQuery["note"];
	applyUpdate: ApplyUpdateFunction<NoteFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the Note model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(() => NoteFormConversion.toFormValues(props.note), [props.note]);
	const formState = NoteFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<NoteFormValues.Detail>;
};

/**
 * Renders the detail form of the Note model using the given form state.
 */
export function Detail({ formState, note, applyUpdate, onSuccess }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<NoteFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form name={`note.detail.${note.id}`} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.NoteType formState={formState} formType={FormType.Update} id={note.id} />
				<Field.Author formState={formState} formType={FormType.Update} id={note.id} currentAuthor={note.author} />
				<Field.Title formState={formState} formType={FormType.Update} id={note.id} />
				<Field.Body formState={formState} formType={FormType.Update} id={note.id} />
				<Field.CreatedAt formState={formState} formType={FormType.Update} id={note.id} />
				<Field.IsTimeDependent formState={formState} formType={FormType.Update} id={note.id} />
				<FieldIf formState={formState} names={dateFieldNames} condition={formState.formValues.isTimeDependent}>
					<Field.StartDate formState={formState} formType={FormType.Update} id={note.id} />
					<Field.EndDate formState={formState} formType={FormType.Update} id={note.id} />
				</FieldIf>
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	note: NoteDetailQuery["note"];
};

/**
 * Renders a read-only detail form of the Note model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(() => NoteFormConversion.toFormValues(props.note), [props.note]);
	const formState = NoteFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<NoteFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the Note model using the given form state.
 */
export function ReadOnly({ formState, note }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.NoteType formState={formState} formType={FormType.Update} id={note.id} />
			<Field.Author formState={formState} formType={FormType.Update} id={note.id} currentAuthor={note.author} />
			<Field.Title formState={formState} formType={FormType.Update} id={note.id} />
			<Field.Body formState={formState} formType={FormType.Update} id={note.id} />
			<Field.CreatedAt formState={formState} formType={FormType.Update} id={note.id} />
			<Field.IsTimeDependent formState={formState} formType={FormType.Update} id={note.id} />
			<FieldIf formState={formState} names={dateFieldNames} condition={formState.formValues.isTimeDependent}>
				<Field.StartDate formState={formState} formType={FormType.Update} id={note.id} />
				<Field.EndDate formState={formState} formType={FormType.Update} id={note.id} />
			</FieldIf>
		</Column>
	);
}
