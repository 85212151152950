import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import {
	SchoolStudentEnrollmentForm,
	SchoolStudentEnrollmentFormat,
	useSchoolStudentEnrollmentDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { schoolStudentEnrollmentHubDetailPageInfo } from "./pageinfo";

export function SchoolStudentEnrollmentHubDetailPage() {
	const { schoolStudentEnrollmentID } = schoolStudentEnrollmentHubDetailPageInfo.useParams();

	const { loading, data, error } = useSchoolStudentEnrollmentDetailQuery({
		variables: { id: schoolStudentEnrollmentID },
	});
	const schoolStudentEnrollment = data?.schoolStudentEnrollment;
	useActivePageRegistration(
		schoolStudentEnrollmentHubDetailPageInfo,
		!!schoolStudentEnrollment ? schoolStudentEnrollmentHubDetailPageInfo.title(schoolStudentEnrollment) : "",
	);

	const { syncID, synchronize } = useSync();

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading school student enrollment</If>
				<Else>
					{!!schoolStudentEnrollment && (
						<React.Fragment>
							<Heading.H1>{SchoolStudentEnrollmentFormat.name(schoolStudentEnrollment)}</Heading.H1>

							<SchoolStudentEnrollmentForm.ControlledDetail
								key={syncID}
								schoolStudentEnrollment={schoolStudentEnrollment}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
