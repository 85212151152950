import { newPageInfo } from "@hex-insights/router";
import { expenseHubPageInfo } from "../pageinfo";

export const expenseHubCreatePageInfo = newPageInfo({
	name: "New Expense",
	title: "New",
	description: "Create a new Expense record.",
	exact: true,
	to: expenseHubPageInfo.to + "/new",
	path: expenseHubPageInfo.path + "/new",
	parent: expenseHubPageInfo,
});
