import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const documentResourceUserViewRecordHubPageInfo = newPageInfo({
	title: "Document Resource User View Records",
	description: "Pages related to the Document Resource User View Record model.",
	exact: false,
	to: hubsPageInfo.to + "/document-resource-user-view-records",
	path: hubsPageInfo.path + "/document-resource-user-view-records",
	parent: hubsPageInfo,
});
