import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { LearningObjectiveHubCreatePage } from "./CreatePage";
import { learningObjectiveHubCreatePageInfo } from "./CreatePage/pageinfo";
import { LearningObjectiveHubDetailPage } from "./DetailPage";
import { learningObjectiveHubDetailPageInfo } from "./DetailPage/pageinfo";
import { LearningObjectiveHubIndexPage } from "./IndexPage";
import { learningObjectiveHubIndexPageInfo } from "./IndexPage/pageinfo";
import { learningObjectiveHubPageInfo } from "./pageinfo";

export function LearningObjectiveHub() {
	useActivePageRegistration(learningObjectiveHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...learningObjectiveHubIndexPageInfo.routeProps}>
				<LearningObjectiveHubIndexPage />
			</Route>
			<Route {...learningObjectiveHubCreatePageInfo.routeProps}>
				<LearningObjectiveHubCreatePage />
			</Route>
			<Route {...learningObjectiveHubDetailPageInfo.routeProps}>
				<LearningObjectiveHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
