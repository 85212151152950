import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { NoteAttachmentOrderFormValues } from "../../../../Utilities";

export type NoteAttachmentOrderFormControllerProps = {
	formState: FormState<NoteAttachmentOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the NoteAttachment model.
 */
export function NoteAttachmentOrderFormController({ formState }: NoteAttachmentOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={NoteAttachmentOrderFormValues.fieldOptions}
			fieldTypeMapping={NoteAttachmentOrderFormValues.fieldTypeMapping}
		/>
	);
}
