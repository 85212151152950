import { newPageInfo } from "@hex-insights/router";
import { lessonPlanHubPageInfo } from "../pageinfo";

export const lessonPlanHubCreatePageInfo = newPageInfo({
	name: "New Lesson Plan",
	title: "New",
	description: "Create a new Lesson Plan record.",
	exact: true,
	to: lessonPlanHubPageInfo.to + "/new",
	path: lessonPlanHubPageInfo.path + "/new",
	parent: lessonPlanHubPageInfo,
});
