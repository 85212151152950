import { IndexForms } from "@hex-insights/app-modules";
import { ContractFilterFormValues } from "../../../../Utilities";

export type ContractFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, ContractFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Contract model.
 */
export function ContractFilterFormController(props: ContractFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={ContractFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `ContractFilterForm`
