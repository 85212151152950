import React from "react";
import { Column } from "@hex-insights/core";
import { FormState, FormType } from "@hex-insights/forms";
import {
	MFAFactorDetailQuery,
	MFAFactorFormConversion,
	MFAFactorFormState,
	MFAFactorFormValues,
} from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledReadOnlyProps = {
	mfaFactor: MFAFactorDetailQuery["mfaFactor"];
};

/**
 * Renders a read-only detail form of the MFAFactor model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => MFAFactorFormConversion.toFormValues(props.mfaFactor),
		[props.mfaFactor],
	);
	const formState = MFAFactorFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<MFAFactorFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the MFAFactor model using the given form state.
 */
export function ReadOnly({ formState, mfaFactor }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.CreatedAt formState={formState} formType={FormType.Update} id={mfaFactor.id} />
			<Field.Count formState={formState} formType={FormType.Update} id={mfaFactor.id} />
			<Field.IsApp formState={formState} formType={FormType.Update} id={mfaFactor.id} />
			<Field.Email formState={formState} formType={FormType.Update} id={mfaFactor.id} />
			<Field.Sms formState={formState} formType={FormType.Update} id={mfaFactor.id} />
			<Field.IsVerified formState={formState} formType={FormType.Update} id={mfaFactor.id} />
			<Field.User formState={formState} formType={FormType.Update} id={mfaFactor.id} currentUser={mfaFactor.user} />
		</Column>
	);
}
