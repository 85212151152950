import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, Icon, If, Row, useUpdateEffect } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubFormField,
	SubFormRenderProps,
	SubmissionStatus,
	SubmitButton,
} from "@hex-insights/forms";
import {
	PaymentDetailQuery,
	PaymentFormConversion,
	PaymentFormState,
	PaymentFormValues,
	Student,
} from "../../../../Utilities";
import { PaymentLineItemField } from "../PaymentLineItem";
import * as Field from "./Field";
import styles from "./styles.module.css";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<PaymentFormValues.Create>;
	onSuccess: () => void;
};

/**
 * Renders the create form of the Payment model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = PaymentFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<PaymentFormValues.Create>;
	formNameSuffix?: string;
	studentID?: Student["id"] | null;
};

/**
 * Renders the create form of the Payment model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess, formNameSuffix = "", studentID }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<PaymentFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name={"payment.create" + formNameSuffix} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Form.Column>
				<Field.Parent
					formState={formState}
					formType={FormType.Create}
					queryVariables={
						studentID
							? {
									filters: { relationships: [{ studentIDEQ: studentID }] },
							  }
							: undefined
					}
				/>
				<Field.CreatedDate formState={formState} formType={FormType.Create} />
				<Field.PaymentMethod formState={formState} formType={FormType.Create} />
				<SubFormField
					formState={formState}
					name="paymentLineItems"
					blankItem={PaymentFormValues.blankPaymentLineItem}
					minItems={1}
					className={styles["create__payment-line-items"]}
				>
					{(props) => <PaymentLineItemFields {...props} studentID={studentID} />}
					{({ onClick, disabled }) => (
						<Row justify="center" style={{ padding: "0.5rem 0" }}>
							<Button variant="secondary" size="small" onClick={onClick} disabled={disabled}>
								Add Line Item
							</Button>
						</Row>
					)}
				</SubFormField>
				<Field.Notes formState={formState} formType={FormType.Create} className={styles["create__notes"]} />

				<Form.ActionBar>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Form.ActionBar>
			</Form.Column>
		</Form>
	);
}

type PaymentLineItemFieldsProps = SubFormRenderProps<PaymentFormValues.Create["paymentLineItems"][0]> & {
	studentID?: Student["id"] | null;
};

function PaymentLineItemFields({ formState, onRemoveClick, studentID }: PaymentLineItemFieldsProps) {
	const { invoiceID: clearInvoiceID } = formState.formClearFunctions;
	useUpdateEffect(() => {
		clearInvoiceID();
	}, [studentID, clearInvoiceID]);

	return (
		<Row justify="spaced-start" align="flex-end" className={styles["create__payment-line-items__item"]}>
			<PaymentLineItemField.Invoice
				formState={formState}
				formType={FormType.Create}
				queryVariables={{
					filters: {
						contractInstallment: [
							{
								contract: [
									{
										student: [
											// This student and siblings
											{
												relationships: [
													{
														parent: [{ relationships: [{ studentIDEQ: studentID ?? "0" }] }],
													},
												],
											},
										],
									},
								],
							},
						],
					},
				}}
			/>
			<PaymentLineItemField.Amount formState={formState} formType={FormType.Create} />
			<Button
				variant="tertiary"
				size="small"
				onClick={onRemoveClick}
				disabled={formState.disabled}
				style={{ marginBottom: "0.5rem" }}
			>
				<Icon.Trash size="1rem" style={{ display: "block" }} />
			</Button>
		</Row>
	);
}

export type ControlledDetailProps = {
	payment: PaymentDetailQuery["payment"];
	applyUpdate: ApplyUpdateFunction<PaymentFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the Payment model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(() => PaymentFormConversion.toFormValues(props.payment), [props.payment]);
	const formState = PaymentFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<PaymentFormValues.Detail>;
};

/**
 * Renders the detail form of the Payment model using the given form state.
 */
export function Detail({ formState, payment, applyUpdate, onSuccess }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<PaymentFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form name={`payment.detail.${payment.id}`} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.CreatedDate formState={formState} formType={FormType.Update} id={payment.id} />
				<Field.PaymentMethod formState={formState} formType={FormType.Update} id={payment.id} />
				<Field.Notes formState={formState} formType={FormType.Update} id={payment.id} />
				<Field.Parent formState={formState} formType={FormType.Update} id={payment.id} currentParent={payment.parent} />
				<Field.PaymentLineItems
					formState={formState}
					formType={FormType.Update}
					id={payment.id}
					currentPaymentLineItems={payment.paymentLineItems}
				/>
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	payment: PaymentDetailQuery["payment"];
};

/**
 * Renders a read-only detail form of the Payment model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(() => PaymentFormConversion.toFormValues(props.payment), [props.payment]);
	const formState = PaymentFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<PaymentFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the Payment model using the given form state.
 */
export function ReadOnly({ formState, payment }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.CreatedDate formState={formState} formType={FormType.Update} id={payment.id} />
			<Field.PaymentMethod formState={formState} formType={FormType.Update} id={payment.id} />
			<Field.Notes formState={formState} formType={FormType.Update} id={payment.id} />
			<Field.Parent formState={formState} formType={FormType.Update} id={payment.id} currentParent={payment.parent} />
			<Field.PaymentLineItems
				formState={formState}
				formType={FormType.Update}
				id={payment.id}
				currentPaymentLineItems={payment.paymentLineItems}
			/>
		</Column>
	);
}
