import { newPageInfo } from "@hex-insights/router";
import { invoiceHubPageInfo } from "../pageinfo";

export const invoiceHubCreatePageInfo = newPageInfo({
	name: "New Invoice",
	title: "New",
	description: "Create a new Invoice record.",
	exact: true,
	to: invoiceHubPageInfo.to + "/new",
	path: invoiceHubPageInfo.path + "/new",
	parent: invoiceHubPageInfo,
});
