import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	ReportCardForm,
	ReportCardFormValues,
	ReportCardMutation,
	useReportCardDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { reportCardHubDetailPageInfo } from "./pageinfo";

function ReportCardHubDetailPageWrapper() {
	// Manages identity of the ReportCardHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <ReportCardHubDetailPage key={id} />;
}

export { ReportCardHubDetailPageWrapper as ReportCardHubDetailPage };

function ReportCardHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useReportCardDetailQuery({ variables: { id } });
	const reportCard = data?.reportCard;
	useActivePageRegistration(
		reportCardHubDetailPageInfo,
		!!reportCard ? reportCardHubDetailPageInfo.title(reportCard) : "",
	);

	const update = ReportCardMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<ReportCardFormValues.Detail>, initialFormValues: ReportCardFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading reportCard</If>
				<Else>
					{!!reportCard && (
						<React.Fragment>
							<Heading level={1}>{reportCard.id}</Heading>

							<ReportCardForm.ControlledDetail
								key={syncID}
								reportCard={reportCard}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
