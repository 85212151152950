import { IndexForms } from "@hex-insights/app-modules";
import { CourseSectionPeriodOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the CourseSectionPeriod model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		CourseSectionPeriodOrderFormValues.initial,
		CourseSectionPeriodOrderFormValues.fieldValues,
	);
}
