import "@hex-insights/core";
import { MFAFactorDetailQuery } from "../../GraphQL";
import { MFAFactorFormValues } from "../ModelFormValues";

/**
 * Converts a MFAFactor instance to its corresponding detail form values.
 *
 * @param mfaFactor The instance to convert.
 */
export function toFormValues(mfaFactor: MFAFactorDetailQuery["mfaFactor"]): MFAFactorFormValues.Detail {
	return {
		createdAt: mfaFactor.createdAt,
		count: mfaFactor.count,
		isApp: mfaFactor.isApp,
		email: mfaFactor.email,
		sms: mfaFactor.sms,
		isVerified: mfaFactor.isVerified,
		userID: mfaFactor.user?.id ?? null,
	};
}
