import { CaseStyle, convertCase } from "@hex-insights/core";
import { EnrollmentApplicationParent } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the formIdentifier field of the EnrollmentApplicationParent model.
 */
export function formIdentifier(value: EnrollmentApplicationParent["formIdentifier"]): string {
	return toString(value);
}

/**
 * Formats the name field of the EnrollmentApplicationParent model.
 */
export function name(value: EnrollmentApplicationParent["name"]): string {
	return toString(value);
}

/**
 * Formats the firstName field of the EnrollmentApplicationParent model.
 */
export function firstName(value: EnrollmentApplicationParent["firstName"]): string {
	return toString(value);
}

/**
 * Formats the lastName field of the EnrollmentApplicationParent model.
 */
export function lastName(value: EnrollmentApplicationParent["lastName"]): string {
	return toString(value);
}

/**
 * Formats the nationality field of the EnrollmentApplicationParent model.
 */
export function nationality(value: EnrollmentApplicationParent["nationality"]): string {
	return toString(value);
}

/**
 * Formats the occupation field of the EnrollmentApplicationParent model.
 */
export function occupation(value: EnrollmentApplicationParent["occupation"]): string {
	return toString(value);
}

/**
 * Formats the relationshipType field of the EnrollmentApplicationParent model.
 */
export function relationshipType(value: EnrollmentApplicationParent["relationshipType"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return convertCase(value, CaseStyle.Title);
}

/**
 * Formats the relationshipTypeCustom field of the EnrollmentApplicationParent model.
 */
export function relationshipTypeCustom(value: EnrollmentApplicationParent["relationshipTypeCustom"]): string {
	return toString(value);
}

/**
 * Formats the isPrimaryContact field of the EnrollmentApplicationParent model.
 */
export function isPrimaryContact(value: EnrollmentApplicationParent["isPrimaryContact"]): string {
	return toString(value);
}

/**
 * Formats the livesWithStudent field of the EnrollmentApplicationParent model.
 */
export function livesWithStudent(value: EnrollmentApplicationParent["livesWithStudent"]): string {
	return toString(value);
}

/**
 * Formats the isAuthorizedForPickup field of the EnrollmentApplicationParent model.
 */
export function isAuthorizedForPickup(value: EnrollmentApplicationParent["isAuthorizedForPickup"]): string {
	return toString(value);
}

/**
 * Formats the isEmergencyContact field of the EnrollmentApplicationParent model.
 */
export function isEmergencyContact(value: EnrollmentApplicationParent["isEmergencyContact"]): string {
	return toString(value);
}

/**
 * Formats the isFinancialContact field of the EnrollmentApplicationParent model.
 */
export function isFinancialContact(value: EnrollmentApplicationParent["isFinancialContact"]): string {
	return toString(value);
}

/**
 * Formats the addressSameAsFormIdentifier field of the EnrollmentApplicationParent model.
 */
export function addressSameAsFormIdentifier(value: EnrollmentApplicationParent["addressSameAsFormIdentifier"]): string {
	return toString(value);
}

/**
 * Formats the addressLine1 field of the EnrollmentApplicationParent model.
 */
export function addressLine1(value: EnrollmentApplicationParent["addressLine1"]): string {
	return toString(value);
}

/**
 * Formats the addressLine2 field of the EnrollmentApplicationParent model.
 */
export function addressLine2(value: EnrollmentApplicationParent["addressLine2"]): string {
	return toString(value);
}

/**
 * Formats the city field of the EnrollmentApplicationParent model.
 */
export function city(value: EnrollmentApplicationParent["city"]): string {
	return toString(value);
}

/**
 * Formats the stateOrProvince field of the EnrollmentApplicationParent model.
 */
export function stateOrProvince(value: EnrollmentApplicationParent["stateOrProvince"]): string {
	return toString(value);
}

/**
 * Formats the postalCode field of the EnrollmentApplicationParent model.
 */
export function postalCode(value: EnrollmentApplicationParent["postalCode"]): string {
	return toString(value);
}

/**
 * Formats the country field of the EnrollmentApplicationParent model.
 */
export function country(value: EnrollmentApplicationParent["country"]): string {
	return toString(value);
}

/**
 * Formats the homePhone field of the EnrollmentApplicationParent model.
 */
export function homePhone(value: EnrollmentApplicationParent["homePhone"]): string {
	return toString(value);
}

/**
 * Formats the workPhone field of the EnrollmentApplicationParent model.
 */
export function workPhone(value: EnrollmentApplicationParent["workPhone"]): string {
	return toString(value);
}

/**
 * Formats the mobilePhone field of the EnrollmentApplicationParent model.
 */
export function mobilePhone(value: EnrollmentApplicationParent["mobilePhone"]): string {
	return toString(value);
}

/**
 * Formats the email field of the EnrollmentApplicationParent model.
 */
export function email(value: EnrollmentApplicationParent["email"]): string {
	return toString(value);
}
