import { newPageInfo } from "@hex-insights/router";
import { employeeHubPageInfo } from "../pageinfo";

export const employeeHubIndexPageInfo = newPageInfo({
	name: "Employees Index",
	title: "",
	description: "Index of Employees.",
	exact: true,
	to: employeeHubPageInfo.to,
	path: employeeHubPageInfo.path,
	parent: employeeHubPageInfo,
});
