import "@hex-insights/core";
import { MFAInvalidOTPAttemptDetailQuery } from "../../GraphQL";
import { MFAInvalidOTPAttemptFormValues } from "../ModelFormValues";

/**
 * Converts a MFAInvalidOTPAttempt instance to its corresponding detail form values.
 *
 * @param mfaInvalidOTPAttempt The instance to convert.
 */
export function toFormValues(
	mfaInvalidOTPAttempt: MFAInvalidOTPAttemptDetailQuery["mfaInvalidOTPAttempt"],
): MFAInvalidOTPAttemptFormValues.Detail {
	return {
		createdAt: mfaInvalidOTPAttempt.createdAt,
		sessionID: mfaInvalidOTPAttempt.session?.id ?? null,
		userID: mfaInvalidOTPAttempt.user?.id ?? null,
	};
}
