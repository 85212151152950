import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	PermissionGroupFilterFormController,
	PermissionGroupFilterFormState,
	PermissionGroupFilterFormUtils,
	PermissionGroupFormat,
	PermissionGroupIndexQuery,
	PermissionGroupOrderField,
	PermissionGroupOrderFormController,
	PermissionGroupOrderFormState,
	PermissionGroupOrderFormValues,
	PermissionGroupPaginationForm,
	PermissionGroupSearchForm,
	usePermissionGroupIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { permissionGroupHubCreatePageInfo } from "../CreatePage/pageinfo";
import { permissionGroupHubDetailPageInfo } from "../DetailPage/pageinfo";
import { permissionGroupHubIndexPageInfo } from "./pageinfo";

const columns: (keyof PermissionGroupIndexQuery["permissionGroupConnection"]["edges"][0]["node"])[] = [
	"name",
	"description",
];

function orderFieldToColumn(field: PermissionGroupOrderField | null) {
	if (field === null) {
		return "id";
	}
	return PermissionGroupOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return PermissionGroupOrderFormValues.modelFieldToOrderField[column];
}

export function PermissionGroupHubIndexPage() {
	useActivePageRegistration(permissionGroupHubIndexPageInfo);

	const filterFormState = PermissionGroupFilterFormState.useFormState();
	const orderFormState = PermissionGroupOrderFormState.useFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(
		filterFormState.formValues,
		PermissionGroupFilterFormUtils.toFilterInputs,
	);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = usePermissionGroupIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		PermissionGroupFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = PermissionGroupFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.permissionGroupConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading level={1} noMargin>
					Permission Groups
				</Heading>

				<InternalLink to={permissionGroupHubCreatePageInfo.to}>New Permission Group</InternalLink>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<PermissionGroupSearchForm formState={filterFormState} />
				<PermissionGroupFilterFormController formState={filterFormState} numActiveFilters={numActiveFilters} />
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<PermissionGroupOrderFormController formState={orderFormState} />
			</Row>

			<Table.Container className="hub__index-page__table__container">
				<Table columns={columns} minColumnWidth="10rem" {...tableSortingProps} className="hub__index-page__table">
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="name">Name</Table.Heading>
							<Table.Heading column="description" noSort>
								Description
							</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.permissionGroupConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="name" oneLine>
											<InternalLink to={permissionGroupHubDetailPageInfo.to(e.node.id)}>
												{PermissionGroupFormat.Fields.name(e.node.name)}
											</InternalLink>
										</Table.Cell>
										<Table.Cell column="description" oneLine>
											{PermissionGroupFormat.Fields.description(e.node.description)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<PermissionGroupPaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.permissionGroupConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
