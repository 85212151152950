import { newPageInfo } from "@hex-insights/router";
import { reportCardHubPageInfo } from "../pageinfo";

export const reportCardHubIndexPageInfo = newPageInfo({
	name: "Report Cards Index",
	title: "",
	description: "Index of Report Cards.",
	exact: true,
	to: reportCardHubPageInfo.to,
	path: reportCardHubPageInfo.path,
	parent: reportCardHubPageInfo,
});
