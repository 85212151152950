import { newPageInfo } from "@hex-insights/router";
import { ReportCard } from "@hex-insights/verita.shared";
import { reportCardHubPageInfo } from "../pageinfo";

type ReportCardForRouteTitle = Pick<ReportCard, "id">;

export const reportCardHubDetailPageInfo = newPageInfo({
	name: "Report Card Detail",
	title: (e: ReportCardForRouteTitle) => e.id,
	description: "Detail page for an reportCard.",
	exact: false,
	isLeaf: true,
	to: (id: ReportCard["id"]) => reportCardHubPageInfo.to + `/${id}`,
	path: reportCardHubPageInfo.path + "/:id(\\d+)",
	parent: reportCardHubPageInfo,
});
