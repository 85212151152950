import React from "react";
import {
	BooleanField,
	FieldDisplayArgs,
	RadioButtonsInput,
	RadioField,
	SelectField,
	TextField,
} from "@hex-insights/forms";
import {
	AcademicYearSelect,
	BillingSetupSubmissionFormValues,
	StudentSelect,
	useAcademicYearSelectLazyQuery,
	useStudentSelectLazyQuery,
	validateEmail,
} from "../../../../Utilities";
import { AcademicYearLink, StudentLink } from "../../../Links";
import { PhoneInput } from "../../../PhoneInput";
import { FutureBaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the BillingSetupSubmission model.
 */
type FieldProps<K extends keyof BillingSetupSubmissionFormValues.Base = keyof BillingSetupSubmissionFormValues.Base> =
	FutureBaseFieldProps<Pick<BillingSetupSubmissionFormValues.Base, K>>;

/**
 * Renders a field component for the `numPayments` field of the BillingSetupSubmission model.
 */
export function NumPayments({ formState, ...props }: FieldProps<"numPayments">) {
	return (
		<RadioField
			formState={formState}
			name="numPayments"
			label="How would you like to pay?"
			options={BillingSetupSubmissionFormValues.numPaymentsOptions}
			Input={RadioButtonsInput}
			blankValue={null}
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `isInterestedInBusPlan` field of the BillingSetupSubmission model.
 */
export function IsInterestedInBusPlan({
	formState,
	label = "Are you interested in bus pickup and dropoff service?",
	...props
}: FieldProps<"isInterestedInBusPlan">) {
	return <BooleanField formState={formState} name="isInterestedInBusPlan" label={label} {...props} />;
}

/**
 * Renders a field component for the `isInterestedInMealPlan` field of the BillingSetupSubmission model.
 */
export function IsInterestedInMealPlan({
	formState,
	label = "Are you interested in a meal plan?",
	...props
}: FieldProps<"isInterestedInMealPlan">) {
	return <BooleanField formState={formState} name="isInterestedInMealPlan" label={label} {...props} />;
}

/**
 * Renders a field component for the `authorName` field of the BillingSetupSubmission model.
 */
export function AuthorName({ formState, ...props }: FieldProps<"authorName">) {
	return <TextField formState={formState} name="authorName" {...props} />;
}

/**
 * Renders a field component for the `authorEmail` field of the BillingSetupSubmission model.
 */
export function AuthorEmail({ formState, ...props }: FieldProps<"authorEmail">) {
	return <TextField formState={formState} name="authorEmail" immediateValidation={validateEmail} {...props} />;
}

/**
 * Renders a field component for the `authorPhone` field of the BillingSetupSubmission model.
 */
export function AuthorPhone({ formState, ...props }: FieldProps<"authorPhone">) {
	return <TextField formState={formState} name="authorPhone" optional Input={PhoneInput} {...props} />;
}

export type AcademicYearProps = FieldProps<"academicYearID"> & {
	currentAcademicYear?: AcademicYearSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `academicYear` edge of the BillingSetupSubmission model.
 */
export function AcademicYear({ formState, currentAcademicYear, ...props }: AcademicYearProps) {
	const [loadOptions, { loading, data }] = useAcademicYearSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.academicYearID) {
			loadOptions();
		}
	}, [formState.formEditing.academicYearID, loadOptions]);
	const options = React.useMemo(
		() => AcademicYearSelect.toOptions(data?.academicYearConnection.edges, currentAcademicYear),
		[data, currentAcademicYear],
	);

	return (
		<SelectField
			formState={formState}
			name="academicYearID"
			isLoading={loading}
			options={options}
			display={displayAcademicYear}
			blankValue={null}
			{...props}
		/>
	);
}

function displayAcademicYear({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <AcademicYearLink instance={{ id }}>{formattedValue}</AcademicYearLink>;
}

export type StudentProps = FieldProps<"studentID"> & {
	currentStudent?: StudentSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `student` edge of the BillingSetupSubmission model.
 */
export function Student({ formState, currentStudent, ...props }: StudentProps) {
	const [loadOptions, { loading, data }] = useStudentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.studentID) {
			loadOptions();
		}
	}, [formState.formEditing.studentID, loadOptions]);
	const options = React.useMemo(
		() => StudentSelect.toOptions(data?.studentConnection.edges, currentStudent),
		[data, currentStudent],
	);

	return (
		<SelectField
			formState={formState}
			name="studentID"
			isLoading={loading}
			options={options}
			display={displayStudent}
			blankValue={null}
			{...props}
		/>
	);
}

function displayStudent({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <StudentLink instance={{ id }}>{formattedValue}</StudentLink>;
}
