import React from "react";
import { BooleanField, DateTimeField, FieldDisplayArgs, FormType, SelectField } from "@hex-insights/forms";
import {
	AcademicYearSelect,
	ClassYearSelect,
	SchoolStudentEnrollmentFormValues,
	StudentSelect,
	useAcademicYearSelectLazyQuery,
	useClassYearSelectLazyQuery,
	useStudentSelectLazyQuery,
} from "../../../../Utilities";
import { AcademicYearLink, ClassYearLink, StudentLink } from "../../../Links";
import { FutureBaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the SchoolStudentEnrollment model.
 */
type FieldProps<K extends keyof SchoolStudentEnrollmentFormValues.Base = keyof SchoolStudentEnrollmentFormValues.Base> =
	FutureBaseFieldProps<Pick<SchoolStudentEnrollmentFormValues.Base, K>>;

/**
 * Renders a field component for the `createdDate` field of the SchoolStudentEnrollment model.
 */
export function CreatedDate({ formState, ...props }: FieldProps<"createdDate">) {
	return (
		<DateTimeField
			formState={formState}
			name="createdDate"
			optional={FormType.isCreate(formState.formType)}
			precision="day"
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `paymentDueDate` field of the SchoolStudentEnrollment model.
 */
export function PaymentDueDate({ formState, ...props }: FieldProps<"paymentDueDate">) {
	return (
		<DateTimeField
			formState={formState}
			name="paymentDueDate"
			optional={FormType.isCreate(formState.formType)}
			precision="day"
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `hasPaidFee` field of the SchoolStudentEnrollment model.
 */
export function HasPaidFee({ formState, ...props }: FieldProps<"hasPaidFee">) {
	return <BooleanField formState={formState} name="hasPaidFee" {...props} />;
}

/**
 * Renders a field component for the `isVoided` field of the SchoolStudentEnrollment model.
 */
export function IsVoided({ formState, ...props }: FieldProps<"isVoided">) {
	return <BooleanField formState={formState} name="isVoided" {...props} />;
}

export type AcademicYearProps = FieldProps<"academicYearID"> & {
	currentAcademicYear?: AcademicYearSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `academicYear` edge of the SchoolStudentEnrollment model.
 */
export function AcademicYear({ formState, currentAcademicYear, ...props }: AcademicYearProps) {
	const [loadOptions, { loading, data }] = useAcademicYearSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.academicYearID) {
			loadOptions();
		}
	}, [formState.formEditing.academicYearID, loadOptions]);
	const options = React.useMemo(
		() => AcademicYearSelect.toOptions(data?.academicYearConnection.edges, currentAcademicYear),
		[data, currentAcademicYear],
	);

	return (
		<SelectField
			formState={formState}
			name="academicYearID"
			isLoading={loading}
			options={options}
			display={displayAcademicYear}
			blankValue={null}
			{...props}
		/>
	);
}

function displayAcademicYear({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <AcademicYearLink instance={{ id }}>{formattedValue}</AcademicYearLink>;
}

export type ClassYearProps = FieldProps<"classYearID"> & {
	currentClassYear?: ClassYearSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `classYear` edge of the SchoolStudentEnrollment model.
 */
export function ClassYear({ formState, currentClassYear, ...props }: ClassYearProps) {
	const [loadOptions, { loading, data }] = useClassYearSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.classYearID) {
			loadOptions();
		}
	}, [formState.formEditing.classYearID, loadOptions]);
	const options = React.useMemo(
		() => ClassYearSelect.toOptions(data?.classYearConnection.edges, currentClassYear),
		[data, currentClassYear],
	);

	return (
		<SelectField
			formState={formState}
			name="classYearID"
			isLoading={loading}
			options={options}
			display={displayClassYear}
			blankValue={null}
			{...props}
		/>
	);
}

function displayClassYear({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ClassYearLink instance={{ id }}>{formattedValue}</ClassYearLink>;
}

export type StudentProps = FieldProps<"studentID"> & {
	currentStudent?: StudentSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `student` edge of the SchoolStudentEnrollment model.
 */
export function Student({ formState, currentStudent, ...props }: StudentProps) {
	const [loadOptions, { loading, data }] = useStudentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.studentID) {
			loadOptions();
		}
	}, [formState.formEditing.studentID, loadOptions]);
	const options = React.useMemo(
		() => StudentSelect.toOptions(data?.studentConnection.edges, currentStudent),
		[data, currentStudent],
	);

	return (
		<SelectField
			formState={formState}
			name="studentID"
			isLoading={loading}
			options={options}
			display={displayStudent}
			blankValue={null}
			{...props}
		/>
	);
}

function displayStudent({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <StudentLink instance={{ id }}>{formattedValue}</StudentLink>;
}
