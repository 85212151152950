import { newPageInfo } from "@hex-insights/router";
import { termHubPageInfo } from "../pageinfo";

export const termHubIndexPageInfo = newPageInfo({
	name: "Terms Index",
	title: "",
	description: "Index of Terms.",
	exact: true,
	to: termHubPageInfo.to,
	path: termHubPageInfo.path,
	parent: termHubPageInfo,
});
