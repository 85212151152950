import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { AcademicYearTuitionHubCreatePage } from "./CreatePage";
import { academicYearTuitionHubCreatePageInfo } from "./CreatePage/pageinfo";
import { AcademicYearTuitionHubDetailPage } from "./DetailPage";
import { academicYearTuitionHubDetailPageInfo } from "./DetailPage/pageinfo";
import { AcademicYearTuitionHubIndexPage } from "./IndexPage";
import { academicYearTuitionHubIndexPageInfo } from "./IndexPage/pageinfo";
import { academicYearTuitionHubPageInfo } from "./pageinfo";

export function AcademicYearTuitionHub() {
	useActivePageRegistration(academicYearTuitionHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...academicYearTuitionHubIndexPageInfo.routeProps}>
				<AcademicYearTuitionHubIndexPage />
			</Route>
			<Route {...academicYearTuitionHubCreatePageInfo.routeProps}>
				<AcademicYearTuitionHubCreatePage />
			</Route>
			<Route {...academicYearTuitionHubDetailPageInfo.routeProps}>
				<AcademicYearTuitionHubDetailPage key={academicYearTuitionHubDetailPageInfo.useKey()} />
			</Route>
		</PageRouteSwitch>
	);
}
