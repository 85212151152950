import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export const dashboardsPageInfo = newPageInfo({
	title: "Dashboards",
	description: "Dashboards providing an overview of the school from different angles.",
	exact: false,
	to: "/dashboards",
	path: "/dashboards",
	parent: rootPageInfo,
});
