import { IndexForms } from "@hex-insights/app-modules";
import { HomeRoomSectionFilterFormValues } from "../../../../Utilities";

export type HomeRoomSectionSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the HomeRoomSection model.
 */
export function HomeRoomSectionSearchForm(props: HomeRoomSectionSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type HomeRoomSectionFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, HomeRoomSectionFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the HomeRoomSection model.
 */
export function HomeRoomSectionFilterFormController(props: HomeRoomSectionFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={HomeRoomSectionFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `HomeRoomSectionFilterForm`
