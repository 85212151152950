import { newPageInfo } from "@hex-insights/router";
import { addressHubPageInfo } from "../pageinfo";

export const addressHubCreatePageInfo = newPageInfo({
	name: "New Address",
	title: "New",
	description: "Create a new Address record.",
	exact: true,
	to: addressHubPageInfo.to + "/new",
	path: addressHubPageInfo.path + "/new",
	parent: addressHubPageInfo,
});
