import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import {
	EnrollmentApplicationSubmission,
	EnrollmentApplicationSubmissionFormat,
	EnrollmentApplicationSubmissionNameFragment,
} from "../../../../Utilities";

type ModelForLink = Pick<EnrollmentApplicationSubmission, "id"> & EnrollmentApplicationSubmissionNameFragment;

export type EnrollmentApplicationSubmissionLinkProps = DistributiveOmit<
	Models.LinkProps<ModelForLink>,
	"pageInfoKey" | "format"
>;

/**
 * Renders a link to the given EnrollmentApplicationSubmission instance.
 */
export function EnrollmentApplicationSubmissionLink(props: EnrollmentApplicationSubmissionLinkProps) {
	return (
		<Models.Link<ModelForLink>
			pageInfoKey="EnrollmentApplicationSubmission"
			format={EnrollmentApplicationSubmissionFormat.name}
			{...props}
		/>
	);
}
