import { CourseAttendanceRecord } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the attendanceDate field of the CourseAttendanceRecord model.
 */
export function attendanceDate(value: CourseAttendanceRecord["attendanceDate"] | null): string {
	return toString(value);
}

/**
 * Formats the isPresent field of the CourseAttendanceRecord model.
 */
export function isPresent(value: CourseAttendanceRecord["isPresent"]): string {
	return toString(value);
}

/**
 * Formats the isLate field of the CourseAttendanceRecord model.
 */
export function isLate(value: CourseAttendanceRecord["isLate"]): string {
	return toString(value);
}

/**
 * Formats the isExcused field of the CourseAttendanceRecord model.
 */
export function isExcused(value: CourseAttendanceRecord["isExcused"]): string {
	return toString(value);
}

/**
 * Formats the notes field of the CourseAttendanceRecord model.
 */
export function notes(value: CourseAttendanceRecord["notes"]): string {
	return toString(value);
}
