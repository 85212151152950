import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { AcademicYearCreateInput, AcademicYearDetailQuery, AcademicYearUpdateInput } from "../../GraphQL";
import { AcademicYearFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the AcademicYear model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: AcademicYearFormValues.Create): AcademicYearCreateInput {
	const input: NullPartial<AcademicYearCreateInput> = { ...formValues };
	return input as AcademicYearCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the AcademicYear model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<AcademicYearFormValues.Detail>,
	initialFormValues: AcademicYearFormValues.Detail,
): AcademicYearUpdateInput {
	const inputFormValues = omitKeys(formValues, ["billingSetupSubmissionIDs", "schoolStudentEnrollmentIDs", "termIDs"]);
	const input: AcademicYearUpdateInput = { ...inputFormValues };

	if (formValues.billingSetupSubmissionIDs !== undefined) {
		const { added, removed } = getArrayDiff(
			initialFormValues.billingSetupSubmissionIDs,
			formValues.billingSetupSubmissionIDs,
		);
		input.addBillingSetupSubmissionIDs = added;
		input.removeBillingSetupSubmissionIDs = removed;
	}

	if (formValues.schoolStudentEnrollmentIDs !== undefined) {
		const { added, removed } = getArrayDiff(
			initialFormValues.schoolStudentEnrollmentIDs,
			formValues.schoolStudentEnrollmentIDs,
		);
		input.addSchoolStudentEnrollmentIDs = added;
		input.removeSchoolStudentEnrollmentIDs = removed;
	}

	if (formValues.termIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.termIDs, formValues.termIDs);
		input.addTermIDs = added;
		input.removeTermIDs = removed;
	}

	return input;
}

/**
 * Converts a AcademicYear instance to its corresponding detail form values.
 *
 * @param academicYear The instance to convert.
 */
export function toFormValues(academicYear: AcademicYearDetailQuery["academicYear"]): AcademicYearFormValues.Detail {
	return {
		name: academicYear.name,
		billingSetupSubmissionIDs: academicYear.billingSetupSubmissions.map((e) => e.id),
		schoolStudentEnrollmentIDs: academicYear.schoolStudentEnrollments.map((e) => e.id),
		termIDs: academicYear.terms.map((e) => e.id),
	};
}
