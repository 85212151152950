import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const paymentHubPageInfo = newPageInfo({
	title: "Payments",
	description: "Pages related to the Payment model.",
	exact: false,
	to: hubsPageInfo.to + "/payments",
	path: hubsPageInfo.path + "/payments",
	parent: hubsPageInfo,
});
