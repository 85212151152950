import { NullPartial } from "@hex-insights/core";
import { InvoiceLineItemCreateInput, InvoiceLineItemDetailQuery, InvoiceLineItemUpdateInput } from "../../GraphQL";
import { InvoiceLineItemFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the InvoiceLineItem model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: InvoiceLineItemFormValues.Create): InvoiceLineItemCreateInput {
	const input: NullPartial<InvoiceLineItemCreateInput> = { ...formValues };
	return input as InvoiceLineItemCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the InvoiceLineItem model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<InvoiceLineItemFormValues.Detail>,
	_initialFormValues: InvoiceLineItemFormValues.Detail,
): InvoiceLineItemUpdateInput {
	const input: InvoiceLineItemUpdateInput = { ...formValues };

	if (formValues.serviceSubscriptionID === null) {
		input.serviceSubscriptionID = null;
		input.clearServiceSubscription = true;
	}

	return input;
}

/**
 * Converts a InvoiceLineItem instance to its corresponding detail form values.
 *
 * @param invoiceLineItem The instance to convert.
 */
export function toFormValues(
	invoiceLineItem: InvoiceLineItemDetailQuery["invoiceLineItem"],
): InvoiceLineItemFormValues.Detail {
	return {
		lineItemNumber: invoiceLineItem.lineItemNumber,
		amount: invoiceLineItem.amount,
		description: invoiceLineItem.description,
		invoiceID: invoiceLineItem.invoice?.id ?? null,
		serviceSubscriptionID: invoiceLineItem.serviceSubscription?.id ?? null,
	};
}
