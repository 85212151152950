import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { ParentHubCreatePage } from "./CreatePage";
import { parentHubCreatePageInfo } from "./CreatePage/pageinfo";
import { ParentHubDetailPage } from "./DetailPage";
import { parentHubDetailPageInfo } from "./DetailPage/pageinfo";
import { ParentHubIndexPage } from "./IndexPage";
import { parentHubIndexPageInfo } from "./IndexPage/pageinfo";
import { parentHubPageInfo } from "./pageinfo";

export function ParentHub() {
	useActivePageRegistration(parentHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...parentHubIndexPageInfo.routeProps}>
				<ParentHubIndexPage />
			</Route>
			<Route {...parentHubCreatePageInfo.routeProps}>
				<ParentHubCreatePage />
			</Route>
			<Route {...parentHubDetailPageInfo.routeProps}>
				<ParentHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
