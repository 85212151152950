import React from "react";
import { DateTimeField, FieldDisplayArgs, MultiSelectField, SelectField, TextField } from "@hex-insights/forms";
import {
	AcademicYearSelect,
	CourseSectionSelect,
	HomeRoomSectionSelect,
	TermFormValues,
	useAcademicYearSelectLazyQuery,
	useCourseSectionSelectLazyQuery,
	useHomeRoomSectionSelectLazyQuery,
} from "../../../../Utilities";
import { AcademicYearLink, CourseSectionLink, HomeRoomSectionLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Term model.
 */
type FieldProps<K extends keyof TermFormValues.Base = keyof TermFormValues.Base> = BaseFieldProps<
	Pick<TermFormValues.Base, K>
>;

/**
 * Generic props for fields of the Term model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof TermFormValues.Detail = keyof TermFormValues.Detail> = BaseFieldProps<
	Pick<TermFormValues.Detail, K>
>;

/**
 * Renders a field component for the `name` field of the Term model.
 */
export function Name({ formState }: FieldProps<"name">) {
	return <TextField formState={formState} name="name" />;
}

/**
 * Renders a field component for the `startDate` field of the Term model.
 */
export function StartDate({ formState }: FieldProps<"startDate">) {
	return <DateTimeField formState={formState} name="startDate" precision="day" />;
}

/**
 * Renders a field component for the `endDate` field of the Term model.
 */
export function EndDate({ formState }: FieldProps<"endDate">) {
	return <DateTimeField formState={formState} name="endDate" precision="day" />;
}

export type AcademicYearProps = FieldProps<"academicYearID"> & {
	currentAcademicYear?: AcademicYearSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `academicYear` edge of the SchoolStudentEnrollment model.
 */
export function AcademicYear({ formState, currentAcademicYear, ...props }: AcademicYearProps) {
	const [loadOptions, { loading, data }] = useAcademicYearSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.academicYearID) {
			loadOptions();
		}
	}, [formState.formEditing.academicYearID, loadOptions]);
	const options = React.useMemo(
		() => AcademicYearSelect.toOptions(data?.academicYearConnection.edges, currentAcademicYear),
		[data, currentAcademicYear],
	);

	return (
		<SelectField
			formState={formState}
			name="academicYearID"
			isLoading={loading}
			options={options}
			display={displayAcademicYear}
			blankValue={null}
			{...props}
		/>
	);
}

function displayAcademicYear({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <AcademicYearLink instance={{ id }}>{formattedValue}</AcademicYearLink>;
}

export type CourseSectionsProps = DetailFieldProps<"courseSectionIDs"> & {
	currentCourseSections?: CourseSectionSelect.ModelForOption[];
};

/**
 * Renders a field component for the `courseSections` edge of the Term model.
 */
export function CourseSections({ formState, currentCourseSections }: CourseSectionsProps) {
	const [loadOptions, { loading, data }] = useCourseSectionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.courseSectionIDs) {
			loadOptions();
		}
	}, [formState.formEditing.courseSectionIDs, loadOptions]);
	const options = React.useMemo(
		() => CourseSectionSelect.toMultiOptions(data?.courseSectionConnection.edges, currentCourseSections),
		[data, currentCourseSections],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="courseSectionIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayCourseSectionInstance}
		/>
	);
}

function displayCourseSectionInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <CourseSectionLink instance={{ id }}>{formattedValue}</CourseSectionLink>;
}

export type HomeRoomSectionsProps = DetailFieldProps<"homeRoomSectionIDs"> & {
	currentHomeRoomSections?: HomeRoomSectionSelect.ModelForOption[];
};

/**
 * Renders a field component for the `homeRoomSections` edge of the Term model.
 */
export function HomeRoomSections({ formState, currentHomeRoomSections }: HomeRoomSectionsProps) {
	const [loadOptions, { loading, data }] = useHomeRoomSectionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.homeRoomSectionIDs) {
			loadOptions();
		}
	}, [formState.formEditing.homeRoomSectionIDs, loadOptions]);
	const options = React.useMemo(
		() => HomeRoomSectionSelect.toMultiOptions(data?.homeRoomSectionConnection.edges, currentHomeRoomSections),
		[data, currentHomeRoomSections],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="homeRoomSectionIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayHomeRoomSectionInstance}
		/>
	);
}

function displayHomeRoomSectionInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <HomeRoomSectionLink instance={{ id }}>{formattedValue}</HomeRoomSectionLink>;
}
