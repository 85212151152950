import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Column, Conditional, Else, Heading, If, makeClassName, mappedArray, Row } from "@hex-insights/core";
import { FormState } from "@hex-insights/forms";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	EnrollmentApplicationFilterFormController,
	EnrollmentApplicationFilterFormState,
	EnrollmentApplicationFilterFormUtils,
	EnrollmentApplicationFilterFormValues,
	EnrollmentApplicationFormat,
	EnrollmentApplicationIndexQuery,
	EnrollmentApplicationOrderField,
	EnrollmentApplicationOrderFormController,
	EnrollmentApplicationOrderFormState,
	EnrollmentApplicationOrderFormValues,
	EnrollmentApplicationPaginationForm,
	EnrollmentApplicationParentFormat,
	EnrollmentApplicationSearchForm,
	EnrollmentApplicationStatusFilterButtons,
	EnrollmentApplicationStatusFilterButtonsProps,
	EnrollmentApplicationStudentFormat,
	EnrollmentApplicationUserViewRecordUtils,
	useEnrollmentApplicationIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { AuthenticationContext } from "../../../../Contexts";
import { InviteEmailController } from "../../../Admissions/Components";
import { enrollmentApplicationHubDetailPageInfo } from "../DetailPage/pageinfo";
import { enrollmentApplicationHubIndexPageInfo } from "./pageinfo";
import styles from "./styles.module.css";

const columns = [
	"createdAt",
	"status",
	"studentName",
	"studentAge",
	"studentGradeLevelApplyingFor",
	"parent1Name",
	"parent2Name",
	"viewStatus",
];

const columnWidths = {
	createdAt: "10rem",
	status: "6rem",
	viewStatus: "5rem",
};

function orderFieldToColumn(field: EnrollmentApplicationOrderField | null) {
	if (field === null) {
		return "id";
	}
	return EnrollmentApplicationOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return EnrollmentApplicationOrderFormValues.modelFieldToOrderField[column];
}

export function EnrollmentApplicationHubIndexPage() {
	useActivePageRegistration(enrollmentApplicationHubIndexPageInfo);

	const filterFormState = EnrollmentApplicationFilterFormState.useFormState();
	const orderFormState = EnrollmentApplicationOrderFormState.useFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(
		filterFormState.formValues,
		EnrollmentApplicationFilterFormUtils.toFilterInputs,
	);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { user } = React.useContext(AuthenticationContext);
	const { loading, data, error } = useEnrollmentApplicationIndexQuery({
		variables: { ...queryVariables, userID: user?.id ?? "0" },
		fetchPolicy: "cache-and-network",
	});
	EnrollmentApplicationUserViewRecordUtils.useViewRecorder(data?.enrollmentApplicationConnection.edges);

	const clearFilters = React.useCallback(() => {
		EnrollmentApplicationFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = EnrollmentApplicationFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.enrollmentApplicationConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading level={1} noMargin>
					Enrollment Applications
				</Heading>

				<InviteEmailController />
			</Row>

			<Row justify="spaced-start" align="center" overflow="wrap" style={{ marginBottom: "0.75rem" }}>
				<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem" overflow="wrap">
					<EnrollmentApplicationSearchForm formState={filterFormState} />
					<EnrollmentApplicationFilterFormController formState={filterFormState} numActiveFilters={numActiveFilters} />
					<If condition={numActiveFilters > 0}>
						<Button variant="link" size="small" onClick={clearFilters}>
							Clear
						</Button>
					</If>
					<EnrollmentApplicationOrderFormController formState={orderFormState} />
				</Row>

				<QuickFilters filterFormState={filterFormState} />
			</Row>

			<Table.Container className="hub__index-page__table__container">
				<Table
					columns={columns}
					columnWidths={columnWidths}
					minColumnWidth="10rem"
					{...tableSortingProps}
					className="hub__index-page__table"
				>
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="createdAt">Submitted</Table.Heading>
							<Table.Heading column="status">Status</Table.Heading>
							<Table.Heading column="studentName" noSort>
								Student
							</Table.Heading>
							<Table.Heading column="studentAge" noSort>
								Age
							</Table.Heading>
							<Table.Heading column="studentGradeLevelApplyingFor" noSort>
								Grade Level
							</Table.Heading>
							<Table.Heading column="parent1Name" noSort>
								Parent/Guardian 1
							</Table.Heading>
							<Table.Heading column="parent2Name" noSort>
								Parent/Guardian 2
							</Table.Heading>
							<Table.Heading column="viewStatus" noSort></Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={!data}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.enrollmentApplicationConnection.edges.map((e) => {
									const student = e.node.enrollmentApplicationStudent;
									const parents = student.enrollmentApplicationSubmission.enrollmentApplicationParents;

									return (
										<Table.Row key={e.node.id} className="hub__index-page__table__row">
											<Table.Cell column="createdAt" oneLine>
												<InternalLink to={enrollmentApplicationHubDetailPageInfo.to(e.node.id)}>
													{EnrollmentApplicationFormat.Fields.createdAt(e.node.createdAt)}
												</InternalLink>
											</Table.Cell>
											<Table.Cell column="status" oneLine>
												<span className={styles["status"] + " " + styles[`status--${e.node.status}`]}>
													{EnrollmentApplicationFormat.Fields.status(e.node.status)}
												</span>
											</Table.Cell>
											<Table.Cell column="studentName" oneLine>
												<InternalLink to={enrollmentApplicationHubDetailPageInfo.to(e.node.id)}>
													{EnrollmentApplicationStudentFormat.name(student)}
												</InternalLink>
											</Table.Cell>
											<Table.Cell column="studentAge" oneLine>
												{EnrollmentApplicationStudentFormat.age(student)}
											</Table.Cell>
											<Table.Cell column="studentGradeLevelApplyingFor" oneLine>
												{EnrollmentApplicationStudentFormat.Fields.gradeLevelApplyingFor(student.gradeLevelApplyingFor)}
											</Table.Cell>
											<Table.Cell column="parent1Name" oneLine>
												{parents.length > 0 ? EnrollmentApplicationParentFormat.name(parents[0]) : ""}
											</Table.Cell>
											<Table.Cell column="parent2Name" oneLine>
												{parents.length > 1 ? EnrollmentApplicationParentFormat.name(parents[1]) : ""}
											</Table.Cell>
											<Table.Cell column="viewStatus" oneLine>
												<ViewStatus viewRecords={e.node.enrollmentApplicationUserViewRecords} />
											</Table.Cell>
										</Table.Row>
									);
								})}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<EnrollmentApplicationPaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.enrollmentApplicationConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}

type ViewStatusProps = {
	viewRecords: EnrollmentApplicationIndexQuery["enrollmentApplicationConnection"]["edges"][0]["node"]["enrollmentApplicationUserViewRecords"];
};

function ViewStatus({ viewRecords }: ViewStatusProps) {
	const [text, statusClassName] = React.useMemo(() => {
		if (viewRecords.length === 0) {
			return ["New", styles["view-status--new"]];
		}

		for (let i = 0; i < viewRecords.length; i++) {
			const viewRecord = viewRecords[i];
			if (viewRecord.isDetail) {
				return ["Seen", styles["view-status--seen"]];
			}
		}

		return ["", ""];
	}, [viewRecords]);

	return (
		<Column justify="center" align="center" className={makeClassName(styles["view-status"], statusClassName)}>
			<span>{text}</span>
		</Column>
	);
}

const statusFilterButtonProps: EnrollmentApplicationStatusFilterButtonsProps["buttonProps"] = { size: "small" };

type QuickFiltersProps = {
	filterFormState: FormState<EnrollmentApplicationFilterFormValues.FormValues>;
};

function QuickFilters({ filterFormState }: QuickFiltersProps) {
	return (
		<Row justify="spaced-start" horizontalSpacing="0.25rem">
			<EnrollmentApplicationStatusFilterButtons formState={filterFormState} buttonProps={statusFilterButtonProps} />
		</Row>
	);
}
