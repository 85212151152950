import { Column, Row } from "@hex-insights/core";
import { BigDateDisplay, DailyReport, EventCalendarTile, StudentsQuickActions } from "@hex-insights/verita.shared";
import { useSearch } from "../../GlobalSearch/use-search";
import { DataLists, GeneralDashboardResourcesTile, GeneralDashboardTimelineTile } from "../Components";
import { LessonPlansTile } from "./LessonPlansTile";

export { type LessonPlansOverviewModalProps, LessonPlansOverviewModal } from "./LessonPlansTile";

export function AcademicDashboard() {
	return (
		<Row
			justify="spaced-start"
			style={{
				height: "calc(100vh - var(--header---height) - 2 * var(--main---padding-top))",
			}}
		>
			<Column
				justify="spaced-start"
				style={{
					flexGrow: 1,
					minWidth: 0,
					paddingTop: "0.5rem",
				}}
			>
				<Row justify="spaced-start" align="center">
					<BigDateDisplay />
					<DailyReport style={{ flexGrow: 1 }} />
				</Row>
				<DataLists />
				<GeneralDashboardTimelineTile />
				<GeneralDashboardResourcesTile />
			</Column>

			<Column
				justify="spaced-start"
				style={{
					width: "30%",
					maxWidth: "25rem",
					flexShrink: 0,
					backgroundColor: "#f2f9ff",
					borderRadius: "var(--general__tile---border-radius)",
					padding: "1rem",
				}}
			>
				<StudentsQuickActions useSearch={useSearch} />
				<EventCalendarTile style={{ flexGrow: 1, height: "100%", overflow: "hidden", boxShadow: "none" }} />
				<LessonPlansTile style={{ boxShadow: "none" }} />
			</Column>
		</Row>
	);
}
