import { newPageInfo } from "@hex-insights/router";
import { courseAttendanceRecordHubPageInfo } from "../pageinfo";

export const courseAttendanceRecordHubIndexPageInfo = newPageInfo({
	name: "Course Attendance Records Index",
	title: "",
	description: "Index of Course Attendance Records.",
	exact: true,
	to: courseAttendanceRecordHubPageInfo.to,
	path: courseAttendanceRecordHubPageInfo.path,
	parent: courseAttendanceRecordHubPageInfo,
});
