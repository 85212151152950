import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	EmployeeFilterFormController,
	EmployeeFilterFormState,
	EmployeeFilterFormUtils,
	EmployeeFormat,
	EmployeeOrderField,
	EmployeeOrderFormController,
	EmployeeOrderFormState,
	EmployeeOrderFormValues,
	EmployeePaginationForm,
	EmployeeSearchForm,
	useEmployeeIndexQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { employeeHubCreatePageInfo } from "../CreatePage/pageinfo";
import { employeeHubDetailPageInfo } from "../DetailPage/pageinfo";
import { employeeHubIndexPageInfo } from "./pageinfo";

const columns = ["name", "email", "jobTitle", "role", "startDate", "endDate"];

function orderFieldToColumn(field: EmployeeOrderField | null) {
	if (field === null) {
		return "id";
	}
	return EmployeeOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return EmployeeOrderFormValues.modelFieldToOrderField[column];
}

export function EmployeeHubIndexPage() {
	useActivePageRegistration(employeeHubIndexPageInfo);

	const filterFormState = EmployeeFilterFormState.useFormState();
	const orderFormState = EmployeeOrderFormState.useFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(filterFormState.formValues, EmployeeFilterFormUtils.toFilterInputs);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useEmployeeIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		EmployeeFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = EmployeeFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.employeeConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading level={1} noMargin>
					Employees
				</Heading>

				<InternalLink to={employeeHubCreatePageInfo.to}>New Employee</InternalLink>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<EmployeeSearchForm formState={filterFormState} />
				<EmployeeFilterFormController formState={filterFormState} numActiveFilters={numActiveFilters} />
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<EmployeeOrderFormController formState={orderFormState} />
			</Row>

			<Table.Container className="hub__index-page__table__container">
				<Table columns={columns} minColumnWidth="10rem" {...tableSortingProps} className="hub__index-page__table">
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="name">Name</Table.Heading>
							<Table.Heading column="email">Email</Table.Heading>
							<Table.Heading column="jobTitle">Job Title</Table.Heading>
							<Table.Heading column="role">Role</Table.Heading>
							<Table.Heading column="startDate">Start Date</Table.Heading>
							<Table.Heading column="endDate">End Date</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column={columns[0]} colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.employeeConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="name" oneLine>
											<InternalLink to={employeeHubDetailPageInfo.to(e.node.id)}>{e.node.person.name}</InternalLink>
										</Table.Cell>
										<Table.Cell column="email" oneLine>
											{EmployeeFormat.Fields.email(e.node.email)}
										</Table.Cell>
										<Table.Cell column="jobTitle" oneLine>
											{EmployeeFormat.Fields.jobTitle(e.node.jobTitle)}
										</Table.Cell>
										<Table.Cell column="role" oneLine>
											{EmployeeFormat.Fields.role(e.node.role)}
										</Table.Cell>
										<Table.Cell column="startDate" oneLine>
											{EmployeeFormat.Fields.startDate(e.node.startDate)}
										</Table.Cell>
										<Table.Cell column="endDate" oneLine>
											{EmployeeFormat.Fields.endDate(e.node.endDate)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<EmployeePaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.employeeConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
