import React from "react";
import { Heading, Modal, ModalProps } from "@hex-insights/core";
import {
	PaymentForm,
	PaymentFormState,
	PaymentFormValues,
	PaymentMutation,
	Student,
} from "@hex-insights/verita.shared";

export type NewPaymentModalProps = {
	studentID: Student["id"];
	onSuccess: () => void;
} & Pick<ModalProps, "ifRef" | "onClose">;

export function NewPaymentModal({ studentID, onSuccess, ifRef, onClose }: NewPaymentModalProps) {
	const create = PaymentMutation.useCreate();

	const applyCreate = React.useCallback(
		async (formValues: PaymentFormValues.Create) => {
			const { errors } = await create(formValues);
			return errors;
		},
		[create],
	);

	const formState = PaymentFormState.useCreateFormState();

	return (
		<Modal ifRef={ifRef} size="small" onClose={onClose}>
			<Modal.Header>
				<Heading.H2 noMargin>New Payment</Heading.H2>
			</Modal.Header>
			<Modal.Body>
				<PaymentForm.Create
					applyCreate={applyCreate}
					onSuccess={onSuccess}
					formState={formState}
					formNameSuffix={`.${studentID}`}
					studentID={studentID}
				/>
			</Modal.Body>
		</Modal>
	);
}
