import { TrustedDeviceNameFragment } from "../../../GraphQL";
import * as Fields from "./fields";

export { Fields };

/**
 * Formats the full name of the given instance.
 */
export function name(instance: TrustedDeviceNameFragment) {
	return Fields.name(instance.name);
}
