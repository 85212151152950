import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { EmployeeOrderFormValues } from "../../../../Utilities";

export type EmployeeOrderFormControllerProps = {
	formState: FormState<EmployeeOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the Employee model.
 */
export function EmployeeOrderFormController({ formState }: EmployeeOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={EmployeeOrderFormValues.fieldOptions}
			fieldTypeMapping={EmployeeOrderFormValues.fieldTypeMapping}
		/>
	);
}
