import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { InvoiceLineItemOrderFormValues } from "../../../../Utilities";

export type InvoiceLineItemOrderFormControllerProps = {
	formState: FormState<InvoiceLineItemOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the InvoiceLineItem model.
 */
export function InvoiceLineItemOrderFormController({ formState }: InvoiceLineItemOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={InvoiceLineItemOrderFormValues.fieldOptions}
			fieldTypeMapping={InvoiceLineItemOrderFormValues.fieldTypeMapping}
		/>
	);
}
