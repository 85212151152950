import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { PersonForm, PersonFormValues, PersonMutation, usePersonDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { personHubDetailPageInfo } from "./pageinfo";

function PersonHubDetailPageWrapper() {
	// Manages identity of the PersonHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <PersonHubDetailPage key={id} />;
}

export { PersonHubDetailPageWrapper as PersonHubDetailPage };

function PersonHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = usePersonDetailQuery({ variables: { id } });
	const person = data?.person;
	useActivePageRegistration(personHubDetailPageInfo, !!person ? personHubDetailPageInfo.title(person) : "");

	const update = PersonMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<PersonFormValues.Detail>, initialFormValues: PersonFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading person</If>
				<Else>
					{!!person && (
						<React.Fragment>
							<Heading level={1}>{person.name}</Heading>

							<PersonForm.ControlledDetail
								key={syncID}
								person={person}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
