import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { DiscountOrderFormValues } from "../../../../Utilities";

export type DiscountOrderFormControllerProps = {
	formState: FormState<DiscountOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the Discount model.
 */
export function DiscountOrderFormController({ formState }: DiscountOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={DiscountOrderFormValues.fieldOptions}
			fieldTypeMapping={DiscountOrderFormValues.fieldTypeMapping}
		/>
	);
}
