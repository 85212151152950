import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	graphTypeNames,
	Service,
	ServiceCreateInput,
	ServiceCreateMutation,
	ServiceDeleteMutation,
	ServiceDetailDocument,
	ServiceDetailQuery,
	ServiceDetailQueryVariables,
	ServiceSubscription,
	ServiceUpdateInput,
	ServiceUpdateMutation,
	useServiceCreateMutation,
	useServiceDeleteMutation,
	useServiceUpdateMutation,
} from "../../GraphQL";
import { ServiceFormConversion } from "../ModelFormConversion";
import { ServiceFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the Service model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Service model.
 */
export function useCreate() {
	const [createService] = useServiceCreateMutation();

	return React.useCallback(
		async (formValues: ServiceFormValues.Create) => {
			const input = ServiceFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createService({ variables: { input }, update: updateCache });

			return { data: data?.createService ?? null, errors: errors ?? null };
		},
		[createService],
	);
}

/**
 * Returns an `update` function for the Service model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Service model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Service["id"]) {
	const [updateService] = useServiceUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<ServiceFormValues.Detail>, initialFormValues: ServiceFormValues.Detail) => {
			const input = ServiceFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateService({ variables: { id, input }, update: updateCache });

			return { data: data?.updateService ?? null, errors: errors ?? null };
		},
		[updateService, id],
	);
}

/**
 * Returns a `del` function for the Service model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Service["id"]) {
	const [deleteService] = useServiceDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteService({ variables: { id }, update: updateCache });

		return { data: data?.deleteService ?? false, errors: errors ?? null };
	}, [deleteService, id]);
}

function getUpdateCacheForCreate(input: ServiceCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ServiceCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createService;

		cache.writeQuery<ServiceDetailQuery, ServiceDetailQueryVariables>({
			query: ServiceDetailDocument,
			data: { service: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.serviceSubscriptionIDs) {
			for (let i = 0; i < input.serviceSubscriptionIDs.length; i++) {
				addToServiceOfServiceSubscriptionCache(cache, input.serviceSubscriptionIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "serviceConnection" });
	};
}

function getUpdateCacheForUpdate(input: ServiceUpdateInput, _initialFormValues: ServiceFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ServiceUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateService;

		if (input.addServiceSubscriptionIDs) {
			for (let i = 0; i < input.addServiceSubscriptionIDs.length; i++) {
				addToServiceOfServiceSubscriptionCache(cache, input.addServiceSubscriptionIDs[i], updatedObject);
			}
		}
		if (input.removeServiceSubscriptionIDs) {
			for (let i = 0; i < input.removeServiceSubscriptionIDs.length; i++) {
				removeFromServiceOfServiceSubscriptionCache(cache, input.removeServiceSubscriptionIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: Service["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ServiceDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteService) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Service }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "serviceConnection" });
		cache.gc();
	};
}

function addToServiceOfServiceSubscriptionCache(
	cache: ApolloCache<any>,
	targetID: ServiceSubscription["id"],
	object: Pick<Service, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ServiceSubscription }),
		fields: {
			service: () => objectRef,
			serviceID: () => object.id,
		},
	});
}

function removeFromServiceOfServiceSubscriptionCache(
	cache: ApolloCache<any>,
	targetID: ServiceSubscription["id"],
	_object: Pick<Service, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ServiceSubscription }),
		fields: {
			service: () => null,
			serviceID: () => null,
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Service, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment ServiceRef on Service {
				id
			}
		`,
		data: object,
	});
}
