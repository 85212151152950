import React from "react";
import { Bar, BarChart, Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { categoricalColorSchemes, defaultBarProps, defaultLineProps } from "@hex-insights/charts";
import {
	addTimeToDate,
	Button,
	ClassNameProps,
	Column,
	Conditional,
	formatDateTime,
	formatInt,
	If,
	Row,
	stringToLocalDate,
	StyleProps,
	toLocalDateString,
	useUpdateEffect,
	valuesTypeFilter,
} from "@hex-insights/core";
import { DateTimeField, FormType, useFormState, ValidationDisplayPolicy } from "@hex-insights/forms";
import { useActivePageRegistration, useHistory, useLocation } from "@hex-insights/router";
import {
	useAdmissionsApplicationDailyCountsReportQuery,
	useAdmissionsApplicationsByGradeReportQuery,
	useAdmissionsEnrollmentsByGradeReportQuery,
	useAdmissionsReenrollmentsByGradeReportQuery,
} from "@hex-insights/verita.shared";
import { admissionsReportsPageInfo } from "./pageinfo";

const heightOfAboveContent = "18rem";

export function AdmissionsReportsPage() {
	useActivePageRegistration(admissionsReportsPageInfo);

	return (
		<AdmissionsReport
			style={{ width: "100%" }}
			chartContainerStyle={{
				width: "100%",
				height: `calc(100vh - var(--header---height) - ${heightOfAboveContent})`,
				minHeight: "12rem",
			}}
		/>
	);
}

type ReportOption = "applications-by-day" | "applications-by-grade" | "enrollments-by-grade" | "reenrollment-rates";

const reportOptionTypeFilter = valuesTypeFilter<ReportOption>(
	["applications-by-day", "applications-by-grade", "enrollments-by-grade", "reenrollment-rates"],
	"applications-by-day",
);

export type AdmissionsReportProps = {
	chartContainerClassName?: string;
	chartContainerStyle?: React.CSSProperties;
} & Partial<ClassNameProps & StyleProps>;

export function AdmissionsReport({
	chartContainerClassName,
	chartContainerStyle,
	className,
	style,
}: AdmissionsReportProps) {
	const history = useHistory();
	const { hash } = useLocation();
	const [selectedReport, setSelectedReport] = React.useState<ReportOption>(() =>
		reportOptionTypeFilter(hash.replace("#", "")),
	);

	React.useEffect(() => {
		setSelectedReport(reportOptionTypeFilter(hash.replace("#", "")));
	}, [hash]);
	useUpdateEffect(() => {
		history.push("#" + selectedReport);
	}, [history, selectedReport]);

	return (
		<Column justify="spaced-start" className={className} style={style}>
			<Row justify="spaced-center" align="center" verticalSpacing="0.25rem">
				<Button
					variant={selectedReport === "applications-by-day" ? "primary" : "tertiary"}
					size="small"
					onClick={() => setSelectedReport("applications-by-day")}
					style={{ whiteSpace: "nowrap", width: "100%" }}
				>
					Applications by Day
				</Button>
				<Button
					variant={selectedReport === "applications-by-grade" ? "primary" : "tertiary"}
					size="small"
					onClick={() => setSelectedReport("applications-by-grade")}
					style={{ whiteSpace: "nowrap", width: "100%" }}
				>
					Applications by Grade
				</Button>
				<Button
					variant={selectedReport === "enrollments-by-grade" ? "primary" : "tertiary"}
					size="small"
					onClick={() => setSelectedReport("enrollments-by-grade")}
					style={{ whiteSpace: "nowrap", width: "100%" }}
				>
					Enrollments by Grade
				</Button>
				<Button
					variant={selectedReport === "reenrollment-rates" ? "primary" : "tertiary"}
					size="small"
					onClick={() => setSelectedReport("reenrollment-rates")}
					style={{ whiteSpace: "nowrap", width: "100%" }}
				>
					Re-enrollment Rates
				</Button>
			</Row>

			<div className={chartContainerClassName} style={chartContainerStyle}>
				<Conditional>
					<If condition={selectedReport === "applications-by-day"}>
						<ApplicationsByDayChart />
					</If>
					<If condition={selectedReport === "applications-by-grade"}>
						<ApplicationsByGradeChart />
					</If>
					<If condition={selectedReport === "enrollments-by-grade"}>
						<EnrollmentsByGradeChart />
					</If>
					<If condition={selectedReport === "reenrollment-rates"}>
						<ReenrollmentRatesChart />
					</If>
				</Conditional>
			</div>
		</Column>
	);
}

type ApplicationsByDayChartFormValues = {
	startDate: string | null;
	endDate: string | null;
};

function ApplicationsByDayChart() {
	const initialFormValues = React.useMemo<ApplicationsByDayChartFormValues>(
		() => ({
			startDate: toLocalDateString(addTimeToDate(new Date(), [-1, "week"])),
			endDate: toLocalDateString(new Date()),
		}),
		[],
	);
	const formState = useFormState({
		initialFormValues,
		formType: FormType.Standing,
		validationDisplayPolicy: ValidationDisplayPolicy.none,
	});

	const { data, error } = useAdmissionsApplicationDailyCountsReportQuery({
		variables: {
			filter: {
				startDate: formState.formValues.startDate ?? initialFormValues.startDate!,
				endDate: formState.formValues.endDate ?? initialFormValues.endDate!,
			},
		},
	});

	if (error) {
		return null;
	}

	return (
		<Column style={{ width: "100%", height: "100%" }}>
			<Row justify="spaced-start">
				<DateTimeField formState={formState} name="startDate" />
				<DateTimeField formState={formState} name="endDate" />
			</Row>

			<ResponsiveContainer width="99%" height="100%">
				<LineChart data={data?.admissionsApplicationDailyCountsReport.data ?? []}>
					<XAxis dataKey="date" tickFormatter={formatDateTick} />
					<Tooltip
						isAnimationActive={false}
						labelFormatter={formatDateLabel}
						formatter={applicationsTooltipFormatter}
					/>

					<Line {...defaultLineProps} stroke={categoricalColorSchemes.tableau10[0]} strokeWidth={2} dataKey="value" />
				</LineChart>
			</ResponsiveContainer>
		</Column>
	);
}

function ApplicationsByGradeChart() {
	const { data, error } = useAdmissionsApplicationsByGradeReportQuery();

	if (error) {
		return null;
	}

	return (
		<ResponsiveContainer width="99%" height="100%">
			<BarChart data={data?.admissionsApplicationsByGradeReport.data ?? []}>
				<XAxis dataKey="grade" tickFormatter={formatLabelTick} />
				<Tooltip isAnimationActive={false} formatter={applicationsTooltipFormatter} />

				<Bar {...defaultBarProps} fill={categoricalColorSchemes.tableau10[0]} dataKey="value" />
			</BarChart>
		</ResponsiveContainer>
	);
}

function EnrollmentsByGradeChart() {
	const { data, error } = useAdmissionsEnrollmentsByGradeReportQuery();

	if (error) {
		return null;
	}

	return (
		<ResponsiveContainer width="99%" height="100%">
			<BarChart data={data?.admissionsEnrollmentsByGradeReport.data ?? []}>
				<XAxis dataKey="grade" tickFormatter={formatLabelTick} />
				<Tooltip isAnimationActive={false} formatter={enrollmentsByGradeChartTooltipFormatter} />

				<Bar {...defaultBarProps} stackId="0" fill={categoricalColorSchemes.tableau10[3]} dataKey="reenrollmentValue" />
				<Bar
					{...defaultBarProps}
					stackId="0"
					fill={categoricalColorSchemes.tableau10[0]}
					dataKey="newEnrollmentValue"
				/>
			</BarChart>
		</ResponsiveContainer>
	);
}

function ReenrollmentRatesChart() {
	const { data, error } = useAdmissionsReenrollmentsByGradeReportQuery();

	if (error) {
		return null;
	}

	return (
		<ResponsiveContainer width="99%" height="100%">
			<BarChart data={data?.admissionsReenrollmentsByGradeReport.data ?? []}>
				<XAxis dataKey="grade" tickFormatter={formatLabelTick} />
				<Tooltip isAnimationActive={false} formatter={reenrollmentRatesTooltipFormatter} />

				<Bar {...defaultBarProps} fill={categoricalColorSchemes.tableau10[1]} dataKey="enrolledValue" />
				<Bar {...defaultBarProps} fill={categoricalColorSchemes.tableau10[0]} dataKey="reenrolledValue" />
			</BarChart>
		</ResponsiveContainer>
	);
}

function formatLabelTick(value: any) {
	if (value === "auto" || value === 0) {
		return "";
	}
	return value;
}

function formatDateTick(value: string) {
	if (value === "auto") {
		// TODO removed by version 2.1.14 of recharts
		return "";
	}
	const format = new Date(value).getFullYear() === new Date().getFullYear() ? "D/M" : "D/M/YY";
	return formatDateTime(stringToLocalDate(value, "day"), format);
}

function formatDateLabel(value: string) {
	return formatDateTime(stringToLocalDate(value, "day"), "ddd, MMM Do, YYYY");
}

function applicationsTooltipFormatter(value: number, _name: string): [string, string] {
	return [formatInt(value), "Applications"];
}

function enrollmentsByGradeChartTooltipFormatter(value: number, name: string): [string, string] {
	const label = name === "reenrollmentValue" ? "Re-enrollments" : "New Enrollments";
	return [formatInt(value), label];
}

function reenrollmentRatesTooltipFormatter(value: number, name: string): [string, string] {
	const label = name === "enrolledValue" ? "Current Enrollments" : "Next Year's Enrollments";
	return [formatInt(value), label];
}
