import { DateTimeField, FormType, RadioField, TextField } from "@hex-insights/forms";
import { EnrollmentApplicationFormValues } from "../../../../Utilities";
import { FutureBaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the EnrollmentApplication model.
 */
type FieldProps<K extends keyof EnrollmentApplicationFormValues.Base = keyof EnrollmentApplicationFormValues.Base> =
	FutureBaseFieldProps<Pick<EnrollmentApplicationFormValues.Base, K>>;

/**
 * Generic props for fields of the EnrollmentApplication model that only appear in the detail form.
 */
type DetailFieldProps<
	K extends keyof EnrollmentApplicationFormValues.Detail = keyof EnrollmentApplicationFormValues.Detail,
> = FutureBaseFieldProps<Pick<EnrollmentApplicationFormValues.Detail, K>>;

/**
 * Renders a field component for the `createdAt` field of the EnrollmentApplication model.
 */
export function CreatedAt({ formState, ...props }: DetailFieldProps<"createdAt">) {
	return (
		<DateTimeField
			formState={formState}
			name="createdAt"
			optional={FormType.isCreate(formState.formType)}
			precision="minute"
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `status` field of the EnrollmentApplication model.
 */
export function Status({ formState, ...props }: FieldProps<"status">) {
	return (
		<RadioField
			formState={formState}
			name="status"
			options={EnrollmentApplicationFormValues.statusOptions}
			blankValue={null}
			optional={FormType.isCreate(formState.formType)}
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `externalLink` field of the EnrollmentApplication model.
 */
export function ExternalLink({ formState, label = "HubSpot Link", ...props }: FieldProps<"externalLink">) {
	return <TextField formState={formState} name="externalLink" label={label} {...props} />;
}
