import { IndexForms } from "@hex-insights/app-modules";
import { MealPlanOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the MealPlan model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		MealPlanOrderFormValues.initial,
		MealPlanOrderFormValues.fieldValues,
	);
}
