import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import {
	BillingSetupSubmissionForm,
	BillingSetupSubmissionFormat,
	useBillingSetupSubmissionDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { billingSetupSubmissionHubDetailPageInfo } from "./pageinfo";

export function BillingSetupSubmissionHubDetailPage() {
	const { billingSetupSubmissionID } = billingSetupSubmissionHubDetailPageInfo.useParams();

	const { loading, data, error } = useBillingSetupSubmissionDetailQuery({
		variables: { id: billingSetupSubmissionID },
	});
	const billingSetupSubmission = data?.billingSetupSubmission;
	useActivePageRegistration(
		billingSetupSubmissionHubDetailPageInfo,
		!!billingSetupSubmission ? billingSetupSubmissionHubDetailPageInfo.title(billingSetupSubmission) : "",
	);

	const { syncID, synchronize } = useSync();

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading billing setup submission</If>
				<Else>
					{!!billingSetupSubmission && (
						<React.Fragment>
							<Heading.H1>{BillingSetupSubmissionFormat.name(billingSetupSubmission)}</Heading.H1>

							<BillingSetupSubmissionForm.ControlledDetail
								key={syncID}
								billingSetupSubmission={billingSetupSubmission}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
