import React from "react";
import { useApolloClient } from "@apollo/client";
import { AsyncValidationFunction, invalid, valid } from "@hex-insights/forms";
import {
	User,
	UserCheckUniqueEmailDocument,
	UserCheckUniqueEmailQuery,
	UserCheckUniqueEmailQueryVariables,
} from "../../GraphQL";
import { UserFormValues } from "../ModelFormValues";

/**
 * Returns an asynchronous validation function to check that the given email is unique for the
 * User model.
 *
 * @param ignoreID The ID of the instance to ignore (generally the current instance in an update form).
 */
export function useUniqueEmailValidator(ignoreID?: User["id"]): AsyncValidationFunction<UserFormValues.Base["email"]> {
	const client = useApolloClient();
	return React.useCallback(
		async (value: UserFormValues.Base["email"]) => {
			if (value === "") {
				return valid();
			}

			const { data } = await client.query<UserCheckUniqueEmailQuery, UserCheckUniqueEmailQueryVariables>({
				query: UserCheckUniqueEmailDocument,
				variables: { email: value, ignoreID },
			});

			if (data.userConnection.totalCount === 0) {
				return valid();
			}

			return invalid("This email is taken.");
		},
		[client, ignoreID],
	);
}
