import { ClassNameProps, Column, Row, StyleProps } from "@hex-insights/core";
import { Label } from "@hex-insights/forms";
import {
	formatPrice,
	Tile,
	Timeline,
	TimelineObjectType,
	usePaginatedTimelineQuery,
} from "@hex-insights/verita.shared";

const timelineObjectTypes = [TimelineObjectType.Contract, TimelineObjectType.Invoice, TimelineObjectType.Payment];

type RevenueTileProps = Partial<ClassNameProps & StyleProps>;

export function RevenueTile({ className, style }: RevenueTileProps) {
	const { data, networkStatus, refetch, hasMore, onLoadMoreClick } = usePaginatedTimelineQuery({
		filter: { objectTypes: timelineObjectTypes },
	});

	return (
		<Tile className={className} style={style}>
			<Tile.Body style={{ height: "100%" }}>
				<Column justify="spaced-start" style={{ height: "100%" }}>
					<Row justify="space-between">
						<Column align="center">
							<Label>Invoices Due</Label>
							<span style={{ fontSize: "1.5rem" }}>{formatPrice(500_000_00)}</span>
						</Column>
						<Column align="center">
							<Label>Payments Received</Label>
							<span style={{ fontSize: "1.5rem" }}>{formatPrice(437_000_00)}</span>
						</Column>
						<Column align="center">
							<Label>Overdue Invoices</Label>
							<span style={{ fontSize: "1.5rem" }}>10 ({formatPrice(30_500_00)})</span>
						</Column>
					</Row>

					<div className="timeline__scrolling-container__container" style={{ height: "100%", overflow: "hidden" }}>
						<div className="timeline__scrolling-container" style={{ height: "100%", overflow: "scroll" }}>
							<Timeline data={data?.timeline} networkStatus={networkStatus} refetch={refetch} />

							<Timeline.LoadMoreButton hasMore={hasMore} networkStatus={networkStatus} onClick={onLoadMoreClick} />
						</div>
					</div>
				</Column>
			</Tile.Body>
		</Tile>
	);
}
