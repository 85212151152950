import React from "react";
import {
	ClassNameProps,
	Column,
	Conditional,
	Else,
	Heading,
	If,
	pickKeys,
	Row,
	Section,
	StyleProps,
	useSync,
} from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	EnrollmentApplicationForm,
	EnrollmentApplicationFormValues,
	EnrollmentApplicationModules,
	EnrollmentApplicationMutation,
	EnrollmentApplicationParentDetailQuery,
	EnrollmentApplicationParentForm,
	EnrollmentApplicationParentFormat,
	EnrollmentApplicationStudentDetailQuery,
	EnrollmentApplicationStudentForm,
	EnrollmentApplicationStudentFormat,
	EnrollmentApplicationSubmission,
	PersonIcon,
	Tile,
	useEnrollmentApplicationDetailQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { enrollmentApplicationHubDetailPageInfo } from "./pageinfo";

function EnrollmentApplicationHubDetailPageWrapper() {
	// Manages identity of the EnrollmentApplicationHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <EnrollmentApplicationHubDetailPage key={id} />;
}

export { EnrollmentApplicationHubDetailPageWrapper as EnrollmentApplicationHubDetailPage };

function EnrollmentApplicationHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useEnrollmentApplicationDetailQuery({ variables: { id } });
	const enrollmentApplication = data?.enrollmentApplication;
	useActivePageRegistration(
		enrollmentApplicationHubDetailPageInfo,
		!!enrollmentApplication ? enrollmentApplicationHubDetailPageInfo.title(enrollmentApplication) : "",
	);

	const update = EnrollmentApplicationMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<EnrollmentApplicationFormValues.Detail>,
			initialFormValues: EnrollmentApplicationFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading enrollmentApplication</If>
				<Else>
					{!!enrollmentApplication && (
						<React.Fragment>
							<Heading level={1}>{enrollmentApplication.id}</Heading>

							<Row justify="spaced-start">
								<Tile>
									<Tile.Body>
										<EnrollmentApplicationForm.ControlledDetail
											key={syncID}
											enrollmentApplication={enrollmentApplication}
											applyUpdate={applyUpdate}
											onSuccess={synchronize}
										/>
									</Tile.Body>
								</Tile>

								<FormSubmissionDataTile
									student={enrollmentApplication.enrollmentApplicationStudent}
									submission={enrollmentApplication.enrollmentApplicationStudent.enrollmentApplicationSubmission}
									style={{ flexGrow: 1, width: "100%", overflow: "hidden" }}
								/>
							</Row>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}

type FormSubmissionDataTileProps = {
	student: EnrollmentApplicationStudentDetailQuery["enrollmentApplicationStudent"];
	submission: Pick<EnrollmentApplicationSubmission, "doesConsentToDataUse" | "signatureName" | "signatureDate"> & {
		enrollmentApplicationParents: EnrollmentApplicationParentDetailQuery["enrollmentApplicationParent"][];
	};
} & Partial<ClassNameProps & StyleProps>;

function FormSubmissionDataTile({ student, submission, className, style }: FormSubmissionDataTileProps) {
	const dataConsent = React.useMemo(() => pickKeys(submission, ["doesConsentToDataUse"]), [submission]);
	const signature = React.useMemo(() => pickKeys(submission, ["signatureName", "signatureDate"]), [submission]);

	return (
		<Tile className={className} style={style}>
			<Tile.Body>
				<Section>
					<Section.Header>
						<Heading level={2} noMargin>
							Student
						</Heading>
					</Section.Header>
					<Section.Body>
						<Section>
							<Section.Header>
								<Row justify="spaced-start" align="center">
									<PersonIcon
										person={{
											name: EnrollmentApplicationStudentFormat.name(student),
											imageURL: student.imageURL ?? "",
										}}
										imageSize="6rem"
									/>
									<Heading level={3} noMargin>
										{EnrollmentApplicationStudentFormat.name(student)}
									</Heading>
								</Row>
							</Section.Header>
							<Section.Body>
								<EnrollmentApplicationStudentForm.ControlledReadOnly
									enrollmentApplicationStudent={student}
									headingLevel={4}
								/>
							</Section.Body>
						</Section>
					</Section.Body>
				</Section>
				<Section>
					<Section.Header>
						<Heading level={2} noMargin>
							Parents/Guardians
						</Heading>
					</Section.Header>
					<Section.Body>
						<Column justify="spaced-start">
							{submission.enrollmentApplicationParents.map((parent) => (
								<Section key={parent.id}>
									<Section.Header>
										<Row justify="spaced-start" align="center">
											<Heading level={3} noMargin>
												{EnrollmentApplicationParentFormat.name(parent)}
											</Heading>
										</Row>
									</Section.Header>
									<Section.Body>
										<EnrollmentApplicationParentForm.ControlledReadOnly
											enrollmentApplicationParent={parent}
											headingLevel={4}
											allParents={submission.enrollmentApplicationParents}
										/>
									</Section.Body>
								</Section>
							))}
						</Column>
					</Section.Body>
				</Section>
				<Section>
					<Section.Header>
						<Heading level={2} noMargin>
							Declarations
						</Heading>
					</Section.Header>
					<Section.Body>
						<Column justify="spaced-start">
							<EnrollmentApplicationModules.DataConsentForm.ControlledReadOnly initialFormValues={dataConsent} />
							<Section>
								<Section.Header>
									<Heading level={3} noMargin>
										Electronic Signature
									</Heading>
								</Section.Header>
								<Section.Body>
									<EnrollmentApplicationModules.SignatureForm.ControlledReadOnly initialFormValues={signature} />
								</Section.Body>
							</Section>
						</Column>
					</Section.Body>
				</Section>
			</Tile.Body>
		</Tile>
	);
}
