import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const busHubPageInfo = newPageInfo({
	title: "Buses",
	description: "Pages related to the Bus model.",
	exact: false,
	to: hubsPageInfo.to + "/buses",
	path: hubsPageInfo.path + "/buses",
	parent: hubsPageInfo,
});
