import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { ContractInstallment, ContractInstallmentFormat, ContractInstallmentNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<ContractInstallment, "id"> & ContractInstallmentNameFragment;

export type ContractInstallmentLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given ContractInstallment instance.
 */
export function ContractInstallmentLink(props: ContractInstallmentLinkProps) {
	return (
		<Models.Link<ModelForLink> pageInfoKey="ContractInstallment" format={ContractInstallmentFormat.name} {...props} />
	);
}
