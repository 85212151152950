import { IndexForms } from "@hex-insights/app-modules";
import { EnrollmentApplicationUserViewRecordFilterFormValues } from "../../../../Utilities";

export type EnrollmentApplicationUserViewRecordFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, EnrollmentApplicationUserViewRecordFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the EnrollmentApplicationUserViewRecord model.
 */
export function EnrollmentApplicationUserViewRecordFilterFormController(
	props: EnrollmentApplicationUserViewRecordFilterFormControllerProps,
) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={EnrollmentApplicationUserViewRecordFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `EnrollmentApplicationUserViewRecordFilterForm`
