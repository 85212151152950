import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { ServiceHubCreatePage } from "./CreatePage";
import { serviceHubCreatePageInfo } from "./CreatePage/pageinfo";
import { ServiceHubDetailPage } from "./DetailPage";
import { serviceHubDetailPageInfo } from "./DetailPage/pageinfo";
import { ServiceHubIndexPage } from "./IndexPage";
import { serviceHubIndexPageInfo } from "./IndexPage/pageinfo";
import { serviceHubPageInfo } from "./pageinfo";

export function ServiceHub() {
	useActivePageRegistration(serviceHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...serviceHubIndexPageInfo.routeProps}>
				<ServiceHubIndexPage />
			</Route>
			<Route {...serviceHubCreatePageInfo.routeProps}>
				<ServiceHubCreatePage />
			</Route>
			<Route {...serviceHubDetailPageInfo.routeProps}>
				<ServiceHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
