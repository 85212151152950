import { newPageInfo } from "@hex-insights/router";
import { LoginRecord } from "@hex-insights/verita.shared";
import { loginRecordHubPageInfo } from "../pageinfo";

type LoginRecordForRouteTitle = Pick<LoginRecord, "id">;

export const loginRecordHubDetailPageInfo = newPageInfo({
	name: "Login Record Detail",
	title: (e: LoginRecordForRouteTitle) => e.id,
	description: "Detail page for an loginRecord.",
	exact: false,
	isLeaf: true,
	to: (id: LoginRecord["id"]) => loginRecordHubPageInfo.to + `/${id}`,
	path: loginRecordHubPageInfo.path + "/:id(\\d+)",
	parent: loginRecordHubPageInfo,
});
