import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { CalendarEventOrderFormValues } from "../../../../Utilities";

export type CalendarEventOrderFormControllerProps = {
	formState: FormState<CalendarEventOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the CalendarEvent model.
 */
export function CalendarEventOrderFormController({ formState }: CalendarEventOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={CalendarEventOrderFormValues.fieldOptions}
			fieldTypeMapping={CalendarEventOrderFormValues.fieldTypeMapping}
		/>
	);
}
