import { newPageInfo } from "@hex-insights/router";
import { userGroupHubPageInfo } from "../pageinfo";

export const userGroupHubIndexPageInfo = newPageInfo({
	name: "User Groups Index",
	title: "",
	description: "Index of User Groups.",
	exact: true,
	to: userGroupHubPageInfo.to,
	path: userGroupHubPageInfo.path,
	parent: userGroupHubPageInfo,
});
