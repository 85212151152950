import React from "react";
import {
	BooleanField,
	DateTimeField,
	FieldDisplayArgs,
	FileField,
	FormType,
	MultiSelectField,
	RadioButtonsInput,
	RadioField,
	SelectField,
	TextField,
} from "@hex-insights/forms";
import {
	DocumentResourceFolderSelect,
	DocumentResourceFormValues,
	useDocumentResourceFolderSelectLazyQuery,
	UserSelect,
	useUserSelectLazyQuery,
} from "../../../../Utilities";
import { DocumentResourceFolderLink, UserLink } from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the DocumentResource model.
 */
type FieldProps<K extends keyof DocumentResourceFormValues.Base = keyof DocumentResourceFormValues.Base> =
	BaseFieldProps<Pick<DocumentResourceFormValues.Base, K>>;

/**
 * Generic props for fields of the DocumentResource model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof DocumentResourceFormValues.Detail = keyof DocumentResourceFormValues.Detail> =
	BaseFieldProps<Pick<DocumentResourceFormValues.Detail, K>>;

/**
 * Renders a field component for the `name` field of the DocumentResource model.
 */
export function Name({ formState }: FieldProps<"name">) {
	return <TextField formState={formState} name="name" />;
}

/**
 * Renders a field component for the `description` field of the DocumentResource model.
 */
export function Description({ formState }: FieldProps<"description">) {
	return <TextAreaField formState={formState} name="description" optional />;
}

/**
 * Renders a field component for the `hasImage` field of the DocumentResource model.
 */
export function HasImage({ formState, formType }: FieldProps<"hasImage">) {
	return (
		<BooleanField
			formState={formState}
			name="hasImage"
			label={formType === FormType.Create ? "Add Icon" : "Has Icon"}
		/>
	);
}

/**
 * Renders a field component for the `image` field of the DocumentResource model.
 */
export function Image({ formState }: FieldProps<"image">) {
	return <FileField formState={formState} name="image" label="Icon Image" optional />;
}

const isFileOptions = [
	{ label: "File", value: true },
	{ label: "Link", value: false },
];

/**
 * Renders a field component for the `isFile` field of the DocumentResource model.
 */
export function IsFile({ formState }: FieldProps<"isFile">) {
	return (
		<RadioField
			formState={formState}
			name="isFile"
			label="Resource Type"
			options={isFileOptions}
			noClear
			Input={RadioButtonsInput}
		/>
	);
}

/**
 * Renders a field component for the `file` field of the DocumentResource model.
 */
export function File({ formState }: FieldProps<"file">) {
	return <FileField formState={formState} name="file" />;
}

/**
 * Renders a field component for the `resourceURL` field of the DocumentResource model.
 */
export function ResourceURL({ formState }: FieldProps<"resourceURL">) {
	return <TextField formState={formState} name="resourceURL" />;
}

/**
 * Renders a field component for the `createdAt` field of the DocumentResource model.
 */
export function CreatedAt({ formState, formType = FormType.Update }: DetailFieldProps<"createdAt">) {
	return (
		<DateTimeField formState={formState} name="createdAt" optional={FormType.isCreate(formType)} precision="minute" />
	);
}

export type AuthorProps = DetailFieldProps<"authorID"> & {
	currentAuthor?: UserSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `author` edge of the DocumentResource model.
 */
export function Author({ formState, currentAuthor }: AuthorProps) {
	const [loadOptions, { loading, data }] = useUserSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.authorID) {
			loadOptions();
		}
	}, [formState.formEditing.authorID, loadOptions]);
	const options = React.useMemo(
		() => UserSelect.toOptions(data?.userConnection.edges, currentAuthor),
		[data, currentAuthor],
	);

	return (
		<SelectField
			formState={formState}
			name="authorID"
			isLoading={loading}
			options={options}
			optional
			display={displayAuthor}
			blankValue={null}
		/>
	);
}

function displayAuthor({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserLink instance={{ id }}>{formattedValue}</UserLink>;
}

export type DocumentResourceFoldersProps = DetailFieldProps<"documentResourceFolderIDs"> & {
	currentDocumentResourceFolders?: DocumentResourceFolderSelect.ModelForOption[];
};

/**
 * Renders a field component for the `documentResourceFolders` edge of the DocumentResource model.
 */
export function DocumentResourceFolders({ formState, currentDocumentResourceFolders }: DocumentResourceFoldersProps) {
	const [loadOptions, { loading, data }] = useDocumentResourceFolderSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.documentResourceFolderIDs) {
			loadOptions();
		}
	}, [formState.formEditing.documentResourceFolderIDs, loadOptions]);
	const options = React.useMemo(
		() =>
			DocumentResourceFolderSelect.toMultiOptions(
				data?.documentResourceFolderConnection.edges,
				currentDocumentResourceFolders,
			),
		[data, currentDocumentResourceFolders],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="documentResourceFolderIDs"
			label="Folders"
			isLoading={loading}
			options={options}
			displayInstance={displayDocumentResourceFolderInstance}
		/>
	);
}

function displayDocumentResourceFolderInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <DocumentResourceFolderLink instance={{ id }}>{formattedValue}</DocumentResourceFolderLink>;
}
