import { ClassYear, Course, HomeRoom, HomeRoomSection } from "../../GraphQL";

export type Base = {
	name: HomeRoom["name"];
	description: HomeRoom["description"];
	studentCapacity: HomeRoom["studentCapacity"] | null;
	classYearID: ClassYear["id"] | null;
};

export type Create = Base;

export type Detail = Base & {
	courseIDs: Course["id"][];
	homeRoomSectionIDs: HomeRoomSection["id"][];
};

export const initialCreate: Create = {
	name: "",
	description: "",
	studentCapacity: null,
	classYearID: null,
};
