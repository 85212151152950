import { NullPartial, omitKeys } from "@hex-insights/core";
import { NoteReactionCreateInput, NoteReactionDetailQuery, NoteReactionUpdateInput } from "../../GraphQL";
import { NoteReactionFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the NoteReaction model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: NoteReactionFormValues.Create): NoteReactionCreateInput {
	const input: NullPartial<NoteReactionCreateInput> = { ...formValues };
	return input as NoteReactionCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the NoteReaction model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<NoteReactionFormValues.Detail>,
	_initialFormValues: NoteReactionFormValues.Detail,
): NoteReactionUpdateInput {
	const inputFormValues = omitKeys(formValues, ["createdAt", "authorID", "noteID"]);
	const input: NoteReactionUpdateInput = { ...inputFormValues };
	return input;
}

/**
 * Converts a NoteReaction instance to its corresponding detail form values.
 *
 * @param noteReaction The instance to convert.
 */
export function toFormValues(noteReaction: NoteReactionDetailQuery["noteReaction"]): NoteReactionFormValues.Detail {
	return {
		reactionType: noteReaction.reactionType,
		createdAt: noteReaction.createdAt,
		authorID: noteReaction.author?.id ?? null,
		noteID: noteReaction.note?.id ?? null,
	};
}
