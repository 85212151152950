import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	EnrollmentInvitation,
	EnrollmentInvitationCreateInput,
	EnrollmentInvitationCreateMutation,
	EnrollmentInvitationDeleteMutation,
	EnrollmentInvitationDetailDocument,
	EnrollmentInvitationDetailQuery,
	EnrollmentInvitationDetailQueryVariables,
	EnrollmentInvitationUpdateInput,
	EnrollmentInvitationUpdateMutation,
	graphTypeNames,
	Student,
	useEnrollmentInvitationCreateMutation,
	useEnrollmentInvitationDeleteMutation,
	useEnrollmentInvitationUpdateMutation,
} from "../../GraphQL";
import { EnrollmentInvitationFormConversion } from "../ModelFormConversion";
import { EnrollmentInvitationFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the EnrollmentInvitation model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the EnrollmentInvitation model.
 */
export function useCreate() {
	const [createEnrollmentInvitation] = useEnrollmentInvitationCreateMutation();

	return React.useCallback(
		async (formValues: EnrollmentInvitationFormValues.Create) => {
			const input = EnrollmentInvitationFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createEnrollmentInvitation({ variables: { input }, update: updateCache });

			return { data: data?.createEnrollmentInvitation ?? null, errors: errors ?? null };
		},
		[createEnrollmentInvitation],
	);
}

/**
 * Returns an `update` function for the EnrollmentInvitation model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the EnrollmentInvitation model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: EnrollmentInvitation["id"]) {
	const [updateEnrollmentInvitation] = useEnrollmentInvitationUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<EnrollmentInvitationFormValues.Detail>,
			initialFormValues: EnrollmentInvitationFormValues.Detail,
		) => {
			const input = EnrollmentInvitationFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateEnrollmentInvitation({
				variables: { id, input },
				update: updateCache,
			});

			return { data: data?.updateEnrollmentInvitation ?? null, errors: errors ?? null };
		},
		[updateEnrollmentInvitation, id],
	);
}

/**
 * Returns a `del` function for the EnrollmentInvitation model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: EnrollmentInvitation["id"]) {
	const [deleteEnrollmentInvitation] = useEnrollmentInvitationDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteEnrollmentInvitation({ variables: { id }, update: updateCache });

		return { data: data?.deleteEnrollmentInvitation ?? false, errors: errors ?? null };
	}, [deleteEnrollmentInvitation, id]);
}

function getUpdateCacheForCreate(input: EnrollmentInvitationCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<EnrollmentInvitationCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createEnrollmentInvitation;

		cache.writeQuery<EnrollmentInvitationDetailQuery, EnrollmentInvitationDetailQueryVariables>({
			query: EnrollmentInvitationDetailDocument,
			data: { enrollmentInvitation: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.studentID) {
			addToEnrollmentInvitationsOfStudentCache(cache, input.studentID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "enrollmentInvitationConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: EnrollmentInvitationUpdateInput,
	initialFormValues: EnrollmentInvitationFormValues.Detail,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<EnrollmentInvitationUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateEnrollmentInvitation;

		if (initialFormValues.studentID && (input.studentID || input.clearStudent)) {
			removeFromEnrollmentInvitationsOfStudentCache(cache, initialFormValues.studentID, updatedObject);
		}
		if (input.studentID) {
			addToEnrollmentInvitationsOfStudentCache(cache, input.studentID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: EnrollmentInvitation["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<EnrollmentInvitationDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteEnrollmentInvitation) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.EnrollmentInvitation }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "enrollmentInvitationConnection" });
		cache.gc();
	};
}

function addToEnrollmentInvitationsOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<EnrollmentInvitation, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			enrollmentInvitations: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromEnrollmentInvitationsOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<EnrollmentInvitation, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			enrollmentInvitations: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<EnrollmentInvitation, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment EnrollmentInvitationRef on EnrollmentInvitation {
				id
			}
		`,
		data: object,
	});
}
