import { IndexForms } from "@hex-insights/app-modules";
import { MealPlanFilterFormValues } from "../../../../Utilities";

export type MealPlanSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the MealPlan model.
 */
export function MealPlanSearchForm(props: MealPlanSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type MealPlanFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, MealPlanFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the MealPlan model.
 */
export function MealPlanFilterFormController(props: MealPlanFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={MealPlanFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `MealPlanFilterForm`
