import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { BusPlanHubCreatePage } from "./CreatePage";
import { busPlanHubCreatePageInfo } from "./CreatePage/pageinfo";
import { BusPlanHubDetailPage } from "./DetailPage";
import { busPlanHubDetailPageInfo } from "./DetailPage/pageinfo";
import { BusPlanHubIndexPage } from "./IndexPage";
import { busPlanHubIndexPageInfo } from "./IndexPage/pageinfo";
import { busPlanHubPageInfo } from "./pageinfo";

export function BusPlanHub() {
	useActivePageRegistration(busPlanHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...busPlanHubIndexPageInfo.routeProps}>
				<BusPlanHubIndexPage />
			</Route>
			<Route {...busPlanHubCreatePageInfo.routeProps}>
				<BusPlanHubCreatePage />
			</Route>
			<Route {...busPlanHubDetailPageInfo.routeProps}>
				<BusPlanHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
