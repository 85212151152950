import { IndexForms } from "@hex-insights/app-modules";
import { SessionOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the Session model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		SessionOrderFormValues.initial,
		SessionOrderFormValues.fieldValues,
	);
}
