import React from "react";
import { useApolloClient } from "@apollo/client";
import { FieldInputOption } from "@hex-insights/forms";
import { CampusFormat } from "../../Format";
import {
	Campus,
	CampusCreateMutation,
	CampusNameFragment,
	CampusSelectDocument,
	CampusSelectQuery,
	CampusSelectQueryVariables,
	graphTypeNames,
	Maybe,
} from "../../GraphQL";

/**
 * A slim version of the Campus model for use in select fields.
 */
export type ModelForOption = Pick<Campus, "id"> & CampusNameFragment;

/**
 * A slim version of the Campus model edge for use in select fields.
 */
export type EdgeForOption = {
	node: ModelForOption;
};

/**
 * Converts the given `edges` to options to be given to a radio or select field.
 *
 * @param edges The edges to convert to options.
 * @param current The current instance for the field. Used to display when `edges` is blank (e.g. with lazy loading).
 */
export function toOptions(edges?: EdgeForOption[], current?: Maybe<ModelForOption>) {
	if (edges === undefined) {
		if (!(current === null || current === undefined)) {
			return [toOption(current)];
		}
		return [];
	}
	return edges.map((e) => toOption(e.node));
}

/**
 * Converts the given `edges` to options to be given to a multi-select field.
 *
 * @param edges The edges to convert to options.
 * @param current The current instances for the field. Used to display when `edges` is blank (e.g. with lazy loading).
 */
export function toMultiOptions(edges?: EdgeForOption[], currents?: ModelForOption[]) {
	if (edges === undefined) {
		if (currents !== undefined) {
			return currents.map(toOption);
		}
		return [];
	}
	return edges.map((e) => toOption(e.node));
}

/**
 * Converts a single Campus instance to an option to be given to a radio, select, or
 * multi-select field.
 *
 * @param instance The instance to convert to an option.
 */
export function toOption(instance: ModelForOption): FieldInputOption<Campus["id"]> {
	return {
		label: CampusFormat.name(instance),
		value: instance.id,
	};
}

/**
 * Returns an `addToCache` function that accepts a single Campus instance and adds it to the
 * cache for the select query. Useful when allowing creating of Campus instance in other
 * models' forms.
 */
export function useAddToCache() {
	const client = useApolloClient();

	return React.useCallback(
		(campus: CampusCreateMutation["createCampus"]) => {
			const cacheResult = client.readQuery<CampusSelectQuery, CampusSelectQueryVariables>({
				query: CampusSelectDocument,
			});
			client.writeQuery<CampusSelectQuery, CampusSelectQueryVariables>({
				query: CampusSelectDocument,
				data: {
					campusConnection: {
						...cacheResult?.campusConnection,
						edges: [
							...(cacheResult?.campusConnection.edges ?? []),
							{
								__typename: graphTypeNames.CampusEdge,
								node: campus,
							},
						],
					},
				},
			});
		},
		[client],
	);
}
