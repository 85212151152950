import { IndexForms } from "@hex-insights/app-modules";
import { SessionFilterFormValues } from "../../../../Utilities";

export type SessionFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, SessionFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Session model.
 */
export function SessionFilterFormController(props: SessionFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={SessionFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `SessionFilterForm`
