import { newPageInfo } from "@hex-insights/router";
import { Employee } from "@hex-insights/verita.shared";
import { employeeHubPageInfo } from "../pageinfo";

export type EmployeeHubDetailPageRouteParams = {
	id: string;
};

type EmployeeForRouteTitle = { person: Pick<Employee["person"], "name"> };

export const employeeHubDetailPageInfo = newPageInfo({
	name: "Employee Detail",
	title: (e: EmployeeForRouteTitle) => e.person.name,
	description: "Detail page for an employee.",
	exact: false,
	isLeaf: true,
	to: (id: Employee["id"]) => employeeHubPageInfo.to + `/${id}`,
	path: employeeHubPageInfo.path + "/:id(\\d+)",
	parent: employeeHubPageInfo,
});
