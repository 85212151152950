import React from "react";
import { Conditional, Else, Heading, If } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { SessionForm, useSessionDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { sessionHubDetailPageInfo } from "./pageinfo";

function SessionHubDetailPageWrapper() {
	// Manages identity of the SessionHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <SessionHubDetailPage key={id} />;
}

export { SessionHubDetailPageWrapper as SessionHubDetailPage };

function SessionHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useSessionDetailQuery({ variables: { id } });
	const session = data?.session;
	useActivePageRegistration(sessionHubDetailPageInfo, !!session ? sessionHubDetailPageInfo.title(session) : "");

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading session</If>
				<Else>
					{!!session && (
						<React.Fragment>
							<Heading level={1}>{session.id}</Heading>

							<SessionForm.ControlledReadOnly session={session} />
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
