import { IndexForms } from "@hex-insights/app-modules";
import { AcademicYearOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the AcademicYear model.
 */
export function useOrderFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		AcademicYearOrderFormValues.initial,
		AcademicYearOrderFormValues.fieldValues,
	);
}
