import { newPageInfo } from "@hex-insights/router";
import { EnrollmentApplicationUserViewRecord } from "@hex-insights/verita.shared";
import { enrollmentApplicationUserViewRecordHubPageInfo } from "../pageinfo";

type EnrollmentApplicationUserViewRecordForRouteTitle = Pick<EnrollmentApplicationUserViewRecord, "id">;

export const enrollmentApplicationUserViewRecordHubDetailPageInfo = newPageInfo({
	name: "Enrollment Application User View Record Detail",
	title: (e: EnrollmentApplicationUserViewRecordForRouteTitle) => e.id,
	description: "Detail page for an enrollmentApplicationUserViewRecord.",
	exact: false,
	isLeaf: true,
	to: (id: EnrollmentApplicationUserViewRecord["id"]) => enrollmentApplicationUserViewRecordHubPageInfo.to + `/${id}`,
	path: enrollmentApplicationUserViewRecordHubPageInfo.path + "/:id(\\d+)",
	parent: enrollmentApplicationUserViewRecordHubPageInfo,
});
