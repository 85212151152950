import React from "react";
import { Column, Heading } from "@hex-insights/core";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { StudentForm, StudentFormValues, StudentMutation } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { studentHubDetailPageInfo } from "../DetailPage/pageinfo";
import { studentHubCreatePageInfo } from "./pageinfo";

export function StudentHubCreatePage() {
	useActivePageRegistration(studentHubCreatePageInfo);

	const create = StudentMutation.useCreateWithPerson();
	const createdIDRef = React.useRef<string | null>(null);
	const { push: pushHistory } = useHistory();

	const applyCreate = React.useCallback(
		async (formValues: StudentFormValues.CreateWithPerson) => {
			const { data, errors } = await create(formValues);
			createdIDRef.current = data?.id ?? null;
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		if (createdIDRef.current !== null) {
			pushHistory(studentHubDetailPageInfo.to(createdIDRef.current));
		}
	}, [pushHistory]);

	return (
		<Main>
			<Heading level={1}>New Student</Heading>

			<Column style={{ width: "fit-content" }}>
				<StudentForm.ControlledCreateWithPerson applyCreate={applyCreate} onSuccess={onSuccess} />
			</Column>
		</Main>
	);
}
