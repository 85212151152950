import React from "react";
import { FieldDisplayArgs, MultiSelectField, SelectField, TextField } from "@hex-insights/forms";
import {
	CourseSectionFormValues,
	CourseSectionPeriodSelect,
	CourseSelect,
	HomeRoomSectionSelect,
	TermSelect,
	useCourseSectionPeriodSelectLazyQuery,
	useCourseSelectLazyQuery,
	useHomeRoomSectionSelectLazyQuery,
	useTermSelectLazyQuery,
} from "../../../../Utilities";
import { CourseLink, CourseSectionPeriodLink, HomeRoomSectionLink, TermLink } from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the CourseSection model.
 */
type FieldProps<K extends keyof CourseSectionFormValues.Base = keyof CourseSectionFormValues.Base> = BaseFieldProps<
	Pick<CourseSectionFormValues.Base, K>
>;

/**
 * Generic props for fields of the CourseSection model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof CourseSectionFormValues.Detail = keyof CourseSectionFormValues.Detail> =
	BaseFieldProps<Pick<CourseSectionFormValues.Detail, K>>;

/**
 * Renders a field component for the `name` field of the CourseSection model.
 */
export function Name({ formState }: FieldProps<"name">) {
	return <TextField formState={formState} name="name" />;
}

/**
 * Renders a field component for the `description` field of the CourseSection model.
 */
export function Description({ formState }: FieldProps<"description">) {
	return <TextAreaField formState={formState} name="description" optional />;
}

export type CourseProps = FieldProps<"courseID"> & {
	currentCourse?: CourseSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `course` edge of the CourseSection model.
 */
export function Course({ formState, currentCourse }: CourseProps) {
	const [loadOptions, { loading, data }] = useCourseSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.courseID) {
			loadOptions();
		}
	}, [formState.formEditing.courseID, loadOptions]);
	const options = React.useMemo(
		() => CourseSelect.toOptions(data?.courseConnection.edges, currentCourse),
		[data, currentCourse],
	);

	return (
		<SelectField
			formState={formState}
			name="courseID"
			isLoading={loading}
			options={options}
			display={displayCourse}
			blankValue={null}
		/>
	);
}

function displayCourse({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <CourseLink instance={{ id }}>{formattedValue}</CourseLink>;
}

export type CourseSectionPeriodsProps = DetailFieldProps<"courseSectionPeriodIDs"> & {
	currentCourseSectionPeriods?: CourseSectionPeriodSelect.ModelForOption[];
};

/**
 * Renders a field component for the `courseSectionPeriods` edge of the CourseSection model.
 */
export function CourseSectionPeriods({ formState, currentCourseSectionPeriods }: CourseSectionPeriodsProps) {
	const [loadOptions, { loading, data }] = useCourseSectionPeriodSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.courseSectionPeriodIDs) {
			loadOptions();
		}
	}, [formState.formEditing.courseSectionPeriodIDs, loadOptions]);
	const options = React.useMemo(
		() =>
			CourseSectionPeriodSelect.toMultiOptions(data?.courseSectionPeriodConnection.edges, currentCourseSectionPeriods),
		[data, currentCourseSectionPeriods],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="courseSectionPeriodIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayCourseSectionPeriodInstance}
		/>
	);
}

function displayCourseSectionPeriodInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <CourseSectionPeriodLink instance={{ id }}>{formattedValue}</CourseSectionPeriodLink>;
}

export type HomeRoomSectionProps = FieldProps<"homeRoomSectionID"> & {
	currentHomeRoomSection?: HomeRoomSectionSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `homeRoomSection` edge of the CourseSection model.
 */
export function HomeRoomSection({ formState, currentHomeRoomSection }: HomeRoomSectionProps) {
	const [loadOptions, { loading, data }] = useHomeRoomSectionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.homeRoomSectionID) {
			loadOptions();
		}
	}, [formState.formEditing.homeRoomSectionID, loadOptions]);
	const options = React.useMemo(
		() => HomeRoomSectionSelect.toOptions(data?.homeRoomSectionConnection.edges, currentHomeRoomSection),
		[data, currentHomeRoomSection],
	);

	return (
		<SelectField
			formState={formState}
			name="homeRoomSectionID"
			isLoading={loading}
			options={options}
			display={displayHomeRoomSection}
			blankValue={null}
		/>
	);
}

function displayHomeRoomSection({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <HomeRoomSectionLink instance={{ id }}>{formattedValue}</HomeRoomSectionLink>;
}

export type TermProps = FieldProps<"termID"> & {
	currentTerm?: TermSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `term` edge of the CourseSection model.
 */
export function Term({ formState, currentTerm }: TermProps) {
	const [loadOptions, { loading, data }] = useTermSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.termID) {
			loadOptions();
		}
	}, [formState.formEditing.termID, loadOptions]);
	const options = React.useMemo(
		() => TermSelect.toOptions(data?.termConnection.edges, currentTerm),
		[data, currentTerm],
	);

	return (
		<SelectField
			formState={formState}
			name="termID"
			isLoading={loading}
			options={options}
			display={displayTerm}
			blankValue={null}
		/>
	);
}

function displayTerm({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <TermLink instance={{ id }}>{formattedValue}</TermLink>;
}
