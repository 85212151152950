import { newPageInfo } from "@hex-insights/router";
import { admissionsPageInfo } from "../pageinfo";

export const admissionsEnrollmentInvitationsPageInfo = newPageInfo({
	title: "EnrollmentInvitations",
	description: "Overview of enrollment invitations.",
	exact: true,
	to: admissionsPageInfo.to + "/enrollment-invitations",
	path: admissionsPageInfo.path + "/enrollment-invitations",
	parent: admissionsPageInfo,
});
