import { newPageInfo } from "@hex-insights/router";
import { expenseCategoryHubPageInfo } from "../pageinfo";

export const expenseCategoryHubCreatePageInfo = newPageInfo({
	name: "New Expense Category",
	title: "New",
	description: "Create a new Expense Category record.",
	exact: true,
	to: expenseCategoryHubPageInfo.to + "/new",
	path: expenseCategoryHubPageInfo.path + "/new",
	parent: expenseCategoryHubPageInfo,
});
