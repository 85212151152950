import { newPageInfo } from "@hex-insights/router";
import { documentResourceFolderHubPageInfo } from "../pageinfo";

export const documentResourceFolderHubIndexPageInfo = newPageInfo({
	name: "Document Resource Folders Index",
	title: "",
	description: "Index of Document Resource Folders.",
	exact: true,
	to: documentResourceFolderHubPageInfo.to,
	path: documentResourceFolderHubPageInfo.path,
	parent: documentResourceFolderHubPageInfo,
});
