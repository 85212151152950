export function TermSetupDetailHomeRoomSectionsCreatePage() {
	return <div>TODO</div>;
}

// import React from "react";
// import {
//     Button,
//     Column,
//     compareObjects,
//     compareStringsAsc,
//     Grid,
//     Heading,
//     mappedArray,
//     pickKeys,
//     Row,
//     Section,
//     sort,
//     useCount,
//     useLazyRef,
// } from "@hex-insights/core";
// import {
//     SubFormField,
//     Form,
//     FormSubmitFunction,
//     NumberField,
//     SubmissionStatus,
//     submissionSuccess,
//     SubmitButton,
//     useFormState,
// } from "@hex-insights/forms";
// import { useActivePageRegistration, useHistory, useRouteParams } from "@hex-insights/router";
// import {
//     getGraduationYearForClassYearAndTerm,
//     HomeRoom,
//     HomeRoomSection,
//     HomeRoomSectionField,
//     HomeRoomSectionFormValues,
//     onCreateBulkSubmit,
//     PersonIcon,
//     Tile,
//     useEmployeeIndexQuery,
//     useHomeRoomDetailQuery,
//     useHomeRoomSectionsBulkCreate,
//     useRoomIndexQuery,
//     useStudentListQuery,
// } from "@hex-insights/verita.shared";
// import { Main } from "../../../../../Components";
// import { useCurrentTerm } from "../../Utilities";
// import { termSetupDetailHomeRoomSectionsDetailPageInfo } from "../DetailPage/pageinfo";
// import { termSetupDetailHomeRoomSectionsCreatePageInfo } from "./pageinfo";
// import generalStyles from "../../general.module.css";

// export function TermSetupDetailHomeRoomSectionsCreatePage() {
//     useActivePageRegistration(termSetupDetailHomeRoomSectionsCreatePageInfo);

//     const { count: pageNumber, increment } = useCount(0);
//     const [homeRoomID, setHomeRoomID] = React.useState<HomeRoom["id"] | null>(null);
//     const [numberOfSections, setNumberOfSections] = React.useState<number>(0);

//     const onSelectionSuccess = React.useCallback(
//         (selectionFormValues: HomeRoomSectionsSelectionFormValues) => {
//             setHomeRoomID(selectionFormValues.homeRoomID);
//             increment();
//         },
//         [increment],
//     );

//     const onEnumerationSuccess = React.useCallback(
//         (enumerationFormValues: HomeRoomSectionsEnumerationFormValues) => {
//             setNumberOfSections(enumerationFormValues.numberOfSections ?? 0);
//             increment();
//         },
//         [increment],
//     );

//     const { termID } = useRouteParams<{ termID: string }>();
//     const { push: pushHistory } = useHistory();
//     const onAssignmentSuccess = React.useCallback(
//         (homeRoomSectionID: HomeRoomSection["id"]) => {
//             pushHistory(termSetupDetailHomeRoomSectionsDetailPageInfo.to(termID, homeRoomSectionID));
//         },
//         [pushHistory, termID],
//     );

//     if (pageNumber <= 0) {
//         return <HomeRoomSelection onSuccess={onSelectionSuccess} />;
//     }
//     if (pageNumber === 1) {
//         return <HomeRoomSectionsEnumeration homeRoomID={homeRoomID ?? "0"} onSuccess={onEnumerationSuccess} />;
//     }
//     return (
//         <HomeRoomSectionsAssignment
//             homeRoomID={homeRoomID ?? "0"}
//             initialNumberOfSections={numberOfSections}
//             onSuccess={onAssignmentSuccess}
//         />
//     );
// }

// type HomeRoomSectionsSelectionFormValues = {
//     homeRoomID: HomeRoom["id"] | null;
// };

// const initialHomeRoomSectionsSelectionFormValues: HomeRoomSectionsSelectionFormValues = {
//     homeRoomID: null,
// };

// type HomeRoomSelectionProps = {
//     onSuccess: (fv: HomeRoomSectionsSelectionFormValues) => void;
// };

// function HomeRoomSelection({ onSuccess }: HomeRoomSelectionProps) {
//     const formState = useFormState({ initialFormValues: initialHomeRoomSectionsSelectionFormValues });

//     React.useEffect(() => {
//         if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
//             onSuccess(formState.formValues);
//         }
//     }, [formState.submissionStatus, onSuccess, formState.formValues]);

//     return (
//         <Main>
//             <Row justify="spaced-start" className={generalStyles["main-row"]}>
//                 <Tile className={generalStyles["controller-tile"]}>
//                     <Tile.Header>
//                         <Heading level={1} noMargin>
//                             Home Room Setup
//                         </Heading>
//                     </Tile.Header>
//                     <Tile.Body>
//                         <Form
//                             formState={formState}
//                             onSubmit={formState.onSubmitWrapper(continueOnSubmit)}
//                             noNotifications
//                         >
//                             <Column justify="spaced-start">
//                                 <HomeRoomSectionField.HomeRoom formState={formState} />

//                                 <Row justify="flex-end">
//                                     <Button variant="primary" onClick={formState.onSubmitWrapper(continueOnSubmit)}>
//                                         Continue
//                                     </Button>
//                                 </Row>
//                             </Column>
//                         </Form>
//                     </Tile.Body>
//                 </Tile>
//             </Row>
//         </Main>
//     );
// }

// type HomeRoomSectionsEnumerationFormValues = {
//     numberOfSections: number | null;
// };

// const initialHomeRoomSectionsEnumerationFormValues: HomeRoomSectionsEnumerationFormValues = {
//     numberOfSections: null,
// };

// type HomeRoomSectionsEnumerationProps = {
//     homeRoomID: HomeRoom["id"];
//     onSuccess: (fv: HomeRoomSectionsEnumerationFormValues) => void;
// };

// function HomeRoomSectionsEnumeration({ homeRoomID, onSuccess }: HomeRoomSectionsEnumerationProps) {
//     const { data: homeRoomData } = useHomeRoomDetailQuery({ variables: { id: homeRoomID } });
//     const homeRoom = homeRoomData?.homeRoom;
//     const term = useCurrentTerm();

//     const { loading: loadingStudents, data: studentsData } = useStudentListQuery({
//         variables: {
//             filters: {
//                 graduatingYearEQ: getGraduationYearForClassYearAndTerm(homeRoom?.classYear ?? null, term ?? null),
//             },
//         },
//     });
//     const studentEdges = studentsData?.studentConnection.edges ?? [];

//     const formState = useFormState({ initialFormValues: initialHomeRoomSectionsEnumerationFormValues });

//     React.useEffect(() => {
//         if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
//             onSuccess(formState.formValues);
//         }
//     }, [formState.submissionStatus, onSuccess, formState.formValues]);

//     return (
//         <Main>
//             <Row justify="spaced-start" className={generalStyles["main-row"]}>
//                 <Tile className={generalStyles["controller-tile"]}>
//                     <Tile.Header>
//                         <Heading level={1} noMargin>
//                             {homeRoom ? homeRoom.name : "..."}
//                         </Heading>
//                     </Tile.Header>
//                     <Tile.Body>
//                         <Column justify="spaced-start">
//                             <Row justify="space-between">
//                                 <span>Number of Students</span>
//                                 <span>{loadingStudents ? "..." : studentEdges.length}</span>
//                             </Row>
//                             <Row justify="space-between">
//                                 <span>Home Room Capacity</span>
//                                 <span>{homeRoom ? homeRoom.studentCapacity : "..."}</span>
//                             </Row>

//                             <Form
//                                 formState={formState}
//                                 onSubmit={formState.onSubmitWrapper(continueOnSubmit)}
//                                 noNotifications
//                             >
//                                 <Column justify="spaced-start">
//                                     <NumberField formState={formState} name="numberOfSections" autoFocus />

//                                     <Row justify="flex-end">
//                                         <Button variant="primary" onClick={formState.onSubmitWrapper(continueOnSubmit)}>
//                                             Continue
//                                         </Button>
//                                     </Row>
//                                 </Column>
//                             </Form>
//                         </Column>
//                     </Tile.Body>
//                 </Tile>

//                 <Tile className={generalStyles["preview-tile--full"]} style={{ flexGrow: 1 }}>
//                     <Tile.Header>
//                         <Heading level={2} noMargin>
//                             Students
//                         </Heading>
//                     </Tile.Header>
//                     <Tile.Body>
//                         <Grid columns={5}>
//                             {studentEdges.map((e) => (
//                                 <PersonIcon key={e.node.id} person={e.node.person} withName />
//                             ))}
//                         </Grid>
//                     </Tile.Body>
//                 </Tile>
//             </Row>
//         </Main>
//     );
// }

// type HomeRoomSectionsAssignmentFormValues = {
//     homeRoomSections: Pick<HomeRoomSectionFormValues.Create, "name" | "roomID" | "teacherIDs" | "studentIDs">[];
// };

// type HomeRoomSectionsAssignmentProps = {
//     homeRoomID: HomeRoom["id"];
//     initialNumberOfSections: number;
//     onSuccess: (id: HomeRoomSection["id"]) => void;
// };

// function HomeRoomSectionsAssignment({
//     homeRoomID,
//     initialNumberOfSections,
//     onSuccess,
// }: HomeRoomSectionsAssignmentProps) {
//     const { termID } = useRouteParams<{ termID: string }>();
//     const term = useCurrentTerm();

//     const { data: homeRoomData } = useHomeRoomDetailQuery({ variables: { id: homeRoomID } });
//     const homeRoom = homeRoomData?.homeRoom;

//     const studentFilters = React.useMemo(
//         () => ({
//             graduatingYearEQ: getGraduationYearForClassYearAndTerm(homeRoom?.classYear ?? null, term ?? null),
//         }),
//         [homeRoom, term],
//     );

//     const { data: studentsData } = useStudentListQuery({ variables: { filters: studentFilters } });
//     const studentEdges = studentsData?.studentConnection.edges ?? [];
//     const { data: employeesData } = useEmployeeIndexQuery();
//     const employeeEdges = employeesData?.employeeConnection.edges ?? [];
//     const { data: roomsData } = useRoomIndexQuery();
//     const roomEdges = roomsData?.roomConnection.edges ?? [];

//     const blankFormValuesRef = useLazyRef(() =>
//         pickKeys(HomeRoomSectionFormValues.initialCreate, ["name", "roomID", "teacherIDs", "studentIDs"]),
//     );
//     const initialFormValuesRef = useLazyRef<HomeRoomSectionsAssignmentFormValues>(() => ({
//         homeRoomSections: mappedArray(initialNumberOfSections, () => blankFormValuesRef.current),
//     }));
//     const formState = useFormState({ initialFormValues: initialFormValuesRef.current });

//     const targetIDRef = React.useRef<HomeRoomSection["id"] | null>(null);
//     const create = useHomeRoomSectionsBulkCreate();
//     const applyCreate = React.useCallback(
//         async (formValuesSets: HomeRoomSectionFormValues.Create[]) => {
//             const { data, errors } = await create(formValuesSets);
//             if (data && data.edges.length > 0) {
//                 const firstID = sort(
//                     data.edges,
//                     compareObjects((e) => e.node.name, compareStringsAsc()),
//                 )[0].node.id;
//                 targetIDRef.current = firstID;
//             }
//             return errors;
//         },
//         [create],
//     );
//     const onSubmit = React.useCallback<FormSubmitFunction<HomeRoomSectionsAssignmentFormValues>>(
//         async (formState) => {
//             const formValuesSets = formState.formValues.homeRoomSections.map((e) => ({
//                 ...e,
//                 description: homeRoom?.description ?? "DESCRIPTION NEEDED",
//                 courseSectionIDs: [],
//                 homeRoomID,
//                 termID,
//             }));
//             return onCreateBulkSubmit(formValuesSets, applyCreate);
//         },
//         [applyCreate, homeRoom, homeRoomID, termID],
//     );

//     React.useEffect(() => {
//         if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
//             onSuccess(targetIDRef.current ?? "0");
//         }
//     }, [formState.submissionStatus, onSuccess, formState.formValues]);

//     return (
//         <Main>
//             <Row justify="spaced-start" className={generalStyles["main-row"]}>
//                 <Tile className={generalStyles["controller-tile"]}>
//                     <Tile.Header>
//                         <Heading level={1} noMargin>
//                             {homeRoom ? homeRoom.name : "..."}
//                         </Heading>
//                     </Tile.Header>
//                     <Tile.Body>
//                         <Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} noNotifications>
//                             <Column justify="spaced-start">
//                                 <SubFormField
//                                     formState={formState}
//                                     name="homeRoomSections"
//                                     blankItem={blankFormValuesRef.current}
//                                 >
//                                     {({ formState, index, onRemoveClick }) => (
//                                         <Column justify="spaced-start" style={{ marginBottom: "1rem" }}>
//                                             <Row justify="space-between" align="center">
//                                                 <Heading level={2} noMargin>
//                                                     Section {index + 1}
//                                                 </Heading>

//                                                 <Button
//                                                     variant="tertiary"
//                                                     size="small"
//                                                     onClick={onRemoveClick}
//                                                     disabled={formState.disabled}
//                                                 >
//                                                     Remove
//                                                 </Button>
//                                             </Row>
//                                             <HomeRoomSectionField.Name formState={formState} />
//                                             <HomeRoomSectionField.Room formState={formState} />
//                                             <HomeRoomSectionField.Teachers formState={formState} />
//                                             <HomeRoomSectionField.Students
//                                                 formState={formState}
//                                                 queryVariables={{ filters: studentFilters }}
//                                             />
//                                         </Column>
//                                     )}
//                                     {({ onClick, disabled }) => (
//                                         <Row justify="center">
//                                             <Button variant="secondary" onClick={onClick} disabled={disabled}>
//                                                 Add Another Section
//                                             </Button>
//                                         </Row>
//                                     )}
//                                 </SubFormField>

//                                 <Row justify="flex-end">
//                                     <SubmitButton
//                                         submissionStatus={formState.submissionStatus}
//                                         onClick={formState.onSubmitWrapper(onSubmit)}
//                                     >
//                                         Continue
//                                     </SubmitButton>
//                                 </Row>
//                             </Column>
//                         </Form>
//                     </Tile.Body>
//                 </Tile>

//                 <Tile className={generalStyles["preview-tile--full"]} style={{ flexGrow: 1 }}>
//                     <Tile.Body>
//                         {formState.formValues.homeRoomSections.map((homeRoomSectionFormValues, i) => (
//                             <Section key={i}>
//                                 <Section.Header>
//                                     <Row justify="spaced-start" align="center">
//                                         <Heading
//                                             level={2}
//                                             noMargin
//                                             style={!homeRoomSectionFormValues.name ? { color: "#aaa" } : undefined}
//                                         >
//                                             {homeRoomSectionFormValues.name || `Section ${i + 1}`}
//                                         </Heading>
//                                         <span>
//                                             {
//                                                 roomEdges.find((e) => e.node.id === homeRoomSectionFormValues.roomID)
//                                                     ?.node?.name
//                                             }
//                                         </span>
//                                     </Row>
//                                 </Section.Header>
//                                 <Section.Body>
//                                     <Section>
//                                         <Section.Header>
//                                             <Heading level={3} noMargin>
//                                                 Teachers
//                                             </Heading>
//                                         </Section.Header>
//                                         <Section.Body>
//                                             <Grid columns={5}>
//                                                 {employeeEdges.map((e) => {
//                                                     if (!homeRoomSectionFormValues.teacherIDs.includes(e.node.id)) {
//                                                         return null;
//                                                     }
//                                                     return (
//                                                         <PersonIcon key={e.node.id} person={e.node.person} withName />
//                                                     );
//                                                 })}
//                                             </Grid>
//                                         </Section.Body>
//                                     </Section>
//                                     <Section>
//                                         <Section.Header>
//                                             <Heading level={3} noMargin>
//                                                 Students
//                                             </Heading>
//                                         </Section.Header>
//                                         <Section.Body>
//                                             <Grid columns={5}>
//                                                 {studentEdges.map((e) => {
//                                                     if (!homeRoomSectionFormValues.studentIDs.includes(e.node.id)) {
//                                                         return null;
//                                                     }
//                                                     return (
//                                                         <PersonIcon key={e.node.id} person={e.node.person} withName />
//                                                     );
//                                                 })}
//                                             </Grid>
//                                         </Section.Body>
//                                     </Section>
//                                 </Section.Body>
//                             </Section>
//                         ))}
//                     </Tile.Body>
//                 </Tile>
//             </Row>
//         </Main>
//     );
// }

// async function continueOnSubmit() {
//     return submissionSuccess();
// }
