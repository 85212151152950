import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { ClassYearHubCreatePage } from "./CreatePage";
import { classYearHubCreatePageInfo } from "./CreatePage/pageinfo";
import { ClassYearHubDetailPage } from "./DetailPage";
import { classYearHubDetailPageInfo } from "./DetailPage/pageinfo";
import { ClassYearHubIndexPage } from "./IndexPage";
import { classYearHubIndexPageInfo } from "./IndexPage/pageinfo";
import { classYearHubPageInfo } from "./pageinfo";

export function ClassYearHub() {
	useActivePageRegistration(classYearHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...classYearHubIndexPageInfo.routeProps}>
				<ClassYearHubIndexPage />
			</Route>
			<Route {...classYearHubCreatePageInfo.routeProps}>
				<ClassYearHubCreatePage />
			</Route>
			<Route {...classYearHubDetailPageInfo.routeProps}>
				<ClassYearHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
