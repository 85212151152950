import { newPageInfo } from "@hex-insights/router";
import { resourcesPageInfo, ResourcesPageRouteParams } from "../pageinfo";

export type ResourcesFoldersPageRouteParams = ResourcesPageRouteParams;

export const resourcesFoldersPageInfo = newPageInfo({
	title: "Folders",
	description: "Folder page.",
	exact: false,
	to: resourcesPageInfo.to + "/folders",
	path: resourcesPageInfo.path + "/folders",
	parent: resourcesPageInfo,
});
