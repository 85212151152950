import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import {
	MFAInvalidOTPAttempt,
	MFAInvalidOTPAttemptFormat,
	MFAInvalidOTPAttemptNameFragment,
} from "../../../../Utilities";

type ModelForLink = Pick<MFAInvalidOTPAttempt, "id"> & MFAInvalidOTPAttemptNameFragment;

export type MFAInvalidOTPAttemptLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given MFAInvalidOTPAttempt instance.
 */
export function MFAInvalidOTPAttemptLink(props: MFAInvalidOTPAttemptLinkProps) {
	return (
		<Models.Link<ModelForLink> pageInfoKey="MFAInvalidOTPAttempt" format={MFAInvalidOTPAttemptFormat.name} {...props} />
	);
}
