import React from "react";
import { categoricalColorSchemes } from "@hex-insights/charts";
import {
	abbreviateNumber,
	addTimeToDate,
	Button,
	ClassNameProps,
	Column,
	compareObjects,
	compareStringsAsc,
	dateTruncMonth,
	formatDateTime,
	formatPercentage,
	getObjectValues,
	Heading,
	Icon,
	List,
	Modal,
	Row,
	Section,
	sort,
	StyleProps,
	toLocalDateString,
	Tooltip,
} from "@hex-insights/core";
import { Label } from "@hex-insights/forms";
import { InternalLink } from "@hex-insights/router";
import { BasicTable } from "@hex-insights/tables";
import {
	ExpenseCategory,
	ExpenseIndexQuery,
	ExpenseOrderField,
	formatPrice,
	OrderDirection,
	Tile,
	useExpenseBudgetIndexQuery,
	useExpenseIndexQuery,
} from "@hex-insights/verita.shared";
import { expenseHubDetailPageInfo } from "../../../../Pages/Hubs/Expense/DetailPage/pageinfo";
import { BudgetPie } from "../../../BudgetPie";
import styles from "./styles.module.css";

type AggregatedExpenseBudgetData = {
	total: {
		budgetAmount: number;
		expenseAmount: number;
	};
	categories: AggregatedExpenseCategoryBudgetData[];
};

type AggregatedExpenseCategoryBudgetData = {
	categoryID: ExpenseCategory["id"];
	categoryName: ExpenseCategory["name"];
	budgetAmount: number;
	expenseAmount: number;
};

export type ExpensesTileProps = Partial<ClassNameProps & StyleProps>;

export function ExpensesTile({ className, style }: ExpensesTileProps) {
	const { startDate, endDate } = React.useMemo(() => {
		const startDate = dateTruncMonth(new Date());
		const endDate = addTimeToDate(startDate, [1, "month"]);
		return { startDate, endDate };
	}, []);

	const { data: budgetData } = useExpenseBudgetIndexQuery({
		variables: {
			filters: {
				budgetMonthEQ: toLocalDateString(startDate),
			},
		},
	});
	const sortedBudgetEdges = budgetData
		? sort(
				budgetData.expenseBudgetConnection.edges,
				compareObjects((e) => e.node.expenseCategory.name, compareStringsAsc()),
		  )
		: [];
	const { data: expensesData } = useExpenseIndexQuery({
		variables: {
			filters: {
				createdDateGTE: toLocalDateString(startDate),
				createdDateLT: toLocalDateString(endDate),
			},
			order: { field: ExpenseOrderField.CreatedDate, direction: OrderDirection.Desc },
		},
	});

	const aggregatedData = React.useMemo(() => {
		const aggregatedData: AggregatedExpenseBudgetData = {
			total: {
				budgetAmount: 0,
				expenseAmount: 0,
			},
			categories: [],
		};

		if (!(budgetData && expensesData)) {
			return aggregatedData;
		}

		const categoryDataMap: Record<ExpenseCategory["id"], AggregatedExpenseCategoryBudgetData> = {};
		for (let i = 0; i < budgetData.expenseBudgetConnection.edges.length; i++) {
			const budget = budgetData.expenseBudgetConnection.edges[i].node;
			categoryDataMap[budget.expenseCategory.id] = {
				categoryID: budget.expenseCategory.id,
				categoryName: budget.expenseCategory.name,
				budgetAmount: budget.amount,
				expenseAmount: 0,
			};
			aggregatedData.total.budgetAmount += budget.amount;
		}
		for (let i = 0; i < expensesData.expenseConnection.edges.length; i++) {
			const expense = expensesData.expenseConnection.edges[i].node;
			if (!categoryDataMap[expense.expenseCategory.id]) {
				categoryDataMap[expense.expenseCategory.id] = {
					categoryID: expense.expenseCategory.id,
					categoryName: expense.expenseCategory.name,
					budgetAmount: 0,
					expenseAmount: 0,
				};
			}
			categoryDataMap[expense.expenseCategory.id].expenseAmount += expense.amount;
			aggregatedData.total.expenseAmount += expense.amount;
		}

		aggregatedData.categories = getObjectValues(categoryDataMap).sort(
			compareObjects("categoryName", compareStringsAsc()),
		);

		return aggregatedData;
	}, [budgetData, expensesData]);

	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<Tile className={className} style={{ ...style, position: "relative" }}>
			<Tile.Body>
				<Column>
					<Row justify="spaced-start" align="center">
						<BudgetProgressBar data={aggregatedData} />

						<BudgetStats
							expenseAmount={aggregatedData.total.expenseAmount}
							budgetAmount={aggregatedData.total.budgetAmount}
							style={{ flexShrink: 0 }}
						/>

						<Button
							variant="link"
							size="large"
							onClick={toggleIsModalOpen}
							className={styles["expenses-expand-button"]}
							style={{ padding: 0, border: 0 }}
						>
							<Icon.ChevronRight
								size="2.5rem"
								className={styles["expenses-expand-button__icon"]}
								style={{ display: "block" }}
							/>
						</Button>
					</Row>

					<Modal.If condition={isModalOpen}>
						<Modal onClose={toggleIsModalOpen} style={{ width: "80vw", maxWidth: "none", height: "70vh" }}>
							<Modal.Body style={{ height: "100%" }}>
								<Column style={{ height: "100%" }}>
									<Row justify="spaced-start" align="center">
										<BudgetProgressBar data={aggregatedData} />

										<BudgetStats
											expenseAmount={aggregatedData.total.expenseAmount}
											budgetAmount={aggregatedData.total.budgetAmount}
											style={{ flexShrink: 0 }}
										/>
									</Row>

									<Row justify="space-around" style={{ height: "15rem", padding: "1rem 0" }}>
										<Row justify="spaced-start">
											{aggregatedData.categories.map((e, i) => (
												<BudgetCategoryProgressBar
													key={e.categoryID}
													data={e}
													color={getColor(categoricalColorSchemes.tableau10, i)}
												/>
											))}
										</Row>

										<div className={styles["budget-pie-container"]}>
											<BudgetPie data={sortedBudgetEdges} />
										</div>
									</Row>

									<Row style={{ height: "100%", overflow: "hidden" }}>
										<BudgetBreakdown data={aggregatedData} />
										<ExpensesList edges={expensesData?.expenseConnection.edges ?? []} />
									</Row>
								</Column>
							</Modal.Body>
						</Modal>
					</Modal.If>
				</Column>
			</Tile.Body>
		</Tile>
	);
}

type BudgetCategoryProgressBarProps = {
	data: AggregatedExpenseCategoryBudgetData;
	color: string;
};

function BudgetCategoryProgressBar({ data, color }: BudgetCategoryProgressBarProps) {
	const expenseHeight = getExpensePercentage(data);

	return (
		<Column justify="spaced-start" style={{ "--category-color": color } as React.CSSProperties}>
			<Column justify="flex-end" className={styles["budget-category-progress-bar"]}>
				<div style={{ height: expenseHeight }} className={styles["budget-category-progress-bar__bar"]}></div>
			</Column>

			<Tooltip.Container>
				<div className={styles["budget-category-progress-bar__tooltip-base"]}></div>

				<Tooltip>
					<Tooltip.Header>
						<span style={{ fontSize: "1.1rem", whiteSpace: "nowrap" }}>{data.categoryName}</span>
					</Tooltip.Header>
					<Tooltip.Body>
						<BasicTable style={{ "--table__cell---font-size": "1rem" } as React.CSSProperties}>
							<BasicTable.Body>
								<BasicTable.Row>
									<BasicTable.Cell>Budget</BasicTable.Cell>
									<BasicTable.Cell>{formatPrice(data.budgetAmount)}</BasicTable.Cell>
								</BasicTable.Row>
								<BasicTable.Row>
									<BasicTable.Cell>Expenses</BasicTable.Cell>
									<BasicTable.Cell>{formatPrice(data.expenseAmount)}</BasicTable.Cell>
								</BasicTable.Row>
							</BasicTable.Body>
						</BasicTable>
					</Tooltip.Body>
				</Tooltip>
			</Tooltip.Container>
		</Column>
	);
}

type BudgetProgressBarProps = {
	data: AggregatedExpenseBudgetData;
};

function getColor(colorSet: string[], index: number) {
	return colorSet[index % colorSet.length];
}

function BudgetProgressBar({ data }: BudgetProgressBarProps) {
	const expensesWidth = getExpensePercentage(data.total);

	return (
		<div className={styles["budget-progress-bar"]}>
			<Row className={styles["budget-progress-bar__expense-bars"]} style={{ width: expensesWidth }}>
				{data.categories.map((e, i) => (
					<BudgetProgressBarItemBar
						key={e.categoryID}
						data={e}
						totalExpenseAmount={data.total.expenseAmount}
						color={getColor(categoricalColorSchemes.tableau10, i)}
					/>
				))}
			</Row>
			<Row className={styles["budget-progress-bar__expense-tooltips"]} style={{ width: expensesWidth }}>
				{data.categories.map((e) => (
					<BudgetProgressBarItemTooltip key={e.categoryID} data={e} totalExpenseAmount={data.total.expenseAmount} />
				))}
			</Row>
		</div>
	);
}

function getExpensePercentage(data: AggregatedExpenseBudgetData["total"]) {
	if (data.expenseAmount === 0 && data.budgetAmount === 0) {
		return "0%";
	}

	if (data.expenseAmount >= data.budgetAmount) {
		return "100%";
	}

	return `${(data.expenseAmount / data.budgetAmount) * 100}%`;
}

type BudgetProgressBarItemBarProps = {
	data: AggregatedExpenseCategoryBudgetData;
	totalExpenseAmount: number;
	color: string;
};

function BudgetProgressBarItemBar({ data, totalExpenseAmount, color }: BudgetProgressBarItemBarProps) {
	return (
		<div
			style={{
				width: `${(data.expenseAmount / totalExpenseAmount) * 100}%`,
				height: "100%",
				backgroundColor: color,
			}}
		/>
	);
}

// const tooltipSideOrder: CardinalLocation[] = ["bottom", "top"];

type BudgetProgressBarItemTooltipProps = {
	data: AggregatedExpenseCategoryBudgetData;
	totalExpenseAmount: number;
};

function BudgetProgressBarItemTooltip({ data, totalExpenseAmount }: BudgetProgressBarItemTooltipProps) {
	return (
		<Tooltip.Container
			side="vertical"
			// sideOrder={tooltipSideOrder}
			style={{
				width: `${(data.expenseAmount / totalExpenseAmount) * 100}%`,
				height: "100%",
			}}
		>
			<Tooltip>
				<Tooltip.Header>
					<span style={{ fontSize: "1.1rem", whiteSpace: "nowrap" }}>{data.categoryName}</span>
				</Tooltip.Header>
				<Tooltip.Body>
					<BasicTable style={{ "--table__cell---font-size": "1rem" } as React.CSSProperties}>
						<BasicTable.Body>
							<BasicTable.Row>
								<BasicTable.Cell>Budget</BasicTable.Cell>
								<BasicTable.Cell>{formatPrice(data.budgetAmount)}</BasicTable.Cell>
							</BasicTable.Row>
							<BasicTable.Row>
								<BasicTable.Cell>Expenses</BasicTable.Cell>
								<BasicTable.Cell>{formatPrice(data.expenseAmount)}</BasicTable.Cell>
							</BasicTable.Row>
						</BasicTable.Body>
					</BasicTable>
				</Tooltip.Body>
			</Tooltip>
		</Tooltip.Container>
	);
}

type BudgetStatsProps = {
	expenseAmount: number;
	budgetAmount: number;
} & Partial<ClassNameProps & StyleProps>;

function BudgetStats({ expenseAmount, budgetAmount, className, style }: BudgetStatsProps) {
	return (
		<Row justify="spaced-start" className={className} style={style}>
			<Column>
				<Label>Budget</Label>
				<span>
					{abbreviateNumber(expenseAmount / 100)} / {abbreviateNumber(budgetAmount / 100)} (
					{formatPercentage(budgetAmount !== 0 ? expenseAmount / budgetAmount : 0)})
				</span>
			</Column>
		</Row>
	);
}

type BudgetBreakdownProps = {
	data: AggregatedExpenseBudgetData;
};

function BudgetBreakdown({ data }: BudgetBreakdownProps) {
	return (
		<Section style={{ flexGrow: 1, height: "100%" }}>
			<Section.Body style={{ height: "100%", paddingTop: 0, overflow: "scroll" }}>
				<BasicTable style={{ "--table__header---top": "0rem", width: "100%" } as React.CSSProperties}>
					<BasicTable.Header>
						<BasicTable.Row>
							<BasicTable.Heading className={styles["budget-breakdown__table__heading"]}>Category</BasicTable.Heading>
							<BasicTable.Heading className={styles["budget-breakdown__table__heading"]}>Budget</BasicTable.Heading>
							<BasicTable.Heading className={styles["budget-breakdown__table__heading"]}>Expenses</BasicTable.Heading>
							<BasicTable.Heading className={styles["budget-breakdown__table__heading"]}>Progress</BasicTable.Heading>
						</BasicTable.Row>
					</BasicTable.Header>
					<BasicTable.Body>
						{data.categories.map((e) => {
							const budgetPercent = e.expenseAmount / e.budgetAmount;
							return (
								<BasicTable.Row key={e.categoryID}>
									<BasicTable.Cell>{e.categoryName}</BasicTable.Cell>
									<BasicTable.Cell numeric>{formatPrice(e.budgetAmount)}</BasicTable.Cell>
									<BasicTable.Cell numeric>{formatPrice(e.expenseAmount)}</BasicTable.Cell>
									<BasicTable.Cell
										numeric
										style={{
											color:
												(e.budgetAmount > 0 && budgetPercent <= 1) || (e.budgetAmount === 0 && e.expenseAmount === 0)
													? "var(--success-color)"
													: "var(--danger-color)",
										}}
									>
										{e.budgetAmount !== 0 ? formatPercentage(budgetPercent) : "--%"}
									</BasicTable.Cell>
								</BasicTable.Row>
							);
						})}
					</BasicTable.Body>
				</BasicTable>
			</Section.Body>
		</Section>
	);
}

type ExpensesListProps = {
	edges: ExpenseIndexQuery["expenseConnection"]["edges"];
};

function ExpensesList({ edges }: ExpensesListProps) {
	return (
		<Section style={{ height: "100%" }}>
			<Section.Header>
				<Heading level={3} noMargin>
					Expenses
				</Heading>
			</Section.Header>
			<Section.Body style={{ height: "100%", paddingTop: 0, overflow: "scroll" }}>
				<List style={{ paddingLeft: 0, listStyleType: "none" }}>
					{edges.map(({ node }) => (
						<List.Item key={node.id}>
							<Row justify="space-between">
								<div style={{ marginRight: "0.5rem" }}>
									{node.expenseCategory.name} for {formatPrice(node.amount)} on{" "}
									{formatDateTime(node.createdDate, "DD/MM")}{" "}
								</div>
								<InternalLink to={expenseHubDetailPageInfo.to(node.id)}>view</InternalLink>
							</Row>
						</List.Item>
					))}
				</List>
			</Section.Body>
		</Section>
	);
}
