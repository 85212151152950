import { IndexForms } from "@hex-insights/app-modules";
import { HomeRoomFilterFormValues } from "../../../../Utilities";

export type HomeRoomSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the HomeRoom model.
 */
export function HomeRoomSearchForm(props: HomeRoomSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type HomeRoomFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, HomeRoomFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the HomeRoom model.
 */
export function HomeRoomFilterFormController(props: HomeRoomFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={HomeRoomFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `HomeRoomFilterForm`
