import { newPageInfo } from "@hex-insights/router";
import { discountHubPageInfo } from "../pageinfo";

export const discountHubCreatePageInfo = newPageInfo({
	name: "New Discount",
	title: "New",
	description: "Create a new Discount record.",
	exact: true,
	to: discountHubPageInfo.to + "/new",
	path: discountHubPageInfo.path + "/new",
	parent: discountHubPageInfo,
});
