import { newPageInfo } from "@hex-insights/router";
import { Campus } from "@hex-insights/verita.shared";
import { campusHubPageInfo } from "../pageinfo";

export type CampusHubDetailPageRouteParams = {
	id: string;
};

type CampusForRouteTitle = Pick<Campus, "name">;

export const campusHubDetailPageInfo = newPageInfo({
	name: "Campus Detail",
	title: (e: CampusForRouteTitle) => e.name,
	description: "Detail page for an campus.",
	exact: false,
	isLeaf: true,
	to: (id: Campus["id"]) => campusHubPageInfo.to + `/${id}`,
	path: campusHubPageInfo.path + "/:id(\\d+)",
	parent: campusHubPageInfo,
});
