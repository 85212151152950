import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { ContractFilterInput, ContractOrder, useContractPaginationQuery } from "../../../../Utilities";

export type ContractPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: ContractFilterInput | ContractFilterInput[] | null;
	order: ContractOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the Contract model.
 */
export function ContractPaginationForm({ formState, filters, order, setPaginationInfo }: ContractPaginationFormProps) {
	const { loading, data } = useContractPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.contractConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
