import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { ClassYearCreateInput, ClassYearDetailQuery, ClassYearUpdateInput } from "../../GraphQL";
import { ClassYearFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the ClassYear model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: ClassYearFormValues.Create): ClassYearCreateInput {
	const input: NullPartial<ClassYearCreateInput> = { ...formValues };
	return input as ClassYearCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the ClassYear model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<ClassYearFormValues.Detail>,
	initialFormValues: ClassYearFormValues.Detail,
): ClassYearUpdateInput {
	const inputFormValues = omitKeys(formValues, ["courseIDs", "homeRoomIDs"]);
	const input: ClassYearUpdateInput = { ...inputFormValues };

	if (formValues.courseIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.courseIDs, formValues.courseIDs);
		input.addCourseIDs = added;
		input.removeCourseIDs = removed;
	}

	if (formValues.homeRoomIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.homeRoomIDs, formValues.homeRoomIDs);
		input.addHomeRoomIDs = added;
		input.removeHomeRoomIDs = removed;
	}

	return input;
}

/**
 * Converts a ClassYear instance to its corresponding detail form values.
 *
 * @param classYear The instance to convert.
 */
export function toFormValues(classYear: ClassYearDetailQuery["classYear"]): ClassYearFormValues.Detail {
	return {
		name: classYear.name,
		yearsFromGraduation: classYear.yearsFromGraduation,
		courseIDs: classYear.courses.map((e) => e.id),
		homeRoomIDs: classYear.homeRooms.map((e) => e.id),
	};
}
