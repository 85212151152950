import React from "react";
import { DateTimeField, FieldDisplayArgs, FormType, SelectField, TextField } from "@hex-insights/forms";
import {
	NoteCommentFormValues,
	NoteSelect,
	useNoteSelectLazyQuery,
	UserSelect,
	useUserSelectLazyQuery,
} from "../../../../Utilities";
import { NoteLink, UserLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the NoteComment model.
 */
type FieldProps<K extends keyof NoteCommentFormValues.Base = keyof NoteCommentFormValues.Base> = BaseFieldProps<
	Pick<NoteCommentFormValues.Base, K>
>;

/**
 * Generic props for fields of the NoteComment model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof NoteCommentFormValues.Detail = keyof NoteCommentFormValues.Detail> =
	BaseFieldProps<Pick<NoteCommentFormValues.Detail, K>>;

/**
 * Renders a field component for the `body` field of the NoteComment model.
 */
export function Body({ formState }: FieldProps<"body">) {
	return <TextField formState={formState} name="body" />;
}

/**
 * Renders a field component for the `createdAt` field of the NoteComment model.
 */
export function CreatedAt({ formState, formType = FormType.Update }: DetailFieldProps<"createdAt">) {
	return (
		<DateTimeField formState={formState} name="createdAt" optional={FormType.isCreate(formType)} precision="minute" />
	);
}

export type AuthorProps = DetailFieldProps<"authorID"> & {
	currentAuthor?: UserSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `author` edge of the NoteComment model.
 */
export function Author({ formState, currentAuthor }: AuthorProps) {
	const [loadOptions, { loading, data }] = useUserSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.authorID) {
			loadOptions();
		}
	}, [formState.formEditing.authorID, loadOptions]);
	const options = React.useMemo(
		() => UserSelect.toOptions(data?.userConnection.edges, currentAuthor),
		[data, currentAuthor],
	);

	return (
		<SelectField
			formState={formState}
			name="authorID"
			isLoading={loading}
			options={options}
			optional
			display={displayAuthor}
			blankValue={null}
		/>
	);
}

function displayAuthor({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserLink instance={{ id }}>{formattedValue}</UserLink>;
}

export type NoteProps = FieldProps<"noteID"> & {
	currentNote?: NoteSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `note` edge of the NoteComment model.
 */
export function Note({ formState, currentNote }: NoteProps) {
	const [loadOptions, { loading, data }] = useNoteSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.noteID) {
			loadOptions();
		}
	}, [formState.formEditing.noteID, loadOptions]);
	const options = React.useMemo(
		() => NoteSelect.toOptions(data?.noteConnection.edges, currentNote),
		[data, currentNote],
	);

	return (
		<SelectField
			formState={formState}
			name="noteID"
			isLoading={loading}
			options={options}
			display={displayNote}
			blankValue={null}
		/>
	);
}

function displayNote({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <NoteLink instance={{ id }}>{formattedValue}</NoteLink>;
}
