import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { UserCreateInput, UserDetailQuery, UserUpdateInput } from "../../GraphQL";
import { UserFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the User model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: UserFormValues.Create): UserCreateInput {
	const input: NullPartial<UserCreateInput> = { ...formValues };
	return input as UserCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the User model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<UserFormValues.Detail>,
	initialFormValues: UserFormValues.Detail,
): UserUpdateInput {
	const inputFormValues = omitKeys(formValues, [
		"passwordUpdatedAt",
		"loginRecordIDs",
		"mfaFactorID",
		"mfaInvalidOTPAttemptIDs",
		"sessionIDs",
		"trustedDeviceIDs",
		"permissionGroupIDs",
		"userGroupIDs",
	]);
	const input: UserUpdateInput = { ...inputFormValues };

	if (formValues.personID === null) {
		input.personID = null;
		input.clearPerson = true;
	}

	if (formValues.permissionGroupIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.permissionGroupIDs, formValues.permissionGroupIDs);
		input.addPermissionGroupIDs = added;
		input.removePermissionGroupIDs = removed;
	}

	if (formValues.userGroupIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.userGroupIDs, formValues.userGroupIDs);
		input.addUserGroupIDs = added;
		input.removeUserGroupIDs = removed;
	}

	return input;
}

/**
 * Converts a User instance to its corresponding detail form values.
 *
 * @param user The instance to convert.
 */
export function toFormValues(user: UserDetailQuery["user"]): UserFormValues.Detail {
	return {
		email: user.email,
		status: user.status,
		passwordUpdatedAt: user.passwordUpdatedAt,
		loginRecordIDs: user.loginRecords.map((e) => e.id),
		mfaFactorID: user.mfaFactor?.id ?? null,
		mfaInvalidOTPAttemptIDs: user.mfaInvalidOTPAttempts.map((e) => e.id),
		permissionGroupIDs: user.permissionGroups.map((e) => e.id),
		personID: user.person?.id ?? null,
		sessionIDs: user.sessions.map((e) => e.id),
		trustedDeviceIDs: user.trustedDevices.map((e) => e.id),
		userGroupIDs: user.userGroups.map((e) => e.id),
	};
}
