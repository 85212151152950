import React from "react";
import { Heading } from "@hex-insights/core";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { AddressForm, AddressFormValues, AddressMutation } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { addressHubDetailPageInfo } from "../DetailPage/pageinfo";
import { addressHubCreatePageInfo } from "./pageinfo";

export function AddressHubCreatePage() {
	useActivePageRegistration(addressHubCreatePageInfo);

	const create = AddressMutation.useCreate();
	const createdIDRef = React.useRef<string | null>(null);
	const { push: pushHistory } = useHistory();

	const applyCreate = React.useCallback(
		async (formValues: AddressFormValues.Create) => {
			const { data, errors } = await create(formValues);
			createdIDRef.current = data?.id ?? null;
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		if (createdIDRef.current !== null) {
			pushHistory(addressHubDetailPageInfo.to(createdIDRef.current));
		}
	}, [pushHistory]);

	return (
		<Main>
			<Heading level={1}>New Address</Heading>

			<AddressForm.ControlledCreate applyCreate={applyCreate} onSuccess={onSuccess} />
		</Main>
	);
}
