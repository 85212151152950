import { newPageInfo } from "@hex-insights/router";
import { invoiceLineItemHubPageInfo } from "../pageinfo";

export const invoiceLineItemHubCreatePageInfo = newPageInfo({
	name: "New Invoice Line Item",
	title: "New",
	description: "Create a new Invoice Line Item record.",
	exact: true,
	to: invoiceLineItemHubPageInfo.to + "/new",
	path: invoiceLineItemHubPageInfo.path + "/new",
	parent: invoiceLineItemHubPageInfo,
});
