import React from "react";
import {
	Button,
	Heading,
	Icon,
	Modal,
	ModalProps,
	NotificationContainer,
	NotificationRenderProps,
	RequiredKeys,
	Row,
} from "@hex-insights/core";
import {
	Form,
	FormSubmitFunction,
	submissionFailure,
	SubmissionStatus,
	submissionSuccess,
	SubmitButton,
	TextField,
	useFormState,
} from "@hex-insights/forms";
import {
	enrollmentApplicationAppURL,
	FormActionRow,
	FormColumn,
	TextAreaField,
	useSendEnrollmentApplicationInviteEmailMutation,
} from "@hex-insights/verita.shared";

export function InviteEmailController() {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<Button variant="primary" onClick={toggleIsModalOpen}>
				<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
					<Icon.Send size="1rem" /> Invite
				</Row>
			</Button>

			<Modal.If condition={isModalOpen}>
				<InviteEmailModal onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type InviteEmailFormValues = {
	recipientAddresses: string;
	subject: string;
	body: string;
};

const initialInviteEmailFormValues: InviteEmailFormValues = {
	recipientAddresses: "",
	subject: "You're Invited to Apply to Verita International School!",
	body: `Hello,

We're reaching out to invite you to apply to Verita International School in Athens, Greece.

To submit an application, click here: ${enrollmentApplicationAppURL()}

Thank you,
The Verita Team`,
};

type InviteEmailModalProps = RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function InviteEmailModal({ ifRef, onClose }: InviteEmailModalProps) {
	const formState = useFormState({ initialFormValues: initialInviteEmailFormValues });
	const [sendInvite] = useSendEnrollmentApplicationInviteEmailMutation();

	const onSubmit = React.useCallback<FormSubmitFunction<InviteEmailFormValues>>(
		async (formState) => {
			const { recipientAddresses, subject, body } = formState.formValues;

			const inputs = {
				recipientAddresses: recipientAddresses.split(/[\s,;]+/),
				subject,
				body,
			};
			const { errors } = await sendInvite({ variables: inputs });
			if (errors) {
				return submissionFailure([{ _: ["Failed to send. Please try again later."] }]);
			}
			return submissionSuccess();
		},
		[sendInvite],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onClose();
		}
	}, [formState.submissionStatus, onClose]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} style={{ width: "fit-content", minWidth: "32rem" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					Invite to Apply
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<Form
					formState={formState}
					onSubmit={formState.onSubmitWrapper(onSubmit)}
					style={{ "--text-field---width": "100%" } as React.CSSProperties}
					SubmissionSuccessNotification={InviteEmailSuccessNotification}
				>
					<FormColumn style={{ width: "100%" }}>
						<TextField
							formState={formState}
							name="recipientAddresses"
							hint="Separate multiple addresses with a space, comma, or semi-colon."
							autoFocus
						/>
						<TextField formState={formState} name="subject" />
						<TextAreaField formState={formState} name="body" minRows={4} />

						<FormActionRow>
							<SubmitButton onClick={formState.onSubmitWrapper(onSubmit)} submissionStatus={formState.submissionStatus}>
								<Row justify="spaced-start" horizontalSpacing="0.5rem" align="center">
									<Icon.Send size="1rem" /> Send
								</Row>
							</SubmitButton>
						</FormActionRow>
					</FormColumn>
				</Form>
			</Modal.Body>
		</Modal>
	);
}

function InviteEmailSuccessNotification(props: NotificationRenderProps) {
	return (
		<NotificationContainer variant="success" {...props}>
			Email invite sent.
		</NotificationContainer>
	);
}
