import { newPageInfo } from "@hex-insights/router";
import { resourcesPageInfo, ResourcesPageRouteParams } from "../pageinfo";

export type ResourcesSharedPageRouteParams = ResourcesPageRouteParams;

export const resourcesSharedPageInfo = newPageInfo({
	name: "Resources Shared with Me",
	title: "Shared",
	description: "Folders that have been shared with me.",
	exact: true,
	to: resourcesPageInfo.to + "/shared",
	path: resourcesPageInfo.path + "/shared",
	parent: resourcesPageInfo,
});
