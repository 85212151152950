import { NullPartial } from "@hex-insights/core";
import {
	CourseSectionPeriodCreateInput,
	CourseSectionPeriodDetailQuery,
	CourseSectionPeriodUpdateInput,
} from "../../GraphQL";
import { CourseSectionPeriodFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the CourseSectionPeriod model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: CourseSectionPeriodFormValues.Create): CourseSectionPeriodCreateInput {
	const input: NullPartial<CourseSectionPeriodCreateInput> = { ...formValues };
	return input as CourseSectionPeriodCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the CourseSectionPeriod model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<CourseSectionPeriodFormValues.Detail>,
	_initialFormValues: CourseSectionPeriodFormValues.Detail,
): CourseSectionPeriodUpdateInput {
	const input: CourseSectionPeriodUpdateInput = { ...formValues };
	return input;
}

/**
 * Converts a CourseSectionPeriod instance to its corresponding detail form values.
 *
 * @param courseSectionPeriod The instance to convert.
 */
export function toFormValues(
	courseSectionPeriod: CourseSectionPeriodDetailQuery["courseSectionPeriod"],
): CourseSectionPeriodFormValues.Detail {
	return {
		dayOfWeek: courseSectionPeriod.dayOfWeek,
		startTimeMinutes: courseSectionPeriod.startTimeMinutes,
		endTimeMinutes: courseSectionPeriod.endTimeMinutes,
		courseSectionID: courseSectionPeriod.courseSection?.id ?? null,
		roomID: courseSectionPeriod.room?.id ?? null,
	};
}
