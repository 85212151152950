import React from "react";
import { Column } from "@hex-insights/core";
import { FormState, FormType } from "@hex-insights/forms";
import {
	MFAInvalidOTPAttemptDetailQuery,
	MFAInvalidOTPAttemptFormConversion,
	MFAInvalidOTPAttemptFormState,
	MFAInvalidOTPAttemptFormValues,
} from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledReadOnlyProps = {
	mfaInvalidOTPAttempt: MFAInvalidOTPAttemptDetailQuery["mfaInvalidOTPAttempt"];
};

/**
 * Renders a read-only detail form of the MFAInvalidOTPAttempt model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => MFAInvalidOTPAttemptFormConversion.toFormValues(props.mfaInvalidOTPAttempt),
		[props.mfaInvalidOTPAttempt],
	);
	const formState = MFAInvalidOTPAttemptFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<MFAInvalidOTPAttemptFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the MFAInvalidOTPAttempt model using the given form state.
 */
export function ReadOnly({ formState, mfaInvalidOTPAttempt }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.CreatedAt formState={formState} formType={FormType.Update} id={mfaInvalidOTPAttempt.id} />
			<Field.Session
				formState={formState}
				formType={FormType.Update}
				id={mfaInvalidOTPAttempt.id}
				currentSession={mfaInvalidOTPAttempt.session}
			/>
			<Field.User
				formState={formState}
				formType={FormType.Update}
				id={mfaInvalidOTPAttempt.id}
				currentUser={mfaInvalidOTPAttempt.user}
			/>
		</Column>
	);
}
