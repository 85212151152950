import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { EmployeeCreateInput, EmployeeDetailQuery, EmployeeUpdateInput, PersonUpdateInput } from "../../GraphQL";
import { EmployeeFormValues, PersonFormValues } from "../ModelFormValues";
import * as PersonFormConversion from "./person";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Employee model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: EmployeeFormValues.Create): EmployeeCreateInput {
	const input: NullPartial<EmployeeCreateInput> = { ...formValues };
	return input as EmployeeCreateInput;
}

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Employee model with nested
 * Person form values.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateWithPersonInput(formValues: EmployeeFormValues.CreateWithPerson): EmployeeCreateInput {
	const inputFormValues = EmployeeFormValues.omitPersonCreateFormValues(formValues);
	const input: NullPartial<EmployeeCreateInput> = { ...inputFormValues };
	const personFormValues = PersonFormValues.pickCreateInOtherFormValues(formValues);
	input.person = PersonFormConversion.toGQLCreateInOtherInput(personFormValues);
	return input as EmployeeCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Employee model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<EmployeeFormValues.Detail>,
	initialFormValues: Partial<EmployeeFormValues.Detail>,
): EmployeeUpdateInput {
	const inputFormValues = omitKeys(formValues, ["campusIDs"]);
	const input: EmployeeUpdateInput = { ...inputFormValues };

	if (formValues.endDate === null) {
		input.clearEndDate = true;
	}

	if (formValues.campusIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.campusIDs ?? [], formValues.campusIDs);
		input.addCampusIDs = added;
		input.removeCampusIDs = removed;
	}

	return input;
}

export function toGQLUpdateWithPersonInput(
	formValues: Partial<EmployeeFormValues.DetailWithPerson>,
	initialFormValues: Partial<EmployeeFormValues.DetailWithPerson>,
): [EmployeeUpdateInput, PersonUpdateInput] {
	const inputFormValues = EmployeeFormValues.omitPersonDetailFormValues(formValues);
	const initialInputFormValues = EmployeeFormValues.omitPersonDetailFormValues(initialFormValues);
	const input = toGQLUpdateInput(inputFormValues, initialInputFormValues);
	const personFormValues = PersonFormValues.pickDetailInOtherFormValues(formValues);
	const initialPersonFormValues = PersonFormValues.pickDetailInOtherFormValues(initialFormValues);
	const personInput = PersonFormConversion.toGQLUpdateInput(personFormValues, initialPersonFormValues);
	return [input, personInput];
}

/**
 * Converts a Employee instance to its corresponding detail form values.
 *
 * @param employee The instance to convert.
 */
export function toFormValues(employee: EmployeeDetailQuery["employee"]): EmployeeFormValues.Detail {
	return {
		email: employee.email,
		role: employee.role,
		jobTitle: employee.jobTitle,
		startDate: employee.startDate,
		endDate: employee.endDate,
		campusIDs: employee.campuses.map((e) => e.id),
		personID: employee.person?.id ?? null,
	};
}

export function toFormValuesWithPerson(employee: EmployeeDetailQuery["employee"]): EmployeeFormValues.DetailWithPerson {
	return {
		...omitKeys(toFormValues(employee), ["personID"]),
		...PersonFormConversion.toFormValuesInOther(employee.person),
	};
}
