import { newPageInfo } from "@hex-insights/router";
import { calendarEventHubPageInfo } from "../pageinfo";

export const calendarEventHubCreatePageInfo = newPageInfo({
	name: "New Calendar Event",
	title: "New",
	description: "Create a new Calendar Event record.",
	exact: true,
	to: calendarEventHubPageInfo.to + "/new",
	path: calendarEventHubPageInfo.path + "/new",
	parent: calendarEventHubPageInfo,
});
