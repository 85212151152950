import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	graphTypeNames,
	LearningObjective,
	LearningObjectiveMark,
	LearningObjectiveMarkCreateInput,
	LearningObjectiveMarkCreateMutation,
	LearningObjectiveMarkDeleteMutation,
	LearningObjectiveMarkDetailDocument,
	LearningObjectiveMarkDetailQuery,
	LearningObjectiveMarkDetailQueryVariables,
	LearningObjectiveMarkUpdateInput,
	LearningObjectiveMarkUpdateMutation,
	ReportCard,
	useLearningObjectiveMarkCreateMutation,
	useLearningObjectiveMarkDeleteMutation,
	useLearningObjectiveMarkUpdateMutation,
} from "../../GraphQL";
import { LearningObjectiveMarkFormConversion } from "../ModelFormConversion";
import { LearningObjectiveMarkFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the LearningObjectiveMark model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the LearningObjectiveMark model.
 */
export function useCreate() {
	const [createLearningObjectiveMark] = useLearningObjectiveMarkCreateMutation();

	return React.useCallback(
		async (formValues: LearningObjectiveMarkFormValues.Create) => {
			const input = LearningObjectiveMarkFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createLearningObjectiveMark({ variables: { input }, update: updateCache });

			return { data: data?.createLearningObjectiveMark ?? null, errors: errors ?? null };
		},
		[createLearningObjectiveMark],
	);
}

/**
 * Returns an `update` function for the LearningObjectiveMark model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the LearningObjectiveMark model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: LearningObjectiveMark["id"]) {
	const [updateLearningObjectiveMark] = useLearningObjectiveMarkUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<LearningObjectiveMarkFormValues.Detail>,
			initialFormValues: LearningObjectiveMarkFormValues.Detail,
		) => {
			const input = LearningObjectiveMarkFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateLearningObjectiveMark({
				variables: { id, input },
				update: updateCache,
			});

			return { data: data?.updateLearningObjectiveMark ?? null, errors: errors ?? null };
		},
		[updateLearningObjectiveMark, id],
	);
}

/**
 * Returns a `del` function for the LearningObjectiveMark model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: LearningObjectiveMark["id"]) {
	const [deleteLearningObjectiveMark] = useLearningObjectiveMarkDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteLearningObjectiveMark({ variables: { id }, update: updateCache });

		return { data: data?.deleteLearningObjectiveMark ?? false, errors: errors ?? null };
	}, [deleteLearningObjectiveMark, id]);
}

function getUpdateCacheForCreate(input: LearningObjectiveMarkCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<LearningObjectiveMarkCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createLearningObjectiveMark;

		cache.writeQuery<LearningObjectiveMarkDetailQuery, LearningObjectiveMarkDetailQueryVariables>({
			query: LearningObjectiveMarkDetailDocument,
			data: { learningObjectiveMark: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.learningObjectiveID) {
			addToLearningObjectiveMarksOfLearningObjectiveCache(cache, input.learningObjectiveID, createdObject);
		}

		if (input.reportCardID) {
			addToLearningObjectiveMarksOfReportCardCache(cache, input.reportCardID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "learningObjectiveMarkConnection" });
	};
}

export function getUpdateCacheForUpdate(
	input: LearningObjectiveMarkUpdateInput,
	initialFormValues: LearningObjectiveMarkFormValues.Detail,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<LearningObjectiveMarkUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateLearningObjectiveMark;

		if (initialFormValues.learningObjectiveID && input.learningObjectiveID) {
			removeFromLearningObjectiveMarksOfLearningObjectiveCache(
				cache,
				initialFormValues.learningObjectiveID,
				updatedObject,
			);
		}
		if (input.learningObjectiveID) {
			addToLearningObjectiveMarksOfLearningObjectiveCache(cache, input.learningObjectiveID, updatedObject);
		}

		if (initialFormValues.reportCardID && input.reportCardID) {
			removeFromLearningObjectiveMarksOfReportCardCache(cache, initialFormValues.reportCardID, updatedObject);
		}
		if (input.reportCardID) {
			addToLearningObjectiveMarksOfReportCardCache(cache, input.reportCardID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: LearningObjectiveMark["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<LearningObjectiveMarkDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteLearningObjectiveMark) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.LearningObjectiveMark }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "learningObjectiveMarkConnection" });
		cache.gc();
	};
}

function addToLearningObjectiveMarksOfLearningObjectiveCache(
	cache: ApolloCache<any>,
	targetID: LearningObjective["id"],
	object: Pick<LearningObjectiveMark, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.LearningObjective }),
		fields: {
			learningObjectiveMarks: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromLearningObjectiveMarksOfLearningObjectiveCache(
	cache: ApolloCache<any>,
	targetID: LearningObjective["id"],
	object: Pick<LearningObjectiveMark, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.LearningObjective }),
		fields: {
			learningObjectiveMarks: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToLearningObjectiveMarksOfReportCardCache(
	cache: ApolloCache<any>,
	targetID: ReportCard["id"],
	object: Pick<LearningObjectiveMark, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ReportCard }),
		fields: {
			learningObjectiveMarks: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromLearningObjectiveMarksOfReportCardCache(
	cache: ApolloCache<any>,
	targetID: ReportCard["id"],
	object: Pick<LearningObjectiveMark, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ReportCard }),
		fields: {
			learningObjectiveMarks: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<LearningObjectiveMark, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment LearningObjectiveMarkRef on LearningObjectiveMark {
				id
			}
		`,
		data: object,
	});
}
