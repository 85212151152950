import { AcademicYearTuition, Tuition } from "../../GraphQL";

export type Base = {
	year: AcademicYearTuition["year"] | null;
	nurseryPrice: AcademicYearTuition["nurseryPrice"] | null;
	receptionPrice: AcademicYearTuition["receptionPrice"] | null;
	primaryPrice: AcademicYearTuition["primaryPrice"] | null;
	secondaryPrice: AcademicYearTuition["secondaryPrice"] | null;
	tuitionID: Tuition["id"] | null;
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	year: null,
	nurseryPrice: null,
	receptionPrice: null,
	primaryPrice: null,
	secondaryPrice: null,
	tuitionID: null,
};
