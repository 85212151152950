import { newPageInfo } from "@hex-insights/router";
import { relationshipHubPageInfo } from "../pageinfo";

export const relationshipHubCreatePageInfo = newPageInfo({
	name: "New Relationship",
	title: "New",
	description: "Create a new Relationship record.",
	exact: true,
	to: relationshipHubPageInfo.to + "/new",
	path: relationshipHubPageInfo.path + "/new",
	parent: relationshipHubPageInfo,
});
