import { AcademicYear, CourseSection, HomeRoomSection, Term } from "../../GraphQL";

export type Base = {
	name: Term["name"];
	startDate: Term["startDate"] | null;
	endDate: Term["endDate"] | null;
	academicYearID: AcademicYear["id"] | null;
};

export type Create = Base;

export type Detail = Base & {
	courseSectionIDs: CourseSection["id"][];
	homeRoomSectionIDs: HomeRoomSection["id"][];
};

export const initialCreate: Create = {
	name: "",
	startDate: null,
	endDate: null,
	academicYearID: null,
};
