import React from "react";
import { Authentication } from "@hex-insights/app-modules";
import { NavigationPrompt, Route } from "@hex-insights/router";
import {
	EmployeeRole,
	EmployeeWrapperContext,
	EmployeeWrapperContextManager,
	teacherPortalURL,
	UserContextManager,
} from "@hex-insights/verita.shared";
import { Footer, Header, MobileViewingInDevelopment, PageRouteSwitch, TrustedDeviceController } from "./Components";
import { AuthenticationContext } from "./Contexts";
import { AdmissionsPage } from "./Pages/Admissions";
import { admissionsPageInfo } from "./Pages/Admissions/pageinfo";
import { AccountDisabledPage } from "./Pages/Authentication/AccountDisabled";
import { LoginPage } from "./Pages/Authentication/Login";
import { loginPageInfo } from "./Pages/Authentication/Login/pageinfo";
import { LogoutPage } from "./Pages/Authentication/Logout";
import { logoutPageInfo } from "./Pages/Authentication/Logout/pageinfo";
import { MFALoginPage } from "./Pages/Authentication/MFALogin";
import { RecoverPage } from "./Pages/Authentication/Recover";
import { recoverPageInfo } from "./Pages/Authentication/Recover/pageinfo";
import { SetPasswordPage } from "./Pages/Authentication/SetPassword";
import { setPasswordPageInfo } from "./Pages/Authentication/SetPassword/pageinfo";
import { SplashPage } from "./Pages/Authentication/Splash";
import { BudgetPage } from "./Pages/Budget";
import { budgetPageInfo } from "./Pages/Budget/pageinfo";
import { BusListPage } from "./Pages/BusList";
import { busListPageInfo } from "./Pages/BusList/pageinfo";
import { DashboardsPage } from "./Pages/Dashboards";
import { dashboardsPageInfo } from "./Pages/Dashboards/pageinfo";
import { HomePage } from "./Pages/Home";
import { homePageInfo } from "./Pages/Home/pageinfo";
import { HubsPage } from "./Pages/Hubs";
import { hubsPageInfo } from "./Pages/Hubs/pageinfo";
import { MealPlanListPage } from "./Pages/MealPlanList";
import { mealPlanListPageInfo } from "./Pages/MealPlanList/pageinfo";
import { ResourcesPage } from "./Pages/Resources";
import { resourcesPageInfo } from "./Pages/Resources/pageinfo";
import { TermSetup } from "./Pages/TermSetup";
import { termSetupPageInfo } from "./Pages/TermSetup/pageinfo";

export function App() {
	return (
		<EmployeeWrapperContextManager>
			<BaseApp />
		</EmployeeWrapperContextManager>
	);
}

export function BaseApp() {
	const { status } = React.useContext(AuthenticationContext);

	switch (status) {
		case Authentication.AuthenticationStatus.Loading:
			return <SplashPage />;
		case Authentication.AuthenticationStatus.Disabled:
			return <AppDisabled />;
		case Authentication.AuthenticationStatus.MFARequired:
			return <AppMFALogin />;
		case Authentication.AuthenticationStatus.Unauthenticated:
			return <AppUnauthenticated />;
		default:
			return <AppAuthenticated />;
	}
}

function AppAuthenticated() {
	const { user } = React.useContext(AuthenticationContext);

	const { isLoading, employee } = React.useContext(EmployeeWrapperContext);
	if (isLoading) {
		return <SplashPage />;
	}
	if (employee && employee.role === EmployeeRole.Teacher) {
		window.location.replace(teacherPortalURL());
		return <SplashPage />;
	}

	return (
		<UserContextManager user={user!}>
			<NavigationPrompt
				message={(targetLocation) =>
					targetLocation.pathname === logoutPageInfo.to ? "Are you sure you want to log out?" : true
				}
			/>

			<Header />

			<PageRouteSwitch>
				<Route {...homePageInfo.routeProps}>
					<HomePage />
				</Route>
				<Route {...dashboardsPageInfo.routeProps}>
					<DashboardsPage />
				</Route>
				<Route {...resourcesPageInfo.routeProps}>
					<ResourcesPage />
				</Route>
				<Route {...admissionsPageInfo.routeProps}>
					<AdmissionsPage />
				</Route>
				<Route {...hubsPageInfo.routeProps}>
					<HubsPage />
				</Route>
				<Route {...budgetPageInfo.routeProps}>
					<BudgetPage />
				</Route>
				<Route {...termSetupPageInfo.routeProps}>
					<TermSetup />
				</Route>
				<Route {...busListPageInfo.routeProps}>
					<BusListPage />
				</Route>
				<Route {...mealPlanListPageInfo.routeProps}>
					<MealPlanListPage />
				</Route>
				<Route {...logoutPageInfo.routeProps}>
					<LogoutPage />
				</Route>
			</PageRouteSwitch>

			<TrustedDeviceController />

			<MobileViewingInDevelopment />

			<Footer />
		</UserContextManager>
	);
}

function AppUnauthenticated() {
	return (
		<PageRouteSwitch>
			<Route {...recoverPageInfo.routeProps}>
				<RecoverPage />
			</Route>
			<Route {...setPasswordPageInfo.routeProps}>
				<SetPasswordPage />
			</Route>
			<Route {...logoutPageInfo.routeProps}>
				<LogoutPage />
			</Route>
			<Route {...loginPageInfo.routeProps}>
				<LoginPage />
			</Route>
		</PageRouteSwitch>
	);
}

function AppMFALogin() {
	return (
		<PageRouteSwitch>
			<Route {...logoutPageInfo}>
				<LogoutPage />
			</Route>
			<Route>
				<MFALoginPage />
			</Route>
		</PageRouteSwitch>
	);
}

function AppDisabled() {
	return (
		<PageRouteSwitch>
			<Route {...logoutPageInfo}>
				<LogoutPage />
			</Route>
			<Route>
				<AccountDisabledPage />
			</Route>
		</PageRouteSwitch>
	);
}
