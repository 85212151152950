import { newPageInfo } from "@hex-insights/router";
import { busListPageInfo } from "../pageinfo";

export const busListPickupPageInfo = newPageInfo({
	name: "Bus List: Pickup",
	title: "Pickup",
	description: "List of students on buses for pickup.",
	exact: true,
	to: busListPageInfo.to + "/pickup",
	path: busListPageInfo.path + "/pickup",
	parent: busListPageInfo,
});
