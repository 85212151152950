import { Address } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the shortAddress field of the Address model.
 */
export function shortAddress(value: Address["shortAddress"]): string {
	return toString(value);
}

/**
 * Formats the fullAddress field of the Address model.
 */
export function fullAddress(value: Address["fullAddress"]): string {
	return toString(value);
}

/**
 * Formats the addressLine1 field of the Address model.
 */
export function addressLine1(value: Address["addressLine1"]): string {
	return toString(value);
}

/**
 * Formats the addressLine2 field of the Address model.
 */
export function addressLine2(value: Address["addressLine2"]): string {
	return toString(value);
}

/**
 * Formats the city field of the Address model.
 */
export function city(value: Address["city"]): string {
	return toString(value);
}

/**
 * Formats the postalCode field of the Address model.
 */
export function postalCode(value: Address["postalCode"]): string {
	return toString(value);
}

/**
 * Formats the stateOrProvince field of the Address model.
 */
export function stateOrProvince(value: Address["stateOrProvince"]): string {
	return toString(value);
}

/**
 * Formats the country field of the Address model.
 */
export function country(value: Address["country"]): string {
	return toString(value);
}
