import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { EnrollmentApplicationStudentHubCreatePage } from "./CreatePage";
import { enrollmentApplicationStudentHubCreatePageInfo } from "./CreatePage/pageinfo";
import { EnrollmentApplicationStudentHubDetailPage } from "./DetailPage";
import { enrollmentApplicationStudentHubDetailPageInfo } from "./DetailPage/pageinfo";
import { EnrollmentApplicationStudentHubIndexPage } from "./IndexPage";
import { enrollmentApplicationStudentHubIndexPageInfo } from "./IndexPage/pageinfo";
import { enrollmentApplicationStudentHubPageInfo } from "./pageinfo";

export function EnrollmentApplicationStudentHub() {
	useActivePageRegistration(enrollmentApplicationStudentHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...enrollmentApplicationStudentHubIndexPageInfo.routeProps}>
				<EnrollmentApplicationStudentHubIndexPage />
			</Route>
			<Route {...enrollmentApplicationStudentHubCreatePageInfo.routeProps}>
				<EnrollmentApplicationStudentHubCreatePage />
			</Route>
			<Route {...enrollmentApplicationStudentHubDetailPageInfo.routeProps}>
				<EnrollmentApplicationStudentHubDetailPage key={enrollmentApplicationStudentHubDetailPageInfo.useKey()} />
			</Route>
		</PageRouteSwitch>
	);
}
