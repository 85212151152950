import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import {
	EnrollmentApplicationFilterInput,
	EnrollmentApplicationOrder,
	useEnrollmentApplicationPaginationQuery,
} from "../../../../Utilities";

export type EnrollmentApplicationPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: EnrollmentApplicationFilterInput | EnrollmentApplicationFilterInput[] | null;
	order: EnrollmentApplicationOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the EnrollmentApplication model.
 */
export function EnrollmentApplicationPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: EnrollmentApplicationPaginationFormProps) {
	const { loading, data } = useEnrollmentApplicationPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.enrollmentApplicationConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
