import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { BillingSetupSubmissionHubCreatePage } from "./CreatePage";
import { billingSetupSubmissionHubCreatePageInfo } from "./CreatePage/pageinfo";
import { BillingSetupSubmissionHubDetailPage } from "./DetailPage";
import { billingSetupSubmissionHubDetailPageInfo } from "./DetailPage/pageinfo";
import { BillingSetupSubmissionHubIndexPage } from "./IndexPage";
import { billingSetupSubmissionHubIndexPageInfo } from "./IndexPage/pageinfo";
import { billingSetupSubmissionHubPageInfo } from "./pageinfo";

export function BillingSetupSubmissionHub() {
	useActivePageRegistration(billingSetupSubmissionHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...billingSetupSubmissionHubIndexPageInfo.routeProps}>
				<BillingSetupSubmissionHubIndexPage />
			</Route>
			<Route {...billingSetupSubmissionHubCreatePageInfo.routeProps}>
				<BillingSetupSubmissionHubCreatePage />
			</Route>
			<Route {...billingSetupSubmissionHubDetailPageInfo.routeProps}>
				<BillingSetupSubmissionHubDetailPage key={billingSetupSubmissionHubDetailPageInfo.useKey()} />
			</Route>
		</PageRouteSwitch>
	);
}
