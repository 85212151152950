import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { UserHubCreatePage } from "./CreatePage";
import { userHubCreatePageInfo } from "./CreatePage/pageinfo";
import { UserHubDetailPage } from "./DetailPage";
import { userHubDetailPageInfo } from "./DetailPage/pageinfo";
import { UserHubIndexPage } from "./IndexPage";
import { userHubIndexPageInfo } from "./IndexPage/pageinfo";
import { userHubPageInfo } from "./pageinfo";

export function UserHub() {
	useActivePageRegistration(userHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...userHubIndexPageInfo.routeProps}>
				<UserHubIndexPage />
			</Route>
			<Route {...userHubCreatePageInfo.routeProps}>
				<UserHubCreatePage />
			</Route>
			<Route {...userHubDetailPageInfo.routeProps}>
				<UserHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
