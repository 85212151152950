import React from "react";
import { FieldDisplayArgs, MultiSelectField, NumberField, TextField } from "@hex-insights/forms";
import {
	ClassYearFormValues,
	CourseSelect,
	HomeRoomSelect,
	useCourseSelectLazyQuery,
	useHomeRoomSelectLazyQuery,
} from "../../../../Utilities";
import { CourseLink, HomeRoomLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the ClassYear model.
 */
type FieldProps<K extends keyof ClassYearFormValues.Base = keyof ClassYearFormValues.Base> = BaseFieldProps<
	Pick<ClassYearFormValues.Base, K>
>;

/**
 * Generic props for fields of the ClassYear model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof ClassYearFormValues.Detail = keyof ClassYearFormValues.Detail> = BaseFieldProps<
	Pick<ClassYearFormValues.Detail, K>
>;

/**
 * Renders a field component for the `name` field of the ClassYear model.
 */
export function Name({ formState }: FieldProps<"name">) {
	return <TextField formState={formState} name="name" />;
}

/**
 * Renders a field component for the `yearsFromGraduation` field of the ClassYear model.
 */
export function YearsFromGraduation({ formState }: FieldProps<"yearsFromGraduation">) {
	return <NumberField formState={formState} name="yearsFromGraduation" validationUnit={1} min={0} />;
}

export type CoursesProps = DetailFieldProps<"courseIDs"> & {
	currentCourses?: CourseSelect.ModelForOption[];
};

/**
 * Renders a field component for the `courses` edge of the ClassYear model.
 */
export function Courses({ formState, currentCourses }: CoursesProps) {
	const [loadOptions, { loading, data }] = useCourseSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.courseIDs) {
			loadOptions();
		}
	}, [formState.formEditing.courseIDs, loadOptions]);
	const options = React.useMemo(
		() => CourseSelect.toMultiOptions(data?.courseConnection.edges, currentCourses),
		[data, currentCourses],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="courseIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayCourseInstance}
		/>
	);
}

function displayCourseInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <CourseLink instance={{ id }}>{formattedValue}</CourseLink>;
}

export type HomeRoomsProps = DetailFieldProps<"homeRoomIDs"> & {
	currentHomeRooms?: HomeRoomSelect.ModelForOption[];
};

/**
 * Renders a field component for the `homeRooms` edge of the ClassYear model.
 */
export function HomeRooms({ formState, currentHomeRooms }: HomeRoomsProps) {
	const [loadOptions, { loading, data }] = useHomeRoomSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.homeRoomIDs) {
			loadOptions();
		}
	}, [formState.formEditing.homeRoomIDs, loadOptions]);
	const options = React.useMemo(
		() => HomeRoomSelect.toMultiOptions(data?.homeRoomConnection.edges, currentHomeRooms),
		[data, currentHomeRooms],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="homeRoomIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayHomeRoomInstance}
		/>
	);
}

function displayHomeRoomInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <HomeRoomLink instance={{ id }}>{formattedValue}</HomeRoomLink>;
}
