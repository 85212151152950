import { newPageInfo } from "@hex-insights/router";
import { documentResourceFolderHubPageInfo } from "../pageinfo";

export const documentResourceFolderHubCreatePageInfo = newPageInfo({
	name: "New Document Resource Folder",
	title: "New",
	description: "Create a new Document Resource Folder record.",
	exact: true,
	to: documentResourceFolderHubPageInfo.to + "/new",
	path: documentResourceFolderHubPageInfo.path + "/new",
	parent: documentResourceFolderHubPageInfo,
});
