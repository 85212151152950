import React from "react";
import { Conditional, Else, Heading, If, Row, Section, toLocalDateString, useSync } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	ClassYearForm,
	ClassYearFormValues,
	ClassYearMutation,
	PersonIcon,
	Tile,
	useClassYearDetailQuery,
	useEmployeeIndexQuery,
	useStudentListQuery,
} from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { employeeHubDetailPageInfo } from "../../Employee/DetailPage/pageinfo";
import { studentHubDetailPageInfo } from "../../Student/DetailPage/pageinfo";
import { classYearHubDetailPageInfo, ClassYearHubDetailPageRouteParams } from "./pageinfo";

function ClassYearHubDetailPageWrapper() {
	// Manages identity of the ClassYearHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <ClassYearHubDetailPage key={id} />;
}

export { ClassYearHubDetailPageWrapper as ClassYearHubDetailPage };

function ClassYearHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useClassYearDetailQuery({ variables: { id } });
	const classYear = data?.classYear;
	useActivePageRegistration(classYearHubDetailPageInfo, !!classYear ? classYearHubDetailPageInfo.title(classYear) : "");

	const update = ClassYearMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<ClassYearFormValues.Detail>, initialFormValues: ClassYearFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading classYear</If>
				<Else>
					{!!classYear && (
						<React.Fragment>
							<Heading level={1}>{classYear.name}</Heading>

							<Row justify="spaced-start">
								<Tile>
									<Tile.Body>
										<ClassYearForm.ControlledDetail
											key={syncID}
											classYear={classYear}
											applyUpdate={applyUpdate}
											onSuccess={synchronize}
										/>
									</Tile.Body>
								</Tile>

								<Tile style={{ flexGrow: 1, width: "100%", overflow: "hidden" }}>
									<Tile.Body>
										<EmployeesSection />
										<StudentsSection />
									</Tile.Body>
								</Tile>
							</Row>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}

function EmployeesSection() {
	const { id } = useRouteParams<ClassYearHubDetailPageRouteParams>();
	const today = toLocalDateString(new Date());
	const { loading, data } = useEmployeeIndexQuery({
		variables: {
			filters: [
				{
					homeRoomSectionTeacherEnrollments: [
						{
							homeRoomSection: [
								{
									homeRoom: [{ classYearIDEQ: id }],
									term: [{ startDateLTE: today, endDateGTE: today }],
								},
							],
						},
					],
				},
				{
					courseSectionTeacherEnrollments: [
						{
							courseSection: [
								{
									course: [{ classYears: [{ idEQ: id }] }],
									term: [{ startDateLTE: today, endDateGTE: today }],
								},
							],
						},
					],
				},
			],
		},
	});

	return (
		<Section>
			<Section.Header>
				<Heading level={3} noMargin>
					Teachers
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Row justify="spaced-start" overflow="scroll" style={{ width: "100%" }}>
							{data?.employeeConnection.edges.map((e) => (
								<InternalLink
									key={e.node.id}
									to={employeeHubDetailPageInfo.to(e.node.id)}
									className="link--no-text-decoration"
								>
									<PersonIcon person={e.node.person} />
								</InternalLink>
							))}
						</Row>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}

function StudentsSection() {
	const { id } = useRouteParams<ClassYearHubDetailPageRouteParams>();
	const today = toLocalDateString(new Date());
	const { loading, data } = useStudentListQuery({
		variables: {
			filters: [
				{
					homeRoomSectionStudentEnrollments: [
						{
							homeRoomSection: [
								{
									homeRoom: [{ classYearIDEQ: id }],
									term: [{ startDateLTE: today, endDateGTE: today }],
								},
							],
						},
					],
				},
				{
					courseSectionStudentEnrollments: [
						{
							courseSection: [
								{
									course: [{ classYears: [{ idEQ: id }] }],
									term: [{ startDateLTE: today, endDateGTE: today }],
								},
							],
						},
					],
				},
			],
		},
	});

	return (
		<Section>
			<Section.Header>
				<Heading level={3} noMargin>
					Students
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Row justify="spaced-start" overflow="scroll" style={{ width: "100%" }}>
							{data?.studentConnection.edges.map((e) => (
								<InternalLink
									key={e.node.id}
									to={studentHubDetailPageInfo.to(e.node.id)}
									className="link--no-text-decoration"
								>
									<PersonIcon person={e.node.person} />
								</InternalLink>
							))}
						</Row>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}
