import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { AcademicYear, AcademicYearFormat, AcademicYearNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<AcademicYear, "id"> & AcademicYearNameFragment;

export type AcademicYearLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given AcademicYear instance.
 */
export function AcademicYearLink(props: AcademicYearLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="AcademicYear" format={AcademicYearFormat.name} {...props} />;
}
