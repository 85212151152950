import React from "react";
import {
	Button,
	ClassNameProps,
	Column,
	Conditional,
	Else,
	forceUseHookDependencies,
	If,
	Modal,
	ModalProps,
	Row,
	StyleProps,
	useSync,
	useToggle,
	useUpdateEffect,
} from "@hex-insights/core";
import { ExternalLink } from "@hex-insights/router";
import { DocumentResource } from "../../Utilities";
import styles from "./styles.module.css";

export type DocumentResourceDisplayProps = {
	documentResource: Pick<DocumentResource, "name" | "description" | "imageURL" | "isFile" | "fileURL" | "resourceURL">;
} & Partial<ClassNameProps & StyleProps>;

export function DocumentResourceDisplay({
	documentResource: { name, description, imageURL, isFile, fileURL, resourceURL },
}: DocumentResourceDisplayProps) {
	const { isOn: isPreviewOpen, toggle: toggleIsPreviewOpen } = useToggle(false);

	return (
		<React.Fragment>
			<Button onClick={toggleIsPreviewOpen} className={styles["document-resource-display"]}>
				<Column
					justify="spaced-start"
					verticalSpacing="0.25rem"
					align="center"
					className={styles["document-resource-display__column"]}
				>
					<ImageContainer src={imageURL} />
					<span
						title={name + (description !== "" ? "\n\n" + description : "")}
						className={styles["document-resource-display__name"]}
					>
						{name}
					</span>
				</Column>
			</Button>

			<Modal.If condition={isPreviewOpen}>
				<ResourcePreview
					url={isFile ? fileURL : resourceURL}
					resourceType={isFile ? "file" : "link"}
					onClose={toggleIsPreviewOpen}
				/>
			</Modal.If>
		</React.Fragment>
	);
}

type ResourcePreviewProps = {
	url: string;
	resourceType: "link" | "file";
} & Pick<ModalProps, "ifRef" | "onClose">;

function ResourcePreview({ url, resourceType, ifRef, onClose }: ResourcePreviewProps) {
	return (
		<Modal ifRef={ifRef} onClose={onClose} className={styles["resource-preview"]}>
			<Modal.Body style={{ paddingBottom: "0.25rem" }}>
				<Conditional>
					<If condition={resourceType === "link"}>
						<iframe src={url} frameBorder="0" className={styles["resource-preview__display"]}></iframe>
					</If>
					<If condition={resourceType === "file"}>
						<embed src={url} className={styles["resource-preview__display"]} />
					</If>
				</Conditional>
			</Modal.Body>
			<Modal.Footer>
				<Row justify="flex-end">
					<ExternalLink href={url}>View Externally</ExternalLink>
				</Row>
			</Modal.Footer>
		</Modal>
	);
}

// TODO make single image container component (<Image>)
//  this one is the full fixed size one (as opposed to height-scaled or width-scaled)

type ImageContainerProps = {
	src: string;
};

function ImageContainer({ src }: ImageContainerProps) {
	const ref = React.useRef<HTMLImageElement | null>(null);

	const { syncID, synchronize } = useSync();

	const imageShape = React.useMemo(() => {
		forceUseHookDependencies(syncID);
		if (!ref.current) {
			return "wide";
		}
		const { width, height } = ref.current.getBoundingClientRect();
		return width >= height ? "wide" : "tall";
	}, [syncID]);

	const [hasImageError, setHasImageError] = React.useState(false);
	useUpdateEffect(() => {
		setHasImageError(false);
	}, [src]);

	const hasImage = src !== "" && !hasImageError;

	return (
		<div className={styles["image-container"] + " " + styles[`image-container--${imageShape}`]}>
			<Conditional>
				<If condition={hasImage}>
					<img ref={ref} src={src} onLoad={synchronize} onError={() => setHasImageError(true)} />
				</If>
				<Else>
					<div className={styles["image-placeholder"]} />
				</Else>
			</Conditional>
		</div>
	);
}
