import { BusPlanNameFragment } from "../../../GraphQL";
import * as StudentFormat from "../Student";
import * as Fields from "./fields";

export { Fields };

/**
 * Formats the full name of the given instance.
 */
export function name(instance: BusPlanNameFragment) {
	return `${StudentFormat.name(instance.student)}'s Bus Plan`;
}
