import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import {
	SchoolStudentEnrollment,
	SchoolStudentEnrollmentFormat,
	SchoolStudentEnrollmentNameFragment,
} from "../../../../Utilities";

type ModelForLink = Pick<SchoolStudentEnrollment, "id"> & SchoolStudentEnrollmentNameFragment;

export type SchoolStudentEnrollmentLinkProps = DistributiveOmit<
	Models.LinkProps<ModelForLink>,
	"pageInfoKey" | "format"
>;

/**
 * Renders a link to the given SchoolStudentEnrollment instance.
 */
export function SchoolStudentEnrollmentLink(props: SchoolStudentEnrollmentLinkProps) {
	return (
		<Models.Link<ModelForLink>
			pageInfoKey="SchoolStudentEnrollment"
			format={SchoolStudentEnrollmentFormat.name}
			{...props}
		/>
	);
}
