import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import {
	Button,
	CaseStyle,
	Conditional,
	convertCase,
	dateDiff,
	dateTrunc,
	Drawer,
	Else,
	If,
	mappedArray,
	Row,
	stringToLocalDate,
} from "@hex-insights/core";
import { FormState } from "@hex-insights/forms";
import { useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import {
	AcademicYearFilterButtons,
	AcademicYearFormat,
	ClassYearFormat,
	HR,
	PersonIcon,
	SchoolStudentEnrollment,
	SchoolStudentEnrollmentCreatedAfterFilterButton,
	SchoolStudentEnrollmentFilterFormController,
	SchoolStudentEnrollmentFilterFormState,
	SchoolStudentEnrollmentFilterFormUtils,
	SchoolStudentEnrollmentFilterFormValues,
	SchoolStudentEnrollmentFormat,
	SchoolStudentEnrollmentOrderField,
	SchoolStudentEnrollmentOrderForm,
	SchoolStudentEnrollmentOrderFormState,
	SchoolStudentEnrollmentOrderFormValues,
	SchoolStudentEnrollmentPaginationForm,
	SchoolStudentEnrollmentPaidStatusFilterButtons,
	SchoolStudentEnrollmentPaidStatusFilterButtonsProps,
	SchoolStudentEnrollmentPaymentDueBeforeFilterButton,
	SchoolStudentEnrollmentSearchForm,
	StudentFormat,
	useSchoolStudentEnrollmentIndexQuery,
} from "@hex-insights/verita.shared";
import { EnrollmentDrawer } from "./EnrollmentDrawer";
import { admissionsEnrollmentsPageInfo } from "./pageinfo";
import styles from "./styles.module.css";

const columns = [
	"studentImageURL",
	"student",
	"classYear",
	"academicYear",
	"createdDate",
	"paymentDueDate",
	"hasPaidFee",
];

const columnWidths = {
	studentImageURL: "4rem",
};

function orderFieldToColumn(field: SchoolStudentEnrollmentOrderField | null) {
	if (field === null) {
		return "id";
	}
	return SchoolStudentEnrollmentOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return SchoolStudentEnrollmentOrderFormValues.modelFieldToOrderField[column];
}

export function AdmissionsEnrollmentsPage() {
	useActivePageRegistration(admissionsEnrollmentsPageInfo);

	const filterFormState = SchoolStudentEnrollmentFilterFormState.useFormState();
	const orderFormState = SchoolStudentEnrollmentOrderFormState.useOrderFormState();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(
		filterFormState.formValues,
		SchoolStudentEnrollmentFilterFormUtils.toFilterInputs,
	);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useSchoolStudentEnrollmentIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		SchoolStudentEnrollmentFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = SchoolStudentEnrollmentFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.schoolStudentEnrollmentConnection.edges.length ?? 0),
		0,
	);

	const [openSchoolStudentEnrollmentID, setOpenSchoolStudentEnrollmentID] = React.useState<
		SchoolStudentEnrollment["id"] | null
	>(null);
	const { isDrawerOpen, openDrawer: baseOpenDrawer, closeDrawer } = Drawer.useToggle(false);
	const openDrawer = React.useCallback(
		(id: SchoolStudentEnrollment["id"]) => {
			setOpenSchoolStudentEnrollmentID(id);
			baseOpenDrawer();
		},
		[baseOpenDrawer],
	);

	return (
		<div>
			<Row justify="spaced-start" align="center" overflow="wrap" style={{ marginBottom: "0.75rem" }}>
				<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem" overflow="wrap">
					<SchoolStudentEnrollmentSearchForm formState={filterFormState} />
					<SchoolStudentEnrollmentFilterFormController
						formState={filterFormState}
						numActiveFilters={numActiveFilters}
					/>
					<If condition={numActiveFilters > 0}>
						<Button variant="link" size="small" onClick={clearFilters}>
							Clear
						</Button>
					</If>
					<SchoolStudentEnrollmentOrderForm.OrderController formState={orderFormState} />
				</Row>
				<HR color="var(--verita-blue)" style={{ height: "1.5rem" }} />
				<QuickFilters filterFormState={filterFormState} />
			</Row>

			<Drawer.If condition={isDrawerOpen}>
				<EnrollmentDrawer
					key={openSchoolStudentEnrollmentID}
					schoolStudentEnrollmentID={openSchoolStudentEnrollmentID ?? ""}
					onClose={closeDrawer}
				/>
			</Drawer.If>

			<Table.Container className="hub__index-page__table__container">
				<Table
					columns={columns}
					minColumnWidth="10rem"
					columnWidths={columnWidths}
					{...tableSortingProps}
					className="hub__index-page__table"
				>
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="student" noSort>
								Student
							</Table.Heading>
							<Table.Heading column="classYear" noSort>
								Class Year
							</Table.Heading>
							<Table.Heading column="academicYear" noSort>
								Academic Year
							</Table.Heading>
							<Table.Heading column="createdDate">Created Date</Table.Heading>
							<Table.Heading column="paymentDueDate">Payment Due Date</Table.Heading>
							<Table.Heading column="hasPaidFee">Payment Status</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column="studentImageURL" colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.schoolStudentEnrollmentConnection.edges.map((e) => {
									const daysToPaymentDue = dateDiff(
										stringToLocalDate(e.node.paymentDueDate, "day"),
										dateTrunc(new Date(), "day"),
										"days",
									);
									let paymentDueAdditionalText = "";
									let isPaymentOverdue = false;
									if (!e.node.hasPaidFee) {
										if (daysToPaymentDue === 0) {
											paymentDueAdditionalText = "Today";
										} else if (daysToPaymentDue > 0) {
											paymentDueAdditionalText = `In ${daysToPaymentDue} days`;
										} else {
											paymentDueAdditionalText = `${Math.abs(daysToPaymentDue)} days overdue`;
											isPaymentOverdue = true;
										}
									}

									const paymentStatus = SchoolStudentEnrollmentFormat.paymentStatus(e.node);

									return (
										<Table.Row
											key={e.node.id}
											onClick={() => openDrawer(e.node.id)}
											className="hub__index-page__table__row"
										>
											<Table.Cell
												column="studentImageURL"
												oneLine
												style={{ paddingTop: "0.1rem", paddingBottom: "0.1rem" }}
											>
												<Row justify="center" align="center" style={{ height: "100%" }}>
													<PersonIcon person={e.node.student.person} imageSize="3rem" />
												</Row>
											</Table.Cell>
											<Table.Cell column="student" oneLine>
												{StudentFormat.name(e.node.student)}
											</Table.Cell>
											<Table.Cell column="classYear" oneLine>
												{ClassYearFormat.name(e.node.classYear)}
											</Table.Cell>
											<Table.Cell column="academicYear" oneLine>
												{AcademicYearFormat.name(e.node.academicYear)}
											</Table.Cell>
											<Table.Cell column="createdDate" oneLine>
												{SchoolStudentEnrollmentFormat.Fields.createdDate(e.node.createdDate)}
											</Table.Cell>
											<Table.Cell column="paymentDueDate" oneLine>
												<Row justify="spaced-start" align="center">
													{SchoolStudentEnrollmentFormat.Fields.paymentDueDate(e.node.paymentDueDate)}
													<span
														style={{ fontSize: "0.9rem", color: isPaymentOverdue ? "var(--danger-color)" : "#777" }}
													>
														{paymentDueAdditionalText}
													</span>
												</Row>
											</Table.Cell>
											<Table.Cell column="hasPaidFee" oneLine>
												<span
													className={
														styles["payment-status"] +
														" " +
														styles[`payment-status--${convertCase(paymentStatus, CaseStyle.Kebab)}`]
													}
												>
													{paymentStatus}
												</span>
											</Table.Cell>
										</Table.Row>
									);
								})}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<SchoolStudentEnrollmentPaginationForm.Pagination
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.schoolStudentEnrollmentConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</div>
	);
}

const filterButtonProps: SchoolStudentEnrollmentPaidStatusFilterButtonsProps["buttonProps"] = {
	size: "small",
	style: {
		paddingTop: "0.5rem",
		paddingBottom: "0.5rem",
		borderRadius: "1rem",
	},
};

type QuickFiltersProps = {
	filterFormState: FormState<SchoolStudentEnrollmentFilterFormValues.FormValues>;
};

function QuickFilters({ filterFormState }: QuickFiltersProps) {
	return (
		<Row justify="spaced-start" horizontalSpacing="0.25rem">
			<AcademicYearFilterButtons formState={filterFormState} buttonProps={filterButtonProps} />
			<SchoolStudentEnrollmentPaidStatusFilterButtons formState={filterFormState} buttonProps={filterButtonProps} />
			<SchoolStudentEnrollmentCreatedAfterFilterButton formState={filterFormState} buttonProps={filterButtonProps} />
			<SchoolStudentEnrollmentPaymentDueBeforeFilterButton
				formState={filterFormState}
				buttonProps={filterButtonProps}
			/>
		</Row>
	);
}
