import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { EnrollmentApplicationFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the EnrollmentApplication model.
 */
export function useFormState(initialFormValues?: Partial<EnrollmentApplicationFilterFormValues.FormValues>) {
	return IndexForms.useFilterFormStateWithQueryStateSync(
		React.useMemo(
			() => ({ ...EnrollmentApplicationFilterFormValues.getInitial(), ...initialFormValues }),
			[initialFormValues],
		),
		EnrollmentApplicationFilterFormValues.typeFilterObject,
	);
}
