import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import {
	PaymentLineItemFilterInput,
	PaymentLineItemOrder,
	usePaymentLineItemPaginationQuery,
} from "../../../../Utilities";

export type PaymentLineItemPaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: PaymentLineItemFilterInput | PaymentLineItemFilterInput[] | null;
	order: PaymentLineItemOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the PaymentLineItem model.
 */
export function PaymentLineItemPaginationForm({
	formState,
	filters,
	order,
	setPaginationInfo,
}: PaymentLineItemPaginationFormProps) {
	const { loading, data } = usePaymentLineItemPaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.paymentLineItemConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
