import { newPageInfo } from "@hex-insights/router";
import { enrollmentApplicationHubPageInfo } from "../pageinfo";

export const enrollmentApplicationHubIndexPageInfo = newPageInfo({
	name: "Enrollment Applications Index",
	title: "",
	description: "Index of Enrollment Applications.",
	exact: true,
	to: enrollmentApplicationHubPageInfo.to,
	path: enrollmentApplicationHubPageInfo.path,
	parent: enrollmentApplicationHubPageInfo,
});
