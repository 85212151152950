import { newPageInfo } from "@hex-insights/router";
import { DocumentResourceFolder } from "@hex-insights/verita.shared";
import { resourcesFoldersPageInfo, ResourcesFoldersPageRouteParams } from "../pageinfo";

export type ResourcesFoldersDetailPageRouteParams = ResourcesFoldersPageRouteParams & {
	documentResourceFolderID: string;
};

type DocumentResourceFolderForTitle = Pick<DocumentResourceFolder, "name">;

export const resourcesFoldersDetailPageInfo = newPageInfo({
	name: "Resources Folder Detail",
	title: (e: DocumentResourceFolderForTitle) => e.name,
	description: "Detail page for a folder.",
	exact: false,
	to: (id: DocumentResourceFolder["id"]) => resourcesFoldersPageInfo.to + `/${id}`,
	path: resourcesFoldersPageInfo.path + "/:documentResourceFolderID(\\d+)",
	parent: resourcesFoldersPageInfo,
});
