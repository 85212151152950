import React from "react";
import { FieldDisplayArgs, MultiSelectField, NumberField, SelectField, TextField } from "@hex-insights/forms";
import {
	ClassYearSelect,
	CourseSelect,
	HomeRoomFormValues,
	HomeRoomSectionSelect,
	useClassYearSelectLazyQuery,
	useCourseSelectLazyQuery,
	useHomeRoomSectionSelectLazyQuery,
} from "../../../../Utilities";
import { ClassYearLink, CourseLink, HomeRoomSectionLink } from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the HomeRoom model.
 */
type FieldProps<K extends keyof HomeRoomFormValues.Base = keyof HomeRoomFormValues.Base> = BaseFieldProps<
	Pick<HomeRoomFormValues.Base, K>
>;

/**
 * Generic props for fields of the HomeRoom model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof HomeRoomFormValues.Detail = keyof HomeRoomFormValues.Detail> = BaseFieldProps<
	Pick<HomeRoomFormValues.Detail, K>
>;

/**
 * Renders a field component for the `name` field of the HomeRoom model.
 */
export function Name({ formState }: FieldProps<"name">) {
	return <TextField formState={formState} name="name" />;
}

/**
 * Renders a field component for the `description` field of the HomeRoom model.
 */
export function Description({ formState }: FieldProps<"description">) {
	return <TextAreaField formState={formState} name="description" optional />;
}

/**
 * Renders a field component for the `studentCapacity` field of the HomeRoom model.
 */
export function StudentCapacity({ formState }: FieldProps<"studentCapacity">) {
	return <NumberField formState={formState} name="studentCapacity" validationUnit={1} min={0} optional />;
}

export type ClassYearProps = FieldProps<"classYearID"> & {
	currentClassYear?: ClassYearSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `classYear` edge of the HomeRoom model.
 */
export function ClassYear({ formState, currentClassYear }: ClassYearProps) {
	const [loadOptions, { loading, data }] = useClassYearSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.classYearID) {
			loadOptions();
		}
	}, [formState.formEditing.classYearID, loadOptions]);
	const options = React.useMemo(
		() => ClassYearSelect.toOptions(data?.classYearConnection.edges, currentClassYear),
		[data, currentClassYear],
	);

	return (
		<SelectField
			formState={formState}
			name="classYearID"
			isLoading={loading}
			options={options}
			display={displayClassYear}
			blankValue={null}
		/>
	);
}

function displayClassYear({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ClassYearLink instance={{ id }}>{formattedValue}</ClassYearLink>;
}

export type CoursesProps = DetailFieldProps<"courseIDs"> & {
	currentCourses?: CourseSelect.ModelForOption[];
};

/**
 * Renders a field component for the `courses` edge of the HomeRoom model.
 */
export function Courses({ formState, currentCourses }: CoursesProps) {
	const [loadOptions, { loading, data }] = useCourseSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.courseIDs) {
			loadOptions();
		}
	}, [formState.formEditing.courseIDs, loadOptions]);
	const options = React.useMemo(
		() => CourseSelect.toMultiOptions(data?.courseConnection.edges, currentCourses),
		[data, currentCourses],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="courseIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayCourseInstance}
		/>
	);
}

function displayCourseInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <CourseLink instance={{ id }}>{formattedValue}</CourseLink>;
}

export type HomeRoomSectionsProps = DetailFieldProps<"homeRoomSectionIDs"> & {
	currentHomeRoomSections?: HomeRoomSectionSelect.ModelForOption[];
};

/**
 * Renders a field component for the `homeRoomSections` edge of the HomeRoom model.
 */
export function HomeRoomSections({ formState, currentHomeRoomSections }: HomeRoomSectionsProps) {
	const [loadOptions, { loading, data }] = useHomeRoomSectionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.homeRoomSectionIDs) {
			loadOptions();
		}
	}, [formState.formEditing.homeRoomSectionIDs, loadOptions]);
	const options = React.useMemo(
		() => HomeRoomSectionSelect.toMultiOptions(data?.homeRoomSectionConnection.edges, currentHomeRoomSections),
		[data, currentHomeRoomSections],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="homeRoomSectionIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayHomeRoomSectionInstance}
		/>
	);
}

function displayHomeRoomSectionInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <HomeRoomSectionLink instance={{ id }}>{formattedValue}</HomeRoomSectionLink>;
}
