import { formatDateTime, stringToLocalDate } from "@hex-insights/core";
import { MealMenu } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the menuDate field of the MealMenu model.
 */
export function menuDate(value: MealMenu["menuDate"] | null): string {
	if (value === null) {
		return toString(value);
	}
	if (stringToLocalDate(value, "day").getFullYear() === new Date().getFullYear()) {
		return formatDateTime(value, "dddd, D MMM");
	}
	return formatDateTime(value, "dddd, D MMM, YYYY");
}

/**
 * Formats the breakfast field of the MealMenu model.
 */
export function breakfast(value: MealMenu["breakfast"]): string {
	return toString(value);
}

/**
 * Formats the lunch field of the MealMenu model.
 */
export function lunch(value: MealMenu["lunch"]): string {
	return toString(value);
}

/**
 * Formats the snack field of the MealMenu model.
 */
export function snack(value: MealMenu["snack"]): string {
	return toString(value);
}

/**
 * Formats the vegetarianBreakfast field of the MealMenu model.
 */
export function vegetarianBreakfast(value: MealMenu["vegetarianBreakfast"]): string {
	return toString(value);
}

/**
 * Formats the vegetarianLunch field of the MealMenu model.
 */
export function vegetarianLunch(value: MealMenu["vegetarianLunch"]): string {
	return toString(value);
}

/**
 * Formats the vegetarianSnack field of the MealMenu model.
 */
export function vegetarianSnack(value: MealMenu["vegetarianSnack"]): string {
	return toString(value);
}
