import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { PermissionGroup, PermissionGroupFormat, PermissionGroupNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<PermissionGroup, "id"> & PermissionGroupNameFragment;

export type PermissionGroupLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given PermissionGroup instance.
 */
export function PermissionGroupLink(props: PermissionGroupLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="PermissionGroup" format={PermissionGroupFormat.name} {...props} />;
}
