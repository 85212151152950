import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { StudentOrderFormValues } from "../../../../Utilities";

export type StudentOrderFormControllerProps = {
	formState: FormState<StudentOrderFormValues.FormValues>;
};

/**
 * Renders the order form of the Student model.
 */
export function StudentOrderFormController({ formState }: StudentOrderFormControllerProps) {
	return (
		<IndexForms.OrderFormController
			formState={formState}
			fieldOptions={StudentOrderFormValues.fieldOptions}
			fieldTypeMapping={StudentOrderFormValues.fieldTypeMapping}
		/>
	);
}
