import { IndexForms } from "@hex-insights/app-modules";
import { ClassYearFilterFormValues } from "../../../../Utilities";

export type ClassYearSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the ClassYear model.
 */
export function ClassYearSearchForm(props: ClassYearSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type ClassYearFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, ClassYearFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the ClassYear model.
 */
export function ClassYearFilterFormController(props: ClassYearFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={ClassYearFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define StandardFilters component and add to `ClassYearFilterForm`
