import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Session, SessionFormat, SessionNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Session, "id"> & SessionNameFragment;

export type SessionLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Session instance.
 */
export function SessionLink(props: SessionLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Session" format={SessionFormat.name} {...props} />;
}
