import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { BusPlanForm, BusPlanFormValues, BusPlanMutation, useBusPlanDetailQuery } from "@hex-insights/verita.shared";
import { Main } from "../../../../Components";
import { busPlanHubDetailPageInfo } from "./pageinfo";

function BusPlanHubDetailPageWrapper() {
	// Manages identity of the BusPlanHubDetailPage component for path param updates.
	const { id } = useRouteParams<{ id: string }>();
	return <BusPlanHubDetailPage key={id} />;
}

export { BusPlanHubDetailPageWrapper as BusPlanHubDetailPage };

function BusPlanHubDetailPage() {
	const { id } = useRouteParams<{ id: string }>();

	const { loading, data, error } = useBusPlanDetailQuery({ variables: { id } });
	const busPlan = data?.busPlan;
	useActivePageRegistration(busPlanHubDetailPageInfo, !!busPlan ? busPlanHubDetailPageInfo.title(busPlan) : "");

	const update = BusPlanMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<BusPlanFormValues.Detail>, initialFormValues: BusPlanFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading busPlan</If>
				<Else>
					{!!busPlan && (
						<React.Fragment>
							<Heading level={1}>{busPlan.id}</Heading>

							<BusPlanForm.ControlledDetail
								key={syncID}
								busPlan={busPlan}
								applyUpdate={applyUpdate}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
