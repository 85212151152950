import { NullPartial, omitKeys } from "@hex-insights/core";
import {
	EnrollmentInvitationCreateInput,
	EnrollmentInvitationDetailQuery,
	EnrollmentInvitationUpdateInput,
} from "../../GraphQL";
import { EnrollmentInvitationFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the EnrollmentInvitation model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: EnrollmentInvitationFormValues.Create): EnrollmentInvitationCreateInput {
	const input: NullPartial<EnrollmentInvitationCreateInput> = { ...formValues };
	return input as EnrollmentInvitationCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the EnrollmentInvitation model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<EnrollmentInvitationFormValues.Detail>,
	_initialFormValues: EnrollmentInvitationFormValues.Detail,
): EnrollmentInvitationUpdateInput {
	const inputFormValues = omitKeys(formValues, ["createdAt"]);
	const input: EnrollmentInvitationUpdateInput = { ...inputFormValues };

	if (formValues.studentID === null) {
		input.studentID = null;
		input.clearStudent = true;
	}

	return input;
}

/**
 * Converts a EnrollmentInvitation instance to its corresponding detail form values.
 *
 * @param enrollmentInvitation The instance to convert.
 */
export function toFormValues(
	enrollmentInvitation: EnrollmentInvitationDetailQuery["enrollmentInvitation"],
): EnrollmentInvitationFormValues.Detail {
	return {
		createdAt: enrollmentInvitation.createdAt,
		response: enrollmentInvitation.response,
		studentID: enrollmentInvitation.student?.id ?? null,
	};
}
