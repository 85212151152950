import { Course, CourseSection, CourseSectionPeriod, HomeRoomSection, Term } from "../../GraphQL";
import { Create as CourseSectionPeriodCreate } from "./course-section-period";

export type Base = {
	name: CourseSection["name"];
	description: CourseSection["description"];
	courseID: Course["id"] | null;
	homeRoomSectionID: HomeRoomSection["id"] | null;
	termID: Term["id"] | null;
};

export type Create = Base;

export type CreateWithNestedPeriods = Create & {
	courseSectionPeriods: Omit<CourseSectionPeriodCreate, "courseSectionID">[];
};

export type Detail = Base & {
	courseSectionPeriodIDs: CourseSectionPeriod["id"][];
};

export const initialCreate: Create = {
	name: "",
	description: "",
	courseID: null,
	homeRoomSectionID: null,
	termID: null,
};
