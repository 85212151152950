import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	ContractInstallment,
	graphTypeNames,
	Invoice,
	InvoiceCreateInput,
	InvoiceCreateMutation,
	InvoiceDeleteMutation,
	InvoiceDetailDocument,
	InvoiceDetailQuery,
	InvoiceDetailQueryVariables,
	InvoiceLineItem,
	InvoiceUpdateInput,
	InvoiceUpdateMutation,
	PaymentLineItem,
	useInvoiceCreateMutation,
	useInvoiceDeleteMutation,
	useInvoiceUpdateMutation,
} from "../../GraphQL";
import { InvoiceFormConversion } from "../ModelFormConversion";
import { InvoiceFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the Invoice model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Invoice model.
 */
export function useCreate() {
	const [createInvoice] = useInvoiceCreateMutation();

	return React.useCallback(
		async (formValues: InvoiceFormValues.Create) => {
			const input = InvoiceFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createInvoice({ variables: { input }, update: updateCache });

			return { data: data?.createInvoice ?? null, errors: errors ?? null };
		},
		[createInvoice],
	);
}

/**
 * Returns an `update` function for the Invoice model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Invoice model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Invoice["id"]) {
	const [updateInvoice] = useInvoiceUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<InvoiceFormValues.Detail>, initialFormValues: InvoiceFormValues.Detail) => {
			const input = InvoiceFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateInvoice({ variables: { id, input }, update: updateCache });

			return { data: data?.updateInvoice ?? null, errors: errors ?? null };
		},
		[updateInvoice, id],
	);
}

/**
 * Returns a `del` function for the Invoice model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Invoice["id"]) {
	const [deleteInvoice] = useInvoiceDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteInvoice({ variables: { id }, update: updateCache });

		return { data: data?.deleteInvoice ?? false, errors: errors ?? null };
	}, [deleteInvoice, id]);
}

function getUpdateCacheForCreate(input: InvoiceCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<InvoiceCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createInvoice;

		cache.writeQuery<InvoiceDetailQuery, InvoiceDetailQueryVariables>({
			query: InvoiceDetailDocument,
			data: { invoice: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.contractInstallmentID) {
			addToInvoiceOfContractInstallmentCache(cache, input.contractInstallmentID, createdObject);
		}

		if (input.invoiceLineItemIDs) {
			for (let i = 0; i < input.invoiceLineItemIDs.length; i++) {
				addToInvoiceOfInvoiceLineItemCache(cache, input.invoiceLineItemIDs[i], createdObject);
			}
		}

		if (input.paymentLineItemIDs) {
			for (let i = 0; i < input.paymentLineItemIDs.length; i++) {
				addToInvoiceOfPaymentLineItemCache(cache, input.paymentLineItemIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "invoiceConnection" });
	};
}

function getUpdateCacheForUpdate(input: InvoiceUpdateInput, initialFormValues: InvoiceFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<InvoiceUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateInvoice;

		if (initialFormValues.contractInstallmentID && input.contractInstallmentID) {
			removeFromInvoiceOfContractInstallmentCache(cache, initialFormValues.contractInstallmentID, updatedObject);
		}
		if (input.contractInstallmentID) {
			addToInvoiceOfContractInstallmentCache(cache, input.contractInstallmentID, updatedObject);
		}

		if (input.addInvoiceLineItemIDs) {
			for (let i = 0; i < input.addInvoiceLineItemIDs.length; i++) {
				addToInvoiceOfInvoiceLineItemCache(cache, input.addInvoiceLineItemIDs[i], updatedObject);
			}
		}
		if (input.removeInvoiceLineItemIDs) {
			for (let i = 0; i < input.removeInvoiceLineItemIDs.length; i++) {
				removeFromInvoiceOfInvoiceLineItemCache(cache, input.removeInvoiceLineItemIDs[i], updatedObject);
			}
		}

		if (input.addPaymentLineItemIDs) {
			for (let i = 0; i < input.addPaymentLineItemIDs.length; i++) {
				addToInvoiceOfPaymentLineItemCache(cache, input.addPaymentLineItemIDs[i], updatedObject);
			}
		}
		if (input.removePaymentLineItemIDs) {
			for (let i = 0; i < input.removePaymentLineItemIDs.length; i++) {
				removeFromInvoiceOfPaymentLineItemCache(cache, input.removePaymentLineItemIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: Invoice["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<InvoiceDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteInvoice) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Invoice }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "invoiceConnection" });
		cache.gc();
	};
}

function addToInvoiceOfContractInstallmentCache(
	cache: ApolloCache<any>,
	targetID: ContractInstallment["id"],
	object: Pick<Invoice, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ContractInstallment }),
		fields: {
			invoice: () => objectRef,
		},
	});
}

function removeFromInvoiceOfContractInstallmentCache(
	cache: ApolloCache<any>,
	targetID: ContractInstallment["id"],
	_object: Pick<Invoice, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ContractInstallment }),
		fields: {
			invoice: () => null,
		},
	});
}

function addToInvoiceOfInvoiceLineItemCache(
	cache: ApolloCache<any>,
	targetID: InvoiceLineItem["id"],
	object: Pick<Invoice, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.InvoiceLineItem }),
		fields: {
			invoice: () => objectRef,
			invoiceID: () => object.id,
		},
	});
}

function removeFromInvoiceOfInvoiceLineItemCache(
	cache: ApolloCache<any>,
	targetID: InvoiceLineItem["id"],
	_object: Pick<Invoice, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.InvoiceLineItem }),
		fields: {
			invoice: () => null,
			invoiceID: () => null,
		},
	});
}

function addToInvoiceOfPaymentLineItemCache(
	cache: ApolloCache<any>,
	targetID: PaymentLineItem["id"],
	object: Pick<Invoice, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.PaymentLineItem }),
		fields: {
			invoice: () => objectRef,
			invoiceID: () => object.id,
		},
	});
}

function removeFromInvoiceOfPaymentLineItemCache(
	cache: ApolloCache<any>,
	targetID: PaymentLineItem["id"],
	_object: Pick<Invoice, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.PaymentLineItem }),
		fields: {
			invoice: () => null,
			invoiceID: () => null,
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Invoice, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment InvoiceRef on Invoice {
				id
			}
		`,
		data: object,
	});
}
