import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { DiscountHubCreatePage } from "./CreatePage";
import { discountHubCreatePageInfo } from "./CreatePage/pageinfo";
import { DiscountHubDetailPage } from "./DetailPage";
import { discountHubDetailPageInfo } from "./DetailPage/pageinfo";
import { DiscountHubIndexPage } from "./IndexPage";
import { discountHubIndexPageInfo } from "./IndexPage/pageinfo";
import { discountHubPageInfo } from "./pageinfo";

export function DiscountHub() {
	useActivePageRegistration(discountHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...discountHubIndexPageInfo.routeProps}>
				<DiscountHubIndexPage />
			</Route>
			<Route {...discountHubCreatePageInfo.routeProps}>
				<DiscountHubCreatePage />
			</Route>
			<Route {...discountHubDetailPageInfo.routeProps}>
				<DiscountHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
