import { AcademicYearTuition } from "../../../GraphQL";
import { formatPrice } from "../../money";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the year field of the AcademicYearTuition model.
 */
export function year(value: AcademicYearTuition["year"] | null): string {
	return toString(value);
}

/**
 * Formats the nurseryPrice field of the AcademicYearTuition model.
 */
export function nurseryPrice(value: AcademicYearTuition["nurseryPrice"] | null): string {
	return formatPrice(value);
}

/**
 * Formats the receptionPrice field of the AcademicYearTuition model.
 */
export function receptionPrice(value: AcademicYearTuition["receptionPrice"] | null): string {
	return formatPrice(value);
}

/**
 * Formats the primaryPrice field of the AcademicYearTuition model.
 */
export function primaryPrice(value: AcademicYearTuition["primaryPrice"] | null): string {
	return formatPrice(value);
}

/**
 * Formats the secondaryPrice field of the AcademicYearTuition model.
 */
export function secondaryPrice(value: AcademicYearTuition["secondaryPrice"] | null): string {
	return formatPrice(value);
}
