import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormState } from "@hex-insights/forms";
import { NoteFilterInput, NoteOrder, useNotePaginationQuery } from "../../../../Utilities";

export type NotePaginationFormProps = {
	formState: FormState<IndexForms.PaginationFormValues>;
	filters: NoteFilterInput | NoteFilterInput[] | null;
	order: NoteOrder | null;
	setPaginationInfo: (action: React.SetStateAction<IndexForms.PaginationInfo>) => void;
};

/**
 * Renders the pagination form of the Note model.
 */
export function NotePaginationForm({ formState, filters, order, setPaginationInfo }: NotePaginationFormProps) {
	const { loading, data } = useNotePaginationQuery({ variables: { filters, order } });

	return (
		<IndexForms.PaginationForm
			formState={formState}
			isLoading={loading}
			connection={data?.noteConnection}
			setPaginationInfo={setPaginationInfo}
		/>
	);
}
