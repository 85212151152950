import { IndexForms } from "@hex-insights/app-modules";
import { PersonOrderFormValues } from "../ModelOrderFormValues";

/**
 * Returns a `formState` object for use in the order form of the Person model.
 */
export function useFormState() {
	return IndexForms.useOrderFormStateWithQueryStateSync(
		PersonOrderFormValues.initial,
		PersonOrderFormValues.fieldValues,
	);
}
