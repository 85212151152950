import { Note, NoteAttachment } from "../../GraphQL";

export type Base = {
	order: NoteAttachment["order"] | null;
	isFile: NoteAttachment["isFile"];
	file: File | null;
	linkURL: NoteAttachment["linkURL"];
	noteID: Note["id"] | null;
};

export type Create = Base;

export type CreateInNote = Omit<Create, "noteID">;

export type Detail = Base & {
	url: NoteAttachment["url"];
};

export const initialCreate: Create = {
	order: null,
	isFile: true,
	file: null,
	linkURL: "",
	noteID: null,
};

export const blankItemInNote: CreateInNote = {
	order: null,
	isFile: true,
	file: null,
	linkURL: "",
};
