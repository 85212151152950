import { newPageInfo } from "@hex-insights/router";
import { ContractInstallment } from "@hex-insights/verita.shared";
import { contractInstallmentHubPageInfo } from "../pageinfo";

type ContractInstallmentForRouteTitle = Pick<ContractInstallment, "id">;

export const contractInstallmentHubDetailPageInfo = newPageInfo({
	name: "Contract Installment Detail",
	title: (e: ContractInstallmentForRouteTitle) => e.id,
	description: "Detail page for an contractInstallment.",
	exact: false,
	isLeaf: true,
	to: (id: ContractInstallment["id"]) => contractInstallmentHubPageInfo.to + `/${id}`,
	path: contractInstallmentHubPageInfo.path + "/:id(\\d+)",
	parent: contractInstallmentHubPageInfo,
});
