import { newPageInfo } from "@hex-insights/router";
import { schoolAttendanceRecordHubPageInfo } from "../pageinfo";

export const schoolAttendanceRecordHubCreatePageInfo = newPageInfo({
	name: "New School Attendance Record",
	title: "New",
	description: "Create a new School Attendance Record record.",
	exact: true,
	to: schoolAttendanceRecordHubPageInfo.to + "/new",
	path: schoolAttendanceRecordHubPageInfo.path + "/new",
	parent: schoolAttendanceRecordHubPageInfo,
});
