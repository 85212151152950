import React from "react";
import { DateTimeField, FieldDisplayArgs, FormType, SelectField, TextField } from "@hex-insights/forms";
import {
	EnrollmentApplicationNoteFormValues,
	EnrollmentApplicationSelect,
	useEnrollmentApplicationSelectLazyQuery,
	UserSelect,
	useUserSelectLazyQuery,
} from "../../../../Utilities";
import { EnrollmentApplicationLink, UserLink } from "../../../Links";
import { FutureBaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the EnrollmentApplicationNote model.
 */
type FieldProps<
	K extends keyof EnrollmentApplicationNoteFormValues.Base = keyof EnrollmentApplicationNoteFormValues.Base,
> = FutureBaseFieldProps<Pick<EnrollmentApplicationNoteFormValues.Base, K>>;

/**
 * Generic props for fields of the EnrollmentApplicationNote model that only appear in the detail form.
 */
type DetailFieldProps<
	K extends keyof EnrollmentApplicationNoteFormValues.Detail = keyof EnrollmentApplicationNoteFormValues.Detail,
> = FutureBaseFieldProps<Pick<EnrollmentApplicationNoteFormValues.Detail, K>>;

/**
 * Renders a field component for the `createdAt` field of the EnrollmentApplicationNote model.
 */
export function CreatedAt({ formState, ...props }: DetailFieldProps<"createdAt">) {
	return (
		<DateTimeField
			formState={formState}
			name="createdAt"
			optional={FormType.isCreate(formState.formType)}
			precision="minute"
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `body` field of the EnrollmentApplicationNote model.
 */
export function Body({ formState, ...props }: FieldProps<"body">) {
	return <TextField formState={formState} name="body" multiLine {...props} />;
}

export type AuthorProps = DetailFieldProps<"authorID"> & {
	currentAuthor?: UserSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `author` edge of the EnrollmentApplicationNote model.
 */
export function Author({ formState, currentAuthor, ...props }: AuthorProps) {
	const [loadOptions, { loading, data }] = useUserSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.authorID) {
			loadOptions();
		}
	}, [formState.formEditing.authorID, loadOptions]);
	const options = React.useMemo(
		() => UserSelect.toOptions(data?.userConnection.edges, currentAuthor),
		[data, currentAuthor],
	);

	return (
		<SelectField
			formState={formState}
			name="authorID"
			isLoading={loading}
			options={options}
			display={displayAuthor}
			blankValue={null}
			{...props}
		/>
	);
}

function displayAuthor({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserLink instance={{ id }}>{formattedValue}</UserLink>;
}

export type EnrollmentApplicationProps = FieldProps<"enrollmentApplicationID"> & {
	currentEnrollmentApplication?: EnrollmentApplicationSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `enrollmentApplication` edge of the EnrollmentApplicationNote model.
 */
export function EnrollmentApplication({
	formState,
	currentEnrollmentApplication,
	...props
}: EnrollmentApplicationProps) {
	const [loadOptions, { loading, data }] = useEnrollmentApplicationSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.enrollmentApplicationID) {
			loadOptions();
		}
	}, [formState.formEditing.enrollmentApplicationID, loadOptions]);
	const options = React.useMemo(
		() =>
			EnrollmentApplicationSelect.toOptions(data?.enrollmentApplicationConnection.edges, currentEnrollmentApplication),
		[data, currentEnrollmentApplication],
	);

	return (
		<SelectField
			formState={formState}
			name="enrollmentApplicationID"
			isLoading={loading}
			options={options}
			display={displayEnrollmentApplication}
			blankValue={null}
			{...props}
		/>
	);
}

function displayEnrollmentApplication({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <EnrollmentApplicationLink instance={{ id }}>{formattedValue}</EnrollmentApplicationLink>;
}
